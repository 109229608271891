/**
 * @name views.history
 * @namespace Summaries of past seasons, leaguewide.
 */
define('views/historyNational',["dao", "globals", "ui", "core/player", "core/team", "lib/bluebird", "lib/knockout", "util/bbgmView", "util/helpers", "views/components"], function (dao, g, ui, player, team, Promise, ko, bbgmView, helpers, components) {
     "use strict";

    function get(req) {
        var season;

        season = helpers.validateSeason(req.params.season);

        // If playoffs aren't over, season awards haven't been set
        if (g.phase <= g.PHASE.NATIONAL_PLAYOFFS) {
            // View last season by default
            if (season === g.season) {
                season -= 1;
            }
        }

        if (season < g.startingSeason) {
            return {
                errorMessage: "There is no league history yet. Check back after the playoffs."
            };
        }

        return {
            season: season
        };
    }

    function updateHistory(inputs, updateEvents, vm) {


        if (updateEvents.indexOf("dbChange") >= 0 || updateEvents.indexOf("firstRun") >= 0 || vm.season() !== inputs.season) {
            return Promise.all([
                dao.players.getAll({
                    index: "retiredYear",
                    key: inputs.season
                }),
                team.filter({
				   attrs: ["tid","cid","abbrev", "region", "name"],
					seasonAttrs: ["playoffRoundsWonNational"],
                    season: inputs.season
                })
            ]).spread(function ( retiredPlayers, teams) {
					var i;
					var champNat,runnerUpNat,madePlayoffs1,madePlayoffs2,wonCotten,wonPeach,wonOrange,wonAlamo,wonFiesta,wonMusicCity,wonBelk,wonLiberty,wonOutback;
					var wonHoliday,wonCitrus,wonBocaRaton,wonTexas,wonIndependence,wonAthletic,wonTaxSlayer,wonSun,wonLasVegas,wonBeach,wonPinstripe,wonFarms,wonStPetersburg,wonPotato;
					var wonMilitary,wonNewMexico,wonBahamas,wonBirmingham,wonCactus,wonArmedForces,wonQuickLane,wonHawaii,wonHeartOfDallas,wonPoinsettia,wonGoDaddy,wonNewOrleans;
					var wonCamellia,lostCotten,lostPeach,lostOrange,lostAlamo,lostFiesta,lostMusicCity,lostBelk,lostLiberty,lostOutback,lostHoliday,lostCitrus,lostBocaRaton;
					var lostTexas,lostIndependence,lostAthletic,lostTaxSlayer,lostSun,lostLasVegas,lostBeach,lostPinstripe,lostFarms,lostStPetersburg,lostPotato,lostMilitary;
					var lostNewMexico,lostBahamas,lostBirmingham,lostCactus,lostArmedForces,lostQuickLane,lostHawaii,lostHeartOfDallas,lostPoinsettia,lostGoDaddy,lostNewOrleans,lostCamellia;

					var finalfour;
					var eliteeight,eliteeight1,eliteeight2,eliteeight3,eliteeight4;
					var sweet,sweet1,sweet2,sweet3,sweet4,sweet5,sweet6,sweet7,sweet8;

					var showExtraConferences,showExtraConferences2;			
			
                    retiredPlayers = player.filter(retiredPlayers, {
                        attrs: ["pid", "name", "age","year", "hof"],
                        season: inputs.season
                    });
                    for (i = 0; i < retiredPlayers.length; i++) {
                        // Show age at retirement, not current age
                        retiredPlayers[i].age -= g.season - inputs.season;
                    }					
                    retiredPlayers.sort(function (a, b) { return a.age - b.age; });


			//			var champ,champ2,champ3,champ4,champ5,champ6,champ7,champ8,champ9,champ10,champ11,champ12,champ13,champ14,champ15,champ16,champ17,champ18,champ19,champ20, i;
			//			var conf,conf2,conf3,conf4,conf5,conf6,conf7,conf8,conf9,conf10,conf11,conf12,conf13,conf14,conf15,conf16,conf17,conf18,conf19,conf20;


						
						showExtraConferences = false;
 						showExtraConferences2 = false;					  
           /*             for (i = 0; i < teams.length; i++) {
//                            if ((teams[i].playoffRoundsWon === 1) && (teams[i].cid == userCid ) ){
                            if ((teams[i].playoffRoundsWon === 1) ){
                                champ = teams[i];
                                break;
                            }
                        } */
						var numberConferences,showPlayoffs4,showPlayoffs32,showPlayoffs64;
						// console.log(g.phase);
						numberConferences = 5;
						showPlayoffs4 = true;
						showPlayoffs32 = false;
						showPlayoffs64 = false;
										
						if (g.gameType == 0) {
							numberConferences = 5;
							//numberDivisions = 10;
							showExtraConferences = false;
							showExtraConferences2 = false;
							showPlayoffs4 = true;
							showPlayoffs32 = false;
							showPlayoffs64 = false;
						} else if (g.gameType == 1) {						
							numberConferences = 5;			
							showExtraConferences = true;
							showExtraConferences2 = false;
							showPlayoffs4 = true;
							showPlayoffs32 = false;
							showPlayoffs64 = false;
						} else if (g.gameType == 2) {						
							numberConferences = 10;			
							showExtraConferences = true;
							showExtraConferences2 = true;
							showPlayoffs4 = true;
							showPlayoffs32 = false;
							showPlayoffs64 = false;
						} else if ( (g.gameType == 3)) {
							numberConferences = 15;			
							showExtraConferences = false;
							showExtraConferences2 = false;
							showPlayoffs4 = false;
							showPlayoffs32 = true;
							showPlayoffs64 = false;
						} else if ( (g.gameType == 4)) {
							numberConferences = 20;			
							showExtraConferences = false;
							showExtraConferences2 = false;
							showPlayoffs4 = false;
							showPlayoffs32 = true;
							showPlayoffs64 = false;
						} else if ( (g.gameType == 5)) {
							numberConferences = 20;			
							showExtraConferences = false;
							showExtraConferences2 = false;
							showPlayoffs4 = false;
							showPlayoffs32 = true;
							showPlayoffs64 = false;
						} else if ( (g.gameType == 6)) {
							numberConferences = 5;			
							showExtraConferences = false;
							showExtraConferences2 = false;
							showPlayoffs4 = false;
							showPlayoffs32 = true;
							showPlayoffs64 = false;
						}							
						  // console.log(teams.length);
						  
						for (i = 0; i < teams.length; i++) {
						  // console.log(teams[i].playoffRoundsWonNational);
						}

						/*	champ: champ,
							runnerUp: runnerUp,
							madePlayoffs1: madePlayoffs1,
							madePlayoffs2: madePlayoffs2,*/
                       
                        if 	(g.gameType < 3) {
							
								for (i = 0; i < teams.length; i++) {
									if (teams[i].playoffRoundsWonNational > 160) {                        
										champNat = teams[i];	
								  // console.log(teams[i].playoffRoundsWonNational);
										
										break;
									}
								}
							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >80) && (teams[i].playoffRoundsWonNational <= 160) ) {      
										runnerUpNat = teams[i];						
								  // console.log(teams[i].playoffRoundsWonNational);
										
										break;
									}
								}				
							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >= 77) && (teams[i].playoffRoundsWonNational <= 80) ) {      
										madePlayoffs1 = teams[i];						
								  // console.log(teams[i].playoffRoundsWonNational);
										
										break;
									}
								}				
							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >= 77) && (teams[i].playoffRoundsWonNational <= 80)  && (madePlayoffs1 != teams[i])) {      
										madePlayoffs2 = teams[i];						
								  // console.log(teams[i].playoffRoundsWonNational);
										
										break;
										
									}
								}	

							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >154) && (teams[i].playoffRoundsWonNational <= 156) ) {      
										wonCotten = teams[i];						
										break;
									}
								}				
							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >74) && (teams[i].playoffRoundsWonNational <= 76) ) {      
										lostCotten = teams[i];						
										break;
									}
								}		
							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >152) && (teams[i].playoffRoundsWonNational <= 154) ) {      
										wonPeach = teams[i];						
										break;
									}
								}				
							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >72) && (teams[i].playoffRoundsWonNational <= 74) ) {      
										lostPeach = teams[i];						
										break;
									}
								}		
							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >150) && (teams[i].playoffRoundsWonNational <= 152) ) {      
										wonOrange = teams[i];						
										break;
									}
								}				
							   for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWonNational >70) && (teams[i].playoffRoundsWonNational <= 72) ) {      
										lostOrange = teams[i];						
										break;
									}
								}							
														
								if ((g.gameType > 0)) {
									showExtraConferences = true;	
									
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >148) && (teams[i].playoffRoundsWonNational <= 150) ) {      
											wonAlamo = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >68) && (teams[i].playoffRoundsWonNational <= 70) ) {      
											lostAlamo = teams[i];						
											break;
										}
									}		
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >146) && (teams[i].playoffRoundsWonNational <= 148) ) {      
											wonFiesta = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >66) && (teams[i].playoffRoundsWonNational <= 68) ) {      
											lostFiesta = teams[i];						
											break;
										}
									}							
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >144) && (teams[i].playoffRoundsWonNational <= 146) ) {      
											wonBelk = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >64) && (teams[i].playoffRoundsWonNational <= 66) ) {      
											lostBelk = teams[i];						
											break;
										}
									}							
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >142) && (teams[i].playoffRoundsWonNational <= 144) ) {      
											wonMusicCity = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 62) && (teams[i].playoffRoundsWonNational <= 64) ) {      
											lostMusicCity = teams[i];						
											break;
										}
									}							
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 140) && (teams[i].playoffRoundsWonNational <= 142) ) {      
											wonLiberty = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 60) && (teams[i].playoffRoundsWonNational <= 62) ) {      
											lostLiberty = teams[i];						
											break;
										}
									}							

									
								} else {
								
									wonAlamo = champNat;						
									lostAlamo = champNat;	
									wonFiesta = champNat;						
									lostFiesta = champNat;										
									wonMusicCity = champNat;	
									wonBelk = champNat;	
									wonLiberty = champNat;	
									lostMusicCity = champNat;	
									lostBelk = champNat;	
									lostLiberty = champNat;	
									
								
								}
								
								if ((g.gameType > 1)) {
									showExtraConferences2 = true;					  
		 
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >138) && (teams[i].playoffRoundsWonNational <= 140) ) {      
											wonOutback = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >58) && (teams[i].playoffRoundsWonNational <= 60) ) {      
											lostOutback = teams[i];						
											break;
										}
									}							
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >136) && (teams[i].playoffRoundsWonNational <= 138) ) {      
											wonHoliday = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >56) && (teams[i].playoffRoundsWonNational <= 58) ) {      
											lostHoliday = teams[i];						
											break;
										}
									}							

								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >134) && (teams[i].playoffRoundsWonNational <= 136) ) {      
											wonCitrus = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >54) && (teams[i].playoffRoundsWonNational <= 56) ) {      
											lostCitrus = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >132) && (teams[i].playoffRoundsWonNational <= 134) ) {      
											wonBocaRaton = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >52) && (teams[i].playoffRoundsWonNational <= 54) ) {      
											lostBocaRaton = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >130) && (teams[i].playoffRoundsWonNational <= 132) ) {      
											wonTexas = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >50) && (teams[i].playoffRoundsWonNational <= 52) ) {      
											lostTexas = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >128) && (teams[i].playoffRoundsWonNational <= 130) ) {      
											wonIndependence = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 48) && (teams[i].playoffRoundsWonNational <= 50) ) {      
											lostIndependence = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >126) && (teams[i].playoffRoundsWonNational <= 128) ) {      
											wonAthletic = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >46) && (teams[i].playoffRoundsWonNational <= 48) ) {      
											lostAthletic = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >124) && (teams[i].playoffRoundsWonNational <= 126) ) {      
											wonTaxSlayer = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >44) && (teams[i].playoffRoundsWonNational <= 46) ) {      
											lostTaxSlayer = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >122) && (teams[i].playoffRoundsWonNational <= 124) ) {      
											wonSun = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >42) && (teams[i].playoffRoundsWonNational <= 44) ) {      
											lostSun = teams[i];						
											break;
										}
									}	

								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >120) && (teams[i].playoffRoundsWonNational <= 122) ) {      
											wonLasVegas = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >40) && (teams[i].playoffRoundsWonNational <= 42) ) {      
											lostLasVegas = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >118) && (teams[i].playoffRoundsWonNational <= 120) ) {      
											wonBeach = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >38) && (teams[i].playoffRoundsWonNational <= 40) ) {      
											lostBeach = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >116) && (teams[i].playoffRoundsWonNational <= 118) ) {      
											wonPinstripe = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >36) && (teams[i].playoffRoundsWonNational <= 38) ) {      
											lostPinstripe = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >114) && (teams[i].playoffRoundsWonNational <= 116) ) {      
											wonFarms = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >34) && (teams[i].playoffRoundsWonNational <= 36) ) {      
											lostFarms = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >112) && (teams[i].playoffRoundsWonNational <= 114) ) {      
											wonStPetersburg = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >32) && (teams[i].playoffRoundsWonNational <= 34) ) {      
											lostStPetersburg = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >110) && (teams[i].playoffRoundsWonNational <= 112) ) {      
											wonPotato = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >30) && (teams[i].playoffRoundsWonNational <= 32) ) {      
											lostPotato = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >108) && (teams[i].playoffRoundsWonNational <= 110) ) {      
											wonMilitary = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >28) && (teams[i].playoffRoundsWonNational <= 30) ) {      
											lostMilitary = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >106) && (teams[i].playoffRoundsWonNational <= 108) ) {      
											wonNewMexico = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >26) && (teams[i].playoffRoundsWonNational <= 28) ) {      
											lostNewMexico = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >104) && (teams[i].playoffRoundsWonNational <= 106) ) {      
											wonBahamas = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >24) && (teams[i].playoffRoundsWonNational <= 26) ) {      
											lostBahamas = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >102) && (teams[i].playoffRoundsWonNational <= 104) ) {      
											wonBirmingham = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >22) && (teams[i].playoffRoundsWonNational <= 24) ) {      
											lostBirmingham = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >100) && (teams[i].playoffRoundsWonNational <= 102) ) {      
											wonCactus = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 20) && (teams[i].playoffRoundsWonNational <= 22) ) {      
											lostCactus = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >98) && (teams[i].playoffRoundsWonNational <= 100) ) {      
											wonArmedForces = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >18) && (teams[i].playoffRoundsWonNational <= 20) ) {      
											lostArmedForces = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >96) && (teams[i].playoffRoundsWonNational <= 98) ) {      
											wonQuickLane = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >16) && (teams[i].playoffRoundsWonNational <= 18) ) {      
											lostQuickLane = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 94) && (teams[i].playoffRoundsWonNational <= 96) ) {      
											wonHawaii = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >14) && (teams[i].playoffRoundsWonNational <= 16) ) {      
											lostHawaii = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >92) && (teams[i].playoffRoundsWonNational <= 94) ) {      
											wonHeartOfDallas = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >12) && (teams[i].playoffRoundsWonNational <= 14) ) {      
											lostHeartOfDallas = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >90) && (teams[i].playoffRoundsWonNational <= 92) ) {      
											wonPoinsettia = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >10) && (teams[i].playoffRoundsWonNational <= 12) ) {      
											lostPoinsettia = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational >88) && (teams[i].playoffRoundsWonNational <= 90) ) {      
											wonGoDaddy = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 8) && (teams[i].playoffRoundsWonNational <= 10) ) {      
											lostGoDaddy = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 86) && (teams[i].playoffRoundsWonNational <= 88) ) {      
											wonNewOrleans = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 6) && (teams[i].playoffRoundsWonNational <= 8) ) {      
											lostNewOrleans = teams[i];						
											break;
										}
									}			
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 84) && (teams[i].playoffRoundsWonNational <= 86) ) {      
											wonCamellia = teams[i];						
											break;
										}
									}				
								   for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWonNational > 4) && (teams[i].playoffRoundsWonNational <= 6) ) {      
											lostCamellia = teams[i];						
											break;
										}
									}			
									eliteeight1 = champNat;
									eliteeight2 = champNat;
									eliteeight3 = champNat;
									eliteeight4 = champNat;
									sweet1 = champNat;
									sweet2 = champNat;
									sweet3 = champNat;
									sweet4 = champNat;
									sweet5 = champNat;
									sweet6 = champNat;
									sweet7 = champNat;
									sweet8 = champNat;									
									
		 
								}			else {
								   wonOutback = champNat;		
								   lostOutback = champNat;		
								   wonHoliday = champNat;		
								   lostHoliday = champNat;		
									wonCitrus = champNat;
									wonBocaRaton = champNat;
									wonTexas = champNat;
									wonIndependence = champNat;
									wonAthletic = champNat;
									wonTaxSlayer = champNat;
									wonSun = champNat;
									wonLasVegas = champNat;
									wonBeach = champNat;
									wonPinstripe = champNat;
									wonFarms = champNat;
									wonStPetersburg = champNat;
									wonPotato = champNat;
									wonMilitary = champNat;
									wonNewMexico = champNat;
									wonBahamas = champNat;
									wonBirmingham = champNat;
									wonCactus = champNat;
									wonArmedForces = champNat;
									wonQuickLane = champNat;
									wonHawaii = champNat;
									wonHeartOfDallas = champNat;
									wonPoinsettia = champNat;
									wonGoDaddy = champNat;
									wonNewOrleans = champNat;
									wonCamellia = champNat;
									lostCitrus = champNat;
									lostBocaRaton = champNat;
									lostTexas = champNat;
									lostIndependence = champNat;
									lostAthletic = champNat;
									lostTaxSlayer = champNat;
									lostSun = champNat;
									lostLasVegas = champNat;
									lostBeach = champNat;
									lostPinstripe = champNat;
									lostFarms = champNat;
									lostStPetersburg = champNat;
									lostPotato = champNat;
									lostMilitary = champNat;
									lostNewMexico = champNat;
									lostBahamas = champNat;
									lostBirmingham = champNat;
									lostCactus = champNat;
									lostArmedForces = champNat;
									lostQuickLane = champNat;
									lostHawaii = champNat;
									lostHeartOfDallas = champNat;
									lostPoinsettia = champNat;
									lostGoDaddy = champNat;
									lostNewOrleans = champNat;
									lostCamellia = champNat;							
									eliteeight1 = champNat;
									eliteeight2 = champNat;
									eliteeight3 = champNat;
									eliteeight4 = champNat;
									sweet1 = champNat;
									sweet2 = champNat;
									sweet3 = champNat;
									sweet4 = champNat;
									sweet5 = champNat;
									sweet6 = champNat;
									sweet7 = champNat;
									sweet8 = champNat;
								}						
										
						} else {
							 // Get champs
							 
								finalfour = 0;
								eliteeight = 0;
								sweet = 0;
								for (i = 0; i < teams.length; i++) {
							//	   // console.log("i: "+i+" teams[i].playoffRoundsWonNational: "+teams[i].playoffRoundsWonNational+" finalfour: "+finalfour);
									if (teams[i].playoffRoundsWonNational === 5) {
										champNat = teams[i];
									  //  break;
									}
									if (teams[i].playoffRoundsWonNational === 4) {
										runnerUpNat = teams[i];
								   //     break;
									}					
									if ((teams[i].playoffRoundsWonNational === 3) && (finalfour === 0)) {
										finalfour += 1;
										madePlayoffs1 = teams[i];
								   //     break;
									} else  if ((teams[i].playoffRoundsWonNational === 3) && (finalfour === 1)) {
										finalfour += 1;					
										madePlayoffs2 = teams[i];
								   //     break;
									}					
									if ((teams[i].playoffRoundsWonNational === 2) && (eliteeight === 0)) {
										eliteeight += 1;
										eliteeight1 = teams[i];
								   //     break;
									} else  if ((teams[i].playoffRoundsWonNational === 2) && (eliteeight === 1)) {
										eliteeight += 1;					
										eliteeight2 = teams[i];
								   //     break;
									} else  if ((teams[i].playoffRoundsWonNational === 2) && (eliteeight === 2)) {
										eliteeight += 1;					
										eliteeight3 = teams[i];
									} else  if ((teams[i].playoffRoundsWonNational === 2) && (eliteeight === 3)) {
										eliteeight += 1;					
										eliteeight4 = teams[i];
									}					
									
									if ((teams[i].playoffRoundsWonNational === 1) && (sweet === 0)) {
										sweet += 1;
										sweet1 = teams[i];
								   //     break;
									} else  if ((teams[i].playoffRoundsWonNational === 1) && (sweet === 1)) {
										sweet += 1;					
										sweet2 = teams[i];
								   //     break;
									} else  if ((teams[i].playoffRoundsWonNational === 1) && (sweet === 2)) {
										sweet += 1;					
										sweet3 = teams[i];
									} else  if ((teams[i].playoffRoundsWonNational === 1) && (sweet === 3)) {
										sweet += 1;					
										sweet4 = teams[i];
									} else  if ((teams[i].playoffRoundsWonNational === 1) && (sweet === 4)) {
										sweet += 1;					
										sweet5 = teams[i];
									} else  if ((teams[i].playoffRoundsWonNational === 1) && (sweet === 5)) {
										sweet += 1;					
										sweet6 = teams[i];
									} else  if ((teams[i].playoffRoundsWonNational === 1) && (sweet === 6)) {
										sweet += 1;					
										sweet7 = teams[i];
									} else  if ((teams[i].playoffRoundsWonNational === 1) && (sweet === 7)) {
										sweet += 1;					
										sweet8 = teams[i];
									}										
									
									
								}
									wonCotten = champNat;		
									wonPeach = champNat;		
									wonOrange = champNat;		
									wonAlamo = champNat;		
									wonFiesta = champNat;		
									wonMusicCity = champNat;		
									wonBelk = champNat;		
									wonLiberty = champNat;		
									lostCotten = champNat;		
									lostPeach = champNat;		
									lostOrange = champNat;		
									lostAlamo = champNat;		
									lostFiesta = champNat;		
									lostMusicCity = champNat;		
									lostBelk = champNat;		
									lostLiberty = champNat;		
								
								   wonOutback = champNat;		
								   lostOutback = champNat;		
								   wonHoliday = champNat;		
								   lostHoliday = champNat;		
									wonCitrus = champNat;
									wonBocaRaton = champNat;
									wonTexas = champNat;
									wonIndependence = champNat;
									wonAthletic = champNat;
									wonTaxSlayer = champNat;
									wonSun = champNat;
									wonLasVegas = champNat;
									wonBeach = champNat;
									wonPinstripe = champNat;
									wonFarms = champNat;
									wonStPetersburg = champNat;
									wonPotato = champNat;
									wonMilitary = champNat;
									wonNewMexico = champNat;
									wonBahamas = champNat;
									wonBirmingham = champNat;
									wonCactus = champNat;
									wonArmedForces = champNat;
									wonQuickLane = champNat;
									wonHawaii = champNat;
									wonHeartOfDallas = champNat;
									wonPoinsettia = champNat;
									wonGoDaddy = champNat;
									wonNewOrleans = champNat;
									wonCamellia = champNat;
									lostCitrus = champNat;
									lostBocaRaton = champNat;
									lostTexas = champNat;
									lostIndependence = champNat;
									lostAthletic = champNat;
									lostTaxSlayer = champNat;
									lostSun = champNat;
									lostLasVegas = champNat;
									lostBeach = champNat;
									lostPinstripe = champNat;
									lostFarms = champNat;
									lostStPetersburg = champNat;
									lostPotato = champNat;
									lostMilitary = champNat;
									lostNewMexico = champNat;
									lostBahamas = champNat;
									lostBirmingham = champNat;
									lostCactus = champNat;
									lostArmedForces = champNat;
									lostQuickLane = champNat;
									lostHawaii = champNat;
									lostHeartOfDallas = champNat;
									lostPoinsettia = champNat;
									lostGoDaddy = champNat;
									lostNewOrleans = champNat;
									lostCamellia = champNat;											
								
						}
						
                      /*  champNat.tid = g.teamAbbrevsCache.indexOf(champNat.abbrev);
                        runnerUpNat.tid = g.teamAbbrevsCache.indexOf(runnerUpNat.abbrev);						
                        madePlayoffs1.tid = g.teamAbbrevsCache.indexOf(madePlayoffs1.abbrev);
                        madePlayoffs2.tid = g.teamAbbrevsCache.indexOf(madePlayoffs2.abbrev);						
                        eliteeight1.tid = g.teamAbbrevsCache.indexOf(eliteeight1.abbrev);
                        eliteeight2.tid = g.teamAbbrevsCache.indexOf(eliteeight2.abbrev);						
                        eliteeight3.tid = g.teamAbbrevsCache.indexOf(eliteeight3.abbrev);
                        eliteeight4.tid = g.teamAbbrevsCache.indexOf(eliteeight4.abbrev);						
                        sweet1.tid = g.teamAbbrevsCache.indexOf(sweet1.abbrev);
                        sweet2.tid = g.teamAbbrevsCache.indexOf(sweet2.abbrev);						
                        sweet3.tid = g.teamAbbrevsCache.indexOf(sweet3.abbrev);
                        sweet4.tid = g.teamAbbrevsCache.indexOf(sweet4.abbrev);						
                        sweet5.tid = g.teamAbbrevsCache.indexOf(sweet5.abbrev);
                        sweet6.tid = g.teamAbbrevsCache.indexOf(sweet6.abbrev);						
                        sweet7.tid = g.teamAbbrevsCache.indexOf(sweet7.abbrev);						
                        sweet8.tid = g.teamAbbrevsCache.indexOf(sweet8.abbrev);						
                        wonCotten.tid = g.teamAbbrevsCache.indexOf(wonCotten.abbrev);						
                        wonPeach.tid = g.teamAbbrevsCache.indexOf(wonPeach.abbrev);						
                        wonOrange.tid = g.teamAbbrevsCache.indexOf(wonOrange.abbrev);
                        wonAlamo.tid = g.teamAbbrevsCache.indexOf(wonAlamo.abbrev);						
                        wonFiesta.tid = g.teamAbbrevsCache.indexOf(wonFiesta.abbrev);
                        wonMusicCity.tid = g.teamAbbrevsCache.indexOf(wonMusicCity.abbrev);						
                        wonBelk.tid = g.teamAbbrevsCache.indexOf(wonBelk.abbrev);
                        wonLiberty.tid = g.teamAbbrevsCache.indexOf(wonLiberty.abbrev);						
                        wonOutback.tid = g.teamAbbrevsCache.indexOf(wonOutback.abbrev);
                        wonHoliday.tid = g.teamAbbrevsCache.indexOf(wonHoliday.abbrev);						
                        wonCitrus.tid = g.teamAbbrevsCache.indexOf(wonCitrus.abbrev);
                        wonBocaRaton.tid = g.teamAbbrevsCache.indexOf(wonBocaRaton.abbrev);						
                        wonTexas.tid = g.teamAbbrevsCache.indexOf(wonTexas.abbrev);
                        wonIndependence.tid = g.teamAbbrevsCache.indexOf(wonIndependence.abbrev);						
                        wonAthletic.tid = g.teamAbbrevsCache.indexOf(wonAthletic.abbrev);
                        wonTaxSlayer.tid = g.teamAbbrevsCache.indexOf(wonTaxSlayer.abbrev);						
                        wonSun.tid = g.teamAbbrevsCache.indexOf(wonSun.abbrev);						
                        wonLasVegas.tid = g.teamAbbrevsCache.indexOf(wonLasVegas.abbrev);						
                        wonBeach.tid = g.teamAbbrevsCache.indexOf(wonBeach.abbrev);						
                        wonPinstripe.tid = g.teamAbbrevsCache.indexOf(wonPinstripe.abbrev);						
                        wonFarms.tid = g.teamAbbrevsCache.indexOf(wonFarms.abbrev);						
                        wonStPetersburg.tid = g.teamAbbrevsCache.indexOf(wonStPetersburg.abbrev);						
                        wonPotato.tid = g.teamAbbrevsCache.indexOf(wonPotato.abbrev);						
                        wonMilitary.tid = g.teamAbbrevsCache.indexOf(wonMilitary.abbrev);						
                        wonNewMexico.tid = g.teamAbbrevsCache.indexOf(wonNewMexico.abbrev);						
                        wonBahamas.tid = g.teamAbbrevsCache.indexOf(wonBahamas.abbrev);						
                        wonBirmingham.tid = g.teamAbbrevsCache.indexOf(wonBirmingham.abbrev);						
                        wonCactus.tid = g.teamAbbrevsCache.indexOf(wonCactus.abbrev);						
                        wonArmedForces.tid = g.teamAbbrevsCache.indexOf(wonArmedForces.abbrev);						
                        wonQuickLane.tid = g.teamAbbrevsCache.indexOf(wonQuickLane.abbrev);						
                        wonHawaii.tid = g.teamAbbrevsCache.indexOf(wonHawaii.abbrev);						
                        wonHeartOfDallas.tid = g.teamAbbrevsCache.indexOf(wonHeartOfDallas.abbrev);						
                        wonPoinsettia.tid = g.teamAbbrevsCache.indexOf(wonPoinsettia.abbrev);						
                        wonGoDaddy.tid = g.teamAbbrevsCache.indexOf(wonGoDaddy.abbrev);						
                        wonNewOrleans.tid = g.teamAbbrevsCache.indexOf(wonNewOrleans.abbrev);						
                        wonCamellia.tid = g.teamAbbrevsCache.indexOf(wonCamellia.abbrev);						
                        lostCotten.tid = g.teamAbbrevsCache.indexOf(lostCotten.abbrev);						
                        lostPeach.tid = g.teamAbbrevsCache.indexOf(lostPeach.abbrev);						
                        lostOrange.tid = g.teamAbbrevsCache.indexOf(lostOrange.abbrev);
                        lostAlamo.tid = g.teamAbbrevsCache.indexOf(lostAlamo.abbrev);						
                        lostFiesta.tid = g.teamAbbrevsCache.indexOf(lostFiesta.abbrev);
                        lostMusicCity.tid = g.teamAbbrevsCache.indexOf(lostMusicCity.abbrev);						
                        lostBelk.tid = g.teamAbbrevsCache.indexOf(lostBelk.abbrev);
                        lostLiberty.tid = g.teamAbbrevsCache.indexOf(lostLiberty.abbrev);						
                        lostOutback.tid = g.teamAbbrevsCache.indexOf(lostOutback.abbrev);
                        lostHoliday.tid = g.teamAbbrevsCache.indexOf(lostHoliday.abbrev);						
                        lostCitrus.tid = g.teamAbbrevsCache.indexOf(lostCitrus.abbrev);
                        lostBocaRaton.tid = g.teamAbbrevsCache.indexOf(lostBocaRaton.abbrev);						
                        lostTexas.tid = g.teamAbbrevsCache.indexOf(lostTexas.abbrev);
                        lostIndependence.tid = g.teamAbbrevsCache.indexOf(lostIndependence.abbrev);						
                        lostAthletic.tid = g.teamAbbrevsCache.indexOf(lostAthletic.abbrev);
                        lostTaxSlayer.tid = g.teamAbbrevsCache.indexOf(lostTaxSlayer.abbrev);						
                        lostSun.tid = g.teamAbbrevsCache.indexOf(lostSun.abbrev);						
                        lostLasVegas.tid = g.teamAbbrevsCache.indexOf(lostLasVegas.abbrev);						
                        lostBeach.tid = g.teamAbbrevsCache.indexOf(lostBeach.abbrev);						
                        lostPinstripe.tid = g.teamAbbrevsCache.indexOf(lostPinstripe.abbrev);						
                        lostFarms.tid = g.teamAbbrevsCache.indexOf(lostFarms.abbrev);						
                        lostStPetersburg.tid = g.teamAbbrevsCache.indexOf(lostStPetersburg.abbrev);						
                        lostPotato.tid = g.teamAbbrevsCache.indexOf(lostPotato.abbrev);						
                        lostMilitary.tid = g.teamAbbrevsCache.indexOf(lostMilitary.abbrev);						
                        lostNewMexico.tid = g.teamAbbrevsCache.indexOf(lostNewMexico.abbrev);						
                        lostBahamas.tid = g.teamAbbrevsCache.indexOf(lostBahamas.abbrev);						
                        lostBirmingham.tid = g.teamAbbrevsCache.indexOf(lostBirmingham.abbrev);						
                        lostCactus.tid = g.teamAbbrevsCache.indexOf(lostCactus.abbrev);						
                        lostArmedForces.tid = g.teamAbbrevsCache.indexOf(lostArmedForces.abbrev);						
                        lostQuickLane.tid = g.teamAbbrevsCache.indexOf(lostQuickLane.abbrev);						
                        lostHawaii.tid = g.teamAbbrevsCache.indexOf(lostHawaii.abbrev);						
                        lostHeartOfDallas.tid = g.teamAbbrevsCache.indexOf(lostHeartOfDallas.abbrev);						
                        lostPoinsettia.tid = g.teamAbbrevsCache.indexOf(lostPoinsettia.abbrev);						
                        lostGoDaddy.tid = g.teamAbbrevsCache.indexOf(lostGoDaddy.abbrev);						
                        lostNewOrleans.tid = g.teamAbbrevsCache.indexOf(lostNewOrleans.abbrev);						
                        lostCamellia.tid = g.teamAbbrevsCache.indexOf(lostCamellia.abbrev);		*/				
				
						
                return {
                      //      awards: awards,
						//	showExtraConferences: showExtraConferences,							
							champNat: champNat,
							runnerUpNat: runnerUpNat,
							madePlayoffs1: madePlayoffs1,
							madePlayoffs2: madePlayoffs2,
							eliteeight1: eliteeight1,
							eliteeight2: eliteeight2,
							eliteeight3: eliteeight3,
							eliteeight4: eliteeight4,
							sweet1: sweet1,
							sweet2: sweet2,
							sweet3: sweet3,
							sweet4: sweet4,
							sweet5: sweet5,
							sweet6: sweet6,
							sweet7: sweet7,
							sweet8: sweet8,							
							wonCotten: wonCotten,
							wonPeach: wonPeach,
							wonOrange: wonOrange,
							wonAlamo: wonAlamo,
							wonFiesta: wonFiesta,
							wonMusicCity: wonMusicCity,
							wonBelk: wonBelk,
							wonLiberty: wonLiberty,
							wonOutback: wonOutback,
							wonHoliday: wonHoliday,
							wonCitrus: wonCitrus,
							wonBocaRaton: wonBocaRaton,
							wonTexas: wonTexas,
							wonIndependence: wonIndependence,
							wonAthletic: wonAthletic,
							wonTaxSlayer: wonTaxSlayer,
							wonSun: wonSun,
							wonLasVegas: wonLasVegas,
							wonBeach: wonBeach,
							wonPinstripe: wonPinstripe,
							wonFarms: wonFarms,
							wonStPetersburg: wonStPetersburg,
							wonPotato: wonPotato,
							wonMilitary: wonMilitary,
							wonNewMexico: wonNewMexico,
							wonBahamas: wonBahamas,
							wonBirmingham: wonBirmingham,
							wonCactus: wonCactus,
							wonArmedForces: wonArmedForces,
							wonQuickLane: wonQuickLane,
							wonHawaii: wonHawaii,
							wonHeartOfDallas: wonHeartOfDallas,
							wonPoinsettia: wonPoinsettia,
							wonGoDaddy: wonGoDaddy,
							wonNewOrleans: wonNewOrleans,
							wonCamellia: wonCamellia,
							lostCotten: lostCotten,
							lostPeach: lostPeach,
							lostOrange: lostOrange,
							lostAlamo: lostAlamo,
							lostFiesta: lostFiesta,
							lostMusicCity: lostMusicCity,							
							lostBelk: lostBelk,
							lostLiberty: lostLiberty,
							lostOutback: lostOutback,
							lostHoliday: lostHoliday,
							lostCitrus: lostCitrus,
							lostBocaRaton: lostBocaRaton,
							lostTexas: lostTexas,
							lostIndependence: lostIndependence,
							lostAthletic: lostAthletic,
							lostTaxSlayer: lostTaxSlayer,
							lostSun: lostSun,
							lostLasVegas: lostLasVegas,
							lostBeach: lostBeach,
							lostPinstripe: lostPinstripe,
							lostFarms: lostFarms,
							lostStPetersburg: lostStPetersburg,
							lostPotato: lostPotato,
							lostMilitary: lostMilitary,
							lostNewMexico: lostNewMexico,
							lostBahamas: lostBahamas,
							lostBirmingham: lostBirmingham,
							lostCactus: lostCactus,
							lostArmedForces: lostArmedForces,
							lostQuickLane: lostQuickLane,
							lostHawaii: lostHawaii,
							lostHeartOfDallas: lostHeartOfDallas,
							lostPoinsettia: lostPoinsettia,
							lostGoDaddy: lostGoDaddy,
							lostNewOrleans: lostNewOrleans,
							lostCamellia: lostCamellia,		
							showExtraConferences: showExtraConferences,
							showExtraConferences2: showExtraConferences2,
							showPlayoffs4: showPlayoffs4,
							showPlayoffs32: showPlayoffs32,
							showPlayoffs64: showPlayoffs64,														
                            retiredPlayers: retiredPlayers,
                            season: inputs.season,
							userTid: g.userTid
                };
            });
        }
    }

    function uiFirst(vm) {
        ko.computed(function () {
            ui.title("National Playoff Summary - " + vm.season());
        }).extend({throttle: 1});
    }

    function uiEvery(updateEvents, vm) {
        components.dropdown("history-national-dropdown", ["seasons"], [vm.season()], updateEvents);
    }

    return bbgmView.init({
        id: "historyNational",
        get: get,
        runBefore: [updateHistory],
        uiFirst: uiFirst,
        uiEvery: uiEvery
    });
});

/**
 * @name util.message
 * @namespace Messages from the owner of the team to the GM.
 */
define('util/message',["dao", "globals", "lib/bluebird", "util/helpers", "util/random"], function (dao, g, Promise, helpers, random) {


    "use strict";

    var activities, playoffs, intro, first, money, ovr, wins;

    // First message after new game
    first = [
        "<p>Hey, sorry I didn't recognize you in the lobby this morning. I'm sure I'll get to know you eventually. Maybe after I get back from my trip to Tahiti?</p><p>Well, listen. Times are rough. Donations only increased by 10% last year. I know, I know, horrible. Getting that new stadium is going to require a lot more than that!</p><p>So I'll cut right to the chase. We need donations. We need applicants. And we need championships. We like to consider ourselves on the same educational level as Harvard.</p><p>Make it happen.</p>"
    ];

    // Random activities the owner claims to be doing
    activities = [
        "doling out political favors for some undeserving applicants",
        "wondering why our basketball coach is so much better than you",
        "organizing orgies at the governor's mansion (he's a very particular gentleman)",
        "planning my daughter's million dollar wedding with school funds",
        "finding more of those teachers who know how to give the right grades for our athletes",
        "covering up that..., well let's just say this conversation never took place",
        "finalizing that $500k+ renovation to my school residence",
        "visiting my daughter-in-law",
        "going to conferences in Tahiti",
        "having sex with half the freshman girls (it's hard work, believe me)",
        "exchanging tips with our boosters about how to avoid detection when giving recruits gifts",
        "bribing the commissioner to let me ref our games",
        "working on my golf game with Kim Jong Il (no, he's not dead)",		
        "lobbying the state government for more subsidies",		
        "coming up with a way to slightly shrink or expand the hoop, depending on which one we're shooting at",
        "recruiting a potential Nobel prize winner",
        "helping one of your coaches with his underprivileged youth charity",
        "laughing about the association busting us again for paying players (as if more probation will stop us)",
        "wondering why the association is threatening our football program with the death penalty",
        "making sure the slush fund for player is working as it should",
        "figuring out how to game the US News ranking algorithm"		

//        "suing a local newspaper. Remember, when all else fails, buy your critics off or sue them into the ground.",
    ];

    // Intro of annual message
    intro = [];
    intro = [
        "Sorry we haven't chatted much this year, but I've been busy {{activity}}. "
    ];

    // 0: bad overall, getting worse
    // 1: bad overall, improving
    // 2: mediocre
    // 3: good overall, getting worse
    // 4: good overall, improving

    // Wins
    wins = [];
    wins[0] = [
        "This is an embarrassment. We lose so much, I can't even show my face around town. Becoming president was supposed to make me a celebrity, but not one of those bad celebrities that everyone hates. Turn it around.",
        "I need some wins. Our boosters hate losers. Applicants hate losers. What's your strategy? Keep on losing until I fire you? You're making good progress, then."
    ];
    wins[1] = [
        "I recognize we're getting better and our team has some potential for growth, but don't fuck this up. You've already used up most of my patience.",
        "You keep telling me we have \"potential\", but potential doesn't win games."
    ];
    wins[2] = [
        "So, I mean, it could be worse. But that's not good enough.",
        "Doing OK doesn't bring in the applicants and the donations.  Mediocrity can be worse than losing. I hope you have some plan to get us to the next level."
    ];
    wins[3] = [
        "Don't think you can coast on your past success for too long. I'm not planning on rebuilding for a decade.",
        "What have you done for me lately?"
    ];
    wins[4] = [
        "I'm pleased with our regular season performance.",
        "I like the roster you've put together. We'll be at the top of our conference for a long time."
    ];

    // Playoffs
    playoffs = [];
	
	if (g.gameType < 3) { 
	
	   playoffs[0] = [
			"Our alumni are starving, absolutely starving, for some bowl exposure. But with the job you're doing, we're not even close to a bowl. Unacceptable.",
			"Bowl? Don't talk to me about that. You kidding me? Bowl? I just hope we can win a game!"
		];
		playoffs[1] = [
			"With our alumni, you can't just be happy with making a bowl game. You have to get to the next level.",
			"A bowl loss is boring.",
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making a bowl is okay I guess, but I'm not satisfied.",			
		];
		playoffs[2] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, winning a bowl game is okay I guess, but I'm not satisfied.",
			"We need a national championship. Soon."
		];
		
		playoffs[3] = [
			"Consistent bowl success is the standard. Never forget that.",
			"I hope you don't plan on missing a bowl again."
		];
		playoffs[4] = [
			"Winning national championships can cover up a lot of flaws.",
			"I need more applicants and donations. Go get me another national championship."
		];	
	
	} else if (g.gametype == 5) { 
	
	
	   playoffs[0] = [
			"Our alumni are starving, absolutely starving, for some playoff success. But with the job you're doing, we're not even close to the playoffs. Unacceptable.",
			"Playoffs? Don't talk to me about that. You kidding me? Playoffs? I just hope we can win a game!"
		];
		playoffs[1] = [
			"With our alumni, you can't just be happy with making the playoffs. You have to get to the next level.",
			"A first round playoff exit is boring."
		];
		playoffs[2] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making the playoffs is okay I guess, but I'm not satisfied.",
			"We need to make some real noise in the playoffs. Soon."
		];
	   playoffs[3] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, winning one game in the playoffs is okay I guess, but I'm not satisfied.",
			"We need to make some real noise in the playoffs. Soon."
		];
		playoffs[4] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making it to the sweet sixteen was sweet, but I'm not satisfied.",
			"Making the sweet sixteen still means you're tied for 8th loser. Make it further next time."
		];
		playoffs[5] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making it to the elite eight was no small feat, but I'm not satisfied.",
			"At another school an elite eight appearance would mean your golden. Not here."
		];	
		playoffs[6] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making it to the final four was awesome, but I'm not satisfied.",
			"Everybody loved that final four appearance. Don't rip our hearts out next time with a loss."
		];		
		playoffs[7] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Don't choke next time.",
			"I knew you were a choker when we hired you. That is your fatal flaw.",
			"So close we could almost taste the national championship. Too bad we choked."		
		];		
			
		
		playoffs[8] = [
			"Consistent playoff success is the standard. Never forget that.",
			"I hope you don't plan on missing the playoffs again."
		];
		playoffs[9] = [
			"Winning national championships can cover up a lot of flaws.",
			"I need more applicants and donations. Go get me another national championships."
		];	
	
	} else {
	
		playoffs[0] = [
			"Our alumni are starving, absolutely starving, for some playoff success. But with the job you're doing, we're not even close to the playoffs. Unacceptable.",
			"playoffs? Don't talk to me about that. You kidding me? Playoffs? I just hope we can win a game!"
		];
		playoffs[1] = [
			"With our alumni, you can't just be happy with making the playoffs. You have to get to the next level.",
			"A first round playoff exit is boring."
		];
		playoffs[2] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making the playoffs is okay I guess, but I'm not satisfied.",
			"We need to make some real noise in the playoffs. Soon."
		];
		playoffs[3] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making it to the sweet sixteen was sweet, but I'm not satisfied.",
			"Making the sweet sixteen still means you're tied for 8th loser. Make it further next time."
		];
		playoffs[4] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making it to the elite eight was no small feat, but I'm not satisfied.",
			"At another school an elite eight appearance would mean your golden. Not here."
		];	
		playoffs[5] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Yeah, making it to the final four was awesome, but I'm not satisfied.",
			"Everybody loved that final four appearance. Don't rip our hearts out next time with a loss."
		];		
		playoffs[6] = [
			"Hey. I'm a champion. I don't know about you, but that's what this school does. We win championships. Don't choke next time.",
			"I knew you were a choker when we hired you. That is your fatal flaw.",
			"So close we could almost taste the national championship. Too bad we choked."		
		];		

		playoffs[7] = [
			"Consistent playoff success is the standard. Never forget that.",
			"I hope you don't plan on missing the playoffs again."
		];
		playoffs[8] = [
			"Winning national championships can cover up a lot of flaws.",
			"I need more applicants and donations. Go get me another national championship."
		];

	}
    // Money // now regular conference playoffs
	// something else?
    money = [];
    money[0] = [
        "Honesty, how are we going to win a national championship when we can't even win our division?",
        "Nobody said it was easy. But that is what we brought you here for. We need to be on top of our division."
	
//        "Money is an issue. The school is going broke. This is ridiculous. Enrollment should be growing, but I can barely afford my monacle polish these days.",
 //       "I can't afford a season in the red. Is it really that hard to turn a big profit in this business?"
    ];
    money[1] = [
        "With our alumni, you can't just be happy with making the conference championship. You have to get to the next level.",
        "Winning the division is great, but we want to dominate the conference."
    ];
    money[2] = [
        "With our alumni, you can't just be happy with making the conference championship. You have to get to the next level.",
        "Winning the division is great, but we want to dominate the conference."
    ];	
    money[3] = [
        "We expect to always compete for a conference championship. We fell short this year.",
        "I hope that wasn't a sign you aren't good enough for this conference anymore. Next year you better be back on track."
    ];
    money[4] = [
        "Well you've proved this conference sucks.",
        "I'm making some calls. Hopefully we can get some better teams in this conference. Anybody could beat these scrubs."
    ];

    // 0: bad
    // 1: mediocre
    // 2: good

    // Overall
    ovr = [];
    ovr[0] = [
        "Bye.",
        "Please, don't bother me until you have some good news.",
        "I'm watching you. Seriously, one of your assistant coaches is a spy. Don't fuck up."
    ];
    ovr[1] = [
        "You bore me. Everything about you, it's just boring. Come talk to me when you've got that stadium paid for and won me some more championships.",
        "You know, coaches aren't hired to be mediocre. Do better next year."
    ];
    ovr[2] = [
        "Anyway, overall I'm happy with the progress you've made, but I need to get back to {{activity}}."
    ];

    /**
     * @param {IDBTransaction} tx An IndexedDB transaction on gameAttributes and messages, readwrite.
     */
    function generate(tx, deltas) {
        var activity1, activity2, indMoney,  indPlayoffs, indOvr, indWins, m, ownerMoodSum;

        // If auto play seasons or multi team mode, no messages
        if (g.autoPlaySeasons > 0 || g.userTids.length > 1) {
            return Promise.resolve();
        }		
		
        ownerMoodSum = g.ownerMood.wins + g.ownerMood.playoffs + g.ownerMood.money;

        if (g.showFirstOwnerMessage) {
            m = random.choice(first);
            require("core/league").setGameAttributes(tx, {showFirstOwnerMessage: false}); // Okay that this is async, since it won't be called again until much later
        } else {
            activity1 = random.choice(activities);
            activity2 = random.choice(activities);
            while (activity1 === activity2) {
                activity2 = random.choice(activities);
            }

			// console.log("wins: "+g.ownerMood.wins+" "+deltas.wins );
			// console.log("playoffs: "+g.ownerMood.playoffs+" "+deltas.playoffs );
			// console.log("money: "+g.ownerMood.money+" "+deltas.money );
			
            indWins = 2;
            if (g.ownerMood.wins <= 0 && deltas.wins < 0) {
                indWins = 0;
            } else if (g.ownerMood.wins < -0.5 && deltas.wins >= 0) {
                indWins = 1;
            } else if (g.ownerMood.wins > 0 && deltas.wins < 0) {
                indWins = 3;
            } else if (g.ownerMood.wins > 0 && deltas.wins > 0) {
                indWins = 4;
            }

			
			//NT
			if (g.gameType < 3) { 
			
				indPlayoffs = 0;
				if (g.ownerMood.playoffs <= 0 && deltas.playoffs < 0) {
					indPlayoffs = 0;
				} else if (deltas.playoffs < 0.0534) {
					indPlayoffs = 1;
				} else if (deltas.playoffs < 0.0866) {
					indPlayoffs = 2;
				} else if (g.ownerMood.playoffs > 0 && deltas.playoffs < 0) {
					indPlayoffs = 3;
				} else if (g.ownerMood.playoffs > 0 && deltas.playoffs > 0) {
					indPlayoffs = 4;
				}

			
			} else if (g.gameType == 5) {
			
				indPlayoffs = 0;
				if (g.ownerMood.playoffs <= 0 && deltas.playoffs < 0) {
					indPlayoffs = 0;
				} else if (g.ownerMood.playoffs <= 0 && deltas.playoffs === 0) {
					indPlayoffs = 1;
				} else if (g.ownerMood.playoffs <= 0 && deltas.playoffs > 0) {
					indPlayoffs = 3;
					if (deltas.playoffs == .04) {
						indPlayoffs = 4;
					} else if (deltas.playoffs == .06) {
						indPlayoffs = 5;
					} else if (deltas.playoffs == .08) {
						indPlayoffs = 6;
					} else if (deltas.playoffs == .10) {
						indPlayoffs = 7;
					}
					
				} else if (g.ownerMood.playoffs >= 0 && deltas.playoffs >= 0) {
					indPlayoffs = 2;
					if (deltas.playoffs == .02) {
						indPlayoffs = 3;
					} else if (deltas.playoffs == .04) {
						indPlayoffs = 4;
					} else if (deltas.playoffs == .06) {
						indPlayoffs = 5;
					} else if (deltas.playoffs == .08) {
						indPlayoffs = 6;
					} else if (deltas.playoffs == .10) {
						indPlayoffs = 7;
					}	
				} else if (g.ownerMood.playoffs >= 0 && deltas.playoffs < 0) {
					indPlayoffs = 8;
				}
				if (deltas.playoffs === 0.2) {
					indPlayoffs = 9;
				}
			
			
			} else {
			
				indPlayoffs = 0;
				if (g.ownerMood.playoffs <= 0 && deltas.playoffs < 0) {
					indPlayoffs = 0;
				} else if (g.ownerMood.playoffs <= 0 && deltas.playoffs === 0) {
					indPlayoffs = 1;
				} else if (g.ownerMood.playoffs <= 0 && deltas.playoffs > 0 ) {
					indPlayoffs = 3;
					if (deltas.playoffs == .05) {
						indPlayoffs = 4;
					} else if (deltas.playoffs == .075) {
						indPlayoffs = 5;
					} else if (deltas.playoffs == .1) {
						indPlayoffs = 6;
					}
					
				} else if (g.ownerMood.playoffs >= 0 && deltas.playoffs >= 0) {
					indPlayoffs = 2;
					if (deltas.playoffs == .025) {
						indPlayoffs = 3;
					} else if (deltas.playoffs == .05) {
						indPlayoffs = 4;
					} else if (deltas.playoffs == .075) {
						indPlayoffs = 5;
					} else if (deltas.playoffs == .100) {
						indPlayoffs = 6;
					}				
				} else if (g.ownerMood.playoffs >= 0 && deltas.playoffs < 0) {
					indPlayoffs = 7;
				}
				if (deltas.playoffs === 0.2) {
					indPlayoffs = 8;
				}			
			
			}
			
/*            if (g.ownerMood.playoffs <= 0 && deltas.playoffs < 0) {
                indPlayoffs = 0;
            } else if (g.ownerMood.playoffs <= 0 && deltas.playoffs === 0) {
                indPlayoffs = 1;
            } else if (g.ownerMood.playoffs <= 0 && deltas.playoffs > 0) {
                indPlayoffs = 2;
            } else if (g.ownerMood.playoffs >= 0 && deltas.playoffs >= 0) {
                indPlayoffs = 2;
            } else if (g.ownerMood.playoffs >= 0 && deltas.playoffs < 0) {
                indPlayoffs = 3;
            }
            if (deltas.playoffs === 0.2) {
                indPlayoffs = 4;
            } */

			
			
            indMoney = 0;
            if (g.ownerMood.money < -0.5 && deltas.money == .10) {
                indMoney = 1;
            } else if (deltas.money == .10) {
                indMoney = 2;
            } else if (g.ownerMood.money > 0 && deltas.money < 0) {
                indMoney = 3;
            } else if (deltas.money > 0) {
                indMoney = 4;
            }

            indOvr = 1;
            if (ownerMoodSum > 0.5) {
                indOvr = 2;
            } else if (ownerMoodSum < -0.5) {
                indOvr = 0;
            }

			
			// console.log("ownerMoodSum: "+ownerMoodSum);
			// console.log("indMoney: "+indMoney);
			// console.log("indPlayoffs: "+indPlayoffs);
			// console.log("indWins: "+indWins);
			
			
            if (ownerMoodSum > -1) {
                m = "<p>" + random.choice(intro).replace("{{activity}}", activity1) + "</p>" +
                    "<p>" + random.choice(wins[indWins]) + " " + random.choice(playoffs[indPlayoffs]) + "</p>" +
                    "<p>" + random.choice(money[indMoney]) + "</p>" +
                    "<p>" + random.choice(ovr[indOvr]).replace("{{activity}}", activity2) + "</p>";
            //} else if (g.season < g.gracePeriodEnd) {
			} else if (g.season < g.gracePeriodEnd || g.godMode ) {		

               if (deltas.wins < 0 && deltas.playoffs < 0 && deltas.money < 0) {
                    m = "<p>What the hell did you do to our program?! I'd fire you, but I can't find anyone who wants to clean up your mess.</p>";
                } else if (deltas.money < 1 && deltas.wins >= 0 && deltas.playoffs >= 0) {
                    m = "<p>We can't consistently be the best team if we can't even win own conference championship. We expect more from you.</p>";
                } else if (deltas.money > 0 && deltas.wins > 0 && deltas.playoffs < 0) {
                    m = "<p>Our booster are out for blood. Make some bowl games, or I'll let those animals have you.</p>";
                } else if (deltas.money > 0 && deltas.wins < 0 && deltas.playoffs < 0) {
                    m = "<p>Getting lucky in the conference isn't enough. The program is built on quicksand. Figure it out.</p>";
                } else if (deltas.money > 0 && deltas.wins < 0 && deltas.playoffs > 0) {
                    m = "<p>You are the luckiest SOB I've ever seen. But luck runs out. </p>";
                } else if (deltas.money < 1 && deltas.wins < 0 && deltas.playoffs > 0) {
                    m = "<p>Our conference isn't that hard. This is embarrassing.</p>";
                } else {
                    m = "<p>The longer you keep your job, the more I question why I hired you. Do better or get out.</p>";
                }			
			

			
            /*    if (deltas.wins < 0 && deltas.playoffs < 0 && deltas.money < 0) {
                    m = "<p>What the hell did you do to my franchise?! I'd fire you, but I can't find anyone who wants to clean up your mess.</p>";
                } else if (deltas.money < 0 && deltas.wins >= 0 && deltas.playoffs >= 0) {
                    m = "<p>In thisI don't care what our colors are. I need to see some green! I won't wait forever. MAKE ME MONEY.</p>";
                } else if (deltas.money >= 0 && deltas.wins < 0 && deltas.playoffs < 0) {
                    m = "<p>Our fans are out for blood. Put a winning team together, or I'll let those animals have you.</p>";
                } else {
                    m = "<p>The longer you keep your job, the more I question why I hired you. Do better or get out.</p>";
                }*/
            } else {
			
				if (g.ownerMood.wins < 0 && g.ownerMood.playoffs < 0 && g.ownerMood.money < 0) {
                    m = "<p>You've been an all-around disappointment. You're fired.</p>";
                } else if (g.ownerMood.money < 1 && g.ownerMood.wins < 0 && g.ownerMood.playoffs >= 0) {
                    m = "<p>Our conference is just too tough for you right now.  You're fired.</p>";
                } else if (g.ownerMood.money < 1 && g.ownerMood.wins >= 0 && g.ownerMood.playoffs < 0) {
                    m = "<p>Regular seasons wins are great, but your teams just haven't been built for championship success. We need someone who can put it all together.  You're fired.</p>";
                } else if (g.ownerMood.money > 0 && g.ownerMood.wins <= 0 && g.ownerMood.playoffs < 0) {
                    m = "<p>You are mired in mediocrity. We don't want to be just an average team in our conference. We want to dominate. You're fired.</p>";
                } else if (g.ownerMood.money > 0 && g.ownerMood.wins < 0 && g.ownerMood.playoffs > 0) {
                    m = "<p>You've been very lucky. Just think if we had a coach that had some skill?  You're fired.</p>";
                } else if (g.ownerMood.money > 0 && g.ownerMood.wins >= 0 && g.ownerMood.playoffs <= 0) {
                    m = "<p>You have been a solid coach in our conference, but we expect national success. You're fired.</p>";
                } else if (g.ownerMood.money > 1 && g.ownerMood.wins < 0 && g.ownerMood.playoffs < 0) {
                    m = "<p>Getting lucky in the conference championship isn't enough. We need better players and national success. You're fired.</p>";
                } else {
                    m = "<p>You're fired.</p>";
                }					
			
			
                /*if (g.ownerMood.wins < 0 && g.ownerMood.playoffs < 0 && g.ownerMood.money < 0) {
                    m = "<p>You've been an all-around disappointment. You're fired.</p>";
                } else if (g.ownerMood.money < 0 && g.ownerMood.wins >= 0 && g.ownerMood.playoffs >= 0) {
                    m = "<p>You've won some games, but you're just not making me enough profit. It's not all about wins and losses, dollars matter too. You're fired.</p>";
                } else if (g.ownerMood.money >= 0 && g.ownerMood.wins < 0 && g.ownerMood.playoffs < 0) {
                    m = "<p>I like that you've made a nice profit for me, but you're not putting a competitive team on the court. We need a new direction. You're fired.</p>";
                } else {
                    m = "<p>You're fired.</p>";
                }*/
                m += '<p>I hear a few other teams are looking for a new coach. <a href="' + helpers.leagueUrl(["new_team"]) + '">Take a look.</a> Please, go run one of those teams into the ground.</p>';
            }
        }

      //  tx = dao.tx("messages", "readwrite");
        return dao.messages.add({
            ot: tx,
            value: {
                read: false,
                from: "The President",
                year: g.season,
                text: m
            }
        }).then(function () {
            if (ownerMoodSum > -1) {
                return;
            }
            if (g.season < g.gracePeriodEnd || g.godMode ) {
                // Can't get fired yet... or because of God Mode
                return;
            }
            // Fired!
            return require("core/league").setGameAttributes(tx, {
                gameOver: true,
                showFirstOwnerMessage: true
            });
        });
    }


    return {
        generate: generate
    };
});

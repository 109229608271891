/**
 * @name views.history
 * @namespace Summaries of past seasons, leaguewide.
 */
define('views/history',["globals", "ui", "core/player", "core/team", "lib/jquery", "lib/knockout", "util/bbgmView", "util/helpers", "util/viewHelpers", "views/components"], function (g, ui, player, team, $, ko, bbgmView, helpers, viewHelpers, components) {
    "use strict";

    function get(req) {
        var season;

        season = helpers.validateSeason(req.params.season);

        // If playoffs aren't over, season awards haven't been set
        if (g.phase <= g.PHASE.PLAYOFFS) {
            // View last season by default
            if (season === g.season) {
                season -= 1;
            }
        }

        if (season < g.startingSeason) {
            return {
                errorMessage: "There is no league history yet. Check back after the playoffs."
            };
        }

        return {
            season: season
        };
    }

    function updateHistory(inputs, updateEvents, vm) {
        var deferred;

        if (updateEvents.indexOf("dbChange") >= 0 || updateEvents.indexOf("firstRun") >= 0 || vm.season() !== inputs.season) {
            deferred = $.Deferred();

            g.dbl.transaction("awards").objectStore("awards").get(inputs.season).onsuccess = function (event) {
                var awards;

                awards = event.target.result;

                // Hack placeholder for old seasons before Finals MVP existed
                if (!awards.hasOwnProperty("finalsMvp")) {
                    awards.finalsMvp = {
                        pid: 0,
                        name: "N/A",
                        pts: 0,
                        trb: 0,
                        ast: 0
                    };
                }

                 dao.players.getAll({
                    index: "retiredYear",
                    key: inputs.season
                }, function (retiredPlayers) {
                    var i;

                    retiredPlayers = player.filter(retiredPlayers, {
                        attrs: ["pid", "name", "age", "hof"],
                        season: inputs.season,
                        fuzz: true
                    });
                    retiredPlayers.sort(function (a, b) { return b.age - a.age; });

                    team.filter({
                        attrs: ["tid","cid","abbrev", "region", "name"],
                        seasonAttrs: ["playoffRoundsWon"],
                        season: inputs.season
                    }, function (teams) {
                        var  i;
						var champ,champ2,champ3,champ4,champ5,champ6,champ7,champ8,champ9,champ10,champ11,champ12,champ13,champ14,champ15,champ16,champ17,champ18,champ19,champ20, i;
						var conf,conf2,conf3,conf4,conf5,conf6,conf7,conf8,conf9,conf10,conf11,conf12,conf13,conf14,conf15,conf16,conf17,conf18,conf19,conf20;

						var showExtraConferences;
						
						showExtraConferences = false;
           /*             for (i = 0; i < teams.length; i++) {
//                            if ((teams[i].playoffRoundsWon === 1) && (teams[i].cid == userCid ) ){
                            if ((teams[i].playoffRoundsWon === 1) ){
                                champ = teams[i];
                                break;
                            }
                        } */

						for (i = 0; i < teams.length; i++) {
						  console.log(teams[i].playoffRoundsWon);
						}
						
						for (i = 0; i < teams.length; i++) {
							if (teams[i].playoffRoundsWon === 1) {                        
								champ = teams[i];	
								conf = i;
								break;
							}
						}
					   for (i = 0; i < teams.length; i++) {
							if ((teams[i].playoffRoundsWon === 1) && (conf!=i)) {      
								champ2 = teams[i];						
								conf2 = i;						
								break;
							}
						}				
					   for (i = 0; i < teams.length; i++) {
					   
							if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i)) {                        
						
								champ3 = teams[i];						
								conf3 = i;						
								break;
							}
						}				
					   for (i = 0; i < teams.length; i++) {
							if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i)) {                        
								champ4 = teams[i];						
								conf4 = i;						
								break;
							}
						}				
					   for (i = 0; i < teams.length; i++) {
							if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i)) {                        
							
								champ5 = teams[i];						
								conf5 = i;						
								break;
							}
						}								
						if ((g.gameType == 2) || (g.gameType == 4)) {
							numberConferences = 20;
							showExtraConferences = true;					  
 

							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i)) {                        
										champ6 = teams[i];						
										conf6 = i;						
										break;
									}
								}					
							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i)) {                        
										champ7 = teams[i];						
										conf7 = i;						
										break;
									}
								}							
								
							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i)) {                        
										champ8 = teams[i];						
										conf8 = i;						
										break;
									}
								}						
								
							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i)) {                        
										champ9 = teams[i];						
										conf9 = i;						
										break;
									}
								}						
								
							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i)) {                        
										champ10 = teams[i];						
										conf10 = i;						
										break;
									}
								}	

							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i)) {                        
										champ11 = teams[i];						
										conf11 = i;						
										break;
									}
								}	

								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i)) {                        
										champ12 = teams[i];						
										conf12 = i;						
										break;
									}
								}					

								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i)) {                        
										champ13 = teams[i];	
										conf13 = i;						
										break;
									}
								}				

								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i)) { 
										champ14 = teams[i];	
										conf14 = i;						
										break;
									}
								}								
								
								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i)) { 
										champ15 = teams[i];	
										conf15 = i;						
										break;
									}
								}								
								
								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i)) { 
										champ16 = teams[i];	
										conf16 = i;						
										break;
									}
								}				

								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i) && (conf16!=i)) { 
										champ17 = teams[i];	
										conf17 = i;						
										break;
									}
								}

								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i) && (conf16!=i) && (conf17!=i)) { 
										champ18 = teams[i];	
										conf18 = i;						
										break;
									}
								}

								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i) && (conf16!=i) && (conf17!=i) && (conf18!=i)) { 
										champ19 = teams[i];	
										conf19 = i;						
										break;
									}
								}

								for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i) && (conf16!=i) && (conf17!=i) && (conf18!=i) && (conf19!=i)) { 
										champ20 = teams[i];	
										conf20 = i;						
										break;
									}
								}							
						}			else {
						   champ6 = champ;
						   champ7 = champ;						   
						   champ8 = champ;
						   champ9 = champ;
						   champ10 = champ;
						   champ11 = champ;
						   champ12 = champ;
						   champ13 = champ;
						   champ14 = champ;
						   champ15 = champ;
						   champ16 = champ;
						   champ17 = champ;
						   champ18 = champ;
						   champ19 = champ;
						   champ20 = champ;
						}						
								
						console.log(champ.tid);
						console.log(champ2.tid);
						console.log(champ3.tid);
						console.log(champ4.tid);
						console.log(champ5.tid);
						
                        deferred.resolve({
                            awards: awards,
							showExtraConferences: showExtraConferences,							
                            champ: champ,
							champ2: champ2,
							champ3: champ3,
							champ4: champ4,
							champ5: champ5,
							champ6: champ6,
							champ7: champ7,
							champ8: champ8,
							champ9: champ9,
							champ10: champ10,
							champ11: champ11,
							champ12: champ12,
							champ13: champ13,
							champ14: champ14,
							champ15: champ15,
							champ16: champ16,
							champ17: champ17,
							champ18: champ18,
							champ19: champ19,
							champ20: champ20,							
                            retiredPlayers: retiredPlayers,
                            season: inputs.season,
							userTid: g.userTid
                        });
                    });
                });
            };

            return deferred.promise();
        }
    }

    function uiFirst(vm) {
        ko.computed(function () {
            ui.title("Season Summary - " + vm.season());
        }).extend({throttle: 1});
    }

    function uiEvery(updateEvents, vm) {
        components.dropdown("history-dropdown", ["seasons"], [vm.season()], updateEvents);
    }

    return bbgmView.init({
        id: "history",
        get: get,
        runBefore: [updateHistory],
        uiFirst: uiFirst,
        uiEvery: uiEvery
    });
});

/**
 * @name views.playoffs
 * @namespace Show current or archived playoffs, or projected matchups for an in-progress season.
 */
define('views/playoffs',["dao", "globals", "ui", "core/team", "lib/knockout", "util/bbgmView", "util/helpers", "views/components"], function (dao, g, ui, team, ko, bbgmView, helpers, components) {
    "use strict";

    function get(req) {
        return {
            season: helpers.validateSeason(req.params.season)
        };
    }

    function updatePlayoffs(inputs, updateEvents, vm) {
        var deferred;

        if (updateEvents.indexOf("dbChange") >= 0 || updateEvents.indexOf("firstRun") >= 0 || inputs.season !== vm.season() || (inputs.season === g.season && updateEvents.indexOf("gameSim") >= 0)) {
            deferred = $.Deferred();
            
			var showExtraConferences;
			var numberConferences;
			
			showExtraConferences = false;
			if ((g.gameType == 2) || (g.gameType == 4)) {
				numberConferences = 20;
				showExtraConferences = true;					  
			}
			
            if (inputs.season === g.season && g.phase < g.PHASE.PLAYOFFS) {
                // In the current season, before playoffs start, display projected matchups
                team.filter({
                    attrs: ["tid","did", "cid", "abbrev", "name"],
                    seasonAttrs: ["winpConf","winp"],
                    season: inputs.season,
                    sortBy: ["winpConf","winp", "-lost", "won"]
                }, function (teams) {
                    var did,cid, i, j, keys, series, teamsConf;
					var numberConferences, numberDivisions;

					numberDivisions = 10;
					if ((g.gameType == 2) || (g.gameType == 4)) {
						numberDivisions = 40;
					} 
					
                    series = [];  // First round, second round, third round, fourth round
                    teamsConf = [];
                    for (did = 0; did < numberDivisions; did++) {					
//                    for (cid = 0; cid < 2; cid++) {
                        for (i = 0; i < teams.length; i++) {
                            if (teams[i].did === did) {
                                teamsConf.push(teams[i]);
								i = teams.length;
                            }
                        }    											    
                    }
					console.log(g.gameType);
					
					numberConferences = 5;

                    for (i = 0; i < numberConferences; i++) {
					
						series[ i  ] = {home: teamsConf[i*2], away: teamsConf[i*2+1]};
//						series[ i  ] = {home: teamsConf[i*2], away: teamsConf[teamTwo]};
						series[ i ].home.seed = 1;
						series[ i ].away.seed = 1;	

					}						
/*					series[0][ 1  ] = {home: teamsConf[2], away: teamsConf[3]};
					series[0][ 1 ].home.seed = 1;
					series[0][ 1 ].away.seed = 2;	
					series[0][ 0  ] = {home: teamsConf[4], away: teamsConf[5]};
					series[0][ 0 ].home.seed = 1;
					series[0][ 0 ].away.seed = 2;	
					series[0][ 0  ] = {home: teamsConf[6], away: teamsConf[7]};
					series[0][ 0 ].home.seed = 1;
					series[0][ 0 ].away.seed = 2;	
					series[0][ 0  ] = {home: teamsConf[8], away: teamsConf[9]};
					series[0][ 0 ].home.seed = 1;
					series[0][ 0 ].away.seed = 2;	*/
					
                    deferred.resolve({
                        finalMatchups: false,
                        showExtraConferences: showExtraConferences,
                        series: series,
                        season: inputs.season
                    });
                });
            } else {
                // Display the current or archived playoffs
                g.dbl.transaction("playoffSeries").objectStore("playoffSeries").get(inputs.season).onsuccess = function (event) {
                    var i, j, playoffSeries, series;

                    playoffSeries = event.target.result;
                    series = playoffSeries.series;

                    deferred.resolve({
                        finalMatchups: true,
                        showExtraConferences: showExtraConferences,
                        series: series,
                        season: inputs.season
                    });
                };
            }

            return deferred.promise();
        }
    }

    function uiFirst(vm) {
        ko.computed(function () {
            ui.title("Playoffs - " + vm.season());
        }).extend({throttle: 1});
    }

    function uiEvery(updateEvents, vm) {
        components.dropdown("playoffs-dropdown", ["seasons"], [vm.season()], updateEvents);
    }

    return bbgmView.init({
        id: "playoffs",
        get: get,
        runBefore: [updatePlayoffs],
        uiFirst: uiFirst,
        uiEvery: uiEvery
    });
});

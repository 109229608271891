/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/names',[], function () {
    "use strict";

    var first, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    first = [
        ["James", 3.318],
        ["John", 6.589],
        ["Robert", 9.732],
        ["Michael", 12.361],
        ["William", 14.812],
        ["David", 17.176],
        ["Richard", 18.878],
        ["Charles", 20.401],
        ["Joseph", 21.805],
        ["Thomas", 23.185],
        ["Christopher", 24.22],
        ["Daniel", 25.194],
        ["Paul", 26.142],
        ["Mark", 27.081],
        ["Donald", 28.012],
        ["George", 28.939],
        ["Kenneth", 29.766],
        ["Steven", 30.546],
        ["Edward", 31.325],
        ["Brian", 32.061],
        ["Ronald", 32.787],
        ["Anthony", 33.508],
        ["Kevin", 34.179],
        ["Jason", 34.839],
        ["Matthew", 35.496],
        ["Gary", 36.147],
        ["Timothy", 36.786],
        ["Jose", 37.399],
        ["Larry", 37.997],
        ["Jeffrey", 38.588],
        ["Frank", 39.169],
        ["Scott", 39.715],
        ["Eric", 40.259],
        ["Stephen", 40.799],
        ["Andrew", 41.335],
        ["Raymond", 41.824],
        ["Gregory", 42.265],
        ["Joshua", 42.7],
        ["Jerry", 43.132],
        ["Dennis", 43.547],
        ["Walter", 43.946],
        ["Patrick", 44.335],
        ["Peter", 44.716],
        ["Harold", 45.087],
        ["Douglas", 45.454],
        ["Henry", 45.819],
        ["Carl", 46.165],
        ["Arthur", 46.5],
        ["Ryan", 46.828],
        ["Roger", 47.15],
        ["Joe", 47.471],
        ["Juan", 47.786],
        ["Jack", 48.102],
        ["Albert", 48.415],
        ["Jonathan", 48.729],
        ["Justin", 49.04],
        ["Terry", 49.351],
        ["Gerald", 49.66],
        ["Keith", 49.968],
        ["Samuel", 50.274],
        ["Willie", 50.576],
        ["Ralph", 50.859],
        ["Lawrence", 51.141],
        ["Nicholas", 51.415],
        ["Roy", 51.688],
        ["Benjamin", 51.958],
        ["Bruce", 52.221],
        ["Brandon", 52.48],
        ["Adam", 52.74],
        ["Harry", 52.991],
        ["Fred", 53.241],
        ["Wayne", 53.49],
        ["Billy", 53.738],
        ["Steve", 53.984],
        ["Louis", 54.227],
        ["Jeremy", 54.469],
        ["Aaron", 54.71],
        ["Randy", 54.942],
        ["Howard", 55.172],
        ["Eugene", 55.402],
        ["Carlos", 55.63],
        ["Russell", 55.854],
        ["Bobby", 56.077],
        ["Victor", 56.299],
        ["Martin", 56.515],
        ["Ernest", 56.73],
        ["Phillip", 56.942],
        ["Todd", 57.155],
        ["Jesse", 57.364],
        ["Craig", 57.57],
        ["Alan", 57.774],
        ["Shawn", 57.973],
        ["Clarence", 58.17],
        ["Sean", 58.368],
        ["Philip", 58.565],
        ["Chris", 58.761],
        ["Johnny", 58.957],
        ["Earl", 59.149],
        ["Jimmy", 59.34],
        ["Antonio", 59.531],
        ["Danny", 59.72],
        ["Bryan", 59.91],
        ["Tony", 60.1],
        ["Luis", 60.289],
        ["Mike", 60.478],
        ["Stanley", 60.665],
        ["Leonard", 60.85],
        ["Nathan", 61.035],
        ["Dale", 61.219],
        ["Manuel", 61.4],
        ["Rodney", 61.581],
        ["Curtis", 61.761],
        ["Norman", 61.938],
        ["Allen", 62.112],
        ["Marvin", 62.283],
        ["Vincent", 62.45],
        ["Glenn", 62.617],
        ["Jeffery", 62.783],
        ["Travis", 62.949],
        ["Jeff", 63.114],
        ["Chad", 63.279],
        ["Jacob", 63.444],
        ["Lee", 63.606],
        ["Melvin", 63.768],
        ["Alfred", 63.93],
        ["Kyle", 64.09],
        ["Francis", 64.25],
        ["Bradley", 64.409],
        ["Jesus", 64.564],
        ["Herbert", 64.719],
        ["Frederick", 64.873],
        ["Ray", 65.026],
        ["Joel", 65.177],
        ["Edwin", 65.326],
        ["Don", 65.471],
        ["Eddie", 65.615],
        ["Ricky", 65.756],
        ["Troy", 65.895],
        ["Randall", 66.032],
        ["Barry", 66.167],
        ["Alexander", 66.299],
        ["Bernard", 66.427],
        ["Mario", 66.552],
        ["Leroy", 66.676],
        ["Francisco", 66.801],
        ["Marcus", 66.925],
        ["Micheal", 67.048],
        ["Theodore", 67.171],
        ["Clifford", 67.293],
        ["Miguel", 67.415],
        ["Oscar", 67.538],
        ["Jay", 67.656],
        ["Jim", 67.773],
        ["Tom", 67.89],
        ["Calvin", 68.006],
        ["Alex", 68.12],
        ["Jon", 68.235],
        ["Ronnie", 68.348],
        ["Bill", 68.461],
        ["Lloyd", 68.573],
        ["Tommy", 68.685],
        ["Leon", 68.797],
        ["Derek", 68.908],
        ["Warren", 69.018],
        ["Darrell", 69.126],
        ["Jerome", 69.234],
        ["Floyd", 69.34],
        ["Leo", 69.446],
        ["Alvin", 69.551],
        ["Tim", 69.656],
        ["Wesley", 69.76],
        ["Gordon", 69.864],
        ["Dean", 69.968],
        ["Greg", 70.071],
        ["Jorge", 70.175],
        ["Dustin", 70.278],
        ["Pedro", 70.381],
        ["Derrick", 70.484],
        ["Dan", 70.585],
        ["Lewis", 70.684],
        ["Zachary", 70.782],
        ["Corey", 70.88],
        ["Herman", 70.977],
        ["Maurice", 71.074],
        ["Vernon", 71.171],
        ["Roberto", 71.268],
        ["Clyde", 71.363],
        ["Glen", 71.457],
        ["Hector", 71.551],
        ["Shane", 71.645],
        ["Ricardo", 71.738],
        ["Sam", 71.83],
        ["Rick", 71.921],
        ["Lester", 72.011],
        ["Brent", 72.102],
        ["Ramon", 72.192],
        ["Charlie", 72.281],
        ["Tyler", 72.371],
        ["Gilbert", 72.46],
        ["Gene", 72.547],
        ["Marc", 72.634],
        ["Reginald", 72.717],
        ["Ruben", 72.8],
        ["Brett", 72.882],
        ["Angel", 72.964],
        ["Nathaniel", 73.045],
        ["Rafael", 73.126],
        ["Leslie", 73.207],
        ["Edgar", 73.287],
        ["Milton", 73.367],
        ["Raul", 73.446],
        ["Ben", 73.524],
        ["Chester", 73.602],
        ["Cecil", 73.68],
        ["Duane", 73.757],
        ["Franklin", 73.834],
        ["Andre", 73.91],
        ["Elmer", 73.984],
        ["Brad", 74.057],
        ["Gabriel", 74.13],
        ["Ron", 74.202],
        ["Mitchell", 74.274],
        ["Roland", 74.347],
        ["Arnold", 74.419],
        ["Harvey", 74.491],
        ["Jared", 74.562],
        ["Adrian", 74.631],
        ["Karl", 74.699],
        ["Cory", 74.767],
        ["Claude", 74.835],
        ["Erik", 74.903],
        ["Darryl", 74.97],
        ["Jamie", 75.037],
        ["Neil", 75.102],
        ["Jessie", 75.168],
        ["Christian", 75.233],
        ["Javier", 75.297],
        ["Fernando", 75.362],
        ["Clinton", 75.427],
        ["Ted", 75.491],
        ["Mathew", 75.555],
        ["Tyrone", 75.619],
        ["Darren", 75.683],
        ["Lonnie", 75.746],
        ["Lance", 75.81],
        ["Cody", 75.873],
        ["Julio", 75.936],
        ["Kelly", 75.998],
        ["Kurt", 76.061],
        ["Allan", 76.122],
        ["Nelson", 76.182],
        ["Guy", 76.243],
        ["Clayton", 76.303],
        ["Hugh", 76.363],
        ["Max", 76.421],
        ["Dwayne", 76.48],
        ["Dwight", 76.538],
        ["Armando", 76.596],
        ["Felix", 76.654],
        ["Jimmie", 76.711],
        ["Everett", 76.768],
        ["Jordan", 76.824],
        ["Ian", 76.88],
        ["Wallace", 76.936],
        ["Ken", 76.991],
        ["Bob", 77.047],
        ["Jaime", 77.102],
        ["Casey", 77.156],
        ["Alfredo", 77.21],
        ["Alberto", 77.263],
        ["Dave", 77.316],
        ["Ivan", 77.369],
        ["Johnnie", 77.421],
        ["Sidney", 77.474],
        ["Byron", 77.526],
        ["Julian", 77.578],
        ["Isaac", 77.629],
        ["Morris", 77.68],
        ["Clifton", 77.73],
        ["Willard", 77.78],
        ["Daryl", 77.831],
        ["Ross", 77.88],
        ["Virgil", 77.929],
        ["Andy", 77.979],
        ["Marshall", 78.028],
        ["Salvador", 78.077],
        ["Perry", 78.126],
        ["Kirk", 78.175],
        ["Sergio", 78.224],
        ["Marion", 78.272],
        ["Tracy", 78.32],
        ["Seth", 78.368],
        ["Kent", 78.416],
        ["Terrance", 78.464],
        ["Rene", 78.512],
        ["Eduardo", 78.559],
        ["Terrence", 78.606],
        ["Enrique", 78.652],
        ["Freddie", 78.698],
        ["Wade", 78.743],
        ["Austin", 78.786],
        ["Stuart", 78.83],
        ["Fredrick", 78.873],
        ["Arturo", 78.917],
        ["Alejandro", 78.96],
        ["Jackie", 79.002],
        ["Joey", 79.045],
        ["Nick", 79.088],
        ["Luther", 79.13],
        ["Wendell", 79.172],
        ["Jeremiah", 79.215],
        ["Evan", 79.257],
        ["Julius", 79.298],
        ["Dana", 79.34],
        ["Donnie", 79.381],
        ["Otis", 79.422],
        ["Shannon", 79.462],
        ["Trevor", 79.503],
        ["Oliver", 79.543],
        ["Luke", 79.583],
        ["Homer", 79.623],
        ["Gerard", 79.663],
        ["Doug", 79.703],
        ["Kenny", 79.742],
        ["Hubert", 79.782],
        ["Angelo", 79.821],
        ["Shaun", 79.859],
        ["Lyle", 79.898],
        ["Matt", 79.936],
        ["Lynn", 79.974],
        ["Alfonso", 80.012],
        ["Orlando", 80.049],
        ["Rex", 80.086],
        ["Carlton", 80.123],
        ["Ernesto", 80.16],
        ["Cameron", 80.197],
        ["Neal", 80.233],
        ["Pablo", 80.27],
        ["Lorenzo", 80.306],
        ["Omar", 80.342],
        ["Wilbur", 80.378],
        ["Blake", 80.414],
        ["Grant", 80.45],
        ["Horace", 80.486],
        ["Roderick", 80.521],
        ["Kerry", 80.557],
        ["Abraham", 80.592],
        ["Willis", 80.627],
        ["Rickey", 80.662],
        ["Jean", 80.696],
        ["Ira", 80.731],
        ["Andres", 80.766],
        ["Cesar", 80.8],
        ["Johnathan", 80.834],
        ["Malcolm", 80.868],
        ["Rudolph", 80.902],
        ["Damon", 80.936],
        ["Kelvin", 80.97],
        ["Rudy", 81.004],
        ["Preston", 81.037],
        ["Alton", 81.071],
        ["Archie", 81.104],
        ["Marco", 81.137],
        ["Wm", 81.17],
        ["Pete", 81.202],
        ["Randolph", 81.234],
        ["Garry", 81.267],
        ["Geoffrey", 81.299],
        ["Jonathon", 81.331],
        ["Felipe", 81.363],
        ["Bennie", 81.395],
        ["Gerardo", 81.427],
        ["Ed", 81.458],
        ["Dominic", 81.49],
        ["Robin", 81.522],
        ["Loren", 81.553],
        ["Delbert", 81.585],
        ["Colin", 81.616],
        ["Guillermo", 81.647],
        ["Earnest", 81.678],
        ["Lucas", 81.709],
        ["Benny", 81.739],
        ["Noel", 81.769],
        ["Spencer", 81.799],
        ["Rodolfo", 81.828],
        ["Myron", 81.858],
        ["Edmund", 81.887],
        ["Garrett", 81.917],
        ["Salvatore", 81.946],
        ["Cedric", 81.975],
        ["Lowell", 82.004],
        ["Gregg", 82.032],
        ["Sherman", 82.061],
        ["Wilson", 82.089],
        ["Devin", 82.117],
        ["Sylvester", 82.145],
        ["Kim", 82.173],
        ["Roosevelt", 82.201],
        ["Israel", 82.229],
        ["Jermaine", 82.257],
        ["Forrest", 82.284],
        ["Wilbert", 82.31],
        ["Leland", 82.337],
        ["Simon", 82.363],
        ["Guadalupe", 82.39],
        ["Clark", 82.416],
        ["Irving", 82.442],
        ["Carroll", 82.468],
        ["Bryant", 82.494],
        ["Owen", 82.519],
        ["Rufus", 82.545],
        ["Woodrow", 82.57],
        ["Sammy", 82.595],
        ["Kristopher", 82.62],
        ["Mack", 82.645],
        ["Levi", 82.67],
        ["Marcos", 82.695],
        ["Gustavo", 82.72],
        ["Jake", 82.744],
        ["Lionel", 82.769],
        ["Marty", 82.793],
        ["Taylor", 82.817],
        ["Ellis", 82.842],
        ["Dallas", 82.866],
        ["Gilberto", 82.89],
        ["Clint", 82.914],
        ["Nicolas", 82.938],
        ["Laurence", 82.962],
        ["Ismael", 82.985],
        ["Orville", 83.009],
        ["Drew", 83.033],
        ["Jody", 83.056],
        ["Ervin", 83.08],
        ["Dewey", 83.103],
        ["Al", 83.126],
        ["Wilfred", 83.15],
        ["Josh", 83.173],
        ["Hugo", 83.196],
        ["Ignacio", 83.219],
        ["Caleb", 83.241],
        ["Tomas", 83.264],
        ["Sheldon", 83.287],
        ["Erick", 83.31],
        ["Frankie", 83.332],
        ["Stewart", 83.354],
        ["Doyle", 83.377],
        ["Darrel", 83.399],
        ["Rogelio", 83.421],
        ["Terence", 83.443],
        ["Santiago", 83.465],
        ["Alonzo", 83.487],
        ["Elias", 83.508],
        ["Bert", 83.53],
        ["Elbert", 83.552],
        ["Ramiro", 83.573],
        ["Conrad", 83.595],
        ["Pat", 83.616],
        ["Noah", 83.638],
        ["Grady", 83.659],
        ["Phil", 83.681],
        ["Cornelius", 83.702],
        ["Lamar", 83.723],
        ["Rolando", 83.744],
        ["Clay", 83.765],
        ["Percy", 83.786],
        ["Dexter", 83.806],
        ["Bradford", 83.827],
        ["Merle", 83.848],
        ["Darin", 83.868],
        ["Amos", 83.888],
        ["Terrell", 83.909],
        ["Moses", 83.929],
        ["Irvin", 83.949],
        ["Saul", 83.968],
        ["Roman", 83.988],
        ["Darnell", 84.008],
        ["Randal", 84.027],
        ["Tommie", 84.047],
        ["Timmy", 84.066],
        ["Darrin", 84.086],
        ["Winston", 84.105],
        ["Brendan", 84.124],
        ["Toby", 84.144],
        ["Van", 84.163],
        ["Abel", 84.182],
        ["Dominick", 84.201],
        ["Boyd", 84.22],
        ["Courtney", 84.24],
        ["Jan", 84.259],
        ["Emilio", 84.277],
        ["Elijah", 84.296],
        ["Cary", 84.315],
        ["Domingo", 84.334],
        ["Santos", 84.353],
        ["Aubrey", 84.372],
        ["Emmett", 84.39],
        ["Marlon", 84.409],
        ["Emanuel", 84.428],
        ["Jerald", 84.446],
        ["Edmond", 84.465],
        ["Emil", 84.483],
        ["Dewayne", 84.502],
        ["Will", 84.52],
        ["Otto", 84.538],
        ["Teddy", 84.556],
        ["Reynaldo", 84.574],
        ["Bret", 84.592],
        ["Morgan", 84.61],
        ["Jess", 84.628],
        ["Trent", 84.646],
        ["Humberto", 84.664],
        ["Emmanuel", 84.681],
        ["Stephan", 84.699],
        ["Louie", 84.717],
        ["Vicente", 84.734],
        ["Lamont", 84.751],
        ["Stacy", 84.769],
        ["Garland", 84.786],
        ["Miles", 84.803],
        ["Micah", 84.82],
        ["Efrain", 84.837],
        ["Billie", 84.854],
        ["Logan", 84.871],
        ["Heath", 84.887],
        ["Rodger", 84.904],
        ["Harley", 84.921],
        ["Demetrius", 84.937],
        ["Ethan", 84.954],
        ["Eldon", 84.97],
        ["Rocky", 84.987],
        ["Pierre", 85.003],
        ["Junior", 85.02],
        ["Freddy", 85.036],
        ["Eli", 85.052],
        ["Bryce", 85.068],
        ["Antoine", 85.084],
        ["Robbie", 85.1],
        ["Kendall", 85.116],
        ["Royce", 85.132],
        ["Sterling", 85.148],
        ["Mickey", 85.164],
        ["Chase", 85.18],
        ["Grover", 85.196],
        ["Elton", 85.212],
        ["Cleveland", 85.228],
        ["Dylan", 85.243],
        ["Chuck", 85.259],
        ["Damian", 85.274],
        ["Reuben", 85.29],
        ["Stan", 85.305],
        ["August", 85.321],
        ["Leonardo", 85.336],
        ["Jasper", 85.351],
        ["Russel", 85.367],
        ["Erwin", 85.382],
        ["Benito", 85.397],
        ["Hans", 85.412],
        ["Monte", 85.427],
        ["Blaine", 85.442],
        ["Ernie", 85.456],
        ["Curt", 85.471],
        ["Quentin", 85.486],
        ["Agustin", 85.5],
        ["Murray", 85.515],
        ["Jamal", 85.529],
        ["Devon", 85.544],
        ["Adolfo", 85.558],
        ["Harrison", 85.573],
        ["Tyson", 85.587],
        ["Burton", 85.601],
        ["Brady", 85.616],
        ["Elliott", 85.63],
        ["Wilfredo", 85.644],
        ["Bart", 85.658],
        ["Jarrod", 85.672],
        ["Vance", 85.686],
        ["Denis", 85.7],
        ["Damien", 85.714],
        ["Joaquin", 85.728],
        ["Harlan", 85.742],
        ["Desmond", 85.756],
        ["Elliot", 85.77],
        ["Darwin", 85.783],
        ["Ashley", 85.797],
        ["Gregorio", 85.811],
        ["Buddy", 85.824],
        ["Xavier", 85.838],
        ["Kermit", 85.851],
        ["Roscoe", 85.865],
        ["Esteban", 85.878],
        ["Anton", 85.891],
        ["Solomon", 85.904],
        ["Scotty", 85.917],
        ["Norbert", 85.93],
        ["Elvin", 85.943],
        ["Williams", 85.956],
        ["Nolan", 85.969],
        ["Carey", 85.982],
        ["Rod", 85.994],
        ["Quinton", 86.007],
        ["Hal", 86.02],
        ["Brain", 86.033],
        ["Rob", 86.045],
        ["Elwood", 86.058],
        ["Kendrick", 86.07],
        ["Darius", 86.083],
        ["Moises", 86.096],
        ["Son", 86.108],
        ["Marlin", 86.12],
        ["Fidel", 86.133],
        ["Thaddeus", 86.145],
        ["Cliff", 86.158],
        ["Marcel", 86.17],
        ["Ali", 86.182],
        ["Jackson", 86.195],
        ["Raphael", 86.207],
        ["Bryon", 86.219],
        ["Armand", 86.231],
        ["Alvaro", 86.244],
        ["Jeffry", 86.256],
        ["Dane", 86.268],
        ["Joesph", 86.28],
        ["Thurman", 86.292],
        ["Ned", 86.304],
        ["Sammie", 86.316],
        ["Rusty", 86.328],
        ["Michel", 86.339],
        ["Monty", 86.351],
        ["Rory", 86.363],
        ["Fabian", 86.374],
        ["Reggie", 86.386],
        ["Mason", 86.397],
        ["Graham", 86.409],
        ["Kris", 86.42],
        ["Isaiah", 86.432],
        ["Vaughn", 86.443],
        ["Gus", 86.454],
        ["Avery", 86.466],
        ["Loyd", 86.477],
        ["Diego", 86.488],
        ["Alexis", 86.499],
        ["Adolph", 86.511],
        ["Norris", 86.522],
        ["Millard", 86.533],
        ["Rocco", 86.544],
        ["Gonzalo", 86.555],
        ["Derick", 86.566],
        ["Rodrigo", 86.577],
        ["Gerry", 86.588],
        ["Stacey", 86.599],
        ["Carmen", 86.61],
        ["Wiley", 86.621],
        ["Rigoberto", 86.632],
        ["Alphonso", 86.643],
        ["Ty", 86.654],
        ["Shelby", 86.664],
        ["Rickie", 86.675],
        ["Noe", 86.686],
        ["Vern", 86.696],
        ["Bobbie", 86.707],
        ["Reed", 86.717],
        ["Jefferson", 86.727],
        ["Elvis", 86.738],
        ["Bernardo", 86.748],
        ["Mauricio", 86.758],
        ["Hiram", 86.768],
        ["Donovan", 86.778],
        ["Basil", 86.789],
        ["Riley", 86.799],
        ["Ollie", 86.809],
        ["Nickolas", 86.819],
        ["Maynard", 86.829],
        ["Scot", 86.84],
        ["Vince", 86.85],
        ["Quincy", 86.86],
        ["Eddy", 86.87],
        ["Sebastian", 86.88],
        ["Federico", 86.89],
        ["Ulysses", 86.9],
        ["Heriberto", 86.91],
        ["Donnell", 86.92],
        ["Cole", 86.929],
        ["Denny", 86.939],
        ["Davis", 86.949],
        ["Gavin", 86.959],
        ["Emery", 86.969],
        ["Ward", 86.979],
        ["Romeo", 86.989],
        ["Jayson", 86.998],
        ["Dion", 87.008],
        ["Dante", 87.018],
        ["Clement", 87.028],
        ["Coy", 87.037],
        ["Odell", 87.047],
        ["Maxwell", 87.057],
        ["Jarvis", 87.066],
        ["Bruno", 87.076],
        ["Issac", 87.086],
        ["Mary", 87.095],
        ["Dudley", 87.104],
        ["Brock", 87.114],
        ["Sanford", 87.123],
        ["Colby", 87.133],
        ["Carmelo", 87.142],
        ["Barney", 87.152],
        ["Nestor", 87.161],
        ["Hollis", 87.17],
        ["Stefan", 87.18],
        ["Donny", 87.189],
        ["Art", 87.198],
        ["Linwood", 87.208],
        ["Beau", 87.217],
        ["Weldon", 87.226],
        ["Galen", 87.235],
        ["Isidro", 87.244],
        ["Truman", 87.253],
        ["Delmar", 87.262],
        ["Johnathon", 87.271],
        ["Silas", 87.28],
        ["Frederic", 87.289],
        ["Dick", 87.298],
        ["Kirby", 87.307],
        ["Irwin", 87.316],
        ["Cruz", 87.325],
        ["Merlin", 87.334],
        ["Merrill", 87.343],
        ["Charley", 87.351],
        ["Marcelino", 87.36],
        ["Lane", 87.369],
        ["Harris", 87.378],
        ["Cleo", 87.386],
        ["Carlo", 87.395],
        ["Trenton", 87.404],
        ["Kurtis", 87.413],
        ["Hunter", 87.421],
        ["Aurelio", 87.43],
        ["Winfred", 87.438],
        ["Vito", 87.447],
        ["Collin", 87.456],
        ["Denver", 87.464],
        ["Carter", 87.473],
        ["Leonel", 87.481],
        ["Emory", 87.49],
        ["Pasquale", 87.498],
        ["Mohammad", 87.506],
        ["Mariano", 87.514],
        ["Danial", 87.523],
        ["Blair", 87.531],
        ["Landon", 87.539],
        ["Dirk", 87.548],
        ["Branden", 87.556],
        ["Adan", 87.564],
        ["Numbers", 87.572],
        ["Clair", 87.581],
        ["Buford", 87.589],
        ["German", 87.597],
        ["Bernie", 87.605],
        ["Wilmer", 87.613],
        ["Joan", 87.621],
        ["Emerson", 87.629],
        ["Zachery", 87.637],
        ["Fletcher", 87.645],
        ["Jacques", 87.653],
        ["Errol", 87.661],
        ["Dalton", 87.669],
        ["Monroe", 87.676],
        ["Josue", 87.684],
        ["Dominique", 87.692],
        ["Edwardo", 87.7],
        ["Booker", 87.708],
        ["Wilford", 87.715],
        ["Sonny", 87.723],
        ["Shelton", 87.731],
        ["Carson", 87.739],
        ["Theron", 87.746],
        ["Raymundo", 87.754],
        ["Daren", 87.762],
        ["Tristan", 87.769],
        ["Houston", 87.777],
        ["Robby", 87.785],
        ["Lincoln", 87.792],
        ["Jame", 87.8],
        ["Genaro", 87.807],
        ["Gale", 87.815],
        ["Bennett", 87.822],
        ["Octavio", 87.83],
        ["Cornell", 87.838],
        ["Laverne", 87.845],
        ["Hung", 87.853],
        ["Arron", 87.86],
        ["Antony", 87.868],
        ["Herschel", 87.875],
        ["Alva", 87.883],
        ["Giovanni", 87.89],
        ["Garth", 87.897],
        ["Cyrus", 87.905],
        ["Cyril", 87.912],
        ["Ronny", 87.92],
        ["Stevie", 87.927],
        ["Lon", 87.934],
        ["Freeman", 87.941],
        ["Erin", 87.949],
        ["Duncan", 87.956],
        ["Kennith", 87.963],
        ["Carmine", 87.97],
        ["Augustine", 87.978],
        ["Young", 87.985],
        ["Erich", 87.992],
        ["Chadwick", 87.999],
        ["Wilburn", 88.006],
        ["Russ", 88.013],
        ["Reid", 88.021],
        ["Myles", 88.028],
        ["Anderson", 88.035],
        ["Morton", 88.042],
        ["Jonas", 88.049],
        ["Forest", 88.056],
        ["Mitchel", 88.063],
        ["Mervin", 88.07],
        ["Zane", 88.077],
        ["Rich", 88.084],
        ["Jamel", 88.091],
        ["Lazaro", 88.098],
        ["Alphonse", 88.105],
        ["Randell", 88.112],
        ["Major", 88.119],
        ["Johnie", 88.126],
        ["Jarrett", 88.133],
        ["Brooks", 88.14],
        ["Ariel", 88.147],
        ["Abdul", 88.154],
        ["Dusty", 88.161],
        ["Luciano", 88.168],
        ["Lindsey", 88.174],
        ["Tracey", 88.181],
        ["Seymour", 88.188],
        ["Scottie", 88.195],
        ["Eugenio", 88.202],
        ["Mohammed", 88.208],
        ["Sandy", 88.215],
        ["Valentin", 88.222],
        ["Chance", 88.228],
        ["Arnulfo", 88.235],
        ["Lucien", 88.242],
        ["Ferdinand", 88.248],
        ["Thad", 88.255],
        ["Ezra", 88.262],
        ["Sydney", 88.268],
        ["Aldo", 88.275],
        ["Rubin", 88.281],
        ["Royal", 88.288],
        ["Mitch", 88.294],
        ["Earle", 88.301],
        ["Abe", 88.307],
        ["Wyatt", 88.314],
        ["Marquis", 88.32],
        ["Lanny", 88.326],
        ["Kareem", 88.333],
        ["Jamar", 88.339],
        ["Boris", 88.346],
        ["Isiah", 88.352],
        ["Emile", 88.358],
        ["Elmo", 88.365],
        ["Aron", 88.371],
        ["Leopoldo", 88.377],
        ["Everette", 88.384],
        ["Josef", 88.39],
        ["Gail", 88.396],
        ["Eloy", 88.403],
        ["Dorian", 88.409],
        ["Rodrick", 88.415],
        ["Reinaldo", 88.421],
        ["Lucio", 88.427],
        ["Jerrod", 88.434],
        ["Weston", 88.44],
        ["Hershel", 88.446],
        ["Barton", 88.452],
        ["Parker", 88.458],
        ["Lemuel", 88.464],
        ["LaVern", 88.47],
        ["Burt", 88.477],
        ["Jules", 88.483],
        ["Gil", 88.489],
        ["Eliseo", 88.495],
        ["Ahmad", 88.501],
        ["Nigel", 88.507],
        ["Efren", 88.513],
        ["Antwan", 88.519],
        ["Alden", 88.525],
        ["Margarito", 88.531],
        ["Coleman", 88.537],
        ["Refugio", 88.543],
        ["Dino", 88.549],
        ["Osvaldo", 88.555],
        ["Les", 88.56],
        ["DeAndre", 88.566],
        ["Normand", 88.572],
        ["Kieth", 88.578],
        ["Ivory", 88.584],
        ["Andrea", 88.59],
        ["Trey", 88.595],
        ["Norberto", 88.601],
        ["Napoleon", 88.607],
        ["Jerold", 88.613],
        ["Fritz", 88.619],
        ["Rosendo", 88.624],
        ["Milford", 88.63],
        ["Sang", 88.636],
        ["Deon", 88.641],
        ["Christoper", 88.647],
        ["Alfonzo", 88.653],
        ["Lyman", 88.658],
        ["Josiah", 88.664],
        ["Brant", 88.67],
        ["Wilton", 88.675],
        ["Rico", 88.681],
        ["Jamaal", 88.687],
        ["Dewitt", 88.692],
        ["Carol", 88.698],
        ["Brenton", 88.704],
        ["Yong", 88.709],
        ["Olin", 88.715],
        ["Foster", 88.72],
        ["Faustino", 88.726],
        ["Claudio", 88.731],
        ["Judson", 88.737],
        ["Gino", 88.743],
        ["Edgardo", 88.748],
        ["Berry", 88.754],
        ["Alec", 88.759],
        ["Tanner", 88.765],
        ["Jarred", 88.77],
        ["Donn", 88.776],
        ["Trinidad", 88.781],
        ["Tad", 88.787],
        ["Shirley", 88.792],
        ["Prince", 88.798],
        ["Porfirio", 88.803],
        ["Odis", 88.809],
        ["Maria", 88.814],
        ["Lenard", 88.82],
        ["Chauncey", 88.825],
        ["Chang", 88.831],
        ["Tod", 88.836],
        ["Mel", 88.842],
        ["Marcelo", 88.847],
        ["Kory", 88.853],
        ["Augustus", 88.858],
        ["Keven", 88.864],
        ["Hilario", 88.869],
        ["Bud", 88.874],
        ["Sal", 88.88],
        ["Rosario", 88.885],
        ["Orval", 88.891],
        ["Mauro", 88.896],
        ["Dannie", 88.901],
        ["Zachariah", 88.907],
        ["Olen", 88.912],
        ["Anibal", 88.917],
        ["Milo", 88.923],
        ["Jed", 88.928],
        ["Frances", 88.933],
        ["Thanh", 88.939],
        ["Dillon", 88.944],
        ["Amado", 88.949],
        ["Newton", 88.955],
        ["Connie", 88.96],
        ["Lenny", 88.965],
        ["Tory", 88.97],
        ["Richie", 88.975],
        ["Lupe", 88.981],
        ["Horacio", 88.986],
        ["Brice", 88.991],
        ["Mohamed", 88.996],
        ["Delmer", 89.001],
        ["Dario", 89.006],
        ["Reyes", 89.012],
        ["Dee", 89.017],
        ["Mac", 89.022],
        ["Jonah", 89.027],
        ["Jerrold", 89.032],
        ["Robt", 89.037],
        ["Hank", 89.042],
        ["Sung", 89.047],
        ["Rupert", 89.052],
        ["Rolland", 89.057],
        ["Kenton", 89.062],
        ["Damion", 89.067],
        ["Chi", 89.072],
        ["Antone", 89.077],
        ["Waldo", 89.082],
        ["Fredric", 89.087],
        ["Bradly", 89.092],
        ["Quinn", 89.097],
        ["Kip", 89.102],
        ["Burl", 89.107],
        ["Walker", 89.112],
        ["Tyree", 89.117],
        ["Jefferey", 89.122],
        ["Ahmed", 89.127],
        ["Willy", 89.132],
        ["Stanford", 89.137],
        ["Oren", 89.142],
        ["Noble", 89.146],
        ["Moshe", 89.151],
        ["Mikel", 89.156],
        ["Enoch", 89.161],
        ["Brendon", 89.166],
        ["Quintin", 89.171],
        ["Jamison", 89.176],
        ["Florencio", 89.181],
        ["Darrick", 89.185],
        ["Tobias", 89.19],
        ["Minh", 89.195],
        ["Hassan", 89.2],
        ["Giuseppe", 89.205],
        ["DeMarcus", 89.21],
        ["Cletus", 89.214],
        ["Tyrell", 89.219],
        ["Lyndon", 89.224],
        ["Keenan", 89.229],
        ["Werner", 89.234],
        ["Theo", 89.238],
        ["Geraldo", 89.243],
        ["Lou", 89.248],
        ["Columbus", 89.253],
        ["Chet", 89.257],
        ["Bertram", 89.262],
        ["Markus", 89.267],
        ["Huey", 89.271],
        ["Hilton", 89.276],
        ["Dwain", 89.281],
        ["Donte", 89.285],
        ["Tyron", 89.29],
        ["Omer", 89.295],
        ["Isaias", 89.299],
        ["Hipolito", 89.304],
        ["Fermin", 89.309],
        ["Chung", 89.313],
        ["Adalberto", 89.318],
        ["Valentine", 89.323],
        ["Jamey", 89.327],
        ["Bo", 89.332],
        ["Barrett", 89.336],
        ["Whitney", 89.341],
        ["Teodoro", 89.345],
        ["McKinley", 89.35],
        ["Maximo", 89.355],
        ["Garfield", 89.359],
        ["Sol", 89.364],
        ["Raleigh", 89.368],
        ["Lawerence", 89.373],
        ["Abram", 89.377],
        ["Rashad", 89.382],
        ["King", 89.386],
        ["Emmitt", 89.391],
        ["Daron", 89.395],
        ["Chong", 89.4],
        ["Samual", 89.404],
        ["Paris", 89.409],
        ["Otha", 89.413],
        ["Miquel", 89.418],
        ["Lacy", 89.422],
        ["Eusebio", 89.426],
        ["Dong", 89.431],
        ["Domenic", 89.435],
        ["Darron", 89.44],
        ["Buster", 89.444],
        ["Antonia", 89.449],
        ["Wilber", 89.453],
        ["Renato", 89.458],
        ["Jc", 89.462],
        ["Hoyt", 89.466],
        ["Haywood", 89.471],
        ["Ezekiel", 89.475],
        ["Chas", 89.48],
        ["Florentino", 89.484],
        ["Elroy", 89.489],
        ["Clemente", 89.493],
        ["Arden", 89.497],
        ["Neville", 89.502],
        ["Kelley", 89.506],
        ["Edison", 89.51],
        ["DeShawn", 89.515],
        ["Carrol", 89.519],
        ["Shayne", 89.523],
        ["Nathanial", 89.528],
        ["Jordon", 89.532],
        ["Danilo", 89.536],
        ["Claud", 89.541],
        ["Val", 89.545],
        ["Sherwood", 89.549],
        ["Raymon", 89.554],
        ["Rayford", 89.558],
        ["Cristobal", 89.562],
        ["Ambrose", 89.567],
        ["Titus", 89.571],
        ["Hyman", 89.575],
        ["Felton", 89.579],
        ["Ezequiel", 89.584],
        ["Erasmo", 89.588],
        ["Stanton", 89.592],
        ["Lonny", 89.596],
        ["Len", 89.601],
        ["Ike", 89.605],
        ["Milan", 89.609],
        ["Lino", 89.613],
        ["Jarod", 89.617],
        ["Herb", 89.622],
        ["Andreas", 89.626],
        ["Walton", 89.63],
        ["Rhett", 89.634],
        ["Palmer", 89.638],
        ["Jude", 89.642],
        ["Douglass", 89.647],
        ["Cordell", 89.651],
        ["Oswaldo", 89.655],
        ["Ellsworth", 89.659],
        ["Virgilio", 89.663],
        ["Toney", 89.667],
        ["Nathanael", 89.671],
        ["Del", 89.675],
        ["Britt", 89.679],
        ["Benedict", 89.684],
        ["Mose", 89.688],
        ["Hong", 89.692],
        ["Leigh", 89.696],
        ["Johnson", 89.7],
        ["Isreal", 89.704],
        ["Gayle", 89.708],
        ["Garret", 89.712],
        ["Fausto", 89.716],
        ["Asa", 89.72],
        ["Arlen", 89.724],
        ["Zack", 89.728],
        ["Warner", 89.732],
        ["Modesto", 89.736],
        ["Francesco", 89.74],
        ["Manual", 89.744],
        ["Jae", 89.748],
        ["Gaylord", 89.752],
        ["Gaston", 89.756],
        ["Filiberto", 89.759],
        ["Deangelo", 89.763],
        ["Michale", 89.767],
        ["Granville", 89.771],
        ["Wes", 89.775],
        ["Malik", 89.779],
        ["Zackary", 89.783],
        ["Tuan", 89.787],
        ["Nicky", 89.79],
        ["Eldridge", 89.794],
        ["Cristopher", 89.798],
        ["Cortez", 89.802],
        ["Antione", 89.806],
        ["Malcom", 89.809],
        ["Long", 89.813],
        ["Korey", 89.817],
        ["Jospeh", 89.821],
        ["Colton", 89.825],
        ["Waylon", 89.828],
        ["Von", 89.832],
        ["Hosea", 89.836],
        ["Shad", 89.84],
        ["Santo", 89.843],
        ["Rudolf", 89.847],
        ["Rolf", 89.851],
        ["Rey", 89.855],
        ["Renaldo", 89.858],
        ["Marcellus", 89.862],
        ["Lucius", 89.866],
        ["Lesley", 89.87],
        ["Kristofer", 89.873],
        ["Boyce", 89.877],
        ["Benton", 89.881],
        ["Man", 89.884],
        ["Kasey", 89.888],
        ["Jewell", 89.892],
        ["Hayden", 89.895],
        ["Harland", 89.899],
        ["Arnoldo", 89.903],
        ["Rueben", 89.907],
        ["Leandro", 89.91],
        ["Kraig", 89.914],
        ["Jerrell", 89.918],
        ["Jeromy", 89.921],
        ["Hobert", 89.925],
        ["Cedrick", 89.929],
        ["Arlie", 89.932],
        ["Winford", 89.936],
        ["Wally", 89.939],
        ["Patricia", 89.943],
        ["Luigi", 89.947],
        ["Keneth", 89.95],
        ["Jacinto", 89.954],
        ["Graig", 89.958],
        ["Franklyn", 89.961],
        ["Edmundo", 89.965],
        ["Sid", 89.968],
        ["Porter", 89.972],
        ["Leif", 89.976],
        ["Lauren", 89.979],
        ["Jeramy", 89.983],
        ["Elisha", 89.986],
        ["Buck", 89.99],
        ["Willian", 89.994],
        ["Vincenzo", 89.997],
        ["Shon", 90.001],
        ["Michal", 90.004],
        ["Lynwood", 90.008],
        ["Lindsay", 90.011],
        ["Jewel", 90.015],
        ["Jere", 90.018],
        ["Hai", 90.022],
        ["Elden", 90.026],
        ["Dorsey", 90.029],
        ["Darell", 90.033],
        ["Broderick", 90.036],
        ["Alonso", 90.04]
    ];

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
        ["Smith", 1.006],
        ["Johnson", 1.816],
        ["Williams", 2.515],
        ["Jones", 3.136],
        ["Brown", 3.757],
        ["Davis", 4.237],
        ["Miller", 4.66],
        ["Wilson", 5],
        ["Moore", 5.312],
        ["Taylor", 5.623],
        ["Anderson", 5.934],
        ["Thomas", 6.245],
        ["Jackson", 6.554],
        ["White", 6.834],
        ["Harris", 7.109],
        ["Martin", 7.382],
        ["Thompson", 7.651],
        ["Garcia", 7.905],
        ["Martinez", 8.14],
        ["Robinson", 8.372],
        ["Clark", 8.603],
        ["Rodriguez", 8.832],
        ["Lewis", 9.058],
        ["Lee", 9.278],
        ["Walker", 9.497],
        ["Hall", 9.698],
        ["Allen", 9.897],
        ["Young", 10.09],
        ["Hernandez", 10.282],
        ["King", 10.472],
        ["Wright", 10.662],
        ["Lopez", 10.849],
        ["Hill", 11.035],
        ["Scott", 11.22],
        ["Green", 11.403],
        ["Adams", 11.577],
        ["Baker", 11.748],
        ["Gonzalez", 11.915],
        ["Nelson", 12.077],
        ["Carter", 12.239],
        ["Mitchell", 12.399],
        ["Perez", 12.554],
        ["Roberts", 12.707],
        ["Turner", 12.859],
        ["Phillips", 13.008],
        ["Campbell", 13.157],
        ["Parker", 13.302],
        ["Evans", 13.443],
        ["Edwards", 13.58],
        ["Collins", 13.714],
        ["Stewart", 13.847],
        ["Sanchez", 13.977],
        ["Morris", 14.102],
        ["Rogers", 14.225],
        ["Reed", 14.347],
        ["Cook", 14.467],
        ["Morgan", 14.585],
        ["Bell", 14.702],
        ["Murphy", 14.819],
        ["Bailey", 14.934],
        ["Rivera", 15.047],
        ["Cooper", 15.16],
        ["Richardson", 15.272],
        ["Cox", 15.382],
        ["Howard", 15.492],
        ["Ward", 15.6],
        ["Torres", 15.708],
        ["Peterson", 15.815],
        ["Gray", 15.921],
        ["Ramirez", 16.026],
        ["James", 16.131],
        ["Watson", 16.233],
        ["Brooks", 16.336],
        ["Kelly", 16.438],
        ["Sanders", 16.537],
        ["Price", 16.637],
        ["Bennett", 16.736],
        ["Wood", 16.834],
        ["Barnes", 16.931],
        ["Ross", 17.027],
        ["Henderson", 17.122],
        ["Coleman", 17.217],
        ["Jenkins", 17.312],
        ["Perry", 17.406],
        ["Powell", 17.499],
        ["Long", 17.591],
        ["Patterson", 17.684],
        ["Hughes", 17.776],
        ["Flores", 17.868],
        ["Washington", 17.959],
        ["Butler", 18.05],
        ["Simmons", 18.141],
        ["Foster", 18.231],
        ["Gonzales", 18.318],
        ["Bryant", 18.405],
        ["Alexander", 18.49],
        ["Russell", 18.574],
        ["Griffin", 18.659],
        ["Diaz", 18.742],
        ["Hayes", 18.825],
        ["Myers", 18.908],
        ["Ford", 18.99],
        ["Hamilton", 19.072],
        ["Graham", 19.154],
        ["Sullivan", 19.234],
        ["Wallace", 19.315],
        ["Woods", 19.395],
        ["Cole", 19.476],
        ["West", 19.555],
        ["Jordan", 19.634],
        ["Owens", 19.711],
        ["Reynolds", 19.789],
        ["Fisher", 19.866],
        ["Ellis", 19.943],
        ["Harrison", 20.019],
        ["Gibson", 20.094],
        ["McDonald", 20.169],
        ["Cruz", 20.244],
        ["Marshall", 20.319],
        ["Ortiz", 20.394],
        ["Gomez", 20.468],
        ["Murray", 20.543],
        ["Freeman", 20.617],
        ["Wells", 20.69],
        ["Webb", 20.762],
        ["Simpson", 20.832],
        ["Stevens", 20.902],
        ["Tucker", 20.971],
        ["Porter", 21.041],
        ["Hunter", 21.11],
        ["Hicks", 21.178],
        ["Crawford", 21.246],
        ["Henry", 21.314],
        ["Boyd", 21.381],
        ["Mason", 21.449],
        ["Morales", 21.516],
        ["Kennedy", 21.582],
        ["Warren", 21.649],
        ["Dixon", 21.715],
        ["Ramos", 21.782],
        ["Reyes", 21.848],
        ["Burns", 21.913],
        ["Gordon", 21.978],
        ["Shaw", 22.043],
        ["Holmes", 22.108],
        ["Rice", 22.172],
        ["Robertson", 22.236],
        ["Hunt", 22.299],
        ["Black", 22.362],
        ["Daniels", 22.425],
        ["Palmer", 22.487],
        ["Mills", 22.547],
        ["Nichols", 22.607],
        ["Grant", 22.667],
        ["Knight", 22.727],
        ["Ferguson", 22.786],
        ["Rose", 22.845],
        ["Stone", 22.904],
        ["Hawkins", 22.963],
        ["Dunn", 23.022],
        ["Perkins", 23.079],
        ["Hudson", 23.137],
        ["Spencer", 23.194],
        ["Gardner", 23.251],
        ["Stephens", 23.308],
        ["Payne", 23.365],
        ["Pierce", 23.421],
        ["Berry", 23.477],
        ["Matthews", 23.533],
        ["Arnold", 23.589],
        ["Wagner", 23.645],
        ["Willis", 23.7],
        ["Ray", 23.755],
        ["Watkins", 23.811],
        ["Olson", 23.866],
        ["Carroll", 23.921],
        ["Duncan", 23.976],
        ["Snyder", 24.031],
        ["Hart", 24.086],
        ["Cunningham", 24.14],
        ["Bradley", 24.194],
        ["Lane", 24.247],
        ["Andrews", 24.301],
        ["Ruiz", 24.355],
        ["Harper", 24.408],
        ["Fox", 24.462],
        ["Riley", 24.515],
        ["Armstrong", 24.568],
        ["Carpenter", 24.621],
        ["Weaver", 24.673],
        ["Greene", 24.726],
        ["Lawrence", 24.779],
        ["Elliott", 24.831],
        ["Chavez", 24.883],
        ["Sims", 24.936],
        ["Austin", 24.988],
        ["Peters", 25.04],
        ["Kelley", 25.092],
        ["Franklin", 25.143],
        ["Lawson", 25.195],
        ["Fields", 25.246],
        ["Gutierrez", 25.297],
        ["Ryan", 25.348],
        ["Schmidt", 25.4],
        ["Carr", 25.451],
        ["Vasquez", 25.502],
        ["Castillo", 25.552],
        ["Wheeler", 25.603],
        ["Chapman", 25.653],
        ["Oliver", 25.703],
        ["Montgomery", 25.753],
        ["Richards", 25.802],
        ["Williamson", 25.851],
        ["Johnston", 25.9],
        ["Banks", 25.948],
        ["Meyer", 25.996],
        ["Bishop", 26.045],
        ["McCoy", 26.093],
        ["Howell", 26.14],
        ["Alvarez", 26.188],
        ["Morrison", 26.235],
        ["Hansen", 26.283],
        ["Fernandez", 26.33],
        ["Garza", 26.377],
        ["Harvey", 26.424],
        ["Little", 26.47],
        ["Burton", 26.516],
        ["Stanley", 26.562],
        ["Nguyen", 26.608],
        ["George", 26.653],
        ["Jacobs", 26.699],
        ["Reid", 26.745],
        ["Kim", 26.79],
        ["Fuller", 26.835],
        ["Lynch", 26.88],
        ["Dean", 26.925],
        ["Gilbert", 26.97],
        ["Garrett", 27.015],
        ["Romero", 27.059],
        ["Welch", 27.104],
        ["Larson", 27.148],
        ["Frazier", 27.192],
        ["Burke", 27.235],
        ["Hanson", 27.279],
        ["Day", 27.322],
        ["Mendoza", 27.365],
        ["Moreno", 27.407],
        ["Bowman", 27.45],
        ["Medina", 27.492],
        ["Fowler", 27.535],
        ["Brewer", 27.577],
        ["Hoffman", 27.619],
        ["Carlson", 27.661],
        ["Silva", 27.703],
        ["Pearson", 27.744],
        ["Holland", 27.786],
        ["Douglas", 27.827],
        ["Fleming", 27.868],
        ["Jensen", 27.909],
        ["Vargas", 27.95],
        ["Byrd", 27.991],
        ["Davidson", 28.032],
        ["Hopkins", 28.072],
        ["May", 28.113],
        ["Terry", 28.153],
        ["Herrera", 28.193],
        ["Wade", 28.233],
        ["Soto", 28.273],
        ["Walters", 28.313],
        ["Curtis", 28.352],
        ["Neal", 28.392],
        ["Caldwell", 28.431],
        ["Lowe", 28.471],
        ["Jennings", 28.51],
        ["Barnett", 28.549],
        ["Graves", 28.588],
        ["Jimenez", 28.628],
        ["Horton", 28.667],
        ["Shelton", 28.706],
        ["Barrett", 28.745],
        ["Obrien", 28.783],
        ["Castro", 28.822],
        ["Sutton", 28.86],
        ["Gregory", 28.899],
        ["McKinney", 28.937],
        ["Lucas", 28.975],
        ["Miles", 29.013],
        ["Craig", 29.051],
        ["Rodriquez", 29.088],
        ["Chambers", 29.126],
        ["Holt", 29.163],
        ["Lambert", 29.2],
        ["Fletcher", 29.237],
        ["Watts", 29.274],
        ["Bates", 29.311],
        ["Hale", 29.348],
        ["Rhodes", 29.385],
        ["Pena", 29.421],
        ["Beck", 29.458],
        ["Newman", 29.494],
        ["Haynes", 29.531],
        ["McDaniel", 29.567],
        ["Mendez", 29.603],
        ["Bush", 29.639],
        ["Vaughn", 29.675],
        ["Parks", 29.711],
        ["Dawson", 29.746],
        ["Santiago", 29.781],
        ["Norris", 29.817],
        ["Hardy", 29.852],
        ["Love", 29.887],
        ["Steele", 29.921],
        ["Curry", 29.956],
        ["Powers", 29.991],
        ["Schultz", 30.025],
        ["Barker", 30.06],
        ["Guzman", 30.094],
        ["Page", 30.129],
        ["Munoz", 30.163],
        ["Ball", 30.197],
        ["Keller", 30.231],
        ["Chandler", 30.265],
        ["Weber", 30.299],
        ["Leonard", 30.333],
        ["Walsh", 30.366],
        ["Lyons", 30.4],
        ["Ramsey", 30.433],
        ["Wolfe", 30.466],
        ["Schneider", 30.499],
        ["Mullins", 30.532],
        ["Benson", 30.565],
        ["Sharp", 30.598],
        ["Bowen", 30.631],
        ["Daniel", 30.663],
        ["Barber", 30.696],
        ["Cummings", 30.728],
        ["Hines", 30.76],
        ["Baldwin", 30.793],
        ["Griffith", 30.824],
        ["Valdez", 30.856],
        ["Hubbard", 30.888],
        ["Salazar", 30.92],
        ["Reeves", 30.951],
        ["Warner", 30.983],
        ["Stevenson", 31.014],
        ["Burgess", 31.045],
        ["Santos", 31.076],
        ["Tate", 31.107],
        ["Cross", 31.138],
        ["Garner", 31.169],
        ["Mann", 31.2],
        ["Mack", 31.231],
        ["Moss", 31.262],
        ["Thornton", 31.292],
        ["Dennis", 31.323],
        ["McGee", 31.354],
        ["Farmer", 31.384],
        ["Delgado", 31.415],
        ["Aguilar", 31.445],
        ["Vega", 31.475],
        ["Glover", 31.505],
        ["Manning", 31.535],
        ["Cohen", 31.566],
        ["Harmon", 31.596],
        ["Rodgers", 31.626],
        ["Robbins", 31.656],
        ["Newton", 31.686],
        ["Todd", 31.716],
        ["Blair", 31.746],
        ["Higgins", 31.776],
        ["Ingram", 31.806],
        ["Reese", 31.836],
        ["Cannon", 31.865],
        ["Strickland", 31.895],
        ["Townsend", 31.925],
        ["Potter", 31.955],
        ["Goodwin", 31.984],
        ["Walton", 32.014],
        ["Rowe", 32.043],
        ["Hampton", 32.073],
        ["Ortega", 32.102],
        ["Patton", 32.131],
        ["Swanson", 32.161],
        ["Joseph", 32.19],
        ["Francis", 32.219],
        ["Goodman", 32.248],
        ["Maldonado", 32.277],
        ["Yates", 32.305],
        ["Becker", 32.334],
        ["Erickson", 32.363],
        ["Hodges", 32.392],
        ["Rios", 32.421],
        ["Conner", 32.449],
        ["Adkins", 32.478],
        ["Webster", 32.506],
        ["Norman", 32.535],
        ["Malone", 32.563],
        ["Hammond", 32.591],
        ["Flowers", 32.62],
        ["Cobb", 32.648],
        ["Moody", 32.676],
        ["Quinn", 32.704],
        ["Blake", 32.732],
        ["Maxwell", 32.759],
        ["Pope", 32.787],
        ["Floyd", 32.814],
        ["Osborne", 32.842],
        ["Paul", 32.869],
        ["McCarthy", 32.896],
        ["Guerrero", 32.924],
        ["Lindsey", 32.951],
        ["Estrada", 32.978],
        ["Sandoval", 33.005],
        ["Gibbs", 33.032],
        ["Tyler", 33.059],
        ["Gross", 33.086],
        ["Fitzgerald", 33.113],
        ["Stokes", 33.14],
        ["Doyle", 33.167],
        ["Sherman", 33.193],
        ["Saunders", 33.22],
        ["Wise", 33.247],
        ["Colon", 33.274],
        ["Gill", 33.3],
        ["Alvarado", 33.327],
        ["Greer", 33.353],
        ["Padilla", 33.38],
        ["Simon", 33.406],
        ["Waters", 33.432],
        ["Nunez", 33.459],
        ["Ballard", 33.485],
        ["Schwartz", 33.511],
        ["McBride", 33.536],
        ["Houston", 33.562],
        ["Christensen", 33.588],
        ["Klein", 33.614],
        ["Pratt", 33.64],
        ["Briggs", 33.666],
        ["Parsons", 33.692],
        ["McLaughlin", 33.717],
        ["Zimmerman", 33.743],
        ["French", 33.769],
        ["Buchanan", 33.794],
        ["Moran", 33.82],
        ["Copeland", 33.845],
        ["Roy", 33.871],
        ["Pittman", 33.896],
        ["Brady", 33.922],
        ["McCormick", 33.947],
        ["Holloway", 33.972],
        ["Brock", 33.998],
        ["Poole", 34.023],
        ["Frank", 34.048],
        ["Logan", 34.073],
        ["Owen", 34.098],
        ["Bass", 34.122],
        ["Marsh", 34.147],
        ["Drake", 34.172],
        ["Wong", 34.197],
        ["Jefferson", 34.221],
        ["Park", 34.246],
        ["Morton", 34.271],
        ["Abbott", 34.295],
        ["Sparks", 34.32],
        ["Patrick", 34.344],
        ["Norton", 34.369],
        ["Huff", 34.393],
        ["Clayton", 34.417],
        ["Massey", 34.442],
        ["Lloyd", 34.466],
        ["Figueroa", 34.49],
        ["Carson", 34.514],
        ["Bowers", 34.538],
        ["Roberson", 34.563],
        ["Barton", 34.587],
        ["Tran", 34.611],
        ["Lamb", 34.635],
        ["Harrington", 34.659],
        ["Casey", 34.683],
        ["Boone", 34.706],
        ["Cortez", 34.73],
        ["Clarke", 34.754],
        ["Mathis", 34.778],
        ["Singleton", 34.801],
        ["Wilkins", 34.825],
        ["Cain", 34.849],
        ["Bryan", 34.872],
        ["Underwood", 34.896],
        ["Hogan", 34.92],
        ["McKenzie", 34.943],
        ["Collier", 34.966],
        ["Luna", 34.99],
        ["Phelps", 35.013],
        ["McGuire", 35.036],
        ["Allison", 35.059],
        ["Bridges", 35.083],
        ["Wilkerson", 35.106],
        ["Nash", 35.129],
        ["Summers", 35.152],
        ["Atkins", 35.175],
        ["Wilcox", 35.198],
        ["Pitts", 35.221],
        ["Conley", 35.244],
        ["Marquez", 35.267],
        ["Burnett", 35.29],
        ["Richard", 35.313],
        ["Cochran", 35.335],
        ["Chase", 35.358],
        ["Davenport", 35.381],
        ["Hood", 35.404],
        ["Gates", 35.427],
        ["Clay", 35.449],
        ["Ayala", 35.472],
        ["Sawyer", 35.495],
        ["Roman", 35.517],
        ["Vazquez", 35.54],
        ["Dickerson", 35.562],
        ["Hodge", 35.585],
        ["Acosta", 35.607],
        ["Flynn", 35.63],
        ["Espinoza", 35.652],
        ["Nicholson", 35.675],
        ["Monroe", 35.697],
        ["Wolf", 35.719],
        ["Morrow", 35.741],
        ["Kirk", 35.763],
        ["Randall", 35.785],
        ["Anthony", 35.807],
        ["Whitaker", 35.829],
        ["Oconnor", 35.851],
        ["Skinner", 35.873],
        ["Ware", 35.895],
        ["Molina", 35.916],
        ["Kirby", 35.938],
        ["Huffman", 35.96],
        ["Bradford", 35.982],
        ["Charles", 36.003],
        ["Gilmore", 36.025],
        ["Dominguez", 36.046],
        ["Oneal", 36.068],
        ["Bruce", 36.09],
        ["Lang", 36.111],
        ["Combs", 36.132],
        ["Kramer", 36.154],
        ["Heath", 36.175],
        ["Hancock", 36.196],
        ["Gallagher", 36.218],
        ["Gaines", 36.239],
        ["Shaffer", 36.26],
        ["Short", 36.281],
        ["Wiggins", 36.302],
        ["Mathews", 36.323],
        ["McClain", 36.344],
        ["Fischer", 36.365],
        ["Wall", 36.386],
        ["Small", 36.407],
        ["Melton", 36.428],
        ["Hensley", 36.449],
        ["Bond", 36.47],
        ["Dyer", 36.491],
        ["Cameron", 36.511],
        ["Grimes", 36.532],
        ["Contreras", 36.553],
        ["Christian", 36.573],
        ["Wyatt", 36.594],
        ["Baxter", 36.615],
        ["Snow", 36.635],
        ["Mosley", 36.656],
        ["Shepherd", 36.677],
        ["Larsen", 36.697],
        ["Hoover", 36.718],
        ["Beasley", 36.738],
        ["Glenn", 36.758],
        ["Petersen", 36.779],
        ["Whitehead", 36.799],
        ["Meyers", 36.819],
        ["Keith", 36.84],
        ["Garrison", 36.86],
        ["Vincent", 36.88],
        ["Shields", 36.9],
        ["Horn", 36.92],
        ["Savage", 36.941],
        ["Olsen", 36.961],
        ["Schroeder", 36.981],
        ["Hartman", 37.001],
        ["Woodard", 37.021],
        ["Mueller", 37.041],
        ["Kemp", 37.061],
        ["Deleon", 37.081],
        ["Booth", 37.101],
        ["Patel", 37.121],
        ["Calhoun", 37.14],
        ["Wiley", 37.16],
        ["Eaton", 37.18],
        ["Cline", 37.2],
        ["Navarro", 37.22],
        ["Harrell", 37.24],
        ["Lester", 37.259],
        ["Humphrey", 37.279],
        ["Parrish", 37.299],
        ["Duran", 37.318],
        ["Hutchinson", 37.338],
        ["Hess", 37.358],
        ["Dorsey", 37.377],
        ["Bullock", 37.397],
        ["Robles", 37.416],
        ["Beard", 37.436],
        ["Dalton", 37.455],
        ["Avila", 37.475],
        ["Vance", 37.494],
        ["Rich", 37.514],
        ["Blackwell", 37.533],
        ["York", 37.552],
        ["Johns", 37.572],
        ["Blankenship", 37.591],
        ["Trevino", 37.61],
        ["Salinas", 37.63],
        ["Campos", 37.649],
        ["Pruitt", 37.668],
        ["Moses", 37.688],
        ["Callahan", 37.707],
        ["Golden", 37.726],
        ["Montoya", 37.745],
        ["Hardin", 37.764],
        ["Guerra", 37.783],
        ["McDowell", 37.802],
        ["Carey", 37.821],
        ["Stafford", 37.84],
        ["Gallegos", 37.859],
        ["Henson", 37.878],
        ["Wilkinson", 37.897],
        ["Booker", 37.916],
        ["Merritt", 37.935],
        ["Miranda", 37.953],
        ["Atkinson", 37.972],
        ["Orr", 37.991],
        ["Decker", 38.01],
        ["Hobbs", 38.028],
        ["Preston", 38.047],
        ["Tanner", 38.066],
        ["Knox", 38.084],
        ["Pacheco", 38.103],
        ["Stephenson", 38.121],
        ["Glass", 38.14],
        ["Rojas", 38.158],
        ["Serrano", 38.176],
        ["Marks", 38.195],
        ["Hickman", 38.213],
        ["English", 38.231],
        ["Sweeney", 38.249],
        ["Strong", 38.267],
        ["Prince", 38.285],
        ["McClure", 38.304],
        ["Conway", 38.322],
        ["Walter", 38.34],
        ["Roth", 38.358],
        ["Maynard", 38.376],
        ["Farrell", 38.394],
        ["Lowery", 38.411],
        ["Hurst", 38.429],
        ["Nixon", 38.447],
        ["Weiss", 38.465],
        ["Trujillo", 38.483],
        ["Ellison", 38.5],
        ["Sloan", 38.518],
        ["Juarez", 38.536],
        ["Winters", 38.553],
        ["McLean", 38.571],
        ["Randolph", 38.588],
        ["Leon", 38.606],
        ["Boyer", 38.624],
        ["Villarreal", 38.641],
        ["McCall", 38.659],
        ["Gentry", 38.676],
        ["Carrillo", 38.694],
        ["Kent", 38.711],
        ["Ayers", 38.729],
        ["Lara", 38.746],
        ["Shannon", 38.763],
        ["Sexton", 38.781],
        ["Pace", 38.798],
        ["Hull", 38.815],
        ["Leblanc", 38.833],
        ["Browning", 38.85],
        ["Velasquez", 38.867],
        ["Leach", 38.884],
        ["Chang", 38.901],
        ["House", 38.918],
        ["Sellers", 38.936],
        ["Herring", 38.953],
        ["Noble", 38.97],
        ["Foley", 38.987],
        ["Bartlett", 39.004],
        ["Mercado", 39.021],
        ["Landry", 39.037],
        ["Durham", 39.054],
        ["Walls", 39.071],
        ["Barr", 39.088],
        ["McKee", 39.105],
        ["Bauer", 39.122],
        ["Rivers", 39.139],
        ["Everett", 39.155],
        ["Bradshaw", 39.172],
        ["Pugh", 39.189],
        ["Velez", 39.206],
        ["Rush", 39.222],
        ["Estes", 39.239],
        ["Dodson", 39.255],
        ["Morse", 39.272],
        ["Sheppard", 39.289],
        ["Weeks", 39.305],
        ["Camacho", 39.322],
        ["Bean", 39.338],
        ["Barron", 39.355],
        ["Livingston", 39.371],
        ["Middleton", 39.388],
        ["Spears", 39.404],
        ["Branch", 39.42],
        ["Blevins", 39.437],
        ["Chen", 39.453],
        ["Kerr", 39.469],
        ["McConnell", 39.486],
        ["Hatfield", 39.502],
        ["Harding", 39.518],
        ["Ashley", 39.535],
        ["Solis", 39.551],
        ["Herman", 39.567],
        ["Frost", 39.583],
        ["Giles", 39.599],
        ["Blackburn", 39.616],
        ["William", 39.632],
        ["Pennington", 39.648],
        ["Woodward", 39.664],
        ["Finley", 39.68],
        ["McIntosh", 39.696],
        ["Koch", 39.713],
        ["Best", 39.729],
        ["Solomon", 39.745],
        ["McCullough", 39.761],
        ["Dudley", 39.777],
        ["Nolan", 39.793],
        ["Blanchard", 39.809],
        ["Rivas", 39.825],
        ["Brennan", 39.841],
        ["Mejia", 39.856],
        ["Kane", 39.872],
        ["Benton", 39.888],
        ["Joyce", 39.904],
        ["Buckley", 39.92],
        ["Haley", 39.936],
        ["Valentine", 39.952],
        ["Maddox", 39.967],
        ["Russo", 39.983],
        ["McKnight", 39.999],
        ["Buck", 40.015],
        ["Moon", 40.03],
        ["McMillan", 40.046],
        ["Crosby", 40.062],
        ["Berg", 40.077],
        ["Dotson", 40.093],
        ["Mays", 40.109],
        ["Roach", 40.124],
        ["Church", 40.14],
        ["Chan", 40.155],
        ["Richmond", 40.171],
        ["Meadows", 40.187],
        ["Faulkner", 40.202],
        ["Oneill", 40.218],
        ["Knapp", 40.233],
        ["Kline", 40.248],
        ["Barry", 40.264],
        ["Ochoa", 40.279],
        ["Jacobson", 40.295],
        ["Gay", 40.31],
        ["Avery", 40.326],
        ["Hendricks", 40.341],
        ["Horne", 40.356],
        ["Shepard", 40.372],
        ["Hebert", 40.387],
        ["Cherry", 40.402],
        ["Cardenas", 40.418],
        ["McIntyre", 40.433],
        ["Whitney", 40.448],
        ["Waller", 40.463],
        ["Holman", 40.478],
        ["Donaldson", 40.494],
        ["Cantu", 40.509],
        ["Terrell", 40.524],
        ["Morin", 40.539],
        ["Gillespie", 40.555],
        ["Fuentes", 40.57],
        ["Tillman", 40.585],
        ["Sanford", 40.6],
        ["Bentley", 40.615],
        ["Peck", 40.63],
        ["Key", 40.645],
        ["Salas", 40.66],
        ["Rollins", 40.676],
        ["Gamble", 40.691],
        ["Dickson", 40.706],
        ["Battle", 40.721],
        ["Santana", 40.736],
        ["Cabrera", 40.751],
        ["Cervantes", 40.766],
        ["Howe", 40.781],
        ["Hinton", 40.796],
        ["Hurley", 40.811],
        ["Spence", 40.825],
        ["Zamora", 40.84],
        ["Yang", 40.855],
        ["McNeil", 40.87],
        ["Suarez", 40.885],
        ["Case", 40.9],
        ["Petty", 40.915],
        ["Gould", 40.929],
        ["McFarland", 40.944],
        ["Sampson", 40.959],
        ["Carver", 40.974],
        ["Bray", 40.989],
        ["Rosario", 41.003],
        ["Macdonald", 41.018],
        ["Stout", 41.033],
        ["Hester", 41.047],
        ["Melendez", 41.062],
        ["Dillon", 41.077],
        ["Farley", 41.091],
        ["Hopper", 41.106],
        ["Galloway", 41.121],
        ["Potts", 41.135],
        ["Bernard", 41.15],
        ["Joyner", 41.164],
        ["Stein", 41.179],
        ["Aguirre", 41.193],
        ["Osborn", 41.208],
        ["Mercer", 41.222],
        ["Bender", 41.236],
        ["Franco", 41.251],
        ["Rowland", 41.265],
        ["Sykes", 41.28],
        ["Benjamin", 41.294],
        ["Travis", 41.308],
        ["Pickett", 41.323],
        ["Crane", 41.337],
        ["Sears", 41.351],
        ["Mayo", 41.365],
        ["Dunlap", 41.379],
        ["Hayden", 41.394],
        ["Wilder", 41.408],
        ["McKay", 41.422],
        ["Coffey", 41.436],
        ["McCarty", 41.45],
        ["Ewing", 41.464],
        ["Cooley", 41.478],
        ["Vaughan", 41.492],
        ["Bonner", 41.506],
        ["Cotton", 41.52],
        ["Holder", 41.534],
        ["Stark", 41.548],
        ["Ferrell", 41.562],
        ["Cantrell", 41.575],
        ["Fulton", 41.589],
        ["Lynn", 41.603],
        ["Lott", 41.617],
        ["Calderon", 41.631],
        ["Rosa", 41.644],
        ["Pollard", 41.658],
        ["Hooper", 41.672],
        ["Burch", 41.686],
        ["Mullen", 41.7],
        ["Fry", 41.713],
        ["Riddle", 41.727],
        ["Levy", 41.741],
        ["David", 41.754],
        ["Duke", 41.768],
        ["Odonnell", 41.782],
        ["Guy", 41.795],
        ["Michael", 41.809],
        ["Britt", 41.823],
        ["Frederick", 41.836],
        ["Daugherty", 41.85],
        ["Berger", 41.864],
        ["Dillard", 41.877],
        ["Alston", 41.891],
        ["Jarvis", 41.904],
        ["Frye", 41.918],
        ["Riggs", 41.932],
        ["Chaney", 41.945],
        ["Odom", 41.959],
        ["Duffy", 41.972],
        ["Fitzpatrick", 41.986],
        ["Valenzuela", 41.999],
        ["Merrill", 42.012],
        ["Mayer", 42.026],
        ["Alford", 42.039],
        ["McPherson", 42.053],
        ["Acevedo", 42.066],
        ["Donovan", 42.079],
        ["Barrera", 42.093],
        ["Albert", 42.106],
        ["Cote", 42.119],
        ["Reilly", 42.133],
        ["Compton", 42.146],
        ["Raymond", 42.159],
        ["Mooney", 42.173],
        ["McGowan", 42.186],
        ["Craft", 42.199],
        ["Cleveland", 42.212],
        ["Clemons", 42.226],
        ["Wynn", 42.239],
        ["Nielsen", 42.252],
        ["Baird", 42.265],
        ["Stanton", 42.278],
        ["Snider", 42.292],
        ["Rosales", 42.305],
        ["Bright", 42.318],
        ["Witt", 42.331],
        ["Stuart", 42.344],
        ["Hays", 42.357],
        ["Holden", 42.371],
        ["Rutledge", 42.384],
        ["Kinney", 42.397],
        ["Clements", 42.41],
        ["Castaneda", 42.423],
        ["Slater", 42.436],
        ["Hahn", 42.449],
        ["Emerson", 42.462],
        ["Conrad", 42.475],
        ["Burks", 42.488],
        ["Delaney", 42.501],
        ["Pate", 42.514],
        ["Lancaster", 42.527],
        ["Sweet", 42.54],
        ["Justice", 42.552],
        ["Tyson", 42.565],
        ["Sharpe", 42.578],
        ["Whitfield", 42.591],
        ["Talley", 42.604],
        ["Macias", 42.617],
        ["Irwin", 42.629],
        ["Burris", 42.642],
        ["Ratliff", 42.655],
        ["McCray", 42.668],
        ["Madden", 42.681],
        ["Kaufman", 42.693],
        ["Beach", 42.706],
        ["Goff", 42.719],
        ["Cash", 42.732],
        ["Bolton", 42.744],
        ["McFadden", 42.757],
        ["Levine", 42.77],
        ["Good", 42.782],
        ["Byers", 42.795],
        ["Kirkland", 42.808],
        ["Kidd", 42.821],
        ["Workman", 42.833],
        ["Carney", 42.846],
        ["Dale", 42.859],
        ["McLeod", 42.871],
        ["Holcomb", 42.884],
        ["England", 42.896],
        ["Finch", 42.909],
        ["Head", 42.921],
        ["Burt", 42.934],
        ["Hendrix", 42.946],
        ["Sosa", 42.959],
        ["Haney", 42.971],
        ["Franks", 42.984],
        ["Sargent", 42.996],
        ["Nieves", 43.009],
        ["Downs", 43.021],
        ["Rasmussen", 43.033],
        ["Bird", 43.046],
        ["Hewitt", 43.058],
        ["Lindsay", 43.071],
        ["Le", 43.083],
        ["Foreman", 43.095],
        ["Valencia", 43.108],
        ["Oneil", 43.12],
        ["Delacruz", 43.132],
        ["Vinson", 43.145],
        ["Dejesus", 43.157],
        ["Hyde", 43.169],
        ["Forbes", 43.182],
        ["Gilliam", 43.194],
        ["Guthrie", 43.206],
        ["Wooten", 43.218],
        ["Huber", 43.231],
        ["Barlow", 43.243],
        ["Boyle", 43.255],
        ["McMahon", 43.267],
        ["Buckner", 43.279],
        ["Rocha", 43.291],
        ["Puckett", 43.303],
        ["Langley", 43.315],
        ["Knowles", 43.328],
        ["Cooke", 43.34],
        ["Velazquez", 43.352],
        ["Whitley", 43.364],
        ["Noel", 43.376],
        ["Vang", 43.388],
        ["Shea", 43.399],
        ["Rouse", 43.411],
        ["Hartley", 43.423],
        ["Mayfield", 43.435],
        ["Elder", 43.447],
        ["Rankin", 43.459],
        ["Hanna", 43.471],
        ["Cowan", 43.483],
        ["Lucero", 43.495],
        ["Arroyo", 43.507],
        ["Slaughter", 43.519],
        ["Haas", 43.531],
        ["Oconnell", 43.542],
        ["Minor", 43.554],
        ["Kendrick", 43.566],
        ["Shirley", 43.578],
        ["Kendall", 43.59],
        ["Boucher", 43.602],
        ["Archer", 43.613],
        ["Boggs", 43.625],
        ["Odell", 43.637],
        ["Dougherty", 43.649],
        ["Andersen", 43.661],
        ["Newell", 43.672],
        ["Crowe", 43.684],
        ["Wang", 43.696],
        ["Friedman", 43.708],
        ["Bland", 43.719],
        ["Swain", 43.731],
        ["Holley", 43.743],
        ["Felix", 43.754],
        ["Pearce", 43.766],
        ["Childs", 43.778],
        ["Yarbrough", 43.789],
        ["Galvan", 43.801],
        ["Proctor", 43.812],
        ["Meeks", 43.824],
        ["Lozano", 43.836],
        ["Mora", 43.847],
        ["Rangel", 43.859],
        ["Bacon", 43.87],
        ["Villanueva", 43.882],
        ["Schaefer", 43.893],
        ["Rosado", 43.905],
        ["Helms", 43.917],
        ["Boyce", 43.928],
        ["Goss", 43.94],
        ["Stinson", 43.951],
        ["Smart", 43.963],
        ["Lake", 43.974],
        ["Ibarra", 43.986],
        ["Hutchins", 43.997],
        ["Covington", 44.009],
        ["Reyna", 44.02],
        ["Gregg", 44.032],
        ["Werner", 44.043],
        ["Crowley", 44.054],
        ["Hatcher", 44.066],
        ["Mackey", 44.077],
        ["Bunch", 44.089],
        ["Womack", 44.1],
        ["Polk", 44.111],
        ["Jamison", 44.123],
        ["Dodd", 44.134],
        ["Childress", 44.146],
        ["Childers", 44.157],
        ["Camp", 44.168],
        ["Villa", 44.18],
        ["Dye", 44.191],
        ["Springer", 44.202],
        ["Mahoney", 44.214],
        ["Dailey", 44.225],
        ["Belcher", 44.236],
        ["Lockhart", 44.248],
        ["Griggs", 44.259],
        ["Costa", 44.27],
        ["Connor", 44.281],
        ["Brandt", 44.293],
        ["Winter", 44.304],
        ["Walden", 44.315],
        ["Moser", 44.326],
        ["Tracy", 44.338],
        ["Tatum", 44.349],
        ["McCann", 44.36],
        ["Akers", 44.371],
        ["Lutz", 44.382],
        ["Pryor", 44.393],
        ["Law", 44.404],
        ["Orozco", 44.415],
        ["McAllister", 44.427],
        ["Lugo", 44.438],
        ["Davies", 44.449],
        ["Shoemaker", 44.46],
        ["Madison", 44.471],
        ["Rutherford", 44.482],
        ["Newsome", 44.493],
        ["Magee", 44.504],
        ["Chamberlain", 44.515],
        ["Blanton", 44.526],
        ["Simms", 44.537],
        ["Godfrey", 44.548],
        ["Flanagan", 44.559],
        ["Crum", 44.57],
        ["Cordova", 44.581],
        ["Escobar", 44.592],
        ["Downing", 44.603],
        ["Sinclair", 44.614],
        ["Donahue", 44.625],
        ["Krueger", 44.636],
        ["McGinnis", 44.647],
        ["Gore", 44.658],
        ["Farris", 44.669],
        ["Webber", 44.68],
        ["Corbett", 44.691],
        ["Andrade", 44.701],
        ["Starr", 44.712],
        ["Lyon", 44.723],
        ["Yoder", 44.734],
        ["Hastings", 44.745],
        ["McGrath", 44.756],
        ["Spivey", 44.767],
        ["Krause", 44.778],
        ["Harden", 44.788],
        ["Crabtree", 44.799],
        ["Kirkpatrick", 44.81],
        ["Hollis", 44.821],
        ["Brandon", 44.832],
        ["Arrington", 44.843],
        ["Ervin", 44.853],
        ["Clifton", 44.864],
        ["Ritter", 44.875],
        ["McGhee", 44.886],
        ["Bolden", 44.897],
        ["Maloney", 44.907],
        ["Gagnon", 44.918],
        ["Dunbar", 44.929],
        ["Ponce", 44.94],
        ["Pike", 44.95],
        ["Mayes", 44.961],
        ["Heard", 44.972],
        ["Beatty", 44.983],
        ["Mobley", 44.993],
        ["Kimball", 45.004],
        ["Butts", 45.015],
        ["Montes", 45.026],
        ["Herbert", 45.036],
        ["Grady", 45.047],
        ["Eldridge", 45.058],
        ["Braun", 45.068],
        ["Hamm", 45.079],
        ["Gibbons", 45.09],
        ["Seymour", 45.1],
        ["Moyer", 45.111],
        ["Manley", 45.122],
        ["Herron", 45.132],
        ["Plummer", 45.143],
        ["Elmore", 45.153],
        ["Cramer", 45.164],
        ["Gary", 45.175],
        ["Rucker", 45.185],
        ["Hilton", 45.196],
        ["Blue", 45.206],
        ["Pierson", 45.217],
        ["Fontenot", 45.227],
        ["Field", 45.238],
        ["Rubio", 45.248],
        ["Grace", 45.259],
        ["Goldstein", 45.269],
        ["Elkins", 45.28],
        ["Wills", 45.29],
        ["Novak", 45.301],
        ["John", 45.311],
        ["Hickey", 45.322],
        ["Worley", 45.332],
        ["Gorman", 45.343],
        ["Katz", 45.353],
        ["Dickinson", 45.364],
        ["Broussard", 45.374],
        ["Fritz", 45.384],
        ["Woodruff", 45.395],
        ["Crow", 45.405],
        ["Christopher", 45.416],
        ["Britton", 45.426],
        ["Forrest", 45.436],
        ["Nance", 45.447],
        ["Lehman", 45.457],
        ["Bingham", 45.467],
        ["Zuniga", 45.478],
        ["Whaley", 45.488],
        ["Shafer", 45.498],
        ["Coffman", 45.509],
        ["Steward", 45.519],
        ["Delarosa", 45.529],
        ["Nix", 45.54],
        ["Neely", 45.55],
        ["Numbers", 45.56],
        ["Mata", 45.571],
        ["Manuel", 45.581],
        ["Davila", 45.591],
        ["McCabe", 45.601],
        ["Kessler", 45.612],
        ["Emery", 45.622],
        ["Bowling", 45.632],
        ["Hinkle", 45.643],
        ["Welsh", 45.653],
        ["Pagan", 45.663],
        ["Goldberg", 45.673],
        ["Goins", 45.683],
        ["Crouch", 45.694],
        ["Cuevas", 45.704],
        ["Quinones", 45.714],
        ["McDermott", 45.724],
        ["Hendrickson", 45.734],
        ["Samuels", 45.745],
        ["Denton", 45.755],
        ["Bergeron", 45.765],
        ["Lam", 45.775],
        ["Ivey", 45.785],
        ["Locke", 45.795],
        ["Haines", 45.805],
        ["Thurman", 45.815],
        ["Snell", 45.825],
        ["Hoskins", 45.836],
        ["Byrne", 45.846],
        ["Milton", 45.856],
        ["Winston", 45.866],
        ["Arthur", 45.876],
        ["Arias", 45.886],
        ["Stanford", 45.896],
        ["Roe", 45.906],
        ["Corbin", 45.916],
        ["Beltran", 45.926],
        ["Chappell", 45.936],
        ["Hurt", 45.946],
        ["Downey", 45.956],
        ["Dooley", 45.966],
        ["Tuttle", 45.976],
        ["Couch", 45.986],
        ["Payton", 45.996],
        ["McElroy", 46.006],
        ["Crockett", 46.016],
        ["Groves", 46.026],
        ["Clement", 46.036],
        ["Leslie", 46.046],
        ["Cartwright", 46.055],
        ["Dickey", 46.065],
        ["McGill", 46.075],
        ["Dubois", 46.085],
        ["Muniz", 46.095],
        ["Erwin", 46.105],
        ["Self", 46.115],
        ["Tolbert", 46.125],
        ["Dempsey", 46.134],
        ["Cisneros", 46.144],
        ["Sewell", 46.154],
        ["Latham", 46.164],
        ["Garland", 46.174],
        ["Vigil", 46.183],
        ["Tapia", 46.193],
        ["Sterling", 46.203],
        ["Rainey", 46.213],
        ["Norwood", 46.223],
        ["Lacy", 46.232],
        ["Stroud", 46.242],
        ["Meade", 46.252],
        ["Amos", 46.262],
        ["Tipton", 46.271],
        ["Lord", 46.281],
        ["Kuhn", 46.291],
        ["Hilliard", 46.301],
        ["Bonilla", 46.311],
        ["Teague", 46.32],
        ["Courtney", 46.33],
        ["Gunn", 46.34],
        ["Ho", 46.349],
        ["Greenwood", 46.359],
        ["Correa", 46.369],
        ["Reece", 46.378],
        ["Weston", 46.388],
        ["Poe", 46.398],
        ["Trent", 46.407],
        ["Pineda", 46.417],
        ["Phipps", 46.427],
        ["Frey", 46.436],
        ["Kaiser", 46.446],
        ["Ames", 46.456],
        ["Paige", 46.465],
        ["Gunter", 46.475],
        ["Schmitt", 46.484],
        ["Milligan", 46.494],
        ["Espinosa", 46.503],
        ["Carlton", 46.513],
        ["Bowden", 46.523],
        ["Vickers", 46.532],
        ["Lowry", 46.542],
        ["Pritchard", 46.551],
        ["Costello", 46.561],
        ["Piper", 46.57],
        ["McClellan", 46.58],
        ["Lovell", 46.589],
        ["Drew", 46.599],
        ["Sheehan", 46.608],
        ["Quick", 46.617],
        ["Hatch", 46.627],
        ["Dobson", 46.636],
        ["Singh", 46.646],
        ["Jeffries", 46.655],
        ["Hollingsworth", 46.665],
        ["Sorensen", 46.674],
        ["Meza", 46.684],
        ["Fink", 46.693],
        ["Donnelly", 46.702],
        ["Burrell", 46.712],
        ["Bruno", 46.721],
        ["Tomlinson", 46.731],
        ["Colbert", 46.74],
        ["Billings", 46.749],
        ["Ritchie", 46.759],
        ["Helton", 46.768],
        ["Sutherland", 46.777],
        ["Peoples", 46.787],
        ["McQueen", 46.796],
        ["Gaston", 46.806],
        ["Thomason", 46.815],
        ["McKinley", 46.824],
        ["Givens", 46.834],
        ["Crocker", 46.843],
        ["Vogel", 46.852],
        ["Robison", 46.862],
        ["Dunham", 46.871],
        ["Coker", 46.88],
        ["Swartz", 46.89],
        ["Keys", 46.899],
        ["Lilly", 46.908],
        ["Ladner", 46.917],
        ["Hannah", 46.927],
        ["Willard", 46.936],
        ["Richter", 46.945],
        ["Hargrove", 46.955],
        ["Edmonds", 46.964],
        ["Brantley", 46.973],
        ["Albright", 46.982],
        ["Murdock", 46.992],
        ["Boswell", 47.001],
        ["Muller", 47.01],
        ["Quintero", 47.019],
        ["Padgett", 47.028],
        ["Kenney", 47.038],
        ["Daly", 47.047],
        ["Connolly", 47.056],
        ["Pierre", 47.065],
        ["Inman", 47.074],
        ["Quintana", 47.084],
        ["Lund", 47.093],
        ["Barnard", 47.102],
        ["Villegas", 47.111],
        ["Simons", 47.12],
        ["Land", 47.129],
        ["Huggins", 47.138],
        ["Tidwell", 47.148],
        ["Sanderson", 47.157],
        ["Bullard", 47.166],
        ["McClendon", 47.175],
        ["Duarte", 47.184],
        ["Draper", 47.193],
        ["Meredith", 47.202],
        ["Marrero", 47.211],
        ["Dwyer", 47.22],
        ["Abrams", 47.23],
        ["Stover", 47.239],
        ["Goode", 47.248],
        ["Fraser", 47.257],
        ["Crews", 47.266],
        ["Bernal", 47.275],
        ["Smiley", 47.284],
        ["Godwin", 47.293],
        ["Fish", 47.302],
        ["Conklin", 47.311],
        ["McNeal", 47.32],
        ["Baca", 47.329],
        ["Esparza", 47.338],
        ["Crowder", 47.347],
        ["Bower", 47.356],
        ["Nicholas", 47.365],
        ["Chung", 47.374],
        ["Brewster", 47.383],
        ["McNeill", 47.392],
        ["Dick", 47.401],
        ["Rodrigues", 47.409],
        ["Leal", 47.418],
        ["Coates", 47.427],
        ["Raines", 47.436],
        ["McCain", 47.445],
        ["McCord", 47.454],
        ["Miner", 47.463],
        ["Holbrook", 47.472],
        ["Swift", 47.481],
        ["Dukes", 47.489],
        ["Carlisle", 47.498],
        ["Aldridge", 47.507],
        ["Ackerman", 47.516],
        ["Starks", 47.525],
        ["Ricks", 47.533],
        ["Holliday", 47.542],
        ["Ferris", 47.551],
        ["Hairston", 47.56],
        ["Sheffield", 47.569],
        ["Lange", 47.577],
        ["Fountain", 47.586],
        ["Marino", 47.595],
        ["Doss", 47.604],
        ["Betts", 47.612],
        ["Kaplan", 47.621],
        ["Carmichael", 47.63],
        ["Bloom", 47.639],
        ["Ruffin", 47.647],
        ["Penn", 47.656],
        ["Kern", 47.665],
        ["Bowles", 47.673],
        ["Sizemore", 47.682],
        ["Larkin", 47.691],
        ["Dupree", 47.699],
        ["Jewell", 47.708],
        ["Silver", 47.717],
        ["Seals", 47.726],
        ["Metcalf", 47.734],
        ["Hutchison", 47.743],
        ["Henley", 47.751],
        ["Farr", 47.76],
        ["Castle", 47.769],
        ["McCauley", 47.777],
        ["Hankins", 47.786],
        ["Gustafson", 47.795],
        ["Deal", 47.803],
        ["Curran", 47.812],
        ["Ash", 47.821],
        ["Waddell", 47.829],
        ["Ramey", 47.838],
        ["Cates", 47.847],
        ["Pollock", 47.855],
        ["Major", 47.864],
        ["Irvin", 47.872],
        ["Cummins", 47.881],
        ["Messer", 47.89],
        ["Heller", 47.898],
        ["Dewitt", 47.907],
        ["Lin", 47.915],
        ["Funk", 47.924],
        ["Cornett", 47.932],
        ["Palacios", 47.941],
        ["Galindo", 47.949],
        ["Cano", 47.958],
        ["Hathaway", 47.966],
        ["Singer", 47.975],
        ["Pham", 47.983],
        ["Enriquez", 47.992],
        ["Aaron", 48],
        ["Salgado", 48.009],
        ["Pelletier", 48.017],
        ["Painter", 48.026],
        ["Wiseman", 48.034],
        ["Blount", 48.043],
        ["Hand", 48.051],
        ["Feliciano", 48.059],
        ["Temple", 48.068],
        ["Houser", 48.076],
        ["Doherty", 48.085],
        ["Mead", 48.093],
        ["McGraw", 48.101],
        ["Toney", 48.11],
        ["Swan", 48.118],
        ["Melvin", 48.126],
        ["Capps", 48.135],
        ["Blanco", 48.143],
        ["Blackmon", 48.152],
        ["Wesley", 48.16],
        ["Thomson", 48.168],
        ["McManus", 48.177],
        ["Fair", 48.185],
        ["Burkett", 48.193],
        ["Post", 48.202],
        ["Gleason", 48.21],
        ["Rudolph", 48.218],
        ["Ott", 48.227],
        ["Dickens", 48.235],
        ["Cormier", 48.243],
        ["Voss", 48.251],
        ["Rushing", 48.26],
        ["Rosenberg", 48.268],
        ["Hurd", 48.276],
        ["Dumas", 48.285],
        ["Benitez", 48.293],
        ["Arellano", 48.301],
        ["Story", 48.309],
        ["Marin", 48.318],
        ["Caudill", 48.326],
        ["Bragg", 48.334],
        ["Jaramillo", 48.343],
        ["Huerta", 48.351],
        ["Gipson", 48.359],
        ["Colvin", 48.367],
        ["Biggs", 48.376],
        ["Vela", 48.384],
        ["Platt", 48.392],
        ["Cassidy", 48.4],
        ["Tompkins", 48.408],
        ["McCollum", 48.417],
        ["Kay", 48.425],
        ["Gabriel", 48.433],
        ["Dolan", 48.441],
        ["Daley", 48.45],
        ["Crump", 48.458],
        ["Street", 48.466],
        ["Sneed", 48.474],
        ["Kilgore", 48.482],
        ["Grove", 48.491],
        ["Grimm", 48.499],
        ["Davison", 48.507],
        ["Brunson", 48.515],
        ["Prater", 48.523],
        ["Marcum", 48.531],
        ["Devine", 48.54],
        ["Kyle", 48.548],
        ["Dodge", 48.556],
        ["Stratton", 48.564],
        ["Rosas", 48.572],
        ["Choi", 48.58],
        ["Tripp", 48.589],
        ["Ledbetter", 48.597],
        ["Lay", 48.605],
        ["Hightower", 48.613],
        ["Haywood", 48.621],
        ["Feldman", 48.629],
        ["Epps", 48.637],
        ["Yeager", 48.645],
        ["Posey", 48.653],
        ["Sylvester", 48.662],
        ["Scruggs", 48.67],
        ["Cope", 48.678],
        ["Stubbs", 48.686],
        ["Richey", 48.694],
        ["Overton", 48.702],
        ["Trotter", 48.71],
        ["Sprague", 48.718],
        ["Cordero", 48.726],
        ["Butcher", 48.734],
        ["Burger", 48.742],
        ["Stiles", 48.75],
        ["Burgos", 48.758],
        ["Woodson", 48.766],
        ["Horner", 48.774],
        ["Bassett", 48.782],
        ["Purcell", 48.79],
        ["Haskins", 48.798],
        ["Gee", 48.806],
        ["Akins", 48.814],
        ["Abraham", 48.823],
        ["Hoyt", 48.83],
        ["Ziegler", 48.838],
        ["Spaulding", 48.846],
        ["Hadley", 48.854],
        ["Grubbs", 48.862],
        ["Sumner", 48.87],
        ["Murillo", 48.878],
        ["Zavala", 48.886],
        ["Shook", 48.894],
        ["Lockwood", 48.902],
        ["Jarrett", 48.91],
        ["Driscoll", 48.918],
        ["Dahl", 48.926],
        ["Thorpe", 48.934],
        ["Sheridan", 48.942],
        ["Redmond", 48.949],
        ["Putnam", 48.957],
        ["McWilliams", 48.965],
        ["McRae", 48.973],
        ["Cornell", 48.981],
        ["Felton", 48.989],
        ["Romano", 48.997],
        ["Joiner", 49.005],
        ["Sadler", 49.012],
        ["Hedrick", 49.02],
        ["Hager", 49.028],
        ["Hagen", 49.036],
        ["Fitch", 49.044],
        ["Coulter", 49.052],
        ["Thacker", 49.059],
        ["Mansfield", 49.067],
        ["Langston", 49.075],
        ["Guidry", 49.083],
        ["Ferreira", 49.091],
        ["Corley", 49.098],
        ["Conn", 49.106],
        ["Rossi", 49.114],
        ["Lackey", 49.122],
        ["Cody", 49.13],
        ["Baez", 49.137],
        ["Saenz", 49.145],
        ["McNamara", 49.153],
        ["Darnell", 49.16],
        ["Michel", 49.168],
        ["McMullen", 49.176],
        ["McKenna", 49.184],
        ["McDonough", 49.191],
        ["Link", 49.199],
        ["Engel", 49.207],
        ["Browne", 49.215],
        ["Roper", 49.222],
        ["Peacock", 49.23],
        ["Eubanks", 49.238],
        ["Drummond", 49.245],
        ["Stringer", 49.253],
        ["Pritchett", 49.261],
        ["Parham", 49.268],
        ["Mims", 49.276],
        ["Landers", 49.284],
        ["Ham", 49.291],
        ["Grayson", 49.299],
        ["Stacy", 49.307],
        ["Schafer", 49.314],
        ["Egan", 49.322],
        ["Timmons", 49.33],
        ["Ohara", 49.337],
        ["Keen", 49.345],
        ["Hamlin", 49.353],
        ["Finn", 49.36],
        ["Cortes", 49.368],
        ["McNair", 49.376],
        ["Louis", 49.383],
        ["Clifford", 49.391],
        ["Nadeau", 49.398],
        ["Moseley", 49.406],
        ["Michaud", 49.414],
        ["Rosen", 49.421],
        ["Oakes", 49.429],
        ["Kurtz", 49.436],
        ["Jeffers", 49.444],
        ["Calloway", 49.452],
        ["Beal", 49.459],
        ["Bautista", 49.467],
        ["Winn", 49.474],
        ["Suggs", 49.482],
        ["Stern", 49.49],
        ["Stapleton", 49.497],
        ["Lyles", 49.505],
        ["Laird", 49.512],
        ["Montano", 49.52],
        ["Diamond", 49.527],
        ["Dawkins", 49.535],
        ["Roland", 49.543],
        ["Hagan", 49.55],
        ["Goldman", 49.558],
        ["Bryson", 49.565],
        ["Barajas", 49.573],
        ["Lovett", 49.58],
        ["Segura", 49.588],
        ["Metz", 49.595],
        ["Lockett", 49.603],
        ["Langford", 49.61],
        ["Hinson", 49.618],
        ["Eastman", 49.625],
        ["Rock", 49.633],
        ["Hooks", 49.64],
        ["Woody", 49.648],
        ["Smallwood", 49.655],
        ["Shapiro", 49.663],
        ["Crowell", 49.67],
        ["Whalen", 49.678],
        ["Triplett", 49.685],
        ["Hooker", 49.693],
        ["Chatman", 49.7],
        ["Aldrich", 49.708],
        ["Cahill", 49.715],
        ["Youngblood", 49.723],
        ["Ybarra", 49.73],
        ["Stallings", 49.738],
        ["Sheets", 49.745],
        ["Samuel", 49.752],
        ["Reeder", 49.76],
        ["Person", 49.767],
        ["Pack", 49.775],
        ["Lacey", 49.782],
        ["Connelly", 49.79],
        ["Bateman", 49.797],
        ["Abernathy", 49.804],
        ["Winkler", 49.812],
        ["Wilkes", 49.819],
        ["Masters", 49.827],
        ["Hackett", 49.834],
        ["Granger", 49.841],
        ["Gillis", 49.849],
        ["Schmitz", 49.856],
        ["Sapp", 49.863],
        ["Napier", 49.871],
        ["Souza", 49.878],
        ["Lanier", 49.886],
        ["Gomes", 49.893],
        ["Weir", 49.9],
        ["Otero", 49.908],
        ["Ledford", 49.915],
        ["Burroughs", 49.922],
        ["Babcock", 49.93],
        ["Ventura", 49.937],
        ["Siegel", 49.944],
        ["Dugan", 49.952],
        ["Clinton", 49.959],
        ["Christie", 49.966],
        ["Bledsoe", 49.974],
        ["Atwood", 49.981],
        ["Wray", 49.988],
        ["Varner", 49.996],
        ["Spangler", 50.003],
        ["Otto", 50.01],
        ["Anaya", 50.017],
        ["Staley", 50.025],
        ["Kraft", 50.032],
        ["Fournier", 50.039],
        ["Eddy", 50.047],
        ["Belanger", 50.054],
        ["Wolff", 50.061],
        ["Thorne", 50.069],
        ["Bynum", 50.076],
        ["Burnette", 50.083],
        ["Boykin", 50.09],
        ["Swenson", 50.098],
        ["Purvis", 50.105],
        ["Pina", 50.112],
        ["Khan", 50.119],
        ["Duvall", 50.127],
        ["Darby", 50.134],
        ["Xiong", 50.141],
        ["Kauffman", 50.148],
        ["Ali", 50.156],
        ["Yu", 50.163],
        ["Healy", 50.17],
        ["Engle", 50.177],
        ["Corona", 50.185],
        ["Benoit", 50.192],
        ["Valle", 50.199],
        ["Steiner", 50.206],
        ["Spicer", 50.214],
        ["Shaver", 50.221],
        ["Randle", 50.228],
        ["Lundy", 50.235],
        ["Dow", 50.242],
        ["Chin", 50.25],
        ["Calvert", 50.257],
        ["Staton", 50.264],
        ["Neff", 50.271],
        ["Kearney", 50.278],
        ["Darden", 50.286],
        ["Oakley", 50.293],
        ["Medeiros", 50.3],
        ["McCracken", 50.307],
        ["Crenshaw", 50.314],
        ["Block", 50.322],
        ["Beaver", 50.329],
        ["Perdue", 50.336],
        ["Dill", 50.343],
        ["Whittaker", 50.35],
        ["Tobin", 50.357],
        ["Cornelius", 50.365],
        ["Washburn", 50.372],
        ["Hogue", 50.379],
        ["Goodrich", 50.386],
        ["Easley", 50.393],
        ["Bravo", 50.4],
        ["Dennison", 50.407],
        ["Vera", 50.414],
        ["Shipley", 50.422],
        ["Kerns", 50.429],
        ["Jorgensen", 50.436],
        ["Crain", 50.443],
        ["Abel", 50.45],
        ["Villalobos", 50.457],
        ["Maurer", 50.464],
        ["Longoria", 50.471],
        ["Keene", 50.478],
        ["Coon", 50.485],
        ["Sierra", 50.492],
        ["Witherspoon", 50.499],
        ["Staples", 50.506],
        ["Pettit", 50.513],
        ["Kincaid", 50.52],
        ["Eason", 50.528],
        ["Madrid", 50.535],
        ["Echols", 50.542],
        ["Lusk", 50.549],
        ["Wu", 50.556],
        ["Stahl", 50.563],
        ["Currie", 50.57],
        ["Thayer", 50.577],
        ["Shultz", 50.584],
        ["Sherwood", 50.591],
        ["McNally", 50.598],
        ["Seay", 50.604],
        ["North", 50.611],
        ["Maher", 50.618],
        ["Kenny", 50.625],
        ["Hope", 50.632],
        ["Gagne", 50.639],
        ["Barrow", 50.646],
        ["Nava", 50.653],
        ["Myles", 50.66],
        ["Moreland", 50.667],
        ["Honeycutt", 50.674],
        ["Hearn", 50.681],
        ["Diggs", 50.688],
        ["Caron", 50.695],
        ["Whitten", 50.702],
        ["Westbrook", 50.709],
        ["Stovall", 50.716],
        ["Ragland", 50.723],
        ["Queen", 50.73],
        ["Munson", 50.736],
        ["Meier", 50.743],
        ["Looney", 50.75],
        ["Kimble", 50.757],
        ["Jolly", 50.764],
        ["Hobson", 50.771],
        ["London", 50.778],
        ["Goddard", 50.785],
        ["Culver", 50.792],
        ["Burr", 50.799],
        ["Presley", 50.806],
        ["Negron", 50.813],
        ["Connell", 50.819],
        ["Tovar", 50.826],
        ["Marcus", 50.833],
        ["Huddleston", 50.84],
        ["Hammer", 50.847],
        ["Ashby", 50.854],
        ["Salter", 50.861],
        ["Root", 50.868],
        ["Pendleton", 50.875],
        ["Oleary", 50.881],
        ["Nickerson", 50.888],
        ["Myrick", 50.895],
        ["Judd", 50.902],
        ["Jacobsen", 50.909],
        ["Elliot", 50.916],
        ["Bain", 50.923],
        ["Adair", 50.929],
        ["Starnes", 50.936],
        ["Sheldon", 50.943],
        ["Matos", 50.95],
        ["Light", 50.957],
        ["Busby", 50.964],
        ["Herndon", 50.971],
        ["Hanley", 50.977],
        ["Bellamy", 50.984],
        ["Jack", 50.991],
        ["Doty", 50.998],
        ["Bartley", 51.005],
        ["Yazzie", 51.011],
        ["Rowell", 51.018],
        ["Parson", 51.025],
        ["Gifford", 51.032],
        ["Cullen", 51.039],
        ["Christiansen", 51.045],
        ["Benavides", 51.052],
        ["Barnhart", 51.059],
        ["Talbot", 51.066],
        ["Mock", 51.072],
        ["Crandall", 51.079],
        ["Connors", 51.086],
        ["Bonds", 51.093],
        ["Whitt", 51.099],
        ["Gage", 51.106],
        ["Bergman", 51.113],
        ["Arredondo", 51.12],
        ["Addison", 51.126],
        ["Marion", 51.133],
        ["Lujan", 51.14],
        ["Dowdy", 51.147],
        ["Jernigan", 51.153],
        ["Huynh", 51.16],
        ["Bouchard", 51.167],
        ["Dutton", 51.173],
        ["Rhoades", 51.18],
        ["Ouellette", 51.187],
        ["Kiser", 51.193],
        ["Rubin", 51.2],
        ["Herrington", 51.207],
        ["Hare", 51.213],
        ["Denny", 51.22],
        ["Blackman", 51.227],
        ["Babb", 51.233],
        ["Allred", 51.24],
        ["Rudd", 51.247],
        ["Paulson", 51.253],
        ["Ogden", 51.26],
        ["Koenig", 51.267],
        ["Jacob", 51.273],
        ["Irving", 51.28],
        ["Geiger", 51.287],
        ["Begay", 51.293],
        ["Parra", 51.3],
        ["Champion", 51.306],
        ["Lassiter", 51.313],
        ["Hawk", 51.32],
        ["Esposito", 51.326],
        ["Cho", 51.333],
        ["Waldron", 51.34],
        ["Vernon", 51.346],
        ["Ransom", 51.353],
        ["Prather", 51.359],
        ["Keenan", 51.366],
        ["Jean", 51.373],
        ["Grover", 51.379],
        ["Chacon", 51.386],
        ["Vick", 51.392],
        ["Sands", 51.399],
        ["Roark", 51.405],
        ["Parr", 51.412],
        ["Mayberry", 51.419],
        ["Greenberg", 51.425],
        ["Coley", 51.432],
        ["Bruner", 51.438],
        ["Whitman", 51.445],
        ["Skaggs", 51.451],
        ["Shipman", 51.458],
        ["Means", 51.465],
        ["Leary", 51.471],
        ["Hutton", 51.478],
        ["Romo", 51.484],
        ["Medrano", 51.491],
        ["Ladd", 51.497],
        ["Kruse", 51.504],
        ["Friend", 51.511],
        ["Darling", 51.517],
        ["Askew", 51.524],
        ["Valentin", 51.53],
        ["Schulz", 51.537],
        ["Alfaro", 51.543],
        ["Tabor", 51.55],
        ["Mohr", 51.556],
        ["Gallo", 51.563],
        ["Bermudez", 51.569],
        ["Pereira", 51.576],
        ["Isaac", 51.582],
        ["Bliss", 51.589],
        ["Reaves", 51.595],
        ["Flint", 51.602],
        ["Comer", 51.608],
        ["Boston", 51.615],
        ["Woodall", 51.621],
        ["Naquin", 51.628],
        ["Guevara", 51.634],
        ["Earl", 51.641],
        ["Delong", 51.647],
        ["Carrier", 51.654],
        ["Pickens", 51.66],
        ["Brand", 51.666],
        ["Tilley", 51.673],
        ["Schaffer", 51.679],
        ["Read", 51.686],
        ["Lim", 51.692],
        ["Knutson", 51.699],
        ["Fenton", 51.705],
        ["Doran", 51.712],
        ["Chu", 51.718],
        ["Vogt", 51.724],
        ["Vann", 51.731],
        ["Prescott", 51.737],
        ["McLain", 51.744],
        ["Landis", 51.75],
        ["Corcoran", 51.757],
        ["Ambrose", 51.763],
        ["Zapata", 51.769],
        ["Hyatt", 51.776],
        ["Hemphill", 51.782],
        ["Faulk", 51.789],
        ["Call", 51.795],
        ["Dove", 51.801],
        ["Boudreaux", 51.808],
        ["Aragon", 51.814],
        ["Whitlock", 51.821],
        ["Trejo", 51.827],
        ["Tackett", 51.833],
        ["Shearer", 51.84],
        ["Saldana", 51.846],
        ["Hanks", 51.852],
        ["Gold", 51.859],
        ["Driver", 51.865],
        ["McKinnon", 51.871],
        ["Koehler", 51.878],
        ["Champagne", 51.884],
        ["Bourgeois", 51.891],
        ["Pool", 51.897],
        ["Keyes", 51.903],
        ["Goodson", 51.91],
        ["Foote", 51.916],
        ["Early", 51.922],
        ["Lunsford", 51.929],
        ["Goldsmith", 51.935],
        ["Flood", 51.941],
        ["Winslow", 51.948],
        ["Sams", 51.954],
        ["Reagan", 51.96],
        ["McCloud", 51.967],
        ["Hough", 51.973],
        ["Esquivel", 51.979],
        ["Naylor", 51.985],
        ["Loomis", 51.992],
        ["Coronado", 51.998],
        ["Ludwig", 52.004],
        ["Braswell", 52.011],
        ["Bearden", 52.017],
        ["Sherrill", 52.023],
        ["Huang", 52.029],
        ["Fagan", 52.036],
        ["Ezell", 52.042],
        ["Edmondson", 52.048],
        ["Cyr", 52.054],
        ["Cronin", 52.061],
        ["Nunn", 52.067],
        ["Lemon", 52.073],
        ["Guillory", 52.079],
        ["Grier", 52.086],
        ["Dubose", 52.092],
        ["Traylor", 52.098],
        ["Ryder", 52.104],
        ["Dobbins", 52.111],
        ["Coyle", 52.117],
        ["Aponte", 52.123],
        ["Whitmore", 52.129],
        ["Smalls", 52.136],
        ["Rowan", 52.142],
        ["Malloy", 52.148],
        ["Cardona", 52.154],
        ["Braxton", 52.16],
        ["Borden", 52.167],
        ["Humphries", 52.173],
        ["Carrasco", 52.179],
        ["Ruff", 52.185],
        ["Metzger", 52.191],
        ["Huntley", 52.198],
        ["Hinojosa", 52.204],
        ["Finney", 52.21],
        ["Madsen", 52.216],
        ["Hong", 52.222],
        ["Hills", 52.228],
        ["Ernst", 52.235],
        ["Dozier", 52.241],
        ["Burkhart", 52.247],
        ["Bowser", 52.253],
        ["Peralta", 52.259],
        ["Daigle", 52.265],
        ["Whittington", 52.272],
        ["Sorenson", 52.278],
        ["Saucedo", 52.284],
        ["Roche", 52.29],
        ["Redding", 52.296],
        ["Loyd", 52.302],
        ["Fugate", 52.308],
        ["Avalos", 52.315],
        ["Waite", 52.321],
        ["Lind", 52.327],
        ["Huston", 52.333],
        ["Hay", 52.339],
        ["Benedict", 52.345],
        ["Hawthorne", 52.351],
        ["Hamby", 52.357],
        ["Boyles", 52.363],
        ["Boles", 52.37],
        ["Regan", 52.376],
        ["Faust", 52.382],
        ["Crook", 52.388],
        ["Beam", 52.394],
        ["Barger", 52.4],
        ["Hinds", 52.406],
        ["Gallardo", 52.412],
        ["Elias", 52.418],
        ["Willoughby", 52.424],
        ["Willingham", 52.43],
        ["Wilburn", 52.436],
        ["Eckert", 52.442],
        ["Busch", 52.448],
        ["Zepeda", 52.455],
        ["Worthington", 52.461],
        ["Tinsley", 52.467],
        ["Russ", 52.473],
        ["Li", 52.479],
        ["Hoff", 52.485],
        ["Hawley", 52.491],
        ["Carmona", 52.497],
        ["Varela", 52.503],
        ["Rector", 52.509],
        ["Newcomb", 52.515],
        ["Mallory", 52.521],
        ["Kinsey", 52.527],
        ["Dube", 52.533],
        ["Whatley", 52.539],
        ["Strange", 52.545],
        ["Ragsdale", 52.551],
        ["Ivy", 52.557],
        ["Bernstein", 52.563],
        ["Becerra", 52.569],
        ["Yost", 52.575],
        ["Mattson", 52.581],
        ["Ly", 52.587],
        ["Felder", 52.593],
        ["Cheek", 52.599],
        ["Luke", 52.605],
        ["Handy", 52.611],
        ["Grossman", 52.617],
        ["Gauthier", 52.623],
        ["Escobedo", 52.629],
        ["Braden", 52.635],
        ["Beckman", 52.641],
        ["Mott", 52.647],
        ["Hillman", 52.653],
        ["Gil", 52.659],
        ["Flaherty", 52.665],
        ["Dykes", 52.671],
        ["Doe", 52.677],
        ["Stockton", 52.683],
        ["Stearns", 52.688],
        ["Lofton", 52.694],
        ["Kitchen", 52.7],
        ["Coats", 52.706],
        ["Cavazos", 52.712],
        ["Beavers", 52.718],
        ["Barrios", 52.724],
        ["Tang", 52.73],
        ["Parish", 52.736],
        ["Mosher", 52.742],
        ["Lincoln", 52.748],
        ["Cardwell", 52.754],
        ["Coles", 52.76],
        ["Burnham", 52.766],
        ["Weller", 52.772],
        ["Lemons", 52.777],
        ["Beebe", 52.783],
        ["Aguilera", 52.789],
        ["Ring", 52.795],
        ["Parnell", 52.801],
        ["Harman", 52.807],
        ["Couture", 52.813],
        ["Alley", 52.819],
        ["Schumacher", 52.825],
        ["Redd", 52.83],
        ["Dobbs", 52.836],
        ["Blum", 52.842],
        ["Blalock", 52.848],
        ["Merchant", 52.854],
        ["Ennis", 52.86],
        ["Denson", 52.866],
        ["Cottrell", 52.871],
        ["Chester", 52.877],
        ["Brannon", 52.883],
        ["Bagley", 52.889],
        ["Aviles", 52.895],
        ["Watt", 52.901],
        ["Sousa", 52.906],
        ["Rosenthal", 52.912],
        ["Rooney", 52.918],
        ["Dietz", 52.924],
        ["Blank", 52.93],
        ["Paquette", 52.936],
        ["McClelland", 52.941],
        ["Duff", 52.947],
        ["Velasco", 52.953],
        ["Lentz", 52.959],
        ["Grubb", 52.965],
        ["Burrows", 52.97],
        ["Barbour", 52.976],
        ["Ulrich", 52.982],
        ["Shockley", 52.988],
        ["Rader", 52.994],
        ["German", 52.999],
        ["Beyer", 53.005],
        ["Mixon", 53.011],
        ["Layton", 53.017],
        ["Altman", 53.022],
        ["Alonzo", 53.028],
        ["Weathers", 53.034],
        ["Titus", 53.04],
        ["Stoner", 53.046],
        ["Squires", 53.051],
        ["Shipp", 53.057],
        ["Priest", 53.063],
        ["Lipscomb", 53.069],
        ["Cutler", 53.074],
        ["Caballero", 53.08],
        ["Zimmer", 53.086],
        ["Willett", 53.092],
        ["Thurston", 53.097],
        ["Storey", 53.103],
        ["Medley", 53.109],
        ["Lyle", 53.114],
        ["Epperson", 53.12],
        ["Shah", 53.126],
        ["McMillian", 53.132],
        ["Baggett", 53.137],
        ["Torrez", 53.143],
        ["Laws", 53.149],
        ["Hirsch", 53.155],
        ["Dent", 53.16],
        ["Corey", 53.166],
        ["Poirier", 53.172],
        ["Peachey", 53.177],
        ["Jacques", 53.183],
        ["Farrar", 53.189],
        ["Creech", 53.194],
        ["Barth", 53.2],
        ["Trimble", 53.206],
        ["France", 53.211],
        ["Dupre", 53.217],
        ["Albrecht", 53.223],
        ["Sample", 53.228],
        ["Lawler", 53.234],
        ["Crisp", 53.24],
        ["Conroy", 53.245],
        ["Chadwick", 53.251],
        ["Wetzel", 53.257],
        ["Nesbitt", 53.262],
        ["Murry", 53.268],
        ["Jameson", 53.274],
        ["Wilhelm", 53.279],
        ["Patten", 53.285],
        ["Minton", 53.291],
        ["Matson", 53.296],
        ["Kimbrough", 53.302],
        ["Iverson", 53.307],
        ["Guinn", 53.313],
        ["Gale", 53.319],
        ["Fortune", 53.324],
        ["Croft", 53.33],
        ["Toth", 53.336],
        ["Pulliam", 53.341],
        ["Nugent", 53.347],
        ["Newby", 53.352],
        ["Littlejohn", 53.358],
        ["Dias", 53.364],
        ["Canales", 53.369],
        ["Bernier", 53.375],
        ["Baron", 53.38],
        ["Barney", 53.386],
        ["Singletary", 53.392],
        ["Renteria", 53.397],
        ["Pruett", 53.403],
        ["McHugh", 53.408],
        ["Mabry", 53.414],
        ["Landrum", 53.42],
        ["Brower", 53.425],
        ["Weldon", 53.431],
        ["Stoddard", 53.436],
        ["Ruth", 53.442],
        ["Cagle", 53.448],
        ["Stjohn", 53.453],
        ["Scales", 53.459],
        ["Kohler", 53.464],
        ["Kellogg", 53.47],
        ["Hopson", 53.475],
        ["Gant", 53.481],
        ["Tharp", 53.486],
        ["Gann", 53.492],
        ["Zeigler", 53.498],
        ["Pringle", 53.503],
        ["Hammons", 53.509],
        ["Fairchild", 53.514],
        ["Deaton", 53.52],
        ["Chavis", 53.525],
        ["Carnes", 53.531],
        ["Rowley", 53.536],
        ["Matlock", 53.542],
        ["Libby", 53.547],
        ["Kearns", 53.553],
        ["Irizarry", 53.558],
        ["Carrington", 53.564],
        ["Starkey", 53.569],
        ["Pepper", 53.575],
        ["Lopes", 53.58],
        ["Jarrell", 53.586],
        ["Fay", 53.591],
        ["Craven", 53.597],
        ["Beverly", 53.602],
        ["Baum", 53.608],
        ["Spain", 53.613],
        ["Littlefield", 53.619],
        ["Linn", 53.624],
        ["Humphreys", 53.63],
        ["Hook", 53.635],
        ["High", 53.641],
        ["Etheridge", 53.646],
        ["Cuellar", 53.652],
        ["Chastain", 53.657],
        ["Chance", 53.663],
        ["Bundy", 53.668],
        ["Speer", 53.674],
        ["Skelton", 53.679],
        ["Quiroz", 53.685],
        ["Pyle", 53.69],
        ["Portillo", 53.696],
        ["Ponder", 53.701],
        ["Moulton", 53.706],
        ["Machado", 53.712],
        ["Liu", 53.717],
        ["Killian", 53.723],
        ["Hutson", 53.728],
        ["Hitchcock", 53.734],
        ["Ellsworth", 53.739],
        ["Dowling", 53.745],
        ["Cloud", 53.75],
        ["Burdick", 53.756],
        ["Spann", 53.761],
        ["Pedersen", 53.767],
        ["Levin", 53.772],
        ["Leggett", 53.778],
        ["Hayward", 53.783],
        ["Hacker", 53.788],
        ["Dietrich", 53.794],
        ["Beaulieu", 53.799],
        ["Barksdale", 53.805],
        ["Wakefield", 53.81],
        ["Snowden", 53.816],
        ["Paris", 53.821],
        ["Briscoe", 53.827],
        ["Bowie", 53.832],
        ["Berman", 53.837],
        ["Ogle", 53.843],
        ["McGregor", 53.848],
        ["Laughlin", 53.854],
        ["Helm", 53.859],
        ["Burden", 53.864],
        ["Wheatley", 53.87],
        ["Schreiber", 53.875],
        ["Pressley", 53.881],
        ["Parris", 53.886],
        ["Ng", 53.892],
        ["Alaniz", 53.897],
        ["Agee", 53.902],
        ["Urban", 53.908],
        ["Swann", 53.913],
        ["Snodgrass", 53.919],
        ["Schuster", 53.924],
        ["Radford", 53.929],
        ["Monk", 53.935],
        ["Mattingly", 53.94],
        ["Main", 53.945],
        ["Lamar", 53.951],
        ["Harp", 53.956],
        ["Girard", 53.962],
        ["Cheney", 53.967],
        ["Yancey", 53.972],
        ["Wagoner", 53.978],
        ["Ridley", 53.983],
        ["Lombardo", 53.988],
        ["Lau", 53.994],
        ["Hudgins", 53.999],
        ["Gaskins", 54.005],
        ["Duckworth", 54.01],
        ["Coe", 54.015],
        ["Coburn", 54.021],
        ["Willey", 54.026],
        ["Prado", 54.031],
        ["Newberry", 54.037],
        ["Magana", 54.042],
        ["Hammonds", 54.048],
        ["Elam", 54.053],
        ["Whipple", 54.058],
        ["Slade", 54.064],
        ["Serna", 54.069],
        ["Ojeda", 54.074],
        ["Liles", 54.08],
        ["Dorman", 54.085],
        ["Diehl", 54.09],
        ["Angel", 54.096],
        ["Upton", 54.101],
        ["Reardon", 54.106],
        ["Michaels", 54.112],
        ["Kelsey", 54.117],
        ["Goetz", 54.122],
        ["Eller", 54.128],
        ["Bauman", 54.133],
        ["Baer", 54.138],
        ["Augustine", 54.144],
        ["Layne", 54.149],
        ["Hummel", 54.154],
        ["Brenner", 54.159],
        ["Amaya", 54.165],
        ["Adamson", 54.17],
        ["Ornelas", 54.175],
        ["Dowell", 54.181],
        ["Cloutier", 54.186],
        ["Christy", 54.191],
        ["Castellanos", 54.197],
        ["Wing", 54.202],
        ["Wellman", 54.207],
        ["Saylor", 54.212],
        ["Orourke", 54.218],
        ["Moya", 54.223],
        ["Montalvo", 54.228],
        ["Kilpatrick", 54.234],
        ["Harley", 54.239],
        ["Durbin", 54.244],
        ["Shell", 54.249],
        ["Oldham", 54.255],
        ["Kang", 54.26],
        ["Garvin", 54.265],
        ["Foss", 54.27],
        ["Branham", 54.276],
        ["Bartholomew", 54.281],
        ["Templeton", 54.286],
        ["Maguire", 54.291],
        ["Holton", 54.297],
        ["Alonso", 54.302],
        ["Rider", 54.307],
        ["Monahan", 54.312],
        ["McCormack", 54.318],
        ["Beaty", 54.323],
        ["Anders", 54.328],
        ["Streeter", 54.333],
        ["Nieto", 54.338],
        ["Nielson", 54.344],
        ["Moffett", 54.349],
        ["Lankford", 54.354],
        ["Keating", 54.359],
        ["Heck", 54.365],
        ["Gatlin", 54.37],
        ["Delatorre", 54.375],
        ["Callaway", 54.38],
        ["Adcock", 54.385],
        ["Worrell", 54.391],
        ["Unger", 54.396],
        ["Robinette", 54.401],
        ["Nowak", 54.406],
        ["Jeter", 54.411],
        ["Brunner", 54.417],
        ["Ashton", 54.422],
        ["Steen", 54.427],
        ["Parrott", 54.432],
        ["Overstreet", 54.437],
        ["Nobles", 54.443],
        ["Montanez", 54.448],
        ["Luther", 54.453],
        ["Clevenger", 54.458],
        ["Brinkley", 54.463],
        ["Trahan", 54.468],
        ["Quarles", 54.474],
        ["Pickering", 54.479],
        ["Pederson", 54.484],
        ["Jansen", 54.489],
        ["Grantham", 54.494],
        ["Gilchrist", 54.499],
        ["Crespo", 54.505],
        ["Aiken", 54.51],
        ["Schell", 54.515],
        ["Schaeffer", 54.52],
        ["Lorenz", 54.525],
        ["Leyva", 54.53],
        ["Harms", 54.536],
        ["Dyson", 54.541],
        ["Wallis", 54.546],
        ["Pease", 54.551],
        ["Leavitt", 54.556],
        ["Hyman", 54.561],
        ["Cheng", 54.566],
        ["Cavanaugh", 54.571],
        ["Batts", 54.577],
        ["Warden", 54.582],
        ["Seaman", 54.587],
        ["Rockwell", 54.592],
        ["Quezada", 54.597],
        ["Paxton", 54.602],
        ["Linder", 54.607],
        ["Houck", 54.612],
        ["Fontaine", 54.618],
        ["Durant", 54.623],
        ["Caruso", 54.628],
        ["Adler", 54.633],
        ["Pimentel", 54.638],
        ["Mize", 54.643],
        ["Lytle", 54.648],
        ["Donald", 54.653],
        ["Cleary", 54.658],
        ["Cason", 54.664],
        ["Acker", 54.669],
        ["Switzer", 54.674],
        ["Salmon", 54.679],
        ["Isaacs", 54.684],
        ["Higginbotham", 54.689],
        ["Han", 54.694],
        ["Waterman", 54.699],
        ["Van Dyke", 54.704],
        ["Stamper", 54.709],
        ["Sisk", 54.714],
        ["Shuler", 54.719],
        ["Riddick", 54.724],
        ["Redman", 54.73],
        ["McMahan", 54.735],
        ["Levesque", 54.74],
        ["Hatton", 54.745],
        ["Bronson", 54.75],
        ["Bollinger", 54.755],
        ["Arnett", 54.76],
        ["Okeefe", 54.765],
        ["Gerber", 54.77],
        ["Gannon", 54.775],
        ["Farnsworth", 54.78],
        ["Baughman", 54.785],
        ["Silverman", 54.79],
        ["Satterfield", 54.795],
        ["Royal", 54.8],
        ["McCrary", 54.805],
        ["Kowalski", 54.81],
        ["Joy", 54.816],
        ["Grigsby", 54.821],
        ["Greco", 54.826],
        ["Cabral", 54.831],
        ["Trout", 54.836],
        ["Rinehart", 54.841],
        ["Mahon", 54.846],
        ["Linton", 54.851],
        ["Gooden", 54.856],
        ["Curley", 54.861],
        ["Baugh", 54.866],
        ["Wyman", 54.871],
        ["Weiner", 54.876],
        ["Schwab", 54.881],
        ["Schuler", 54.886],
        ["Morrissey", 54.891],
        ["Mahan", 54.896],
        ["Coy", 54.901],
        ["Bunn", 54.906],
        ["Andrew", 54.911],
        ["Thrasher", 54.916],
        ["Spear", 54.921],
        ["Waggoner", 54.926],
        ["Shelley", 54.931],
        ["Robert", 54.936],
        ["Qualls", 54.941],
        ["Purdy", 54.946],
        ["McWhorter", 54.951],
        ["Mauldin", 54.956],
        ["Mark", 54.961],
        ["Jordon", 54.966],
        ["Gilman", 54.971],
        ["Perryman", 54.976],
        ["Newsom", 54.981],
        ["Menard", 54.985],
        ["Martino", 54.99],
        ["Graf", 54.995],
        ["Billingsley", 55],
        ["Artis", 55.005],
        ["Simpkins", 55.01],
        ["Salisbury", 55.015],
        ["Quintanilla", 55.02],
        ["Gilliland", 55.025],
        ["Fraley", 55.03],
        ["Foust", 55.035],
        ["Crouse", 55.04],
        ["Scarborough", 55.045],
        ["Ngo", 55.05],
        ["Grissom", 55.055],
        ["Fultz", 55.06],
        ["Rico", 55.065],
        ["Marlow", 55.069],
        ["Markham", 55.074],
        ["Madrigal", 55.079],
        ["Lawton", 55.084],
        ["Barfield", 55.089],
        ["Whiting", 55.094],
        ["Varney", 55.099],
        ["Schwarz", 55.104],
        ["Huey", 55.109],
        ["Gooch", 55.114],
        ["Arce", 55.119],
        ["Wheat", 55.123],
        ["Truong", 55.128],
        ["Poulin", 55.133],
        ["Mackenzie", 55.138],
        ["Leone", 55.143],
        ["Hurtado", 55.148],
        ["Selby", 55.153],
        ["Gaither", 55.158],
        ["Fortner", 55.162],
        ["Culpepper", 55.167],
        ["Coughlin", 55.172],
        ["Brinson", 55.177],
        ["Boudreau", 55.182],
        ["Barkley", 55.187],
        ["Bales", 55.192],
        ["Stepp", 55.196],
        ["Holm", 55.201],
        ["Tan", 55.206],
        ["Schilling", 55.211],
        ["Morrell", 55.216],
        ["Kahn", 55.221],
        ["Heaton", 55.225],
        ["Gamez", 55.23],
        ["Douglass", 55.235],
        ["Causey", 55.24],
        ["Brothers", 55.245],
        ["Turpin", 55.25],
        ["Shanks", 55.254],
        ["Schrader", 55.259],
        ["Meek", 55.264],
        ["Isom", 55.269],
        ["Hardison", 55.274],
        ["Carranza", 55.278],
        ["Yanez", 55.283],
        ["Way", 55.288],
        ["Scroggins", 55.293],
        ["Schofield", 55.298],
        ["Runyon", 55.302],
        ["Ratcliff", 55.307],
        ["Murrell", 55.312],
        ["Moeller", 55.317],
        ["Irby", 55.322],
        ["Currier", 55.326],
        ["Butterfield", 55.331],
        ["Yee", 55.336],
        ["Ralston", 55.341],
        ["Pullen", 55.346],
        ["Pinson", 55.35],
        ["Estep", 55.355],
        ["East", 55.36],
        ["Carbone", 55.365],
        ["Lance", 55.37],
        ["Hawks", 55.374],
        ["Ellington", 55.379],
        ["Casillas", 55.384],
        ["Spurlock", 55.389],
        ["Sikes", 55.393],
        ["Motley", 55.398],
        ["McCartney", 55.403],
        ["Kruger", 55.408],
        ["Isbell", 55.412],
        ["Houle", 55.417],
        ["Francisco", 55.422],
        ["Burk", 55.427],
        ["Bone", 55.431],
        ["Tomlin", 55.436],
        ["Shelby", 55.441],
        ["Quigley", 55.446],
        ["Neumann", 55.45],
        ["Lovelace", 55.455],
        ["Fennell", 55.46],
        ["Colby", 55.465],
        ["Cheatham", 55.469],
        ["Bustamante", 55.474],
        ["Skidmore", 55.479],
        ["Hidalgo", 55.483],
        ["Forman", 55.488],
        ["Culp", 55.493],
        ["Bowens", 55.498],
        ["Betancourt", 55.502],
        ["Aquino", 55.507],
        ["Robb", 55.512],
        ["Rea", 55.516],
        ["Milner", 55.521],
        ["Martel", 55.526],
        ["Gresham", 55.531],
        ["Wiles", 55.535],
        ["Ricketts", 55.54],
        ["Gavin", 55.545],
        ["Dowd", 55.549],
        ["Collazo", 55.554],
        ["Bostic", 55.559],
        ["Blakely", 55.563],
        ["Sherrod", 55.568],
        ["Power", 55.573],
        ["Kenyon", 55.577],
        ["Gandy", 55.582],
        ["Ebert", 55.587],
        ["Deloach", 55.591],
        ["Cary", 55.596],
        ["Bull", 55.601],
        ["Allard", 55.606],
        ["Sauer", 55.61],
        ["Robins", 55.615],
        ["Olivares", 55.619],
        ["Gillette", 55.624],
        ["Chestnut", 55.629],
        ["Bourque", 55.633],
        ["Paine", 55.638],
        ["Lyman", 55.643],
        ["Hite", 55.647],
        ["Hauser", 55.652],
        ["Devore", 55.657],
        ["Crawley", 55.661],
        ["Chapa", 55.666],
        ["Vu", 55.671],
        ["Tobias", 55.675],
        ["Talbert", 55.68],
        ["Poindexter", 55.684],
        ["Millard", 55.689],
        ["Meador", 55.694],
        ["McDuffie", 55.698],
        ["Mattox", 55.703],
        ["Kraus", 55.708],
        ["Harkins", 55.712],
        ["Choate", 55.717],
        ["Bess", 55.721],
        ["Wren", 55.726],
        ["Sledge", 55.731],
        ["Sanborn", 55.735],
        ["Outlaw", 55.74],
        ["Kinder", 55.745],
        ["Geary", 55.749],
        ["Cornwell", 55.754],
        ["Barclay", 55.758],
        ["Adam", 55.763],
        ["Abney", 55.768],
        ["Seward", 55.772],
        ["Rhoads", 55.777],
        ["Howland", 55.781],
        ["Fortier", 55.786],
        ["Easter", 55.791],
        ["Benner", 55.795],
        ["Vines", 55.8],
        ["Tubbs", 55.804],
        ["Troutman", 55.809],
        ["Rapp", 55.813],
        ["Noe", 55.818],
        ["McCurdy", 55.823],
        ["Harder", 55.827],
        ["Deluca", 55.832],
        ["Westmoreland", 55.836],
        ["South", 55.841],
        ["Havens", 55.845],
        ["Guajardo", 55.85],
        ["Ely", 55.855],
        ["Clary", 55.859],
        ["Seal", 55.864],
        ["Meehan", 55.868],
        ["Herzog", 55.873],
        ["Guillen", 55.877],
        ["Ashcraft", 55.882],
        ["Waugh", 55.886],
        ["Renner", 55.891],
        ["Milam", 55.895],
        ["Jung", 55.9],
        ["Elrod", 55.905],
        ["Churchill", 55.909],
        ["Buford", 55.914],
        ["Breaux", 55.918],
        ["Bolin", 55.923],
        ["Asher", 55.927],
        ["Windham", 55.932],
        ["Tirado", 55.936],
        ["Pemberton", 55.941],
        ["Nolen", 55.945],
        ["Noland", 55.95],
        ["Knott", 55.954],
        ["Emmons", 55.959],
        ["Cornish", 55.963],
        ["Christenson", 55.968],
        ["Brownlee", 55.972],
        ["Barbee", 55.977],
        ["Waldrop", 55.981],
        ["Pitt", 55.986],
        ["Olvera", 55.99],
        ["Lombardi", 55.995],
        ["Gruber", 55.999],
        ["Gaffney", 56.004],
        ["Eggleston", 56.008],
        ["Banda", 56.013],
        ["Archuleta", 56.017],
        ["Still", 56.022],
        ["Slone", 56.026],
        ["Prewitt", 56.031],
        ["Pfeiffer", 56.035],
        ["Nettles", 56.04],
        ["Mena", 56.044],
        ["McAdams", 56.049],
        ["Henning", 56.053],
        ["Gardiner", 56.058],
        ["Cromwell", 56.062],
        ["Chisholm", 56.067],
        ["Burleson", 56.071],
        ["Box", 56.076],
        ["Vest", 56.08],
        ["Oglesby", 56.085],
        ["McCarter", 56.089],
        ["Malcolm", 56.093],
        ["Lumpkin", 56.098],
        ["Larue", 56.102],
        ["Grey", 56.107],
        ["Wofford", 56.111],
        ["Van Horn", 56.116],
        ["Thorn", 56.12],
        ["Teel", 56.125],
        ["Swafford", 56.129],
        ["Stclair", 56.134],
        ["Stanfield", 56.138],
        ["Ocampo", 56.142],
        ["Herrmann", 56.147],
        ["Hannon", 56.151],
        ["Arsenault", 56.156],
        ["Roush", 56.16],
        ["McAlister", 56.165],
        ["Hiatt", 56.169],
        ["Gunderson", 56.174],
        ["Forsythe", 56.178],
        ["Duggan", 56.182],
        ["Delvalle", 56.187],
        ["Cintron", 56.191],
        ["Wilks", 56.196],
        ["Weinstein", 56.2],
        ["Uribe", 56.205],
        ["Rizzo", 56.209],
        ["Noyes", 56.213],
        ["McLendon", 56.218],
        ["Gurley", 56.222],
        ["Bethea", 56.227],
        ["Winstead", 56.231],
        ["Maples", 56.235],
        ["Harry", 56.24],
        ["Guyton", 56.244],
        ["Giordano", 56.249],
        ["Alderman", 56.253],
        ["Valdes", 56.258],
        ["Polanco", 56.262],
        ["Pappas", 56.266],
        ["Lively", 56.271],
        ["Grogan", 56.275],
        ["Griffiths", 56.279],
        ["Bobo", 56.284],
        ["Arevalo", 56.288],
        ["Whitson", 56.293],
        ["Sowell", 56.297],
        ["Rendon", 56.301],
        ["Matthew", 56.306],
        ["Julian", 56.31],
        ["Fernandes", 56.314],
        ["Farrow", 56.319],
        ["Edmond", 56.323],
        ["Benavidez", 56.328],
        ["Ayres", 56.332],
        ["Alicea", 56.336],
        ["Stump", 56.341],
        ["Smalley", 56.345],
        ["Seitz", 56.349],
        ["Schulte", 56.354],
        ["Gilley", 56.358],
        ["Gallant", 56.362],
        ["Dewey", 56.367],
        ["Casper", 56.371],
        ["Canfield", 56.376],
        ["Wolford", 56.38],
        ["Omalley", 56.384],
        ["McNutt", 56.389],
        ["McNulty", 56.393],
        ["McGovern", 56.397],
        ["Hardman", 56.402],
        ["Harbin", 56.406],
        ["Cowart", 56.41],
        ["Chavarria", 56.415],
        ["Brink", 56.419],
        ["Beckett", 56.423],
        ["Bagwell", 56.428],
        ["Armstead", 56.432],
        ["Anglin", 56.436],
        ["Abreu", 56.441],
        ["Reynoso", 56.445],
        ["Krebs", 56.449],
        ["Jett", 56.454],
        ["Hoffmann", 56.458],
        ["Greenfield", 56.462],
        ["Forte", 56.467],
        ["Burney", 56.471],
        ["Broome", 56.475],
        ["Sisson", 56.48],
        ["Parent", 56.484],
        ["Jude", 56.488],
        ["Younger", 56.492],
        ["Trammell", 56.497],
        ["Partridge", 56.501],
        ["Marvin", 56.505],
        ["Mace", 56.51],
        ["Lomax", 56.514],
        ["Lemieux", 56.518],
        ["Gossett", 56.522],
        ["Frantz", 56.527],
        ["Fogle", 56.531],
        ["Cooney", 56.535],
        ["Broughton", 56.54],
        ["Pence", 56.544],
        ["Paulsen", 56.548],
        ["Neil", 56.552],
        ["Muncy", 56.557],
        ["McArthur", 56.561],
        ["Hollins", 56.565],
        ["Edward", 56.57],
        ["Beauchamp", 56.574],
        ["Withers", 56.578],
        ["Osorio", 56.582],
        ["Mulligan", 56.587],
        ["Hoyle", 56.591],
        ["Foy", 56.595],
        ["Dockery", 56.599],
        ["Cockrell", 56.604],
        ["Begley", 56.608],
        ["Amador", 56.612],
        ["Roby", 56.616],
        ["Rains", 56.621],
        ["Lindquist", 56.625],
        ["Gentile", 56.629],
        ["Everhart", 56.633],
        ["Bohannon", 56.638],
        ["Wylie", 56.642],
        ["Thao", 56.646],
        ["Sommers", 56.65],
        ["Purnell", 56.655],
        ["Palma", 56.659],
        ["Fortin", 56.663],
        ["Dunning", 56.667],
        ["Breeden", 56.671],
        ["Vail", 56.676],
        ["Phelan", 56.68],
        ["Phan", 56.684],
        ["Marx", 56.688],
        ["Cosby", 56.693],
        ["Colburn", 56.697],
        ["Chong", 56.701],
        ["Boling", 56.705],
        ["Biddle", 56.709],
        ["Ledesma", 56.714],
        ["Gaddis", 56.718],
        ["Denney", 56.722],
        ["Chow", 56.726],
        ["Bueno", 56.73],
        ["Berrios", 56.735],
        ["Wicker", 56.739],
        ["Tolliver", 56.743],
        ["Thibodeaux", 56.747],
        ["Nagle", 56.751],
        ["Lavoie", 56.755],
        ["Fisk", 56.76],
        ["Do", 56.764],
        ["Crist", 56.768],
        ["Barbosa", 56.772],
        ["Reedy", 56.776],
        ["March", 56.781],
        ["Locklear", 56.785],
        ["Kolb", 56.789],
        ["Himes", 56.793],
        ["Behrens", 56.797],
        ["Beckwith", 56.801],
        ["Beckham", 56.806],
        ["Weems", 56.81],
        ["Wahl", 56.814],
        ["Shorter", 56.818],
        ["Shackelford", 56.822],
        ["Rees", 56.826],
        ["Muse", 56.83],
        ["Free", 56.835],
        ["Cerda", 56.839],
        ["Valadez", 56.843],
        ["Thibodeau", 56.847],
        ["Saavedra", 56.851],
        ["Ridgeway", 56.855],
        ["Reiter", 56.859],
        ["McHenry", 56.864],
        ["Majors", 56.868],
        ["Lachance", 56.872],
        ["Keaton", 56.876],
        ["Israel", 56.88],
        ["Ferrara", 56.884],
        ["Falcon", 56.888],
        ["Clemens", 56.892],
        ["Blocker", 56.897],
        ["Applegate", 56.901],
        ["Paz", 56.905],
        ["Needham", 56.909],
        ["Mojica", 56.913],
        ["Kuykendall", 56.917],
        ["Hamel", 56.921],
        ["Escamilla", 56.925],
        ["Doughty", 56.93],
        ["Burchett", 56.934],
        ["Ainsworth", 56.938],
        ["Wilbur", 56.942],
        ["Vidal", 56.946],
        ["Upchurch", 56.95],
        ["Thigpen", 56.954],
        ["Strauss", 56.958],
        ["Spruill", 56.962],
        ["Sowers", 56.967],
        ["Riggins", 56.971],
        ["Ricker", 56.975],
        ["McCombs", 56.979],
        ["Harlow", 56.983],
        ["Garnett", 56.987],
        ["Buffington", 56.991],
        ["Yi", 56.995],
        ["Sotelo", 56.999],
        ["Olivas", 57.003],
        ["Negrete", 57.007],
        ["Morey", 57.012],
        ["Macon", 57.016],
        ["Logsdon", 57.02],
        ["Lapointe", 57.024],
        ["Florence", 57.028],
        ["Cathey", 57.032],
        ["Bigelow", 57.036],
        ["Bello", 57.04],
        ["Westfall", 57.044],
        ["Stubblefield", 57.048],
        ["Peak", 57.052],
        ["Lindley", 57.056],
        ["Jeffrey", 57.06],
        ["Hein", 57.065],
        ["Hawes", 57.069],
        ["Farrington", 57.073],
        ["Edge", 57.077],
        ["Breen", 57.081],
        ["Birch", 57.085],
        ["Wilde", 57.089],
        ["Steed", 57.093],
        ["Sepulveda", 57.097],
        ["Reinhardt", 57.101],
        ["Proffitt", 57.105],
        ["Minter", 57.109],
        ["Messina", 57.113],
        ["McNabb", 57.117],
        ["Maier", 57.121],
        ["Keeler", 57.125],
        ["Gamboa", 57.13],
        ["Donohue", 57.134],
        ["Dexter", 57.138],
        ["Basham", 57.142],
        ["Shinn", 57.146],
        ["Orlando", 57.15],
        ["Crooks", 57.154],
        ["Cota", 57.158],
        ["Borders", 57.162],
        ["Bills", 57.166],
        ["Bachman", 57.17],
        ["Tisdale", 57.174],
        ["Tavares", 57.178],
        ["Schmid", 57.182],
        ["Pickard", 57.186],
        ["Jasper", 57.19],
        ["Gulley", 57.194],
        ["Fonseca", 57.198],
        ["Delossantos", 57.202],
        ["Condon", 57.206],
        ["Clancy", 57.21],
        ["Batista", 57.214],
        ["Wicks", 57.218],
        ["Wadsworth", 57.222],
        ["New", 57.226],
        ["Martell", 57.23],
        ["Lo", 57.234],
        ["Littleton", 57.238],
        ["Ison", 57.242],
        ["Haag", 57.246],
        ["Folsom", 57.25],
        ["Brumfield", 57.254],
        ["Broyles", 57.258],
        ["Brito", 57.262],
        ["Mireles", 57.266],
        ["McDonnell", 57.27],
        ["Leclair", 57.274],
        ["Hamblin", 57.278],
        ["Gough", 57.282],
        ["Fanning", 57.286],
        ["Binder", 57.29],
        ["Winfield", 57.294],
        ["Whitworth", 57.298],
        ["Soriano", 57.302],
        ["Palumbo", 57.306],
        ["Newkirk", 57.31],
        ["Mangum", 57.314],
        ["Hutcherson", 57.318],
        ["Comstock", 57.322],
        ["Cecil", 57.326],
        ["Carlin", 57.33],
        ["Beall", 57.334],
        ["Bair", 57.338],
        ["Wendt", 57.342],
        ["Watters", 57.346],
        ["Walling", 57.35],
        ["Putman", 57.354],
        ["Otoole", 57.358],
        ["Oliva", 57.362],
        ["Morley", 57.366],
        ["Mares", 57.37],
        ["Lemus", 57.373],
        ["Keener", 57.377],
        ["Jeffery", 57.381],
        ["Hundley", 57.385],
        ["Dial", 57.389],
        ["Damico", 57.393],
        ["Billups", 57.397],
        ["Strother", 57.401],
        ["McFarlane", 57.405],
        ["Lamm", 57.409],
        ["Eaves", 57.413],
        ["Crutcher", 57.417],
        ["Caraballo", 57.421],
        ["Canty", 57.425],
        ["Atwell", 57.429],
        ["Taft", 57.433],
        ["Siler", 57.437],
        ["Rust", 57.441],
        ["Rawls", 57.444],
        ["Rawlings", 57.448],
        ["Prieto", 57.452],
        ["Niles", 57.456],
        ["McNeely", 57.46],
        ["McAfee", 57.464],
        ["Hulsey", 57.468],
        ["Harlan", 57.472],
        ["Hackney", 57.476],
        ["Galvez", 57.48],
        ["Escalante", 57.484],
        ["Delagarza", 57.488],
        ["Crider", 57.492],
        ["Charlton", 57.496],
        ["Bandy", 57.499],
        ["Wilbanks", 57.503],
        ["Stowe", 57.507],
        ["Steinberg", 57.511],
        ["Samson", 57.515],
        ["Renfro", 57.519],
        ["Masterson", 57.523],
        ["Massie", 57.527],
        ["Lanham", 57.531],
        ["Haskell", 57.535],
        ["Hamrick", 57.539],
        ["Fort", 57.542],
        ["Dehart", 57.546],
        ["Card", 57.55],
        ["Burdette", 57.554],
        ["Branson", 57.558],
        ["Bourne", 57.562],
        ["Babin", 57.566],
        ["Aleman", 57.57],
        ["Worthy", 57.574],
        ["Tibbs", 57.578],
        ["Sweat", 57.582],
        ["Smoot", 57.585],
        ["Slack", 57.589],
        ["Paradis", 57.593],
        ["Packard", 57.597],
        ["Mull", 57.601],
        ["Luce", 57.605],
        ["Houghton", 57.609],
        ["Gantt", 57.613],
        ["Furman", 57.617],
        ["Danner", 57.62],
        ["Christianson", 57.624],
        ["Burge", 57.628],
        ["Broderick", 57.632],
        ["Ashford", 57.636],
        ["Arndt", 57.64],
        ["Almeida", 57.644],
        ["Stallworth", 57.648],
        ["Shade", 57.652],
        ["Searcy", 57.656],
        ["Sager", 57.659],
        ["Noonan", 57.663],
        ["McLemore", 57.667],
        ["McIntire", 57.671],
        ["Maxey", 57.675],
        ["Lavigne", 57.679],
        ["Jobe", 57.683],
        ["Ireland", 57.687],
        ["Ferrer", 57.69],
        ["Falk", 57.694],
        ["Edgar", 57.698],
        ["Coffin", 57.702],
        ["Byrnes", 57.706],
        ["Aranda", 57.71],
        ["Apodaca", 57.714],
        ["Stamps", 57.718],
        ["Rounds", 57.721],
        ["Peek", 57.725],
        ["Olmstead", 57.729],
        ["Lewandowski", 57.733],
        ["Kaminski", 57.737],
        ["Her", 57.741],
        ["Dunaway", 57.745],
        ["Bruns", 57.748],
        ["Brackett", 57.752],
        ["Amato", 57.756],
        ["Reich", 57.76],
        ["McClung", 57.764],
        ["Lacroix", 57.768],
        ["Koontz", 57.772],
        ["Herrick", 57.775],
        ["Hardesty", 57.779],
        ["Flanders", 57.783],
        ["Cousins", 57.787],
        ["Close", 57.791],
        ["Cato", 57.795],
        ["Cade", 57.799],
        ["Vickery", 57.802],
        ["Shank", 57.806],
        ["Nagel", 57.81],
        ["Dupuis", 57.814],
        ["Croteau", 57.818],
        ["Cotter", 57.821],
        ["Cable", 57.825],
        ["Stuckey", 57.829],
        ["Stine", 57.833],
        ["Porterfield", 57.837],
        ["Pauley", 57.841],
        ["Nye", 57.844],
        ["Moffitt", 57.848],
        ["Lu", 57.852],
        ["Knudsen", 57.856],
        ["Hardwick", 57.86],
        ["Goforth", 57.863],
        ["Dupont", 57.867],
        ["Blunt", 57.871],
        ["Barrows", 57.875],
        ["Barnhill", 57.879],
        ["Shull", 57.883],
        ["Rash", 57.886],
        ["Ralph", 57.89],
        ["Penny", 57.894],
        ["Lorenzo", 57.898],
        ["Loftis", 57.902],
        ["Lemay", 57.905],
        ["Kitchens", 57.909],
        ["Horvath", 57.913],
        ["Grenier", 57.917],
        ["Fuchs", 57.921],
        ["Fairbanks", 57.924],
        ["Culbertson", 57.928],
        ["Calkins", 57.932],
        ["Burnside", 57.936],
        ["Beattie", 57.94],
        ["Ashworth", 57.943],
        ["Albertson", 57.947],
        ["Wertz", 57.951],
        ["Vo", 57.955],
        ["Vaught", 57.958],
        ["Vallejo", 57.962],
        ["Tyree", 57.966],
        ["Turk", 57.97],
        ["Tuck", 57.974],
        ["Tijerina", 57.977],
        ["Sage", 57.981],
        ["Picard", 57.985],
        ["Peterman", 57.989],
        ["Otis", 57.993],
        ["Marroquin", 57.996],
        ["Marr", 58],
        ["Lantz", 58.004],
        ["Hoang", 58.008],
        ["Demarco", 58.011],
        ["Daily", 58.015],
        ["Cone", 58.019],
        ["Berube", 58.023],
        ["Barnette", 58.027],
        ["Wharton", 58.03],
        ["Stinnett", 58.034],
        ["Slocum", 58.038],
        ["Scanlon", 58.042],
        ["Sander", 58.045],
        ["Pinto", 58.049],
        ["Mancuso", 58.053],
        ["Lima", 58.057],
        ["Judge", 58.06],
        ["Headley", 58.064],
        ["Epstein", 58.068],
        ["Counts", 58.072],
        ["Clarkson", 58.076],
        ["Carnahan", 58.079],
        ["Brice", 58.083],
        ["Boren", 58.087],
        ["Arteaga", 58.091],
        ["Adame", 58.094],
        ["Zook", 58.098],
        ["Whittle", 58.102],
        ["Whitehurst", 58.106],
        ["Wenzel", 58.109],
        ["Saxton", 58.113],
        ["Rhea", 58.117],
        ["Reddick", 58.121],
        ["Puente", 58.124],
        ["Hazel", 58.128],
        ["Handley", 58.132],
        ["Haggerty", 58.136],
        ["Earley", 58.139],
        ["Devlin", 58.143],
        ["Dallas", 58.147],
        ["Chaffin", 58.151],
        ["Cady", 58.154],
        ["Ahmed", 58.158],
        ["Acuna", 58.162],
        ["Solano", 58.166],
        ["Sigler", 58.169],
        ["Pollack", 58.173],
        ["Pendergrass", 58.177],
        ["Ostrander", 58.181],
        ["Janes", 58.184],
        ["Francois", 58.188],
        ["Fine", 58.192],
        ["Crutchfield", 58.196],
        ["Cordell", 58.199],
        ["Chamberlin", 58.203],
        ["Brubaker", 58.207],
        ["Baptiste", 58.211],
        ["Willson", 58.214],
        ["Reis", 58.218],
        ["Neeley", 58.222],
        ["Mullin", 58.225],
        ["Mercier", 58.229],
        ["Lira", 58.233],
        ["Layman", 58.237],
        ["Keeling", 58.24],
        ["Higdon", 58.244],
        ["Guest", 58.248],
        ["Forrester", 58.251],
        ["Espinal", 58.255],
        ["Dion", 58.259],
        ["Chapin", 58.263],
        ["Carl", 58.266],
        ["Warfield", 58.27],
        ["Toledo", 58.274],
        ["Pulido", 58.277],
        ["Peebles", 58.281],
        ["Nagy", 58.285],
        ["Montague", 58.289],
        ["Mello", 58.292],
        ["Lear", 58.296],
        ["Jaeger", 58.3],
        ["Hogg", 58.303],
        ["Graff", 58.307],
        ["Furr", 58.311],
        ["Derrick", 58.314],
        ["Cave", 58.318],
        ["Canada", 58.322],
        ["Soliz", 58.326],
        ["Poore", 58.329],
        ["Mendenhall", 58.333],
        ["McLaurin", 58.337],
        ["Maestas", 58.34],
        ["Low", 58.344],
        ["Gable", 58.348],
        ["Belt", 58.351],
        ["Barraza", 58.355],
        ["Tillery", 58.359],
        ["Snead", 58.362],
        ["Pond", 58.366],
        ["Neill", 58.37],
        ["McCulloch", 58.373],
        ["McCorkle", 58.377],
        ["Lightfoot", 58.381],
        ["Hutchings", 58.384],
        ["Holloman", 58.388],
        ["Harness", 58.392],
        ["Dorn", 58.395],
        ["Council", 58.399],
        ["Bock", 58.403],
        ["Zielinski", 58.406],
        ["Turley", 58.41],
        ["Treadwell", 58.414],
        ["Stpierre", 58.417],
        ["Starling", 58.421],
        ["Somers", 58.425],
        ["Oswald", 58.428],
        ["Merrick", 58.432],
        ["Marquis", 58.436],
        ["Ivory", 58.439],
        ["Easterling", 58.443],
        ["Bivens", 58.447],
        ["Truitt", 58.45],
        ["Poston", 58.454],
        ["Parry", 58.458],
        ["Ontiveros", 58.461],
        ["Olivarez", 58.465],
        ["Neville", 58.469],
        ["Moreau", 58.472],
        ["Medlin", 58.476],
        ["Ma", 58.479],
        ["Lenz", 58.483],
        ["Knowlton", 58.487],
        ["Fairley", 58.49],
        ["Cobbs", 58.494],
        ["Chisolm", 58.498],
        ["Bannister", 58.501],
        ["Woodworth", 58.505],
        ["Toler", 58.509],
        ["Ocasio", 58.512],
        ["Noriega", 58.516],
        ["Neuman", 58.519],
        ["Moye", 58.523],
        ["Milburn", 58.527],
        ["McClanahan", 58.53],
        ["Lilley", 58.534],
        ["Hanes", 58.538],
        ["Flannery", 58.541],
        ["Dellinger", 58.545],
        ["Danielson", 58.548],
        ["Conti", 58.552],
        ["Blodgett", 58.556],
        ["Beers", 58.559],
        ["Weatherford", 58.563],
        ["Strain", 58.567],
        ["Karr", 58.57],
        ["Hitt", 58.574],
        ["Denham", 58.577],
        ["Custer", 58.581],
        ["Coble", 58.585],
        ["Clough", 58.588],
        ["Casteel", 58.592],
        ["Bolduc", 58.595],
        ["Batchelor", 58.599],
        ["Ammons", 58.603],
        ["Whitlow", 58.606],
        ["Tierney", 58.61],
        ["Staten", 58.613],
        ["Sibley", 58.617],
        ["Seifert", 58.621],
        ["Schubert", 58.624],
        ["Salcedo", 58.628],
        ["Mattison", 58.631],
        ["Laney", 58.635],
        ["Haggard", 58.639],
        ["Grooms", 58.642],
        ["Dix", 58.646],
        ["Dees", 58.649],
        ["Cromer", 58.653],
        ["Cooks", 58.656],
        ["Colson", 58.66],
        ["Caswell", 58.664],
        ["Zarate", 58.667],
        ["Swisher", 58.671],
        ["Stacey", 58.674],
        ["Shin", 58.678],
        ["Ragan", 58.682],
        ["Pridgen", 58.685],
        ["McVey", 58.689],
        ["Matheny", 58.692],
        ["Leigh", 58.696],
        ["Lafleur", 58.699],
        ["Franz", 58.703],
        ["Ferraro", 58.707],
        ["Dugger", 58.71],
        ["Whiteside", 58.714],
        ["Rigsby", 58.717],
        ["McMurray", 58.721],
        ["Lehmann", 58.724],
        ["Large", 58.728],
        ["Jacoby", 58.732],
        ["Hildebrand", 58.735],
        ["Hendrick", 58.739],
        ["Headrick", 58.742],
        ["Goad", 58.746],
        ["Fincher", 58.749],
        ["Drury", 58.753],
        ["Borges", 58.756],
        ["Archibald", 58.76],
        ["Albers", 58.764],
        ["Woodcock", 58.767],
        ["Trapp", 58.771],
        ["Soares", 58.774],
        ["Seaton", 58.778],
        ["Richie", 58.781],
        ["Monson", 58.785],
        ["Luckett", 58.788],
        ["Lindberg", 58.792],
        ["Kopp", 58.795],
        ["Keeton", 58.799],
        ["Hsu", 58.803],
        ["Healey", 58.806],
        ["Garvey", 58.81],
        ["Gaddy", 58.813],
        ["Fain", 58.817],
        ["Burchfield", 58.82],
        ["Badger", 58.824],
        ["Wentworth", 58.827],
        ["Strand", 58.831],
        ["Stack", 58.834],
        ["Spooner", 58.838],
        ["Saucier", 58.841],
        ["Sales", 58.845],
        ["Ruby", 58.849],
        ["Ricci", 58.852],
        ["Plunkett", 58.856],
        ["Pannell", 58.859],
        ["Ness", 58.863],
        ["Leger", 58.866],
        ["Hoy", 58.87],
        ["Freitas", 58.873],
        ["Fong", 58.877],
        ["Elizondo", 58.88],
        ["Duval", 58.884],
        ["Chun", 58.887],
        ["Calvin", 58.891],
        ["Beaudoin", 58.894],
        ["Urbina", 58.898],
        ["Stock", 58.901],
        ["Rickard", 58.905],
        ["Partin", 58.908],
        ["Moe", 58.912],
        ["McGrew", 58.916],
        ["McClintock", 58.919],
        ["Ledoux", 58.923],
        ["Forsyth", 58.926],
        ["Faison", 58.93],
        ["Devries", 58.933],
        ["Bertrand", 58.937],
        ["Wasson", 58.94],
        ["Tilton", 58.944],
        ["Scarbrough", 58.947],
        ["Pride", 58.951],
        ["Oh", 58.954],
        ["Leung", 58.958],
        ["Larry", 58.961],
        ["Irvine", 58.965],
        ["Garber", 58.968],
        ["Denning", 58.972],
        ["Corral", 58.975],
        ["Colley", 58.979],
        ["Castleberry", 58.982],
        ["Bowlin", 58.986],
        ["Bogan", 58.989],
        ["Beale", 58.993],
        ["Baines", 58.996],
        ["True", 59],
        ["Trice", 59.003],
        ["Rayburn", 59.007],
        ["Parkinson", 59.01],
        ["Pak", 59.013],
        ["Nunes", 59.017],
        ["McMillen", 59.02],
        ["Leahy", 59.024],
        ["Lea", 59.027],
        ["Kimmel", 59.031],
        ["Higgs", 59.034],
        ["Fulmer", 59.038],
        ["Carden", 59.041],
        ["Bedford", 59.045],
        ["Taggart", 59.048],
        ["Spearman", 59.052],
        ["Register", 59.055],
        ["Prichard", 59.059],
        ["Morrill", 59.062],
        ["Koonce", 59.066],
        ["Heinz", 59.069],
        ["Hedges", 59.073],
        ["Guenther", 59.076],
        ["Grice", 59.079],
        ["Findley", 59.083],
        ["Earle", 59.086],
        ["Dover", 59.09],
        ["Creighton", 59.093],
        ["Boothe", 59.097],
        ["Bayer", 59.1],
        ["Arreola", 59.104],
        ["Vitale", 59.107],
        ["Valles", 59.111],
        ["See", 59.114],
        ["Raney", 59.118],
        ["Peter", 59.121],
        ["Osgood", 59.124],
        ["Lowell", 59.128],
        ["Hanlon", 59.131],
        ["Burley", 59.135],
        ["Bounds", 59.138],
        ["Worden", 59.142],
        ["Weatherly", 59.145],
        ["Vetter", 59.149],
        ["Tanaka", 59.152],
        ["Stiltner", 59.155],
        ["Sell", 59.159],
        ["Nevarez", 59.162],
        ["Mosby", 59.166],
        ["Montero", 59.169],
        ["Melancon", 59.173],
        ["Harter", 59.176],
        ["Hamer", 59.179],
        ["Goble", 59.183],
        ["Gladden", 59.186],
        ["Gist", 59.19],
        ["Ginn", 59.193],
        ["Akin", 59.197],
        ["Zaragoza", 59.2],
        ["Towns", 59.203],
        ["Tarver", 59.207],
        ["Sammons", 59.21],
        ["Royster", 59.214],
        ["Oreilly", 59.217],
        ["Muir", 59.221],
        ["Morehead", 59.224],
        ["Luster", 59.227],
        ["Kingsley", 59.231],
        ["Kelso", 59.234],
        ["Grisham", 59.238],
        ["Glynn", 59.241],
        ["Baumann", 59.244],
        ["Alves", 59.248],
        ["Yount", 59.251],
        ["Tamayo", 59.255],
        ["Tam", 59.258],
        ["Paterson", 59.261],
        ["Oates", 59.265],
        ["Menendez", 59.268],
        ["Longo", 59.272],
        ["Hargis", 59.275],
        ["Greenlee", 59.278],
        ["Gillen", 59.282],
        ["Desantis", 59.285],
        ["Conover", 59.289],
        ["Breedlove", 59.292],
        ["Wayne", 59.295],
        ["Sumpter", 59.299],
        ["Scherer", 59.302],
        ["Rupp", 59.306],
        ["Reichert", 59.309],
        ["Heredia", 59.312],
        ["Fallon", 59.316],
        ["Creel", 59.319],
        ["Cohn", 59.323],
        ["Clemmons", 59.326],
        ["Casas", 59.329],
        ["Bickford", 59.333],
        ["Belton", 59.336],
        ["Bach", 59.339],
        ["Williford", 59.343],
        ["Whitcomb", 59.346],
        ["Tennant", 59.35],
        ["Sutter", 59.353],
        ["Stull", 59.356],
        ["Sessions", 59.36],
        ["McCallum", 59.363],
        ["Manson", 59.366],
        ["Langlois", 59.37],
        ["Keel", 59.373],
        ["Keegan", 59.377],
        ["Emanuel", 59.38],
        ["Dangelo", 59.383],
        ["Dancy", 59.387],
        ["Damron", 59.39],
        ["Clapp", 59.393],
        ["Clanton", 59.397],
        ["Bankston", 59.4],
        ["Trinidad", 59.404],
        ["Oliveira", 59.407],
        ["Mintz", 59.41],
        ["McInnis", 59.414],
        ["Martens", 59.417],
        ["Mabe", 59.42],
        ["Laster", 59.424],
        ["Jolley", 59.427],
        ["Irish", 59.43],
        ["Hildreth", 59.434],
        ["Hefner", 59.437],
        ["Glaser", 59.44],
        ["Duckett", 59.444],
        ["Demers", 59.447],
        ["Brockman", 59.45],
        ["Blais", 59.454],
        ["Back", 59.457],
        ["Alcorn", 59.461],
        ["Agnew", 59.464],
        ["Toliver", 59.467],
        ["Tice", 59.471],
        ["Song", 59.474],
        ["Seeley", 59.477],
        ["Najera", 59.481],
        ["Musser", 59.484],
        ["McFall", 59.487],
        ["Laplante", 59.491],
        ["Galvin", 59.494],
        ["Fajardo", 59.497],
        ["Doan", 59.501],
        ["Coyne", 59.504],
        ["Copley", 59.507],
        ["Clawson", 59.511],
        ["Cheung", 59.514],
        ["Barone", 59.517],
        ["Wynne", 59.521],
        ["Woodley", 59.524],
        ["Tremblay", 59.527],
        ["Stoll", 59.531],
        ["Sparrow", 59.534],
        ["Sparkman", 59.537],
        ["Schweitzer", 59.541],
        ["Sasser", 59.544],
        ["Samples", 59.547],
        ["Roney", 59.551],
        ["Ramon", 59.554],
        ["Legg", 59.557],
        ["Lai", 59.561],
        ["Joe", 59.564],
        ["Heim", 59.567],
        ["Farias", 59.57],
        ["Concepcion", 59.574],
        ["Colwell", 59.577],
        ["Christman", 59.58],
        ["Bratcher", 59.584],
        ["Alba", 59.587],
        ["Winchester", 59.59],
        ["Upshaw", 59.594],
        ["Southerland", 59.597],
        ["Sorrell", 59.6],
        ["Shay", 59.604],
        ["Sells", 59.607],
        ["Mount", 59.61],
        ["McCloskey", 59.614],
        ["Martindale", 59.617],
        ["Luttrell", 59.62],
        ["Loveless", 59.623],
        ["Lovejoy", 59.627],
        ["Linares", 59.63],
        ["Latimer", 59.633],
        ["Holly", 59.637],
        ["Embry", 59.64],
        ["Coombs", 59.643],
        ["Bratton", 59.647],
        ["Bostick", 59.65],
        ["Boss", 59.653],
        ["Venable", 59.657],
        ["Tuggle", 59.66],
        ["Toro", 59.663],
        ["Staggs", 59.666],
        ["Sandlin", 59.67],
        ["Jefferies", 59.673],
        ["Heckman", 59.676],
        ["Griffis", 59.68],
        ["Crayton", 59.683],
        ["Clem", 59.686],
        ["Button", 59.689],
        ["Browder", 59.693],
        ["Allan", 59.696],
        ["Thorton", 59.699],
        ["Sturgill", 59.703],
        ["Sprouse", 59.706],
        ["Royer", 59.709],
        ["Rousseau", 59.712],
        ["Ridenour", 59.716],
        ["Pogue", 59.719],
        ["Perales", 59.722],
        ["Peeples", 59.725],
        ["Metzler", 59.729],
        ["Mesa", 59.732],
        ["McCutcheon", 59.735],
        ["McBee", 59.739],
        ["Jay", 59.742],
        ["Hornsby", 59.745],
        ["Heffner", 59.748],
        ["Corrigan", 59.752],
        ["Armijo", 59.755],
        ["Vue", 59.758],
        ["Romeo", 59.761],
        ["Plante", 59.765],
        ["Peyton", 59.768],
        ["Paredes", 59.771],
        ["Macklin", 59.774],
        ["Hussey", 59.778],
        ["Hodgson", 59.781],
        ["Granados", 59.784],
        ["Frias", 59.788],
        ["Carman", 59.791],
        ["Brent", 59.794],
        ["Becnel", 59.797],
        ["Batten", 59.801],
        ["Almanza", 59.804],
        ["Turney", 59.807],
        ["Teal", 59.81],
        ["Sturgeon", 59.814],
        ["Meeker", 59.817],
        ["McDaniels", 59.82],
        ["Limon", 59.823],
        ["Keeney", 59.827],
        ["Kee", 59.83],
        ["Hutto", 59.833],
        ["Holguin", 59.836],
        ["Gorham", 59.84],
        ["Fishman", 59.843],
        ["Fierro", 59.846],
        ["Blanchette", 59.849],
        ["Rodrigue", 59.852],
        ["Reddy", 59.856],
        ["Osburn", 59.859],
        ["Oden", 59.862],
        ["Lerma", 59.865],
        ["Kirkwood", 59.869],
        ["Keefer", 59.872],
        ["Haugen", 59.875],
        ["Hammett", 59.878],
        ["Chalmers", 59.882],
        ["Carlos", 59.885],
        ["Brinkman", 59.888],
        ["Baumgartner", 59.891],
        ["Zhang", 59.894],
        ["Valerio", 59.898],
        ["Tellez", 59.901],
        ["Steffen", 59.904],
        ["Shumate", 59.907],
        ["Sauls", 59.91],
        ["Ripley", 59.914],
        ["Kemper", 59.917],
        ["Jacks", 59.92],
        ["Guffey", 59.923],
        ["Evers", 59.927],
        ["Craddock", 59.93],
        ["Carvalho", 59.933],
        ["Blaylock", 59.936],
        ["Banuelos", 59.939],
        ["Balderas", 59.943],
        ["Wooden", 59.946],
        ["Wheaton", 59.949],
        ["Turnbull", 59.952],
        ["Shuman", 59.955],
        ["Pointer", 59.959],
        ["Mosier", 59.962],
        ["McCue", 59.965],
        ["Ligon", 59.968],
        ["Kozlowski", 59.971],
        ["Johansen", 59.975],
        ["Ingle", 59.978],
        ["Herr", 59.981],
        ["Briones", 59.984],
        ["Southern", 59.987],
        ["Snipes", 59.99],
        ["Rickman", 59.994],
        ["Pipkin", 59.997],
        ["Peace", 60],
        ["Pantoja", 60.003],
        ["Orosco", 60.006],
        ["Moniz", 60.01],
        ["Lawless", 60.013],
        ["Kunkel", 60.016],
        ["Hibbard", 60.019],
        ["Galarza", 60.022],
        ["Enos", 60.025],
        ["Bussey", 60.029],
        ["Settle", 60.032],
        ["Schott", 60.035],
        ["Salcido", 60.038],
        ["Perreault", 60.041],
        ["McDougal", 60.044],
        ["McCool", 60.048],
        ["Haight", 60.051],
        ["Garris", 60.054],
        ["Ferry", 60.057],
        ["Easton", 60.06],
        ["Conyers", 60.063],
        ["Atherton", 60.067],
        ["Wimberly", 60.07],
        ["Utley", 60.073],
        ["Stephen", 60.076],
        ["Spellman", 60.079],
        ["Smithson", 60.082],
        ["Slagle", 60.085],
        ["Skipper", 60.089],
        ["Ritchey", 60.092],
        ["Rand", 60.095],
        ["Petit", 60.098],
        ["Osullivan", 60.101],
        ["Oaks", 60.104],
        ["Nutt", 60.108],
        ["McVay", 60.111],
        ["McCreary", 60.114],
        ["Mayhew", 60.117],
        ["Knoll", 60.12],
        ["Jewett", 60.123],
        ["Harwood", 60.126],
        ["Hailey", 60.13],
        ["Cardoza", 60.133],
        ["Ashe", 60.136],
        ["Arriaga", 60.139],
        ["Andres", 60.142],
        ["Zeller", 60.145],
        ["Wirth", 60.148],
        ["Whitmire", 60.152],
        ["Stauffer", 60.155],
        ["Spring", 60.158],
        ["Rountree", 60.161],
        ["Redden", 60.164],
        ["McCaffrey", 60.167],
        ["Martz", 60.17],
        ["Loving", 60.173],
        ["Larose", 60.177],
        ["Langdon", 60.18],
        ["Humes", 60.183],
        ["Gaskin", 60.186],
        ["Faber", 60.189],
        ["Doll", 60.192],
        ["Devito", 60.195],
        ["Cass", 60.199],
        ["Almond", 60.202],
        ["Wingfield", 60.205],
        ["Wingate", 60.208],
        ["Villareal", 60.211],
        ["Tyner", 60.214],
        ["Smothers", 60.217],
        ["Severson", 60.22],
        ["Reno", 60.223],
        ["Pennell", 60.227],
        ["Maupin", 60.23],
        ["Leighton", 60.233],
        ["Janssen", 60.236],
        ["Hassell", 60.239],
        ["Hallman", 60.242],
        ["Halcomb", 60.245],
        ["Folse", 60.248],
        ["Fitzsimmons", 60.251],
        ["Fahey", 60.255],
        ["Cranford", 60.258],
        ["Bolen", 60.261],
        ["Battles", 60.264],
        ["Battaglia", 60.267],
        ["Wooldridge", 60.27],
        ["Weed", 60.273],
        ["Trask", 60.276],
        ["Rosser", 60.279],
        ["Regalado", 60.283],
        ["McEwen", 60.286],
        ["Keefe", 60.289],
        ["Fuqua", 60.292],
        ["Echevarria", 60.295],
        ["Domingo", 60.298],
        ["Dang", 60.301],
        ["Caro", 60.304],
        ["Boynton", 60.307],
        ["Andrus", 60.31],
        ["Wild", 60.314],
        ["Viera", 60.317],
        ["Van Meter", 60.32],
        ["Taber", 60.323],
        ["Spradlin", 60.326],
        ["Seibert", 60.329],
        ["Provost", 60.332],
        ["Prentice", 60.335],
        ["Oliphant", 60.338],
        ["Laporte", 60.341],
        ["Hwang", 60.344],
        ["Hatchett", 60.347],
        ["Hass", 60.351],
        ["Greiner", 60.354],
        ["Freedman", 60.357],
        ["Covert", 60.36],
        ["Chilton", 60.363],
        ["Byars", 60.366],
        ["Wiese", 60.369],
        ["Venegas", 60.372],
        ["Swank", 60.375],
        ["Shrader", 60.378],
        ["Roderick", 60.381],
        ["Roberge", 60.384],
        ["Mullis", 60.387],
        ["Mortensen", 60.391],
        ["McCune", 60.394],
        ["Marlowe", 60.397],
        ["Kirchner", 60.4],
        ["Keck", 60.403],
        ["Isaacson", 60.406],
        ["Hostetler", 60.409],
        ["Halverson", 60.412],
        ["Gunther", 60.415],
        ["Griswold", 60.418],
        ["Gerard", 60.421],
        ["Fenner", 60.424],
        ["Durden", 60.427],
        ["Blackwood", 60.43],
        ["Bertram", 60.433],
        ["Ahrens", 60.437],
        ["Sawyers", 60.44],
        ["Savoy", 60.443],
        ["Nabors", 60.446],
        ["McSwain", 60.449],
        ["Mackay", 60.452],
        ["Loy", 60.455],
        ["Lavender", 60.458],
        ["Lash", 60.461],
        ["Labbe", 60.464],
        ["Jessup", 60.467],
        ["Hubert", 60.47],
        ["Fullerton", 60.473],
        ["Donnell", 60.476],
        ["Cruse", 60.479],
        ["Crittenden", 60.482],
        ["Correia", 60.485],
        ["Centeno", 60.488],
        ["Caudle", 60.491],
        ["Canady", 60.495],
        ["Callender", 60.498],
        ["Alarcon", 60.501],
        ["Ahern", 60.504],
        ["Winfrey", 60.507],
        ["Tribble", 60.51],
        ["Tom", 60.513],
        ["Styles", 60.516],
        ["Salley", 60.519],
        ["Roden", 60.522],
        ["Musgrove", 60.525],
        ["Minnick", 60.528],
        ["Fortenberry", 60.531],
        ["Carrion", 60.534],
        ["Bunting", 60.537],
        ["Bethel", 60.54],
        ["Batiste", 60.543],
        ["Woo", 60.546],
        ["Whited", 60.549],
        ["Underhill", 60.552],
        ["Stillwell", 60.555],
        ["Silvia", 60.558],
        ["Rauch", 60.561],
        ["Pippin", 60.564],
        ["Perrin", 60.567],
        ["Messenger", 60.57],
        ["Mancini", 60.573],
        ["Lister", 60.576],
        ["Kinard", 60.579],
        ["Hartmann", 60.582],
        ["Fleck", 60.585],
        ["Broadway", 60.588],
        ["Wilt", 60.591],
        ["Treadway", 60.594],
        ["Thornhill", 60.598],
        ["Speed", 60.601],
        ["Spalding", 60.604],
        ["Sam", 60.607],
        ["Rafferty", 60.61],
        ["Pitre", 60.613],
        ["Patino", 60.616],
        ["Ordonez", 60.619],
        ["Linkous", 60.622],
        ["Kelleher", 60.625],
        ["Homan", 60.628],
        ["Holiday", 60.631],
        ["Galbraith", 60.634],
        ["Feeney", 60.637],
        ["Dorris", 60.64],
        ["Curtin", 60.643],
        ["Coward", 60.646],
        ["Camarillo", 60.649],
        ["Buss", 60.652],
        ["Bunnell", 60.655],
        ["Bolt", 60.658],
        ["Beeler", 60.661],
        ["Autry", 60.664],
        ["Alcala", 60.667],
        ["Witte", 60.67],
        ["Wentz", 60.673],
        ["Stidham", 60.676],
        ["Shively", 60.679],
        ["Nunley", 60.682],
        ["Meacham", 60.685],
        ["Martins", 60.688],
        ["Lemke", 60.691],
        ["Lefebvre", 60.694],
        ["Kaye", 60.696],
        ["Hynes", 60.699],
        ["Horowitz", 60.702],
        ["Hoppe", 60.705],
        ["Holcombe", 60.708],
        ["Estrella", 60.711],
        ["Dunne", 60.714],
        ["Derr", 60.717],
        ["Cochrane", 60.72],
        ["Brittain", 60.723],
        ["Bedard", 60.726],
        ["Beauregard", 60.729],
        ["Torrence", 60.732],
        ["Strunk", 60.735],
        ["Soria", 60.738],
        ["Simonson", 60.741],
        ["Shumaker", 60.744],
        ["Scoggins", 60.747],
        ["Packer", 60.75],
        ["Oconner", 60.753],
        ["Moriarty", 60.756],
        ["Leroy", 60.759],
        ["Kuntz", 60.762],
        ["Ives", 60.765],
        ["Hutcheson", 60.768],
        ["Horan", 60.771],
        ["Hales", 60.774],
        ["Garmon", 60.777],
        ["Fitts", 60.78],
        ["Dell", 60.783],
        ["Bohn", 60.786],
        ["Atchison", 60.789],
        ["Worth", 60.792],
        ["Wisniewski", 60.795],
        ["Will", 60.798],
        ["Van Winkle", 60.801],
        ["Sturm", 60.804],
        ["Sallee", 60.807],
        ["Prosser", 60.81],
        ["Moen", 60.812],
        ["Lundberg", 60.815],
        ["Kunz", 60.818],
        ["Kohl", 60.821],
        ["Keane", 60.824],
        ["Jorgenson", 60.827],
        ["Jaynes", 60.83],
        ["Funderburk", 60.833],
        ["Freed", 60.836],
        ["Frame", 60.839],
        ["Durr", 60.842],
        ["Creamer", 60.845],
        ["Cosgrove", 60.848],
        ["Candelaria", 60.851],
        ["Berlin", 60.854],
        ["Batson", 60.857],
        ["Van Hoose", 60.86],
        ["Thomsen", 60.863],
        ["Teeter", 60.866],
        ["Sommer", 60.869],
        ["Smyth", 60.872],
        ["Sena", 60.874],
        ["Redmon", 60.877],
        ["Orellana", 60.88],
        ["Maness", 60.883],
        ["Lennon", 60.886],
        ["Heflin", 60.889],
        ["Goulet", 60.892],
        ["Frick", 60.895],
        ["Forney", 60.898],
        ["Dollar", 60.901],
        ["Bunker", 60.904],
        ["Asbury", 60.907],
        ["Aguiar", 60.91],
        ["Talbott", 60.913],
        ["Southard", 60.916],
        ["Pleasant", 60.919],
        ["Mowery", 60.921],
        ["Mears", 60.924],
        ["Lemmon", 60.927],
        ["Krieger", 60.93],
        ["Hickson", 60.933],
        ["Gracia", 60.936],
        ["Elston", 60.939],
        ["Duong", 60.942],
        ["Delgadillo", 60.945],
        ["Dayton", 60.948],
        ["Dasilva", 60.951],
        ["Conaway", 60.954],
        ["Catron", 60.957],
        ["Bruton", 60.96],
        ["Bradbury", 60.962],
        ["Bordelon", 60.965],
        ["Bivins", 60.968],
        ["Bittner", 60.971],
        ["Bergstrom", 60.974],
        ["Beals", 60.977],
        ["Abell", 60.98],
        ["Whelan", 60.983],
        ["Travers", 60.986],
        ["Tejada", 60.989],
        ["Pulley", 60.992],
        ["Pino", 60.995],
        ["Norfleet", 60.997],
        ["Nealy", 61],
        ["Maes", 61.003],
        ["Loper", 61.006],
        ["Held", 61.009],
        ["Gerald", 61.012],
        ["Gatewood", 61.015],
        ["Frierson", 61.018],
        ["Freund", 61.021],
        ["Finnegan", 61.024],
        ["Cupp", 61.027],
        ["Covey", 61.029],
        ["Catalano", 61.032],
        ["Boehm", 61.035],
        ["Bader", 61.038],
        ["Yoon", 61.041],
        ["Walston", 61.044],
        ["Tenney", 61.047],
        ["Sipes", 61.05],
        ["Roller", 61.053],
        ["Rawlins", 61.056],
        ["Medlock", 61.058],
        ["McCaskill", 61.061],
        ["McCallister", 61.064],
        ["Marcotte", 61.067],
        ["MacLean", 61.07],
        ["Hughey", 61.073],
        ["Henke", 61.076],
        ["Harwell", 61.079],
        ["Gladney", 61.082],
        ["Gilson", 61.084],
        ["Dew", 61.087],
        ["Chism", 61.09],
        ["Caskey", 61.093],
        ["Brandenburg", 61.096],
        ["Baylor", 61.099],
        ["Villasenor", 61.102],
        ["Veal", 61.105],
        ["Van", 61.108],
        ["Thatcher", 61.11],
        ["Stegall", 61.113],
        ["Shore", 61.116],
        ["Petrie", 61.119],
        ["Nowlin", 61.122],
        ["Navarrete", 61.125],
        ["Muhammad", 61.128],
        ["Lombard", 61.131],
        ["Loftin", 61.133],
        ["Lemaster", 61.136],
        ["Kroll", 61.139],
        ["Kovach", 61.142],
        ["Kimbrell", 61.145],
        ["Kidwell", 61.148],
        ["Hershberger", 61.151],
        ["Fulcher", 61.154],
        ["Eng", 61.156],
        ["Cantwell", 61.159],
        ["Bustos", 61.162],
        ["Boland", 61.165],
        ["Bobbitt", 61.168],
        ["Binkley", 61.171],
        ["Wester", 61.174],
        ["Weis", 61.177],
        ["Verdin", 61.179],
        ["Tong", 61.182],
        ["Tiller", 61.185],
        ["Sisco", 61.188],
        ["Sharkey", 61.191],
        ["Seymore", 61.194],
        ["Rosenbaum", 61.197],
        ["Rohr", 61.199],
        ["Quinonez", 61.202],
        ["Pinkston", 61.205],
        ["Nation", 61.208],
        ["Malley", 61.211],
        ["Logue", 61.214],
        ["Lessard", 61.217],
        ["Lerner", 61.22],
        ["Lebron", 61.222],
        ["Krauss", 61.225],
        ["Klinger", 61.228],
        ["Halstead", 61.231],
        ["Haller", 61.234],
        ["Getz", 61.237],
        ["Burrow", 61.24],
        ["Brant", 61.242],
        ["Alger", 61.245],
        ["Victor", 61.248],
        ["Shores", 61.251],
        ["Scully", 61.254],
        ["Pounds", 61.257],
        ["Pfeifer", 61.259],
        ["Perron", 61.262],
        ["Nelms", 61.265],
        ["Munn", 61.268],
        ["McMaster", 61.271],
        ["McKenney", 61.274],
        ["Manns", 61.277],
        ["Knudson", 61.279],
        ["Hutchens", 61.282],
        ["Huskey", 61.285],
        ["Goebel", 61.288],
        ["Flagg", 61.291],
        ["Cushman", 61.294],
        ["Click", 61.296],
        ["Castellano", 61.299],
        ["Carder", 61.302],
        ["Bumgarner", 61.305],
        ["Blaine", 61.308],
        ["Bible", 61.311],
        ["Wampler", 61.314],
        ["Spinks", 61.316],
        ["Robson", 61.319],
        ["Neel", 61.322],
        ["McReynolds", 61.325],
        ["Mathias", 61.328],
        ["Maas", 61.331],
        ["Loera", 61.333],
        ["Kasper", 61.336],
        ["Jose", 61.339],
        ["Jenson", 61.342],
        ["Florez", 61.345],
        ["Coons", 61.348],
        ["Buckingham", 61.35],
        ["Brogan", 61.353],
        ["Berryman", 61.356],
        ["Wilmoth", 61.359],
        ["Wilhite", 61.362],
        ["Thrash", 61.364],
        ["Shephard", 61.367],
        ["Seidel", 61.37],
        ["Schulze", 61.373],
        ["Roldan", 61.376],
        ["Pettis", 61.379],
        ["Obryan", 61.381],
        ["Maki", 61.384],
        ["Mackie", 61.387],
        ["Hatley", 61.39],
        ["Frazer", 61.393],
        ["Fiore", 61.395],
        ["Falls", 61.398],
        ["Chesser", 61.401],
        ["Bui", 61.404],
        ["Bottoms", 61.407],
        ["Bisson", 61.409],
        ["Benefield", 61.412],
        ["Allman", 61.415],
        ["Wilke", 61.418],
        ["Trudeau", 61.421],
        ["Timm", 61.423],
        ["Shifflett", 61.426],
        ["Rau", 61.429],
        ["Mundy", 61.432],
        ["Milliken", 61.435],
        ["Mayers", 61.437],
        ["Leake", 61.44],
        ["Kohn", 61.443],
        ["Huntington", 61.446],
        ["Horsley", 61.449],
        ["Hermann", 61.451],
        ["Guerin", 61.454],
        ["Fryer", 61.457],
        ["Frizzell", 61.46],
        ["Foret", 61.463],
        ["Flemming", 61.465],
        ["Fife", 61.468],
        ["Criswell", 61.471],
        ["Carbajal", 61.474],
        ["Bozeman", 61.477],
        ["Boisvert", 61.479],
        ["Archie", 61.482],
        ["Antonio", 61.485],
        ["Angulo", 61.488],
        ["Wallen", 61.491],
        ["Tapp", 61.493],
        ["Silvers", 61.496],
        ["Ramsay", 61.499],
        ["Oshea", 61.502],
        ["Orta", 61.505],
        ["Moll", 61.507],
        ["McKeever", 61.51],
        ["McGehee", 61.513],
        ["Luciano", 61.516],
        ["Linville", 61.518],
        ["Kiefer", 61.521],
        ["Ketchum", 61.524],
        ["Howerton", 61.527],
        ["Groce", 61.53],
        ["Gaylord", 61.532],
        ["Gass", 61.535],
        ["Fusco", 61.538],
        ["Corbitt", 61.541],
        ["Blythe", 61.543],
        ["Betz", 61.546],
        ["Bartels", 61.549],
        ["Amaral", 61.552],
        ["Aiello", 61.555],
        ["Yoo", 61.557],
        ["Weddle", 61.56],
        ["Troy", 61.563],
        ["Sun", 61.566],
        ["Sperry", 61.568],
        ["Seiler", 61.571],
        ["Runyan", 61.574],
        ["Raley", 61.577],
        ["Overby", 61.579],
        ["Osteen", 61.582],
        ["Olds", 61.585],
        ["McKeown", 61.588],
        ["Mauro", 61.591],
        ["Matney", 61.593],
        ["Lauer", 61.596],
        ["Lattimore", 61.599],
        ["Hindman", 61.602],
        ["Hartwell", 61.604],
        ["Fredrickson", 61.607],
        ["Fredericks", 61.61],
        ["Espino", 61.613],
        ["Clegg", 61.615],
        ["Carswell", 61.618],
        ["Cambell", 61.621],
        ["Burkholder", 61.624],
        ["August", 61.627],
        ["Woodbury", 61.629],
        ["Welker", 61.632],
        ["Totten", 61.635],
        ["Thornburg", 61.638],
        ["Theriault", 61.64],
        ["Stitt", 61.643],
        ["Stamm", 61.646],
        ["Stackhouse", 61.649],
        ["Simone", 61.651],
        ["Scholl", 61.654],
        ["Saxon", 61.657],
        ["Rife", 61.66],
        ["Razo", 61.662],
        ["Quinlan", 61.665],
        ["Pinkerton", 61.668],
        ["Olivo", 61.671],
        ["Nesmith", 61.673],
        ["Nall", 61.676],
        ["Mattos", 61.679],
        ["Leak", 61.682],
        ["Lafferty", 61.684],
        ["Justus", 61.687],
        ["Giron", 61.69],
        ["Geer", 61.693],
        ["Fielder", 61.695],
        ["Eagle", 61.698],
        ["Drayton", 61.701],
        ["Dortch", 61.704],
        ["Conners", 61.706],
        ["Conger", 61.709],
        ["Chau", 61.712],
        ["Boatwright", 61.715],
        ["Billiot", 61.717],
        ["Barden", 61.72],
        ["Armenta", 61.723],
        ["Antoine", 61.726],
        ["Tibbetts", 61.728],
        ["Steadman", 61.731],
        ["Slattery", 61.734],
        ["Sides", 61.736],
        ["Rinaldi", 61.739],
        ["Raynor", 61.742],
        ["Rayford", 61.745],
        ["Pinckney", 61.747],
        ["Pettigrew", 61.75],
        ["Nickel", 61.753],
        ["Milne", 61.756],
        ["Matteson", 61.758],
        ["Halsey", 61.761],
        ["Gonsalves", 61.764],
        ["Fellows", 61.767],
        ["Durand", 61.769],
        ["Desimone", 61.772],
        ["Cowley", 61.775],
        ["Cowles", 61.777],
        ["Brill", 61.78],
        ["Barham", 61.783],
        ["Barela", 61.786],
        ["Barba", 61.788],
        ["Ashmore", 61.791],
        ["Withrow", 61.794],
        ["Valenti", 61.797],
        ["Tejeda", 61.799],
        ["Spriggs", 61.802],
        ["Sayre", 61.805],
        ["Salerno", 61.807],
        ["Place", 61.81],
        ["Peltier", 61.813],
        ["Peel", 61.816],
        ["Merriman", 61.818],
        ["Matheson", 61.821],
        ["Lowman", 61.824],
        ["Lindstrom", 61.826],
        ["Hyland", 61.829],
        ["Homer", 61.832],
        ["Ha", 61.835],
        ["Giroux", 61.837],
        ["Fries", 61.84],
        ["Frasier", 61.843],
        ["Earls", 61.846],
        ["Dugas", 61.848],
        ["Damon", 61.851],
        ["Dabney", 61.854],
        ["Collado", 61.856],
        ["Briseno", 61.859],
        ["Baxley", 61.862],
        ["Andre", 61.865],
        ["Word", 61.867],
        ["Whyte", 61.87],
        ["Wenger", 61.873],
        ["Vanover", 61.875],
        ["Van Buren", 61.878],
        ["Thiel", 61.881],
        ["Schindler", 61.883],
        ["Schiller", 61.886],
        ["Rigby", 61.889],
        ["Pomeroy", 61.892],
        ["Passmore", 61.894],
        ["Marble", 61.897],
        ["Manzo", 61.9],
        ["Mahaffey", 61.902],
        ["Lindgren", 61.905],
        ["Laflamme", 61.908],
        ["Greathouse", 61.911],
        ["Fite", 61.913],
        ["Ferrari", 61.916],
        ["Calabrese", 61.919],
        ["Bayne", 61.921],
        ["Yamamoto", 61.924],
        ["Wick", 61.927],
        ["Townes", 61.929],
        ["Thames", 61.932],
        ["Steel", 61.935],
        ["Reinhart", 61.937],
        ["Peeler", 61.94],
        ["Naranjo", 61.943],
        ["Montez", 61.945],
        ["McDade", 61.948],
        ["Mast", 61.951],
        ["Markley", 61.954],
        ["Marchand", 61.956],
        ["Leeper", 61.959],
        ["Kong", 61.962],
        ["Kellum", 61.964],
        ["Hudgens", 61.967],
        ["Hennessey", 61.97],
        ["Hadden", 61.972],
        ["Guess", 61.975],
        ["Gainey", 61.978],
        ["Coppola", 61.98],
        ["Borrego", 61.983],
        ["Bolling", 61.986],
        ["Beane", 61.988],
        ["Ault", 61.991],
        ["Slaton", 61.994],
        ["Poland", 61.997],
        ["Pape", 61.999],
        ["Null", 62.002],
        ["Mulkey", 62.005],
        ["Lightner", 62.007],
        ["Langer", 62.01],
        ["Hillard", 62.013],
        ["Glasgow", 62.015],
        ["Fabian", 62.018],
        ["Ethridge", 62.021],
        ["Enright", 62.023],
        ["Derosa", 62.026],
        ["Baskin", 62.029],
        ["Alfred", 62.031],
        ["Weinberg", 62.034],
        ["Turman", 62.037],
        ["Tinker", 62.039],
        ["Somerville", 62.042],
        ["Pardo", 62.045],
        ["Noll", 62.047],
        ["Lashley", 62.05],
        ["Ingraham", 62.052],
        ["Hiller", 62.055],
        ["Hendon", 62.058],
        ["Glaze", 62.06],
        ["Flora", 62.063],
        ["Cothran", 62.066],
        ["Cooksey", 62.068],
        ["Conte", 62.071],
        ["Carrico", 62.074],
        ["Apple", 62.076],
        ["Abner", 62.079],
        ["Wooley", 62.082],
        ["Swope", 62.084],
        ["Summerlin", 62.087],
        ["Sturgis", 62.09],
        ["Sturdivant", 62.092],
        ["Stott", 62.095],
        ["Spurgeon", 62.097],
        ["Spillman", 62.1],
        ["Speight", 62.103],
        ["Roussel", 62.105],
        ["Popp", 62.108],
        ["Nutter", 62.111],
        ["McKeon", 62.113],
        ["Mazza", 62.116],
        ["Magnuson", 62.119],
        ["Lanning", 62.121],
        ["Kozak", 62.124],
        ["Jankowski", 62.127],
        ["Heyward", 62.129],
        ["Forster", 62.132],
        ["Corwin", 62.134],
        ["Callaghan", 62.137],
        ["Bays", 62.14],
        ["Wortham", 62.142],
        ["Usher", 62.145],
        ["Theriot", 62.148],
        ["Sayers", 62.15],
        ["Sabo", 62.153],
        ["Rupert", 62.155],
        ["Poling", 62.158],
        ["Nathan", 62.161],
        ["Loya", 62.163],
        ["Lieberman", 62.166],
        ["Levi", 62.169],
        ["Laroche", 62.171],
        ["Labelle", 62.174],
        ["Howes", 62.176],
        ["Harr", 62.179],
        ["Garay", 62.182],
        ["Fogarty", 62.184],
        ["Everson", 62.187],
        ["Durkin", 62.19],
        ["Dominquez", 62.192],
        ["Chaves", 62.195],
        ["Chambliss", 62.197],
        ["Alfonso", 62.2],
        ["Witcher", 62.203],
        ["Wilber", 62.205],
        ["Vieira", 62.208],
        ["Vandiver", 62.21],
        ["Terrill", 62.213],
        ["Stoker", 62.216],
        ["Schreiner", 62.218],
        ["Nestor", 62.221],
        ["Moorman", 62.224],
        ["Liddell", 62.226],
        ["Lew", 62.229],
        ["Lawhorn", 62.231],
        ["Krug", 62.234],
        ["Irons", 62.237],
        ["Hylton", 62.239],
        ["Hollenbeck", 62.242],
        ["Herrin", 62.244],
        ["Hembree", 62.247],
        ["Hair", 62.25],
        ["Goolsby", 62.252],
        ["Goodin", 62.255],
        ["Gilmer", 62.257],
        ["Foltz", 62.26],
        ["Dinkins", 62.263],
        ["Daughtry", 62.265],
        ["Caban", 62.268],
        ["Brim", 62.27],
        ["Briley", 62.273],
        ["Bilodeau", 62.276],
        ["Bear", 62.278],
        ["Wyant", 62.281],
        ["Vergara", 62.283],
        ["Tallent", 62.286],
        ["Swearingen", 62.289],
        ["Stroup", 62.291],
        ["Sherry", 62.294],
        ["Scribner", 62.296],
        ["Roger", 62.299],
        ["Quillen", 62.302],
        ["Pitman", 62.304],
        ["Monaco", 62.307],
        ["McCants", 62.309],
        ["Maxfield", 62.312],
        ["Martinson", 62.315],
        ["Landon", 62.317],
        ["Holtz", 62.32],
        ["Flournoy", 62.322],
        ["Brookins", 62.325],
        ["Brody", 62.327],
        ["Baumgardner", 62.33],
        ["Angelo", 62.333],
        ["Straub", 62.335],
        ["Sills", 62.338],
        ["Roybal", 62.34],
        ["Roundtree", 62.343],
        ["Oswalt", 62.346],
        ["Money", 62.348],
        ["McGriff", 62.351],
        ["McDougall", 62.353],
        ["McCleary", 62.356],
        ["Maggard", 62.358],
        ["Gragg", 62.361],
        ["Gooding", 62.364],
        ["Godinez", 62.366],
        ["Doolittle", 62.369],
        ["Donato", 62.371],
        ["Cowell", 62.374],
        ["Cassell", 62.376],
        ["Bracken", 62.379],
        ["Appel", 62.382],
        ["Ahmad", 62.384],
        ["Zambrano", 62.387],
        ["Reuter", 62.389],
        ["Perea", 62.392],
        ["Olive", 62.394],
        ["Nakamura", 62.397],
        ["Monaghan", 62.4],
        ["Mickens", 62.402],
        ["McClinton", 62.405],
        ["McClary", 62.407],
        ["Marler", 62.41],
        ["Kish", 62.412],
        ["Judkins", 62.415],
        ["Gilbreath", 62.417],
        ["Freese", 62.42],
        ["Flanigan", 62.423],
        ["Felts", 62.425],
        ["Erdmann", 62.428],
        ["Dodds", 62.43],
        ["Chew", 62.433],
        ["Brownell", 62.435],
        ["Brazil", 62.438],
        ["Boatright", 62.44],
        ["Barreto", 62.443],
        ["Slayton", 62.446],
        ["Sandberg", 62.448],
        ["Saldivar", 62.451],
        ["Pettway", 62.453],
        ["Odum", 62.456],
        ["Narvaez", 62.458],
        ["Moultrie", 62.461],
        ["Montemayor", 62.463],
        ["Merrell", 62.466],
        ["Lees", 62.468],
        ["Keyser", 62.471],
        ["Hoke", 62.474],
        ["Hardaway", 62.476],
        ["Hannan", 62.479],
        ["Gilbertson", 62.481],
        ["Fogg", 62.484],
        ["Dumont", 62.486],
        ["Deberry", 62.489],
        ["Coggins", 62.491],
        ["Carrera", 62.494],
        ["Buxton", 62.496],
        ["Bucher", 62.499],
        ["Broadnax", 62.502],
        ["Beeson", 62.504],
        ["Araujo", 62.507],
        ["Appleton", 62.509],
        ["Amundson", 62.512],
        ["Aguayo", 62.514],
        ["Ackley", 62.517],
        ["Yocum", 62.519],
        ["Worsham", 62.522],
        ["Shivers", 62.524],
        ["Shelly", 62.527],
        ["Sanches", 62.529],
        ["Sacco", 62.532],
        ["Robey", 62.535],
        ["Rhoden", 62.537],
        ["Pender", 62.54],
        ["Ochs", 62.542],
        ["McCurry", 62.545],
        ["Madera", 62.547],
        ["Luong", 62.55],
        ["Luis", 62.552],
        ["Knotts", 62.555],
        ["Jackman", 62.557],
        ["Heinrich", 62.56],
        ["Hargrave", 62.562],
        ["Gault", 62.565],
        ["Forest", 62.567],
        ["Comeaux", 62.57],
        ["Chitwood", 62.572],
        ["Child", 62.575],
        ["Caraway", 62.577],
        ["Boettcher", 62.58],
        ["Bernhardt", 62.583],
        ["Barrientos", 62.585],
        ["Zink", 62.588],
        ["Wickham", 62.59],
        ["Whiteman", 62.593],
        ["Thorp", 62.595],
        ["Stillman", 62.598],
        ["Settles", 62.6],
        ["Schoonover", 62.603],
        ["Roque", 62.605],
        ["Riddell", 62.608],
        ["Rey", 62.61],
        ["Pilcher", 62.613],
        ["Phifer", 62.615],
        ["Novotny", 62.618],
        ["Maple", 62.62],
        ["MacLeod", 62.623],
        ["Hardee", 62.625],
        ["Haase", 62.628],
        ["Grider", 62.63],
        ["Fredrick", 62.633],
        ["Earnest", 62.635],
        ["Doucette", 62.638],
        ["Clausen", 62.64],
        ["Christmas", 62.643],
        ["Bevins", 62.645],
        ["Beamon", 62.648],
        ["Badillo", 62.65],
        ["Tolley", 62.653],
        ["Tindall", 62.655],
        ["Soule", 62.658],
        ["Snook", 62.66],
        ["Sebastian", 62.663],
        ["Seale", 62.665],
        ["Pitcher", 62.668],
        ["Pinkney", 62.67],
        ["Pellegrino", 62.673],
        ["Nowell", 62.675],
        ["Nemeth", 62.678],
        ["Nail", 62.68],
        ["Mondragon", 62.683],
        ["McLane", 62.685],
        ["Lundgren", 62.688],
        ["Ingalls", 62.69],
        ["Hudspeth", 62.693],
        ["Hixson", 62.695],
        ["Gearhart", 62.698],
        ["Furlong", 62.7],
        ["Downes", 62.703],
        ["Dionne", 62.705],
        ["Dibble", 62.708],
        ["Deyoung", 62.71],
        ["Cornejo", 62.713],
        ["Camara", 62.715],
        ["Brookshire", 62.718],
        ["Boyette", 62.72],
        ["Wolcott", 62.723],
        ["Tracey", 62.725],
        ["Surratt", 62.728],
        ["Sellars", 62.73],
        ["Segal", 62.733],
        ["Salyer", 62.735],
        ["Reeve", 62.738],
        ["Rausch", 62.74],
        ["Philips", 62.743],
        ["Labonte", 62.745],
        ["Haro", 62.748],
        ["Gower", 62.75],
        ["Freeland", 62.753],
        ["Fawcett", 62.755],
        ["Eads", 62.757],
        ["Driggers", 62.76],
        ["Donley", 62.762],
        ["Collett", 62.765],
        ["Cage", 62.767],
        ["Bromley", 62.77],
        ["Boatman", 62.772],
        ["Ballinger", 62.775],
        ["Baldridge", 62.777],
        ["Volz", 62.78],
        ["Trombley", 62.782],
        ["Stonge", 62.785],
        ["Silas", 62.787],
        ["Shanahan", 62.79],
        ["Rivard", 62.792],
        ["Rhyne", 62.795],
        ["Pedroza", 62.797],
        ["Matias", 62.799],
        ["Mallard", 62.802],
        ["Jamieson", 62.804],
        ["Hedgepeth", 62.807],
        ["Hartnett", 62.809],
        ["Estevez", 62.812],
        ["Eskridge", 62.814],
        ["Denman", 62.817],
        ["Chiu", 62.819],
        ["Chinn", 62.822],
        ["Catlett", 62.824],
        ["Carmack", 62.827],
        ["Buie", 62.829],
        ["Book", 62.832],
        ["Bechtel", 62.834],
        ["Beardsley", 62.836],
        ["Bard", 62.839],
        ["Ballou", 62.841],
        ["Windsor", 62.844],
        ["Ulmer", 62.846],
        ["Storm", 62.849],
        ["Skeen", 62.851],
        ["Robledo", 62.854],
        ["Rincon", 62.856],
        ["Reitz", 62.859],
        ["Piazza", 62.861],
        ["Pearl", 62.863],
        ["Munger", 62.866],
        ["Moten", 62.868],
        ["McMichael", 62.871],
        ["Loftus", 62.873],
        ["Ledet", 62.876],
        ["Kersey", 62.878],
        ["Groff", 62.881],
        ["Fowlkes", 62.883],
        ["Folk", 62.885],
        ["Crumpton", 62.888],
        ["Collette", 62.89],
        ["Clouse", 62.893],
        ["Bettis", 62.895],
        ["Villagomez", 62.898],
        ["Timmerman", 62.9],
        ["Strom", 62.903],
        ["Saul", 62.905],
        ["Santoro", 62.907],
        ["Roddy", 62.91],
        ["Phillip", 62.912],
        ["Penrod", 62.915],
        ["Musselman", 62.917],
        ["MacPherson", 62.92],
        ["Leboeuf", 62.922],
        ["Harless", 62.924],
        ["Haddad", 62.927],
        ["Guido", 62.929],
        ["Golding", 62.932],
        ["Fulkerson", 62.934],
        ["Fannin", 62.937],
        ["Dulaney", 62.939],
        ["Dowdell", 62.941],
        ["Deane", 62.944],
        ["Cottle", 62.946],
        ["Ceja", 62.949],
        ["Cate", 62.951],
        ["Bosley", 62.954],
        ["Benge", 62.956],
        ["Albritton", 62.958],
        ["Voigt", 62.961],
        ["Trowbridge", 62.963],
        ["Soileau", 62.966],
        ["Seely", 62.968],
        ["Rome", 62.971],
        ["Rohde", 62.973],
        ["Pearsall", 62.975],
        ["Paulk", 62.978],
        ["Orth", 62.98],
        ["Nason", 62.983],
        ["Mota", 62.985],
        ["McMullin", 62.987],
        ["Marquardt", 62.99],
        ["Madigan", 62.992],
        ["Hoag", 62.995],
        ["Gillum", 62.997],
        ["Gayle", 63],
        ["Gabbard", 63.002],
        ["Fenwick", 63.004],
        ["Fender", 63.007],
        ["Eck", 63.009],
        ["Danforth", 63.012],
        ["Cushing", 63.014],
        ["Cress", 63.016],
        ["Creed", 63.019],
        ["Cazares", 63.021],
        ["Casanova", 63.024],
        ["Bey", 63.026],
        ["Bettencourt", 63.029],
        ["Barringer", 63.031],
        ["Baber", 63.033],
        ["Stansberry", 63.036],
        ["Schramm", 63.038],
        ["Rutter", 63.041],
        ["Rivero", 63.043],
        ["Race", 63.045],
        ["Oquendo", 63.048],
        ["Necaise", 63.05],
        ["Mouton", 63.053],
        ["Montenegro", 63.055],
        ["Miley", 63.057],
        ["McGough", 63.06],
        ["Marra", 63.062],
        ["MacMillan", 63.065],
        ["Lock", 63.067],
        ["Lamontagne", 63.069],
        ["Jasso", 63.072],
        ["Jaime", 63.074],
        ["Horst", 63.077],
        ["Hetrick", 63.079],
        ["Heilman", 63.081],
        ["Gaytan", 63.084],
        ["Gall", 63.086],
        ["Fried", 63.089],
        ["Fortney", 63.091],
        ["Eden", 63.093],
        ["Dingle", 63.096],
        ["Desjardins", 63.098],
        ["Dabbs", 63.101],
        ["Burbank", 63.103],
        ["Brigham", 63.105],
        ["Breland", 63.108],
        ["Beaman", 63.11],
        ["Banner", 63.113],
        ["Arriola", 63.115],
        ["Yarborough", 63.117],
        ["Wallin", 63.12],
        ["Treat", 63.122],
        ["Toscano", 63.125],
        ["Stowers", 63.127],
        ["Reiss", 63.129],
        ["Pichardo", 63.132],
        ["Orton", 63.134],
        ["Mitchel", 63.136],
        ["Michels", 63.139],
        ["McNamee", 63.141],
        ["McCrory", 63.144],
        ["Leatherman", 63.146],
        ["Kell", 63.148],
        ["Keister", 63.151],
        ["Jerome", 63.153],
        ["Horning", 63.156],
        ["Hargett", 63.158],
        ["Guay", 63.16],
        ["Friday", 63.163],
        ["Ferro", 63.165],
        ["Deboer", 63.167],
        ["Dagostino", 63.17],
        ["Clemente", 63.172],
        ["Christ", 63.175],
        ["Carper", 63.177],
        ["Bowler", 63.179],
        ["Blanks", 63.182],
        ["Beaudry", 63.184],
        ["Willie", 63.187],
        ["Towle", 63.189],
        ["Tafoya", 63.191],
        ["Stricklin", 63.194],
        ["Strader", 63.196],
        ["Soper", 63.198],
        ["Sonnier", 63.201],
        ["Sigmon", 63.203],
        ["Schenk", 63.205],
        ["Saddler", 63.208],
        ["Rodman", 63.21],
        ["Pedigo", 63.213],
        ["Mendes", 63.215],
        ["Lunn", 63.217],
        ["Lohr", 63.22],
        ["Lahr", 63.222],
        ["Kingsbury", 63.224],
        ["Jarman", 63.227],
        ["Hume", 63.229],
        ["Holliman", 63.232],
        ["Hofmann", 63.234],
        ["Haworth", 63.236],
        ["Harrelson", 63.239],
        ["Hambrick", 63.241],
        ["Flick", 63.243],
        ["Edmunds", 63.246],
        ["Dacosta", 63.248],
        ["Crossman", 63.25],
        ["Colston", 63.253],
        ["Chaplin", 63.255],
        ["Carrell", 63.258],
        ["Budd", 63.26],
        ["Weiler", 63.262],
        ["Waits", 63.265],
        ["Viola", 63.267],
        ["Valentino", 63.269],
        ["Trantham", 63.272],
        ["Tarr", 63.274],
        ["Straight", 63.276],
        ["Solorio", 63.279],
        ["Roebuck", 63.281],
        ["Powe", 63.283],
        ["Plank", 63.286],
        ["Pettus", 63.288],
        ["Palm", 63.291],
        ["Pagano", 63.293],
        ["Mink", 63.295],
        ["Luker", 63.298],
        ["Leathers", 63.3],
        ["Joslin", 63.302],
        ["Hartzell", 63.305],
        ["Gambrell", 63.307],
        ["Fears", 63.309],
        ["Deutsch", 63.312],
        ["Cepeda", 63.314],
        ["Carty", 63.316],
        ["Caputo", 63.319],
        ["Brewington", 63.321],
        ["Bedell", 63.323],
        ["Ballew", 63.326],
        ["Applewhite", 63.328],
        ["Warnock", 63.331],
        ["Walz", 63.333],
        ["Urena", 63.335],
        ["Tudor", 63.338],
        ["Reel", 63.34],
        ["Pigg", 63.342],
        ["Parton", 63.345],
        ["Mickelson", 63.347],
        ["Meagher", 63.349],
        ["McLellan", 63.352],
        ["McCulley", 63.354],
        ["Mandel", 63.356],
        ["Leech", 63.359],
        ["Lavallee", 63.361],
        ["Kraemer", 63.363],
        ["Kling", 63.366],
        ["Kipp", 63.368],
        ["Kingston", 63.37],
        ["Kehoe", 63.373],
        ["Hochstetler", 63.375],
        ["Harriman", 63.377],
        ["Gregoire", 63.38],
        ["Grabowski", 63.382],
        ["Gosselin", 63.384],
        ["Gammon", 63.387],
        ["Fancher", 63.389],
        ["Edens", 63.391],
        ["Desai", 63.394],
        ["Butt", 63.396],
        ["Brannan", 63.398],
        ["Armendariz", 63.401],
        ["Woolsey", 63.403],
        ["Whitehouse", 63.405],
        ["Whetstone", 63.408],
        ["Ussery", 63.41],
        ["Towne", 63.412],
        ["Tower", 63.415],
        ["Testa", 63.417],
        ["Tallman", 63.419],
        ["Studer", 63.422],
        ["Strait", 63.424],
        ["Steinmetz", 63.426],
        ["Sorrells", 63.428],
        ["Sauceda", 63.431],
        ["Rolfe", 63.433],
        ["Rae", 63.435],
        ["Paddock", 63.438],
        ["Mitchem", 63.44],
        ["McGinn", 63.442],
        ["McCrea", 63.445],
        ["Luck", 63.447],
        ["Lovato", 63.449],
        ["Ling", 63.452],
        ["Hazen", 63.454],
        ["Gilpin", 63.456],
        ["Gaynor", 63.459],
        ["Fike", 63.461],
        ["Devoe", 63.463],
        ["Delrio", 63.466],
        ["Curiel", 63.468],
        ["Burkhardt", 63.47],
        ["Bristol", 63.473],
        ["Bode", 63.475],
        ["Backus", 63.477],
        ["Alton", 63.48],
        ["Zinn", 63.482],
        ["Watanabe", 63.484],
        ["Wachter", 63.486],
        ["Van Pelt", 63.489],
        ["Turnage", 63.491],
        ["Shaner", 63.493],
        ["Schroder", 63.496],
        ["Sato", 63.498],
        ["Riordan", 63.5],
        ["Quimby", 63.503],
        ["Portis", 63.505],
        ["Natale", 63.507],
        ["McKoy", 63.51],
        ["McCown", 63.512],
        ["Marker", 63.514],
        ["Lucio", 63.516],
        ["Kilmer", 63.519],
        ["Karl", 63.521],
        ["Hotchkiss", 63.523],
        ["Hesse", 63.526],
        ["Halbert", 63.528],
        ["Gwinn", 63.53],
        ["Godsey", 63.533],
        ["Desmond", 63.535],
        ["Delisle", 63.537],
        ["Chrisman", 63.539],
        ["Canter", 63.542],
        ["Brook", 63.544],
        ["Arbogast", 63.546],
        ["Angell", 63.549],
        ["Acree", 63.551],
        ["Yancy", 63.553],
        ["Woolley", 63.556],
        ["Wesson", 63.558],
        ["Weatherspoon", 63.56],
        ["Trainor", 63.562],
        ["Stockman", 63.565],
        ["Spiller", 63.567],
        ["Sipe", 63.569],
        ["Rooks", 63.572],
        ["Reavis", 63.574],
        ["Propst", 63.576],
        ["Porras", 63.578],
        ["Neilson", 63.581],
        ["Mullens", 63.583],
        ["Loucks", 63.585],
        ["Llewellyn", 63.588],
        ["Lamont", 63.59],
        ["Kumar", 63.592],
        ["Koester", 63.595],
        ["Klingensmith", 63.597],
        ["Kirsch", 63.599],
        ["Kester", 63.601],
        ["Honaker", 63.604],
        ["Hodson", 63.606],
        ["Hennessy", 63.608],
        ["Helmick", 63.611],
        ["Garrity", 63.613],
        ["Garibay", 63.615],
        ["Fee", 63.617],
        ["Drain", 63.62],
        ["Casarez", 63.622],
        ["Callis", 63.624],
        ["Botello", 63.627],
        ["Bay", 63.629],
        ["Aycock", 63.631],
        ["Avant", 63.633],
        ["Angle", 63.636],
        ["Wingard", 63.638],
        ["Wayman", 63.64],
        ["Tully", 63.643],
        ["Theisen", 63.645],
        ["Szymanski", 63.647],
        ["Stansbury", 63.649],
        ["Segovia", 63.652],
        ["Rudy", 63.654],
        ["Rainwater", 63.656],
        ["Preece", 63.658],
        ["Pirtle", 63.661],
        ["Padron", 63.663],
        ["Mincey", 63.665],
        ["McKelvey", 63.668],
        ["Mathes", 63.67],
        ["Marty", 63.672],
        ["Larrabee", 63.674],
        ["Kornegay", 63.677],
        ["Klug", 63.679],
        ["Judy", 63.681],
        ["Ingersoll", 63.683],
        ["Hecht", 63.686],
        ["Germain", 63.688],
        ["Eggers", 63.69],
        ["Dykstra", 63.693],
        ["Denis", 63.695],
        ["Deering", 63.697],
        ["Decoteau", 63.699],
        ["Deason", 63.702],
        ["Dearing", 63.704],
        ["Cofield", 63.706],
        ["Carrigan", 63.708],
        ["Brush", 63.711],
        ["Bonham", 63.713],
        ["Bahr", 63.715],
        ["Aucoin", 63.718],
        ["Appleby", 63.72],
        ["Almonte", 63.722],
        ["Yager", 63.724],
        ["Womble", 63.727],
        ["Wimmer", 63.729],
        ["Weimer", 63.731],
        ["Vanderpool", 63.733],
        ["Stancil", 63.736],
        ["Sprinkle", 63.738],
        ["Romine", 63.74],
        ["Remington", 63.742],
        ["Pfaff", 63.745],
        ["Peckham", 63.747],
        ["Olivera", 63.749],
        ["Meraz", 63.751],
        ["Maze", 63.754],
        ["Lathrop", 63.756],
        ["Koehn", 63.758],
        ["Jonas", 63.76],
        ["Hazelton", 63.763],
        ["Halvorson", 63.765],
        ["Hallock", 63.767],
        ["Haddock", 63.77],
        ["Ducharme", 63.772],
        ["Dehaven", 63.774],
        ["Colton", 63.776],
        ["Caruthers", 63.779],
        ["Brehm", 63.781],
        ["Bosworth", 63.783],
        ["Bost", 63.785],
        ["Blow", 63.788],
        ["Bias", 63.79],
        ["Beeman", 63.792],
        ["Basile", 63.794],
        ["Bane", 63.797],
        ["Aikens", 63.799],
        ["Zachary", 63.801],
        ["Wold", 63.803],
        ["Walther", 63.806],
        ["Tabb", 63.808],
        ["Suber", 63.81],
        ["Strawn", 63.812],
        ["Stocks", 63.815],
        ["Stocker", 63.817],
        ["Shirey", 63.819],
        ["Schlosser", 63.821],
        ["Salvador", 63.824],
        ["Riedel", 63.826],
        ["Rembert", 63.828],
        ["Reimer", 63.83],
        ["Pyles", 63.832],
        ["Pickle", 63.835],
        ["Peele", 63.837],
        ["Merriweather", 63.839],
        ["Letourneau", 63.841],
        ["Latta", 63.844],
        ["Kidder", 63.846],
        ["Hixon", 63.848],
        ["Hillis", 63.85],
        ["Hight", 63.853],
        ["Herbst", 63.855],
        ["Henriquez", 63.857],
        ["Haygood", 63.859],
        ["Hamill", 63.862],
        ["Gabel", 63.864],
        ["Fritts", 63.866],
        ["Eubank", 63.868],
        ["Duty", 63.871],
        ["Dawes", 63.873],
        ["Correll", 63.875],
        ["Coffee", 63.877],
        ["Cha", 63.88],
        ["Bushey", 63.882],
        ["Buchholz", 63.884],
        ["Brotherton", 63.886],
        ["Bridge", 63.889],
        ["Botts", 63.891],
        ["Barnwell", 63.893],
        ["Auger", 63.895],
        ["Atchley", 63.897],
        ["Westphal", 63.9],
        ["Veilleux", 63.902],
        ["Ulloa", 63.904],
        ["Truman", 63.906],
        ["Stutzman", 63.909],
        ["Shriver", 63.911],
        ["Ryals", 63.913],
        ["Prior", 63.915],
        ["Pilkington", 63.918],
        ["Newport", 63.92],
        ["Moyers", 63.922],
        ["Miracle", 63.924],
        ["Marrs", 63.926],
        ["Mangrum", 63.929],
        ["Maddux", 63.931],
        ["Lockard", 63.933],
        ["Laing", 63.935],
        ["Kuhl", 63.938],
        ["Harney", 63.94],
        ["Hammock", 63.942],
        ["Hamlett", 63.944],
        ["Felker", 63.946],
        ["Doerr", 63.949],
        ["Depriest", 63.951],
        ["Carrasquillo", 63.953],
        ["Carothers", 63.955],
        ["Bogle", 63.958],
        ["Blood", 63.96],
        ["Bischoff", 63.962],
        ["Bergen", 63.964],
        ["Albanese", 63.966],
        ["Wyckoff", 63.969],
        ["Vermillion", 63.971],
        ["Van Sickle", 63.973],
        ["Thibault", 63.975],
        ["Tetreault", 63.978],
        ["Stickney", 63.98],
        ["Shoemake", 63.982],
        ["Ruggiero", 63.984],
        ["Rawson", 63.986],
        ["Racine", 63.989],
        ["Philpot", 63.991],
        ["Paschal", 63.993],
        ["McElhaney", 63.995],
        ["Mathison", 63.997],
        ["Legrand", 64],
        ["Lapierre", 64.002],
        ["Kwan", 64.004],
        ["Kremer", 64.006],
        ["Jiles", 64.008],
        ["Hilbert", 64.011],
        ["Geyer", 64.013],
        ["Faircloth", 64.015],
        ["Ehlers", 64.017],
        ["Egbert", 64.02],
        ["Desrosiers", 64.022],
        ["Dalrymple", 64.024],
        ["Cotten", 64.026],
        ["Cashman", 64.028],
        ["Cadena", 64.031],
        ["Breeding", 64.033],
        ["Boardman", 64.035],
        ["Alcaraz", 64.037],
        ["Ahn", 64.039],
        ["Wyrick", 64.042],
        ["Therrien", 64.044],
        ["Tankersley", 64.046],
        ["Strickler", 64.048],
        ["Puryear", 64.05],
        ["Plourde", 64.053],
        ["Pattison", 64.055],
        ["Pardue", 64.057],
        ["Milan", 64.059],
        ["McGinty", 64.061],
        ["McEvoy", 64.064],
        ["Landreth", 64.066],
        ["Kuhns", 64.068],
        ["Koon", 64.07],
        ["Hewett", 64.072],
        ["Giddens", 64.075],
        ["Everette", 64.077],
        ["Emerick", 64.079],
        ["Eades", 64.081],
        ["Deangelis", 64.083],
        ["Cosme", 64.085],
        ["Ceballos", 64.088],
        ["Birdsong", 64.09],
        ["Benham", 64.092],
        ["Bemis", 64.094],
        ["Armour", 64.096],
        ["Anguiano", 64.099],
        ["Angeles", 64.101],
        ["Welborn", 64.103],
        ["Tsosie", 64.105],
        ["Storms", 64.107],
        ["Shoup", 64.11],
        ["Sessoms", 64.112],
        ["Samaniego", 64.114],
        ["Rood", 64.116],
        ["Rojo", 64.118],
        ["Rhinehart", 64.12],
        ["Raby", 64.123],
        ["Northcutt", 64.125],
        ["Myer", 64.127],
        ["Munguia", 64.129],
        ["Morehouse", 64.131],
        ["More", 64.133],
        ["McDevitt", 64.136],
        ["Mateo", 64.138],
        ["Mallett", 64.14],
        ["Lozada", 64.142],
        ["Lemoine", 64.144],
        ["Kuehn", 64.147],
        ["Hallett", 64.149],
        ["Grim", 64.151],
        ["Gillard", 64.153],
        ["Gaylor", 64.155],
        ["Garman", 64.157],
        ["Gallaher", 64.16],
        ["Feaster", 64.162],
        ["Faris", 64.164],
        ["Darrow", 64.166],
        ["Dardar", 64.168],
        ["Coney", 64.171],
        ["Carreon", 64.173],
        ["Byron", 64.175],
        ["Braithwaite", 64.177],
        ["Boylan", 64.179],
        ["Boyett", 64.181],
        ["Born", 64.184],
        ["Bixler", 64.186],
        ["Bigham", 64.188],
        ["Benford", 64.19],
        ["Barragan", 64.192],
        ["Barnum", 64.194],
        ["Zuber", 64.197],
        ["Wyche", 64.199],
        ["Westcott", 64.201],
        ["Vining", 64.203],
        ["Stoltzfus", 64.205],
        ["Simonds", 64.207],
        ["Shupe", 64.21],
        ["Sabin", 64.212],
        ["Ruble", 64.214],
        ["Rittenhouse", 64.216],
        ["Richman", 64.218],
        ["Perrone", 64.22],
        ["Mulholland", 64.223],
        ["Millan", 64.225],
        ["Meister", 64.227],
        ["Mathew", 64.229],
        ["Lomeli", 64.231],
        ["Kite", 64.233],
        ["Jemison", 64.236],
        ["Hulett", 64.238],
        ["Holler", 64.24],
        ["Hickerson", 64.242],
        ["Herold", 64.244],
        ["Hazelwood", 64.246],
        ["Griffen", 64.249],
        ["Gause", 64.251],
        ["Forde", 64.253],
        ["Eisenberg", 64.255],
        ["Dilworth", 64.257],
        ["Charron", 64.259],
        ["Chaisson", 64.262],
        ["Brodie", 64.264],
        ["Bristow", 64.266],
        ["Breunig", 64.268],
        ["Brace", 64.27],
        ["Boutwell", 64.272],
        ["Bentz", 64.274],
        ["Belk", 64.277],
        ["Bayless", 64.279],
        ["Batchelder", 64.281],
        ["Baran", 64.283],
        ["Baeza", 64.285],
        ["Zimmermann", 64.287],
        ["Weathersby", 64.29],
        ["Volk", 64.292],
        ["Toole", 64.294],
        ["Theis", 64.296],
        ["Tedesco", 64.298],
        ["Shine", 64.3],
        ["Searle", 64.302],
        ["Schenck", 64.305],
        ["Satterwhite", 64.307],
        ["Sandy", 64.309],
        ["Ruelas", 64.311],
        ["Royce", 64.313],
        ["Rankins", 64.315],
        ["Partida", 64.317],
        ["Nesbit", 64.32],
        ["Morel", 64.322],
        ["Menchaca", 64.324],
        ["Levasseur", 64.326],
        ["Kaylor", 64.328],
        ["Johnstone", 64.33],
        ["Hulse", 64.333],
        ["Hollar", 64.335],
        ["Hersey", 64.337],
        ["Harrigan", 64.339],
        ["Harbison", 64.341],
        ["Guyer", 64.343],
        ["Gish", 64.345],
        ["Giese", 64.348],
        ["Gerlach", 64.35],
        ["Geller", 64.352],
        ["Geisler", 64.354],
        ["Falcone", 64.356],
        ["Ernest", 64.358],
        ["Elwell", 64.36],
        ["Doucet", 64.363],
        ["Deese", 64.365],
        ["Darr", 64.367],
        ["Corder", 64.369],
        ["Chafin", 64.371],
        ["Byler", 64.373],
        ["Bussell", 64.375],
        ["Burdett", 64.378],
        ["Brasher", 64.38],
        ["Bowe", 64.382],
        ["Bellinger", 64.384],
        ["Bastian", 64.386],
        ["Barner", 64.388],
        ["Alleyne", 64.39],
        ["Wilborn", 64.393],
        ["Weil", 64.395],
        ["Wegner", 64.397],
        ["Wales", 64.399],
        ["Tatro", 64.401],
        ["Spitzer", 64.403],
        ["Smithers", 64.405],
        ["Schoen", 64.407],
        ["Resendez", 64.41],
        ["Pete", 64.412],
        ["Parisi", 64.414],
        ["Overman", 64.416],
        ["Obrian", 64.418],
        ["Mudd", 64.42],
        ["Moy", 64.422],
        ["McLaren", 64.425],
        ["Mahler", 64.427],
        ["Maggio", 64.429],
        ["Lindner", 64.431],
        ["Lalonde", 64.433],
        ["Lacasse", 64.435],
        ["Laboy", 64.437],
        ["Killion", 64.439],
        ["Kahl", 64.442],
        ["Jessen", 64.444],
        ["Jamerson", 64.446],
        ["Houk", 64.448],
        ["Henshaw", 64.45],
        ["Gustin", 64.452],
        ["Groom", 64.454],
        ["Graber", 64.456],
        ["Durst", 64.459],
        ["Duenas", 64.461],
        ["Davey", 64.463],
        ["Cundiff", 64.465],
        ["Conlon", 64.467],
        ["Colunga", 64.469],
        ["Coakley", 64.471],
        ["Chiles", 64.474],
        ["Capers", 64.476],
        ["Buell", 64.478],
        ["Bricker", 64.48],
        ["Bissonnette", 64.482],
        ["Birmingham", 64.484],
        ["Bartz", 64.486],
        ["Bagby", 64.488],
        ["Zayas", 64.491],
        ["Volpe", 64.493],
        ["Treece", 64.495],
        ["Toombs", 64.497],
        ["Thom", 64.499],
        ["Terrazas", 64.501],
        ["Swinney", 64.503],
        ["Skiles", 64.505],
        ["Silveira", 64.507],
        ["Shouse", 64.51],
        ["Senn", 64.512],
        ["Rambo", 64.514],
        ["Ramage", 64.516],
        ["Nez", 64.518],
        ["Moua", 64.52],
        ["Marlin", 64.522],
        ["Malik", 64.524],
        ["Langham", 64.527],
        ["Kyles", 64.529],
        ["Holston", 64.531],
        ["Hoagland", 64.533],
        ["Herd", 64.535],
        ["Hector", 64.537],
        ["Feller", 64.539],
        ["Emory", 64.541],
        ["Denison", 64.543],
        ["Corliss", 64.546],
        ["Carraway", 64.548],
        ["Burford", 64.55],
        ["Bickel", 64.552],
        ["Ambriz", 64.554],
        ["Abercrombie", 64.556],
        ["Yamada", 64.558],
        ["Winner", 64.56],
        ["Weidner", 64.562],
        ["Waddle", 64.564],
        ["Verduzco", 64.567],
        ["Thurmond", 64.569],
        ["Swindle", 64.571],
        ["Schrock", 64.573],
        ["Sanabria", 64.575],
        ["Rosenberger", 64.577],
        ["Probst", 64.579],
        ["Peabody", 64.581],
        ["Olinger", 64.583],
        ["Neighbors", 64.585],
        ["Nazario", 64.588],
        ["McCafferty", 64.59],
        ["McBroom", 64.592],
        ["McAbee", 64.594],
        ["Mazur", 64.596],
        ["Matherne", 64.598],
        ["Mapes", 64.6],
        ["Leverett", 64.602],
        ["Killingsworth", 64.604],
        ["Heisler", 64.606],
        ["Griego", 64.609],
        ["Grande", 64.611],
        ["Gosnell", 64.613],
        ["Frankel", 64.615],
        ["Franke", 64.617],
        ["Ferrante", 64.619],
        ["Fenn", 64.621],
        ["Elmer", 64.623],
        ["Ehrlich", 64.625],
        ["Christopherso", 64.627],
        ["Chick", 64.63],
        ["Chasse", 64.632],
        ["Chancellor", 64.634],
        ["Caton", 64.636],
        ["Brunelle", 64.638],
        ["Bly", 64.64],
        ["Bloomfield", 64.642],
        ["Babbitt", 64.644],
        ["Azevedo", 64.646],
        ["Abramson", 64.648],
        ["Ables", 64.651],
        ["Abeyta", 64.653],
        ["Youmans", 64.655],
        ["Wozniak", 64.657],
        ["Wainwright", 64.659],
        ["Summer", 64.661],
        ["Stowell", 64.663],
        ["Smitherman", 64.665],
        ["Sites", 64.667],
        ["Samuelson", 64.669],
        ["Runge", 64.671],
        ["Rule", 64.673],
        ["Rothman", 64.676],
        ["Rosenfeld", 64.678],
        ["Quan", 64.68],
        ["Peake", 64.682],
        ["Oxford", 64.684],
        ["Owings", 64.686],
        ["Olmos", 64.688],
        ["Munro", 64.69],
        ["Moreira", 64.692],
        ["Leatherwood", 64.694],
        ["Larkins", 64.696],
        ["Krantz", 64.698],
        ["Kovacs", 64.701],
        ["Kizer", 64.703],
        ["Kindred", 64.705],
        ["Karnes", 64.707],
        ["Jaffe", 64.709],
        ["Hubbell", 64.711],
        ["Hosey", 64.713],
        ["Hauck", 64.715],
        ["Harold", 64.717],
        ["Goodell", 64.719],
        ["Favors", 64.721],
        ["Erdman", 64.723],
        ["Dvorak", 64.726],
        ["Doane", 64.728],
        ["Cureton", 64.73],
        ["Cofer", 64.732],
        ["Buehler", 64.734],
        ["Bierman", 64.736],
        ["Berndt", 64.738],
        ["Banta", 64.74],
        ["Annis", 64.742],
        ["Abram", 64.744],
        ["Abdullah", 64.746],
        ["Warwick", 64.748],
        ["Waltz", 64.75],
        ["Turcotte", 64.753],
        ["Trinh", 64.755],
        ["Torrey", 64.757],
        ["Stith", 64.759],
        ["Seger", 64.761],
        ["Sachs", 64.763],
        ["Quesada", 64.765],
        ["Pinder", 64.767],
        ["Peppers", 64.769],
        ["Pascual", 64.771],
        ["Paschall", 64.773],
        ["Parkhurst", 64.775],
        ["Ozuna", 64.777],
        ["Oster", 64.779],
        ["Nicholls", 64.781],
        ["Mortimer", 64.784],
        ["Lheureux", 64.786],
        ["Lavalley", 64.788],
        ["Kimura", 64.79],
        ["Jablonski", 64.792],
        ["Haun", 64.794],
        ["Gourley", 64.796],
        ["Gilligan", 64.798],
        ["Fix", 64.8],
        ["Derby", 64.802],
        ["Croy", 64.804],
        ["Cotto", 64.806],
        ["Cargill", 64.808],
        ["Burwell", 64.81],
        ["Burgett", 64.812],
        ["Buckman", 64.815],
        ["Brett", 64.817],
        ["Booher", 64.819],
        ["Adorno", 64.821],
        ["Wrenn", 64.823],
        ["Whittemore", 64.825],
        ["Urias", 64.827],
        ["Szabo", 64.829],
        ["Sayles", 64.831],
        ["Saiz", 64.833],
        ["Rutland", 64.835],
        ["Rael", 64.837],
        ["Plant", 64.839],
        ["Pharr", 64.841],
        ["Penney", 64.843],
        ["Pelkey", 64.845],
        ["Ogrady", 64.847],
        ["Nickell", 64.849],
        ["Musick", 64.852],
        ["Moats", 64.854],
        ["Mather", 64.856],
        ["Massa", 64.858],
        ["Laurent", 64.86],
        ["Kirschner", 64.862],
        ["Kieffer", 64.864],
        ["Kellar", 64.866],
        ["Hendershot", 64.868],
        ["Gott", 64.87],
        ["Godoy", 64.872],
        ["Gadson", 64.874],
        ["Furtado", 64.876],
        ["Fiedler", 64.878],
        ["Erskine", 64.88],
        ["Edison", 64.882],
        ["Dutcher", 64.884],
        ["Dever", 64.886],
        ["Daggett", 64.888],
        ["Chevalier", 64.89],
        ["Chao", 64.893],
        ["Brake", 64.895],
        ["Ballesteros", 64.897],
        ["Amerson", 64.899],
        ["Alejandro", 64.901],
        ["Wingo", 64.903],
        ["Waldon", 64.905],
        ["Trott", 64.907],
        ["Spikes", 64.909],
        ["Silvey", 64.911],
        ["Showers", 64.913],
        ["Schlegel", 64.915],
        ["Rue", 64.917],
        ["Ritz", 64.919],
        ["Pepin", 64.921],
        ["Pelayo", 64.923],
        ["Parsley", 64.925],
        ["Palermo", 64.927],
        ["Moorehead", 64.929],
        ["McHale", 64.931],
        ["Lett", 64.933],
        ["Kocher", 64.935],
        ["Kilburn", 64.937],
        ["Iglesias", 64.939],
        ["Humble", 64.941],
        ["Hulbert", 64.943],
        ["Huckaby", 64.946],
        ["Hix", 64.948],
        ["Haven", 64.95],
        ["Hartford", 64.952],
        ["Hardiman", 64.954],
        ["Gurney", 64.956],
        ["Grigg", 64.958],
        ["Grasso", 64.96],
        ["Goings", 64.962],
        ["Fillmore", 64.964],
        ["Farber", 64.966],
        ["Depew", 64.968],
        ["Dandrea", 64.97],
        ["Dame", 64.972],
        ["Cowen", 64.974],
        ["Covarrubias", 64.976],
        ["Cory", 64.978],
        ["Burrus", 64.98],
        ["Bracy", 64.982],
        ["Ardoin", 64.984],
        ["Thompkins", 64.986],
        ["Suzuki", 64.988],
        ["Standley", 64.99],
        ["Russel", 64.992],
        ["Radcliffe", 64.994],
        ["Pohl", 64.996],
        ["Persaud", 64.998],
        ["Percy", 65],
        ["Parenteau", 65.002],
        ["Pabon", 65.004],
        ["Newson", 65.006],
        ["Newhouse", 65.008],
        ["Napolitano", 65.01],
        ["Mulcahy", 65.012],
        ["Maya", 65.014],
        ["Malave", 65.016],
        ["Keim", 65.018],
        ["Hooten", 65.021],
        ["Hernandes", 65.023],
        ["Heffernan", 65.025],
        ["Hearne", 65.027],
        ["Greenleaf", 65.029],
        ["Glick", 65.031],
        ["Fuhrman", 65.033],
        ["Fetter", 65.035],
        ["Faria", 65.037],
        ["Dishman", 65.039],
        ["Dickenson", 65.041],
        ["Crites", 65.043],
        ["Criss", 65.045],
        ["Clapper", 65.047],
        ["Chenault", 65.049],
        ["Castor", 65.051],
        ["Casto", 65.053],
        ["Bugg", 65.055],
        ["Bove", 65.057],
        ["Bonney", 65.059],
        ["Blessing", 65.061],
        ["Ard", 65.063],
        ["Anderton", 65.065],
        ["Allgood", 65.067],
        ["Alderson", 65.069],
        ["Woodman", 65.071],
        ["Wisdom", 65.073],
        ["Warrick", 65.075],
        ["Toomey", 65.077],
        ["Tooley", 65.079],
        ["Tarrant", 65.081],
        ["Summerville", 65.083],
        ["Stebbins", 65.085],
        ["Sokol", 65.087],
        ["Sink", 65.089],
        ["Searles", 65.091],
        ["Schutz", 65.093],
        ["Schumann", 65.095],
        ["Scheer", 65.097],
        ["Remillard", 65.099],
        ["Raper", 65.101],
        ["Proulx", 65.103],
        ["Palmore", 65.105],
        ["Monroy", 65.107],
        ["Miguel", 65.109],
        ["Messier", 65.111],
        ["Melo", 65.113],
        ["Melanson", 65.115],
        ["Mashburn", 65.117],
        ["Manzano", 65.119],
        ["Lussier", 65.121],
        ["Lovely", 65.123],
        ["Lien", 65.125],
        ["Jenks", 65.127],
        ["Huneycutt", 65.129],
        ["Hartwig", 65.131],
        ["Grimsley", 65.133],
        ["Fulk", 65.135],
        ["Fielding", 65.137],
        ["Fidler", 65.139],
        ["Engstrom", 65.141],
        ["Eldred", 65.143],
        ["Dantzler", 65.145],
        ["Crandell", 65.147],
        ["Ching", 65.149],
        ["Calder", 65.151],
        ["Brumley", 65.153],
        ["Breton", 65.155],
        ["Brann", 65.157],
        ["Bramlett", 65.159],
        ["Boykins", 65.161],
        ["Bianco", 65.163],
        ["Bancroft", 65.165],
        ["Almaraz", 65.167],
        ["Alcantar", 65.169],
        ["Whitmer", 65.171],
        ["Whitener", 65.173],
        ["Welton", 65.175],
        ["Vineyard", 65.177],
        ["Su", 65.179],
        ["Rahn", 65.181],
        ["Paquin", 65.183],
        ["Mizell", 65.185],
        ["Mix", 65.187],
        ["McMillin", 65.189],
        ["McKean", 65.191],
        ["Marston", 65.193],
        ["Maciel", 65.195],
        ["Lundquist", 65.197],
        ["Louie", 65.199],
        ["Liggins", 65.201],
        ["Lampkin", 65.203],
        ["Kranz", 65.205],
        ["Koski", 65.207],
        ["Kirkham", 65.209],
        ["Jiminez", 65.211],
        ["Hazzard", 65.213],
        ["Harrod", 65.215],
        ["Graziano", 65.217],
        ["Grammer", 65.219],
        ["Gendron", 65.221],
        ["Garrido", 65.223],
        ["Fordham", 65.225],
        ["Englert", 65.227],
        ["Elwood", 65.229],
        ["Dryden", 65.231],
        ["Demoss", 65.233],
        ["Deluna", 65.235],
        ["Crabb", 65.237],
        ["Comeau", 65.239],
        ["Claudio", 65.241],
        ["Brummett", 65.243],
        ["Blume", 65.245],
        ["Benally", 65.247],
        ["Wessel", 65.249],
        ["Van Buskirk", 65.251],
        ["Thorson", 65.253],
        ["Stumpf", 65.255],
        ["Stockwell", 65.256],
        ["Rocco", 65.258],
        ["Reams", 65.26],
        ["Radtke", 65.262],
        ["Rackley", 65.264],
        ["Pelton", 65.266],
        ["Niemi", 65.268],
        ["Newland", 65.27],
        ["Nelsen", 65.272],
        ["Morrissette", 65.274],
        ["Miramontes", 65.276],
        ["McGinley", 65.278],
        ["McCluskey", 65.28],
        ["Marley", 65.282],
        ["Marchant", 65.284],
        ["Luevano", 65.286],
        ["Lampe", 65.288],
        ["Lail", 65.29],
        ["Jeffcoat", 65.292],
        ["Infante", 65.294],
        ["Hu", 65.296],
        ["Hinman", 65.298],
        ["Gaona", 65.3],
        ["Erb", 65.302],
        ["Eady", 65.304],
        ["Desmarais", 65.306],
        ["Decosta", 65.308],
        ["Dansby", 65.31],
        ["Cisco", 65.312],
        ["Choe", 65.314],
        ["Breckenridge", 65.316],
        ["Bostwick", 65.318],
        ["Borg", 65.32],
        ["Bianchi", 65.322],
        ["Beer", 65.323],
        ["Alberts", 65.325],
        ["Adrian", 65.327],
        ["Wilkie", 65.329],
        ["Whorton", 65.331],
        ["Vargo", 65.333],
        ["Tait", 65.335],
        ["Sylvia", 65.337],
        ["Soucy", 65.339],
        ["Schuman", 65.341],
        ["Ousley", 65.343],
        ["Mumford", 65.345],
        ["Lum", 65.347],
        ["Lippert", 65.349],
        ["Leath", 65.351],
        ["Lavergne", 65.353],
        ["Laliberte", 65.355],
        ["Kirksey", 65.357],
        ["Kenner", 65.359],
        ["Johnsen", 65.361],
        ["Izzo", 65.363],
        ["Hiles", 65.365],
        ["Gullett", 65.367],
        ["Greenwell", 65.369],
        ["Gaspar", 65.37],
        ["Galbreath", 65.372],
        ["Gaitan", 65.374],
        ["Ericson", 65.376],
        ["Duck", 65.378],
        ["Delapaz", 65.38],
        ["Croom", 65.382],
        ["Cottingham", 65.384],
        ["Clift", 65.386],
        ["Bushnell", 65.388],
        ["Boozer", 65.39],
        ["Bice", 65.392],
        ["Bernardo", 65.394],
        ["Beason", 65.396],
        ["Arrowood", 65.398],
        ["Waring", 65.4],
        ["Voorhees", 65.402],
        ["Truax", 65.404],
        ["Shreve", 65.406],
        ["Shockey", 65.408],
        ["Schatz", 65.409],
        ["Sandifer", 65.411],
        ["Rubino", 65.413],
        ["Rozier", 65.415],
        ["Roseberry", 65.417],
        ["Roll", 65.419],
        ["Player", 65.421],
        ["Pieper", 65.423],
        ["Peden", 65.425],
        ["Nester", 65.427],
        ["Nave", 65.429],
        ["Murphey", 65.431],
        ["Malinowski", 65.433],
        ["MacGregor", 65.435],
        ["Liang", 65.437],
        ["Lafrance", 65.439],
        ["Kunkle", 65.441],
        ["Kirkman", 65.442],
        ["Jorge", 65.444],
        ["Hipp", 65.446],
        ["Hasty", 65.448],
        ["Haddix", 65.45],
        ["Gervais", 65.452],
        ["Gerdes", 65.454],
        ["Garfield", 65.456],
        ["Gamache", 65.458],
        ["Fouts", 65.46],
        ["Fitzwater", 65.462],
        ["Dillingham", 65.464],
        ["Deming", 65.466],
        ["Deanda", 65.468],
        ["Cedeno", 65.47],
        ["Cannady", 65.472],
        ["Burson", 65.473],
        ["Bouldin", 65.475],
        ["Arceneaux", 65.477],
        ["Woodhouse", 65.479],
        ["Whitford", 65.481],
        ["Wescott", 65.483],
        ["Welty", 65.485],
        ["Weigel", 65.487],
        ["Torgerson", 65.489],
        ["Toms", 65.491],
        ["Surber", 65.493],
        ["Sunderland", 65.495],
        ["Sterner", 65.497],
        ["Setzer", 65.499],
        ["Salvatore", 65.5],
        ["Riojas", 65.502],
        ["Pumphrey", 65.504],
        ["Puga", 65.506],
        ["Pedro", 65.508],
        ["Patch", 65.51],
        ["Metts", 65.512],
        ["McGarry", 65.514],
        ["McCandless", 65.516],
        ["Magill", 65.518],
        ["Lupo", 65.52],
        ["Loveland", 65.522],
        ["Llamas", 65.524],
        ["Leclerc", 65.525],
        ["Koons", 65.527],
        ["Kahler", 65.529],
        ["Huss", 65.531],
        ["Holbert", 65.533],
        ["Heintz", 65.535],
        ["Haupt", 65.537],
        ["Grimmett", 65.539],
        ["Gaskill", 65.541],
        ["Flower", 65.543],
        ["Ellingson", 65.545],
        ["Dorr", 65.547],
        ["Dingess", 65.549],
        ["Deweese", 65.55],
        ["Desilva", 65.552],
        ["Crossley", 65.554],
        ["Cordeiro", 65.556],
        ["Converse", 65.558],
        ["Conde", 65.56],
        ["Cheeks", 65.562],
        ["Caldera", 65.564],
        ["Cairns", 65.566],
        ["Burmeister", 65.568],
        ["Burkhalter", 65.57],
        ["Brawner", 65.572],
        ["Bott", 65.574],
        ["Youngs", 65.575],
        ["Vierra", 65.577],
        ["Valladares", 65.579],
        ["Tiffany", 65.581],
        ["Shrum", 65.583],
        ["Shropshire", 65.585],
        ["Sevilla", 65.587],
        ["Rusk", 65.589],
        ["Roof", 65.591],
        ["Rodarte", 65.593],
        ["Pedraza", 65.595],
        ["Nino", 65.596],
        ["Montana", 65.598],
        ["Merino", 65.6],
        ["McMinn", 65.602],
        ["Markle", 65.604],
        ["Mapp", 65.606],
        ["Lucia", 65.608],
        ["Lajoie", 65.61],
        ["Koerner", 65.612],
        ["Kittrell", 65.614],
        ["Kato", 65.616],
        ["Hyder", 65.617],
        ["Hollifield", 65.619],
        ["Heiser", 65.621],
        ["Hazlett", 65.623],
        ["Greenwald", 65.625],
        ["Fant", 65.627],
        ["Eldredge", 65.629],
        ["Dreher", 65.631],
        ["Delafuente", 65.633],
        ["Cravens", 65.635],
        ["Claypool", 65.636],
        ["Beecher", 65.638],
        ["Aronson", 65.64],
        ["Alanis", 65.642],
        ["Worthen", 65.644],
        ["Wojcik", 65.646],
        ["Winger", 65.648],
        ["Whitacre", 65.65],
        ["Wellington", 65.652],
        ["Valverde", 65.654],
        ["Valdivia", 65.655],
        ["Troupe", 65.657],
        ["Thrower", 65.659],
        ["Swindell", 65.661],
        ["Suttles", 65.663],
        ["Suh", 65.665],
        ["Stroman", 65.667],
        ["Spires", 65.669],
        ["Slate", 65.671],
        ["Shealy", 65.672],
        ["Sarver", 65.674],
        ["Sartin", 65.676],
        ["Sadowski", 65.678],
        ["Rondeau", 65.68],
        ["Rolon", 65.682],
        ["Rick", 65.684],
        ["Rex", 65.686],
        ["Rascon", 65.688],
        ["Priddy", 65.69],
        ["Pine", 65.691],
        ["Paulino", 65.693],
        ["Nolte", 65.695],
        ["Munroe", 65.697],
        ["Molloy", 65.699],
        ["Mellon", 65.701],
        ["McIver", 65.703],
        ["Lykins", 65.705],
        ["Loggins", 65.707],
        ["Lillie", 65.708],
        ["Lenoir", 65.71],
        ["Klotz", 65.712],
        ["Kempf", 65.714],
        ["Jone", 65.716],
        ["Hupp", 65.718],
        ["Hollowell", 65.72],
        ["Hollander", 65.722],
        ["Haynie", 65.724],
        ["Hassan", 65.725],
        ["Harkness", 65.727],
        ["Harker", 65.729],
        ["Gottlieb", 65.731],
        ["Frith", 65.733],
        ["Eddins", 65.735],
        ["Driskell", 65.737],
        ["Doggett", 65.739],
        ["Densmore", 65.741],
        ["Charette", 65.742],
        ["Cassady", 65.744],
        ["Carrol", 65.746],
        ["Byrum", 65.748],
        ["Burcham", 65.75],
        ["Buggs", 65.752],
        ["Benn", 65.754],
        ["Whitted", 65.756],
        ["Warrington", 65.758],
        ["Van Dusen", 65.759],
        ["Vaillancourt", 65.761],
        ["Steger", 65.763],
        ["Spell", 65.765],
        ["Siebert", 65.767],
        ["Scofield", 65.769],
        ["Quirk", 65.771],
        ["Purser", 65.773],
        ["Plumb", 65.774],
        ["Orcutt", 65.776],
        ["Northern", 65.778],
        ["Nordstrom", 65.78],
        ["Mosely", 65.782],
        ["Michalski", 65.784],
        ["McPhail", 65.786],
        ["McDavid", 65.788],
        ["McCraw", 65.789],
        ["Martini", 65.791],
        ["Marchese", 65.793],
        ["Mannino", 65.795],
        ["Leo", 65.797],
        ["Lefevre", 65.799],
        ["Largent", 65.801],
        ["Lanza", 65.803],
        ["Kress", 65.804],
        ["Isham", 65.806],
        ["Hunsaker", 65.808],
        ["Hoch", 65.81],
        ["Hildebrandt", 65.812],
        ["Guarino", 65.814],
        ["Grijalva", 65.816],
        ["Graybill", 65.818],
        ["Fick", 65.819],
        ["Ewell", 65.821],
        ["Ewald", 65.823],
        ["Deangelo", 65.825],
        ["Cusick", 65.827],
        ["Crumley", 65.829],
        ["Coston", 65.831],
        ["Cathcart", 65.833],
        ["Carruthers", 65.834],
        ["Bullington", 65.836],
        ["Brian", 65.838],
        ["Bowes", 65.84],
        ["Blain", 65.842],
        ["Blackford", 65.844],
        ["Barboza", 65.846],
        ["Yingling", 65.848],
        ["Woodland", 65.849],
        ["Wert", 65.851],
        ["Weiland", 65.853],
        ["Varga", 65.855],
        ["Silverstein", 65.857],
        ["Sievers", 65.859],
        ["Shuster", 65.861],
        ["Shumway", 65.862],
        ["Scudder", 65.864],
        ["Runnels", 65.866],
        ["Rumsey", 65.868],
        ["Renfroe", 65.87],
        ["Provencher", 65.872],
        ["Polley", 65.874],
        ["Mohler", 65.876],
        ["Middlebrooks", 65.877],
        ["Kutz", 65.879],
        ["Koster", 65.881],
        ["Korn", 65.883],
        ["Grow", 65.885],
        ["Groth", 65.887],
        ["Glidden", 65.889],
        ["Fazio", 65.89],
        ["Deen", 65.892],
        ["Corn", 65.894],
        ["Copper", 65.896],
        ["Chipman", 65.898],
        ["Chenoweth", 65.9],
        ["Champlin", 65.902],
        ["Cedillo", 65.903],
        ["Carrero", 65.905],
        ["Carmody", 65.907],
        ["Buckles", 65.909],
        ["Brien", 65.911],
        ["Boutin", 65.913],
        ["Bosch", 65.915],
        ["Bill", 65.916],
        ["Berkowitz", 65.918],
        ["Altamirano", 65.92],
        ["Wilfong", 65.922],
        ["Wiegand", 65.924],
        ["Waites", 65.926],
        ["Truesdale", 65.928],
        ["Toussaint", 65.929],
        ["Tobey", 65.931],
        ["Tedder", 65.933],
        ["Steelman", 65.935],
        ["Sirois", 65.937],
        ["Schnell", 65.939],
        ["Robichaud", 65.94],
        ["Ridge", 65.942],
        ["Richburg", 65.944],
        ["Pray", 65.946],
        ["Plumley", 65.948],
        ["Pizarro", 65.95],
        ["Piercy", 65.952],
        ["Ortego", 65.953],
        ["Oberg", 65.955],
        ["Neace", 65.957],
        ["Music", 65.959],
        ["Mickey", 65.961],
        ["Mertz", 65.963],
        ["McNew", 65.964],
        ["Matta", 65.966],
        ["Lawyer", 65.968],
        ["Lapp", 65.97],
        ["Lair", 65.972],
        ["Kibler", 65.974],
        ["Jessie", 65.975],
        ["Howlett", 65.977],
        ["Hollister", 65.979],
        ["Hofer", 65.981],
        ["Hatten", 65.983],
        ["Hagler", 65.985],
        ["Germany", 65.987],
        ["Falgoust", 65.988],
        ["Engelhardt", 65.99],
        ["Eberle", 65.992],
        ["Eastwood", 65.994],
        ["Dombrowski", 65.996],
        ["Dinsmore", 65.998],
        ["Daye", 65.999],
        ["Cool", 66.001],
        ["Casares", 66.003],
        ["Capone", 66.005],
        ["Braud", 66.007],
        ["Balch", 66.009],
        ["Autrey", 66.011],
        ["Wendel", 66.012],
        ["Tyndall", 66.014],
        ["Toy", 66.016],
        ["Strobel", 66.018],
        ["Stoltz", 66.02],
        ["Spinelli", 66.021],
        ["Serrato", 66.023],
        ["Rochester", 66.025],
        ["Reber", 66.027],
        ["Real", 66.029],
        ["Rathbone", 66.031],
        ["Palomino", 66.032],
        ["Noah", 66.034],
        ["Nickels", 66.036],
        ["Mayle", 66.038],
        ["Mathers", 66.04],
        ["Mach", 66.042],
        ["Loeffler", 66.043],
        ["Littrell", 66.045],
        ["Levinson", 66.047],
        ["Leong", 66.049],
        ["Lemire", 66.051],
        ["Lejeune", 66.053],
        ["Lazo", 66.054],
        ["Lasley", 66.056],
        ["Koller", 66.058],
        ["Kennard", 66.06],
        ["Jester", 66.062],
        ["Hoelscher", 66.064],
        ["Hintz", 66.065],
        ["Hagerman", 66.067],
        ["Greaves", 66.069],
        ["Fore", 66.071],
        ["Eudy", 66.073],
        ["Engler", 66.075],
        ["Corrales", 66.076],
        ["Cordes", 66.078],
        ["Brunet", 66.08],
        ["Bidwell", 66.082],
        ["Bennet", 66.084],
        ["Bare", 66.085],
        ["Tyrrell", 66.087],
        ["Tharpe", 66.089],
        ["Swinton", 66.091],
        ["Stribling", 66.093],
        ["Steven", 66.095],
        ["Southworth", 66.096],
        ["Sisneros", 66.098],
        ["Shane", 66.1],
        ["Savoie", 66.102],
        ["Samons", 66.104],
        ["Ruvalcaba", 66.105],
        ["Roscoe", 66.107],
        ["Ries", 66.109],
        ["Ramer", 66.111],
        ["Omara", 66.113],
        ["Mosqueda", 66.114],
        ["Millar", 66.116],
        ["McPeak", 66.118],
        ["Macomber", 66.12],
        ["Luckey", 66.122],
        ["Litton", 66.124],
        ["Lehr", 66.125],
        ["Lavin", 66.127],
        ["Hubbs", 66.129],
        ["Hoard", 66.131],
        ["Hibbs", 66.133],
        ["Hagans", 66.134],
        ["Futrell", 66.136],
        ["Exum", 66.138],
        ["Evenson", 66.14],
        ["Dicks", 66.142],
        ["Culler", 66.143],
        ["Chou", 66.145],
        ["Carbaugh", 66.147],
        ["Callen", 66.149],
        ["Brashear", 66.151],
        ["Bloomer", 66.153],
        ["Blakeney", 66.154],
        ["Bigler", 66.156],
        ["Addington", 66.158],
        ["Woodford", 66.16],
        ["Witter", 66.162],
        ["Unruh", 66.163],
        ["Tolentino", 66.165],
        ["Sumrall", 66.167],
        ["Stgermain", 66.169],
        ["Smock", 66.171],
        ["Sherer", 66.172],
        ["Salem", 66.174],
        ["Rochelle", 66.176],
        ["Rayner", 66.178],
        ["Pooler", 66.18],
        ["Oquinn", 66.181],
        ["Nero", 66.183],
        ["Milano", 66.185],
        ["McGlothlin", 66.187],
        ["Mars", 66.189],
        ["Linden", 66.19],
        ["Kowal", 66.192],
        ["Kerrigan", 66.194],
        ["Ibrahim", 66.196],
        ["Harvell", 66.197],
        ["Hanrahan", 66.199],
        ["Goodall", 66.201],
        ["Geist", 66.203],
        ["Fussell", 66.205],
        ["Fung", 66.206],
        ["Ferebee", 66.208],
        ["Federico", 66.21],
        ["Eley", 66.212],
        ["Eggert", 66.214],
        ["Dorsett", 66.215],
        ["Dingman", 66.217],
        ["Destefano", 66.219],
        ["Colucci", 66.221],
        ["Clemmer", 66.223],
        ["Caesar", 66.224],
        ["Burnell", 66.226],
        ["Brumbaugh", 66.228],
        ["Boddie", 66.23],
        ["Berryhill", 66.232],
        ["Avelar", 66.233],
        ["Alcantara", 66.235],
        ["Abbey", 66.237],
        ["Winder", 66.239],
        ["Winchell", 66.241],
        ["Vandenberg", 66.242],
        ["Trotman", 66.244],
        ["Thurber", 66.246],
        ["Thibeault", 66.248],
        ["Stlouis", 66.249],
        ["Stilwell", 66.251],
        ["Sperling", 66.253],
        ["Shattuck", 66.255],
        ["Sarmiento", 66.257],
        ["Ruppert", 66.258],
        ["Rumph", 66.26],
        ["Renaud", 66.262],
        ["Randazzo", 66.264],
        ["Rademacher", 66.265],
        ["Quiles", 66.267],
        ["Pearman", 66.269],
        ["Palomo", 66.271],
        ["Mercurio", 66.273],
        ["Lowrey", 66.274],
        ["Lindeman", 66.276],
        ["Lawlor", 66.278],
        ["Larosa", 66.28],
        ["Lander", 66.282],
        ["Labrecque", 66.283],
        ["Kimber", 66.285],
        ["Hovis", 66.287],
        ["Holifield", 66.289],
        ["Henninger", 66.29],
        ["Hawkes", 66.292],
        ["Hartfield", 66.294],
        ["Hann", 66.296],
        ["Hague", 66.298],
        ["Genovese", 66.299],
        ["Garrick", 66.301],
        ["Fudge", 66.303],
        ["Frink", 66.305],
        ["Eddings", 66.306],
        ["Dinh", 66.308],
        ["Dear", 66.31],
        ["Cutter", 66.312],
        ["Cribbs", 66.314],
        ["Constant", 66.315],
        ["Calvillo", 66.317],
        ["Bunton", 66.319],
        ["Brodeur", 66.321],
        ["Bolding", 66.322],
        ["Blanding", 66.324],
        ["Agosto", 66.326],
        ["Zahn", 66.328],
        ["Wiener", 66.33],
        ["Trussell", 66.331],
        ["Tew", 66.333],
        ["Tello", 66.335],
        ["Teixeira", 66.337],
        ["Stephan", 66.338],
        ["Speck", 66.34],
        ["Sharma", 66.342],
        ["Shanklin", 66.344],
        ["Sealy", 66.345],
        ["Scanlan", 66.347],
        ["Santamaria", 66.349],
        ["Roundy", 66.351],
        ["Robichaux", 66.353],
        ["Ringer", 66.354],
        ["Rigney", 66.356],
        ["Prevost", 66.358],
        ["Polson", 66.36],
        ["Philip", 66.361],
        ["Pass", 66.363],
        ["Nord", 66.365],
        ["Moxley", 66.367],
        ["Mohammed", 66.368],
        ["Medford", 66.37],
        ["McCaslin", 66.372],
        ["McArdle", 66.374],
        ["MacArthur", 66.375],
        ["Lewin", 66.377],
        ["Lasher", 66.379],
        ["Ketcham", 66.381],
        ["Keiser", 66.383],
        ["Heine", 66.384],
        ["Hackworth", 66.386],
        ["Grose", 66.388],
        ["Grizzle", 66.39],
        ["Grass", 66.391],
        ["Gillman", 66.393],
        ["Gartner", 66.395],
        ["Garth", 66.397],
        ["Frazee", 66.398],
        ["Fleury", 66.4],
        ["Fast", 66.402],
        ["Edson", 66.404],
        ["Edmonson", 66.405],
        ["Derry", 66.407],
        ["Deck", 66.409],
        ["Cronk", 66.411],
        ["Conant", 66.413],
        ["Burress", 66.414],
        ["Burgin", 66.416],
        ["Broom", 66.418],
        ["Brockington", 66.42],
        ["Bolick", 66.421],
        ["Boger", 66.423],
        ["Birchfield", 66.425],
        ["Billington", 66.427],
        ["Baily", 66.428],
        ["Bahena", 66.43],
        ["Armbruster", 66.432],
        ["Anson", 66.434],
        ["Yoho", 66.435],
        ["Wilcher", 66.437],
        ["Tinney", 66.439],
        ["Timberlake", 66.441],
        ["Thoma", 66.442],
        ["Thielen", 66.444],
        ["Sutphin", 66.446],
        ["Stultz", 66.448],
        ["Sikora", 66.449],
        ["Serra", 66.451],
        ["Schulman", 66.453],
        ["Scheffler", 66.455],
        ["Santillan", 66.456],
        ["Robin", 66.458],
        ["Rego", 66.46],
        ["Preciado", 66.462],
        ["Pinkham", 66.463],
        ["Monday", 66.465],
        ["Mickle", 66.467],
        ["Luu", 66.469],
        ["Lomas", 66.47],
        ["Lizotte", 66.472],
        ["Lent", 66.474],
        ["Lenard", 66.476],
        ["Kellerman", 66.477],
        ["Keil", 66.479],
        ["Juan", 66.481],
        ["Johanson", 66.483],
        ["Hernadez", 66.484],
        ["Hartsfield", 66.486],
        ["Hang", 66.488],
        ["Haber", 66.49],
        ["Gorski", 66.491],
        ["Farkas", 66.493],
        ["Eberhardt", 66.495],
        ["Duquette", 66.497],
        ["Delano", 66.498],
        ["Cropper", 66.5],
        ["Cozart", 66.502],
        ["Cockerham", 66.504],
        ["Chamblee", 66.505],
        ["Cartagena", 66.507],
        ["Cahoon", 66.509],
        ["Buzzell", 66.511],
        ["Brister", 66.512],
        ["Brewton", 66.514],
        ["Blackshear", 66.516],
        ["Benfield", 66.518],
        ["Aston", 66.519],
        ["Ashburn", 66.521],
        ["Arruda", 66.523],
        ["Wetmore", 66.525],
        ["Weise", 66.526],
        ["Vaccaro", 66.528],
        ["Tucci", 66.53],
        ["Sudduth", 66.532],
        ["Stromberg", 66.533],
        ["Stoops", 66.535],
        ["Showalter", 66.537],
        ["Shears", 66.538],
        ["Runion", 66.54],
        ["Rowden", 66.542],
        ["Rosenblum", 66.544],
        ["Riffle", 66.545],
        ["Renfrow", 66.547],
        ["Peres", 66.549],
        ["Obryant", 66.551],
        ["Nicolas", 66.552],
        ["Leftwich", 66.554],
        ["Lark", 66.556],
        ["Landeros", 66.558],
        ["Kistler", 66.559],
        ["Killough", 66.561],
        ["Kerley", 66.563],
        ["Kastner", 66.564],
        ["Hoggard", 66.566],
        ["Hartung", 66.568],
        ["Guertin", 66.57],
        ["Govan", 66.571],
        ["Gatling", 66.573],
        ["Gailey", 66.575],
        ["Fullmer", 66.577],
        ["Fulford", 66.578],
        ["Flatt", 66.58],
        ["Esquibel", 66.582],
        ["Endicott", 66.584],
        ["Edmiston", 66.585],
        ["Edelstein", 66.587],
        ["Dufresne", 66.589],
        ["Dressler", 66.59],
        ["Dickman", 66.592],
        ["Chee", 66.594],
        ["Busse", 66.596],
        ["Bonnett", 66.597],
        ["Bogart", 66.599],
        ["Berard", 66.601],
        ["Barrington", 66.603],
        ["Arena", 66.604],
        ["Anton", 66.606],
        ["Yoshida", 66.608],
        ["Velarde", 66.609],
        ["Veach", 66.611],
        ["Van Houten", 66.613],
        ["Vachon", 66.615],
        ["Tolson", 66.616],
        ["Tolman", 66.618],
        ["Tennyson", 66.62],
        ["Stites", 66.621],
        ["Soler", 66.623],
        ["Shutt", 66.625],
        ["Ruggles", 66.627],
        ["Rhone", 66.628],
        ["Pegues", 66.63],
        ["Ong", 66.632],
        ["Neese", 66.634],
        ["Muro", 66.635],
        ["Moncrief", 66.637],
        ["Mefford", 66.639],
        ["McPhee", 66.64],
        ["McMorris", 66.642],
        ["McEachern", 66.644],
        ["McClurg", 66.646],
        ["Mansour", 66.647],
        ["Mai", 66.649],
        ["Mader", 66.651],
        ["Leija", 66.652],
        ["Lecompte", 66.654],
        ["Lafountain", 66.656],
        ["Labrie", 66.658],
        ["Jaquez", 66.659],
        ["Heald", 66.661],
        ["Hash", 66.663],
        ["Hartle", 66.664],
        ["Gainer", 66.666],
        ["Frisby", 66.668],
        ["Farina", 66.67],
        ["Eidson", 66.671],
        ["Edgerton", 66.673],
        ["Dyke", 66.675],
        ["Durrett", 66.676],
        ["Duhon", 66.678],
        ["Cuomo", 66.68],
        ["Cobos", 66.682],
        ["Cervantez", 66.683],
        ["Bybee", 66.685],
        ["Brockway", 66.687],
        ["Borowski", 66.688],
        ["Binion", 66.69],
        ["Beery", 66.692],
        ["Arguello", 66.694],
        ["Amaro", 66.695],
        ["Acton", 66.697],
        ["Yuen", 66.699],
        ["Winton", 66.7],
        ["Wigfall", 66.702],
        ["Weekley", 66.704],
        ["Vidrine", 66.706],
        ["Vannoy", 66.707],
        ["Tardiff", 66.709],
        ["Shoop", 66.711],
        ["Shilling", 66.712],
        ["Schick", 66.714],
        ["Sand", 66.716],
        ["Safford", 66.717],
        ["Prendergast", 66.719],
        ["Pilgrim", 66.721],
        ["Pellerin", 66.723],
        ["Osuna", 66.724],
        ["Nissen", 66.726],
        ["Nalley", 66.728],
        ["Moritz", 66.729],
        ["Moller", 66.731],
        ["Messner", 66.733],
        ["Messick", 66.734],
        ["Merry", 66.736],
        ["Merrifield", 66.738],
        ["McGuinness", 66.74],
        ["Matherly", 66.741],
        ["Marcano", 66.743],
        ["Mahone", 66.745],
        ["Lemos", 66.746],
        ["Lebrun", 66.748],
        ["Jara", 66.75],
        ["Hoffer", 66.751],
        ["Hewlett", 66.753],
        ["Herren", 66.755],
        ["Hecker", 66.757],
        ["Haws", 66.758],
        ["Haug", 66.76],
        ["Hack", 66.762],
        ["Gwin", 66.763],
        ["Gober", 66.765],
        ["Gilliard", 66.767],
        ["Fredette", 66.768],
        ["Favela", 66.77],
        ["Echeverria", 66.772],
        ["Downer", 66.774],
        ["Donofrio", 66.775],
        ["Desrochers", 66.777],
        ["Dee", 66.779],
        ["Crozier", 66.78],
        ["Corson", 66.782],
        ["Clyde", 66.784],
        ["Bechtold", 66.785],
        ["Argueta", 66.787],
        ["Aparicio", 66.789],
        ["Zamudio", 66.791],
        ["Willette", 66.792],
        ["Westover", 66.794],
        ["Westerman", 66.796],
        ["Utter", 66.797],
        ["Troyer", 66.799],
        ["Thies", 66.801],
        ["Tapley", 66.802],
        ["Slavin", 66.804],
        ["Shirk", 66.806],
        ["Sandler", 66.807],
        ["Roop", 66.809],
        ["Rimmer", 66.811],
        ["Raymer", 66.812],
        ["Range", 66.814],
        ["Radcliff", 66.816],
        ["Otten", 66.818],
        ["Moorer", 66.819],
        ["Millet", 66.821],
        ["McKibben", 66.823],
        ["McCutchen", 66.824],
        ["McAvoy", 66.826],
        ["McAdoo", 66.828],
        ["Mayorga", 66.829],
        ["Mastin", 66.831],
        ["Martineau", 66.833],
        ["Marek", 66.834],
        ["Madore", 66.836],
        ["Leflore", 66.838],
        ["Kroeger", 66.839],
        ["Kennon", 66.841],
        ["Jimerson", 66.843],
        ["Javier", 66.845],
        ["Hostetter", 66.846],
        ["Hornback", 66.848],
        ["Hendley", 66.85],
        ["Hance", 66.851],
        ["Guardado", 66.853],
        ["Granado", 66.855],
        ["Gowen", 66.856],
        ["Goodale", 66.858],
        ["Flinn", 66.86],
        ["Fleetwood", 66.861],
        ["Fitz", 66.863],
        ["Durkee", 66.865],
        ["Duprey", 66.866],
        ["Dipietro", 66.868],
        ["Dilley", 66.87],
        ["Clyburn", 66.871],
        ["Brawley", 66.873],
        ["Beckley", 66.875],
        ["Arana", 66.877],
        ["Weatherby", 66.878],
        ["Vollmer", 66.88],
        ["Victoria", 66.882],
        ["Vestal", 66.883],
        ["Tunnell", 66.885],
        ["Trigg", 66.887],
        ["Tingle", 66.888],
        ["Takahashi", 66.89],
        ["Sweatt", 66.892],
        ["Storer", 66.893],
        ["Snapp", 66.895],
        ["Shiver", 66.897],
        ["Rooker", 66.898],
        ["Red", 66.9],
        ["Rathbun", 66.902],
        ["Poisson", 66.903],
        ["Perrine", 66.905],
        ["Perri", 66.907],
        ["Pastor", 66.908],
        ["Parmer", 66.91],
        ["Parke", 66.912],
        ["Pare", 66.913],
        ["Papa", 66.915],
        ["Palmieri", 66.917],
        ["Nottingham", 66.918],
        ["Midkiff", 66.92],
        ["Mecham", 66.922],
        ["McComas", 66.923],
        ["McAlpine", 66.925],
        ["Lovelady", 66.927],
        ["Lillard", 66.928],
        ["Lally", 66.93],
        ["Knopp", 66.932],
        ["Kile", 66.933],
        ["Kiger", 66.935],
        ["Haile", 66.937],
        ["Gupta", 66.938],
        ["Goldsberry", 66.94],
        ["Gilreath", 66.942],
        ["Fulks", 66.943],
        ["Friesen", 66.945],
        ["Franzen", 66.947],
        ["Flack", 66.948],
        ["Findlay", 66.95],
        ["Ferland", 66.952],
        ["Dreyer", 66.953],
        ["Dore", 66.955],
        ["Dennard", 66.957],
        ["Deckard", 66.958],
        ["Debose", 66.96],
        ["Crim", 66.962],
        ["Coulombe", 66.963],
        ["Cork", 66.965],
        ["Chancey", 66.967],
        ["Cantor", 66.968],
        ["Branton", 66.97],
        ["Bissell", 66.972],
        ["Barns", 66.973],
        ["Woolard", 66.975],
        ["Witham", 66.977],
        ["Wasserman", 66.978],
        ["Waldo", 66.98],
        ["Spiegel", 66.982],
        ["Shoffner", 66.983],
        ["Scholz", 66.985],
        ["Ruch", 66.987],
        ["Rossman", 66.988],
        ["Ready", 66.99],
        ["Petry", 66.992],
        ["Palacio", 66.993],
        ["Paez", 66.995],
        ["Neary", 66.996],
        ["Mortenson", 66.998],
        ["Millsap", 67],
        ["Miele", 67.001],
        ["Mick", 67.003],
        ["Menke", 67.005],
        ["McKim", 67.006],
        ["McAnally", 67.008],
        ["Martines", 67.01],
        ["Manor", 67.011],
        ["Malcom", 67.013],
        ["Lemley", 67.015],
        ["Larochelle", 67.016],
        ["Klaus", 67.018],
        ["Klatt", 67.02],
        ["Kaufmann", 67.021],
        ["Kapp", 67.023],
        ["Helmer", 67.025],
        ["Hedge", 67.026],
        ["Halloran", 67.028],
        ["Glisson", 67.03],
        ["Frechette", 67.031],
        ["Fontana", 67.033],
        ["Enoch", 67.035],
        ["Eagan", 67.036],
        ["Drum", 67.038],
        ["Distefano", 67.039],
        ["Danley", 67.041],
        ["Creekmore", 67.043],
        ["Chartier", 67.044],
        ["Chaffee", 67.046],
        ["Carillo", 67.048],
        ["Burg", 67.049],
        ["Bolinger", 67.051],
        ["Berkley", 67.053],
        ["Benz", 67.054],
        ["Basso", 67.056],
        ["Bash", 67.058],
        ["Barrier", 67.059],
        ["Zelaya", 67.061],
        ["Woodring", 67.063],
        ["Witkowski", 67.064],
        ["Wilmot", 67.066],
        ["Wilkens", 67.067],
        ["Wieland", 67.069],
        ["Virgil", 67.071],
        ["Verdugo", 67.072],
        ["Urquhart", 67.074],
        ["Tsai", 67.076],
        ["Timms", 67.077],
        ["Swiger", 67.079],
        ["Swaim", 67.081],
        ["Sussman", 67.082],
        ["Scarlett", 67.084],
        ["Pires", 67.086],
        ["Molnar", 67.087],
        ["McAtee", 67.089],
        ["Maurice", 67.09],
        ["Lowder", 67.092],
        ["Loos", 67.094],
        ["Linker", 67.095],
        ["Landes", 67.097],
        ["Kingery", 67.099],
        ["Keeley", 67.1],
        ["Hufford", 67.102],
        ["Higa", 67.104],
        ["Hendren", 67.105],
        ["Hammack", 67.107],
        ["Hamann", 67.108],
        ["Gillam", 67.11],
        ["Gerhardt", 67.112],
        ["Fell", 67.113],
        ["Eugene", 67.115],
        ["Edelman", 67.117],
        ["Eby", 67.118],
        ["Delk", 67.12],
        ["Deans", 67.122],
        ["Curl", 67.123],
        ["Constantine", 67.125],
        ["Cleaver", 67.126],
        ["Claar", 67.128],
        ["Casiano", 67.13],
        ["Carruth", 67.131],
        ["Carlyle", 67.133],
        ["Bump", 67.135],
        ["Brophy", 67.136],
        ["Bolanos", 67.138],
        ["Bibbs", 67.14],
        ["Bessette", 67.141],
        ["Beggs", 67.143],
        ["Baugher", 67.144],
        ["Bartel", 67.146],
        ["Averill", 67.148],
        ["Andresen", 67.149],
        ["Amin", 67.151],
        ["Alden", 67.153],
        ["Adames", 67.154],
        ["Wildman", 67.156],
        ["Via", 67.158],
        ["Valente", 67.159],
        ["Turnbow", 67.161],
        ["Tse", 67.162],
        ["Swink", 67.164],
        ["Sublett", 67.166],
        ["Stroh", 67.167],
        ["Stringfellow", 67.169],
        ["Ridgway", 67.17],
        ["Pugliese", 67.172],
        ["Poteat", 67.174],
        ["Pang", 67.175],
        ["Ohare", 67.177],
        ["Neubauer", 67.179],
        ["Murchison", 67.18],
        ["Mohamed", 67.182],
        ["Mingo", 67.183],
        ["Lucky", 67.185],
        ["Lemmons", 67.187],
        ["Kwon", 67.188],
        ["Kellam", 67.19],
        ["Kean", 67.192],
        ["Jarmon", 67.193],
        ["Hyden", 67.195],
        ["Hudak", 67.196],
        ["Hollinger", 67.198],
        ["Henkel", 67.2],
        ["Hemingway", 67.201],
        ["Hasson", 67.203],
        ["Hansel", 67.205],
        ["Halter", 67.206],
        ["Haire", 67.208],
        ["Goodnight", 67.209],
        ["Ginsberg", 67.211],
        ["Gillispie", 67.213],
        ["Fogel", 67.214],
        ["Flory", 67.216],
        ["Etter", 67.218],
        ["Elledge", 67.219],
        ["Eckman", 67.221],
        ["Deas", 67.222],
        ["Currin", 67.224],
        ["Crafton", 67.226],
        ["Coomer", 67.227],
        ["Colter", 67.229],
        ["Claxton", 67.23],
        ["Bulter", 67.232],
        ["Braddock", 67.234],
        ["Bowyer", 67.235],
        ["Blizzard", 67.237],
        ["Binns", 67.239],
        ["Bing", 67.24],
        ["Bellows", 67.242],
        ["Baskerville", 67.243],
        ["Barros", 67.245],
        ["Ansley", 67.247],
        ["Woolf", 67.248],
        ["Wight", 67.25],
        ["Waldman", 67.252],
        ["Wadley", 67.253],
        ["Tull", 67.255],
        ["Trull", 67.256],
        ["Tesch", 67.258],
        ["Struck", 67.26],
        ["Stouffer", 67.261],
        ["Stadler", 67.263],
        ["Slay", 67.264],
        ["Shubert", 67.266],
        ["Sedillo", 67.268],
        ["Santacruz", 67.269],
        ["Reinke", 67.271],
        ["Raleigh", 67.272],
        ["Poynter", 67.274],
        ["Neri", 67.276],
        ["Neale", 67.277],
        ["Natividad", 67.279],
        ["Mowry", 67.28],
        ["Moralez", 67.282],
        ["Monger", 67.284],
        ["Mitchum", 67.285],
        ["Merryman", 67.287],
        ["Manion", 67.288],
        ["MacDougall", 67.29],
        ["Lux", 67.292],
        ["Litchfield", 67.293],
        ["Ley", 67.295],
        ["Levitt", 67.296],
        ["Lepage", 67.298],
        ["Lasalle", 67.3],
        ["Laine", 67.301],
        ["Khoury", 67.303],
        ["Kavanagh", 67.305],
        ["Karns", 67.306],
        ["Ivie", 67.308],
        ["Huebner", 67.309],
        ["Hodgkins", 67.311],
        ["Halpin", 67.313],
        ["Garica", 67.314],
        ["Eversole", 67.316],
        ["Dutra", 67.317],
        ["Dunagan", 67.319],
        ["Duffey", 67.321],
        ["Dillman", 67.322],
        ["Dillion", 67.324],
        ["Deville", 67.325],
        ["Dearborn", 67.327],
        ["Damato", 67.329],
        ["Courson", 67.33],
        ["Coulson", 67.332],
        ["Burdine", 67.333],
        ["Bryce", 67.335],
        ["Bousquet", 67.337],
        ["Bonin", 67.338],
        ["Bish", 67.34],
        ["Atencio", 67.341],
        ["Westbrooks", 67.343],
        ["Wages", 67.345],
        ["Vaca", 67.346],
        ["Tye", 67.348],
        ["Toner", 67.349],
        ["Tomas", 67.351],
        ["Tillis", 67.353],
        ["Swett", 67.354],
        ["Surface", 67.356],
        ["Struble", 67.357],
        ["Stanfill", 67.359],
        ["Son", 67.361],
        ["Solorzano", 67.362],
        ["Slusher", 67.364],
        ["Sipple", 67.365],
        ["Sim", 67.367],
        ["Silvas", 67.368],
        ["Shults", 67.37],
        ["Schexnayder", 67.372],
        ["Saez", 67.373],
        ["Rodas", 67.375],
        ["Rager", 67.376],
        ["Pulver", 67.378],
        ["Plaza", 67.38],
        ["Penton", 67.381],
        ["Paniagua", 67.383],
        ["Meneses", 67.384],
        ["McFarlin", 67.386],
        ["McAuley", 67.388],
        ["Matz", 67.389],
        ["Maloy", 67.391],
        ["Magruder", 67.392],
        ["Lohman", 67.394],
        ["Landa", 67.395],
        ["Lacombe", 67.397],
        ["Jaimes", 67.399],
        ["Hom", 67.4],
        ["Holzer", 67.402],
        ["Holst", 67.403],
        ["Heil", 67.405],
        ["Hackler", 67.407],
        ["Grundy", 67.408],
        ["Gregor", 67.41],
        ["Gilkey", 67.411],
        ["Farnham", 67.413],
        ["Durfee", 67.415],
        ["Dunton", 67.416],
        ["Dunston", 67.418],
        ["Duda", 67.419],
        ["Dews", 67.421],
        ["Dana", 67.423],
        ["Craver", 67.424],
        ["Corriveau", 67.426],
        ["Conwell", 67.427],
        ["Colella", 67.429],
        ["Chambless", 67.43],
        ["Bremer", 67.432],
        ["Boutte", 67.434],
        ["Bourassa", 67.435],
        ["Blaisdell", 67.437],
        ["Backman", 67.438],
        ["Babineaux", 67.44],
        ["Audette", 67.442],
        ["Alleman", 67.443],
        ["Towner", 67.445],
        ["Taveras", 67.446],
        ["Tarango", 67.448],
        ["Sullins", 67.449],
        ["Suiter", 67.451],
        ["Stallard", 67.453],
        ["Solberg", 67.454],
        ["Schlueter", 67.456],
        ["Poulos", 67.457],
        ["Pimental", 67.459],
        ["Owsley", 67.461],
        ["Olivier", 67.462],
        ["Okelley", 67.464],
        ["Nations", 67.465],
        ["Moffatt", 67.467],
        ["Metcalfe", 67.468],
        ["Meekins", 67.47],
        ["Medellin", 67.472],
        ["McGlynn", 67.473],
        ["McCowan", 67.475],
        ["Marriott", 67.476],
        ["Marable", 67.478],
        ["Lennox", 67.479],
        ["Lamoureux", 67.481],
        ["Koss", 67.483],
        ["Kerby", 67.484],
        ["Karp", 67.486],
        ["Jason", 67.487],
        ["Isenberg", 67.489],
        ["Howze", 67.49],
        ["Hockenberry", 67.492],
        ["Highsmith", 67.494],
        ["Harbour", 67.495],
        ["Hallmark", 67.497],
        ["Gusman", 67.498],
        ["Greeley", 67.5],
        ["Giddings", 67.501],
        ["Gaudet", 67.503],
        ["Gallup", 67.505],
        ["Fleenor", 67.506],
        ["Eicher", 67.508],
        ["Edington", 67.509],
        ["Dimaggio", 67.511],
        ["Dement", 67.512],
        ["Demello", 67.514],
        ["Decastro", 67.516],
        ["Cruise", 67.517],
        ["Bushman", 67.519],
        ["Brundage", 67.52],
        ["Brooker", 67.522],
        ["Brooke", 67.523],
        ["Bourg", 67.525],
        ["Board", 67.527],
        ["Blackstock", 67.528],
        ["Bergmann", 67.53],
        ["Beaton", 67.531],
        ["Banister", 67.533],
        ["Argo", 67.534],
        ["Appling", 67.536],
        ["Wortman", 67.538],
        ["Watterson", 67.539],
        ["Villalpando", 67.541],
        ["Tillotson", 67.542],
        ["Tighe", 67.544],
        ["Sundberg", 67.545],
        ["Sternberg", 67.547],
        ["Stamey", 67.549],
        ["Speaks", 67.55],
        ["Shipe", 67.552],
        ["Seeger", 67.553],
        ["Scarberry", 67.555],
        ["Sattler", 67.556],
        ["Sain", 67.558],
        ["Rothstein", 67.559],
        ["Poteet", 67.561],
        ["Plowman", 67.563],
        ["Pettiford", 67.564],
        ["Penland", 67.566],
        ["Peach", 67.567],
        ["Partain", 67.569],
        ["Pankey", 67.57],
        ["Oyler", 67.572],
        ["Ogletree", 67.573],
        ["Ogburn", 67.575],
        ["Moton", 67.577],
        ["Million", 67.578],
        ["Merkel", 67.58],
        ["Mask", 67.581],
        ["Markus", 67.583],
        ["Lucier", 67.584],
        ["Lazarus", 67.586],
        ["Lavelle", 67.587],
        ["Lakey", 67.589],
        ["Kratz", 67.591],
        ["Kinser", 67.592],
        ["Kershaw", 67.594],
        ["Josephson", 67.595],
        ["Jesse", 67.597],
        ["Imhoff", 67.598],
        ["Ibanez", 67.6],
        ["Hendry", 67.601],
        ["Hammon", 67.603],
        ["Frisbie", 67.605],
        ["Friedrich", 67.606],
        ["Frawley", 67.608],
        ["Fraga", 67.609],
        ["Forester", 67.611],
        ["Eskew", 67.612],
        ["Emmert", 67.614],
        ["Drennan", 67.616],
        ["Doyon", 67.617],
        ["Dominick", 67.619],
        ["Dandridge", 67.62],
        ["Cumming", 67.622],
        ["Cawley", 67.623],
        ["Carvajal", 67.625],
        ["Bracey", 67.626],
        ["Belisle", 67.628],
        ["Batey", 67.63],
        ["Ahner", 67.631],
        ["Wysocki", 67.633],
        ["Weiser", 67.634],
        ["Veliz", 67.636],
        ["Tincher", 67.637],
        ["Sherlock", 67.639],
        ["Santo", 67.64],
        ["Sansone", 67.642],
        ["Sankey", 67.643],
        ["Sandstrom", 67.645],
        ["Sale", 67.647],
        ["Rohrer", 67.648],
        ["Risner", 67.65],
        ["Pridemore", 67.651],
        ["Pfeffer", 67.653],
        ["Persinger", 67.654],
        ["Peery", 67.656],
        ["Oubre", 67.657],
        ["Orange", 67.659],
        ["Nowicki", 67.66],
        ["Musgrave", 67.662],
        ["Murdoch", 67.663],
        ["Mullinax", 67.665],
        ["McCary", 67.667],
        ["Mathieu", 67.668],
        ["Livengood", 67.67],
        ["Leonardo", 67.671],
        ["Kyser", 67.673],
        ["Klink", 67.674],
        ["Kimes", 67.676],
        ["Kellner", 67.677],
        ["Kavanaugh", 67.679],
        ["Kasten", 67.68],
        ["Imes", 67.682],
        ["Hoey", 67.684],
        ["Hinshaw", 67.685],
        ["Halley", 67.687],
        ["Hake", 67.688],
        ["Gurule", 67.69],
        ["Grube", 67.691],
        ["Grillo", 67.693],
        ["Geter", 67.694],
        ["Gatto", 67.696],
        ["Garver", 67.697],
        ["Garretson", 67.699],
        ["Farwell", 67.7],
        ["Eiland", 67.702],
        ["Dunford", 67.704],
        ["Decarlo", 67.705],
        ["Corso", 67.707],
        ["Core", 67.708],
        ["Colman", 67.71],
        ["Collard", 67.711],
        ["Cleghorn", 67.713],
        ["Chasteen", 67.714],
        ["Cavender", 67.716],
        ["Carlile", 67.717],
        ["Calvo", 67.719],
        ["Byerly", 67.721],
        ["Brogdon", 67.722],
        ["Broadwater", 67.724],
        ["Breault", 67.725],
        ["Bono", 67.727],
        ["Bergin", 67.728],
        ["Behr", 67.73],
        ["Ballenger", 67.731],
        ["Amick", 67.733],
        ["Yan", 67.734],
        ["Vice", 67.736],
        ["Tamez", 67.737],
        ["Stiffler", 67.739],
        ["Steinke", 67.74],
        ["Simmon", 67.742],
        ["Shankle", 67.744],
        ["Schaller", 67.745],
        ["Salmons", 67.747],
        ["Sackett", 67.748],
        ["Saad", 67.75],
        ["Rideout", 67.751],
        ["Reader", 67.753],
        ["Ratcliffe", 67.754],
        ["Rao", 67.756],
        ["Ranson", 67.757],
        ["Randell", 67.759],
        ["Plascencia", 67.76],
        ["Petterson", 67.762],
        ["Olszewski", 67.763],
        ["Olney", 67.765],
        ["Olguin", 67.766],
        ["Nilsson", 67.768],
        ["Nevels", 67.769],
        ["Morelli", 67.771],
        ["Montiel", 67.773],
        ["Monge", 67.774],
        ["Michell", 67.776],
        ["Michaelson", 67.777],
        ["Mertens", 67.779],
        ["McChesney", 67.78],
        ["McAlpin", 67.782],
        ["Mathewson", 67.783],
        ["Lower", 67.785],
        ["Loudermilk", 67.786],
        ["Lineberry", 67.788],
        ["Liggett", 67.789],
        ["Lamp", 67.791],
        ["Kinlaw", 67.792],
        ["Kight", 67.794],
        ["Just", 67.795],
        ["Jost", 67.797],
        ["Hereford", 67.798],
        ["Hardeman", 67.8],
        ["Halpern", 67.802],
        ["Halliday", 67.803],
        ["Hafer", 67.805],
        ["Gaul", 67.806],
        ["Friel", 67.808],
        ["Freitag", 67.809],
        ["Frances", 67.811],
        ["Forsberg", 67.812],
        ["Evangelista", 67.814],
        ["Doering", 67.815],
        ["Dicarlo", 67.817],
        ["Dendy", 67.818],
        ["Delp", 67.82],
        ["Deguzman", 67.821],
        ["Dameron", 67.823],
        ["Curtiss", 67.824],
        ["Cousin", 67.826],
        ["Cosper", 67.827],
        ["Charley", 67.829],
        ["Cauthen", 67.831],
        ["Cao", 67.832],
        ["Camper", 67.834],
        ["Bradberry", 67.835],
        ["Bouton", 67.837],
        ["Bonnell", 67.838],
        ["Bixby", 67.84],
        ["Bieber", 67.841],
        ["Beveridge", 67.843],
        ["Belle", 67.844],
        ["Bedwell", 67.846],
        ["Barhorst", 67.847],
        ["Bannon", 67.849],
        ["Baltazar", 67.85],
        ["Baier", 67.852],
        ["Ayotte", 67.853],
        ["Attaway", 67.855],
        ["Arenas", 67.856],
        ["Alex", 67.858],
        ["Abrego", 67.86],
        ["Watford", 67.861],
        ["Valley", 67.863],
        ["Turgeon", 67.864],
        ["Tunstall", 67.866],
        ["Thaxton", 67.867],
        ["Thai", 67.869],
        ["Tenorio", 67.87],
        ["Stotts", 67.872],
        ["Sthilaire", 67.873],
        ["Spiker", 67.875],
        ["Shedd", 67.876],
        ["Seng", 67.878],
        ["Seabolt", 67.879],
        ["Scalf", 67.881],
        ["Salyers", 67.882],
        ["Ruhl", 67.884],
        ["Rowlett", 67.885],
        ["Robinett", 67.887],
        ["Pfister", 67.888],
        ["Perlman", 67.89],
        ["Pepe", 67.891],
        ["Parkman", 67.893],
        ["Paradise", 67.894],
        ["Olin", 67.896],
        ["Nunnally", 67.897],
        ["Norvell", 67.899],
        ["Napper", 67.9],
        ["Modlin", 67.902],
        ["McKellar", 67.903],
        ["McClean", 67.905],
        ["Mascarenas", 67.906],
        ["Manchester", 67.908],
        ["Leibowitz", 67.909],
        ["Ledezma", 67.911],
        ["Kuhlman", 67.912],
        ["Kobayashi", 67.914],
        ["Hunley", 67.915],
        ["Holmquist", 67.917],
        ["Hinkley", 67.918],
        ["Hazard", 67.92],
        ["Hartsell", 67.921],
        ["Gribble", 67.923],
        ["Gravely", 67.924],
        ["Fifield", 67.926],
        ["Eliason", 67.927],
        ["Doctor", 67.929],
        ["Doak", 67.931],
        ["Crossland", 67.932],
        ["Cover", 67.934],
        ["Clair", 67.935],
        ["Carleton", 67.937],
        ["Butters", 67.938],
        ["Bridgeman", 67.94],
        ["Bojorquez", 67.941],
        ["Boggess", 67.943],
        ["Banker", 67.944],
        ["Auten", 67.946],
        ["Woosley", 67.947],
        ["Wine", 67.949],
        ["Whiteley", 67.95],
        ["Wexler", 67.952],
        ["Twomey", 67.953],
        ["Tullis", 67.955],
        ["Townley", 67.956],
        ["To", 67.958],
        ["Standridge", 67.959],
        ["Stamp", 67.961],
        ["Springs", 67.962],
        ["Santoyo", 67.964],
        ["Rueda", 67.965],
        ["Riendeau", 67.967],
        ["Revell", 67.968],
        ["Pless", 67.97],
        ["Ottinger", 67.971],
        ["Nigro", 67.973],
        ["Nickles", 67.974],
        ["Mulvey", 67.976],
        ["Menefee", 67.977],
        ["McShane", 67.978],
        ["McLoughlin", 67.98],
        ["McKinzie", 67.981],
        ["Marrow", 67.983],
        ["Markey", 67.984],
        ["Mariano", 67.986],
        ["Lockridge", 67.987],
        ["Lipsey", 67.989],
        ["Knisley", 67.99],
        ["Knepper", 67.992],
        ["Kitts", 67.993],
        ["Kiel", 67.995],
        ["Jinks", 67.996],
        ["Hathcock", 67.998],
        ["Godin", 67.999],
        ["Gallego", 68.001],
        ["Fikes", 68.002],
        ["Fecteau", 68.004],
        ["Estabrook", 68.005],
        ["Ellinger", 68.007],
        ["Dustin", 68.008],
        ["Dunlop", 68.01],
        ["Dudek", 68.011],
        ["Diego", 68.013],
        ["Countryman", 68.014],
        ["Chauvin", 68.016],
        ["Chatham", 68.017],
        ["Bullins", 68.019],
        ["Brownfield", 68.02],
        ["Boughton", 68.022],
        ["Bloodworth", 68.023],
        ["Bibb", 68.025],
        ["Baucom", 68.026],
        ["Barbieri", 68.028],
        ["Aubin", 68.029],
        ["Armitage", 68.031],
        ["Alessi", 68.032],
        ["Absher", 68.034],
        ["Abbate", 68.035],
        ["Zito", 68.037],
        ["Woolery", 68.038],
        ["Wiggs", 68.04],
        ["Wacker", 68.041],
        ["Violette", 68.043],
        ["Tynes", 68.044],
        ["Tolle", 68.046],
        ["Telles", 68.047],
        ["Tarter", 68.049],
        ["Swarey", 68.05],
        ["Strode", 68.052],
        ["Stockdale", 68.053],
        ["Stella", 68.055],
        ["Stalnaker", 68.056],
        ["Spina", 68.057],
        ["Schiff", 68.059],
        ["Saari", 68.06],
        ["Risley", 68.062],
        ["Reading", 68.063],
        ["Rameriz", 68.065],
        ["Rakes", 68.066],
        ["Pettaway", 68.068],
        ["Penner", 68.069],
        ["Paulus", 68.071],
        ["Palladino", 68.072],
        ["Omeara", 68.074],
        ["Montelongo", 68.075],
        ["Melnick", 68.077],
        ["Mehta", 68.078],
        ["McGary", 68.08],
        ["McCourt", 68.081],
        ["McCollough", 68.083],
        ["Marchetti", 68.084],
        ["Manzanares", 68.086],
        ["Lowther", 68.087],
        ["Leiva", 68.089],
        ["Lauderdale", 68.09],
        ["Lafontaine", 68.091],
        ["Kowalczyk", 68.093],
        ["Knighton", 68.094],
        ["Joubert", 68.096],
        ["Jaworski", 68.097],
        ["Ide", 68.099],
        ["Huth", 68.1],
        ["Hurdle", 68.102],
        ["Hung", 68.103],
        ["Housley", 68.105],
        ["Hackman", 68.106],
        ["Gulick", 68.108],
        ["Gordy", 68.109],
        ["Gilstrap", 68.111],
        ["Gehrke", 68.112],
        ["Gebhart", 68.114],
        ["Gaudette", 68.115],
        ["Foxworth", 68.117],
        ["Finger", 68.118],
        ["Essex", 68.12],
        ["Endres", 68.121],
        ["Dunkle", 68.123],
        ["Clare", 68.124],
        ["Cimino", 68.125],
        ["Cardinal", 68.127],
        ["Caddell", 68.128],
        ["Brauer", 68.13],
        ["Braley", 68.131],
        ["Bodine", 68.133],
        ["Blackmore", 68.134],
        ["Belden", 68.136],
        ["Backer", 68.137],
        ["Ayer", 68.139],
        ["Andress", 68.14],
        ["Alva", 68.142],
        ["Wisner", 68.143],
        ["Walk", 68.145],
        ["Vuong", 68.146],
        ["Valliere", 68.148],
        ["Twigg", 68.149],
        ["Tso", 68.151],
        ["Tavarez", 68.152],
        ["Strahan", 68.153],
        ["Steib", 68.155],
        ["Staub", 68.156],
        ["Sowder", 68.158],
        ["Shoulders", 68.159],
        ["Seiber", 68.161],
        ["Schutt", 68.162],
        ["Scharf", 68.164],
        ["Schade", 68.165],
        ["Rodriques", 68.167],
        ["Risinger", 68.168],
        ["Renshaw", 68.17],
        ["Rath", 68.171],
        ["Rahman", 68.172],
        ["Presnell", 68.174],
        ["Pillow", 68.175],
        ["Piatt", 68.177],
        ["Pasquale", 68.178],
        ["Nieman", 68.18],
        ["Nicol", 68.181],
        ["Nevins", 68.183],
        ["Milford", 68.184],
        ["McIlwain", 68.186],
        ["McGaha", 68.187],
        ["McCully", 68.189],
        ["McComb", 68.19],
        ["Maye", 68.191],
        ["Massengale", 68.193],
        ["Macedo", 68.194],
        ["Lines", 68.196],
        ["Lesher", 68.197],
        ["Leland", 68.199],
        ["Kearse", 68.2],
        ["Jauregui", 68.202],
        ["Husted", 68.203],
        ["Hudnall", 68.205],
        ["Holmberg", 68.206],
        ["Hertel", 68.208],
        ["Hershey", 68.209],
        ["Hardie", 68.21],
        ["Glidewell", 68.212],
        ["Frausto", 68.213],
        ["Fassett", 68.215],
        ["Dash", 68.216],
        ["Dalessandro", 68.218],
        ["Dahlgren", 68.219],
        ["Corum", 68.221],
        ["Constantino", 68.222],
        ["Conlin", 68.224],
        ["Colquitt", 68.225],
        ["Colombo", 68.227],
        ["Claycomb", 68.228],
        ["Carley", 68.229],
        ["Cardin", 68.231],
        ["Cancel", 68.232],
        ["Buller", 68.234],
        ["Boring", 68.235],
        ["Boney", 68.237],
        ["Bocanegra", 68.238],
        ["Blazer", 68.24],
        ["Biggers", 68.241],
        ["Benedetto", 68.243],
        ["Araiza", 68.244],
        ["Andino", 68.246],
        ["Albin", 68.247],
        ["Zorn", 68.248],
        ["Werth", 68.25],
        ["Weisman", 68.251],
        ["Walley", 68.253],
        ["Vanegas", 68.254],
        ["Ulibarri", 68.256],
        ["Towers", 68.257],
        ["Towe", 68.259],
        ["Tedford", 68.26],
        ["Teasley", 68.262],
        ["Suttle", 68.263],
        ["Steffens", 68.264],
        ["Stcyr", 68.266],
        ["Squire", 68.267],
        ["Smythe", 68.269],
        ["Singley", 68.27],
        ["Sifuentes", 68.272],
        ["Shuck", 68.273],
        ["Session", 68.275],
        ["Schram", 68.276],
        ["Sass", 68.277],
        ["Rieger", 68.279],
        ["Ridenhour", 68.28],
        ["Rickert", 68.282],
        ["Richerson", 68.283],
        ["Rayborn", 68.285],
        ["Rabe", 68.286],
        ["Raab", 68.288],
        ["Pendley", 68.289],
        ["Pastore", 68.29],
        ["Ordway", 68.292],
        ["Moynihan", 68.293],
        ["Mellott", 68.295],
        ["McKissick", 68.296],
        ["McGann", 68.298],
        ["McCready", 68.299],
        ["Mauney", 68.301],
        ["Marrufo", 68.302],
        ["List", 68.303],
        ["Lenhart", 68.305],
        ["Lazar", 68.306],
        ["Lafave", 68.308],
        ["Keele", 68.309],
        ["Kautz", 68.311],
        ["Jardine", 68.312],
        ["Jahnke", 68.314],
        ["Jacobo", 68.315],
        ["Hord", 68.316],
        ["Hardcastle", 68.318],
        ["Hageman", 68.319],
        ["Griffey", 68.321],
        ["Giglio", 68.322],
        ["Gehring", 68.324],
        ["Fortson", 68.325],
        ["Duque", 68.327],
        ["Duplessis", 68.328],
        ["Donner", 68.329],
        ["Dicken", 68.331],
        ["Derosier", 68.332],
        ["Deitz", 68.334],
        ["Dalessio", 68.335],
        ["Cyrus", 68.337],
        ["Cram", 68.338],
        ["Chi", 68.34],
        ["Center", 68.341],
        ["Castleman", 68.343],
        ["Candelario", 68.344],
        ["Callison", 68.345],
        ["Caceres", 68.347],
        ["Bozarth", 68.348],
        ["Biles", 68.35],
        ["Bejarano", 68.351],
        ["Beech", 68.353],
        ["Bashaw", 68.354],
        ["Avina", 68.356],
        ["Armentrout", 68.357],
        ["Angus", 68.358],
        ["Alverez", 68.36],
        ["Acord", 68.361],
        ["Zack", 68.363],
        ["Waterhouse", 68.364],
        ["Vereen", 68.366],
        ["VanLandingham", 68.367],
        ["Uhl", 68.368],
        ["Strawser", 68.37],
        ["Shotwell", 68.371],
        ["Severance", 68.373],
        ["Seltzer", 68.374],
        ["Schoonmaker", 68.376],
        ["Schock", 68.377],
        ["Schaub", 68.378],
        ["Schaffner", 68.38],
        ["Roeder", 68.381],
        ["Rodrigez", 68.383],
        ["Riffe", 68.384],
        ["Rhine", 68.386],
        ["Rasberry", 68.387],
        ["Rancourt", 68.389],
        ["Railey", 68.39],
        ["Quade", 68.391],
        ["Pursley", 68.393],
        ["Prouty", 68.394],
        ["Perdomo", 68.396],
        ["Oxley", 68.397],
        ["Osterman", 68.399],
        ["Nickens", 68.4],
        ["Murphree", 68.401],
        ["Mounts", 68.403],
        ["Monte", 68.404],
        ["Merida", 68.406],
        ["Maus", 68.407],
        ["Mattern", 68.409],
        ["Masse", 68.41],
        ["Martinelli", 68.411],
        ["Mangan", 68.413],
        ["Lutes", 68.414],
        ["Ludwick", 68.416],
        ["Loney", 68.417],
        ["Laureano", 68.419],
        ["Lasater", 68.42],
        ["Knighten", 68.421],
        ["Kissinger", 68.423],
        ["Kimsey", 68.424],
        ["Kessinger", 68.426],
        ["Honea", 68.427],
        ["Hollingshead", 68.429],
        ["Hockett", 68.43],
        ["Heyer", 68.431],
        ["Heron", 68.433],
        ["Gurrola", 68.434],
        ["Gove", 68.436],
        ["Glasscock", 68.437],
        ["Gillett", 68.439],
        ["Galan", 68.44],
        ["Featherstone", 68.441],
        ["Eckhardt", 68.443],
        ["Duron", 68.444],
        ["Dunson", 68.446],
        ["Dasher", 68.447],
        ["Culbreth", 68.449],
        ["Cowden", 68.45],
        ["Cowans", 68.451],
        ["Claypoole", 68.453],
        ["Churchwell", 68.454],
        ["Chabot", 68.456],
        ["Caviness", 68.457],
        ["Cater", 68.459],
        ["Caston", 68.46],
        ["Callan", 68.461],
        ["Byington", 68.463],
        ["Burkey", 68.464],
        ["Boden", 68.466],
        ["Beckford", 68.467],
        ["Atwater", 68.469],
        ["Arms", 68.47],
        ["Archambault", 68.471],
        ["Alvey", 68.473],
        ["Alsup", 68.474],
        ["Yon", 68.476],
        ["Whisenant", 68.477],
        ["Weese", 68.479],
        ["Voyles", 68.48],
        ["Verret", 68.481],
        ["Tsang", 68.483],
        ["Tessier", 68.484],
        ["Sweitzer", 68.486],
        ["Sherwin", 68.487],
        ["Shaughnessy", 68.489],
        ["Revis", 68.49],
        ["Remy", 68.491],
        ["Prine", 68.493],
        ["Philpott", 68.494],
        ["Peavy", 68.496],
        ["Paynter", 68.497],
        ["Parmenter", 68.498],
        ["Ovalle", 68.5],
        ["Offutt", 68.501],
        ["Nightingale", 68.503],
        ["Newlin", 68.504],
        ["Nakano", 68.505],
        ["Myatt", 68.507],
        ["Muth", 68.508],
        ["Mohan", 68.51],
        ["McMillon", 68.511],
        ["McCarley", 68.513],
        ["McCaleb", 68.514],
        ["Maxson", 68.515],
        ["Marinelli", 68.517],
        ["Maley", 68.518],
        ["Macy", 68.52],
        ["Liston", 68.521],
        ["Letendre", 68.522],
        ["Kain", 68.524],
        ["Huntsman", 68.525],
        ["Hirst", 68.527],
        ["Hagerty", 68.528],
        ["Gulledge", 68.53],
        ["Greenway", 68.531],
        ["Grajeda", 68.532],
        ["Gorton", 68.534],
        ["Goines", 68.535],
        ["Gittens", 68.537],
        ["Frederickson", 68.538],
        ["Fanelli", 68.539],
        ["Embree", 68.541],
        ["Eichelberger", 68.542],
        ["Dunkin", 68.544],
        ["Dull", 68.545],
        ["Dixson", 68.547],
        ["Dillow", 68.548],
        ["DeFelice", 68.549],
        ["Chumley", 68.551],
        ["Burleigh", 68.552],
        ["Borkowski", 68.554],
        ["Binette", 68.555],
        ["Biggerstaff", 68.556],
        ["Berglund", 68.558],
        ["Beller", 68.559],
        ["Audet", 68.561],
        ["Arbuckle", 68.562],
        ["Allain", 68.563],
        ["Alfano", 68.565],
        ["Zander", 68.566],
        ["Youngman", 68.568],
        ["Wittman", 68.569],
        ["Weintraub", 68.571],
        ["Van Zant", 68.572],
        ["Vaden", 68.573],
        ["Twitty", 68.575],
        ["Trader", 68.576],
        ["Toon", 68.578],
        ["Till", 68.579],
        ["Stollings", 68.58],
        ["Standifer", 68.582],
        ["Spinner", 68.583],
        ["Sines", 68.584],
        ["Shope", 68.586],
        ["Scalise", 68.587],
        ["Saville", 68.589],
        ["Romans", 68.59],
        ["Posada", 68.591],
        ["Pisano", 68.593],
        ["Otte", 68.594],
        ["Nolasco", 68.596],
        ["Napoli", 68.597],
        ["Mier", 68.598],
        ["Merkle", 68.6],
        ["Mendiola", 68.601],
        ["Melcher", 68.603],
        ["Mejias", 68.604],
        ["McMurry", 68.605],
        ["McCalla", 68.607],
        ["Markowitz", 68.608],
        ["Marine", 68.61],
        ["Manis", 68.611],
        ["Mallette", 68.612],
        ["MacFarlane", 68.614],
        ["Lough", 68.615],
        ["Looper", 68.617],
        ["Landin", 68.618],
        ["Kittle", 68.619],
        ["Kinsella", 68.621],
        ["Kinnard", 68.622],
        ["Hobart", 68.624],
        ["Herald", 68.625],
        ["Helman", 68.626],
        ["Hellman", 68.628],
        ["Hartsock", 68.629],
        ["Halford", 68.631],
        ["Hage", 68.632],
        ["Gordan", 68.633],
        ["Glasser", 68.635],
        ["Gayton", 68.636],
        ["Gattis", 68.638],
        ["Gastelum", 68.639],
        ["Gaspard", 68.64],
        ["Frisch", 68.642],
        ["Force", 68.643],
        ["Fitzhugh", 68.645],
        ["Eckstein", 68.646],
        ["Eberly", 68.647],
        ["Dowden", 68.649],
        ["Despain", 68.65],
        ["Crumpler", 68.652],
        ["Crotty", 68.653],
        ["Cornelison", 68.654],
        ["Collin", 68.656],
        ["Colin", 68.657],
        ["Chouinard", 68.659],
        ["Chamness", 68.66],
        ["Catlin", 68.661],
        ["Cann", 68.663],
        ["Bumgardner", 68.664],
        ["Budde", 68.666],
        ["Branum", 68.667],
        ["Bradfield", 68.668],
        ["Braddy", 68.67],
        ["Borst", 68.671],
        ["Birdwell", 68.673],
        ["Bent", 68.674],
        ["Bazan", 68.675],
        ["Bank", 68.677],
        ["Banas", 68.678],
        ["Bade", 68.68],
        ["Aubrey", 68.681],
        ["Arango", 68.682],
        ["Ahearn", 68.684],
        ["Addis", 68.685],
        ["Zumwalt", 68.687],
        ["Wurth", 68.688],
        ["Wilk", 68.689],
        ["Widener", 68.691],
        ["Wagstaff", 68.692],
        ["Vella", 68.694],
        ["Urrutia", 68.695],
        ["Terwilliger", 68.696],
        ["Tart", 68.698],
        ["Steinman", 68.699],
        ["Staats", 68.7],
        ["Sloat", 68.702],
        ["Rives", 68.703],
        ["Riggle", 68.705],
        ["Revels", 68.706],
        ["Reichard", 68.707],
        ["Prickett", 68.709],
        ["Poff", 68.71],
        ["Pitzer", 68.712],
        ["Petro", 68.713],
        ["Pell", 68.714],
        ["Northrup", 68.716],
        ["Nicks", 68.717],
        ["Moline", 68.718],
        ["Mielke", 68.72],
        ["Maynor", 68.721],
        ["Mallon", 68.723],
        ["Magness", 68.724],
        ["Lingle", 68.725],
        ["Lindell", 68.727],
        ["Lieb", 68.728],
        ["Lesko", 68.729],
        ["LeBeau", 68.731],
        ["Lammers", 68.732],
        ["Lafond", 68.734],
        ["Kiernan", 68.735],
        ["Ketron", 68.736],
        ["Jurado", 68.738],
        ["Holmgren", 68.739],
        ["Hilburn", 68.741],
        ["Hayashi", 68.742],
        ["Hashimoto", 68.743],
        ["Harbaugh", 68.745],
        ["Hans", 68.746],
        ["Guillot", 68.747],
        ["Gard", 68.749],
        ["Froehlich", 68.75],
        ["Felipe", 68.752],
        ["Feinberg", 68.753],
        ["Falco", 68.754],
        ["Dufour", 68.756],
        ["Drees", 68.757],
        ["Doney", 68.759],
        ["Diep", 68.76],
        ["de la O", 68.761],
        ["Daves", 68.763],
        ["Dail", 68.764],
        ["Cutting", 68.765],
        ["Crowson", 68.767],
        ["Coss", 68.768],
        ["Congdon", 68.77],
        ["Carner", 68.771],
        ["Camarena", 68.772],
        ["Butterworth", 68.774],
        ["Burlingame", 68.775],
        ["Bouffard", 68.777],
        ["Bloch", 68.778],
        ["Bilyeu", 68.779],
        ["Barta", 68.781],
        ["Bakke", 68.782],
        ["Baillargeon", 68.783],
        ["Avent", 68.785],
        ["Aquilar", 68.786],
        ["Ake", 68.788],
        ["Aho", 68.789],
        ["Zeringue", 68.79],
        ["Yeh", 68.792],
        ["Yarber", 68.793],
        ["Wolfson", 68.794],
        ["Wendell", 68.796],
        ["Vogler", 68.797],
        ["Voelker", 68.799],
        ["Truss", 68.8],
        ["Troxell", 68.801],
        ["Thrift", 68.803],
        ["Strouse", 68.804],
        ["Spielman", 68.805],
        ["Sistrunk", 68.807],
        ["Shows", 68.808],
        ["Sevigny", 68.809],
        ["Schuller", 68.811],
        ["Schaaf", 68.812],
        ["Ruffner", 68.814],
        ["Routh", 68.815],
        ["Roseman", 68.816],
        ["Ricciardi", 68.818],
        ["Peraza", 68.819],
        ["Pegram", 68.82],
        ["Overturf", 68.822],
        ["Olander", 68.823],
        ["Odaniel", 68.825],
        ["Neu", 68.826],
        ["Millner", 68.827],
        ["Melchor", 68.829],
        ["Maxie", 68.83],
        ["Marvel", 68.831],
        ["Maroney", 68.833],
        ["Machuca", 68.834],
        ["Macaluso", 68.835],
        ["Livesay", 68.837],
        ["Layfield", 68.838],
        ["Laskowski", 68.84],
        ["Kwiatkowski", 68.841],
        ["Ko", 68.842],
        ["Kiley", 68.844],
        ["Kilby", 68.845],
        ["Julien", 68.846],
        ["Hovey", 68.848],
        ["Heywood", 68.849],
        ["Hayman", 68.85],
        ["Havard", 68.852],
        ["Harville", 68.853],
        ["Haigh", 68.855],
        ["Hagood", 68.856],
        ["Grieco", 68.857],
        ["Glassman", 68.859],
        ["Gebhardt", 68.86],
        ["Garry", 68.861],
        ["Freeze", 68.863],
        ["Fleischer", 68.864],
        ["Fann", 68.866],
        ["Elson", 68.867],
        ["Eccles", 68.868],
        ["Cunha", 68.87],
        ["Crumb", 68.871],
        ["Crew", 68.872],
        ["Blakley", 68.874],
        ["Bardwell", 68.875],
        ["Abshire", 68.876],
        ["Woodham", 68.878],
        ["Wines", 68.879],
        ["Welter", 68.881],
        ["Wargo", 68.882],
        ["Varnado", 68.883],
        ["Tutt", 68.885],
        ["Traynor", 68.886],
        ["Swaney", 68.887],
        ["Svoboda", 68.889],
        ["Stricker", 68.89],
        ["Stoffel", 68.891],
        ["Stambaugh", 68.893],
        ["Sickler", 68.894],
        ["Shackleford", 68.895],
        ["Selman", 68.897],
        ["Seaver", 68.898],
        ["Sansom", 68.899],
        ["Sanmiguel", 68.901],
        ["Royston", 68.902],
        ["Rourke", 68.903],
        ["Rockett", 68.905],
        ["Rioux", 68.906],
        ["Puleo", 68.908],
        ["Pitchford", 68.909],
        ["Persons", 68.91],
        ["Normand", 68.912],
        ["Nardi", 68.913],
        ["Mulvaney", 68.914],
        ["Middaugh", 68.916],
        ["Manners", 68.917],
        ["Malek", 68.918],
        ["Lodge", 68.92],
        ["Leos", 68.921],
        ["Lathan", 68.922],
        ["Kujawa", 68.924],
        ["Kimbro", 68.925],
        ["Killebrew", 68.926],
        ["Joshua", 68.928],
        ["Houlihan", 68.929],
        ["Hobby", 68.931],
        ["Hinckley", 68.932],
        ["Herod", 68.933],
        ["Hepler", 68.935],
        ["Hamner", 68.936],
        ["Hammel", 68.937],
        ["Hallowell", 68.939],
        ["Gonsalez", 68.94],
        ["Gingerich", 68.941],
        ["Gambill", 68.943],
        ["Funkhouser", 68.944],
        ["Fricke", 68.945],
        ["Fewell", 68.947],
        ["Falkner", 68.948],
        ["Endsley", 68.949],
        ["Dulin", 68.951],
        ["Drennen", 68.952],
        ["Deaver", 68.953],
        ["D'Ambrosio", 68.955],
        ["Clover", 68.956],
        ["Chadwell", 68.958],
        ["Ceasar", 68.959],
        ["Castanon", 68.96],
        ["Canon", 68.962],
        ["Burkes", 68.963],
        ["Brune", 68.964],
        ["Brisco", 68.966],
        ["Brinker", 68.967],
        ["Bowker", 68.968],
        ["Boldt", 68.97],
        ["Berner", 68.971],
        ["Bee", 68.972],
        ["Beaumont", 68.974],
        ["Beaird", 68.975],
        ["Bazemore", 68.976],
        ["Barrick", 68.978],
        ["Arnette", 68.979],
        ["Albano", 68.981],
        ["Younts", 68.982],
        ["Wunderlich", 68.983],
        ["Weidman", 68.985],
        ["Van Ness", 68.986],
        ["Tu", 68.987],
        ["Toland", 68.989],
        ["Theobald", 68.99],
        ["Stickler", 68.991],
        ["Steiger", 68.993],
        ["Stanger", 68.994],
        ["Spies", 68.995],
        ["Spector", 68.997],
        ["Sollars", 68.998],
        ["Smedley", 68.999],
        ["Seibel", 69.001],
        ["Scoville", 69.002],
        ["Saito", 69.003],
        ["Rye", 69.005],
        ["Rummel", 69.006],
        ["Rude", 69.007],
        ["Rowles", 69.009],
        ["Rouleau", 69.01],
        ["Roos", 69.011],
        ["Rogan", 69.013],
        ["Roemer", 69.014],
        ["Ream", 69.015],
        ["Raya", 69.017],
        ["Purkey", 69.018],
        ["Priester", 69.019],
        ["Perreira", 69.021],
        ["Penick", 69.022],
        ["Paulin", 69.023],
        ["Parkins", 69.025],
        ["Overcash", 69.026],
        ["Oleson", 69.027],
        ["Nicely", 69.029],
        ["Neves", 69.03],
        ["Muldrow", 69.031],
        ["Minard", 69.033],
        ["Midgett", 69.034],
        ["Michalak", 69.035],
        ["Melgar", 69.037],
        ["McEntire", 69.038],
        ["McAuliffe", 69.039],
        ["Marti", 69.041],
        ["Marte", 69.042],
        ["Lydon", 69.043],
        ["Lindholm", 69.045],
        ["Leyba", 69.046],
        ["Leader", 69.047],
        ["Langevin", 69.049],
        ["Lagasse", 69.05],
        ["Lafayette", 69.051],
        ["Kesler", 69.053],
        ["Kelton", 69.054],
        ["Kao", 69.055],
        ["Kaminsky", 69.057],
        ["Jump", 69.058],
        ["Jaggers", 69.059],
        ["Humbert", 69.061],
        ["Huck", 69.062],
        ["Howarth", 69.063],
        ["Hinrichs", 69.065],
        ["Higley", 69.066],
        ["Gupton", 69.067],
        ["Guimond", 69.069],
        ["Gravois", 69.07],
        ["Giguere", 69.071],
        ["Fretwell", 69.073],
        ["Fontes", 69.074],
        ["Feeley", 69.075],
        ["Faucher", 69.077],
        ["Fall", 69.078],
        ["Evan", 69.079],
        ["Eichhorn", 69.081],
        ["Ecker", 69.082],
        ["Earp", 69.083],
        ["Dole", 69.085],
        ["Dinger", 69.086],
        ["Derryberry", 69.087],
        ["de Mars", 69.089],
        ["Deel", 69.09],
        ["Copenhaver", 69.091],
        ["Collinsworth", 69.093],
        ["Colangelo", 69.094],
        ["Cloyd", 69.095],
        ["Claiborne", 69.097],
        ["Caulfield", 69.098],
        ["Carlsen", 69.099],
        ["Calzada", 69.101],
        ["Caffey", 69.102],
        ["Broadus", 69.103],
        ["Brenneman", 69.105],
        ["Bouie", 69.106],
        ["Bodnar", 69.107],
        ["Blaney", 69.109],
        ["Blanc", 69.11],
        ["Blades", 69.111],
        ["Beltz", 69.113],
        ["Behling", 69.114],
        ["Begin", 69.115],
        ["Barahona", 69.117],
        ["Yun", 69.118],
        ["Yockey", 69.119],
        ["Winkle", 69.121],
        ["Windom", 69.122],
        ["Wimer", 69.123],
        ["Wilford", 69.125],
        ["Wash", 69.126],
        ["Villatoro", 69.127],
        ["Trexler", 69.129],
        ["Teran", 69.13],
        ["Taliaferro", 69.131],
        ["Sydnor", 69.133],
        ["Swinson", 69.134],
        ["Snelling", 69.135],
        ["Smtih", 69.137],
        ["Siu", 69.138],
        ["Simonton", 69.139],
        ["Simoneaux", 69.14],
        ["Simoneau", 69.142],
        ["Sherrer", 69.143],
        ["Seavey", 69.144],
        ["Scheel", 69.146],
        ["Rushton", 69.147],
        ["Rupe", 69.148],
        ["Ruano", 69.15],
        ["Rodney", 69.151],
        ["Rippy", 69.152],
        ["Reiner", 69.154],
        ["Reiff", 69.155],
        ["Rabinowitz", 69.156],
        ["Quach", 69.158],
        ["Penley", 69.159],
        ["Odle", 69.16],
        ["Nock", 69.162],
        ["Minnich", 69.163],
        ["McKown", 69.164],
        ["McCarver", 69.166],
        ["McAndrew", 69.167],
        ["Longley", 69.168],
        ["Laux", 69.169],
        ["LaMothe", 69.171],
        ["Lafreniere", 69.172],
        ["Kropp", 69.173],
        ["Krick", 69.175],
        ["Kates", 69.176],
        ["Jepson", 69.177],
        ["Huie", 69.179],
        ["Howse", 69.18],
        ["Howie", 69.181],
        ["Henriques", 69.183],
        ["Haydon", 69.184],
        ["Haught", 69.185],
        ["Hatter", 69.187],
        ["Hartzog", 69.188],
        ["Harkey", 69.189],
        ["Grimaldo", 69.191],
        ["Goshorn", 69.192],
        ["Gormley", 69.193],
        ["Gluck", 69.195],
        ["Gilroy", 69.196],
        ["Gillenwater", 69.197],
        ["Giffin", 69.199],
        ["Folks", 69.2],
        ["Fluker", 69.201],
        ["Feder", 69.202],
        ["Eyre", 69.204],
        ["Eshelman", 69.205],
        ["Eakins", 69.206],
        ["Dryer", 69.208],
        ["Disney", 69.209],
        ["Detwiler", 69.21],
        ["del Rosario", 69.212],
        ["Davisson", 69.213],
        ["Celestine", 69.214],
        ["Catalan", 69.216],
        ["Canning", 69.217],
        ["Calton", 69.218],
        ["Buster", 69.22],
        ["Brammer", 69.221],
        ["Botelho", 69.222],
        ["Blakney", 69.224],
        ["Bartell", 69.225],
        ["Averett", 69.226],
        ["Askins", 69.228],
        ["Aker", 69.229],
        ["Zak", 69.23],
        ["Worcester", 69.231],
        ["Witmer", 69.233],
        ["Wiser", 69.234],
        ["Winkelman", 69.235],
        ["Widmer", 69.237],
        ["Whittier", 69.238],
        ["Western", 69.239],
        ["Weitzel", 69.241],
        ["Wardell", 69.242],
        ["Wagers", 69.243],
        ["Ullman", 69.245],
        ["Tupper", 69.246],
        ["Tingley", 69.247],
        ["Tilghman", 69.248],
        ["Talton", 69.25],
        ["Simard", 69.251],
        ["Seda", 69.252],
        ["Scheller", 69.254],
        ["Sala", 69.255],
        ["Rundell", 69.256],
        ["Rost", 69.258],
        ["Roa", 69.259],
        ["Ribeiro", 69.26],
        ["Rabideau", 69.261],
        ["Primm", 69.263],
        ["Porch", 69.264],
        ["Polite", 69.265],
        ["Pinon", 69.267],
        ["Peart", 69.268],
        ["Ostrom", 69.269],
        ["Ober", 69.271],
        ["Nystrom", 69.272],
        ["Nussbaum", 69.273],
        ["Nurse", 69.275],
        ["Naughton", 69.276],
        ["Murr", 69.277],
        ["Moorhead", 69.278],
        ["Monti", 69.28],
        ["Monteiro", 69.281],
        ["Melson", 69.282],
        ["Meissner", 69.284],
        ["McLin", 69.285],
        ["McGruder", 69.286],
        ["Marotta", 69.288],
        ["Makowski", 69.289],
        ["Majewski", 69.29],
        ["Madewell", 69.291],
        ["Lunt", 69.293],
        ["Lukens", 69.294],
        ["Leininger", 69.295],
        ["Lebel", 69.297],
        ["Lakin", 69.298],
        ["Laguna", 69.299],
        ["Kepler", 69.301],
        ["Jaques", 69.302],
        ["Hunnicutt", 69.303],
        ["Hungerford", 69.304],
        ["Hoopes", 69.306],
        ["Hertz", 69.307],
        ["Heins", 69.308],
        ["Hammers", 69.31],
        ["Halliburton", 69.311],
        ["Grosso", 69.312],
        ["Gravitt", 69.314],
        ["Glasper", 69.315],
        ["Gideon", 69.316],
        ["Gallman", 69.318],
        ["Gallaway", 69.319],
        ["Funke", 69.32],
        ["Fulbright", 69.321],
        ["Falgout", 69.323],
        ["Eakin", 69.324],
        ["Dostie", 69.325],
        ["Dorado", 69.327],
        ["Dewberry", 69.328],
        ["DeRose", 69.329],
        ["Cutshall", 69.331],
        ["Crampton", 69.332],
        ["Costanzo", 69.333],
        ["Colletti", 69.334],
        ["Cloninger", 69.336],
        ["Claytor", 69.337],
        ["Chiang", 69.338],
        ["Canterbury", 69.34],
        ["Campagna", 69.341],
        ["Burd", 69.342],
        ["Brokaw", 69.344],
        ["Broaddus", 69.345],
        ["Bretz", 69.346],
        ["Brainard", 69.348],
        ["Binford", 69.349],
        ["Bilbrey", 69.35],
        ["Alpert", 69.351],
        ["Aitken", 69.353],
        ["Ahlers", 69.354],
        ["Zajac", 69.355],
        ["Yale", 69.357],
        ["Woolfolk", 69.358],
        ["Witten", 69.359],
        ["Windle", 69.36],
        ["Wayland", 69.362],
        ["Tramel", 69.363],
        ["Tittle", 69.364],
        ["Talavera", 69.366],
        ["Suter", 69.367],
        ["Straley", 69.368],
        ["Stetson", 69.369],
        ["Specht", 69.371],
        ["Sommerville", 69.372],
        ["Soloman", 69.373],
        ["So", 69.375],
        ["Skeens", 69.376],
        ["Sigman", 69.377],
        ["Sibert", 69.378],
        ["Shavers", 69.38],
        ["Schuck", 69.381],
        ["Schmit", 69.382],
        ["Sartain", 69.384],
        ["Sabol", 69.385],
        ["Rosenblatt", 69.386],
        ["Rollo", 69.388],
        ["Rashid", 69.389],
        ["Rabb", 69.39],
        ["Province", 69.391],
        ["Polston", 69.393],
        ["Nyberg", 69.394],
        ["Northrop", 69.395],
        ["Navarra", 69.397],
        ["Muldoon", 69.398],
        ["Mulder", 69.399],
        ["Mikesell", 69.4],
        ["McDougald", 69.402],
        ["McBurney", 69.403],
        ["Mauricio", 69.404],
        ["Mariscal", 69.406],
        ["Lui", 69.407],
        ["Lozier", 69.408],
        ["Lingerfelt", 69.409],
        ["Legere", 69.411],
        ["Latour", 69.412],
        ["Lagunas", 69.413],
        ["la Cour", 69.415],
        ["Kurth", 69.416],
        ["Ku", 69.417],
        ["Killen", 69.418],
        ["Kiely", 69.42],
        ["Kayser", 69.421],
        ["Kahle", 69.422],
        ["Julius", 69.424],
        ["Isley", 69.425],
        ["Huertas", 69.426],
        ["Hower", 69.427],
        ["Hinz", 69.429],
        ["Haugh", 69.43],
        ["Gumm", 69.431],
        ["Given", 69.433],
        ["Galicia", 69.434],
        ["Fortunato", 69.435],
        ["Flake", 69.436],
        ["Dunleavy", 69.438],
        ["Duggins", 69.439],
        ["Doby", 69.44],
        ["DiGiovanni", 69.442],
        ["Devaney", 69.443],
        ["del Toro", 69.444],
        ["Cribb", 69.445],
        ["Crank", 69.447],
        ["Corpuz", 69.448],
        ["Coronel", 69.449],
        ["Comfort", 69.451],
        ["Coen", 69.452],
        ["Charbonneau", 69.453],
        ["Caine", 69.454],
        ["Burchette", 69.456],
        ["Blakey", 69.457],
        ["Blakemore", 69.458],
        ["Bergquist", 69.46],
        ["Beene", 69.461],
        ["Beaudette", 69.462],
        ["Bayles", 69.463],
        ["Ballance", 69.465],
        ["Bakker", 69.466],
        ["Bailes", 69.467],
        ["Asberry", 69.469],
        ["Arwood", 69.47],
        ["Zucker", 69.471],
        ["Willman", 69.472],
        ["Whitesell", 69.474],
        ["Wald", 69.475],
        ["Walcott", 69.476],
        ["Vancleave", 69.478],
        ["Trump", 69.479],
        ["Trail", 69.48],
        ["Strasser", 69.481],
        ["Simas", 69.483],
        ["Shorts", 69.484],
        ["Shick", 69.485],
        ["Schleicher", 69.486],
        ["Schaal", 69.488],
        ["Saleh", 69.489],
        ["Rotz", 69.49],
        ["Resnick", 69.492],
        ["Raphael", 69.493],
        ["Rainer", 69.494],
        ["Partee", 69.495],
        ["Ollis", 69.497],
        ["Oller", 69.498],
        ["Oday", 69.499],
        ["Noles", 69.5],
        ["Munday", 69.502],
        ["Mountain", 69.503],
        ["Mong", 69.504],
        ["Millican", 69.506],
        ["Merwin", 69.507],
        ["Mazzola", 69.508],
        ["Mansell", 69.509],
        ["Magallanes", 69.511],
        ["Llanes", 69.512],
        ["Lewellen", 69.513],
        ["Lepore", 69.514],
        ["Kisner", 69.516],
        ["Keesee", 69.517],
        ["Jim", 69.518],
        ["Jeanlouis", 69.52],
        ["Ingham", 69.521],
        ["Hornbeck", 69.522],
        ["Hermes", 69.523],
        ["Hawn", 69.525],
        ["Hartz", 69.526],
        ["Harber", 69.527],
        ["Haffner", 69.528],
        ["Gutshall", 69.53],
        ["Guth", 69.531],
        ["Grays", 69.532],
        ["Grams", 69.534],
        ["Gowan", 69.535],
        ["Finlay", 69.536],
        ["Finkelstein", 69.537],
        ["Eyler", 69.539],
        ["Enloe", 69.54],
        ["Dungan", 69.541],
        ["Diez", 69.542],
        ["Dearman", 69.544],
        ["Dann", 69.545],
        ["Cull", 69.546],
        ["Crosson", 69.547],
        ["Creek", 69.549],
        ["Chronister", 69.55],
        ["Cassity", 69.551],
        ["Campion", 69.553],
        ["Callihan", 69.554],
        ["Butz", 69.555],
        ["Breazeale", 69.556],
        ["Blumenthal", 69.558],
        ["Billy", 69.559],
        ["Berkey", 69.56],
        ["Batty", 69.561],
        ["Batton", 69.563],
        ["Barge", 69.564],
        ["Arvizu", 69.565],
        ["Alexis", 69.567],
        ["Alderete", 69.568],
        ["Aldana", 69.569],
        ["Albaugh", 69.57],
        ["Abernethy", 69.572],
        ["Work", 69.573],
        ["Wolter", 69.574],
        ["Wille", 69.575],
        ["Tweed", 69.577],
        ["Tollefson", 69.578],
        ["Thomasson", 69.579],
        ["Teter", 69.58],
        ["Testerman", 69.582],
        ["Sproul", 69.583],
        ["Spates", 69.584],
        ["Southwick", 69.585],
        ["Soukup", 69.587],
        ["Skelly", 69.588],
        ["Senter", 69.589],
        ["Sealey", 69.59],
        ["Sawicki", 69.592],
        ["Sargeant", 69.593],
        ["Rossiter", 69.594],
        ["Rosemond", 69.596],
        ["Repp", 69.597],
        ["Pound", 69.598],
        ["Pink", 69.599],
        ["Pifer", 69.601],
        ["Ormsby", 69.602],
        ["Nickelson", 69.603],
        ["Naumann", 69.604],
        ["Morabito", 69.606],
        ["Monzon", 69.607],
        ["Millsaps", 69.608],
        ["Millen", 69.609],
        ["McElrath", 69.611],
        ["Marcoux", 69.612],
        ["Mantooth", 69.613],
        ["Madson", 69.614],
        ["MacNeil", 69.616],
        ["MacKinnon", 69.617],
        ["Louque", 69.618],
        ["Leister", 69.619],
        ["Lampley", 69.621],
        ["Kushner", 69.622],
        ["Krouse", 69.623],
        ["Kirwan", 69.624],
        ["June", 69.626],
        ["Jessee", 69.627],
        ["Janson", 69.628],
        ["Jahn", 69.629],
        ["Jacquez", 69.631],
        ["Islas", 69.632],
        ["Hutt", 69.633],
        ["Holladay", 69.634],
        ["Hillyer", 69.636],
        ["Hepburn", 69.637],
        ["Hensel", 69.638],
        ["Harrold", 69.639],
        ["Guadalupe", 69.641],
        ["Gingrich", 69.642],
        ["Geis", 69.643],
        ["Gales", 69.645],
        ["Fults", 69.646],
        ["Finnell", 69.647],
        ["Ferri", 69.648],
        ["Featherston", 69.65],
        ["Epley", 69.651],
        ["Ebersole", 69.652],
        ["Eames", 69.653],
        ["Dunigan", 69.655],
        ["Drye", 69.656],
        ["Dismuke", 69.657],
        ["DeVaughn", 69.658],
        ["DeLorenzo", 69.66],
        ["Damiano", 69.661],
        ["Confer", 69.662],
        ["Collum", 69.663],
        ["Clower", 69.665],
        ["Clow", 69.666],
        ["Claussen", 69.667],
        ["Clack", 69.668],
        ["Caylor", 69.67],
        ["Cawthon", 69.671],
        ["Casias", 69.672],
        ["Carreno", 69.673],
        ["Carlo", 69.675],
        ["Bluhm", 69.676],
        ["Bingaman", 69.677],
        ["Bewley", 69.678],
        ["Belew", 69.68],
        ["Beckner", 69.681],
        ["Beamer", 69.682],
        ["Barefoot", 69.683],
        ["Auld", 69.685],
        ["Amey", 69.686],
        ["Wolfenbarger", 69.687],
        ["Wilkey", 69.688],
        ["Wicklund", 69.69],
        ["Waltman", 69.691],
        ["Villalba", 69.692],
        ["Valero", 69.693],
        ["Valdovinos", 69.695],
        ["Ung", 69.696],
        ["Ullrich", 69.697],
        ["Tyus", 69.698],
        ["Twyman", 69.7],
        ["Trost", 69.701],
        ["Tardif", 69.702],
        ["Tanguay", 69.703],
        ["Stripling", 69.705],
        ["Steinbach", 69.706],
        ["Shumpert", 69.707],
        ["Sasaki", 69.708],
        ["Sappington", 69.71],
        ["Sandusky", 69.711],
        ["Reinhold", 69.712],
        ["Reinert", 69.713],
        ["Quijano", 69.714],
        ["Pye", 69.716],
        ["Poor", 69.717],
        ["Placencia", 69.718],
        ["Pinkard", 69.719],
        ["Phinney", 69.721],
        ["Perrotta", 69.722],
        ["Pernell", 69.723],
        ["Parrett", 69.724],
        ["Oxendine", 69.726],
        ["Owensby", 69.727],
        ["Orman", 69.728],
        ["Nuno", 69.729],
        ["Mori", 69.731],
        ["McRoberts", 69.732],
        ["McNeese", 69.733],
        ["McKamey", 69.734],
        ["McCullum", 69.736],
        ["Markel", 69.737],
        ["Mardis", 69.738],
        ["Maines", 69.739],
        ["Lueck", 69.741],
        ["Lubin", 69.742],
        ["Lefler", 69.743],
        ["Leffler", 69.744],
        ["Lavery", 69.745],
        ["Larios", 69.747],
        ["LaBarbera", 69.748],
        ["Kershner", 69.749],
        ["Josey", 69.75],
        ["Jean-Baptiste", 69.752],
        ["Izaguirre", 69.753],
        ["Hermosillo", 69.754],
        ["Haviland", 69.755],
        ["Hartshorn", 69.757],
        ["Hamlet", 69.758],
        ["Hafner", 69.759],
        ["Ginter", 69.76],
        ["Getty", 69.762],
        ["Franck", 69.763],
        ["Fiske", 69.764],
        ["Emmett", 69.765],
        ["Dufrene", 69.767],
        ["Doody", 69.768],
        ["Davie", 69.769],
        ["Dangerfield", 69.77],
        ["Dahlberg", 69.772],
        ["Cuthbertson", 69.773],
        ["Crone", 69.774],
        ["Coffelt", 69.775],
        ["Claus", 69.776],
        ["Chidester", 69.778],
        ["Chesson", 69.779],
        ["Cauley", 69.78],
        ["Caudell", 69.781],
        ["Cantara", 69.783],
        ["Campo", 69.784],
        ["Caines", 69.785],
        ["Bullis", 69.786],
        ["Bucci", 69.788],
        ["Brochu", 69.789],
        ["Bosco", 69.79],
        ["Bogard", 69.791],
        ["Bickerstaff", 69.793],
        ["Benning", 69.794],
        ["Arzola", 69.795],
        ["Antonelli", 69.796],
        ["Adkinson", 69.798],
        ["Zellers", 69.799],
        ["Wulf", 69.8],
        ["Worsley", 69.801],
        ["Woolridge", 69.802],
        ["Whitton", 69.804],
        ["Westerfield", 69.805],
        ["Walczak", 69.806],
        ["Vassar", 69.807],
        ["Truett", 69.809],
        ["Trueblood", 69.81],
        ["Trawick", 69.811],
        ["Townsley", 69.812],
        ["Topping", 69.813],
        ["Tobar", 69.815],
        ["Telford", 69.816],
        ["Sung", 69.817],
        ["Steverson", 69.818],
        ["Stagg", 69.82],
        ["Sitton", 69.821],
        ["Sill", 69.822],
        ["Sherrell", 69.823],
        ["Sergent", 69.824],
        ["Schoenfeld", 69.826],
        ["Sarabia", 69.827],
        ["Rutkowski", 69.828],
        ["Rubenstein", 69.829],
        ["Rigdon", 69.831],
        ["Prentiss", 69.832],
        ["Pomerleau", 69.833],
        ["Plumlee", 69.834],
        ["Phoenix", 69.835],
        ["Philbrick", 69.837],
        ["Peer", 69.838],
        ["Patty", 69.839],
        ["Patnode", 69.84],
        ["Oloughlin", 69.842],
        ["Obregon", 69.843],
        ["Nuss", 69.844],
        ["Napoleon", 69.845],
        ["Morell", 69.847],
        ["Moose", 69.848],
        ["Mikell", 69.849],
        ["Mele", 69.85],
        ["McInerney", 69.851],
        ["McGuigan", 69.853],
        ["McBrayer", 69.854],
        ["Lore", 69.855],
        ["Lor", 69.856],
        ["Look", 69.858],
        ["Lollar", 69.859],
        ["Lakes", 69.86],
        ["Kuehl", 69.861],
        ["Kinzer", 69.862],
        ["Kamp", 69.864],
        ["Joplin", 69.865],
        ["Jacobi", 69.866],
        ["Howells", 69.867],
        ["Holstein", 69.869],
        ["Hedden", 69.87],
        ["Hassler", 69.871],
        ["Harty", 69.872],
        ["Halle", 69.873],
        ["Greig", 69.875],
        ["Granville", 69.876],
        ["Gouge", 69.877],
        ["Goodrum", 69.878],
        ["Gerhart", 69.88],
        ["Geier", 69.881],
        ["Geddes", 69.882],
        ["Gast", 69.883],
        ["Forehand", 69.884],
        ["Ferree", 69.886],
        ["Fendley", 69.887],
        ["Feltner", 69.888],
        ["Fang", 69.889],
        ["Esqueda", 69.891],
        ["Encarnacion", 69.892],
        ["Eichler", 69.893],
        ["Egger", 69.894],
        ["Edmundson", 69.895],
        ["Eatmon", 69.897],
        ["Dragon", 69.898],
        ["Doud", 69.899],
        ["Donohoe", 69.9],
        ["Donelson", 69.902],
        ["DiLorenzo", 69.903],
        ["DiGiacomo", 69.904],
        ["Diggins", 69.905],
        ["DeLozier", 69.906],
        ["de Jong", 69.908],
        ["Danford", 69.909],
        ["Crippen", 69.91],
        ["Coppage", 69.911],
        ["Cogswell", 69.913],
        ["Clardy", 69.914],
        ["Cioffi", 69.915],
        ["Cabe", 69.916],
        ["Brunette", 69.918],
        ["Bresnahan", 69.919],
        ["Bramble", 69.92],
        ["Blomquist", 69.921],
        ["Blackstone", 69.922],
        ["Biller", 69.924],
        ["Bevis", 69.925],
        ["Bevan", 69.926],
        ["Bethune", 69.927],
        ["Benbow", 69.929],
        ["Baty", 69.93],
        ["Basinger", 69.931],
        ["Balcom", 69.932],
        ["Andes", 69.933],
        ["Aman", 69.935],
        ["Aguero", 69.936],
        ["Adkisson", 69.937],
        ["Yandell", 69.938],
        ["Wilds", 69.94],
        ["Whisenhunt", 69.941],
        ["Weigand", 69.942],
        ["Weeden", 69.943],
        ["Voight", 69.944],
        ["Villar", 69.946],
        ["Trottier", 69.947],
        ["Tillett", 69.948],
        ["Suazo", 69.949],
        ["Setser", 69.95],
        ["Scurry", 69.952],
        ["Schuh", 69.953],
        ["Schreck", 69.954],
        ["Schauer", 69.955],
        ["Samora", 69.956],
        ["Roane", 69.958],
        ["Rinker", 69.959],
        ["Reimers", 69.96],
        ["Reason", 69.961],
        ["Ratchford", 69.962],
        ["Popovich", 69.964],
        ["Parkin", 69.965],
        ["Nichol", 69.966],
        ["Natal", 69.967],
        ["Melville", 69.969],
        ["McBryde", 69.97],
        ["Magdaleno", 69.971],
        ["Loehr", 69.972],
        ["Lockman", 69.973],
        ["Lingo", 69.975],
        ["Leduc", 69.976],
        ["Larocca", 69.977],
        ["Lao", 69.978],
        ["Lamere", 69.979],
        ["Laclair", 69.981],
        ["Krall", 69.982],
        ["Korte", 69.983],
        ["Koger", 69.984],
        ["Jumper", 69.985],
        ["Jalbert", 69.987],
        ["Hughs", 69.988],
        ["Higbee", 69.989],
        ["Henton", 69.99],
        ["Heaney", 69.991],
        ["Haith", 69.993],
        ["Gump", 69.994],
        ["Greeson", 69.995],
        ["Goodloe", 69.996],
        ["Gholston", 69.998],
        ["Gasper", 69.999],
        ["Gagliardi", 70],
        ["Fregoso", 70.001],
        ["Farthing", 70.002],
        ["Fabrizio", 70.004],
        ["Ensor", 70.005],
        ["Elswick", 70.006],
        ["Elgin", 70.007],
        ["Eklund", 70.008],
        ["Eaddy", 70.01],
        ["Drouin", 70.011],
        ["Dorton", 70.012],
        ["Dizon", 70.013],
        ["de Rouen", 70.014],
        ["Delia", 70.016],
        ["de Herrera", 70.017],
        ["Davy", 70.018],
        ["Dark", 70.019],
        ["Dampier", 70.02],
        ["Cullum", 70.022],
        ["Culley", 70.023],
        ["Cowgill", 70.024],
        ["Cardoso", 70.025],
        ["Cardinale", 70.027],
        ["Brodsky", 70.028],
        ["Broadbent", 70.029],
        ["Brimmer", 70.03],
        ["Briceno", 70.031],
        ["Branscum", 70.033],
        ["Bolyard", 70.034],
        ["Boley", 70.035],
        ["Bennington", 70.036],
        ["Beadle", 70.037],
        ["Baur", 70.039],
        ["Ballentine", 70.04],
        ["Azure", 70.041],
        ["Aultman", 70.042],
        ["Augustus", 70.043],
        ["Asuncion", 70.045],
        ["Arciniega", 70.046],
        ["Aguila", 70.047],
        ["Aceves", 70.048],
        ["Yepez", 70.049],
        ["Yap", 70.051],
        ["Woodrum", 70.052],
        ["Wethington", 70.053],
        ["Weissman", 70.054],
        ["Veloz", 70.055],
        ["Trusty", 70.057],
        ["Troup", 70.058],
        ["Trammel", 70.059],
        ["Theodore", 70.06],
        ["Tarpley", 70.061],
        ["Stivers", 70.063],
        ["Steck", 70.064],
        ["Sprayberry", 70.065],
        ["Spraggins", 70.066],
        ["Spitler", 70.067],
        ["Spiers", 70.069],
        ["Sohn", 70.07],
        ["Seagraves", 70.071],
        ["Schiffman", 70.072],
        ["Rudnick", 70.073],
        ["Rizo", 70.074],
        ["Riccio", 70.076],
        ["Rennie", 70.077],
        ["Quinton", 70.078],
        ["Quackenbush", 70.079],
        ["Puma", 70.08],
        ["Plott", 70.082],
        ["Pearcy", 70.083],
        ["Parada", 70.084],
        ["Paiz", 70.085],
        ["Munford", 70.086],
        ["Moskowitz", 70.088],
        ["Mease", 70.089],
        ["McNary", 70.09],
        ["McCusker", 70.091],
        ["Matt", 70.092],
        ["Lozoya", 70.094],
        ["Longmire", 70.095],
        ["Loesch", 70.096],
        ["Lasky", 70.097],
        ["Kuhlmann", 70.098],
        ["Krieg", 70.1],
        ["Koziol", 70.101],
        ["Kowalewski", 70.102],
        ["Konrad", 70.103],
        ["Kindle", 70.104],
        ["Jowers", 70.105],
        ["Jolin", 70.107],
        ["Jaco", 70.108],
        ["Hua", 70.109],
        ["Horgan", 70.11],
        ["Hine", 70.111],
        ["Hileman", 70.113],
        ["Hepner", 70.114],
        ["Heise", 70.115],
        ["Heady", 70.116],
        ["Hawkinson", 70.117],
        ["Hannigan", 70.119],
        ["Haberman", 70.12],
        ["Guilford", 70.121],
        ["Grimaldi", 70.122],
        ["Gilles", 70.123],
        ["Garton", 70.125],
        ["Gagliano", 70.126],
        ["Fruge", 70.127],
        ["Follett", 70.128],
        ["Fiscus", 70.129],
        ["Ferretti", 70.131],
        ["Ebner", 70.132],
        ["Easterday", 70.133],
        ["Eanes", 70.134],
        ["Dirks", 70.135],
        ["DiMarco", 70.136],
        ["De Palma", 70.138],
        ["DeForest", 70.139],
        ["Dance", 70.14],
        ["Cruce", 70.141],
        ["Craighead", 70.142],
        ["Christner", 70.144],
        ["Candler", 70.145],
        ["Cadwell", 70.146],
        ["Burchell", 70.147],
        ["Buettner", 70.148],
        ["Brinton", 70.15],
        ["Breed", 70.151],
        ["Brazier", 70.152],
        ["Brannen", 70.153],
        ["Brame", 70.154],
        ["Bova", 70.156],
        ["Bomar", 70.157],
        ["Blakeslee", 70.158],
        ["Belknap", 70.159],
        ["Bangs", 70.16],
        ["Balzer", 70.162],
        ["Athey", 70.163],
        ["Armes", 70.164],
        ["Alvis", 70.165],
        ["Alverson", 70.166],
        ["Alvardo", 70.167],
        ["Alter", 70.169],
        ["Zhao", 70.17],
        ["Yeung", 70.171],
        ["Yen", 70.172],
        ["Wheelock", 70.173],
        ["Westlund", 70.175],
        ["Wessels", 70.176],
        ["Volkman", 70.177],
        ["Threadgill", 70.178],
        ["Thelen", 70.179],
        ["Tandy", 70.18],
        ["Tague", 70.182],
        ["Ta", 70.183],
        ["Symons", 70.184],
        ["Swinford", 70.185],
        ["Sturtevant", 70.186],
        ["Straka", 70.188],
        ["Stier", 70.189],
        ["Stagner", 70.19],
        ["Segarra", 70.191],
        ["Seawright", 70.192],
        ["Sack", 70.193],
        ["Rutan", 70.195],
        ["Roux", 70.196],
        ["Ringler", 70.197],
        ["Riker", 70.198],
        ["Ramsdell", 70.199],
        ["Quattlebaum", 70.2],
        ["Purifoy", 70.202],
        ["Poulson", 70.203],
        ["Permenter", 70.204],
        ["Peloquin", 70.205],
        ["Pasley", 70.206],
        ["Pagel", 70.208],
        ["Osman", 70.209],
        ["Obannon", 70.21],
        ["Nygaard", 70.211],
        ["Nipper", 70.212],
        ["Newcomer", 70.213],
        ["Munos", 70.215],
        ["Motta", 70.216],
        ["Meadors", 70.217],
        ["McQuiston", 70.218],
        ["McNiel", 70.219],
        ["McMann", 70.22],
        ["McCrae", 70.222],
        ["Mayne", 70.223],
        ["Matte", 70.224],
        ["Martine", 70.225],
        ["Lucy", 70.226],
        ["Legault", 70.227],
        ["Lechner", 70.229],
        ["Lack", 70.23],
        ["Kucera", 70.231],
        ["Krohn", 70.232],
        ["Kratzer", 70.233],
        ["Koopman", 70.235],
        ["Judson", 70.236],
        ["Jeske", 70.237],
        ["Horrocks", 70.238],
        ["Homes", 70.239],
        ["Hock", 70.24],
        ["Hibbler", 70.242],
        ["Hesson", 70.243],
        ["Hersh", 70.244],
        ["Harvin", 70.245],
        ["Halvorsen", 70.246],
        ["Griner", 70.247],
        ["Grindle", 70.249],
        ["Glen", 70.25],
        ["Gladstone", 70.251],
        ["Garofalo", 70.252],
        ["Frampton", 70.253],
        ["Forbis", 70.255],
        ["Fernando", 70.256],
        ["Eddington", 70.257],
        ["Diorio", 70.258],
        ["Dingus", 70.259],
        ["Dewar", 70.26],
        ["Desalvo", 70.262],
        ["Curcio", 70.263],
        ["Creasy", 70.264],
        ["Cortese", 70.265],
        ["Cordoba", 70.266],
        ["Connally", 70.267],
        ["Cluff", 70.269],
        ["Cascio", 70.27],
        ["Capuano", 70.271],
        ["Canaday", 70.272],
        ["Calabro", 70.273],
        ["Bussard", 70.275],
        ["Brayton", 70.276],
        ["Borja", 70.277],
        ["Bigley", 70.278],
        ["Arnone", 70.279],
        ["Arguelles", 70.28],
        ["Acuff", 70.282],
        ["Zamarripa", 70.283],
        ["Wooton", 70.284],
        ["Wolfgang", 70.285],
        ["Widner", 70.286],
        ["Wideman", 70.287],
        ["Threatt", 70.289],
        ["Thiele", 70.29],
        ["Templin", 70.291],
        ["Teeters", 70.292],
        ["Synder", 70.293],
        ["Swint", 70.294],
        ["Swick", 70.296],
        ["Sturges", 70.297],
        ["Stogner", 70.298],
        ["Stedman", 70.299],
        ["Spratt", 70.3],
        ["Six", 70.301],
        ["Siegfried", 70.303],
        ["Shetler", 70.304],
        ["Scull", 70.305],
        ["Savino", 70.306],
        ["Sather", 70.307],
        ["Rothwell", 70.308],
        ["Rook", 70.309],
        ["Rone", 70.311],
        ["Rolf", 70.312],
        ["Rhee", 70.313],
        ["Quevedo", 70.314],
        ["Privett", 70.315],
        ["Pouliot", 70.316],
        ["Poche", 70.318],
        ["Pickel", 70.319],
        ["Petrillo", 70.32],
        ["Pellegrini", 70.321],
        ["Peaslee", 70.322],
        ["Partlow", 70.323],
        ["Otey", 70.325],
        ["Nunnery", 70.326],
        ["Morelock", 70.327],
        ["Morello", 70.328],
        ["Meunier", 70.329],
        ["Messinger", 70.33],
        ["McKie", 70.332],
        ["McCubbin", 70.333],
        ["McCarron", 70.334],
        ["Maria", 70.335],
        ["Lerch", 70.336],
        ["Lavine", 70.337],
        ["Laverty", 70.338],
        ["Lariviere", 70.34],
        ["Lamkin", 70.341],
        ["Kugler", 70.342],
        ["Krol", 70.343],
        ["Kissel", 70.344],
        ["Keeter", 70.345],
        ["Hummer", 70.347],
        ["Hubble", 70.348],
        ["Hickox", 70.349],
        ["Hetzel", 70.35],
        ["Hayner", 70.351],
        ["Hagy", 70.352],
        ["Hadlock", 70.354],
        ["Groh", 70.355],
        ["Gregorio", 70.356],
        ["Gottschalk", 70.357],
        ["Goodsell", 70.358],
        ["Gloria", 70.359],
        ["Gerry", 70.361],
        ["Gassaway", 70.362],
        ["Garrard", 70.363],
        ["Galligan", 70.364],
        ["Fye", 70.365],
        ["Firth", 70.366],
        ["Fenderson", 70.367],
        ["Feinstein", 70.369],
        ["Etienne", 70.37],
        ["Engleman", 70.371],
        ["Emrick", 70.372],
        ["Ellender", 70.373],
        ["Drews", 70.374],
        ["Doiron", 70.376],
        ["Degraw", 70.377],
        ["Deegan", 70.378],
        ["Dart", 70.379],
        ["Crissman", 70.38],
        ["Corr", 70.381],
        ["Cookson", 70.383],
        ["Coil", 70.384],
        ["Cleaves", 70.385],
        ["Charest", 70.386],
        ["Chapple", 70.387],
        ["Chaparro", 70.388],
        ["Castano", 70.39],
        ["Carpio", 70.391],
        ["Byer", 70.392],
        ["Bufford", 70.393],
        ["Bridgewater", 70.394],
        ["Bridgers", 70.395],
        ["Brandes", 70.397],
        ["Borrero", 70.398],
        ["Bonanno", 70.399],
        ["Aube", 70.4],
        ["Ancheta", 70.401],
        ["Abarca", 70.402],
        ["Abad", 70.403],
        ["Yung", 70.405],
        ["Yim", 70.406],
        ["Wooster", 70.407],
        ["Woodrow", 70.408],
        ["Wimbush", 70.409],
        ["Willhite", 70.41],
        ["Willams", 70.411],
        ["Wigley", 70.413],
        ["Weisberg", 70.414],
        ["Wardlaw", 70.415],
        ["Vigue", 70.416],
        ["Van Hook", 70.417],
        ["Unknow", 70.418],
        ["Torre", 70.419],
        ["Tasker", 70.421],
        ["Tarbox", 70.422],
        ["Strachan", 70.423],
        ["Standard", 70.424],
        ["Slover", 70.425],
        ["Shamblin", 70.426],
        ["Semple", 70.428],
        ["Schuyler", 70.429],
        ["Schrimsher", 70.43],
        ["Sayer", 70.431],
        ["Salzman", 70.432],
        ["Salomon", 70.433],
        ["Rubalcava", 70.434],
        ["Riles", 70.436],
        ["Rickey", 70.437],
        ["Reneau", 70.438],
        ["Reichel", 70.439],
        ["Rayfield", 70.44],
        ["Rabon", 70.441],
        ["Pyatt", 70.442],
        ["Prindle", 70.444],
        ["Poss", 70.445],
        ["Polito", 70.446],
        ["Plemmons", 70.447],
        ["Pesce", 70.448],
        ["Perrault", 70.449],
        ["Pereyra", 70.45],
        ["Ostrowski", 70.452],
        ["Nilsen", 70.453],
        ["Niemeyer", 70.454],
        ["Nick", 70.455],
        ["Munsey", 70.456],
        ["Mundell", 70.457],
        ["Moncada", 70.458],
        ["Miceli", 70.46],
        ["Meader", 70.461],
        ["McMasters", 70.462],
        ["McKeehan", 70.463],
        ["Matsumoto", 70.464],
        ["Marron", 70.465],
        ["Marden", 70.466],
        ["Lizarraga", 70.468],
        ["Lingenfelter", 70.469],
        ["Lewallen", 70.47],
        ["Laurence", 70.471],
        ["Langan", 70.472],
        ["Lamanna", 70.473],
        ["Kovac", 70.474],
        ["Kinsler", 70.476],
        ["Kephart", 70.477],
        ["Keown", 70.478],
        ["Kass", 70.479],
        ["Kammerer", 70.48],
        ["Jeffreys", 70.481],
        ["Hysell", 70.482],
        ["Householder", 70.484],
        ["Hosmer", 70.485],
        ["Hardnett", 70.486],
        ["Hanner", 70.487],
        ["Guyette", 70.488],
        ["Greening", 70.489],
        ["Glazer", 70.49],
        ["Ginder", 70.492],
        ["Fromm", 70.493],
        ["Fortuna", 70.494],
        ["Fluellen", 70.495],
        ["Finkle", 70.496],
        ["Fey", 70.497],
        ["Fessler", 70.498],
        ["Essary", 70.5],
        ["Eisele", 70.501],
        ["Duren", 70.502],
        ["Dittmer", 70.503],
        ["Crochet", 70.504],
        ["Cosentino", 70.505],
        ["Cogan", 70.506],
        ["Coelho", 70.508],
        ["Cavin", 70.509],
        ["Carrizales", 70.51],
        ["Campuzano", 70.511],
        ["Brough", 70.512],
        ["Bow", 70.513],
        ["Bopp", 70.514],
        ["Bookman", 70.516],
        ["Bobb", 70.517],
        ["Blouin", 70.518],
        ["Beesley", 70.519],
        ["Battista", 70.52],
        ["Bascom", 70.521],
        ["Bakken", 70.523],
        ["Badgett", 70.524],
        ["Arneson", 70.525],
        ["Anselmo", 70.526],
        ["Albino", 70.527],
        ["Ahumada", 70.528],
        ["Agustin", 70.529],
        ["Woodyard", 70.531],
        ["Wolters", 70.532],
        ["Wireman", 70.533],
        ["Wilton", 70.534],
        ["Willison", 70.535],
        ["Warman", 70.536],
        ["Wan", 70.537],
        ["Waldrup", 70.538],
        ["Vowell", 70.54],
        ["Van Tassel", 70.541],
        ["Vale", 70.542],
        ["Twombly", 70.543],
        ["Toomer", 70.544],
        ["Tennison", 70.545],
        ["Teets", 70.546],
        ["Tedeschi", 70.547],
        ["Swanner", 70.549],
        ["Swallow", 70.55],
        ["Stutz", 70.551],
        ["Stelly", 70.552],
        ["Sheehy", 70.553],
        ["Schermerhorn", 70.554],
        ["Scala", 70.555],
        ["Sandidge", 70.556],
        ["Salters", 70.558],
        ["Salo", 70.559],
        ["Saechao", 70.56],
        ["Roseboro", 70.561],
        ["Rolle", 70.562],
        ["Ressler", 70.563],
        ["Renz", 70.564],
        ["Renn", 70.565],
        ["Redford", 70.567],
        ["Raposa", 70.568],
        ["Rainbolt", 70.569],
        ["Pompey", 70.57],
        ["Pelfrey", 70.571],
        ["Orndorff", 70.572],
        ["Oney", 70.573],
        ["Nolin", 70.575],
        ["Nimmons", 70.576],
        ["Ney", 70.577],
        ["Nardone", 70.578],
        ["Myhre", 70.579],
        ["Morman", 70.58],
        ["Mines", 70.581],
        ["Menjivar", 70.582],
        ["McGlone", 70.584],
        ["McCammon", 70.585],
        ["Maxon", 70.586],
        ["Maris", 70.587],
        ["Marciano", 70.588],
        ["Manus", 70.589],
        ["Maiden", 70.59],
        ["Lowrance", 70.591],
        ["Lorenzen", 70.593],
        ["Lonergan", 70.594],
        ["Lollis", 70.595],
        ["Littles", 70.596],
        ["Lindahl", 70.597],
        ["Lansing", 70.598],
        ["Lamas", 70.599],
        ["Lach", 70.6],
        ["Kuster", 70.602],
        ["Krawczyk", 70.603],
        ["Knuth", 70.604],
        ["Knecht", 70.605],
        ["Kirkendall", 70.606],
        ["Keitt", 70.607],
        ["Keever", 70.608],
        ["Kantor", 70.61],
        ["Jarboe", 70.611],
        ["Hoye", 70.612],
        ["Houchens", 70.613],
        ["Holter", 70.614],
        ["Holsinger", 70.615],
        ["Hickok", 70.616],
        ["Herb", 70.617],
        ["Helwig", 70.619],
        ["Helgeson", 70.62],
        ["Heater", 70.621],
        ["Hassett", 70.622],
        ["Harner", 70.623],
        ["Hamman", 70.624],
        ["Hames", 70.625],
        ["Hadfield", 70.626],
        ["Goree", 70.628],
        ["Goldfarb", 70.629],
        ["Gaughan", 70.63],
        ["Gaudreau", 70.631],
        ["Gantz", 70.632],
        ["Gallion", 70.633],
        ["Frady", 70.634],
        ["Foti", 70.635],
        ["Flesher", 70.637],
        ["Ferrin", 70.638],
        ["Faught", 70.639],
        ["Engram", 70.64],
        ["Elbert", 70.641],
        ["Donegan", 70.642],
        ["Desouza", 70.643],
        ["Degroot", 70.645],
        ["Cutright", 70.646],
        ["Crowl", 70.647],
        ["Criner", 70.648],
        ["Coke", 70.649],
        ["Coan", 70.65],
        ["Clinkscales", 70.651],
        ["Chewning", 70.652],
        ["Chavira", 70.654],
        ["Catchings", 70.655],
        ["Carlock", 70.656],
        ["Bye", 70.657],
        ["Bulger", 70.658],
        ["Buenrostro", 70.659],
        ["Bramblett", 70.66],
        ["Brack", 70.661],
        ["Boulware", 70.663],
        ["Bordeaux", 70.664],
        ["Bookout", 70.665],
        ["Bitner", 70.666],
        ["Birt", 70.667],
        ["Baranowski", 70.668],
        ["Baisden", 70.669],
        ["Augustin", 70.67],
        ["Allmon", 70.672],
        ["Alberto", 70.673],
        ["Acklin", 70.674],
        ["Yoakum", 70.675],
        ["Wilbourn", 70.676],
        ["Whisler", 70.677],
        ["Weinberger", 70.678],
        ["Washer", 70.679],
        ["Vasques", 70.681],
        ["Van Zandt", 70.682],
        ["Van Atta", 70.683],
        ["Troxler", 70.684],
        ["Tomes", 70.685],
        ["Tindle", 70.686],
        ["Tims", 70.687],
        ["Throckmorton", 70.688],
        ["Thach", 70.689],
        ["Stpeter", 70.691],
        ["Stlaurent", 70.692],
        ["Stenson", 70.693],
        ["Spry", 70.694],
        ["Spitz", 70.695],
        ["Songer", 70.696],
        ["Snavely", 70.697],
        ["Sly", 70.698],
        ["Sleeper", 70.699],
        ["Shroyer", 70.701],
        ["Shortridge", 70.702],
        ["Shenk", 70.703],
        ["Sevier", 70.704],
        ["Seabrook", 70.705],
        ["Scrivner", 70.706],
        ["Saltzman", 70.707],
        ["Rosenberry", 70.708],
        ["Rockwood", 70.709],
        ["Robeson", 70.711],
        ["Roan", 70.712],
        ["Reiser", 70.713],
        ["Redwine", 70.714],
        ["Ramires", 70.715],
        ["Raber", 70.716],
        ["Profit", 70.717],
        ["Posner", 70.718],
        ["Popham", 70.719],
        ["Pipes", 70.721],
        ["Piotrowski", 70.722],
        ["Pinard", 70.723],
        ["Peterkin", 70.724],
        ["Pelham", 70.725],
        ["Peiffer", 70.726],
        ["Peay", 70.727],
        ["Peavey", 70.728],
        ["Nadler", 70.729],
        ["Musso", 70.731],
        ["Milo", 70.732],
        ["Millett", 70.733],
        ["Mestas", 70.734],
        ["McGowen", 70.735],
        ["Marques", 70.736],
        ["Marasco", 70.737],
        ["Manriquez", 70.738],
        ["Manos", 70.74],
        ["Mair", 70.741],
        ["Lipps", 70.742],
        ["Lesser", 70.743],
        ["Leiker", 70.744],
        ["Leeds", 70.745],
        ["Krumm", 70.746],
        ["Knorr", 70.747],
        ["Kinslow", 70.748],
        ["Kessel", 70.75],
        ["Kendricks", 70.751],
        ["Kelm", 70.752],
        ["Ito", 70.753],
        ["Irick", 70.754],
        ["Ickes", 70.755],
        ["Hurlburt", 70.756],
        ["Horta", 70.757],
        ["Hoekstra", 70.758],
        ["Heuer", 70.76],
        ["Helmuth", 70.761],
        ["Heatherly", 70.762],
        ["Hampson", 70.763],
        ["Hagar", 70.764],
        ["Haga", 70.765],
        ["Greenlaw", 70.766],
        ["Grau", 70.767],
        ["Godbey", 70.768],
        ["Gingras", 70.77],
        ["Gillies", 70.771],
        ["Gibb", 70.772],
        ["Gayden", 70.773],
        ["Gauvin", 70.774],
        ["Garrow", 70.775],
        ["Fontanez", 70.776],
        ["Florio", 70.777],
        ["Fleischman", 70.778],
        ["Finke", 70.78],
        ["Fasano", 70.781],
        ["Fan", 70.782],
        ["Faith", 70.783],
        ["Ezzell", 70.784],
        ["Ewers", 70.785],
        ["Eveland", 70.786],
        ["Eckenrode", 70.787],
        ["Duclos", 70.788],
        ["Drumm", 70.79],
        ["Dimmick", 70.791],
        ["Delancey", 70.792],
        ["Defazio", 70.793],
        ["Deacon", 70.794],
        ["Dashiell", 70.795],
        ["Damian", 70.796],
        ["Cusack", 70.797],
        ["Crowther", 70.798],
        ["Crigger", 70.8],
        ["Cray", 70.801],
        ["Coolidge", 70.802],
        ["Coldiron", 70.803],
        ["Cleland", 70.804],
        ["Chalfant", 70.805],
        ["Cassel", 70.806],
        ["Cape", 70.807],
        ["Camire", 70.809],
        ["Cabrales", 70.81],
        ["Broomfield", 70.811],
        ["Brittingham", 70.812],
        ["Brisson", 70.813],
        ["Brickey", 70.814],
        ["Braziel", 70.815],
        ["Brazell", 70.816],
        ["Bragdon", 70.817],
        ["Boulanger", 70.819],
        ["Bos", 70.82],
        ["Boman", 70.821],
        ["Bohannan", 70.822],
        ["Beem", 70.823],
        ["Barto", 70.824],
        ["Barre", 70.825],
        ["Barley", 70.826],
        ["Baptist", 70.827],
        ["Azar", 70.829],
        ["Ashbaugh", 70.83],
        ["Armistead", 70.831],
        ["Almazan", 70.832],
        ["Adamski", 70.833],
        ["Zendejas", 70.834],
        ["Winburn", 70.835],
        ["Willaims", 70.836],
        ["Wilhoit", 70.837],
        ["Westberry", 70.838],
        ["Wentzel", 70.84],
        ["Wendling", 70.841],
        ["Wager", 70.842],
        ["Visser", 70.843],
        ["Van Scoy", 70.844],
        ["Van Kirk", 70.845],
        ["Vallee", 70.846],
        ["Tweedy", 70.847],
        ["Thornberry", 70.848],
        ["Sweeny", 70.849],
        ["Stalker", 70.851],
        ["Spradling", 70.852],
        ["Spano", 70.853],
        ["Smelser", 70.854],
        ["Shim", 70.855],
        ["Sechrist", 70.856],
        ["Schall", 70.857],
        ["Scaife", 70.858],
        ["Rugg", 70.859],
        ["Ruben", 70.86],
        ["Rothrock", 70.862],
        ["Roesler", 70.863],
        ["Riehl", 70.864],
        ["Ridings", 70.865],
        ["Render", 70.866],
        ["Ransdell", 70.867],
        ["Radke", 70.868],
        ["Pinero", 70.869],
        ["Petree", 70.87],
        ["Pendergast", 70.871],
        ["Peluso", 70.872],
        ["Pecoraro", 70.874],
        ["Pascoe", 70.875],
        ["Panek", 70.876],
        ["Oshiro", 70.877],
        ["Noon", 70.878],
        ["Navarrette", 70.879],
        ["Murguia", 70.88],
        ["Moores", 70.881],
        ["Moberg", 70.882],
        ["Mike", 70.883],
        ["Michaelis", 70.885],
        ["McWhirter", 70.886],
        ["McSweeney", 70.887],
        ["McQuade", 70.888],
        ["McCay", 70.889],
        ["Mauk", 70.89],
        ["Mariani", 70.891],
        ["Marceau", 70.892],
        ["Mandeville", 70.893],
        ["Maeda", 70.894],
        ["Lunde", 70.896],
        ["Ludlow", 70.897],
        ["Loeb", 70.898],
        ["Lindo", 70.899],
        ["Linderman", 70.9],
        ["Leveille", 70.901],
        ["Leith", 70.902],
        ["Larock", 70.903],
        ["Lambrecht", 70.904],
        ["Kulp", 70.905],
        ["Kinsley", 70.906],
        ["Kimberlin", 70.908],
        ["Kesterson", 70.909],
        ["Jacinto", 70.91],
        ["Ice", 70.911],
        ["Hui", 70.912],
        ["Hoyos", 70.913],
        ["Helfrich", 70.914],
        ["Hanke", 70.915],
        ["Hail", 70.916],
        ["Guillermo", 70.917],
        ["Grisby", 70.919],
        ["Goyette", 70.92],
        ["Gouveia", 70.921],
        ["Glazier", 70.922],
        ["Gile", 70.923],
        ["Gerena", 70.924],
        ["Gelinas", 70.925],
        ["Gasaway", 70.926],
        ["Garden", 70.927],
        ["Funches", 70.928],
        ["Fujimoto", 70.93],
        ["Flynt", 70.931],
        ["Fenske", 70.932],
        ["Fellers", 70.933],
        ["Fehr", 70.934],
        ["Eslinger", 70.935],
        ["Escalera", 70.936],
        ["Enciso", 70.937],
        ["Duley", 70.938],
        ["Dittman", 70.939],
        ["Dineen", 70.94],
        ["Diller", 70.942],
        ["Devault", 70.943],
        ["Dao", 70.944],
        ["Collings", 70.945],
        ["Clymer", 70.946],
        ["Clowers", 70.947],
        ["Chavers", 70.948],
        ["Charland", 70.949],
        ["Castorena", 70.95],
        ["Castello", 70.951],
        ["Camargo", 70.953],
        ["Bunce", 70.954],
        ["Bullen", 70.955],
        ["Boyes", 70.956],
        ["Borchers", 70.957],
        ["Borchardt", 70.958],
        ["Birnbaum", 70.959],
        ["Birdsall", 70.96],
        ["Billman", 70.961],
        ["Benites", 70.962],
        ["Bankhead", 70.964],
        ["Ange", 70.965],
        ["Ammerman", 70.966],
        ["Adkison", 70.967],
        ["Yuan", 70.968],
        ["Winegar", 70.969],
        ["Wickman", 70.97],
        ["Wear", 70.971],
        ["Warr", 70.972],
        ["Warnke", 70.973],
        ["Villeneuve", 70.974],
        ["Veasey", 70.975],
        ["Vassallo", 70.977],
        ["Van Natta", 70.978],
        ["Vadnais", 70.979],
        ["Twilley", 70.98],
        ["Truelove", 70.981],
        ["Towery", 70.982],
        ["Tomblin", 70.983],
        ["Tippett", 70.984],
        ["Theiss", 70.985],
        ["Talkington", 70.986],
        ["Talamantes", 70.987],
        ["Swart", 70.988],
        ["Swanger", 70.99],
        ["Streit", 70.991],
        ["Straw", 70.992],
        ["Stines", 70.993],
        ["Stabler", 70.994],
        ["Spurling", 70.995],
        ["Sobel", 70.996],
        ["Sine", 70.997],
        ["Simmers", 70.998],
        ["Shippy", 70.999],
        ["Shiflett", 71],
        ["Shearin", 71.001],
        ["Sauter", 71.002],
        ["Sanderlin", 71.004],
        ["Rusch", 71.005],
        ["Runkle", 71.006],
        ["Ruckman", 71.007],
        ["Rorie", 71.008],
        ["Roesch", 71.009],
        ["Roberto", 71.01],
        ["Richert", 71.011],
        ["Rehm", 71.012],
        ["Randel", 71.013],
        ["Ragin", 71.014],
        ["Quesenberry", 71.015],
        ["Puentes", 71.017],
        ["Plyler", 71.018],
        ["Plotkin", 71.019],
        ["Paugh", 71.02],
        ["Oshaughnessy", 71.021],
        ["Ohalloran", 71.022],
        ["Norsworthy", 71.023],
        ["Niemann", 71.024],
        ["Nader", 71.025],
        ["Moorefield", 71.026],
        ["Mooneyham", 71.027],
        ["Modica", 71.028],
        ["Miyamoto", 71.03],
        ["Mickel", 71.031],
        ["Mebane", 71.032],
        ["McKinnie", 71.033],
        ["Mazurek", 71.034],
        ["Mancilla", 71.035],
        ["Lukas", 71.036],
        ["Lovins", 71.037],
        ["Loughlin", 71.038],
        ["Lotz", 71.039],
        ["Lindsley", 71.04],
        ["Liddle", 71.041],
        ["Levan", 71.042],
        ["Lederman", 71.044],
        ["LeClaire", 71.045],
        ["Lasseter", 71.046],
        ["LaPoint", 71.047],
        ["Lamoreaux", 71.048],
        ["La Follette", 71.049],
        ["Kubiak", 71.05],
        ["Kirtley", 71.051],
        ["Keffer", 71.052],
        ["Kaczmarek", 71.053],
        ["Jennette", 71.054],
        ["Housman", 71.055],
        ["Honey", 71.057],
        ["Hiers", 71.058],
        ["Hibbert", 71.059],
        ["Herrod", 71.06],
        ["Hegarty", 71.061],
        ["Hathorn", 71.062],
        ["Harsh", 71.063],
        ["Greenhaw", 71.064],
        ["Grafton", 71.065],
        ["Govea", 71.066],
        ["Gardener", 71.067],
        ["Futch", 71.068],
        ["Furst", 71.07],
        ["Frisbee", 71.071],
        ["Fred", 71.072],
        ["Franko", 71.073],
        ["Forcier", 71.074],
        ["Foran", 71.075],
        ["Flickinger", 71.076],
        ["Fairfield", 71.077],
        ["Eure", 71.078],
        ["Emrich", 71.079],
        ["Embrey", 71.08],
        ["Edgington", 71.081],
        ["Ecklund", 71.082],
        ["Eckard", 71.084],
        ["Durante", 71.085],
        ["Deyo", 71.086],
        ["Del Vecchio", 71.087],
        ["Deeds", 71.088],
        ["Dade", 71.089],
        ["Currey", 71.09],
        ["Cuff", 71.091],
        ["Creswell", 71.092],
        ["Cottrill", 71.093],
        ["Casavant", 71.094],
        ["Cartier", 71.095],
        ["Cargile", 71.097],
        ["Capel", 71.098],
        ["Cammack", 71.099],
        ["Calfee", 71.1],
        ["Buzzard", 71.101],
        ["Burse", 71.102],
        ["Burruss", 71.103],
        ["Brust", 71.104],
        ["Brousseau", 71.105],
        ["Bridwell", 71.106],
        ["Braaten", 71.107],
        ["Borkholder", 71.108],
        ["Bloomquist", 71.11],
        ["Bjork", 71.111],
        ["Bartelt", 71.112],
        ["Arp", 71.113],
        ["Amburgey", 71.114],
        ["Yeary", 71.115],
        ["Yao", 71.116],
        ["Whitefield", 71.117],
        ["Vinyard", 71.118],
        ["Vicente", 71.119],
        ["Van Valkenburg", 71.12],
        ["Twitchell", 71.121],
        ["Timmins", 71.122],
        ["Tester", 71.123],
        ["Tapper", 71.124],
        ["Stringham", 71.126],
        ["Starcher", 71.127],
        ["Spotts", 71.128],
        ["Slaugh", 71.129],
        ["Simonsen", 71.13],
        ["Sheffer", 71.131],
        ["Sequeira", 71.132],
        ["Rosati", 71.133],
        ["Rode", 71.134],
        ["Rhymes", 71.135],
        ["Reza", 71.136],
        ["Record", 71.137],
        ["Quint", 71.138],
        ["Pollak", 71.139],
        ["Peirce", 71.14],
        ["Patillo", 71.142],
        ["Parkerson", 71.143],
        ["Paiva", 71.144],
        ["Nilson", 71.145],
        ["Nice", 71.146],
        ["Nevin", 71.147],
        ["Narcisse", 71.148],
        ["Nair", 71.149],
        ["Mitton", 71.15],
        ["Merriam", 71.151],
        ["Merced", 71.152],
        ["Meiners", 71.153],
        ["McKain", 71.154],
        ["McElveen", 71.155],
        ["McBeth", 71.156],
        ["Marsden", 71.158],
        ["Marez", 71.159],
        ["Manke", 71.16],
        ["Mahurin", 71.161],
        ["Mabrey", 71.162],
        ["Luper", 71.163],
        ["Krull", 71.164],
        ["Kees", 71.165],
        ["Iles", 71.166],
        ["Hunsicker", 71.167],
        ["Hornbuckle", 71.168],
        ["Holtzclaw", 71.169],
        ["Hirt", 71.17],
        ["Hinnant", 71.171],
        ["Heston", 71.172],
        ["Hering", 71.173],
        ["Hemenway", 71.175],
        ["Hegwood", 71.176],
        ["Hearns", 71.177],
        ["Halterman", 71.178],
        ["Halls", 71.179],
        ["Guiterrez", 71.18],
        ["Grote", 71.181],
        ["Granillo", 71.182],
        ["Grainger", 71.183],
        ["Glasco", 71.184],
        ["Gilder", 71.185],
        ["Garren", 71.186],
        ["Garlock", 71.187],
        ["Garey", 71.188],
        ["Fu", 71.189],
        ["Fryar", 71.191],
        ["Fredricks", 71.192],
        ["Fraizer", 71.193],
        ["Foxx", 71.194],
        ["Foshee", 71.195],
        ["Ferrel", 71.196],
        ["Felty", 71.197],
        ["Feathers", 71.198],
        ["Everitt", 71.199],
        ["Evens", 71.2],
        ["Esser", 71.201],
        ["Elkin", 71.202],
        ["Eberhart", 71.203],
        ["Durso", 71.204],
        ["Duguay", 71.205],
        ["Driskill", 71.207],
        ["Doster", 71.208],
        ["Dewall", 71.209],
        ["DeVeau", 71.21],
        ["Demps", 71.211],
        ["DeMaio", 71.212],
        ["Del Real", 71.213],
        ["DeLeo", 71.214],
        ["DeLay", 71.215],
        ["Deem", 71.216],
        ["Darrah", 71.217],
        ["Cumberbatch", 71.218],
        ["Culberson", 71.219],
        ["Cranmer", 71.22],
        ["Cordle", 71.221],
        ["Colgan", 71.222],
        ["Chesley", 71.224],
        ["Cavallo", 71.225],
        ["Castellon", 71.226],
        ["Castelli", 71.227],
        ["Carreras", 71.228],
        ["Carnell", 71.229],
        ["Carmon", 71.23],
        ["Carmen", 71.231],
        ["Carlucci", 71.232],
        ["Bottom", 71.233],
        ["Bontrager", 71.234],
        ["Blumberg", 71.235],
        ["Blasingame", 71.236],
        ["Becton", 71.237],
        ["Ayon", 71.238],
        ["Artrip", 71.24],
        ["Arline", 71.241],
        ["Andujar", 71.242],
        ["Alkire", 71.243],
        ["Alder", 71.244],
        ["Agan", 71.245],
        ["Zukowski", 71.246],
        ["Zuckerman", 71.247],
        ["Zehr", 71.248],
        ["Wroblewski", 71.249],
        ["Wrigley", 71.25],
        ["Woodside", 71.251],
        ["Wigginton", 71.252],
        ["Westman", 71.253],
        ["Westgate", 71.254],
        ["Werts", 71.255],
        ["Washam", 71.256],
        ["Wardlow", 71.257],
        ["Walser", 71.258],
        ["Waiters", 71.26],
        ["Teller", 71.261],
        ["Tadlock", 71.262],
        ["Stuck", 71.263],
        ["Stringfield", 71.264],
        ["Stimpson", 71.265],
        ["Stickley", 71.266],
        ["Starbuck", 71.267],
        ["Standish", 71.268],
        ["Spurlin", 71.269],
        ["Spindler", 71.27],
        ["Speller", 71.271],
        ["Spaeth", 71.272],
        ["Sotomayor", 71.273],
        ["Sok", 71.274],
        ["Sluder", 71.275],
        ["Shryock", 71.276],
        ["Shepardson", 71.277],
        ["Shatley", 71.278],
        ["Scannell", 71.279],
        ["Santistevan", 71.281],
        ["Rosner", 71.282],
        ["Rolland", 71.283],
        ["Rhode", 71.284],
        ["Resto", 71.285],
        ["Reinhard", 71.286],
        ["Rathburn", 71.287],
        ["Prisco", 71.288],
        ["Poulsen", 71.289],
        ["Pinney", 71.29],
        ["Phares", 71.291],
        ["Pennock", 71.292],
        ["Pastrana", 71.293],
        ["Oviedo", 71.294],
        ["Ostler", 71.295],
        ["Noto", 71.296],
        ["Nauman", 71.297],
        ["Mulford", 71.298],
        ["Moise", 71.299],
        ["Moberly", 71.3],
        ["Mirabal", 71.302],
        ["Ming", 71.303],
        ["Metoyer", 71.304],
        ["Metheny", 71.305],
        ["Mentzer", 71.306],
        ["Meldrum", 71.307],
        ["McInturff", 71.308],
        ["McElyea", 71.309],
        ["McDougle", 71.31],
        ["Massaro", 71.311],
        ["Lumpkins", 71.312],
        ["Loveday", 71.313],
        ["Lofgren", 71.314],
        ["Loe", 71.315],
        ["Lirette", 71.316],
        ["Lesperance", 71.317],
        ["Lefkowitz", 71.318],
        ["Ledger", 71.319],
        ["Lauzon", 71.32],
        ["Lain", 71.321],
        ["Lachapelle", 71.322],
        ["Kurz", 71.324],
        ["Klassen", 71.325],
        ["Keough", 71.326],
        ["Kempton", 71.327],
        ["Kaelin", 71.328],
        ["Jeffords", 71.329],
        ["Im", 71.33],
        ["Huot", 71.331],
        ["Hsieh", 71.332],
        ["Hoyer", 71.333],
        ["Horwitz", 71.334],
        ["Hopp", 71.335],
        ["Hoeft", 71.336],
        ["Hennig", 71.337],
        ["Haskin", 71.338],
        ["Grill", 71.339],
        ["Gourdine", 71.34],
        ["Golightly", 71.341],
        ["Girouard", 71.342],
        ["Fulgham", 71.343],
        ["Fritsch", 71.345],
        ["Freer", 71.346],
        ["Frasher", 71.347],
        ["Foulk", 71.348],
        ["Firestone", 71.349],
        ["Fiorentino", 71.35],
        ["Fedor", 71.351],
        ["Feather", 71.352],
        ["Ensley", 71.353],
        ["Englehart", 71.354],
        ["Eells", 71.355],
        ["Ebel", 71.356],
        ["Dunphy", 71.357],
        ["Donahoe", 71.358],
        ["Dimas", 71.359],
        ["DiLeo", 71.36],
        ["DiBenedetto", 71.361],
        ["Dabrowski", 71.362],
        ["Crick", 71.363],
        ["Coonrod", 71.364],
        ["Conder", 71.366],
        ["Coddington", 71.367],
        ["Chunn", 71.368],
        ["Choy", 71.369],
        ["Chaput", 71.37],
        ["Cerna", 71.371],
        ["Carreiro", 71.372],
        ["Calahan", 71.373],
        ["Braggs", 71.374],
        ["Bourdon", 71.375],
        ["Boner", 71.376],
        ["Bollman", 71.377],
        ["Bittle", 71.378],
        ["Ben", 71.379],
        ["Behm", 71.38],
        ["Bauder", 71.381],
        ["Batt", 71.382],
        ["Barreras", 71.383],
        ["Aubuchon", 71.384],
        ["Anzalone", 71.385],
        ["Adamo", 71.386],
        ["Zhou", 71.388],
        ["Zerbe", 71.389],
        ["Zachery", 71.39],
        ["Witty", 71.391],
        ["Wirt", 71.392],
        ["Willcox", 71.393],
        ["Westberg", 71.394],
        ["Weikel", 71.395],
        ["Waymire", 71.396],
        ["Vroman", 71.397],
        ["Vinci", 71.398],
        ["Vallejos", 71.399],
        ["Tutor", 71.4],
        ["Truesdell", 71.401],
        ["Troutt", 71.402],
        ["Trotta", 71.403],
        ["Tollison", 71.404],
        ["Toles", 71.405],
        ["Tichenor", 71.406],
        ["Tai", 71.407],
        ["Symonds", 71.408],
        ["Surles", 71.409],
        ["Sunday", 71.41],
        ["Strayer", 71.411],
        ["Stgeorge", 71.412],
        ["Sroka", 71.413],
        ["Sorrentino", 71.414],
        ["Solares", 71.415],
        ["Snelson", 71.416],
        ["Silvestri", 71.417],
        ["Sikorski", 71.419],
        ["Shawver", 71.42],
        ["Schumaker", 71.421],
        ["Schorr", 71.422],
        ["Schooley", 71.423],
        ["Scates", 71.424],
        ["Satterlee", 71.425],
        ["Satchell", 71.426],
        ["Sacks", 71.427],
        ["Rymer", 71.428],
        ["Roselli", 71.429],
        ["Robitaille", 71.43],
        ["Riegel", 71.431],
        ["Richer", 71.432],
        ["Regis", 71.433],
        ["Reames", 71.434],
        ["Provenzano", 71.435],
        ["Proper", 71.436],
        ["Priestley", 71.437],
        ["Plaisance", 71.438],
        ["Pettey", 71.439],
        ["Palomares", 71.44],
        ["Oman", 71.441],
        ["Nowakowski", 71.442],
        ["Nace", 71.443],
        ["Monette", 71.444],
        ["Minyard", 71.445],
        ["McLamb", 71.446],
        ["McHone", 71.447],
        ["McCarroll", 71.449],
        ["Masson", 71.45],
        ["Marco", 71.451],
        ["Magoon", 71.452],
        ["Maddy", 71.453],
        ["Lundin", 71.454],
        ["Loza", 71.455],
        ["Licata", 71.456],
        ["Lesley", 71.457],
        ["Leonhardt", 71.458],
        ["Lema", 71.459],
        ["Landwehr", 71.46],
        ["Kircher", 71.461],
        ["Kinch", 71.462],
        ["Karpinski", 71.463],
        ["Johannsen", 71.464],
        ["Hussain", 71.465],
        ["Houghtaling", 71.466],
        ["Hoskinson", 71.467],
        ["Hollaway", 71.468],
        ["Holeman", 71.469],
        ["Hobgood", 71.47],
        ["Hilt", 71.471],
        ["Hiebert", 71.472],
        ["Gros", 71.473],
        ["Gram", 71.474],
        ["Goggin", 71.475],
        ["Gentle", 71.476],
        ["Geissler", 71.477],
        ["Gadbois", 71.478],
        ["Gabaldon", 71.48],
        ["Fleshman", 71.481],
        ["Flannigan", 71.482],
        ["Files", 71.483],
        ["Fairman", 71.484],
        ["Epp", 71.485],
        ["Eilers", 71.486],
        ["Dycus", 71.487],
        ["Dunmire", 71.488],
        ["Duffield", 71.489],
        ["Dowler", 71.49],
        ["Ditto", 71.491],
        ["Deloatch", 71.492],
        ["DeHaan", 71.493],
        ["Deemer", 71.494],
        ["Corner", 71.495],
        ["Clayborn", 71.496],
        ["Christofferso", 71.497],
        ["Chilson", 71.498],
        ["Chesney", 71.499],
        ["Chatfield", 71.5],
        ["Charlie", 71.501],
        ["Caster", 71.502],
        ["Carron", 71.503],
        ["Canale", 71.504],
        ["Camden", 71.505],
        ["Buff", 71.506],
        ["Brigman", 71.507],
        ["Branstetter", 71.508],
        ["Bosse", 71.509],
        ["Borton", 71.511],
        ["Bonar", 71.512],
        ["Blau", 71.513],
        ["Biron", 71.514],
        ["Beagle", 71.515],
        ["Barroso", 71.516],
        ["Arvin", 71.517],
        ["Arispe", 71.518],
        ["Zacharias", 71.519],
        ["Zabel", 71.52],
        ["Yaeger", 71.521],
        ["Works", 71.522],
        ["Woolford", 71.523],
        ["Whetzel", 71.524],
        ["Weakley", 71.525],
        ["Veatch", 71.526],
        ["Van Deusen", 71.527],
        ["Tufts", 71.528],
        ["Troxel", 71.529],
        ["Troche", 71.53],
        ["Traver", 71.531],
        ["Townsel", 71.532],
        ["Tosh", 71.533],
        ["Talarico", 71.534],
        ["Swilley", 71.535],
        ["Sterrett", 71.536],
        ["Stenger", 71.537],
        ["Springfield", 71.538],
        ["Speakman", 71.539],
        ["Sowards", 71.54],
        ["Sours", 71.541],
        ["Souders", 71.542],
        ["Souder", 71.543],
        ["Soles", 71.544],
        ["Sobers", 71.545],
        ["Snoddy", 71.546],
        ["Smither", 71.547],
        ["Sias", 71.548],
        ["Shute", 71.549],
        ["Shoaf", 71.55],
        ["Shahan", 71.551],
        ["Schuetz", 71.552],
        ["Scaggs", 71.553],
        ["Santini", 71.554],
        ["Rosson", 71.555],
        ["Rolen", 71.556],
        ["Robidoux", 71.557],
        ["Rentas", 71.558],
        ["Recio", 71.559],
        ["Pixley", 71.56],
        ["Pawlowski", 71.561],
        ["Pawlak", 71.563],
        ["Paull", 71.564],
        ["Pascal", 71.565],
        ["Overbey", 71.566],
        ["Orear", 71.567],
        ["Oliveri", 71.568],
        ["Oldenburg", 71.569],
        ["Nutting", 71.57],
        ["Naugle", 71.571],
        ["Mote", 71.572],
        ["Mossman", 71.573],
        ["Moor", 71.574],
        ["Misner", 71.575],
        ["Milazzo", 71.576],
        ["Michelson", 71.577],
        ["Mei", 71.578],
        ["McEntee", 71.579],
        ["McCullar", 71.58],
        ["McCree", 71.581],
        ["McAleer", 71.582],
        ["Mazzone", 71.583],
        ["Maxim", 71.584],
        ["Marshal", 71.585],
        ["Mandell", 71.586],
        ["Manahan", 71.587],
        ["Malott", 71.588],
        ["Maisonet", 71.589],
        ["Mailloux", 71.59],
        ["Lumley", 71.591],
        ["Lowrie", 71.592],
        ["Louviere", 71.593],
        ["Lipinski", 71.594],
        ["Lindemann", 71.595],
        ["Leppert", 71.596],
        ["Leopold", 71.597],
        ["Leasure", 71.598],
        ["Leaf", 71.599],
        ["Labarge", 71.6],
        ["Kubik", 71.601],
        ["Knisely", 71.602],
        ["Knepp", 71.603],
        ["Kenworthy", 71.604],
        ["Kennelly", 71.605],
        ["Kelch", 71.606],
        ["Karg", 71.607],
        ["Kanter", 71.608],
        ["Ignacio", 71.609],
        ["Hyer", 71.61],
        ["Houchin", 71.611],
        ["Hosley", 71.612],
        ["Hosler", 71.613],
        ["Hollon", 71.614],
        ["Holleman", 71.615],
        ["Heitman", 71.616],
        ["Hebb", 71.617],
        ["Haggins", 71.618],
        ["Gwaltney", 71.619],
        ["Guin", 71.62],
        ["Greenman", 71.622],
        ["Goulding", 71.623],
        ["Gorden", 71.624],
        ["Goodyear", 71.625],
        ["Geraci", 71.626],
        ["Georges", 71.627],
        ["Gathers", 71.628],
        ["Frison", 71.629],
        ["Feagin", 71.63],
        ["Falconer", 71.631],
        ["Espada", 71.632],
        ["Erving", 71.633],
        ["Erikson", 71.634],
        ["Eisenhauer", 71.635],
        ["Eder", 71.636],
        ["Ebeling", 71.637],
        ["Durgin", 71.638],
        ["Drown", 71.639],
        ["Dowdle", 71.64],
        ["Dinwiddie", 71.641],
        ["Del Castillo", 71.642],
        ["Dedrick", 71.643],
        ["Crimmins", 71.644],
        ["Covell", 71.645],
        ["Cournoyer", 71.646],
        ["Coria", 71.647],
        ["Cohan", 71.648],
        ["Cataldo", 71.649],
        ["Carpentier", 71.65],
        ["Canas", 71.651],
        ["Campa", 71.652],
        ["Brode", 71.653],
        ["Brashears", 71.654],
        ["Blaser", 71.655],
        ["Bicknell", 71.656],
        ["Berk", 71.657],
        ["Bednar", 71.658],
        ["Barwick", 71.659],
        ["Ascencio", 71.66],
        ["Althoff", 71.661],
        ["Almodovar", 71.662],
        ["Alamo", 71.663],
        ["Zirkle", 71.664],
        ["Zabala", 71.665],
        ["Xu", 71.666],
        ["Wolverton", 71.667],
        ["Winebrenner", 71.668],
        ["Wetherell", 71.669],
        ["Westlake", 71.67],
        ["Wegener", 71.671],
        ["Weddington", 71.672],
        ["Vong", 71.673],
        ["Tuten", 71.674],
        ["Trosclair", 71.675],
        ["Trim", 71.676],
        ["Tressler", 71.677],
        ["Theroux", 71.678],
        ["Teske", 71.679],
        ["Sword", 71.68],
        ["Swinehart", 71.681],
        ["Swensen", 71.682],
        ["Sundquist", 71.683],
        ["Southall", 71.684],
        ["Socha", 71.685],
        ["Sizer", 71.686],
        ["Silverberg", 71.687],
        ["Shortt", 71.688],
        ["Shimizu", 71.689],
        ["Sherrard", 71.69],
        ["Shen", 71.691],
        ["Shaeffer", 71.692],
        ["Seth", 71.693],
        ["Scheid", 71.694],
        ["Scheetz", 71.695],
        ["Saravia", 71.696],
        ["Sanner", 71.697],
        ["Rubinstein", 71.698],
        ["Rozell", 71.699],
        ["Romer", 71.7],
        ["Ringo", 71.701],
        ["Rheaume", 71.702],
        ["Reisinger", 71.703],
        ["Raven", 71.704],
        ["Randles", 71.705],
        ["Pullum", 71.706],
        ["Petrella", 71.707],
        ["Payan", 71.708],
        ["Papp", 71.709],
        ["Pablo", 71.71],
        ["Nordin", 71.711],
        ["Norcross", 71.712],
        ["Nicoletti", 71.713],
        ["Nicholes", 71.714],
        ["Newbold", 71.715],
        ["Nakagawa", 71.716],
        ["Mraz", 71.717],
        ["Monteith", 71.718],
        ["Milstead", 71.719],
        ["Milliner", 71.72],
        ["Mellen", 71.721],
        ["McCardle", 71.722],
        ["Matthias", 71.723],
        ["Marcy", 71.724],
        ["Luft", 71.725],
        ["Loo", 71.726],
        ["Locker", 71.727],
        ["Liptak", 71.728],
        ["Lipp", 71.729],
        ["Leitch", 71.73],
        ["Latimore", 71.731],
        ["Larrison", 71.732],
        ["Landau", 71.733],
        ["Laborde", 71.734],
        ["Koval", 71.735],
        ["Izquierdo", 71.736],
        ["Hymel", 71.737],
        ["Hoskin", 71.738],
        ["Holte", 71.739],
        ["Hoefer", 71.74],
        ["Hayworth", 71.741],
        ["Hausman", 71.742],
        ["Harrill", 71.743],
        ["Harrel", 71.744],
        ["Hardt", 71.745],
        ["Gully", 71.746],
        ["Groover", 71.747],
        ["Grinnell", 71.748],
        ["Greenspan", 71.749],
        ["Graver", 71.75],
        ["Grandberry", 71.751],
        ["Gorrell", 71.752],
        ["Goldenberg", 71.753],
        ["Goguen", 71.754],
        ["Gilleland", 71.755],
        ["Garr", 71.756],
        ["Fuson", 71.757],
        ["Foye", 71.758],
        ["Felt", 71.759],
        ["Feldmann", 71.76],
        ["Everly", 71.761],
        ["Dyess", 71.762],
        ["Dyal", 71.763],
        ["Dunnigan", 71.764],
        ["Downie", 71.765],
        ["Dolby", 71.766],
        ["Divine", 71.767],
        ["Deatherage", 71.768],
        ["Dates", 71.769],
        ["Danna", 71.77],
        ["Cosey", 71.771],
        ["Corrado", 71.772],
        ["Cheever", 71.773],
        ["Celaya", 71.774],
        ["Caver", 71.775],
        ["Cashion", 71.776],
        ["Caplinger", 71.777],
        ["Cansler", 71.778],
        ["Byrge", 71.779],
        ["Bruder", 71.78],
        ["Brew", 71.781],
        ["Breuer", 71.782],
        ["Breslin", 71.783],
        ["Brazelton", 71.784],
        ["Botkin", 71.785],
        ["Bonneau", 71.786],
        ["Bones", 71.787],
        ["Bondurant", 71.788],
        ["Bohanan", 71.789],
        ["Bogue", 71.79],
        ["Boes", 71.791],
        ["Bodner", 71.792],
        ["Boatner", 71.793],
        ["Blatt", 71.794],
        ["Bickley", 71.795],
        ["Belliveau", 71.796],
        ["Beiler", 71.797],
        ["Beier", 71.798],
        ["Beckstead", 71.799],
        ["Bart", 71.8],
        ["Bang", 71.801],
        ["Bachmann", 71.802],
        ["Atkin", 71.803],
        ["Aron", 71.804],
        ["Andreas", 71.805],
        ["Altizer", 71.806],
        ["Alloway", 71.807],
        ["Allaire", 71.808],
        ["Albro", 71.809],
        ["Abron", 71.81],
        ["Zellmer", 71.811],
        ["Yetter", 71.812],
        ["Yelverton", 71.813],
        ["Wiltshire", 71.814],
        ["Wiens", 71.815],
        ["Whidden", 71.816],
        ["Wait", 71.817],
        ["Viramontes", 71.818],
        ["Van Wormer", 71.819],
        ["Topper", 71.82],
        ["Tarantino", 71.821],
        ["Tanksley", 71.822],
        ["Sumlin", 71.823],
        ["Strauch", 71.824],
        ["Strang", 71.825],
        ["Stice", 71.826],
        ["Spahn", 71.827],
        ["Sosebee", 71.828],
        ["Sigala", 71.829],
        ["Shrout", 71.83],
        ["Seamon", 71.831],
        ["Schrum", 71.832],
        ["Schneck", 71.833],
        ["Schantz", 71.834],
        ["Said", 71.835],
        ["Ruddy", 71.836],
        ["Romig", 71.837],
        ["Roehl", 71.838],
        ["Renninger", 71.839],
        ["Reding", 71.84],
        ["Pyne", 71.841],
        ["Polak", 71.842],
        ["Pohlman", 71.843],
        ["Pasillas", 71.844],
        ["Oldfield", 71.845],
        ["Oldaker", 71.846],
        ["Ohanlon", 71.847],
        ["Ogilvie", 71.848],
        ["Norberg", 71.849],
        ["Nolette", 71.85],
        ["Nies", 71.851],
        ["Neufeld", 71.852],
        ["Nellis", 71.853],
        ["Mummert", 71.854],
        ["Mulvihill", 71.855],
        ["Mullaney", 71.856],
        ["Monteleone", 71.857],
        ["Mendonca", 71.858],
        ["Meisner", 71.859],
        ["McMullan", 71.86],
        ["McCluney", 71.861],
        ["Mattis", 71.862],
        ["Massengill", 71.863],
        ["Manfredi", 71.864],
        ["Luedtke", 71.865],
        ["Lounsbury", 71.866],
        ["Lora", 71.867],
        ["Liberatore", 71.868],
        ["Leek", 71.869],
        ["Lease", 71.87],
        ["Lazaro", 71.871],
        ["Lamphere", 71.872],
        ["Laforge", 71.873],
        ["Kuo", 71.874],
        ["Koo", 71.875],
        ["Jourdan", 71.876],
        ["Ismail", 71.876],
        ["Iorio", 71.877],
        ["Iniguez", 71.878],
        ["Ikeda", 71.879],
        ["Hubler", 71.88],
        ["Hodgdon", 71.881],
        ["Hocking", 71.882],
        ["Heacock", 71.883],
        ["Haslam", 71.884],
        ["Haralson", 71.885],
        ["Hanshaw", 71.886],
        ["Hannum", 71.887],
        ["Hallam", 71.888],
        ["Haden", 71.889],
        ["Garnes", 71.89],
        ["Garces", 71.891],
        ["Gammage", 71.892],
        ["Gambino", 71.893],
        ["Finkel", 71.894],
        ["Faucett", 71.895],
        ["Fahy", 71.896],
        ["Esteban", 71.897],
        ["Ehrhardt", 71.898],
        ["Eggen", 71.899],
        ["Dusek", 71.9],
        ["Durrant", 71.901],
        ["Dubay", 71.902],
        ["Dones", 71.903],
        ["Dey", 71.904],
        ["DePasquale", 71.905],
        ["de Lucia", 71.906],
        ["Degraff", 71.907],
        ["Deer", 71.908],
        ["DeCamp", 71.909],
        ["Davalos", 71.91],
        ["Darwin", 71.911],
        ["Dan", 71.912],
        ["Cullins", 71.913],
        ["Conard", 71.914],
        ["Clouser", 71.915],
        ["Clontz", 71.916],
        ["Cifuentes", 71.917],
        ["Chico", 71.918],
        ["Chappel", 71.919],
        ["Chaffins", 71.92],
        ["Celis", 71.921],
        ["Carwile", 71.922],
        ["Byram", 71.923],
        ["Bruggeman", 71.924],
        ["Brick", 71.925],
        ["Bressler", 71.926],
        ["Brathwaite", 71.927],
        ["Brasfield", 71.928],
        ["Bradburn", 71.929],
        ["Boose", 71.93],
        ["Boon", 71.931],
        ["Bodie", 71.932],
        ["Blosser", 71.933],
        ["Blas", 71.934],
        ["Bise", 71.935],
        ["Bertsch", 71.936],
        ["Bernardi", 71.937],
        ["Bernabe", 71.938],
        ["Bengtson", 71.939],
        ["Barrette", 71.94],
        ["Astorga", 71.941],
        ["Armand", 71.942],
        ["Antone", 71.943],
        ["Alday", 71.944],
        ["Albee", 71.945],
        ["Abrahamson", 71.945],
        ["Yarnell", 71.946],
        ["Wiltse", 71.947],
        ["Wile", 71.948],
        ["Wiebe", 71.949],
        ["Waguespack", 71.95],
        ["Vasser", 71.951],
        ["Upham", 71.952],
        ["Tyre", 71.953],
        ["Turek", 71.954],
        ["Tune", 71.955],
        ["Traxler", 71.956],
        ["Torain", 71.957],
        ["Tomaszewski", 71.958],
        ["Tinnin", 71.959],
        ["Tiner", 71.96],
        ["Tindell", 71.961],
        ["Teed", 71.962],
        ["Styron", 71.963],
        ["Stahlman", 71.964],
        ["Staab", 71.965],
        ["Spoon", 71.966],
        ["Spells", 71.967],
        ["Skiba", 71.968],
        ["Shih", 71.969],
        ["Sheperd", 71.97],
        ["Seidl", 71.971],
        ["Secor", 71.972],
        ["Schutte", 71.973],
        ["Sanfilippo", 71.974],
        ["Ruder", 71.975],
        ["Rondon", 71.976],
        ["Reina", 71.977],
        ["Rearick", 71.977],
        ["Rank", 71.978],
        ["Procter", 71.979],
        ["Prochaska", 71.98],
        ["Pettengill", 71.981],
        ["Pauly", 71.982],
        ["Neilsen", 71.983],
        ["Nally", 71.984],
        ["Mutter", 71.985],
        ["Mullenax", 71.986],
        ["Morano", 71.987],
        ["Meads", 71.988],
        ["McNaughton", 71.989],
        ["McMurtry", 71.99],
        ["McMath", 71.991],
        ["McKinsey", 71.992],
        ["Matthes", 71.993],
        ["Massenburg", 71.994],
        ["Marlar", 71.995],
        ["Margolis", 71.996],
        ["Marcos", 71.997],
        ["Malin", 71.998],
        ["Magallon", 71.999],
        ["Mackin", 72],
        ["Lovette", 72.001],
        ["Loughran", 72.002],
        ["Loring", 72.003],
        ["Longstreet", 72.004],
        ["Loiselle", 72.005],
        ["Lenihan", 72.006],
        ["Laub", 72.007],
        ["Kunze", 72.008],
        ["Kull", 72.009],
        ["Koepke", 72.009],
        ["Knights", 72.01],
        ["Kerwin", 72.011],
        ["Kalinowski", 72.012],
        ["Kagan", 72.013],
        ["Innis", 72.014],
        ["Innes", 72.015],
        ["Husband", 72.016],
        ["Holtzman", 72.017],
        ["Heinemann", 72.018],
        ["Harshman", 72.019],
        ["Haider", 72.02],
        ["Haack", 72.021],
        ["Guss", 72.022],
        ["Grondin", 72.023],
        ["Grissett", 72.024],
        ["Greenawalt", 72.025],
        ["Gravel", 72.026],
        ["Goudy", 72.027],
        ["Goodlett", 72.028],
        ["Goldston", 72.029],
        ["Gokey", 72.03],
        ["Goin", 72.031],
        ["Gardea", 72.032],
        ["Galaviz", 72.033],
        ["Gafford", 72.034],
        ["Gabrielson", 72.035],
        ["Furlow", 72.036],
        ["Fritch", 72.037],
        ["Fordyce", 72.038],
        ["Folger", 72.039],
        ["Elizalde", 72.04],
        ["Ehlert", 72.041],
        ["Eckhoff", 72.041],
        ["Eccleston", 72.042],
        ["Ealey", 72.043],
        ["Dubin", 72.044],
        ["Dolphin", 72.045],
        ["Dieter", 72.046],
        ["Diemer", 72.047],
        ["Deschamps", 72.048],
        ["de la Pena", 72.049],
        ["Decicco", 72.05],
        ["Debolt", 72.051],
        ["Daum", 72.052],
        ["Cullinan", 72.053],
        ["Crittendon", 72.054],
        ["Crase", 72.055],
        ["Cossey", 72.056],
        ["Coppock", 72.057],
        ["Coots", 72.058],
        ["Colyer", 72.059],
        ["Columbus", 72.06],
        ["Cluck", 72.061],
        ["Chamberland", 72.062],
        ["Cane", 72.063],
        ["Burkhead", 72.064],
        ["Bumpus", 72.065],
        ["Buchan", 72.066],
        ["Borman", 72.067],
        ["Bork", 72.068],
        ["Boe", 72.069],
        ["Birkholz", 72.07],
        ["Berardi", 72.071],
        ["Benda", 72.072],
        ["Behnke", 72.073],
        ["Barter", 72.073],
        ["Auer", 72.074],
        ["Amezquita", 72.075],
        ["Wotring", 72.076],
        ["Wirtz", 72.077],
        ["Wingert", 72.078],
        ["Wiesner", 72.079],
        ["Whitesides", 72.08],
        ["Weyant", 72.081],
        ["Wainscott", 72.082],
        ["Vivian", 72.083],
        ["Venezia", 72.084],
        ["Varnell", 72.085],
        ["Tussey", 72.086],
        ["Trainer", 72.087],
        ["Toll", 72.088],
        ["Thurlow", 72.089],
        ["Tack", 72.09],
        ["Tabares", 72.091],
        ["Stiver", 72.092],
        ["Stell", 72.093],
        ["Starke", 72.094],
        ["Stanhope", 72.095],
        ["Stanek", 72.095],
        ["Sisler", 72.096],
        ["Sinnott", 72.097],
        ["Sidney", 72.098],
        ["Siciliano", 72.099],
        ["Shehan", 72.1],
        ["Selph", 72.101],
        ["Seager", 72.102],
        ["Scurlock", 72.103],
        ["Scranton", 72.104],
        ["Santucci", 72.105],
        ["Santangelo", 72.106],
        ["Saltsman", 72.107],
        ["Ruel", 72.108],
        ["Ropp", 72.109],
        ["Rolling", 72.11],
        ["Rogge", 72.111],
        ["Rettig", 72.112],
        ["Renwick", 72.113],
        ["Reidy", 72.114],
        ["Reider", 72.115],
        ["Redfield", 72.116],
        ["Quam", 72.116],
        ["Premo", 72.117],
        ["Port", 72.118],
        ["Pier", 72.119],
        ["Peet", 72.12],
        ["Parente", 72.121],
        ["Paolucci", 72.122],
        ["Pan", 72.123],
        ["Palmquist", 72.124],
        ["Orme", 72.125],
        ["Ohler", 72.126],
        ["Ogg", 72.127],
        ["Netherton", 72.128],
        ["Mutchler", 72.129],
        ["Morita", 72.13],
        ["Mistretta", 72.131],
        ["Minnis", 72.132],
        ["Middendorf", 72.133],
        ["Menzel", 72.134],
        ["Mendosa", 72.135],
        ["Mendelson", 72.136],
        ["Meaux", 72.136],
        ["McSpadden", 72.137],
        ["McQuaid", 72.138],
        ["McNatt", 72.139],
        ["Manigault", 72.14],
        ["Maney", 72.141],
        ["Mager", 72.142],
        ["Lung", 72.143],
        ["Lukes", 72.144],
        ["Lopresti", 72.145],
        ["Liriano", 72.146],
        ["Lipton", 72.147],
        ["Letson", 72.148],
        ["Lechuga", 72.149],
        ["Lazenby", 72.15],
        ["Lauria", 72.151],
        ["Larimore", 72.152],
        ["Kwok", 72.153],
        ["Kwak", 72.154],
        ["Krupp", 72.155],
        ["Krupa", 72.156],
        ["Krum", 72.157],
        ["Kopec", 72.157],
        ["Kinchen", 72.158],
        ["Kifer", 72.159],
        ["Kerney", 72.16],
        ["Kerner", 72.161],
        ["Kennison", 72.162],
        ["Kegley", 72.163],
        ["Kays", 72.164],
        ["Karcher", 72.165],
        ["Justis", 72.166],
        ["Johson", 72.167],
        ["Jellison", 72.168],
        ["Janke", 72.169],
        ["Isabell", 72.17],
        ["Huskins", 72.171],
        ["Holzman", 72.172],
        ["Hollie", 72.173],
        ["Hinojos", 72.174],
        ["Highland", 72.175],
        ["Hefley", 72.176],
        ["He", 72.177],
        ["Hatmaker", 72.178],
        ["Harte", 72.178],
        ["Halloway", 72.179],
        ["Hallenbeck", 72.18],
        ["Goodwyn", 72.181],
        ["Glaspie", 72.182],
        ["Gillian", 72.183],
        ["Geise", 72.184],
        ["Fullwood", 72.185],
        ["Fryman", 72.186],
        ["Frew", 72.187],
        ["Frakes", 72.188],
        ["Fraire", 72.189],
        ["Farrer", 72.19],
        ["Enlow", 72.191],
        ["Engen", 72.192],
        ["Ellzey", 72.193],
        ["Eckles", 72.194],
        ["Earles", 72.195],
        ["Ealy", 72.196],
        ["Dunkley", 72.197],
        ["Drinkard", 72.198],
        ["Dreiling", 72.198],
        ["Draeger", 72.199],
        ["Dinardo", 72.2],
        ["Dills", 72.201],
        ["Desroches", 72.202],
        ["de Santiago", 72.203],
        ["Current", 72.204],
        ["Curlee", 72.205],
        ["Crumbley", 72.206],
        ["Critchlow", 72.207],
        ["Coury", 72.208],
        ["Courtright", 72.209],
        ["Coffield", 72.21],
        ["Cleek", 72.211],
        ["Christen", 72.212],
        ["Charpentier", 72.213],
        ["Cardone", 72.214],
        ["Caples", 72.215],
        ["Cantin", 72.216],
        ["Buntin", 72.217],
        ["Bugbee", 72.218],
        ["Brinkerhoff", 72.219],
        ["Brackin", 72.219],
        ["Bourland", 72.22],
        ["Bohl", 72.221],
        ["Bogdan", 72.222],
        ["Blassingame", 72.223],
        ["Beacham", 72.224],
        ["Banning", 72.225],
        ["Auguste", 72.226],
        ["Andreasen", 72.227],
        ["Amann", 72.228],
        ["Almon", 72.229],
        ["Alejo", 72.23],
        ["Adelman", 72.231],
        ["Abston", 72.232],
        ["Zeno", 72.233],
        ["Yerger", 72.234],
        ["Wymer", 72.235],
        ["Woodberry", 72.236],
        ["Windley", 72.237],
        ["Whiteaker", 72.237],
        ["Westfield", 72.238],
        ["Weibel", 72.239],
        ["Wanner", 72.24],
        ["Waldrep", 72.241],
        ["Vital", 72.242],
        ["Villani", 72.243],
        ["Van Arsdale", 72.244],
        ["Utterback", 72.245],
        ["Updike", 72.246],
        ["Triggs", 72.247],
        ["Topete", 72.248],
        ["Tolar", 72.249],
        ["Tigner", 72.25],
        ["Thoms", 72.251],
        ["Tauber", 72.252],
        ["Tarvin", 72.253],
        ["Tally", 72.253],
        ["Swiney", 72.254],
        ["Sweatman", 72.255],
        ["Studebaker", 72.256],
        ["Streets", 72.257],
        ["Stennett", 72.258],
        ["States", 72.259],
        ["Starrett", 72.26],
        ["Stannard", 72.261],
        ["Stalvey", 72.262],
        ["Sonnenberg", 72.263],
        ["Smithey", 72.264],
        ["Sieber", 72.265],
        ["Sickles", 72.266],
        ["Shinault", 72.267],
        ["Segars", 72.268],
        ["Sanger", 72.268],
        ["Salmeron", 72.269],
        ["Rothe", 72.27],
        ["Rizzi", 72.271],
        ["Rine", 72.272],
        ["Ricard", 72.273],
        ["Restrepo", 72.274],
        ["Ralls", 72.275],
        ["Ragusa", 72.276],
        ["Quiroga", 72.277],
        ["Ping", 72.278],
        ["Phung", 72.279],
        ["Pero", 72.28],
        ["Pegg", 72.281],
        ["Pavlik", 72.282],
        ["Papenfuss", 72.283],
        ["Oropeza", 72.283],
        ["Omar", 72.284],
        ["Okane", 72.285],
        ["Neer", 72.286],
        ["Nee", 72.287],
        ["Nathaniel", 72.288],
        ["Mudge", 72.289],
        ["Mozingo", 72.29],
        ["Molinaro", 72.291],
        ["Mikel", 72.292],
        ["McVicker", 72.293],
        ["McGarvey", 72.294],
        ["McFalls", 72.295],
        ["McCraney", 72.296],
        ["Matus", 72.297],
        ["Magers", 72.298],
        ["Llanos", 72.298],
        ["Livermore", 72.299],
        ["Liss", 72.3],
        ["Linehan", 72.301],
        ["Leto", 72.302],
        ["Leitner", 72.303],
        ["Laymon", 72.304],
        ["Lawing", 72.305],
        ["Lawerence", 72.306],
        ["Lacourse", 72.307],
        ["Kwong", 72.308],
        ["Kollar", 72.309],
        ["Kneeland", 72.31],
        ["Keo", 72.311],
        ["Kennett", 72.312],
        ["Kellett", 72.313],
        ["Kangas", 72.313],
        ["Janzen", 72.314],
        ["Hutter", 72.315],
        ["Huse", 72.316],
        ["Huling", 72.317],
        ["Hoss", 72.318],
        ["Hohn", 72.319],
        ["Hofmeister", 72.32],
        ["Hewes", 72.321],
        ["Hern", 72.322],
        ["Harjo", 72.323],
        ["Habib", 72.324],
        ["Gust", 72.325],
        ["Guice", 72.326],
        ["Grullon", 72.327],
        ["Greggs", 72.328],
        ["Grayer", 72.328],
        ["Granier", 72.329],
        ["Grable", 72.33],
        ["Gowdy", 72.331],
        ["Giannini", 72.332],
        ["Getchell", 72.333],
        ["Gartman", 72.334],
        ["Garnica", 72.335],
        ["Ganey", 72.336],
        ["Gallimore", 72.337],
        ["Fray", 72.338],
        ["Fetters", 72.339],
        ["Fergerson", 72.34],
        ["Farlow", 72.341],
        ["Fagundes", 72.342],
        ["Exley", 72.343],
        ["Esteves", 72.343],
        ["Enders", 72.344],
        ["Edenfield", 72.345],
        ["Easterwood", 72.346],
        ["Drakeford", 72.347],
        ["DiPasquale", 72.348],
        ["De Sousa", 72.349],
        ["DeShields", 72.35],
        ["Deeter", 72.351],
        ["Dedmon", 72.352],
        ["Debord", 72.353],
        ["Daughtery", 72.354],
        ["Cutts", 72.355],
        ["Courtemanche", 72.356],
        ["Coursey", 72.357],
        ["Copple", 72.358],
        ["Coomes", 72.358],
        ["Collis", 72.359],
        ["Coll", 72.36],
        ["Cogburn", 72.361],
        ["Clopton", 72.362],
        ["Choquette", 72.363],
        ["Chaidez", 72.364],
        ["Castrejon", 72.365],
        ["Calhoon", 72.366],
        ["Burbach", 72.367],
        ["Bulloch", 72.368],
        ["Buchman", 72.369],
        ["Bruhn", 72.37],
        ["Bohon", 72.371],
        ["Blough", 72.372],
        ["Bien", 72.373],
        ["Belmont", 72.374],
        ["Baynes", 72.374],
        ["Barstow", 72.375],
        ["Zeman", 72.376],
        ["Zackery", 72.377],
        ["Yardley", 72.378],
        ["Yamashita", 72.379],
        ["Wulff", 72.38],
        ["Wilken", 72.381],
        ["Wiliams", 72.382],
        ["Wickersham", 72.383],
        ["Wible", 72.384],
        ["Whipkey", 72.385],
        ["Wedgeworth", 72.386],
        ["Walmsley", 72.386],
        ["Walkup", 72.387],
        ["Vreeland", 72.388],
        ["Verrill", 72.389],
        ["Valera", 72.39],
        ["Umana", 72.391],
        ["Traub", 72.392],
        ["Timothy", 72.393],
        ["Swingle", 72.394],
        ["Swing", 72.395],
        ["Summey", 72.396],
        ["Stroupe", 72.397],
        ["Stockstill", 72.398],
        ["Steffey", 72.398],
        ["Stefanski", 72.399],
        ["Statler", 72.4],
        ["Stapp", 72.401],
        ["Speights", 72.402],
        ["Sons", 72.403],
        ["Solari", 72.404],
        ["Soderberg", 72.405],
        ["Slick", 72.406],
        ["Shunk", 72.407],
        ["Shorey", 72.408],
        ["Shewmaker", 72.409],
        ["Sheilds", 72.409],
        ["Schiffer", 72.41],
        ["Schank", 72.411],
        ["Schaff", 72.412],
        ["Sagers", 72.413],
        ["Rodger", 72.414],
        ["Rochon", 72.415],
        ["Riser", 72.416],
        ["Rickett", 72.417],
        ["Reale", 72.418],
        ["Raglin", 72.419],
        ["Poon", 72.42],
        ["Polly", 72.421],
        ["Polen", 72.421],
        ["Plata", 72.422],
        ["Pitcock", 72.423],
        ["Percival", 72.424],
        ["Palen", 72.425],
        ["Pahl", 72.426],
        ["Orona", 72.427],
        ["Oberle", 72.428],
        ["Nocera", 72.429],
        ["Navas", 72.43],
        ["Nault", 72.431],
        ["Mullings", 72.432],
        ["Mouser", 72.433],
        ["Moos", 72.433],
        ["Montejano", 72.434],
        ["Monreal", 72.435],
        ["Minick", 72.436],
        ["Middlebrook", 72.437],
        ["Meece", 72.438],
        ["McMillion", 72.439],
        ["McCullen", 72.44],
        ["Mauck", 72.441],
        ["Marshburn", 72.442],
        ["Maillet", 72.443],
        ["Mahaney", 72.444],
        ["Magner", 72.445],
        ["Maclin", 72.445],
        ["Lucey", 72.446],
        ["Litteral", 72.447],
        ["Lippincott", 72.448],
        ["Leite", 72.449],
        ["Leis", 72.45],
        ["Leaks", 72.451],
        ["Laurie", 72.452],
        ["Lamarre", 72.453],
        ["Kost", 72.454],
        ["Jurgens", 72.455],
        ["Jesus", 72.456],
        ["Jerkins", 72.457],
        ["Jager", 72.457],
        ["Hurwitz", 72.458],
        ["Hughley", 72.459],
        ["Hotaling", 72.46],
        ["Horstman", 72.461],
        ["Hohman", 72.462],
        ["Hocker", 72.463],
        ["Hively", 72.464],
        ["Hipps", 72.465],
        ["Hile", 72.466],
        ["Hessler", 72.467],
        ["Hermanson", 72.468],
        ["Hepworth", 72.469],
        ["Henn", 72.469],
        ["Helland", 72.47],
        ["Hedlund", 72.471],
        ["Harkless", 72.472],
        ["Haigler", 72.473],
        ["Gutierez", 72.474],
        ["Gum", 72.475],
        ["Grindstaff", 72.476],
        ["Glantz", 72.477],
        ["Giardina", 72.478],
        ["Gerken", 72.479],
        ["Gadsden", 72.48],
        ["Freda", 72.48],
        ["Finnerty", 72.481],
        ["Feld", 72.482],
        ["Farnum", 72.483],
        ["Encinas", 72.484],
        ["Elton", 72.485],
        ["Eager", 72.486],
        ["Drakes", 72.487],
        ["Dennie", 72.488],
        ["Cutlip", 72.489],
        ["Curtsinger", 72.49],
        ["Couto", 72.491],
        ["Cortinas", 72.492],
        ["Corby", 72.492],
        ["Choice", 72.493],
        ["Chiasson", 72.494],
        ["Carle", 72.495],
        ["Carballo", 72.496],
        ["Brindle", 72.497],
        ["Borum", 72.498],
        ["Bober", 72.499],
        ["Blagg", 72.5],
        ["Birk", 72.501],
        ["Berthiaume", 72.502],
        ["Beahm", 72.503],
        ["Batres", 72.504],
        ["Basnight", 72.504],
        ["Barbara", 72.505],
        ["Backes", 72.506],
        ["Axtell", 72.507],
        ["Aust", 72.508],
        ["Au", 72.509],
        ["Atterberry", 72.51],
        ["Alvares", 72.511],
        ["Alt", 72.512],
        ["Alegria", 72.513],
        ["Abe", 72.514],
        ["Yow", 72.515],
        ["Yip", 72.516],
        ["Woodell", 72.516],
        ["Wojciechowski", 72.517],
        ["Winfree", 72.518],
        ["Winbush", 72.519],
        ["Wiest", 72.52],
        ["Wesner", 72.521],
        ["Wax", 72.522],
        ["Wamsley", 72.523],
        ["Wakeman", 72.524],
        ["Verner", 72.525],
        ["Truex", 72.525],
        ["Trafton", 72.526],
        ["Toman", 72.527],
        ["Thorsen", 72.528],
        ["Thor", 72.529],
        ["Theus", 72.53],
        ["Tellier", 72.531],
        ["Tallant", 72.532],
        ["Szeto", 72.533],
        ["Strope", 72.534],
        ["Stills", 72.535],
        ["Stage", 72.535],
        ["Sorg", 72.536],
        ["Simkins", 72.537],
        ["Shuey", 72.538],
        ["Shaul", 72.539],
        ["Servin", 72.54],
        ["Serio", 72.541],
        ["Serafin", 72.542],
        ["Senior", 72.543],
        ["Sebring", 72.544],
        ["Salguero", 72.544],
        ["Saba", 72.545],
        ["Ryerson", 72.546],
        ["Rudder", 72.547],
        ["Ruark", 72.548],
        ["Rother", 72.549],
        ["Rohrbaugh", 72.55],
        ["Rohrbach", 72.551],
        ["Rohan", 72.552],
        ["Rogerson", 72.553],
        ["Risher", 72.554],
        ["Rigg", 72.554],
        ["Reeser", 72.555],
        ["Pryce", 72.556],
        ["Prokop", 72.557],
        ["Prins", 72.558],
        ["Priebe", 72.559],
        ["Prejean", 72.56],
        ["Pinheiro", 72.561],
        ["Petrone", 72.562],
        ["Petri", 72.563],
        ["Penson", 72.564],
        ["Pearlman", 72.564],
        ["Parikh", 72.565],
        ["Pal", 72.566],
        ["Pair", 72.567],
        ["Natoli", 72.568],
        ["Murakami", 72.569],
        ["Mullikin", 72.57],
        ["Mullane", 72.571],
        ["Motes", 72.572],
        ["Morningstar", 72.573],
        ["Monks", 72.573],
        ["McVeigh", 72.574],
        ["McGrady", 72.575],
        ["McGaughey", 72.576],
        ["McCurley", 72.577],
        ["Masi", 72.578],
        ["Marchan", 72.579],
        ["Manske", 72.58],
        ["Maine", 72.581],
        ["Maez", 72.582],
        ["Lusby", 72.583],
        ["Linde", 72.583],
        ["Lile", 72.584],
        ["Likens", 72.585],
        ["Licon", 72.586],
        ["Leroux", 72.587],
        ["Lemaire", 72.588],
        ["Legette", 72.589],
        ["Lax", 72.59],
        ["Laskey", 72.591],
        ["Laprade", 72.592],
        ["Laplant", 72.593],
        ["Lady", 72.593],
        ["Kolar", 72.594],
        ["Kittredge", 72.595],
        ["Kinley", 72.596],
        ["Kerber", 72.597],
        ["Kanagy", 72.598],
        ["Johannes", 72.599],
        ["Jetton", 72.6],
        ["Jayne", 72.601],
        ["January", 72.602],
        ["Janik", 72.602],
        ["Ippolito", 72.603],
        ["Inouye", 72.604],
        ["Hunsinger", 72.605],
        ["Howley", 72.606],
        ["Howery", 72.607],
        ["Horrell", 72.608],
        ["Hoosier", 72.609],
        ["Holthaus", 72.61],
        ["Hiner", 72.611],
        ["Hilson", 72.612],
        ["Hilderbrand", 72.612],
        ["Hasan", 72.613],
        ["Hartzler", 72.614],
        ["Harnish", 72.615],
        ["Harada", 72.616],
        ["Hansford", 72.617],
        ["Halligan", 72.618],
        ["Hagedorn", 72.619],
        ["Gwynn", 72.62],
        ["Gudino", 72.621],
        ["Greenstein", 72.622],
        ["Greear", 72.622],
        ["Gracey", 72.623],
        ["Goudeau", 72.624],
        ["Gose", 72.625],
        ["Goodner", 72.626],
        ["Ginsburg", 72.627],
        ["Gerth", 72.628],
        ["Gerner", 72.629],
        ["Fyfe", 72.63],
        ["Fujii", 72.631],
        ["Frier", 72.631],
        ["Frenette", 72.632],
        ["Folmar", 72.633],
        ["Fleisher", 72.634],
        ["Fleischmann", 72.635],
        ["Fetzer", 72.636],
        ["Fern", 72.637],
        ["Eisenman", 72.638],
        ["Earhart", 72.639],
        ["Dupuy", 72.64],
        ["Dunkelberger", 72.641],
        ["Drummer", 72.641],
        ["Drexler", 72.642],
        ["Dillinger", 72.643],
        ["Dilbeck", 72.644],
        ["Diana", 72.645],
        ["Dewald", 72.646],
        ["Demby", 72.647],
        ["DeFord", 72.648],
        ["Daniell", 72.649],
        ["Dake", 72.65],
        ["Craine", 72.651],
        ["Como", 72.651],
        ["Clever", 72.652],
        ["Chesnut", 72.653],
        ["Casady", 72.654],
        ["Carstens", 72.655],
        ["Carrick", 72.656],
        ["Carino", 72.657],
        ["Carignan", 72.658],
        ["Canchola", 72.659],
        ["Cale", 72.66],
        ["Bushong", 72.66],
        ["Burman", 72.661],
        ["Buono", 72.662],
        ["Brownlow", 72.663],
        ["Broach", 72.664],
        ["Britten", 72.665],
        ["Brickhouse", 72.666],
        ["Boyden", 72.667],
        ["Boulton", 72.668],
        ["Borne", 72.669],
        ["Borland", 72.67],
        ["Bohrer", 72.67],
        ["Blubaugh", 72.671],
        ["Bever", 72.672],
        ["Berggren", 72.673],
        ["Benevides", 72.674],
        ["Arocho", 72.675],
        ["Arends", 72.676],
        ["Amezcua", 72.677],
        ["Almendarez", 72.678],
        ["Zalewski", 72.679],
        ["Witzel", 72.679],
        ["Winkfield", 72.68],
        ["Wilhoite", 72.681],
        ["Vara", 72.682],
        ["Van Gundy", 72.683],
        ["Van Fleet", 72.684],
        ["Van Etten", 72.685],
        ["Vandergriff", 72.686],
        ["Urbanski", 72.687],
        ["Tyrell", 72.687],
        ["Troiano", 72.688],
        ["Tickle", 72.689],
        ["Thibodaux", 72.69],
        ["Straus", 72.691],
        ["Stoneking", 72.692],
        ["Stjean", 72.693],
        ["Stillings", 72.694],
        ["Stiff", 72.695],
        ["Stange", 72.696],
        ["Square", 72.696],
        ["Speicher", 72.697],
        ["Speegle", 72.698],
        ["Sowa", 72.699],
        ["Smeltzer", 72.7],
        ["Slawson", 72.701],
        ["Simmonds", 72.702],
        ["Shuttleworth", 72.703],
        ["Serpa", 72.704],
        ["Senger", 72.704],
        ["Seidman", 72.705],
        ["Schweiger", 72.706],
        ["Schloss", 72.707],
        ["Schimmel", 72.708],
        ["Schechter", 72.709],
        ["Sayler", 72.71],
        ["Sabb", 72.711],
        ["Sabatini", 72.712],
        ["Ronan", 72.712],
        ["Rodiguez", 72.713],
        ["Riggleman", 72.714],
        ["Richins", 72.715],
        ["Reep", 72.716],
        ["Reamer", 72.717],
        ["Prunty", 72.718],
        ["Porath", 72.719],
        ["Plunk", 72.72],
        ["Piland", 72.72],
        ["Philbrook", 72.721],
        ["Pettitt", 72.722],
        ["Perna", 72.723],
        ["Peralez", 72.724],
        ["Pascale", 72.725],
        ["Padula", 72.726],
        ["Oboyle", 72.727],
        ["Nivens", 72.728],
        ["Nickols", 72.728],
        ["Murph", 72.729],
        ["Mundt", 72.73],
        ["Munden", 72.731],
        ["Montijo", 72.732],
        ["McManis", 72.733],
        ["McGrane", 72.734],
        ["McCrimmon", 72.735],
        ["Manzi", 72.736],
        ["Mangold", 72.736],
        ["Malick", 72.737],
        ["Mahar", 72.738],
        ["Maddock", 72.739],
        ["Lust", 72.74],
        ["Losey", 72.741],
        ["Loop", 72.742],
        ["Litten", 72.743],
        ["Liner", 72.744],
        ["Leff", 72.744],
        ["Leedy", 72.745],
        ["Leavell", 72.746],
        ["Ladue", 72.747],
        ["Krahn", 72.748],
        ["Kluge", 72.749],
        ["Junker", 72.75],
        ["Iversen", 72.751],
        ["Imler", 72.752],
        ["Hurtt", 72.752],
        ["Huizar", 72.753],
        ["Hubbert", 72.754],
        ["Howington", 72.755],
        ["Hollomon", 72.756],
        ["Holdren", 72.757],
        ["Hoisington", 72.758],
        ["Hise", 72.759],
        ["Heiden", 72.76],
        ["Hauge", 72.76],
        ["Hartigan", 72.761],
        ["Gutirrez", 72.762],
        ["Griffie", 72.763],
        ["Greenhill", 72.764],
        ["Gratton", 72.765],
        ["Granata", 72.766],
        ["Gottfried", 72.767],
        ["Gertz", 72.768],
        ["Gautreaux", 72.769],
        ["Furry", 72.769],
        ["Furey", 72.77],
        ["Funderburg", 72.771],
        ["Flippen", 72.772],
        ["Fitzgibbon", 72.773],
        ["Fergus", 72.774],
        ["Felice", 72.775],
        ["Eye", 72.776],
        ["Dyar", 72.777],
        ["Drucker", 72.777],
        ["Donoghue", 72.778],
        ["Dildy", 72.779],
        ["Devers", 72.78],
        ["Detweiler", 72.781],
        ["Despres", 72.782],
        ["Denby", 72.783],
        ["Degeorge", 72.784],
        ["Cueto", 72.785],
        ["Cranston", 72.785],
        ["Courville", 72.786],
        ["Clukey", 72.787],
        ["Cirillo", 72.788],
        ["Chon", 72.789],
        ["Chivers", 72.79],
        ["Caudillo", 72.791],
        ["Catt", 72.792],
        ["Butera", 72.793],
        ["Bulluck", 72.793],
        ["Buckmaster", 72.794],
        ["Braunstein", 72.795],
        ["Bracamonte", 72.796],
        ["Bourdeau", 72.797],
        ["Border", 72.798],
        ["Bonnette", 72.799],
        ["Bobadilla", 72.8],
        ["Boaz", 72.801],
        ["Blackledge", 72.801],
        ["Beshears", 72.802],
        ["Bernhard", 72.803],
        ["Bergeson", 72.804],
        ["Baver", 72.805],
        ["Barthel", 72.806],
        ["Balsamo", 72.807],
        ["Bak", 72.808],
        ["Aziz", 72.809],
        ["Awad", 72.809],
        ["Authement", 72.81],
        ["Altom", 72.811],
        ["Altieri", 72.812],
        ["Abels", 72.813],
        ["Zigler", 72.814],
        ["Zhu", 72.815],
        ["Younker", 72.816],
        ["Yeomans", 72.817],
        ["Yearwood", 72.817],
        ["Wurster", 72.818],
        ["Winget", 72.819],
        ["Whitsett", 72.82],
        ["Wechsler", 72.821],
        ["Weatherwax", 72.822],
        ["Wathen", 72.823],
        ["Warriner", 72.824],
        ["Wanamaker", 72.824],
        ["Walraven", 72.825],
        ["Viens", 72.826],
        ["Van de Mark", 72.827],
        ["VanCamp", 72.828],
        ["Uchida", 72.829],
        ["Triana", 72.83],
        ["Tinoco", 72.831],
        ["Terpstra", 72.831],
        ["Tellis", 72.832],
        ["Tarin", 72.833],
        ["Taranto", 72.834],
        ["Takacs", 72.835],
        ["Studdard", 72.836],
        ["Struthers", 72.837],
        ["Strout", 72.838],
        ["Stiller", 72.838],
        ["Spataro", 72.839],
        ["Soderquist", 72.84],
        ["Sliger", 72.841],
        ["Silberman", 72.842],
        ["Shurtleff", 72.843],
        ["Sheetz", 72.844],
        ["Schillinger", 72.845],
        ["Ritch", 72.845],
        ["Reif", 72.846],
        ["Raybon", 72.847],
        ["Ratzlaff", 72.848],
        ["Radley", 72.849],
        ["Putt", 72.85],
        ["Putney", 72.851],
        ["Prime", 72.851],
        ["Press", 72.852],
        ["Pinette", 72.853],
        ["Piner", 72.854],
        ["Petrin", 72.855],
        ["Parise", 72.856],
        ["Osbourne", 72.857],
        ["Nyman", 72.858],
        ["Northington", 72.858],
        ["Noblitt", 72.859],
        ["Nishimura", 72.86],
        ["Nell", 72.861],
        ["Neher", 72.862],
        ["Nalls", 72.863],
        ["Naccarato", 72.864],
        ["Mucha", 72.865],
        ["Mounce", 72.865],
        ["Miron", 72.866],
        ["Millis", 72.867],
        ["Meaney", 72.868],
        ["McNichols", 72.869],
        ["McKinnis", 72.87],
        ["McJunkin", 72.871],
        ["McDuffy", 72.872],
        ["Max", 72.872],
        ["Marcello", 72.873],
        ["Manrique", 72.874],
        ["Mannion", 72.875],
        ["Mangual", 72.876],
        ["Malveaux", 72.877],
        ["Mains", 72.878],
        ["Lumsden", 72.879],
        ["Lucien", 72.879],
        ["Lohmann", 72.88],
        ["Lipe", 72.881],
        ["Lightsey", 72.882],
        ["Lemasters", 72.883],
        ["Leist", 72.884],
        ["Laxton", 72.885],
        ["Laverriere", 72.886],
        ["Latorre", 72.886],
        ["Lamons", 72.887],
        ["Kral", 72.888],
        ["Kopf", 72.889],
        ["Knauer", 72.89],
        ["Kitt", 72.891],
        ["Kaul", 72.892],
        ["Karas", 72.893],
        ["Kamps", 72.893],
        ["Jusino", 72.894],
        ["Janis", 72.895],
        ["Islam", 72.896],
        ["Hullinger", 72.897],
        ["Huges", 72.898],
        ["Hornung", 72.899],
        ["Hiser", 72.9],
        ["Hempel", 72.9],
        ["Helsel", 72.901],
        ["Hassinger", 72.902],
        ["Hargraves", 72.903],
        ["Hammes", 72.904],
        ["Hallberg", 72.905],
        ["Gutman", 72.906],
        ["Gumbs", 72.907],
        ["Gruver", 72.907],
        ["Graddy", 72.908],
        ["Gonsales", 72.909],
        ["Goncalves", 72.91],
        ["Glennon", 72.911],
        ["Gilford", 72.912],
        ["Geno", 72.913],
        ["Freshour", 72.914],
        ["Flippo", 72.914],
        ["Fifer", 72.915],
        ["Few", 72.916],
        ["Fermin", 72.917],
        ["Fason", 72.918],
        ["Farrish", 72.919],
        ["Fallin", 72.92],
        ["Ewert", 72.921],
        ["Estepp", 72.921],
        ["Escudero", 72.922],
        ["Ensminger", 72.923],
        ["Emmanuel", 72.924],
        ["Emberton", 72.925],
        ["Elms", 72.926],
        ["Ellerbe", 72.927],
        ["Eide", 72.928],
        ["Dysart", 72.928],
        ["Dougan", 72.929],
        ["Dierking", 72.93],
        ["Dicus", 72.931],
        ["Detrick", 72.932],
        ["Deroche", 72.933],
        ["DePue", 72.934],
        ["DeMartino", 72.935],
        ["de los Reyes", 72.935],
        ["Dalke", 72.936],
        ["Culbreath", 72.937],
        ["Crownover", 72.938],
        ["Crisler", 72.939],
        ["Crass", 72.94],
        ["Corsi", 72.941],
        ["Chagnon", 72.942],
        ["Centers", 72.942],
        ["Cavanagh", 72.943],
        ["Casson", 72.944],
        ["Carollo", 72.945],
        ["Cadwallader", 72.946],
        ["Burnley", 72.947],
        ["Burciaga", 72.948],
        ["Burchard", 72.949],
        ["Broadhead", 72.949],
        ["Boris", 72.95],
        ["Booze", 72.951],
        ["Bolte", 72.952],
        ["Body", 72.953],
        ["Berens", 72.954],
        ["Bellman", 72.955],
        ["Bellard", 72.956],
        ["Baril", 72.956],
        ["Arden", 72.957],
        ["Antonucci", 72.958],
        ["Amado", 72.959],
        ["Allie", 72.96],
        ["Wolfgram", 72.961],
        ["Winsor", 72.962],
        ["Wimbish", 72.962],
        ["Wilbert", 72.963],
        ["Wier", 72.964],
        ["Wallach", 72.965],
        ["Viveros", 72.966],
        ["Vento", 72.967],
        ["Varley", 72.968],
        ["Van Slyke", 72.969],
        ["Van Gorder", 72.969],
        ["Touchstone", 72.97],
        ["Tomko", 72.971],
        ["Tiemann", 72.972],
        ["Throop", 72.973],
        ["Tamura", 72.974],
        ["Talmadge", 72.975],
        ["Swayze", 72.975],
        ["Sturdevant", 72.976],
        ["Strauser", 72.977],
        ["Stolz", 72.978],
        ["Stenberg", 72.979],
        ["Stayton", 72.98],
        ["Spohn", 72.981],
        ["Spillers", 72.981],
        ["Spillane", 72.982],
        ["Sluss", 72.983],
        ["Sloane", 72.984],
        ["Slavens", 72.985],
        ["Simonetti", 72.986],
        ["Shofner", 72.987],
        ["Shead", 72.987],
        ["Senecal", 72.988],
        ["Seales", 72.989],
        ["Schueler", 72.99],
        ["Schley", 72.991],
        ["Schacht", 72.992],
        ["Sauve", 72.993],
        ["Sarno", 72.993],
        ["Salsbury", 72.994],
        ["Rothschild", 72.995],
        ["Rosier", 72.996],
        ["Rines", 72.997],
        ["Reveles", 72.998],
        ["Rein", 72.999],
        ["Redus", 72.999],
        ["Redfern", 73],
        ["Reck", 73.001],
        ["Ranney", 73.002],
        ["Raggs", 73.003],
        ["Prout", 73.004],
        ["Prill", 73.005],
        ["Preble", 73.005],
        ["Prager", 73.006],
        ["Plemons", 73.007],
        ["Pippen", 73.008],
        ["Pilon", 73.009],
        ["Piccirillo", 73.01],
        ["Pewitt", 73.011],
        ["Pesina", 73.011],
        ["Pecora", 73.012],
        ["Otani", 73.013],
        ["Orsini", 73.014],
        ["Ollie", 73.015],
        ["Oestreich", 73.016],
        ["Odea", 73.017],
        ["Ocallaghan", 73.017],
        ["Northup", 73.018],
        ["Niehaus", 73.019],
        ["Newberg", 73.02],
        ["Nasser", 73.021],
        ["Narron", 73.022],
        ["Monarrez", 73.023],
        ["Mishler", 73.023],
        ["McSherry", 73.024],
        ["McElfresh", 73.025],
        ["Mayon", 73.026],
        ["Mauer", 73.027],
        ["Mattice", 73.028],
        ["Mash", 73.029],
        ["Marrone", 73.029],
        ["Marmolejo", 73.03],
        ["Marini", 73.031],
        ["Marie", 73.032],
        ["Mara", 73.033],
        ["Malm", 73.034],
        ["Machen", 73.035],
        ["Lunceford", 73.035],
        ["Loewen", 73.036],
        ["Liverman", 73.037],
        ["Litwin", 73.038],
        ["Linscott", 73.039],
        ["Levins", 73.04],
        ["Lenox", 73.041],
        ["Legaspi", 73.041],
        ["Leeman", 73.042],
        ["Leavy", 73.043],
        ["Lannon", 73.044],
        ["Lamson", 73.045],
        ["Lambdin", 73.046],
        ["Labarre", 73.047],
        ["Knouse", 73.047],
        ["Klemm", 73.048],
        ["Kleinschmidt", 73.049],
        ["Kirklin", 73.05],
        ["Keels", 73.051],
        ["Juliano", 73.052],
        ["Howser", 73.053],
        ["Hott", 73.053],
        ["Hosier", 73.054],
        ["Hosea", 73.055],
        ["Hopwood", 73.056],
        ["Holyfield", 73.057],
        ["Hodnett", 73.058],
        ["Hirsh", 73.059],
        ["Heimann", 73.06],
        ["Height", 73.06],
        ["Heckel", 73.061],
        ["Harger", 73.062],
        ["Hamil", 73.063],
        ["Hajek", 73.064],
        ["Gurganus", 73.065],
        ["Gunning", 73.066],
        ["Grange", 73.066],
        ["Gonzalas", 73.067],
        ["Goggins", 73.068],
        ["Gerow", 73.069],
        ["Gaydos", 73.07],
        ["Garduno", 73.071],
        ["Ganley", 73.072],
        ["Galey", 73.072],
        ["Farner", 73.073],
        ["Ester", 73.074],
        ["Engles", 73.075],
        ["Emond", 73.076],
        ["Emert", 73.077],
        ["Ellenburg", 73.078],
        ["Edick", 73.078],
        ["Duell", 73.079],
        ["Dublin", 73.08],
        ["Dorazio", 73.081],
        ["Dong", 73.082],
        ["Dimond", 73.083],
        ["Diederich", 73.084],
        ["DeWalt", 73.084],
        ["DePuy", 73.085],
        ["Dempster", 73.086],
        ["Demaria", 73.087],
        ["de Hoyos", 73.088],
        ["Dearth", 73.089],
        ["Dealba", 73.09],
        ["Dane", 73.09],
        ["Czech", 73.091],
        ["Crose", 73.092],
        ["Crespin", 73.093],
        ["Cogdill", 73.094],
        ["Clinard", 73.095],
        ["Cipriano", 73.096],
        ["Chretien", 73.096],
        ["Chalk", 73.097],
        ["Cerny", 73.098],
        ["Ceniceros", 73.099],
        ["Celestin", 73.1],
        ["Caple", 73.101],
        ["Cacho", 73.102],
        ["Burrill", 73.102],
        ["Buhr", 73.103],
        ["Buckland", 73.104],
        ["Branam", 73.105],
        ["Boysen", 73.106],
        ["Bovee", 73.107],
        ["Boos", 73.108],
        ["Boler", 73.108],
        ["Blom", 73.109],
        ["Blasko", 73.11],
        ["Beyers", 73.111],
        ["Belz", 73.112],
        ["Belmonte", 73.113],
        ["Bednarz", 73.114],
        ["Beckmann", 73.114],
        ["Beaudin", 73.115],
        ["Bazile", 73.116],
        ["Barbeau", 73.117],
        ["Balentine", 73.118],
        ["Abrahams", 73.119],
        ["Able", 73.12],
        ["Zielke", 73.12],
        ["Yunker", 73.121],
        ["Yeates", 73.122],
        ["Wrobel", 73.123],
        ["Wike", 73.124],
        ["Whisnant", 73.125],
        ["Wherry", 73.125],
        ["Wagnon", 73.126],
        ["Vogan", 73.127],
        ["Van Sant", 73.128],
        ["Van Nest", 73.129],
        ["Vallo", 73.13],
        ["Ullery", 73.131],
        ["Towles", 73.131],
        ["Towell", 73.132],
        ["Tiger", 73.133],
        ["Thill", 73.134],
        ["Taormina", 73.135],
        ["Tannehill", 73.136],
        ["Taing", 73.136],
        ["Storrs", 73.137],
        ["Stickles", 73.138],
        ["Stetler", 73.139],
        ["Sparling", 73.14],
        ["Solt", 73.141],
        ["Silcox", 73.142],
        ["Sheard", 73.142],
        ["Shadle", 73.143],
        ["Seman", 73.144],
        ["Selleck", 73.145],
        ["Schlemmer", 73.146],
        ["Scher", 73.147],
        ["Sapien", 73.147],
        ["Sainz", 73.148],
        ["Rumble", 73.149],
        ["Roye", 73.15],
        ["Rosamond", 73.151],
        ["Romain", 73.152],
        ["Rizzuto", 73.152],
        ["Resch", 73.153],
        ["Rentz", 73.154],
        ["Rather", 73.155],
        ["Rasch", 73.156],
        ["Ranieri", 73.157],
        ["Purtell", 73.158],
        ["Primmer", 73.158],
        ["Portwood", 73.159],
        ["Pontius", 73.16],
        ["Pons", 73.161],
        ["Pletcher", 73.162],
        ["Pledger", 73.163],
        ["Pirkle", 73.163],
        ["Pillsbury", 73.164],
        ["Pentecost", 73.165],
        ["Peng", 73.166],
        ["Paxson", 73.167],
        ["Ortez", 73.168],
        ["Organ", 73.168],
        ["Oles", 73.169],
        ["Newborn", 73.17],
        ["Mullett", 73.171],
        ["Muirhead", 73.172],
        ["Mouzon", 73.173],
        ["Mork", 73.174],
        ["Mollett", 73.174],
        ["Mohn", 73.175],
        ["Mitcham", 73.176],
        ["Melillo", 73.177],
        ["Mee", 73.178],
        ["Medders", 73.179],
        ["McMiller", 73.179],
        ["McCleery", 73.18],
        ["McCaughey", 73.181],
        ["Manders", 73.182],
        ["Mak", 73.183],
        ["Maciejewski", 73.184],
        ["Macaulay", 73.184],
        ["Lute", 73.185],
        ["Lipman", 73.186],
        ["Lewter", 73.187],
        ["Larocque", 73.188],
        ["Langton", 73.189],
        ["Kriner", 73.19],
        ["Knipp", 73.19],
        ["Killeen", 73.191],
        ["Karn", 73.192],
        ["Kalish", 73.193],
        ["Kaczor", 73.194],
        ["Jonson", 73.195],
        ["Jerez", 73.195],
        ["Jarrard", 73.196],
        ["Janda", 73.197],
        ["Hymes", 73.198],
        ["Hollman", 73.199],
        ["Hollandsworth", 73.2],
        ["Holl", 73.2],
        ["Hobdy", 73.201],
        ["Hitch", 73.202],
        ["Hennen", 73.203],
        ["Hemmer", 73.204],
        ["Hagins", 73.205],
        ["Haddox", 73.206],
        ["Guitierrez", 73.206],
        ["Guernsey", 73.207],
        ["Gorsuch", 73.208],
        ["Gholson", 73.209],
        ["Genova", 73.21],
        ["Gazaway", 73.211],
        ["Gauna", 73.211],
        ["Gammons", 73.212],
        ["Freels", 73.213],
        ["Fonville", 73.214],
        ["Fly", 73.215],
        ["Florian", 73.216],
        ["Fleet", 73.216],
        ["Fetterman", 73.217],
        ["Fava", 73.218],
        ["Farquhar", 73.219],
        ["Farish", 73.22],
        ["Fabela", 73.221],
        ["Escoto", 73.222],
        ["Eisen", 73.222],
        ["Dossett", 73.223],
        ["Dority", 73.224],
        ["Dorfman", 73.225],
        ["Demmer", 73.226],
        ["Dehn", 73.227],
        ["Dawley", 73.227],
        ["D'Arbonne", 73.228],
        ["D'Amore", 73.229],
        ["Damm", 73.23],
        ["Crosley", 73.231],
        ["Cron", 73.232],
        ["Crompton", 73.232],
        ["Crichton", 73.233],
        ["Cotner", 73.234],
        ["Cordon", 73.235],
        ["Conerly", 73.236],
        ["Colvard", 73.237],
        ["Clauson", 73.238],
        ["Chess", 73.238],
        ["Cheeseman", 73.239],
        ["Charity", 73.24],
        ["Cavallaro", 73.241],
        ["Castille", 73.242],
        ["Cabello", 73.243],
        ["Burgan", 73.243],
        ["Buffum", 73.244],
        ["Bruss", 73.245],
        ["Brassfield", 73.246],
        ["Bowerman", 73.247],
        ["Bothwell", 73.248],
        ["Borgen", 73.249],
        ["Bonaparte", 73.249],
        ["Bombard", 73.25],
        ["Boivin", 73.251],
        ["Boissonneault", 73.252],
        ["Bogner", 73.253],
        ["Bodden", 73.254],
        ["Boan", 73.254],
        ["Blanche", 73.255],
        ["Bittinger", 73.256],
        ["Bickham", 73.257],
        ["Bedolla", 73.258],
        ["Bale", 73.259],
        ["Bainbridge", 73.259],
        ["Aybar", 73.26],
        ["Avendano", 73.261],
        ["Ashlock", 73.262],
        ["Amidon", 73.263],
        ["Almanzar", 73.264],
        ["Akridge", 73.265],
        ["Ackermann", 73.265],
        ["Zager", 73.266],
        ["Yong", 73.267],
        ["Xavier", 73.268],
        ["Worrall", 73.269],
        ["Winans", 73.269],
        ["Wilsey", 73.27],
        ["Wightman", 73.271],
        ["Westrick", 73.272],
        ["Wenner", 73.273],
        ["Warne", 73.274],
        ["Warford", 73.274],
        ["Verville", 73.275],
        ["Utecht", 73.276],
        ["Upson", 73.277],
        ["Tuma", 73.278],
        ["Tseng", 73.279],
        ["Troncoso", 73.279],
        ["Trollinger", 73.28],
        ["Torbert", 73.281],
        ["Taulbee", 73.282],
        ["Sutterfield", 73.283],
        ["Stough", 73.284],
        ["Storch", 73.284],
        ["Stonebraker", 73.285],
        ["Stolle", 73.286],
        ["Stilson", 73.287],
        ["Stiefel", 73.288],
        ["Steptoe", 73.289],
        ["Stepney", 73.289],
        ["Stender", 73.29],
        ["Stemple", 73.291],
        ["Staggers", 73.292],
        ["Spurrier", 73.293],
        ["Spray", 73.293],
        ["Spinney", 73.294],
        ["Spengler", 73.295],
        ["Smartt", 73.296],
        ["Skoog", 73.297],
        ["Silvis", 73.298],
        ["Sieg", 73.298],
        ["Shuford", 73.299],
        ["Selfridge", 73.3],
        ["Seguin", 73.301],
        ["Sedgwick", 73.302],
        ["Sease", 73.303],
        ["Scotti", 73.303],
        ["Schroer", 73.304],
        ["Schlenker", 73.305],
        ["Schill", 73.306],
        ["Savarese", 73.307],
        ["Sapienza", 73.308],
        ["Sanson", 73.308],
        ["Sandefur", 73.309],
        ["Salamone", 73.31],
        ["Rusnak", 73.311],
        ["Rudisill", 73.312],
        ["Royalty", 73.312],
        ["Rothermel", 73.313],
        ["Roca", 73.314],
        ["Resendiz", 73.315],
        ["Reliford", 73.316],
        ["Rasco", 73.317],
        ["Raiford", 73.317],
        ["Quisenberry", 73.318],
        ["Quijada", 73.319],
        ["Pullins", 73.32],
        ["Puccio", 73.321],
        ["Postell", 73.322],
        ["Poppe", 73.322],
        ["Pinter", 73.323],
        ["Piche", 73.324],
        ["Petrucci", 73.325],
        ["Pellegrin", 73.326],
        ["Pelaez", 73.327],
        ["Patti", 73.327],
        ["Paton", 73.328],
        ["Pasco", 73.329],
        ["Parkes", 73.33],
        ["Paden", 73.331],
        ["Pabst", 73.331],
        ["Orchard", 73.332],
        ["Olmsted", 73.333],
        ["Newlon", 73.334],
        ["Mynatt", 73.335],
        ["Mustafa", 73.336],
        ["Mower", 73.336],
        ["Morrone", 73.337],
        ["Moree", 73.338],
        ["Moffat", 73.339],
        ["Mixson", 73.34],
        ["Minner", 73.341],
        ["Min", 73.341],
        ["Millette", 73.342],
        ["Mederos", 73.343],
        ["McGahan", 73.344],
        ["McConville", 73.345],
        ["Maughan", 73.346],
        ["Massingill", 73.346],
        ["Marano", 73.347],
        ["Macri", 73.348],
        ["Lovern", 73.349],
        ["Lichtenstein", 73.35],
        ["Leonetti", 73.351],
        ["Lehner", 73.351],
        ["Lawley", 73.352],
        ["Laramie", 73.353],
        ["Lappin", 73.354],
        ["Lahti", 73.355],
        ["Lago", 73.355],
        ["Lacayo", 73.356],
        ["Kuester", 73.357],
        ["Knee", 73.358],
        ["Kincade", 73.359],
        ["Junior", 73.36],
        ["Juhl", 73.36],
        ["Joslyn", 73.361],
        ["Jiron", 73.362],
        ["Jessop", 73.363],
        ["Jerry", 73.364],
        ["Jarosz", 73.365],
        ["Jain", 73.365],
        ["Hults", 73.366],
        ["Hoge", 73.367],
        ["Hodgins", 73.368],
        ["Hoban", 73.369],
        ["Hinkson", 73.37],
        ["Hillyard", 73.37],
        ["Herzig", 73.371],
        ["Hervey", 73.372],
        ["Henriksen", 73.373],
        ["Hawker", 73.374],
        ["Hause", 73.374],
        ["Hard", 73.375],
        ["Hankerson", 73.376],
        ["Gregson", 73.377],
        ["Golliday", 73.378],
        ["Gilcrease", 73.379],
        ["Gessner", 73.379],
        ["Gerace", 73.38],
        ["Garwood", 73.381],
        ["Garst", 73.382],
        ["Gaillard", 73.383],
        ["Flinchum", 73.384],
        ["Fishel", 73.384],
        ["Fishback", 73.385],
        ["Filkins", 73.386],
        ["Fentress", 73.387],
        ["Fabre", 73.388],
        ["Ethier", 73.389],
        ["Espana", 73.389],
        ["Eisner", 73.39],
        ["Ehrhart", 73.391],
        ["Efird", 73.392],
        ["Drennon", 73.393],
        ["Dominy", 73.393],
        ["Dominique", 73.394],
        ["Domingue", 73.395],
        ["DiPaolo", 73.396],
        ["Dinan", 73.397],
        ["DiMartino", 73.398],
        ["Deskins", 73.398],
        ["Dengler", 73.399],
        ["De Freitas", 73.4],
        ["DeFranco", 73.401],
        ["Dancer", 73.402],
        ["Dahlin", 73.403],
        ["Cutshaw", 73.403],
        ["Cuthbert", 73.404],
        ["Croyle", 73.405],
        ["Crothers", 73.406],
        ["Critchfield", 73.407],
        ["Cowie", 73.408],
        ["Costner", 73.408],
        ["Coppedge", 73.409],
        ["Copes", 73.41],
        ["Ciccone", 73.411],
        ["Champ", 73.412],
        ["Cesar", 73.413],
        ["Caufield", 73.413],
        ["Capo", 73.414],
        ["Cambron", 73.415],
        ["Cambridge", 73.416],
        ["Buser", 73.417],
        ["Burnes", 73.417],
        ["Buhl", 73.418],
        ["Buendia", 73.419],
        ["Brindley", 73.42],
        ["Brecht", 73.421],
        ["Bourgoin", 73.422],
        ["Boomer", 73.422],
        ["Blackshire", 73.423],
        ["Birge", 73.424],
        ["Benninger", 73.425],
        ["Bembry", 73.426],
        ["Beil", 73.427],
        ["Begaye", 73.427],
        ["Barrentine", 73.428],
        ["Barks", 73.429],
        ["Banton", 73.43],
        ["Balmer", 73.431],
        ["Baity", 73.432],
        ["Auerbach", 73.432],
        ["Ambler", 73.433],
        ["Alexandre", 73.434],
        ["Ackerson", 73.435],
        ["Zurcher", 73.436],
        ["Zell", 73.436],
        ["Wynkoop", 73.437],
        ["Wallick", 73.438],
        ["Waid", 73.439],
        ["Vos", 73.44],
        ["Vizcaino", 73.441],
        ["Vester", 73.441],
        ["Veale", 73.442],
        ["Vandermark", 73.443],
        ["Vanderford", 73.444],
        ["Tuthill", 73.445],
        ["Trivette", 73.445],
        ["Thiessen", 73.446],
        ["Tewksbury", 73.447],
        ["Tao", 73.448],
        ["Tabron", 73.449],
        ["Swim", 73.449],
        ["Swasey", 73.45],
        ["Swanigan", 73.451],
        ["Stoughton", 73.452],
        ["Stoudt", 73.453],
        ["Stimson", 73.453],
        ["Stecker", 73.454],
        ["Stead", 73.455],
        ["Stall", 73.456],
        ["Spady", 73.457],
        ["Souther", 73.458],
        ["Smoak", 73.458],
        ["Sklar", 73.459],
        ["Simcox", 73.46],
        ["Sidwell", 73.461],
        ["Sharon", 73.462],
        ["Seybert", 73.462],
        ["Sesco", 73.463],
        ["Seeman", 73.464],
        ["Seaborn", 73.465],
        ["Schwenk", 73.466],
        ["Schmeling", 73.466],
        ["Rossignol", 73.467],
        ["Robillard", 73.468],
        ["Robicheaux", 73.469],
        ["Riveria", 73.47],
        ["Rippeon", 73.471],
        ["Ridgley", 73.471],
        ["Remaley", 73.472],
        ["Rehkop", 73.473],
        ["Reddish", 73.474],
        ["Reach", 73.475],
        ["Rauscher", 73.475],
        ["Rachel", 73.476],
        ["Quirion", 73.477],
        ["Pusey", 73.478],
        ["Pruden", 73.479],
        ["Pressler", 73.479],
        ["Potvin", 73.48],
        ["Pospisil", 73.481],
        ["Paradiso", 73.482],
        ["Pangburn", 73.483],
        ["Palmateer", 73.483],
        ["Ownby", 73.484],
        ["Otwell", 73.485],
        ["Osterberg", 73.486],
        ["Osmond", 73.487],
        ["Olsson", 73.488],
        ["Old", 73.488],
        ["Oberlander", 73.489],
        ["Nusbaum", 73.49],
        ["Novack", 73.491],
        ["Nokes", 73.492],
        ["Nicastro", 73.492],
        ["Nehls", 73.493],
        ["Nay", 73.494],
        ["Naber", 73.495],
        ["Mulhern", 73.496],
        ["Motter", 73.496],
        ["Moretz", 73.497],
        ["Milian", 73.498],
        ["Mercedes", 73.499],
        ["McKeel", 73.5],
        ["McClay", 73.501],
        ["McCart", 73.501],
        ["Matsuda", 73.502],
        ["Mary", 73.503],
        ["Martucci", 73.504],
        ["Marple", 73.505],
        ["Marko", 73.505],
        ["Marciniak", 73.506],
        ["Manes", 73.507],
        ["Mancia", 73.508],
        ["Maker", 73.509],
        ["Macrae", 73.509],
        ["Lybarger", 73.51],
        ["Lint", 73.511],
        ["Lineberger", 73.512],
        ["Levingston", 73.513],
        ["Lecroy", 73.513],
        ["Lattimer", 73.514],
        ["Laseter", 73.515],
        ["Kulick", 73.516],
        ["Krier", 73.517],
        ["Knutsen", 73.518],
        ["Klem", 73.518],
        ["Kinne", 73.519],
        ["Kinkade", 73.52],
        ["Ketterman", 73.521],
        ["Kerstetter", 73.522],
        ["Kersten", 73.522],
        ["Karam", 73.523],
        ["Jury", 73.524],
        ["Joshi", 73.525],
        ["Jin", 73.526],
        ["Jent", 73.526],
        ["Jefcoat", 73.527],
        ["Hillier", 73.528],
        ["Hillhouse", 73.529],
        ["Hettinger", 73.53],
        ["Henthorn", 73.53],
        ["Henline", 73.531],
        ["Helzer", 73.532],
        ["Heitzman", 73.533],
        ["Heineman", 73.534],
        ["Heenan", 73.535],
        ["Haughton", 73.535],
        ["Haris", 73.536],
        ["Harbert", 73.537],
        ["Haman", 73.538],
        ["Grinstead", 73.539],
        ["Gremillion", 73.539],
        ["Gorby", 73.54],
        ["Giraldo", 73.541],
        ["Gioia", 73.542],
        ["Gerardi", 73.543],
        ["Geraghty", 73.543],
        ["Gaunt", 73.544],
        ["Gatson", 73.545],
        ["Gardin", 73.546],
        ["Gans", 73.547],
        ["Gammill", 73.548],
        ["Games", 73.548],
        ["Gain", 73.549],
        ["Friedlander", 73.55],
        ["Frahm", 73.551],
        ["Fossett", 73.552],
        ["Fosdick", 73.552],
        ["Forth", 73.553],
        ["Forbush", 73.554],
        ["Fondren", 73.555],
        ["Fleckenstein", 73.556],
        ["Fitchett", 73.556],
        ["Filer", 73.557],
        ["Feliz", 73.558],
        ["Feist", 73.559],
        ["Ewart", 73.56],
        ["Evelyn", 73.56],
        ["Esters", 73.561],
        ["Elsner", 73.562],
        ["Edgin", 73.563],
        ["Eddie", 73.564],
        ["Easterly", 73.565],
        ["Dussault", 73.565],
        ["Durazo", 73.566],
        ["Don", 73.567],
        ["Devereaux", 73.568],
        ["Deshotel", 73.569],
        ["Deckert", 73.569],
        ["Dargan", 73.57],
        ["Dare", 73.571],
        ["Cornman", 73.572],
        ["Conkle", 73.573],
        ["Condit", 73.573],
        ["Commander", 73.574],
        ["Claunch", 73.575],
        ["Clabaugh", 73.576],
        ["Chute", 73.577],
        ["Cheesman", 73.578],
        ["Chea", 73.578],
        ["Charney", 73.579],
        ["Charleston", 73.58],
        ["Casella", 73.581],
        ["Carone", 73.582],
        ["Carbonell", 73.582],
        ["Canipe", 73.583],
        ["Campana", 73.584],
        ["Calles", 73.585],
        ["Cabezas", 73.586],
        ["Cabell", 73.586],
        ["Buttram", 73.587],
        ["Bustillos", 73.588],
        ["Buskirk", 73.589],
        ["Boyland", 73.59],
        ["Bourke", 73.59],
        ["Blakeley", 73.591],
        ["Big", 73.592],
        ["Berumen", 73.593],
        ["Berrier", 73.594],
        ["Bench", 73.595],
        ["Belli", 73.595],
        ["Behrendt", 73.596],
        ["Baumbach", 73.597],
        ["Bartsch", 73.598],
        ["Baney", 73.599],
        ["Arambula", 73.599],
        ["Alldredge", 73.6],
        ["Allbritton", 73.601],
        ["Ziemba", 73.602],
        ["Zanders", 73.603],
        ["Youngquist", 73.603],
        ["Yoshioka", 73.604],
        ["Yohe", 73.605],
        ["Wunder", 73.606],
        ["Woodfin", 73.607],
        ["Wojtowicz", 73.607],
        ["Winkel", 73.608],
        ["Wilmore", 73.609],
        ["Willbanks", 73.61],
        ["Wesolowski", 73.611],
        ["Wendland", 73.611],
        ["Walko", 73.612],
        ["Votaw", 73.613],
        ["Vanek", 73.614],
        ["Uriarte", 73.615],
        ["Urbano", 73.615],
        ["Turnipseed", 73.616],
        ["Triche", 73.617],
        ["Trautman", 73.618],
        ["Towler", 73.619],
        ["Tokarz", 73.619],
        ["Temples", 73.62],
        ["Tefft", 73.621],
        ["Teegarden", 73.622],
        ["Syed", 73.622],
        ["Swigart", 73.623],
        ["Stryker", 73.624],
        ["Stoller", 73.625],
        ["Stapler", 73.626],
        ["Stansfield", 73.626],
        ["Smit", 73.627],
        ["Smelley", 73.628],
        ["Sicard", 73.629],
        ["Shulman", 73.63],
        ["Shew", 73.63],
        ["Shear", 73.631],
        ["Sheahan", 73.632],
        ["Sharpton", 73.633],
        ["Selvidge", 73.634],
        ["Schlesinger", 73.634],
        ["Savell", 73.635],
        ["Sandford", 73.636],
        ["Sabatino", 73.637],
        ["Rosenbloom", 73.638],
        ["Roepke", 73.638],
        ["Rish", 73.639],
        ["Rhames", 73.64],
        ["Renken", 73.641],
        ["Reger", 73.642],
        ["Rappaport", 73.642],
        ["Quarterman", 73.643],
        ["Puig", 73.644],
        ["Prasad", 73.645],
        ["Poplar", 73.646],
        ["Pizano", 73.646],
        ["Pigott", 73.647],
        ["Pick", 73.648],
        ["Phair", 73.649],
        ["Petrick", 73.65],
        ["Patt", 73.65],
        ["Pascua", 73.651],
        ["Paramore", 73.652],
        ["Papineau", 73.653],
        ["Olivieri", 73.653],
        ["Ogren", 73.654],
        ["Norden", 73.655],
        ["Noga", 73.656],
        ["Nisbet", 73.657],
        ["Munk", 73.657],
        ["Munch", 73.658],
        ["Mui", 73.659],
        ["Morvant", 73.66],
        ["Moro", 73.661],
        ["Moloney", 73.661],
        ["Merz", 73.662],
        ["Meng", 73.663],
        ["Meltzer", 73.664],
        ["Mellinger", 73.665],
        ["Mehl", 73.665],
        ["McNealy", 73.666],
        ["McKernan", 73.667],
        ["McHaney", 73.668],
        ["McCleskey", 73.669],
        ["McAndrews", 73.669],
        ["Mayton", 73.67],
        ["Mayor", 73.671],
        ["Markert", 73.672],
        ["Maresca", 73.673],
        ["Marcellus", 73.673],
        ["Maner", 73.674],
        ["Mandujano", 73.675],
        ["Malpass", 73.676],
        ["MacIntyre", 73.677],
        ["Lytton", 73.677],
        ["Lyall", 73.678],
        ["Lummus", 73.679],
        ["Longshore", 73.68],
        ["Longfellow", 73.681],
        ["Lokey", 73.681],
        ["Locher", 73.682],
        ["Leverette", 73.683],
        ["Lepe", 73.684],
        ["Lefever", 73.685],
        ["Leeson", 73.685],
        ["Lederer", 73.686],
        ["Lampert", 73.687],
        ["Lagrone", 73.688],
        ["La", 73.688],
        ["Kreider", 73.689],
        ["Korth", 73.69],
        ["Knopf", 73.691],
        ["Kleist", 73.692],
        ["Kiss", 73.692],
        ["Keltner", 73.693],
        ["Kelling", 73.694],
        ["Kaspar", 73.695],
        ["Kappler", 73.696],
        ["Justin", 73.696],
        ["Josephs", 73.697],
        ["Jiang", 73.698],
        ["Huckins", 73.699],
        ["Horace", 73.7],
        ["Holub", 73.7],
        ["Hofstetter", 73.701],
        ["Hoehn", 73.702],
        ["Higginson", 73.703],
        ["Hennings", 73.704],
        ["Heid", 73.704],
        ["Havel", 73.705],
        ["Hauer", 73.706],
        ["Harnden", 73.707],
        ["Hargreaves", 73.708],
        ["Hanger", 73.708],
        ["Guild", 73.709],
        ["Guidi", 73.71],
        ["Grate", 73.711],
        ["Grandy", 73.712],
        ["Grandstaff", 73.712],
        ["Goza", 73.713],
        ["Goodridge", 73.714],
        ["Goodfellow", 73.715],
        ["Goggans", 73.716],
        ["Godley", 73.716],
        ["Giusti", 73.717],
        ["Gilyard", 73.718],
        ["Geoghegan", 73.719],
        ["Galyon", 73.719],
        ["Gaeta", 73.72],
        ["Funes", 73.721],
        ["Font", 73.722],
        ["Flor", 73.723],
        ["Flanary", 73.723],
        ["Fales", 73.724],
        ["Erlandson", 73.725],
        ["Ellett", 73.726],
        ["Elia", 73.727],
        ["Edinger", 73.727],
        ["Dziedzic", 73.728],
        ["Duerr", 73.729],
        ["Draughn", 73.73],
        ["Donoho", 73.731],
        ["Di Matteo", 73.731],
        ["Devos", 73.732],
        ["DeMatteo", 73.733],
        ["Degnan", 73.734],
        ["Darlington", 73.735],
        ["Danis", 73.735],
        ["Dam", 73.736],
        ["Dahlstrom", 73.737],
        ["Dahlke", 73.738],
        ["Czajkowski", 73.739],
        ["Cumbie", 73.739],
        ["Culbert", 73.74],
        ["Crosier", 73.741],
        ["Croley", 73.742],
        ["Corry", 73.743],
        ["Clinger", 73.743],
        ["Cheshire", 73.744],
        ["Chalker", 73.745],
        ["Cephas", 73.746],
        ["Caywood", 73.747],
        ["Cavalier", 73.747],
        ["Capehart", 73.748],
        ["Cales", 73.749],
        ["Cadiz", 73.75],
        ["Bussiere", 73.75],
        ["Burriss", 73.751],
        ["Burkart", 73.752],
        ["Brundidge", 73.753],
        ["Bronstein", 73.754],
        ["Breeze", 73.754],
        ["Bradt", 73.755],
        ["Boydston", 73.756],
        ["Bostrom", 73.757],
        ["Borel", 73.758],
        ["Bolles", 73.758],
        ["Blay", 73.759],
        ["Blackwelder", 73.76],
        ["Bissett", 73.761],
        ["Bevers", 73.762],
        ["Bester", 73.762],
        ["Bernardino", 73.763],
        ["Benefiel", 73.764],
        ["Belote", 73.765],
        ["Beedle", 73.766],
        ["Beckles", 73.766],
        ["Baysinger", 73.767],
        ["Bassler", 73.768],
        ["Bartee", 73.769],
        ["Barlett", 73.77],
        ["Bargas", 73.77],
        ["Barefield", 73.771],
        ["Baptista", 73.772],
        ["Arterburn", 73.773],
        ["Armas", 73.774],
        ["Apperson", 73.774],
        ["Amoroso", 73.775],
        ["Amedee", 73.776],
        ["Zullo", 73.777],
        ["Zellner", 73.777],
        ["Yelton", 73.778],
        ["Willems", 73.779],
        ["Wilkin", 73.78],
        ["Wiggin", 73.781],
        ["Widman", 73.781],
        ["Welk", 73.782],
        ["Weingarten", 73.783],
        ["Walla", 73.784],
        ["Viers", 73.784],
        ["Vess", 73.785],
        ["Verdi", 73.786],
        ["Veazey", 73.787],
        ["Van Note", 73.788],
        ["Tullos", 73.788],
        ["Trudell", 73.789],
        ["Trower", 73.79],
        ["Trosper", 73.791],
        ["Trimm", 73.791],
        ["Trew", 73.792],
        ["Tousignant", 73.793],
        ["Topp", 73.794],
        ["Tocco", 73.795],
        ["Thoreson", 73.795],
        ["Terhune", 73.796],
        ["Tatom", 73.797],
        ["Suniga", 73.798],
        ["Sumter", 73.799],
        ["Steeves", 73.799],
        ["Stansell", 73.8],
        ["Soltis", 73.801],
        ["Sloss", 73.802],
        ["Slaven", 73.802],
        ["Sing", 73.803],
        ["Shisler", 73.804],
        ["Sheriff", 73.805],
        ["Shanley", 73.806],
        ["Servantes", 73.806],
        ["Selders", 73.807],
        ["Segrest", 73.808],
        ["Seese", 73.809],
        ["Seeber", 73.809],
        ["Schaible", 73.81],
        ["Savala", 73.811],
        ["Sartor", 73.812],
        ["Rutt", 73.813],
        ["Rumbaugh", 73.813],
        ["Ruis", 73.814],
        ["Roten", 73.815],
        ["Roessler", 73.816],
        ["Ritenour", 73.816],
        ["Riney", 73.817],
        ["Restivo", 73.818],
        ["Rene", 73.819],
        ["Renard", 73.82],
        ["Rakestraw", 73.82],
        ["Rake", 73.821],
        ["Rachal", 73.822],
        ["Quiros", 73.823],
        ["Pullin", 73.823],
        ["Prudhomme", 73.824],
        ["Primeaux", 73.825],
        ["Prestridge", 73.826],
        ["Presswood", 73.827],
        ["Ponte", 73.827],
        ["Polzin", 73.828],
        ["Poarch", 73.829],
        ["Pittenger", 73.83],
        ["Piggott", 73.83],
        ["Pickell", 73.831],
        ["Phaneuf", 73.832],
        ["Parvin", 73.833],
        ["Parmley", 73.834],
        ["Palmeri", 73.834],
        ["Paisley", 73.835],
        ["Ozment", 73.836],
        ["Ormond", 73.837],
        ["Ordaz", 73.837],
        ["Ono", 73.838],
        ["Olea", 73.839],
        ["Obanion", 73.84],
        ["Oakman", 73.841],
        ["Novick", 73.841],
        ["Nicklas", 73.842],
        ["Nemec", 73.843],
        ["Nappi", 73.844],
        ["Mund", 73.844],
        ["Morfin", 73.845],
        ["Mera", 73.846],
        ["Melgoza", 73.847],
        ["Melby", 73.848],
        ["McGoldrick", 73.848],
        ["McElwain", 73.849],
        ["McChristian", 73.85],
        ["McCaw", 73.851],
        ["Marquart", 73.851],
        ["Marlatt", 73.852],
        ["Markovich", 73.853],
        ["Mahr", 73.854],
        ["Lupton", 73.855],
        ["Lucus", 73.855],
        ["Lorusso", 73.856],
        ["Lerman", 73.857],
        ["Leddy", 73.858],
        ["Leaman", 73.858],
        ["Leachman", 73.859],
        ["Lavalle", 73.86],
        ["Laduke", 73.861],
        ["Kummer", 73.862],
        ["Koury", 73.862],
        ["Konopka", 73.863],
        ["Koh", 73.864],
        ["Koepp", 73.865],
        ["Kloss", 73.865],
        ["Klock", 73.866],
        ["Khalil", 73.867],
        ["Kernan", 73.868],
        ["Kappel", 73.869],
        ["Jakes", 73.869],
        ["Inoue", 73.87],
        ["Hutsell", 73.871],
        ["Howle", 73.872],
        ["Honore", 73.873],
        ["Hole", 73.873],
        ["Hockman", 73.874],
        ["Hockaday", 73.875],
        ["Hiltz", 73.876],
        ["Hetherington", 73.876],
        ["Hesser", 73.877],
        ["Hershman", 73.878],
        ["Heng", 73.879],
        ["Heffron", 73.88],
        ["Headen", 73.88],
        ["Haskett", 73.881],
        ["Hartline", 73.882],
        ["Harned", 73.883],
        ["Guillemette", 73.883],
        ["Guglielmo", 73.884],
        ["Guercio", 73.885],
        ["Greenbaum", 73.886],
        ["Goris", 73.887],
        ["Glines", 73.887],
        ["Gilmour", 73.888],
        ["Gardella", 73.889],
        ["Gadd", 73.89],
        ["Gabler", 73.89],
        ["Gabbert", 73.891],
        ["Fuselier", 73.892],
        ["Freudenburg", 73.893],
        ["Fragoso", 73.894],
        ["Follis", 73.894],
        ["Flemings", 73.895],
        ["Feltman", 73.896],
        ["Febus", 73.897],
        ["Farren", 73.897],
        ["Fallis", 73.898],
        ["Evert", 73.899],
        ["Ekstrom", 73.9],
        ["Eastridge", 73.901],
        ["Dyck", 73.901],
        ["Dufault", 73.902],
        ["Dubreuil", 73.903],
        ["Dresser", 73.904],
        ["Drapeau", 73.904],
        ["Domingues", 73.905],
        ["Dolezal", 73.906],
        ["Dinkel", 73.907],
        ["Didonato", 73.908],
        ["Devitt", 73.908],
        ["Devane", 73.909],
        ["DeMott", 73.91],
        ["Daughtrey", 73.911],
        ["Daubert", 73.911],
        ["Das", 73.912],
        ["Darrell", 73.913],
        ["Creason", 73.914],
        ["Crary", 73.915],
        ["Costilla", 73.915],
        ["Chipps", 73.916],
        ["Cheatwood", 73.917],
        ["Carmean", 73.918],
        ["Canton", 73.918],
        ["Caffrey", 73.919],
        ["Burgher", 73.92],
        ["Buker", 73.921],
        ["Brunk", 73.922],
        ["Brodbeck", 73.922],
        ["Brantner", 73.923],
        ["Brandy", 73.924],
        ["Bolivar", 73.925],
        ["Boerner", 73.925],
        ["Bodkin", 73.926],
        ["Biel", 73.927],
        ["Betty", 73.928],
        ["Bencomo", 73.929],
        ["Bellino", 73.929],
        ["Beliveau", 73.93],
        ["Beauvais", 73.931],
        ["Beaupre", 73.932],
        ["Baylis", 73.932],
        ["Baskett", 73.933],
        ["Barcus", 73.934],
        ["Barbera", 73.935],
        ["Baltz", 73.936],
        ["Asay", 73.936],
        ["Arney", 73.937],
        ["Arcuri", 73.938],
        ["Ankney", 73.939],
        ["Agostini", 73.939],
        ["Addy", 73.94],
        ["Zwilling", 73.941],
        ["Zubia", 73.942],
        ["Zollinger", 73.943],
        ["Zeitz", 73.943],
        ["Yard", 73.944],
        ["Yanes", 73.945],
        ["Winship", 73.946],
        ["Winningham", 73.946],
        ["Wickline", 73.947],
        ["Webre", 73.948],
        ["Waddington", 73.949],
        ["Vosburgh", 73.949],
        ["Vessels", 73.95],
        ["Verrett", 73.951],
        ["Vedder", 73.952],
        ["Varnum", 73.952],
        ["van Deventer", 73.953],
        ["Vacca", 73.954],
        ["Usry", 73.955],
        ["Towry", 73.956],
        ["Touchet", 73.956],
        ["Tookes", 73.957],
        ["Tonkin", 73.958],
        ["Timko", 73.959],
        ["Tibbitts", 73.959],
        ["Thedford", 73.96],
        ["Tarleton", 73.961],
        ["Talty", 73.962],
        ["Talamantez", 73.962],
        ["Tafolla", 73.963],
        ["Sugg", 73.964],
        ["Strecker", 73.965],
        ["Stirling", 73.965],
        ["Steffan", 73.966],
        ["Spiva", 73.967],
        ["Slape", 73.968],
        ["Siemens", 73.969],
        ["Shatzer", 73.969],
        ["Seyler", 73.97],
        ["Seamans", 73.971],
        ["Schmaltz", 73.972],
        ["Schipper", 73.972],
        ["Sasso", 73.973],
        ["Sailor", 73.974],
        ["Ruppe", 73.975],
        ["Runner", 73.975],
        ["Royals", 73.976],
        ["Roudebush", 73.977],
        ["Ripple", 73.978],
        ["Riemer", 73.978],
        ["Richarson", 73.979],
        ["Revilla", 73.98],
        ["Reichenbach", 73.981],
        ["Ratley", 73.981],
        ["Railsback", 73.982],
        ["Quayle", 73.983],
        ["Poplin", 73.984],
        ["Poorman", 73.985],
        ["Ponton", 73.985],
        ["Polo", 73.986],
        ["Pollitt", 73.987],
        ["Poitras", 73.988],
        ["Piscitelli", 73.988],
        ["Piedra", 73.989],
        ["Pickles", 73.99],
        ["Pew", 73.991],
        ["Perera", 73.991],
        ["People", 73.992],
        ["Penwell", 73.993],
        ["Pelt", 73.994],
        ["Pauline", 73.994],
        ["Parkhill", 73.995],
        ["Paladino", 73.996],
        ["Ore", 73.997],
        ["Oram", 73.998],
        ["Olmo", 73.998],
        ["Oliveras", 73.999],
        ["Olivarria", 74],
        ["Ogorman", 74.001],
        ["Near", 74.001],
        ["Naron", 74.002],
        ["Na", 74.003],
        ["Muncie", 74.004],
        ["Mowbray", 74.004],
        ["Morones", 74.005],
        ["Moretti", 74.006],
        ["Monn", 74.007],
        ["Mitts", 74.007],
        ["Minks", 74.008],
        ["Minarik", 74.009],
        ["Mimms", 74.01],
        ["Milliron", 74.01],
        ["Millington", 74.011],
        ["Millhouse", 74.012],
        ["Messersmith", 74.013],
        ["McNett", 74.014],
        ["McKinstry", 74.014],
        ["McGeorge", 74.015],
        ["McDill", 74.016],
        ["McAteer", 74.017],
        ["Mazzeo", 74.017],
        ["Matchett", 74.018],
        ["Mahood", 74.019],
        ["Mabery", 74.02],
        ["Lundell", 74.02],
        ["Louden", 74.021],
        ["Losoya", 74.022],
        ["Lisk", 74.023],
        ["Lezama", 74.023],
        ["Leib", 74.024],
        ["Lebo", 74.025],
        ["Lanoue", 74.026],
        ["Lanford", 74.027],
        ["Lafortune", 74.027],
        ["Kump", 74.028],
        ["Krone", 74.029],
        ["Kreps", 74.03],
        ["Kott", 74.03],
        ["Kopecky", 74.031],
        ["Kolodziej", 74.032],
        ["Knuckles", 74.033],
        ["Kinman", 74.033],
        ["Kimmons", 74.034],
        ["Kelty", 74.035],
        ["Kaster", 74.036],
        ["Karlson", 74.036],
        ["Kania", 74.037],
        ["Jules", 74.038],
        ["Joyal", 74.039],
        ["Job", 74.039],
        ["Jenner", 74.04],
        ["Jasinski", 74.041],
        ["Jandreau", 74.042],
        ["Isenhour", 74.043],
        ["Hunziker", 74.043],
        ["Huhn", 74.044],
        ["Houde", 74.045],
        ["Houchins", 74.046],
        ["Holtman", 74.046],
        ["Hodo", 74.047],
        ["Heyman", 74.048],
        ["Hentges", 74.049],
        ["Hedberg", 74.049],
        ["Hayne", 74.05],
        ["Haycraft", 74.051],
        ["Harshbarger", 74.052],
        ["Harshaw", 74.052],
        ["Harriss", 74.053],
        ["Haring", 74.054],
        ["Hansell", 74.055],
        ["Hanford", 74.056],
        ["Handler", 74.056],
        ["Hamburg", 74.057],
        ["Hamblen", 74.058],
        ["Gunnell", 74.059],
        ["Groat", 74.059],
        ["Gorecki", 74.06],
        ["Gochenour", 74.061],
        ["Gleeson", 74.062],
        ["Genest", 74.062],
        ["Geiser", 74.063],
        ["Gabriele", 74.064],
        ["Fulghum", 74.065],
        ["Friese", 74.065],
        ["Fridley", 74.066],
        ["Freeborn", 74.067],
        ["Frailey", 74.068],
        ["Flaugher", 74.068],
        ["Fiala", 74.069],
        ["Ettinger", 74.07],
        ["Etheredge", 74.071],
        ["Espitia", 74.072],
        ["Eriksen", 74.072],
        ["Engelbrecht", 74.073],
        ["Engebretson", 74.074],
        ["Elie", 74.075],
        ["Eickhoff", 74.075],
        ["Edney", 74.076],
        ["Edelen", 74.077],
        ["Eberhard", 74.078],
        ["Eastin", 74.078],
        ["Eakes", 74.079],
        ["Driggs", 74.08],
        ["Doner", 74.081],
        ["Donaghy", 74.081],
        ["Disalvo", 74.082],
        ["Deshong", 74.083],
        ["Dahms", 74.084],
        ["Dahlquist", 74.084],
        ["Curren", 74.085],
        ["Cripe", 74.086],
        ["Cree", 74.087],
        ["Creager", 74.088],
        ["Corle", 74.088],
        ["Conatser", 74.089],
        ["Commons", 74.09],
        ["Coggin", 74.091],
        ["Coder", 74.091],
        ["Coaxum", 74.092],
        ["Closson", 74.093],
        ["Clodfelter", 74.094],
        ["Classen", 74.094],
        ["Chittenden", 74.095],
        ["Castilleja", 74.096],
        ["Casale", 74.097],
        ["Cartee", 74.097],
        ["Carriere", 74.098],
        ["Canup", 74.099],
        ["Canizales", 74.1],
        ["Burgoon", 74.101],
        ["Bunger", 74.101],
        ["Bugarin", 74.102],
        ["Buchanon", 74.103],
        ["Bruning", 74.104],
        ["Bruck", 74.104],
        ["Brookes", 74.105],
        ["Broadwell", 74.106],
        ["Brier", 74.107],
        ["Brekke", 74.107],
        ["Breese", 74.108],
        ["Bracero", 74.109],
        ["Bowley", 74.11],
        ["Bowersox", 74.11],
        ["Bose", 74.111],
        ["Bogar", 74.112],
        ["Blossom", 74.113],
        ["Blauser", 74.113],
        ["Blacker", 74.114],
        ["Bjorklund", 74.115],
        ["Belair", 74.116],
        ["Baumer", 74.117],
        ["Basler", 74.117],
        ["Barb", 74.118],
        ["Baltimore", 74.119],
        ["Baize", 74.12],
        ["Baden", 74.12],
        ["Auman", 74.121],
        ["Amundsen", 74.122],
        ["Amore", 74.123],
        ["Alvarenga", 74.123],
        ["Adan", 74.124],
        ["Adamczyk", 74.125],
        ["Yerkes", 74.126],
        ["Yerby", 74.126],
        ["Yawn", 74.127],
        ["Yamaguchi", 74.128],
        ["Worthey", 74.129],
        ["Wolk", 74.129],
        ["Wixom", 74.13],
        ["Wiersma", 74.131],
        ["Wieczorek", 74.132],
        ["Whiddon", 74.132],
        ["Weyer", 74.133],
        ["Wetherington", 74.134],
        ["Wein", 74.135],
        ["Watchman", 74.135],
        ["Warf", 74.136],
        ["Wansley", 74.137],
        ["Vesely", 74.138],
        ["Velazco", 74.138],
        ["Van Norman", 74.139],
        ["Valasquez", 74.14],
        ["Utz", 74.141],
        ["Urso", 74.141],
        ["Turco", 74.142],
        ["Turbeville", 74.143],
        ["Trivett", 74.144],
        ["Torrance", 74.144],
        ["Toothaker", 74.145],
        ["Toohey", 74.146],
        ["Tondreau", 74.147],
        ["Thaler", 74.147],
        ["Sylvain", 74.148],
        ["Swindler", 74.149],
        ["Swigert", 74.15],
        ["Swider", 74.15],
        ["Stiner", 74.151],
        ["Stever", 74.152],
        ["Steffes", 74.153],
        ["Stampley", 74.153],
        ["Stair", 74.154],
        ["Smidt", 74.155],
        ["Skeete", 74.156],
        ["Silvestre", 74.156],
        ["Shy", 74.157],
        ["Shutts", 74.158],
        ["Shock", 74.159],
        ["Shealey", 74.159],
        ["Seigler", 74.16],
        ["Schweizer", 74.161],
        ["Schuldt", 74.162],
        ["Schlichting", 74.162],
        ["Scherr", 74.163],
        ["Saulsberry", 74.164],
        ["Saner", 74.165],
        ["Rosin", 74.165],
        ["Rosato", 74.166],
        ["Roling", 74.167],
        ["Rohn", 74.168],
        ["Rix", 74.168],
        ["Rister", 74.169],
        ["Remley", 74.17],
        ["Remick", 74.171],
        ["Recinos", 74.171],
        ["Ramm", 74.172],
        ["Raabe", 74.173],
        ["Pursell", 74.174],
        ["Poythress", 74.174],
        ["Poli", 74.175],
        ["Pokorny", 74.176],
        ["Plum", 74.176],
        ["Pettry", 74.177],
        ["Petrey", 74.178],
        ["Petitt", 74.179],
        ["Penman", 74.179],
        ["Payson", 74.18],
        ["Paquet", 74.181],
        ["Pappalardo", 74.182],
        ["Outland", 74.182],
        ["Oscar", 74.183],
        ["Orenstein", 74.184],
        ["Nuttall", 74.185],
        ["Nuckols", 74.185],
        ["Nott", 74.186],
        ["Nimmo", 74.187],
        ["Murtagh", 74.188],
        ["Mousseau", 74.188],
        ["Moulder", 74.189],
        ["Mooneyhan", 74.19],
        ["Moak", 74.191],
        ["Minch", 74.191],
        ["Miera", 74.192],
        ["Mercuri", 74.193],
        ["Meighan", 74.194],
        ["McNelly", 74.194],
        ["McGuffin", 74.195],
        ["McCreery", 74.196],
        ["McClaskey", 74.197],
        ["Man", 74.197],
        ["Mainor", 74.198],
        ["Luongo", 74.199],
        ["Lundstrom", 74.2],
        ["Loughman", 74.2],
        ["Loose", 74.201],
        ["Lobo", 74.202],
        ["Lobb", 74.203],
        ["Linhart", 74.203],
        ["Liberty", 74.204],
        ["Lever", 74.205],
        ["Leu", 74.206],
        ["Leiter", 74.206],
        ["Lehoux", 74.207],
        ["Lehn", 74.208],
        ["Lares", 74.209],
        ["Lapan", 74.209],
        ["Langhorne", 74.21],
        ["Lamon", 74.211],
        ["Ladwig", 74.212],
        ["Ladson", 74.212],
        ["Kuzma", 74.213],
        ["Kreitzer", 74.214],
        ["Knop", 74.215],
        ["Keech", 74.215],
        ["Kea", 74.216],
        ["Kadlec", 74.217],
        ["Jo", 74.218],
        ["Jhonson", 74.218],
        ["Jantz", 74.219],
        ["Inglis", 74.22],
        ["Husk", 74.221],
        ["Hulme", 74.221],
        ["Housel", 74.222],
        ["Hofman", 74.223],
        ["Hillery", 74.224],
        ["Heidenreich", 74.224],
        ["Heaps", 74.225],
        ["Haslett", 74.226],
        ["Harting", 74.227],
        ["Hartig", 74.227],
        ["Hamler", 74.228],
        ["Halton", 74.229],
        ["Hallum", 74.23],
        ["Gutierres", 74.23],
        ["Guida", 74.231],
        ["Guerrier", 74.232],
        ["Grossi", 74.233],
        ["Gress", 74.233],
        ["Greenhalgh", 74.234],
        ["Gravelle", 74.235],
        ["Gow", 74.236],
        ["Goslin", 74.236],
        ["Gonyea", 74.237],
        ["Gipe", 74.238],
        ["Gerstner", 74.239],
        ["Gasser", 74.239],
        ["Garceau", 74.24],
        ["Gannaway", 74.241],
        ["Gama", 74.242],
        ["Gallop", 74.242],
        ["Gaiser", 74.243],
        ["Fullilove", 74.244],
        ["Foutz", 74.244],
        ["Fossum", 74.245],
        ["Flannagan", 74.246],
        ["Farrior", 74.247],
        ["Faller", 74.247],
        ["Ericksen", 74.248],
        ["Entrekin", 74.249],
        ["Enochs", 74.25],
        ["Englund", 74.25],
        ["Ellenberger", 74.251],
        ["Eastland", 74.252],
        ["Earwood", 74.253],
        ["Dudash", 74.253],
        ["Du", 74.254],
        ["Drozd", 74.255],
        ["Desoto", 74.256],
        ["Delph", 74.256],
        ["Dekker", 74.257],
        ["DeJohn", 74.258],
        ["DeGarmo", 74.259],
        ["DeFeo", 74.259],
        ["DeFalco", 74.26],
        ["DeBlois", 74.261],
        ["Dacus", 74.262],
        ["Cudd", 74.262],
        ["Crossen", 74.263],
        ["Crooms", 74.264],
        ["Cronan", 74.265],
        ["Costin", 74.265],
        ["Costanza", 74.266],
        ["Cordray", 74.267],
        ["Comerford", 74.268],
        ["Collie", 74.268],
        ["Colegrove", 74.269],
        ["Coldwell", 74.27],
        ["Claassen", 74.271],
        ["Chartrand", 74.271],
        ["Castiglione", 74.272],
        ["Carte", 74.273],
        ["Cardella", 74.274],
        ["Carberry", 74.274],
        ["Capp", 74.275],
        ["Capobianco", 74.276],
        ["Cangelosi", 74.277],
        ["Buch", 74.277],
        ["Brunell", 74.278],
        ["Brucker", 74.279],
        ["Brockett", 74.28],
        ["Brizendine", 74.28],
        ["Brinegar", 74.281],
        ["Brimer", 74.282],
        ["Brase", 74.283],
        ["Bosque", 74.283],
        ["Bonk", 74.284],
        ["Bolger", 74.285],
        ["Bohanon", 74.286],
        ["Bohan", 74.286],
        ["Blazek", 74.287],
        ["Berning", 74.288],
        ["Bergan", 74.289],
        ["Bennette", 74.289],
        ["Beauchemin", 74.29],
        ["Battiste", 74.291],
        ["Barra", 74.292],
        ["Balogh", 74.292],
        ["Avis", 74.293],
        ["Avallone", 74.294],
        ["Aubry", 74.295],
        ["Ashcroft", 74.295],
        ["Asencio", 74.296],
        ["Arledge", 74.297],
        ["Anchondo", 74.298],
        ["Amy", 74.298],
        ["Alvord", 74.299],
        ["Acheson", 74.3],
        ["Zaleski", 74.301],
        ["Yonker", 74.301],
        ["Wyss", 74.302],
        ["Wycoff", 74.303],
        ["Woodburn", 74.303],
        ["Wininger", 74.304],
        ["Winders", 74.305],
        ["Willmon", 74.306],
        ["Wiechmann", 74.306],
        ["Westley", 74.307],
        ["Weatherholt", 74.308],
        ["Warnick", 74.309],
        ["Wardle", 74.309],
        ["Warburton", 74.31],
        ["Volkert", 74.311],
        ["Virgin", 74.311],
        ["Villanveva", 74.312],
        ["Veit", 74.313],
        ["Vass", 74.314],
        ["Van Allen", 74.314],
        ["Tung", 74.315],
        ["Toribio", 74.316],
        ["Toothman", 74.317],
        ["Tiggs", 74.317],
        ["Thornsberry", 74.318],
        ["Thome", 74.319],
        ["Tepper", 74.32],
        ["Teeple", 74.32],
        ["Tebo", 74.321],
        ["Tassone", 74.322],
        ["Tann", 74.322],
        ["Sultan", 74.323],
        ["Stucker", 74.324],
        ["Stotler", 74.325],
        ["Stoneman", 74.325],
        ["Stehle", 74.326],
        ["Stanback", 74.327],
        ["Stallcup", 74.328],
        ["Spurr", 74.328],
        ["Speers", 74.329],
        ["Spada", 74.33],
        ["Solum", 74.33],
        ["Smolen", 74.331],
        ["Sinn", 74.332],
        ["Silvernail", 74.333],
        ["Sholes", 74.333],
        ["Shives", 74.334],
        ["Shain", 74.335],
        ["Secrest", 74.336],
        ["Seagle", 74.336],
        ["Schuette", 74.337],
        ["Schoch", 74.338],
        ["Schnieders", 74.339],
        ["Schild", 74.339],
        ["Schiavone", 74.34],
        ["Schiavo", 74.341],
        ["Scharff", 74.341],
        ["Santee", 74.342],
        ["Sandell", 74.343],
        ["Salvo", 74.344],
        ["Rollings", 74.344],
        ["Rollin", 74.345],
        ["Rivenburg", 74.346],
        ["Ritzman", 74.347],
        ["Rist", 74.347],
        ["Rio", 74.348],
        ["Ricardo", 74.349],
        ["Reynosa", 74.35],
        ["Retana", 74.35],
        ["Reiber", 74.351],
        ["Regnier", 74.352],
        ["Rarick", 74.352],
        ["Ransome", 74.353],
        ["Rall", 74.354],
        ["Propes", 74.355],
        ["Prall", 74.355],
        ["Poyner", 74.356],
        ["Ponds", 74.357],
        ["Poitra", 74.358],
        ["Plaster", 74.358],
        ["Pippins", 74.359],
        ["Pinion", 74.36],
        ["Piccolo", 74.36],
        ["Phu", 74.361],
        ["Perillo", 74.362],
        ["Penrose", 74.363],
        ["Pendergraft", 74.363],
        ["Pelchat", 74.364],
        ["Peed", 74.365],
        ["Patenaude", 74.366],
        ["Palko", 74.366],
        ["Odoms", 74.367],
        ["Oddo", 74.368],
        ["Novoa", 74.369],
        ["Noone", 74.369],
        ["Newburn", 74.37],
        ["Negri", 74.371],
        ["Nantz", 74.371],
        ["Mosser", 74.372],
        ["Moshier", 74.373],
        ["Molter", 74.374],
        ["Molinari", 74.374],
        ["Moler", 74.375],
        ["Millman", 74.376],
        ["Meurer", 74.377],
        ["Mendel", 74.377],
        ["McRay", 74.378],
        ["McNicholas", 74.379],
        ["McNerney", 74.379],
        ["McKillip", 74.38],
        ["McIlvain", 74.381],
        ["McAdory", 74.382],
        ["Matter", 74.382],
        ["Master", 74.383],
        ["Marmol", 74.384],
        ["Marinez", 74.385],
        ["Manzer", 74.385],
        ["Mankin", 74.386],
        ["Makris", 74.387],
        ["Majeski", 74.388],
        ["Magnus", 74.388],
        ["Maffei", 74.389],
        ["Luoma", 74.39],
        ["Luman", 74.39],
        ["Luebke", 74.391],
        ["Luby", 74.392],
        ["Lomonaco", 74.393],
        ["Loar", 74.393],
        ["Litchford", 74.394],
        ["Lintz", 74.395],
        ["Licht", 74.396],
        ["Levenson", 74.396],
        ["Legge", 74.397],
        ["Laughter", 74.398],
        ["Lanigan", 74.399],
        ["Krom", 74.399],
        ["Kreger", 74.4],
        ["Koop", 74.401],
        ["Kober", 74.401],
        ["Klima", 74.402],
        ["Kitterman", 74.403],
        ["Kinkead", 74.404],
        ["Kimbell", 74.404],
        ["Kilian", 74.405],
        ["Kibbe", 74.406],
        ["Kendig", 74.407],
        ["Kemmer", 74.407],
        ["Kash", 74.408],
        ["Jenkin", 74.409],
        ["Inniss", 74.409],
        ["Hurlbut", 74.41],
        ["Hunsucker", 74.411],
        ["Hugo", 74.412],
        ["Huckabee", 74.412],
        ["Hoxie", 74.413],
        ["Hoglund", 74.414],
        ["Hockensmith", 74.415],
        ["Hoadley", 74.415],
        ["Hinkel", 74.416],
        ["Higuera", 74.417],
        ["Herrman", 74.418],
        ["Heiner", 74.418],
        ["Hausmann", 74.419],
        ["Haubrich", 74.42],
        ["Hassen", 74.42],
        ["Hanlin", 74.421],
        ["Hallinan", 74.422],
        ["Haglund", 74.423],
        ["Hagberg", 74.423],
        ["Gullo", 74.424],
        ["Gullion", 74.425],
        ["Groner", 74.426],
        ["Greenwalt", 74.426],
        ["Grand", 74.427],
        ["Goodwill", 74.428],
        ["Gong", 74.428],
        ["Gobert", 74.429],
        ["Glowacki", 74.43],
        ["Glessner", 74.431],
        ["Gines", 74.431],
        ["Gildersleeve", 74.432],
        ["Gildea", 74.433],
        ["Gerke", 74.434],
        ["Gerhard", 74.434],
        ["Gebhard", 74.435],
        ["Gatton", 74.436],
        ["Gately", 74.437],
        ["Galasso", 74.437],
        ["Fralick", 74.438],
        ["Fouse", 74.439],
        ["Fluharty", 74.439],
        ["Faucette", 74.44],
        ["Fairfax", 74.441],
        ["Evanoff", 74.442],
        ["Elser", 74.442],
        ["Ellard", 74.443],
        ["Egerton", 74.444],
        ["Edie", 74.445],
        ["Ector", 74.445],
        ["Ebling", 74.446],
        ["Dunkel", 74.447],
        ["Duhart", 74.448],
        ["Drysdale", 74.448],
        ["Dostal", 74.449],
        ["Dorey", 74.45],
        ["Dolph", 74.45],
        ["Doles", 74.451],
        ["Dismukes", 74.452],
        ["Digregorio", 74.453],
        ["Digby", 74.453],
        ["Dewees", 74.454],
        ["Deramus", 74.455],
        ["Denniston", 74.456],
        ["Dennett", 74.456],
        ["Deloney", 74.457],
        ["DeLaughter", 74.458],
        ["Darcy", 74.458],
        ["Cuneo", 74.459],
        ["Cumberland", 74.46],
        ["Crotts", 74.461],
        ["Crosswhite", 74.461],
        ["Cremeans", 74.462],
        ["Creasey", 74.463],
        ["Cottman", 74.464],
        ["Cothern", 74.464],
        ["Costales", 74.465],
        ["Cosner", 74.466],
        ["Corpus", 74.467],
        ["Cora", 74.467],
        ["Constable", 74.468],
        ["Colligan", 74.469],
        ["Cobble", 74.469],
        ["Clutter", 74.47],
        ["Chupp", 74.471],
        ["Chevez", 74.472],
        ["Chatmon", 74.472],
        ["Chaires", 74.473],
        ["Caplan", 74.474],
        ["Caffee", 74.475],
        ["Cabana", 74.475],
        ["Burrough", 74.476],
        ["Burditt", 74.477],
        ["Buckler", 74.477],
        ["Brunswick", 74.478],
        ["Brouillard", 74.479],
        ["Broady", 74.48],
        ["Bowlby", 74.48],
        ["Bouley", 74.481],
        ["Borgman", 74.482],
        ["Boltz", 74.483],
        ["Boddy", 74.483],
        ["Blackston", 74.484],
        ["Birdsell", 74.485],
        ["Bedgood", 74.486],
        ["Bate", 74.486],
        ["Basil", 74.487],
        ["Bartos", 74.488],
        ["Barriga", 74.488],
        ["Barrie", 74.489],
        ["Barna", 74.49],
        ["Barcenas", 74.491],
        ["Banach", 74.491],
        ["Baccus", 74.492],
        ["Auclair", 74.493],
        ["Ashman", 74.494],
        ["Arter", 74.494],
        ["Arendt", 74.495],
        ["Ansell", 74.496],
        ["Allums", 74.497],
        ["Allsop", 74.497],
        ["Allender", 74.498],
        ["Alber", 74.499],
        ["Albarran", 74.499],
        ["Adelson", 74.5],
        ["Zoll", 74.501],
        ["Wysong", 74.502],
        ["Wimbley", 74.502],
        ["Wildes", 74.503],
        ["Whitis", 74.504],
        ["Whitehill", 74.504],
        ["Whicker", 74.505],
        ["Weymouth", 74.506],
        ["Well", 74.507],
        ["Weldy", 74.507],
        ["Wark", 74.508],
        ["Wareham", 74.509],
        ["Waddy", 74.509],
        ["Viveiros", 74.51],
        ["Vito", 74.511],
        ["Vides", 74.512],
        ["Vecchio", 74.512],
        ["Vath", 74.513],
        ["Vandoren", 74.514],
        ["Vanderhoof", 74.514],
        ["Unrein", 74.515],
        ["Uecker", 74.516],
        ["Tsan", 74.517],
        ["Trepanier", 74.517],
        ["Tregre", 74.518],
        ["Torkelson", 74.519],
        ["Ton", 74.519],
        ["Tobler", 74.52],
        ["Tineo", 74.521],
        ["Timmer", 74.522],
        ["Swopes", 74.522],
        ["Swofford", 74.523],
        ["Sweeten", 74.524],
        ["Swarts", 74.524],
        ["Summerfield", 74.525],
        ["Sumler", 74.526],
        ["Stucky", 74.527],
        ["Strozier", 74.527],
        ["Stigall", 74.528],
        ["Stickel", 74.529],
        ["Stennis", 74.529],
        ["Stelzer", 74.53],
        ["Steely", 74.531],
        ["Solar", 74.532],
        ["Slayden", 74.532],
        ["Skillern", 74.533],
        ["Shurtz", 74.534],
        ["Shelor", 74.534],
        ["Shellenbarger", 74.535],
        ["Shand", 74.536],
        ["Shabazz", 74.537],
        ["Seo", 74.537],
        ["Scroggs", 74.538],
        ["Schwandt", 74.539],
        ["Schrecengost", 74.54],
        ["Schoenrock", 74.54],
        ["Schirmer", 74.541],
        ["Sandridge", 74.542],
        ["Ruzicka", 74.542],
        ["Rozek", 74.543],
        ["Rowlands", 74.544],
        ["Roser", 74.545],
        ["Rosendahl", 74.545],
        ["Romanowski", 74.546],
        ["Romaine", 74.547],
        ["Rolston", 74.547],
        ["Rink", 74.548],
        ["Riggio", 74.549],
        ["Reichman", 74.55],
        ["Redondo", 74.55],
        ["Reay", 74.551],
        ["Rawlinson", 74.552],
        ["Raskin", 74.552],
        ["Raine", 74.553],
        ["Quandt", 74.554],
        ["Purpura", 74.555],
        ["Purdue", 74.555],
        ["Pruneda", 74.556],
        ["Prevatte", 74.557],
        ["Prettyman", 74.557],
        ["Pinedo", 74.558],
        ["Pierro", 74.559],
        ["Pidgeon", 74.56],
        ["Phillippi", 74.56],
        ["Pfeil", 74.561],
        ["Penix", 74.562],
        ["Peasley", 74.562],
        ["Paro", 74.563],
        ["Overall", 74.564],
        ["Ospina", 74.565],
        ["Ortegon", 74.565],
        ["Ogata", 74.566],
        ["Ogara", 74.567],
        ["Normandin", 74.567],
        ["Nordman", 74.568],
        ["Nims", 74.569],
        ["Nassar", 74.57],
        ["Motz", 74.57],
        ["Morlan", 74.571],
        ["Mooring", 74.572],
        ["Moles", 74.572],
        ["Moir", 74.573],
        ["Mizrahi", 74.574],
        ["Mire", 74.575],
        ["Minaya", 74.575],
        ["Millwood", 74.576],
        ["Mikula", 74.577],
        ["Messmer", 74.577],
        ["Meikle", 74.578],
        ["McTaggart", 74.579],
        ["McGonagle", 74.58],
        ["McEwan", 74.58],
        ["McCasland", 74.581],
        ["McCane", 74.582],
        ["McCaffery", 74.582],
        ["McAlexander", 74.583],
        ["Mattocks", 74.584],
        ["Mattie", 74.585],
        ["Matranga", 74.585],
        ["Martone", 74.586],
        ["Markland", 74.587],
        ["Maravilla", 74.587],
        ["Manno", 74.588],
        ["Manly", 74.589],
        ["Mancha", 74.59],
        ["Mallery", 74.59],
        ["Magno", 74.591],
        ["Lorentz", 74.592],
        ["Locklin", 74.592],
        ["Livingstone", 74.593],
        ["Lipford", 74.594],
        ["Lininger", 74.595],
        ["Line", 74.595],
        ["Liao", 74.596],
        ["Lepley", 74.597],
        ["Leming", 74.597],
        ["Lemelin", 74.598],
        ["Leadbetter", 74.599],
        ["Lawhon", 74.6],
        ["Lattin", 74.6],
        ["Langworthy", 74.601],
        ["Lampman", 74.602],
        ["Lambeth", 74.602],
        ["Lamarr", 74.603],
        ["Lahey", 74.604],
        ["Krajewski", 74.605],
        ["Klopp", 74.605],
        ["Kinnison", 74.606],
        ["Kestner", 74.607],
        ["Kerry", 74.607],
        ["Kennell", 74.608],
        ["Karim", 74.609],
        ["Jozwiak", 74.61],
        ["Jakubowski", 74.61],
        ["Jagger", 74.611],
        ["Ivery", 74.612],
        ["Ishmael", 74.612],
        ["Iliff", 74.613],
        ["Iddings", 74.614],
        ["Hudkins", 74.615],
        ["Houseman", 74.615],
        ["Holz", 74.616],
        ["Holderman", 74.617],
        ["Hoehne", 74.617],
        ["Highfill", 74.618],
        ["Hiett", 74.619],
        ["Heskett", 74.62],
        ["Heldt", 74.62],
        ["Hedman", 74.621],
        ["Hayslett", 74.622],
        ["Hatchell", 74.622],
        ["Hasse", 74.623],
        ["Hamon", 74.624],
        ["Hamada", 74.625],
        ["Hakala", 74.625],
        ["Haislip", 74.626],
        ["Haffey", 74.627],
        ["Hackbarth", 74.627],
        ["Guo", 74.628],
        ["Gullickson", 74.629],
        ["Guerrette", 74.63],
        ["Guan", 74.63],
        ["Greenblatt", 74.631],
        ["Goudreau", 74.632],
        ["Gongora", 74.633],
        ["Godbout", 74.633],
        ["Glaude", 74.634],
        ["Gills", 74.635],
        ["Gillison", 74.635],
        ["Gigliotti", 74.636],
        ["Gargano", 74.637],
        ["Gallucci", 74.638],
        ["Galli", 74.638],
        ["Galante", 74.639],
        ["Frasure", 74.64],
        ["Fodor", 74.64],
        ["Fizer", 74.641],
        ["Fishburn", 74.642],
        ["Finkbeiner", 74.643],
        ["Finck", 74.643],
        ["Fager", 74.644],
        ["Estey", 74.645],
        ["Espiritu", 74.645],
        ["Eppinger", 74.646],
        ["Epperly", 74.647],
        ["Emig", 74.648],
        ["Eckley", 74.648],
        ["Dray", 74.649],
        ["Dorsch", 74.65],
        ["Dille", 74.65],
        ["Devita", 74.651],
        ["Deslauriers", 74.652],
        ["Demery", 74.653],
        ["Delorme", 74.653],
        ["Delbosque", 74.654],
        ["Dauphin", 74.655],
        ["Dantonio", 74.655],
        ["Curd", 74.656],
        ["Crume", 74.657],
        ["Crown", 74.658],
        ["Cozad", 74.658],
        ["Cossette", 74.659],
        ["Comacho", 74.66],
        ["Climer", 74.66],
        ["Chadbourne", 74.661],
        ["Cespedes", 74.662],
        ["Cayton", 74.663],
        ["Castaldo", 74.663],
        ["Carpino", 74.664],
        ["Carls", 74.665],
        ["Capozzi", 74.665],
        ["Canela", 74.666],
        ["Cadet", 74.667],
        ["Buzard", 74.668],
        ["Busick", 74.668],
        ["Burlison", 74.669],
        ["Brinkmann", 74.67],
        ["Bridgeforth", 74.67],
        ["Bourbeau", 74.671],
        ["Bornstein", 74.672],
        ["Boots", 74.673],
        ["Bonfiglio", 74.673],
        ["Boice", 74.674],
        ["Boese", 74.675],
        ["Biondi", 74.675],
        ["Bilski", 74.676],
        ["Betton", 74.677],
        ["Berwick", 74.678],
        ["Berlanga", 74.678],
        ["Behan", 74.679],
        ["Becraft", 74.68],
        ["Barrientez", 74.68],
        ["Banh", 74.681],
        ["Balke", 74.682],
        ["Balderrama", 74.683],
        ["Bahe", 74.683],
        ["Bachand", 74.684],
        ["Atlas", 74.685],
        ["Armer", 74.685],
        ["Arceo", 74.686],
        ["Aliff", 74.687],
        ["Alatorre", 74.688],
        ["Zermeno", 74.688],
        ["Zane", 74.689],
        ["Younce", 74.69],
        ["You", 74.69],
        ["Yeoman", 74.691],
        ["Yamasaki", 74.692],
        ["Wroten", 74.692],
        ["Worm", 74.693],
        ["Woodby", 74.694],
        ["Winer", 74.695],
        ["Wilmer", 74.695],
        ["Willits", 74.696],
        ["Wilcoxon", 74.697],
        ["Wehmeyer", 74.697],
        ["Waterbury", 74.698],
        ["Wass", 74.699],
        ["Wann", 74.699],
        ["Wake", 74.7],
        ["Wachtel", 74.701],
        ["Vizcarra", 74.702],
        ["Vince", 74.702],
        ["Victory", 74.703],
        ["Veitch", 74.704],
        ["Vanderbilt", 74.704],
        ["Vallone", 74.705],
        ["Vallery", 74.706],
        ["Ureno", 74.706],
        ["Tyer", 74.707],
        ["Tipps", 74.708],
        ["Tiedeman", 74.709],
        ["Theberge", 74.709],
        ["Texeira", 74.71],
        ["Taub", 74.711],
        ["Tapscott", 74.711],
        ["Stutts", 74.712],
        ["Stults", 74.713],
        ["Stukes", 74.713],
        ["Staff", 74.714],
        ["Spink", 74.715],
        ["Sottile", 74.716],
        ["Smithwick", 74.716],
        ["Slane", 74.717],
        ["Simeone", 74.718],
        ["Silvester", 74.718],
        ["Siegrist", 74.719],
        ["Shiffer", 74.72],
        ["Sheedy", 74.72],
        ["Sheaffer", 74.721],
        ["Severin", 74.722],
        ["Sellman", 74.723],
        ["Scotto", 74.723],
        ["Schupp", 74.724],
        ["Schueller", 74.725],
        ["Schreier", 74.725],
        ["Schoolcraft", 74.726],
        ["Schoenberger", 74.727],
        ["Schnabel", 74.727],
        ["Sangster", 74.728],
        ["Samford", 74.729],
        ["Saliba", 74.73],
        ["Ryles", 74.73],
        ["Ryans", 74.731],
        ["Rossetti", 74.732],
        ["Rodriguz", 74.732],
        ["Risch", 74.733],
        ["Riel", 74.734],
        ["Rezendes", 74.734],
        ["Rester", 74.735],
        ["Rencher", 74.736],
        ["Recker", 74.737],
        ["Rathjen", 74.737],
        ["Profitt", 74.738],
        ["Poteete", 74.739],
        ["Polizzi", 74.739],
        ["Perrigo", 74.74],
        ["Patridge", 74.741],
        ["Osby", 74.741],
        ["Orvis", 74.742],
        ["Opperman", 74.743],
        ["Oppenheim", 74.744],
        ["Onorato", 74.744],
        ["Olaughlin", 74.745],
        ["Ohagan", 74.746],
        ["Ogles", 74.746],
        ["Oehler", 74.747],
        ["Obyrne", 74.748],
        ["Nuzzo", 74.748],
        ["Nickle", 74.749],
        ["Nease", 74.75],
        ["Neagle", 74.751],
        ["Navarette", 74.751],
        ["Nagata", 74.752],
        ["Musto", 74.753],
        ["Morning", 74.753],
        ["Morison", 74.754],
        ["Montz", 74.755],
        ["Mogensen", 74.755],
        ["Mizer", 74.756],
        ["Miraglia", 74.757],
        ["Mingus", 74.758],
        ["Migliore", 74.758],
        ["Merideth", 74.759],
        ["Menges", 74.76],
        ["Mellor", 74.76],
        ["McNear", 74.761],
        ["McNab", 74.762],
        ["McLoud", 74.762],
        ["McElligott", 74.763],
        ["McCollom", 74.764],
        ["Maynes", 74.765],
        ["Marquette", 74.765],
        ["Markowski", 74.766],
        ["Marcantonio", 74.767],
        ["Mar", 74.767],
        ["Maldanado", 74.768],
        ["Makin", 74.769],
        ["Macey", 74.769],
        ["Lundeen", 74.77],
        ["Lovin", 74.771],
        ["Longino", 74.772],
        ["Lisle", 74.772],
        ["Linthicum", 74.773],
        ["Limones", 74.774],
        ["Lesure", 74.774],
        ["Lesage", 74.775],
        ["Leisure", 74.776],
        ["Lauver", 74.776],
        ["Laubach", 74.777],
        ["Latshaw", 74.778],
        ["Lary", 74.779],
        ["Lapham", 74.779],
        ["Lacoste", 74.78],
        ["Lacher", 74.781],
        ["Kutcher", 74.781],
        ["Knickerbocker", 74.782],
        ["Klos", 74.783],
        ["Klingler", 74.783],
        ["Kleiman", 74.784],
        ["Kittleson", 74.785],
        ["Kimbrel", 74.786],
        ["Kimberly", 74.786],
        ["Kemmerer", 74.787],
        ["Kelson", 74.788],
        ["Keese", 74.788],
        ["Kam", 74.789],
        ["Kallas", 74.79],
        ["Jurgensen", 74.79],
        ["Junkins", 74.791],
        ["Juneau", 74.792],
        ["Juergens", 74.793],
        ["Jolliff", 74.793],
        ["Jelks", 74.794],
        ["Janicki", 74.795],
        ["Jang", 74.795],
        ["Innocent", 74.796],
        ["Ingles", 74.797],
        ["Inge", 74.797],
        ["Huguley", 74.798],
        ["Huggard", 74.799],
        ["Howton", 74.8],
        ["Hone", 74.8],
        ["Holford", 74.801],
        ["Holding", 74.802],
        ["Hogle", 74.802],
        ["Hipple", 74.803],
        ["Heimbach", 74.804],
        ["Heider", 74.804],
        ["Heidel", 74.805],
        ["Havener", 74.806],
        ["Hattaway", 74.807],
        ["Harrah", 74.807],
        ["Hanscom", 74.808],
        ["Hankinson", 74.809],
        ["Hamdan", 74.809],
        ["Gridley", 74.81],
        ["Goulette", 74.811],
        ["Goulart", 74.811],
        ["Goodspeed", 74.812],
        ["Goodrow", 74.813],
        ["Go", 74.814],
        ["Girardi", 74.814],
        ["Gent", 74.815],
        ["Gautreau", 74.816],
        ["Ganz", 74.816],
        ["Gandara", 74.817],
        ["Gamblin", 74.818],
        ["Galipeau", 74.818],
        ["Fyffe", 74.819],
        ["Furrow", 74.82],
        ["Fulp", 74.82],
        ["Fricks", 74.821],
        ["Frase", 74.822],
        ["Frandsen", 74.823],
        ["Fout", 74.823],
        ["Foulks", 74.824],
        ["Fouche", 74.825],
        ["Foskey", 74.825],
        ["Forgey", 74.826],
        ["Foor", 74.827],
        ["Fobbs", 74.827],
        ["Finklea", 74.828],
        ["Fincham", 74.829],
        ["Figueiredo", 74.83],
        ["Festa", 74.83],
        ["Ferrier", 74.831],
        ["Fellman", 74.832],
        ["Eslick", 74.832],
        ["Eilerman", 74.833],
        ["Eckart", 74.834],
        ["Eaglin", 74.834],
        ["Dunfee", 74.835],
        ["Dumond", 74.836],
        ["Drewry", 74.837],
        ["Douse", 74.837],
        ["Domino", 74.838],
        ["Dimick", 74.839],
        ["Diener", 74.839],
        ["Dickert", 74.84],
        ["Deines", 74.841],
        ["Degree", 74.841],
        ["Declue", 74.842],
        ["Daw", 74.843],
        ["Dattilo", 74.844],
        ["Danko", 74.844],
        ["Custodio", 74.845],
        ["Cuccia", 74.846],
        ["Crunk", 74.846],
        ["Crispin", 74.847],
        ["Corp", 74.848],
        ["Cornwall", 74.848],
        ["Corea", 74.849],
        ["Coppin", 74.85],
        ["Considine", 74.851],
        ["Coniglio", 74.851],
        ["Conboy", 74.852],
        ["Collar", 74.853],
        ["Cockrum", 74.853],
        ["Clute", 74.854],
        ["Clewis", 74.855],
        ["Claude", 74.855],
        ["Christiano", 74.856],
        ["Channell", 74.857],
        ["Channel", 74.858],
        ["Cerrato", 74.858],
        ["Cecere", 74.859],
        ["Catoe", 74.86],
        ["Castillon", 74.86],
        ["Castile", 74.861],
        ["Carstarphen", 74.862],
        ["Carmouche", 74.862],
        ["Caperton", 74.863],
        ["Buteau", 74.864],
        ["Bury", 74.865],
        ["Bumpers", 74.865],
        ["Brey", 74.866],
        ["Brenton", 74.867],
        ["Brazeal", 74.867],
        ["Brassard", 74.868],
        ["Brass", 74.869],
        ["Braga", 74.869],
        ["Bradham", 74.87],
        ["Bourget", 74.871],
        ["Borrelli", 74.872],
        ["Borba", 74.872],
        ["Boothby", 74.873],
        ["Bohr", 74.874],
        ["Bohm", 74.874],
        ["Boehme", 74.875],
        ["Bodin", 74.876],
        ["Bloss", 74.876],
        ["Blocher", 74.877],
        ["Bizzell", 74.878],
        ["Bieker", 74.879],
        ["Berthelot", 74.879],
        ["Bernardini", 74.88],
        ["Berends", 74.881],
        ["Benard", 74.881],
        ["Belser", 74.882],
        ["Baze", 74.883],
        ["Bartling", 74.883],
        ["Barrientes", 74.884],
        ["Barras", 74.885],
        ["Barcia", 74.886],
        ["Banfield", 74.886],
        ["Aurand", 74.887],
        ["Artman", 74.888],
        ["Arnott", 74.888],
        ["Arend", 74.889],
        ["Ardis", 74.89],
        ["Amon", 74.89],
        ["Almaguer", 74.891],
        ["Allee", 74.892],
        ["Albarado", 74.893],
        ["Alameda", 74.893],
        ["Abdo", 74.894],
        ["Zuehlke", 74.895],
        ["Zoeller", 74.895],
        ["Yokoyama", 74.896],
        ["Yocom", 74.897],
        ["Wyllie", 74.897],
        ["Woolum", 74.898],
        ["Wint", 74.899],
        ["Winland", 74.899],
        ["Wink", 74.9],
        ["Wilner", 74.901],
        ["Wilmes", 74.901],
        ["Whitlatch", 74.902],
        ["Westervelt", 74.903],
        ["Walthall", 74.904],
        ["Walkowiak", 74.904],
        ["Walburn", 74.905],
        ["Viviano", 74.906],
        ["Vanderhoff", 74.906],
        ["Valez", 74.907],
        ["Ugalde", 74.908],
        ["Trumbull", 74.908],
        ["Todaro", 74.909],
        ["Tilford", 74.91],
        ["Tidd", 74.91],
        ["Tibbits", 74.911],
        ["Terranova", 74.912],
        ["Templeman", 74.912],
        ["Tannenbaum", 74.913],
        ["Talmage", 74.914],
        ["Tabarez", 74.914],
        ["Swearengin", 74.915],
        ["Swartwood", 74.916],
        ["Svendsen", 74.917],
        ["Strum", 74.917],
        ["Strack", 74.918],
        ["Storie", 74.919],
        ["Stockard", 74.919],
        ["Steinbeck", 74.92],
        ["Starns", 74.921],
        ["Stanko", 74.921],
        ["Stankiewicz", 74.922],
        ["Stacks", 74.923],
        ["Stach", 74.923],
        ["Sproles", 74.924],
        ["Spenser", 74.925],
        ["Smotherman", 74.925],
        ["Slusser", 74.926],
        ["Sinha", 74.927],
        ["Silber", 74.927],
        ["Siefert", 74.928],
        ["Siddiqui", 74.929],
        ["Shuff", 74.929],
        ["Sherburne", 74.93],
        ["Seldon", 74.931],
        ["Seddon", 74.932],
        ["Schweigert", 74.932],
        ["Schroeter", 74.933],
        ["Schmucker", 74.934],
        ["Saffold", 74.934],
        ["Rutz", 74.935],
        ["Rundle", 74.936],
        ["Rosinski", 74.936],
        ["Rosenow", 74.937],
        ["Rogalski", 74.938],
        ["Ridout", 74.938],
        ["Rhymer", 74.939],
        ["Replogle", 74.94],
        ["Regina", 74.94],
        ["Reda", 74.941],
        ["Raygoza", 74.942],
        ["Ratner", 74.942],
        ["Rascoe", 74.943],
        ["Rahm", 74.944],
        ["Quincy", 74.945],
        ["Quast", 74.945],
        ["Pry", 74.946],
        ["Pressnell", 74.947],
        ["Predmore", 74.947],
        ["Pou", 74.948],
        ["Porto", 74.949],
        ["Pleasants", 74.949],
        ["Pigford", 74.95],
        ["Pavone", 74.951],
        ["Patnaude", 74.951],
        ["Parramore", 74.952],
        ["Papadopoulos", 74.953],
        ["Palmatier", 74.953],
        ["Ouzts", 74.954],
        ["Oshields", 74.955],
        ["Ortis", 74.955],
        ["Olmeda", 74.956],
        ["Olden", 74.957],
        ["Okamoto", 74.958],
        ["Norby", 74.958],
        ["Nitz", 74.959],
        ["Niebuhr", 74.96],
        ["Nevius", 74.96],
        ["Neiman", 74.961],
        ["Neidig", 74.962],
        ["Neece", 74.962],
        ["Murawski", 74.963],
        ["Mroz", 74.964],
        ["Moylan", 74.964],
        ["Moultry", 74.965],
        ["Mosteller", 74.966],
        ["Moring", 74.966],
        ["Morganti", 74.967],
        ["Mook", 74.968],
        ["Moffet", 74.968],
        ["Mettler", 74.969],
        ["Merlo", 74.97],
        ["Mengel", 74.971],
        ["Mendelsohn", 74.971],
        ["Meli", 74.972],
        ["Melchior", 74.973],
        ["McMeans", 74.973],
        ["McFaddin", 74.974],
        ["McCullers", 74.975],
        ["McCollister", 74.975],
        ["McCloy", 74.976],
        ["McClaine", 74.977],
        ["Maury", 74.977],
        ["Maser", 74.978],
        ["Martelli", 74.979],
        ["Manthey", 74.979],
        ["Malkin", 74.98],
        ["Maio", 74.981],
        ["Magwood", 74.981],
        ["Maginnis", 74.982],
        ["Mabon", 74.983],
        ["Luton", 74.983],
        ["Lusher", 74.984],
        ["Lucht", 74.985],
        ["Lobato", 74.986],
        ["Levis", 74.986],
        ["Letellier", 74.987],
        ["Legendre", 74.988],
        ["Laurel", 74.988],
        ["Latson", 74.989],
        ["Larmon", 74.99],
        ["Largo", 74.99],
        ["Landreneau", 74.991],
        ["Landgraf", 74.992],
        ["Lamberson", 74.992],
        ["Kurland", 74.993],
        ["Kresge", 74.994],
        ["Korman", 74.994],
        ["Korando", 74.995],
        ["Klapper", 74.996],
        ["Kitson", 74.996],
        ["Kinyon", 74.997],
        ["Kincheloe", 74.998],
        ["Kawamoto", 74.999],
        ["Kawakami", 74.999],
        ["Jenney", 75],
        ["Jeanpierre", 75.001],
        ["Ivers", 75.001],
        ["Issa", 75.002],
        ["Ince", 75.003],
        ["Hugh", 75.003],
        ["Hug", 75.004],
        ["Honda", 75.005],
        ["Hollier", 75.005],
        ["Hollars", 75.006],
        ["Hoerner", 75.007],
        ["Hodgkinson", 75.007],
        ["Hiott", 75.008],
        ["Hibbitts", 75.009],
        ["Herlihy", 75.009],
        ["Henricks", 75.01],
        ["Heavner", 75.011],
        ["Hayhurst", 75.012],
        ["Harvill", 75.012],
        ["Harewood", 75.013],
        ["Hanselman", 75.014],
        ["Hanning", 75.014],
        ["Gwyn", 75.015],
        ["Gustavson", 75.016],
        ["Grounds", 75.016],
        ["Grizzard", 75.017],
        ["Grinder", 75.018],
        ["Graybeal", 75.018],
        ["Gravley", 75.019],
        ["Gorney", 75.02],
        ["Goll", 75.02],
        ["Goehring", 75.021],
        ["Godines", 75.022],
        ["Gobeil", 75.022],
        ["Glickman", 75.023],
        ["Giuliano", 75.024],
        ["Gimbel", 75.025],
        ["Gift", 75.025],
        ["Geib", 75.026],
        ["Gayhart", 75.027],
        ["Gatti", 75.027],
        ["Gains", 75.028],
        ["Gadberry", 75.029],
        ["Frei", 75.029],
        ["Fraise", 75.03],
        ["Fouch", 75.031],
        ["Forst", 75.031],
        ["Forsman", 75.032],
        ["Folden", 75.033],
        ["Fogleman", 75.033],
        ["Figaro", 75.034],
        ["Fetty", 75.035],
        ["Feely", 75.035],
        ["Fabry", 75.036],
        ["Eury", 75.037],
        ["Estill", 75.037],
        ["Epling", 75.038],
        ["Elamin", 75.039],
        ["Echavarria", 75.04],
        ["Dutil", 75.04],
        ["Duryea", 75.041],
        ["Dumais", 75.042],
        ["Drago", 75.042],
        ["Downard", 75.043],
        ["Douthit", 75.044],
        ["Doolin", 75.044],
        ["Dobos", 75.045],
        ["Dison", 75.046],
        ["Dinges", 75.046],
        ["Diebold", 75.047],
        ["Desilets", 75.048],
        ["Deshazo", 75.048],
        ["Depaz", 75.049],
        ["Degennaro", 75.05],
        ["Dall", 75.05],
        ["Cyphers", 75.051],
        ["Cryer", 75.052],
        ["Croce", 75.053],
        ["Crisman", 75.053],
        ["Credle", 75.054],
        ["Coriell", 75.055],
        ["Copp", 75.055],
        ["Coop", 75.056],
        ["Compos", 75.057],
        ["Colmenero", 75.057],
        ["Cogar", 75.058],
        ["Cliff", 75.059],
        ["Chapel", 75.059],
        ["Carnevale", 75.06],
        ["Campanella", 75.061],
        ["Caley", 75.061],
        ["Calderone", 75.062],
        ["Burtch", 75.063],
        ["Brouwer", 75.063],
        ["Brehmer", 75.064],
        ["Brassell", 75.065],
        ["Brafford", 75.066],
        ["Bourquin", 75.066],
        ["Bourn", 75.067],
        ["Bohnert", 75.068],
        ["Blewett", 75.068],
        ["Blass", 75.069],
        ["Blakes", 75.07],
        ["Bhakta", 75.07],
        ["Besser", 75.071],
        ["Berge", 75.072],
        ["Bellis", 75.072],
        ["Balfour", 75.073],
        ["Avera", 75.074],
        ["Austria", 75.074],
        ["Applin", 75.075],
        ["Ammon", 75.076],
        ["Alsop", 75.076],
        ["Aleshire", 75.077],
        ["Akbar", 75.078],
        ["Zoller", 75.078],
        ["Zapien", 75.079],
        ["Wymore", 75.08],
        ["Wyble", 75.081],
        ["Wolken", 75.081],
        ["Wix", 75.082],
        ["Wickstrom", 75.083],
        ["Whobrey", 75.083],
        ["Whigham", 75.084],
        ["Westerlund", 75.085],
        ["Welsch", 75.085],
        ["Weisser", 75.086],
        ["Weisner", 75.087],
        ["Weinstock", 75.087],
        ["Wehner", 75.088],
        ["Watlington", 75.089],
        ["Wakeland", 75.089],
        ["Wafer", 75.09],
        ["Virgen", 75.091],
        ["Victorino", 75.091],
        ["Veltri", 75.092],
        ["Veith", 75.093],
        ["Urich", 75.093],
        ["Uresti", 75.094],
        ["Umberger", 75.095],
        ["Twedt", 75.095],
        ["Tuohy", 75.096],
        ["Tschida", 75.097],
        ["Trumble", 75.097],
        ["Troia", 75.098],
        ["Tristan", 75.099],
        ["Trimmer", 75.099],
        ["Topps", 75.1],
        ["Tonn", 75.101],
        ["Tiernan", 75.101],
        ["Threet", 75.102],
        ["Thrall", 75.103],
        ["Thetford", 75.103],
        ["Teneyck", 75.104],
        ["Tartaglia", 75.105],
        ["Swords", 75.105],
        ["Strohl", 75.106],
        ["Streater", 75.107],
        ["Strausbaugh", 75.107],
        ["Stradley", 75.108],
        ["Stonecipher", 75.109],
        ["Steadham", 75.109],
        ["Stansel", 75.11],
        ["Stalcup", 75.111],
        ["Stabile", 75.111],
        ["Sprenger", 75.112],
        ["Spradley", 75.113],
        ["Speier", 75.113],
        ["Southwood", 75.114],
        ["Sorrels", 75.115],
        ["Slezak", 75.115],
        ["Skow", 75.116],
        ["Sirmans", 75.117],
        ["Simental", 75.117],
        ["Silk", 75.118],
        ["Sifford", 75.119],
        ["Sievert", 75.119],
        ["Shover", 75.12],
        ["Sheley", 75.121],
        ["Selzer", 75.121],
        ["Scriven", 75.122],
        ["Schwindt", 75.123],
        ["Schwan", 75.123],
        ["Schroth", 75.124],
        ["Saylors", 75.125],
        ["Saragosa", 75.125],
        ["Sant", 75.126],
        ["Salaam", 75.127],
        ["Saephan", 75.127],
        ["Routt", 75.128],
        ["Rousey", 75.129],
        ["Ros", 75.129],
        ["Rolfes", 75.13],
        ["Rieke", 75.131],
        ["Rieder", 75.131],
        ["Richeson", 75.132],
        ["Redinger", 75.133],
        ["Rasnick", 75.133],
        ["Rapoza", 75.134],
        ["Rambert", 75.135],
        ["Rafael", 75.135],
        ["Quist", 75.136],
        ["Pyron", 75.137],
        ["Punch", 75.137],
        ["Pullman", 75.138],
        ["Przybylski", 75.139],
        ["Pridmore", 75.139],
        ["Pooley", 75.14],
        ["Pines", 75.141],
        ["Perkinson", 75.141],
        ["Perine", 75.142],
        ["Perham", 75.143],
        ["Pecor", 75.143],
        ["Peavler", 75.144],
        ["Partington", 75.145],
        ["Panton", 75.145],
        ["Oliverio", 75.146],
        ["Olague", 75.147],
        ["Ohman", 75.147],
        ["Ohearn", 75.148],
        ["Noyola", 75.149],
        ["Nicolai", 75.149],
        ["Nebel", 75.15],
        ["Murtha", 75.151],
        ["Muff", 75.151],
        ["Mowrey", 75.152],
        ["Moroney", 75.153],
        ["Morgenstern", 75.153],
        ["Morant", 75.154],
        ["Monty", 75.155],
        ["Monsour", 75.155],
        ["Mohammad", 75.156],
        ["Moffit", 75.157],
        ["Mijares", 75.157],
        ["Meriwether", 75.158],
        ["Mendieta", 75.159],
        ["Melendrez", 75.159],
        ["Mejorado", 75.16],
        ["McKittrick", 75.161],
        ["McKey", 75.161],
        ["McKenny", 75.162],
        ["McKelvy", 75.163],
        ["McKechnie", 75.163],
        ["McElvain", 75.164],
        ["McCoin", 75.165],
        ["Mazzarella", 75.165],
        ["Mazon", 75.166],
        ["Maurin", 75.167],
        ["Matthies", 75.167],
        ["Maston", 75.168],
        ["Maske", 75.169],
        ["Marzano", 75.169],
        ["Marmon", 75.17],
        ["Marburger", 75.171],
        ["Mangus", 75.171],
        ["Mangino", 75.172],
        ["Mallet", 75.173],
        ["Luo", 75.173],
        ["Losada", 75.174],
        ["Londono", 75.175],
        ["Lobdell", 75.175],
        ["Lipson", 75.176],
        ["Lesniak", 75.177],
        ["Leighty", 75.177],
        ["Lei", 75.178],
        ["League", 75.179],
        ["Lavallie", 75.179],
        ["Lareau", 75.18],
        ["Laperle", 75.181],
        ["Lape", 75.181],
        ["Laforce", 75.182],
        ["Laffey", 75.183],
        ["Kuehner", 75.183],
        ["Kravitz", 75.184],
        ["Kowalsky", 75.185],
        ["Kohr", 75.185],
        ["Kinsman", 75.186],
        ["Keppler", 75.187],
        ["Kennemer", 75.187],
        ["Keiper", 75.188],
        ["Keely", 75.189],
        ["Kaler", 75.189],
        ["Jun", 75.19],
        ["Jelinek", 75.191],
        ["Jarnagin", 75.191],
        ["Issac", 75.192],
        ["Isakson", 75.193],
        ["Hypes", 75.193],
        ["Hutzler", 75.194],
        ["Huls", 75.195],
        ["Horak", 75.195],
        ["Hitz", 75.196],
        ["Hice", 75.197],
        ["Herrell", 75.197],
        ["Henslee", 75.198],
        ["Heitz", 75.199],
        ["Heiss", 75.199],
        ["Heiman", 75.2],
        ["Hasting", 75.201],
        ["Hartwick", 75.201],
        ["Harmer", 75.202],
        ["Harland", 75.203],
        ["Hammontree", 75.203],
        ["Haldeman", 75.204],
        ["Hakes", 75.205],
        ["Guse", 75.205],
        ["Guillotte", 75.206],
        ["Guard", 75.207],
        ["Groleau", 75.207],
        ["Greve", 75.208],
        ["Greenough", 75.209],
        ["Golub", 75.209],
        ["Golson", 75.21],
        ["Goldschmidt", 75.211],
        ["Golder", 75.211],
        ["Godbolt", 75.212],
        ["Gilmartin", 75.213],
        ["Gies", 75.213],
        ["Gibby", 75.214],
        ["Geren", 75.215],
        ["Genthner", 75.215],
        ["Gendreau", 75.216],
        ["Gemmill", 75.217],
        ["Gaymon", 75.217],
        ["Galyean", 75.218],
        ["Galeano", 75.219],
        ["Friar", 75.219],
        ["Folkerts", 75.22],
        ["Fleeman", 75.221],
        ["Fitzgibbons", 75.221],
        ["Ferranti", 75.222],
        ["Felan", 75.223],
        ["Farrand", 75.223],
        ["Eoff", 75.224],
        ["Enger", 75.225],
        ["Engels", 75.225],
        ["Ducksworth", 75.226],
        ["Duby", 75.227],
        ["Dry", 75.227],
        ["Drumheller", 75.228],
        ["Douthitt", 75.229],
        ["Doris", 75.229],
        ["Donis", 75.23],
        ["Dixion", 75.231],
        ["Dittrich", 75.231],
        ["Dials", 75.232],
        ["Dessert", 75.233],
        ["Descoteaux", 75.233],
        ["Depaul", 75.234],
        ["Denker", 75.235],
        ["Demuth", 75.235],
        ["Demelo", 75.236],
        ["Delacerda", 75.237],
        ["Deforge", 75.237],
        ["Danos", 75.238],
        ["Dalley", 75.239],
        ["Daigneault", 75.239],
        ["Cybulski", 75.24],
        ["Crystal", 75.241],
        ["Cristobal", 75.241],
        ["Cothren", 75.242],
        ["Corns", 75.243],
        ["Corkery", 75.243],
        ["Copas", 75.244],
        ["Coco", 75.245],
        ["Clubb", 75.245],
        ["Clore", 75.246],
        ["Chitty", 75.247],
        ["Chichester", 75.247],
        ["Chery", 75.248],
        ["Charon", 75.249],
        ["Chamber", 75.249],
        ["Chace", 75.25],
        ["Catanzaro", 75.251],
        ["Castonguay", 75.251],
        ["Cassella", 75.252],
        ["Caroll", 75.253],
        ["Carlberg", 75.253],
        ["Cammarata", 75.254],
        ["Calle", 75.255],
        ["Cajigas", 75.255],
        ["Byas", 75.256],
        ["Buzbee", 75.257],
        ["Busey", 75.257],
        ["Burling", 75.258],
        ["Bufkin", 75.259],
        ["Brzezinski", 75.259],
        ["Brun", 75.26],
        ["Brickner", 75.261],
        ["Brabham", 75.261],
        ["Boller", 75.262],
        ["Bodily", 75.263],
        ["Bockman", 75.263],
        ["Bleich", 75.264],
        ["Blakeman", 75.265],
        ["Bisbee", 75.265],
        ["Bier", 75.266],
        ["Bezanson", 75.267],
        ["Bevilacqua", 75.267],
        ["Besaw", 75.268],
        ["Berrian", 75.269],
        ["Berkeley", 75.269],
        ["Bequette", 75.27],
        ["Beauford", 75.271],
        ["Baumgarten", 75.271],
        ["Baudoin", 75.272],
        ["Batie", 75.273],
        ["Basaldua", 75.273],
        ["Bardin", 75.274],
        ["Bangert", 75.275],
        ["Banes", 75.275],
        ["Backlund", 75.276],
        ["Avitia", 75.277],
        ["Artz", 75.277],
        ["Archey", 75.278],
        ["Apel", 75.279],
        ["Amico", 75.279],
        ["Alam", 75.28],
        ["Aden", 75.281],
        ["Zebrowski", 75.281],
        ["Yokota", 75.282],
        ["Wormley", 75.283],
        ["Wootton", 75.283],
        ["Woodie", 75.284],
        ["Womac", 75.285],
        ["Wiltz", 75.285],
        ["Wigington", 75.286],
        ["Whitehorn", 75.287],
        ["Whisman", 75.287],
        ["Weisgerber", 75.288],
        ["Weigle", 75.289],
        ["Weedman", 75.289],
        ["Watkin", 75.29],
        ["Wasilewski", 75.291],
        ["Wadlington", 75.291],
        ["Wadkins", 75.292],
        ["Viverette", 75.293],
        ["Vidaurri", 75.293],
        ["Vidales", 75.294],
        ["Vezina", 75.294],
        ["Van Leer", 75.295],
        ["Van Hoy", 75.296],
        ["Van Guilder", 75.296],
        ["Van Brunt", 75.297],
        ["Uy", 75.298],
        ["Updegraff", 75.298],
        ["Tylor", 75.299],
        ["Trinkle", 75.3],
        ["Touchette", 75.3],
        ["Tilson", 75.301],
        ["Tilman", 75.302],
        ["Tengan", 75.302],
        ["Tarkington", 75.303],
        ["Surrett", 75.304],
        ["Super", 75.304],
        ["Summy", 75.305],
        ["Streetman", 75.306],
        ["Straughter", 75.306],
        ["Steere", 75.307],
        ["Stalling", 75.308],
        ["Spruell", 75.308],
        ["Spadaro", 75.309],
        ["Solley", 75.309],
        ["Smathers", 75.31],
        ["Silvera", 75.311],
        ["Siems", 75.311],
        ["Shreffler", 75.312],
        ["Sholar", 75.313],
        ["Selden", 75.313],
        ["Schaper", 75.314],
        ["Samayoa", 75.315],
        ["Ruggeri", 75.315],
        ["Rowen", 75.316],
        ["Rosso", 75.317],
        ["Rosenbalm", 75.317],
        ["Roosevelt", 75.318],
        ["Roose", 75.319],
        ["Ronquillo", 75.319],
        ["Rogowski", 75.32],
        ["Rexford", 75.321],
        ["Repass", 75.321],
        ["Renzi", 75.322],
        ["Renick", 75.323],
        ["Renda", 75.323],
        ["Rehberg", 75.324],
        ["Reaper", 75.324],
        ["Ranck", 75.325],
        ["Raffa", 75.326],
        ["Rackers", 75.326],
        ["Raap", 75.327],
        ["Pugsley", 75.328],
        ["Puglisi", 75.328],
        ["Prinz", 75.329],
        ["Primus", 75.33],
        ["Pounders", 75.33],
        ["Pon", 75.331],
        ["Pompa", 75.332],
        ["Plasencia", 75.332],
        ["Pipkins", 75.333],
        ["Pillar", 75.334],
        ["Petrosky", 75.334],
        ["Pelley", 75.335],
        ["Pauls", 75.336],
        ["Pauli", 75.336],
        ["Parkison", 75.337],
        ["Parisien", 75.338],
        ["Pangle", 75.338],
        ["Pancoast", 75.339],
        ["Palazzolo", 75.339],
        ["Owenby", 75.34],
        ["Overbay", 75.341],
        ["Orris", 75.341],
        ["Orlowski", 75.342],
        ["Nipp", 75.343],
        ["Newbern", 75.343],
        ["Nedd", 75.344],
        ["Nealon", 75.345],
        ["Najar", 75.345],
        ["Mysliwiec", 75.346],
        ["Myron", 75.347],
        ["Myres", 75.347],
        ["Musson", 75.348],
        ["Murrieta", 75.349],
        ["Munsell", 75.349],
        ["Mumma", 75.35],
        ["Muldowney", 75.351],
        ["Moyle", 75.351],
        ["Mowen", 75.352],
        ["Mose", 75.353],
        ["Morejon", 75.353],
        ["Moodie", 75.354],
        ["Monier", 75.354],
        ["Mikkelsen", 75.355],
        ["Miers", 75.356],
        ["Metzinger", 75.356],
        ["Melin", 75.357],
        ["McQuay", 75.358],
        ["McPeek", 75.358],
        ["McNeeley", 75.359],
        ["McGlothin", 75.36],
        ["McGhie", 75.36],
        ["McDonell", 75.361],
        ["McCumber", 75.362],
        ["McCranie", 75.362],
        ["McBean", 75.363],
        ["Mayhugh", 75.364],
        ["Marts", 75.364],
        ["Marenco", 75.365],
        ["Manges", 75.366],
        ["Lynam", 75.366],
        ["Lupien", 75.367],
        ["Luff", 75.368],
        ["Luebbert", 75.368],
        ["Loh", 75.369],
        ["Loflin", 75.369],
        ["Lococo", 75.37],
        ["Loch", 75.371],
        ["Lis", 75.371],
        ["Linke", 75.372],
        ["Lightle", 75.373],
        ["Lewellyn", 75.373],
        ["Leishman", 75.374],
        ["Lebow", 75.375],
        ["Lebouef", 75.375],
        ["Leanos", 75.376],
        ["Lanz", 75.377],
        ["Landy", 75.377],
        ["Landaverde", 75.378],
        ["Lacefield", 75.379],
        ["Kyler", 75.379],
        ["Kuebler", 75.38],
        ["Kropf", 75.381],
        ["Kroeker", 75.381],
        ["Kluesner", 75.382],
        ["Klass", 75.382],
        ["Kimberling", 75.383],
        ["Kilkenny", 75.384],
        ["Kiker", 75.384],
        ["Ketter", 75.385],
        ["Kelemen", 75.386],
        ["Keasler", 75.386],
        ["Kawamura", 75.387],
        ["Karst", 75.388],
        ["Kardos", 75.388],
        ["Jeremiah", 75.389],
        ["Jared", 75.39],
        ["Igo", 75.39],
        ["Huseman", 75.391],
        ["Huseby", 75.392],
        ["Hurlbert", 75.392],
        ["Huard", 75.393],
        ["Hottinger", 75.394],
        ["Hornberger", 75.394],
        ["Hopps", 75.395],
        ["Holdsworth", 75.396],
        ["Hensen", 75.396],
        ["Heilig", 75.397],
        ["Heeter", 75.397],
        ["Harpole", 75.398],
        ["Haak", 75.399],
        ["Gutowski", 75.399],
        ["Gunnels", 75.4],
        ["Grimmer", 75.401],
        ["Grieve", 75.401],
        ["Gravatt", 75.402],
        ["Granderson", 75.403],
        ["Gotcher", 75.403],
        ["Gleaves", 75.404],
        ["Genao", 75.405],
        ["Garfinkel", 75.405],
        ["Frerichs", 75.406],
        ["Foushee", 75.407],
        ["Flanery", 75.407],
        ["Finnie", 75.408],
        ["Feldt", 75.409],
        ["Fagin", 75.409],
        ["Ewalt", 75.41],
        ["Ellefson", 75.411],
        ["Eiler", 75.411],
        ["Eckhart", 75.412],
        ["Eastep", 75.412],
        ["Dwight", 75.413],
        ["Digirolamo", 75.414],
        ["Didomenico", 75.414],
        ["Devera", 75.415],
        ["Delavega", 75.416],
        ["Defilippo", 75.416],
        ["Debusk", 75.417],
        ["Daub", 75.418],
        ["Damiani", 75.418],
        ["Cupples", 75.419],
        ["Cuddy", 75.42],
        ["Crofoot", 75.42],
        ["Courter", 75.421],
        ["Coto", 75.422],
        ["Costigan", 75.422],
        ["Corning", 75.423],
        ["Corman", 75.424],
        ["Corlett", 75.424],
        ["Cooperman", 75.425],
        ["Collison", 75.426],
        ["Coghlan", 75.426],
        ["Cobbins", 75.427],
        ["Coady", 75.427],
        ["Coachman", 75.428],
        ["Clothier", 75.429],
        ["Client", 75.429],
        ["Clear", 75.43],
        ["Cipolla", 75.431],
        ["Chmielewski", 75.431],
        ["Chiodo", 75.432],
        ["Chatterton", 75.433],
        ["Chappelle", 75.433],
        ["Chairez", 75.434],
        ["Ceron", 75.435],
        ["Casperson", 75.435],
        ["Casler", 75.436],
        ["Casados", 75.437],
        ["Carrow", 75.437],
        ["Carolina", 75.438],
        ["Carlino", 75.439],
        ["Carico", 75.439],
        ["Cardillo", 75.44],
        ["Caouette", 75.441],
        ["Canto", 75.441],
        ["Canavan", 75.442],
        ["Cambra", 75.442],
        ["Byard", 75.443],
        ["Buterbaugh", 75.444],
        ["Buse", 75.444],
        ["Bucy", 75.445],
        ["Buckwalter", 75.446],
        ["Bubb", 75.446],
        ["Bryd", 75.447],
        ["Brissette", 75.448],
        ["Brault", 75.448],
        ["Bradwell", 75.449],
        ["Boshears", 75.45],
        ["Borchert", 75.45],
        ["Blansett", 75.451],
        ["Blanch", 75.452],
        ["Blade", 75.452],
        ["Biondo", 75.453],
        ["Bilbo", 75.454],
        ["Biehl", 75.454],
        ["Bessey", 75.455],
        ["Berta", 75.455],
        ["Belles", 75.456],
        ["Bella", 75.457],
        ["Beeks", 75.457],
        ["Beekman", 75.458],
        ["Beaufort", 75.459],
        ["Bayliss", 75.459],
        ["Bardsley", 75.46],
        ["Avilla", 75.461],
        ["Astudillo", 75.461],
        ["Ardito", 75.462],
        ["Anwar", 75.463],
        ["Antunez", 75.463],
        ["Amen", 75.464],
        ["Aderholt", 75.465],
        ["Abate", 75.465],
        ["Yowell", 75.466],
        ["Yin", 75.467],
        ["Yearby", 75.467],
        ["Ye", 75.468],
        ["Wurst", 75.468],
        ["Woolverton", 75.469],
        ["Woolbright", 75.47],
        ["Wildermuth", 75.47],
        ["Whittenburg", 75.471],
        ["Whitely", 75.472],
        ["Wetter", 75.472],
        ["Wetherbee", 75.473],
        ["Wenz", 75.474],
        ["Welliver", 75.474],
        ["Welling", 75.475],
        ["Welcome", 75.475],
        ["Wason", 75.476],
        ["Warrior", 75.477],
        ["Warlick", 75.477],
        ["Voorhies", 75.478],
        ["Vivier", 75.479],
        ["Villines", 75.479],
        ["Vida", 75.48],
        ["Verde", 75.481],
        ["Veiga", 75.481],
        ["Varghese", 75.482],
        ["Van Wyk", 75.482],
        ["Van Wingerden", 75.483],
        ["Van Horne", 75.484],
        ["Umstead", 75.484],
        ["Twiggs", 75.485],
        ["Tusing", 75.486],
        ["Trego", 75.486],
        ["Tompson", 75.487],
        ["Tinkle", 75.488],
        ["Thoman", 75.488],
        ["Thole", 75.489],
        ["Tatman", 75.489],
        ["Tartt", 75.49],
        ["Suda", 75.491],
        ["Studley", 75.491],
        ["Strock", 75.492],
        ["Strawbridge", 75.493],
        ["Stokely", 75.493],
        ["Stec", 75.494],
        ["Stang", 75.495],
        ["Stalter", 75.495],
        ["Speidel", 75.496],
        ["Spafford", 75.496],
        ["Spade", 75.497],
        ["Sontag", 75.498],
        ["Sokolowski", 75.498],
        ["Skillman", 75.499],
        ["Skelley", 75.5],
        ["Skalski", 75.5],
        ["Sison", 75.501],
        ["Sippel", 75.502],
        ["Sinquefield", 75.502],
        ["Sin", 75.503],
        ["Siegle", 75.503],
        ["Sher", 75.504],
        ["Sharrow", 75.505],
        ["Setliff", 75.505],
        ["Sera", 75.506],
        ["Sellner", 75.507],
        ["Selig", 75.507],
        ["Seibold", 75.508],
        ["Seery", 75.509],
        ["Scriber", 75.509],
        ["Schull", 75.51],
        ["Schrupp", 75.51],
        ["Schippers", 75.511],
        ["Say", 75.512],
        ["Saulsbury", 75.512],
        ["Sao", 75.513],
        ["Santillo", 75.514],
        ["Sanor", 75.514],
        ["Sancho", 75.515],
        ["Rufus", 75.516],
        ["Rubalcaba", 75.516],
        ["Roosa", 75.517],
        ["Ronk", 75.517],
        ["Robbs", 75.518],
        ["Roache", 75.519],
        ["River", 75.519],
        ["Riebe", 75.52],
        ["Reinoso", 75.521],
        ["Quin", 75.521],
        ["Prude", 75.522],
        ["Preuss", 75.523],
        ["Pottorff", 75.523],
        ["Pontiff", 75.524],
        ["Plouffe", 75.524],
        ["Picou", 75.525],
        ["Picklesimer", 75.526],
        ["Pettyjohn", 75.526],
        ["Petti", 75.527],
        ["Penaloza", 75.528],
        ["Parmelee", 75.528],
        ["Pardee", 75.529],
        ["Palazzo", 75.529],
        ["Overholt", 75.53],
        ["Ogawa", 75.531],
        ["Ofarrell", 75.531],
        ["Nova", 75.532],
        ["Nolting", 75.533],
        ["Noda", 75.533],
        ["Nicola", 75.534],
        ["Nickson", 75.535],
        ["Nevitt", 75.535],
        ["Neveu", 75.536],
        ["Navarre", 75.536],
        ["Nam", 75.537],
        ["Murrow", 75.538],
        ["Munz", 75.538],
        ["Mulloy", 75.539],
        ["Monzo", 75.54],
        ["Milliman", 75.54],
        ["Metivier", 75.541],
        ["Merlino", 75.542],
        ["McPeters", 75.542],
        ["McKissack", 75.543],
        ["McKeen", 75.543],
        ["McGurk", 75.544],
        ["McFee", 75.545],
        ["McFarren", 75.545],
        ["McElwee", 75.546],
        ["McEachin", 75.547],
        ["McDonagh", 75.547],
        ["McCarville", 75.548],
        ["Mayhall", 75.549],
        ["Mattoon", 75.549],
        ["Martello", 75.55],
        ["Marconi", 75.55],
        ["Marbury", 75.551],
        ["Mao", 75.552],
        ["Manzella", 75.552],
        ["Maly", 75.553],
        ["Malec", 75.554],
        ["Maitland", 75.554],
        ["Maheu", 75.555],
        ["MacLennan", 75.556],
        ["Lyke", 75.556],
        ["Luera", 75.557],
        ["Loyola", 75.557],
        ["Lowenstein", 75.558],
        ["Losh", 75.559],
        ["Lopiccolo", 75.559],
        ["Longacre", 75.56],
        ["Loman", 75.561],
        ["Loden", 75.561],
        ["Loaiza", 75.562],
        ["Lieber", 75.563],
        ["Libbey", 75.563],
        ["Lenhardt", 75.564],
        ["Lefebre", 75.564],
        ["Lauterbach", 75.565],
        ["Lauritsen", 75.566],
        ["Lass", 75.566],
        ["Larocco", 75.567],
        ["Larimer", 75.568],
        ["Lansford", 75.568],
        ["Lanclos", 75.569],
        ["Lamay", 75.57],
        ["Lal", 75.57],
        ["Kulikowski", 75.571],
        ["Kriebel", 75.571],
        ["Kosinski", 75.572],
        ["Kleinman", 75.573],
        ["Kleiner", 75.573],
        ["Kleckner", 75.574],
        ["Kistner", 75.575],
        ["Kissner", 75.575],
        ["Kissell", 75.576],
        ["Kilroy", 75.577],
        ["Kenna", 75.577],
        ["Keisler", 75.578],
        ["Keeble", 75.578],
        ["Keaney", 75.579],
        ["Kale", 75.58],
        ["Joly", 75.58],
        ["Jimison", 75.581],
        ["Jeans", 75.582],
        ["Ikner", 75.582],
        ["Hursey", 75.583],
        ["Hruska", 75.584],
        ["Hove", 75.584],
        ["Hou", 75.585],
        ["Host", 75.585],
        ["Hosking", 75.586],
        ["Hoose", 75.587],
        ["Holle", 75.587],
        ["Hoeppner", 75.588],
        ["Hittle", 75.589],
        ["Hitchens", 75.589],
        ["Hirth", 75.59],
        ["Hinerman", 75.591],
        ["Hilario", 75.591],
        ["Higby", 75.592],
        ["Hertzog", 75.592],
        ["Hentz", 75.593],
        ["Hensler", 75.594],
        ["Heist", 75.594],
        ["Heier", 75.595],
        ["Hegg", 75.596],
        ["Hassel", 75.596],
        ["Harpe", 75.597],
        ["Hara", 75.598],
        ["Hank", 75.598],
        ["Hain", 75.599],
        ["Hagopian", 75.599],
        ["Grimshaw", 75.6],
        ["Grado", 75.601],
        ["Gowin", 75.601],
        ["Gowans", 75.602],
        ["Googe", 75.603],
        ["Goodlow", 75.603],
        ["Goering", 75.604],
        ["Gleaton", 75.605],
        ["Gidley", 75.605],
        ["Giannone", 75.606],
        ["Gascon", 75.606],
        ["Garneau", 75.607],
        ["Gambrel", 75.608],
        ["Galaz", 75.608],
        ["Fuentez", 75.609],
        ["Frisina", 75.61],
        ["Fresquez", 75.61],
        ["Fraher", 75.611],
        ["Fitting", 75.612],
        ["Feuerstein", 75.612],
        ["Felten", 75.613],
        ["Everman", 75.613],
        ["Estell", 75.614],
        ["Ertel", 75.615],
        ["Erazo", 75.615],
        ["Ensign", 75.616],
        ["Endo", 75.617],
        ["Ellerman", 75.617],
        ["Eichorn", 75.618],
        ["Edgell", 75.619],
        ["Ebron", 75.619],
        ["Eaker", 75.62],
        ["Dundas", 75.62],
        ["Duncanson", 75.621],
        ["Duchene", 75.622],
        ["Ducan", 75.622],
        ["Dombroski", 75.623],
        ["Doman", 75.624],
        ["Dock", 75.624],
        ["Dickison", 75.625],
        ["Dewoody", 75.626],
        ["De Loera", 75.626],
        ["DeLahoussaye", 75.627],
        ["DeJean", 75.627],
        ["DeGroat", 75.628],
        ["Decaro", 75.629],
        ["Dearmond", 75.629],
        ["Dashner", 75.63],
        ["Dales", 75.631],
        ["Crossett", 75.631],
        ["Cressey", 75.632],
        ["Cowger", 75.633],
        ["Courts", 75.633],
        ["Court", 75.634],
        ["Cornette", 75.634],
        ["Corbo", 75.635],
        ["Coplin", 75.636],
        ["Coover", 75.636],
        ["Condie", 75.637],
        ["Cokley", 75.638],
        ["Cicero", 75.638],
        ["Ceaser", 75.639],
        ["Cannaday", 75.64],
        ["Callanan", 75.64],
        ["Cadle", 75.641],
        ["Buscher", 75.641],
        ["Bullion", 75.642],
        ["Bucklin", 75.643],
        ["Bruening", 75.643],
        ["Bruckner", 75.644],
        ["Brose", 75.645],
        ["Branan", 75.645],
        ["Bradway", 75.646],
        ["Botsford", 75.647],
        ["Bortz", 75.647],
        ["Borelli", 75.648],
        ["Bonetti", 75.648],
        ["Bolan", 75.649],
        ["Boerger", 75.65],
        ["Bloomberg", 75.65],
        ["Bingman", 75.651],
        ["Bilger", 75.652],
        ["Berns", 75.652],
        ["Beringer", 75.653],
        ["Beres", 75.653],
        ["Beets", 75.654],
        ["Beede", 75.655],
        ["Beaudet", 75.655],
        ["Beachum", 75.656],
        ["Baughn", 75.657],
        ["Bator", 75.657],
        ["Bastien", 75.658],
        ["Basquez", 75.659],
        ["Barreiro", 75.659],
        ["Barga", 75.66],
        ["Baratta", 75.66],
        ["Balser", 75.661],
        ["Baillie", 75.662],
        ["Axford", 75.662],
        ["Attebery", 75.663],
        ["Arakaki", 75.664],
        ["Annunziata", 75.664],
        ["Andrzejewski", 75.665],
        ["Ament", 75.666],
        ["Amendola", 75.666],
        ["Adcox", 75.667],
        ["Abril", 75.667],
        ["Zenon", 75.668],
        ["Zeitler", 75.669],
        ["Zang", 75.669],
        ["Zambrana", 75.67],
        ["Ybanez", 75.671],
        ["Yagi", 75.671],
        ["Wolak", 75.672],
        ["Wilcoxson", 75.672],
        ["Whitesel", 75.673],
        ["Whitehair", 75.674],
        ["Weyand", 75.674],
        ["Westendorf", 75.675],
        ["Welke", 75.676],
        ["Weinmann", 75.676],
        ["Wei", 75.677],
        ["Weesner", 75.677],
        ["Weekes", 75.678],
        ["Wedel", 75.679],
        ["Wedding", 75.679],
        ["Weatherall", 75.68],
        ["Warthen", 75.681],
        ["Vose", 75.681],
        ["Villalta", 75.682],
        ["Vila", 75.682],
        ["Viator", 75.683],
        ["Vaz", 75.684],
        ["Valtierra", 75.684],
        ["Urbanek", 75.685],
        ["Tulley", 75.685],
        ["Trojanowski", 75.686],
        ["Trapani", 75.687],
        ["Toups", 75.687],
        ["Torpey", 75.688],
        ["Tomita", 75.689],
        ["Tindal", 75.689],
        ["Tieman", 75.69],
        ["Tevis", 75.69],
        ["Tedrow", 75.691],
        ["Taul", 75.692],
        ["Tash", 75.692],
        ["Tammaro", 75.693],
        ["Sylva", 75.694],
        ["Swiderski", 75.694],
        ["Sweeting", 75.695],
        ["Sund", 75.695],
        ["Stutler", 75.696],
        ["Stocking", 75.697],
        ["Stich", 75.697],
        ["Sterns", 75.698],
        ["Stegner", 75.698],
        ["Stalder", 75.699],
        ["Splawn", 75.7],
        ["Speirs", 75.7],
        ["Southwell", 75.701],
        ["Soltys", 75.702],
        ["Smead", 75.702],
        ["Slye", 75.703],
        ["Skipworth", 75.703],
        ["Sipos", 75.704],
        ["Simmerman", 75.705],
        ["Sigmund", 75.705],
        ["Sidhu", 75.706],
        ["Shuffler", 75.707],
        ["Shingleton", 75.707],
        ["Shadwick", 75.708],
        ["Sermons", 75.708],
        ["Seefeldt", 75.709],
        ["Scipio", 75.71],
        ["Schwanke", 75.71],
        ["Schreffler", 75.711],
        ["Schiro", 75.712],
        ["Scheiber", 75.712],
        ["Sandoz", 75.713],
        ["Samsel", 75.713],
        ["Ruddell", 75.714],
        ["Royse", 75.715],
        ["Rouillard", 75.715],
        ["Rotella", 75.716],
        ["Rosalez", 75.716],
        ["Romriell", 75.717],
        ["Rommel", 75.718],
        ["Rizer", 75.718],
        ["Riner", 75.719],
        ["Rickards", 75.72],
        ["Rhoton", 75.72],
        ["Rhem", 75.721],
        ["Reppert", 75.721],
        ["Rayl", 75.722],
        ["Raulston", 75.723],
        ["Raposo", 75.723],
        ["Rapier", 75.724],
        ["Rainville", 75.725],
        ["Radel", 75.725],
        ["Quinney", 75.726],
        ["Purdie", 75.726],
        ["Puffer", 75.727],
        ["Pizzo", 75.728],
        ["Pincus", 75.728],
        ["Petrus", 75.729],
        ["Pendelton", 75.729],
        ["Pendarvis", 75.73],
        ["Peltz", 75.731],
        ["Peguero", 75.731],
        ["Peete", 75.732],
        ["Patricio", 75.733],
        ["Patchett", 75.733],
        ["Parrino", 75.734],
        ["Papke", 75.734],
        ["Pam", 75.735],
        ["Palafox", 75.736],
        ["Ottley", 75.736],
        ["Ostby", 75.737],
        ["Oritz", 75.738],
        ["Oren", 75.738],
        ["Ogan", 75.739],
        ["Odegaard", 75.739],
        ["Oatman", 75.74],
        ["Noell", 75.741],
        ["Nida", 75.741],
        ["Nicoll", 75.742],
        ["Newhall", 75.743],
        ["Newbill", 75.743],
        ["Netzer", 75.744],
        ["Nettleton", 75.744],
        ["Neblett", 75.745],
        ["Murley", 75.746],
        ["Mungo", 75.746],
        ["Mulhall", 75.747],
        ["Mosca", 75.747],
        ["Morissette", 75.748],
        ["Morford", 75.749],
        ["Montag", 75.749],
        ["Monsen", 75.75],
        ["Mitzel", 75.751],
        ["Miskell", 75.751],
        ["Minder", 75.752],
        ["Mehaffey", 75.752],
        ["McQuillen", 75.753],
        ["McLennan", 75.754],
        ["McGrail", 75.754],
        ["McCreight", 75.755],
        ["Mayville", 75.756],
        ["Maysonet", 75.756],
        ["Maust", 75.757],
        ["Mathieson", 75.757],
        ["Mastrangelo", 75.758],
        ["Maskell", 75.759],
        ["Martina", 75.759],
        ["Manz", 75.76],
        ["Malmberg", 75.76],
        ["Makela", 75.761],
        ["Madruga", 75.762],
        ["Luz", 75.762],
        ["Lotts", 75.763],
        ["Longnecker", 75.764],
        ["Logston", 75.764],
        ["Littell", 75.765],
        ["Liska", 75.765],
        ["Lindauer", 75.766],
        ["Lillibridge", 75.767],
        ["Levron", 75.767],
        ["Letchworth", 75.768],
        ["Lesh", 75.769],
        ["Leffel", 75.769],
        ["Leday", 75.77],
        ["Leamon", 75.77],
        ["Laura", 75.771],
        ["Kulas", 75.772],
        ["Kula", 75.772],
        ["Kucharski", 75.773],
        ["Kromer", 75.774],
        ["Kraatz", 75.774],
        ["Konieczny", 75.775],
        ["Konen", 75.775],
        ["Komar", 75.776],
        ["Kivett", 75.777],
        ["Kirts", 75.777],
        ["Kinnear", 75.778],
        ["Kersh", 75.778],
        ["Keithley", 75.779],
        ["Keifer", 75.78],
        ["Judah", 75.78],
        ["Jimenes", 75.781],
        ["Jeppesen", 75.782],
        ["Jasmin", 75.782],
        ["Jansson", 75.783],
        ["Huntsberry", 75.783],
        ["Hund", 75.784],
        ["Huitt", 75.785],
        ["Huffine", 75.785],
        ["Hosford", 75.786],
        ["Hopes", 75.787],
        ["Holmstrom", 75.787],
        ["Hollen", 75.788],
        ["Hodgin", 75.788],
        ["Hirschman", 75.789],
        ["Hiltner", 75.79],
        ["Hilliker", 75.79],
        ["Hibner", 75.791],
        ["Hennis", 75.791],
        ["Helt", 75.792],
        ["Heidelberg", 75.793],
        ["Heger", 75.793],
        ["Heer", 75.794],
        ["Hartness", 75.795],
        ["Hardrick", 75.795],
        ["Halladay", 75.796],
        ["Gula", 75.796],
        ["Guillaume", 75.797],
        ["Guerriero", 75.798],
        ["Grunewald", 75.798],
        ["Grosse", 75.799],
        ["Griffeth", 75.8],
        ["Grenz", 75.8],
        ["Grassi", 75.801],
        ["Grandison", 75.801],
        ["Ginther", 75.802],
        ["Gimenez", 75.803],
        ["Gillingham", 75.803],
        ["Gillham", 75.804],
        ["Gess", 75.805],
        ["Gelman", 75.805],
        ["Gearheart", 75.806],
        ["Gaskell", 75.806],
        ["Gariepy", 75.807],
        ["Gamino", 75.808],
        ["Gallien", 75.808],
        ["Galentine", 75.809],
        ["Fuquay", 75.809],
        ["Froman", 75.81],
        ["Froelich", 75.811],
        ["Friedel", 75.811],
        ["Foos", 75.812],
        ["Fomby", 75.813],
        ["Focht", 75.813],
        ["Flythe", 75.814],
        ["Fiqueroa", 75.814],
        ["Filson", 75.815],
        ["Filip", 75.816],
        ["Fierros", 75.816],
        ["Fett", 75.817],
        ["Fedele", 75.818],
        ["Fasching", 75.818],
        ["Farney", 75.819],
        ["Fargo", 75.819],
        ["Everts", 75.82],
        ["Even", 75.821],
        ["Etzel", 75.821],
        ["Elzey", 75.822],
        ["Eichner", 75.822],
        ["Eger", 75.823],
        ["Eatman", 75.824],
        ["Ducker", 75.824],
        ["Duchesne", 75.825],
        ["Donati", 75.826],
        ["Domenech", 75.826],
        ["Dollard", 75.827],
        ["Dodrill", 75.827],
        ["Dinapoli", 75.828],
        ["Denn", 75.829],
        ["Delfino", 75.829],
        ["Delcid", 75.83],
        ["Delaune", 75.831],
        ["Delatte", 75.831],
        ["Deems", 75.832],
        ["Daluz", 75.832],
        ["Cusson", 75.833],
        ["Cullison", 75.834],
        ["Cue", 75.834],
        ["Cuadrado", 75.835],
        ["Crumrine", 75.836],
        ["Cruickshank", 75.836],
        ["Crosland", 75.837],
        ["Croll", 75.837],
        ["Criddle", 75.838],
        ["Crepeau", 75.839],
        ["Coutu", 75.839],
        ["Couey", 75.84],
        ["Cort", 75.84],
        ["Coppinger", 75.841],
        ["Collman", 75.842],
        ["Cockburn", 75.842],
        ["Coca", 75.843],
        ["Clayborne", 75.844],
        ["Claflin", 75.844],
        ["Cissell", 75.845],
        ["Chowdhury", 75.845],
        ["Chicoine", 75.846],
        ["Chenier", 75.847],
        ["Causby", 75.847],
        ["Caulder", 75.848],
        ["Cassano", 75.849],
        ["Casner", 75.849],
        ["Cardiel", 75.85],
        ["Burner", 75.85],
        ["Brunton", 75.851],
        ["Bruch", 75.852],
        ["Broxton", 75.852],
        ["Brosius", 75.853],
        ["Brooking", 75.853],
        ["Branco", 75.854],
        ["Bracco", 75.855],
        ["Bourgault", 75.855],
        ["Bosserman", 75.856],
        ["Books", 75.857],
        ["Bonet", 75.857],
        ["Bolds", 75.858],
        ["Bolander", 75.858],
        ["Bohman", 75.859],
        ["Boelter", 75.86],
        ["Blohm", 75.86],
        ["Blea", 75.861],
        ["Blaise", 75.862],
        ["Bischof", 75.862],
        ["Billie", 75.863],
        ["Beus", 75.863],
        ["Bellew", 75.864],
        ["Bastarache", 75.865],
        ["Bast", 75.865],
        ["Bartolome", 75.866],
        ["Bark", 75.867],
        ["Barcomb", 75.867],
        ["Barco", 75.868],
        ["Balls", 75.868],
        ["Balk", 75.869],
        ["Balas", 75.87],
        ["Bakos", 75.87],
        ["Avey", 75.871],
        ["Atnip", 75.871],
        ["Ashbrook", 75.872],
        ["Arno", 75.873],
        ["Arbour", 75.873],
        ["Aquirre", 75.874],
        ["Appell", 75.875],
        ["Aldaco", 75.875],
        ["Alcazar", 75.876],
        ["Alban", 75.876],
        ["Ahlstrom", 75.877],
        ["Abadie", 75.878],
        ["Zylstra", 75.878],
        ["Zick", 75.879],
        ["Zheng", 75.879],
        ["Yother", 75.88],
        ["Wyse", 75.881],
        ["Wunsch", 75.881],
        ["Whitty", 75.882],
        ["Weist", 75.883],
        ["Vrooman", 75.883],
        ["Vine", 75.884],
        ["Villalon", 75.884],
        ["Vidrio", 75.885],
        ["Vavra", 75.886],
        ["Vasbinder", 75.886],
        ["Van Matre", 75.887],
        ["Van Dorn", 75.887],
        ["Ugarte", 75.888],
        ["Turberville", 75.889],
        ["Tuel", 75.889],
        ["Trogdon", 75.89],
        ["Town", 75.89],
        ["Toupin", 75.891],
        ["Toone", 75.892],
        ["Tolleson", 75.892],
        ["Tinkham", 75.893],
        ["Tinch", 75.893],
        ["Tiano", 75.894],
        ["Teston", 75.895],
        ["Teer", 75.895],
        ["Tea", 75.896],
        ["Tawney", 75.896],
        ["Taplin", 75.897],
        ["Tant", 75.898],
        ["Tansey", 75.898],
        ["Swayne", 75.899],
        ["Sutcliffe", 75.899],
        ["Sunderman", 75.9],
        ["Suits", 75.901],
        ["Strothers", 75.901],
        ["Stromain", 75.902],
        ["Stork", 75.902],
        ["Stoneburner", 75.903],
        ["Stolte", 75.904],
        ["Stolp", 75.904],
        ["Stoehr", 75.905],
        ["Stingley", 75.905],
        ["Stegman", 75.906],
        ["Stangl", 75.907],
        ["Spinella", 75.907],
        ["Spier", 75.908],
        ["Soules", 75.908],
        ["Sommerfield", 75.909],
        ["Sipp", 75.91],
        ["Simek", 75.91],
        ["Siders", 75.911],
        ["Shufelt", 75.912],
        ["Shue", 75.912],
        ["Shor", 75.913],
        ["Shires", 75.913],
        ["Shellenberger", 75.914],
        ["Sheely", 75.915],
        ["Service", 75.915],
        ["Sepe", 75.916],
        ["Seaberg", 75.916],
        ["Schwing", 75.917],
        ["Scherrer", 75.918],
        ["Scalzo", 75.918],
        ["Saver", 75.919],
        ["Sasse", 75.919],
        ["Sarvis", 75.92],
        ["Santora", 75.921],
        ["Sansbury", 75.921],
        ["Salls", 75.922],
        ["Saleem", 75.922],
        ["Ryland", 75.923],
        ["Rybicki", 75.924],
        ["Ruggieri", 75.924],
        ["Rothenberg", 75.925],
        ["Rosenstein", 75.925],
        ["Roquemore", 75.926],
        ["Rollison", 75.927],
        ["Rodden", 75.927],
        ["Rivet", 75.928],
        ["Rita", 75.928],
        ["Ridlon", 75.929],
        ["Riche", 75.93],
        ["Riccardi", 75.93],
        ["Reiley", 75.931],
        ["Regner", 75.931],
        ["Rech", 75.932],
        ["Rayo", 75.933],
        ["Rawley", 75.933],
        ["Ranger", 75.934],
        ["Raff", 75.934],
        ["Radabaugh", 75.935],
        ["Quon", 75.936],
        ["Quill", 75.936],
        ["Privette", 75.937],
        ["Prange", 75.937],
        ["Pickrell", 75.938],
        ["Perino", 75.939],
        ["Penning", 75.939],
        ["Pankratz", 75.94],
        ["Orlandi", 75.94],
        ["Nyquist", 75.941],
        ["Norrell", 75.942],
        ["Noren", 75.942],
        ["Naples", 75.943],
        ["Nale", 75.944],
        ["Nakashima", 75.944],
        ["Musselwhite", 75.945],
        ["Murrin", 75.945],
        ["Murch", 75.946],
        ["Mullinix", 75.947],
        ["Mullican", 75.947],
        ["Mullan", 75.948],
        ["Morneau", 75.948],
        ["Mondor", 75.949],
        ["Molinar", 75.95],
        ["Mo", 75.95],
        ["Minjares", 75.951],
        ["Minix", 75.951],
        ["Mingle", 75.952],
        ["Minchew", 75.953],
        ["Mill", 75.953],
        ["Milewski", 75.954],
        ["Mikkelson", 75.954],
        ["Mifflin", 75.955],
        ["Messing", 75.956],
        ["Merkley", 75.956],
        ["Meis", 75.957],
        ["Meas", 75.957],
        ["McRoy", 75.958],
        ["McPhearson", 75.959],
        ["McNeel", 75.959],
        ["McMunn", 75.96],
        ["McMorrow", 75.96],
        ["McDorman", 75.961],
        ["McCroskey", 75.962],
        ["McColl", 75.962],
        ["McClusky", 75.963],
        ["McClaran", 75.963],
        ["McCampbell", 75.964],
        ["Mazzariello", 75.965],
        ["Mauzy", 75.965],
        ["Mauch", 75.966],
        ["Mastro", 75.966],
        ["Martinek", 75.967],
        ["Marsala", 75.968],
        ["Marcantel", 75.968],
        ["Mahle", 75.969],
        ["Lyda", 75.969],
        ["Lucius", 75.97],
        ["Luciani", 75.971],
        ["Lubbers", 75.971],
        ["Louder", 75.972],
        ["Lobel", 75.973],
        ["Linsey", 75.973],
        ["Linch", 75.974],
        ["Liller", 75.974],
        ["Legros", 75.975],
        ["Layden", 75.976],
        ["Lapine", 75.976],
        ["Lansberry", 75.977],
        ["Lage", 75.977],
        ["Laforest", 75.978],
        ["Labriola", 75.979],
        ["Koga", 75.979],
        ["Knupp", 75.98],
        ["Klimek", 75.98],
        ["Kittinger", 75.981],
        ["Kirchoff", 75.982],
        ["Kinzel", 75.982],
        ["Killinger", 75.983],
        ["Kilbourne", 75.983],
        ["Ketner", 75.984],
        ["Kepley", 75.985],
        ["Kemble", 75.985],
        ["Kells", 75.986],
        ["Kear", 75.986],
        ["Kaya", 75.987],
        ["Karsten", 75.988],
        ["Kaneshiro", 75.988],
        ["Kamm", 75.989],
        ["Joines", 75.989],
        ["Joachim", 75.99],
        ["Janelle", 75.991],
        ["Jacobus", 75.991],
        ["Iler", 75.992],
        ["Holgate", 75.992],
        ["Hoar", 75.993],
        ["Hisey", 75.994],
        ["Hird", 75.994],
        ["Hilyard", 75.995],
        ["Heslin", 75.995],
        ["Herzberg", 75.996],
        ["Hennigan", 75.997],
        ["Hegland", 75.997],
        ["Hartl", 75.998],
        ["Haner", 75.998],
        ["Handel", 75.999],
        ["Gualtieri", 76],
        ["Greenly", 76],
        ["Grasser", 76.001],
        ["Gran", 76.002],
        ["Goetsch", 76.002],
        ["Godbold", 76.003],
        ["Gilland", 76.003],
        ["Gidney", 76.004],
        ["Gibney", 76.005],
        ["Giancola", 76.005],
        ["Gettinger", 76.006],
        ["Garzon", 76.006],
        ["Garret", 76.007],
        ["Galle", 76.008],
        ["Galgano", 76.008],
        ["Gaier", 76.009],
        ["Gaertner", 76.009],
        ["Fuston", 76.01],
        ["Freel", 76.011],
        ["Fortes", 76.011],
        ["Flock", 76.012],
        ["Fiorillo", 76.012],
        ["Figgs", 76.013],
        ["Fenstermacher", 76.014],
        ["Fedler", 76.014],
        ["Facer", 76.015],
        ["Fabiano", 76.015],
        ["Evins", 76.016],
        ["Eusebio", 76.017],
        ["Euler", 76.017],
        ["Esquer", 76.018],
        ["Enyeart", 76.018],
        ["Elem", 76.019],
        ["Eisenhower", 76.02],
        ["Eich", 76.02],
        ["Edgerly", 76.021],
        ["Durocher", 76.021],
        ["Durgan", 76.022],
        ["Duffin", 76.023],
        ["Drolet", 76.023],
        ["Drewes", 76.024],
        ["Dotts", 76.024],
        ["Dossantos", 76.025],
        ["Dolly", 76.026],
        ["Dockins", 76.026],
        ["Dirksen", 76.027],
        ["Difiore", 76.027],
        ["Dierks", 76.028],
        ["Dickerman", 76.029],
        ["Dice", 76.029],
        ["Dery", 76.03],
        ["Denault", 76.031],
        ["Demaree", 76.031],
        ["Delmonte", 76.032],
        ["Delcambre", 76.032],
        ["Days", 76.033],
        ["Daulton", 76.034],
        ["Darst", 76.034],
        ["Dahle", 76.035],
        ["Curnutt", 76.035],
        ["Cully", 76.036],
        ["Culligan", 76.037],
        ["Cueva", 76.037],
        ["Crosslin", 76.038],
        ["Croskey", 76.038],
        ["Cromartie", 76.039],
        ["Crofts", 76.04],
        ["Covin", 76.04],
        ["Coutee", 76.041],
        ["Countess", 76.041],
        ["Cost", 76.042],
        ["Coppa", 76.043],
        ["Coogan", 76.043],
        ["Condrey", 76.044],
        ["Concannon", 76.044],
        ["Coger", 76.045],
        ["Cloer", 76.046],
        ["Clatterbuck", 76.046],
        ["Cieslak", 76.047],
        ["Chumbley", 76.047],
        ["Choudhury", 76.048],
        ["Chiaramonte", 76.049],
        ["Charboneau", 76.049],
        ["Chai", 76.05],
        ["Carneal", 76.05],
        ["Cappello", 76.051],
        ["Campisi", 76.052],
        ["Callicoat", 76.052],
        ["Burgoyne", 76.053],
        ["Bucholz", 76.053],
        ["Brumback", 76.054],
        ["Brosnan", 76.055],
        ["Brogden", 76.055],
        ["Broder", 76.056],
        ["Brendle", 76.056],
        ["Breece", 76.057],
        ["Bown", 76.058],
        ["Bou", 76.058],
        ["Boser", 76.059],
        ["Bondy", 76.06],
        ["Bolster", 76.06],
        ["Boll", 76.061],
        ["Bluford", 76.061],
        ["Blandon", 76.062],
        ["Biscoe", 76.063],
        ["Bevill", 76.063],
        ["Bence", 76.064],
        ["Battin", 76.064],
        ["Basel", 76.065],
        ["Bartram", 76.066],
        ["Barnaby", 76.066],
        ["Barmore", 76.067],
        ["Balbuena", 76.067],
        ["Badgley", 76.068],
        ["Backstrom", 76.069],
        ["Auyeung", 76.069],
        ["Ater", 76.07],
        ["Arrellano", 76.07],
        ["Arant", 76.071],
        ["Ansari", 76.072],
        ["Alling", 76.072],
        ["Alejandre", 76.073],
        ["Alcock", 76.073],
        ["Alaimo", 76.074],
        ["Aguinaldo", 76.075],
        ["Aarons", 76.075],
        ["Zurita", 76.076],
        ["Zeiger", 76.076],
        ["Zawacki", 76.077],
        ["Yutzy", 76.078],
        ["Yarger", 76.078],
        ["Wygant", 76.079],
        ["Wurm", 76.079],
        ["Wuest", 76.08],
        ["Wolfram", 76.081],
        ["Witherell", 76.081],
        ["Wisneski", 76.082],
        ["Whitby", 76.082],
        ["Whelchel", 76.083],
        ["Weisz", 76.083],
        ["Weisinger", 76.084],
        ["Weishaar", 76.085],
        ["Wehr", 76.085],
        ["Wedge", 76.086],
        ["Waxman", 76.086],
        ["Waldschmidt", 76.087],
        ["Walck", 76.088],
        ["Waggener", 76.088],
        ["Vosburg", 76.089],
        ["Vita", 76.089],
        ["Villela", 76.09],
        ["Vercher", 76.091],
        ["Venters", 76.091],
        ["Van Scyoc", 76.092],
        ["Van Dyne", 76.092],
        ["Valenza", 76.093],
        ["Utt", 76.093],
        ["Urick", 76.094],
        ["Ungar", 76.095],
        ["Ulm", 76.095],
        ["Tumlin", 76.096],
        ["Tsao", 76.096],
        ["Tryon", 76.097],
        ["Trudel", 76.098],
        ["Treiber", 76.098],
        ["Tow", 76.099],
        ["Tober", 76.099],
        ["Tipler", 76.1],
        ["Tillson", 76.101],
        ["Tiedemann", 76.101],
        ["Thornley", 76.102],
        ["Tetrault", 76.102],
        ["Temme", 76.103],
        ["Tarrance", 76.103],
        ["Tackitt", 76.104],
        ["Sykora", 76.105],
        ["Sweetman", 76.105],
        ["Swatzell", 76.106],
        ["Sutliff", 76.106],
        ["Suhr", 76.107],
        ["Sturtz", 76.108],
        ["Strub", 76.108],
        ["Strayhorn", 76.109],
        ["Stormer", 76.109],
        ["Steveson", 76.11],
        ["Stengel", 76.111],
        ["Steinfeldt", 76.111],
        ["Spiro", 76.112],
        ["Spieker", 76.112],
        ["Speth", 76.113],
        ["Spero", 76.113],
        ["Soza", 76.114],
        ["Souliere", 76.115],
        ["Soucie", 76.115],
        ["Snedeker", 76.116],
        ["Slifer", 76.116],
        ["Skillings", 76.117],
        ["Situ", 76.118],
        ["Siniard", 76.118],
        ["Simeon", 76.119],
        ["Signorelli", 76.119],
        ["Siggers", 76.12],
        ["Shultis", 76.121],
        ["Shrewsbury", 76.121],
        ["Shippee", 76.122],
        ["Shimp", 76.122],
        ["Sherron", 76.123],
        ["Shepler", 76.123],
        ["Sharpless", 76.124],
        ["Shadrick", 76.125],
        ["Severt", 76.125],
        ["Severs", 76.126],
        ["Semon", 76.126],
        ["Semmes", 76.127],
        ["Seiter", 76.128],
        ["Segers", 76.128],
        ["Sclafani", 76.129],
        ["Sciortino", 76.129],
        ["Schroyer", 76.13],
        ["Schrack", 76.131],
        ["Schoenberg", 76.131],
        ["Schober", 76.132],
        ["Scheidt", 76.132],
        ["Scheele", 76.133],
        ["Satter", 76.133],
        ["Sartori", 76.134],
        ["Sarris", 76.135],
        ["Sarratt", 76.135],
        ["Salvaggio", 76.136],
        ["Saladino", 76.136],
        ["Sakamoto", 76.137],
        ["Saine", 76.138],
        ["Ryman", 76.138],
        ["Rumley", 76.139],
        ["Ruggerio", 76.139],
        ["Rucks", 76.14],
        ["Roughton", 76.141],
        ["Room", 76.141],
        ["Robards", 76.142],
        ["Ricca", 76.142],
        ["Rexroad", 76.143],
        ["Resler", 76.143],
        ["Reny", 76.144],
        ["Rentschler", 76.145],
        ["Redrick", 76.145],
        ["Redick", 76.146],
        ["Reagle", 76.146],
        ["Raymo", 76.147],
        ["Rape", 76.148],
        ["Raker", 76.148],
        ["Racette", 76.149],
        ["Pyburn", 76.149],
        ["Pritt", 76.15],
        ["Presson", 76.151],
        ["Pressman", 76.151],
        ["Pough", 76.152],
        ["Plain", 76.152],
        ["Pisani", 76.153],
        ["Perz", 76.153],
        ["Perras", 76.154],
        ["Pelzer", 76.155],
        ["Pedrosa", 76.155],
        ["Palos", 76.156],
        ["Palmisano", 76.156],
        ["Paille", 76.157],
        ["Orem", 76.158],
        ["Orbison", 76.158],
        ["Oliveros", 76.159],
        ["Nourse", 76.159],
        ["Nordquist", 76.16],
        ["Newbury", 76.161],
        ["Nelligan", 76.161],
        ["Nawrocki", 76.162],
        ["Myler", 76.162],
        ["Mumaw", 76.163],
        ["Morphis", 76.163],
        ["Moldenhauer", 76.164],
        ["Miyashiro", 76.165],
        ["Mignone", 76.165],
        ["Mickelsen", 76.166],
        ["Michalec", 76.166],
        ["Mesta", 76.167],
        ["McRee", 76.168],
        ["McQueary", 76.168],
        ["McNinch", 76.169],
        ["McNeilly", 76.169],
        ["McLelland", 76.17],
        ["McLawhorn", 76.171],
        ["McGreevy", 76.171],
        ["McConkey", 76.172],
        ["Mattes", 76.172],
        ["Maselli", 76.173],
        ["Marten", 76.173],
        ["Mart", 76.174],
        ["Marcucci", 76.175],
        ["Manseau", 76.175],
        ["Manjarrez", 76.176],
        ["Malbrough", 76.176],
        ["Machin", 76.177],
        ["Mabie", 76.178],
        ["Lynde", 76.178],
        ["Lykes", 76.179],
        ["Lueras", 76.179],
        ["Lokken", 76.18],
        ["Loken", 76.181],
        ["Linzy", 76.181],
        ["Lillis", 76.182],
        ["Lilienthal", 76.182],
        ["Levey", 76.183],
        ["Legler", 76.183],
        ["Leedom", 76.184],
        ["Lebowitz", 76.185],
        ["Lazzaro", 76.185],
        ["Larabee", 76.186],
        ["Lapinski", 76.186],
        ["Langner", 76.187],
        ["Langenfeld", 76.188],
        ["Lampkins", 76.188],
        ["Lamotte", 76.189],
        ["Lambright", 76.189],
        ["LaGarde", 76.19],
        ["LaDouceur", 76.191],
        ["Labrador", 76.191],
        ["LaBounty", 76.192],
        ["LaBlanc", 76.192],
        ["LaBerge", 76.193],
        ["Kyte", 76.193],
        ["Kroon", 76.194],
        ["Kron", 76.195],
        ["Kraker", 76.195],
        ["Kouba", 76.196],
        ["Kirwin", 76.196],
        ["Kincer", 76.197],
        ["Kimbler", 76.198],
        ["Kegler", 76.198],
        ["Keach", 76.199],
        ["Katzman", 76.199],
        ["Katzer", 76.2],
        ["Kalman", 76.201],
        ["Journey", 76.201],
        ["Jimmerson", 76.202],
        ["Jenning", 76.202],
        ["Janus", 76.203],
        ["Iacovelli", 76.203],
        ["Hust", 76.204],
        ["Huson", 76.205],
        ["Husby", 76.205],
        ["Humphery", 76.206],
        ["Hufnagel", 76.206],
        ["Honig", 76.207],
        ["Holsey", 76.208],
        ["Holoman", 76.208],
        ["Hohl", 76.209],
        ["Hogge", 76.209],
        ["Hinderliter", 76.21],
        ["Hildebrant", 76.211],
        ["Hick", 76.211],
        ["Hey", 76.212],
        ["Hemby", 76.212],
        ["Helle", 76.213],
        ["Heintzelman", 76.213],
        ["Heidrick", 76.214],
        ["Hearon", 76.215],
        ["Heap", 76.215],
        ["Hazelip", 76.216],
        ["Hauk", 76.216],
        ["Hasbrouck", 76.217],
        ["Harton", 76.218],
        ["Hartin", 76.218],
        ["Harpster", 76.219],
        ["Hansley", 76.219],
        ["Hanchett", 76.22],
        ["Haar", 76.221],
        ["Guthridge", 76.221],
        ["Gulbranson", 76.222],
        ["Guill", 76.222],
        ["Guerrera", 76.223],
        ["Grund", 76.223],
        ["Grosvenor", 76.224],
        ["Grist", 76.225],
        ["Grell", 76.225],
        ["Grear", 76.226],
        ["Granberry", 76.226],
        ["Gonser", 76.227],
        ["Giunta", 76.228],
        ["Giuliani", 76.228],
        ["Gillon", 76.229],
        ["Gillmore", 76.229],
        ["Gillan", 76.23],
        ["Gibbon", 76.231],
        ["Gettys", 76.231],
        ["Gelb", 76.232],
        ["Gano", 76.232],
        ["Galliher", 76.233],
        ["Fullen", 76.233],
        ["Frese", 76.234],
        ["Frates", 76.235],
        ["Foxwell", 76.235],
        ["Fleishman", 76.236],
        ["Fleener", 76.236],
        ["Fielden", 76.237],
        ["Ferrera", 76.238],
        ["Feng", 76.238],
        ["Fells", 76.239],
        ["Feemster", 76.239],
        ["Fauntleroy", 76.24],
        ["Fails", 76.241],
        ["Evatt", 76.241],
        ["Espy", 76.242],
        ["Eno", 76.242],
        ["Emmerich", 76.243],
        ["Edwin", 76.243],
        ["Edler", 76.244],
        ["Eastham", 76.245],
        ["Dunavant", 76.245],
        ["Duca", 76.246],
        ["Drinnon", 76.246],
        ["Dowe", 76.247],
        ["Dorgan", 76.248],
        ["Dollinger", 76.248],
        ["Divers", 76.249],
        ["Dipalma", 76.249],
        ["Difranco", 76.25],
        ["Dietrick", 76.251],
        ["Denzer", 76.251],
        ["Demarest", 76.252],
        ["Delee", 76.252],
        ["Delariva", 76.253],
        ["Delany", 76.253],
        ["Decesare", 76.254],
        ["Debellis", 76.255],
        ["Deavers", 76.255],
        ["Deardorff", 76.256],
        ["Dawe", 76.256],
        ["Darosa", 76.257],
        ["Darley", 76.258],
        ["Dalzell", 76.258],
        ["Dahlen", 76.259],
        ["Curto", 76.259],
        ["Cupps", 76.26],
        ["Cunniff", 76.261],
        ["Cude", 76.261],
        ["Crivello", 76.262],
        ["Cripps", 76.262],
        ["Cresswell", 76.263],
        ["Cousar", 76.263],
        ["Cotta", 76.264],
        ["Compo", 76.265],
        ["Colorado", 76.265],
        ["Clyne", 76.266],
        ["Clayson", 76.266],
        ["Cearley", 76.267],
        ["Catania", 76.268],
        ["Carini", 76.268],
        ["Cargo", 76.269],
        ["Cantero", 76.269],
        ["Cali", 76.27],
        ["Buttrey", 76.271],
        ["Buttler", 76.271],
        ["Burpee", 76.272],
        ["Bulkley", 76.272],
        ["Buitron", 76.273],
        ["Buda", 76.273],
        ["Bublitz", 76.274],
        ["Bryer", 76.275],
        ["Bryden", 76.275],
        ["Brouillette", 76.276],
        ["Brott", 76.276],
        ["Brookman", 76.277],
        ["Bronk", 76.278],
        ["Breshears", 76.278],
        ["Brennen", 76.279],
        ["Brannum", 76.279],
        ["Brandl", 76.28],
        ["Braman", 76.281],
        ["Bracewell", 76.281],
        ["Boyter", 76.282],
        ["Bomberger", 76.282],
        ["Bold", 76.283],
        ["Bogen", 76.283],
        ["Boeding", 76.284],
        ["Bob", 76.285],
        ["Blauvelt", 76.285],
        ["Blandford", 76.286],
        ["Bigger", 76.286],
        ["Biermann", 76.287],
        ["Bielecki", 76.288],
        ["Bibby", 76.288],
        ["Berthold", 76.289],
        ["Berkman", 76.289],
        ["Belvin", 76.29],
        ["Bellomy", 76.291],
        ["Beland", 76.291],
        ["Behne", 76.292],
        ["Beecham", 76.292],
        ["Becher", 76.293],
        ["Beams", 76.293],
        ["Bax", 76.294],
        ["Bassham", 76.295],
        ["Barret", 76.295],
        ["Baley", 76.296],
        ["Bacchus", 76.296],
        ["Auxier", 76.297],
        ["Atkison", 76.298],
        ["Ary", 76.298],
        ["Arocha", 76.299],
        ["Arechiga", 76.299],
        ["Anspach", 76.3],
        ["An", 76.301],
        ["Algarin", 76.301],
        ["Alcott", 76.302],
        ["Alberty", 76.302],
        ["Ager", 76.303],
        ["Adolph", 76.303],
        ["Ackman", 76.304],
        ["Abdul", 76.305],
        ["Abdallah", 76.305],
        ["Zwick", 76.306],
        ["Ziemer", 76.306],
        ["Zastrow", 76.307],
        ["Zajicek", 76.307],
        ["Yokum", 76.308],
        ["Yokley", 76.309],
        ["Wittrock", 76.309],
        ["Winebarger", 76.31],
        ["Wilker", 76.31],
        ["Wilham", 76.311],
        ["Whitham", 76.312],
        ["Wetzler", 76.312],
        ["Westling", 76.313],
        ["Westbury", 76.313],
        ["Wendler", 76.314],
        ["Wellborn", 76.314],
        ["Weitzman", 76.315],
        ["Weitz", 76.316],
        ["Weight", 76.316],
        ["Wallner", 76.317],
        ["Waldroup", 76.317],
        ["Vrabel", 76.318],
        ["Vowels", 76.318],
        ["Volker", 76.319],
        ["Vitiello", 76.32],
        ["Visconti", 76.32],
        ["Villicana", 76.321],
        ["Vibbert", 76.321],
        ["Vesey", 76.322],
        ["Vannatter", 76.322],
        ["van Gilder", 76.323],
        ["Vandervort", 76.324],
        ["Vandegrift", 76.324],
        ["Van Alstyne", 76.325],
        ["Vallecillo", 76.325],
        ["Usrey", 76.326],
        ["Tynan", 76.326],
        ["Turpen", 76.327],
        ["Tuller", 76.328],
        ["Trisler", 76.328],
        ["Townson", 76.329],
        ["Tillmon", 76.329],
        ["Threlkeld", 76.33],
        ["Thornell", 76.33],
        ["Terrio", 76.331],
        ["Taunton", 76.332],
        ["Tarry", 76.332],
        ["Tardy", 76.333],
        ["Swoboda", 76.333],
        ["Swihart", 76.334],
        ["Sustaita", 76.334],
        ["Suitt", 76.335],
        ["Stuber", 76.336],
        ["Strine", 76.336],
        ["Stookey", 76.337],
        ["Stmartin", 76.337],
        ["Stiger", 76.338],
        ["Stainbrook", 76.338],
        ["Solem", 76.339],
        ["Smail", 76.34],
        ["Sligh", 76.34],
        ["Siple", 76.341],
        ["Sieben", 76.341],
        ["Shumake", 76.342],
        ["Shriner", 76.342],
        ["Showman", 76.343],
        ["Shiner", 76.344],
        ["Sheen", 76.344],
        ["Sheckler", 76.345],
        ["Seim", 76.345],
        ["Secrist", 76.346],
        ["Scoggin", 76.346],
        ["Schultheis", 76.347],
        ["Schmalz", 76.348],
        ["Schendel", 76.348],
        ["Schacher", 76.349],
        ["Savard", 76.349],
        ["Saulter", 76.35],
        ["Santillanes", 76.35],
        ["Sandiford", 76.351],
        ["Sande", 76.352],
        ["Salzer", 76.352],
        ["Salvato", 76.353],
        ["Saltz", 76.353],
        ["Sakai", 76.354],
        ["Ryckman", 76.354],
        ["Ryant", 76.355],
        ["Ruck", 76.356],
        ["Ronald", 76.356],
        ["Rocker", 76.357],
        ["Rittenberry", 76.357],
        ["Ristau", 76.358],
        ["Risk", 76.358],
        ["Richart", 76.359],
        ["Rhynes", 76.36],
        ["Reyer", 76.36],
        ["Reulet", 76.361],
        ["Reser", 76.361],
        ["Redington", 76.362],
        ["Reddington", 76.362],
        ["Rebello", 76.363],
        ["Reasor", 76.364],
        ["Raftery", 76.364],
        ["Rabago", 76.365],
        ["Raasch", 76.365],
        ["Quintanar", 76.366],
        ["Pylant", 76.366],
        ["Purington", 76.367],
        ["Provencal", 76.368],
        ["Prom", 76.368],
        ["Prioleau", 76.369],
        ["Prestwood", 76.369],
        ["Pothier", 76.37],
        ["Popa", 76.37],
        ["Polster", 76.371],
        ["Politte", 76.372],
        ["Poffenberger", 76.372],
        ["Pinner", 76.373],
        ["Pietrzak", 76.373],
        ["Pettie", 76.374],
        ["Penaflor", 76.374],
        ["Pellot", 76.375],
        ["Pellham", 76.376],
        ["Paylor", 76.376],
        ["Payeur", 76.377],
        ["Papas", 76.377],
        ["Paik", 76.378],
        ["Oyola", 76.378],
        ["Osbourn", 76.379],
        ["Orzechowski", 76.38],
        ["Oppenheimer", 76.38],
        ["Olesen", 76.381],
        ["Oja", 76.381],
        ["Ohl", 76.382],
        ["Nuckolls", 76.382],
        ["Nordberg", 76.383],
        ["Noonkester", 76.384],
        ["Nold", 76.384],
        ["Nitta", 76.385],
        ["Niblett", 76.385],
        ["Neuhaus", 76.386],
        ["Nesler", 76.386],
        ["Ned", 76.387],
        ["Nanney", 76.388],
        ["Myrie", 76.388],
        ["Mutch", 76.389],
        ["Motto", 76.389],
        ["Mosquera", 76.39],
        ["Morena", 76.39],
        ["Montalto", 76.391],
        ["Montagna", 76.392],
        ["Mizelle", 76.392],
        ["Mincy", 76.393],
        ["Millikan", 76.393],
        ["Millay", 76.394],
        ["Miler", 76.394],
        ["Milbourn", 76.395],
        ["Mikels", 76.396],
        ["Migues", 76.396],
        ["Miesner", 76.397],
        ["Mershon", 76.397],
        ["Merrow", 76.398],
        ["Merlin", 76.398],
        ["Melia", 76.399],
        ["Meigs", 76.4],
        ["Mealey", 76.4],
        ["McRaney", 76.401],
        ["McMartin", 76.401],
        ["McLachlan", 76.402],
        ["McGeehan", 76.402],
        ["McFerren", 76.403],
        ["McDole", 76.404],
        ["McCaulley", 76.404],
        ["McAnulty", 76.405],
        ["Maziarz", 76.405],
        ["Maul", 76.406],
        ["Mateer", 76.407],
        ["Martinsen", 76.407],
        ["Marson", 76.408],
        ["Mariotti", 76.408],
        ["Manna", 76.409],
        ["Mang", 76.409],
        ["Mance", 76.41],
        ["Malbon", 76.411],
        ["Mah", 76.411],
        ["Magnusson", 76.412],
        ["MacLachlan", 76.412],
        ["Macek", 76.413],
        ["Lurie", 76.413],
        ["Luc", 76.414],
        ["Lown", 76.415],
        ["Loranger", 76.415],
        ["Lonon", 76.416],
        ["Lisenby", 76.416],
        ["Linsley", 76.417],
        ["Linger", 76.417],
        ["Lenk", 76.418],
        ["Leavens", 76.419],
        ["Learned", 76.419],
        ["Lauritzen", 76.42],
        ["Lathem", 76.42],
        ["Lashbrook", 76.421],
        ["Landman", 76.421],
        ["Lamarche", 76.422],
        ["Lamantia", 76.423],
        ["Laguerre", 76.423],
        ["Lagrange", 76.424],
        ["Kogan", 76.424],
        ["Klingbeil", 76.425],
        ["Kist", 76.425],
        ["Kimpel", 76.426],
        ["Kime", 76.427],
        ["Kier", 76.427],
        ["Kerfoot", 76.428],
        ["Kennamer", 76.428],
        ["Kellems", 76.429],
        ["Kammer", 76.429],
        ["Kamen", 76.43],
        ["Jess", 76.431],
        ["Jepsen", 76.431],
        ["Jarnigan", 76.432],
        ["Isler", 76.432],
        ["Ishee", 76.433],
        ["Isabel", 76.433],
        ["Hux", 76.434],
        ["Hungate", 76.435],
        ["Hummell", 76.435],
        ["Hultgren", 76.436],
        ["Huffaker", 76.436],
        ["Hruby", 76.437],
        ["Hover", 76.437],
        ["Hornick", 76.438],
        ["Hooser", 76.439],
        ["Hooley", 76.439],
        ["Hoggan", 76.44],
        ["Hirano", 76.44],
        ["Hilley", 76.441],
        ["Higham", 76.441],
        ["Heuser", 76.442],
        ["Henrickson", 76.443],
        ["Henegar", 76.443],
        ["Hellwig", 76.444],
        ["Heide", 76.444],
        ["Hedley", 76.445],
        ["Hasegawa", 76.445],
        ["Hartt", 76.446],
        ["Hambright", 76.447],
        ["Halfacre", 76.447],
        ["Hafley", 76.448],
        ["Guion", 76.448],
        ["Guinan", 76.449],
        ["Grunwald", 76.449],
        ["Grothe", 76.45],
        ["Gries", 76.451],
        ["Greaney", 76.451],
        ["Granda", 76.452],
        ["Grabill", 76.452],
        ["Gothard", 76.453],
        ["Gossman", 76.453],
        ["Gosser", 76.454],
        ["Gossard", 76.455],
        ["Gosha", 76.455],
        ["Goldner", 76.456],
        ["Gobin", 76.456],
        ["Gloss", 76.457],
        ["Ginyard", 76.457],
        ["Gilkes", 76.458],
        ["Gilden", 76.459],
        ["Gerson", 76.459],
        ["Gephart", 76.46],
        ["Gengler", 76.46],
        ["Gautier", 76.461],
        ["Gassett", 76.461],
        ["Garon", 76.462],
        ["Gandhi", 76.463],
        ["Galusha", 76.463],
        ["Gallager", 76.464],
        ["Galdamez", 76.464],
        ["Fulmore", 76.465],
        ["Fritsche", 76.465],
        ["Fowles", 76.466],
        ["Foutch", 76.467],
        ["Forward", 76.467],
        ["Footman", 76.468],
        ["Fludd", 76.468],
        ["Flakes", 76.469],
        ["Ferriera", 76.469],
        ["Ferrero", 76.47],
        ["Ferreri", 76.471],
        ["Fenimore", 76.471],
        ["Fegley", 76.472],
        ["Fegan", 76.472],
        ["Fearn", 76.473],
        ["Farrier", 76.473],
        ["Fansler", 76.474],
        ["Fane", 76.475],
        ["Falzone", 76.475],
        ["Fairweather", 76.476],
        ["Etherton", 76.476],
        ["Elsberry", 76.477],
        ["Dykema", 76.477],
        ["Duppstadt", 76.478],
        ["Dunnam", 76.479],
        ["Dunklin", 76.479],
        ["Duet", 76.48],
        ["Due", 76.48],
        ["Dudgeon", 76.481],
        ["Dubuc", 76.481],
        ["Doxey", 76.482],
        ["Dory", 76.483],
        ["Donmoyer", 76.483],
        ["Dodgen", 76.484],
        ["Disanto", 76.484],
        ["Dingler", 76.485],
        ["Dimattia", 76.485],
        ["Dilday", 76.486],
        ["Digennaro", 76.487],
        ["Diedrich", 76.487],
        ["Derossett", 76.488],
        ["Deputy", 76.488],
        ["Depp", 76.489],
        ["Demasi", 76.489],
        ["Degraffenreid", 76.49],
        ["Deakins", 76.491],
        ["Deady", 76.491],
        ["Davin", 76.492],
        ["Daigre", 76.492],
        ["Daddario", 76.493],
        ["Czerwinski", 76.493],
        ["Cullens", 76.494],
        ["Cubbage", 76.495],
        ["Cracraft", 76.495],
        ["Constance", 76.496],
        ["Comes", 76.496],
        ["Combest", 76.497],
        ["Coletti", 76.498],
        ["Coghill", 76.498],
        ["Clerk", 76.499],
        ["Claybrooks", 76.499],
        ["Class", 76.5],
        ["Christofferse", 76.5],
        ["Chiesa", 76.501],
        ["Chason", 76.502],
        ["Chamorro", 76.502],
        ["Cessna", 76.503],
        ["Celentano", 76.503],
        ["Cayer", 76.504],
        ["Carolan", 76.504],
        ["Carnegie", 76.505],
        ["Capetillo", 76.506],
        ["Callier", 76.506],
        ["Cadogan", 76.507],
        ["Caba", 76.507],
        ["Byrom", 76.508],
        ["Byrns", 76.508],
        ["Burrowes", 76.509],
        ["Burket", 76.51],
        ["Burdge", 76.51],
        ["Burbage", 76.511],
        ["Bukowski", 76.511],
        ["Buchholtz", 76.512],
        ["Brunt", 76.512],
        ["Brungardt", 76.513],
        ["Brunetti", 76.514],
        ["Brumbelow", 76.514],
        ["Brugger", 76.515],
        ["Broadhurst", 76.515],
        ["Brigance", 76.516],
        ["Brandow", 76.516],
        ["Bouknight", 76.517],
        ["Bottorff", 76.518],
        ["Bottomley", 76.518],
        ["Bosarge", 76.519],
        ["Borger", 76.519],
        ["Bona", 76.52],
        ["Bombardier", 76.52],
        ["Bologna", 76.521],
        ["Boggan", 76.522],
        ["Blumer", 76.522],
        ["Blecha", 76.523],
        ["Birney", 76.523],
        ["Birkland", 76.524],
        ["Betances", 76.524],
        ["Beran", 76.525],
        ["Benny", 76.526],
        ["Benes", 76.526],
        ["Belin", 76.527],
        ["Belgrave", 76.527],
        ["Bealer", 76.528],
        ["Bauch", 76.528],
        ["Bath", 76.529],
        ["Bashir", 76.53],
        ["Bartow", 76.53],
        ["Baro", 76.531],
        ["Barnhouse", 76.531],
        ["Barile", 76.532],
        ["Ballweg", 76.532],
        ["Baisley", 76.533],
        ["Bains", 76.534],
        ["Baehr", 76.534],
        ["Badilla", 76.535],
        ["Bachus", 76.535],
        ["Bacher", 76.536],
        ["Bachelder", 76.536],
        ["Auzenne", 76.537],
        ["Aten", 76.538],
        ["Astle", 76.538],
        ["Allis", 76.539],
        ["Agarwal", 76.539],
        ["Adger", 76.54],
        ["Adamek", 76.54],
        ["Ziolkowski", 76.541],
        ["Zinke", 76.542],
        ["Zazueta", 76.542],
        ["Zamorano", 76.543],
        ["Younkin", 76.543],
        ["Won", 76.544],
        ["Wittig", 76.544],
        ["Witman", 76.545],
        ["Winsett", 76.545],
        ["Winkles", 76.546],
        ["Wiedman", 76.547],
        ["Whitner", 76.547],
        ["Whitcher", 76.548],
        ["Wetherby", 76.548],
        ["Westra", 76.549],
        ["Westhoff", 76.549],
        ["Wehrle", 76.55],
        ["Wee", 76.55],
        ["Wagaman", 76.551],
        ["Voris", 76.552],
        ["Vicknair", 76.552],
        ["Vegas", 76.553],
        ["Veasley", 76.553],
        ["Vaugh", 76.554],
        ["Vanish", 76.554],
        ["Vanderburg", 76.555],
        ["Valletta", 76.555],
        ["Tunney", 76.556],
        ["Trumbo", 76.557],
        ["Truluck", 76.557],
        ["Trueman", 76.558],
        ["Truby", 76.558],
        ["Trombly", 76.559],
        ["Trojan", 76.559],
        ["Tourville", 76.56],
        ["Tostado", 76.56],
        ["Tone", 76.561],
        ["Titcomb", 76.562],
        ["Timpson", 76.562],
        ["Tignor", 76.563],
        ["Thrush", 76.563],
        ["Thresher", 76.564],
        ["Thiede", 76.564],
        ["Tews", 76.565],
        ["Tamplin", 76.565],
        ["Taff", 76.566],
        ["Tacker", 76.567],
        ["Syverson", 76.567],
        ["Sylvestre", 76.568],
        ["Summerall", 76.568],
        ["Stumbaugh", 76.569],
        ["Strouth", 76.569],
        ["Straker", 76.57],
        ["Stradford", 76.57],
        ["Stoney", 76.571],
        ["Stokley", 76.572],
        ["Steinhoff", 76.572],
        ["Steinberger", 76.573],
        ["Stairs", 76.573],
        ["Spigner", 76.574],
        ["Soltero", 76.574],
        ["Snively", 76.575],
        ["Sletten", 76.575],
        ["Sinkler", 76.576],
        ["Sinegal", 76.577],
        ["Simoes", 76.577],
        ["Siller", 76.578],
        ["Sigel", 76.578],
        ["Shoe", 76.579],
        ["Shire", 76.579],
        ["Shinkle", 76.58],
        ["Shellman", 76.58],
        ["Sheller", 76.581],
        ["Sheats", 76.582],
        ["Sharer", 76.582],
        ["Selvage", 76.583],
        ["Sedlak", 76.583],
        ["Sea", 76.584],
        ["Schriver", 76.584],
        ["Schimke", 76.585],
        ["Scheuerman", 76.585],
        ["Schanz", 76.586],
        ["Savory", 76.587],
        ["Saulters", 76.587],
        ["Sauers", 76.588],
        ["Sais", 76.588],
        ["Rusin", 76.589],
        ["Rumfelt", 76.589],
        ["Ruhland", 76.59],
        ["Rozar", 76.591],
        ["Rosborough", 76.591],
        ["Ronning", 76.592],
        ["Rolph", 76.592],
        ["Roloff", 76.593],
        ["Rogue", 76.593],
        ["Robie", 76.594],
        ["Riviera", 76.594],
        ["Rimer", 76.595],
        ["Riehle", 76.596],
        ["Ricco", 76.596],
        ["Rhein", 76.597],
        ["Retzlaff", 76.597],
        ["Reisman", 76.598],
        ["Reimann", 76.598],
        ["Re", 76.599],
        ["Rayes", 76.599],
        ["Raub", 76.6],
        ["Raminez", 76.601],
        ["Quesinberry", 76.601],
        ["Pua", 76.602],
        ["Procopio", 76.602],
        ["Priolo", 76.603],
        ["Printz", 76.603],
        ["Prewett", 76.604],
        ["Preas", 76.604],
        ["Prahl", 76.605],
        ["Portugal", 76.606],
        ["Poovey", 76.606],
        ["Ploof", 76.607],
        ["Platz", 76.607],
        ["Plaisted", 76.608],
        ["Pinzon", 76.608],
        ["Pineiro", 76.609],
        ["Pickney", 76.609],
        ["Petrovich", 76.61],
        ["Perl", 76.611],
        ["Pehrson", 76.611],
        ["Peets", 76.612],
        ["Pavon", 76.612],
        ["Pautz", 76.613],
        ["Pascarella", 76.613],
        ["Paras", 76.614],
        ["Paolini", 76.614],
        ["Pals", 76.615],
        ["Pafford", 76.616],
        ["Oyer", 76.616],
        ["Ovellette", 76.617],
        ["Outten", 76.617],
        ["Outen", 76.618],
        ["Ours", 76.618],
        ["Orduna", 76.619],
        ["Odriscoll", 76.619],
        ["Oberlin", 76.62],
        ["Nosal", 76.621],
        ["Niven", 76.621],
        ["Nisbett", 76.622],
        ["Nevers", 76.622],
        ["Nathanson", 76.623],
        ["Mule", 76.623],
        ["Mukai", 76.624],
        ["Mozee", 76.624],
        ["Mowers", 76.625],
        ["Motyka", 76.626],
        ["Morency", 76.626],
        ["Montford", 76.627],
        ["Mollica", 76.627],
        ["Molden", 76.628],
        ["Mitten", 76.628],
        ["Miser", 76.629],
        ["Mina", 76.629],
        ["Millender", 76.63],
        ["Midgette", 76.631],
        ["Messerly", 76.631],
        ["Melendy", 76.632],
        ["Meisel", 76.632],
        ["Meidinger", 76.633],
        ["Meany", 76.633],
        ["McNitt", 76.634],
        ["McNemar", 76.634],
        ["McMakin", 76.635],
        ["McGaugh", 76.636],
        ["McCaa", 76.636],
        ["Mauriello", 76.637],
        ["Maudlin", 76.637],
        ["Matzke", 76.638],
        ["Mattia", 76.638],
        ["Matteo", 76.639],
        ["Matsumura", 76.639],
        ["Masuda", 76.64],
        ["Mangels", 76.641],
        ["Maloof", 76.641],
        ["Malizia", 76.642],
        ["Mahmoud", 76.642],
        ["Maglione", 76.643],
        ["Maddix", 76.643],
        ["Lucchesi", 76.644],
        ["Lochner", 76.644],
        ["Linquist", 76.645],
        ["Lino", 76.646],
        ["Lietz", 76.646],
        ["Leventhal", 76.647],
        ["Leopard", 76.647],
        ["Lemanski", 76.648],
        ["Leiser", 76.648],
        ["Laury", 76.649],
        ["Lauber", 76.649],
        ["Lamberth", 76.65],
        ["Kuss", 76.651],
        ["Kung", 76.651],
        ["Kulik", 76.652],
        ["Kuiper", 76.652],
        ["Krout", 76.653],
        ["Kotter", 76.653],
        ["Kort", 76.654],
        ["Kohlmeier", 76.654],
        ["Koffler", 76.655],
        ["Koeller", 76.656],
        ["Knipe", 76.656],
        ["Knauss", 76.657],
        ["Kleiber", 76.657],
        ["Kissee", 76.658],
        ["Kirst", 76.658],
        ["Kirch", 76.659],
        ["Kilgo", 76.66],
        ["Kerlin", 76.66],
        ["Kellison", 76.661],
        ["Kehl", 76.661],
        ["Kalb", 76.662],
        ["Jorden", 76.662],
        ["Jantzen", 76.663],
        ["Jamar", 76.663],
        ["Inabinet", 76.664],
        ["Ikard", 76.665],
        ["Husman", 76.665],
        ["Hunsberger", 76.666],
        ["Hundt", 76.666],
        ["Hucks", 76.667],
        ["Houtz", 76.667],
        ["Houseknecht", 76.668],
        ["Hoots", 76.668],
        ["Hogsett", 76.669],
        ["Hogans", 76.67],
        ["Hintze", 76.67],
        ["Hession", 76.671],
        ["Henault", 76.671],
        ["Hemming", 76.672],
        ["Helsley", 76.672],
        ["Heinen", 76.673],
        ["Heffington", 76.673],
        ["Heberling", 76.674],
        ["Heasley", 76.675],
        ["Heal", 76.675],
        ["Hazley", 76.676],
        ["Hazeltine", 76.676],
        ["Hayton", 76.677],
        ["Hayse", 76.677],
        ["Hawke", 76.678],
        ["Haston", 76.678],
        ["Harward", 76.679],
        ["Harvard", 76.68],
        ["Harrow", 76.68],
        ["Hanneman", 76.681],
        ["Hafford", 76.681],
        ["Hadnot", 76.682],
        ["Guerro", 76.682],
        ["Graig", 76.683],
        ["Grahm", 76.683],
        ["Gowins", 76.684],
        ["Gordillo", 76.685],
        ["Goosby", 76.685],
        ["Glatt", 76.686],
        ["Gibbens", 76.686],
        ["Ghent", 76.687],
        ["Gerrard", 76.687],
        ["Germann", 76.688],
        ["Geil", 76.688],
        ["Gebo", 76.689],
        ["Gean", 76.69],
        ["Garling", 76.69],
        ["Gardenhire", 76.691],
        ["Garbutt", 76.691],
        ["Gagner", 76.692],
        ["Furguson", 76.692],
        ["Funchess", 76.693],
        ["Fujiwara", 76.693],
        ["Fujita", 76.694],
        ["Friley", 76.695],
        ["Frigo", 76.695],
        ["Forshee", 76.696],
        ["Folkes", 76.696],
        ["Filler", 76.697],
        ["Fernald", 76.697],
        ["Ferber", 76.698],
        ["Feingold", 76.698],
        ["Favorite", 76.699],
        ["Faul", 76.7],
        ["Farrelly", 76.7],
        ["Fairbank", 76.701],
        ["Failla", 76.701],
        ["Estelle", 76.702],
        ["Espey", 76.702],
        ["Eshleman", 76.703],
        ["Ertl", 76.703],
        ["Erhart", 76.704],
        ["Erhardt", 76.705],
        ["Erbe", 76.705],
        ["Elsea", 76.706],
        ["Ells", 76.706],
        ["Ellman", 76.707],
        ["Eisenhart", 76.707],
        ["Ehmann", 76.708],
        ["Earnhardt", 76.708],
        ["Duplantis", 76.709],
        ["Dulac", 76.71],
        ["Ducote", 76.71],
        ["Draves", 76.711],
        ["Dosch", 76.711],
        ["Dolce", 76.712],
        ["Divito", 76.712],
        ["Ditch", 76.713],
        ["Dimauro", 76.713],
        ["Derringer", 76.714],
        ["Demeo", 76.715],
        ["Demartini", 76.715],
        ["Delima", 76.716],
        ["Dehner", 76.716],
        ["Degen", 76.717],
        ["Defrancisco", 76.717],
        ["Defoor", 76.718],
        ["Dedeaux", 76.718],
        ["Debnam", 76.719],
        ["Cypert", 76.72],
        ["Cutrer", 76.72],
        ["Cusumano", 76.721],
        ["Custis", 76.721],
        ["Croker", 76.722],
        ["Courtois", 76.722],
        ["Costantino", 76.723],
        ["Cormack", 76.723],
        ["Corbeil", 76.724],
        ["Copher", 76.725],
        ["Conlan", 76.725],
        ["Conkling", 76.726],
        ["Cogdell", 76.726],
        ["Cilley", 76.727],
        ["Chapdelaine", 76.727],
        ["Cendejas", 76.728],
        ["Castiglia", 76.728],
        ["Cassette", 76.729],
        ["Cashin", 76.73],
        ["Carstensen", 76.73],
        ["Carol", 76.731],
        ["Caprio", 76.731],
        ["Calcote", 76.732],
        ["Calaway", 76.732],
        ["Byfield", 76.733],
        ["Butner", 76.734],
        ["Bushway", 76.734],
        ["Burritt", 76.735],
        ["Browner", 76.735],
        ["Brobst", 76.736],
        ["Briner", 76.736],
        ["Brighton", 76.737],
        ["Bridger", 76.737],
        ["Brickley", 76.738],
        ["Brendel", 76.739],
        ["Bratten", 76.739],
        ["Bratt", 76.74],
        ["Brainerd", 76.74],
        ["Brackman", 76.741],
        ["Bowne", 76.741],
        ["Bouck", 76.742],
        ["Borunda", 76.742],
        ["Bordner", 76.743],
        ["Bonenfant", 76.744],
        ["Boer", 76.744],
        ["Boehmer", 76.745],
        ["Bodiford", 76.745],
        ["Bleau", 76.746],
        ["Blankinship", 76.746],
        ["Blane", 76.747],
        ["Blaha", 76.747],
        ["Bitting", 76.748],
        ["Bissonette", 76.749],
        ["Bigby", 76.749],
        ["Bibeau", 76.75],
        ["Beverage", 76.75],
        ["Bermudes", 76.751],
        ["Berke", 76.751],
        ["Bergevin", 76.752],
        ["Bergerson", 76.752],
        ["Bendel", 76.753],
        ["Belville", 76.754],
        ["Bechard", 76.754],
        ["Bearce", 76.755],
        ["Beadles", 76.755],
        ["Batz", 76.756],
        ["Bartlow", 76.756],
        ["Barren", 76.757],
        ["Ayoub", 76.757],
        ["Avans", 76.758],
        ["Aumiller", 76.759],
        ["Arviso", 76.759],
        ["Arpin", 76.76],
        ["Arnwine", 76.76],
        ["Armwood", 76.761],
        ["Arent", 76.761],
        ["Arehart", 76.762],
        ["Arcand", 76.762],
        ["Antle", 76.763],
        ["Ambrosino", 76.764],
        ["Alongi", 76.764],
        ["Alm", 76.765],
        ["Allshouse", 76.765],
        ["Ahart", 76.766],
        ["Aguon", 76.766],
        ["Ziebarth", 76.767],
        ["Zeledon", 76.767],
        ["Zakrzewski", 76.768],
        ["Yuhas", 76.768],
        ["Yingst", 76.769],
        ["Yedinak", 76.77],
        ["Wommack", 76.77],
        ["Winnett", 76.771],
        ["Wingler", 76.771],
        ["Wilcoxen", 76.772],
        ["Whitmarsh", 76.772],
        ["Whistler", 76.773],
        ["Wayt", 76.773],
        ["Watley", 76.774],
        ["Wasser", 76.774],
        ["Warkentin", 76.775],
        ["Voll", 76.776],
        ["Vogelsang", 76.776],
        ["Voegele", 76.777],
        ["Vivanco", 76.777],
        ["Vinton", 76.778],
        ["Villafane", 76.778],
        ["Viles", 76.779],
        ["Versace", 76.779],
        ["Ver", 76.78],
        ["Venne", 76.78],
        ["Van Wagoner", 76.781],
        ["Van Wagenen", 76.781],
        ["Van Leuven", 76.782],
        ["Vanauken", 76.783],
        ["Uselton", 76.783],
        ["Uren", 76.784],
        ["Trumbauer", 76.784],
        ["Tritt", 76.785],
        ["Treadaway", 76.785],
        ["Tozier", 76.786],
        ["Tope", 76.786],
        ["Tomczak", 76.787],
        ["Tomberlin", 76.787],
        ["Tomasini", 76.788],
        ["Tollett", 76.788],
        ["Toller", 76.789],
        ["Titsworth", 76.79],
        ["Tirrell", 76.79],
        ["Tilly", 76.791],
        ["Tavera", 76.791],
        ["Tarnowski", 76.792],
        ["Tanouye", 76.792],
        ["Tall", 76.793],
        ["Swarthout", 76.793],
        ["Sutera", 76.794],
        ["Surette", 76.794],
        ["Styers", 76.795],
        ["Styer", 76.796],
        ["Stipe", 76.796],
        ["Stickland", 76.797],
        ["Steve", 76.797],
        ["Stembridge", 76.798],
        ["Stearn", 76.798],
        ["Starkes", 76.799],
        ["Stanberry", 76.799],
        ["Stahr", 76.8],
        ["Spino", 76.8],
        ["Spicher", 76.801],
        ["Sperber", 76.801],
        ["Speece", 76.802],
        ["Soo", 76.803],
        ["Sonntag", 76.803],
        ["Sneller", 76.804],
        ["Smalling", 76.804],
        ["Slowik", 76.805],
        ["Slocumb", 76.805],
        ["Sliva", 76.806],
        ["Slemp", 76.806],
        ["Slama", 76.807],
        ["Sitz", 76.807],
        ["Sisto", 76.808],
        ["Sisemore", 76.808],
        ["Sindelar", 76.809],
        ["Shipton", 76.81],
        ["Shillings", 76.81],
        ["Sheeley", 76.811],
        ["Sharber", 76.811],
        ["Shaddix", 76.812],
        ["Severns", 76.812],
        ["Severino", 76.813],
        ["Sever", 76.813],
        ["Sensabaugh", 76.814],
        ["Seder", 76.814],
        ["Seawell", 76.815],
        ["Seamons", 76.816],
        ["Schrantz", 76.816],
        ["Schooler", 76.817],
        ["Scheffer", 76.817],
        ["Scheerer", 76.818],
        ["Scalia", 76.818],
        ["Saum", 76.819],
        ["Santibanez", 76.819],
        ["Sano", 76.82],
        ["Sanjuan", 76.82],
        ["Sampley", 76.821],
        ["Sailer", 76.821],
        ["Sabella", 76.822],
        ["Sabbagh", 76.823],
        ["Royall", 76.823],
        ["Rottman", 76.824],
        ["Rivenbark", 76.824],
        ["Rikard", 76.825],
        ["Ricketson", 76.825],
        ["Rickel", 76.826],
        ["Rethman", 76.826],
        ["Reily", 76.827],
        ["Reddin", 76.827],
        ["Reasoner", 76.828],
        ["Reade", 76.828],
        ["Rast", 76.829],
        ["Ranallo", 76.83],
        ["Rana", 76.83],
        ["Quintal", 76.831],
        ["Pung", 76.831],
        ["Pucci", 76.832],
        ["Proto", 76.832],
        ["Prosperie", 76.833],
        ["Prim", 76.833],
        ["Preusser", 76.834],
        ["Preslar", 76.834],
        ["Powley", 76.835],
        ["Postma", 76.836],
        ["Pinnix", 76.836],
        ["Pilla", 76.837],
        ["Pietsch", 76.837],
        ["Pickerel", 76.838],
        ["Pica", 76.838],
        ["Pharris", 76.839],
        ["Petway", 76.839],
        ["Petillo", 76.84],
        ["Perin", 76.84],
        ["Pereda", 76.841],
        ["Pennypacker", 76.841],
        ["Pennebaker", 76.842],
        ["Pedrick", 76.843],
        ["Patin", 76.843],
        ["Patchell", 76.844],
        ["Parodi", 76.844],
        ["Parman", 76.845],
        ["Pantano", 76.845],
        ["Padua", 76.846],
        ["Padro", 76.846],
        ["Osterhout", 76.847],
        ["Orner", 76.847],
        ["Opp", 76.848],
        ["Olivar", 76.848],
        ["Ohlson", 76.849],
        ["Odonoghue", 76.85],
        ["Oceguera", 76.85],
        ["Oberry", 76.851],
        ["Novello", 76.851],
        ["Noguera", 76.852],
        ["Newquist", 76.852],
        ["Newcombe", 76.853],
        ["Neihoff", 76.853],
        ["Nehring", 76.854],
        ["Nees", 76.854],
        ["Nebeker", 76.855],
        ["Nau", 76.856],
        ["Mundo", 76.856],
        ["Mullenix", 76.857],
        ["Morrisey", 76.857],
        ["Moronta", 76.858],
        ["Morillo", 76.858],
        ["Morefield", 76.859],
        ["Mongillo", 76.859],
        ["Molino", 76.86],
        ["Minto", 76.86],
        ["Midgley", 76.861],
        ["Michie", 76.861],
        ["Menzies", 76.862],
        ["Medved", 76.863],
        ["Mechling", 76.863],
        ["Mealy", 76.864],
        ["McShan", 76.864],
        ["McQuaig", 76.865],
        ["McNees", 76.865],
        ["McGlade", 76.866],
        ["McGarity", 76.866],
        ["McGahey", 76.867],
        ["McDuff", 76.867],
        ["Mayweather", 76.868],
        ["Mastropietro", 76.868],
        ["Masten", 76.869],
        ["Maranto", 76.87],
        ["Maniscalco", 76.87],
        ["Maize", 76.871],
        ["Mahmood", 76.871],
        ["Maddocks", 76.872],
        ["Maday", 76.872],
        ["Macha", 76.873],
        ["Maag", 76.873],
        ["Luken", 76.874],
        ["Lopp", 76.874],
        ["Lolley", 76.875],
        ["Llanas", 76.876],
        ["Litz", 76.876],
        ["Litherland", 76.877],
        ["Lindenberg", 76.877],
        ["Lieu", 76.878],
        ["Letcher", 76.878],
        ["Lentini", 76.879],
        ["Lemelle", 76.879],
        ["Leet", 76.88],
        ["Lecuyer", 76.88],
        ["Leber", 76.881],
        ["Laursen", 76.881],
        ["Latch", 76.882],
        ["Larrick", 76.883],
        ["Lantigua", 76.883],
        ["Langlinais", 76.884],
        ["Lalli", 76.884],
        ["Lafever", 76.885],
        ["Labat", 76.885],
        ["Labadie", 76.886],
        ["Kurt", 76.886],
        ["Krogman", 76.887],
        ["Kohut", 76.887],
        ["Knarr", 76.888],
        ["Klimas", 76.888],
        ["Klar", 76.889],
        ["Kittelson", 76.89],
        ["Kirschbaum", 76.89],
        ["Kintzel", 76.891],
        ["Kincannon", 76.891],
        ["Kimmell", 76.892],
        ["Killgore", 76.892],
        ["Kettner", 76.893],
        ["Kelsch", 76.893],
        ["Karle", 76.894],
        ["Kapoor", 76.894],
        ["Johansson", 76.895],
        ["Jock", 76.896],
        ["Jenkinson", 76.896],
        ["Janney", 76.897],
        ["Isabelle", 76.897],
        ["Iraheta", 76.898],
        ["Insley", 76.898],
        ["Hyslop", 76.899],
        ["Hy", 76.899],
        ["Human", 76.9],
        ["Huckstep", 76.9],
        ["Holleran", 76.901],
        ["Hoerr", 76.901],
        ["Hinze", 76.902],
        ["Hinnenkamp", 76.903],
        ["Hilger", 76.903],
        ["Higgin", 76.904],
        ["Hicklin", 76.904],
        ["Heroux", 76.905],
        ["Henkle", 76.905],
        ["Helfer", 76.906],
        ["Heikkinen", 76.906],
        ["Heckstall", 76.907],
        ["Heckler", 76.907],
        ["Heavener", 76.908],
        ["Haydel", 76.908],
        ["Haveman", 76.909],
        ["Haubert", 76.91],
        ["Harrop", 76.91],
        ["Harnois", 76.911],
        ["Hansard", 76.911],
        ["Hanover", 76.912],
        ["Hammitt", 76.912],
        ["Haliburton", 76.913],
        ["Haefner", 76.913],
        ["Hadsell", 76.914],
        ["Haakenson", 76.914],
        ["Guynn", 76.915],
        ["Guizar", 76.916],
        ["Grout", 76.916],
        ["Grosz", 76.917],
        ["Goo", 76.917],
        ["Gomer", 76.918],
        ["Golla", 76.918],
        ["Godby", 76.919],
        ["Glanz", 76.919],
        ["Glancy", 76.92],
        ["Givan", 76.92],
        ["Giesen", 76.921],
        ["Gerst", 76.921],
        ["Gayman", 76.922],
        ["Garraway", 76.923],
        ["Gabor", 76.923],
        ["Furness", 76.924],
        ["Frisk", 76.924],
        ["Fremont", 76.925],
        ["Frary", 76.925],
        ["Forand", 76.926],
        ["Fessenden", 76.926],
        ["Ferrigno", 76.927],
        ["Fearon", 76.927],
        ["Favreau", 76.928],
        ["Faulks", 76.928],
        ["Falbo", 76.929],
        ["Ewen", 76.93],
        ["Everton", 76.93],
        ["Eurich", 76.931],
        ["Etchison", 76.931],
        ["Esterly", 76.932],
        ["Entwistle", 76.932],
        ["Ellingsworth", 76.933],
        ["Elders", 76.933],
        ["Ek", 76.934],
        ["Eisenbarth", 76.934],
        ["Edelson", 76.935],
        ["Eckel", 76.936],
        ["Earnshaw", 76.936],
        ["Dunneback", 76.937],
        ["Doyal", 76.937],
        ["Donnellan", 76.938],
        ["Dolin", 76.938],
        ["Dibiase", 76.939],
        ["Deschenes", 76.939],
        ["Dermody", 76.94],
        ["Denmark", 76.94],
        ["Degregorio", 76.941],
        ["Darnall", 76.941],
        ["Dant", 76.942],
        ["Dansereau", 76.943],
        ["Danaher", 76.943],
        ["Dammann", 76.944],
        ["Dames", 76.944],
        ["Czarnecki", 76.945],
        ["Cuyler", 76.945],
        ["Custard", 76.946],
        ["Cummingham", 76.946],
        ["Cuffie", 76.947],
        ["Cuffee", 76.947],
        ["Cudney", 76.948],
        ["Cuadra", 76.948],
        ["Crigler", 76.949],
        ["Creger", 76.95],
        ["Coughlan", 76.95],
        ["Corvin", 76.951],
        ["Cortright", 76.951],
        ["Corchado", 76.952],
        ["Connery", 76.952],
        ["Conforti", 76.953],
        ["Condron", 76.953],
        ["Colosimo", 76.954],
        ["Colclough", 76.954],
        ["Cola", 76.955],
        ["Cohee", 76.956],
        ["Claire", 76.956],
        ["Ciotti", 76.957],
        ["Chill", 76.957],
        ["Chien", 76.958],
        ["Check", 76.958],
        ["Chacko", 76.959],
        ["Cevallos", 76.959],
        ["Cavitt", 76.96],
        ["Cavins", 76.96],
        ["Castagna", 76.961],
        ["Cashwell", 76.961],
        ["Carrozza", 76.962],
        ["Carrara", 76.963],
        ["Capra", 76.963],
        ["Campas", 76.964],
        ["Callas", 76.964],
        ["Caison", 76.965],
        ["Cai", 76.965],
        ["Caggiano", 76.966],
        ["Cabot", 76.966],
        ["Bynoe", 76.967],
        ["Buswell", 76.967],
        ["Burpo", 76.968],
        ["Burnam", 76.968],
        ["Burges", 76.969],
        ["Buerger", 76.97],
        ["Buelow", 76.97],
        ["Bueche", 76.971],
        ["Buckle", 76.971],
        ["Bruni", 76.972],
        ["Brummitt", 76.972],
        ["Brodersen", 76.973],
        ["Briese", 76.973],
        ["Breit", 76.974],
        ["Brakebill", 76.974],
        ["Braatz", 76.975],
        ["Boyers", 76.976],
        ["Boughner", 76.976],
        ["Borror", 76.977],
        ["Borquez", 76.977],
        ["Bonelli", 76.978],
        ["Bohner", 76.978],
        ["Blaze", 76.979],
        ["Blaker", 76.979],
        ["Blackmer", 76.98],
        ["Bissette", 76.98],
        ["Bibbins", 76.981],
        ["Bhatt", 76.981],
        ["Bhatia", 76.982],
        ["Bessler", 76.983],
        ["Bergh", 76.983],
        ["Beresford", 76.984],
        ["Bensen", 76.984],
        ["Benningfield", 76.985],
        ["Benito", 76.985],
        ["Bellantoni", 76.986],
        ["Behler", 76.986],
        ["Beehler", 76.987],
        ["Beazley", 76.987],
        ["Beauchesne", 76.988],
        ["Bargo", 76.988],
        ["Bannerman", 76.989],
        ["Baltes", 76.99],
        ["Balog", 76.99],
        ["Ballantyne", 76.991],
        ["Bad", 76.991],
        ["Axelson", 76.992],
        ["Apgar", 76.992],
        ["Aoki", 76.993],
        ["Anstett", 76.993],
        ["Alejos", 76.994],
        ["Alcocer", 76.994],
        ["Albury", 76.995],
        ["Aichele", 76.996],
        ["Ahl", 76.996],
        ["Ackles", 76.997],
        ["Zerangue", 76.997],
        ["Zehner", 76.998],
        ["Zank", 76.998],
        ["Zacarias", 76.999],
        ["Youngberg", 76.999],
        ["Yorke", 77],
        ["Yarbro", 77],
        ["Xie", 77.001],
        ["Wydra", 77.001],
        ["Worthley", 77.002],
        ["Wolbert", 77.002],
        ["Wittmer", 77.003],
        ["Witherington", 77.003],
        ["Wishart", 77.004],
        ["Wire", 77.004],
        ["Winnie", 77.005],
        ["Winkleman", 77.006],
        ["Willilams", 77.006],
        ["Willer", 77.007],
        ["Wiedeman", 77.007],
        ["Whittingham", 77.008],
        ["Whitbeck", 77.008],
        ["Whetsel", 77.009],
        ["Wheless", 77.009],
        ["Westerberg", 77.01],
        ["Welcher", 77.01],
        ["Wegman", 77.011],
        ["Waterfield", 77.011],
        ["Wasinger", 77.012],
        ["Warfel", 77.012],
        ["Wannamaker", 77.013],
        ["Walborn", 77.013],
        ["Wada", 77.014],
        ["Vogl", 77.014],
        ["Vizcarrondo", 77.015],
        ["Vitela", 77.015],
        ["Villeda", 77.016],
        ["Veras", 77.017],
        ["Venuti", 77.017],
        ["Veney", 77.018],
        ["Ulrey", 77.018],
        ["Uhlig", 77.019],
        ["Turcios", 77.019],
        ["Tremper", 77.02],
        ["Torian", 77.02],
        ["Torbett", 77.021],
        ["Thrailkill", 77.021],
        ["Terrones", 77.022],
        ["Teitelbaum", 77.022],
        ["Teems", 77.023],
        ["Tay", 77.023],
        ["Swoope", 77.024],
        ["Sunseri", 77.024],
        ["Stutes", 77.025],
        ["Stthomas", 77.025],
        ["Strohm", 77.026],
        ["Stroble", 77.026],
        ["Striegel", 77.027],
        ["Streicher", 77.028],
        ["Stodola", 77.028],
        ["Stinchcomb", 77.029],
        ["Steves", 77.029],
        ["Steppe", 77.03],
        ["Stem", 77.03],
        ["Steller", 77.031],
        ["Staudt", 77.031],
        ["Starner", 77.032],
        ["Stamant", 77.032],
        ["Stam", 77.033],
        ["Stackpole", 77.033],
        ["Sprankle", 77.034],
        ["Speciale", 77.034],
        ["Spahr", 77.035],
        ["Sowders", 77.035],
        ["Sova", 77.036],
        ["Soluri", 77.036],
        ["Soderlund", 77.037],
        ["Slinkard", 77.038],
        ["Skates", 77.038],
        ["Sjogren", 77.039],
        ["Sirianni", 77.039],
        ["Siewert", 77.04],
        ["Sickels", 77.04],
        ["Sica", 77.041],
        ["Shugart", 77.041],
        ["Shoults", 77.042],
        ["Shive", 77.042],
        ["Shimer", 77.043],
        ["Shier", 77.043],
        ["Shield", 77.044],
        ["Shepley", 77.044],
        ["Sheeran", 77.045],
        ["Sharper", 77.045],
        ["Sevin", 77.046],
        ["Severe", 77.046],
        ["Seto", 77.047],
        ["Segundo", 77.047],
        ["Sedlacek", 77.048],
        ["Scuderi", 77.049],
        ["Schurman", 77.049],
        ["Schuelke", 77.05],
        ["Scholten", 77.05],
        ["Schlater", 77.051],
        ["Schisler", 77.051],
        ["Schiefelbein", 77.052],
        ["Schalk", 77.052],
        ["Sanon", 77.053],
        ["Sae", 77.053],
        ["Sabala", 77.054],
        ["Ruyle", 77.054],
        ["Ruybal", 77.055],
        ["Ruf", 77.055],
        ["Rueb", 77.056],
        ["Rowsey", 77.056],
        ["Rosol", 77.057],
        ["Rocheleau", 77.057],
        ["Rishel", 77.058],
        ["Rippey", 77.058],
        ["Ringgold", 77.059],
        ["Rieves", 77.06],
        ["Ridinger", 77.06],
        ["Rew", 77.061],
        ["Retherford", 77.061],
        ["Rempe", 77.062],
        ["Reith", 77.062],
        ["Rafter", 77.063],
        ["Raffaele", 77.063],
        ["Quinto", 77.064],
        ["Putz", 77.064],
        ["Purdom", 77.065],
        ["Puls", 77.065],
        ["Pulaski", 77.066],
        ["Propp", 77.066],
        ["Principato", 77.067],
        ["Preiss", 77.067],
        ["Prada", 77.068],
        ["Polansky", 77.068],
        ["Poch", 77.069],
        ["Plath", 77.07],
        ["Pittard", 77.07],
        ["Pinnock", 77.071],
        ["Pfarr", 77.071],
        ["Pfannenstiel", 77.072],
        ["Penniman", 77.072],
        ["Pauling", 77.073],
        ["Patchen", 77.073],
        ["Paschke", 77.074],
        ["Parkey", 77.074],
        ["Pando", 77.075],
        ["Overly", 77.075],
        ["Ouimet", 77.076],
        ["Ottman", 77.076],
        ["Otter", 77.077],
        ["Ostlund", 77.077],
        ["Ormiston", 77.078],
        ["Occhipinti", 77.078],
        ["Nowacki", 77.079],
        ["Norred", 77.079],
        ["Noack", 77.08],
        ["Nishida", 77.081],
        ["Nilles", 77.081],
        ["Nicodemus", 77.082],
        ["Neth", 77.082],
        ["Nealey", 77.083],
        ["Myricks", 77.083],
        ["Murff", 77.084],
        ["Mungia", 77.084],
        ["Mullet", 77.085],
        ["Motsinger", 77.085],
        ["Moscato", 77.086],
        ["Mort", 77.086],
        ["Morado", 77.087],
        ["Moors", 77.087],
        ["Monnier", 77.088],
        ["Molyneux", 77.088],
        ["Modzelewski", 77.089],
        ["Miura", 77.089],
        ["Minich", 77.09],
        ["Militello", 77.091],
        ["Milbrandt", 77.091],
        ["Michalik", 77.092],
        ["Meserve", 77.092],
        ["Merle", 77.093],
        ["Mendivil", 77.093],
        ["Melara", 77.094],
        ["Meadow", 77.094],
        ["McNish", 77.095],
        ["McElhannon", 77.095],
        ["McCroy", 77.096],
        ["McCrady", 77.096],
        ["Mazzella", 77.097],
        ["Maule", 77.097],
        ["Mattera", 77.098],
        ["Mathena", 77.098],
        ["Matas", 77.099],
        ["Mass", 77.099],
        ["Mascorro", 77.1],
        ["Marone", 77.1],
        ["Marinello", 77.101],
        ["Marguez", 77.102],
        ["Marcell", 77.102],
        ["Manwaring", 77.103],
        ["Manhart", 77.103],
        ["Mangano", 77.104],
        ["Maggi", 77.104],
        ["Lymon", 77.105],
        ["Luter", 77.105],
        ["Luse", 77.106],
        ["Lukasik", 77.106],
        ["Luiz", 77.107],
        ["Ludlum", 77.107],
        ["Luczak", 77.108],
        ["Lowenthal", 77.108],
        ["Lossett", 77.109],
        ["Lorentzen", 77.109],
        ["Loredo", 77.11],
        ["Longworth", 77.11],
        ["Lomanto", 77.111],
        ["Lisi", 77.111],
        ["Lish", 77.112],
        ["Lipsky", 77.113],
        ["Linck", 77.113],
        ["Liedtke", 77.114],
        ["Levering", 77.114],
        ["Lessman", 77.115],
        ["Lemond", 77.115],
        ["Lembo", 77.116],
        ["Ledonne", 77.116],
        ["Leatham", 77.117],
        ["Laufer", 77.117],
        ["Lanphear", 77.118],
        ["Langlais", 77.118],
        ["Lando", 77.119],
        ["Lamphear", 77.119],
        ["Lamberton", 77.12],
        ["Lafon", 77.12],
        ["Lade", 77.121],
        ["Lacross", 77.121],
        ["Kyzer", 77.122],
        ["Krok", 77.123],
        ["Kring", 77.123],
        ["Krell", 77.124],
        ["Krehbiel", 77.124],
        ["Kratochvil", 77.125],
        ["Krach", 77.125],
        ["Kovar", 77.126],
        ["Kostka", 77.126],
        ["Knudtson", 77.127],
        ["Knaack", 77.127],
        ["Kliebert", 77.128],
        ["Klahn", 77.128],
        ["Kirkley", 77.129],
        ["Kimzey", 77.129],
        ["Kettle", 77.13],
        ["Kerrick", 77.13],
        ["Kennerson", 77.131],
        ["Keesler", 77.131],
        ["Karlin", 77.132],
        ["Kan", 77.132],
        ["Jenny", 77.133],
        ["Janousek", 77.134],
        ["Jan", 77.134],
        ["Imel", 77.135],
        ["Icenhour", 77.135],
        ["Hyler", 77.136],
        ["Hunger", 77.136],
        ["Hudock", 77.137],
        ["Houpt", 77.137],
        ["Hopping", 77.138],
        ["Hoops", 77.138],
        ["Holquin", 77.139],
        ["Holiman", 77.139],
        ["Holahan", 77.14],
        ["Hodapp", 77.14],
        ["Hires", 77.141],
        ["Hillen", 77.141],
        ["Hickmon", 77.142],
        ["Hersom", 77.142],
        ["Henrich", 77.143],
        ["Helvey", 77.143],
        ["Heidt", 77.144],
        ["Heideman", 77.145],
        ["Hedstrom", 77.145],
        ["Hedin", 77.146],
        ["Hebron", 77.146],
        ["Hayter", 77.147],
        ["Harn", 77.147],
        ["Hardage", 77.148],
        ["Harbor", 77.148],
        ["Halsted", 77.149],
        ["Hahne", 77.149],
        ["Hagemann", 77.15],
        ["Guzik", 77.15],
        ["Guel", 77.151],
        ["Groesbeck", 77.151],
        ["Gritton", 77.152],
        ["Grego", 77.152],
        ["Graziani", 77.153],
        ["Grasty", 77.153],
        ["Graney", 77.154],
        ["Gouin", 77.155],
        ["Gossage", 77.155],
        ["Golston", 77.156],
        ["Goheen", 77.156],
        ["Godina", 77.157],
        ["Glade", 77.157],
        ["Giorgi", 77.158],
        ["Giambrone", 77.158],
        ["Gerrity", 77.159],
        ["Gerrish", 77.159],
        ["Gero", 77.16],
        ["Gerling", 77.16],
        ["Gaulke", 77.161],
        ["Garlick", 77.161],
        ["Galiano", 77.162],
        ["Gaiter", 77.162],
        ["Gahagan", 77.163],
        ["Gagnier", 77.163],
        ["Friddle", 77.164],
        ["Fredericksen", 77.164],
        ["Franqui", 77.165],
        ["Follansbee", 77.166],
        ["Foerster", 77.166],
        ["Flury", 77.167],
        ["Fitzmaurice", 77.167],
        ["Fiorini", 77.168],
        ["Finlayson", 77.168],
        ["Fiecke", 77.169],
        ["Fickes", 77.169],
        ["Fichter", 77.17],
        ["Ferron", 77.17],
        ["Ferdinand", 77.171],
        ["Farrel", 77.171],
        ["Fackler", 77.172],
        ["Eyman", 77.172],
        ["Escarcega", 77.173],
        ["Errico", 77.173],
        ["Erler", 77.174],
        ["Erby", 77.174],
        ["Engman", 77.175],
        ["Engelmann", 77.175],
        ["Elsass", 77.176],
        ["Elliston", 77.177],
        ["Eddleman", 77.177],
        ["Eadie", 77.178],
        ["Dummer", 77.178],
        ["Drost", 77.179],
        ["Dorrough", 77.179],
        ["Dorrance", 77.18],
        ["Doolan", 77.18],
        ["Donalson", 77.181],
        ["Domenico", 77.181],
        ["Ditullio", 77.182],
        ["Dittmar", 77.182],
        ["Dishon", 77.183],
        ["Dionisio", 77.183],
        ["Dike", 77.184],
        ["Devinney", 77.184],
        ["Desir", 77.185],
        ["Deschamp", 77.185],
        ["Derrickson", 77.186],
        ["Delamora", 77.187],
        ["Deitch", 77.187],
        ["Dechant", 77.188],
        ["Dave", 77.188],
        ["Danek", 77.189],
        ["Dahmen", 77.189],
        ["Curci", 77.19],
        ["Cudjoe", 77.19],
        ["Crumble", 77.191],
        ["Croxton", 77.191],
        ["Creasman", 77.192],
        ["Craney", 77.192],
        ["Crader", 77.193],
        ["Cowling", 77.193],
        ["Coulston", 77.194],
        ["Cortina", 77.194],
        ["Corlew", 77.195],
        ["Corl", 77.195],
        ["Copland", 77.196],
        ["Convery", 77.196],
        ["Cohrs", 77.197],
        ["Clune", 77.198],
        ["Clausing", 77.198],
        ["Cipriani", 77.199],
        ["Cinnamon", 77.199],
        ["Cianciolo", 77.2],
        ["Chubb", 77.2],
        ["Chittum", 77.201],
        ["Chenard", 77.201],
        ["Charlesworth", 77.202],
        ["Charlebois", 77.202],
        ["Champine", 77.203],
        ["Chamlee", 77.203],
        ["Chagoya", 77.204],
        ["Casselman", 77.204],
        ["Cardello", 77.205],
        ["Capasso", 77.205],
        ["Cannella", 77.206],
        ["Calderwood", 77.206],
        ["Byford", 77.207],
        ["Buttars", 77.207],
        ["Bushee", 77.208],
        ["Burrage", 77.209],
        ["Buentello", 77.209],
        ["Brzozowski", 77.21],
        ["Bryner", 77.21],
        ["Brumit", 77.211],
        ["Brookover", 77.211],
        ["Bronner", 77.212],
        ["Bromberg", 77.212],
        ["Brixey", 77.213],
        ["Brinn", 77.213],
        ["Briganti", 77.214],
        ["Bremner", 77.214],
        ["Brawn", 77.215],
        ["Branscome", 77.215],
        ["Brannigan", 77.216],
        ["Bradsher", 77.216],
        ["Bozek", 77.217],
        ["Boulay", 77.217],
        ["Bormann", 77.218],
        ["Bongiorno", 77.219],
        ["Bollin", 77.219],
        ["Bohler", 77.22],
        ["Bogert", 77.22],
        ["Bodenhamer", 77.221],
        ["Blose", 77.221],
        ["Blind", 77.222],
        ["Bivona", 77.222],
        ["Bitter", 77.223],
        ["Billips", 77.223],
        ["Bibler", 77.224],
        ["Benfer", 77.224],
        ["Benedetti", 77.225],
        ["Belue", 77.225],
        ["Bellanger", 77.226],
        ["Belford", 77.226],
        ["Behn", 77.227],
        ["Beerman", 77.227],
        ["Barnhardt", 77.228],
        ["Baltzell", 77.228],
        ["Balling", 77.229],
        ["Balducci", 77.23],
        ["Bainter", 77.23],
        ["Babineau", 77.231],
        ["Babich", 77.231],
        ["Baade", 77.232],
        ["Attwood", 77.232],
        ["Asmus", 77.233],
        ["Asaro", 77.233],
        ["Artiaga", 77.234],
        ["April", 77.234],
        ["Applebaum", 77.235],
        ["Ang", 77.235],
        ["Anding", 77.236],
        ["Amar", 77.236],
        ["Amaker", 77.237],
        ["Allsup", 77.237],
        ["Alligood", 77.238],
        ["Alers", 77.238],
        ["Agin", 77.239],
        ["Agar", 77.239],
        ["Achenbach", 77.24],
        ["Abramowitz", 77.241],
        ["Abbas", 77.241],
        ["Aasen", 77.242],
        ["Zehnder", 77.242],
        ["Yopp", 77.243],
        ["Yelle", 77.243],
        ["Yeldell", 77.244],
        ["Wynter", 77.244],
        ["Woodmansee", 77.245],
        ["Wooding", 77.245],
        ["Woll", 77.246],
        ["Winborne", 77.246],
        ["Willsey", 77.247],
        ["Willeford", 77.247],
        ["Widger", 77.248],
        ["Whiten", 77.248],
        ["Whitchurch", 77.249],
        ["Whang", 77.249],
        ["Wen", 77.25],
        ["Weissinger", 77.25],
        ["Weinman", 77.251],
        ["Weingartner", 77.251],
        ["Weidler", 77.252],
        ["Waltrip", 77.252],
        ["Walt", 77.253],
        ["Wagar", 77.253],
        ["Wafford", 77.254],
        ["Vitagliano", 77.254],
        ["Villalvazo", 77.255],
        ["Villacorta", 77.255],
        ["Vigna", 77.256],
        ["Vickrey", 77.256],
        ["Vicini", 77.257],
        ["Ventimiglia", 77.257],
        ["Vanden Bosch", 77.258],
        ["Valvo", 77.258],
        ["Valazquez", 77.259],
        ["Utsey", 77.259],
        ["Urbaniak", 77.26],
        ["Unzueta", 77.26],
        ["Trombetta", 77.261],
        ["Trevizo", 77.261],
        ["Trembley", 77.262],
        ["Tremaine", 77.262],
        ["Traverso", 77.263],
        ["Tores", 77.263],
        ["Tolan", 77.264],
        ["Tillison", 77.264],
        ["Tietjen", 77.265],
        ["Tee", 77.266],
        ["Teachout", 77.266],
        ["Taube", 77.267],
        ["Tatham", 77.267],
        ["Tarwater", 77.268],
        ["Tarbell", 77.268],
        ["Sydow", 77.269],
        ["Sy", 77.269],
        ["Swims", 77.27],
        ["Swader", 77.27],
        ["Striplin", 77.271],
        ["Stops", 77.271],
        ["Stoltenberg", 77.272],
        ["Steinhauer", 77.272],
        ["Steil", 77.273],
        ["Steigerwald", 77.273],
        ["Starkweather", 77.274],
        ["Stallman", 77.274],
        ["Squier", 77.275],
        ["Sparacino", 77.275],
        ["Span", 77.276],
        ["Spadafora", 77.276],
        ["Shiflet", 77.277],
        ["Shibata", 77.277],
        ["Shevlin", 77.278],
        ["Sherrick", 77.278],
        ["Shake", 77.279],
        ["Sessums", 77.279],
        ["Servais", 77.28],
        ["Senters", 77.28],
        ["Seevers", 77.281],
        ["Seelye", 77.281],
        ["Searfoss", 77.282],
        ["Seabrooks", 77.282],
        ["Scoles", 77.283],
        ["Schwager", 77.283],
        ["Schrom", 77.284],
        ["Schmeltzer", 77.284],
        ["Scheffel", 77.285],
        ["Sax", 77.285],
        ["Sawin", 77.286],
        ["Saterfiel", 77.286],
        ["Sardina", 77.287],
        ["Sanroman", 77.287],
        ["Sane", 77.288],
        ["Sandin", 77.288],
        ["Salamanca", 77.289],
        ["Saladin", 77.289],
        ["Sak", 77.29],
        ["Sabia", 77.29],
        ["Rustin", 77.291],
        ["Rushin", 77.291],
        ["Ruley", 77.292],
        ["Rueter", 77.292],
        ["Row", 77.293],
        ["Rotter", 77.293],
        ["Rosenzweig", 77.294],
        ["Roles", 77.294],
        ["Rohe", 77.295],
        ["Roder", 77.296],
        ["Rockey", 77.296],
        ["Ro", 77.297],
        ["Riter", 77.297],
        ["Rieth", 77.298],
        ["Ried", 77.298],
        ["Riding", 77.299],
        ["Riddles", 77.299],
        ["Ridder", 77.3],
        ["Rennick", 77.3],
        ["Remmers", 77.301],
        ["Remer", 77.301],
        ["Relyea", 77.302],
        ["Reilley", 77.302],
        ["Reder", 77.303],
        ["Rasheed", 77.303],
        ["Rakowski", 77.304],
        ["Rabin", 77.304],
        ["Queener", 77.305],
        ["Pursel", 77.305],
        ["Prue", 77.306],
        ["Prowell", 77.306],
        ["Pritts", 77.307],
        ["Primo", 77.307],
        ["Presler", 77.308],
        ["Pouncy", 77.308],
        ["Porche", 77.309],
        ["Porcaro", 77.309],
        ["Pollman", 77.31],
        ["Pleas", 77.31],
        ["Planas", 77.311],
        ["Pinkley", 77.311],
        ["Pinegar", 77.312],
        ["Pilger", 77.312],
        ["Philson", 77.313],
        ["Petties", 77.313],
        ["Perrodin", 77.314],
        ["Pendergrast", 77.314],
        ["Patao", 77.315],
        ["Pasternak", 77.315],
        ["Passarelli", 77.316],
        ["Pasko", 77.316],
        ["Parshall", 77.317],
        ["Panos", 77.317],
        ["Panella", 77.318],
        ["Palombo", 77.318],
        ["Padillo", 77.319],
        ["Oyama", 77.319],
        ["Overlock", 77.32],
        ["Overbeck", 77.32],
        ["Otterson", 77.321],
        ["Orrell", 77.321],
        ["Ornellas", 77.322],
        ["Opitz", 77.322],
        ["Okelly", 77.323],
        ["Officer", 77.323],
        ["Obando", 77.324],
        ["Noggle", 77.325],
        ["Nicosia", 77.325],
        ["Netto", 77.326],
        ["Negrin", 77.326],
        ["Natali", 77.327],
        ["Nakayama", 77.327],
        ["Nagao", 77.328],
        ["Nadel", 77.328],
        ["Musial", 77.329],
        ["Murrill", 77.329],
        ["Murrah", 77.33],
        ["Munsch", 77.33],
        ["Mucci", 77.331],
        ["Mrozek", 77.331],
        ["Moyes", 77.332],
        ["Mowrer", 77.332],
        ["Moris", 77.333],
        ["Morais", 77.333],
        ["Moorhouse", 77.334],
        ["Monico", 77.334],
        ["Mone", 77.335],
        ["Mondy", 77.335],
        ["Moncayo", 77.336],
        ["Mole", 77.336],
        ["Miltenberger", 77.337],
        ["Milsap", 77.337],
        ["Milone", 77.338],
        ["Millikin", 77.338],
        ["Milardo", 77.339],
        ["Mika", 77.339],
        ["Micheals", 77.34],
        ["Micco", 77.34],
        ["Meyerson", 77.341],
        ["Mericle", 77.341],
        ["Mendell", 77.342],
        ["Meinhardt", 77.342],
        ["Meachum", 77.343],
        ["McLeroy", 77.343],
        ["McGray", 77.344],
        ["McGonigal", 77.344],
        ["Maultsby", 77.345],
        ["Matis", 77.345],
        ["Matheney", 77.346],
        ["Matamoros", 77.346],
        ["Marro", 77.347],
        ["Marcil", 77.347],
        ["Marcial", 77.348],
        ["Mantz", 77.348],
        ["Mannings", 77.349],
        ["Maltby", 77.349],
        ["Malchow", 77.35],
        ["Maiorano", 77.35],
        ["Mahn", 77.351],
        ["Mahlum", 77.351],
        ["Maglio", 77.352],
        ["Mae", 77.352],
        ["Maberry", 77.353],
        ["Lustig", 77.354],
        ["Luellen", 77.354],
        ["Longwell", 77.355],
        ["Longenecker", 77.355],
        ["Lofland", 77.356],
        ["Locascio", 77.356],
        ["Linney", 77.357],
        ["Linneman", 77.357],
        ["Lighty", 77.358],
        ["Levell", 77.358],
        ["Levay", 77.359],
        ["Lenahan", 77.359],
        ["Lemen", 77.36],
        ["Lehto", 77.36],
        ["Lebaron", 77.361],
        ["Lanctot", 77.361],
        ["Lamy", 77.362],
        ["Lainez", 77.362],
        ["Laffoon", 77.363],
        ["LaBombard", 77.363],
        ["Kujawski", 77.364],
        ["Kroger", 77.364],
        ["Kreutzer", 77.365],
        ["Korhonen", 77.365],
        ["Kondo", 77.366],
        ["Kollman", 77.366],
        ["Kohan", 77.367],
        ["Kogut", 77.367],
        ["Knaus", 77.368],
        ["Kivi", 77.368],
        ["Kittel", 77.369],
        ["Kinner", 77.369],
        ["Kindig", 77.37],
        ["Kindel", 77.37],
        ["Kiesel", 77.371],
        ["Kidney", 77.371],
        ["Kibby", 77.372],
        ["Khang", 77.372],
        ["Kettler", 77.373],
        ["Ketterer", 77.373],
        ["Kepner", 77.374],
        ["Kelliher", 77.374],
        ["Keenum", 77.375],
        ["Kanode", 77.375],
        ["Kail", 77.376],
        ["July", 77.376],
        ["Juhasz", 77.377],
        ["Jowett", 77.377],
        ["Jolicoeur", 77.378],
        ["Jeon", 77.378],
        ["Iser", 77.379],
        ["Ingrassia", 77.379],
        ["Imai", 77.38],
        ["Hutchcraft", 77.38],
        ["Humiston", 77.381],
        ["Hulings", 77.381],
        ["Hukill", 77.382],
        ["Huizenga", 77.383],
        ["Hugley", 77.383],
        ["Huddle", 77.384],
        ["Hose", 77.384],
        ["Hornyak", 77.385],
        ["Hodder", 77.385],
        ["Hisle", 77.386],
        ["Hillenbrand", 77.386],
        ["Hille", 77.387],
        ["Higuchi", 77.387],
        ["Hertzler", 77.388],
        ["Herdon", 77.388],
        ["Heppner", 77.389],
        ["Hepp", 77.389],
        ["Heitmann", 77.39],
        ["Heckart", 77.39],
        ["Hazlewood", 77.391],
        ["Hayles", 77.391],
        ["Hayek", 77.392],
        ["Hawthorn", 77.392],
        ["Hawkin", 77.393],
        ["Haugland", 77.393],
        ["Hasler", 77.394],
        ["Harbuck", 77.394],
        ["Happel", 77.395],
        ["Hambly", 77.395],
        ["Hambleton", 77.396],
        ["Hagaman", 77.396],
        ["Guzzi", 77.397],
        ["Gullette", 77.397],
        ["Guinyard", 77.398],
        ["Grogg", 77.398],
        ["Grise", 77.399],
        ["Griffing", 77.399],
        ["Goto", 77.4],
        ["Gosney", 77.4],
        ["Goods", 77.401],
        ["Goley", 77.401],
        ["Goldblatt", 77.402],
        ["Gledhill", 77.402],
        ["Girton", 77.403],
        ["Giltner", 77.403],
        ["Gillock", 77.404],
        ["Gilham", 77.404],
        ["Gilfillan", 77.405],
        ["Giblin", 77.405],
        ["Gentner", 77.406],
        ["Gehlert", 77.406],
        ["Gehl", 77.407],
        ["Garten", 77.407],
        ["Garney", 77.408],
        ["Garlow", 77.408],
        ["Garett", 77.409],
        ["Galles", 77.409],
        ["Galeana", 77.41],
        ["Futral", 77.41],
        ["Fuhr", 77.411],
        ["Friedland", 77.412],
        ["Franson", 77.412],
        ["Fransen", 77.413],
        ["Foulds", 77.413],
        ["Follmer", 77.414],
        ["Foland", 77.414],
        ["Flax", 77.415],
        ["Flavin", 77.415],
        ["Firkins", 77.416],
        ["Fillion", 77.416],
        ["Figueredo", 77.417],
        ["Ferrill", 77.417],
        ["Fenster", 77.418],
        ["Fenley", 77.418],
        ["Fauver", 77.419],
        ["Farfan", 77.419],
        ["Factor", 77.42],
        ["Eustice", 77.42],
        ["Eppler", 77.421],
        ["Engelman", 77.421],
        ["Engelke", 77.422],
        ["Emmer", 77.422],
        ["Elzy", 77.423],
        ["Ellwood", 77.423],
        ["Ellerbee", 77.424],
        ["Elks", 77.424],
        ["Ehret", 77.425],
        ["Ebbert", 77.425],
        ["Durrah", 77.426],
        ["Dupras", 77.426],
        ["Dubuque", 77.427],
        ["Dragoo", 77.427],
        ["Donlon", 77.428],
        ["Dolloff", 77.428],
        ["Doi", 77.429],
        ["Dibella", 77.429],
        ["Derrico", 77.43],
        ["Demko", 77.43],
        ["Demar", 77.431],
        ["Darrington", 77.431],
        ["Czapla", 77.432],
        ["Crooker", 77.432],
        ["Creagh", 77.433],
        ["Cranor", 77.433],
        ["Craner", 77.434],
        ["Crafts", 77.434],
        ["Crabill", 77.435],
        ["Coyer", 77.435],
        ["Cowman", 77.436],
        ["Cowherd", 77.436],
        ["Cottone", 77.437],
        ["Costillo", 77.437],
        ["Coster", 77.438],
        ["Costas", 77.438],
        ["Cosenza", 77.439],
        ["Corker", 77.439],
        ["Collinson", 77.44],
        ["Coello", 77.441],
        ["Clingman", 77.441],
        ["Clingerman", 77.442],
        ["Claborn", 77.442],
        ["Citizen", 77.443],
        ["Chmura", 77.443],
        ["Chausse", 77.444],
        ["Chaudhry", 77.444],
        ["Chapell", 77.445],
        ["Chancy", 77.445],
        ["Cerrone", 77.446],
        ["Caves", 77.446],
        ["Caverly", 77.447],
        ["Caulkins", 77.447],
        ["Carn", 77.448],
        ["Campfield", 77.448],
        ["Campanelli", 77.449],
        ["Callaham", 77.449],
        ["Cadorette", 77.45],
        ["Butkovich", 77.45],
        ["Buske", 77.451],
        ["Burrier", 77.451],
        ["Burkley", 77.452],
        ["Bunyard", 77.452],
        ["Budge", 77.453],
        ["Buckelew", 77.453],
        ["Buchheit", 77.454],
        ["Broman", 77.454],
        ["Brescia", 77.455],
        ["Brasel", 77.455],
        ["Brain", 77.456],
        ["Boyster", 77.456],
        ["Booe", 77.457],
        ["Bonomo", 77.457],
        ["Bonnet", 77.458],
        ["Bondi", 77.458],
        ["Bohnsack", 77.459],
        ["Bobby", 77.459],
        ["Blomberg", 77.46],
        ["Blanford", 77.46],
        ["Bilderback", 77.461],
        ["Biggins", 77.461],
        ["Bently", 77.462],
        ["Behrends", 77.462],
        ["Beegle", 77.463],
        ["Bedoya", 77.463],
        ["Bechtol", 77.464],
        ["Beaubien", 77.464],
        ["Bayerl", 77.465],
        ["Baumgart", 77.465],
        ["Baumeister", 77.466],
        ["Barratt", 77.466],
        ["Barlowe", 77.467],
        ["Barkman", 77.467],
        ["Barbagallo", 77.468],
        ["Baldree", 77.468],
        ["Baine", 77.469],
        ["Bail", 77.47],
        ["Baggs", 77.47],
        ["Bacote", 77.471],
        ["Aylward", 77.471],
        ["Ashurst", 77.472],
        ["Arvidson", 77.472],
        ["Arthurs", 77.473],
        ["Arrieta", 77.473],
        ["Arrey", 77.474],
        ["Arreguin", 77.474],
        ["Arrant", 77.475],
        ["Arner", 77.475],
        ["Armor", 77.476],
        ["Arizmendi", 77.476],
        ["Anker", 77.477],
        ["Amis", 77.477],
        ["Amend", 77.478],
        ["Alphin", 77.478],
        ["Allbright", 77.479],
        ["Aikin", 77.479],
        ["Acres", 77.48]
    ];

    return {
        first: first,
        last: last
    };
});

/**
 * @name views.playoffs
 * @namespace Show current or archived playoffs, or projected matchups for an in-progress season.
 */
define('views/nationalPlayoff',["dao", "globals", "ui", "core/team", "lib/knockout", "util/bbgmView", "util/helpers", "views/components"], function (dao, g, ui, team, ko, bbgmView, helpers, components) {
    "use strict";

    function get(req) {
        return {
            season: helpers.validateSeason(req.params.season)
        };
    }

    function updatePlayoffs(inputs, updateEvents, vm) {

        if (updateEvents.indexOf("dbChange") >= 0 || updateEvents.indexOf("firstRun") >= 0 || inputs.season !== vm.season() || (inputs.season === g.season && updateEvents.indexOf("gameSim") >= 0)) {

         //   tx = g.dbl.transaction(["teams","games"]);
			
			
			var showExtraConferences,numberDivisions,showExtraConferences2;

		//	console.log(g.gameType);
			showExtraConferences = false;
			showExtraConferences2 = false;
			/*if ((g.gameType == 2) || (g.gameType == 4)) {
				showExtraConferences = true;					  
				showExtraConferences2 = true;
			} else if ((g.gameType == 1) || (g.gameType == 3)) {
				showExtraConferences = true;					  
			}*/						

			var numberConferences,showPlayoffs4,showPlayoffs32,showPlayoffs64;
	//		console.log(g.phase);
			numberConferences = 5;
			showPlayoffs4 = true;
			showPlayoffs32 = false;
			showPlayoffs64 = false;
			
/*			if ((g.gameType == 2) || (g.gameType == 4)) {
				numberConferences = 20;
				showExtraConferences = true;					  
			} */
					
			if (g.gameType == 0) {
				numberConferences = 5;
				//numberDivisions = 10;
				showExtraConferences = false;
				showExtraConferences2 = false;
				showPlayoffs4 = true;
				showPlayoffs32 = false;
				showPlayoffs64 = false;
			} else if (g.gameType == 1) {						
				numberConferences = 5;			
				showExtraConferences = true;
				showExtraConferences2 = false;
				showPlayoffs4 = true;
				showPlayoffs32 = false;
				showPlayoffs64 = false;
			} else if (g.gameType == 2) {						
				numberConferences = 10;			
				showExtraConferences = true;
				showExtraConferences2 = true;
				showPlayoffs4 = true;
				showPlayoffs32 = false;
				showPlayoffs64 = false;
			} else if ( (g.gameType == 3)) {
				numberConferences = 15;			
				showExtraConferences = false;
				showExtraConferences2 = false;
				showPlayoffs4 = false;
				showPlayoffs32 = true;
				showPlayoffs64 = false;
			} else if ( (g.gameType == 4)) {
				numberConferences = 20;			
				showExtraConferences = false;
				showExtraConferences2 = false;
				showPlayoffs4 = false;
				showPlayoffs32 = true;
				showPlayoffs64 = false;
			} else if ( (g.gameType == 5)) {
				numberConferences = 20;			
				showExtraConferences = false;
				showExtraConferences2 = false;
				showPlayoffs4 = false;
				showPlayoffs32 = false;
				showPlayoffs64 = true;
			} else if (g.gameType == 6) {						
				numberConferences = 5;			
				showExtraConferences = false;
				showExtraConferences2 = false;
				showPlayoffs4 = false;
				showPlayoffs32 = true;
				showPlayoffs64 = false;
				
			}						
								
			
			
            // If in the current season and before playoffs started, display projected matchups
            if (inputs.season === g.season && g.phase < g.PHASE.NATIONAL_PLAYOFFS) {
                return team.filter({
                    attrs: ["tid","did", "cid", "abbrev", "name"],
                    seasonAttrs: ["winpConf","winp"],
					stats: ["gp", "pts", "oppPts", "diff"],						
                    season: inputs.season
                //    sortBy: ["winpConf","winp", "-lost", "won"]
                }).then(function (teams) {
                  //  var cid, i, series, teamsConf;
				
					var game;
	
	//				return dao.games.getAll({
/*					dao.games.getAll({
						index: "season",
						key: g.season					
					}).then(function (game) { */
					
//					g.dbl.transaction("games").objectStore("games").get(inputs.season).onsuccess = function (event) {
//					g.dbl.transaction("games").objectStore("games").get(g.season).onsuccess = function (event) {
					/*g.dbl.transaction("games").objectStore("games").index("season").getAll(g.season).onsuccess = function (event) {
					
					//tx.objectStore("games").index("season").getAll(g.season).onsuccess = function (event) {
				
					game = event.target.result;*/
					return dao.games.getAll({
						index: "season",
						key: g.season					
					}).then(function (game) {					
					
							var i, j;
							
							var teamPointsFor,teamPointsAgainst,teamAdjPointsFor,teamAdjPointsAgainst,teamSchedule,teamOppPointsFor,teamOppPointsAgainst,teamOppOppPointsFor,teamOppOppPointsAgainst;
							
							var teamPowerRank;
							
							var trackGames,trackOpps,trackOppsOpps;
							var overallRankMetric;
			//                   console.log(g.season);

					/*			console.log("game.length"+game.length);
								console.log("keys"+Object.keys(game));*/
									
							teamPointsFor = [];
							teamPointsAgainst = [];
							teamAdjPointsFor = [];
							teamAdjPointsAgainst = [];
							teamOppPointsFor = [];
							teamOppPointsAgainst = [];
							teamOppOppPointsFor = [];
							teamOppOppPointsAgainst = [];
							trackGames = [];
							trackOpps = [];
							trackOppsOpps = [];
							teamSchedule = [];
							
							teamPowerRank = [];

							
							for (i = 0; i < g.numTeams; i++) {
								teamPointsFor[i] = [];
								teamPointsAgainst[i] = [];
								teamAdjPointsFor[i] = [];
								teamAdjPointsAgainst[i] = [];
								teamOppPointsFor[i] = [];
								teamOppPointsAgainst[i] = [];
								teamOppOppPointsFor[i] = [];
								teamOppOppPointsAgainst[i] = [];
								teamPowerRank[i] = [];					
								trackGames[i] = [];					
								trackOpps[i] = [];
								trackOppsOpps[i] = [];
								teamSchedule[i] = [];
								teamPointsFor[i] = 0;
								teamPointsAgainst[i] = 0;
								teamAdjPointsFor[i] = 0;
								teamAdjPointsAgainst[i] = 0;
								teamOppPointsFor[i] = 0;
								teamOppPointsAgainst[i] = 0;
								teamOppOppPointsFor[i] = 0;
								teamOppOppPointsAgainst[i] = 0;		
								trackGames[i] = 0;					
								trackOpps[i] = 0;
								trackOppsOpps[i] = 0;
								teamPowerRank[i] = 0;						
							}				
							
						  
						   for (i = 0; i < game.length; i++) {
									teamPointsFor[game[i].won.tid] += game[i].won.pts;
									teamPointsFor[game[i].lost.tid] += game[i].lost.pts;
									teamPointsAgainst[game[i].won.tid] += game[i].lost.pts;
									teamPointsAgainst[game[i].lost.tid] += game[i].won.pts;
									trackGames[game[i].won.tid] += 1;
									trackGames[game[i].lost.tid] += 1;
							}
							for (i = 0; i < g.numTeams; i++) {
								if (trackGames[i]> 0) {
									teamPointsFor[i] /= trackGames[i];
									teamPointsAgainst[i] /= trackGames[i];
								}
							}		


				
							//// now gather opponent data
							for (i = 0; i < g.numTeams; i++) {
								for (j = 0; j < game.length; j++) {
									if (i===game[j].won.tid) {
										teamOppPointsFor[i] += teamPointsFor[game[j].lost.tid];						   
										teamOppPointsAgainst[i] += teamPointsAgainst[game[j].lost.tid];		
										trackOpps[i] += 1;							
									} else if (i===game[j].lost.tid) {
										teamOppPointsFor[i] += teamPointsFor[game[j].won.tid];						
										teamOppPointsAgainst[i] += teamPointsAgainst[game[j].won.tid];						   
										trackOpps[i] += 1;								
									}				
								}				
							}
							
							for (i = 0; i < g.numTeams; i++) {
								if (trackOpps[i]> 0) {
									teamOppPointsFor[i] /= trackOpps[i];
									teamOppPointsAgainst[i] /= trackOpps[i];
								}
							}				
							

							//// now gather opponent opponent data
							for (i = 0; i < g.numTeams; i++) {
								for (j = 0; j < game.length; j++) {
									if (i===game[j].won.tid) {
										teamOppOppPointsFor[i] += teamOppPointsFor[game[j].lost.tid];						   
										teamOppOppPointsAgainst[i] += teamOppPointsAgainst[game[j].lost.tid];						   
										trackOppsOpps[i] += 1;							
									} else if (i===game[j].lost.tid) {
										teamOppOppPointsFor[i] += teamOppPointsFor[game[j].won.tid];						
										teamOppOppPointsAgainst[i] += teamOppPointsAgainst[game[j].won.tid];						   
										trackOppsOpps[i] += 1;							
									}				
								}				
							}				
							
							for (i = 0; i < g.numTeams; i++) {
								if (trackOppsOpps[i]> 0) {
									teamOppOppPointsFor[i] /= trackOppsOpps[i];
									teamOppOppPointsAgainst[i] /= trackOppsOpps[i];
								}
							}						
					

							// PERFORMANCE
							for (i = 0; i < g.numTeams; i++) {
								// Modulate point differential by recent record: +5 for 10-0 in last 10 and -5 for 0-10
							//	console.log(teams[i].lastTen);
							//	console.log(teams[i].diff);								
		//						teams[i].performance = teams[i].diff - 5 + 5 * (parseInt(teams[i].lastTen.split("-")[0], 10)) / 10;
								teams[i].performance = teams[i].diff;
							}								
							
							
							////now create SOS/RPI/PowerRank
							for (i = 0; i < g.numTeams; i++) {
								teams[i].power = (teamPointsFor[i]-teamPointsAgainst[i]+teamOppPointsFor[i]*2-teamOppPointsAgainst[i]*2+teamOppOppPointsFor[i]-teamOppOppPointsAgainst[i])/4;
							}				

							teams.sort(function (a, b) { return b.power - a.power; });
							for (i = 0; i < teams.length; i++) {
								teams[i].powerRank = i + 1;
							}	
							
							teams.sort(function (a, b) { return b.performance - a.performance; });
							for (i = 0; i < teams.length; i++) {
								teams[i].performanceRank = i + 1;
							}
										
							overallRankMetric = function (t) {
								return t.powerRank * 3 + t.performanceRank * 3;
							};							
							
							
							teams.sort(function (a, b) {
								return overallRankMetric(a) - overallRankMetric(b);
							});					
					
							for (i = 0; i < teams.length; i++) {

								teams[i].overallRank = i + 1;								
							//	console.log(teams[i].overallRank );								
							}						
					
							if ( (g.gameType < 3)) {
								var did,cid, i, series, teamsConf;

								
								series = [[], [], [], [], [], []];  // First round, second round, third round, fourth round
	//							series = [[], []];  // First round, second round, third round, fourth round
								teamsConf = [];
						  //      for (did = 0; did < numberDivisions; did++) {					
			//                    for (cid = 0; cid < 2; cid++) {
									for (i = 0; i < teams.length; i++) {
							//			  console.log("i: "+i+" power: "+teams[i].power+" name: "+teams[i].name);
			//                            if (teams[i].did === did) {
											teamsConf.push(teams[i]);
											//i = teams.length;
			//                            }
											
									}    											    
							 //   }
								
						 /*       for (i = 0; i < numberConferences; i++) {
								
									series[ i  ] = {home: teamsConf[i*2], away: teamsConf[i*2+1]};
			//						series[ i  ] = {home: teamsConf[i*2], away: teamsConf[teamTwo]};
									series[ i ].home.seed = 1;
									series[ i ].away.seed = 1;	

								}						*/
							/*	series[0][ 1  ] = {home: teamsConf[2], away: teamsConf[3]};
								series[0][ 1 ].home.seed = 1;
								series[0][ 1 ].away.seed = 2;	
								series[0][ 0  ] = {home: teamsConf[4], away: teamsConf[5]};
								series[0][ 0 ].home.seed = 1;
								series[0][ 0 ].away.seed = 2;	*/
								series[0][ 0  ] = {home: teamsConf[0], away: teamsConf[3]};
								series[0][ 0 ].home.seed = 1;
								series[0][ 0 ].away.seed = 4;	
								series[0][ 1  ] = {home: teamsConf[1], away: teamsConf[2]};
								series[0][ 1 ].home.seed = 2;
								series[0][ 1 ].away.seed = 3;	
								series[0][ 2  ] = {home: teamsConf[4], away: teamsConf[5]};
								series[0][ 2 ].home.seed = 5;
								series[0][ 2 ].away.seed = 6;	
								series[0][ 3  ] = {home: teamsConf[6], away: teamsConf[7]};
								series[0][ 3 ].home.seed = 7;
								series[0][ 3 ].away.seed = 8;	
								series[0][ 4  ] = {home: teamsConf[8], away: teamsConf[9]};
								series[0][ 4 ].home.seed = 9;
								series[0][ 4 ].away.seed = 10;	
								
								if (showExtraConferences) {
									for (i = 5; i < 10; i++) {
										series[0][ i  ] = {home: teamsConf[i*2], away: teamsConf[i*2+1]};
										series[0][ i ].home.seed = i*2+1;
										series[0][ i ].away.seed = i*2+2;	
									
									}					    										
								}
								if (showExtraConferences2) {
									for (i = 10; i < 38; i++) {
										series[0][ i  ] = {home: teamsConf[i*2], away: teamsConf[i*2+1]};
										series[0][ i ].home.seed = i*2+1;
										series[0][ i ].away.seed = i*2+2;	
									
									}					    										
								}
								
							} else if ( (g.gameType == 5)) {
						
								var bracket;
								var i, series, teamsConf;					
								series = [[], [], [], [], [], []];  // First round, second round, third round, fourth round
		//                    for (cid = 0; cid < 4; cid++) {
								teamsConf = [];
		//                        for (i = 0; i < teams.length; i++) {
								for (i = 0; i < 64; i++) {
										teamsConf.push(teams[i]);
								}
								
								for (bracket = 0; bracket < 4; bracket++) {
									series[0][bracket * 8] = {home: teams[3-bracket], away: teams[16*4-4+bracket]};
									series[0][bracket * 8].away.seed = 16;
									series[0][bracket * 8].home.seed = 1;
									series[0][1 + bracket * 8] = {home: teamsConf[8*4-4+bracket], away: teamsConf[9*4-4+bracket]};
									series[0][1 + bracket * 8].away.seed = 9;
									series[0][1 + bracket * 8].home.seed = 8;
									series[0][2 + bracket * 8] = {home: teamsConf[4*4-4+bracket], away: teamsConf[13*4-4+bracket]};
									series[0][2 + bracket * 8].away.seed = 13;
									series[0][2 + bracket * 8].home.seed = 4;
									series[0][3 + bracket * 8] = {home: teamsConf[5*4-4+bracket], away: teamsConf[12*4-4+bracket]};
									series[0][3 + bracket * 8].away.seed = 12;
									series[0][3 + bracket * 8].home.seed = 5;
									series[0][4 + bracket * 8] = {home: teamsConf[6*4-4+bracket], away: teamsConf[11*4-4+bracket]};
									series[0][4 + bracket * 8].away.seed = 11;
									series[0][4 + bracket * 8].home.seed = 6;
									series[0][5 + bracket * 8] = {home: teamsConf[3*4-4+bracket], away: teamsConf[14*4-4+bracket]};
									series[0][5 + bracket * 8].away.seed = 14;
									series[0][5 + bracket * 8].home.seed = 3;
									series[0][6 + bracket * 8] = {home: teamsConf[7*4-4+bracket], away: teamsConf[10*4-4+bracket]};
									series[0][6 + bracket * 8].away.seed = 10;
									series[0][6 + bracket * 8].home.seed = 7;
									series[0][7 + bracket * 8] = {home: teamsConf[2*4-4+bracket], away: teamsConf[15*4-4+bracket]};
									series[0][7 + bracket * 8].away.seed = 15;
									series[0][7 + bracket * 8].home.seed = 2;
								}									
							} else if ( (g.gameType > 2)) {
							
								var bracket;
								var i, series, teamsConf;
								
								
								series = [[], [], [], [], [], []];  // First round, second round, third round, fourth round
			//                    for (cid = 0; cid < 4; cid++) {
									teamsConf = [];
			//                        for (i = 0; i < teams.length; i++) {
									for (i = 0; i < 32; i++) {
											teamsConf.push(teams[i]);
									}
									
									for (bracket = 0; bracket < 4; bracket++) {
							/*			series[0][bracket * 8] = {home: teams[3-bracket], away: teams[16*4-4+bracket]};
										series[0][bracket * 8].away.seed = 16;
										series[0][bracket * 8].home.seed = 1;
										series[0][1 + bracket * 8] = {home: teamsConf[8*4-4+bracket], away: teamsConf[9*4-4+bracket]};
										series[0][1 + bracket * 8].away.seed = 9;
										series[0][1 + bracket * 8].home.seed = 8;
										series[0][2 + bracket * 8] = {home: teamsConf[4*4-4+bracket], away: teamsConf[13*4-4+bracket]};
										series[0][2 + bracket * 8].away.seed = 13;
										series[0][2 + bracket * 8].home.seed = 4;
										series[0][3 + bracket * 8] = {home: teamsConf[5*4-4+bracket], away: teamsConf[12*4-4+bracket]};
										series[0][3 + bracket * 8].away.seed = 12;
										series[0][3 + bracket * 8].home.seed = 5;
										series[0][4 + bracket * 8] = {home: teamsConf[6*4-4+bracket], away: teamsConf[11*4-4+bracket]};
										series[0][4 + bracket * 8].away.seed = 11;
										series[0][4 + bracket * 8].home.seed = 6;
										series[0][5 + bracket * 8] = {home: teamsConf[3*4-4+bracket], away: teamsConf[14*4-4+bracket]};
										series[0][5 + bracket * 8].away.seed = 14;
										series[0][5 + bracket * 8].home.seed = 3;
										series[0][6 + bracket * 8] = {home: teamsConf[7*4-4+bracket], away: teamsConf[10*4-4+bracket]};
										series[0][6 + bracket * 8].away.seed = 10;
										series[0][6 + bracket * 8].home.seed = 7;
										series[0][7 + bracket * 8] = {home: teamsConf[2*4-4+bracket], away: teamsConf[15*4-4+bracket]};
										series[0][7 + bracket * 8].away.seed = 15;
										series[0][7 + bracket * 8].home.seed = 2;*/
										
										series[0][bracket * 4] = {home: teamsConf[3-bracket], away: teamsConf[8*4-4+bracket]};
										series[0][bracket * 4].away.seed = 8;
										series[0][bracket * 4].home.seed = 1;
										series[0][1 + bracket * 4] = {home: teamsConf[4*4-4+bracket], away: teamsConf[5*4-4+bracket]};
										series[0][1 + bracket * 4].away.seed = 5;
										series[0][1 + bracket * 4].home.seed = 4;
										series[0][2 + bracket * 4] = {home: teamsConf[3*4-4+bracket], away: teamsConf[6*4-4+bracket]};
										series[0][2 + bracket * 4].away.seed = 6;
										series[0][2 + bracket * 4].home.seed = 3;
										series[0][3 + bracket * 4] = {home: teamsConf[2*4-4+bracket], away: teamsConf[7*4-4+bracket]};
										series[0][3 + bracket * 4].away.seed = 7;
										series[0][3+ bracket * 4].home.seed = 2;							
									}						
							
							}
											
						console.log(series);
						return {
								finalMatchups: false,
								showExtraConferences: showExtraConferences,
								showExtraConferences2: showExtraConferences2,
								showPlayoffs4: showPlayoffs4,
								showPlayoffs32: showPlayoffs32,
								showPlayoffs64: showPlayoffs64,							
								series: series,
								season: inputs.season
						};
					//}	
					});								
				});
            }
			
            // Display the current or archived playoffs
            return dao.playoffSeriesNational.get({key: inputs.season}).then(function (playoffSeriesNational) {
                var series;

                series = playoffSeriesNational.series;

                return {			
               /* // Display the current or archived playoffs
                g.dbl.transaction("playoffSeriesNational").objectStore("playoffSeriesNational").get(inputs.season).onsuccess = function (event) {
                    var i, j, playoffSeriesNational, series;

                    playoffSeriesNational = event.target.result;
                    series = playoffSeriesNational.series;

                    deferred.resolve({*/
                        finalMatchups: true,
						showExtraConferences: showExtraConferences,
						showExtraConferences2: showExtraConferences2,
						showPlayoffs4: showPlayoffs4,
						showPlayoffs32: showPlayoffs32,
						showPlayoffs64: showPlayoffs64,													
                        series: series,
                        season: inputs.season
                 };
            });
        }
    }
	
                 	

    function uiFirst(vm) {
        ko.computed(function () {
            ui.title("National Playoffs (NP) - " + vm.season());
        }).extend({throttle: 1});
    }

    function uiEvery(updateEvents, vm) {
        components.dropdown("national-playoff-dropdown", ["seasons"], [vm.season()], updateEvents);
    }

    return bbgmView.init({
        id: "nationalPlayoff",
        get: get,
        runBefore: [updatePlayoffs],
        uiFirst: uiFirst,
        uiEvery: uiEvery
    });
});

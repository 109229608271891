/**
 * @name views.negotiation
 * @namespace Contract negotiation.
 */
define('views/negotiation',["dao", "globals", "ui", "core/contractNegotiation", "core/player", "core/team", "lib/knockout", "util/bbgmView", "util/helpers"], function (dao, g, ui, contractNegotiation, player, team, ko, bbgmView, helpers) {

    "use strict";

    // Show the negotiations list if there are more ongoing negotiations
    function redirectNegotiationOrRoster(cancelled) {
        dao.negotiations.getAll().then(function (negotiations) {

            if (negotiations.length > 0) {
                ui.realtimeUpdate([], helpers.leagueUrl(["negotiation"]));
            } else if (cancelled) {
                ui.realtimeUpdate([], helpers.leagueUrl(["free_agents"]));
            } else {
                ui.realtimeUpdate([], helpers.leagueUrl(["roster"]));
            }
        });
    }

    function get(req) {
        var pid;

        pid = parseInt(req.params.pid, 10);

        return {
            pid: pid >= 0 ? pid : null // Null will load whatever the active one is
        };
    }

    function post(req) {
        var pid, teamAmountNew, teamYearsNew;

        pid = parseInt(req.params.pid, 10);

        if (req.params.hasOwnProperty("cancel")) {
            contractNegotiation.cancel(pid).then(function () {
                redirectNegotiationOrRoster(true);
            });
        } else if (req.params.hasOwnProperty("accept")) {
            contractNegotiation.accept(pid).then(function (error) {
                if (error !== undefined && error) {
                    helpers.errorNotify(error);
                }
                redirectNegotiationOrRoster(false);
            });
        } else if (req.params.hasOwnProperty("new")) {
            // If there is no active negotiation with this pid, 
			
            dao.negotiations.get({key: pid}).then(function (negotiation) {
                var tx;
                if (!negotiation) {
                    tx = dao.tx(["gameAttributes", "messages", "negotiations", "players","teams"], "readwrite");
                    contractNegotiation.create(tx, pid, false).then(function (error) {
                        tx.complete().then(function () {
                            if (error !== undefined && error) {
                                helpers.errorNotify(error);
                                ui.realtimeUpdate([], helpers.leagueUrl(["free_agents"]));
                            } else {
                                ui.realtimeUpdate([], helpers.leagueUrl(["negotiation", pid]));
                            }
                        });
                    });
                } else {
                    ui.realtimeUpdate([], helpers.leagueUrl(["negotiation", pid]));
                }
            });
        } else {
            // Make an offer to the player;
            teamAmountNew = parseInt(req.params.teamAmount * 10, 10);
            teamYearsNew = parseInt(req.params.teamYears, 10);

            // Any NaN?
            if (teamAmountNew !== teamAmountNew || teamYearsNew !== teamYearsNew) {
                ui.realtimeUpdate([], helpers.leagueUrl(["negotiation", pid]));
            } else {
                contractNegotiation.offer(pid, teamAmountNew, teamYearsNew).then(function () {
                    ui.realtimeUpdate([], helpers.leagueUrl(["negotiation", pid]));
                });
            }
        }
    }

     function updateNegotiation(inputs, updateEvents, vm) {
        // Call getAll so it works on null key
        return dao.negotiations.getAll({key: inputs.pid}).then(function (negotiations) {
            if (negotiations.length === 0) {
                return {
                    errorMessage: "No negotiation with player " + inputs.pid + " in progress."
                };
            }

            var negotiation = negotiations[0];

            negotiation.player.expiration = negotiation.player.years + g.season;
            // Adjust to account for in-season signings
            if (g.phase <= g.PHASE.AFTER_TRADE_DEADLINE) {
                negotiation.player.expiration -= 1;
            }

            // Can't flatten more because of the return errorMessage above
            return dao.players.get({
                key: negotiation.pid
            }).then(function (p) {
                p = player.filter(p, {
                    attrs: ["pid", "name", "freeAgentMood","age","city","state","age","miles"],
                    ratings: ["ovr", "pot", "hgt", "stre", "spd", "jmp", "endu","hnd", "ins", "dnk", "ft", "fg", "tp", "blk", "stl", "drb", "pss", "reb","cvr","kck", "skills"],
                    season: g.season,
                    showNoStats: true,
                    showRookies: true,
                    fuzz: true
                });
				
                // This can happen if a negotiation is somehow started with a retired player
                if (!p) {
                    contractNegotiation.cancel(negotiation.pid);
                    return {
                        errorMessage: "Invalid negotiation. Please try again."
                    };
                }				

                // See views.freeAgents for moods as well
				p.miles = helpers.round(p.miles[g.userTid], 0);
				
                if (p.freeAgentMood[g.userTid] < 0.25) {
                    p.mood = '<span class="text-success"><b>Eager to play for you.</b></span>';
                } else if (p.freeAgentMood[g.userTid] < 0.5) {
                    p.mood = '<b>Willing to play at your school.</b>';
                } else if (p.freeAgentMood[g.userTid] < 0.75) {
                    p.mood = '<span class="text-warning"><b>Thinks he can do better.</b></span>';
                } else {
                    p.mood = '<span class="text-danger"><b>Thinks playing for your program is a joke.</b></span>';
                }
                delete p.freeAgentMood;

				return dao.players.getAll({
					index: "tid",
					key: g.userTid
				}).then(function (userPlayers) {					
				
				/*g.dbl.transaction("players").objectStore("players").index("tid").count(g.userTid).onsuccess = function (event) {
					var userPlayers;

					userPlayers = event.target.result;*/
					
		/*			team.filter({
							seasonAttrs: ["cash"],			
							season: g.season
					}).then(function (t) {		*/
//					tx.objectStore("teams").get(g.userTid).onsuccess = function (event) {



             /*g.dbl.transaction("teams").objectStore("teams").get(inputs.tid).onsuccess = function (event) {
                    var barData, barSeasons, i, keys, t, teamAll, tempData;

                    t = event.target.result;
                    t.seasons.reverse();  // Most recent season first

                    keys = ["won", "hype", "pop", "att", "cash", "revenues", "expenses"];
                    barData = {};
                    for (i = 0; i < keys.length; i++) {
                        if (typeof t.seasons[0][keys[i]] !== "object") {
                            barData[keys[i]] = helpers.nullPad(_.pluck(t.seasons, keys[i]), showInt);
                        } else {
                            // Handle an object in the database
                            barData[keys[i]] = {};
                            tempData = _.pluck(t.seasons, keys[i]);
                            _.each(tempData[0], function (value, key, obj) {
                                barData[keys[i]][key] = helpers.nullPad(_.pluck(_.pluck(tempData, key), "amount"), showInt);
                            });
                        }
                    }*/



				/*	g.dbl.transaction("teams").objectStore("teams").get(g.userTid).onsuccess = function (event) {
						var t;
						t = event.target.result;
						t.seasons.reverse();  // Most recent season first*/
						
						/*t = team.filter(event.target.result, {
							seasonAttrs: ["cash"],			
							season: g.season
						});		*/
					    // console.log(t.seasons.length);
					    // console.log(t.tid);
					    // console.log(t.seasons[0]);
					    // console.log(t.seasons[0].cash);
					    // console.log(t.seasons[0].win);
					
					return team.filter({
							seasonAttrs: ["cash"],			
							season: g.season
					}).then(function (t) {
						
						return team.getPayroll(null, g.userTid).get(0).then(function (payroll) {

									var cash,cashAvailable;
		//							cash = _.pluck(t, "cash");
		//							cash = _.pluck(t.seasons[0], "cash");
			///////////////////						cash = t.seasons[0].cash;
		//							cashAvailable = cash[g.userTid] - payroll  ;
/////////////									cashAvailable = cash - payroll  ;
									// console.log(payroll);
									cash = _.pluck(t, "cash");
									cashAvailable = cash[g.userTid] - payroll/1000  ;
								//	console.log(cash[g.userTid]+" "+payroll+" "+userPlayers.length);
									return {
										salaryCap: g.salaryCap,
										payroll: payroll/1000,
										cash: cash[g.userTid],							
		//								cash: cash,							
										cashAvailable: cashAvailable ,							
										numRosterSpots: 70 -  userPlayers.length,	
										team: {region: g.teamRegionsCache[g.userTid], name: g.teamNamesCache[g.userTid]},
										player: p,
										negotiation: {
											team: {
												amount: negotiation.team.amount,
												years: negotiation.team.years
											},
											player: {
												amount: negotiation.player.amount,
												expiration: negotiation.player.expiration,
												years: negotiation.player.years
											},
											resigning: negotiation.resigning
										}
									};
						});
					});						
				});
			});
        });
    }

    function uiFirst(vm) {
        ko.computed(function () {
            ui.title("Contract Negotiation - " + vm.player.name());
        }).extend({throttle: 1});
    }

    return bbgmView.init({
        id: "negotiation",
        get: get,
        post: post,
        runBefore: [updateNegotiation],
        uiFirst: uiFirst
    });
});

/**
 * @name views.freeAgents
 * @namespace List of free agents.
 */
define('views/freeAgents',["dao", "globals", "ui", "core/freeAgents", "core/player", "core/team", "lib/bluebird", "lib/jquery", "lib/knockout", "lib/underscore", "util/bbgmView", "util/helpers"], function (dao, g, ui, freeAgents, player, team, Promise, $, ko, _, bbgmView, helpers) {

    "use strict";

    var mapping;

    function disableButtons() {
        $("#free-agents button").attr("disabled", "disabled");
        $("#game-sim-warning").show();
    }

    function enableButtons() {
        $("#free-agents button").removeAttr("disabled");
        $("#game-sim-warning").hide();
    }

    function get(req) {
//        if (g.phase >= g.PHASE.AFTER_TRADE_DEADLINE && g.phase <= g.PHASE.RESIGN_PLAYERS) {
        if ((g.phase >= g.PHASE.REGULAR_SEASON && g.phase <= g.PHASE.BEFORE_DRAFT) && (g.sizeType > 1)) {
		
            if (g.phase === g.PHASE.RESIGN_PLAYERS) {
                return {
                    redirectUrl: helpers.leagueUrl(["negotiation"])
                };
            }

            return {
                errorMessage: "You're not allowed to sign recruits now."
            };
        }
    }

    mapping = {
        players: {
            create: function (options) {
                return options.data;
            }
        }
    };

    function updateFreeAgents() {
		
		

        // DIRTY QUICK FIX FOR v10 db upgrade bug - eventually remove
        // This isn't just for v10 db upgrade! Needed the same fix for http://www.reddit.com/r/BasketballGM/comments/2tf5ya/draft_bug/cnz58m2?context=3 - draft class not always generated with the correct seasons
        var tx = dao.tx("players", "readwrite");
        dao.players.get({
            ot: tx,
            index: "tid",
            key: g.PLAYER.UNDRAFTED
        }).then(function (p) {
            var season;

			console.log(p.ratings[0].season);
			console.log(g.phase);
			console.log(g.PHASE.FREE_AGENCY);
			console.log(p.length);

            season = p.ratings[0].season;
            if (season !== g.season && g.phase === g.PHASE.FREE_AGENCY) {
console.log("FIXING FUCKED UP DRAFT CLASS");
console.log(season);
//console.log(g.PLAYER.UNDRAFTED);
//console.log(g.PLAYER.FREE_AGENT);

                dao.players.iterate({
                    ot: tx,
                    index: "tid",
//                    key: g.PLAYER.UNDRAFTED,
                    key: g.PLAYER.FREE_AGENT,					
                    callback: function (p) {
						//console.log(p.ratings[0].season+" "+p.draft.year+" "+g.season+" "+p.tid);
						
                        p.ratings[0].season = g.season;
                    //    p.draft.year = g.season;
					//	console.log(p.ratings[0].season+" "+p.draft.year+" "+g.season);						
                        return p;
                    }
                });
            }
        });

        return tx.complete().then(function () {
            return Promise.all([ 		
//        return Promise.all([
            team.getPayroll(null, g.userTid).get(0),
           team.filter({
                attrs: ["strategy"],
				seasonAttrs: ["cash","hype"],			
                season: g.season
            }),			
            dao.players.getAll({
                index: "tid",
                key: g.userTid
            }),
            dao.players.getAll({
                index: "tid",
                key: g.PLAYER.FREE_AGENT,
                statsSeasons: [g.season, g.season - 1]     
            })					
          ]);			
        }).spread(function (payroll,teams, userPlayers, players) {			
        //]).spread(function (payroll,teams, userPlayers, players) {
            var capSpace, i;
			var cash,hype;

            cash = _.pluck(teams, "cash");
			hype = _.pluck(teams, "hype");
			
            capSpace = (cash[g.userTid] - payroll) ;

            if (capSpace < 0) {
                capSpace = 0;
            }

            players = player.filter(players, {
							attrs: ["pid", "name", "pos", "age", "year","contract", "freeAgentMood", "injury", "watch","miles","city","state"],
							ratings: ["ovr", "pot", "skills", "hgt", "stre", "spd", "jmp", "endu","hnd"],
					//		stats: ["min", "pts", "trb", "ast", "per","qbr","ruya","reyc","fgaMidRange","gp"],
							season: g.season,
							showNoStats: true,
							showRookies: true,
							fuzz: true,
							oldStats: true
						}); 

						// console.log(players.length);
						for (i = 0; i < players.length; i++) {
							//console.log(players[i].contract.amount);
/*							players[i].contract.amount = freeAgents.amountWithMood(players[i].contract.amount, players[i].freeAgentMood[g.userTid]);
							players[i].mood = player.moodColorText(players[i]);
							players[i].composite = players[i].ratings.ovr+players[i].ratings.pot;*/
						
							players[i].contract.amount = freeAgents.amountWithMoodMilesHype(players[i].contract.amount, players[i].freeAgentMood[g.userTid], players[i].miles[g.userTid],hype[g.userTid]);
//							players[i].contract.amount = freeAgents.amountWithMoodMilesHype(players[i].contract.amount, players[i].freeAgentMood[g.userTid], players[i].miles[g.userTid],hype);
						//	console.log(players[i].contract.amount);
							
							if (g.daysLeft == 0 ) {
								players[i].contract.amount *= .6 ;
								players[i].contract.amount -= 20000 ;								
								if (players[i].contract.amount<0) {
									players[i].contract.amount = 0 ;
								}
															  
							}
							
							players[i].mood = player.moodColorText(players[i]);
							players[i].composite = players[i].ratings.ovr+players[i].ratings.pot;

						
						}

            return {
							capSpace: capSpace,
	//						numRosterSpots: 52 - userPlayers.length,					
	//						numRosterSpots: 53 - userPlayers,					
							numRosterSpots: g.maxRosterSize - userPlayers.length,					
		//					numRosterSpots: 52 ,					
							players: players
            };
        });
    }
	
           /* return {
                capSpace: capSpace,
                players: players
            };
        });
    }	*/ // use college basketball coach to integrate
	
	

    function uiFirst(vm) {
        ui.title("Potential Recruits");

        $("#help-salary-cap").popover({
            title: "Cap Space",
            html: true,
            content: "<p>\"Cap space\" is the difference between your current payroll and the salary cap. You can sign a free agent to any valid contract as long as you don't go over the cap.</p>You can only exceed the salary cap to sign free agents to minimum contracts ($" + g.minContract + "k/year)."
        });

        ko.computed(function () {
            ui.datatable($("#free-agents"), 4, _.map(vm.players(), function (p) {
                var negotiateButton;
                if (freeAgents.refuseToNegotiate(p.contract.amount , p.freeAgentMood[g.userTid])) {
                    negotiateButton = "Refuses!";
                } else {
                    negotiateButton = '<form action="' + helpers.leagueUrl(["negotiation", p.pid], {noQueryString: true}) + '" method="POST" style="margin: 0"><input type="hidden" name="new" value="1"><button type="submit" class="btn btn-default btn-xs">Recruit</button></form>';
                }
                // The display: none for mood allows sorting, somehow
//                return [helpers.playerNameLabels(p.pid, p.name, p.injury, p.ratings.skills, p.watch), p.pos, String(p.age), String(p.ratings.ovr), String(p.ratings.pot), helpers.round(p.stats.gp, 0), helpers.round(p.stats.qbr, 1), helpers.round(p.stats.ruya, 1), helpers.round(p.stats.reyc, 1), helpers.round(p.stats.fgaMidRange, 0), helpers.formatCurrency(p.contract.amount, "M") + ' thru ' + p.contract.exp, '<div title="' + p.mood.text + '" style="width: 100%; height: 21px; background-color: ' + p.mood.color + '"><span style="display: none">' + p.freeAgentMood[g.userTid] + '</span></div>', negotiateButton];
                return [helpers.playerNameLabels(p.pid, p.name, p.injury, p.ratings.skills, p.watch), p.pos, p.year,p.city,p.state, helpers.round(p.miles[g.userTid], 0)  , String(p.ratings.ovr), String(p.ratings.pot), String(p.composite), String(p.ratings.hgt),String(p.ratings.stre),String(p.ratings.spd),String(p.ratings.jmp),String(p.ratings.endu),String(p.ratings.hnd), helpers.formatCurrency(p.contract.amount, "") ,'<div title="' + p.mood.text + '" style="width: 100%; height: 21px; background-color: ' + p.mood.color + '"><span style="display: none">' + p.freeAgentMood[g.userTid] + '</span></div>', negotiateButton];
				
            }));
        }).extend({throttle: 1});

        ui.tableClickableRows($("#free-agents"));

        // Form enabling/disabling
        $("#free-agents").on("gameSimulationStart", function () {
            disableButtons();
        });
        $("#free-agents").on("gameSimulationStop", function () {
            enableButtons();
        });
    }

    function uiEvery() {
        // Wait for datatable
        setTimeout(function () {
            if (g.gamesInProgress) {
                disableButtons();
            } else {
                enableButtons();
            }
        }, 10);
    }

    return bbgmView.init({
        id: "freeAgents",
        get: get,
        mapping: mapping,
        runBefore: [updateFreeAgents],
        uiFirst: uiFirst,
        uiEvery: uiEvery
    });
});

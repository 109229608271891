/**
 * @name views.playoffs
 * @namespace Show current or archived playoffs, or projected matchups for an in-progress season.
 */
define('views/conferenceChampionships',["dao", "globals", "ui", "core/team", "lib/knockout", "util/bbgmView", "util/helpers", "views/components"], function (dao, g, ui, team, ko, bbgmView, helpers, components) {
    "use strict";

    function get(req) {
        return {
            season: helpers.validateSeason(req.params.season)
        };
    }

    function updatePlayoffs(inputs, updateEvents, vm) {

        if (updateEvents.indexOf("dbChange") >= 0 || updateEvents.indexOf("firstRun") >= 0 || inputs.season !== vm.season() || (inputs.season === g.season && updateEvents.indexOf("gameSim") >= 0)) {

			var numberConferences, showExtraConferencesSecond,showPowerConferences,showIIIPowerConferencesFirst,showIIIPowerConferencesSecond,showExtraConferencesFirst;
		//	console.log(g.phase);
			numberConferences = 5;
			showPowerConferences = true;
			showExtraConferencesSecond = false;
			showIIIPowerConferencesSecond = false;
			showIIIPowerConferencesFirst = false;
			showExtraConferencesFirst = false;
			
/*			if ((g.gameType == 2) || (g.gameType == 4)) {
				numberConferences = 20;
				showExtraConferences = true;					  
			} */
					
			if (g.gameType == 0) {
				numberConferences = 5;
				//numberDivisions = 10;
				showExtraConferencesFirst = false;
				showExtraConferencesSecond = false;
				showPowerConferences = true;
				showIIIPowerConferencesFirst = false;
				showIIIPowerConferencesSecond = false;
			} else if ((g.gameType == 1) && (g.gameType == 6)) {						
				numberConferences = 5;			
				showExtraConferencesFirst = false;
				showExtraConferencesSecond = false;
				showPowerConferences = true;
				showIIIPowerConferencesFirst = false;
				showIIIPowerConferencesSecond = false;
			} else if (g.gameType == 2) {						
				numberConferences = 10;			
				showExtraConferencesFirst = false;
				showExtraConferencesSecond = false;
				showPowerConferences = false;
				showIIIPowerConferencesFirst = false;
				showIIIPowerConferencesSecond = true;
			} else if ( (g.gameType == 3)) {
				numberConferences = 15;			
				showExtraConferencesFirst = true;				
				showExtraConferencesSecond = false;
				showPowerConferences = false;
				showIIIPowerConferencesFirst = false;
				showIIIPowerConferencesSecond = false;
			} else if ( (g.gameType == 4)) {
				numberConferences = 20;			
				showExtraConferencesFirst = false;
				showExtraConferencesSecond = true;
				showPowerConferences = false;
				showIIIPowerConferencesFirst = true;
				showIIIPowerConferencesSecond = false;
			} else if ( (g.gameType == 5)) {
				numberConferences = 20;			
				showExtraConferencesFirst = false;
				showExtraConferencesSecond = true;
				showPowerConferences = true;
				showIIIPowerConferencesFirst = false;
				showIIIPowerConferencesSecond = false;
			}						
					
					
            // If in the current season and before playoffs started, display projected matchups
            if (inputs.season === g.season && g.phase < g.PHASE.PLAYOFFS) {
                return team.filter({
                    attrs: ["tid","did", "cid", "abbrev", "name"],
                    seasonAttrs: ["winpConf","winp"],
                    season: inputs.season,
                    sortBy: ["winpConf","winp", "-lost", "won"]
                }).then(function (teams) {
                    var did,cid, i, series, teamsConf;
					var numberDivisions;

				/*	numberDivisions = 10;
					if ((g.gameType == 2) || (g.gameType == 4)) {
						numberDivisions = 40;
					} */
					
                    series = [];  // First round, second round, third round, fourth round
                    teamsConf = [];
                    for (did = 0; did < (numberConferences*2); did++) {					
//                    for (cid = 0; cid < 2; cid++) {
                        for (i = 0; i < teams.length; i++) {
                            if (teams[i].did === did) {
                                teamsConf.push(teams[i]);
								i = teams.length;
                            }
                        }    											    
                    }
		//			console.log(g.gameType);
					

                    for (i = 0; i < numberConferences; i++) {
					
						series[ i  ] = {home: teamsConf[i*2], away: teamsConf[i*2+1]};
//						series[ i  ] = {home: teamsConf[i*2], away: teamsConf[teamTwo]};
						series[ i ].home.seed = 1;
						series[ i ].away.seed = 1;	

					}						
/*					series[0][ 1  ] = {home: teamsConf[2], away: teamsConf[3]};
					series[0][ 1 ].home.seed = 1;
					series[0][ 1 ].away.seed = 2;	
					series[0][ 0  ] = {home: teamsConf[4], away: teamsConf[5]};
					series[0][ 0 ].home.seed = 1;
					series[0][ 0 ].away.seed = 2;	
					series[0][ 0  ] = {home: teamsConf[6], away: teamsConf[7]};
					series[0][ 0 ].home.seed = 1;
					series[0][ 0 ].away.seed = 2;	
					series[0][ 0  ] = {home: teamsConf[8], away: teamsConf[9]};
					series[0][ 0 ].home.seed = 1;
					series[0][ 0 ].away.seed = 2;	*/
					
                    return {
                        finalMatchups: false,
//                        showExtraConferences: showExtraConferences,
						showPowerConferences:showPowerConferences,
						showExtraConferencesSecond:showExtraConferencesSecond,
						showIIIPowerConferencesSecond:showIIIPowerConferencesSecond,
						showIIIPowerConferencesFirst:showIIIPowerConferencesFirst,
						showExtraConferencesFirst:showExtraConferencesFirst,						
                        series: series,
                        season: inputs.season
                    };
                });
            }
                // Display the current or archived playoffs
            // Display the current or archived playoffs
            return dao.playoffSeries.get({key: inputs.season}).then(function (playoffSeries) {
                var series;

                series = playoffSeries.series;

                return {
                        finalMatchups: true,
//                        showExtraConferences: showExtraConferences,
						showPowerConferences:showPowerConferences,
						showExtraConferencesSecond:showExtraConferencesSecond,
						showIIIPowerConferencesSecond:showIIIPowerConferencesSecond,
						showIIIPowerConferencesFirst:showIIIPowerConferencesFirst,
						showExtraConferencesFirst:showExtraConferencesFirst,						
                        series: series,
                        season: inputs.season
                };
            });
        }
    }

    function uiFirst(vm) {
        ko.computed(function () {
            ui.title("Conference Championships - " + vm.season());
        }).extend({throttle: 1});
    }

    function uiEvery(updateEvents, vm) {
        components.dropdown("conference-championships-dropdown", ["seasons"], [vm.season()], updateEvents);
    }

    return bbgmView.init({
        id: "conferenceChampionships",
        get: get,
        runBefore: [updatePlayoffs],
        uiFirst: uiFirst,
        uiEvery: uiEvery
    });
});

/**
 * @name data.location
 * @namespace First names and last names.
 */
define('data/locationPlayer',[], function () {
    "use strict";

    var location;

    // http://www.unitedstateszipcodes.org/
    // Pop 10-18, Cumulative Frequency
    location = [
		["El Paso","TX",31.84,-106.43,21095],
		["Chicago","IL",41.83,-87.68,41294],
		["Los Angeles","CA",34,-118.25,61331],
		["Bell Gardens","CA",33.97,-118.15,80702],
		["Fontana","CA",34.08,-117.46,99337],
		["Pacoima","CA",34.25,-118.41,117670],
		["Brownsville","TX",25.92,-97.48,135500],
		["Norwalk","CA",33.9,-118.07,153289],
		["Katy","TX",29.79,-95.82,170624],
		["Fontana","CA",34.14,-117.45,187696],
		["South Gate","CA",33.94,-118.2,204749],
		["Long Beach","CA",33.86,-118.18,221666],
		["Bakersfield","CA",35.2,-118.86,238196],
		["Chicago","IL",41.83,-87.68,254684],
		["Brooklyn","NY",40.64,-73.94,271092],
		["Los Angeles","CA",33.95,-118.29,287281],
		["Chicago","IL",41.83,-87.68,303249],
		["Rialto","CA",34.1,-117.38,319194],
		["Brooklyn","NY",40.64,-73.94,335058],
		["Sylmar","CA",34.32,-118.38,350556],
		["Bronx","NY",40.84,-73.87,366052],
		["Grand Prairie","TX",32.69,-97.01,381416],
		["Chicago","IL",41.83,-87.68,396751],
		["Houston","TX",29.76,-95.38,411908],
		["La Puente","CA",34.02,-117.93,427043],
		["Cicero","IL",41.84,-87.75,442060],
		["Palmdale","CA",34.52,-118.08,457035],
		["Santa Ana","CA",33.71,-117.9,471884],
		["Riverside","CA",33.89,-117.44,486645],
		["Hesperia","CA",34.36,-117.32,501320],
		["San Juan","PR",18.4,-66.06,515922],
		["Brooklyn","NY",40.64,-73.94,530455],
		["Brooklyn","NY",40.64,-73.94,544957],
		["Dallas","TX",32.79,-96.76,559384],
		["Moreno Valley","CA",33.92,-117.24,573789],
		["Oxnard","CA",34.14,-119.1,588109],
		["Lancaster","CA",34.69,-117.9,602294],
		["Hawthorne","CA",33.91,-118.34,616375],
		["Bronx","NY",40.84,-73.87,630444],
		["Brooklyn","NY",40.64,-73.94,644433],
		["Chula Vista","CA",32.61,-117.03,658361],
		["Bronx","NY",40.84,-73.87,672270],
		["Chicago","IL",41.83,-87.68,686104],
		["Riverside","CA",33.99,-117.44,699929],
		["College Station","TX",30.6,-96.31,713738],
		["Corona","NY",40.74,-73.85,727521],
		["Laredo","TX",27.53,-99.48,741289],
		["Brooklyn","NY",40.64,-73.94,755046],
		["Baldwin Park","CA",34.09,-117.96,768764],
		["Bronx","NY",40.84,-73.87,782403],
		["Lawrenceville","GA",33.94,-83.99,796039],
		["Huntington Park","CA",33.97,-118.21,809646],
		["Temecula","CA",33.53,-117.04,823249],
		["Fresno","CA",36.79,-119.92,836727],
		["Watsonville","CA",36.95,-121.72,850200],
		["Porterville","CA",35.96,-118.92,863523],
		["Albuquerque","NM",35.11,-106.62,876769],
		["Mission","TX",26.21,-98.31,890007],
		["Lakewood","NJ",40.09,-74.21,903209],
		["Anaheim","CA",33.81,-117.97,916251],
		["Lynwood","CA",33.92,-118.2,929281],
		["Pittsburg","CA",38,-121.83,942295],
		["Chino Hills","CA",33.94,-117.72,955305],
		["Caguas","PR",18.23,-66.03,968294],
		["Katy","TX",29.79,-95.82,981283],
		["Los Angeles","CA",33.96,-118.27,994228],
		["Sugar Land","TX",29.61,-95.61,1007130],
		["Sacramento","CA",38.47,-121.44,1020028],
		["Yuma","AZ",32.68,-114.66,1032918],
		["San Diego","CA",32.56,-117.01,1045793],
		["Pomona","CA",34.03,-117.75,1058656],
		["Aurora","IL",41.76,-88.29,1071483],
		["Pharr","TX",26.18,-98.18,1084302],
		["Brooklyn","NY",40.64,-73.94,1097119],
		["Stockton","CA",37.9,-121.4,1109924],
		["Bellflower","CA",33.88,-118.13,1122708],
		["Edinburg","TX",26.35,-98.11,1135475],
		["Chino","CA",33.98,-117.65,1148201],
		["Las Vegas","NV",36.17,-115.05,1160887],
		["Houston","TX",29.76,-95.38,1173560],
		["Bronx","NY",40.84,-73.87,1186207],
		["Dallas","TX",32.79,-96.76,1198826],
		["Ridgewood","NY",40.7,-73.89,1211443],
		["Brooklyn","NY",40.64,-73.94,1224041],
		["Tulare","CA",36.18,-119.36,1236609],
		["Lawrenceville","GA",33.94,-83.99,1249137],
		["Provo","UT",40.33,-111.57,1261627],
		["Westminster","CA",33.74,-117.99,1274076],
		["Lancaster","CA",34.72,-118.48,1286441],
		["San Diego","CA",32.73,-117.08,1298794],
		["Toa Alta","PR",18.39,-66.25,1311107],
		["Mission","TX",26.31,-98.35,1323378],
		["Philadelphia","PA",39.95,-75.16,1335631],
		["Bronx","NY",40.84,-73.87,1347869],
		["Cypress","TX",29.96,-95.69,1360107],
		["Brooklyn","NY",40.64,-73.94,1372327],
		["West Lafayette","IN",40.44,-86.91,1384459],
		["Chicago","IL",41.83,-87.68,1396573],
		["Corona","CA",33.83,-117.6,1408687],
		["Anaheim","CA",33.82,-117.91,1420786],
		["Fresno","CA",36.75,-119.67,1432884],
		["Philadelphia","PA",39.95,-75.16,1444977],
		["Riverside","CA",33.96,-117.31,1457067],
		["Toa Baja","PR",18.44,-66.25,1469107],
		["Los Angeles","CA",34.02,-118.15,1481065],
		["Brooklyn","NY",40.64,-73.94,1492991],
		["Mckinney","TX",33.2,-96.65,1504889],
		["Bronx","NY",40.84,-73.87,1516773],
		["Goleta","CA",34.5,-120.12,1528646],
		["El Paso","TX",31.84,-106.43,1540517],
		["Woodbridge","VA",38.63,-77.26,1552284],
		["Brooklyn","NY",40.64,-73.94,1563946],
		["Olathe","KS",38.88,-94.81,1575589],
		["Chicago","IL",41.83,-87.68,1587177],
		["Murrieta","CA",33.54,-117.33,1598736],
		["Chula Vista","CA",32.63,-117.05,1610292],
		["Chicago","IL",41.83,-87.68,1621829],
		["Spring","TX",30.06,-95.38,1633351],
		["Weslaco","TX",26.16,-97.98,1644869],
		["Pasco","WA",46.41,-118.9,1656350],
		["Panorama City","CA",34.22,-118.44,1667825],
		["San Marcos","TX",29.88,-97.92,1679232],
		["Suwanee","GA",34.05,-84.07,1690607],
		["Tempe","AZ",33.43,-111.91,1701968],
		["Santa Ana","CA",33.74,-117.89,1713325],
		["Brooklyn","NY",40.64,-73.94,1724655],
		["Elk Grove","CA",38.44,-121.3,1735951],
		["Waukegan","IL",42.37,-87.86,1747241],
		["Salt Lake City","UT",40.65,-112.01,1758528],
		["Chicago","IL",41.83,-87.68,1769808],
		["Brooklyn","NY",40.64,-73.94,1781073],
		["Bayamon","PR",18.28,-66.13,1792275],
		["Bakersfield","CA",35.43,-118.83,1803358],
		["Pflugerville","TX",30.44,-97.62,1814431],
		["San Diego","CA",32.71,-117.05,1825503],
		["Los Angeles","CA",33.97,-118.24,1836574],
		["Allen","TX",33.1,-96.66,1847611],
		["Hamilton","OH",39.39,-84.56,1858626],
		["Passaic","NJ",40.85,-74.12,1869637],
		["Perris","CA",33.83,-117.2,1880647],
		["Simi Valley","CA",34.25,-118.78,1891609],
		["Long Beach","CA",33.78,-118.18,1902565],
		["League City","TX",29.48,-95.1,1913515],
		["Salinas","CA",36.67,-121.61,1924455],
		["Tallahassee","FL",30.45,-84.28,1935381],
		["Victorville","CA",34.49,-117.36,1946307],
		["Aurora","CO",39.69,-104.81,1957231],
		["Mansfield","TX",32.56,-97.11,1968150],
		["San Benito","TX",26.13,-97.63,1979065],
		["Powder Springs","GA",33.86,-84.68,1989946],
		["Chicago","IL",41.83,-87.68,2000818],
		["Alpharetta","GA",34.06,-84.27,2011652],
		["Los Angeles","CA",34,-118.28,2022486],
		["Chesapeake","VA",36.67,-76.3,2033281],
		["Bronx","NY",40.84,-73.87,2044070],
		["Hanford","CA",36.27,-119.63,2054857],
		["Houston","TX",29.76,-95.38,2065632],
		["Katy","TX",29.79,-95.82,2076388],
		["New York","NY",40.71,-73.99,2087110],
		["Elmhurst","NY",40.72,-73.87,2097808],
		["Bronx","NY",40.84,-73.87,2108505],
		["Raleigh","NC",35.82,-78.65,2119182],
		["Brooklyn","NY",40.64,-73.94,2129858],
		["Philadelphia","PA",39.95,-75.16,2140530],
		["Azusa","CA",34.17,-117.87,2151191],
		["Clearfield","UT",41.11,-112.05,2161845],
		["Staten Island","NY",40.58,-74.14,2172487],
		["Stockbridge","GA",33.54,-84.24,2183129],
		["Laredo","TX",27.53,-99.48,2193749],
		["Fairfield","CA",38.28,-122,2204360],
		["Indio","CA",33.71,-116.23,2214966],
		["Brownsville","TX",25.92,-97.48,2225525],
		["Douglasville","GA",33.74,-84.74,2236075],
		["Phoenix","AZ",33.49,-112.21,2246575],
		["San Bernardino","CA",34.22,-117.37,2257057],
		["Aurora","CO",39.69,-104.81,2267538],
		["Philadelphia","PA",39.95,-75.16,2278007],
		["Chandler","AZ",33.32,-111.82,2288468],
		["Saint Peters","MO",38.77,-90.6,2298906],
		["San Diego","CA",32.69,-117.11,2309329],
		["Reseda","CA",34.2,-118.54,2319746],
		["Blacksburg","VA",37.23,-80.42,2330161],
		["Ithaca","NY",42.44,-76.5,2340560],
		["Virginia Beach","VA",36.73,-76.04,2350954],
		["La Habra","CA",33.94,-117.95,2361304],
		["Chicago Heights","IL",41.5,-87.63,2371652],
		["San Bernardino","CA",34.17,-117.24,2382000],
		["Compton","CA",33.87,-118.21,2392344],
		["Cedar Park","TX",30.51,-97.83,2402686],
		["Los Angeles","CA",33.94,-118.24,2413009],
		["Pico Rivera","CA",33.98,-118.08,2423322],
		["Paramount","CA",33.89,-118.16,2433622],
		["Tucson","AZ",32.07,-110.88,2443902],
		["Brooklyn","NY",40.64,-73.94,2454172],
		["Charlotte","NC",35.19,-80.83,2464437],
		["Atlanta","GA",33.61,-84.49,2474700],
		["Greenville","NC",35.59,-77.37,2484954],
		["Elk Grove","CA",38.34,-121.42,2495197],
		["Fresno","CA",36.74,-119.75,2505424],
		["Frisco","TX",33.14,-96.81,2515640],
		["Ontario","CA",34.02,-117.58,2525823],
		["Garland","TX",32.91,-96.62,2535992],
		["Bronx","NY",40.84,-73.87,2546158],
		["Eagle Pass","TX",28.7,-100.49,2556314],
		["El Monte","CA",34.07,-118.01,2566461],
		["Bronx","NY",40.84,-73.87,2576606],
		["Hollywood","FL",26.02,-80.16,2586749],
		["North Las Vegas","NV",36.27,-115.13,2596890],
		["Port Saint Lucie","FL",27.27,-80.35,2606998],
		["Hayward","CA",37.63,-122.04,2617097],
		["El Paso","TX",31.65,-106.15,2627194],
		["Milwaukee","WI",43.06,-87.96,2637287],
		["Oxnard","CA",34.19,-119.17,2647378],
		["Glendale","AZ",33.53,-112.18,2657458],
		["Santa Ana","CA",33.71,-117.87,2667520],
		["Huntington Station","NY",40.84,-73.4,2677575],
		["Loganville","GA",33.83,-83.89,2687625],
		["Perris","CA",33.78,-117.33,2697674],
		["Los Angeles","CA",34.04,-118.18,2707712],
		["Brooklyn","NY",40.64,-73.94,2717738],
		["Santa Maria","CA",34.95,-120.5,2727711],
		["El Paso","TX",31.84,-106.43,2737669],
		["Carolina","PR",18.4,-65.98,2747624],
		["Las Vegas","NV",36.23,-115.05,2757529],
		["El Paso","TX",31.84,-106.43,2767419],
		["Anaheim","CA",33.84,-117.95,2777306],
		["San Antonio","TX",29.45,-98.5,2787176],
		["Corona","CA",33.9,-117.61,2797045],
		["Brooklyn","NY",40.64,-73.94,2806913],
		["Brentwood","CA",37.87,-121.68,2816771],
		["Las Vegas","NV",36.21,-115.21,2826628],
		["Miami","FL",25.77,-80.2,2836479],
		["Spring Valley","NY",41.11,-74.04,2846326],
		["North Las Vegas","NV",36.21,-115.12,2856172],
		["Bakersfield","CA",35.38,-119.22,2866017],
		["Sacramento","CA",38.48,-121.4,2875858],
		["Bronx","NY",40.84,-73.87,2885691],
		["Colton","CA",34.01,-117.27,2895499],
		["Brooklyn","NY",40.64,-73.94,2905295],
		["Salinas","CA",36.72,-121.63,2915084],
		["Fort Lauderdale","FL",26.14,-80.13,2924871],
		["Phoenix","AZ",33.44,-112.13,2934646],
		["Murrieta","CA",33.57,-117.14,2944420],
		["Wilmington","CA",33.77,-118.26,2954190],
		["San Bernardino","CA",34.1,-117.27,2963954],
		["Rancho Cucamonga","CA",34.09,-117.58,2973704],
		["Antioch","TN",36.04,-86.64,2983444],
		["Brooklyn","NY",40.64,-73.94,2993176],
		["Mesquite","TX",32.76,-96.59,3002900],
		["Brooklyn","NY",40.64,-73.94,3012618],
		["Brooklyn","NY",40.64,-73.94,3022326],
		["Lithonia","GA",33.71,-84.1,3032030],
		["Trujillo Alto","PR",18.36,-66.01,3041717],
		["Phoenix","AZ",33.38,-112.11,3051402],
		["Mount Pleasant","MI",43.59,-84.77,3061086],
		["Summerville","SC",33,-80.17,3070768],
		["Ontario","CA",34.04,-117.67,3080441],
		["Round Lake","IL",42.34,-88.11,3090081],
		["Clarksville","TN",36.56,-87.34,3099711],
		["Bronx","NY",40.84,-73.87,3109335],
		["Detroit","MI",42.38,-83.1,3118956],
		["Chicago","IL",41.83,-87.68,3128567],
		["North Hills","CA",34.23,-118.47,3138177],
		["Chicago","IL",41.83,-87.68,3147779],
		["San Antonio","TX",29.45,-98.5,3157377],
		["Montebello","CA",34,-118.1,3166973],
		["El Cajon","CA",32.84,-116.88,3176568],
		["Mcallen","TX",26.21,-98.23,3186150],
		["Arecibo","PR",18.45,-66.73,3195728],
		["Beaverton","OR",45.44,-122.88,3205302],
		["Santa Ana","CA",33.75,-117.85,3214872],
		["Dallas","TX",32.79,-96.76,3224426],
		["Dallas","TX",32.79,-96.76,3233978],
		["Keller","TX",32.93,-97.22,3243512],
		["Denver","CO",39.76,-104.87,3253043],
		["National City","CA",32.67,-117.09,3262569],
		["Houston","TX",29.76,-95.38,3272082],
		["Houston","TX",29.76,-95.38,3281578],
		["Bowling Green","KY",36.97,-86.44,3291071],
		["Palmdale","CA",34.61,-118.27,3300563],
		["Pearland","TX",29.55,-95.27,3310045],
		["El Paso","TX",31.84,-106.43,3319521],
		["Antioch","CA",37.99,-121.81,3328992],
		["Ewa Beach","HI",21.31,-158.01,3338455],
		["Waipahu","HI",21.39,-158.01,3347915],
		["Mesa","AZ",33.39,-111.78,3357342],
		["Far Rockaway","NY",40.59,-73.81,3366761],
		["Madera","CA",37.01,-119.94,3376171],
		["Union City","CA",37.59,-122.06,3385579],
		["Wellington","FL",26.74,-80.12,3394980],
		["Berwyn","IL",41.84,-87.79,3404356],
		["Acworth","GA",34.05,-84.67,3413704],
		["South Jordan","UT",40.55,-111.96,3423050],
		["Detroit","MI",42.38,-83.1,3432384],
		["Moreno Valley","CA",33.97,-117.25,3441717],
		["San Antonio","TX",29.45,-98.5,3451049],
		["Spring Valley","CA",32.71,-116.99,3460381],
		["Gilroy","CA",37.06,-121.55,3469702],
		["Woodside","NY",40.74,-73.9,3479011],
		["Folsom","CA",38.67,-121.14,3488304],
		["Gilbert","AZ",33.36,-111.75,3497593],
		["Orlando","FL",28.5,-81.37,3506877],
		["Houston","TX",29.76,-95.38,3516144],
		["Davis","CA",38.48,-121.64,3525411],
		["Chicago","IL",41.83,-87.68,3534671],
		["Arlington","TX",32.69,-97.12,3543929],
		["Cupertino","CA",37.3,-122.09,3553183],
		["Lilburn","GA",33.88,-84.13,3562421],
		["Vega Baja","PR",18.44,-66.39,3571645],
		["Piscataway","NJ",40.51,-74.45,3580867],
		["Compton","CA",33.87,-118.24,3590082],
		["Hollywood","FL",26.02,-80.16,3599293],
		["Normal","IL",40.51,-88.99,3608493],
		["New Brunswick","NJ",40.48,-74.44,3617661],
		["Riverside","CA",33.9,-117.4,3626826],
		["Ontario","CA",34.07,-117.62,3635990],
		["San Jose","CA",37.37,-121.75,3645151],
		["Oviedo","FL",28.65,-81.18,3654307],
		["Los Angeles","CA",34.04,-118.21,3663462],
		["Ypsilanti","MI",42.24,-83.62,3672616],
		["Highland","CA",34.13,-117.15,3681764],
		["Philadelphia","PA",39.95,-75.16,3690910],
		["Philadelphia","PA",39.95,-75.16,3700046],
		["Glendale","AZ",33.66,-112.18,3709172],
		["Delano","CA",35.78,-119.23,3718297],
		["Augusta","GA",33.45,-81.99,3727408],
		["West Palm Beach","FL",26.74,-80.12,3736518],
		["Medina","OH",41.13,-81.86,3745617],
		["Stafford","VA",38.42,-77.4,3754705],
		["Tuscaloosa","AL",33.23,-87.54,3763788],
		["Chicago","IL",41.83,-87.68,3772856],
		["Mason","OH",39.36,-84.3,3781923],
		["Phoenix","AZ",33.47,-112.19,3790969],
		["Napa","CA",38.55,-122.15,3800010],
		["Plainfield","IL",41.61,-88.2,3809043],
		["Visalia","CA",36.4,-119.38,3818076],
		["Chicago","IL",41.83,-87.68,3827097],
		["Monroe","NY",41.32,-74.18,3836113],
		["Miami","FL",25.77,-80.2,3845124],
		["Washington","DC",38.89,-77.03,3854132],
		["Vancouver","WA",45.67,-122.47,3863139],
		["Spring","TX",30.06,-95.38,3872144],
		["Marietta","GA",33.95,-84.54,3881147],
		["American Fork","UT",40.39,-111.78,3890149],
		["Hialeah","FL",25.86,-80.29,3899144],
		["Bay Shore","NY",40.72,-73.25,3908130],
		["Brentwood","NY",40.78,-73.24,3917114],
		["Modesto","CA",37.67,-120.95,3926096],
		["Tucson","AZ",32.24,-110.95,3935077],
		["Los Angeles","CA",34.11,-118.19,3944058],
		["Dearborn","MI",42.31,-83.21,3953030],
		["Greeley","CO",40.42,-104.74,3962000],
		["Taylor","MI",42.22,-83.26,3970947],
		["Saint Paul","MN",44.94,-93.1,3979890],
		["Lake Elsinore","CA",33.68,-117.37,3988822],
		["Cypress","TX",29.96,-95.69,3997751],
		["Aurora","IL",41.76,-88.29,4006679],
		["Burleson","TX",32.53,-97.32,4015605],
		["Mesquite","TX",32.76,-96.59,4024530],
		["San Jose","CA",37.28,-121.83,4033449],
		["Albuquerque","NM",35.11,-106.62,4042359],
		["San Antonio","TX",29.45,-98.5,4051268],
		["San Diego","CA",32.75,-117.07,4060173],
		["Peoria","AZ",33.57,-112.25,4069075],
		["Naperville","IL",41.76,-88.15,4077975],
		["Carson","CA",33.82,-118.26,4086872],
		["Chicago","IL",41.83,-87.68,4095755],
		["Fort Worth","TX",32.75,-97.33,4104626],
		["Austin","TX",30.26,-97.74,4113492],
		["San Jose","CA",37.33,-121.83,4122351],
		["Yonkers","NY",40.94,-73.86,4131202],
		["North Hollywood","CA",34.2,-118.39,4140041],
		["Humble","TX",29.99,-95.26,4148864],
		["Covington","GA",33.6,-83.85,4157683],
		["Garland","TX",32.91,-96.62,4166497],
		["Gaithersburg","MD",39.14,-77.21,4175301],
		["Missouri City","TX",29.57,-95.53,4184093],
		["Fredericksburg","VA",38.29,-77.48,4192879],
		["East Lansing","MI",42.73,-84.48,4201664],
		["Detroit","MI",42.38,-83.1,4210437],
		["San Francisco","CA",37.72,-122.44,4219191],
		["Virginia Beach","VA",36.73,-76.04,4227927],
		["Phoenix","AZ",33.46,-111.98,4236648],
		["Pompano Beach","FL",26.23,-80.13,4245364],
		["Albuquerque","NM",35.11,-106.62,4254070],
		["Plano","TX",33.04,-96.74,4262768],
		["Broken Arrow","OK",36.03,-95.78,4271463],
		["Canovanas","PR",18.37,-65.9,4280157],
		["Hendersonville","TN",36.29,-86.6,4288845],
		["Laguna Niguel","CA",33.53,-117.7,4297521],
		["Brockton","MA",42.08,-71.02,4306183],
		["San Diego","CA",32.88,-117.13,4314844],
		["Escondido","CA",33.13,-116.98,4323503],
		["Toms River","NJ",39.95,-74.18,4332150],
		["Tracy","CA",37.73,-121.42,4340797],
		["Lakeville","MN",44.67,-93.24,4349437],
		["Dalton","GA",34.76,-84.97,4358075],
		["Crown Point","IN",41.42,-87.35,4366713],
		["San Pablo","CA",37.98,-122.33,4375347],
		["Bakersfield","CA",35.34,-119.07,4383971],
		["New York","NY",40.71,-73.99,4392594],
		["Bakersfield","CA",35.33,-119.02,4401215],
		["Vacaville","CA",38.35,-121.93,4409835],
		["New Castle","DE",39.66,-75.57,4418454],
		["Jersey City","NJ",40.71,-74.06,4427068],
		["Harlingen","TX",26.19,-97.68,4435671],
		["Jamaica","NY",40.68,-73.78,4444264],
		["La Jolla","CA",32.86,-117.24,4452854],
		["Union City","NJ",40.76,-74.03,4461430],
		["Jamaica","NY",40.68,-73.78,4470006],
		["Bolingbrook","IL",41.7,-88.07,4478579],
		["Coachella","CA",33.7,-116.17,4487149],
		["San Antonio","TX",29.45,-98.5,4495713],
		["Mcdonough","GA",33.45,-84.14,4504263],
		["Macomb","MI",42.66,-82.91,4512810],
		["Philadelphia","PA",39.95,-75.16,4521355],
		["Parker","CO",39.5,-104.76,4529892],
		["Rio Grande","PR",18.38,-65.83,4538419],
		["Sicklerville","NJ",39.73,-74.98,4546939],
		["Chicago","IL",41.83,-87.68,4555452],
		["Phoenix","AZ",33.62,-112,4563964],
		["Newburgh","NY",41.5,-74.02,4572464],
		["Phoenix","AZ",33.48,-112.25,4580964],
		["Rosemead","CA",34.06,-118.08,4589461],
		["Virginia Beach","VA",36.73,-76.04,4597951],
		["Hollywood","FL",26.02,-80.16,4606435],
		["Allentown","PA",40.59,-75.47,4614918],
		["Wasilla","AK",61.52,-149.57,4623400],
		["Alpharetta","GA",34.06,-84.27,4631871],
		["Brownsville","TX",25.92,-97.48,4640336],
		["Caguas","PR",18.23,-66.03,4648800],
		["Tustin","CA",33.74,-117.82,4657257],
		["Andover","MN",45.25,-93.33,4665712],
		["Hilliard","OH",40.03,-83.14,4674162],
		["Antelope","CA",38.71,-121.36,4682607],
		["Waxhaw","NC",34.92,-80.74,4691050],
		["Hollister","CA",36.89,-121.24,4699489],
		["Bakersfield","CA",35.15,-119.06,4707926],
		["Kennesaw","GA",34.02,-84.61,4716354],
		["Modesto","CA",37.63,-121.01,4724780],
		["Tampa","FL",27.95,-82.48,4733201],
		["Sugar Land","TX",29.63,-95.65,4741619],
		["Grove City","OH",39.87,-83.07,4750028],
		["San Jose","CA",37.23,-121.82,4758432],
		["Elyria","OH",41.37,-82.1,4766835],
		["Portland","OR",45.55,-122.81,4775217],
		["Hollywood","FL",26.02,-80.16,4783598],
		["Los Angeles","CA",34.01,-118.2,4791973],
		["Lake Worth","FL",26.61,-80.05,4800346],
		["Homestead","FL",25.46,-80.45,4808716],
		["Loveland","OH",39.26,-84.27,4817078],
		["Mcallen","TX",26.21,-98.23,4825440],
		["Ogden","UT",41.24,-112.13,4833800],
		["San Juan","PR",18.4,-66.06,4842156],
		["Philadelphia","PA",40.06,-75.07,4850511],
		["Columbus","GA",32.51,-84.87,4858854],
		["Salt Lake City","UT",40.69,-111.99,4867178],
		["Pasadena","MD",39.11,-76.55,4875501],
		["Lawrence","MA",42.7,-71.16,4883821],
		["Memphis","TN",35.1,-90,4892139],
		["Crystal Lake","IL",42.23,-88.33,4900453],
		["New Braunfels","TX",29.7,-98.11,4908762],
		["Charlotte","NC",35.19,-80.83,4917069],
		["Jacksonville","FL",30.33,-81.65,4925376],
		["Houston","TX",29.76,-95.38,4933681],
		["Collierville","TN",35.05,-89.67,4941983],
		["Wake Forest","NC",35.97,-78.52,4950262],
		["Concord","NC",35.4,-80.59,4958541],
		["El Centro","CA",32.77,-115.6,4966818],
		["San Pedro","CA",33.73,-118.29,4975090],
		["Houston","TX",29.76,-95.38,4983360],
		["Los Angeles","CA",34.04,-118.29,4991625],
		["Monsey","NY",41.11,-74.06,4999879],
		["Queen Creek","AZ",33.16,-111.59,5008130],
		["Charlotte","NC",35.19,-80.83,5016380],
		["Waianae","HI",21.44,-158.18,5024629],
		["Freehold","NJ",40.25,-74.27,5032877],
		["Flushing","NY",40.77,-73.84,5041121],
		["Bronx","NY",40.84,-73.87,5049360],
		["Los Angeles","CA",33.92,-118.24,5057599],
		["Milpitas","CA",37.44,-121.87,5065824],
		["Los Angeles","CA",34.06,-118.43,5074046],
		["Middleburg","FL",30.05,-81.9,5082265],
		["Bayonne","NJ",40.66,-74.11,5090483],
		["Palmdale","CA",34.5,-118.01,5098699],
		["Friendswood","TX",29.51,-95.19,5106907],
		["Wayne","NJ",40.94,-74.24,5115108],
		["Brentwood","TN",35.99,-86.78,5123299],
		["Los Angeles","CA",34.07,-118.26,5131490],
		["Lake Forest","CA",33.64,-117.67,5139677],
		["Fort Lauderdale","FL",26.14,-80.13,5147860],
		["Juana Diaz","PR",18.05,-66.5,5156041],
		["Fremont","CA",37.56,-121.98,5164219],
		["Buckeye","AZ",33.38,-112.55,5172394],
		["Richmond","KY",37.74,-84.29,5180567],
		["Saint Louis","MO",38.63,-90.24,5188740],
		["Riverside","CA",33.93,-117.49,5196908],
		["Huntington Beach","CA",33.72,-118,5205075],
		["Pleasant Grove","UT",40.46,-111.63,5213237],
		["Cathedral City","CA",33.82,-116.45,5221397],
		["Garden Grove","CA",33.78,-117.93,5229557],
		["Chicago","IL",41.83,-87.68,5237716],
		["Parkville","MD",39.38,-76.55,5245872],
		["Memphis","TN",35.1,-90,5254028],
		["Perth Amboy","NJ",40.52,-74.27,5262183],
		["Albuquerque","NM",35.11,-106.62,5270327],
		["Goose Creek","SC",32.98,-79.99,5278470],
		["San Diego","CA",32.96,-117.12,5286611],
		["Baltimore","MD",39.3,-76.61,5294747],
		["Fullerton","CA",33.88,-117.96,5302878],
		["Jackson","NJ",40.1,-74.35,5311002],
		["Orlando","FL",28.5,-81.37,5319122],
		["Round Rock","TX",30.51,-97.67,5327241],
		["Miami","FL",25.77,-80.2,5335356],
		["Potomac","MD",39.02,-77.19,5343468],
		["Seattle","WA",47.66,-122.29,5351573],
		["Beaverton","OR",45.52,-122.85,5359670],
		["Mayaguez","PR",18.2,-67.14,5367765],
		["Yucaipa","CA",34.04,-117.01,5375857],
		["Lompoc","CA",34.6,-120.39,5383931],
		["Irving","TX",32.85,-96.96,5391993],
		["Carrollton","TX",32.98,-96.89,5400032],
		["Simi Valley","CA",34.3,-118.72,5408067],
		["Oceanside","CA",33.27,-117.28,5416090],
		["Fremont","CA",37.51,-121.89,5424112],
		["University Park","PA",40.8,-77.85,5432132],
		["Nashville","TN",36.17,-86.78,5440152],
		["Yukon","OK",35.51,-97.76,5448171],
		["Morganton","NC",35.74,-81.69,5456182],
		["San Diego","CA",32.95,-117.19,5464191],
		["Ballwin","MO",38.59,-90.55,5472199],
		["El Cajon","CA",32.79,-116.96,5480201],
		["Washington","DC",38.89,-77.03,5488198],
		["Edinburg","TX",26.46,-98.25,5496195],
		["Fairfax","VA",38.85,-77.29,5504190],
		["Los Angeles","CA",34.04,-118.34,5512185],
		["Pomona","CA",34.08,-117.73,5520177],
		["Ashburn","VA",39.04,-77.48,5528167],
		["Cumming","GA",34.2,-84.13,5536157],
		["Lancaster","PA",40.04,-76.3,5544143],
		["Statesboro","GA",32.44,-81.77,5552125],
		["Milwaukee","WI",43.06,-87.96,5560106],
		["Flower Mound","TX",33.02,-97.09,5568085],
		["Miami","FL",25.77,-80.2,5576023],
		["Corona","CA",33.87,-117.53,5583957],
		["Hayward","CA",37.67,-122.08,5591880],
		["Hialeah","FL",25.86,-80.29,5599801],
		["Troy","NY",42.73,-73.67,5607714],
		["Round Rock","TX",30.51,-97.67,5615616],
		["Elgin","IL",42.03,-88.28,5623514],
		["Jacksonville","FL",30.33,-81.65,5631410],
		["Jacksonville","FL",30.33,-81.65,5639304],
		["Hollywood","FL",26.02,-80.16,5647197],
		["Nampa","ID",43.49,-116.61,5655084],
		["Salem","OR",44.9,-122.92,5662968],
		["Detroit","MI",42.38,-83.1,5670842],
		["Clovis","CA",36.93,-119.56,5678714],
		["Los Angeles","CA",33.95,-118.3,5686576],
		["Woodbridge","VA",38.63,-77.26,5694437],
		["Escondido","CA",33.09,-116.94,5702295],
		["Rio Grande City","TX",26.37,-98.82,5710152],
		["Pompano Beach","FL",26.23,-80.13,5718004],
		["Fresno","CA",36.64,-119.91,5725844],
		["Marrero","LA",29.88,-90.11,5733676],
		["Virginia Beach","VA",36.73,-76.04,5741500],
		["Sun Valley","CA",34.23,-118.34,5749320],
		["Greensboro","NC",36.07,-79.82,5757137],
		["Chicago","IL",41.83,-87.68,5764954],
		["Cedar Hill","TX",32.58,-96.95,5772771],
		["Newark","OH",40.06,-82.42,5780582],
		["Visalia","CA",36.29,-119.38,5788393],
		["Mentor","OH",41.69,-81.33,5796200],
		["Wylie","TX",33.04,-96.51,5804007],
		["San Antonio","TX",29.45,-98.5,5811811],
		["Virginia Beach","VA",36.73,-76.04,5819613],
		["Del Rio","TX",29.37,-100.89,5827407],
		["Hacienda Heights","CA",34,-117.97,5835201],
		["South San Francisco","CA",37.65,-122.42,5842991],
		["Humacao","PR",18.15,-65.81,5850769],
		["San Antonio","TX",29.45,-98.5,5858547],
		["Tooele","UT",40.57,-112.33,5866324],
		["Roseville","CA",38.78,-121.37,5874097],
		["Aguadilla","PR",18.43,-67.15,5881868],
		["Laredo","TX",27.53,-99.48,5889637],
		["Poway","CA",32.97,-117.02,5897398],
		["San Antonio","TX",29.45,-98.5,5905158],
		["New York","NY",40.71,-73.99,5912916],
		["Orlando","FL",28.5,-81.37,5920667],
		["Staten Island","NY",40.58,-74.14,5928406],
		["West Haven","CT",41.27,-72.96,5936144],
		["Cypress","CA",33.81,-118.03,5943877],
		["Lehi","UT",40.32,-111.96,5951603],
		["Costa Mesa","CA",33.64,-117.91,5959323],
		["Las Vegas","NV",36.23,-115.29,5967040],
		["Antioch","CA",37.96,-121.76,5974754],
		["San Luis Obispo","CA",35.27,-120.69,5982467],
		["Norman","OK",35.22,-97.34,5990167],
		["Monroe","NC",34.98,-80.54,5997865],
		["Massapequa","NY",40.66,-73.47,6005562],
		["San Marcos","CA",33.15,-117.17,6013255],
		["Ceres","CA",37.55,-120.96,6020942],
		["Desoto","TX",32.58,-96.86,6028628],
		["Baytown","TX",29.74,-94.96,6036313],
		["Milwaukee","WI",43.06,-87.96,6043996],
		["San Antonio","TX",29.45,-98.5,6051679],
		["Casa Grande","AZ",32.91,-111.74,6059356],
		["Middletown","OH",39.5,-84.37,6067030],
		["Houston","TX",29.76,-95.38,6074701],
		["Columbia","MO",38.95,-92.32,6082364],
		["Fountain Valley","CA",33.71,-117.94,6090027],
		["Tucson","AZ",32.26,-111,6097684],
		["Duluth","GA",34,-84.15,6105340],
		["Las Vegas","NV",36.12,-115.08,6112995],
		["Houston","TX",29.76,-95.38,6120645],
		["Jonesboro","AR",35.82,-90.68,6128289],
		["West Jordan","UT",40.59,-112.03,6135932],
		["Bakersfield","CA",35.54,-118.91,6143573],
		["Detroit","MI",42.38,-83.1,6151213],
		["Meridian","ID",43.65,-116.43,6158851],
		["Reynoldsburg","OH",39.95,-82.79,6166479],
		["Lancaster","OH",39.72,-82.59,6174107],
		["Mililani","HI",21.45,-158.01,6181733],
		["Duluth","GA",34,-84.15,6189358],
		["Chicago","IL",41.83,-87.68,6196975],
		["Tucson","AZ",32.19,-110.98,6204592],
		["Atlanta","GA",33.71,-84.53,6212206],
		["Cumming","GA",34.2,-84.13,6219807],
		["Cayey","PR",18.11,-66.16,6227406],
		["Marietta","GA",33.95,-84.54,6235003],
		["Findlay","OH",41.04,-83.64,6242600],
		["Painesville","OH",41.72,-81.24,6250193],
		["San Jose","CA",37.35,-121.85,6257786],
		["Memphis","TN",35.1,-90,6265378],
		["Columbia","SC",34,-81.03,6272957],
		["Zanesville","OH",39.95,-82.01,6280536],
		["Indianapolis","IN",39.77,-86.14,6288115],
		["Warner Robins","GA",32.61,-83.63,6295688],
		["Upland","CA",34.1,-117.66,6303258],
		["Irving","TX",32.85,-96.96,6310819],
		["Mcdonough","GA",33.45,-84.14,6318369],
		["Los Angeles","CA",34.07,-118.31,6325904],
		["New York","NY",40.71,-73.99,6333432],
		["Canton","MI",42.3,-83.48,6340957],
		["Orlando","FL",28.5,-81.37,6348471],
		["Denham Springs","LA",30.47,-90.95,6355985],
		["Naperville","IL",41.76,-88.15,6363490],
		["La Mirada","CA",33.9,-118,6370993],
		["Los Angeles","CA",34.08,-118.17,6378493],
		["Pittsburgh","PA",40.43,-79.97,6385982],
		["Dacula","GA",33.98,-83.88,6393470],
		["Tucson","AZ",32.09,-111.08,6400953],
		["Olathe","KS",38.88,-94.81,6408432],
		["Irvington","NJ",40.72,-74.23,6415908],
		["Rio Rancho","NM",35.27,-106.66,6423384],
		["North Bergen","NJ",40.79,-74.02,6430858],
		["Westerville","OH",40.12,-82.91,6438327],
		["Springdale","AR",36.18,-94.14,6445795],
		["Laredo","TX",27.53,-99.48,6453262],
		["Stone Mountain","GA",33.8,-84.17,6460726],
		["Orlando","FL",28.5,-81.37,6468185],
		["La Crosse","WI",43.82,-91.23,6475642],
		["Fontana","CA",34.05,-117.46,6483097],
		["Lancaster","PA",40.04,-76.3,6490548],
		["Colorado Springs","CO",38.86,-104.76,6497999],
		["South El Monte","CA",34.04,-118.04,6505448],
		["Newark","DE",39.67,-75.75,6512893],
		["Clermont","FL",28.55,-81.75,6520336],
		["Woodbridge","VA",38.63,-77.26,6527778],
		["Dekalb","IL",41.89,-88.75,6535220],
		["Laredo","TX",27.53,-99.48,6542662],
		["Menifee","CA",33.65,-117.18,6550104],
		["Orange","CA",33.81,-117.79,6557544],
		["Miami","FL",25.77,-80.2,6564980],
		["Olive Branch","MS",34.95,-89.82,6572414],
		["Beloit","WI",42.52,-89.02,6579847],
		["El Paso","TX",31.84,-106.43,6587277],
		["Long Beach","CA",33.81,-118.18,6594707],
		["Hagerstown","MD",39.63,-77.71,6602135],
		["Dallas","GA",33.91,-84.83,6609562],
		["Lewisville","TX",33.04,-96.98,6616980],
		["Hempstead","NY",40.7,-73.61,6624395],
		["Charlotte","NC",35.19,-80.83,6631810],
		["Van Nuys","CA",34.2,-118.44,6639224],
		["Memphis","TN",35.1,-90,6646636],
		["Rancho Santa Margarita","CA",33.61,-117.61,6654048],
		["Donna","TX",26.16,-98.05,6661454],
		["Bronx","NY",40.84,-73.87,6668859],
		["Los Angeles","CA",34.02,-118.31,6676261],
		["Germantown","MD",39.17,-77.26,6683661],
		["Hemet","CA",33.64,-116.83,6691060],
		["Roswell","GA",34.03,-84.35,6698456],
		["Santee","CA",32.85,-116.99,6705850],
		["Bronx","NY",40.84,-73.87,6713243],
		["Federal Way","WA",47.3,-122.37,6720636],
		["Newnan","GA",33.37,-84.78,6728025],
		["Charlotte","NC",35.19,-80.83,6735409],
		["Salt Lake City","UT",40.7,-111.94,6742791],
		["Oregon City","OR",45.32,-122.52,6750165],
		["Everett","WA",47.9,-122.18,6757534],
		["Buffalo","NY",42.88,-78.85,6764898],
		["Oklahoma City","OK",35.46,-97.51,6772259],
		["Palo Alto","CA",37.45,-122.1,6779620],
		["Calexico","CA",32.69,-115.51,6786968],
		["Murfreesboro","TN",35.85,-86.39,6794300],
		["Baltimore","MD",39.3,-76.61,6801626],
		["Carmichael","CA",38.62,-121.32,6808951],
		["Chicago","IL",41.83,-87.68,6816275],
		["Lynchburg","VA",37.4,-79.19,6823597],
		["New Haven","CT",41.31,-72.92,6830908],
		["Garden Grove","CA",33.75,-117.94,6838219],
		["Springfield","MO",37.19,-93.28,6845525],
		["Champaign","IL",40.11,-88.26,6852827],
		["Rancho Cordova","CA",38.6,-121.26,6860129],
		["Twin Falls","ID",42.41,-114.59,6867429],
		["Logan","UT",41.7,-111.87,6874727],
		["Fayetteville","NC",35.05,-78.87,6882024],
		["Bristol","CT",41.68,-72.94,6889319],
		["Placentia","CA",33.88,-117.85,6896613],
		["Dorchester Center","MA",42.35,-71.06,6903906],
		["Elmhurst","IL",41.89,-87.94,6911197],
		["Chapel Hill","NC",35.92,-79.04,6918486],
		["Jacksonville","FL",30.33,-81.65,6925763],
		["West Chester","OH",39.33,-84.4,6933038],
		["Greeley","CO",40.42,-104.74,6940309],
		["Union","NJ",40.69,-74.26,6947575],
		["Fort Worth","TX",32.75,-97.33,6954841],
		["Augusta","GA",33.45,-81.99,6962104],
		["Birmingham","AL",33.52,-86.79,6969366],
		["Denver","CO",39.76,-104.87,6976623],
		["Granada Hills","CA",34.29,-118.5,6983876],
		["Frisco","TX",33.14,-96.81,6991126],
		["Lodi","CA",38.11,-121.16,6998376],
		["Los Angeles","CA",34.02,-118.35,7005621],
		["Lake Zurich","IL",42.19,-88.08,7012865],
		["Dundalk","MD",39.26,-76.49,7020104],
		["Downey","CA",33.92,-118.14,7027340],
		["Bend","OR",44.07,-121.25,7034576],
		["Yorba Linda","CA",33.89,-117.78,7041810],
		["O Fallon","MO",38.78,-90.7,7049034],
		["Baltimore","MD",39.3,-76.61,7056254],
		["Greenville","NC",35.59,-77.37,7063472],
		["Moreno Valley","CA",33.9,-117.1,7070689],
		["Oak Lawn","IL",41.71,-87.75,7077900],
		["Spanaway","WA",47.05,-122.36,7085104],
		["Alvin","TX",29.43,-95.24,7092304],
		["Whittier","CA",33.94,-118.03,7099494],
		["Chicago","IL",41.83,-87.68,7106682],
		["Snellville","GA",33.85,-84,7113868],
		["Canoga Park","CA",34.22,-118.59,7121054],
		["Boise","ID",43.54,-116.29,7128238],
		["Silver Spring","MD",39.01,-77.02,7135419],
		["Coppell","TX",32.96,-96.99,7142600],
		["Van Nuys","CA",34.19,-118.49,7149781],
		["West Covina","CA",34.06,-117.94,7156957],
		["Jackson","TN",35.63,-88.82,7164126],
		["Kyle","TX",29.99,-97.87,7171292],
		["Dayton","OH",39.77,-84.19,7178457],
		["Salina","KS",38.81,-97.61,7185622],
		["Riverton","UT",40.47,-112,7192787],
		["Kent","WA",47.36,-122.1,7199951],
		["Midlothian","VA",37.5,-77.63,7207114],
		["Stockton","CA",38,-121.32,7214276],
		["Columbia","TN",35.62,-87.04,7221434],
		["Oakland","CA",37.77,-122.21,7228589],
		["Milwaukee","WI",43.06,-87.96,7235741],
		["Newark","NJ",40.72,-74.17,7242890],
		["Brooklyn","NY",40.64,-73.94,7250034],
		["Birmingham","AL",33.52,-86.79,7257175],
		["Fayetteville","AR",36.07,-94.15,7264315],
		["Bakersfield","CA",35.18,-119.21,7271444],
		["Austin","TX",30.26,-97.74,7278569],
		["San Antonio","TX",29.45,-98.5,7285690],
		["Middletown","NY",41.44,-74.42,7292809],
		["Huntington Beach","CA",33.66,-117.97,7299928],
		["Houston","TX",29.76,-95.38,7307046],
		["Miami","FL",25.77,-80.2,7314156],
		["San Juan","TX",26.19,-98.15,7321264],
		["Layton","UT",41.07,-111.98,7328371],
		["Greenwood","IN",39.61,-86.11,7335477],
		["Plano","TX",33.04,-96.74,7342582],
		["Saint Louis","MO",38.63,-90.24,7349685],
		["Phoenix","AZ",33.5,-112.12,7356788],
		["Kennesaw","GA",34.02,-84.61,7363889],
		["Avondale","AZ",33.44,-112.31,7370985],
		["Draper","UT",40.49,-111.84,7378080],
		["Fort Collins","CO",40.55,-105.06,7385173],
		["East Brunswick","NJ",40.42,-74.41,7392265],
		["Smyrna","TN",35.98,-86.51,7399357],
		["Barrington","IL",42.15,-88.12,7406449],
		["Douglasville","GA",33.74,-84.74,7413537],
		["Los Banos","CA",36.9,-121.02,7420622],
		["Bonney Lake","WA",47.18,-122.16,7427702],
		["Dallas","TX",32.79,-96.76,7434772],
		["Fort Washington","MD",38.73,-77,7441841],
		["Mission Viejo","CA",33.6,-117.64,7448906],
		["Orlando","FL",28.5,-81.37,7455970],
		["Morgantown","WV",39.63,-79.94,7463033],
		["San Jose","CA",37.33,-121.77,7470096],
		["Simpsonville","SC",34.73,-82.25,7477156],
		["Miami","FL",25.77,-80.2,7484211],
		["Chillicothe","OH",39.33,-82.98,7491265],
		["Rock Hill","SC",34.93,-81.02,7498316],
		["Yakima","WA",46.59,-120.53,7505366],
		["Morgan Hill","CA",37.17,-121.65,7512413],
		["Vista","CA",33.21,-117.2,7519458],
		["Marysville","WA",48.05,-122.14,7526502],
		["Miami","FL",25.77,-80.2,7533536],
		["South Richmond Hill","NY",40.68,-73.82,7540564],
		["Holland","MI",42.77,-86.1,7547592],
		["Victorville","CA",34.48,-117.36,7554620],
		["New York","NY",40.71,-73.99,7561646],
		["Riverside","CA",33.89,-117.32,7568672],
		["Montgomery","AL",32.35,-86.28,7575693],
		["Morrisville","PA",40.2,-74.78,7582713],
		["Gurabo","PR",18.25,-65.97,7589725],
		["Providence","RI",41.82,-71.41,7596736],
		["Stockton","CA",38.02,-121.3,7603745],
		["Grand Blanc","MI",42.92,-83.61,7610749],
		["Raleigh","NC",35.82,-78.65,7617749],
		["Pickerington","OH",39.89,-82.76,7624747],
		["Mission Viejo","CA",33.6,-117.66,7631744],
		["Spanish Fork","UT",40.02,-111.55,7638740],
		["Murfreesboro","TN",35.85,-86.39,7645735],
		["San Jose","CA",37.34,-121.88,7652729],
		["Rock Hill","SC",34.93,-81.02,7659722],
		["Staten Island","NY",40.58,-74.14,7666712],
		["Leander","TX",30.56,-97.86,7673701],
		["The Colony","TX",33.09,-96.88,7680687],
		["Webster","NY",43.21,-77.42,7687671],
		["Anaheim","CA",33.81,-117.91,7694654],
		["Kennewick","WA",46.21,-119.16,7701634],
		["Lexington","SC",33.98,-81.22,7708611],
		["Plymouth","MA",41.95,-70.66,7715585],
		["Deltona","FL",28.9,-81.2,7722558],
		["Norcross","GA",33.94,-84.2,7729530],
		["Romeoville","IL",41.64,-88.08,7736502],
		["Rohnert Park","CA",38.34,-122.7,7743474],
		["Stamford","CT",41.09,-73.55,7750445],
		["Los Lunas","NM",34.81,-106.73,7757413],
		["Brandon","FL",27.92,-82.29,7764379],
		["Detroit","MI",42.38,-83.1,7771345],
		["Chula Vista","CA",32.64,-116.98,7778304],
		["Guayama","PR",17.97,-66.11,7785253],
		["Boston","MA",42.35,-71.06,7792202],
		["Saint Paul","MN",44.94,-93.1,7799150],
		["Naperville","IL",41.76,-88.15,7806097],
		["Aurora","CO",39.69,-104.81,7813042],
		["Redlands","CA",34.06,-117.17,7819984],
		["Spokane","WA",47.78,-117.45,7826926],
		["Princeton","NJ",40.35,-74.65,7833865],
		["Brooklyn","NY",40.64,-73.94,7840801],
		["Manteca","CA",37.83,-121.18,7847737],
		["Bayamon","PR",18.28,-66.13,7854670],
		["Hialeah","FL",25.86,-80.29,7861602],
		["Vallejo","CA",38.11,-122.2,7868532],
		["Huntersville","NC",35.41,-80.84,7875459],
		["Athens","OH",39.32,-82.09,7882379],
		["Canton","GA",34.24,-84.49,7889295],
		["Chicago","IL",41.83,-87.68,7896206],
		["Hartford","CT",41.76,-72.68,7903112],
		["Miami","FL",25.77,-80.2,7910017],
		["Hamilton","OH",39.39,-84.56,7916921],
		["Silver Spring","MD",39.01,-77.02,7923824],
		["Concord","NC",35.4,-80.59,7930720],
		["Annandale","VA",38.83,-77.21,7937613],
		["Yuma","AZ",32.72,-114.51,7944506],
		["Arlington","TX",32.69,-97.12,7951394],
		["Miami","FL",25.77,-80.2,7958281],
		["Broomfield","CO",39.94,-105.06,7965163],
		["Saint Johns","FL",30.08,-81.59,7972044],
		["North Las Vegas","NV",36.22,-115.16,7978925],
		["Trabuco Canyon","CA",33.61,-117.54,7985805],
		["Grapevine","TX",32.93,-97.07,7992680],
		["Mission","TX",26.21,-98.31,7999554],
		["Suffolk","VA",36.7,-76.63,8006427],
		["Bountiful","UT",40.88,-111.82,8013295],
		["Buena Park","CA",33.84,-118,8020162],
		["Fremont","CA",37.5,-121.96,8027028],
		["Newbury Park","CA",34.17,-118.95,8033893],
		["Canyon Country","CA",34.4,-118.4,8040758],
		["Cerritos","CA",33.86,-118.05,8047622],
		["Longmont","CO",40.17,-105.11,8054480],
		["Diamond Bar","CA",33.99,-117.81,8061338],
		["Marion","OH",40.58,-83.12,8068192],
		["Columbus","OH",39.98,-82.98,8075043],
		["Medford","MA",42.42,-71.1,8081892],
		["Greensburg","PA",40.31,-79.54,8088741],
		["Boston","MA",42.35,-71.06,8095588],
		["Orlando","FL",28.5,-81.37,8102434],
		["Holland","MI",42.77,-86.1,8109279],
		["Littleton","CO",39.57,-105.14,8116124],
		["New York","NY",40.71,-73.99,8122964],
		["College Station","TX",30.6,-96.31,8129802],
		["Jupiter","FL",26.92,-80.1,8136639],
		["Williamsburg","VA",37.27,-76.7,8143473],
		["Las Vegas","NV",36.3,-115.24,8150307],
		["Hampton","VA",37.04,-76.29,8157140],
		["Jamaica","NY",40.68,-73.78,8163972],
		["West Chester","PA",39.95,-75.6,8170804],
		["Seguin","TX",29.57,-97.96,8177636],
		["Mount Prospect","IL",42.06,-87.93,8184459],
		["San Antonio","TX",29.45,-98.5,8191274],
		["Philadelphia","PA",39.95,-75.16,8198085],
		["Stockton","CA",38.04,-121.35,8204896],
		["Livermore","CA",37.68,-121.75,8211703],
		["Manati","PR",18.43,-66.48,8218507],
		["Oxnard","CA",34.25,-119.16,8225311],
		["Cleveland","OH",41.47,-81.67,8232113],
		["Ponce","PR",17.98,-66.6,8238910],
		["Anoka","MN",45.21,-93.39,8245705],
		["Indianapolis","IN",39.77,-86.14,8252499],
		["Madison","AL",34.7,-86.74,8259290],
		["Franklin","TN",35.92,-86.84,8266077],
		["Goodyear","AZ",33.37,-112.38,8272862],
		["Bayamon","PR",18.28,-66.13,8279643],
		["Winston Salem","NC",36.1,-80.24,8286423],
		["Florissant","MO",38.79,-90.32,8293201],
		["Kalispell","MT",48.19,-114.38,8299978],
		["Apopka","FL",28.68,-81.51,8306754],
		["Denver","CO",39.76,-104.87,8313528],
		["Summerville","SC",33,-80.17,8320300],
		["Escondido","CA",33.22,-117.1,8327072],
		["Elk Grove","CA",38.35,-121.42,8333844],
		["Santa Fe","NM",35.67,-105.95,8340608],
		["Lawton","OK",34.6,-98.42,8347368],
		["Bronx","NY",40.84,-73.87,8354125],
		["Mankato","MN",44.16,-93.99,8360881],
		["Kernersville","NC",36.11,-80.07,8367634],
		["El Dorado Hills","CA",38.68,-121.05,8374385],
		["Los Angeles","CA",33.98,-118.33,8381134],
		["Downey","CA",33.94,-118.13,8387882],
		["Carol Stream","IL",41.91,-88.13,8394626],
		["Farmington","NM",36.75,-108.18,8401367],
		["Downingtown","PA",40,-75.7,8408106],
		["Ellenwood","GA",33.63,-84.26,8414841],
		["Arlington","TN",35.28,-89.66,8421576],
		["Saint Paul","MN",44.94,-93.1,8428310],
		["Decatur","GA",33.77,-84.29,8435042],
		["Elgin","IL",42.03,-88.28,8441772],
		["Grand Prairie","TX",32.69,-97.01,8448500],
		["Woodstock","GA",34.1,-84.51,8455227],
		["Evanston","IL",42.04,-87.68,8461952],
		["Fresno","CA",36.88,-119.77,8468677],
		["Santa Ana","CA",33.76,-117.79,8475400],
		["Columbia","MO",38.95,-92.32,8482120],
		["Killeen","TX",31.09,-97.71,8488840],
		["New York","NY",40.71,-73.99,8495559],
		["Sanford","FL",28.78,-81.27,8502278],
		["Fort Worth","TX",32.75,-97.33,8508997],
		["Kaysville","UT",41.02,-111.92,8515716],
		["Leesburg","VA",39.1,-77.55,8522432],
		["Miami","FL",25.77,-80.2,8529148],
		["Spring","TX",30.06,-95.38,8535863],
		["Daly City","CA",37.67,-122.47,8542575],
		["Poughkeepsie","NY",41.69,-73.92,8549286],
		["Columbia","SC",34,-81.03,8555997],
		["Adelanto","CA",34.66,-117.51,8562703],
		["Bakersfield","CA",35.39,-118.98,8569408],
		["Modesto","CA",37.67,-121.01,8576112],
		["Oceanside","CA",33.2,-117.29,8582815],
		["Brooklyn","NY",40.64,-73.94,8589514],
		["Columbus","OH",39.98,-82.98,8596213],
		["Bothell","WA",47.84,-122.19,8602911],
		["Bronx","NY",40.84,-73.87,8609603],
		["Lansdale","PA",40.24,-75.28,8616295],
		["Poughkeepsie","NY",41.69,-73.92,8622986],
		["Hampton","GA",33.38,-84.28,8629674],
		["Elizabethtown","KY",37.7,-85.87,8636354],
		["Sacramento","CA",38.64,-121.44,8643034],
		["Littleton","CO",39.59,-105.01,8649711],
		["Mount Juliet","TN",36.2,-86.52,8656385],
		["Greensboro","NC",36.07,-79.82,8663056],
		["Stockton","CA",37.96,-121.26,8669726],
		["Las Vegas","NV",36,-115.14,8676387],
		["Owings Mills","MD",39.41,-76.79,8683047],
		["Evans","GA",33.51,-82.14,8689707],
		["Englishtown","NJ",40.29,-74.36,8696365],
		["Pasadena","TX",29.66,-95.15,8703023],
		["Buford","GA",34.11,-83.99,8709680],
		["Savannah","GA",32.08,-81.1,8716337],
		["Lockport","NY",43.16,-78.69,8722982],
		["Hephzibah","GA",33.28,-82.1,8729619],
		["O Fallon","MO",38.78,-90.7,8736255],
		["Buffalo","NY",42.88,-78.85,8742887],
		["Pomona","CA",34.06,-117.79,8749517],
		["Fallbrook","CA",33.39,-117.29,8756147],
		["Hickory","NC",35.73,-81.32,8762776],
		["Clarksville","TN",36.56,-87.34,8769404],
		["Honolulu","HI",21.3,-157.85,8776032],
		["Delaware","OH",40.29,-83.08,8782653],
		["Memphis","TN",35.1,-90,8789273],
		["Hillsboro","OR",45.44,-122.96,8795893],
		["Howell","MI",42.6,-83.93,8802510],
		["Ventura","CA",34.28,-119.22,8809124],
		["Spring","TX",30.06,-95.38,8815731],
		["Gwynn Oak","MD",39.32,-76.72,8822328],
		["Chicago","IL",41.83,-87.68,8828925],
		["Fort Worth","TX",32.75,-97.33,8835522],
		["Carmel","IN",39.96,-86.1,8842115],
		["Glen Burnie","MD",39.16,-76.6,8848707],
		["Keller","TX",32.93,-97.22,8855298],
		["Kissimmee","FL",28.3,-81.41,8861887],
		["Coatesville","PA",39.98,-75.81,8868474],
		["Apple Valley","CA",34.42,-117.14,8875061],
		["Greensboro","NC",36.07,-79.82,8881647],
		["Pleasanton","CA",37.65,-121.85,8888223],
		["Methuen","MA",42.71,-71.18,8894798],
		["Jackson Heights","NY",40.75,-73.88,8901368],
		["San Diego","CA",32.72,-117.09,8907936],
		["Orlando","FL",28.5,-81.37,8914501],
		["Florissant","MO",38.79,-90.32,8921066],
		["Elkton","MD",39.6,-75.82,8927630],
		["Arlington Heights","IL",42.09,-87.98,8934187],
		["Coamo","PR",18.08,-66.36,8940743],
		["Manassas","VA",38.74,-77.48,8947296],
		["Auburn","WA",47.28,-122.1,8953848],
		["Trenton","NJ",40.22,-74.76,8960399],
		["Marietta","GA",33.95,-84.54,8966948],
		["Humble","TX",29.99,-95.26,8973495],
		["Alameda","CA",37.77,-122.27,8980040],
		["Gardena","CA",33.89,-118.29,8986584],
		["Muskegon","MI",43.22,-86.25,8993126],
		["Egg Harbor Township","NJ",39.41,-74.58,8999660],
		["Buford","GA",34.11,-83.99,9006194],
		["Winter Garden","FL",28.56,-81.58,9012724],
		["Caldwell","ID",43.66,-116.63,9019242],
		["South Ozone Park","NY",40.67,-73.81,9025757],
		["Kaneohe","HI",21.41,-157.79,9032272],
		["Hialeah","FL",25.86,-80.29,9038785],
		["Madera","CA",36.91,-120.19,9045297],
		["Red Oak","TX",32.51,-96.8,9051802],
		["Temecula","CA",33.53,-117.1,9058307],
		["Santa Maria","CA",34.82,-120.41,9064812],
		["Newark","DE",39.67,-75.75,9071313],
		["Tucson","AZ",32.22,-110.82,9077814],
		["West New York","NJ",40.78,-74,9084313],
		["Herndon","VA",38.96,-77.38,9090812],
		["Riverside","CA",33.92,-117.36,9097311],
		["Colorado Springs","CO",38.86,-104.76,9103808],
		["Clovis","NM",34.41,-103.2,9110303],
		["Moreno Valley","CA",33.87,-117.22,9116792],
		["Gilbert","AZ",33.3,-111.76,9123280],
		["Oakley","CA",37.99,-121.68,9129766],
		["Walnut","CA",34.01,-117.85,9136251],
		["Apple Valley","CA",34.57,-117.15,9142729],
		["Honolulu","HI",21.3,-157.85,9149206],
		["Saint Charles","MO",38.79,-90.51,9155682],
		["Surprise","AZ",33.6,-112.37,9162157],
		["Bellingham","WA",48.73,-122.48,9168632],
		["Jonesboro","GA",33.52,-84.35,9175106],
		["Mesa","AZ",33.45,-111.64,9181580],
		["Moorhead","MN",46.86,-96.75,9188052],
		["Lindenhurst","NY",40.68,-73.37,9194523],
		["Detroit","MI",42.38,-83.1,9200985],
		["Turlock","CA",37.47,-120.85,9207446],
		["Plano","TX",33.04,-96.74,9213902],
		["Houston","TX",29.76,-95.38,9220357],
		["Chesapeake","VA",36.67,-76.3,9226811],
		["Albuquerque","NM",35.11,-106.62,9233265],
		["Eau Claire","WI",44.82,-91.49,9239715],
		["Long Beach","CA",33.81,-118.22,9246165],
		["Merced","CA",37.33,-120.48,9252615],
		["Las Vegas","NV",36.17,-115.12,9259064],
		["Lancaster","PA",40.04,-76.3,9265504],
		["Burke","VA",38.78,-77.27,9271944],
		["Jacksonville","NC",34.76,-77.4,9278382],
		["Cleveland","OH",41.47,-81.67,9284820],
		["Dallas","TX",32.79,-96.76,9291258],
		["Kenner","LA",29.99,-90.24,9297692],
		["Detroit","MI",42.38,-83.1,9304120],
		["Bartlett","IL",41.97,-88.2,9310548],
		["Opa Locka","FL",25.93,-80.26,9316975],
		["Fayetteville","NC",35.05,-78.87,9323401],
		["Fresno","CA",36.79,-119.76,9329827],
		["West Chester","PA",39.95,-75.6,9336250],
		["Henderson","NV",36.03,-115.07,9342670],
		["Visalia","CA",36.37,-119.18,9349090],
		["Massillon","OH",40.78,-81.52,9355508],
		["Austin","TX",30.26,-97.74,9361925],
		["San Antonio","TX",29.45,-98.5,9368340],
		["Pasadena","TX",29.66,-95.15,9374750],
		["El Paso","TX",31.84,-106.43,9381157],
		["Mobile","AL",30.68,-88.04,9387560],
		["Orlando","FL",28.5,-81.37,9393960],
		["Providence","RI",41.82,-71.41,9400358],
		["Brooklyn","NY",40.64,-73.94,9406755],
		["Phoenix","AZ",33.37,-112.03,9413148],
		["Longview","WA",46.19,-123.05,9419539],
		["Las Vegas","NV",36.11,-115.27,9425927],
		["Florence","KY",38.99,-84.64,9432314],
		["Thomasville","NC",35.88,-80.07,9438700],
		["New York","NY",40.71,-73.99,9445082],
		["Whittier","CA",33.93,-118,9451464],
		["Honolulu","HI",21.3,-157.85,9457846],
		["Houston","TX",29.76,-95.38,9464227],
		["Carrollton","TX",32.98,-96.89,9470606],
		["Alamo","TX",26.18,-98.11,9476985],
		["Aliso Viejo","CA",33.57,-117.72,9483363],
		["Howell","NJ",40.14,-74.19,9489738],
		["Upper Marlboro","MD",38.81,-76.75,9496109],
		["Raleigh","NC",35.82,-78.65,9502479],
		["Plano","TX",33.04,-96.74,9508848],
		["Waco","TX",31.56,-97.18,9515213],
		["Calumet City","IL",41.61,-87.54,9521577],
		["Atwater","CA",37.32,-120.63,9527941],
		["Lancaster","SC",34.72,-80.77,9534303],
		["Frankfort","KY",38.19,-84.86,9540664],
		["Des Plaines","IL",42.03,-87.9,9547022],
		["Hanover Park","IL",41.97,-88.14,9553380],
		["Commerce City","CO",39.86,-104.86,9559737],
		["Lynn","MA",42.47,-70.96,9566092],
		["Columbus","OH",39.98,-82.98,9572446],
		["Garland","TX",32.91,-96.62,9578799],
		["Beaumont","CA",33.94,-116.97,9585152],
		["Moses Lake","WA",47.19,-119.3,9591503],
		["Williamsport","PA",41.23,-77.03,9597853],
		["Columbus","OH",39.98,-82.98,9604200],
		["Milledgeville","GA",33.08,-83.23,9610543],
		["Jacksonville","FL",30.33,-81.65,9616885],
		["West Jordan","UT",40.58,-111.98,9623224],
		["Lombard","IL",41.87,-88.01,9629562],
		["Roseville","MI",42.5,-82.93,9635899],
		["Thousand Oaks","CA",34.21,-118.88,9642236],
		["Scottsdale","AZ",33.61,-111.94,9648570],
		["Durham","NC",35.98,-78.91,9654903],
		["Cedar Falls","IA",42.52,-92.45,9661234],
		["Isabela","PR",18.5,-67.02,9667557],
		["Ellicott City","MD",39.27,-76.83,9673880],
		["Albany","GA",31.57,-84.17,9680203],
		["Joliet","IL",41.52,-88.12,9686526],
		["Phoenix","AZ",33.83,-112.11,9692849],
		["Saint Charles","MO",38.79,-90.51,9699170],
		["Bensalem","PA",40.1,-74.93,9705487],
		["Houston","TX",29.76,-95.38,9711801],
		["Deltona","FL",28.9,-81.2,9718114],
		["Thibodaux","LA",29.79,-90.82,9724427],
		["Montgomery","AL",32.35,-86.28,9730739],
		["Winter Springs","FL",28.68,-81.27,9737050],
		["Lenoir","NC",35.9,-81.53,9743355],
		["Winnetka","CA",34.2,-118.57,9749660],
		["Juncos","PR",18.22,-65.91,9755964],
		["Alpharetta","GA",34.06,-84.27,9762265],
		["Saginaw","MI",43.41,-83.94,9768563],
		["Houston","TX",29.76,-95.38,9774861],
		["Chico","CA",39.74,-121.83,9781159],
		["Bridgeport","CT",41.18,-73.19,9787455],
		["Torrance","CA",33.83,-118.31,9793749],
		["El Cajon","CA",32.77,-116.86,9800043],
		["Aguada","PR",18.38,-67.18,9806335],
		["Edmond","OK",35.67,-97.41,9812625],
		["Aurora","CO",39.69,-104.81,9818913],
		["Scarsdale","NY",40.98,-73.77,9825200],
		["Woodstock","GA",34.1,-84.51,9831487],
		["Carmel","IN",39.96,-86.1,9837773],
		["Irving","TX",32.85,-96.96,9844059],
		["Rancho Cucamonga","CA",34.14,-117.61,9850342],
		["New Iberia","LA",30,-91.81,9856624],
		["Marlton","NJ",39.9,-74.92,9862904],
		["Gresham","OR",45.47,-122.37,9869181],
		["Fayetteville","GA",33.44,-84.46,9875457],
		["Philadelphia","PA",39.95,-75.16,9881732],
		["Mesa","AZ",33.44,-111.85,9888003],
		["Rio Rancho","NM",35.32,-106.71,9894274],
		["San Antonio","TX",29.45,-98.5,9900542],
		["Staten Island","NY",40.58,-74.14,9906809],
		["Hamtramck","MI",42.39,-83.05,9913074],
		["Gurnee","IL",42.37,-87.93,9919338],
		["Wichita","KS",37.68,-97.34,9925595],
		["Carpentersville","IL",42.12,-88.27,9931847],
		["Brooklyn","NY",40.64,-73.94,9938098],
		["De Pere","WI",44.43,-88.07,9944349],
		["Jackson","MS",32.32,-90.2,9950595],
		["Peoria","AZ",33.76,-112.24,9956840],
		["Hatillo","PR",18.48,-66.82,9963084],
		["Tampa","FL",27.95,-82.48,9969327],
		["Pompano Beach","FL",26.23,-80.13,9975569],
		["Tampa","FL",27.95,-82.48,9981804],
		["Mercedes","TX",26.14,-97.92,9988039],
		["Miami","FL",25.77,-80.2,9994270],
		["Middletown","DE",39.45,-75.71,10000499],
		["San Lorenzo","PR",18.19,-65.96,10006727],
		["Hanover","PA",39.81,-76.98,10012954],
		["Cary","NC",35.78,-78.79,10019178],
		["Centreville","VA",38.84,-77.44,10025401],
		["Dublin","OH",40.11,-83.13,10031620],
		["Miami","FL",25.85,-80.41,10037838],
		["Upper Darby","PA",39.96,-75.27,10044055],
		["Richmond","VA",37.55,-77.46,10050270],
		["Malden","MA",42.43,-71.05,10056479],
		["North Hollywood","CA",34.18,-118.39,10062687],
		["Miami","FL",25.77,-80.2,10068893],
		["Appleton","WI",44.26,-88.39,10075098],
		["Levittown","NY",40.72,-73.51,10081302],
		["Ann Arbor","MI",42.27,-83.73,10087506],
		["Eugene","OR",44.04,-123.22,10093708],
		["Elmont","NY",40.7,-73.7,10099907],
		["Springfield","MA",42.11,-72.53,10106105],
		["San Jose","CA",37.19,-121.83,10112302],
		["Phoenix","AZ",33.59,-112.1,10118498],
		["Richmond","VA",37.55,-77.46,10124693],
		["Pearland","TX",29.55,-95.27,10130884],
		["Phoenix","AZ",33.55,-112.13,10137073],
		["Kapolei","HI",21.34,-158.08,10143260],
		["Cabo Rojo","PR",18.08,-67.14,10149445],
		["Bridgeton","NJ",39.42,-75.22,10155629],
		["Killeen","TX",31.09,-97.71,10161813],
		["Denver","CO",39.76,-104.87,10167997],
		["Moorpark","CA",34.31,-118.87,10174179],
		["Aurora","CO",39.69,-104.81,10180358],
		["Hinesville","GA",31.83,-81.59,10186536],
		["Yauco","PR",18.03,-66.86,10192711],
		["Memphis","TN",35.1,-90,10198884],
		["Hampton","VA",37.04,-76.29,10205056],
		["Corpus Christi","TX",27.8,-97.39,10211228],
		["Conway","AR",35.05,-92.5,10217399],
		["Cidra","PR",18.17,-66.15,10223569],
		["New Rochelle","NY",40.92,-73.77,10229738],
		["Chicago","IL",41.83,-87.68,10235907],
		["Montclair","CA",34.07,-117.7,10242074],
		["Fenton","MO",38.53,-90.45,10248240],
		["Phoenix","AZ",33.49,-112.16,10254406],
		["San Sebastian","PR",18.33,-66.99,10260569],
		["Baltimore","MD",39.3,-76.61,10266731],
		["Peachtree City","GA",33.39,-84.56,10272893],
		["Memphis","TN",35.1,-90,10279055],
		["Los Angeles","CA",34.1,-118.22,10285215],
		["Lake Worth","FL",26.61,-80.05,10291373],
		["New Albany","IN",38.3,-85.82,10297531],
		["Vineland","NJ",39.46,-74.99,10303685],
		["Syracuse","NY",43.04,-76.14,10309838],
		["Harvey","LA",29.88,-90.06,10315991],
		["Las Vegas","NV",36.14,-115.27,10322141],
		["Philadelphia","PA",39.95,-75.16,10328290],
		["Brunswick","OH",41.24,-81.82,10334438],
		["Homestead","FL",25.46,-80.45,10340585],
		["Harrisonburg","VA",38.43,-78.87,10346731],
		["Owasso","OK",36.28,-95.83,10352877],
		["Newport News","VA",37.07,-76.51,10359021],
		["Easton","PA",40.68,-75.22,10365163],
		["Oswego","NY",43.45,-76.5,10371301],
		["Houston","TX",29.76,-95.38,10377439],
		["Rocklin","CA",38.81,-121.28,10383577],
		["Arlington","WA",48.18,-121.67,10389715],
		["Bentonville","AR",36.36,-94.2,10395850],
		["Philadelphia","PA",39.95,-75.16,10401982],
		["Sunnyvale","CA",37.35,-122.03,10408114],
		["Compton","CA",33.91,-118.23,10414245],
		["San Jose","CA",37.25,-121.92,10420373],
		["Las Vegas","NV",36.14,-115.03,10426500],
		["Denton","TX",33.14,-97.06,10432624],
		["Santa Ana","CA",33.76,-117.88,10438748],
		["Lancaster","CA",34.72,-118.15,10444872],
		["Moca","PR",18.39,-67.11,10450993],
		["Lithonia","GA",33.71,-84.1,10457112],
		["Corona","CA",33.83,-117.5,10463231],
		["Missouri City","TX",29.57,-95.53,10469349],
		["Puyallup","WA",47.12,-122.25,10475467],
		["Grand Rapids","MI",42.96,-85.65,10481582],
		["Edmond","OK",35.67,-97.41,10487696],
		["Chicago","IL",41.83,-87.68,10493808],
		["Sacramento","CA",38.51,-121.49,10499916],
		["Boone","NC",36.2,-81.66,10506023],
		["Sanger","CA",36.79,-119.44,10512130],
		["Washington","DC",38.89,-77.03,10518236],
		["Houston","TX",29.76,-95.38,10524339],
		["Conway","SC",33.83,-79.04,10530440],
		["Lititz","PA",40.15,-76.3,10536540],
		["Pullman","WA",46.72,-117.18,10542635],
		["Ellicott City","MD",39.27,-76.83,10548729],
		["Avondale","AZ",33.47,-112.29,10554823],
		["West Palm Beach","FL",26.74,-80.12,10560915],
		["Mooresville","NC",35.57,-80.81,10567006],
		["High Point","NC",35.98,-79.99,10573091],
		["Bloomington","CA",34.06,-117.39,10579176],
		["Richmond","VA",37.55,-77.46,10585255],
		["Los Angeles","CA",34.08,-118.2,10591334],
		["Columbus","OH",39.98,-82.98,10597409],
		["Las Cruces","NM",32.33,-106.75,10603483],
		["Pompano Beach","FL",26.23,-80.13,10609553],
		["Rancho Cucamonga","CA",34.14,-117.51,10615623],
		["Tracy","CA",37.62,-121.45,10621692],
		["Canton","MI",42.3,-83.48,10627759],
		["Rancho Palos Verdes","CA",33.75,-118.31,10633826],
		["Rowland Heights","CA",33.97,-117.9,10639886],
		["Miami Gardens","FL",25.93,-80.26,10645945],
		["Ringgold","GA",34.91,-85.1,10652002],
		["Salem","OR",45.01,-122.91,10658058],
		["College Park","MD",38.99,-76.93,10664111],
		["Jonesboro","GA",33.52,-84.35,10670164],
		["Merced","CA",37.29,-120.48,10676217],
		["Freeport","NY",40.65,-73.58,10682269],
		["Yuba City","CA",39.05,-121.61,10688320],
		["Westfield","MA",42.13,-72.75,10694370],
		["Los Angeles","CA",34.02,-118.28,10700420],
		["La Porte","IN",41.6,-86.71,10706468],
		["Taunton","MA",41.9,-71.09,10712512],
		["Las Vegas","NV",36.08,-115.14,10718553],
		["Houston","TX",29.76,-95.38,10724592],
		["Cincinnati","OH",39.09,-84.51,10730628],
		["Capitol Heights","MD",38.87,-76.9,10736662],
		["Rexburg","ID",43.78,-111.76,10742694],
		["Fort Worth","TX",32.75,-97.33,10748722],
		["Houston","TX",29.76,-95.38,10754749],
		["San Antonio","TX",29.45,-98.5,10760776],
		["Federal Way","WA",47.3,-122.31,10766803],
		["Fresno","CA",36.78,-119.82,10772827],
		["Gulfport","MS",30.38,-89.06,10778850],
		["Battle Ground","WA",45.8,-122.48,10784873],
		["Georgetown","KY",38.2,-84.55,10790895],
		["Houston","TX",29.76,-95.38,10796917],
		["Buffalo Grove","IL",42.16,-87.96,10802937],
		["Lawrenceville","GA",33.94,-83.99,10808956],
		["Memphis","TN",35.1,-90,10814975],
		["Rochester","MN",44.01,-92.47,10820994],
		["District Heights","MD",38.85,-76.88,10827012],
		["Gainesville","GA",34.29,-83.83,10833029],
		["Jacksonville","FL",30.33,-81.65,10839046],
		["Winter Park","FL",28.59,-81.34,10845062],
		["Saint Paul","MN",44.94,-93.1,10851077],
		["Victoria","TX",28.82,-96.98,10857092],
		["Kailua","HI",21.4,-157.73,10863104],
		["Indiana","PA",40.62,-79.15,10869113],
		["Richardson","TX",32.97,-96.7,10875120],
		["Newark","NJ",40.72,-74.17,10881126],
		["Palm Bay","FL",27.98,-80.66,10887132],
		["Gilbert","AZ",33.34,-111.81,10893136],
		["Decatur","GA",33.77,-84.29,10899135],
		["Hillsboro","OR",45.58,-122.91,10905131],
		["Powell","OH",40.16,-83.06,10911124],
		["Calhoun","GA",34.49,-84.94,10917114],
		["Wooster","OH",40.81,-81.93,10923103],
		["Claremont","CA",34.12,-117.71,10929092],
		["Phoenix","AZ",33.28,-112.03,10935080],
		["Hilo","HI",19.69,-155.08,10941067],
		["Kissimmee","FL",28.3,-81.41,10947053],
		["Matthews","NC",35.12,-80.71,10953035],
		["Collegeville","PA",40.18,-75.45,10959015],
		["Trenton","MI",42.13,-83.22,10964994],
		["Brooklyn","NY",40.64,-73.94,10970972],
		["Upper Marlboro","MD",38.81,-76.75,10976950],
		["New Lenox","IL",41.51,-87.98,10982928],
		["Grand Prairie","TX",32.69,-97.01,10988906],
		["Hillsborough","NJ",40.47,-74.62,10994882],
		["Sykesville","MD",39.37,-76.97,11000857],
		["Converse","TX",29.51,-98.31,11006826],
		["Santa Rosa","CA",38.39,-122.75,11012793],
		["Camarillo","CA",34.23,-119.07,11018758],
		["York","PA",39.96,-76.73,11024720],
		["Cary","NC",35.78,-78.79,11030681],
		["Charlotte","NC",35.19,-80.83,11036642],
		["Winona","MN",44.04,-91.65,11042602],
		["Sewell","NJ",39.75,-75.09,11048557],
		["Pawtucket","RI",41.87,-71.37,11054511],
		["Monroe","LA",32.51,-92.08,11060459],
		["Roy","UT",41.17,-112.04,11066406],
		["Silver Spring","MD",39.01,-77.02,11072352],
		["Middletown","CT",41.54,-72.65,11078296],
		["Fort Lauderdale","FL",26.14,-80.13,11084240],
		["San Antonio","TX",29.45,-98.5,11090183],
		["Lake Villa","IL",42.41,-88.08,11096125],
		["Cleveland","OH",41.47,-81.67,11102063],
		["San Antonio","TX",29.45,-98.5,11108001],
		["Baytown","TX",29.74,-94.96,11113938],
		["Oswego","IL",41.69,-88.33,11119874],
		["Tulsa","OK",36.12,-95.91,11125810],
		["Tomball","TX",30.09,-95.61,11131745],
		["Athens","GA",33.95,-83.39,11137679],
		["Portland","OR",45.51,-122.49,11143612],
		["Paso Robles","CA",35.61,-120.76,11149542],
		["Portland","OR",45.44,-122.76,11155467],
		["Glendale","AZ",33.53,-112.22,11161389],
		["Williamstown","NJ",39.68,-74.98,11167309],
		["Dubuque","IA",42.5,-90.69,11173229],
		["Amarillo","TX",35.2,-101.81,11179149],
		["Opelousas","LA",30.52,-92.08,11185068],
		["Wenatchee","WA",47.37,-120.35,11190984],
		["Valparaiso","IN",41.47,-87.05,11196896],
		["Allendale","MI",42.98,-85.95,11202808],
		["Greer","SC",34.93,-82.23,11208716],
		["Garland","TX",32.91,-96.62,11214624],
		["Meridian","ID",43.59,-116.42,11220532],
		["Vista","CA",33.18,-117.24,11226439],
		["Lemoore","CA",36.29,-119.82,11232344],
		["Winston Salem","NC",36.1,-80.24,11238248],
		["Holyoke","MA",42.21,-72.64,11244151],
		["Cleveland","OH",41.47,-81.67,11250050],
		["Saint George","UT",37.18,-113.6,11255949],
		["Dallas","TX",32.79,-96.76,11261845],
		["Pottstown","PA",40.25,-75.64,11267734],
		["San Ysidro","CA",32.55,-117.04,11273621],
		["Utica","NY",43.1,-75.23,11279506],
		["Durham","NC",35.98,-78.91,11285390],
		["Saint Paul","MN",44.94,-93.1,11291274],
		["Turlock","CA",37.53,-120.84,11297158],
		["Redmond","WA",47.68,-122.12,11303041],
		["Fitchburg","MA",42.58,-71.81,11308921],
		["Guaynabo","PR",18.38,-66.11,11314798],
		["Southlake","TX",32.95,-97.14,11320674],
		["Jersey City","NJ",40.71,-74.06,11326549],
		["Allentown","PA",40.59,-75.47,11332423],
		["Gilbert","AZ",33.3,-111.74,11338296],
		["Teaneck","NJ",40.88,-74.01,11344167],
		["Richmond","IN",39.82,-84.88,11350035],
		["Starkville","MS",33.45,-88.82,11355901],
		["Sumter","SC",33.94,-80.39,11361764],
		["Pompano Beach","FL",26.23,-80.13,11367627],
		["Renton","WA",47.43,-122.11,11373486],
		["Reno","NV",39.76,-119.86,11379344],
		["Houston","TX",29.76,-95.38,11385197],
		["Houston","TX",29.76,-95.38,11391049],
		["West Orange","NJ",40.79,-74.26,11396899],
		["Saint Paul","MN",44.94,-93.1,11402749],
		["Salem","OR",45.01,-123.01,11408599],
		["Corozal","PR",18.34,-66.31,11414445],
		["Walla Walla","WA",46.11,-118.3,11420291],
		["Berkeley","CA",37.86,-122.25,11426134],
		["Jackson","MI",42.24,-84.4,11431976],
		["Columbia","MO",38.95,-92.32,11437817],
		["Long Beach","CA",33.78,-118.14,11443655],
		["Chandler","AZ",33.24,-111.78,11449489],
		["Laveen","AZ",33.3,-112.2,11455323],
		["Milwaukee","WI",43.06,-87.96,11461155],
		["Redding","CA",40.68,-122.25,11466986],
		["Washington","PA",40.17,-80.24,11472815],
		["Santa Rosa","CA",38.5,-122.76,11478644],
		["Oxford","OH",39.5,-84.74,11484471],
		["Clifton","NJ",40.86,-74.15,11490296],
		["Westbury","NY",40.75,-73.58,11496120],
		["Alhambra","CA",34.09,-118.13,11501944],
		["Martinez","CA",37.98,-122.15,11507768],
		["Chicago","IL",41.83,-87.68,11513591],
		["Lake Mary","FL",28.75,-81.33,11519413],
		["Fairfield","CT",41.13,-73.28,11525234],
		["Jersey City","NJ",40.71,-74.06,11531052],
		["Bellevue","WA",47.55,-122.14,11536865],
		["Peabody","MA",42.53,-70.97,11542676],
		["Newark","CA",37.51,-122.04,11548487],
		["Allentown","PA",40.59,-75.47,11554296],
		["Garner","NC",35.69,-78.62,11560103],
		["El Paso","TX",31.84,-106.43,11565910],
		["Chester","PA",39.84,-75.37,11571716],
		["Virginia Beach","VA",36.78,-76.08,11577522],
		["Rochester","NY",43.16,-77.61,11583327],
		["Bethlehem","PA",40.62,-75.36,11589130],
		["Lexington","SC",33.98,-81.22,11594932],
		["Adrian","MI",41.89,-84.04,11600734],
		["Fresno","CA",36.76,-119.76,11606535],
		["Plainfield","NJ",40.63,-74.4,11612334],
		["Boca Raton","FL",26.37,-80.1,11618133],
		["Citrus Heights","CA",38.69,-121.26,11623930],
		["Garden City","KS",37.97,-100.86,11629726],
		["Green Bay","WI",44.52,-87.98,11635521],
		["Torrance","CA",33.84,-118.35,11641316],
		["San Fernando","CA",34.28,-118.44,11647110],
		["Encinitas","CA",33.05,-117.25,11652902],
		["Saint Cloud","MN",45.55,-94.17,11658692],
		["Lansing","MI",42.7,-84.55,11664481],
		["Greensboro","NC",36.07,-79.82,11670266],
		["Wilmington","DE",39.73,-75.53,11676050],
		["Cartersville","GA",34.16,-84.8,11681833],
		["Inglewood","CA",33.95,-118.36,11687615],
		["Apopka","FL",28.68,-81.51,11693393],
		["Nicholasville","KY",37.87,-84.58,11699170],
		["Ocoee","FL",28.57,-81.53,11704944],
		["West Chicago","IL",41.88,-88.22,11710717],
		["Costa Mesa","CA",33.67,-117.9,11716489],
		["Cincinnati","OH",39.09,-84.51,11722259],
		["Rockford","MI",43.12,-85.55,11728029],
		["Aurora","CO",39.69,-104.81,11733797],
		["Lakeside","CA",32.9,-116.89,11739564],
		["Desert Hot Springs","CA",33.92,-116.48,11745330],
		["New Baltimore","MI",42.68,-82.73,11751095],
		["Danbury","CT",41.4,-73.47,11756858],
		["Chesapeake","VA",36.67,-76.3,11762621],
		["Valley Stream","NY",40.66,-73.7,11768382],
		["Las Vegas","NV",36.1,-115.02,11774143],
		["Anchorage","AK",61.17,-149.63,11779903],
		["Irwin","PA",40.32,-79.69,11785661],
		["Newark","NJ",40.72,-74.17,11791418],
		["Urbana","IL",40.1,-88.2,11797175],
		["Victorville","CA",34.57,-117.33,11802932],
		["Glen Ellyn","IL",41.86,-88.06,11808688],
		["Dallas","TX",32.79,-96.76,11814444],
		["Moline","IL",41.48,-90.48,11820199],
		["Kent","WA",47.4,-122.19,11825954],
		["Carolina","PR",18.4,-65.98,11831707],
		["Arlington","TX",32.69,-97.12,11837460],
		["Phoenix","AZ",33.42,-112.2,11843213],
		["Chandler","AZ",33.27,-111.94,11848966],
		["Philadelphia","PA",39.95,-75.16,11854718],
		["Philadelphia","PA",39.95,-75.16,11860467],
		["Idaho Falls","ID",43.5,-111.89,11866215],
		["Castro Valley","CA",37.7,-122.08,11871963],
		["Chandler","AZ",33.27,-111.82,11877710],
		["Lakeland","FL",28.04,-81.96,11883449],
		["San Antonio","TX",29.45,-98.5,11889187],
		["Fishers","IN",39.96,-85.94,11894923],
		["Revere","MA",42.41,-70.99,11900658],
		["Grand Rapids","MI",42.96,-85.65,11906391],
		["Fairport","NY",43.1,-77.44,11912123],
		["Rapid City","SD",44.07,-103.23,11917852],
		["Prairieville","LA",30.3,-90.97,11923580],
		["Salisbury","MD",38.37,-75.58,11929306],
		["Fairborn","OH",39.8,-84.01,11935030],
		["Herriman","UT",40.48,-112.07,11940753],
		["Yabucoa","PR",18.04,-65.87,11946475],
		["Rowlett","TX",32.9,-96.55,11952195],
		["Yonkers","NY",40.94,-73.86,11957912],
		["Durham","NC",35.98,-78.91,11963629],
		["Winston Salem","NC",36.1,-80.24,11969342],
		["Belleville","MI",42.2,-83.48,11975054],
		["Lanham","MD",38.96,-76.85,11980765],
		["Columbus","IN",39.21,-85.91,11986476],
		["Cedar Rapids","IA",41.97,-91.66,11992185],
		["Houston","TX",29.76,-95.38,11997893],
		["Milwaukee","WI",43.06,-87.96,12003599],
		["Tampa","FL",27.95,-82.48,12009304],
		["Dorado","PR",18.47,-66.27,12015007],
		["New Haven","CT",41.31,-72.92,12020710],
		["Bronx","NY",40.84,-73.87,12026412],
		["Orem","UT",40.31,-111.71,12032114],
		["Mundelein","IL",42.26,-88,12037815],
		["West Jordan","UT",40.62,-111.98,12043516],
		["Kalamazoo","MI",42.27,-85.58,12049215],
		["San Luis","AZ",32.49,-114.77,12054914],
		["Belvidere","IL",42.25,-88.84,12060608],
		["Sheboygan","WI",43.7,-87.74,12066300],
		["Carlsbad","CA",33.09,-117.27,12071992],
		["Forney","TX",32.75,-96.46,12077683],
		["Daly City","CA",37.68,-122.43,12083373],
		["San Tan Valley","AZ",33.17,-111.57,12089056],
		["Mckinney","TX",33.2,-96.65,12094730],
		["Hope Mills","NC",34.97,-78.95,12100403],
		["Washington","DC",38.89,-77.03,12106073],
		["Flint","MI",43.02,-83.69,12111742],
		["El Paso","TX",31.84,-106.43,12117409],
		["Bear","DE",39.58,-75.68,12123075],
		["Louisville","KY",38.22,-85.74,12128739],
		["Pittsfield","MA",42.45,-73.26,12134397],
		["Stone Mountain","GA",33.8,-84.17,12140054],
		["Tampa","FL",27.95,-82.48,12145710],
		["Hattiesburg","MS",31.31,-89.3,12151366],
		["Columbus","OH",39.98,-82.98,12157022],
		["Merrillville","IN",41.47,-87.32,12162678],
		["Lafayette","IN",40.41,-86.87,12168333],
		["Bronx","NY",40.84,-73.87,12173987],
		["Florence","SC",34.18,-79.78,12179638],
		["Neenah","WI",44.16,-88.47,12185288],
		["Vega Alta","PR",18.41,-66.32,12190936],
		["Channelview","TX",29.78,-95.11,12196583],
		["Amarillo","TX",35.2,-101.81,12202225],
		["Hopkinsville","KY",36.85,-87.49,12207865],
		["Yuba City","CA",39.05,-121.73,12213505],
		["Rochester","NY",43.16,-77.61,12219143],
		["Fort Collins","CO",40.55,-105.06,12224781],
		["Covina","CA",34.09,-117.9,12230419],
		["Snellville","GA",33.85,-84,12236055],
		["Granite City","IL",38.71,-90.12,12241691],
		["Houston","TX",29.76,-95.38,12247327],
		["Los Angeles","CA",34.06,-118.27,12252963],
		["Port Arthur","TX",29.89,-93.92,12258598],
		["Long Beach","CA",33.79,-118.12,12264232],
		["Little Rock","AR",34.72,-92.35,12269863],
		["Louisville","KY",38.22,-85.74,12275493],
		["Madison","WI",43.07,-89.38,12281123],
		["San Antonio","TX",29.45,-98.5,12286753],
		["San Jose","CA",37.3,-122,12292383],
		["Lewisville","TX",33.04,-96.98,12298012],
		["North Tonawanda","NY",43.04,-78.86,12303640],
		["Elizabeth City","NC",36.29,-76.22,12309268],
		["Roseville","CA",38.76,-121.28,12314896],
		["Somerset","NJ",40.49,-74.48,12320519],
		["Fishers","IN",39.95,-86.02,12326141],
		["Lakewood","OH",41.48,-81.8,12331760],
		["Fort Collins","CO",40.55,-105.06,12337378],
		["Wilson","NC",35.73,-77.92,12342995],
		["Springfield Gardens","NY",40.66,-73.75,12348611],
		["Kissimmee","FL",28.3,-81.41,12354227],
		["Cordova","TN",35.15,-89.75,12359841],
		["Tolleson","AZ",33.42,-112.27,12365455],
		["Modesto","CA",37.65,-121.13,12371068],
		["Wallingford","CT",41.44,-72.81,12376678],
		["Fairfield","OH",39.33,-84.54,12382288],
		["Morovis","PR",18.32,-66.4,12387896],
		["Odessa","TX",31.85,-102.36,12393503],
		["Springfield","MO",37.19,-93.28,12399106],
		["Woodhaven","NY",40.69,-73.85,12404707],
		["Anchorage","AK",61.14,-149.76,12410308],
		["Harlingen","TX",26.19,-97.68,12415907],
		["Tallahassee","FL",30.45,-84.28,12421502],
		["Riverdale","IL",41.64,-87.63,12427095],
		["Waxahachie","TX",32.4,-96.85,12432688],
		["Houston","TX",29.76,-95.38,12438278],
		["Medford","OR",42.33,-122.79,12443868],
		["Springdale","AR",36.18,-94.14,12449457],
		["San Leandro","CA",37.71,-122.16,12455046],
		["Irvine","CA",33.7,-117.76,12460634],
		["Reno","NV",39.48,-119.74,12466220],
		["Bangor","ME",44.83,-68.78,12471804],
		["Grayslake","IL",42.34,-88.03,12477388],
		["Columbia","SC",34,-81.03,12482971],
		["Bridgewater","NJ",40.59,-74.62,12488552],
		["Bloomfield","NJ",40.81,-74.18,12494132],
		["Phoenix","AZ",33.5,-112.1,12499710],
		["Gonzales","LA",30.21,-90.92,12505287],
		["Anchorage","AK",61.2,-149.82,12510863],
		["Fuquay Varina","NC",35.54,-78.83,12516437],
		["Madison","MS",32.45,-90.1,12522011],
		["Cleveland","OH",41.47,-81.67,12527584],
		["Chesapeake","VA",36.67,-76.3,12533155],
		["Orlando","FL",28.5,-81.37,12538726],
		["San Tan Valley","AZ",33.16,-111.56,12544297],
		["Henderson","NV",36.01,-114.95,12549867],
		["Rogers","AR",36.33,-94.13,12555433],
		["Las Piedras","PR",18.18,-65.86,12560998],
		["Fort Worth","TX",32.75,-97.33,12566563],
		["Odessa","TX",31.85,-102.36,12572128],
		["Tampa","FL",27.95,-82.48,12577688],
		["Los Angeles","CA",33.95,-118.4,12583248],
		["Santa Clara","CA",37.35,-121.98,12588808],
		["Arlington","TX",32.69,-97.12,12594363],
		["Jackson","MI",42.24,-84.4,12599917],
		["Hamburg","NY",42.72,-78.83,12605470],
		["Flagstaff","AZ",35.29,-111.32,12611023],
		["Jackson","TN",35.63,-88.82,12616575],
		["Jacksonville","NC",34.76,-77.4,12622126],
		["Anderson","SC",34.51,-82.64,12627677],
		["Rosenberg","TX",29.54,-95.79,12633227],
		["Ramona","CA",33.07,-116.83,12638777],
		["Saint George","UT",37.06,-113.57,12644326],
		["Peoria","AZ",33.65,-112.24,12649874],
		["Winter Haven","FL",28.04,-81.72,12655417],
		["Raleigh","NC",35.82,-78.65,12660959],
		["Kissimmee","FL",28.3,-81.41,12666500],
		["Sterling Heights","MI",42.58,-83.03,12672041],
		["Santa Clarita","CA",34.43,-118.51,12677582],
		["Chandler","AZ",33.32,-111.87,12683121],
		["Linden","NJ",40.62,-74.23,12688659],
		["Las Vegas","NV",36.17,-115.2,12694197],
		["Huntington","NY",40.87,-73.4,12699734],
		["Hudsonville","MI",42.86,-85.86,12705268],
		["Park Ridge","IL",42.01,-87.84,12710800],
		["Acworth","GA",34.05,-84.67,12716331],
		["Portage","IN",41.58,-87.18,12721862],
		["Ft Mitchell","KY",39.02,-84.56,12727392],
		["Los Angeles","CA",34,-118.3,12732922],
		["San Diego","CA",33.02,-117.12,12738449],
		["Miami","FL",25.77,-80.2,12743975],
		["Milwaukee","WI",43.06,-87.96,12749500],
		["Port Saint Lucie","FL",27.27,-80.35,12755021],
		["Orlando","FL",28.5,-81.37,12760540],
		["Oshkosh","WI",44.01,-88.55,12766058],
		["Hobbs","NM",32.73,-103.16,12771574],
		["San Jose","CA",37.42,-121.77,12777090],
		["Merced","CA",37.41,-120.5,12782604],
		["Buena Park","CA",33.87,-117.99,12788117],
		["Gallatin","TN",36.37,-86.45,12793628],
		["Post Falls","ID",47.78,-117.02,12799138],
		["Bloomsburg","PA",41,-76.45,12804644],
		["Rochester","NY",43.16,-77.61,12810149],
		["Brooklyn","NY",40.64,-73.94,12815653],
		["Austin","TX",30.26,-97.74,12821157],
		["San Jose","CA",37.3,-121.8,12826660],
		["Wahiawa","HI",21.5,-158.02,12832161],
		["Glenview","IL",42.07,-87.81,12837661],
		["El Mirage","AZ",33.59,-112.33,12843161],
		["Aurora","IL",41.76,-88.29,12848659],
		["Altoona","PA",40.5,-78.4,12854156],
		["San Antonio","TX",29.45,-98.5,12859652],
		["Monrovia","CA",34.15,-117.99,12865146],
		["Michigan City","IN",41.71,-86.87,12870639],
		["Liberty","MO",39.24,-94.41,12876131],
		["Loveland","CO",40.41,-105.07,12881623],
		["Arlington","TX",32.69,-97.12,12887114],
		["West Babylon","NY",40.71,-73.35,12892603],
		["Westland","MI",42.31,-83.38,12898088],
		["Franklin","MA",42.08,-71.38,12903572],
		["New Hyde Park","NY",40.73,-73.68,12909056],
		["Owensboro","KY",37.75,-87.11,12914539],
		["Sparks","NV",39.64,-119.69,12920019],
		["Mesa","AZ",33.44,-111.8,12925498],
		["Pompano Beach","FL",26.23,-80.13,12930974],
		["Valparaiso","IN",41.47,-87.05,12936450],
		["Round Rock","TX",30.54,-97.64,12941925],
		["Staten Island","NY",40.58,-74.14,12947396],
		["Denver","CO",39.76,-104.87,12952867],
		["San Diego","CA",32.8,-117.16,12958336],
		["Patchogue","NY",40.76,-73.01,12963804],
		["Orange Park","FL",30.16,-81.7,12969271],
		["Lebanon","TN",36.2,-86.32,12974738],
		["San Antonio","TX",29.45,-98.5,12980204],
		["Memphis","TN",35.1,-90,12985668],
		["New Bedford","MA",41.66,-70.93,12991131],
		["Portland","OR",45.46,-122.49,12996594],
		["Detroit","MI",42.38,-83.1,13002056],
		["Cedar Rapids","IA",41.97,-91.66,13007518],
		["Tyler","TX",32.32,-95.3,13012979],
		["Cottage Grove","MN",44.81,-92.92,13018439],
		["Fullerton","CA",33.87,-117.89,13023895],
		["Mcminnville","OR",45.19,-123.24,13029351],
		["Catonsville","MD",39.26,-76.74,13034806],
		["Raleigh","NC",35.82,-78.65,13040261],
		["Rome","GA",34.26,-85.18,13045716],
		["Philadelphia","PA",39.95,-75.16,13051168],
		["Chapel Hill","NC",35.92,-79.04,13056620],
		["Elmwood Park","IL",41.92,-87.81,13062070],
		["Minneapolis","MN",44.96,-93.26,13067518],
		["Orange Park","FL",30.16,-81.7,13072965],
		["Tempe","AZ",33.36,-111.93,13078412],
		["Hemet","CA",33.73,-117.05,13083858],
		["Elk River","MN",45.33,-93.56,13089302],
		["Ogden","UT",41.21,-111.97,13094746],
		["Attleboro","MA",41.93,-71.29,13100189],
		["Winder","GA",33.99,-83.72,13105632],
		["Concord","CA",37.97,-121.96,13111074],
		["Fairfield","CA",38.23,-122.12,13116516],
		["Milwaukee","WI",43.06,-87.96,13121957],
		["Camuy","PR",18.48,-66.84,13127397],
		["Murfreesboro","TN",35.85,-86.39,13132837],
		["Columbus","OH",39.98,-82.98,13138277],
		["La Porte","TX",29.66,-95.04,13143715],
		["Canyon Country","CA",34.43,-118.46,13149151],
		["Northbrook","IL",42.12,-87.83,13154586],
		["Spring","TX",30.06,-95.38,13160021],
		["Clarksville","TN",36.56,-87.34,13165455],
		["Port Huron","MI",42.97,-82.42,13170889],
		["Eugene","OR",44.06,-123.08,13176321],
		["Clifton Park","NY",42.85,-73.8,13181752],
		["Belton","TX",31.06,-97.46,13187178],
		["Streamwood","IL",42.02,-88.17,13192603],
		["Indianapolis","IN",39.77,-86.14,13198026],
		["Lumberton","NC",34.63,-79.01,13203448],
		["Rochester","MI",42.68,-83.12,13208869],
		["Medford","OR",42.28,-122.9,13214290],
		["Tampa","FL",27.95,-82.48,13219710],
		["Chattanooga","TN",35.06,-85.25,13225129],
		["Golden","CO",39.73,-105.21,13230548],
		["Ogden","UT",41.18,-111.91,13235967],
		["Southampton","PA",40.18,-75,13241384],
		["Clayton","NC",35.64,-78.45,13246800],
		["Palm Coast","FL",29.57,-81.21,13252216],
		["Burnsville","MN",44.76,-93.27,13257632],
		["Gretna","LA",29.91,-90.05,13263047],
		["Fort Worth","TX",32.75,-97.33,13268462],
		["Cleveland","OH",41.47,-81.67,13273876],
		["Mesa","AZ",33.38,-111.84,13279290],
		["Bellingham","WA",48.79,-122.41,13284704],
		["Nashville","TN",36.17,-86.78,13290115],
		["Tucson","AZ",32.21,-110.88,13295525],
		["San Ramon","CA",37.76,-121.93,13300935],
		["Addison","IL",41.93,-88,13306344],
		["Zion","IL",42.45,-87.84,13311752],
		["Las Vegas","NV",36.15,-115.11,13317159],
		["Philadelphia","PA",39.95,-75.16,13322565],
		["Jamestown","NY",42.09,-79.23,13327965],
		["Mount Vernon","NY",40.91,-73.82,13333364],
		["Saint Charles","MO",38.79,-90.51,13338763],
		["Brick","NJ",40.06,-74.11,13344161],
		["Reading","PA",40.33,-75.92,13349558],
		["Beaumont","TX",30.08,-94.14,13354955],
		["Lawndale","CA",33.88,-118.35,13360351],
		["Selma","CA",36.53,-119.64,13365742],
		["Miami","FL",25.77,-80.2,13371131],
		["Thousand Oaks","CA",34.19,-118.81,13376520],
		["Leominster","MA",42.51,-71.77,13381907],
		["Kissimmee","FL",28.3,-81.41,13387293],
		["Manteca","CA",37.73,-121.24,13392678],
		["Barranquitas","PR",18.18,-66.3,13398061],
		["Baton Rouge","LA",30.44,-91.12,13403443],
		["Henderson","NV",35.98,-115.11,13408825],
		["Rockville","MD",39.08,-77.15,13414206],
		["Cabot","AR",34.96,-92.02,13419587],
		["San Marcos","CA",33.11,-117.16,13424968],
		["La Place","LA",30.12,-90.46,13430347],
		["Littleton","CO",39.59,-105.01,13435726],
		["Santa Maria","CA",34.91,-120.23,13441105],
		["Mount Laurel","NJ",39.94,-74.9,13446483],
		["Salt Lake City","UT",40.81,-111.94,13451859],
		["Clementon","NJ",39.8,-74.98,13457234],
		["Mechanicsville","VA",37.62,-77.35,13462609],
		["Lincoln","NE",40.81,-96.68,13467984],
		["Pekin","IL",40.56,-89.62,13473355],
		["Saint Louis","MO",38.63,-90.24,13478725],
		["Houston","TX",29.76,-95.38,13484094],
		["Baltimore","MD",39.3,-76.61,13489462],
		["Jeffersonville","IN",38.3,-85.72,13494829],
		["Westminster","MD",39.57,-77,13500194],
		["Fajardo","PR",18.33,-65.65,13505558],
		["Lincoln","CA",38.93,-121.25,13510922],
		["Greenfield","IN",39.79,-85.77,13516284],
		["Irvine","CA",33.63,-117.83,13521645],
		["Indianapolis","IN",39.77,-86.14,13527002],
		["Salt Lake City","UT",40.62,-111.71,13532359],
		["Ponce","PR",17.98,-66.6,13537713],
		["Elkhart","IN",41.68,-85.96,13543065],
		["Petersburg","VA",37.2,-77.39,13548412],
		["East Elmhurst","NY",40.76,-73.88,13553758],
		["Livonia","MI",42.39,-83.37,13559104],
		["Millville","NJ",39.39,-75.05,13564449],
		["Austin","TX",30.26,-97.74,13569793],
		["Louisville","KY",38.22,-85.74,13575134],
		["Monroe","LA",32.51,-92.08,13580475],
		["Humble","TX",29.99,-95.26,13585816],
		["Reedley","CA",36.65,-119.4,13591156],
		["Goldsboro","NC",35.37,-77.97,13596489],
		["Wayne","PA",40.06,-75.4,13601821],
		["Philadelphia","PA",39.95,-75.16,13607152],
		["Oceanside","CA",33.18,-117.29,13612479],
		["Lincoln Park","MI",42.24,-83.18,13617804],
		["Petaluma","CA",38.23,-122.56,13623129],
		["Irmo","SC",34.09,-81.18,13628452],
		["Phoenix","AZ",33.4,-112.02,13633775],
		["Corvallis","OR",44.63,-123.27,13639098],
		["Reading","PA",40.33,-75.92,13644420],
		["Canton","GA",34.24,-84.49,13649742],
		["Santa Paula","CA",34.4,-119.07,13655062],
		["San Antonio","TX",29.45,-98.5,13660381],
		["Orange","CA",33.79,-117.78,13665699],
		["Sandy","UT",40.56,-111.72,13671016],
		["Virginia Beach","VA",36.73,-76.04,13676332],
		["Rock Island","IL",41.47,-90.58,13681645],
		["Fayetteville","NC",35.05,-78.87,13686957],
		["Smithtown","NY",40.85,-73.21,13692268],
		["Tuscaloosa","AL",33.23,-87.54,13697579],
		["Wentzville","MO",38.81,-90.85,13702890],
		["Chesterfield","VA",37.37,-77.5,13708200],
		["Atlanta","GA",33.78,-84.44,13713510],
		["Houston","TX",29.76,-95.38,13718820],
		["Tucson","AZ",32.17,-110.79,13724129],
		["Trumbull","CT",41.25,-73.2,13729436],
		["Endicott","NY",42.09,-76.05,13734742],
		["Shakopee","MN",44.77,-93.47,13740048],
		["Coraopolis","PA",40.51,-80.16,13745353],
		["Sanford","NC",35.47,-79.18,13750657],
		["Barberton","OH",41.01,-81.6,13755961],
		["Stillwater","MN",45.05,-92.82,13761262],
		["Grand Rapids","MI",42.96,-85.65,13766561],
		["Fort Worth","TX",32.75,-97.33,13771859],
		["Houston","TX",29.76,-95.38,13777156],
		["Little Elm","TX",33.16,-96.93,13782451],
		["Wilmington","NC",34.21,-77.91,13787745],
		["Ballwin","MO",38.59,-90.55,13793038],
		["Whittier","CA",33.98,-118.06,13798329],
		["Ocean Springs","MS",30.4,-88.8,13803618],
		["Fenton","MI",42.8,-83.71,13808907],
		["Griffin","GA",33.24,-84.27,13814193],
		["Detroit","MI",42.38,-83.1,13819479],
		["Harvey","IL",41.6,-87.65,13824765],
		["San Diego","CA",32.68,-117.04,13830051],
		["Tempe","AZ",33.4,-111.92,13835335],
		["San Jose","CA",37.29,-121.88,13840619],
		["Bethesda","MD",38.98,-77.12,13845902],
		["Newhall","CA",34.36,-118.47,13851185],
		["Essex","MD",39.3,-76.44,13856467],
		["Charlotte","NC",35.19,-80.83,13861747],
		["Valrico","FL",27.88,-82.24,13867026],
		["Chesterfield","MO",38.65,-90.58,13872304],
		["Stafford","TX",29.62,-95.56,13877581],
		["Phoenix","AZ",33.62,-112.05,13882858],
		["Fond Du Lac","WI",43.77,-88.44,13888133],
		["Houston","TX",29.76,-95.38,13893407],
		["Edison","NJ",40.52,-74.36,13898679],
		["Charlotte","NC",35.19,-80.83,13903950],
		["Bossier City","LA",32.51,-93.66,13909218],
		["San Bernardino","CA",34.14,-117.3,13914486],
		["Glendale","AZ",33.57,-112.18,13919752],
		["Paterson","NJ",40.91,-74.16,13925016],
		["Covington","GA",33.6,-83.85,13930280],
		["Minneapolis","MN",44.96,-93.26,13935544],
		["Galt","CA",38.27,-121.22,13940808],
		["Andover","MA",42.65,-71.14,13946071],
		["Wheeling","WV",40.07,-80.69,13951334],
		["Dearborn Heights","MI",42.32,-83.27,13956597],
		["Oakland","CA",37.73,-122.17,13961857],
		["Everett","MA",42.4,-71.05,13967116],
		["Riverdale","GA",33.56,-84.4,13972373],
		["Jacksonville","FL",30.33,-81.65,13977629],
		["Huntsville","AL",34.7,-86.62,13982884],
		["Inglewood","CA",33.93,-118.36,13988135],
		["East Stroudsburg","PA",41,-75.17,13993382],
		["Herndon","VA",38.96,-77.38,13998627],
		["Seaside","CA",36.62,-121.82,14003871],
		["Chester","VA",37.35,-77.43,14009114],
		["Edwardsville","IL",38.8,-89.96,14014357],
		["Temple Hills","MD",38.8,-76.94,14019599],
		["East Lansing","MI",42.73,-84.48,14024840],
		["Dover","DE",39.15,-75.51,14030080],
		["Granger","IN",41.73,-86.13,14035320],
		["Saint Paul","MN",44.94,-93.1,14040560],
		["Dinuba","CA",36.52,-119.39,14045799],
		["Richmond","CA",37.92,-122.34,14051037],
		["Baltimore","MD",39.3,-76.61,14056273],
		["Richmond","TX",29.58,-95.76,14061509],
		["Bend","OR",43.98,-121.27,14066745],
		["Ronkonkoma","NY",40.8,-73.12,14071980],
		["La Vergne","TN",35.99,-86.57,14077214],
		["Eugene","OR",43.92,-123.08,14082448],
		["Houston","TX",29.76,-95.38,14087681],
		["Waldorf","MD",38.64,-76.9,14092912],
		["Danville","IL",40.14,-87.61,14098143],
		["Old Bridge","NJ",40.39,-74.33,14103373],
		["Fort Lauderdale","FL",26.11,-80.39,14108603],
		["Houston","TX",29.76,-95.38,14113833],
		["Mesquite","TX",32.76,-96.59,14119060],
		["Dickinson","TX",29.45,-95.06,14124286],
		["Elizabeth","NJ",40.66,-74.19,14129511],
		["Raleigh","NC",35.82,-78.65,14134735],
		["Clinton","MD",38.76,-76.89,14139958],
		["Kankakee","IL",41.11,-87.86,14145178],
		["Brownsburg","IN",39.84,-86.39,14150397],
		["San Bernardino","CA",34.12,-117.32,14155616],
		["Citrus Heights","CA",38.69,-121.31,14160833],
		["Merrick","NY",40.65,-73.55,14166048],
		["Cleveland","OH",41.47,-81.67,14171261],
		["Bel Air","MD",39.53,-76.34,14176473],
		["Sacramento","CA",38.53,-121.44,14181685],
		["Southaven","MS",34.97,-89.99,14186896],
		["Olympia","WA",46.97,-122.87,14192107],
		["Algonquin","IL",42.16,-88.3,14197316],
		["Indian Trail","NC",35.07,-80.67,14202524],
		["Dallas","GA",33.91,-84.83,14207732],
		["Raleigh","NC",35.82,-78.65,14212936],
		["Conyers","GA",33.66,-84.01,14218139],
		["Mobile","AL",30.68,-88.04,14223342],
		["Sandusky","OH",41.45,-82.71,14228543],
		["Cape Girardeau","MO",37.3,-89.55,14233744],
		["Spring","TX",30.06,-95.38,14238945],
		["Woodland","CA",38.71,-121.85,14244146],
		["Woonsocket","RI",41.99,-71.5,14249344],
		["Springville","UT",40.2,-111.47,14254540],
		["Carolina","PR",18.4,-65.98,14259735],
		["Alliance","OH",40.91,-81.11,14264929],
		["Houston","TX",29.76,-95.38,14270123],
		["Raeford","NC",34.97,-79.22,14275316],
		["Taylors","SC",34.91,-82.31,14280508],
		["Roswell","GA",34.03,-84.35,14285700],
		["Lawrenceville","GA",33.94,-83.99,14290891],
		["Redford","MI",42.39,-83.29,14296082],
		["San Jacinto","CA",33.79,-116.93,14301271],
		["Enfield","CT",41.96,-72.56,14306458],
		["Baton Rouge","LA",30.44,-91.12,14311643],
		["Memphis","TN",35.1,-90,14316826],
		["Louisville","KY",38.22,-85.74,14322008],
		["Indianapolis","IN",39.77,-86.14,14327188],
		["Toledo","OH",41.66,-83.58,14332367],
		["San Juan Capistrano","CA",33.52,-117.61,14337545],
		["Charlotte","NC",35.19,-80.83,14342720],
		["Tallahassee","FL",30.45,-84.28,14347895],
		["Columbus","OH",39.98,-82.98,14353069],
		["Marietta","GA",33.95,-84.54,14358242],
		["Searcy","AR",35.24,-91.73,14363415],
		["East Orange","NJ",40.76,-74.21,14368586],
		["North Canton","OH",40.81,-81.37,14373757],
		["Jamaica","NY",40.68,-73.78,14378927],
		["Lincolnton","NC",35.47,-81.24,14384097],
		["Chico","CA",39.71,-121.81,14389267],
		["Columbia","MD",39.2,-76.85,14394436],
		["Charlotte","NC",35.19,-80.83,14399602],
		["Sacramento","CA",38.51,-121.44,14404768],
		["Mount Airy","NC",36.5,-80.61,14409933],
		["Rome","NY",43.21,-75.47,14415097],
		["Corpus Christi","TX",27.8,-97.39,14420256],
		["Sacramento","CA",38.61,-121.49,14425415],
		["Lexington","NC",35.8,-80.25,14430572],
		["Salt Lake City","UT",40.65,-111.92,14435727],
		["San Diego","CA",32.99,-117.07,14440881],
		["Copperas Cove","TX",31.11,-97.9,14446034],
		["Hialeah","FL",25.86,-80.29,14451184],
		["Amherst","MA",42.37,-72.52,14456332],
		["Council Bluffs","IA",41.24,-95.85,14461479],
		["Salisbury","MD",38.37,-75.58,14466621],
		["Burlington","NC",36.08,-79.44,14471763],
		["Blackwood","NJ",39.79,-75.06,14476904],
		["Stow","OH",41.17,-81.43,14482044],
		["Albuquerque","NM",35.11,-106.62,14487184],
		["Los Angeles","CA",34.02,-118.39,14492324],
		["La Quinta","CA",33.65,-116.27,14497464],
		["Colorado Springs","CO",38.86,-104.76,14502602],
		["Mobile","AL",30.68,-88.04,14507739],
		["Derry","NH",42.89,-71.28,14512873],
		["Brighton","CO",39.95,-104.79,14518007],
		["Fair Oaks","CA",38.65,-121.25,14523139],
		["Raleigh","NC",35.82,-78.65,14528270],
		["San Leandro","CA",37.7,-122.12,14533401],
		["Beverly","MA",42.55,-70.84,14538530],
		["Auburn","NY",42.93,-76.56,14543659],
		["Camden","NJ",39.93,-75.1,14548785],
		["Macon","GA",32.83,-83.65,14553911],
		["Livermore","CA",37.77,-121.75,14559036],
		["La Puente","CA",34.04,-117.98,14564159],
		["La Verne","CA",34.15,-117.76,14569282],
		["San Jose","CA",37.27,-121.84,14574405],
		["Mayaguez","PR",18.2,-67.14,14579526],
		["Anaheim","CA",33.83,-117.86,14584647],
		["Mesa","AZ",33.44,-111.76,14589767],
		["Kokomo","IN",40.47,-86.13,14594886],
		["Fort Worth","TX",32.75,-97.33,14600005],
		["Corpus Christi","TX",27.8,-97.39,14605124],
		["La Crescenta","CA",34.23,-118.24,14610243],
		["Hyattsville","MD",38.95,-76.95,14615359],
		["Phoenix","AZ",33.55,-112.09,14620475],
		["Miami","FL",25.77,-80.2,14625588],
		["Seattle","WA",47.54,-122.27,14630700],
		["Orem","UT",40.29,-111.72,14635811],
		["Pocatello","ID",42.9,-112.39,14640921],
		["Denton","TX",33.21,-97.13,14646028],
		["Staten Island","NY",40.58,-74.14,14651133],
		["Chicago","IL",41.83,-87.68,14656237],
		["Houston","TX",29.76,-95.38,14661340],
		["Detroit","MI",42.38,-83.1,14666442],
		["San Francisco","CA",37.72,-122.41,14671544],
		["Saint Albans","NY",40.69,-73.75,14676645],
		["Lake Worth","FL",26.61,-80.05,14681746],
		["Port Richey","FL",28.3,-82.7,14686846],
		["Hicksville","NY",40.76,-73.52,14691945],
		["Tampa","FL",27.95,-82.48,14697044],
		["Knoxville","TN",35.97,-83.94,14702142],
		["Midlothian","TX",32.49,-96.99,14707240],
		["Shrewsbury","MA",42.3,-71.71,14712335],
		["Indianapolis","IN",39.77,-86.14,14717430],
		["Roswell","NM",33.33,-104.52,14722525],
		["Long Beach","CA",33.82,-118.11,14727619],
		["Indianapolis","IN",39.77,-86.14,14732712],
		["Gainesville","FL",29.67,-82.33,14737804],
		["Homestead","FL",25.46,-80.45,14742895],
		["Newburgh","IN",37.94,-87.4,14747986],
		["Westland","MI",42.31,-83.38,14753077],
		["Lake Stevens","WA",48.04,-122.05,14758168],
		["Henrico","VA",37.55,-77.46,14763258],
		["Houston","TX",29.76,-95.38,14768348],
		["Edison","NJ",40.52,-74.36,14773437],
		["Barstow","CA",34.97,-116.99,14778525],
		["Valdosta","GA",30.84,-83.27,14783612],
		["Perrysburg","OH",41.55,-83.62,14788698],
		["Rogers","AR",36.33,-94.13,14793781],
		["Salt Lake City","UT",40.7,-112.05,14798864],
		["Chatsworth","GA",34.77,-84.77,14803945],
		["Palatine","IL",42.11,-88.04,14809025],
		["Kingwood","TX",29.99,-95.26,14814105],
		["Spokane","WA",47.69,-117.45,14819185],
		["Kearny","NJ",40.75,-74.11,14824264],
		["Saint Charles","IL",41.92,-88.3,14829343],
		["Reidsville","NC",36.34,-79.67,14834421],
		["Cleveland","OH",41.47,-81.67,14839499],
		["Knoxville","TN",35.97,-83.94,14844576],
		["Milwaukee","WI",43.06,-87.96,14849652],
		["Billings","MT",45.76,-108.57,14854727],
		["Salem","VA",37.28,-80.05,14859800],
		["Texarkana","TX",33.44,-94.07,14864873],
		["Springfield","OR",44.1,-122.8,14869944],
		["Jersey City","NJ",40.71,-74.06,14875013],
		["Sparks","NV",39.53,-119.73,14880081],
		["Atlantic City","NJ",39.36,-74.42,14885148],
		["San Diego","CA",32.81,-117.19,14890213],
		["Philadelphia","PA",39.95,-75.16,14895277],
		["Central Islip","NY",40.78,-73.19,14900340],
		["Dodge City","KS",37.75,-100.01,14905403],
		["Loveland","CO",40.41,-105.07,14910466],
		["Ann Arbor","MI",42.27,-83.73,14915528],
		["Ormond Beach","FL",29.28,-81.06,14920589],
		["Shreveport","LA",32.47,-93.79,14925650],
		["Lufkin","TX",31.33,-94.72,14930709],
		["Oakland","CA",37.73,-122.2,14935768],
		["Cibolo","TX",29.57,-98.23,14940826],
		["Albuquerque","NM",35.11,-106.62,14945884],
		["Savannah","GA",32.08,-81.1,14950941],
		["Frankfort","IL",41.49,-87.83,14955997],
		["Ypsilanti","MI",42.24,-83.62,14961052],
		["Fresno","CA",36.81,-119.75,14966107],
		["Oakland","CA",37.75,-122.13,14971161],
		["Torrance","CA",33.8,-118.35,14976213],
		["Valrico","FL",27.94,-82.25,14981264],
		["Thornton","CO",39.76,-104.87,14986314],
		["Bay City","MI",43.59,-83.88,14991362],
		["Manitowoc","WI",44.09,-87.67,14996410],
		["Villa Rica","GA",33.73,-84.92,15001455],
		["Lebanon","OH",39.42,-84.21,15006500],
		["Geneva","IL",41.88,-88.31,15011544],
		["North Highlands","CA",38.66,-121.37,15016588],
		["Orlando","FL",28.5,-81.37,15021631],
		["Clovis","CA",36.81,-119.71,15026673],
		["Auburn","WA",47.3,-122.26,15031715],
		["Newport News","VA",37.07,-76.51,15036756],
		["Elkhart","IN",41.68,-85.96,15041797],
		["Baltimore","MD",39.3,-76.61,15046836],
		["Paragould","AR",36.05,-90.51,15051875],
		["Eden Prairie","MN",44.84,-93.45,15056913],
		["Pineville","LA",31.33,-92.41,15061950],
		["Mount Airy","MD",39.37,-77.15,15066986],
		["Mooresville","NC",35.57,-80.81,15072022],
		["Bloomington","IN",39.16,-86.52,15077058],
		["Colorado Springs","CO",38.86,-104.76,15082093],
		["Owensboro","KY",37.75,-87.11,15087127],
		["Marion","IA",42.03,-91.58,15092161],
		["Morristown","NJ",40.79,-74.47,15097194],
		["Saint Louis","MO",38.63,-90.24,15102227],
		["Maple Valley","WA",47.4,-121.99,15107260],
		["Snohomish","WA",47.94,-122,15112290],
		["Philadelphia","PA",39.95,-75.16,15117319],
		["Mira Loma","CA",33.99,-117.53,15122348],
		["Mckinney","TX",33.2,-96.65,15127376],
		["Tampa","FL",27.95,-82.48,15132403],
		["Kent","WA",47.36,-122.19,15137430],
		["Houston","TX",29.76,-95.38,15142455],
		["Jacksonville","AR",34.86,-92.12,15147479],
		["Lafayette","LA",30.21,-92.02,15152501],
		["Minneapolis","MN",44.96,-93.26,15157522],
		["Fresh Meadows","NY",40.73,-73.79,15162541],
		["New York","NY",40.71,-73.99,15167559],
		["Sylvania","OH",41.71,-83.7,15172577],
		["Rochester","NY",43.16,-77.61,15177592],
		["Van Buren","AR",35.44,-94.35,15182607],
		["Brea","CA",33.92,-117.86,15187621],
		["Reisterstown","MD",39.45,-76.81,15192633],
		["Las Vegas","NV",36.06,-115.31,15197645],
		["Libertyville","IL",42.28,-87.96,15202656],
		["Flagstaff","AZ",35.23,-111.72,15207667],
		["Fort Lauderdale","FL",26.11,-80.39,15212675],
		["Daytona Beach","FL",29.19,-81.05,15217679],
		["Lexington","NC",35.8,-80.25,15222682],
		["Magnolia","TX",30.21,-95.74,15227685],
		["Vacaville","CA",38.41,-122.01,15232688],
		["Palatine","IL",42.11,-88.04,15237686],
		["Lubbock","TX",33.57,-101.87,15242684],
		["Camarillo","CA",34.2,-118.98,15247679],
		["Bloomington","IL",40.47,-88.97,15252671],
		["Shippensburg","PA",40.04,-77.52,15257662],
		["Cumberland","MD",39.65,-78.76,15262653],
		["Tucson","AZ",32.07,-110.92,15267643],
		["Middle River","MD",39.33,-76.43,15272632],
		["Hialeah","FL",25.86,-80.29,15277620],
		["Buffalo","NY",42.88,-78.85,15282605],
		["Boise","ID",43.63,-116.28,15287589],
		["Los Angeles","CA",33.92,-118.27,15292573],
		["Pleasanton","CA",37.67,-121.93,15297557],
		["Philadelphia","PA",39.95,-75.16,15302539],
		["Plymouth","MI",42.37,-83.46,15307520],
		["Henderson","NV",36.05,-115.05,15312501],
		["Washington","DC",38.89,-77.03,15317481],
		["Houston","TX",29.76,-95.38,15322461],
		["Houston","TX",29.76,-95.38,15327439],
		["Van Nuys","CA",34.17,-118.43,15332417],
		["West Covina","CA",34.06,-117.89,15337395],
		["Vancouver","WA",45.64,-122.63,15342373],
		["Shelton","CT",41.3,-73.13,15347350],
		["Green Bay","WI",44.52,-87.98,15352327],
		["Shreveport","LA",32.47,-93.79,15357303],
		["Pittsford","NY",43.09,-77.51,15362278],
		["Garland","TX",32.91,-96.62,15367253],
		["Fairmont","WV",39.48,-80.14,15372227],
		["Osseo","MN",45.11,-93.39,15377201],
		["Oklahoma City","OK",35.46,-97.51,15382174],
		["Lakeland","FL",28.04,-81.96,15387146],
		["San Antonio","TX",29.45,-98.5,15392118],
		["Richmond Hill","NY",40.69,-73.83,15397089],
		["Henderson","NV",36,-114.96,15402060],
		["York","PA",39.96,-76.73,15407029],
		["Navarre","FL",30.36,-87.17,15411997],
		["Broken Arrow","OK",36.03,-95.78,15416960],
		["Bryan","TX",30.66,-96.36,15421923],
		["Castaic","CA",34.51,-118.68,15426885],
		["Farmington","MN",44.65,-93.17,15431846],
		["Ambler","PA",40.15,-75.22,15436806],
		["Gastonia","NC",35.25,-81.17,15441766],
		["New Britain","CT",41.67,-72.78,15446725],
		["Hollywood","FL",26.02,-80.16,15451683],
		["Dothan","AL",31.23,-85.4,15456641],
		["Tacoma","WA",47.2,-122.4,15461599],
		["Jackson","MS",32.32,-90.2,15466554],
		["Eau Claire","WI",44.82,-91.49,15471509],
		["Norristown","PA",40.12,-75.33,15476463],
		["Sun Prairie","WI",43.18,-89.23,15481417],
		["San Ramon","CA",37.78,-121.99,15486370],
		["Orland Park","IL",41.61,-87.85,15491320],
		["Chicago","IL",41.83,-87.68,15496270],
		["Houston","TX",29.76,-95.38,15501220],
		["Anaheim","CA",33.85,-117.79,15506170],
		["Apex","NC",35.72,-78.84,15511119],
		["Houston","TX",29.76,-95.38,15516065],
		["Grand Rapids","MI",42.96,-85.65,15521010],
		["Canal Winchester","OH",39.84,-82.82,15525954],
		["North Brunswick","NJ",40.45,-74.48,15530896],
		["Romulus","MI",42.22,-83.36,15535837],
		["Parker","CO",39.5,-104.76,15540777],
		["Valencia","CA",34.46,-118.57,15545711],
		["Bourbonnais","IL",41.16,-87.88,15550644],
		["O Fallon","IL",38.59,-89.91,15555577],
		["Las Vegas","NV",35.99,-115.15,15560510],
		["Statesville","NC",35.78,-80.88,15565442],
		["San Francisco","CA",37.73,-122.38,15570372],
		["Norristown","PA",40.12,-75.33,15575300],
		["Fayetteville","NC",35.05,-78.87,15580227],
		["Eastpointe","MI",42.46,-82.94,15585154],
		["Wilkes Barre","PA",41.24,-75.87,15590080],
		["Hemet","CA",33.66,-116.98,15595006],
		["Atlanta","GA",33.75,-84.42,15599931],
		["Saint Paul","MN",44.94,-93.1,15604856],
		["Burlington","NJ",40.07,-74.85,15609777],
		["San Francisco","CA",37.72,-122.48,15614698],
		["Honolulu","HI",21.3,-157.85,15619618],
		["Frederick","MD",39.42,-77.41,15624537],
		["Louisville","KY",38.22,-85.74,15629456],
		["Little Rock","AR",34.72,-92.35,15634374],
		["Maywood","CA",33.98,-118.18,15639292],
		["Lafayette","IN",40.41,-86.87,15644209],
		["Boulder","CO",40.02,-105.25,15649125],
		["Mount Pleasant","SC",32.82,-79.86,15654040],
		["Portland","OR",45.54,-122.5,15658954],
		["Salem","MA",42.51,-70.9,15663867],
		["Spring Hill","FL",28.55,-82.39,15668780],
		["Saint Paul","MN",44.94,-93.1,15673693],
		["Fort Lauderdale","FL",26.14,-80.13,15678605],
		["Wildomar","CA",33.62,-117.25,15683516],
		["Hattiesburg","MS",31.31,-89.3,15688426],
		["San Juan","PR",18.4,-66.06,15693335],
		["Cortland","NY",42.6,-76.18,15698244],
		["Oceanside","CA",33.35,-117.43,15703152],
		["Wantagh","NY",40.66,-73.51,15708059],
		["Monroe Township","NJ",40.34,-74.44,15712965],
		["Richmond","TX",29.58,-95.76,15717870],
		["Miami","FL",25.77,-80.2,15722774],
		["Niles","MI",41.83,-86.24,15727675],
		["Santa Rosa","CA",38.44,-122.78,15732573],
		["Arnold","MO",38.42,-90.36,15737468],
		["Louisville","KY",38.22,-85.74,15742362],
		["Littleton","CO",39.59,-105.01,15747255],
		["Dublin","CA",37.71,-121.91,15752148],
		["Avon","IN",39.76,-86.39,15757040],
		["Yakima","WA",46.71,-120.42,15761932],
		["Flushing","NY",40.77,-73.84,15766823],
		["Forest Hills","NY",40.72,-73.84,15771714],
		["Lafayette","LA",30.21,-92.02,15776605],
		["Miami","FL",25.77,-80.29,15781493],
		["Edinburg","TX",26.29,-98.16,15786381],
		["Chicago","IL",41.83,-87.68,15791267],
		["Rockwall","TX",32.92,-96.45,15796152],
		["Chicago","IL",41.83,-87.68,15801036],
		["Los Angeles","CA",34,-118.43,15805920],
		["Bristow","VA",38.73,-77.55,15810803],
		["Kennewick","WA",46.07,-119.08,15815685],
		["Deer Park","TX",29.69,-95.11,15820566],
		["Roanoke","VA",37.27,-79.95,15825446],
		["Xenia","OH",39.68,-83.94,15830325],
		["Tucson","AZ",32.25,-111.08,15835202],
		["Bradenton","FL",27.48,-82.57,15840077],
		["Allen","TX",33.1,-96.66,15844952],
		["Naranjito","PR",18.3,-66.24,15849825],
		["Kingwood","TX",29.99,-95.26,15854698],
		["Baldwin","NY",40.66,-73.61,15859570],
		["Springfield","MO",37.19,-93.28,15864441],
		["Fort Worth","TX",32.75,-97.33,15869312],
		["Durham","NC",35.98,-78.91,15874182],
		["Benton Harbor","MI",42.11,-86.44,15879052],
		["Camp Lejeune","NC",34.68,-77.34,15883921],
		["Bettendorf","IA",41.56,-90.47,15888790],
		["Longmont","CO",40.17,-105.11,15893656],
		["Tahlequah","OK",35.91,-94.97,15898520],
		["Arlington","TX",32.69,-97.12,15903383],
		["Saratoga","CA",37.25,-122.06,15908246],
		["Brooklyn","NY",40.64,-73.94,15913107],
		["Altadena","CA",34.2,-118.13,15917968],
		["Ponce","PR",17.98,-66.6,15922828],
		["Panama City","FL",30.17,-85.67,15927686],
		["Midland","MI",43.62,-84.22,15932544],
		["Bristol","TN",36.56,-82.19,15937400],
		["Austin","TX",30.26,-97.74,15942256],
		["Las Vegas","NV",36.11,-115.21,15947112],
		["Bellmore","NY",40.65,-73.52,15951967],
		["Cary","IL",42.2,-88.24,15956819],
		["Kingsville","TX",27.5,-97.86,15961671],
		["Plattsburgh","NY",44.69,-73.45,15966522],
		["Riverview","FL",27.88,-82.33,15971373],
		["Mesa","AZ",33.43,-111.71,15976223],
		["Opa Locka","FL",25.93,-80.26,15981071],
		["Manhattan","KS",39.19,-96.59,15985919],
		["Yakima","WA",46.62,-120.69,15990767],
		["Sioux Falls","SD",43.54,-96.73,15995613],
		["Brockton","MA",42.08,-71.02,16000458],
		["Utuado","PR",18.27,-66.7,16005296],
		["Huntington Beach","CA",33.67,-118,16010134],
		["Lockport","IL",41.58,-88.04,16014971],
		["Billings","MT",45.65,-108.38,16019806],
		["Tinley Park","IL",41.57,-87.8,16024641],
		["Phoenix","AZ",33.72,-112.08,16029476],
		["Enterprise","AL",31.32,-85.84,16034309],
		["Providence","RI",41.82,-71.41,16039140],
		["Lake In The Hills","IL",42.18,-88.34,16043971],
		["Fleming Island","FL",30.16,-81.7,16048800],
		["Hudson","OH",41.24,-81.45,16053629],
		["Campbell","CA",37.28,-121.95,16058454],
		["San German","PR",18.08,-67.04,16063277],
		["Dayton","OH",39.77,-84.19,16068100],
		["Dorchester","MA",42.35,-71.06,16072919],
		["Westfield","NJ",40.65,-74.34,16077737],
		["Des Moines","IA",41.57,-93.61,16082555],
		["Honolulu","HI",21.3,-157.85,16087373],
		["Columbia","MD",39.2,-76.85,16092190],
		["Kenosha","WI",42.58,-87.85,16097007],
		["Santa Cruz","CA",37.04,-122.1,16101824],
		["Houston","TX",29.76,-95.38,16106640],
		["Houston","TX",29.76,-95.38,16111456],
		["Warminster","PA",40.21,-75.07,16116271],
		["Warren","MI",42.49,-83.02,16121085],
		["Oakdale","CA",37.8,-120.74,16125899],
		["Hixson","TN",35.16,-85.2,16130711],
		["Carlsbad","NM",32.4,-104.23,16135521],
		["Boise","ID",43.63,-116.32,16140330],
		["Vicksburg","MS",32.32,-90.87,16145138],
		["Las Vegas","NV",36.19,-115.26,16149946],
		["Manchester","NH",42.99,-71.45,16154753],
		["Omaha","NE",41.26,-96.01,16159558],
		["East Meadow","NY",40.71,-73.55,16164360],
		["Saint Paul","MN",44.94,-93.1,16169161],
		["Tonawanda","NY",43,-78.87,16173961],
		["Glendale Heights","IL",41.92,-88.07,16178761],
		["Dover","DE",39.15,-75.51,16183559],
		["Newnan","GA",33.37,-84.78,16188356],
		["Asbury Park","NJ",40.22,-74.01,16193151],
		["Birmingham","AL",33.52,-86.79,16197946],
		["Houston","TX",29.76,-95.38,16202739],
		["Flowery Branch","GA",34.18,-83.92,16207530],
		["Muskegon","MI",43.22,-86.25,16212318],
		["Hot Springs National Park","AR",34.5,-93.05,16217104],
		["Mesa","AZ",33.37,-111.64,16221890],
		["Marietta","GA",33.95,-84.54,16226673],
		["Palmetto","FL",27.52,-82.57,16231454],
		["Traverse City","MI",44.75,-85.6,16236234],
		["Gresham","OR",45.5,-122.43,16241013],
		["Ellensburg","WA",46.97,-120.47,16245791],
		["Saratoga Springs","NY",43.06,-73.77,16250568],
		["Nampa","ID",43.6,-116.54,16255345],
		["Prescott Valley","AZ",34.66,-112.29,16260117],
		["Fort Worth","TX",32.75,-97.33,16264888],
		["Havertown","PA",39.97,-75.31,16269658],
		["Wilmington","DE",39.73,-75.53,16274426],
		["Detroit","MI",42.38,-83.1,16279194],
		["Rolla","MO",37.94,-91.76,16283961],
		["Glen Allen","VA",37.66,-77.48,16288726],
		["Flushing","NY",40.77,-73.84,16293487],
		["Westerville","OH",40.12,-82.91,16298248],
		["Villalba","PR",18.13,-66.48,16303008],
		["Syracuse","UT",41.05,-112.06,16307767],
		["San Juan","PR",18.4,-66.06,16312524],
		["Midland","TX",32.02,-102.1,16317281],
		["Camas","WA",45.64,-122.37,16322035],
		["Ardmore","OK",34.19,-97.11,16326788],
		["Redding","CA",40.52,-122.32,16331540],
		["Austin","TX",30.26,-97.74,16336290],
		["Edmonds","WA",47.83,-122.32,16341040],
		["Jefferson City","MO",38.57,-92.19,16345789],
		["Las Vegas","NV",36.24,-115.23,16350538],
		["Atlanta","GA",33.7,-84.38,16355286],
		["Bronx","NY",40.84,-73.87,16360033],
		["Hyattsville","MD",38.95,-76.95,16364780],
		["Blue Springs","MO",39.01,-94.28,16369527],
		["Oklahoma City","OK",35.46,-97.51,16374272],
		["Bemidji","MN",47.48,-94.87,16379016],
		["Burlingame","CA",37.56,-122.37,16383758],
		["Salem","OR",44.9,-123.06,16388500],
		["Amherst","MA",42.37,-72.52,16393240],
		["Kalamazoo","MI",42.27,-85.58,16397980],
		["Wheaton","IL",41.85,-88.1,16402720],
		["Batavia","IL",41.85,-88.3,16407460],
		["Lynnwood","WA",47.8,-122.28,16412200],
		["Windsor Mill","MD",39.33,-76.78,16416939],
		["Ashland","OH",40.86,-82.31,16421678],
		["Loxahatchee","FL",26.78,-80.43,16426415],
		["Patterson","CA",37.49,-121.25,16431152],
		["Cheshire","CT",41.5,-72.9,16435886],
		["Norfolk","VA",36.84,-76.28,16440620],
		["Bozeman","MT",45.77,-110.93,16445354],
		["New City","NY",41.15,-73.99,16450087],
		["Omaha","NE",41.26,-96.01,16454820],
		["Noblesville","IN",40.05,-86.03,16459552],
		["Brandon","MS",32.28,-90,16464283],
		["Bethlehem","PA",40.62,-75.36,16469012],
		["Baton Rouge","LA",30.44,-91.12,16473741],
		["Philadelphia","PA",39.95,-75.16,16478469],
		["Brooklyn","MD",39.22,-76.61,16483197],
		["Ogden","UT",41.31,-111.96,16487925],
		["Spartanburg","SC",34.94,-81.92,16492652],
		["New Kensington","PA",40.56,-79.75,16497378],
		["Metairie","LA",29.99,-90.17,16502104],
		["New York","NY",40.71,-73.99,16506827],
		["Pueblo","CO",38.27,-104.62,16511550],
		["Ridgecrest","CA",35.55,-117.67,16516272],
		["San Dimas","CA",34.1,-117.81,16520993],
		["Butler","PA",40.86,-79.89,16525713],
		["Corona","CA",33.75,-117.48,16530433],
		["Sedalia","MO",38.7,-93.23,16535152],
		["New Orleans","LA",30.06,-89.93,16539870],
		["Williamsburg","VA",37.27,-76.7,16544587],
		["Las Vegas","NV",36.22,-115.03,16549304],
		["Sterling","VA",39,-77.4,16554019],
		["Cordova","TN",35.15,-89.75,16558734],
		["Phoenix","AZ",33.5,-112.14,16563449],
		["Ann Arbor","MI",42.27,-83.73,16568163],
		["Fresno","CA",36.6,-119.75,16572874],
		["Woodburn","OR",45.13,-122.82,16577583],
		["Phoenixville","PA",40.13,-75.51,16582291],
		["Henderson","KY",37.83,-87.58,16586998],
		["Danville","CA",37.81,-121.97,16591705],
		["Lacey","WA",47.02,-122.79,16596412],
		["Columbus","GA",32.51,-84.87,16601118],
		["Pembroke Pines","FL",26,-80.34,16605824],
		["North Las Vegas","NV",36.25,-115.08,16610530],
		["Marysville","CA",39.15,-121.47,16615236],
		["Miami","FL",25.77,-80.2,16619941],
		["Midland","MI",43.62,-84.22,16624646],
		["Wappingers Falls","NY",41.59,-73.91,16629350],
		["Pompano Beach","FL",26.23,-80.13,16634054],
		["Langhorne","PA",40.17,-74.91,16638756],
		["Lorton","VA",38.7,-77.24,16643456],
		["Spokane","WA",47.63,-117.25,16648156],
		["Westwego","LA",29.9,-90.14,16652854],
		["San Francisco","CA",37.74,-122.41,16657552],
		["Meriden","CT",41.53,-72.79,16662249],
		["Arvada","CO",39.82,-105.1,16666946],
		["Lakeland","FL",28.04,-81.96,16671642],
		["Hartsville","SC",34.36,-80.08,16676337],
		["Des Moines","IA",41.57,-93.61,16681032],
		["Santa Rosa","CA",38.45,-122.68,16685727],
		["Windsor","CA",38.53,-122.81,16690421],
		["Worcester","MA",42.26,-71.8,16695114],
		["Newark","NJ",40.72,-74.17,16699806],
		["Garden City","NY",40.72,-73.64,16704498],
		["Mechanicsville","VA",37.62,-77.35,16709190],
		["Los Angeles","CA",34.09,-118.29,16713882],
		["Sterling","VA",39,-77.4,16718573],
		["San Angelo","TX",31.44,-100.45,16723263],
		["Arvada","CO",39.82,-105.1,16727953],
		["New York","NY",40.71,-73.99,16732641],
		["East Chicago","IN",41.64,-87.45,16737329],
		["Louisville","CO",39.96,-105.14,16742017],
		["Gilbert","AZ",33.26,-111.71,16746703],
		["Sacramento","CA",38.68,-121.34,16751389],
		["Kenosha","WI",42.58,-87.85,16756073],
		["San Diego","CA",32.89,-117.07,16760755],
		["Philadelphia","PA",39.95,-75.16,16765436],
		["Norwich","CT",41.55,-72.08,16770116],
		["Pueblo","CO",38.27,-104.62,16774795],
		["Clinton Township","MI",42.58,-82.91,16779471],
		["Omaha","NE",41.26,-96.01,16784145],
		["Dallas","TX",32.79,-96.76,16788818],
		["Lancaster","NY",42.9,-78.66,16793490],
		["Davison","MI",43.03,-83.51,16798161],
		["Temple City","CA",34.1,-118.05,16802832],
		["Fairburn","GA",33.56,-84.58,16807500],
		["Cape Coral","FL",26.63,-81.99,16812168],
		["Pittsburgh","PA",40.43,-79.97,16816835],
		["Oak Harbor","WA",48.32,-122.63,16821502],
		["Miamisburg","OH",39.63,-84.27,16826167],
		["Sioux Falls","SD",43.54,-96.73,16830832],
		["Grand Rapids","MI",42.96,-85.65,16835496],
		["Baton Rouge","LA",30.44,-91.12,16840160],
		["Kingsport","TN",36.52,-82.55,16844822],
		["Schaumburg","IL",42.03,-88.08,16849481],
		["San Bruno","CA",37.62,-122.43,16854140],
		["Prior Lake","MN",44.72,-93.43,16858798],
		["Watertown","NY",43.97,-75.91,16863455],
		["Kansas City","MO",39.09,-94.58,16868112],
		["Mount Pleasant","TX",33.16,-94.97,16872769],
		["Shepherdsville","KY",37.98,-85.71,16877423],
		["Payson","UT",40.01,-111.73,16882077],
		["Staten Island","NY",40.58,-74.14,16886730],
		["Rochester","MI",42.68,-83.12,16891383],
		["Willingboro","NJ",40.02,-74.88,16896033],
		["Vallejo","CA",38.09,-122.25,16900682],
		["Quincy","MA",42.26,-71,16905330],
		["Troy","OH",40.04,-84.21,16909978],
		["Surprise","AZ",33.64,-112.38,16914626],
		["Torrance","CA",33.86,-118.33,16919274],
		["New Britain","CT",41.67,-72.78,16923921],
		["Lewiston","ME",44.08,-70.17,16928567],
		["Providence","RI",41.82,-71.41,16933210],
		["Saint Louis","MO",38.63,-90.24,16937853],
		["Sacramento","CA",38.49,-121.53,16942496],
		["Council Bluffs","IA",41.24,-95.85,16947138],
		["San Antonio","TX",29.45,-98.5,16951778],
		["Fort Lauderdale","FL",26.14,-80.13,16956417],
		["Kissimmee","FL",28.08,-81.44,16961056],
		["Garden Grove","CA",33.78,-117.97,16965695],
		["Fredericksburg","VA",38.29,-77.48,16970333],
		["Evansville","IN",37.98,-87.54,16974970],
		["Lakewood","CA",33.84,-118.14,16979607],
		["Immokalee","FL",26.41,-81.42,16984243],
		["Clovis","CA",36.91,-119.58,16988879],
		["Cleveland","OH",41.47,-81.67,16993511],
		["Marietta","GA",33.95,-84.54,16998142],
		["Brawley","CA",33.02,-115.45,17002773],
		["Louisville","KY",38.22,-85.74,17007403],
		["Jacksonville","FL",30.33,-81.65,17012032],
		["Augusta","GA",33.45,-81.99,17016660],
		["Redmond","OR",44.26,-121.22,17021288],
		["Greensboro","NC",36.07,-79.82,17025915],
		["Philadelphia","PA",39.95,-75.16,17030540],
		["Fairfax","VA",38.85,-77.29,17035165],
		["Durham","NH",43.13,-70.92,17039789],
		["Indianapolis","IN",39.77,-86.14,17044412],
		["Texarkana","AR",33.45,-94.01,17049035],
		["Concord","CA",38,-122.03,17053657],
		["Wilmette","IL",42.07,-87.72,17058278],
		["Fountain","CO",38.66,-104.69,17062899],
		["Port Chester","NY",41,-73.66,17067519],
		["Rosedale","NY",40.66,-73.73,17072138],
		["Racine","WI",42.72,-87.8,17076756],
		["Frederick","MD",39.42,-77.41,17081373],
		["East Elmhurst","NY",40.76,-73.88,17085989],
		["Tyler","TX",32.32,-95.3,17090605],
		["Stafford","VA",38.42,-77.4,17095219],
		["New Port Richey","FL",28.24,-82.71,17099833],
		["Tacoma","WA",47.15,-122.44,17104446],
		["Pine Bluff","AR",34.21,-92.01,17109058],
		["Pensacola","FL",30.44,-87.18,17113669],
		["Oconomowoc","WI",43.1,-88.49,17118280],
		["Glendale","CA",34.13,-118.24,17122891],
		["Chicago","IL",41.83,-87.68,17127500],
		["Laurel","MD",39.09,-76.85,17132107],
		["Bel Air","MD",39.53,-76.34,17136714],
		["Dorchester","MA",42.35,-71.06,17141320],
		["Dayton","TX",30.05,-94.89,17145926],
		["Portland","OR",45.48,-122.6,17150531],
		["Charlotte","NC",35.19,-80.83,17155135],
		["Severn","MD",39.13,-76.69,17159735],
		["Milton","FL",30.63,-87.04,17164335],
		["Asheboro","NC",35.65,-79.84,17168933],
		["Colorado Springs","CO",38.86,-104.76,17173531],
		["Mesa","AZ",33.38,-111.87,17178129],
		["El Monte","CA",34.08,-118.03,17182727],
		["Gastonia","NC",35.25,-81.17,17187323],
		["Cherry Hill","NJ",39.9,-74.99,17191916],
		["Wheaton","IL",41.85,-88.1,17196508],
		["Mesa","AZ",33.33,-111.63,17201099],
		["Lapeer","MI",43.04,-83.32,17205686],
		["Orangeburg","SC",33.49,-80.86,17210272],
		["Franklin","IN",39.49,-86.05,17214857],
		["Hoffman Estates","IL",42.04,-88.12,17219441],
		["Marquette","MI",46.55,-87.39,17224022],
		["Milwaukee","WI",43.06,-87.96,17228603],
		["Everett","WA",47.89,-122.25,17233183],
		["Newark","NJ",40.72,-74.17,17237762],
		["Joplin","MO",37.08,-94.5,17242341],
		["Charlotte","NC",35.19,-80.83,17246919],
		["Houston","TX",29.76,-95.38,17251497],
		["Hartford","CT",41.76,-72.68,17256074],
		["Greer","SC",34.93,-82.23,17260651],
		["Winchester","KY",37.99,-84.18,17265228],
		["Saint Louis","MO",38.63,-90.24,17269805],
		["Clinton Township","MI",42.58,-82.91,17274381],
		["Lares","PR",18.29,-66.88,17278955],
		["Reading","PA",40.33,-75.92,17283529],
		["Nottingham","MD",39.38,-76.48,17288103],
		["Pensacola","FL",30.44,-87.18,17292676],
		["Fargo","ND",46.87,-96.81,17297249],
		["Pensacola","FL",30.44,-87.18,17301821],
		["Highland Park","MI",42.4,-83.09,17306392],
		["Zeeland","MI",42.81,-86.01,17310962],
		["Lubbock","TX",33.57,-101.87,17315532],
		["Storrs Mansfield","CT",41.8,-72.25,17320101],
		["Ogden","UT",41.15,-111.98,17324670],
		["Tucson","AZ",32.33,-111.05,17329239],
		["Decatur","IL",39.85,-88.93,17333807],
		["Cookeville","TN",36.15,-85.5,17338372],
		["Arlington","TX",32.69,-97.12,17342937],
		["Chatsworth","CA",34.28,-118.61,17347502],
		["Natchitoches","LA",31.75,-93.09,17352066],
		["Palm Bay","FL",27.98,-80.66,17356629],
		["Placerville","CA",38.73,-120.8,17361192],
		["Northfield","MN",44.45,-93.16,17365752],
		["Tinley Park","IL",41.57,-87.84,17370312],
		["Joplin","MO",37.08,-94.5,17374872],
		["San Gabriel","CA",34.08,-118.09,17379432],
		["West Sacramento","CA",38.66,-121.62,17383990],
		["Basking Ridge","NJ",40.67,-74.56,17388547],
		["East Northport","NY",40.87,-73.32,17393100],
		["Springfield","VA",38.78,-77.17,17397653],
		["Phoenix","AZ",33.34,-112.06,17402206],
		["Springfield","MA",42.11,-72.53,17406758],
		["Shelton","WA",47.23,-123.2,17411310],
		["Matthews","NC",35.12,-80.71,17415861],
		["Sterling Heights","MI",42.58,-83.03,17420411],
		["Skokie","IL",42.03,-87.73,17424961],
		["Conway","AR",35.08,-92.45,17429511],
		["Randallstown","MD",39.37,-76.8,17434058],
		["Burlington","NC",36.08,-79.44,17438605],
		["Menomonee Falls","WI",43.14,-88.12,17443152],
		["Framingham","MA",42.3,-71.43,17447698],
		["Carrollton","GA",33.58,-85.07,17452244],
		["Schertz","TX",29.55,-98.26,17456789],
		["Arcadia","CA",34.13,-118.02,17461334],
		["North Andover","MA",42.7,-71.11,17465878],
		["New York","NY",40.71,-73.99,17470422],
		["Portland","OR",45.45,-122.69,17474966],
		["Lowell","MA",42.63,-71.32,17479509],
		["Ukiah","CA",39.12,-123.28,17484052],
		["Chelsea","MA",42.39,-71.03,17488593],
		["Gainesville","GA",34.29,-83.83,17493134],
		["Goshen","IN",41.57,-85.83,17497675],
		["Waco","TX",31.56,-97.18,17502216],
		["Seattle","WA",47.48,-122.29,17506757],
		["Snohomish","WA",47.85,-122.09,17511298],
		["Dallas","TX",32.79,-96.76,17515838],
		["Texas City","TX",29.4,-94.95,17520378],
		["Plano","TX",33.04,-96.74,17524917],
		["Tomball","TX",30.09,-95.61,17529456],
		["Plainview","TX",34.19,-101.72,17533995],
		["Indianapolis","IN",39.77,-86.14,17538533],
		["Flower Mound","TX",33.02,-97.09,17543070],
		["Brooklyn","NY",40.64,-73.94,17547604],
		["Aurora","CO",39.69,-104.81,17552138],
		["Lewiston","ID",46.16,-116.92,17556669],
		["Provo","UT",40.22,-111.69,17561199],
		["Lagrange","GA",33.03,-84.98,17565728],
		["Flint","MI",43.02,-83.69,17570256],
		["Longmont","CO",40.17,-105.11,17574783],
		["Manhattan Beach","CA",33.88,-118.4,17579310],
		["Litchfield Park","AZ",33.51,-112.36,17583836],
		["Utica","NY",43.1,-75.23,17588361],
		["Mc Lean","VA",38.94,-77.19,17592886],
		["Houston","TX",29.76,-95.38,17597409],
		["Huntsville","TX",30.71,-95.54,17601932],
		["Raleigh","NC",35.82,-78.65,17606454],
		["Jacksonville","FL",30.33,-81.65,17610976],
		["Lebanon","PA",40.34,-76.42,17615496],
		["Puyallup","WA",47.1,-122.32,17620016],
		["Littleton","CO",39.54,-105,17624534],
		["Holly Springs","NC",35.65,-78.83,17629051],
		["Maricopa","AZ",33.01,-111.97,17633568],
		["Fort Lauderdale","FL",26.14,-80.13,17638083],
		["Zionsville","IN",39.95,-86.27,17642598],
		["Winston Salem","NC",36.1,-80.24,17647112],
		["Sacramento","CA",38.48,-121.34,17651626],
		["Canonsburg","PA",40.26,-80.18,17656138],
		["Charlotte","NC",35.19,-80.83,17660650],
		["Cincinnati","OH",39.09,-84.51,17665162],
		["Woodstock","IL",42.31,-88.43,17669674],
		["Lafayette","LA",30.21,-92.02,17674186],
		["Shelbyville","TN",35.49,-86.44,17678697],
		["Oak Park","MI",42.46,-83.18,17683207],
		["Alice","TX",27.75,-98.06,17687717],
		["Boston","MA",42.35,-71.06,17692225],
		["Odessa","TX",31.85,-102.36,17696733],
		["Porter Ranch","CA",34.28,-118.55,17701241],
		["Sacramento","CA",38.69,-121.56,17705749],
		["Inglewood","CA",33.93,-118.33,17710256],
		["Sandy","UT",40.56,-111.86,17714761],
		["Midland","TX",32.02,-102.1,17719265],
		["Albuquerque","NM",35.11,-106.62,17723769],
		["Modesto","CA",37.72,-121.03,17728273],
		["Redding","CA",40.6,-122.46,17732775],
		["El Paso","TX",31.84,-106.43,17737274],
		["Atlanta","GA",33.66,-84.45,17741772],
		["Kansas City","KS",39.1,-94.62,17746268],
		["Indianapolis","IN",39.77,-86.14,17750763],
		["Slidell","LA",30.28,-89.77,17755258],
		["Richmond","CA",37.96,-122.38,17759753],
		["Durham","NC",35.98,-78.91,17764247],
		["Richmond","VA",37.55,-77.46,17768740],
		["Springfield","IL",39.78,-89.64,17773232],
		["Ventura","CA",34.27,-119.16,17777724],
		["Jacksonville","FL",30.33,-81.65,17782214],
		["Everett","WA",47.94,-122.24,17786704],
		["Nixa","MO",37.04,-93.29,17791193],
		["Whittier","CA",34,-118.03,17795681],
		["Rockledge","FL",28.31,-80.72,17800167],
		["Bloomington","IL",40.47,-88.97,17804652],
		["Marysville","OH",40.23,-83.37,17809136],
		["Fort Lauderdale","FL",26.14,-80.13,17813619],
		["Ann Arbor","MI",42.27,-83.73,17818100],
		["Lawrence","KS",38.96,-95.25,17822581],
		["Sacramento","CA",38.54,-121.38,17827062],
		["Savannah","GA",32.08,-81.1,17831541],
		["Toledo","OH",41.66,-83.58,17836019],
		["Overland Park","KS",38.9,-94.68,17840497],
		["Tyler","TX",32.32,-95.3,17844975],
		["Coeur D Alene","ID",47.72,-116.75,17849452],
		["West Deptford","NJ",39.83,-75.15,17853928],
		["Stanton","CA",33.79,-117.99,17858403],
		["Kokomo","IN",40.47,-86.13,17862877],
		["Mchenry","IL",42.34,-88.29,17867350],
		["Houston","TX",29.76,-95.38,17871823],
		["Denver","CO",39.76,-104.87,17876296],
		["Albany","OR",44.63,-123,17880769],
		["Orangevale","CA",38.68,-121.22,17885240],
		["Commack","NY",40.84,-73.28,17889710],
		["San Antonio","TX",29.45,-98.5,17894179],
		["Grants Pass","OR",42.53,-123.34,17898647],
		["Manassas","VA",38.74,-77.48,17903114],
		["Gastonia","NC",35.25,-81.17,17907580],
		["Vancouver","WA",45.68,-122.57,17912046],
		["Rome","GA",34.26,-85.18,17916511],
		["Decatur","AL",34.57,-86.98,17920976],
		["Longwood","FL",28.7,-81.34,17925440],
		["Angleton","TX",29.16,-95.42,17929904],
		["San Antonio","TX",29.45,-98.5,17934368],
		["Westport","CT",41.12,-73.34,17938831],
		["Tupelo","MS",34.26,-88.73,17943294],
		["San Clemente","CA",33.48,-117.61,17947756],
		["Beachwood","OH",41.47,-81.5,17952217],
		["Franklin","WI",42.88,-88,17956678],
		["Flemington","NJ",40.5,-74.86,17961138],
		["Madison","TN",36.25,-86.69,17965598],
		["Slidell","LA",30.28,-89.77,17970057],
		["Rosemount","MN",44.74,-93.07,17974515],
		["Minneapolis","MN",44.96,-93.26,17978972],
		["Lake Jackson","TX",29.04,-95.44,17983429],
		["Horn Lake","MS",34.95,-90.03,17987885],
		["Biloxi","MS",30.39,-88.88,17992341],
		["Manassas","VA",38.74,-77.48,17996795],
		["Grosse Pointe","MI",42.38,-82.91,18001248],
		["Suisun City","CA",38.16,-121.93,18005701],
		["Chicago","IL",41.83,-87.68,18010153],
		["Chestnut Hill","MA",42.31,-71.16,18014604],
		["Saginaw","MI",43.41,-83.94,18019055],
		["Houston","TX",29.76,-95.38,18023506],
		["Springfield","IL",39.78,-89.64,18027956],
		["Rochester","MI",42.68,-83.12,18032405],
		["Flint","MI",43.02,-83.69,18036853],
		["Manassas","VA",38.74,-77.48,18041300],
		["Portland","OR",45.46,-122.55,18045746],
		["Savannah","GA",32.08,-81.1,18050191],
		["Lake Charles","LA",30.21,-93.2,18054635],
		["Statesville","NC",35.78,-80.88,18059077],
		["Henrico","VA",37.55,-77.46,18063517],
		["Port Orchard","WA",47.54,-122.58,18067956],
		["Dallas","TX",32.79,-96.76,18072394],
		["Belleville","NJ",40.79,-74.16,18076831],
		["Wadsworth","OH",41.02,-81.73,18081268],
		["Fort Hood","TX",31.09,-97.71,18085705],
		["Menlo Park","CA",37.45,-122.17,18090139],
		["Cincinnati","OH",39.09,-84.51,18094570],
		["Magna","UT",40.7,-112.08,18099001],
		["Coventry","RI",41.68,-71.66,18103431],
		["Lake Charles","LA",30.21,-93.2,18107861],
		["Tehachapi","CA",35.12,-118.51,18112289],
		["Stockton","CA",38.04,-121.2,18116717],
		["Yorktown Heights","NY",41.27,-73.78,18121144],
		["Altamonte Springs","FL",28.66,-81.39,18125571],
		["Centereach","NY",40.87,-73.08,18129997],
		["Hudson","WI",44.97,-92.73,18134423],
		["Scottsdale","AZ",33.66,-111.87,18138849],
		["Englewood","CO",39.64,-104.99,18143274],
		["Pensacola","FL",30.44,-87.18,18147698],
		["Richardson","TX",32.97,-96.7,18152122],
		["Bridgewater","MA",41.98,-70.97,18156545],
		["Pacifica","CA",37.6,-122.45,18160967],
		["Baldwinsville","NY",43.15,-76.33,18165388],
		["Henrico","VA",37.55,-77.46,18169809],
		["Arcadia","CA",34.12,-118.05,18174230],
		["Newtown","PA",40.22,-74.93,18178650],
		["Olivehurst","CA",39.08,-121.55,18183070],
		["Martinsville","IN",39.42,-86.42,18187489],
		["Marshalltown","IA",42.03,-92.91,18191908],
		["Fort Lauderdale","FL",26.14,-80.13,18196325],
		["Carlisle","PA",40.2,-77.2,18200740],
		["Pompano Beach","FL",26.23,-80.13,18205155],
		["Minneapolis","MN",44.96,-93.26,18209570],
		["Santa Cruz","CA",36.98,-122.06,18213985],
		["Savage","MN",44.75,-93.35,18218398],
		["San Antonio","TX",29.45,-98.5,18222811],
		["Renton","WA",47.49,-122.1,18227224],
		["Livingston","NJ",40.78,-74.32,18231636],
		["Cocoa","FL",28.37,-80.75,18236048],
		["South Lyon","MI",42.46,-83.65,18240460],
		["Leavenworth","KS",39.32,-94.92,18244872],
		["Kansas City","MO",39.09,-94.58,18249283],
		["Haltom City","TX",32.81,-97.27,18253694],
		["Midland","TX",32.02,-102.1,18258105],
		["Portland","OR",45.61,-122.74,18262515],
		["Neptune","NJ",40.21,-74.08,18266924],
		["Kerrville","TX",30.04,-99.13,18271332],
		["Farmingdale","NY",40.73,-73.44,18275739],
		["Cleveland","OH",41.47,-81.67,18280144],
		["Harker Heights","TX",31.06,-97.65,18284549],
		["Conroe","TX",30.32,-95.47,18288953],
		["Fort Lauderdale","FL",26.14,-80.13,18293355],
		["Kent","OH",41.14,-81.36,18297757],
		["Birmingham","AL",33.52,-86.79,18302158],
		["Baltimore","MD",39.3,-76.61,18306558],
		["Fayetteville","GA",33.44,-84.46,18310958],
		["Lawrence Township","NJ",40.22,-74.76,18315357],
		["Sidney","OH",40.28,-84.16,18319756],
		["Pasadena","CA",34.16,-118.12,18324155],
		["Newport News","VA",37.07,-76.51,18328553],
		["Greenville","SC",34.83,-82.37,18332951],
		["Brandon","MS",32.28,-90,18337349],
		["Stevens Point","WI",44.54,-89.56,18341747],
		["Warrensburg","MO",38.75,-93.72,18346145],
		["Nashville","TN",36.17,-86.78,18350542],
		["Valencia","CA",34.41,-118.56,18354938],
		["Braintree","MA",42.2,-71,18359333],
		["Dallas","TX",32.79,-96.76,18363728],
		["Las Vegas","NV",36.14,-115.18,18368123],
		["Lorain","OH",41.44,-82.18,18372517],
		["Burlington","WI",42.67,-88.27,18376911],
		["Dallas","TX",32.79,-96.76,18381305],
		["West Covina","CA",34.02,-117.89,18385698],
		["Fresno","CA",36.84,-119.82,18390091],
		["Quakertown","PA",40.43,-75.34,18394482],
		["Poplar Bluff","MO",36.76,-90.4,18398873],
		["Memphis","TN",35.1,-90,18403263],
		["Denver","CO",39.76,-104.87,18407653],
		["Fort Wayne","IN",41.07,-85.13,18412042],
		["Big Spring","TX",32.24,-101.47,18416431],
		["Anasco","PR",18.28,-67.14,18420819],
		["Waukesha","WI",43.01,-88.23,18425207],
		["Elk Grove Village","IL",42,-87.99,18429595],
		["Olympia","WA",46.98,-122.74,18433982],
		["Oak Creek","WI",42.88,-87.89,18438368],
		["Mishawaka","IN",41.66,-86.16,18442753],
		["Wasco","CA",35.64,-119.44,18447137],
		["Sanford","NC",35.36,-79.13,18451519],
		["Cincinnati","OH",39.09,-84.51,18455901],
		["Lafayette","LA",30.21,-92.02,18460283],
		["Columbus","GA",32.51,-84.87,18464664],
		["Ravenna","OH",41.15,-81.24,18469045],
		["Bridgeport","CT",41.18,-73.19,18473425],
		["Circle Pines","MN",45.13,-93.15,18477804],
		["Alamogordo","NM",32.88,-105.95,18482182],
		["Fredericksburg","VA",38.29,-77.48,18486556],
		["Athens","GA",33.95,-83.39,18490930],
		["Grand Rapids","MI",42.96,-85.65,18495304],
		["Waukesha","WI",43.01,-88.23,18499677],
		["Washington","DC",38.89,-77.03,18504048],
		["Highland Park","IL",42.18,-87.8,18508419],
		["Naples","FL",26.14,-81.79,18512789],
		["La Grange","IL",41.8,-87.87,18517158],
		["Grand Island","NE",40.92,-98.36,18521527],
		["Ridgefield","CT",41.27,-73.49,18525895],
		["Orlando","FL",28.5,-81.37,18530263],
		["Muscatine","IA",41.41,-91.07,18534631],
		["Haines City","FL",28.1,-81.62,18538997],
		["Lincoln","NE",40.81,-96.68,18543363],
		["Manchester","CT",41.78,-72.51,18547727],
		["Orlando","FL",28.5,-81.37,18552090],
		["West Monroe","LA",32.51,-92.14,18556452],
		["Albuquerque","NM",35.11,-106.62,18560812],
		["Newark","NJ",40.72,-74.17,18565170],
		["Lexington","KY",38.04,-84.45,18569528],
		["Cumberland","RI",41.94,-71.41,18573885],
		["Melbourne","FL",28.08,-80.6,18578242],
		["Goshen","IN",41.57,-85.83,18582597],
		["Severna Park","MD",39.08,-76.57,18586951],
		["Saint Louis","MO",38.63,-90.24,18591305],
		["Sapulpa","OK",35.99,-96.09,18595651],
		["Austin","TX",30.26,-97.74,18599997],
		["Cheyenne","WY",41.14,-104.79,18604343],
		["Springfield","OR",44.05,-123,18608687],
		["Greenville","SC",34.83,-82.37,18613028],
		["Elizabethport","NJ",40.66,-74.19,18617368],
		["Inglewood","CA",33.97,-118.35,18621708],
		["Marlborough","MA",42.34,-71.54,18626047],
		["Medford","NJ",39.86,-74.82,18630386],
		["Evansville","IN",37.98,-87.54,18634725],
		["Ashtabula","OH",41.87,-80.79,18639063],
		["Mount Pleasant","SC",32.82,-79.86,18643400],
		["New Castle","PA",40.99,-80.34,18647735],
		["Chula Vista","CA",32.6,-116.93,18652070],
		["Atlanta","GA",33.76,-84.42,18656403],
		["Maryville","TN",35.74,-83.97,18660736],
		["Blackfoot","ID",43.27,-112.56,18665069],
		["Vallejo","CA",38.13,-122.25,18669399],
		["Cleveland","OH",41.47,-81.67,18673728],
		["Greenwood","IN",39.61,-86.11,18678056],
		["Mableton","GA",33.81,-84.56,18682383],
		["Norcross","GA",33.94,-84.2,18686709],
		["Whittier","CA",33.97,-118.01,18691034],
		["Ridgewood","NJ",40.98,-74.11,18695357],
		["Miami","FL",25.77,-80.2,18699680],
		["Naples","FL",26.14,-81.79,18704003],
		["Glenside","PA",40.1,-75.15,18708325],
		["Northridge","CA",34.23,-118.51,18712644],
		["Baton Rouge","LA",30.44,-91.12,18716962],
		["Knoxville","TN",35.97,-83.94,18721278],
		["Longview","TX",32.51,-94.76,18725594],
		["Rancho Cucamonga","CA",34.19,-117.61,18729910],
		["Detroit","MI",42.38,-83.1,18734225],
		["Detroit","MI",42.38,-83.1,18738540],
		["Shirley","NY",40.79,-72.87,18742854],
		["Lansing","IL",41.56,-87.54,18747167],
		["Norfolk","NE",42.03,-97.41,18751480],
		["Corsicana","TX",32.08,-96.46,18755793],
		["Aiken","SC",33.54,-81.72,18760105],
		["Mechanicsburg","PA",40.24,-77.02,18764416],
		["Myrtle Beach","SC",33.63,-79.02,18768727],
		["Culpeper","VA",38.47,-78,18773035],
		["Wheeling","IL",42.13,-87.92,18777343],
		["Fort Worth","TX",32.75,-97.33,18781651],
		["Naugatuck","CT",41.48,-73.05,18785958],
		["Lutherville Timonium","MD",39.43,-76.64,18790264],
		["Indianapolis","IN",39.77,-86.14,18794570],
		["Torrington","CT",41.83,-73.12,18798875],
		["Rochester","NY",43.16,-77.61,18803180],
		["Media","PA",39.91,-75.38,18807485],
		["El Paso","TX",31.84,-106.43,18811787],
		["Vancouver","WA",45.7,-122.69,18816089],
		["Glen Allen","VA",37.66,-77.48,18820388],
		["Kirkland","WA",47.72,-122.2,18824687],
		["Sand Springs","OK",36.13,-96.13,18828985],
		["Solon","OH",41.38,-81.44,18833282],
		["Dolton","IL",41.62,-87.59,18837579],
		["Arvin","CA",35.08,-118.79,18841875],
		["El Dorado","AR",33.21,-92.66,18846168],
		["Montgomery Village","MD",39.18,-77.19,18850458],
		["West Linn","OR",45.34,-122.66,18854748],
		["Milford","CT",41.22,-73.06,18859037],
		["Newport Beach","CA",33.62,-117.87,18863326],
		["West Bloomfield","MI",42.56,-83.38,18867614],
		["Guaynabo","PR",18.38,-66.11,18871901],
		["Front Royal","VA",38.92,-78.18,18876188],
		["Lufkin","TX",31.33,-94.72,18880475],
		["Burbank","IL",41.74,-87.76,18884761],
		["Tacoma","WA",47.13,-122.4,18889046],
		["Alexandria","VA",38.82,-77.08,18893330],
		["York","PA",39.96,-76.73,18897611],
		["Auburn","AL",32.59,-85.48,18901892],
		["Seattle","WA",47.73,-122.34,18906173],
		["Batavia","OH",39.07,-84.18,18910453],
		["Camden","NJ",39.93,-75.1,18914729],
		["Penuelas","PR",18.06,-66.72,18919004],
		["Inman","SC",35.05,-82.09,18923279],
		["Riverdale","GA",33.56,-84.4,18927554],
		["Macon","GA",32.83,-83.65,18931829],
		["Vincennes","IN",38.67,-87.51,18936104],
		["Dallas","TX",32.79,-96.76,18940379],
		["Milwaukee","WI",43.06,-87.96,18944652],
		["Butte","MT",46,-112.44,18948925],
		["Pearl City","HI",21.4,-157.96,18953198],
		["Chico","CA",39.9,-121.83,18957470],
		["New London","CT",41.35,-72.1,18961740],
		["Ada","OK",34.77,-96.66,18966010],
		["Tiffin","OH",41.11,-83.17,18970279],
		["Great Lakes","IL",42.3,-87.85,18974547],
		["Littleton","CO",39.59,-105.01,18978815],
		["Staten Island","NY",40.58,-74.14,18983082],
		["Glastonbury","CT",41.7,-72.6,18987348],
		["Casselberry","FL",28.66,-81.32,18991614],
		["Banning","CA",33.92,-116.83,18995879],
		["Elizabethtown","PA",40.15,-76.59,19000143],
		["Grand Rapids","MI",42.96,-85.65,19004407],
		["Fort Wayne","IN",41.07,-85.13,19008670],
		["Shingle Springs","CA",38.61,-120.97,19012932],
		["Oklahoma City","OK",35.46,-97.51,19017193],
		["Redwood City","CA",37.49,-122.21,19021454],
		["Deerfield","IL",42.16,-87.84,19025714],
		["Calabasas","CA",34.11,-118.67,19029972],
		["Gainesville","GA",34.29,-83.83,19034228],
		["Mount Vernon","WA",48.42,-122.42,19038484],
		["Ennis","TX",32.33,-96.62,19042739],
		["Milton","MA",42.24,-71.08,19046993],
		["Windermere","FL",28.49,-81.53,19051246],
		["Los Angeles","CA",34.05,-118.31,19055498],
		["Aguas Buenas","PR",18.25,-66.1,19059749],
		["Warren","MI",42.49,-83.02,19064000],
		["Bellevue","NE",41.15,-95.92,19068250],
		["Dallas","TX",32.79,-96.76,19072500],
		["Roanoke","TX",33,-97.23,19076750],
		["Gibsonia","PA",40.63,-79.94,19080999],
		["Frederick","MD",39.42,-77.41,19085248],
		["Charleston","IL",39.48,-88.17,19089495],
		["Bluffton","SC",32.23,-80.86,19093740],
		["Lexington","KY",38.04,-84.45,19097985],
		["Uniontown","OH",40.97,-81.4,19102230],
		["Layton","UT",41.08,-111.9,19106474],
		["Covina","CA",34.07,-117.84,19110717],
		["Naperville","IL",41.76,-88.15,19114959],
		["Mcminnville","TN",35.68,-85.77,19119200],
		["Seattle","WA",47.38,-122.31,19123441],
		["Prattville","AL",32.46,-86.44,19127681],
		["Pueblo","CO",38.27,-104.62,19131921],
		["Glendale","AZ",33.59,-112.17,19136160],
		["Hopewell Junction","NY",41.58,-73.8,19140398],
		["Conyers","GA",33.66,-84.01,19144636],
		["North Augusta","SC",33.51,-81.95,19148873],
		["Las Cruces","NM",32.33,-106.75,19153110],
		["Newberg","OR",45.32,-122.98,19157346],
		["Lowell","MA",42.63,-71.32,19161581],
		["Miami","FL",25.77,-80.2,19165816],
		["Iowa City","IA",41.65,-91.53,19170050],
		["San Angelo","TX",31.44,-100.45,19174283],
		["East Wenatchee","WA",47.49,-120.19,19178516],
		["Newark","NJ",40.72,-74.17,19182748],
		["Rockwall","TX",32.92,-96.45,19186979],
		["Brookings","SD",44.3,-96.78,19191207],
		["Galesburg","IL",40.95,-90.37,19195435],
		["New Bern","NC",35.11,-77.07,19199661],
		["Corpus Christi","TX",27.8,-97.39,19203887],
		["Cheyenne","WY",41.14,-104.79,19208113],
		["Redwood City","CA",37.46,-122.24,19212339],
		["Oneonta","NY",42.45,-75.06,19216564],
		["Liverpool","NY",43.1,-76.21,19220788],
		["Georgetown","TX",30.64,-97.68,19225012],
		["Mesa","AZ",33.38,-111.63,19229236],
		["Sierra Vista","AZ",31.59,-110.17,19233460],
		["Grovetown","GA",33.44,-82.2,19237683],
		["Lucedale","MS",30.92,-88.59,19241906],
		["Mechanicsburg","PA",40.21,-77,19246128],
		["Brunswick","GA",31.32,-81.53,19250349],
		["Sammamish","WA",47.62,-122.02,19254570],
		["Randolph","MA",42.17,-71.05,19258790],
		["Toledo","OH",41.66,-83.58,19263009],
		["Noblesville","IN",40.05,-86.03,19267228],
		["Indianapolis","IN",39.77,-86.14,19271447],
		["Houma","LA",29.57,-90.7,19275666],
		["Cincinnati","OH",39.09,-84.51,19279884],
		["Toledo","OH",41.66,-83.58,19284100],
		["Westlake","OH",41.45,-81.92,19288316],
		["Dayton","OH",39.77,-84.19,19292532],
		["Plano","TX",33.04,-96.74,19296748],
		["Fort Lauderdale","FL",26.14,-80.13,19300963],
		["Kansas City","MO",39.09,-94.58,19305178],
		["Phoenix","AZ",33.63,-112.09,19309393],
		["Carbondale","IL",37.72,-89.21,19313607],
		["Dallas","TX",32.79,-96.76,19317818],
		["Palm Harbor","FL",28.08,-82.76,19322028],
		["Quincy","IL",39.93,-91.38,19326238],
		["Nogales","AZ",31.45,-110.83,19330448],
		["Norfolk","VA",36.84,-76.28,19334657],
		["Mahwah","NJ",41.08,-74.18,19338865],
		["Fort Lauderdale","FL",26.14,-80.13,19343073],
		["Cleveland","OH",41.47,-81.67,19347281],
		["Saint Charles","IL",41.92,-88.3,19351489],
		["Jacksonville","FL",30.33,-81.65,19355696],
		["Cleveland","TN",35.17,-84.86,19359903],
		["Ooltewah","TN",35.06,-85.09,19364110],
		["Menomonie","WI",44.88,-91.91,19368317],
		["Houston","TX",29.76,-95.38,19372524],
		["Hartford","CT",41.76,-72.68,19376730],
		["Houston","TX",29.76,-95.38,19380936],
		["Palmer","AK",61.6,-148.46,19385142],
		["Madison","WI",43.07,-89.38,19389347],
		["Macomb","MI",42.66,-82.91,19393551],
		["Eagle","ID",43.79,-116.38,19397755],
		["Columbia","SC",34,-81.03,19401958],
		["Clover","SC",35.11,-81.22,19406161],
		["Aiea","HI",21.38,-157.92,19410364],
		["Saint Paul","MN",44.94,-93.1,19414566],
		["Rowlett","TX",32.9,-96.55,19418768],
		["Fair Lawn","NJ",40.93,-74.11,19422968],
		["Phoenix","AZ",33.62,-112.13,19427166],
		["Arcadia","FL",27.21,-81.85,19431363],
		["Jacksonville","TX",31.96,-95.26,19435559],
		["Schenectady","NY",42.8,-73.92,19439754],
		["Covington","LA",30.47,-90.11,19443949],
		["Macon","GA",32.83,-83.65,19448143],
		["Phoenix","AZ",33.46,-112.05,19452334],
		["Davis","CA",38.53,-121.66,19456525],
		["Roseville","CA",38.74,-121.25,19460716],
		["Quebradillas","PR",18.47,-66.93,19464906],
		["Springfield","VA",38.78,-77.17,19469096],
		["Orland Park","IL",41.61,-87.85,19473285],
		["Cranston","RI",41.76,-71.48,19477472],
		["Toledo","OH",41.66,-83.58,19481659],
		["Wichita","KS",37.68,-97.34,19485846],
		["Inkster","MI",42.29,-83.31,19490032],
		["Bowie","MD",38.95,-76.73,19494217],
		["Valdosta","GA",30.84,-83.27,19498402],
		["Dallas","TX",32.79,-96.76,19502587],
		["Colorado Springs","CO",38.86,-104.76,19506771],
		["Faribault","MN",44.29,-93.27,19510953],
		["North Richland Hills","TX",32.86,-97.21,19515135],
		["Hesperia","CA",34.39,-117.39,19519316],
		["West Memphis","AR",35.14,-90.18,19523495],
		["San Francisco","CA",37.76,-122.48,19527674],
		["Osseo","MN",45.11,-93.39,19531852],
		["Missoula","MT",46.85,-113.99,19536030],
		["Owosso","MI",42.99,-84.17,19540207],
		["Lebanon","PA",40.34,-76.42,19544383],
		["Ladson","SC",33,-80.1,19548559],
		["Logansport","IN",40.75,-86.35,19552735],
		["San Antonio","TX",29.45,-98.5,19556911],
		["Londonderry","NH",42.85,-71.36,19561086],
		["Morristown","TN",36.21,-83.29,19565260],
		["Grand Rapids","MI",42.96,-85.65,19569433],
		["Florence","AL",34.82,-87.66,19573604],
		["Derby","KS",37.54,-97.25,19577775],
		["Hyattsville","MD",38.95,-76.95,19581944],
		["Tallahassee","FL",30.53,-84.1,19586113],
		["Miami","FL",25.77,-80.2,19590282],
		["Albuquerque","NM",35.11,-106.62,19594451],
		["San Juan","PR",18.4,-66.06,19598619],
		["Olney","MD",39.14,-77.08,19602787],
		["Staunton","VA",38.15,-79.06,19606954],
		["Corpus Christi","TX",27.8,-97.39,19611119],
		["Cambridge","MA",42.37,-71.11,19615282],
		["Woodridge","IL",41.73,-88.04,19619444],
		["Fort Wayne","IN",41.07,-85.13,19623605],
		["Imperial","MO",38.36,-90.37,19627766],
		["Stockton","CA",37.95,-121.12,19631926],
		["Salem","OR",45.02,-123.11,19636085],
		["Yorktown","VA",37.23,-76.5,19640243],
		["Murray","KY",36.61,-88.31,19644401],
		["Oroville","CA",39.45,-121.39,19648559],
		["Lubbock","TX",33.57,-101.87,19652716],
		["Baton Rouge","LA",30.44,-91.12,19656872],
		["Middletown","NJ",40.39,-74.11,19661027],
		["Ozone Park","NY",40.69,-73.85,19665182],
		["Orchard Park","NY",42.76,-78.74,19669337],
		["Louisville","KY",38.22,-85.74,19673492],
		["Stuart","FL",27.19,-80.24,19677646],
		["Boca Raton","FL",26.37,-80.1,19681799],
		["Los Angeles","CA",34,-118.34,19685952],
		["Palm Coast","FL",29.57,-81.21,19690104],
		["San Antonio","TX",29.45,-98.5,19694256],
		["Nampa","ID",43.6,-116.61,19698408],
		["Winchester","VA",39.17,-78.17,19702558],
		["Fremont","CA",37.55,-122.07,19706708],
		["Philadelphia","PA",39.95,-75.16,19710857],
		["Chicago","IL",41.83,-87.68,19715006],
		["Hyde Park","MA",42.26,-71.11,19719154],
		["Ephrata","PA",40.18,-76.18,19723301],
		["Grand Haven","MI",43.05,-86.22,19727448],
		["Redondo Beach","CA",33.87,-118.37,19731594],
		["Miami","FL",25.79,-80.36,19735739],
		["Fort Dodge","IA",42.5,-94.17,19739884],
		["Knoxville","TN",35.97,-83.94,19744028],
		["Urbandale","IA",41.63,-93.74,19748172],
		["Stone Mountain","GA",33.8,-84.17,19752315],
		["Laguna Hills","CA",33.6,-117.71,19756457],
		["Portland","OR",45.44,-122.61,19760599],
		["Oxon Hill","MD",38.8,-76.99,19764740],
		["Willoughby","OH",41.64,-81.4,19768881],
		["Indianapolis","IN",39.77,-86.14,19773022],
		["Leesburg","VA",39.1,-77.55,19777162],
		["Ozark","MO",37.01,-93.2,19781302],
		["Easley","SC",34.82,-82.58,19785441],
		["Marietta","GA",33.95,-84.54,19789580],
		["Magnolia","TX",30.21,-95.74,19793719],
		["Bayamon","PR",18.41,-66.15,19797856],
		["Omaha","NE",41.26,-96.01,19801993],
		["Bossier City","LA",32.51,-93.66,19806130],
		["Memphis","TN",35.1,-90,19810266],
		["San Francisco","CA",37.74,-122.48,19814402],
		["Sonoma","CA",38.24,-122.46,19818538],
		["Waterbury","CT",41.55,-73.03,19822673],
		["Smyrna","GA",33.86,-84.51,19826808],
		["Colorado Springs","CO",38.86,-104.76,19830943],
		["Springfield","OH",39.92,-83.79,19835077],
		["Springfield","IL",39.78,-89.64,19839211],
		["York","SC",34.99,-81.23,19843344],
		["Ballston Spa","NY",43,-73.85,19847475],
		["Agoura Hills","CA",34.12,-118.76,19851606],
		["Moncks Corner","SC",33.19,-79.99,19855736],
		["Milford","OH",39.17,-84.28,19859865],
		["Clarkston","MI",42.73,-83.42,19863994],
		["Brenham","TX",30.15,-96.39,19868123],
		["Reno","NV",39.49,-119.9,19872252],
		["Portage","MI",42.2,-85.59,19876380],
		["Absecon","NJ",39.48,-74.47,19880506],
		["Wilmington","NC",34.21,-77.91,19884632],
		["Westminster","CO",39.88,-105.05,19888758],
		["Eastlake","OH",41.65,-81.43,19892883],
		["Medford","NY",40.82,-72.98,19897007],
		["Muskogee","OK",35.74,-95.35,19901131],
		["Mahopac","NY",41.36,-73.74,19905254],
		["Katy","TX",29.79,-95.82,19909377],
		["Orocovis","PR",18.22,-66.39,19913498],
		["Kingston","NY",41.93,-73.99,19917618],
		["Lakewood","CA",33.85,-118.11,19921738],
		["Charlotte","NC",35.19,-80.83,19925857],
		["Hobart","IN",41.53,-87.26,19929976],
		["Norman","OK",35.22,-97.34,19934094],
		["Vancouver","WA",45.63,-122.52,19938212],
		["Waukegan","IL",42.37,-87.86,19942328],
		["North Olmsted","OH",41.41,-81.92,19946443],
		["Pikesville","MD",39.39,-76.7,19950557],
		["Gainesville","FL",29.67,-82.33,19954671],
		["Miami","FL",25.77,-80.2,19958785],
		["Southington","CT",41.6,-72.88,19962898],
		["West Islip","NY",40.7,-73.29,19967010],
		["Halethorpe","MD",39.21,-76.72,19971121],
		["Hollywood","FL",26.02,-80.16,19975229],
		["Las Vegas","NV",36.28,-115.28,19979336],
		["Oakland Gardens","NY",40.74,-73.75,19983442],
		["Harrisburg","PA",40.27,-76.88,19987546],
		["Saint Paul","MN",44.94,-93.1,19991650],
		["East Orange","NJ",40.76,-74.21,19995752],
		["Washington","DC",38.89,-77.03,19999854],
		["Billerica","MA",42.55,-71.26,20003955],
		["Suitland","MD",38.83,-76.92,20008055],
		["Millington","TN",35.33,-89.88,20012154],
		["Arlington","VA",38.87,-77.1,20016252],
		["Danville","VA",36.58,-79.4,20020350],
		["Eagle River","AK",61.21,-149.25,20024448],
		["Oceanside","NY",40.63,-73.63,20028545],
		["Gainesville","VA",38.79,-77.61,20032642],
		["Wausau","WI",44.96,-89.64,20036739],
		["Easley","SC",34.82,-82.58,20040835],
		["Portsmouth","OH",38.75,-82.95,20044931],
		["Colorado Springs","CO",38.86,-104.76,20049027],
		["Syosset","NY",40.81,-73.5,20053121],
		["Louisville","KY",38.22,-85.74,20057214],
		["Plainfield","IL",41.61,-88.2,20061306],
		["Downey","CA",33.95,-118.12,20065397],
		["Decatur","AL",34.57,-86.98,20069487],
		["Billings","MT",45.87,-108.47,20073577],
		["Johnson City","TN",36.33,-82.36,20077666],
		["Worcester","MA",42.26,-71.8,20081754],
		["Phillipsburg","NJ",40.68,-75.18,20085840],
		["Hazleton","PA",40.95,-75.97,20089925],
		["Vancouver","WA",45.6,-122.51,20094010],
		["Columbia","SC",34,-81.03,20098094],
		["New Orleans","LA",30.06,-89.93,20102178],
		["Claremore","OK",36.31,-95.61,20106261],
		["San Jose","CA",37.25,-121.88,20110343],
		["Saint Augustine","FL",29.89,-81.31,20114424],
		["Jacksonville","FL",30.33,-81.65,20118505],
		["Monroe","MI",41.91,-83.38,20122585],
		["Mokena","IL",41.53,-87.87,20126665],
		["North Richland Hills","TX",32.86,-97.21,20130744],
		["Corpus Christi","TX",27.8,-97.39,20134822],
		["San Rafael","CA",37.97,-122.5,20138900],
		["Kenosha","WI",42.58,-87.85,20142977],
		["Oak Forest","IL",41.6,-87.75,20147054],
		["Auburn","WA",47.31,-122.2,20151130],
		["Saint Paul","MN",44.94,-93.1,20155205],
		["Stratford","CT",41.2,-73.13,20159279],
		["Natchez","MS",31.54,-91.38,20163353],
		["Youngstown","OH",41.09,-80.64,20167427],
		["Freeport","IL",42.29,-89.63,20171498],
		["Eugene","OR",44.1,-123.13,20175569],
		["Uniondale","NY",40.7,-73.59,20179639],
		["Hollywood","FL",26.02,-80.16,20183709],
		["Sumter","SC",33.94,-80.39,20187778],
		["Grandville","MI",42.9,-85.75,20191847],
		["North Dartmouth","MA",41.64,-71,20195915],
		["Silver Spring","MD",39.01,-77.02,20199983],
		["Orlando","FL",28.5,-81.37,20204051],
		["Castle Rock","CO",39.37,-104.85,20208119],
		["Miami","FL",25.77,-80.2,20212186],
		["Bardstown","KY",37.81,-85.46,20216253],
		["Vernal","UT",40.55,-109.57,20220320],
		["Fort Lauderdale","FL",26.14,-80.13,20224386],
		["Port Orchard","WA",47.46,-122.65,20228452],
		["Janesville","WI",42.68,-89.01,20232516],
		["Fort Leonard Wood","MO",37.7,-92.15,20236580],
		["Flint","MI",43.02,-83.69,20240642],
		["New York","NY",40.71,-73.99,20244703],
		["Stroudsburg","PA",40.98,-75.19,20248764],
		["El Paso","TX",31.84,-106.43,20252825],
		["Columbus","OH",39.98,-82.98,20256885],
		["San Lorenzo","CA",37.68,-122.13,20260945],
		["Winchester","CA",33.63,-117.07,20265004],
		["Madison","WI",43.07,-89.38,20269062],
		["Rapid City","SD",44.07,-103.23,20273120],
		["Grand Forks","ND",47.91,-97.07,20277178],
		["Granite Bay","CA",38.76,-121.16,20281236],
		["Poulsbo","WA",47.75,-122.61,20285294],
		["Jefferson City","MO",38.57,-92.19,20289351],
		["Pensacola","FL",30.44,-87.18,20293407],
		["Niceville","FL",30.53,-86.48,20297463],
		["Racine","WI",42.72,-87.8,20301519],
		["Worcester","MA",42.26,-71.8,20305574],
		["Sanford","FL",28.78,-81.27,20309629],
		["Milwaukee","WI",43.06,-87.96,20313683],
		["Lansing","MI",42.7,-84.55,20317736],
		["Milwaukee","WI",43.06,-87.96,20321789],
		["Abilene","TX",32.45,-99.73,20325842],
		["Nashville","TN",36.17,-86.78,20329894],
		["Stockton","CA",38.01,-121.38,20333946],
		["Indianapolis","IN",39.77,-86.14,20337997],
		["Des Plaines","IL",42.03,-87.9,20342048],
		["Bartow","FL",27.89,-81.84,20346097],
		["Chicago","IL",41.83,-87.68,20350146],
		["Terre Haute","IN",39.47,-87.38,20354194],
		["Blue Island","IL",41.65,-87.68,20358242],
		["Spokane","WA",47.69,-117.38,20362289],
		["Rochester","NY",43.16,-77.61,20366335],
		["Emporia","KS",38.41,-96.19,20370381],
		["Gaithersburg","MD",39.14,-77.21,20374426],
		["Brighton","CO",39.95,-104.9,20378471],
		["High Point","NC",35.98,-79.99,20382515],
		["Huntington","IN",40.88,-85.5,20386559],
		["Cranberry Twp","PA",40.71,-80.1,20390602],
		["Red Bluff","CA",40.13,-122.43,20394645],
		["Glasgow","KY",36.99,-85.92,20398687],
		["Randolph","NJ",40.84,-74.58,20402728],
		["Locust Grove","GA",33.34,-84.1,20406769],
		["Benicia","CA",38.11,-122.13,20410810],
		["Sunnyside","WA",46.44,-119.91,20414851],
		["Akron","OH",41.08,-81.52,20418890],
		["Ventura","CA",34.33,-119.31,20422929],
		["Columbia","SC",34,-81.03,20426967],
		["Sherwood","AR",34.83,-92.2,20431005],
		["Charlotte","NC",35.19,-80.83,20435042],
		["Plainfield","IN",39.7,-86.38,20439078],
		["Independence","KY",38.95,-84.54,20443112],
		["Fort Worth","TX",32.75,-97.33,20447146],
		["Woodland Hills","CA",34.17,-118.61,20451180],
		["Pasadena","CA",34.17,-118.17,20455212],
		["Bakersfield","CA",35.38,-119.2,20459244],
		["Georgetown","SC",33.36,-79.29,20463275],
		["Simpsonville","SC",34.73,-82.25,20467305],
		["Alton","IL",38.9,-90.15,20471335],
		["Naguabo","PR",18.21,-65.73,20475364],
		["Myrtle Beach","SC",33.69,-78.89,20479392],
		["Los Angeles","CA",34.06,-118.31,20483420],
		["Palatka","FL",29.64,-81.65,20487447],
		["Papillion","NE",41.15,-96.04,20491472],
		["Boerne","TX",29.8,-98.73,20495494],
		["Hastings","MN",44.73,-92.85,20499515],
		["Green Bay","WI",44.52,-87.98,20503535],
		["Owatonna","MN",44.08,-93.22,20507555],
		["Minneapolis","MN",44.96,-93.26,20511575],
		["Crosby","TX",29.91,-95.05,20515594],
		["Cleveland","TN",35.17,-84.86,20519612],
		["San Antonio","TX",29.45,-98.5,20523630],
		["San Mateo","CA",37.54,-122.3,20527648],
		["Chicago","IL",41.83,-87.68,20531665],
		["Grants Pass","OR",42.37,-123.39,20535679],
		["Asheville","NC",35.57,-82.54,20539691],
		["Colleyville","TX",32.89,-97.14,20543703],
		["Brigham City","UT",41.51,-112.04,20547715],
		["San Clemente","CA",33.42,-117.62,20551727],
		["Livonia","MI",42.39,-83.37,20555738],
		["Houston","TX",29.76,-95.38,20559748],
		["Lowell","MA",42.63,-71.32,20563757],
		["Richmond","TX",29.68,-95.7,20567766],
		["Fremont","OH",41.35,-83.11,20571774],
		["Juneau","AK",58.58,-134.77,20575781],
		["Alexandria","VA",38.82,-77.08,20579787],
		["Hackettstown","NJ",40.85,-74.82,20583792],
		["Whitestone","NY",40.79,-73.81,20587797],
		["Waterbury","CT",41.55,-73.03,20591800],
		["New York","NY",40.71,-73.99,20595803],
		["Bowie","MD",38.95,-76.73,20599805],
		["Deland","FL",29.05,-81.31,20603807],
		["Flushing","NY",40.77,-73.84,20607808],
		["Warner Robins","GA",32.61,-83.63,20611809],
		["Brandon","FL",27.92,-82.29,20615810],
		["Canton","MS",32.61,-90.03,20619810],
		["Indianapolis","IN",39.77,-86.14,20623810],
		["Balch Springs","TX",32.76,-96.59,20627810],
		["Scottsdale","AZ",33.61,-111.89,20631810],
		["Redlands","CA",34,-117.12,20635809],
		["Glendora","CA",34.14,-117.85,20639807],
		["Woburn","MA",42.48,-71.15,20643803],
		["Bradenton","FL",27.48,-82.57,20647798],
		["Harrison","AR",36.24,-93.11,20651793],
		["Tualatin","OR",45.36,-122.75,20655788],
		["Ruston","LA",32.53,-92.63,20659782],
		["Longview","TX",32.51,-94.76,20663776],
		["Columbus","NE",41.43,-97.35,20667769],
		["Helotes","TX",29.56,-98.69,20671761],
		["Auburndale","FL",28.07,-81.79,20675752],
		["Concord","NH",43.23,-71.56,20679742],
		["Oklahoma City","OK",35.46,-97.51,20683732],
		["Sterling Heights","MI",42.58,-83.03,20687720],
		["Hot Springs National Park","AR",34.5,-93.05,20691708],
		["Greensboro","NC",36.07,-79.82,20695695],
		["Breaux Bridge","LA",30.27,-91.9,20699682],
		["Pasadena","TX",29.66,-95.15,20703669],
		["Lubbock","TX",33.57,-101.87,20707656],
		["Klamath Falls","OR",42.16,-121.68,20711642],
		["Chicago","IL",41.83,-87.68,20715627],
		["Lincoln","NE",40.81,-96.68,20719612],
		["Tallahassee","FL",30.45,-84.28,20723596],
		["Dothan","AL",31.23,-85.4,20727580],
		["Elizabethton","TN",36.33,-82.23,20731564],
		["Westfield","IN",40.04,-86.13,20735548],
		["Hagerstown","MD",39.63,-77.71,20739531],
		["Arlington","TX",32.69,-97.12,20743513],
		["Waldorf","MD",38.64,-76.9,20747494],
		["Santa Isabel","PR",17.97,-66.4,20751473],
		["Conyers","GA",33.66,-84.01,20755451],
		["Wailuku","HI",20.88,-156.5,20759429],
		["Dracut","MA",42.66,-71.3,20763406],
		["Plainfield","IL",41.66,-88.21,20767382],
		["Baton Rouge","LA",30.44,-91.12,20771358],
		["Riverview","FL",27.79,-82.29,20775333],
		["West Palm Beach","FL",26.74,-80.12,20779306],
		["Bryn Mawr","PA",40.02,-75.31,20783278],
		["Dublin","GA",32.53,-82.92,20787250],
		["Corbin","KY",36.93,-84.1,20791221],
		["Phoenix","AZ",33.5,-111.98,20795191],
		["Pleasant Hill","CA",37.95,-122.07,20799161],
		["Albuquerque","NM",35.11,-106.62,20803128],
		["Pittsburgh","PA",40.43,-79.97,20807094],
		["Greenville","SC",34.83,-82.37,20811060],
		["Olympia","WA",47.1,-123.06,20815026],
		["Keene","NH",42.95,-72.29,20818991],
		["Wilmington","NC",34.21,-77.91,20822953],
		["San Diego","CA",32.82,-117.08,20826915],
		["Melbourne","FL",28.08,-80.6,20830876],
		["New Berlin","WI",42.97,-88.12,20834837],
		["Kent","WA",47.39,-122.26,20838798],
		["Chippewa Falls","WI",44.93,-91.39,20842758],
		["Tucson","AZ",32.43,-111.03,20846718],
		["Suffolk","VA",36.7,-76.63,20850676],
		["Hackensack","NJ",40.88,-74.04,20854633],
		["Port Washington","NY",40.82,-73.68,20858590],
		["Bullhead City","AZ",35.09,-114.57,20862547],
		["Franklin","OH",39.55,-84.3,20866503],
		["Porter","TX",30.1,-95.29,20870459],
		["Oxford","MS",34.35,-89.52,20874414],
		["Collinsville","IL",38.67,-89.99,20878369],
		["Deer Park","NY",40.76,-73.32,20882323],
		["Mount Vernon","OH",40.39,-82.47,20886276],
		["Hollis","NY",40.71,-73.76,20890226],
		["Milwaukee","WI",43.06,-87.96,20894176],
		["Traverse City","MI",44.75,-85.6,20898125],
		["Danville","CA",37.78,-121.88,20902073],
		["Spokane","WA",47.59,-117.35,20906021],
		["Maywood","IL",41.88,-87.84,20909967],
		["Doylestown","PA",40.36,-75.09,20913912],
		["New Orleans","LA",30.06,-89.93,20917857],
		["Voorhees","NJ",39.84,-74.95,20921800],
		["Brick","NJ",40.06,-74.11,20925741],
		["Ottumwa","IA",41.01,-92.41,20929682],
		["Mays Landing","NJ",39.45,-74.72,20933622],
		["Buffalo","NY",42.88,-78.85,20937562],
		["San Antonio","TX",29.45,-98.5,20941502],
		["Meadville","PA",41.64,-80.14,20945441],
		["Hickory","NC",35.73,-81.32,20949380],
		["Schenectady","NY",42.8,-73.92,20953318],
		["Mansfield","MA",42.02,-71.21,20957255],
		["Dublin","OH",40.11,-83.13,20961192],
		["Cincinnati","OH",39.09,-84.51,20965128],
		["Indianapolis","IN",39.77,-86.14,20969064],
		["New Orleans","LA",30.06,-89.93,20973000],
		["Gainesville","FL",29.67,-82.33,20976935],
		["Indianapolis","IN",39.77,-86.14,20980870],
		["Fairfax","VA",38.85,-77.29,20984804],
		["Killeen","TX",31.09,-97.71,20988738],
		["Salt Lake City","UT",40.74,-111.98,20992670],
		["Reno","NV",39.54,-119.84,20996602],
		["Indianapolis","IN",39.77,-86.14,21000533],
		["Northridge","CA",34.24,-118.55,21004463],
		["Salem","NH",42.78,-71.2,21008392],
		["Albuquerque","NM",35.11,-106.62,21012321],
		["Powhatan","VA",37.54,-77.92,21016248],
		["Norfolk","VA",36.84,-76.28,21020175],
		["Alabaster","AL",33.22,-86.82,21024101],
		["Meridian","MS",32.38,-88.71,21028027],
		["Cleveland","OH",41.47,-81.67,21031953],
		["Mason City","IA",43.15,-93.19,21035879],
		["Tulsa","OK",36.12,-95.91,21039804],
		["Waldorf","MD",38.64,-76.9,21043728],
		["Lawrence","MA",42.7,-71.16,21047651],
		["Baltimore","MD",39.3,-76.61,21051574],
		["Matawan","NJ",40.41,-74.23,21055496],
		["Fayetteville","NC",35.05,-78.87,21059418],
		["Trenton","NJ",40.22,-74.76,21063337],
		["Virginia Beach","VA",36.73,-76.04,21067256],
		["Peoria","IL",40.74,-89.6,21071175],
		["Mandeville","LA",30.37,-90.08,21075094],
		["Ozone Park","NY",40.69,-73.85,21079012],
		["Cleveland","TN",35.17,-84.86,21082930],
		["Loiza","PR",18.43,-65.88,21086847],
		["Rochester","NY",43.16,-77.61,21090764],
		["Sevierville","TN",35.89,-83.57,21094681],
		["Kansas City","KS",39.1,-94.62,21098598],
		["Rocky Mount","NC",35.95,-77.81,21102514],
		["Cantonment","FL",30.6,-87.34,21106430],
		["Ponte Vedra Beach","FL",30.23,-81.38,21110345],
		["Fort Mill","SC",35,-80.94,21114259],
		["Blue Springs","MO",39.01,-94.28,21118173],
		["San Antonio","TX",29.45,-98.5,21122087],
		["Midlothian","VA",37.5,-77.63,21126000],
		["Robstown","TX",27.79,-97.66,21129913],
		["Chambersburg","PA",39.92,-77.71,21133825],
		["Broomfield","CO",39.94,-105.06,21137737],
		["Goldsboro","NC",35.37,-77.97,21141648],
		["Columbus","OH",39.98,-82.98,21145558],
		["Sulphur","LA",30.22,-93.35,21149468],
		["Hermiston","OR",45.85,-119.21,21153378],
		["Superior","WI",46.67,-92.08,21157287],
		["Stillwater","OK",36.13,-97.07,21161195],
		["Denver","CO",39.76,-104.87,21165103],
		["Pearl","MS",32.32,-90.2,21169010],
		["Greensboro","NC",36.07,-79.82,21172916],
		["Memphis","TN",35.1,-90,21176821],
		["Darby","PA",39.92,-75.26,21180724],
		["North Platte","NE",41.13,-100.77,21184627],
		["Euless","TX",32.84,-97.07,21188530],
		["Miami","FL",25.77,-80.2,21192432],
		["Dyersburg","TN",36.03,-89.38,21196334],
		["Crawfordsville","IN",40.04,-86.89,21200235],
		["Westford","MA",42.58,-71.43,21204135],
		["South Windsor","CT",41.81,-72.61,21208035],
		["Windsor","CT",41.85,-72.65,21211934],
		["Troy","MI",42.58,-83.14,21215832],
		["Waco","TX",31.56,-97.18,21219730],
		["Houston","TX",29.76,-95.38,21223628],
		["Roxbury","MA",42.35,-71.06,21227524],
		["Lake Forest","IL",42.23,-87.85,21231420],
		["Defiance","OH",41.28,-84.36,21235314],
		["Columbus","OH",39.98,-82.98,21239207],
		["Del Valle","TX",30.16,-97.6,21243100],
		["Brooklyn","NY",40.64,-73.94,21246992],
		["Maspeth","NY",40.72,-73.9,21250884],
		["Petaluma","CA",38.21,-122.76,21254776],
		["Monroe","WA",47.87,-121.88,21258668],
		["Staten Island","NY",40.58,-74.14,21262559],
		["Las Vegas","NV",36.18,-115.17,21266450],
		["Tucker","GA",33.85,-84.22,21270338],
		["Oakland","CA",37.78,-122.24,21274225],
		["Austin","TX",30.26,-97.74,21278111],
		["Mattapan","MA",42.26,-71.1,21281996],
		["Roanoke Rapids","NC",36.45,-77.65,21285881],
		["Pataskala","OH",40,-82.67,21289766],
		["Hutchinson","KS",38.06,-97.91,21293651],
		["Rockville","MD",39.08,-77.15,21297535],
		["Lansing","MI",42.7,-84.55,21301419],
		["Aibonito","PR",18.14,-66.26,21305302],
		["North Royalton","OH",41.31,-81.74,21309185],
		["Aliquippa","PA",40.61,-80.25,21313066],
		["Irvine","CA",33.69,-117.78,21316947],
		["Chaska","MN",44.82,-93.6,21320827],
		["Springboro","OH",39.56,-84.23,21324706],
		["Lynchburg","VA",37.4,-79.19,21328584],
		["Ocala","FL",29.18,-82.13,21332462],
		["Las Vegas","NV",36.03,-115.21,21336340],
		["Riverbank","CA",37.73,-120.95,21340218],
		["Hayward","CA",37.62,-122.11,21344095],
		["Hendersonville","NC",35.32,-82.46,21347970],
		["Houma","LA",29.57,-90.7,21351844],
		["Broken Arrow","OK",36.03,-95.78,21355718],
		["Buffalo","NY",42.88,-78.85,21359591],
		["Decatur","IL",39.85,-88.93,21363464],
		["Fremont","NE",41.44,-96.49,21367337],
		["Carson","CA",33.86,-118.25,21371210],
		["Little Falls","NJ",40.87,-74.21,21375082],
		["Colorado Springs","CO",38.86,-104.76,21378954],
		["Wyoming","MI",42.96,-85.65,21382825],
		["Tifton","GA",31.46,-83.51,21386694],
		["Lithia","FL",27.85,-82.17,21390562],
		["Lebanon","OR",44.52,-122.81,21394429],
		["Tewksbury","MA",42.61,-71.23,21398295],
		["Richmond","VA",37.55,-77.46,21402161],
		["Oak Park","IL",41.88,-87.78,21406027],
		["Arlington","VA",38.87,-77.1,21409892],
		["Sherwood","OR",45.35,-122.86,21413757],
		["Forest Grove","OR",45.55,-123.15,21417620],
		["Whitewater","WI",42.83,-88.73,21421482],
		["Latrobe","PA",40.31,-79.38,21425343],
		["Titusville","FL",28.58,-80.82,21429203],
		["Peyton","CO",39.03,-104.48,21433063],
		["Surprise","AZ",33.61,-112.46,21436923],
		["Plainview","NY",40.78,-73.47,21440782],
		["Central Point","OR",42.39,-122.93,21444641],
		["Dearborn","MI",42.31,-83.21,21448499],
		["Champaign","IL",40.11,-88.26,21452357],
		["Dalton","GA",34.76,-84.97,21456214],
		["Saint Petersburg","FL",27.75,-82.64,21460071],
		["Galloway","OH",39.93,-83.19,21463928],
		["Grand Junction","CO",39.08,-108.55,21467785],
		["North Hollywood","CA",34.16,-118.37,21471642],
		["Villa Park","IL",41.88,-87.97,21475498],
		["Idaho Falls","ID",43.52,-112.27,21479354],
		["Graham","NC",36.06,-79.39,21483209],
		["Louisville","KY",38.22,-85.74,21487064],
		["Somerton","AZ",32.53,-114.71,21490919],
		["Saint Cloud","FL",28.24,-81.28,21494773],
		["West Des Moines","IA",41.57,-93.75,21498627],
		["Lakeland","FL",28.04,-81.96,21502480],
		["Saint Louis","MO",38.63,-90.24,21506333],
		["Greenville","SC",34.83,-82.37,21510185],
		["Sammamish","WA",47.58,-122.03,21514036],
		["Rockford","IL",42.27,-89.06,21517886],
		["Bethlehem","PA",40.62,-75.36,21521735],
		["Vidor","TX",30.12,-94,21525584],
		["Edmond","OK",35.65,-97.52,21529432],
		["Lafayette","CA",37.9,-122.11,21533280],
		["Saint Louis","MO",38.63,-90.24,21537127],
		["Deland","FL",29.05,-81.31,21540972],
		["Belleville","IL",38.53,-90,21544817],
		["Sacramento","CA",38.62,-121.38,21548659],
		["Orem","UT",40.29,-111.67,21552500],
		["New York","NY",40.71,-73.99,21556339],
		["Jenison","MI",42.9,-85.82,21560178],
		["Tucson","AZ",32.3,-111.16,21564017],
		["Hopewell","VA",37.29,-77.29,21567854],
		["Blytheville","AR",35.93,-89.92,21571691],
		["Greenwood","SC",34.19,-82.15,21575527],
		["Greenville","MS",33.38,-91.05,21579362],
		["Champlin","MN",45.16,-93.38,21583197],
		["Natick","MA",42.28,-71.35,21587031],
		["Cary","NC",35.78,-78.79,21590865],
		["Richmond","VA",37.55,-77.46,21594698],
		["Garden City","MI",42.32,-83.34,21598530],
		["Glendora","CA",34.11,-117.85,21602362],
		["Spring Hill","FL",28.44,-82.56,21606192],
		["Seattle","WA",47.75,-122.29,21610022],
		["Palos Verdes Peninsula","CA",33.77,-118.34,21613850],
		["New Milford","CT",41.58,-73.4,21617677],
		["Orange","NJ",40.76,-74.23,21621504],
		["Saint Augustine","FL",29.89,-81.31,21625330],
		["Boynton Beach","FL",26.52,-80.08,21629155],
		["Shafter","CA",35.49,-119.3,21632980],
		["Providence","RI",41.82,-71.41,21636804],
		["Belton","MO",38.81,-94.53,21640628],
		["Portland","OR",45.4,-122.61,21644452],
		["Lebanon","MO",37.67,-92.66,21648274],
		["Woodland","CA",38.69,-121.68,21652096],
		["Rochester","NY",43.16,-77.61,21655917],
		["Clemson","SC",34.68,-82.81,21659738],
		["Saint Louis","MO",38.63,-90.24,21663559],
		["Azle","TX",32.89,-97.53,21667380],
		["Greenwood","SC",34.19,-82.15,21671200],
		["Brockport","NY",43.21,-77.94,21675019],
		["New York","NY",40.71,-73.99,21678837],
		["Dahlonega","GA",34.53,-83.98,21682655],
		["River Falls","WI",44.85,-92.62,21686473],
		["Spokane","WA",47.65,-117.37,21690291],
		["Lake Charles","LA",30.21,-93.2,21694108],
		["Charlottesville","VA",38.03,-78.48,21697924],
		["Aiken","SC",33.54,-81.72,21701740],
		["Happy Valley","OR",45.44,-122.52,21705556],
		["Saint Petersburg","FL",27.75,-82.64,21709371],
		["Palm City","FL",27.16,-80.26,21713186],
		["Springfield","MO",37.19,-93.28,21717001],
		["Corpus Christi","TX",27.8,-97.39,21720816],
		["Seattle","WA",47.68,-122.28,21724631],
		["Spartanburg","SC",34.94,-81.92,21728445],
		["Monroe","MI",41.91,-83.38,21732256],
		["Darien","CT",41.05,-73.47,21736066],
		["Corinth","MS",34.93,-88.51,21739875],
		["Fresno","TX",29.53,-95.45,21743683],
		["Salinas","PR",17.97,-66.29,21747490],
		["Brighton","MI",42.53,-83.78,21751297],
		["Boise","ID",43.59,-116.19,21755104],
		["Sabana Grande","PR",18.08,-66.96,21758910],
		["Newark","DE",39.67,-75.75,21762716],
		["Great Falls","MT",47.29,-111.34,21766522],
		["South Elgin","IL",41.99,-88.29,21770328],
		["Muskegon","MI",43.22,-86.25,21774133],
		["Waukesha","WI",43.01,-88.23,21777938],
		["Saint Paul","MN",44.94,-93.1,21781743],
		["Austell","GA",33.82,-84.64,21785547],
		["New Castle","IN",39.92,-85.36,21789351],
		["Worcester","MA",42.26,-71.8,21793154],
		["Cleveland","OH",41.47,-81.67,21796957],
		["Louisville","KY",38.22,-85.74,21800759],
		["Gettysburg","PA",39.83,-77.23,21804560],
		["Montgomery","IL",41.72,-88.34,21808361],
		["Austin","TX",30.26,-97.74,21812162],
		["Baltimore","MD",39.3,-76.61,21815962],
		["Forest Park","GA",33.61,-84.35,21819762],
		["Elizabeth","NJ",40.66,-74.19,21823561],
		["Baltimore","MD",39.3,-76.61,21827359],
		["Denver","CO",39.76,-104.87,21831157],
		["La Canada Flintridge","CA",34.23,-118.14,21834955],
		["Marion","NC",35.68,-82,21838752],
		["Orlando","FL",28.5,-81.37,21842549],
		["Brainerd","MN",46.35,-94.19,21846345],
		["Roswell","NM",33.37,-104.52,21850141],
		["Southgate","MI",42.2,-83.2,21853936],
		["Houston","TX",29.76,-95.38,21857731],
		["Jacksonville","FL",30.33,-81.65,21861525],
		["Livingston","TX",30.7,-94.93,21865319],
		["Martinsville","VA",36.68,-79.86,21869112],
		["Zachary","LA",30.66,-91.15,21872904],
		["Rock Springs","WY",41.59,-109.22,21876695],
		["Seymour","IN",38.95,-85.88,21880485],
		["Jacksonville","FL",30.33,-81.65,21884273],
		["Gainesville","TX",33.63,-97.15,21888060],
		["Gallup","NM",35.52,-108.73,21891846],
		["Shelbyville","IN",39.52,-85.77,21895629],
		["Allen Park","MI",42.25,-83.21,21899412],
		["Willimantic","CT",41.71,-72.21,21903194],
		["Panama City","FL",30.17,-85.67,21906976],
		["Sioux City","IA",42.5,-96.39,21910758],
		["Colorado Springs","CO",38.86,-104.76,21914538],
		["Aberdeen","SD",45.46,-98.48,21918317],
		["Lemon Grove","CA",32.73,-117.03,21922096],
		["Hastings","NE",40.58,-98.39,21925874],
		["Pasadena","TX",29.66,-95.15,21929652],
		["Culver City","CA",33.99,-118.39,21933430],
		["Fort Wayne","IN",41.07,-85.13,21937207],
		["Downers Grove","IL",41.79,-88.01,21940984],
		["Marysville","WA",48.09,-122.24,21944760],
		["Monroe","NC",34.98,-80.54,21948535],
		["Independence","MO",39.08,-94.35,21952310],
		["Bedford","TX",32.84,-97.13,21956085],
		["Garfield","NJ",40.87,-74.1,21959859],
		["Minneapolis","MN",44.96,-93.26,21963632],
		["Kailua Kona","HI",19.63,-155.99,21967405],
		["Edgewood","MD",39.42,-76.29,21971177],
		["Shelby","NC",35.28,-81.54,21974949],
		["Goodlettsville","TN",36.33,-86.7,21978721],
		["Temple","TX",31.09,-97.36,21982493],
		["San Antonio","TX",29.45,-98.5,21986265],
		["Riverside","NJ",40.03,-74.95,21990036],
		["Land O Lakes","FL",28.25,-82.49,21993807],
		["Atascadero","CA",35.45,-120.71,21997578],
		["Leesburg","GA",31.73,-84.17,22001348],
		["Muncie","IN",40.19,-85.39,22005118],
		["Tucson","AZ",32.1,-110.68,22008888],
		["Holbrook","NY",40.79,-73.07,22012657],
		["Louisville","KY",38.22,-85.74,22016426],
		["Appleton","WI",44.26,-88.39,22020195],
		["San Diego","CA",32.73,-117.12,22023964],
		["Santa Cruz","CA",36.97,-121.98,22027733],
		["Shelbyville","KY",38.21,-85.23,22031501],
		["Columbus","OH",39.98,-82.98,22035269],
		["Newport News","VA",37.07,-76.51,22039036],
		["Birmingham","AL",33.52,-86.79,22042801],
		["Salinas","CA",36.64,-121.62,22046566],
		["Lynn","MA",42.47,-70.96,22050330],
		["Fort Myers","FL",26.63,-81.85,22054094],
		["Plant City","FL",28.01,-82.14,22057857],
		["Germantown","MD",39.17,-77.26,22061619],
		["Westwood","NJ",40.98,-74.03,22065380],
		["Ankeny","IA",41.71,-93.62,22069140],
		["Bellingham","WA",48.68,-122.41,22072900],
		["Buffalo","NY",42.88,-78.85,22076659],
		["Fort Wayne","IN",41.07,-85.13,22080417],
		["Flushing","MI",43.06,-83.84,22084173],
		["Pompano Beach","FL",26.23,-80.13,22087928],
		["Gardena","CA",33.89,-118.31,22091683],
		["Sparta","NJ",41.04,-74.62,22095437],
		["State College","PA",40.79,-77.85,22099190],
		["Doylestown","PA",40.31,-75.12,22102943],
		["Long Beach","CA",33.83,-118.18,22106696],
		["New York","NY",40.71,-73.99,22110448],
		["Daphne","AL",30.62,-87.89,22114200],
		["Knoxville","TN",35.87,-84.17,22117952],
		["Sandy","UT",40.59,-111.81,22121704],
		["Canoga Park","CA",34.19,-118.6,22125456],
		["Lake Worth","FL",26.61,-80.05,22129207],
		["Nashville","TN",36.17,-86.78,22132958],
		["Fort Worth","TX",32.75,-97.33,22136709],
		["Dickson","TN",36.07,-87.37,22140458],
		["Tampa","FL",27.95,-82.48,22144205],
		["Columbus","OH",39.98,-82.98,22147951],
		["La Mesa","CA",32.78,-117.01,22151697],
		["Bremerton","WA",47.63,-122.63,22155443],
		["Houston","TX",29.76,-95.38,22159188],
		["Seffner","FL",27.99,-82.27,22162932],
		["Norco","CA",33.93,-117.56,22166676],
		["Upland","CA",34.14,-117.65,22170418],
		["Kingston","PA",41.26,-75.88,22174159],
		["Boynton Beach","FL",26.52,-80.08,22177900],
		["San Angelo","TX",31.44,-100.45,22181641],
		["Parkersburg","WV",39.26,-81.54,22185381],
		["Festus","MO",38.22,-90.4,22189121],
		["Rio Rico","AZ",31.5,-111.06,22192861],
		["Lajas","PR",18.04,-67.06,22196600],
		["Picayune","MS",30.52,-89.67,22200338],
		["Utica","MI",42.62,-83.02,22204076],
		["Ashburn","VA",39.04,-77.48,22207813],
		["Abingdon","MD",39.46,-76.27,22211550],
		["Rockville Centre","NY",40.66,-73.63,22215285],
		["Uniontown","PA",39.89,-79.72,22219020],
		["La Mesa","CA",32.75,-116.99,22222755],
		["Duluth","MN",46.78,-92.11,22226488],
		["Vernon Hills","IL",42.23,-87.96,22230221],
		["Rockingham","NC",34.93,-79.76,22233953],
		["Davenport","IA",41.55,-90.6,22237685],
		["Austin","MN",43.67,-92.97,22241416],
		["Barceloneta","PR",18.45,-66.53,22245145],
		["Lansdowne","PA",39.94,-75.27,22248874],
		["Peoria","AZ",33.61,-112.23,22252603],
		["Beaver Falls","PA",40.76,-80.32,22256331],
		["Belleville","IL",38.53,-90,22260059],
		["Danbury","CT",41.4,-73.47,22263785],
		["Rochester","NY",43.16,-77.61,22267511],
		["Hermitage","TN",36.13,-86.58,22271237],
		["Drexel Hill","PA",39.94,-75.3,22274961],
		["Rosharon","TX",29.37,-95.45,22278685],
		["West Palm Beach","FL",26.74,-80.12,22282408],
		["Waynesboro","VA",38.06,-78.9,22286129],
		["Bessemer","AL",33.37,-86.96,22289850],
		["Cave Creek","AZ",33.8,-111.93,22293571],
		["Thermal","CA",33.48,-116.1,22297292],
		["Salem","OR",44.84,-123.08,22301012],
		["Morgantown","WV",39.63,-79.94,22304731],
		["Sherman","TX",33.61,-96.6,22308450],
		["Charlottesville","VA",38.03,-78.48,22312168],
		["Graham","WA",47.01,-122.29,22315885],
		["Centreville","VA",38.84,-77.44,22319601],
		["Neosho","MO",36.84,-94.37,22323317],
		["Uvalde","TX",29.21,-99.78,22327033],
		["Evansville","IN",37.98,-87.54,22330748],
		["Southfield","MI",42.47,-83.25,22334462],
		["Santa Barbara","CA",34.41,-119.7,22338176],
		["Nashua","NH",42.74,-71.49,22341889],
		["Philadelphia","MS",32.77,-89.1,22345601],
		["New Canaan","CT",41.14,-73.49,22349312],
		["Riverview","FL",27.83,-82.29,22353023],
		["Amityville","NY",40.66,-73.41,22356733],
		["Fort Walton Beach","FL",30.42,-86.61,22360442],
		["Clinton","IA",41.84,-90.23,22364150],
		["Mchenry","IL",42.34,-88.29,22367858],
		["Houston","TX",29.76,-95.38,22371566],
		["Manchester","NH",42.99,-71.45,22375273],
		["Munster","IN",41.54,-87.5,22378980],
		["Selden","NY",40.86,-73.04,22382685],
		["Muncie","IN",40.19,-85.39,22386390],
		["Kirksville","MO",40.19,-92.57,22390095],
		["Big Rapids","MI",43.7,-85.48,22393799],
		["Winnetka","IL",42.1,-87.74,22397503],
		["Bethel Park","PA",40.32,-80.03,22401206],
		["Niagara Falls","NY",43.09,-79.01,22404908],
		["Rossville","GA",34.97,-85.28,22408609],
		["Burlington","IA",40.8,-91.12,22412310],
		["Orange","TX",30.1,-93.75,22416010],
		["Monterey Park","CA",34.05,-118.14,22419710],
		["Toledo","OH",41.66,-83.58,22423409],
		["Paramus","NJ",40.94,-74.07,22427106],
		["Cleveland","OH",41.47,-81.67,22430803],
		["Mechanicsville","MD",38.43,-76.73,22434499],
		["Miami","FL",25.77,-80.2,22438195],
		["Gig Harbor","WA",47.29,-122.62,22441891],
		["Houma","LA",29.57,-90.7,22445586],
		["Englewood","CO",39.64,-104.99,22449281],
		["Rialto","CA",34.16,-117.39,22452976],
		["Morganville","NJ",40.36,-74.25,22456669],
		["West Hempstead","NY",40.69,-73.65,22460361],
		["Wilmington","NC",34.21,-77.91,22464053],
		["Milwaukee","WI",43.06,-87.96,22467744],
		["Amsterdam","NY",42.94,-74.19,22471434],
		["Denver","CO",39.76,-104.87,22475124],
		["South Holland","IL",41.59,-87.6,22478813],
		["Fairbanks","AK",64.9,-148.16,22482502],
		["Baton Rouge","LA",30.44,-91.12,22486190],
		["Los Alamitos","CA",33.79,-118.06,22489877],
		["Duarte","CA",34.13,-117.94,22493564],
		["Sunnyvale","CA",37.36,-122.02,22497251],
		["Bolingbrook","IL",41.7,-88.07,22500937],
		["Littleton","CO",39.54,-104.93,22504623],
		["Merrimack","NH",42.86,-71.48,22508308],
		["Cedar City","UT",37.81,-113.22,22511993],
		["Roslindale","MA",42.28,-71.13,22515677],
		["North Attleboro","MA",41.97,-71.33,22519361],
		["Clinton","NC",35,-78.33,22523045],
		["Cape Coral","FL",26.63,-81.99,22526728],
		["Milton","FL",30.63,-87.04,22530409],
		["Great Falls","MT",47.65,-111.38,22534090],
		["Las Cruces","NM",32.33,-106.75,22537771],
		["Beckley","WV",37.78,-81.18,22541450],
		["Fort Mill","SC",35,-80.94,22545127],
		["Franklin","TN",35.92,-86.84,22548803],
		["Melrose Park","IL",41.9,-87.86,22552479],
		["Liberal","KS",37.04,-100.93,22556153],
		["Kelso","WA",46.14,-122.8,22559827],
		["Clinton","MS",32.33,-90.33,22563500],
		["Atlanta","GA",33.72,-84.42,22567172],
		["Stevenson Ranch","CA",34.37,-118.61,22570844],
		["Imperial Beach","CA",32.57,-117.11,22574516],
		["Orlando","FL",28.5,-81.37,22578187],
		["Richland","WA",46.32,-119.32,22581858],
		["Caldwell","ID",43.68,-116.73,22585528],
		["Bergenfield","NJ",40.92,-73.99,22589197],
		["Malvern","PA",40.03,-75.51,22592866],
		["Deridder","LA",30.77,-93.24,22596535],
		["Denver","CO",39.76,-104.87,22600203],
		["Newton","NC",35.66,-81.21,22603870],
		["Johnson City","TN",36.33,-82.36,22607537],
		["High Point","NC",35.98,-79.99,22611202],
		["San Mateo","CA",37.57,-122.32,22614867],
		["Erie","PA",42.12,-80.08,22618531],
		["Weatherford","TX",32.75,-97.77,22622195],
		["Hutto","TX",30.54,-97.54,22625858],
		["Trussville","AL",33.63,-86.59,22629520],
		["Fullerton","CA",33.86,-117.92,22633181],
		["Las Cruces","NM",32.33,-106.75,22636841],
		["Olympia","WA",46.94,-123.04,22640501],
		["Stony Brook","NY",40.9,-73.12,22644159],
		["Fort Valley","GA",32.55,-83.88,22647817],
		["Aurora","CO",39.69,-104.81,22651475],
		["Talladega","AL",33.43,-86.09,22655132],
		["Cedar City","UT",37.66,-113.19,22658789],
		["Newton Center","MA",42.31,-71.19,22662443],
		["Kingston","RI",41.47,-71.52,22666097],
		["Bowling Green","KY",36.97,-86.44,22669751],
		["Sacramento","CA",38.64,-121.54,22673404],
		["Battle Creek","MI",42.29,-85.22,22677056],
		["Oak Ridge","TN",35.96,-84.29,22680707],
		["Hurst","TX",32.83,-97.17,22684357],
		["Paterson","NJ",40.91,-74.16,22688006],
		["Chicago","IL",41.83,-87.68,22691655],
		["Troy","MO",38.97,-90.98,22695304],
		["Shawnee","KS",38.9,-94.68,22698952],
		["Manchester","NH",42.99,-71.45,22702599],
		["Latham","NY",42.74,-73.74,22706246],
		["Kings Mountain","NC",35.24,-81.34,22709893],
		["Alhambra","CA",34.07,-118.14,22713540],
		["Moorestown","NJ",39.97,-74.94,22717186],
		["Fulton","NY",43.31,-76.41,22720832],
		["Tulsa","OK",36.12,-95.91,22724478],
		["Lodi","CA",38.13,-121.39,22728124],
		["Marshfield","MA",42.09,-70.7,22731767],
		["Cape Coral","FL",26.63,-81.99,22735409],
		["Lewis Center","OH",40.18,-82.99,22739051],
		["Green Cove Springs","FL",29.98,-81.67,22742691],
		["Louisville","KY",38.22,-85.74,22746331],
		["Monument","CO",39.07,-104.85,22749971],
		["Laurinburg","NC",34.76,-79.47,22753610],
		["Minneapolis","MN",44.96,-93.26,22757249],
		["Greenwood","MS",33.51,-90.19,22760887],
		["Hyattsville","MD",38.95,-76.95,22764524],
		["Modesto","CA",37.63,-120.97,22768161],
		["Knoxville","TN",35.97,-83.94,22771797],
		["Acton","MA",42.48,-71.46,22775431],
		["Toledo","OH",41.66,-83.58,22779065],
		["South Bend","IN",41.67,-86.26,22782698],
		["Columbus","IN",39.21,-85.91,22786329],
		["Commerce Township","MI",42.59,-83.5,22789959],
		["Colorado Springs","CO",38.86,-104.76,22793589],
		["Abbeville","LA",29.97,-92.12,22797217],
		["Long Beach","NY",40.58,-73.65,22800843],
		["Beaverton","OR",45.45,-122.79,22804469],
		["Wichita","KS",37.68,-97.34,22808093],
		["Portland","OR",45.4,-122.79,22811717],
		["Harrisburg","PA",40.27,-76.88,22815340],
		["Buffalo","NY",42.88,-78.85,22818960],
		["Joliet","IL",41.52,-88.12,22822580],
		["Winchester","VA",39.17,-78.17,22826199],
		["Lake Worth","FL",26.61,-80.05,22829818],
		["Saline","MI",42.17,-83.78,22833437],
		["Lemont","IL",41.67,-87.99,22837056],
		["Chehalis","WA",46.62,-123,22840675],
		["Pittsburg","KS",37.41,-94.69,22844293],
		["Lancaster","TX",32.59,-96.78,22847911],
		["Houston","TX",29.76,-95.38,22851529],
		["Los Angeles","CA",34.13,-118.2,22855147],
		["Vienna","VA",38.9,-77.26,22858764],
		["Appleton","WI",44.26,-88.39,22862381],
		["Topeka","KS",39.03,-95.69,22865998],
		["Austin","TX",30.26,-97.74,22869614],
		["Cleveland","OH",41.47,-81.67,22873229],
		["Bedford","OH",41.39,-81.53,22876844],
		["Benton","AR",34.56,-92.57,22880459],
		["Catano","PR",18.43,-66.11,22884073],
		["Marion","IN",40.54,-85.66,22887687],
		["Hudson","NH",42.76,-71.43,22891298],
		["Bay City","MI",43.59,-83.88,22894909],
		["Wisconsin Rapids","WI",44.39,-89.83,22898520],
		["Queensbury","NY",43.34,-73.67,22902130],
		["Columbus","OH",39.98,-82.98,22905740],
		["South Bend","IN",41.67,-86.26,22909350],
		["Newton","NJ",41.05,-74.75,22912959],
		["Mocksville","NC",35.89,-80.55,22916568],
		["Kansas City","MO",39.09,-94.58,22920176],
		["Saint Petersburg","FL",27.75,-82.64,22923783],
		["Huntsville","AL",34.7,-86.62,22927390],
		["New Orleans","LA",30.06,-89.93,22930997],
		["Queens Village","NY",40.72,-73.74,22934602],
		["Fairhope","AL",30.51,-87.88,22938207],
		["Elko","NV",40.95,-115.79,22941812],
		["Mebane","NC",36.09,-79.27,22945416],
		["Hialeah","FL",25.86,-80.29,22949020],
		["Fargo","ND",46.87,-96.81,22952624],
		["Waterville","ME",44.56,-69.55,22956227],
		["Belle Glade","FL",26.68,-80.67,22959829],
		["Duncan","OK",34.52,-97.96,22963431],
		["Odenton","MD",39.06,-76.69,22967032],
		["Colonial Heights","VA",37.26,-77.39,22970633],
		["Albany","NY",42.66,-73.79,22974233],
		["Perkasie","PA",40.37,-75.29,22977833],
		["Minneapolis","MN",44.96,-93.26,22981433],
		["Bedford","IN",38.86,-86.49,22985031],
		["Tacoma","WA",47.22,-122.54,22988629],
		["New York","NY",40.71,-73.99,22992226],
		["Piedmont","SC",34.71,-82.46,22995823],
		["Atlanta","GA",33.97,-84.32,22999420],
		["Floral Park","NY",40.72,-73.7,23003016],
		["Shreveport","LA",32.47,-93.79,23006611],
		["San Antonio","TX",29.45,-98.5,23010206],
		["Kalamazoo","MI",42.24,-85.48,23013800],
		["Overland Park","KS",38.9,-94.68,23017393],
		["Rego Park","NY",40.72,-73.86,23020985],
		["Wilmington","DE",39.73,-75.53,23024577],
		["Clemmons","NC",36.02,-80.38,23028169],
		["North Charleston","SC",32.92,-80.09,23031761],
		["Casper","WY",42.83,-106.32,23035353],
		["West Palm Beach","FL",26.74,-80.12,23038943],
		["Ponchatoula","LA",30.43,-90.44,23042533],
		["Columbus","OH",39.98,-82.98,23046122],
		["Clarksburg","WV",39.28,-80.33,23049710],
		["Springfield","TN",36.49,-86.87,23053297],
		["Paducah","KY",37.07,-88.63,23056884],
		["Arroyo Grande","CA",35.16,-120.43,23060471],
		["Crofton","MD",39.01,-76.68,23064057],
		["Junction City","KS",39.02,-96.84,23067642],
		["Brooklyn","NY",40.64,-73.94,23071225],
		["Salisbury","NC",35.66,-80.48,23074808],
		["Metairie","LA",29.99,-90.17,23078390],
		["Staten Island","NY",40.58,-74.14,23081970],
		["Chesterton","IN",41.6,-87.05,23085550],
		["Carrollton","GA",33.58,-85.07,23089129],
		["Lexington","KY",38.04,-84.45,23092708],
		["Strongsville","OH",41.31,-81.83,23096287],
		["Bedford","NH",42.95,-71.5,23099865],
		["South Pasadena","CA",34.11,-118.15,23103443],
		["Atlanta","GA",33.94,-84.31,23107020],
		["Buffalo","NY",42.88,-78.85,23110596],
		["Maple Heights","OH",41.4,-81.56,23114172],
		["Willowbrook","IL",41.8,-87.92,23117747],
		["Roseburg","OR",43.24,-123.48,23121322],
		["West Palm Beach","FL",26.74,-80.12,23124896],
		["Sacramento","CA",38.6,-121.46,23128470],
		["Ferndale","WA",48.86,-122.63,23132044],
		["Philadelphia","PA",39.95,-75.16,23135617],
		["Soddy Daisy","TN",35.26,-85.17,23139190],
		["Wyoming","MI",42.89,-85.72,23142763],
		["Arvada","CO",39.82,-105.1,23146336],
		["Hiram","GA",33.86,-84.77,23149907],
		["Port Saint Lucie","FL",27.27,-80.35,23153478],
		["Homer Glen","IL",41.6,-88.01,23157049],
		["Kilgore","TX",32.38,-94.86,23160620],
		["Altoona","PA",40.5,-78.4,23164190],
		["Maumee","OH",41.57,-83.65,23167760],
		["Hammond","LA",30.5,-90.46,23171330],
		["Provo","UT",40.21,-111.61,23174900],
		["Anchorage","AK",61.16,-149.99,23178470],
		["Houston","TX",29.76,-95.38,23182039],
		["Colorado Springs","CO",38.86,-104.76,23185608],
		["Newington","CT",41.68,-72.73,23189176],
		["Royse City","TX",32.97,-96.33,23192743],
		["Salem","OR",44.9,-122.89,23196310],
		["Spring Hill","TN",35.74,-86.92,23199876],
		["Columbus","MS",33.5,-88.4,23203442],
		["Lorain","OH",41.44,-82.18,23207006],
		["Powell","TN",36.03,-84.02,23210569],
		["Pasadena","TX",29.66,-95.15,23214132],
		["Albany","OR",44.62,-123.13,23217695],
		["Cookeville","TN",36.15,-85.5,23221256],
		["South Lake Tahoe","CA",38.84,-120.01,23224817],
		["Muskego","WI",42.89,-88.12,23228377],
		["Mandeville","LA",30.37,-90.08,23231937],
		["Elkridge","MD",39.2,-76.75,23235496],
		["Milwaukee","WI",43.06,-87.96,23239055],
		["Durango","CO",37.27,-107.88,23242613],
		["Cincinnati","OH",39.09,-84.51,23246170],
		["Bothell","WA",47.74,-122.2,23249727],
		["Mcallen","TX",26.21,-98.23,23253283],
		["Hamden","CT",41.37,-72.9,23256838],
		["Waynesboro","PA",39.75,-77.58,23260393],
		["Christiansburg","VA",37.14,-80.4,23263948],
		["Stockton","CA",37.97,-121.32,23267503],
		["Potsdam","NY",44.66,-74.98,23271055],
		["Shelby","NC",35.28,-81.54,23274607],
		["Springfield","MA",42.11,-72.53,23278158],
		["Kent","OH",41.14,-81.36,23281709],
		["Utica","MI",42.62,-83.02,23285259],
		["Flint","MI",43.02,-83.69,23288809],
		["Minneapolis","MN",44.96,-93.26,23292359],
		["Saint Louis","MO",38.63,-90.24,23295908],
		["Brownwood","TX",31.72,-98.97,23299455],
		["Orange","TX",30.1,-93.75,23303002],
		["Hernando","MS",34.82,-89.98,23306548],
		["Bloomington","IN",39.16,-86.52,23310094],
		["Fresno","CA",36.8,-119.8,23313640],
		["Hightstown","NJ",40.26,-74.52,23317184],
		["Henderson","NC",36.37,-78.37,23320728],
		["Jackson","MS",32.32,-90.2,23324272],
		["Minneapolis","MN",44.96,-93.26,23327816],
		["Fort Worth","TX",32.75,-97.33,23331360],
		["Danville","VA",36.58,-79.4,23334902],
		["Saint Louis","MO",38.63,-90.24,23338443],
		["Castle Rock","CO",39.37,-104.85,23341983],
		["Fort Smith","AR",35.36,-94.38,23345522],
		["West Hills","CA",34.2,-118.69,23349061],
		["Orange","CA",33.78,-117.86,23352600],
		["Crowley","TX",32.57,-97.35,23356138],
		["Erlanger","KY",39,-84.58,23359675],
		["Tampa","FL",27.95,-82.48,23363211],
		["Harbor City","CA",33.79,-118.29,23366747],
		["Lagrange","GA",33.03,-84.98,23370281],
		["Palm Beach Gardens","FL",26.74,-80.12,23373814],
		["Elizabeth","NJ",40.66,-74.19,23377344],
		["Carmel","NY",41.41,-73.68,23380874],
		["Melrose Park","IL",41.9,-87.86,23384403],
		["Omaha","NE",41.26,-96.01,23387931],
		["Knightdale","NC",35.79,-78.48,23391458],
		["Elkhart","IN",41.68,-85.96,23394985],
		["Glendale","AZ",33.62,-112.17,23398512],
		["Sparks","NV",39.53,-119.41,23402039],
		["Brookhaven","MS",31.58,-90.44,23405565],
		["Rockford","IL",42.27,-89.06,23409091],
		["Denver","CO",39.76,-104.87,23412617],
		["Anchorage","AK",61.11,-149.9,23416143],
		["Kutztown","PA",40.52,-75.77,23419668],
		["Union City","GA",33.57,-84.54,23423193],
		["Minneapolis","MN",44.96,-93.26,23426717],
		["Oklahoma City","OK",35.46,-97.51,23430240],
		["Huntsville","AL",34.7,-86.62,23433762],
		["Waterloo","IA",42.49,-92.35,23437284],
		["Richmond","VA",37.55,-77.46,23440805],
		["Livonia","MI",42.39,-83.37,23444326],
		["Mansfield","OH",40.76,-82.52,23447846],
		["Buda","TX",30.08,-97.83,23451365],
		["Ocala","FL",29.18,-82.13,23454883],
		["Selma","AL",32.41,-87.03,23458401],
		["Cincinnati","OH",39.09,-84.51,23461919],
		["Anthony","NM",32,-106.59,23465437],
		["Tarzana","CA",34.15,-118.54,23468954],
		["Euless","TX",32.84,-97.07,23472470],
		["Austin","TX",30.26,-97.74,23475986],
		["Mount Holly","NJ",39.99,-74.78,23479501],
		["Pittsburgh","PA",40.43,-79.97,23483016],
		["Austin","TX",30.26,-97.74,23486531],
		["Fort Collins","CO",40.55,-105.06,23490046],
		["San Jose","CA",37.31,-121.93,23493561],
		["Kenosha","WI",42.58,-87.85,23497075],
		["Chesterfield","MO",38.65,-90.58,23500589],
		["Denver","CO",39.76,-104.87,23504103],
		["Millersburg","OH",40.55,-81.91,23507614],
		["Charlottesville","VA",38.03,-78.48,23511124],
		["Stephenville","TX",32.21,-98.21,23514634],
		["Winston Salem","NC",36.1,-80.24,23518143],
		["Kansas City","MO",39.09,-94.58,23521652],
		["Bremerton","WA",47.57,-122.75,23525160],
		["Carteret","NJ",40.58,-74.22,23528667],
		["Schenectady","NY",42.8,-73.92,23532173],
		["Fredericksburg","VA",38.29,-77.48,23535679],
		["Phoenix","AZ",33.5,-112.02,23539185],
		["Lakewood","WA",47.16,-122.5,23542691],
		["East Hartford","CT",41.76,-72.61,23546196],
		["Oklahoma City","OK",35.46,-97.51,23549701],
		["Houston","TX",29.76,-95.38,23553206],
		["Fort Lauderdale","FL",26.14,-80.13,23556710],
		["West Bend","WI",43.42,-88.18,23560214],
		["Eugene","OR",44.03,-123.05,23563718],
		["Henrico","VA",37.55,-77.46,23567221],
		["Grayson","GA",33.89,-83.95,23570724],
		["Canandaigua","NY",42.88,-77.28,23574225],
		["Royersford","PA",40.18,-75.53,23577726],
		["Pensacola","FL",30.44,-87.18,23581227],
		["Downers Grove","IL",41.79,-88.01,23584728],
		["Kansas City","KS",39.1,-94.62,23588229],
		["Cleburne","TX",32.34,-97.4,23591729],
		["Waterbury","CT",41.55,-73.03,23595228],
		["Rahway","NJ",40.6,-74.28,23598727],
		["Alexandria","VA",38.82,-77.08,23602226],
		["Crestwood","KY",38.33,-85.48,23605725],
		["Lansing","MI",42.7,-84.55,23609224],
		["Moscow","ID",46.71,-116.91,23612723],
		["Pontiac","MI",42.64,-83.28,23616221],
		["Richmond Hill","GA",31.93,-81.3,23619718],
		["Helena","MT",46.51,-112.12,23623215],
		["Hinsdale","IL",41.8,-87.92,23626712],
		["Norwalk","CT",41.09,-73.42,23630208],
		["Middletown","OH",39.5,-84.37,23633704],
		["Lima","OH",40.74,-84.11,23637200],
		["Antioch","IL",42.47,-88.07,23640696],
		["Chicopee","MA",42.17,-72.57,23644191],
		["Abilene","TX",32.45,-99.73,23647686],
		["Douglas","AZ",31.54,-109.46,23651181],
		["Houston","TX",29.76,-95.38,23654675],
		["Bothell","WA",47.79,-122.2,23658169],
		["Denham Springs","LA",30.47,-90.95,23661662],
		["Dallas","TX",32.79,-96.76,23665155],
		["Jacksonville","IL",39.72,-90.23,23668647],
		["Warwick","RI",41.7,-71.42,23672138],
		["Flagstaff","AZ",35.18,-111.62,23675629],
		["Fallon","NV",39.5,-118.29,23679119],
		["North Wales","PA",40.21,-75.27,23682608],
		["Sedro Woolley","WA",48.59,-122.13,23686097],
		["Manchester","TN",35.46,-86.08,23689585],
		["Auburn","CA",38.92,-121.07,23693073],
		["Hershey","PA",40.28,-76.64,23696559],
		["Oxford","MI",42.82,-83.25,23700045],
		["Los Angeles","CA",34.08,-118.32,23703531],
		["Clarksdale","MS",34.2,-90.57,23707016],
		["Reno","NV",39.55,-119.8,23710501],
		["Marietta","OH",39.42,-81.44,23713985],
		["Glendale","AZ",33.71,-112.17,23717469],
		["Hammond","IN",41.62,-87.49,23720952],
		["Fort Worth","TX",32.75,-97.33,23724435],
		["Houston","TX",29.76,-95.38,23727918],
		["Palo Alto","CA",37.41,-122.13,23731401],
		["Fargo","ND",46.87,-96.81,23734883],
		["Miami","FL",25.77,-80.2,23738364],
		["Saint Louis","MO",38.63,-90.24,23741845],
		["Victoria","TX",28.82,-96.98,23745325],
		["Elgin","TX",30.34,-97.37,23748804],
		["Cincinnati","OH",39.09,-84.51,23752281],
		["Lafayette","CO",39.99,-105.09,23755758],
		["Jacksonville","FL",30.33,-81.65,23759234],
		["South Bend","IN",41.67,-86.26,23762710],
		["Canyon","TX",34.98,-101.92,23766186],
		["Garden Grove","CA",33.76,-117.97,23769662],
		["Meriden","CT",41.53,-72.79,23773137],
		["Princeton Junction","NJ",40.32,-74.62,23776611],
		["Rockford","IL",42.27,-89.06,23780085],
		["Phoenix","AZ",33.57,-112.05,23783559],
		["Sahuarita","AZ",31.91,-110.9,23787033],
		["Vail","AZ",31.95,-110.69,23790507],
		["Dayton","OH",39.77,-84.19,23793980],
		["Omaha","NE",41.26,-96.01,23797453],
		["Gillette","WY",44.28,-105.51,23800926],
		["Canby","OR",45.21,-122.66,23804399],
		["Fredericksburg","VA",38.29,-77.48,23807871],
		["Maryville","TN",35.74,-83.97,23811341],
		["Reno","NV",39.49,-119.84,23814811],
		["Saint Louis","MO",38.63,-90.24,23818280],
		["Yelm","WA",46.87,-122.57,23821749],
		["Jacksonville","AL",33.81,-85.76,23825217],
		["Santa Clara","CA",37.34,-121.95,23828683],
		["Yorba Linda","CA",33.88,-117.72,23832148],
		["Fort Smith","AR",35.36,-94.38,23835612],
		["Texarkana","TX",33.44,-94.07,23839076],
		["Santa Clarita","CA",34.53,-118.4,23842540],
		["Framingham","MA",42.3,-71.43,23846002],
		["Summit","NJ",40.71,-74.36,23849464],
		["Terrell","TX",32.73,-96.29,23852925],
		["Iowa City","IA",41.65,-91.53,23856385],
		["Fort Worth","TX",32.75,-97.33,23859845],
		["Lynnwood","WA",47.85,-122.28,23863305],
		["Long Branch","NJ",40.29,-73.98,23866762],
		["Albemarle","NC",35.36,-80.19,23870219],
		["Tulsa","OK",36.12,-95.91,23873676],
		["Napa","CA",38.23,-122.29,23877131],
		["Oxford","NC",36.31,-78.58,23880584],
		["Philadelphia","PA",39.95,-75.16,23884035],
		["Miami","FL",25.77,-80.2,23887486],
		["Lees Summit","MO",38.91,-94.38,23890937],
		["Renton","WA",47.51,-122.18,23894388],
		["Grand Rapids","MI",42.96,-85.65,23897838],
		["Huntington Beach","CA",33.71,-118.04,23901288],
		["Laurel","MD",39.09,-76.85,23904737],
		["Philadelphia","PA",39.95,-75.16,23908185],
		["Lusby","MD",38.41,-76.45,23911633],
		["Dawsonville","GA",34.42,-84.11,23915081],
		["Salt Lake City","UT",40.7,-111.85,23918529],
		["Kirkland","WA",47.67,-122.18,23921977],
		["Montclair","NJ",40.82,-74.21,23925423],
		["Milwaukee","WI",43.06,-87.96,23928869],
		["Maplewood","NJ",40.73,-74.27,23932314],
		["Bismarck","ND",46.91,-100.75,23935759],
		["Clearwater","FL",27.97,-82.76,23939203],
		["Mcalester","OK",34.92,-95.76,23942646],
		["Cumming","GA",34.2,-84.13,23946087],
		["Deming","NM",32.26,-107.75,23949528],
		["Kaukauna","WI",44.27,-88.26,23952968],
		["Chantilly","VA",38.87,-77.4,23956407],
		["Hartselle","AL",34.43,-86.93,23959845],
		["Lees Summit","MO",38.91,-94.38,23963283],
		["Miami","FL",25.77,-80.2,23966719],
		["Davenport","IA",41.55,-90.6,23970155],
		["West Springfield","MA",42.12,-72.65,23973590],
		["Lima","OH",40.74,-84.11,23977025],
		["Roanoke","VA",37.27,-79.95,23980459],
		["Princeton","WV",37.36,-81.1,23983893],
		["Burlington","KY",39.02,-84.72,23987327],
		["Mercer Island","WA",47.56,-122.22,23990760],
		["Morrow","GA",33.57,-84.34,23994192],
		["Plymouth","IN",41.34,-86.31,23997624],
		["Dixon","CA",38.39,-121.81,24001056],
		["Theodore","AL",30.54,-88.17,24004487],
		["North Ridgeville","OH",41.38,-82.01,24007917],
		["Roselle","IL",41.98,-88.08,24011347],
		["Camp Hill","PA",40.24,-76.92,24014776],
		["Centralia","WA",46.74,-122.95,24018204],
		["Berea","KY",37.57,-84.29,24021631],
		["Ossining","NY",41.15,-73.87,24025057],
		["Boca Raton","FL",26.37,-80.1,24028482],
		["Milford","MA",42.14,-71.51,24031906],
		["Crawfordville","FL",30.17,-84.37,24035329],
		["Gaithersburg","MD",39.14,-77.21,24038751],
		["Washington","NC",35.55,-77.05,24042173],
		["Buffalo","MN",45.17,-93.86,24045595],
		["Siloam Springs","AR",36.18,-94.54,24049017],
		["Macomb","IL",40.47,-90.68,24052438],
		["Louisburg","NC",36.1,-78.29,24055858],
		["Salt Lake City","UT",40.65,-111.88,24059278],
		["Middle Village","NY",40.71,-73.88,24062697],
		["Norwalk","OH",41.24,-82.61,24066116],
		["Lynnwood","WA",47.85,-122.28,24069534],
		["Winter Haven","FL",28.04,-81.72,24072951],
		["Alexandria","LA",31.29,-92.46,24076368],
		["Long Beach","CA",33.75,-118.2,24079785],
		["Albany","NY",42.66,-73.79,24083201],
		["New Bern","NC",35.11,-77.07,24086617],
		["Odessa","FL",28.2,-82.59,24090032],
		["Oklahoma City","OK",35.46,-97.51,24093447],
		["Harrisonburg","VA",38.43,-78.87,24096861],
		["Fort Worth","TX",32.75,-97.33,24100275],
		["Paducah","KY",37.07,-88.63,24103688],
		["Lisle","IL",41.79,-88.08,24107101],
		["Roscoe","IL",42.41,-89,24110512],
		["Wellesley Hills","MA",42.31,-71.27,24113922],
		["Wichita","KS",37.68,-97.34,24117331],
		["Atlanta","GA",33.89,-84.25,24120739],
		["Sarasota","FL",27.33,-82.54,24124147],
		["Lexington","KY",38.04,-84.45,24127555],
		["Ada","MI",42.95,-85.48,24130963],
		["Portland","OR",45.55,-122.56,24134371],
		["Bradenton","FL",27.48,-82.57,24137778],
		["Saint Louis","MO",38.63,-90.24,24141185],
		["Bismarck","ND",46.8,-100.76,24144591],
		["Anchorage","AK",61.08,-149.7,24147997],
		["Manahawkin","NJ",39.69,-74.25,24151401],
		["New Paltz","NY",41.74,-74.08,24154805],
		["Matteson","IL",41.51,-87.73,24158209],
		["Beeville","TX",28.4,-97.74,24161613],
		["Anderson","SC",34.51,-82.64,24165015],
		["Caldwell","NJ",40.83,-74.27,24168416],
		["Chalfont","PA",40.28,-75.2,24171817],
		["Rosedale","MD",39.32,-76.5,24175218],
		["Dallas","TX",32.79,-96.76,24178619],
		["Jonesboro","AR",35.82,-90.68,24182019],
		["Albany","GA",31.57,-84.17,24185418],
		["Hartland","WI",43.09,-88.34,24188817],
		["Tampa","FL",27.95,-82.48,24192214],
		["Chanhassen","MN",44.85,-93.55,24195611],
		["West Plains","MO",36.74,-91.86,24199008],
		["Kannapolis","NC",35.49,-80.62,24202404],
		["Pasadena","CA",34.16,-118.08,24205800],
		["Overland Park","KS",38.9,-94.68,24209194],
		["Enid","OK",36.4,-97.86,24212588],
		["Reading","PA",40.33,-75.92,24215981],
		["Mooresville","IN",39.61,-86.37,24219374],
		["Port Jefferson Station","NY",40.92,-73.06,24222766],
		["Jonesborough","TN",36.29,-82.47,24226158],
		["Detroit","MI",42.38,-83.1,24229550],
		["Bainbridge Island","WA",47.64,-122.53,24232942],
		["Athens","TN",35.45,-84.6,24236333],
		["King George","VA",38.26,-77.18,24239722],
		["Hammond","IN",41.62,-87.49,24243111],
		["Chantilly","VA",38.87,-77.4,24246498],
		["Lehigh Acres","FL",26.6,-81.63,24249884],
		["Campbellsville","KY",37.34,-85.34,24253270],
		["Jackson","MS",32.32,-90.2,24256654],
		["Cleveland","OH",41.47,-81.67,24260038],
		["Jackson","MO",37.38,-89.65,24263421],
		["Reno","NV",39.38,-119.87,24266804],
		["Lamont","CA",35.25,-118.91,24270187],
		["Wyandotte","MI",42.21,-83.15,24273569],
		["Harleysville","PA",40.27,-75.38,24276950],
		["Oxnard","CA",34.19,-119.23,24280331],
		["Roma","TX",26.4,-99.01,24283711],
		["Indianapolis","IN",39.77,-86.14,24287090],
		["Georgetown","TX",30.64,-97.68,24290468],
		["Honolulu","HI",21.3,-157.85,24293845],
		["Richmond","VA",37.55,-77.46,24297220],
		["Knoxville","TN",35.97,-83.94,24300595],
		["Houston","TX",29.76,-95.38,24303969],
		["Eagle Mountain","UT",40.37,-112.02,24307342],
		["Lindsay","CA",36.2,-119.08,24310715],
		["Dunn","NC",35.31,-78.61,24314087],
		["Groton","CT",41.32,-72.07,24317458],
		["Whitehall","PA",40.63,-75.48,24320829],
		["Kingsport","TN",36.52,-82.55,24324199],
		["Yorkville","IL",41.64,-88.44,24327569],
		["Denver","CO",39.76,-104.87,24330939],
		["Nutley","NJ",40.81,-74.15,24334308],
		["Waterford","MI",42.66,-83.38,24337677],
		["Sebastopol","CA",38.4,-122.86,24341046],
		["Winston Salem","NC",36.1,-80.24,24344414],
		["Burbank","CA",34.17,-118.34,24347780],
		["Astoria","NY",40.78,-73.91,24351145],
		["Kerman","CA",36.71,-120.15,24354510],
		["Portland","OR",45.47,-122.64,24357875],
		["Bay City","TX",28.97,-95.96,24361239],
		["Piqua","OH",40.14,-84.25,24364602],
		["Fort Wayne","IN",41.07,-85.13,24367965],
		["Clarkston","MI",42.73,-83.42,24371328],
		["Tampa","FL",27.95,-82.48,24374690],
		["Danville","KY",37.64,-84.77,24378052],
		["Park Forest","IL",41.48,-87.68,24381414],
		["Rockford","IL",42.27,-89.06,24384776],
		["North Pole","AK",64.77,-147.33,24388138],
		["Moultrie","GA",31.16,-83.77,24391499],
		["West Fargo","ND",46.87,-96.89,24394860],
		["Bastrop","TX",30.11,-97.3,24398221],
		["Rockford","IL",42.27,-89.06,24401579],
		["Pocatello","ID",42.97,-112.36,24404937],
		["Madison Heights","MI",42.5,-83.1,24408294],
		["Wexford","PA",40.63,-80.05,24411650],
		["Winter Haven","FL",28.04,-81.72,24415006],
		["Troutdale","OR",45.52,-122.35,24418362],
		["Chelmsford","MA",42.59,-71.36,24421717],
		["Binghamton","NY",42.1,-75.91,24425072],
		["Birmingham","AL",33.52,-86.79,24428426],
		["Carson City","NV",39.14,-119.71,24431780],
		["Abilene","TX",32.45,-99.73,24435133],
		["Kahului","HI",20.87,-156.45,24438486],
		["Racine","WI",42.72,-87.8,24441838],
		["Grandview","MO",38.88,-94.52,24445190],
		["North Vernon","IN",39,-85.63,24448541],
		["Morehead","KY",38.19,-83.44,24451890],
		["Beaumont","TX",30.08,-94.14,24455239],
		["Midlothian","IL",41.62,-87.72,24458587],
		["San Jose","CA",37.31,-121.96,24461935],
		["Pittsburgh","PA",40.43,-79.97,24465282],
		["Shreveport","LA",32.47,-93.79,24468629],
		["Tullahoma","TN",35.37,-86.2,24471974],
		["Fall River","MA",41.71,-71.1,24475318],
		["Salisbury","NC",35.66,-80.48,24478662],
		["Indianapolis","IN",39.77,-86.14,24482006],
		["Vista","CA",33.18,-117.24,24485350],
		["Rocklin","CA",38.8,-121.24,24488694],
		["Hamden","CT",41.37,-72.9,24492036],
		["Sharpsburg","GA",33.34,-84.64,24495378],
		["Astoria","NY",40.78,-73.91,24498719],
		["Griffin","GA",33.24,-84.27,24502060],
		["Jacksonville","FL",30.33,-81.65,24505401],
		["Portsmouth","VA",36.83,-76.29,24508741],
		["Akron","OH",41.08,-81.52,24512081],
		["Helena","MT",46.76,-112.01,24515421],
		["Yucca Valley","CA",34.15,-116.41,24518761],
		["Dunedin","FL",28.02,-82.77,24522099],
		["Chardon","OH",41.57,-81.2,24525437],
		["Portland","ME",43.66,-70.25,24528774],
		["Erie","PA",42.12,-80.08,24532111],
		["Watertown","SD",44.91,-97.16,24535448],
		["Waltham","MA",42.38,-71.24,24538784],
		["Fairfax Station","VA",38.8,-77.32,24542120],
		["Willmar","MN",45.12,-95.04,24545456],
		["Monroe","GA",33.79,-83.71,24548791],
		["Frankfort","IN",40.28,-86.51,24552126],
		["Florence","SC",34.18,-79.78,24555460],
		["Batesville","AR",35.76,-91.62,24558794],
		["Glen Burnie","MD",39.16,-76.6,24562127],
		["Raleigh","NC",35.82,-78.65,24565460],
		["Cedartown","GA",34.01,-85.25,24568793],
		["Los Gatos","CA",37.23,-121.95,24572126],
		["Paris","TX",33.66,-95.54,24575458],
		["Crestview","FL",30.75,-86.57,24578789],
		["Sulphur Springs","TX",33.14,-95.61,24582120],
		["Cambridge","MA",42.37,-71.11,24585450],
		["Vincentown","NJ",39.8,-74.62,24588776],
		["Machesney Park","IL",42.36,-89.03,24592102],
		["Reading","MA",42.53,-71.1,24595427],
		["Chevy Chase","MD",38.98,-77.08,24598752],
		["Muncie","IN",40.19,-85.39,24602077],
		["Pittsburgh","PA",40.43,-79.97,24605401],
		["Bristol","RI",41.67,-71.27,24608724],
		["Mccomb","MS",31.24,-90.46,24612047],
		["Amarillo","TX",35.2,-101.81,24615370],
		["Walnut Creek","CA",37.91,-122.01,24618693],
		["Salem","OH",40.9,-80.85,24622014],
		["Circleville","OH",39.6,-82.93,24625334],
		["Trenton","NJ",40.22,-74.76,24628653],
		["Spring","TX",30.06,-95.38,24631972],
		["Stoughton","MA",42.11,-71.1,24635290],
		["Thomaston","GA",32.89,-84.32,24638608],
		["Clearwater","FL",27.97,-82.76,24641925],
		["Milwaukee","WI",43.06,-87.96,24645242],
		["New Orleans","LA",30.06,-89.93,24648559],
		["Charleston","SC",32.78,-79.99,24651875],
		["Hillsboro","OH",39.2,-83.61,24655190],
		["Montgomery","TX",30.39,-95.69,24658505],
		["Bradenton","FL",27.48,-82.57,24661819],
		["Cincinnati","OH",39.09,-84.51,24665133],
		["Kennett Square","PA",39.84,-75.71,24668446],
		["Evanston","IL",42.04,-87.68,24671758],
		["Wesley Chapel","FL",28.23,-82.17,24675069],
		["Nazareth","PA",40.73,-75.31,24678379],
		["Lexington Park","MD",38.26,-76.44,24681688],
		["Henderson","NV",36,-115.04,24684997],
		["Sun Valley","NV",39.59,-119.77,24688306],
		["Soledad","CA",36.39,-121.3,24691615],
		["Travelers Rest","SC",34.96,-82.43,24694923],
		["Phoenix","AZ",33.69,-112,24698230],
		["Mulberry","FL",27.9,-81.97,24701536],
		["Green Bay","WI",44.52,-87.98,24704842],
		["Fairfield","CT",41.13,-73.28,24708147],
		["Charleston","SC",32.78,-79.99,24711451],
		["North Miami Beach","FL",25.77,-80.2,24714755],
		["Louisville","KY",38.22,-85.74,24718059],
		["Saint Louis","MO",38.63,-90.24,24721363],
		["Longview","TX",32.51,-94.76,24724667],
		["Seattle","WA",47.49,-122.36,24727969],
		["Kansas City","MO",39.09,-94.58,24731270],
		["Belen","NM",34.65,-106.77,24734571],
		["Alexandria","VA",38.82,-77.08,24737871],
		["Benton","AR",34.61,-92.65,24741171],
		["Forest","VA",37.37,-79.27,24744470],
		["Hereford","TX",34.82,-102.39,24747769],
		["Great Falls","VA",39.01,-77.28,24751067],
		["Menasha","WI",44.21,-88.43,24754364],
		["Glendale","CA",34.16,-118.2,24757661],
		["Saint Paul","MN",44.97,-93.08,24760957],
		["Sandy","UT",40.57,-111.89,24764253],
		["Issaquah","WA",47.5,-122,24767548],
		["Wilmington","MA",42.55,-71.16,24770842],
		["Cheney","WA",47.43,-117.62,24774136],
		["Norton","MA",41.96,-71.18,24777429],
		["Pinson","AL",33.72,-86.65,24780721],
		["Avon Lake","OH",41.49,-82.01,24784013],
		["American Canyon","CA",38.18,-122.25,24787305],
		["Hercules","CA",38.01,-122.25,24790597],
		["East Haven","CT",41.29,-72.86,24793888],
		["Bastrop","LA",32.77,-91.9,24797179],
		["North Charleston","SC",32.78,-79.99,24800469],
		["Peru","IN",40.75,-86.06,24803759],
		["Carthage","MO",37.16,-94.31,24807049],
		["Fernandina Beach","FL",30.65,-81.44,24810337],
		["Saint Petersburg","FL",27.75,-82.64,24813625],
		["Lakewood","CA",33.84,-118.07,24816913],
		["Mill Valley","CA",37.89,-122.56,24820201],
		["Sudbury","MA",42.36,-71.4,24823488],
		["Fort Myers","FL",26.47,-81.8,24826774],
		["Des Moines","IA",41.57,-93.61,24830060],
		["Evergreen","CO",39.63,-105.34,24833346],
		["Tucson","AZ",32.24,-110.92,24836632],
		["Cleveland","OH",41.47,-81.67,24839916],
		["Sachse","TX",32.91,-96.62,24843200],
		["Goodyear","AZ",33.47,-112.39,24846484],
		["Kuna","ID",43.43,-116.32,24849767],
		["Indio","CA",33.75,-116.23,24853050],
		["Johnston","RI",41.81,-71.46,24856332],
		["Pittsburgh","PA",40.43,-79.97,24859614],
		["Rockville","MD",39.08,-77.15,24862895],
		["Norfolk","VA",36.84,-76.28,24866176],
		["New Windsor","NY",41.47,-74.02,24869456],
		["Youngstown","OH",41.09,-80.64,24872735],
		["Saint Paul","MN",44.94,-93.1,24876014],
		["College Park","MD",38.99,-76.93,24879292],
		["Burbank","CA",34.2,-118.3,24882570],
		["Lakewood","WA",47.15,-122.56,24885848],
		["Ville Platte","LA",30.69,-92.28,24889125],
		["Houston","TX",29.76,-95.38,24892402],
		["Annapolis","MD",38.97,-76.5,24895678],
		["Bixby","OK",35.94,-95.87,24898954],
		["Fall River","MA",41.71,-71.1,24902229],
		["Saginaw","MI",43.41,-83.94,24905504],
		["Greenwich","CT",41.06,-73.63,24908778],
		["Allison Park","PA",40.57,-79.95,24912050],
		["Philadelphia","PA",39.95,-75.16,24915322],
		["Beltsville","MD",39.03,-76.92,24918594],
		["Milwaukee","WI",43.06,-87.96,24921866],
		["Sioux City","IA",42.5,-96.39,24925137],
		["Puyallup","WA",47.14,-122.32,24928408],
		["El Paso","TX",31.84,-106.43,24931677],
		["Casper","WY",42.83,-106.32,24934946],
		["West Hartford","CT",41.75,-72.74,24938214],
		["Coram","NY",40.87,-73,24941482],
		["Gainesville","GA",34.29,-83.83,24944748],
		["Warren","OH",41.23,-80.81,24948014],
		["Mountain View","CA",37.38,-122.08,24951280],
		["Feasterville Trevose","PA",40.14,-74.98,24954544],
		["Bartlesville","OK",36.73,-95.94,24957807],
		["Taylorsville","NC",35.92,-81.17,24961069],
		["Wichita","KS",37.68,-97.34,24964331],
		["Miami","FL",25.77,-80.2,24967592],
		["Farmington","MI",42.46,-83.37,24970853],
		["Biddeford","ME",43.46,-70.44,24974113],
		["Rincon","GA",32.29,-81.23,24977373],
		["Lakeland","FL",28.04,-81.96,24980633],
		["Harvest","AL",34.85,-86.74,24983892],
		["Port Hueneme","CA",34.11,-119.11,24987151],
		["Danvers","MA",42.57,-70.95,24990409],
		["Wilmington","NC",34.21,-77.91,24993667],
		["Forest Lake","MN",45.27,-92.98,24996925],
		["Gloucester","MA",42.62,-70.66,25000182],
		["Vancouver","WA",45.67,-122.66,25003439],
		["Rigby","ID",43.69,-111.87,25006695],
		["Scotch Plains","NJ",40.63,-74.37,25009949],
		["Atlanta","GA",33.86,-84.42,25013203],
		["New Caney","TX",30.15,-95.18,25016457],
		["Woodinville","WA",47.75,-122.09,25019711],
		["Utica","MI",42.62,-83.02,25022964],
		["Houston","TX",29.76,-95.38,25026217],
		["Irvine","CA",33.68,-117.85,25029469],
		["San Jose","CA",37.37,-121.86,25032719],
		["Radcliff","KY",37.82,-85.94,25035968],
		["Plano","TX",33.04,-96.74,25039217],
		["Odessa","TX",31.85,-102.36,25042466],
		["New Orleans","LA",30.06,-89.93,25045714],
		["Fairfax","VA",38.85,-77.29,25048961],
		["Saint Paul","MN",44.94,-93.1,25052208],
		["Shreveport","LA",32.47,-93.79,25055455],
		["San Mateo","CA",37.55,-122.26,25058702],
		["Salt Lake City","UT",40.7,-111.71,25061948],
		["Toms River","NJ",39.95,-74.18,25065193],
		["Cincinnati","OH",39.09,-84.51,25068438],
		["Grand Junction","CO",39.08,-108.55,25071683],
		["Littleton","CO",39.59,-105.01,25074926],
		["Roanoke","VA",37.27,-79.95,25078168],
		["Joliet","IL",41.52,-88.12,25081409],
		["Jackson","MS",32.32,-90.2,25084649],
		["East Hartford","CT",41.76,-72.61,25087888],
		["Erie","PA",42.12,-80.08,25091127],
		["Syracuse","NY",43.04,-76.14,25094365],
		["Columbus","GA",32.51,-84.87,25097602],
		["Cape Coral","FL",26.63,-81.99,25100838],
		["Dayton","OH",39.77,-84.19,25104074],
		["Arnold","MD",39.04,-76.49,25107309],
		["Zebulon","NC",35.82,-78.31,25110544],
		["Brookfield","WI",43.06,-88.11,25113779],
		["Oakland","CA",37.83,-122.22,25117014],
		["Charlotte","NC",35.19,-80.83,25120248],
		["Petal","MS",31.34,-89.25,25123481],
		["Lathrop","CA",37.82,-121.29,25126714],
		["Northport","NY",40.9,-73.34,25129946],
		["Los Angeles","CA",34.05,-118.26,25133178],
		["Skokie","IL",42.03,-87.73,25136409],
		["Kearney","NE",40.7,-99.08,25139640],
		["Kingman","AZ",35.24,-114.01,25142871],
		["Haledon","NJ",40.93,-74.18,25146101],
		["Massapequa Park","NY",40.68,-73.44,25149331],
		["Green Bay","WI",44.52,-87.98,25152561],
		["San Carlos","CA",37.49,-122.26,25155791],
		["Wilton","CT",41.18,-73.42,25159020],
		["Jefferson","GA",34.13,-83.59,25162249],
		["New Port Richey","FL",28.24,-82.71,25165478],
		["Marshfield","WI",44.66,-90.17,25168706],
		["Tujunga","CA",34.29,-118.25,25171934],
		["Cuyahoga Falls","OH",41.16,-81.52,25175160],
		["Denton","TX",33.23,-97.11,25178386],
		["Baton Rouge","LA",30.44,-91.12,25181611],
		["Monroe","CT",41.36,-73.2,25184835],
		["Portland","TN",36.58,-86.51,25188059],
		["Northville","MI",42.4,-83.6,25191282],
		["Sugar Land","TX",29.61,-95.61,25194503],
		["Las Cruces","NM",32.33,-106.75,25197724],
		["Kingsland","GA",30.79,-81.66,25200944],
		["Bloomington","IN",39.16,-86.52,25204164],
		["Homewood","IL",41.55,-87.66,25207384],
		["Huntsville","TX",30.71,-95.54,25210604],
		["Coos Bay","OR",43.37,-124.14,25213824],
		["Irving","TX",32.85,-96.96,25217043],
		["Midland","TX",32.02,-102.1,25220258],
		["Greencastle","IN",39.64,-86.84,25223472],
		["Lawton","OK",34.6,-98.42,25226684],
		["Schenectady","NY",42.8,-73.92,25229895],
		["Columbia","SC",34,-81.03,25233106],
		["Lawrenceburg","IN",39.1,-84.86,25236317],
		["Little Rock","AR",34.72,-92.35,25239528],
		["Macungie","PA",40.51,-75.55,25242738],
		["Springfield","PA",39.93,-75.34,25245948],
		["Arlington","TX",32.69,-97.12,25249157],
		["Lillington","NC",35.39,-78.81,25252365],
		["Austin","TX",30.26,-97.74,25255573],
		["Rosamond","CA",34.92,-118.4,25258781],
		["Tobyhanna","PA",41.18,-75.43,25261988],
		["Lexington","KY",38.04,-84.45,25265195],
		["Minot","ND",48.23,-101.29,25268402],
		["Los Fresnos","TX",26.07,-97.47,25271609],
		["Falls Church","VA",38.88,-77.17,25274815],
		["Las Vegas","NV",35.97,-115.21,25278020],
		["Longwood","FL",28.7,-81.34,25281224],
		["Malvern","AR",34.37,-92.81,25284428],
		["Los Altos","CA",37.34,-122.11,25287632],
		["Belmont","MA",42.39,-71.18,25290835],
		["Boiling Springs","SC",34.94,-81.92,25294038],
		["Suffern","NY",41.11,-74.14,25297240],
		["Seabrook","TX",29.56,-95.02,25300442],
		["Spokane","WA",47.69,-117.19,25303644],
		["Guayanilla","PR",18.02,-66.79,25306845],
		["Cordele","GA",31.96,-83.77,25310046],
		["Rockford","IL",42.27,-89.06,25313246],
		["Mesa","AZ",33.39,-111.71,25316446],
		["Dayton","OH",39.77,-84.19,25319645],
		["Enumclaw","WA",47.1,-121.6,25322844],
		["Harrisburg","PA",40.27,-76.88,25326041],
		["Dover","PA",40,-76.84,25329238],
		["Carlsbad","CA",33.13,-117.3,25332435],
		["Laurel","MD",39.09,-76.85,25335630],
		["Maryville","TN",35.74,-83.97,25338825],
		["Nashville","TN",36.17,-86.78,25342019],
		["Luquillo","PR",18.37,-65.71,25345212],
		["Bowling Green","OH",41.37,-83.64,25348405],
		["Slidell","LA",30.28,-89.77,25351598],
		["Sonora","CA",37.98,-120.39,25354791],
		["Seattle","WA",47.71,-122.3,25357984],
		["Carrollton","TX",32.98,-96.89,25361176],
		["Nashua","NH",42.74,-71.49,25364367],
		["Brooklyn","NY",40.64,-73.94,25367557],
		["Bayside","NY",40.76,-73.77,25370747],
		["Madisonville","KY",37.33,-87.5,25373937],
		["Columbia","SC",34,-81.03,25377126],
		["Flat Rock","MI",42.1,-83.27,25380315],
		["Chesapeake","VA",36.67,-76.3,25383503],
		["Indianapolis","IN",39.77,-86.14,25386690],
		["Pinckney","MI",42.45,-83.94,25389877],
		["Clarkston","GA",33.81,-84.24,25393063],
		["Pinellas Park","FL",27.85,-82.7,25396248],
		["Everett","WA",47.98,-122.2,25399432],
		["Warren","MI",42.49,-83.02,25402615],
		["Midvale","UT",40.6,-111.88,25405798],
		["Imperial","CA",32.86,-115.65,25408980],
		["Dover","NH",43.19,-70.87,25412161],
		["Midland","TX",32.02,-102.1,25415342],
		["Dorchester","MA",42.35,-71.06,25418522],
		["Memphis","TN",35.1,-90,25421702],
		["Thomasville","GA",30.83,-83.97,25424880],
		["Salisbury","NC",35.66,-80.48,25428057],
		["Naples","FL",26.14,-81.79,25431234],
		["Enid","OK",36.4,-97.86,25434411],
		["Havelock","NC",34.9,-76.89,25437587],
		["Parsippany","NJ",40.85,-74.4,25440762],
		["Cortlandt Manor","NY",41.3,-73.89,25443937],
		["San Antonio","TX",29.45,-98.5,25447112],
		["Walterboro","SC",32.9,-80.67,25450286],
		["Cleveland","GA",34.59,-83.76,25453460],
		["Russellville","AR",35.27,-93.14,25456634],
		["Rocky Mount","NC",35.95,-77.81,25459807],
		["Fayetteville","AR",36.07,-94.15,25462980],
		["Bessemer","AL",33.43,-86.99,25466151],
		["Saint Louis","MO",38.63,-90.24,25469322],
		["Wethersfield","CT",41.7,-72.67,25472492],
		["Harrisburg","PA",40.27,-76.88,25475662],
		["Aubrey","TX",33.3,-96.98,25478832],
		["Idaho Falls","ID",43.42,-112,25482002],
		["Auburn","AL",32.59,-85.48,25485171],
		["Rochester","MN",44.01,-92.47,25488340],
		["Walker","LA",30.48,-90.86,25491509],
		["Houston","TX",29.76,-95.38,25494678],
		["Clinton","TN",36.09,-84.13,25497846],
		["Asheville","NC",35.57,-82.54,25501013],
		["New Philadelphia","OH",40.48,-81.44,25504180],
		["Salinas","CA",36.76,-121.65,25507347],
		["Greenfield","CA",36.26,-121.37,25510514],
		["Arcata","CA",40.85,-124.05,25513680],
		["Clewiston","FL",26.75,-80.93,25516845],
		["Washington","IL",40.69,-89.43,25520010],
		["Norfolk","VA",36.84,-76.28,25523174],
		["Sussex","NJ",41.2,-74.6,25526337],
		["Point Pleasant Beach","NJ",40.09,-74.04,25529499],
		["East Saint Louis","IL",38.61,-90.13,25532661],
		["Pueblo","CO",38.27,-104.62,25535823],
		["Ann Arbor","MI",42.27,-83.73,25538984],
		["Raymore","MO",38.81,-94.45,25542145],
		["San Antonio","TX",29.45,-98.5,25545306],
		["San Francisco","CA",37.77,-122.49,25548467],
		["Mount Morris","MI",43.11,-83.69,25551627],
		["Marion","IL",37.73,-88.94,25554787],
		["Los Angeles","CA",34.12,-118.28,25557947],
		["Santa Rosa","CA",38.45,-122.59,25561107],
		["Needham","MA",42.28,-71.24,25564265],
		["Wesley Chapel","FL",28.23,-82.17,25567423],
		["Temperance","MI",41.76,-83.57,25570581],
		["Hays","KS",38.87,-99.31,25573739],
		["Oroville","CA",39.5,-121.67,25576896],
		["Providence","RI",41.82,-71.41,25580052],
		["Charleston","SC",32.78,-79.99,25583208],
		["Morgan City","LA",29.7,-91.19,25586363],
		["Laramie","WY",41.31,-105.58,25589518],
		["Pine Bluff","AR",34.21,-92.01,25592671],
		["Madison","CT",41.27,-72.59,25595823],
		["Madison","NJ",40.75,-74.41,25598975],
		["Hillsborough","NC",36.07,-79.1,25602127],
		["Hugo","MN",45.15,-92.96,25605277],
		["Winchester","MA",42.45,-71.14,25608426],
		["Monroeville","PA",40.42,-79.76,25611575],
		["Seaford","DE",38.64,-75.61,25614724],
		["Portales","NM",34.18,-103.34,25617873],
		["Alexandria","VA",38.82,-77.08,25621021],
		["Arlington Heights","IL",42.09,-87.98,25624169],
		["East Amherst","NY",43.04,-78.7,25627316],
		["Bowie","MD",38.95,-76.73,25630463],
		["Mobile","AL",30.68,-88.04,25633610],
		["Bowling Green","OH",41.37,-83.64,25636757],
		["Caledonia","MI",42.79,-85.51,25639904],
		["Aurora","IL",41.77,-88.25,25643051],
		["Omaha","NE",41.26,-96.01,25646198],
		["Franklin","NC",35.18,-83.38,25649344],
		["Montgomery","AL",32.35,-86.28,25652490],
		["Dover","NJ",40.88,-74.55,25655635],
		["Bismarck","ND",46.8,-100.76,25658780],
		["Monterey","CA",36.6,-121.88,25661923],
		["Slippery Rock","PA",41.07,-80.06,25665065],
		["Okeechobee","FL",27.24,-80.82,25668207],
		["Chicopee","MA",42.17,-72.57,25671348],
		["Englewood","NJ",40.89,-73.97,25674489],
		["Reston","VA",38.95,-77.34,25677630],
		["Gulfport","MS",30.38,-89.06,25680771],
		["Mayfield","KY",36.73,-88.64,25683912],
		["Mukilteo","WA",47.91,-122.3,25687053],
		["Winterville","NC",35.52,-77.39,25690193],
		["Elgin","SC",34.16,-80.79,25693333],
		["Kansas City","MO",39.09,-94.58,25696470],
		["Elon","NC",36.09,-79.51,25699606],
		["Homestead","FL",25.46,-80.45,25702742],
		["West Warwick","RI",41.69,-71.51,25705877],
		["Columbus","OH",39.98,-82.98,25709012],
		["Battle Creek","MI",42.32,-85.26,25712147],
		["Warwick","NY",41.25,-74.35,25715281],
		["Columbia","SC",34,-81.03,25718415],
		["Easton","PA",40.68,-75.22,25721548],
		["Greenville","SC",34.83,-82.37,25724680],
		["Savannah","GA",32.08,-81.1,25727812],
		["Wausau","WI",44.96,-89.64,25730944],
		["Labelle","FL",26.64,-81.34,25734075],
		["Greeneville","TN",36.16,-82.81,25737206],
		["Burlington","VT",44.48,-73.22,25740336],
		["Avon","OH",41.45,-82.02,25743465],
		["North Haven","CT",41.38,-72.85,25746593],
		["Springfield","VA",38.78,-77.17,25749721],
		["Hummelstown","PA",40.26,-76.71,25752848],
		["Toledo","OH",41.66,-83.58,25755975],
		["Warwick","RI",41.7,-71.42,25759101],
		["Jacksonville","FL",30.33,-81.65,25762227],
		["Connersville","IN",39.65,-85.14,25765353],
		["Roanoke","VA",37.27,-79.95,25768478],
		["Byron Center","MI",42.81,-85.72,25771603],
		["Riverside","CA",33.99,-117.38,25774728],
		["Newark","DE",39.67,-75.75,25777852],
		["Indianapolis","IN",39.77,-86.14,25780976],
		["Chicago","IL",41.83,-87.68,25784100],
		["Durant","OK",33.98,-96.39,25787224],
		["Hingham","MA",42.23,-70.88,25790347],
		["Fort Pierce","FL",27.44,-80.32,25793470],
		["Indianapolis","IN",39.77,-86.14,25796593],
		["East Liverpool","OH",40.63,-80.56,25799714],
		["Loves Park","IL",42.33,-89.01,25802835],
		["Lees Summit","MO",38.91,-94.38,25805956],
		["Lincoln","NE",40.81,-96.68,25809077],
		["Dallas","TX",32.79,-96.76,25812197],
		["Anderson","CA",40.44,-122.35,25815317],
		["Pacific Palisades","CA",34.07,-118.54,25818436],
		["North Kingstown","RI",41.55,-71.46,25821554],
		["Amherst","OH",41.4,-82.23,25824672],
		["Middleboro","MA",41.86,-70.9,25827789],
		["Gulf Breeze","FL",30.65,-86.88,25830906],
		["Oviedo","FL",28.65,-81.18,25834023],
		["Kingman","AZ",35.24,-113.76,25837140],
		["South Plainfield","NJ",40.57,-74.41,25840256],
		["Winter Park","FL",28.59,-81.34,25843372],
		["Bellwood","IL",41.88,-87.87,25846488],
		["Sikeston","MO",36.88,-89.58,25849604],
		["Shawnee","OK",35.33,-96.91,25852720],
		["Othello","WA",46.8,-119.15,25855835],
		["Austin","TX",30.26,-97.74,25858949],
		["Grove City","PA",41.15,-80.08,25862062],
		["Novi","MI",42.47,-83.49,25865175],
		["Seattle","WA",47.49,-122.25,25868288],
		["Phenix City","AL",32.46,-85.01,25871400],
		["Stillwater","OK",36.13,-97.07,25874512],
		["San Rafael","CA",38.02,-122.54,25877624],
		["Miami Beach","FL",25.77,-80.2,25880735],
		["Minneapolis","MN",44.96,-93.26,25883846],
		["Fulton","MO",38.85,-91.95,25886957],
		["Scottsdale","AZ",33.68,-111.73,25890068],
		["Norwood","MA",42.18,-71.19,25893178],
		["Astoria","NY",40.78,-73.91,25896288],
		["Buffalo","NY",42.88,-78.85,25899398],
		["Swartz Creek","MI",42.96,-83.82,25902508],
		["Mustang","OK",35.39,-97.72,25905618],
		["Troy","MI",42.58,-83.14,25908727],
		["West Monroe","LA",32.51,-92.14,25911836],
		["Gilbert","AZ",33.24,-111.7,25914945],
		["Hammonton","NJ",39.65,-74.77,25918053],
		["Glen Cove","NY",40.86,-73.63,25921161],
		["Kannapolis","NC",35.49,-80.62,25924268],
		["Wilmington","OH",39.44,-83.83,25927374],
		["Battle Creek","MI",42.29,-85.22,25930479],
		["Muskegon","MI",43.22,-86.25,25933583],
		["Washington","MO",38.55,-91.01,25936686],
		["Jerome","ID",42.75,-114.36,25939788],
		["Raleigh","NC",35.82,-78.65,25942889],
		["Fullerton","CA",33.9,-117.91,25945990],
		["Tacoma","WA",47.21,-122.47,25949090],
		["Wichita","KS",37.68,-97.34,25952189],
		["Baker","LA",30.58,-91.15,25955288],
		["Anaheim","CA",33.84,-117.73,25958385],
		["Pittsburgh","PA",40.43,-79.97,25961481],
		["Milton","FL",30.63,-87.04,25964577],
		["Saint Petersburg","FL",27.75,-82.64,25967673],
		["Nashville","TN",36.17,-86.78,25970769],
		["Memphis","TN",35.1,-90,25973865],
		["San Antonio","TX",29.45,-98.5,25976960],
		["Canfield","OH",41.02,-80.76,25980054],
		["Onalaska","WI",43.88,-91.21,25983148],
		["Concord","CA",37.95,-122.02,25986241],
		["Albany","NY",42.66,-73.79,25989333],
		["Athens","AL",34.78,-86.95,25992425],
		["Lake Elsinore","CA",33.71,-117.34,25995517],
		["Lake Oswego","OR",45.41,-122.72,25998609],
		["Indianola","IA",41.36,-93.56,26001700],
		["Houston","TX",29.76,-95.38,26004791],
		["Stanwood","WA",48.21,-122.28,26007882],
		["Cincinnati","OH",39.09,-84.51,26010972],
		["Janesville","WI",42.68,-89.01,26014062],
		["Lithia Springs","GA",33.77,-84.64,26017151],
		["Pewaukee","WI",43.08,-88.25,26020240],
		["Irvine","CA",33.63,-117.81,26023329],
		["Guilford","CT",41.28,-72.67,26026417],
		["Franklin Square","NY",40.7,-73.67,26029505],
		["Leland","NC",34.24,-78,26032593],
		["Big Lake","MN",45.33,-93.74,26035681],
		["Farmington","MI",42.46,-83.37,26038768],
		["East Peoria","IL",40.67,-89.54,26041855],
		["Philadelphia","PA",39.95,-75.16,26044941],
		["Minnetonka","MN",44.93,-93.46,26048027],
		["Canon City","CO",38.44,-105.23,26051113],
		["Greenbelt","MD",38.99,-76.88,26054198],
		["Cleveland","OH",41.47,-81.67,26057283],
		["Mishawaka","IN",41.66,-86.16,26060368],
		["Bridgeport","CT",41.18,-73.19,26063452],
		["Charlotte","NC",35.19,-80.83,26066536],
		["Port Orange","FL",29.19,-81.05,26069619],
		["Twinsburg","OH",41.32,-81.45,26072702],
		["Phelan","CA",34.42,-117.48,26075785],
		["Ridgeland","MS",32.42,-90.13,26078867],
		["Versailles","KY",38.05,-84.72,26081949],
		["Washougal","WA",45.61,-122.23,26085030],
		["Crescent City","CA",41.78,-124.16,26088110],
		["Klamath Falls","OR",42.31,-122.07,26091190],
		["Seaford","NY",40.66,-73.49,26094269],
		["Las Vegas","NM",35.59,-105.22,26097348],
		["San Jacinto","CA",33.81,-117.02,26100427],
		["Grand Rapids","MI",42.97,-85.77,26103505],
		["Ottawa","IL",41.35,-88.83,26106583],
		["Oklahoma City","OK",35.46,-97.51,26109661],
		["Ashland","OR",42.15,-122.53,26112739],
		["Vernon Rockville","CT",41.84,-72.45,26115816],
		["Plant City","FL",28,-82.12,26118893],
		["Saugus","MA",42.46,-71.01,26121969],
		["Pittsburgh","PA",40.43,-79.97,26125045],
		["Atlanta","GA",33.88,-84.37,26128121],
		["Waterford","MI",42.66,-83.38,26131197],
		["Worcester","MA",42.26,-71.8,26134271],
		["Lexington","KY",38.04,-84.45,26137345],
		["Lexington","KY",38.04,-84.45,26140418],
		["Arroyo","PR",17.97,-66.06,26143490],
		["Gloversville","NY",43.05,-74.34,26146562],
		["Buffalo","NY",42.88,-78.85,26149634],
		["Palm Beach Gardens","FL",26.74,-80.12,26152706],
		["Dallas","TX",32.79,-96.76,26155777],
		["Marlboro","NJ",40.31,-74.25,26158846],
		["Pittsburgh","PA",40.43,-79.97,26161915],
		["Columbus","OH",39.98,-82.98,26164984],
		["Geneva","NY",42.86,-76.98,26168052],
		["Columbia","SC",34,-81.03,26171120],
		["Boulder","CO",40.02,-105.25,26174188],
		["Birmingham","AL",33.52,-86.79,26177255],
		["Candler","NC",35.53,-82.69,26180321],
		["Laurel","MS",31.69,-89.14,26183387],
		["Columbia City","IN",41.15,-85.48,26186453],
		["Baytown","TX",29.76,-94.87,26189519],
		["Haymarket","VA",38.81,-77.63,26192584],
		["Norfolk","VA",36.84,-76.28,26195649],
		["Eden","NC",36.5,-79.74,26198714],
		["Omaha","NE",41.26,-96.01,26201779],
		["Altus","OK",34.64,-99.31,26204844],
		["Tacoma","WA",47.28,-122.38,26207909],
		["Ladera Ranch","CA",33.56,-117.63,26210973],
		["Gloucester","VA",37.43,-76.54,26214035],
		["Richmond","VA",37.55,-77.46,26217097],
		["Peoria","IL",40.74,-89.6,26220159],
		["Tucson","AZ",32.33,-110.98,26223221],
		["Sparta","TN",35.93,-85.47,26226282],
		["Watertown","WI",43.19,-88.72,26229343],
		["Mineral Wells","TX",32.82,-98.07,26232403],
		["Greenville","SC",34.83,-82.37,26235462],
		["Muncie","IN",40.19,-85.39,26238521],
		["Mount Vernon","IL",38.31,-88.91,26241580],
		["Kansas City","MO",39.09,-94.58,26244639],
		["El Sobrante","CA",37.95,-122.26,26247698],
		["Pembroke","NC",34.68,-79.19,26250756],
		["Lebanon","IN",40.05,-86.47,26253814],
		["Temple","TX",31.09,-97.36,26256872],
		["Redwood City","CA",37.4,-122.29,26259930],
		["Floresville","TX",29.13,-98.15,26262987],
		["Windsor","CO",40.48,-104.9,26266044],
		["Irvine","CA",33.7,-117.79,26269101],
		["Ames","IA",42.02,-93.62,26272157],
		["Henrico","VA",37.55,-77.46,26275211],
		["Cincinnati","OH",39.09,-84.51,26278265],
		["Russellville","AR",35.27,-93.14,26281319],
		["Choctaw","OK",35.48,-97.26,26284373],
		["Cedar Rapids","IA",41.97,-91.66,26287425],
		["Aston","PA",39.86,-75.43,26290476],
		["Pelham","AL",33.3,-86.79,26293527],
		["Sheridan","WY",44.79,-106.95,26296577],
		["Glassboro","NJ",39.7,-75.11,26299626],
		["Evergreen Park","IL",41.72,-87.7,26302675],
		["Bridgeport","CT",41.18,-73.19,26305722],
		["Fort Lee","NJ",40.85,-73.97,26308769],
		["Nacogdoches","TX",31.61,-94.65,26311816],
		["Lumberton","TX",30.26,-94.2,26314863],
		["Melrose","MA",42.45,-71.05,26317909],
		["Decatur","GA",33.77,-84.29,26320955],
		["Cleveland","TX",30.34,-95.07,26324001],
		["Tampa","FL",27.95,-82.48,26327046],
		["Argyle","TX",33.11,-97.17,26330090],
		["Dayton","OH",39.77,-84.19,26333133],
		["Rocky Mount","NC",35.95,-77.81,26336175],
		["Niles","IL",42.02,-87.8,26339217],
		["Norcross","GA",33.94,-84.2,26342258],
		["Duncanville","TX",32.64,-96.91,26345299],
		["Phoenix","AZ",33.73,-112.14,26348340],
		["Pawtucket","RI",41.87,-71.37,26351380],
		["Farmington","UT",40.99,-111.86,26354420],
		["Farmington","MO",37.78,-90.42,26357459],
		["Pennsauken","NJ",39.96,-75.05,26360497],
		["Branson","MO",36.64,-93.24,26363535],
		["Omaha","NE",41.26,-96.01,26366573],
		["San Jose","CA",37.38,-121.9,26369611],
		["Huntley","IL",42.16,-88.42,26372647],
		["Charleston","SC",32.78,-79.99,26375682],
		["Riverhead","NY",40.94,-72.67,26378716],
		["Sterling","IL",41.79,-89.69,26381750],
		["Leesville","LA",31.13,-93.27,26384784],
		["Marina","CA",36.67,-121.79,26387818],
		["New Haven","CT",41.31,-72.92,26390851],
		["Fort Lauderdale","FL",26.14,-80.13,26393884],
		["Highland","IN",41.54,-87.45,26396917],
		["Mukwonago","WI",42.86,-88.33,26399949],
		["Yonkers","NY",40.94,-73.86,26402980],
		["Rochester","NY",43.16,-77.61,26406011],
		["Pittsburgh","PA",40.43,-79.97,26409042],
		["Lewisburg","PA",40.96,-76.89,26412073],
		["Bellevue","NE",41.15,-95.92,26415103],
		["Pleasantville","NJ",39.39,-74.51,26418132],
		["Foley","AL",30.4,-87.68,26421160],
		["Ocala","FL",29.18,-82.13,26424187],
		["Jackson","MS",32.32,-90.2,26427214],
		["Dallas","TX",32.79,-96.76,26430241],
		["Tarrytown","NY",41.06,-73.86,26433267],
		["Opelika","AL",32.65,-85.37,26436293],
		["Topeka","KS",39.03,-95.69,26439319],
		["Alvarado","TX",32.41,-97.21,26442345],
		["Los Angeles","CA",34.08,-118.49,26445370],
		["Indianapolis","IN",39.77,-86.14,26448394],
		["Chicago","IL",41.83,-87.68,26451417],
		["Syracuse","NY",43.04,-76.14,26454439],
		["Reno","NV",39.38,-119.67,26457461],
		["Tucson","AZ",32.08,-111.11,26460482],
		["Valley Center","CA",33.25,-116.98,26463502],
		["Lawrenceburg","KY",38.03,-84.89,26466519],
		["Sycamore","IL",41.98,-88.69,26469536],
		["Rockaway","NJ",40.89,-74.51,26472552],
		["Syracuse","NY",43.04,-76.14,26475568],
		["Brighton","MI",42.53,-83.78,26478584],
		["Detroit","MI",42.38,-83.1,26481599],
		["Newton","KS",38.04,-97.34,26484614],
		["Grass Valley","CA",39.18,-120.98,26487629],
		["Ciales","PR",18.33,-66.47,26490643],
		["Saint Joseph","MI",42.03,-86.45,26493657],
		["Alsip","IL",41.67,-87.73,26496671],
		["Warsaw","IN",41.23,-85.85,26499684],
		["Country Club Hills","IL",41.56,-87.72,26502697],
		["Fort Collins","CO",40.55,-105.06,26505710],
		["Louisville","KY",38.22,-85.74,26508722],
		["Oklahoma City","OK",35.46,-97.51,26511734],
		["Beaver Dam","WI",43.46,-88.83,26514745],
		["Valley Stream","NY",40.66,-73.7,26517755],
		["Branford","CT",41.28,-72.81,26520764],
		["Conover","NC",35.7,-81.21,26523773],
		["Davenport","IA",41.55,-90.6,26526782],
		["Saint Joseph","MO",39.76,-94.85,26529790],
		["Novato","CA",38.1,-122.63,26532798],
		["Stephens City","VA",39.09,-78.22,26535805],
		["Racine","WI",42.72,-87.8,26538812],
		["Atlanta","GA",33.72,-84.32,26541818],
		["Red Lion","PA",39.89,-76.6,26544822],
		["Leesburg","FL",28.8,-81.88,26547826],
		["Puyallup","WA",47.22,-122.27,26550830],
		["Glen Mills","PA",39.89,-75.5,26553833],
		["Arverne","NY",40.59,-73.79,26556835],
		["Saint Louis","MO",38.63,-90.24,26559836],
		["Toccoa","GA",34.57,-83.32,26562835],
		["New Albany","OH",40.07,-82.8,26565834],
		["Whittier","CA",33.94,-117.99,26568833],
		["Adjuntas","PR",18.16,-66.72,26571831],
		["Dayton","OH",39.77,-84.19,26574829],
		["Montgomery","AL",32.35,-86.28,26577826],
		["Charlotte","MI",42.56,-84.83,26580823],
		["Mountain Home","ID",43.36,-115.52,26583820],
		["Brunswick","GA",31.15,-81.48,26586816],
		["Rochester","NY",43.16,-77.61,26589811],
		["Germantown","TN",35.08,-89.79,26592806],
		["Akron","OH",41.08,-81.52,26595801],
		["Sacramento","CA",38.55,-121.32,26598796],
		["Forest Hill","MD",39.58,-76.39,26601789],
		["Lake Charles","LA",30.21,-93.2,26604782],
		["Kaufman","TX",32.58,-96.3,26607775],
		["Oklahoma City","OK",35.46,-97.51,26610767],
		["Knoxville","TN",35.97,-83.94,26613758],
		["Lawrence","KS",38.96,-95.25,26616749],
		["Monterey Park","CA",34.05,-118.11,26619740],
		["Central Falls","RI",41.88,-71.39,26622730],
		["Staten Island","NY",40.58,-74.14,26625720],
		["Grand Island","NY",43.01,-78.96,26628710],
		["Smithfield","NC",35.5,-78.34,26631700],
		["Minneapolis","MN",44.96,-93.26,26634690],
		["Minneapolis","MN",44.96,-93.26,26637679],
		["Silver Spring","MD",39.01,-77.02,26640666],
		["Houston","TX",29.76,-95.38,26643653],
		["Parlier","CA",36.62,-119.51,26646640],
		["Jacksonville","FL",30.33,-81.65,26649626],
		["Tucson","AZ",32.43,-110.95,26652612],
		["Gaffney","SC",35.07,-81.65,26655597],
		["Warren","OH",41.23,-80.81,26658582],
		["Schererville","IN",41.48,-87.44,26661567],
		["Silver Spring","MD",39.01,-77.02,26664551],
		["Clayton","NC",35.63,-78.36,26667535],
		["Fort Campbell","KY",36.65,-87.54,26670519],
		["Bay Minette","AL",30.88,-87.77,26673502],
		["Alexandria","MN",45.87,-95.37,26676484],
		["Mountain Home","AR",36.33,-92.37,26679466],
		["Houston","TX",29.76,-95.38,26682448],
		["Cincinnati","OH",39.09,-84.51,26685429],
		["Olmsted Falls","OH",41.36,-81.9,26688409],
		["Dyer","IN",41.5,-87.5,26691389],
		["Redondo Beach","CA",33.83,-118.38,26694369],
		["Melbourne","FL",28.08,-80.6,26697348],
		["Prattville","AL",32.46,-86.44,26700326],
		["Smyrna","DE",39.29,-75.6,26703303],
		["Newport News","VA",37.07,-76.51,26706280],
		["Indianapolis","IN",39.77,-86.14,26709257],
		["Chambersburg","PA",39.93,-77.65,26712233],
		["Amelia","OH",39.02,-84.21,26715209],
		["Exeter","NH",42.97,-70.94,26718184],
		["Palm Desert","CA",33.7,-116.4,26721159],
		["Houston","TX",29.76,-95.38,26724133],
		["Camp Pendleton","CA",33.29,-117.3,26727107],
		["Darien","IL",41.74,-87.97,26730080],
		["Atlanta","GA",33.9,-84.3,26733052],
		["Tallahassee","FL",30.45,-84.28,26736022],
		["Saint Petersburg","FL",27.75,-82.64,26738992],
		["Laurel","MS",31.69,-89.14,26741962],
		["Middlefield","OH",41.45,-81.07,26744932],
		["Phoenix","AZ",33.68,-112.04,26747902],
		["Cartersville","GA",34.16,-84.8,26750871],
		["Middleton","WI",43.1,-89.5,26753839],
		["Lock Haven","PA",41.13,-77.45,26756806],
		["Conway","SC",33.83,-79.04,26759772],
		["Apache Junction","AZ",33.4,-111.56,26762738],
		["Rye","NY",40.98,-73.69,26765703],
		["Philadelphia","PA",39.95,-75.16,26768668],
		["Augusta","GA",33.45,-81.99,26771633],
		["Saginaw","MI",43.41,-83.94,26774598],
		["Overland Park","KS",38.9,-94.68,26777563],
		["Aberdeen","WA",46.88,-123.99,26780528],
		["Cleveland","OH",41.47,-81.67,26783492],
		["Wilmington","DE",39.73,-75.53,26786455],
		["Spotsylvania","VA",38.17,-77.7,26789418],
		["Cedar Rapids","IA",41.97,-91.66,26792381],
		["Comerio","PR",18.22,-66.22,26795343],
		["Santa Fe","NM",35.67,-105.95,26798305],
		["Columbus","GA",32.51,-84.87,26801266],
		["New Port Richey","FL",28.24,-82.71,26804227],
		["Maryville","MO",40.34,-94.87,26807187],
		["Chaparral","NM",32.04,-106.44,26810147],
		["Franklin","TN",35.92,-86.84,26813105],
		["Richardson","TX",32.97,-96.7,26816063],
		["Schenectady","NY",42.8,-73.92,26819020],
		["Austin","TX",30.26,-97.74,26821977],
		["Pontiac","MI",42.64,-83.28,26824933],
		["South Portland","ME",43.63,-70.28,26827888],
		["Clio","MI",43.17,-83.73,26830843],
		["Hillside","NJ",40.69,-74.23,26833797],
		["Gardner","KS",38.81,-94.92,26836750],
		["Mandan","ND",46.82,-100.88,26839702],
		["Saint Louis","MO",38.63,-90.24,26842654],
		["New Bedford","MA",41.66,-70.93,26845605],
		["Lakeland","FL",28.04,-81.96,26848556],
		["Vancleave","MS",30.4,-88.8,26851506],
		["Louisville","KY",38.22,-85.74,26854456],
		["West Bend","WI",43.42,-88.18,26857406],
		["Hammond","LA",30.5,-90.46,26860356],
		["San Gabriel","CA",34.11,-118.08,26863306],
		["Tustin","CA",33.75,-117.77,26866256],
		["Fort Myers","FL",26.63,-81.85,26869203],
		["Holt","MI",42.63,-84.52,26872150],
		["Saint Cloud","MN",45.55,-94.17,26875096],
		["Abilene","TX",32.45,-99.73,26878041],
		["Sharon","MA",42.11,-71.18,26880985],
		["Gorham","ME",43.68,-70.44,26883929],
		["Batavia","NY",42.99,-78.18,26886873],
		["Clarks Summit","PA",41.48,-75.7,26889817],
		["Dearborn Heights","MI",42.32,-83.27,26892761],
		["Brooklyn","NY",40.64,-73.94,26895703],
		["Annapolis","MD",39.02,-76.45,26898645],
		["Hallandale","FL",25.98,-80.14,26901587],
		["Urbana","OH",40.1,-83.75,26904529],
		["Oklahoma City","OK",35.46,-97.51,26907471],
		["Lancaster","TX",32.59,-96.78,26910413],
		["Nederland","TX",29.97,-93.99,26913353],
		["Amarillo","TX",35.2,-101.81,26916292],
		["San Diego","CA",32.8,-117.13,26919231],
		["Swedesboro","NJ",39.74,-75.31,26922169],
		["Aurora","OH",41.31,-81.34,26925107],
		["Grand Island","NE",40.92,-98.36,26928045],
		["Binghamton","NY",42.1,-75.91,26930982],
		["Southfield","MI",42.47,-83.25,26933919],
		["Platteville","WI",42.73,-90.47,26936856],
		["Carlsbad","CA",33.1,-117.29,26939793],
		["Fredonia","NY",42.44,-79.33,26942729],
		["Somerset","KY",37.08,-84.6,26945665],
		["Seattle","WA",47.61,-122.3,26948601],
		["Bethlehem","PA",40.62,-75.36,26951536],
		["Tarpon Springs","FL",28.14,-82.78,26954471],
		["Hutchinson","KS",38.06,-97.91,26957406],
		["Jackson","GA",33.29,-83.96,26960340],
		["Freeport","TX",28.95,-95.36,26963274],
		["Kalamazoo","MI",42.27,-85.58,26966207],
		["Heber City","UT",40.43,-111.19,26969140],
		["Satellite Beach","FL",28.17,-80.6,26972072],
		["Granbury","TX",32.44,-97.78,26975004],
		["Las Vegas","NV",36.16,-115.28,26977936],
		["Seneca","SC",34.68,-82.95,26980867],
		["Newberry","SC",34.27,-81.61,26983797],
		["Kansas City","MO",39.09,-94.58,26986727],
		["Haverhill","MA",42.78,-71.08,26989656],
		["Evansville","IN",37.98,-87.54,26992585],
		["Wichita","KS",37.68,-97.34,26995514],
		["Saint Marys","GA",30.75,-81.57,26998442],
		["La Grange","KY",38.4,-85.37,27001370],
		["Rockford","IL",42.27,-89.06,27004298],
		["Albuquerque","NM",35.11,-106.62,27007226],
		["Brunswick","ME",43.91,-69.96,27010153],
		["Brewster","NY",41.39,-73.61,27013080],
		["High Point","NC",35.98,-79.99,27016007],
		["Cameron","NC",35.32,-79.25,27018934],
		["Macon","GA",32.83,-83.65,27021861],
		["Chatham","NJ",40.74,-74.38,27024787],
		["Phenix City","AL",32.46,-85.01,27027713],
		["Cranford","NJ",40.65,-74.3,27030638],
		["Las Vegas","NV",35.9,-115.33,27033563],
		["Mckeesport","PA",40.33,-79.83,27036486],
		["Vienna","VA",38.9,-77.26,27039409],
		["North Charleston","SC",32.89,-80.05,27042332],
		["Mount Washington","KY",38.04,-85.55,27045255],
		["Patillas","PR",18,-66.01,27048177],
		["Burlington","VT",44.48,-73.22,27051099],
		["Williamsburg","KY",36.73,-84.16,27054020],
		["Mattoon","IL",39.47,-88.36,27056941],
		["College Point","NY",40.78,-73.84,27059861],
		["Conroe","TX",30.32,-95.47,27062781],
		["Minneapolis","MN",44.96,-93.26,27065700],
		["Germantown","TN",35.08,-89.79,27068618],
		["Pontotoc","MS",34.24,-89,27071535],
		["Westminster","MD",39.57,-77,27074451],
		["Duncanville","TX",32.64,-96.91,27077367],
		["Seymour","TN",35.87,-83.77,27080282],
		["Hartford","WI",43.32,-88.38,27083197],
		["North Little Rock","AR",34.78,-92.25,27086112],
		["Bellevue","WA",47.61,-122.21,27089027],
		["Washington Court House","OH",39.53,-83.43,27091941],
		["South Houston","TX",29.66,-95.22,27094854],
		["Fillmore","CA",34.4,-118.83,27097767],
		["Walled Lake","MI",42.53,-83.47,27100679],
		["Coldwater","MI",41.94,-85,27103591],
		["Van Nuys","CA",34.17,-118.46,27106503],
		["Avon Park","FL",27.59,-81.5,27109414],
		["Ames","IA",42.02,-93.62,27112325],
		["Milwaukee","WI",43.06,-87.96,27115235],
		["Hurricane","WV",38.43,-82.01,27118144],
		["Merritt Island","FL",28.32,-80.68,27121053],
		["Royal Oak","MI",42.5,-83.15,27123962],
		["Oshkosh","WI",44.01,-88.55,27126871],
		["Scottsdale","AZ",33.49,-111.92,27129780],
		["Clifton","NJ",40.86,-74.15,27132688],
		["Eureka","CA",40.71,-124.11,27135596],
		["Twentynine Palms","CA",34.24,-115.86,27138503],
		["Crestview","FL",30.75,-86.57,27141409],
		["Riverdale","MD",38.96,-76.92,27144314],
		["Gaston","SC",33.81,-81.1,27147219],
		["Blythe","CA",33.75,-114.51,27150124],
		["Aberdeen","MD",39.51,-76.17,27153027],
		["Gainesville","FL",29.67,-82.33,27155930],
		["Excelsior","MN",44.9,-93.56,27158833],
		["Taft","CA",35.17,-119.36,27161734],
		["Novi","MI",42.47,-83.49,27164633],
		["San Diego","CA",32.77,-117.18,27167532],
		["Albany","GA",31.57,-84.17,27170430],
		["Wendell","NC",35.78,-78.36,27173327],
		["Holly","MI",42.79,-83.62,27176224],
		["Denison","TX",33.75,-96.56,27179121],
		["Paradise","CA",39.74,-121.61,27182017],
		["Chagrin Falls","OH",41.43,-81.38,27184912],
		["Des Moines","IA",41.57,-93.61,27187807],
		["Nanuet","NY",41.09,-74.01,27190701],
		["Bozeman","MT",45.65,-111.17,27193595],
		["Oklahoma City","OK",35.46,-97.51,27196489],
		["Jayuya","PR",18.22,-66.59,27199382],
		["Tallahassee","FL",30.23,-84.3,27202275],
		["Lutz","FL",28.13,-82.46,27205168],
		["Tuscaloosa","AL",33.23,-87.54,27208061],
		["Minneapolis","MN",44.96,-93.26,27210954],
		["Wakefield","MA",42.5,-71.06,27213845],
		["Pikeville","KY",37.47,-82.51,27216735],
		["Minneapolis","MN",44.96,-93.26,27219625],
		["Pendleton","OR",45.73,-118.79,27222514],
		["Cary","NC",35.78,-78.79,27225402],
		["Tampa","FL",27.95,-82.48,27228290],
		["London","OH",39.88,-83.44,27231178],
		["Oregon","OH",41.66,-83.41,27234065],
		["Akron","OH",41.08,-81.52,27236952],
		["Annapolis","MD",38.97,-76.5,27239838],
		["Las Vegas","NV",36.07,-115.08,27242724],
		["Abilene","TX",32.45,-99.73,27245609],
		["Bradenton","FL",27.48,-82.57,27248493],
		["Strongsville","OH",41.31,-81.85,27251377],
		["Saint Louis","MO",38.63,-90.24,27254261],
		["Oklahoma City","OK",35.46,-97.51,27257145],
		["Sacramento","CA",38.58,-121.4,27260029],
		["Darlington","SC",34.29,-79.87,27262912],
		["Harrison Township","MI",42.58,-82.81,27265795],
		["Joshua","TX",32.46,-97.38,27268678],
		["Boise","ID",43.56,-116.21,27271561],
		["Corvallis","OR",44.46,-123.34,27274444],
		["Westmont","IL",41.79,-87.97,27277326],
		["Saint Martinville","LA",30.12,-91.83,27280207],
		["Louisville","OH",40.83,-81.26,27283087],
		["Chowchilla","CA",37.07,-120.27,27285967],
		["Lynden","WA",48.95,-122.43,27288847],
		["Purcellville","VA",39.13,-77.71,27291726],
		["Newport","TN",35.96,-83.19,27294605],
		["Battle Creek","MI",42.29,-85.22,27297484],
		["Augusta","ME",44.33,-69.72,27300362],
		["Bowie","MD",38.95,-76.73,27303240],
		["Fort Benning","GA",32.39,-84.87,27306118],
		["Edmond","OK",35.67,-97.41,27308996],
		["Burlington","MA",42.5,-71.2,27311873],
		["Madison","IN",38.75,-85.38,27314750],
		["Woodway","TX",31.49,-97.23,27317626],
		["San Elizario","TX",31.57,-106.26,27320502],
		["Cottonwood","AZ",34.75,-112.01,27323378],
		["Miami","FL",25.77,-80.2,27326253],
		["New York","NY",40.71,-73.99,27329126],
		["Forest City","NC",35.33,-81.86,27331999],
		["Lenexa","KS",38.9,-94.68,27334871],
		["Salt Lake City","UT",40.65,-111.83,27337743],
		["Crowley","LA",30.21,-92.37,27340614],
		["Burley","ID",42.39,-113.78,27343485],
		["Shawnee","OK",35.33,-96.91,27346355],
		["Sheboygan","WI",43.82,-87.75,27349224],
		["Ames","IA",42.02,-93.62,27352092],
		["Milwaukee","WI",43.06,-87.96,27354959],
		["Denver","CO",39.76,-104.87,27357826],
		["Avon","CT",41.8,-72.83,27360692],
		["Merchantville","NJ",39.94,-75.05,27363558],
		["Kinston","NC",35.27,-77.59,27366424],
		["Alexandria","LA",31.29,-92.46,27369290],
		["Livingston","CA",37.34,-120.75,27372156],
		["Dedham","MA",42.24,-71.17,27375021],
		["East Setauket","NY",40.93,-73.1,27377886],
		["Tucson","AZ",32.25,-110.87,27380751],
		["Lakeland","FL",28.04,-81.96,27383615],
		["Warrington","PA",40.25,-75.14,27386477],
		["Union","KY",38.95,-84.66,27389339],
		["Verona","WI",42.98,-89.53,27392201],
		["Lawrenceburg","TN",35.24,-87.33,27395062],
		["Louisville","KY",38.22,-85.74,27397923],
		["Sherman","TX",33.61,-96.6,27400784],
		["Las Vegas","NV",36.17,-115.31,27403645],
		["Browns Mills","NJ",39.95,-74.55,27406505],
		["Tampa","FL",27.95,-82.48,27409365],
		["New Braunfels","TX",29.7,-98.11,27412225],
		["Montrose","CO",38.49,-107.87,27415085],
		["Ripon","CA",37.75,-121.12,27417944],
		["Yazoo City","MS",32.86,-90.4,27420802],
		["Jasper","IN",38.39,-86.93,27423660],
		["Ponca City","OK",36.72,-97.06,27426518],
		["Greensburg","IN",39.34,-85.48,27429375],
		["Hannibal","MO",39.7,-91.38,27432232],
		["Sturgis","MI",41.8,-85.42,27435088],
		["New Iberia","LA",30,-91.81,27437944],
		["Guthrie","OK",35.84,-97.42,27440799],
		["Ironton","OH",38.53,-82.67,27443652],
		["Rochester","MN",44.01,-92.47,27446505],
		["Waycross","GA",31.21,-82.35,27449357],
		["Auburn","ME",44.08,-70.24,27452208],
		["Middletown","PA",40.19,-76.7,27455058],
		["Pottsville","PA",40.67,-76.2,27457908],
		["Alpena","MI",45.07,-83.43,27460758],
		["El Paso","TX",31.84,-106.43,27463608],
		["Waterloo","IA",42.49,-92.35,27466456],
		["Richland","WA",46.35,-119.32,27469304],
		["Fort Lauderdale","FL",26.14,-80.13,27472151],
		["Springfield","MO",37.19,-93.28,27474998],
		["Philadelphia","PA",39.95,-75.16,27477844],
		["Land O Lakes","FL",28.27,-82.52,27480690],
		["Sun City","CA",33.74,-117.18,27483536],
		["Barnegat","NJ",39.75,-74.22,27486381],
		["Bethpage","NY",40.74,-73.48,27489226],
		["Huntingdon Valley","PA",40.13,-75.06,27492070],
		["Omaha","NE",41.26,-96.01,27494914],
		["Claremore","OK",36.28,-95.49,27497758],
		["Orange","CA",33.83,-117.85,27500602],
		["Englewood","OH",39.86,-84.3,27503444],
		["Florence","SC",34.18,-79.78,27506285],
		["Terre Haute","IN",39.47,-87.38,27509126],
		["De Soto","MO",38.13,-90.56,27511967],
		["Reading","PA",40.33,-75.92,27514807],
		["Fort Wayne","IN",41.07,-85.13,27517647],
		["Peoria","IL",40.74,-89.6,27520487],
		["Rolling Meadows","IL",42.07,-88.02,27523325],
		["Kihei","HI",20.76,-156.45,27526163],
		["Florence","AL",34.82,-87.66,27529000],
		["East Stroudsburg","PA",41.02,-75.18,27531836],
		["Alexandria","VA",38.82,-77.08,27534672],
		["Ruskin","FL",27.71,-82.42,27537508],
		["Houston","TX",29.76,-95.38,27540344],
		["Erie","PA",42.12,-80.08,27543178],
		["Cockeysville","MD",39.47,-76.63,27546012],
		["New Orleans","LA",30.06,-89.93,27548846],
		["Belmont","NC",35.24,-81.04,27551679],
		["Clackamas","OR",45.41,-122.49,27554512],
		["Kinston","NC",35.27,-77.59,27557344],
		["Savannah","GA",32.08,-81.1,27560176],
		["Logan","UT",41.85,-111.7,27563008],
		["Martin","TN",36.34,-88.85,27565839],
		["Woodland Hills","CA",34.15,-118.6,27568670],
		["Scranton","PA",41.4,-75.66,27571500],
		["Silver Spring","MD",39.01,-77.02,27574330],
		["Wetumpka","AL",32.54,-86.2,27577160],
		["Norwalk","CT",41.09,-73.42,27579989],
		["Canton","OH",40.81,-81.37,27582818],
		["Champaign","IL",40.11,-88.26,27585647],
		["Lincoln","NE",40.81,-96.68,27588476],
		["York","PA",39.96,-76.73,27591304],
		["Orlando","FL",28.5,-81.37,27594132],
		["Waterford","WI",42.76,-88.21,27596960],
		["Paterson","NJ",40.91,-74.16,27599787],
		["Guyton","GA",32.33,-81.39,27602614],
		["Albany","GA",31.52,-84.3,27605441],
		["East Moline","IL",41.51,-90.42,27608267],
		["Mullica Hill","NJ",39.73,-75.22,27611092],
		["Florence","MS",32.15,-90.12,27613917],
		["West Des Moines","IA",41.57,-93.75,27616742],
		["Independence","MO",39.08,-94.35,27619567],
		["San Jose","CA",37.32,-121.91,27622392],
		["Madison","OH",41.77,-81.05,27625216],
		["Buffalo","NY",42.88,-78.85,27628039],
		["Yorktown","VA",37.23,-76.5,27630861],
		["Saint Albans","WV",38.37,-81.81,27633683],
		["Columbus","OH",39.98,-82.98,27636504],
		["Saint Johns","MI",43,-84.55,27639325],
		["Thiensville","WI",43.23,-87.97,27642145],
		["Knoxville","TN",35.97,-83.94,27644964],
		["Grosse Pointe","MI",42.38,-82.91,27647783],
		["Greenville","OH",40.1,-84.62,27650601],
		["Maricopa","AZ",32.98,-112.11,27653419],
		["Roselle","NJ",40.65,-74.26,27656236],
		["Daytona Beach","FL",29.19,-81.05,27659053],
		["Mission Hills","CA",34.27,-118.45,27661870],
		["South Amboy","NJ",40.47,-74.29,27664686],
		["London","KY",37.12,-84.08,27667502],
		["Manheim","PA",40.16,-76.39,27670317],
		["Bonita Springs","FL",26.34,-81.78,27673132],
		["Lewisburg","TN",35.44,-86.78,27675947],
		["Old Hickory","TN",36.22,-86.61,27678762],
		["Port Charlotte","FL",26.98,-82.1,27681576],
		["Notre Dame","IN",41.7,-86.24,27684390],
		["Germantown","WI",43.23,-88.12,27687203],
		["Dallas","TX",32.79,-96.76,27690016],
		["Newport","NC",34.78,-76.86,27692828],
		["Bloomington","IN",39.16,-86.52,27695640],
		["New Haven","CT",41.31,-72.92,27698451],
		["Okemos","MI",42.7,-84.41,27701262],
		["Watkinsville","GA",33.86,-83.4,27704072],
		["Dallas","OR",44.92,-123.34,27706881],
		["Lodi","NJ",40.87,-74.08,27709689],
		["Tulsa","OK",36.12,-95.91,27712496],
		["Tarboro","NC",35.9,-77.55,27715302],
		["Berea","OH",41.36,-81.86,27718107],
		["Lake Oswego","OR",45.4,-122.68,27720912],
		["Akron","OH",41.08,-81.52,27723715],
		["Franklinton","LA",30.84,-90.14,27726518],
		["Lubbock","TX",33.57,-101.87,27729318],
		["Lexington","KY",38.04,-84.45,27732117],
		["Beaufort","SC",32.42,-80.68,27734915],
		["Spring","TX",30.06,-95.38,27737713],
		["Coeur D Alene","ID",47.59,-116.91,27740510],
		["Peoria","IL",40.74,-89.6,27743306],
		["Portsmouth","VA",36.83,-76.29,27746101],
		["Green Bay","WI",44.52,-87.98,27748896],
		["Tenafly","NJ",40.91,-73.95,27751690],
		["Valdosta","GA",30.84,-83.27,27754484],
		["Broadview Heights","OH",41.31,-81.67,27757277],
		["Sacramento","CA",38.58,-121.37,27760070],
		["Mc Kees Rocks","PA",40.46,-80.06,27762862],
		["Fayetteville","TN",35.14,-86.56,27765654],
		["Nipomo","CA",35.05,-120.48,27768446],
		["Tuckerton","NJ",39.59,-74.32,27771237],
		["Weatherford","TX",32.75,-97.77,27774028],
		["Wyckoff","NJ",40.99,-74.16,27776818],
		["Hazel Crest","IL",41.57,-87.68,27779608],
		["Barrington","RI",41.73,-71.31,27782397],
		["Saint Paul","MN",44.94,-93.1,27785186],
		["Canton","MA",42.15,-71.13,27787973],
		["Jamaica Plain","MA",42.3,-71.11,27790760],
		["Dallas","TX",32.79,-96.76,27793547],
		["Prospect","KY",38.34,-85.6,27796333],
		["Omaha","NE",41.26,-96.01,27799119],
		["Penfield","NY",43.15,-77.44,27801904],
		["Chapin","SC",34.16,-81.34,27804689],
		["Miami","FL",25.77,-80.2,27807474],
		["Anderson","IN",40.09,-85.68,27810259],
		["North Las Vegas","NV",36.3,-115.15,27813044],
		["Granite Falls","NC",35.8,-81.42,27815828],
		["Camden","SC",34.26,-80.61,27818612],
		["Key West","FL",24.55,-81.78,27821396],
		["Springtown","TX",32.96,-97.68,27824180],
		["Spokane","WA",47.75,-117.43,27826964],
		["Wilson","NC",35.73,-77.92,27829746],
		["Beaverton","OR",45.49,-122.8,27832528],
		["Maineville","OH",39.31,-84.22,27835309],
		["Astoria","NY",40.78,-73.91,27838089],
		["Meridian","MS",32.38,-88.71,27840869],
		["Irvine","CA",33.74,-117.76,27843648],
		["York","PA",39.96,-76.73,27846426],
		["Bellaire","TX",29.7,-95.46,27849204],
		["Long Island City","NY",40.74,-73.93,27851981],
		["Austell","GA",33.82,-84.64,27854758],
		["Rogersville","TN",36.4,-83,27857534],
		["Belleville","IL",38.53,-90,27860310],
		["Levelland","TX",33.57,-102.36,27863086],
		["Issaquah","WA",47.55,-122,27865862],
		["Holmdel","NJ",40.37,-74.17,27868637],
		["Dayton","OH",39.77,-84.19,27871412],
		["Aptos","CA",37,-121.87,27874186],
		["Kings Park","NY",40.89,-73.24,27876959],
		["Safford","AZ",32.7,-109.65,27879732],
		["Albertville","AL",34.26,-86.2,27882504],
		["Tucson","AZ",32.31,-110.92,27885276],
		["Blacklick","OH",40.02,-82.8,27888047],
		["Lowell","IN",41.29,-87.41,27890818],
		["Youngsville","LA",30.09,-91.99,27893589],
		["Hayden","ID",47.78,-116.71,27896360],
		["Wakefield","RI",41.45,-71.51,27899130],
		["Great Bend","KS",38.36,-98.8,27901900],
		["Memphis","TN",35.1,-90,27904669],
		["West Palm Beach","FL",26.74,-80.12,27907437],
		["Granville","OH",40.07,-82.5,27910204],
		["Universal City","TX",29.55,-98.3,27912971],
		["Lubbock","TX",33.57,-101.87,27915738],
		["Fernley","NV",39.66,-119.06,27918505],
		["Paterson","NJ",40.91,-74.16,27921271],
		["Cape Coral","FL",26.63,-81.99,27924037],
		["Memphis","TN",35.1,-90,27926802],
		["Leawood","KS",38.9,-94.68,27929567],
		["Alpine","CA",32.8,-116.71,27932332],
		["Hopkinton","MA",42.22,-71.52,27935096],
		["Bryan","TX",30.66,-96.36,27937860],
		["Stanford","CA",37.41,-122.17,27940624],
		["Youngstown","OH",41.09,-80.64,27943387],
		["Silverdale","WA",47.67,-122.72,27946149],
		["Sussex","WI",43.13,-88.21,27948910],
		["Salt Lake City","UT",40.67,-111.79,27951671],
		["New York","NY",40.71,-73.99,27954431],
		["Queens Village","NY",40.72,-73.74,27957191],
		["Tucson","AZ",32.29,-110.83,27959951],
		["Oakland","CA",37.8,-122.21,27962711],
		["Cornelius","NC",35.48,-80.86,27965470],
		["Bellefontaine","OH",40.36,-83.75,27968226],
		["Troy","MI",42.58,-83.14,27970982],
		["Minneapolis","MN",44.96,-93.26,27973738],
		["Bolivar","MO",37.6,-93.41,27976494],
		["Fort Smith","AR",35.36,-94.38,27979250],
		["San Jose","CA",37.24,-121.73,27982006],
		["Pittsburgh","PA",40.43,-79.97,27984760],
		["Myrtle Beach","SC",33.69,-78.89,27987513],
		["West Bloomfield","MI",42.56,-83.38,27990266],
		["Lynbrook","NY",40.65,-73.67,27993018],
		["Cape Coral","FL",26.63,-81.99,27995770],
		["Alexander City","AL",32.92,-85.93,27998522],
		["Greencastle","PA",39.79,-77.72,28001273],
		["Fayetteville","NC",35.05,-78.87,28004024],
		["Grandview","WA",46.26,-119.9,28006775],
		["Minneapolis","MN",44.96,-93.26,28009525],
		["Haughton","LA",32.53,-93.5,28012274],
		["Norman","OK",35.22,-97.34,28015023],
		["Fountain Inn","SC",34.69,-82.2,28017771],
		["Sebastian","FL",27.78,-80.48,28020519],
		["Frostburg","MD",39.66,-78.92,28023265],
		["New Orleans","LA",30.06,-89.93,28026011],
		["Apex","NC",35.67,-78.81,28028756],
		["Atlantic Beach","FL",30.33,-81.4,28031501],
		["Mount Dora","FL",28.8,-81.64,28034246],
		["Saint Cloud","FL",28.24,-81.28,28036991],
		["Bethany","OK",35.5,-97.64,28039736],
		["Fort Worth","TX",32.75,-97.33,28042481],
		["Evans","CO",40.37,-104.69,28045226],
		["Park City","UT",40.71,-111.55,28047970],
		["Belmar","NJ",40.17,-74.02,28050713],
		["Springfield","OH",39.92,-83.79,28053456],
		["Saint Augustine","FL",29.89,-81.31,28056198],
		["Hastings","MI",42.65,-85.28,28058940],
		["Angola","IN",41.64,-85,28061681],
		["Conroe","TX",30.32,-95.47,28064422],
		["Tampa","FL",27.95,-82.48,28067162],
		["Morris","IL",41.36,-88.42,28069901],
		["King City","CA",36.2,-120.94,28072640],
		["San Leandro","CA",37.68,-122.15,28075379],
		["Syracuse","NY",43.04,-76.14,28078117],
		["La Plata","MD",38.53,-76.97,28080855],
		["Florence","AZ",32.95,-111.23,28083593],
		["Jamaica","NY",40.68,-73.78,28086330],
		["Tipp City","OH",39.96,-84.18,28089067],
		["Sault Sainte Marie","MI",46.48,-84.36,28091804],
		["Streator","IL",41.12,-88.83,28094541],
		["Wichita","KS",37.68,-97.34,28097278],
		["Carencro","LA",30.31,-92.03,28100015],
		["Depew","NY",42.91,-78.7,28102751],
		["West Columbia","SC",33.99,-81.08,28105487],
		["Miami","FL",25.77,-80.2,28108223],
		["Decatur","IL",39.85,-88.93,28110959],
		["Carlisle","PA",40.15,-77.26,28113694],
		["Atlanta","GA",33.87,-84.33,28116429],
		["Akron","OH",41.08,-81.52,28119164],
		["Janesville","WI",42.68,-89.07,28121899],
		["Kansas City","KS",39.1,-94.62,28124634],
		["Lackland A F B","TX",29.45,-98.5,28127369],
		["Bradenton","FL",27.48,-82.57,28130103],
		["Marblehead","MA",42.5,-70.85,28132836],
		["York","PA",39.95,-76.81,28135569],
		["North Port","FL",27.04,-82.19,28138301],
		["Decatur","IN",40.83,-84.92,28141033],
		["Bellevue","WA",47.6,-122.11,28143765],
		["Somerville","NJ",40.57,-74.61,28146495],
		["Tulsa","OK",36.12,-95.91,28149225],
		["Forked River","NJ",39.84,-74.19,28151953],
		["Vestal","NY",42.08,-76.05,28154681],
		["Easton","MD",38.77,-76.06,28157409],
		["Semmes","AL",30.77,-88.26,28160136],
		["Steubenville","OH",40.36,-80.64,28162863],
		["Idaho Falls","ID",43.43,-111.89,28165590],
		["Portland","OR",45.57,-122.62,28168317],
		["Tacoma","WA",47.24,-122.47,28171044],
		["Florissant","MO",38.79,-90.32,28173770],
		["Cocoa","FL",28.37,-80.75,28176495],
		["Boca Raton","FL",26.37,-80.1,28179220],
		["Houston","TX",29.76,-95.38,28181944],
		["Hidalgo","TX",26.1,-98.25,28184668],
		["Arlington","TX",32.69,-97.12,28187391],
		["Covina","CA",34.08,-117.88,28190114],
		["New Baltimore","MI",42.68,-82.73,28192836],
		["Plainsboro","NJ",40.33,-74.58,28195557],
		["Cherry Hill","NJ",39.9,-74.99,28198276],
		["Sarasota","FL",27.33,-82.54,28200994],
		["Louisville","KY",38.22,-85.74,28203712],
		["Kansas City","MO",39.09,-94.58,28206429],
		["Miami","OK",36.88,-94.87,28209146],
		["Douglas","GA",31.5,-82.85,28211862],
		["Portland","TX",27.87,-97.32,28214578],
		["Tampa","FL",27.95,-82.48,28217293],
		["Warren","MI",42.49,-83.02,28220008],
		["Cicero","NY",43.17,-76.11,28222722],
		["Philadelphia","PA",39.95,-75.16,28225436],
		["Dayton","TN",35.49,-85.01,28228150],
		["Broomall","PA",39.96,-75.35,28230863],
		["Johnston","IA",41.68,-93.7,28233576],
		["Chula Vista","CA",32.66,-116.94,28236289],
		["Haverhill","MA",42.78,-71.08,28239001],
		["Huntingtown","MD",38.61,-76.61,28241713],
		["Denton","TX",33.21,-97.13,28244425],
		["Flint","MI",43.02,-83.69,28247136],
		["Cranston","RI",41.76,-71.48,28249846],
		["Oklahoma City","OK",35.46,-97.51,28252556],
		["Yonkers","NY",40.94,-73.86,28255265],
		["Ankeny","IA",41.72,-93.6,28257974],
		["Worcester","MA",42.26,-71.8,28260682],
		["Lexington","MA",42.44,-71.23,28263390],
		["Minden","LA",32.61,-93.28,28266098],
		["Port Lavaca","TX",28.61,-96.62,28268806],
		["Memphis","TN",35.1,-90,28271513],
		["Vero Beach","FL",27.64,-80.39,28274219],
		["Castle Rock","CO",39.36,-104.9,28276925],
		["Okeechobee","FL",27.24,-80.82,28279630],
		["Cleveland","OH",41.47,-81.67,28282335],
		["Lindale","TX",32.5,-95.4,28285040],
		["Asheboro","NC",35.71,-79.81,28287744],
		["San Luis Obispo","CA",35.25,-120.62,28290448],
		["Roxbury Crossing","MA",42.35,-71.06,28293151],
		["Panama City","FL",30.17,-85.67,28295854],
		["Minneapolis","MN",44.96,-93.26,28298557],
		["Atlanta","GA",33.93,-84.38,28301257],
		["Millersville","MD",39.05,-76.64,28303956],
		["San Mateo","CA",37.52,-122.35,28306655],
		["Anderson","IN",40.09,-85.68,28309353],
		["Peoria","IL",40.74,-89.6,28312051],
		["Oklahoma City","OK",35.46,-97.51,28314749],
		["Boulder","CO",40.02,-105.25,28317447],
		["Towson","MD",39.39,-76.62,28320144],
		["Harrisburg","NC",35.32,-80.66,28322841],
		["Laurens","SC",34.5,-82.02,28325537],
		["Oxford","PA",39.78,-75.98,28328232],
		["Northville","MI",42.43,-83.48,28330927],
		["Brookfield","WI",43.06,-88.11,28333622],
		["Eden Prairie","MN",44.84,-93.45,28336317],
		["Bronx","NY",40.84,-73.87,28339011],
		["Akron","OH",41.08,-81.52,28341705],
		["Eunice","LA",30.49,-92.41,28344399],
		["Denver","CO",39.76,-104.87,28347093],
		["Manhasset","NY",40.79,-73.69,28349785],
		["Mastic","NY",40.8,-72.84,28352477],
		["Fort Worth","TX",32.75,-97.33,28355169],
		["Los Angeles","CA",34.03,-118.26,28357861],
		["Hood River","OR",45.61,-121.58,28360553],
		["Chester","SC",34.7,-81.21,28363244],
		["Centralia","IL",38.52,-89.12,28365935],
		["Corning","NY",42.14,-77.05,28368625],
		["Birmingham","AL",33.52,-86.79,28371314],
		["Grenada","MS",33.77,-89.81,28374002],
		["West Bloomfield","MI",42.56,-83.38,28376690],
		["Waterford","MI",42.66,-83.38,28379378],
		["Scottsdale","AZ",33.46,-111.92,28382066],
		["Essex Junction","VT",44.49,-73.11,28384753],
		["Bellefonte","PA",40.91,-77.76,28387440],
		["Mount Sterling","KY",38.06,-83.94,28390127],
		["Puyallup","WA",47.19,-122.33,28392814],
		["Pembroke","MA",42.06,-70.8,28395499],
		["Spencerport","NY",43.18,-77.8,28398184],
		["Miami","FL",25.77,-80.2,28400869],
		["Fort Lauderdale","FL",26.14,-80.13,28403554],
		["Phenix City","AL",32.46,-85.01,28406239],
		["Sioux Falls","SD",43.54,-96.73,28408924],
		["Dallas","TX",32.79,-96.76,28411609],
		["Greenville","TX",33.11,-96.1,28414294],
		["Sewickley","PA",40.53,-80.18,28416978],
		["Miami","FL",25.82,-80.3,28419662],
		["Elkhorn","WI",42.67,-88.54,28422346],
		["Fort Lauderdale","FL",26.14,-80.13,28425029],
		["Millbrae","CA",37.59,-122.4,28427712],
		["Spring Lake","NC",35.17,-78.98,28430394],
		["Cedarburg","WI",43.29,-87.98,28433076],
		["Monticello","MN",45.3,-93.8,28435757],
		["Gaffney","SC",35.07,-81.65,28438437],
		["Warren","NJ",40.63,-74.51,28441116],
		["Hudson","FL",28.35,-82.69,28443795],
		["Grand Ledge","MI",42.75,-84.74,28446473],
		["Red Bank","NJ",40.34,-74.06,28449150],
		["Oxford","AL",33.66,-85.81,28451827],
		["Schofield","WI",44.91,-89.61,28454504],
		["Little Rock","AR",34.72,-92.35,28457180],
		["Colorado Springs","CO",38.86,-104.76,28459856],
		["Edgewater","MD",38.9,-76.54,28462531],
		["Waunakee","WI",43.18,-89.45,28465206],
		["Booneville","MS",34.66,-88.56,28467880],
		["Oklahoma City","OK",35.46,-97.51,28470554],
		["Seattle","WA",47.54,-122.31,28473227],
		["La Fayette","GA",34.7,-85.28,28475899],
		["Toledo","OH",41.66,-83.58,28478571],
		["Aztec","NM",36.82,-108,28481242],
		["Gary","IN",41.59,-87.34,28483912],
		["Minneapolis","MN",44.96,-93.26,28486582],
		["Collinsville","OK",36.37,-95.85,28489252],
		["Magnolia","AR",33.27,-93.23,28491920],
		["Bensenville","IL",41.95,-87.94,28494587],
		["Baltimore","MD",39.3,-76.61,28497253],
		["Newport","RI",41.47,-71.3,28499918],
		["Miami","FL",25.77,-80.2,28502583],
		["El Paso","TX",31.84,-106.43,28505248],
		["Brooklyn","NY",40.64,-73.94,28507912],
		["Manlius","NY",43,-75.97,28510576],
		["Brazil","IN",39.52,-87.12,28513239],
		["Saratoga Springs","UT",40.28,-111.88,28515902],
		["Trenton","NJ",40.22,-74.76,28518564],
		["Copiague","NY",40.67,-73.39,28521226],
		["Dade City","FL",28.36,-82.19,28523888],
		["New Orleans","LA",30.06,-89.93,28526550],
		["Sterling Heights","MI",42.58,-83.03,28529211],
		["Dillon","SC",34.42,-79.36,28531871],
		["Sioux City","IA",42.5,-96.39,28534531],
		["Los Angeles","CA",34.11,-118.26,28537191],
		["Melbourne","FL",28.08,-80.6,28539849],
		["Duluth","MN",46.78,-92.11,28542507],
		["East Greenwich","RI",41.63,-71.5,28545164],
		["Monticello","KY",36.84,-84.84,28547821],
		["Joliet","IL",41.52,-88.12,28550478],
		["Saint Michael","MN",45.21,-93.66,28553133],
		["Auburn","IN",41.36,-85.05,28555787],
		["Riverton","WY",43.03,-108.41,28558441],
		["Greenville","PA",41.4,-80.38,28561094],
		["Westlake Village","CA",34.14,-118.89,28563747],
		["Oakland","CA",37.78,-122.12,28566400],
		["Atlanta","GA",33.76,-84.39,28569052],
		["Salt Lake City","UT",40.71,-111.89,28571704],
		["Orinda","CA",37.89,-122.19,28574356],
		["Wichita","KS",37.68,-97.34,28577007],
		["Houston","TX",29.76,-95.38,28579658],
		["Pahrump","NV",36.36,-116.15,28582309],
		["Ojai","CA",34.51,-119.29,28584960],
		["Walpole","MA",42.13,-71.24,28587610],
		["Eatontown","NJ",40.29,-74.05,28590260],
		["Lynn Haven","FL",30.24,-85.65,28592910],
		["Nashville","TN",36.17,-86.78,28595560],
		["Wichita Falls","TX",33.89,-98.51,28598210],
		["Colchester","CT",41.57,-72.33,28600859],
		["Takoma Park","MD",38.98,-77,28603508],
		["San Jose","CA",37.25,-121.67,28606157],
		["Perry","GA",32.46,-83.73,28608803],
		["Ozark","AL",31.43,-85.64,28611448],
		["Mason","MI",42.58,-84.44,28614093],
		["Omaha","NE",41.26,-96.01,28616738],
		["Senatobia","MS",34.61,-89.97,28619382],
		["San Diego","CA",32.81,-117.03,28622026],
		["Fayetteville","NC",34.93,-78.72,28624669],
		["Dickinson","ND",46.88,-102.78,28627312],
		["Corcoran","CA",36.07,-119.53,28629955],
		["Farmingville","NY",40.84,-73.04,28632597],
		["Memphis","TN",35.1,-90,28635239],
		["Camden","AR",33.56,-92.84,28637881],
		["Eureka","CA",40.8,-124.15,28640523],
		["Ludlow","MA",42.16,-72.48,28643164],
		["Chagrin Falls","OH",41.43,-81.38,28645805],
		["Sioux Falls","SD",43.54,-96.73,28648446],
		["Bayville","NJ",39.91,-74.21,28651086],
		["Cambridge","OH",40.02,-81.58,28653726],
		["Gaylord","MI",45.02,-84.67,28656366],
		["Hawaiian Gardens","CA",33.83,-118.07,28659006],
		["Yonkers","NY",40.94,-73.86,28661645],
		["Deerfield Beach","FL",26.31,-80.12,28664284],
		["South Milwaukee","WI",42.91,-87.86,28666923],
		["North Wilkesboro","NC",36.16,-81.14,28669561],
		["Lake City","FL",30.19,-82.64,28672199],
		["Orlando","FL",28.5,-81.37,28674837],
		["Canton","OH",40.81,-81.37,28677475],
		["San Diego","CA",32.79,-117.06,28680113],
		["Rochester","NY",43.16,-77.61,28682750],
		["Marcus Hook","PA",39.81,-75.41,28685387],
		["Blythewood","SC",34.21,-80.97,28688024],
		["Springfield","OH",39.92,-83.79,28690660],
		["Buckhannon","WV",38.98,-80.22,28693295],
		["Harrodsburg","KY",37.76,-84.84,28695930],
		["El Dorado","KS",37.82,-96.85,28698565],
		["Dana Point","CA",33.47,-117.7,28701200],
		["Belmont","CA",37.5,-122.3,28703835],
		["Tulsa","OK",36.12,-95.91,28706469],
		["Waco","TX",31.56,-97.18,28709103],
		["Mamaroneck","NY",40.93,-73.72,28711736],
		["Detroit","MI",42.38,-83.1,28714369],
		["Orlando","FL",28.5,-81.37,28717001],
		["Oldsmar","FL",28.04,-82.67,28719633],
		["Cincinnati","OH",39.09,-84.51,28722265],
		["Jackson","MI",42.24,-84.4,28724897],
		["Baltimore","MD",39.3,-76.61,28727528],
		["Brookpark","OH",41.4,-81.82,28730159],
		["Cadillac","MI",44.24,-85.41,28732790],
		["Wichita","KS",37.68,-97.34,28735421],
		["Granbury","TX",32.44,-97.78,28738052],
		["Scarborough","ME",43.59,-70.33,28740682],
		["Dewitt","MI",42.86,-84.59,28743312],
		["South Hadley","MA",42.26,-72.56,28745940],
		["Parlin","NJ",40.45,-74.3,28748568],
		["Meridian","MS",32.38,-88.71,28751196],
		["Pampa","TX",35.54,-100.96,28753824],
		["Erie","CO",40.02,-105.04,28756452],
		["Monmouth Junction","NJ",40.38,-74.54,28759079],
		["Wyandanch","NY",40.74,-73.37,28761706],
		["Dumfries","VA",38.6,-77.34,28764333],
		["Anniston","AL",33.66,-85.81,28766960],
		["Wichita","KS",37.68,-97.34,28769587],
		["Los Angeles","CA",34.01,-118.28,28772214],
		["Inver Grove Heights","MN",44.82,-93.05,28774840],
		["Birmingham","AL",33.52,-86.79,28777465],
		["Duxbury","MA",42.04,-70.67,28780088],
		["Baltimore","MD",39.3,-76.61,28782711],
		["Saint Petersburg","FL",27.75,-82.64,28785334],
		["Lubbock","TX",33.57,-101.87,28787957],
		["Sunland Park","NM",31.79,-106.57,28790580],
		["Mc Lean","VA",38.94,-77.19,28793201],
		["Dade City","FL",28.36,-82.19,28795822],
		["Fort Wayne","IN",41.07,-85.13,28798443],
		["Lowell","MI",42.93,-85.34,28801064],
		["Cincinnati","OH",39.09,-84.51,28803683],
		["Grand Rapids","MN",47.22,-93.52,28806301],
		["San Diego","CA",32.85,-117.2,28808919],
		["Des Moines","IA",41.57,-93.61,28811535],
		["Centerville","UT",40.93,-111.88,28814151],
		["Gainesville","FL",29.67,-82.33,28816766],
		["Canton","OH",40.81,-81.37,28819381],
		["Joliet","IL",41.52,-88.12,28821996],
		["Independence","MO",39.08,-94.35,28824611],
		["Kingsburg","CA",36.48,-119.5,28827226],
		["Dixon","IL",41.84,-89.47,28829840],
		["Blanchard","OK",35.14,-97.65,28832453],
		["Horseheads","NY",42.16,-76.82,28835065],
		["Warren","MI",42.49,-83.02,28837677],
		["Bronxville","NY",40.93,-73.82,28840288],
		["Buffalo","NY",42.88,-78.85,28842899],
		["Opelika","AL",32.65,-85.37,28845510],
		["Madison","WI",43.07,-89.38,28848121],
		["Howard Beach","NY",40.66,-73.84,28850731],
		["Bogalusa","LA",30.78,-89.86,28853341],
		["Milwaukee","WI",43.06,-87.96,28855950],
		["Westborough","MA",42.26,-71.61,28858558],
		["Logan","OH",39.53,-82.4,28861166],
		["Union","SC",34.72,-81.62,28863773],
		["Live Oak","FL",30.29,-82.98,28866380],
		["Bloomington","IN",39.16,-86.52,28868987],
		["Glendale","CA",34.17,-118.29,28871594],
		["Sun City","CA",33.69,-117.24,28874201],
		["Stoughton","WI",42.92,-89.22,28876807],
		["Martinsburg","WV",39.46,-77.96,28879412],
		["Decatur","GA",33.77,-84.29,28882017],
		["Ontario","OR",44.08,-117.01,28884622],
		["Ansonia","CT",41.34,-73.06,28887226],
		["Latonia","KY",38.98,-84.48,28889829],
		["North Chicago","IL",42.31,-87.85,28892432],
		["White Hall","AR",34.27,-92.09,28895035],
		["San Antonio","TX",29.45,-98.5,28897638],
		["Artesia","NM",32.84,-104.43,28900241],
		["Whiteville","NC",34.32,-78.7,28902843],
		["Miami","FL",25.77,-80.2,28905445],
		["Washington","UT",37.22,-113.48,28908047],
		["Butler","NJ",40.99,-74.34,28910648],
		["Maryland Heights","MO",38.72,-90.47,28913249],
		["Merrill","WI",45.18,-89.7,28915849],
		["Fort Worth","TX",32.75,-97.33,28918449],
		["Decatur","GA",33.77,-84.29,28921048],
		["Rockmart","GA",34,-85.04,28923647],
		["Fitzgerald","GA",31.7,-83.25,28926245],
		["Ripley","TN",35.74,-89.53,28928843],
		["Fort Wayne","IN",41.07,-85.13,28931441],
		["Reading","PA",40.33,-75.92,28934038],
		["Batesville","MS",34.31,-89.93,28936635],
		["Dumas","TX",35.86,-101.96,28939232],
		["Bloomfield","NM",36.71,-107.97,28941829],
		["Griffith","IN",41.52,-87.42,28944425],
		["Athens","TX",32.2,-95.85,28947021],
		["Denver","CO",39.76,-104.87,28949617],
		["Stratford","CT",41.2,-73.13,28952212],
		["Harwood Heights","IL",41.96,-87.8,28954807],
		["Coronado","CA",32.65,-117.14,28957402],
		["Lynchburg","VA",37.4,-79.19,28959996],
		["Venice","FL",27.09,-82.43,28962590],
		["Fort Wayne","IN",41.07,-85.13,28965184],
		["Houston","TX",29.76,-95.38,28967778],
		["Tempe","AZ",33.33,-111.93,28970372],
		["Honolulu","HI",21.3,-157.85,28972966],
		["Seattle","WA",47.54,-122.35,28975560],
		["Wapato","WA",46.45,-120.51,28978154],
		["Short Hills","NJ",40.74,-74.33,28980747],
		["Brooksville","FL",28.55,-82.39,28983340],
		["Mecca","CA",33.55,-115.97,28985933],
		["Hatboro","PA",40.17,-75.1,28988525],
		["University","MS",34.36,-89.59,28991117],
		["Marion","AR",35.2,-90.2,28993709],
		["Jacksonville","FL",30.33,-81.65,28996299],
		["Midlothian","VA",37.48,-77.65,28998888],
		["Merritt Island","FL",28.32,-80.68,29001477],
		["Rutherfordton","NC",35.36,-81.96,29004065],
		["Palm Harbor","FL",28.08,-82.76,29006653],
		["The Dalles","OR",45.54,-121.15,29009241],
		["Pooler","GA",32.1,-81.25,29011828],
		["Mobile","AL",30.68,-88.04,29014415],
		["Fort Worth","TX",32.75,-97.33,29017002],
		["Salida","CA",37.71,-121.09,29019589],
		["Marshall","TX",32.53,-94.35,29022175],
		["Lone Tree","CO",39.59,-105.01,29024761],
		["Corning","CA",39.92,-122.28,29027347],
		["Bethel","CT",41.37,-73.41,29029931],
		["Beverly Hills","CA",34.09,-118.41,29032515],
		["Melville","NY",40.78,-73.41,29035098],
		["Denver","CO",39.76,-104.87,29037681],
		["Buffalo","NY",42.88,-78.85,29040263],
		["Portsmouth","VA",36.83,-76.29,29042845],
		["Arden","NC",35.46,-82.58,29045427],
		["Cochran","GA",32.38,-83.35,29048009],
		["Appleton","WI",44.26,-88.39,29050591],
		["Vancouver","WA",45.72,-122.61,29053173],
		["Durham","NC",35.98,-78.91,29055754],
		["Topeka","KS",39.03,-95.69,29058333],
		["Irving","TX",32.85,-96.96,29060912],
		["Selah","WA",46.76,-120.68,29063491],
		["Spring Hill","FL",28.44,-82.56,29066069],
		["Akron","OH",41.08,-81.52,29068647],
		["Forrest City","AR",35.01,-90.78,29071225],
		["Peekskill","NY",41.28,-73.92,29073802],
		["Mount Holly","NC",35.3,-81.03,29076379],
		["Maitland","FL",28.62,-81.36,29078956],
		["Cullowhee","NC",35.31,-83.17,29081532],
		["Ponce","PR",17.98,-66.6,29084107],
		["Longmeadow","MA",42.04,-72.57,29086682],
		["West Palm Beach","FL",26.74,-80.12,29089257],
		["Gadsden","AL",34.01,-86.01,29091832],
		["Columbus","OH",39.98,-82.98,29094406],
		["Cleburne","TX",32.34,-97.4,29096980],
		["Pittsburgh","PA",40.43,-79.97,29099553],
		["Hazelwood","MO",38.77,-90.35,29102125],
		["Lake Havasu City","AZ",34.44,-114.24,29104697],
		["King","NC",36.27,-80.35,29107267],
		["Greenville","MI",43.17,-85.25,29109837],
		["Sartell","MN",45.62,-94.2,29112407],
		["Seagoville","TX",32.65,-96.54,29114977],
		["Ocala","FL",29.18,-82.13,29117546],
		["Dayton","OH",39.77,-84.19,29120115],
		["Washington","MI",42.72,-83.03,29122684],
		["Falls Church","VA",38.88,-77.17,29125251],
		["Sarasota","FL",27.33,-82.54,29127818],
		["Alexandria","KY",38.96,-84.38,29130385],
		["Houston","TX",29.76,-95.38,29132952],
		["Seattle","WA",47.44,-122.28,29135519],
		["Clifton Heights","PA",39.92,-75.29,29138085],
		["Bowling Green","KY",36.97,-86.44,29140651],
		["Angier","NC",35.51,-78.73,29143215],
		["El Paso","TX",31.84,-106.43,29145779],
		["Portsmouth","RI",41.6,-71.25,29148340],
		["Delray Beach","FL",26.45,-80.08,29150901],
		["Manning","SC",33.69,-80.21,29153461],
		["Hutchinson","MN",44.88,-94.37,29156021],
		["Newtown","CT",41.41,-73.31,29158580],
		["Lockhart","TX",29.87,-97.67,29161139],
		["Euclid","OH",41.59,-81.51,29163697],
		["Republic","MO",37.11,-93.47,29166255],
		["Queens Village","NY",40.72,-73.74,29168812],
		["El Campo","TX",29.19,-96.27,29171368],
		["Lomita","CA",33.79,-118.31,29173924],
		["Valley Village","CA",34.16,-118.4,29176480],
		["Denver","CO",39.76,-104.87,29179035],
		["Burbank","CA",34.19,-118.29,29181590],
		["Clarksville","MD",39.2,-76.94,29184144],
		["Morton Grove","IL",42.04,-87.78,29186698],
		["Fayetteville","AR",36.07,-94.15,29189252],
		["Littlerock","CA",34.48,-117.92,29191806],
		["Geneseo","NY",42.79,-77.81,29194359],
		["Rio Linda","CA",38.68,-121.45,29196912],
		["Springfield","MA",42.11,-72.53,29199464],
		["Colonia","NJ",40.59,-74.31,29202016],
		["Americus","GA",32.07,-84.22,29204568],
		["Miami","FL",25.77,-80.2,29207119],
		["Oshkosh","WI",44.01,-88.55,29209670],
		["Chickasha","OK",35.04,-97.95,29212221],
		["Naples","FL",26.14,-81.79,29214771],
		["Moss Point","MS",30.42,-88.52,29217321],
		["Galveston","TX",29.3,-94.79,29219871],
		["Scituate","MA",42.18,-70.73,29222420],
		["Minneapolis","MN",44.96,-93.26,29224969],
		["Saint Louis","MO",38.63,-90.24,29227518],
		["Garden City","ID",43.64,-116.26,29230067],
		["Buffalo","NY",42.88,-78.85,29232615],
		["Birmingham","AL",33.52,-86.79,29235163],
		["Tampa","FL",27.95,-82.48,29237710],
		["Cleveland","MS",33.74,-90.72,29240257],
		["Alpine","UT",40.5,-111.7,29242804],
		["Cleveland","OH",41.47,-81.67,29245350],
		["Cincinnati","OH",39.09,-84.51,29247895],
		["Denver","CO",39.76,-104.87,29250440],
		["Philadelphia","PA",39.95,-75.16,29252984],
		["Baton Rouge","LA",30.44,-91.12,29255528],
		["Tucson","AZ",32.28,-110.73,29258072],
		["Fostoria","OH",41.15,-83.41,29260613],
		["Pleasant Prairie","WI",42.52,-87.88,29263154],
		["Effingham","IL",39.11,-88.55,29265695],
		["Manhattan","KS",39.19,-96.59,29268236],
		["Brookfield","CT",41.46,-73.39,29270776],
		["Temple","GA",33.73,-85.03,29273316],
		["Phoenix","AZ",33.5,-112.05,29275856],
		["Miami","FL",25.77,-80.2,29278395],
		["Dayton","OH",39.77,-84.19,29280934],
		["Fort Worth","TX",32.75,-97.33,29283473],
		["Temple","TX",31.09,-97.36,29286012],
		["Bradford","PA",41.96,-78.64,29288550],
		["London","KY",37.12,-84.08,29291087],
		["Fort Wayne","IN",41.07,-85.13,29293624],
		["Delhi","CA",37.42,-120.77,29296161],
		["Eastman","GA",32.19,-83.17,29298697],
		["Bloomingdale","IL",41.94,-88.08,29301233],
		["Harrisonburg","VA",38.43,-78.87,29303768],
		["Haslet","TX",32.95,-97.33,29306303],
		["Wolcott","CT",41.61,-72.98,29308837],
		["Harrisburg","PA",40.27,-76.88,29311371],
		["Lexington","VA",37.78,-79.44,29313905],
		["Saint Louis","MO",38.63,-90.24,29316439],
		["Palm Springs","CA",33.85,-116.56,29318973],
		["Davidson","NC",35.49,-80.84,29321506],
		["Austin","TX",30.26,-97.74,29324039],
		["Port Angeles","WA",47.99,-123.25,29326570],
		["Eustis","FL",28.85,-81.68,29329100],
		["Mitchell","SD",43.73,-98.03,29331630],
		["Winton","CA",37.41,-120.59,29334158],
		["Saint Petersburg","FL",27.75,-82.64,29336685],
		["Celina","OH",40.55,-84.57,29339211],
		["Conroe","TX",30.32,-95.47,29341737],
		["San Francisco","CA",37.76,-122.44,29344263],
		["Baltimore","MD",39.3,-76.61,29346788],
		["Eaton Rapids","MI",42.51,-84.65,29349312],
		["Madison","WI",43.07,-89.38,29351836],
		["Sylacauga","AL",33.17,-86.26,29354359],
		["Dayton","OH",39.77,-84.19,29356882],
		["Lincoln University","PA",39.78,-75.88,29359404],
		["Lees Summit","MO",38.91,-94.38,29361926],
		["West Point","MS",33.6,-88.64,29364447],
		["Colorado Springs","CO",38.86,-104.76,29366968],
		["Oakland","CA",37.81,-122.3,29369489],
		["Woodinville","WA",47.75,-122.04,29372010],
		["Tallmadge","OH",41.1,-81.42,29374530],
		["Richton Park","IL",41.48,-87.72,29377050],
		["Chicago","IL",41.83,-87.68,29379570],
		["Cheyenne","WY",41.14,-104.79,29382090],
		["Huntsville","AL",34.7,-86.62,29384608],
		["Gary","IN",41.59,-87.34,29387126],
		["Arkansas City","KS",37.06,-97.04,29389644],
		["Rocky Mount","VA",36.99,-79.89,29392161],
		["Heath","OH",40.02,-82.43,29394678],
		["Knoxville","TN",35.97,-83.94,29397194],
		["Paris","KY",38.2,-84.26,29399710],
		["Covington","KY",39.08,-84.5,29402226],
		["Houston","TX",29.76,-95.38,29404742],
		["Carson City","NV",39.21,-119.71,29407258],
		["Irvine","CA",33.62,-117.79,29409774],
		["Simsbury","CT",41.88,-72.81,29412289],
		["Schenectady","NY",42.8,-73.92,29414804],
		["Southbury","CT",41.48,-73.22,29417318],
		["Independence","OH",41.38,-81.64,29419832],
		["Las Vegas","NV",36.12,-115.13,29422346],
		["Oak Park","CA",34.19,-118.76,29424860],
		["Byron","GA",32.65,-83.75,29427373],
		["Gadsden","AL",34.01,-86.01,29429886],
		["Clifton","VA",38.77,-77.38,29432398],
		["Warrenton","VA",38.71,-77.79,29434910],
		["Orlando","FL",28.5,-81.37,29437421],
		["Columbus","OH",39.98,-82.98,29439932],
		["Okmulgee","OK",35.63,-95.95,29442443],
		["Amarillo","TX",35.2,-101.81,29444954],
		["Kenmore","WA",47.75,-122.24,29447465],
		["Albert Lea","MN",43.65,-93.36,29449975],
		["Riverton","NJ",40.01,-75.01,29452484],
		["Delray Beach","FL",26.45,-80.08,29454993],
		["Fredericksburg","TX",30.27,-98.87,29457502],
		["Santa Fe Springs","CA",33.92,-118.06,29460011],
		["Exeter","CA",36.28,-119.03,29462520],
		["Lake City","FL",30.19,-82.64,29465027],
		["Lubbock","TX",33.57,-101.87,29467534],
		["Houston","TX",29.76,-95.38,29470040],
		["Vero Beach","FL",27.64,-80.39,29472545],
		["Cincinnati","OH",39.09,-84.51,29475050],
		["Taylor","TX",30.57,-97.41,29477555],
		["Butler","PA",40.86,-79.89,29480058],
		["Titusville","FL",28.58,-80.82,29482561],
		["Lake Saint Louis","MO",38.78,-90.78,29485064],
		["Bristol","PA",40.1,-74.85,29487566],
		["Cedar Springs","MI",43.22,-85.55,29490068],
		["Vancouver","WA",45.62,-122.57,29492570],
		["Levittown","PA",40.15,-74.85,29495071],
		["Tampa","FL",27.95,-82.48,29497572],
		["Gautier","MS",30.38,-88.63,29500073],
		["Hammond","IN",41.62,-87.49,29502574],
		["Santa Rosa","CA",38.43,-122.67,29505075],
		["Rochester","NH",43.3,-70.97,29507575],
		["Scranton","PA",41.4,-75.66,29510074],
		["Akron","OH",41.08,-81.52,29512572],
		["Cincinnati","OH",39.09,-84.51,29515070],
		["Memphis","TN",35.1,-90,29517567],
		["Spring Lake","MI",43.07,-86.19,29520064],
		["Shorewood","IL",41.51,-88.21,29522561],
		["Lincoln","IL",40.15,-89.36,29525058],
		["Corpus Christi","TX",27.8,-97.39,29527554],
		["Canutillo","TX",31.91,-106.59,29530050],
		["Espanola","NM",36,-106.06,29532546],
		["Chicago","IL",41.83,-87.68,29535041],
		["Houston","TX",29.76,-95.38,29537536],
		["Buffalo","NY",42.88,-78.85,29540030],
		["Oakton","VA",38.89,-77.3,29542524],
		["South Sioux City","NE",42.46,-96.41,29545018],
		["Tulsa","OK",36.12,-95.91,29547512],
		["Ponce","PR",17.98,-66.6,29550005],
		["Coalinga","CA",36.19,-120.39,29552498],
		["Susanville","CA",40.57,-120.61,29554991],
		["Portland","OR",45.58,-122.7,29557484],
		["Keyport","NJ",40.43,-74.2,29559976],
		["Bluefield","WV",37.26,-81.21,29562468],
		["Los Altos","CA",37.36,-122.14,29564960],
		["Dallas","TX",32.79,-96.76,29567451],
		["Gillette","WY",44.28,-105.51,29569942],
		["Hilton","NY",43.28,-77.79,29572432],
		["Austin","TX",30.26,-97.74,29574922],
		["Sacramento","CA",38.43,-121.51,29577412],
		["Baraboo","WI",43.46,-89.74,29579901],
		["Binghamton","NY",42.1,-75.91,29582389],
		["Johnstown","PA",40.32,-78.91,29584877],
		["Los Angeles","CA",34.06,-118.35,29587364],
		["Toppenish","WA",46.28,-120.25,29589851],
		["Greenbrier","AR",35.22,-92.38,29592337],
		["Sandy","OR",45.38,-122.23,29594823],
		["Long Valley","NJ",40.78,-74.76,29597308],
		["Saint James","NY",40.87,-73.15,29599792],
		["Zimmerman","MN",45.44,-93.59,29602276],
		["Franklin Park","IL",41.93,-87.87,29604760],
		["Wichita Falls","TX",33.89,-98.51,29607244],
		["Lahaina","HI",20.89,-156.67,29609728],
		["Sherman Oaks","CA",34.14,-118.42,29612211],
		["West Roxbury","MA",42.28,-71.16,29614693],
		["Trenton","NJ",40.22,-74.76,29617175],
		["Marion","IN",40.54,-85.66,29619657],
		["Knoxville","TN",35.97,-83.94,29622138],
		["Rocky River","OH",41.47,-81.85,29624619],
		["White Lake","MI",42.65,-83.5,29627099],
		["New Smyrna Beach","FL",29.02,-80.92,29629578],
		["Harrison","OH",39.24,-84.79,29632057],
		["New Carlisle","OH",39.94,-84.02,29634536],
		["Plaquemine","LA",30.28,-91.24,29637015],
		["El Reno","OK",35.54,-97.96,29639494],
		["Prescott","AZ",34.62,-112.42,29641973],
		["Syracuse","NY",43.04,-76.14,29644451],
		["Lewistown","PA",40.59,-77.57,29646929],
		["Maxton","NC",34.73,-79.35,29649407],
		["Youngstown","OH",41.09,-80.64,29651885],
		["Huntsville","AL",34.7,-86.62,29654362],
		["Seattle","WA",47.68,-122.38,29656839],
		["Jasper","GA",34.46,-84.42,29659315],
		["Clinton Township","MI",42.58,-82.91,29661791],
		["Greenville","MS",33.38,-91.05,29664266],
		["Racine","WI",42.72,-87.8,29666740],
		["Belle Chasse","LA",29.84,-90,29669214],
		["Orland","CA",39.73,-122.33,29671687],
		["Pittsburgh","PA",40.43,-79.97,29674159],
		["Reading","PA",40.33,-75.92,29676631],
		["Manor","TX",30.34,-97.55,29679103],
		["Rochester","NY",43.16,-77.61,29681573],
		["Blue Bell","PA",40.14,-75.26,29684043],
		["Du Bois","PA",41.14,-78.73,29686512],
		["Paris","TN",36.3,-88.3,29688981],
		["Moraga","CA",37.83,-122.1,29691450],
		["Plant City","FL",28,-82.12,29693918],
		["Sacramento","CA",38.66,-121.35,29696386],
		["Keaau","HI",19.61,-155.04,29698854],
		["Berwick","PA",41.05,-76.24,29701321],
		["Niles","OH",41.18,-80.75,29703788],
		["Athens","AL",34.78,-86.95,29706254],
		["Auburn University","AL",32.6,-85.48,29708720],
		["Las Vegas","NV",36.06,-115.27,29711186],
		["Davenport","FL",28.16,-81.6,29713651],
		["Mobile","AL",30.68,-88.04,29716116],
		["Seminole","TX",32.71,-102.65,29718581],
		["Thomson","GA",33.46,-82.5,29721045],
		["Oak Park","IL",41.88,-87.78,29723509],
		["Wichita","KS",37.68,-97.34,29725973],
		["Conroe","TX",30.32,-95.47,29728436],
		["Sterling","CO",40.62,-103.19,29730899],
		["Tucson","AZ",32.16,-110.91,29733361],
		["South Saint Paul","MN",44.88,-93.04,29735821],
		["New Orleans","LA",30.06,-89.93,29738281],
		["Rochester","MN",44.01,-92.47,29740740],
		["Largo","FL",27.9,-82.78,29743198],
		["Toledo","OH",41.66,-83.58,29745656],
		["Maumelle","AR",34.85,-92.41,29748114],
		["Olympia","WA",47.11,-122.76,29750572],
		["Millsboro","DE",38.58,-75.29,29753029],
		["Belton","SC",34.52,-82.49,29755486],
		["Minot","ND",48.23,-101.29,29757943],
		["Spokane","WA",47.62,-117.4,29760400],
		["East Providence","RI",41.8,-71.35,29762856],
		["Palm Harbor","FL",28.08,-82.76,29765312],
		["Monticello","AR",33.62,-91.79,29767768],
		["Fort Myers","FL",26.63,-81.85,29770223],
		["Redmond","WA",47.66,-122.01,29772678],
		["Smyrna","GA",33.86,-84.51,29775132],
		["Garden Grove","CA",33.78,-118.02,29777586],
		["Smithfield","RI",41.9,-71.53,29780039],
		["Dumfries","VA",38.56,-77.32,29782492],
		["Los Angeles","CA",34.05,-118.38,29784945],
		["Rochester","NY",43.16,-77.61,29787397],
		["Chesterfield","VA",37.37,-77.5,29789849],
		["Mosinee","WI",44.78,-89.68,29792301],
		["Concord","MA",42.45,-71.35,29794752],
		["Birmingham","MI",42.54,-83.21,29797203],
		["San Antonio","TX",29.45,-98.5,29799654],
		["Grass Valley","CA",39.11,-121.12,29802105],
		["Jennings","LA",30.22,-92.65,29804555],
		["East Aurora","NY",42.76,-78.61,29807004],
		["Saint Louis","MO",38.63,-90.24,29809453],
		["Pocatello","ID",42.73,-112.52,29811902],
		["Westerly","RI",41.37,-71.81,29814350],
		["Hoboken","NJ",40.74,-74.02,29816798],
		["Bangor","PA",40.86,-75.2,29819246],
		["Gainesville","FL",29.67,-82.33,29821693],
		["Elkhorn","NE",41.28,-96.23,29824140],
		["Cincinnati","OH",39.09,-84.51,29826586],
		["Norfolk","VA",36.84,-76.28,29829031],
		["North Fort Myers","FL",26.63,-81.85,29831476],
		["Sevierville","TN",35.89,-83.57,29833921],
		["Elkins Park","PA",40.07,-75.12,29836365],
		["Hockessin","DE",39.78,-75.68,29838809],
		["Siler City","NC",35.72,-79.46,29841253],
		["Three Rivers","MI",41.94,-85.63,29843697],
		["Scranton","PA",41.4,-75.66,29846140],
		["Summerfield","NC",36.2,-79.89,29848583],
		["Andover","KS",37.69,-97.13,29851026],
		["Sunland","CA",34.26,-118.32,29853469],
		["Alamo","CA",37.84,-121.97,29855912],
		["Gardner","MA",42.58,-71.98,29858354],
		["Bethesda","MD",38.98,-77.12,29860796],
		["Clute","TX",29.02,-95.39,29863238],
		["Cincinnati","OH",39.09,-84.51,29865679],
		["Brookfield","IL",41.82,-87.84,29868120],
		["Winslow","AZ",35.1,-110.64,29870561],
		["Coshocton","OH",40.26,-81.84,29873001],
		["Littleton","CO",39.59,-105.01,29875441],
		["Spotsylvania","VA",38.19,-77.68,29877880],
		["Orange City","FL",28.93,-81.29,29880319],
		["San Juan","PR",18.4,-66.06,29882757],
		["Austin","TX",30.26,-97.74,29885195],
		["Laguna Beach","CA",33.55,-117.78,29887633],
		["Saco","ME",43.53,-70.45,29890070],
		["Fort Morgan","CO",40.26,-103.79,29892507],
		["Wilsonville","OR",45.3,-122.78,29894944],
		["Gansevoort","NY",43.19,-73.64,29897380],
		["Mc Farland","CA",35.65,-119.19,29899816],
		["Asheville","NC",35.57,-82.54,29902251],
		["Tampa","FL",27.95,-82.48,29904686],
		["Fond Du Lac","WI",43.77,-88.44,29907120],
		["Quincy","IL",39.93,-91.38,29909554],
		["Redford","MI",42.39,-83.29,29911987],
		["Los Alamos","NM",35.89,-106.28,29914420],
		["Rex","GA",33.58,-84.27,29916852],
		["Alexandria","LA",31.29,-92.46,29919284],
		["Petersburg","VA",37.2,-77.39,29921715],
		["Nacogdoches","TX",31.61,-94.65,29924146],
		["Hauppauge","NY",40.82,-73.21,29926576],
		["Paradise Valley","AZ",33.54,-111.96,29929006],
		["Wilmington","DE",39.73,-75.53,29931433],
		["Northfield","OH",41.34,-81.52,29933860],
		["Webster","TX",29.53,-95.11,29936287],
		["Clarksville","IN",38.31,-85.76,29938712],
		["Wayne","MI",42.27,-83.38,29941137],
		["Houston","TX",29.76,-95.38,29943562],
		["Belle Mead","NJ",40.46,-74.64,29945986],
		["Richmond","VA",37.55,-77.46,29948410],
		["Cullman","AL",34.17,-86.83,29950834],
		["Wapakoneta","OH",40.57,-84.19,29953258],
		["Baltimore","MD",39.3,-76.61,29955681],
		["Hillsboro","MO",38.23,-90.56,29958104],
		["Palmyra","PA",40.3,-76.59,29960526],
		["Elgin","IL",42.03,-88.28,29962948],
		["Western Springs","IL",41.8,-87.9,29965370],
		["Aledo","TX",32.69,-97.6,29967792],
		["Binghamton","NY",42.1,-75.91,29970213],
		["Selma","NC",35.53,-78.28,29972634],
		["Pickens","SC",34.88,-82.7,29975055],
		["Ashland","KY",38.45,-82.64,29977476],
		["Denville","NJ",40.89,-74.48,29979896],
		["Larchmont","NY",40.92,-73.75,29982316],
		["Silsbee","TX",30.34,-94.18,29984735],
		["Bridgeport","CT",41.18,-73.19,29987153],
		["Smithfield","VA",36.98,-76.61,29989571],
		["Colorado Springs","CO",38.86,-104.76,29991989],
		["Coolidge","AZ",32.97,-111.49,29994407],
		["Dumont","NJ",40.94,-73.99,29996824],
		["Clinton","SC",34.47,-81.86,29999240],
		["Quincy","FL",30.58,-84.58,30001656],
		["Saint Clair Shores","MI",42.49,-82.89,30004072],
		["Corpus Christi","TX",27.8,-97.39,30006488],
		["Lincoln","RI",41.91,-71.45,30008903],
		["Cincinnati","OH",39.09,-84.51,30011318],
		["Mount Olive","NC",35.19,-78.06,30013732],
		["Altoona","IA",41.65,-93.47,30016146],
		["West Hartford","CT",41.75,-72.74,30018559],
		["Hazlet","NJ",40.42,-74.17,30020972],
		["Princeton","MN",45.57,-93.59,30023383],
		["Portland","OR",45.53,-122.6,30025794],
		["Westwood","MA",42.21,-71.21,30028204],
		["Englewood","CO",39.64,-104.99,30030614],
		["Hope","AR",33.66,-93.59,30033023],
		["Gaithersburg","MD",39.14,-77.21,30035431],
		["Hilton Head Island","SC",32.19,-80.74,30037839],
		["Santa Barbara","CA",34.01,-119.72,30040247],
		["Minneapolis","MN",44.96,-93.26,30042654],
		["Renton","WA",47.46,-122.21,30045061],
		["Clearwater","FL",27.97,-82.76,30047467],
		["Pinole","CA",37.99,-122.27,30049873],
		["Saugerties","NY",42.07,-73.94,30052278],
		["Union","MO",38.44,-91.01,30054683],
		["Artesia","CA",33.86,-118.08,30057088],
		["Delmar","NY",42.62,-73.83,30059492],
		["Arab","AL",34.32,-86.5,30061896],
		["Carthage","MS",32.74,-89.53,30064300],
		["Fresno","CA",36.74,-119.79,30066704],
		["Saint Paul","MN",44.94,-93.1,30069106],
		["Los Angeles","CA",34.03,-118.43,30071508],
		["Rensselaer","NY",42.64,-73.73,30073909],
		["Montgomery","TX",30.39,-95.69,30076310],
		["Cornelius","OR",45.52,-123.04,30078711],
		["Somerville","MA",42.39,-71.1,30081111],
		["Florida","PR",18.36,-66.56,30083510],
		["Tuscumbia","AL",34.72,-87.7,30085909],
		["Rhinelander","WI",45.63,-89.41,30088308],
		["San Diego","CA",32.73,-117.24,30090707],
		["Seattle","WA",47.67,-122.34,30093106],
		["Long Beach","MS",30.36,-89.16,30095504],
		["Saint Clair Shores","MI",42.49,-82.89,30097902],
		["Ionia","MI",42.98,-85.05,30100300],
		["Minneapolis","MN",44.96,-93.26,30102698],
		["Seattle","WA",47.44,-122.35,30105096],
		["Milford","DE",38.91,-75.43,30107493],
		["Greeneville","TN",36.16,-82.81,30109890],
		["Newton","IA",41.69,-93.04,30112287],
		["Loma Linda","CA",34.05,-117.24,30114684],
		["Boston","MA",42.35,-71.06,30117080],
		["Bradenton","FL",27.48,-82.57,30119476],
		["Warren","OH",41.23,-80.81,30121872],
		["South Orange","NJ",40.74,-74.26,30124267],
		["Millersville","PA",40,-76.35,30126662],
		["Vinton","VA",37.27,-79.88,30129057],
		["Des Moines","IA",41.57,-93.61,30131452],
		["Sequim","WA",48.06,-123.06,30133847],
		["New Haven","IN",41.06,-85.02,30136241],
		["New Fairfield","CT",41.48,-73.48,30138634],
		["Baton Rouge","LA",30.44,-91.12,30141027],
		["Winnemucca","NV",41.12,-118.05,30143420],
		["Lexington","KY",38.04,-84.45,30145812],
		["Pryor","OK",36.3,-95.32,30148204],
		["Wheat Ridge","CO",39.77,-105.09,30150596],
		["Portland","OR",45.49,-122.77,30152988],
		["Trenton","NJ",40.22,-74.76,30155379],
		["Clarkston","WA",46.36,-117.22,30157770],
		["Nashville","TN",36.17,-86.78,30160160],
		["Willis","TX",30.42,-95.47,30162550],
		["Buckley","WA",47.13,-122.04,30164940],
		["Connellsville","PA",40.01,-79.58,30167329],
		["Hyde Park","NY",41.78,-73.93,30169717],
		["Orlando","FL",28.5,-81.37,30172105],
		["Fergus Falls","MN",46.28,-96.06,30174493],
		["West Mifflin","PA",40.35,-79.9,30176880],
		["Toledo","OH",41.66,-83.58,30179267],
		["Bonaire","GA",32.5,-83.56,30181653],
		["Lafayette","LA",30.21,-92.02,30184039],
		["Prineville","OR",44.1,-120.66,30186425],
		["Pontiac","MI",42.64,-83.28,30188810],
		["Beaumont","TX",30.08,-94.14,30191195],
		["Bonita","CA",32.67,-117.01,30193580],
		["Medfield","MA",42.18,-71.3,30195964],
		["Henderson","NC",36.32,-78.41,30198348],
		["Williston","ND",48.16,-103.63,30200731],
		["Berlin","CT",41.62,-72.77,30203113],
		["Pensacola","FL",30.44,-87.18,30205495],
		["Boca Raton","FL",26.37,-80.1,30207877],
		["Manchester","KY",37.15,-83.76,30210258],
		["La Follette","TN",36.37,-84.12,30212638],
		["Columbus","OH",39.98,-82.98,30215018],
		["Pendleton","IN",40,-85.74,30217398],
		["Lawrence","KS",38.96,-95.25,30219778],
		["Denton","TX",33.21,-97.13,30222158],
		["Rockaway Park","NY",40.57,-73.85,30224537],
		["Tacoma","WA",47.19,-122.44,30226916],
		["Weaverville","NC",35.69,-82.55,30229294],
		["Crossville","TN",35.95,-85.03,30231672],
		["Iowa City","IA",41.65,-91.53,30234050],
		["Tulsa","OK",36.12,-95.91,30236428],
		["Las Vegas","NV",36.31,-115.29,30238806],
		["Foxboro","MA",42.06,-71.24,30241183],
		["Vidalia","GA",32.21,-82.4,30243560],
		["Denver","CO",39.76,-104.87,30245936],
		["Ridgefield","WA",45.79,-122.69,30248312],
		["Muskogee","OK",35.74,-95.35,30250687],
		["Marble Falls","TX",30.57,-98.27,30253061],
		["Salt Lake City","UT",40.73,-111.85,30255435],
		["Lubbock","TX",33.57,-101.87,30257808],
		["Charleston","SC",32.78,-79.99,30260180],
		["Las Vegas","NV",36.09,-115.38,30262551],
		["Paterson","NJ",40.91,-74.16,30264921],
		["Barnesville","GA",33.05,-84.15,30267291],
		["New Port Richey","FL",28.24,-82.71,30269661],
		["Farmington","MI",42.46,-83.37,30272031],
		["Allegan","MI",42.52,-85.84,30274401],
		["Marshall","MO",39.11,-93.2,30276771],
		["Silver City","NM",32.78,-108.26,30279141],
		["San Francisco","CA",37.78,-122.46,30281511],
		["Niagara Falls","NY",43.09,-79.01,30283880],
		["Tulsa","OK",36.12,-95.91,30286249],
		["Watertown","MA",42.36,-71.17,30288617],
		["Ashland City","TN",36.27,-87.05,30290985],
		["Los Angeles","CA",34.1,-118.32,30293353],
		["Mountlake Terrace","WA",47.79,-122.3,30295721],
		["Warwick","RI",41.7,-71.42,30298088],
		["Port Saint Lucie","FL",27.27,-80.35,30300455],
		["Canton","OH",40.81,-81.37,30302822],
		["Prairie Village","KS",38.9,-94.68,30305189],
		["Saint Louis","MO",38.63,-90.24,30307555],
		["Pittsburgh","PA",40.43,-79.97,30309920],
		["Bennettsville","SC",34.63,-79.68,30312285],
		["Monroe","LA",32.51,-92.08,30314650],
		["Makawao","HI",20.85,-156.32,30317015],
		["Seminole","FL",27.84,-82.78,30319379],
		["Columbia","KY",37.09,-85.3,30321743],
		["Pleasantville","NY",41.13,-73.78,30324106],
		["Hazel Park","MI",42.46,-83.09,30326469],
		["Athens","GA",33.95,-83.39,30328831],
		["Pulaski","TN",35.19,-87.03,30331193],
		["Sacramento","CA",38.56,-121.43,30333555],
		["Evansville","IN",37.98,-87.54,30335916],
		["Randleman","NC",35.81,-79.8,30338275],
		["Waterloo","IA",42.49,-92.35,30340634],
		["Hudson","MA",42.39,-71.56,30342992],
		["Muscle Shoals","AL",34.74,-87.64,30345350],
		["Plainwell","MI",42.44,-85.64,30347708],
		["Excelsior Springs","MO",39.35,-94.23,30350066],
		["Rayne","LA",30.23,-92.26,30352424],
		["Lawton","OK",34.6,-98.42,30354782],
		["Beaumont","TX",30.08,-94.14,30357139],
		["Albuquerque","NM",35.11,-106.62,30359496],
		["Grand Bay","AL",30.47,-88.34,30361852],
		["Hillsdale","MI",41.92,-84.63,30364208],
		["Hoschton","GA",34.09,-83.76,30366563],
		["Chickamauga","GA",34.87,-85.29,30368918],
		["Cambria Heights","NY",40.69,-73.73,30371272],
		["Largo","FL",27.9,-82.78,30373626],
		["Johnson City","TN",36.33,-82.36,30375980],
		["Rutland","VT",43.6,-72.97,30378333],
		["Bloomfield","CT",41.81,-72.73,30380686],
		["Erie","PA",42.12,-80.08,30383039],
		["Petoskey","MI",45.36,-84.95,30385392],
		["Arkadelphia","AR",34.12,-93.07,30387745],
		["Lexington","TN",35.65,-88.39,30390097],
		["Albion","MI",42.24,-84.75,30392449],
		["Dallas","TX",32.79,-96.76,30394801],
		["Bryan","TX",30.66,-96.36,30397153],
		["Bedford","VA",37.32,-79.52,30399504],
		["Chandler","AZ",33.24,-111.96,30401855],
		["Ramsey","NJ",41.05,-74.14,30404205],
		["Stoneham","MA",42.47,-71.09,30406554],
		["Grand Forks","ND",47.91,-97.07,30408903],
		["Santa Barbara","CA",34.43,-119.68,30411252],
		["Melbourne","FL",28.08,-80.6,30413600],
		["Manorville","NY",40.85,-72.79,30415947],
		["Huntsville","AL",34.7,-86.62,30418291],
		["Louisville","KY",38.22,-85.74,30420635],
		["Lees Summit","MO",38.91,-94.38,30422979],
		["Kansas City","MO",39.09,-94.58,30425323],
		["Helena","AL",33.28,-86.85,30427666],
		["Tulsa","OK",36.12,-95.91,30430009],
		["San Bernardino","CA",34.07,-117.25,30432352],
		["Tolland","CT",41.98,-72.15,30434693],
		["Los Angeles","CA",34.06,-118.24,30437034],
		["Spokane","WA",47.65,-117.31,30439375],
		["Kenner","LA",29.99,-90.24,30441715],
		["Bucyrus","OH",40.8,-82.97,30444054],
		["Albany","NY",42.66,-73.79,30446392],
		["Willow Grove","PA",40.14,-75.11,30448730],
		["Naples","FL",26.14,-81.79,30451067],
		["Perryville","MO",37.72,-89.87,30453404],
		["Pasadena","CA",34.13,-118.13,30455741],
		["Springfield","MA",42.11,-72.53,30458077],
		["Washington","DC",38.89,-77.03,30460413],
		["Park Hills","MO",37.84,-90.52,30462749],
		["Mountain View","CA",37.41,-122.05,30465085],
		["East Islip","NY",40.72,-73.18,30467420],
		["Adairsville","GA",34.36,-84.91,30469755],
		["Fort Myers","FL",26.63,-81.85,30472090],
		["Belgrade","MT",45.81,-111.15,30474425],
		["Seattle","WA",47.73,-122.37,30476760],
		["Holly Springs","MS",34.77,-89.44,30479094],
		["Red Wing","MN",44.58,-92.6,30481428],
		["Oklahoma City","OK",35.46,-97.51,30483762],
		["Ottawa","KS",38.6,-95.26,30486095],
		["Kenai","AK",60.66,-150.9,30488428],
		["Baxley","GA",31.76,-82.35,30490760],
		["Tupelo","MS",34.26,-88.73,30493092],
		["Farmington","MI",42.46,-83.37,30495423],
		["Mount Kisco","NY",41.2,-73.73,30497753],
		["Dillsburg","PA",40.11,-77.03,30500083],
		["Hoffman Estates","IL",42.03,-88.08,30502413],
		["Camden","NJ",39.93,-75.1,30504742],
		["Harper Woods","MI",42.43,-82.92,30507071],
		["Benton","KY",36.85,-88.35,30509399],
		["Decatur","TX",33.23,-97.59,30511726],
		["Glendale","CA",34.16,-118.26,30514053],
		["Selinsgrove","PA",40.8,-76.86,30516379],
		["Grain Valley","MO",39,-94.2,30518705],
		["Falls Church","VA",38.88,-77.17,30521030],
		["Charlotte","NC",35.19,-80.83,30523355],
		["Lugoff","SC",34.22,-80.68,30525680],
		["Show Low","AZ",34.23,-110.07,30528005],
		["Saint Helens","OR",45.88,-122.92,30530328],
		["Wilmington","NC",34.21,-77.91,30532650],
		["Holiday","FL",28.18,-82.74,30534972],
		["Sheppard Afb","TX",33.98,-98.5,30537293],
		["Pittsburgh","PA",40.43,-79.97,30539613],
		["Hampstead","NC",34.36,-77.71,30541933],
		["Columbia","MS",31.25,-89.82,30544253],
		["Baton Rouge","LA",30.44,-91.12,30546573],
		["Fort Sill","OK",34.65,-98.4,30548893],
		["El Segundo","CA",33.91,-118.4,30551213],
		["Princess Anne","MD",38.2,-75.69,30553532],
		["Canton","NY",44.59,-75.17,30555850],
		["Fort Wayne","IN",41.07,-85.13,30558168],
		["Scottsbluff","NE",41.86,-103.66,30560486],
		["Arlington","MA",42.41,-71.16,30562803],
		["Schaumburg","IL",42.03,-88.08,30565120],
		["Las Vegas","NV",36.14,-115.22,30567437],
		["Cohoes","NY",42.77,-73.7,30569753],
		["Savannah","TN",35.22,-88.23,30572069],
		["Kearney","MO",39.36,-94.36,30574385],
		["Waco","TX",31.56,-97.18,30576701],
		["Abington","PA",40.12,-75.12,30579016],
		["Lima","OH",40.74,-84.11,30581331],
		["Comstock Park","MI",43.04,-85.67,30583646],
		["Hopkins","MN",44.92,-93.4,30585961],
		["Seattle","WA",47.58,-122.3,30588276],
		["Sumter","SC",33.94,-80.39,30590590],
		["Zapata","TX",26.89,-99.26,30592904],
		["Willow Spring","NC",35.54,-78.66,30595217],
		["Louisville","KY",38.22,-85.74,30597530],
		["Oberlin","OH",41.29,-82.21,30599843],
		["Washington","IN",38.65,-87.17,30602156],
		["Roseburg","OR",43.21,-123.4,30604469],
		["Buffalo","NY",42.88,-78.85,30606781],
		["Killeen","TX",31.09,-97.71,30609093],
		["Cape May Court House","NJ",39.07,-74.82,30611404],
		["Duluth","MN",46.78,-92.11,30613715],
		["Omaha","NE",41.26,-96.01,30616026],
		["Roosevelt","NY",40.67,-73.58,30618336],
		["Towson","MD",39.39,-76.62,30620646],
		["Denver","NC",35.53,-81.03,30622956],
		["Spartanburg","SC",34.94,-81.92,30625266],
		["New Albany","MS",34.49,-89.02,30627576],
		["Lehigh Acres","FL",26.6,-81.63,30629885],
		["Concord","CA",37.99,-122,30632194],
		["Molalla","OR",45.07,-122.55,30634503],
		["Dover","OH",40.52,-81.47,30636811],
		["Cloquet","MN",46.72,-92.49,30639119],
		["Wabash","IN",40.79,-85.82,30641425],
		["New Richmond","WI",45.12,-92.53,30643731],
		["Louisville","KY",38.22,-85.74,30646036],
		["Haysville","KS",37.56,-97.34,30648341],
		["Fort Atkinson","WI",42.92,-88.84,30650645],
		["North Little Rock","AR",34.78,-92.25,30652949],
		["Scotts Valley","CA",37.06,-122.01,30655253],
		["Mastic Beach","NY",40.76,-72.84,30657556],
		["Macon","GA",32.83,-83.65,30659859],
		["Sarasota","FL",27.33,-82.54,30662162],
		["Springfield","OH",39.92,-83.79,30664464],
		["Malibu","CA",34.06,-118.81,30666766],
		["Brooklyn","NY",40.64,-73.94,30669067],
		["Houston","TX",29.76,-95.38,30671368],
		["Boulder","CO",40.02,-105.25,30673669],
		["Cincinnati","OH",39.09,-84.51,30675968],
		["Pella","IA",41.4,-92.91,30678267],
		["Yankton","SD",42.88,-97.39,30680566],
		["Auburn","GA",34.01,-83.83,30682864],
		["Liberty","TX",30.04,-94.79,30685162],
		["East Falmouth","MA",41.56,-70.55,30687459],
		["Hatfield","PA",40.27,-75.29,30689756],
		["Tacoma","WA",47.26,-122.51,30692053],
		["Wimauma","FL",27.71,-82.3,30694349],
		["Signal Mountain","TN",35.13,-85.34,30696645],
		["Encino","CA",34.15,-118.52,30698941],
		["Hanover","MA",42.11,-70.81,30701236],
		["Jewett City","CT",41.6,-71.98,30703531],
		["Newtown Square","PA",39.97,-75.43,30705826],
		["Weirton","WV",40.4,-80.56,30708121],
		["Eatonton","GA",33.32,-83.38,30710416],
		["Jesup","GA",31.59,-81.88,30712711],
		["Gainesville","FL",29.67,-82.33,30715006],
		["Andrews","TX",32.32,-102.54,30717301],
		["Atlanta","GA",33.85,-84.28,30719595],
		["Knoxville","TN",35.97,-83.94,30721889],
		["Byhalia","MS",34.86,-89.68,30724183],
		["Boaz","AL",34.2,-86.15,30726476],
		["Sparta","WI",43.94,-90.8,30728769],
		["Hanover","NH",43.7,-72.27,30731061],
		["Islip","NY",40.73,-73.21,30733353],
		["Hampstead","MD",39.61,-76.85,30735645],
		["Morristown","TN",36.21,-83.29,30737937],
		["Dallas","PA",41.33,-75.96,30740228],
		["Rutherford","NJ",40.81,-74.1,30742518],
		["Schwenksville","PA",40.25,-75.46,30744807],
		["Emeryville","CA",37.83,-122.29,30747096],
		["Evanston","WY",41.25,-110.96,30749384],
		["Fresno","CA",36.75,-119.81,30751672],
		["Denver","PA",40.23,-76.13,30753959],
		["Kensington","MD",39.02,-77.07,30756246],
		["Havre De Grace","MD",39.54,-76.09,30758533],
		["Holland","OH",41.62,-83.7,30760820],
		["La Vista","NE",41.18,-96.03,30763107],
		["Ashland","VA",37.76,-77.47,30765393],
		["San Antonio","TX",29.45,-98.5,30767679],
		["Phoenix","AZ",33.56,-112,30769965],
		["Cottonwood","CA",40.33,-122.45,30772251],
		["Payson","AZ",34.19,-111.17,30774536],
		["Millbrook","AL",32.49,-86.36,30776820],
		["Howell","MI",42.69,-83.88,30779104],
		["Beacon","NY",41.5,-73.96,30781387],
		["Cuyahoga Falls","OH",41.16,-81.52,30783670],
		["Galion","OH",40.73,-82.79,30785953],
		["Lindon","UT",40.32,-111.74,30788236],
		["Burbank","CA",34.17,-118.32,30790519],
		["Cherry Hill","NJ",39.9,-74.99,30792801],
		["Clive","IA",41.6,-93.76,30795083],
		["Overland Park","KS",38.9,-94.68,30797365],
		["Wallkill","NY",41.6,-74.16,30799646],
		["Huntsville","AL",34.7,-86.62,30801927],
		["Troy","AL",31.8,-85.96,30804208],
		["Saint Paul","MN",44.94,-93.1,30806489],
		["Rantoul","IL",40.3,-88.15,30808770],
		["North Adams","MA",42.68,-73.11,30811050],
		["Minneapolis","MN",44.96,-93.26,30813330],
		["Waterloo","IL",38.33,-90.15,30815610],
		["Dallas","TX",32.79,-96.76,30817889],
		["Lowell","MA",42.63,-71.32,30820167],
		["Bellerose","NY",40.72,-73.71,30822443],
		["Douglassville","PA",40.26,-75.74,30824719],
		["Carriere","MS",30.6,-89.56,30826995],
		["Charlottesville","VA",38.03,-78.48,30829270],
		["Ashland","KY",38.45,-82.64,30831545],
		["Miami","FL",25.77,-80.2,30833818],
		["Waynesboro","MS",31.67,-88.64,30836091],
		["La Grande","OR",45.32,-118.2,30838364],
		["Lynn","MA",42.47,-70.96,30840636],
		["Chapel Hill","NC",35.84,-79.03,30842908],
		["Highland","IL",38.73,-89.69,30845180],
		["Dripping Springs","TX",30.18,-98.08,30847452],
		["Jacksonville Beach","FL",30.29,-81.39,30849723],
		["Dover","FL",27.99,-82.22,30851994],
		["Lehigh Acres","FL",26.6,-81.68,30854265],
		["Eufaula","AL",31.9,-85.15,30856536],
		["Auburn","CA",39,-121.09,30858807],
		["Cottage Grove","OR",43.71,-123.03,30861078],
		["Milwaukee","WI",43.06,-87.96,30863348],
		["Palestine","TX",31.75,-95.64,30865618],
		["Springfield","VA",38.78,-77.17,30867887],
		["Spring Creek","NV",40.55,-115.61,30870156],
		["Santa Barbara","CA",34.45,-119.8,30872424],
		["Half Moon Bay","CA",37.44,-122.41,30874692],
		["Evansville","IN",37.98,-87.54,30876959],
		["Eureka","MO",38.5,-90.64,30879226],
		["Palestine","TX",31.75,-95.64,30881493],
		["West Chester","PA",39.95,-75.6,30883759],
		["Pompano Beach","FL",26.23,-80.13,30886025],
		["Missoula","MT",46.98,-114.12,30888291],
		["Sayreville","NJ",40.46,-74.32,30890556],
		["North East","MD",39.6,-75.94,30892821],
		["Forsyth","GA",33.03,-83.94,30895086],
		["Wauchula","FL",27.54,-81.8,30897351],
		["Marshall","MN",44.45,-95.78,30899616],
		["Chappaqua","NY",41.17,-73.77,30901880],
		["Olean","NY",42.08,-78.43,30904144],
		["Elmira","NY",42.08,-76.8,30906408],
		["Towson","MD",39.3,-76.61,30908672],
		["Chattanooga","TN",35.06,-85.25,30910936],
		["Portage","MI",42.2,-85.59,30913200],
		["Markham","IL",41.6,-87.69,30915464],
		["Albany","CA",37.88,-122.29,30917728],
		["Radford","VA",37.12,-80.55,30919991],
		["Lorain","OH",41.44,-82.18,30922254],
		["Lovington","NM",32.94,-103.35,30924517],
		["San Francisco","CA",37.79,-122.42,30926780],
		["Sayville","NY",40.74,-73.08,30929042],
		["Covington","TN",35.56,-89.64,30931303],
		["Gatesville","TX",31.44,-97.73,30933564],
		["Levittown","PA",40.15,-74.85,30935823],
		["Mobile","AL",30.68,-88.04,30938082],
		["Milford","PA",41.32,-74.8,30940340],
		["New Orleans","LA",30.06,-89.93,30942598],
		["Hurricane","UT",37.13,-112.97,30944856],
		["Tucson","AZ",32.21,-110.75,30947114],
		["Silverton","OR",44.95,-122.71,30949372],
		["Lexington","MA",42.44,-71.23,30951629],
		["Johnson City","NY",42.11,-75.96,30953886],
		["Newport","KY",39.08,-84.48,30956143],
		["Los Angeles","CA",33.99,-118.14,30958400],
		["Bloomington","IN",39.16,-86.52,30960655],
		["Saint Louis","MO",38.63,-90.24,30962910],
		["Dallas","TX",32.79,-96.76,30965165],
		["Dallas","NC",35.31,-81.17,30967419],
		["Waltham","MA",42.38,-71.24,30969672],
		["Scottsville","KY",36.74,-86.19,30971925],
		["Johnstown","PA",40.32,-78.91,30974177],
		["Exton","PA",40.03,-75.63,30976429],
		["Danville","IN",39.76,-86.51,30978681],
		["Elmwood Park","NJ",40.9,-74.11,30980932],
		["Purchase","NY",41.03,-73.71,30983183],
		["Wixom","MI",42.52,-83.53,30985434],
		["Channahon","IL",41.43,-88.21,30987685],
		["Coto Laurel","PR",18.09,-66.57,30989935],
		["Hawthorne","NJ",40.95,-74.15,30992185],
		["Waynesville","NC",35.48,-82.99,30994435],
		["Lexington","KY",38.04,-84.45,30996685],
		["Massillon","OH",40.78,-81.52,30998935],
		["Fort Worth","TX",32.75,-97.33,31001185],
		["Chinle","AZ",36.23,-109.57,31003435],
		["Buffalo","NY",42.88,-78.85,31005684],
		["Charles Town","WV",39.28,-77.85,31007933],
		["Lincoln","NE",40.81,-96.68,31010182],
		["Haddonfield","NJ",39.89,-75.03,31012430],
		["Berlin","MD",38.32,-75.21,31014678],
		["La Palma","CA",33.85,-118.04,31016926],
		["Boston","MA",42.35,-71.06,31019173],
		["Lake City","FL",30.19,-82.64,31021420],
		["Lutz","FL",28.13,-82.46,31023667],
		["Carolina","PR",18.4,-65.98,31025913],
		["Pensacola","FL",30.44,-87.18,31028158],
		["Mexico","MO",39.16,-91.87,31030402],
		["Wichita","KS",37.68,-97.34,31032646],
		["Boise","ID",43.54,-115.92,31034890],
		["Houston","TX",29.76,-95.38,31037133],
		["Vicksburg","MS",32.32,-90.87,31039375],
		["Minooka","IL",41.44,-88.25,31041617],
		["Wilbraham","MA",42.13,-72.43,31043858],
		["Northborough","MA",42.31,-71.64,31046099],
		["Ellijay","GA",34.69,-84.48,31048340],
		["Detroit","MI",42.38,-83.1,31050581],
		["Oregon","WI",42.92,-89.38,31052822],
		["Philadelphia","PA",39.95,-75.16,31055062],
		["Bellevue","WA",47.62,-122.14,31057302],
		["Buffalo","NY",42.88,-78.85,31059541],
		["Huntington","WV",38.41,-82.43,31061780],
		["Waynesboro","GA",33.09,-82.01,31064019],
		["Whitehouse","TX",32.22,-95.21,31066258],
		["Rathdrum","ID",47.84,-116.88,31068496],
		["Spokane","WA",47.74,-117.25,31070734],
		["Chattanooga","TN",35.06,-85.25,31072971],
		["Forest","MS",32.35,-89.47,31075207],
		["Jackson","OH",39.04,-82.62,31077443],
		["San Antonio","TX",29.45,-98.5,31079679],
		["Mountain Top","PA",41.16,-75.9,31081914],
		["Brewton","AL",31.11,-87.07,31084149],
		["Dexter","MI",42.33,-83.87,31086384],
		["Missoula","MT",46.76,-113.97,31088619],
		["Orosi","CA",36.58,-119.19,31090854],
		["Georgetown","DE",38.69,-75.38,31093088],
		["Fort Payne","AL",34.45,-85.69,31095322],
		["Troy","IL",38.73,-89.89,31097556],
		["Fairbanks","AK",64.81,-147.72,31099790],
		["Dayton","OH",39.77,-84.19,31102023],
		["Weston","MA",42.36,-71.26,31104255],
		["Bessemer","AL",33.37,-86.96,31106487],
		["Portland","OR",45.54,-122.64,31108719],
		["East Longmeadow","MA",42.06,-72.51,31110950],
		["Miami","FL",25.78,-80.41,31113181],
		["Bordentown","NJ",40.14,-74.7,31115411],
		["Hanahan","SC",32.91,-80.01,31117641],
		["West Palm Beach","FL",26.74,-80.12,31119871],
		["Rogers","MN",45.19,-93.55,31122101],
		["Saint Louis","MO",38.63,-90.24,31124331],
		["Salt Lake City","UT",40.78,-111.73,31126561],
		["San Diego","CA",32.76,-117.12,31128791],
		["Gainesville","FL",29.67,-82.33,31131020],
		["Nashville","TN",36.17,-86.78,31133249],
		["Corpus Christi","TX",27.8,-97.39,31135478],
		["Austin","TX",30.26,-97.74,31137707],
		["Villanova","PA",40.03,-75.35,31139935],
		["San Pedro","CA",33.73,-118.31,31142163],
		["Metairie","LA",29.99,-90.17,31144390],
		["Hormigueros","PR",18.14,-67.12,31146616],
		["Altamonte Springs","FL",28.66,-81.39,31148842],
		["Atmore","AL",31.02,-87.49,31151068],
		["Hampton","VA",37.04,-76.29,31153293],
		["Webb City","MO",37.14,-94.47,31155518],
		["Stockton","CA",37.95,-121.32,31157743],
		["Bethesda","MD",38.98,-77.12,31159967],
		["Auburn Hills","MI",42.67,-83.24,31162191],
		["Burlington","WA",48.53,-122.31,31164414],
		["Liverpool","NY",43.1,-76.21,31166636],
		["Seminole","FL",27.9,-82.78,31168858],
		["Wichita Falls","TX",33.89,-98.51,31171080],
		["Spartanburg","SC",34.94,-81.92,31173301],
		["Franklin","LA",29.8,-91.51,31175522],
		["Clarksville","AR",35.45,-93.47,31177743],
		["Mount Vernon","WA",48.36,-121.98,31179964],
		["Pottstown","PA",40.25,-75.64,31182184],
		["Sebring","FL",27.49,-81.45,31184404],
		["Saint John","IN",41.44,-87.47,31186624],
		["Rochelle","IL",41.91,-89.06,31188843],
		["Coweta","OK",35.96,-95.66,31191062],
		["Summerfield","FL",29,-82.03,31193280],
		["Sellersburg","IN",38.39,-85.75,31195498],
		["Wesley Chapel","FL",28.28,-82.29,31197715],
		["Bloomfield Hills","MI",42.57,-83.24,31199931],
		["Manchester","CT",41.79,-72.53,31202146],
		["Elmira","NY",42.08,-76.8,31204361],
		["Saint Louis","MO",38.63,-90.24,31206576],
		["Port Arthur","TX",29.89,-93.92,31208791],
		["Dandridge","TN",36.02,-83.41,31211005],
		["Franklin","KY",36.72,-86.57,31213219],
		["Ortonville","MI",42.85,-83.44,31215433],
		["Wauconda","IL",42.26,-88.13,31217647],
		["Henderson","NV",36.09,-114.9,31219861],
		["Winston Salem","NC",36.1,-80.24,31222074],
		["Lake Placid","FL",27.29,-81.36,31224287],
		["Trenton","OH",39.47,-84.46,31226500],
		["Raymondville","TX",26.47,-97.78,31228713],
		["Minneapolis","MN",44.96,-93.26,31230925],
		["Atchison","KS",39.56,-95.13,31233137],
		["Farmington","CT",41.71,-72.83,31235348],
		["Cortland","OH",41.33,-80.72,31237559],
		["Grafton","WI",43.31,-87.95,31239770],
		["Saint Peter","MN",44.32,-93.96,31241981],
		["Atlanta","GA",33.76,-84.42,31244191],
		["Owens Cross Roads","AL",34.6,-86.46,31246401],
		["Linden","MI",42.81,-83.78,31248611],
		["Decorah","IA",43.3,-91.79,31250821],
		["Belleville","IL",38.53,-90,31253031],
		["Covington","LA",30.47,-90.11,31255241],
		["Waterbury","CT",41.55,-73.03,31257450],
		["Spartanburg","SC",34.94,-81.92,31259659],
		["Brookhaven","PA",39.87,-75.39,31261867],
		["Anniston","AL",33.66,-85.81,31264075],
		["Independence","MO",39.08,-94.35,31266283],
		["North Reading","MA",42.56,-71.06,31268490],
		["Morris Plains","NJ",40.83,-74.48,31270697],
		["Lawrence","KS",38.96,-95.25,31272904],
		["Jenks","OK",36,-95.97,31275111],
		["Huntington","WV",38.41,-82.43,31277317],
		["Durham","NC",35.98,-78.91,31279523],
		["West Palm Beach","FL",26.74,-80.12,31281729],
		["Arlington","TX",32.69,-97.12,31283935],
		["Sunnyside","NY",40.74,-73.92,31286140],
		["Philadelphia","PA",39.95,-75.16,31288345],
		["Tulsa","OK",36.12,-95.91,31290550],
		["Tuba City","AZ",36.09,-111.13,31292755],
		["King Of Prussia","PA",40.09,-75.38,31294959],
		["Cynthiana","KY",38.38,-84.29,31297163],
		["Bay Village","OH",41.48,-81.92,31299367],
		["Jenkintown","PA",40.09,-75.12,31301570],
		["Galax","VA",36.66,-80.91,31303773],
		["Harriman","TN",35.93,-84.56,31305976],
		["Henderson","TN",35.44,-88.64,31308179],
		["Akron","OH",41.08,-81.52,31310382],
		["Lake Orion","MI",42.78,-83.24,31312585],
		["Los Angeles","CA",34.04,-118.44,31314788],
		["San Marino","CA",34.12,-118.11,31316991],
		["Boca Raton","FL",26.37,-80.1,31319193],
		["Saint Louis","MO",38.63,-90.24,31321395],
		["Fort Worth","TX",32.75,-97.33,31323597],
		["Pinellas Park","FL",27.85,-82.7,31325796],
		["Fort Myers","FL",26.63,-81.85,31327995],
		["Minneapolis","MN",44.96,-93.26,31330194],
		["Rockland","MA",42.13,-70.91,31332392],
		["Southbridge","MA",42.08,-72.03,31334589],
		["Tulsa","OK",36.12,-95.91,31336786],
		["Canton","NC",35.54,-82.84,31338982],
		["Hebron","KY",39.08,-84.7,31341178],
		["Cleveland","TX",30.34,-95.07,31343374],
		["Erie","PA",42.12,-80.08,31345569],
		["Columbia","PA",40.03,-76.49,31347763],
		["Fort Pierce","FL",27.44,-80.32,31349957],
		["White House","TN",36.46,-86.66,31352151],
		["Youngstown","OH",41.09,-80.64,31354345],
		["Shreveport","LA",32.47,-93.79,31356539],
		["Bloomfield Hills","MI",42.57,-83.24,31358731],
		["Justin","TX",33.08,-97.29,31360923],
		["Vero Beach","FL",27.64,-80.39,31363114],
		["Kapaa","HI",22.09,-159.35,31365305],
		["Marshfield","MO",37.33,-92.91,31367495],
		["Cape May","NJ",38.94,-74.9,31369683],
		["Birmingham","AL",33.52,-86.79,31371871],
		["Alamosa","CO",37.46,-105.87,31374059],
		["Dallas","TX",32.79,-96.76,31376245],
		["Marshall","TX",32.53,-94.35,31378431],
		["Princeton","TX",33.18,-96.5,31380616],
		["Beaumont","TX",30.08,-94.14,31382801],
		["Spring Branch","TX",29.86,-98.4,31384986],
		["Snyder","TX",32.71,-100.91,31387171],
		["Prosser","WA",46.12,-119.68,31389356],
		["Windham","ME",43.79,-70.4,31391540],
		["Morrisville","NC",35.83,-78.83,31393724],
		["North Aurora","IL",41.8,-88.33,31395908],
		["Moberly","MO",39.41,-92.43,31398092],
		["Fruita","CO",39.15,-108.72,31400276],
		["Miller Place","NY",40.93,-72.98,31402459],
		["Washington","DC",38.89,-77.03,31404641],
		["Damascus","MD",39.27,-77.2,31406823],
		["Lexington","KY",38.04,-84.45,31409005],
		["Syracuse","NY",43.04,-76.14,31411186],
		["Jeannette","PA",40.33,-79.62,31413367],
		["Englewood","CO",39.64,-104.96,31415548],
		["Brevard","NC",35.23,-82.73,31417728],
		["Elberton","GA",34.1,-82.86,31419908],
		["Healdsburg","CA",38.63,-122.89,31422088],
		["Lehighton","PA",40.83,-75.71,31424267],
		["Birdsboro","PA",40.26,-75.81,31426446],
		["Leonardtown","MD",38.29,-76.64,31428625],
		["Charlestown","IN",38.45,-85.66,31430804],
		["Alma","AR",35.48,-94.22,31432983],
		["Wilmington","DE",39.73,-75.53,31435161],
		["Aurora","IL",41.71,-88.26,31437339],
		["Fort Lauderdale","FL",26.14,-80.13,31439516],
		["North Salt Lake","UT",40.84,-111.9,31441692],
		["Woodbridge","NJ",40.55,-74.28,31443867],
		["Wilmington","DE",39.73,-75.53,31446042],
		["Marion","SC",34.17,-79.4,31448217],
		["Mobile","AL",30.68,-88.04,31450391],
		["Sallisaw","OK",35.46,-94.8,31452565],
		["Morton","IL",40.61,-89.46,31454738],
		["Reading","PA",40.33,-75.92,31456910],
		["Jamestown","NC",35.99,-79.93,31459082],
		["Shawnee","KS",38.9,-94.68,31461254],
		["Chalmette","LA",29.94,-89.96,31463426],
		["Woods Cross","UT",40.89,-111.93,31465598],
		["Medway","MA",42.13,-71.4,31467769],
		["Lyndhurst","NJ",40.79,-74.11,31469940],
		["Selma","AL",32.41,-87.03,31472111],
		["Harrisonville","MO",38.65,-94.34,31474282],
		["Allentown","PA",40.59,-75.47,31476452],
		["Lumberton","NC",34.63,-79.01,31478622],
		["Wichita Falls","TX",33.89,-98.51,31480792],
		["Studio City","CA",34.13,-118.39,31482962],
		["Conneaut","OH",41.92,-80.57,31485131],
		["La Feria","TX",26.15,-97.82,31487300],
		["Chesapeake","VA",36.67,-76.3,31489468],
		["Camby","IN",39.63,-86.3,31491636],
		["Fort Myers","FL",26.63,-81.85,31493803],
		["Sandpoint","ID",48.34,-116.45,31495970],
		["Oil City","PA",41.42,-79.7,31498136],
		["Palm Bay","FL",27.98,-80.66,31500302],
		["Cincinnati","OH",39.09,-84.51,31502468],
		["Tallahassee","FL",30.45,-84.28,31504633],
		["Fort Thomas","KY",39.08,-84.45,31506798],
		["Vermilion","OH",41.41,-82.31,31508963],
		["Chester Springs","PA",40.1,-75.64,31511127],
		["Brownsville","TN",35.59,-89.25,31513291],
		["Walton","KY",38.86,-84.61,31515455],
		["Milford","NH",42.83,-71.66,31517618],
		["Wichita","KS",37.68,-97.34,31519781],
		["Miami","FL",25.77,-80.2,31521943],
		["Escondido","CA",33.08,-117.13,31524105],
		["Escanaba","MI",45.74,-87.08,31526266],
		["Boulder","CO",40.02,-105.25,31528427],
		["Falls Church","VA",38.88,-77.17,31530587],
		["North Port","FL",27.04,-82.19,31532747],
		["Jacksonville","FL",30.33,-81.65,31534906],
		["Anderson","IN",40.09,-85.68,31537065],
		["Little Rock","AR",34.72,-92.35,31539224],
		["Greenwood","AR",35.21,-94.23,31541383],
		["Price","UT",39.57,-110.8,31543542],
		["Spokane","WA",47.63,-117.55,31545701],
		["Farmville","VA",37.29,-78.39,31547858],
		["Lakeland","FL",27.97,-81.89,31550015],
		["Emmett","ID",43.98,-116.52,31552172],
		["Truckee","CA",39.33,-120.24,31554329],
		["Minneapolis","MN",44.96,-93.26,31556485],
		["Pleasanton","TX",28.96,-98.49,31558641],
		["Hartford","CT",41.76,-72.68,31560796],
		["Homestead","PA",40.4,-79.9,31562950],
		["Kansas City","MO",39.09,-94.58,31565104],
		["West Sacramento","CA",38.59,-121.54,31567258],
		["Apache Junction","AZ",33.38,-111.51,31569411],
		["Nesconset","NY",40.84,-73.15,31571563],
		["Boone","IA",42.05,-93.87,31573715],
		["Rockport","TX",28.04,-97.05,31575867],
		["Augusta","GA",33.45,-81.99,31578018],
		["Fort Myers","FL",26.63,-81.85,31580169],
		["Saucier","MS",30.63,-89.13,31582320],
		["Santa Monica","CA",34.01,-118.46,31584471],
		["Mckinleyville","CA",40.94,-124.02,31586622],
		["Osceola","IN",41.66,-86.07,31588772],
		["Earlimart","CA",35.86,-119.28,31590922],
		["Trenton","NJ",40.22,-74.76,31593071],
		["Ellwood City","PA",40.86,-80.28,31595220],
		["Columbia","SC",34,-81.03,31597369],
		["Jacksonville","FL",30.33,-81.65,31599518],
		["Corydon","IN",38.21,-86.12,31601667],
		["Bridgeview","IL",41.73,-87.8,31603816],
		["Tracy","CA",37.69,-121.39,31605965],
		["Akron","OH",41.08,-81.52,31608113],
		["Edcouch","TX",26.29,-97.96,31610261],
		["Des Moines","IA",41.57,-93.61,31612408],
		["Hudson","NY",42.25,-73.78,31614554],
		["Loris","SC",34.05,-78.89,31616700],
		["Sauk Rapids","MN",45.59,-94.16,31618846],
		["North Babylon","NY",40.73,-73.32,31620991],
		["Union City","TN",36.42,-89.05,31623136],
		["Cincinnati","OH",39.09,-84.51,31625281],
		["North Branch","MN",45.51,-92.97,31627426],
		["Galveston","TX",29.3,-94.79,31629571],
		["Poplarville","MS",30.83,-89.53,31631715],
		["Jasper","TX",30.92,-93.99,31633859],
		["New Bedford","MA",41.66,-70.93,31636001],
		["Victor","NY",42.98,-77.41,31638143],
		["Kendallville","IN",41.44,-85.25,31640284],
		["Warrenton","MO",38.82,-91.14,31642425],
		["Arlington","TX",32.69,-97.12,31644566],
		["Kendall Park","NJ",40.41,-74.56,31646706],
		["Trinity","NC",35.88,-80.01,31648846],
		["Mount Joy","PA",40.11,-76.5,31650984],
		["Destrehan","LA",29.96,-90.36,31653122],
		["Brookline","MA",42.32,-71.14,31655259],
		["Greenwich","CT",41.06,-73.63,31657396],
		["Madisonville","TN",35.52,-84.35,31659533],
		["Salem","IN",38.6,-86.09,31661670],
		["Borger","TX",35.65,-101.4,31663807],
		["Denver","CO",39.76,-104.87,31665944],
		["Horsham","PA",40.18,-75.13,31668080],
		["Belleview","FL",29.05,-82.05,31670216],
		["Edmond","OK",35.65,-97.47,31672351],
		["North Providence","RI",41.86,-71.45,31674485],
		["Emmaus","PA",40.53,-75.49,31676619],
		["Saint Cloud","FL",28.24,-81.28,31678753],
		["Staten Island","NY",40.58,-74.14,31680884],
		["Madison Heights","VA",37.43,-79.1,31683015],
		["Carthage","NC",35.34,-79.41,31685146],
		["Boise","ID",43.71,-116.18,31687277],
		["Levittown","PA",40.15,-74.85,31689407],
		["Gray","GA",33,-83.53,31691537],
		["Naples","FL",26.14,-81.79,31693666],
		["Chattanooga","TN",35.06,-85.25,31695795],
		["Veradale","WA",47.62,-117.18,31697924],
		["Middletown","NY",41.44,-74.42,31700051],
		["River Forest","IL",41.89,-87.81,31702178],
		["Dallas","TX",32.79,-96.76,31704305],
		["Plainville","CT",41.66,-72.9,31706431],
		["Warren","PA",41.84,-79.14,31708557],
		["Clearwater","FL",27.97,-82.76,31710683],
		["Ferndale","MI",42.45,-83.13,31712809],
		["Red Springs","NC",34.81,-79.18,31714933],
		["Louisville","KY",38.22,-85.74,31717057],
		["Seymour","CT",41.4,-73.06,31719180],
		["Murfreesboro","TN",35.85,-86.39,31721303],
		["Farmersville","CA",36.3,-119.19,31723426],
		["West Milford","NJ",41.1,-74.39,31725547],
		["Woodmere","NY",40.63,-73.72,31727668],
		["Canton","OH",40.81,-81.37,31729789],
		["Kewanee","IL",41.24,-89.92,31731910],
		["Winfield","KS",37.26,-96.96,31734031],
		["Orange Cove","CA",36.64,-119.29,31736152],
		["West Richland","WA",46.31,-119.37,31738273],
		["Rochester","NY",43.16,-77.61,31740393],
		["Chattanooga","TN",35.06,-85.25,31742513],
		["Tecumseh","MI",42,-83.94,31744633],
		["Marana","AZ",32.45,-111.29,31746752],
		["Vineland","NJ",39.46,-74.99,31748870],
		["Dubuque","IA",42.5,-90.69,31750988],
		["Austin","TX",30.26,-97.74,31753106],
		["Belchertown","MA",42.27,-72.4,31755223],
		["Stamford","CT",41.09,-73.55,31757340],
		["West Columbia","SC",33.99,-81.08,31759457],
		["Crest Hill","IL",41.55,-88.12,31761574],
		["Brighton","MA",42.35,-71.15,31763690],
		["Bronx","NY",40.84,-73.87,31765806],
		["Mobile","AL",30.68,-88.04,31767922],
		["Holmen","WI",43.95,-91.25,31770037],
		["Gilbertsville","PA",40.32,-75.6,31772151],
		["Astoria","OR",46.12,-123.67,31774265],
		["Nashville","NC",35.96,-77.95,31776378],
		["Fort Walton Beach","FL",30.42,-86.61,31778491],
		["Omaha","NE",41.26,-96.01,31780604],
		["Austin","TX",30.26,-97.74,31782717],
		["Novato","CA",38.13,-122.56,31784830],
		["Taylorsville","KY",38.03,-85.34,31786942],
		["Omaha","NE",41.26,-96.01,31789054],
		["Rocky Hill","CT",41.66,-72.63,31791165],
		["Mahomet","IL",40.19,-88.4,31793276],
		["Quinlan","TX",32.9,-96.13,31795387],
		["North Bend","WA",47.44,-121.62,31797498],
		["Callahan","FL",30.56,-81.83,31799608],
		["Nashville","TN",36.17,-86.78,31801718],
		["Saint Paul","MN",44.94,-93.1,31803827],
		["Burnsville","MN",44.76,-93.27,31805936],
		["Abington","MA",42.11,-70.95,31808044],
		["Washington","DC",38.89,-77.03,31810152],
		["Cheraw","SC",34.69,-79.89,31812260],
		["Dallas","TX",32.79,-96.76,31814368],
		["Clearlake","CA",38.96,-122.64,31816476],
		["Anacortes","WA",48.46,-122.6,31818584],
		["Metuchen","NJ",40.54,-74.36,31820691],
		["Detroit","MI",42.38,-83.1,31822798],
		["Groves","TX",29.94,-93.91,31824905],
		["Escalon","CA",37.83,-121.03,31827011],
		["Huntingdon","PA",40.49,-78.01,31829116],
		["Hazard","KY",37.25,-83.19,31831221],
		["Springfield","OH",39.92,-83.79,31833326],
		["Wayland","MA",42.36,-71.36,31835430],
		["Bellingham","MA",42.09,-71.47,31837534],
		["Port Jervis","NY",41.37,-74.69,31839638],
		["Danville","PA",40.96,-76.61,31841742],
		["Atlanta","GA",33.82,-84.32,31843846],
		["Blairsville","GA",34.87,-83.95,31845950],
		["Clermont","FL",28.63,-81.75,31848054],
		["Stilwell","OK",35.81,-94.63,31850158],
		["Chipley","FL",30.77,-85.54,31852261],
		["Debary","FL",28.88,-81.32,31854362],
		["Groveland","FL",28.56,-81.85,31856463],
		["Omaha","NE",41.26,-96.01,31858564],
		["Enola","PA",40.28,-76.93,31860664],
		["Mansfield","OH",40.76,-82.52,31862764],
		["Pennington","NJ",40.32,-74.79,31864863],
		["Chesnee","SC",35.14,-81.86,31866962],
		["Senoia","GA",33.31,-84.55,31869061],
		["Franklin","MI",42.51,-83.3,31871160],
		["Walnut Creek","CA",37.89,-122.03,31873259],
		["Soldotna","AK",60.32,-150.79,31875358],
		["Pelham","NY",40.91,-73.8,31877456],
		["Fowlerville","MI",42.66,-84.07,31879554],
		["Skiatook","OK",36.36,-96.03,31881652],
		["Tulsa","OK",36.12,-95.91,31883750],
		["Kansas City","MO",39.09,-94.58,31885847],
		["Little Rock","AR",34.72,-92.35,31887944],
		["Nacogdoches","TX",31.61,-94.65,31890041],
		["Mendota","CA",36.67,-120.43,31892138],
		["Youngsville","NC",36.02,-78.47,31894234],
		["Knoxville","TN",35.97,-83.94,31896330],
		["Laurel","DE",38.55,-75.57,31898425],
		["Hibbing","MN",47.4,-92.94,31900520],
		["Crystal Lake","IL",42.23,-88.33,31902615],
		["Riverside","IL",41.83,-87.81,31904710],
		["Ludington","MI",43.95,-86.44,31906804],
		["Waukee","IA",41.6,-93.86,31908898],
		["Lake Geneva","WI",42.58,-88.42,31910992],
		["Baton Rouge","LA",30.44,-91.12,31913086],
		["Iselin","NJ",40.57,-74.31,31915178],
		["Dundee","IL",42.1,-88.29,31917270],
		["Hazlehurst","GA",31.86,-82.59,31919361],
		["Yulee","FL",30.63,-81.57,31921452],
		["Louisville","MS",33.12,-89.05,31923543],
		["Fairview Heights","IL",38.59,-90,31925634],
		["Edmonds","WA",47.8,-122.37,31927725],
		["Chattanooga","TN",35.06,-85.25,31929815],
		["Tomah","WI",43.98,-90.5,31931905],
		["Trenton","NJ",40.22,-74.76,31933994],
		["Kalamazoo","MI",42.27,-85.58,31936083],
		["Ocala","FL",29.18,-82.13,31938171],
		["Middlebury","IN",41.67,-85.7,31940259],
		["Tacoma","WA",47.29,-122.5,31942347],
		["Columbus","MS",33.5,-88.4,31944434],
		["Quincy","WA",47.17,-119.8,31946521],
		["Paterson","NJ",40.91,-74.16,31948607],
		["Camillus","NY",43.03,-76.3,31950693],
		["Buffalo","NY",42.88,-78.85,31952779],
		["Kittanning","PA",40.82,-79.52,31954865],
		["Santa Fe","TX",29.38,-95.09,31956950],
		["Washington","NJ",40.75,-74.98,31959034],
		["Watervliet","NY",42.72,-73.7,31961117],
		["Fort Worth","TX",32.75,-97.33,31963200],
		["Cliffside Park","NJ",40.82,-73.98,31965282],
		["Telford","PA",40.32,-75.32,31967364],
		["Blackshear","GA",31.29,-82.24,31969446],
		["Saint Paul","MN",44.94,-93.1,31971528],
		["Tulsa","OK",36.12,-95.91,31973610],
		["Ceiba","PR",18.26,-65.64,31975690],
		["West Long Branch","NJ",40.29,-74.01,31977770],
		["Somerset","KY",37.08,-84.6,31979850],
		["Rochester","IN",41.06,-86.19,31981930],
		["Arvada","CO",39.82,-105.1,31984010],
		["Easton","PA",40.68,-75.22,31986089],
		["Newport","KY",39.08,-84.48,31988168],
		["Kansas City","MO",39.09,-94.58,31990247],
		["North Easton","MA",42.05,-71.1,31992325],
		["Woodruff","SC",34.74,-82.03,31994403],
		["Dowagiac","MI",41.98,-86.11,31996480],
		["Newman","CA",37.28,-121.2,31998557],
		["New York","NY",40.71,-73.99,32000632],
		["Poplar Grove","IL",42.36,-88.82,32002707],
		["High Ridge","MO",38.46,-90.53,32004782],
		["Santa Barbara","CA",34.43,-119.77,32006857],
		["Safety Harbor","FL",28,-82.69,32008931],
		["Oneonta","AL",33.95,-86.47,32011005],
		["Bay Saint Louis","MS",30.32,-89.51,32013079],
		["Marshall","MI",42.26,-84.95,32015153],
		["Ashland","MA",42.25,-71.46,32017226],
		["Birmingham","AL",33.52,-86.79,32019298],
		["Minneapolis","MN",44.96,-93.26,32021370],
		["Sioux Falls","SD",43.54,-96.73,32023441],
		["Liberty","SC",34.79,-82.69,32025511],
		["Springfield","IL",39.76,-89.72,32027581],
		["Houston","TX",29.76,-95.38,32029651],
		["Holliston","MA",42.2,-71.43,32031720],
		["Shawnee","KS",38.9,-94.68,32033789],
		["Sanger","TX",33.36,-97.17,32035858],
		["Tallahassee","FL",30.45,-84.28,32037925],
		["Erie","PA",42.12,-80.08,32039991],
		["Hubert","NC",34.66,-77.23,32042057],
		["Jacksonville","FL",30.33,-81.65,32044123],
		["East Bridgewater","MA",42.03,-70.95,32046188],
		["Jefferson City","TN",36.11,-83.48,32048253],
		["Monroe","GA",33.79,-83.71,32050317],
		["Shiprock","NM",36.79,-108.69,32052381],
		["Monmouth","OR",44.78,-123.33,32054445],
		["Franklin","PA",41.39,-79.83,32056508],
		["Indianapolis","IN",39.77,-86.14,32058571],
		["Lubbock","TX",33.57,-101.87,32060634],
		["Pacific","MO",38.47,-90.75,32062696],
		["Prairie View","TX",30.08,-95.98,32064757],
		["Fountain Hills","AZ",33.6,-111.74,32066818],
		["Keystone Heights","FL",29.78,-82.03,32068878],
		["Nappanee","IN",41.44,-85.98,32070938],
		["Ellington","CT",41.9,-72.46,32072997],
		["Valley","AL",32.8,-85.17,32075056],
		["Bloomfield Hills","MI",42.57,-83.24,32077115],
		["Boyertown","PA",40.33,-75.63,32079173],
		["Leesville","SC",33.91,-81.51,32081231],
		["New Palestine","IN",39.72,-85.89,32083289],
		["Pierre","SD",44.37,-100.32,32085347],
		["Orangeburg","SC",33.49,-80.86,32087404],
		["Louisville","KY",38.22,-85.74,32089461],
		["Jamestown","ND",46.91,-98.69,32091518],
		["Castro Valley","CA",37.69,-122.01,32093575],
		["Punxsutawney","PA",40.94,-78.97,32095631],
		["Palmyra","VA",37.86,-78.26,32097687],
		["Saint Petersburg","FL",27.75,-82.64,32099743],
		["Lagrange","IN",41.64,-85.41,32101799],
		["Kalamazoo","MI",42.27,-85.58,32103855],
		["Wayzata","MN",44.97,-93.51,32105911],
		["Honolulu","HI",21.3,-157.85,32107967],
		["Fall River","MA",41.71,-71.1,32110022],
		["Vienna","VA",38.9,-77.26,32112077],
		["Oskaloosa","IA",41.29,-92.63,32114132],
		["Wills Point","TX",32.7,-96,32116187],
		["Sparta","MI",43.15,-85.7,32118241],
		["North Augusta","SC",33.51,-81.95,32120294],
		["Harvard","IL",42.42,-88.61,32122347],
		["Barre","VT",44.2,-72.5,32124399],
		["Clarion","PA",41.21,-79.38,32126451],
		["Ellisville","MS",31.59,-89.2,32128503],
		["Plymouth","WI",43.74,-87.97,32130555],
		["Brazoria","TX",29.04,-95.56,32132607],
		["Modesto","CA",37.67,-120.89,32134659],
		["Jericho","NY",40.78,-73.54,32136710],
		["New Ulm","MN",44.31,-94.46,32138761],
		["Austin","TX",30.26,-97.74,32140812],
		["Guanica","PR",17.97,-66.93,32142862],
		["Northport","AL",33.24,-87.59,32144912],
		["Dallas","TX",32.79,-96.76,32146962],
		["Carpinteria","CA",34.43,-119.52,32149012],
		["Auburn","MA",42.2,-71.83,32151061],
		["Lehigh Acres","FL",26.56,-81.59,32153110],
		["Dayton","OH",39.77,-84.19,32155159],
		["Windham","NH",42.8,-71.3,32157207],
		["Crete","IL",41.45,-87.61,32159255],
		["Oklahoma City","OK",35.46,-97.51,32161303],
		["Casper","WY",42.83,-106.32,32163351],
		["Norwalk","CT",41.09,-73.42,32165398],
		["Grayson","KY",38.33,-82.93,32167445],
		["Huffman","TX",30.06,-95.09,32169492],
		["Austin","TX",30.26,-97.74,32171539],
		["Yakima","WA",46.54,-120.76,32173586],
		["Worcester","MA",42.26,-71.8,32175632],
		["Oxford","GA",33.62,-83.87,32177678],
		["Riverview","MI",42.16,-83.21,32179724],
		["Conroe","TX",30.32,-95.47,32181770],
		["Charleston","SC",32.78,-79.99,32183815],
		["De Forest","WI",43.24,-89.34,32185860],
		["Baton Rouge","LA",30.44,-91.12,32187904],
		["Eaton","OH",39.74,-84.63,32189947],
		["Fraser","MI",42.53,-82.94,32191990],
		["Port Allen","LA",30.45,-91.2,32194032],
		["Leitchfield","KY",37.48,-86.29,32196073],
		["Golden","CO",39.73,-105.21,32198114],
		["Nashville","TN",36.17,-86.78,32200154],
		["Burtonsville","MD",39.11,-76.93,32202193],
		["Hammond","IN",41.62,-87.49,32204232],
		["Milwaukee","WI",43.06,-87.96,32206270],
		["Wynne","AR",35.23,-90.78,32208308],
		["Pearl River","NY",41.06,-74,32210345],
		["Vermillion","SD",42.78,-96.92,32212382],
		["Hondo","TX",29.35,-99.15,32214419],
		["La Grange","NC",35.3,-77.78,32216455],
		["Streetsboro","OH",41.23,-81.34,32218491],
		["Dayton","OH",39.77,-84.19,32220527],
		["University Place","WA",47.2,-122.54,32222563],
		["Huntington","WV",38.41,-82.43,32224598],
		["Urbana","IL",40.1,-88.2,32226632],
		["Valley Springs","CA",38.16,-120.84,32228666],
		["Washington","DC",38.89,-77.03,32230699],
		["Clermont","FL",28.53,-81.76,32232732],
		["Delavan","WI",42.62,-88.63,32234765],
		["Rockford","IL",42.27,-89.06,32236798],
		["Stamford","CT",41.09,-73.55,32238830],
		["Fort Bragg","NC",35.13,-79,32240862],
		["Lake Orion","MI",42.78,-83.24,32242894],
		["Fort Riley","KS",38.98,-96.62,32244926],
		["Nevada City","CA",39.34,-120.87,32246958],
		["Rincon","PR",18.34,-67.25,32248989],
		["Guntersville","AL",34.35,-86.27,32251020],
		["Montgomery","AL",32.35,-86.28,32253051],
		["Church Point","LA",30.4,-92.21,32255082],
		["Ruther Glen","VA",38,-77.54,32257112],
		["Bremerton","WA",47.59,-122.62,32259142],
		["Great Neck","NY",40.8,-73.73,32261171],
		["New London","WI",44.39,-88.73,32263200],
		["Alma","MI",43.37,-84.65,32265228],
		["New Rochelle","NY",40.94,-73.78,32267255],
		["Buffalo","NY",42.88,-78.85,32269282],
		["Van Wert","OH",40.86,-84.58,32271309],
		["White Lake","MI",42.65,-83.5,32273336],
		["Shawano","WI",44.77,-88.58,32275363],
		["Anchorage","AK",61.19,-149.94,32277390],
		["Wauseon","OH",41.55,-84.14,32279416],
		["Bessemer City","NC",35.28,-81.28,32281440],
		["Biloxi","MS",30.39,-88.88,32283464],
		["San Jose","CA",37.34,-121.9,32285487],
		["New York","NY",40.77,-73.95,32287509],
		["New Rochelle","NY",40.92,-73.77,32289531],
		["Cudahy","WI",42.94,-87.86,32291553],
		["Wagoner","OK",35.95,-95.38,32293575],
		["Grand Junction","CO",38.99,-108.66,32295597],
		["Northampton","PA",40.68,-75.48,32297618],
		["Montgomery","AL",32.35,-86.28,32299639],
		["San Antonio","TX",29.45,-98.5,32301660],
		["San Juan","PR",18.4,-66.06,32303680],
		["Charlottesville","VA",38.03,-78.48,32305700],
		["Bristol","VA",36.61,-82.16,32307720],
		["Lexington","NE",40.77,-99.74,32309740],
		["Canton","IL",40.56,-90.04,32311759],
		["Smithfield","UT",41.82,-111.87,32313778],
		["Port Orange","FL",29.1,-81,32315796],
		["Houghton","MI",47.11,-88.56,32317814],
		["Hedgesville","WV",39.55,-77.99,32319831],
		["Macon","GA",32.83,-83.65,32321848],
		["Augusta","KS",37.69,-96.97,32323865],
		["Harrisburg","PA",40.27,-76.88,32325881],
		["Port Saint Lucie","FL",27.27,-80.35,32327897],
		["Rupert","ID",42.7,-113.62,32329913],
		["Summerville","GA",34.47,-85.34,32331928],
		["Hampshire","IL",42.09,-88.52,32333943],
		["West Palm Beach","FL",26.74,-80.12,32335957],
		["Palos Hills","IL",41.69,-87.82,32337971],
		["Kodiak","AK",57.79,-152.4,32339985],
		["Sandy Hook","CT",41.4,-73.24,32341998],
		["Mars","PA",40.69,-80.01,32344011],
		["Fort Pierce","FL",27.44,-80.32,32346024],
		["Storm Lake","IA",42.64,-95.19,32348037],
		["Amite","LA",30.73,-90.51,32350050],
		["Saylorsburg","PA",40.9,-75.36,32352062],
		["Whitman","MA",42.08,-70.93,32354073],
		["Deerfield Beach","FL",26.31,-80.12,32356084],
		["Waverly","OH",39.12,-82.98,32358095],
		["Sunnyvale","CA",37.38,-122.01,32360106],
		["Massena","NY",44.92,-74.89,32362116],
		["Cambridge","MD",38.56,-76.07,32364126],
		["Girard","OH",41.15,-80.69,32366135],
		["Shelby","OH",40.88,-82.66,32368144],
		["Cambridge","MN",45.56,-93.22,32370153],
		["Fletcher","NC",35.43,-82.5,32372161],
		["Anderson","IN",40.09,-85.68,32374169],
		["Chicago","IL",41.83,-87.68,32376177],
		["Boston","MA",42.35,-71.06,32378184],
		["Orange","CT",41.27,-73.02,32380191],
		["Murphy","NC",35.09,-84.02,32382198],
		["Hartwell","GA",34.35,-82.93,32384205],
		["Birmingham","AL",33.52,-86.79,32386212],
		["Mobile","AL",30.68,-88.04,32388219],
		["Coralville","IA",41.69,-91.6,32390226],
		["Euclid","OH",41.59,-81.51,32392232],
		["Nevada","MO",37.84,-94.35,32394238],
		["Dunellen","NJ",40.58,-74.46,32396243],
		["Glen Head","NY",40.84,-73.61,32398248],
		["Ballston Lake","NY",42.92,-73.84,32400252],
		["Glenwood Springs","CO",39.54,-107.33,32402256],
		["Charlton","MA",42.13,-71.96,32404259],
		["Montevallo","AL",33.1,-86.85,32406262],
		["Saint Louis","MO",38.63,-90.24,32408265],
		["Omaha","NE",41.26,-96.01,32410268],
		["Poquoson","VA",37.12,-76.34,32412270],
		["Cairo","GA",30.87,-84.2,32414271],
		["Las Vegas","NV",36.19,-115.31,32416272],
		["Wilmer","AL",30.82,-88.35,32418272],
		["House Springs","MO",38.41,-90.56,32420272],
		["Killen","AL",34.86,-87.52,32422271],
		["Evansville","IN",37.98,-87.54,32424270],
		["Santaquin","UT",39.99,-111.81,32426269],
		["Morrison","CO",39.65,-105.18,32428267],
		["Frederick","MD",39.42,-77.41,32430264],
		["Tampa","FL",27.95,-82.48,32432261],
		["Detroit Lakes","MN",46.81,-95.84,32434258],
		["San Anselmo","CA",37.99,-122.58,32436255],
		["Minneapolis","MN",44.96,-93.26,32438251],
		["Las Vegas","NV",36.07,-115.21,32440247],
		["Brecksville","OH",41.3,-81.61,32442242],
		["Mogadore","OH",41.05,-81.4,32444237],
		["Fort Gratiot","MI",43.07,-82.49,32446232],
		["Alexander","AR",34.63,-92.44,32448227],
		["Parrish","FL",27.58,-82.42,32450221],
		["San Diego","CA",32.79,-117.23,32452215],
		["Discovery Bay","CA",37.86,-121.64,32454209],
		["South River","NJ",40.44,-74.37,32456202],
		["Waverly","IA",42.72,-92.46,32458195],
		["Sanford","ME",43.44,-70.78,32460187],
		["Hazel Green","AL",34.92,-86.56,32462179],
		["Waterford","CT",41.34,-72.14,32464169],
		["Pittsburgh","PA",40.43,-79.97,32466159],
		["Lenoir City","TN",35.8,-84.27,32468149],
		["Kansas City","KS",39.1,-94.62,32470139],
		["Corvallis","OR",44.56,-123.27,32472129],
		["Tacoma","WA",47.05,-122.59,32474119],
		["New Milford","NJ",40.93,-74.01,32476108],
		["Bluffton","IN",40.74,-85.17,32478097],
		["Mauldin","SC",34.78,-82.3,32480085],
		["Santa Monica","CA",34.02,-118.47,32482073],
		["Webster","MA",42.04,-71.87,32484060],
		["Collingswood","NJ",39.91,-75.07,32486047],
		["Lutz","FL",28.13,-82.46,32488034],
		["Nashville","TN",36.17,-86.78,32490021],
		["Vandalia","OH",39.87,-84.19,32492008],
		["Scottsburg","IN",38.68,-85.78,32493995],
		["Carthage","TX",32.15,-94.33,32495982],
		["Kissimmee","FL",28.3,-81.41,32497968],
		["Muldrow","OK",35.4,-94.6,32499954],
		["Hockley","TX",30.04,-95.81,32501940],
		["Ephraim","UT",39.35,-111.57,32503926],
		["Derwood","MD",39.13,-77.13,32505911],
		["Weston","CT",41.22,-73.37,32507895],
		["Toney","AL",34.9,-86.72,32509879],
		["Steamboat Springs","CO",40.47,-106.82,32511863],
		["Middletown","RI",41.51,-71.27,32513846],
		["Brattleboro","VT",42.86,-72.57,32515829],
		["Lumberton","NJ",39.96,-74.8,32517812],
		["New York","NY",40.71,-73.99,32519795],
		["Guaynabo","PR",18.38,-66.11,32521777],
		["Flanders","NJ",40.84,-74.7,32523759],
		["Trenton","NJ",40.22,-74.76,32525741],
		["New York","NY",40.71,-73.99,32527723],
		["Johns Island","SC",32.72,-80.07,32529705],
		["Naples","FL",26.14,-81.79,32531687],
		["Taylorville","IL",39.52,-89.26,32533669],
		["Southaven","MS",34.97,-89.99,32535650],
		["Billings","MT",45.8,-108.66,32537631],
		["Holden","MA",42.35,-71.85,32539611],
		["Pelham","NH",42.73,-71.31,32541590],
		["Eagle Point","OR",42.46,-122.66,32543569],
		["Joppa","MD",39.43,-76.35,32545547],
		["Cleveland","OH",41.47,-81.67,32547525],
		["Justice","IL",41.74,-87.83,32549503],
		["Weatherford","OK",35.53,-98.68,32551481],
		["Splendora","TX",30.23,-95.16,32553459],
		["Syracuse","NY",43.04,-76.14,32555436],
		["Dayton","NV",39.26,-119.48,32557413],
		["New Holland","PA",40.1,-76.09,32559389],
		["Tallahassee","FL",30.45,-84.28,32561365],
		["Cedar Lake","IN",41.37,-87.43,32563341],
		["Pleasant Hill","MO",38.79,-94.26,32565317],
		["Wharton","TX",29.31,-96.09,32567293],
		["New Market","MD",39.38,-77.27,32569268],
		["Jensen Beach","FL",27.23,-80.23,32571243],
		["Irvington","AL",30.5,-88.23,32573218],
		["Saint Louis","MO",38.63,-90.24,32575193],
		["Salinas","CA",36.61,-121.62,32577168],
		["Phoenix","AZ",33.5,-112.08,32579142],
		["Washington","DC",38.89,-77.03,32581115],
		["Sparks","NV",39.68,-119.68,32583087],
		["San Juan","PR",18.4,-66.06,32585058],
		["Middlebury","VT",44,-73.15,32587029],
		["Keyser","WV",39.43,-78.98,32589000],
		["Rice Lake","WI",45.49,-91.73,32590971],
		["Seminole","OK",35.23,-96.64,32592942],
		["Glen Rock","NJ",40.95,-74.12,32594912],
		["Moundsville","WV",39.92,-80.74,32596882],
		["Williamston","NC",35.85,-77.05,32598852],
		["Vero Beach","FL",27.64,-80.39,32600822],
		["Orrville","OH",40.84,-81.77,32602792],
		["Paterson","NJ",40.91,-74.16,32604761],
		["White Plains","NY",41.02,-73.75,32606730],
		["Atlanta","GA",33.75,-84.39,32608699],
		["Plano","IL",41.66,-88.53,32610668],
		["Eight Mile","AL",30.76,-88.12,32612636],
		["Wichita","KS",37.68,-97.34,32614604],
		["Winnsboro","LA",32.16,-91.72,32616572],
		["Torrance","CA",33.83,-118.29,32618540],
		["Foothill Ranch","CA",33.7,-117.67,32620508],
		["Amesbury","MA",42.85,-70.92,32622475],
		["Holtsville","NY",40.81,-73.04,32624442],
		["Bryant","AR",34.61,-92.49,32626409],
		["North Little Rock","AR",34.78,-92.25,32628376],
		["Greenfield","MA",42.58,-72.59,32630342],
		["Westminster","SC",34.66,-83.09,32632308],
		["Akron","OH",41.08,-81.52,32634274],
		["Godfrey","IL",38.96,-90.18,32636240],
		["New Hartford","NY",43.07,-75.28,32638205],
		["Moore","SC",34.83,-81.99,32640170],
		["Denver","CO",39.76,-104.87,32642135],
		["Toms River","NJ",39.95,-74.18,32644098],
		["Jupiter","FL",26.92,-80.1,32646061],
		["Mc Calla","AL",33.28,-87.12,32648024],
		["Madison","WI",43.07,-89.38,32649987],
		["Tremonton","UT",41.69,-112.27,32651950],
		["Chino Valley","AZ",34.77,-112.45,32653913],
		["Olympia","WA",47.1,-122.86,32655876],
		["Rockton","IL",42.44,-89.06,32657838],
		["Oklahoma City","OK",35.46,-97.51,32659800],
		["Austin","TX",30.26,-97.74,32661762],
		["Tucson","AZ",32.25,-110.82,32663724],
		["Hopkins","SC",33.88,-80.84,32665685],
		["Geneva","OH",41.79,-80.94,32667646],
		["Minneapolis","MN",44.96,-93.26,32669607],
		["Loomis","CA",38.81,-121.16,32671568],
		["Pittston","PA",41.32,-75.78,32673528],
		["Miami","FL",25.77,-80.2,32675487],
		["Houston","TX",29.76,-95.38,32677446],
		["Gig Harbor","WA",47.36,-122.6,32679405],
		["Russellville","KY",36.83,-86.89,32681363],
		["Ball Ground","GA",34.33,-84.37,32683320],
		["Victoria","TX",28.82,-96.98,32685277],
		["Anderson","SC",34.51,-82.64,32687233],
		["Ocala","FL",29.18,-82.13,32689189],
		["Greenville","AL",31.82,-86.62,32691144],
		["Minneapolis","MN",44.96,-93.26,32693098],
		["Westbrook","ME",43.69,-70.35,32695050],
		["Cleves","OH",39.16,-84.74,32697002],
		["Hewitt","TX",31.45,-97.19,32698954],
		["Novato","CA",38.06,-122.54,32700906],
		["Winnsboro","SC",34.37,-81.08,32702857],
		["Huntsville","AL",34.7,-86.62,32704808],
		["Lafayette","TN",36.52,-86.03,32706759],
		["Marengo","IL",42.24,-88.6,32708710],
		["Quincy","MA",42.26,-71,32710660],
		["Roxboro","NC",36.41,-78.85,32712610],
		["White Plains","NY",41.02,-73.75,32714559],
		["China Grove","NC",35.57,-80.58,32716508],
		["Port Orange","FL",29.1,-81,32718457],
		["Albertville","AL",34.26,-86.2,32720406],
		["Mount Sinai","NY",40.93,-73.01,32722354],
		["Mcpherson","KS",38.36,-97.66,32724302],
		["Burnsville","NC",35.91,-82.29,32726249],
		["Sturgeon Bay","WI",44.83,-87.37,32728196],
		["Overland Park","KS",38.9,-94.68,32730143],
		["Raceland","LA",29.71,-90.59,32732090],
		["Rifle","CO",39.53,-107.77,32734035],
		["Mineola","NY",40.74,-73.63,32735979],
		["Glendale","CA",34.19,-118.24,32737922],
		["Stony Point","NY",41.22,-73.99,32739864],
		["Columbia","MD",39.2,-76.85,32741806],
		["Milwaukee","WI",43.06,-87.96,32743748],
		["Plainfield","NJ",40.63,-74.4,32745688],
		["Saint Petersburg","FL",27.75,-82.64,32747628],
		["Duluth","MN",46.78,-92.11,32749568],
		["Sullivan","MO",38.21,-91.16,32751508],
		["Lake Charles","LA",30.21,-93.2,32753447],
		["San Antonio","TX",29.45,-98.5,32755386],
		["West Grove","PA",39.82,-75.82,32757324],
		["Waupaca","WI",44.35,-89.07,32759262],
		["Greenville","TX",33.11,-96.1,32761200],
		["Castroville","CA",36.76,-121.75,32763138],
		["Brookline","MA",42.32,-71.14,32765075],
		["Garnet Valley","PA",39.84,-75.49,32767012],
		["Sylvania","GA",32.75,-81.63,32768949],
		["Kingston","TN",35.87,-84.51,32770886],
		["Navasota","TX",30.38,-96.08,32772823],
		["Erie","PA",42.12,-80.08,32774759],
		["Monroe","WI",42.59,-89.64,32776695],
		["Clanton","AL",32.84,-86.62,32778630],
		["Swanton","OH",41.58,-83.89,32780565],
		["Marinette","WI",45.08,-87.62,32782500],
		["Metamora","IL",40.79,-89.36,32784435],
		["Wichita","KS",37.68,-97.34,32786370],
		["U S A F Academy","CO",38.99,-104.85,32788305],
		["Bremen","GA",33.7,-85.15,32790239],
		["Waycross","GA",31.21,-82.35,32792173],
		["Sweetwater","TN",35.6,-84.46,32794107],
		["Fulton","MS",34.26,-88.4,32796041],
		["Demotte","IN",41.16,-87.26,32797975],
		["Austin","TX",30.26,-97.74,32799909],
		["Broomfield","CO",39.96,-105.02,32801843],
		["Richlands","NC",34.89,-77.54,32803776],
		["Humboldt","TN",35.82,-88.9,32805709],
		["Hopkins","MN",44.92,-93.4,32807642],
		["Woodward","OK",36.42,-99.4,32809575],
		["Watertown","CT",41.61,-73.12,32811507],
		["Boonton","NJ",40.9,-74.4,32813439],
		["Lake City","SC",33.86,-79.75,32815371],
		["Sabana Hoyos","PR",18.38,-66.62,32817302],
		["Groton","MA",42.6,-71.57,32819233],
		["Colchester","VT",44.53,-73.15,32821164],
		["Rochester","NY",43.16,-77.61,32823095],
		["Yadkinville","NC",36.13,-80.65,32825026],
		["Augusta","GA",33.45,-81.99,32826957],
		["Saint Louis","MO",38.63,-90.24,32828888],
		["La Marque","TX",29.36,-94.99,32830819],
		["Honolulu","HI",21.3,-157.85,32832750],
		["Camano Island","WA",48.16,-122.49,32834681],
		["Seekonk","MA",41.81,-71.33,32836611],
		["Verona","PA",40.5,-79.84,32838541],
		["Starke","FL",29.94,-82.11,32840471],
		["Goshen","NY",41.4,-74.32,32842400],
		["Madison","AL",34.7,-86.74,32844329],
		["Mount Vernon","IN",37.93,-87.89,32846258],
		["Fort Worth","TX",32.75,-97.33,32848187],
		["Grand Junction","CO",39.08,-108.55,32850115],
		["Colts Neck","NJ",40.28,-74.16,32852042],
		["Des Moines","IA",41.57,-93.61,32853969],
		["Elizabeth","CO",39.36,-104.6,32855896],
		["Fairhaven","MA",41.63,-70.9,32857822],
		["Oakland","MD",39.41,-79.41,32859748],
		["Firebaugh","CA",36.85,-120.54,32861674],
		["Lihue","HI",21.97,-159.35,32863600],
		["Swansea","MA",41.75,-71.18,32865525],
		["Benson","NC",35.37,-78.54,32867450],
		["Grand Rapids","MI",42.96,-85.65,32869375],
		["Little Falls","MN",45.97,-94.36,32871300],
		["Chicago","IL",41.83,-87.68,32873225],
		["Oak Grove","MO",39,-94.12,32875150],
		["Carlsbad","CA",33.15,-117.28,32877075],
		["Pahoa","HI",19.49,-154.94,32879000],
		["Saint Albans","VT",44.81,-73.08,32880924],
		["Kingstree","SC",33.66,-79.82,32882847],
		["Westminster","CO",39.88,-105.05,32884770],
		["Pittsburgh","PA",40.43,-79.97,32886692],
		["Largo","FL",27.9,-82.78,32888614],
		["Grafton","OH",41.28,-82.03,32890536],
		["Boonville","IN",38.04,-87.27,32892458],
		["Mount Clemens","MI",42.59,-82.88,32894380],
		["Chatham","IL",39.67,-89.69,32896302],
		["Rocky Point","NY",40.92,-72.92,32898222],
		["Church Hill","TN",36.52,-82.71,32900142],
		["Saint Clair Shores","MI",42.49,-82.89,32902062],
		["White Plains","NY",41.02,-73.75,32903981],
		["Bloomington","IL",40.47,-88.95,32905900],
		["Wichita","KS",37.68,-97.34,32907819],
		["Lincoln","NE",40.81,-96.68,32909737],
		["Boise","ID",43.71,-116.25,32911655],
		["Tylertown","MS",31.11,-90.14,32913572],
		["Blair","NE",41.54,-96.13,32915489],
		["Eloy","AZ",32.65,-111.59,32917406],
		["Santa Clara","CA",37.39,-121.96,32919323],
		["Westport","MA",41.66,-71.1,32921239],
		["Elmer","NJ",39.59,-75.17,32923155],
		["Byram","MS",32.32,-90.2,32925071],
		["Louisville","KY",38.22,-85.74,32926987],
		["Concord","NH",43.23,-71.56,32928902],
		["Oneida","NY",43.08,-75.65,32930817],
		["Warrenton","VA",38.71,-77.79,32932732],
		["Mound","MN",44.93,-93.66,32934647],
		["Odenville","AL",33.68,-86.39,32936561],
		["Thompsons Station","TN",35.82,-87.01,32938475],
		["Two Rivers","WI",44.2,-87.59,32940389],
		["Hooksett","NH",43.09,-71.45,32942302],
		["Saddle River","NJ",41.02,-74.09,32944215],
		["Hermitage","PA",41.22,-80.44,32946128],
		["Gardendale","AL",33.66,-86.8,32948041],
		["Napoleon","OH",41.39,-84.12,32949954],
		["Freeland","MI",43.52,-84.11,32951867],
		["Edgewood","NM",35.08,-106.17,32953780],
		["Crossville","TN",35.95,-85.03,32955692],
		["Paw Paw","MI",42.21,-85.89,32957604],
		["Rapid City","SD",44.07,-103.23,32959516],
		["Kearney","NE",40.7,-99.08,32961428],
		["Kathleen","GA",32.45,-83.58,32963339],
		["Dallas","TX",32.79,-96.76,32965250],
		["Santa Fe","NM",35.57,-106.11,32967161],
		["Walden","NY",41.55,-74.18,32969071],
		["Sunbury","PA",40.86,-76.78,32970980],
		["Madison","WI",43.07,-89.38,32972889],
		["Waconia","MN",44.84,-93.79,32974798],
		["Laconia","NH",43.56,-71.48,32976706],
		["Knoxville","TN",35.97,-83.94,32978614],
		["Lexington","KY",38.04,-84.45,32980522],
		["Saint Louis","MO",38.63,-90.24,32982430],
		["Guymon","OK",36.68,-101.48,32984338],
		["San Antonio","TX",29.45,-98.5,32986246],
		["Long Beach","CA",33.75,-118.13,32988154],
		["Nyack","NY",41.09,-73.91,32990061],
		["Mena","AR",34.58,-94.23,32991968],
		["Swainsboro","GA",32.59,-82.33,32993874],
		["New Prague","MN",44.54,-93.57,32995780],
		["Saint Clair","MI",42.82,-82.49,32997685],
		["California City","CA",35.15,-117.92,32999590],
		["Bryan","OH",41.47,-84.54,33001494],
		["Waterbury","CT",41.55,-73.03,33003397],
		["Advance","NC",35.93,-80.4,33005300],
		["Denver","CO",39.69,-104.88,33007203],
		["Green River","WY",41.51,-109.46,33009106],
		["South Burlington","VT",44.44,-73.21,33011008],
		["Saraland","AL",30.82,-88.09,33012910],
		["Madison","WI",43.07,-89.38,33014812],
		["Troy","NY",42.73,-73.67,33016713],
		["Fairfield","AL",33.47,-86.91,33018614],
		["Tallassee","AL",32.53,-85.89,33020515],
		["Bethel","OH",38.96,-84.08,33022415],
		["Sealy","TX",29.77,-96.15,33024315],
		["Uxbridge","MA",42.08,-71.6,33026214],
		["Orlando","FL",28.5,-81.37,33028113],
		["Belle Vernon","PA",40.12,-79.86,33030011],
		["Berwyn","PA",40.03,-75.44,33031909],
		["Vine Grove","KY",37.81,-85.97,33033807],
		["San Francisco","CA",37.73,-122.45,33035705],
		["Oakland","NJ",41.03,-74.24,33037602],
		["Inver Grove Heights","MN",44.82,-93.05,33039499],
		["Cranston","RI",41.76,-71.48,33041395],
		["Mc Donald","PA",40.37,-80.23,33043291],
		["Austin","TX",30.26,-97.74,33045187],
		["Springfield","MA",42.11,-72.53,33047082],
		["Nashua","NH",42.74,-71.49,33048977],
		["Bennington","VT",42.87,-73.18,33050872],
		["Rensselaer","IN",40.93,-87.14,33052766],
		["Wayland","MI",42.67,-85.64,33054660],
		["Beebe","AR",35.07,-91.89,33056554],
		["Glendale","CA",34.13,-118.26,33058448],
		["Harrisburg","IL",37.73,-88.54,33060341],
		["Coffeyville","KS",37.04,-95.63,33062234],
		["Lincoln","NE",40.81,-96.68,33064127],
		["Stevensville","MD",38.99,-76.3,33066018],
		["Center","TX",31.79,-94.17,33067909],
		["Moscow","PA",41.33,-75.52,33069799],
		["Cortez","CO",37.35,-108.57,33071689],
		["Phoenix","AZ",33.75,-112.14,33073579],
		["Albuquerque","NM",35.11,-106.62,33075469],
		["Blaine","WA",48.93,-122.72,33077359],
		["Jersey City","NJ",40.71,-74.06,33079248],
		["East Syracuse","NY",43.06,-76.07,33081137],
		["Townsend","DE",39.39,-75.69,33083026],
		["Salem","MO",37.63,-91.53,33084915],
		["Nacogdoches","TX",31.61,-94.65,33086803],
		["Morgantown","WV",39.63,-79.94,33088690],
		["Plain City","OH",40.1,-83.27,33090577],
		["Clifton","CO",39.07,-108.46,33092464],
		["Worthington","MN",43.62,-95.59,33094350],
		["Columbia Falls","MT",48.54,-114.34,33096235],
		["North East","PA",42.21,-79.83,33098119],
		["Franklin Lakes","NJ",41,-74.2,33100002],
		["Little Neck","NY",40.76,-73.74,33101885],
		["Baltimore","MD",39.3,-76.61,33103768],
		["Mansfield","OH",40.76,-82.52,33105651],
		["Kansas City","MO",39.09,-94.58,33107534],
		["Phoenix","AZ",33.44,-112.08,33109417],
		["Whiteriver","AZ",33.8,-110.09,33111300],
		["Maple Shade","NJ",39.95,-74.99,33113182],
		["Memphis","TN",35.1,-90,33115064],
		["Monticello","IN",40.74,-86.76,33116946],
		["Fairbanks","AK",64.93,-146.61,33118828],
		["Greenbrier","TN",36.42,-86.8,33120709],
		["Saint Ann","MO",38.72,-90.38,33122590],
		["Milton","VT",44.63,-73.11,33124470],
		["Alexandria","VA",38.82,-77.08,33126350],
		["Macclenny","FL",30.28,-82.12,33128230],
		["Hubbard","OH",41.16,-80.56,33130110],
		["Hickory Hills","IL",41.72,-87.82,33131990],
		["Colorado Springs","CO",38.86,-104.76,33133870],
		["Clayton","CA",37.88,-121.84,33135750],
		["Sweet Home","OR",44.36,-122.69,33137630],
		["South Weymouth","MA",42.2,-70.94,33139509],
		["Ocala","FL",29.18,-82.13,33141387],
		["Dayton","OH",39.77,-84.19,33143265],
		["Tecumseh","OK",35.26,-96.93,33145143],
		["Gladewater","TX",32.54,-94.94,33147021],
		["Vernon","TX",34.14,-99.3,33148899],
		["Orting","WA",47.03,-122.1,33150777],
		["Raynham","MA",41.93,-71.04,33152654],
		["Waynesville","MO",37.82,-92.21,33154531],
		["Wichita Falls","TX",33.89,-98.51,33156408],
		["Amherst","NH",42.86,-71.61,33158284],
		["Sarasota","FL",27.33,-82.54,33160160],
		["Delta","CO",38.74,-108.07,33162036],
		["Mapleton","UT",40.11,-111.48,33163912],
		["Newburyport","MA",42.81,-70.88,33165787],
		["Waynesburg","PA",39.89,-80.18,33167662],
		["Statesboro","GA",32.44,-81.77,33169537],
		["Plant City","FL",28,-82.12,33171412],
		["Gallipolis","OH",38.82,-82.19,33173287],
		["Pittsburg","TX",32.99,-94.96,33175162],
		["Macon","GA",32.83,-83.65,33177036],
		["Sylvester","GA",31.52,-83.83,33178910],
		["Dallas","TX",32.79,-96.76,33180784],
		["State College","PA",40.79,-77.85,33182657],
		["Pensacola","FL",30.44,-87.18,33184530],
		["Madera","CA",36.93,-119.83,33186403],
		["Clairton","PA",40.29,-79.88,33188275],
		["Defuniak Springs","FL",30.74,-86.19,33190147],
		["Clark","NJ",40.62,-74.31,33192018],
		["Edgewater","FL",28.96,-80.9,33193889],
		["Kingsport","TN",36.52,-82.55,33195760],
		["Shreveport","LA",32.47,-93.79,33197631],
		["Fortuna","CA",40.57,-124.15,33199502],
		["Southfield","MI",42.46,-83.28,33201372],
		["Gladwin","MI",43.98,-84.49,33203241],
		["Bartlesville","OK",36.73,-95.94,33205110],
		["La Vernia","TX",29.35,-98.11,33206979],
		["Fort Lupton","CO",40.08,-104.8,33208848],
		["Murrells Inlet","SC",33.55,-79.05,33210716],
		["Gibsonton","FL",27.84,-82.37,33212584],
		["Tampa","FL",27.95,-82.48,33214452],
		["Loudon","TN",35.73,-84.35,33216320],
		["Athens","GA",33.95,-83.39,33218187],
		["Pueblo","CO",38.27,-104.62,33220054],
		["Erie","PA",42.12,-80.08,33221920],
		["Hampton","VA",37.04,-76.29,33223786],
		["Americus","GA",32.06,-84.26,33225652],
		["Newport","MI",42,-83.3,33227518],
		["Murphysboro","IL",37.76,-89.33,33229384],
		["Briarcliff Manor","NY",41.13,-73.84,33231249],
		["Moss Point","MS",30.42,-88.52,33233114],
		["Maysville","KY",38.62,-83.78,33234979],
		["New York","NY",40.71,-73.99,33236843],
		["Dos Palos","CA",37.07,-120.63,33238707],
		["Damascus","OR",45.42,-122.45,33240571],
		["Deer Park","WA",47.99,-117.5,33242435],
		["Fleetwood","PA",40.45,-75.82,33244298],
		["Prosper","TX",33.24,-96.78,33246161],
		["Laurel","MD",39.09,-76.85,33248023],
		["Stanley","NC",35.35,-81.09,33249885],
		["Brunswick","GA",31.21,-81.64,33251747],
		["Tyler","TX",32.32,-95.3,33253609],
		["Oakland","CA",37.81,-122.24,33255471],
		["Agawam","MA",42.06,-72.61,33257332],
		["Easthampton","MA",42.26,-72.68,33259193],
		["Nashua","NH",42.74,-71.49,33261054],
		["Babylon","NY",40.69,-73.32,33262915],
		["Colorado Springs","CO",38.86,-104.76,33264776],
		["Roslyn Heights","NY",40.77,-73.64,33266636],
		["Nashville","TN",36.17,-86.78,33268496],
		["Huron","SD",44.36,-98.21,33270356],
		["Rock Falls","IL",41.77,-89.69,33272216],
		["Woodland","WA",45.94,-122.67,33274076],
		["Quarryville","PA",39.89,-76.16,33275935],
		["Landenberg","PA",39.76,-75.78,33277794],
		["Emporia","VA",36.69,-77.53,33279653],
		["Cocoa","FL",28.37,-80.75,33281512],
		["Isanti","MN",45.48,-93.25,33283371],
		["Carbondale","CO",39.39,-107.21,33285230],
		["Whiteland","IN",39.55,-86.08,33287088],
		["Denver","CO",39.76,-104.87,33288946],
		["Milford","CT",41.23,-73.08,33290803],
		["Kennewick","WA",46.16,-119.25,33292660],
		["Norwich","NY",42.53,-75.52,33294516],
		["Middletown","MD",39.44,-77.54,33296372],
		["Alachua","FL",29.77,-82.48,33298228],
		["Highland","NY",41.71,-73.96,33300083],
		["Levittown","PA",40.15,-74.85,33301938],
		["Claymont","DE",39.8,-75.45,33303793],
		["Sacramento","CA",38.55,-121.49,33305648],
		["Gloucester City","NJ",39.89,-75.11,33307502],
		["Penn Yan","NY",42.66,-77.05,33309356],
		["Hendersonville","NC",35.32,-82.46,33311210],
		["Spring Grove","PA",39.88,-76.86,33313063],
		["Fort Wayne","IN",41.07,-85.13,33314916],
		["Milan","MI",42.08,-83.68,33316769],
		["Salt Lake City","UT",40.79,-111.82,33318622],
		["Sandwich","IL",41.64,-88.62,33320474],
		["Syracuse","NY",43.04,-76.14,33322325],
		["Gretna","LA",29.91,-90.05,33324176],
		["Saint Louis","MO",38.63,-90.24,33326026],
		["Tulsa","OK",36.12,-95.91,33327876],
		["Penitas","TX",26.23,-98.44,33329726],
		["Warrenville","IL",41.82,-88.18,33331575],
		["Laramie","WY",41.31,-105.58,33333424],
		["Dudley","MA",42.05,-71.93,33335271],
		["Sharon","PA",41.23,-80.49,33337118],
		["Indianapolis","IN",39.77,-86.14,33338965],
		["Luling","LA",29.92,-90.36,33340812],
		["Berkeley Heights","NJ",40.67,-74.42,33342658],
		["Warrior","AL",33.81,-86.8,33344504],
		["Minneapolis","MN",44.96,-93.26,33346350],
		["Canyon Lake","TX",29.87,-98.26,33348195],
		["Boynton Beach","FL",26.53,-80.19,33350039],
		["Terre Haute","IN",39.47,-87.38,33351883],
		["Sierra Vista","AZ",31.5,-110.21,33353727],
		["Hazleton","PA",40.96,-76,33355570],
		["Parkersburg","WV",39.26,-81.54,33357413],
		["Kenton","OH",40.64,-83.61,33359256],
		["Gary","IN",41.59,-87.34,33361099],
		["Manteno","IL",41.25,-87.83,33362942],
		["Longview","TX",32.51,-94.76,33364785],
		["Hamlet","NC",34.88,-79.7,33366627],
		["Wickliffe","OH",41.6,-81.46,33368469],
		["Dallas","TX",32.79,-96.76,33370311],
		["Waterford","CA",37.68,-120.64,33372153],
		["Ogdensburg","NY",44.7,-75.47,33373994],
		["Bonne Terre","MO",37.92,-90.54,33375835],
		["Monett","MO",36.92,-93.92,33377676],
		["Fort Scott","KS",37.83,-94.7,33379517],
		["Kemp","TX",32.43,-96.22,33381358],
		["Somerset","MA",41.73,-71.15,33383197],
		["Hartford","CT",41.76,-72.68,33385036],
		["Palm Bay","FL",27.98,-80.66,33386875],
		["Amarillo","TX",35.2,-101.81,33388714],
		["Bellevue","WA",47.62,-122.16,33390553],
		["Albion","NY",43.24,-78.18,33392390],
		["Wichita","KS",37.68,-97.34,33394227],
		["De Queen","AR",34.04,-94.34,33396064],
		["Inglewood","CA",33.95,-118.33,33397901],
		["Portland","OR",45.51,-122.55,33399738],
		["Cherryville","NC",35.38,-81.38,33401574],
		["Kosciusko","MS",33.05,-89.58,33403410],
		["Scranton","PA",41.4,-75.66,33405245],
		["Bellevue","OH",41.27,-82.84,33407080],
		["Fargo","ND",46.87,-96.81,33408915],
		["Tiverton","RI",41.65,-71.2,33410749],
		["Newark","NY",43.04,-77.09,33412583],
		["Dubuque","IA",42.5,-90.69,33414417],
		["West Hartford","CT",41.75,-72.74,33416250],
		["Fallston","MD",39.52,-76.42,33418083],
		["Greensboro","NC",36.07,-79.82,33419916],
		["Sioux Falls","SD",43.54,-96.73,33421749],
		["Myerstown","PA",40.37,-76.3,33423581],
		["Douglas","GA",31.5,-82.85,33425413],
		["Monmouth","IL",40.91,-90.64,33427245],
		["Overland Park","KS",38.9,-94.68,33429077],
		["Moulton","AL",34.48,-87.28,33430908],
		["Piedmont","AL",33.92,-85.61,33432739],
		["Le Mars","IA",42.78,-96.16,33434570],
		["Pepperell","MA",42.66,-71.58,33436400],
		["Chattanooga","TN",35.06,-85.25,33438230],
		["Aurora","MO",36.96,-93.72,33440060],
		["Scottsdale","AZ",33.56,-111.9,33441890],
		["Wilkes Barre","PA",41.24,-75.87,33443719],
		["Cheboygan","MI",45.64,-84.46,33445548],
		["Sarasota","FL",27.33,-82.54,33447376],
		["Rockwood","MI",42.07,-83.24,33449204],
		["Minneapolis","MN",44.96,-93.26,33451032],
		["Portsmouth","NH",43.05,-70.78,33452859],
		["Dunkirk","NY",42.47,-79.33,33454686],
		["Roosevelt","UT",40.28,-110,33456513],
		["Roslyn","NY",40.79,-73.64,33458339],
		["Savannah","GA",32.08,-81.1,33460165],
		["Metairie","LA",29.99,-90.17,33461991],
		["Elk City","OK",35.4,-99.41,33463817],
		["Everett","WA",48,-122.1,33465643],
		["Clinton","NY",43.04,-75.37,33467468],
		["El Cerrito","CA",37.91,-122.3,33469293],
		["Charleston","WV",38.35,-81.63,33471117],
		["Valdosta","GA",30.84,-83.27,33472941],
		["Largo","FL",27.9,-82.78,33474765],
		["South Haven","MI",42.4,-86.27,33476589],
		["Athol","MA",42.59,-72.23,33478412],
		["Kingston","MA",41.99,-70.71,33480235],
		["Bronx","NY",40.84,-73.87,33482058],
		["Bridgeport","WV",39.29,-80.25,33483881],
		["Raymond","MS",32.26,-90.42,33485704],
		["Columbus","OH",39.98,-82.98,33487527],
		["El Paso","TX",31.84,-106.43,33489350],
		["Homestead","FL",25.46,-80.45,33491172],
		["Saint Marys","OH",40.54,-84.39,33492994],
		["Platte City","MO",39.36,-94.77,33494816],
		["Woodlake","CA",36.46,-119.1,33496638],
		["Hayward","CA",37.65,-122.04,33498460],
		["Maunabo","PR",18,-65.9,33500281],
		["Naples","FL",26.14,-81.79,33502102],
		["Batesville","IN",39.29,-85.21,33503923],
		["Independence","KS",37.23,-95.71,33505744],
		["Martinsburg","WV",39.46,-77.96,33507564],
		["Pass Christian","MS",30.31,-89.24,33509384],
		["Dayton","OH",39.77,-84.19,33511204],
		["Reedsburg","WI",43.53,-89.99,33513024],
		["Palo Alto","CA",37.44,-122.15,33514844],
		["Chester","NY",41.35,-74.27,33516663],
		["Swarthmore","PA",39.9,-75.34,33518482],
		["Franklinton","NC",36.1,-78.45,33520301],
		["Honolulu","HI",21.3,-157.85,33522120],
		["Middlesex","NJ",40.57,-74.5,33523938],
		["Camden Wyoming","DE",39.08,-75.62,33525756],
		["Johnstown","OH",40.15,-82.68,33527574],
		["Seattle","WA",47.63,-122.37,33529392],
		["Budd Lake","NJ",40.87,-74.73,33531209],
		["Central","SC",34.72,-82.78,33533026],
		["Munford","TN",35.44,-89.8,33534843],
		["Jeanerette","LA",29.91,-91.67,33536660],
		["Grantsville","UT",40.67,-112.76,33538477],
		["Orange","CA",33.78,-117.84,33540294],
		["Mount Pleasant","PA",40.15,-79.54,33542110],
		["Miami Beach","FL",25.77,-80.2,33543926],
		["Prospect Heights","IL",42.1,-87.92,33545742],
		["Kennett","MO",36.24,-90.04,33547558],
		["Egg Harbor City","NJ",39.56,-74.59,33549373],
		["New York","NY",40.71,-73.99,33551188],
		["Pikeville","NC",35.49,-77.98,33553003],
		["Indianapolis","IN",39.77,-86.14,33554818],
		["Madison","WI",43.07,-89.38,33556633],
		["Seattle","WA",47.55,-122.37,33558448],
		["Gadsden","AL",34.01,-86.01,33560262],
		["Deatsville","AL",32.62,-86.4,33562076],
		["Memphis","TN",35.1,-90,33563890],
		["Lancaster","KY",37.61,-84.57,33565704],
		["Little Rock","AR",34.72,-92.35,33567518],
		["Marion","VA",36.83,-81.51,33569331],
		["Jacksonville","FL",30.33,-81.65,33571144],
		["Lebanon","TN",36.2,-86.32,33572957],
		["Ketchikan","AK",55.61,-131.58,33574770],
		["Spanish Fort","AL",30.67,-87.88,33576581],
		["Falmouth","ME",43.72,-70.24,33578391],
		["Covington","VA",37.77,-79.99,33580201],
		["Jacksonville","FL",30.33,-81.65,33582011],
		["Glendale","AZ",33.53,-112.26,33583821],
		["Northampton","MA",42.32,-72.67,33585630],
		["Montclair","NJ",40.82,-74.21,33587439],
		["Wingate","NC",34.98,-80.44,33589247],
		["San Diego","CA",32.72,-117.23,33591055],
		["Riverside","RI",41.77,-71.35,33592862],
		["Ocala","FL",29.18,-82.13,33594669],
		["Providence","RI",41.82,-71.41,33596475],
		["Secaucus","NJ",40.78,-74.06,33598281],
		["Alameda","CA",37.73,-122.24,33600087],
		["East Weymouth","MA",42.2,-70.94,33601892],
		["Goffstown","NH",43.01,-71.6,33603697],
		["Peoria","IL",40.74,-89.6,33605502],
		["Glen Carbon","IL",38.76,-89.97,33607307],
		["Port Neches","TX",29.97,-93.94,33609112],
		["Mabelvale","AR",34.59,-92.38,33610916],
		["Katonah","NY",41.26,-73.68,33612719],
		["Haverstraw","NY",41.18,-73.95,33614522],
		["South Boston","VA",36.7,-78.9,33616325],
		["Omaha","NE",41.26,-96.01,33618128],
		["Newark","NJ",40.72,-74.17,33619930],
		["Keokuk","IA",40.4,-91.4,33621732],
		["Saint Joseph","MO",39.76,-94.85,33623534],
		["Parsons","KS",37.33,-95.26,33625335],
		["Sweetwater","TX",32.46,-100.4,33627136],
		["Mendham","NJ",40.76,-74.59,33628936],
		["Fishkill","NY",41.53,-73.89,33630736],
		["Abbeville","SC",34.17,-82.37,33632536],
		["Saint Joseph","MO",39.76,-94.85,33634336],
		["Camdenton","MO",38,-92.74,33636136],
		["Dobbs Ferry","NY",41.01,-73.86,33637935],
		["Four Oaks","NC",35.44,-78.42,33639734],
		["Norwalk","IA",41.49,-93.68,33641533],
		["Mesa","AZ",33.59,-111.44,33643332],
		["Swampscott","MA",42.46,-70.89,33645129],
		["Harrison","NJ",40.74,-74.15,33646926],
		["Springfield","NJ",40.69,-74.32,33648723],
		["Fabens","TX",31.5,-106.15,33650520],
		["Pittsburgh","PA",40.43,-79.97,33652316],
		["Chicago","IL",41.83,-87.68,33654112],
		["Saint Joseph","MO",39.76,-94.85,33655908],
		["Fort Worth","TX",32.75,-97.33,33657704],
		["Lynnfield","MA",42.53,-71.04,33659499],
		["Ladys Island","SC",32.43,-80.64,33661294],
		["Lakeland","FL",28.04,-81.96,33663089],
		["Seal Beach","CA",33.75,-118.07,33664884],
		["Greendale","WI",42.93,-88,33666678],
		["Murrysville","PA",40.46,-79.67,33668471],
		["Whitmore Lake","MI",42.42,-83.74,33670264],
		["Duson","LA",30.23,-92.19,33672057],
		["Manvel","TX",29.48,-95.35,33673850],
		["Plymouth","NH",43.73,-71.69,33675642],
		["Clarksburg","MD",39.23,-77.27,33677434],
		["Plattsmouth","NE",41,-95.89,33679226],
		["Fall River","MA",41.71,-71.1,33681017],
		["Newaygo","MI",43.42,-85.8,33682808],
		["Mankato","MN",44.16,-93.99,33684599],
		["Pittsboro","NC",35.71,-79.17,33686389],
		["Trenton","FL",29.61,-82.81,33688179],
		["Middlesboro","KY",36.61,-83.72,33689969],
		["South Bend","IN",41.67,-86.26,33691759],
		["Plover","WI",44.46,-89.54,33693549],
		["Chattanooga","TN",35.06,-85.25,33695338],
		["Dayton","OH",39.77,-84.19,33697127],
		["Columbia","IL",38.45,-90.22,33698916],
		["Cedar Creek","TX",30.08,-97.5,33700705],
		["Zephyrhills","FL",28.23,-82.17,33702493],
		["Rochester","WA",46.8,-123.14,33704281],
		["Colorado Springs","CO",38.72,-104.8,33706068],
		["Havre","MT",48.56,-109.73,33707854],
		["Craig","CO",40.52,-107.55,33709640],
		["Beaver","PA",40.69,-80.3,33711424],
		["Milton Freewater","OR",45.9,-118.34,33713208],
		["Ringwood","NJ",41.11,-74.27,33714991],
		["Jersey Shore","PA",41.2,-77.26,33716773],
		["Franklin","VA",36.68,-76.93,33718555],
		["Madison","WI",43.07,-89.38,33720337],
		["Roselle Park","NJ",40.66,-74.26,33722118],
		["Rayville","LA",32.47,-91.75,33723899],
		["Palos Heights","IL",41.66,-87.79,33725679],
		["Henderson","TX",32.15,-94.79,33727459],
		["Los Angeles","CA",34.1,-118.37,33729239],
		["Chestertown","MD",39.21,-76.07,33731018],
		["Elkins","WV",38.92,-79.85,33732797],
		["Bethlehem","GA",33.93,-83.7,33734576],
		["Birmingham","AL",33.52,-86.79,33736354],
		["Chicago","IL",41.83,-87.68,33738131],
		["Kansas City","MO",39.09,-94.58,33739908],
		["Pearl River","LA",30.37,-89.74,33741685],
		["Ephrata","WA",47.27,-119.59,33743462],
		["Martinsburg","WV",39.46,-77.96,33745238],
		["Madison","GA",33.57,-83.47,33747012],
		["Cincinnati","OH",39.09,-84.51,33748786],
		["Lincolnwood","IL",42,-87.73,33750560],
		["Shreveport","LA",32.47,-93.79,33752334],
		["Berkeley","CA",37.86,-122.27,33754108],
		["Manasquan","NJ",40.11,-74.03,33755881],
		["Montgomery","AL",32.35,-86.28,33757654],
		["Monroeville","AL",31.51,-87.32,33759427],
		["Galena Park","TX",29.74,-95.23,33761200],
		["East Hampton","NY",40.95,-72.19,33762972],
		["Glens Falls","NY",43.31,-73.64,33764744],
		["Sarasota","FL",27.33,-82.54,33766516],
		["Rochester","NY",43.16,-77.61,33768287],
		["Dothan","AL",31.23,-85.4,33770058],
		["Pulaski","VA",37.05,-80.76,33771828],
		["Smithville","MO",39.38,-94.57,33773598],
		["North Little Rock","AR",34.78,-92.25,33775368],
		["Henderson","TX",32.15,-94.79,33777138],
		["Apollo","PA",40.58,-79.56,33778907],
		["Chester","VA",37.35,-77.43,33780676],
		["Pascagoula","MS",30.36,-88.54,33782445],
		["Floyds Knobs","IN",38.31,-85.86,33784214],
		["Portland","IN",40.43,-84.98,33785983],
		["Haverhill","MA",42.78,-71.08,33787751],
		["Trenton","NJ",40.22,-74.76,33789519],
		["Carnegie","PA",40.4,-80.08,33791287],
		["Royal Oak","MI",42.5,-83.15,33793055],
		["Beaumont","TX",30.08,-94.14,33794823],
		["Arlington","VA",38.87,-77.1,33796590],
		["Saint Louis","MO",38.63,-90.24,33798357],
		["Poteet","TX",29.03,-98.57,33800124],
		["Barnwell","SC",33.24,-81.36,33801890],
		["Daytona Beach","FL",29.19,-81.05,33803656],
		["Winchester","TN",35.18,-86.1,33805422],
		["Paola","KS",38.57,-94.86,33807188],
		["Mabank","TX",32.25,-96.08,33808954],
		["San Antonio","TX",29.45,-98.5,33810720],
		["Colorado Springs","CO",38.86,-104.76,33812486],
		["Indianola","MS",33.44,-90.64,33814251],
		["South Bend","IN",41.67,-86.26,33816016],
		["Greenwell Springs","LA",30.52,-91,33817781],
		["Willis","TX",30.42,-95.47,33819546],
		["Corpus Christi","TX",27.8,-97.39,33821311],
		["Winthrop","MA",42.37,-70.98,33823075],
		["Bushkill","PA",41.09,-75,33824839],
		["Honey Brook","PA",40.09,-75.91,33826603],
		["Gulfport","MS",30.38,-89.06,33828367],
		["Hornell","NY",42.32,-77.66,33830130],
		["Lyons","GA",32.2,-82.32,33831893],
		["Milan","TN",35.91,-88.75,33833656],
		["Globe","AZ",33.62,-110.7,33835419],
		["Oaklyn","NJ",39.9,-75.08,33837181],
		["Milford","MI",42.58,-83.6,33838943],
		["Kamuela","HI",20.04,-155.7,33840705],
		["Lewisville","NC",36.09,-80.4,33842466],
		["Lansing","MI",42.7,-84.55,33844227],
		["Austin","TX",30.26,-97.74,33845988],
		["Plymouth Meeting","PA",40.1,-75.28,33847748],
		["Hanceville","AL",34.06,-86.76,33849508],
		["Crystal Springs","MS",31.98,-90.35,33851268],
		["New Orleans","LA",30.06,-89.93,33853028],
		["Tuttle","OK",35.29,-97.75,33854788],
		["Tifton","GA",31.46,-83.51,33856547],
		["Lima","OH",40.74,-84.11,33858306],
		["Summit Argo","IL",41.77,-87.82,33860065],
		["Paterson","NJ",40.91,-74.16,33861823],
		["Smiths Station","AL",32.53,-85.08,33863581],
		["Grinnell","IA",41.74,-92.72,33865339],
		["Pontiac","IL",40.87,-88.64,33867097],
		["Penns Grove","NJ",39.72,-75.46,33868854],
		["Key Biscayne","FL",25.68,-80.16,33870611],
		["Lake Worth","FL",26.58,-80.22,33872367],
		["Olive Hill","KY",38.3,-83.16,33874123],
		["Portland","OR",45.57,-122.59,33875879],
		["Connellys Springs","NC",35.66,-81.49,33877634],
		["Jasper","AL",33.85,-87.27,33879389],
		["Purvis","MS",31.14,-89.4,33881144],
		["Saint Clairsville","OH",40.07,-80.89,33882899],
		["South Bend","IN",41.67,-86.26,33884654],
		["San Diego","CA",32.73,-117.19,33886409],
		["Calera","AL",33.1,-86.75,33888163],
		["Northport","AL",33.24,-87.59,33889917],
		["Wood Dale","IL",41.96,-87.98,33891671],
		["Roanoke","VA",37.27,-79.95,33893424],
		["Southborough","MA",42.3,-71.51,33895176],
		["Kew Gardens","NY",40.7,-73.82,33896928],
		["Beaufort","SC",32.42,-80.68,33898680],
		["Spring Hill","FL",28.55,-82.39,33900432],
		["Bellbrook","OH",39.64,-84.08,33902184],
		["Omaha","NE",41.26,-96.01,33903936],
		["New Castle","PA",40.99,-80.34,33905687],
		["New Cumberland","PA",40.23,-76.87,33907437],
		["Lafayette","IN",40.41,-86.87,33909187],
		["Minneapolis","MN",44.96,-93.26,33910937],
		["Cullman","AL",34.17,-86.83,33912686],
		["Montgomery","AL",32.35,-86.28,33914435],
		["Ashville","OH",39.71,-82.95,33916184],
		["Mc Cordsville","IN",39.9,-85.93,33917933],
		["Avenal","CA",35.92,-120.1,33919682],
		["Oak Ridge","NJ",41.04,-74.48,33921430],
		["Canal Fulton","OH",40.89,-81.58,33923178],
		["Fort Smith","AR",35.36,-94.38,33924926],
		["Annville","PA",40.33,-76.5,33926673],
		["Beatrice","NE",40.26,-96.74,33928420],
		["Lake Havasu City","AZ",34.48,-114.32,33930167],
		["Tuscaloosa","AL",33.23,-87.54,33931913],
		["Powell","WY",44.79,-108.73,33933658],
		["Haleyville","AL",34.23,-87.61,33935402],
		["Norwell","MA",42.16,-70.78,33937145],
		["Williamston","SC",34.61,-82.47,33938888],
		["Atoka","TN",35.44,-89.77,33940631],
		["Lake Grove","NY",40.85,-73.11,33942373],
		["Cincinnati","OH",39.09,-84.51,33944115],
		["Winchendon","MA",42.68,-72.04,33945856],
		["Gary","IN",41.59,-87.34,33947597],
		["Hamden","CT",41.37,-72.9,33949337],
		["Harpers Ferry","WV",39.32,-77.74,33951077],
		["Delafield","WI",43.06,-88.39,33952816],
		["Plainfield","NJ",40.63,-74.4,33954554],
		["Mount Vernon","NY",40.91,-73.82,33956292],
		["Rising Sun","MD",39.69,-76.06,33958030],
		["Ridgeland","SC",32.48,-80.98,33959768],
		["Bishop","CA",37.5,-118.31,33961506],
		["New Oxford","PA",39.86,-77.05,33963243],
		["Morgan","UT",41.01,-111.67,33964980],
		["Pennsville","NJ",39.65,-75.51,33966716],
		["Boulder City","NV",35.91,-114.8,33968452],
		["West Hartford","CT",41.75,-72.74,33970187],
		["Newville","PA",40.17,-77.4,33971922],
		["Manchester","MD",39.65,-76.89,33973657],
		["Venice","CA",33.99,-118.46,33975392],
		["Somerset","PA",40,-79.07,33977126],
		["Jerseyville","IL",39.11,-90.32,33978860],
		["Birmingham","AL",33.52,-86.79,33980593],
		["Lake Ariel","PA",41.45,-75.38,33982325],
		["Tampa","FL",27.95,-82.48,33984057],
		["Boulder","CO",40.02,-105.25,33985789],
		["Keansburg","NJ",40.44,-74.13,33987520],
		["Johnstown","NY",43,-74.37,33989251],
		["Boynton Beach","FL",26.52,-80.08,33990982],
		["Lincoln","NE",40.81,-96.68,33992713],
		["Aransas Pass","TX",27.9,-97.14,33994444],
		["Middleton","ID",43.74,-116.58,33996175],
		["Beech Grove","IN",39.71,-86.08,33997905],
		["Wrentham","MA",42.06,-71.33,33999634],
		["Canastota","NY",43.08,-75.75,34001363],
		["Spring Grove","IL",42.44,-88.24,34003092],
		["Noble","OK",35.14,-97.37,34004821],
		["Feeding Hills","MA",42.06,-72.67,34006549],
		["Robertsdale","AL",30.55,-87.7,34008277],
		["Brandenburg","KY",37.99,-86.18,34010005],
		["Syracuse","NY",43.04,-76.14,34011732],
		["Miami","FL",25.77,-80.2,34013459],
		["Chicago","IL",41.83,-87.68,34015186],
		["Coventry","CT",41.78,-72.3,34016912],
		["Attalla","AL",34.01,-86.1,34018638],
		["Murfreesboro","TN",35.85,-86.39,34020363],
		["Bonifay","FL",30.78,-85.68,34022087],
		["Lehigh Acres","FL",26.6,-81.63,34023811],
		["Creedmoor","NC",36.12,-78.68,34025534],
		["Cleveland","OH",41.47,-81.67,34027257],
		["Rehoboth","MA",41.83,-71.26,34028979],
		["Wellington","OH",41.16,-82.22,34030701],
		["Page","AZ",36.68,-111.41,34032423],
		["Lander","WY",42.83,-108.72,34034144],
		["Buckeye","AZ",33.56,-112.57,34035865],
		["Jamison","PA",40.25,-75.08,34037585],
		["Abingdon","VA",36.7,-81.96,34039305],
		["New Market","AL",34.9,-86.42,34041025],
		["La Grange Park","IL",41.83,-87.87,34042745],
		["Wichita","KS",37.68,-97.34,34044465],
		["Brandywine","MD",38.69,-76.85,34046184],
		["Flossmoor","IL",41.53,-87.68,34047903],
		["Tyngsboro","MA",42.68,-71.41,34049621],
		["Harrison","NY",41.02,-73.72,34051339],
		["Saint Pauls","NC",34.8,-78.97,34053057],
		["Tillamook","OR",45.46,-123.71,34054775],
		["Weatherford","TX",32.75,-97.77,34056492],
		["Wichita Falls","TX",33.89,-98.51,34058209],
		["Nuevo","CA",33.82,-117.1,34059926],
		["Bedford","MA",42.48,-71.26,34061642],
		["Glenshaw","PA",40.52,-79.95,34063358],
		["Blountville","TN",36.53,-82.32,34065074],
		["Burkburnett","TX",34.07,-98.56,34066790],
		["Antigo","WI",45.14,-89.15,34068505],
		["Asheville","NC",35.57,-82.54,34070219],
		["Live Oak","CA",39.23,-121.78,34071933],
		["Cody","WY",44.51,-109.05,34073646],
		["Hudson","NC",35.84,-81.48,34075358],
		["Suffield","CT",41.98,-72.65,34077069],
		["Thurmont","MD",39.62,-77.4,34078780],
		["Crossett","AR",33.12,-91.96,34080491],
		["Gladstone","OR",45.38,-122.59,34082202],
		["Oxford","CT",41.42,-73.11,34083912],
		["Winchester","VA",39.17,-78.17,34085622],
		["Williamston","MI",42.68,-84.28,34087332],
		["Oklahoma City","OK",35.46,-97.51,34089042],
		["Salem","UT",40.02,-111.64,34090752],
		["Gibsonville","NC",36.1,-79.54,34092460],
		["Duncan","SC",34.93,-82.13,34094168],
		["Broken Bow","OK",34.02,-94.73,34095876],
		["Needville","TX",29.39,-95.83,34097584],
		["Austin","TX",30.26,-97.74,34099292],
		["Fort Mohave","AZ",35,-114.56,34101000],
		["Lake Station","IN",41.57,-87.26,34102707],
		["Warsaw","IN",41.23,-85.85,34104414],
		["Belding","MI",43.09,-85.23,34106121],
		["Anna","TX",33.35,-96.54,34107828],
		["Brooklyn","NY",40.64,-73.94,34109534],
		["Medina","NY",43.21,-78.38,34111240],
		["Reno","NV",39.52,-119.81,34112946],
		["Lebanon","KY",37.56,-85.25,34114651],
		["Amarillo","TX",35.2,-101.81,34116356],
		["Newport Beach","CA",33.62,-117.93,34118061],
		["Magnolia","DE",39.06,-75.47,34119765],
		["Valley Center","KS",37.83,-97.37,34121469],
		["Sulphur","LA",30.22,-93.35,34123173],
		["San Francisco","CA",37.78,-122.43,34124877],
		["Hempstead","TX",30.09,-96.08,34126580],
		["Sunnyvale","CA",37.42,-122,34128283],
		["Springfield","MA",42.11,-72.53,34129985],
		["Tulsa","OK",36.12,-95.91,34131687],
		["Lake Wales","FL",27.9,-81.58,34133388],
		["Cadiz","KY",36.86,-87.82,34135089],
		["Cincinnati","OH",39.09,-84.51,34136790],
		["Saginaw","MI",43.41,-83.94,34138491],
		["Los Gatos","CA",37.22,-121.99,34140192],
		["Buzzards Bay","MA",41.75,-70.61,34141892],
		["Independence","MO",39.08,-94.35,34143592],
		["Graham","TX",33.1,-98.57,34145292],
		["Texas City","TX",29.4,-94.95,34146992],
		["Tavares","FL",28.8,-81.73,34148691],
		["Indianapolis","IN",39.77,-86.14,34150390],
		["Sugar Grove","IL",41.76,-88.45,34152089],
		["Liberty Hill","TX",30.66,-97.92,34153788],
		["Greenacres","WA",47.63,-117.14,34155487],
		["Coopersburg","PA",40.51,-75.39,34157185],
		["Dudley","NC",35.27,-78.03,34158883],
		["Bishopville","SC",34.21,-80.24,34160581],
		["Brighton","TN",35.47,-89.73,34162279],
		["Ashland","WI",46.57,-90.87,34163977],
		["Benton","LA",32.69,-93.74,34165675],
		["Hahira","GA",30.99,-83.37,34167372],
		["Sunbury","OH",40.24,-82.86,34169069],
		["Port Isabel","TX",26.07,-97.22,34170766],
		["Portage","WI",43.54,-89.46,34172462],
		["Minneapolis","MN",44.96,-93.26,34174157],
		["Canton","TX",32.54,-95.86,34175852],
		["Coldwater","MS",34.69,-89.97,34177546],
		["Hollidaysburg","PA",40.43,-78.39,34179239],
		["Smithville","TN",35.95,-85.82,34180932],
		["Dayton","OH",39.77,-84.19,34182625],
		["Trinidad","CO",37.16,-104.5,34184318],
		["Gardnerville","NV",38.91,-119.79,34186011],
		["Kansas City","MO",39.09,-94.58,34187703],
		["Cobleskill","NY",42.67,-74.48,34189394],
		["Ocala","FL",29.18,-82.13,34191085],
		["Brandon","SD",43.59,-96.57,34192776],
		["Seattle","WA",47.63,-122.29,34194467],
		["Charleston","WV",38.35,-81.63,34196157],
		["Austin","TX",30.26,-97.74,34197847],
		["Seattle","WA",47.57,-122.4,34199537],
		["Pelzer","SC",34.64,-82.46,34201226],
		["Baltimore","MD",39.3,-76.61,34202914],
		["Wadesboro","NC",34.96,-80.06,34204602],
		["Mount Pleasant","IA",40.96,-91.54,34206290],
		["Willits","CA",39.49,-123.36,34207978],
		["Braselton","GA",34.1,-83.78,34209665],
		["San Angelo","TX",31.44,-100.45,34211352],
		["Jamestown","TN",36.43,-84.93,34213038],
		["Gonzales","TX",29.5,-97.44,34214724],
		["Tyrone","PA",40.67,-78.24,34216408],
		["Missoula","MT",46.94,-113.85,34218092],
		["Bonner Springs","KS",39.05,-94.88,34219776],
		["Pine Bush","NY",41.6,-74.29,34221459],
		["Saint Cloud","MN",45.55,-94.17,34223142],
		["Austin","TX",30.26,-97.74,34224825],
		["Succasunna","NJ",40.85,-74.65,34226507],
		["Norfolk","VA",36.84,-76.28,34228189],
		["Gainesville","FL",29.67,-82.33,34229871],
		["Port Charlotte","FL",26.98,-82.1,34231553],
		["Crockett","TX",31.31,-95.45,34233235],
		["Pearsall","TX",28.88,-99.09,34234917],
		["Fort Mill","SC",34.99,-80.86,34236597],
		["Lamesa","TX",32.73,-101.95,34238277],
		["Walnut Creek","CA",37.91,-122.07,34239957],
		["New Providence","NJ",40.7,-74.4,34241636],
		["Omaha","NE",41.26,-96.01,34243315],
		["Sherman Oaks","CA",34.14,-118.46,34244994],
		["Ipswich","MA",42.67,-70.83,34246672],
		["Carbondale","PA",41.57,-75.5,34248350],
		["Chelsea","MI",42.31,-84.02,34250028],
		["Ridgefield Park","NJ",40.85,-74.02,34251705],
		["West Point","NY",41.36,-74.02,34253382],
		["Lehigh Acres","FL",26.59,-81.72,34255059],
		["Seattle","WA",47.65,-122.4,34256736],
		["Alpharetta","GA",34.06,-84.27,34258412],
		["Dallas","TX",32.79,-96.76,34260088],
		["New Hope","PA",40.36,-74.95,34261763],
		["Irvine","KY",37.69,-83.96,34263438],
		["West Frankfort","IL",37.9,-88.92,34265113],
		["Lampasas","TX",31.06,-98.18,34266788],
		["Northridge","CA",34.24,-118.5,34268463],
		["Mohnton","PA",40.28,-75.98,34270137],
		["Bainbridge","GA",30.8,-84.5,34271811],
		["Saint Paul","MN",44.94,-93.1,34273485],
		["Portland","OR",45.5,-122.69,34275159],
		["Arlington","MA",42.41,-71.16,34276832],
		["Sylva","NC",35.37,-83.22,34278505],
		["Cleveland","OH",41.47,-81.67,34280178],
		["Lucasville","OH",38.87,-82.99,34281851],
		["Poteau","OK",35.04,-94.63,34283524],
		["Paris","TX",33.66,-95.54,34285197],
		["Miami","FL",25.77,-80.2,34286869],
		["Papillion","NE",41.15,-96.04,34288541],
		["Hughson","CA",37.59,-120.85,34290213],
		["Huntington","WV",38.41,-82.43,34291884],
		["Indianapolis","IN",39.77,-86.14,34293554],
		["Troy","MI",42.58,-83.14,34295224],
		["Amarillo","TX",35.2,-101.81,34296894],
		["Fayetteville","NY",43.02,-76,34298563],
		["Venetia","PA",40.26,-80.05,34300232],
		["Denver","CO",39.76,-104.87,34301901],
		["Pueblo","CO",38.27,-104.62,34303570],
		["East Hampton","CT",41.57,-72.49,34305238],
		["Saint Joseph","MO",39.76,-94.85,34306906],
		["Fort Stockton","TX",30.89,-102.88,34308574],
		["Schuylkill Haven","PA",40.62,-76.17,34310241],
		["Sellersville","PA",40.36,-75.3,34311908],
		["Davenport","FL",28.16,-81.6,34313575],
		["Kayenta","AZ",36.64,-110.18,34315241],
		["Lubbock","TX",33.57,-101.87,34316906],
		["Amory","MS",33.98,-88.48,34318569],
		["Davenport","IA",41.55,-90.6,34320232],
		["Saint Joseph","MN",45.56,-94.31,34321895],
		["Thief River Falls","MN",48.11,-96.17,34323558],
		["Charleston","WV",38.35,-81.63,34325220],
		["Ocala","FL",29.18,-82.13,34326882],
		["Lewisville","TX",33.04,-96.98,34328544],
		["San Francisco","CA",37.77,-122.41,34330206],
		["Stafford Springs","CT",41.95,-72.3,34331867],
		["Manhattan","KS",39.19,-96.59,34333528],
		["Mexia","TX",31.67,-96.48,34335189],
		["Hampton Bays","NY",40.86,-72.52,34336849],
		["Portsmouth","VA",36.83,-76.29,34338509],
		["Boynton Beach","FL",26.52,-80.08,34340169],
		["Gridley","CA",39.35,-121.76,34341829],
		["Richboro","PA",40.22,-75,34343488],
		["Cedar","MN",45.34,-93.24,34345147],
		["Hampton","NH",42.94,-70.82,34346805],
		["Croton On Hudson","NY",41.22,-73.89,34348463],
		["Colville","WA",48.67,-117.75,34350121],
		["Avenel","NJ",40.58,-74.27,34351778],
		["Brookville","OH",39.83,-84.41,34353435],
		["Gary","IN",41.59,-87.34,34355092],
		["Ripon","WI",43.84,-88.84,34356749],
		["Burnet","TX",30.76,-98.22,34358406],
		["Madras","OR",44.6,-121.05,34360062],
		["New Brighton","PA",40.73,-80.3,34361717],
		["Wynnewood","PA",39.99,-75.27,34363372],
		["Boyds","MD",39.18,-77.31,34365027],
		["Rockwood","TN",35.87,-84.67,34366682],
		["Bainbridge","GA",30.9,-84.57,34368337],
		["Far Rockaway","NY",40.59,-73.81,34369991],
		["Leeds","AL",33.54,-86.56,34371645],
		["Dry Ridge","KY",38.68,-84.59,34373299],
		["Minneapolis","MN",44.96,-93.26,34374953],
		["Glenpool","OK",35.95,-96,34376607],
		["Seminole","FL",27.84,-82.78,34378260],
		["Fairview","TN",35.97,-87.12,34379913],
		["Middleville","MI",42.71,-85.46,34381566],
		["Rogersville","MO",37.11,-93.05,34383219],
		["Encino","CA",34.15,-118.49,34384872],
		["Highland Mills","NY",41.35,-74.12,34386524],
		["Radford","VA",37.12,-80.55,34388176],
		["Clarendon Hills","IL",41.79,-87.95,34389828],
		["Alfred","NY",42.25,-77.78,34391479],
		["Saltillo","MS",34.38,-88.68,34393130],
		["Austin","TX",30.26,-97.74,34394781],
		["Birmingham","AL",33.52,-86.79,34396431],
		["Mechanicville","NY",42.9,-73.69,34398080],
		["San Jose","CA",37.28,-121.98,34399729],
		["Romeo","MI",42.8,-83,34401377],
		["Saint Clair","MO",38.35,-90.98,34403025],
		["Littlestown","PA",39.74,-77.08,34404672],
		["Sun City","CA",33.7,-117.19,34406319],
		["Claremont","NH",43.37,-72.33,34407965],
		["Cromwell","CT",41.6,-72.63,34409611],
		["Aberdeen","MS",33.82,-88.55,34411257],
		["Rushville","IN",39.61,-85.44,34412902],
		["Caro","MI",43.48,-83.4,34414547],
		["Carolina","PR",18.4,-65.98,34416191],
		["Rockville","MD",39.08,-77.15,34417835],
		["Paris","IL",39.61,-87.69,34419479],
		["Bajadero","PR",18.4,-66.66,34421122],
		["Franklinville","NJ",39.61,-75.02,34422765],
		["Wilkes Barre","PA",41.24,-75.87,34424408],
		["Sheffield Lake","OH",41.48,-82.09,34426051],
		["Manhattan","IL",41.42,-87.98,34427694],
		["Scott","LA",30.23,-92.09,34429337],
		["Brush Prairie","WA",45.73,-122.45,34430980],
		["Honesdale","PA",41.57,-75.25,34432622],
		["Locust Grove","VA",38.33,-77.79,34434264],
		["West Palm Beach","FL",26.74,-80.12,34435906],
		["Salyersville","KY",37.74,-83.06,34437548],
		["Finksburg","MD",39.49,-76.88,34439189],
		["Maiden","NC",35.57,-81.2,34440830],
		["Hobe Sound","FL",27.07,-80.13,34442471],
		["Clearwater","FL",27.97,-82.76,34444112],
		["Tulsa","OK",36.12,-95.91,34445753],
		["Richmond","CA",37.94,-122.31,34447394],
		["Atco","NJ",39.76,-74.85,34449034],
		["Beachwood","NJ",39.92,-74.2,34450674],
		["Independence","OR",44.83,-123.2,34452314],
		["Ontario","NY",43.22,-77.31,34453953],
		["Willard","OH",41.05,-82.72,34455592],
		["Hopatcong","NJ",40.95,-74.65,34457230],
		["Moyock","NC",36.48,-76.13,34458868],
		["Stanford","KY",37.53,-84.66,34460506],
		["Vassar","MI",43.37,-83.57,34462144],
		["La Crosse","WI",43.82,-91.23,34463782],
		["Lakeville","MA",41.85,-70.95,34465419],
		["Carroll","IA",42.06,-94.86,34467056],
		["Chicago Ridge","IL",41.7,-87.77,34468693],
		["Shelley","ID",43.34,-112.07,34470330],
		["Oxford","MA",42.11,-71.87,34471966],
		["Verona","NJ",40.83,-74.24,34473602],
		["Birmingham","AL",33.52,-86.79,34475238],
		["Knoxville","IA",41.31,-93.1,34476874],
		["Wyoming","MN",45.33,-92.99,34478510],
		["Mullins","SC",34.2,-79.25,34480145],
		["Bremen","IN",41.44,-86.15,34481780],
		["Clinton","CT",41.27,-72.53,34483414],
		["Becker","MN",45.37,-93.87,34485048],
		["Tampa","FL",27.95,-82.48,34486681],
		["Houston","TX",29.76,-95.38,34488314],
		["Pocahontas","AR",36.26,-90.96,34489946],
		["Henderson","CO",39.92,-104.87,34491578],
		["Snoqualmie","WA",47.53,-121.8,34493210],
		["Walhalla","SC",34.77,-83.06,34494841],
		["Greensboro","NC",36.07,-79.82,34496470],
		["Clinton","MA",42.41,-71.68,34498098],
		["Fremont","MI",43.46,-85.95,34499726],
		["Colbert","WA",47.84,-117.35,34501354],
		["Ottawa","OH",41.02,-84.04,34502980],
		["Waller","TX",30.05,-95.92,34504606],
		["Henrico","VA",37.55,-77.46,34506231],
		["Clearwater","FL",27.97,-82.76,34507856],
		["Austin","TX",30.26,-97.74,34509481],
		["Las Vegas","NV",36.18,-115.38,34511106],
		["Kansas City","MO",39.09,-94.58,34512730],
		["Commerce","TX",33.23,-95.9,34514354],
		["Woodland Park","CO",38.99,-105.05,34515978],
		["Sheridan","AR",34.3,-92.4,34517601],
		["Fort Worth","TX",32.75,-97.33,34519224],
		["Beaumont","TX",30.08,-94.14,34520847],
		["Preston","ID",42.2,-111.74,34522470],
		["Farmington","NM",36.75,-108.18,34524093],
		["Andrews","SC",33.44,-79.56,34525715],
		["Saint Petersburg","FL",27.75,-82.64,34527337],
		["Omaha","NE",41.26,-96.01,34528959],
		["Lake Dallas","TX",33.12,-97.02,34530581],
		["Flint","TX",32.2,-95.39,34532203],
		["Grover Beach","CA",35.12,-120.61,34533825],
		["Bridgeville","PA",40.35,-80.1,34535446],
		["Columbus","OH",39.98,-82.98,34537067],
		["Elburn","IL",41.89,-88.47,34538688],
		["Carl Junction","MO",37.16,-94.54,34540309],
		["Elsa","TX",26.29,-97.99,34541930],
		["Montgomery","NY",41.52,-74.23,34543550],
		["Lewes","DE",38.76,-75.17,34545170],
		["Fort Bragg","CA",39.47,-123.63,34546790],
		["Saint Marys","PA",41.42,-78.55,34548408],
		["Souderton","PA",40.31,-75.32,34550026],
		["Fort Lauderdale","FL",26.14,-80.13,34551644],
		["Anniston","AL",33.66,-85.81,34553262],
		["Gretna","NE",41.14,-96.24,34554880],
		["Broussard","LA",30.15,-91.97,34556498],
		["Roanoke","AL",33.14,-85.36,34558115],
		["Westchester","IL",41.85,-87.88,34559732],
		["South Point","OH",38.41,-82.57,34561348],
		["Edgerton","WI",42.83,-89.07,34562964],
		["Metairie","LA",29.99,-90.17,34564580],
		["Caldwell","TX",30.52,-96.7,34566196],
		["Bristol","VA",36.61,-82.16,34567811],
		["Barbourville","KY",36.86,-83.88,34569426],
		["Hanson","MA",42.06,-70.85,34571040],
		["Orono","ME",44.88,-68.68,34572654],
		["Knox","IN",41.29,-86.62,34574268],
		["Berkley","MI",42.49,-83.18,34575882],
		["Millbury","MA",42.19,-71.76,34577495],
		["Blairsville","PA",40.43,-79.26,34579108],
		["Columbus","MS",33.5,-88.4,34580721],
		["Dayton","OH",39.77,-84.19,34582334],
		["Terre Haute","IN",39.47,-87.38,34583947],
		["Port Angeles","WA",48.02,-123.82,34585560],
		["Bassett","VA",36.76,-79.98,34587172],
		["Saint Augustine","FL",29.8,-81.26,34588784],
		["Umatilla","FL",28.92,-81.66,34590396],
		["Chesterland","OH",41.52,-81.34,34592008],
		["Pecos","TX",31.4,-103.5,34593620],
		["Corryton","TN",36.12,-83.8,34595231],
		["Nelsonville","OH",39.45,-82.22,34596842],
		["Grover","MO",38.57,-90.65,34598453],
		["Overland Park","KS",38.9,-94.68,34600063],
		["Topeka","KS",39.03,-95.69,34601673],
		["Fortville","IN",39.93,-85.84,34603282],
		["Tucson","AZ",32.06,-111.42,34604890],
		["Sarasota","FL",27.33,-82.54,34606497],
		["North Port","FL",27.04,-82.19,34608104],
		["Jackson","KY",37.55,-83.37,34609711],
		["Duvall","WA",47.73,-121.93,34611318],
		["Panama City","FL",30.17,-85.67,34612924],
		["Russellville","AL",34.5,-87.72,34614530],
		["Kentwood","LA",30.93,-90.51,34616136],
		["West Helena","AR",34.54,-90.64,34617742],
		["Mineola","TX",32.66,-95.48,34619348],
		["Prince George","VA",37.22,-77.28,34620953],
		["Pfafftown","NC",36.15,-80.3,34622558],
		["Highlands","TX",29.81,-95.05,34624163],
		["Glenview","IL",42.08,-87.82,34625767],
		["Tulsa","OK",36.12,-95.91,34627371],
		["San Juan","PR",18.4,-66.06,34628974],
		["Monticello","NY",41.65,-74.68,34630577],
		["Hudson Falls","NY",43.3,-73.58,34632180],
		["Tunkhannock","PA",41.53,-75.94,34633782],
		["Rancho Santa Fe","CA",33.02,-117.19,34635384],
		["Whiting","IN",41.67,-87.48,34636985],
		["River Edge","NJ",40.92,-74.04,34638584],
		["New York","NY",40.71,-73.99,34640183],
		["Glennville","GA",31.93,-81.93,34641782],
		["Tucson","AZ",32.46,-110.97,34643381],
		["Gonzales","CA",36.54,-121.39,34644980],
		["Hawkinsville","GA",32.28,-83.47,34646578],
		["Waupun","WI",43.63,-88.73,34648176],
		["Goddard","KS",37.65,-97.57,34649774],
		["Albany","NY",42.66,-73.79,34651371],
		["Wallingford","PA",39.89,-75.37,34652968],
		["Herrin","IL",37.8,-89.03,34654565],
		["Chanute","KS",37.67,-95.48,34656161],
		["Syracuse","NY",43.04,-76.14,34657756],
		["Perry Hall","MD",39.4,-76.47,34659351],
		["Ada","OH",40.76,-83.82,34660946],
		["Adel","GA",31.13,-83.42,34662540],
		["Spearfish","SD",44.48,-103.84,34664134],
		["Mesquite","NV",36.78,-114.14,34665728],
		["North Bend","OR",43.48,-124.16,34667322],
		["Rumson","NJ",40.36,-74,34668915],
		["Burton","MI",42.99,-83.61,34670508],
		["Bellport","NY",40.75,-72.94,34672100],
		["Nolensville","TN",35.94,-86.64,34673692],
		["Goshen","OH",39.22,-84.09,34675284],
		["Cincinnati","OH",39.09,-84.51,34676876],
		["Princeton","IN",38.35,-87.56,34678468],
		["Taneytown","MD",39.65,-77.16,34680059],
		["Cottage Grove","WI",43.08,-89.2,34681650],
		["Dexter","MO",36.79,-89.96,34683241],
		["Syracuse","NY",43.04,-76.14,34684831],
		["Macedon","NY",43.06,-77.3,34686421],
		["Corry","PA",41.92,-79.63,34688011],
		["Ripley","MS",34.72,-88.94,34689601],
		["Walnut Grove","MS",32.59,-89.45,34691191],
		["Grand Terrace","CA",34.03,-117.31,34692781],
		["Omaha","NE",41.26,-96.01,34694370],
		["Mashpee","MA",41.65,-70.48,34695958],
		["Spring City","PA",40.17,-75.54,34697546],
		["Saginaw","MI",43.41,-84.02,34699134],
		["Milton","WI",42.77,-88.94,34700722],
		["Donaldsonville","LA",30.09,-90.99,34702310],
		["Wytheville","VA",36.95,-81.08,34703897],
		["Cornelia","GA",34.51,-83.52,34705484],
		["Bridgeport","TX",33.2,-97.75,34707071],
		["Hillsboro","TX",32,-97.11,34708658],
		["Colorado City","AZ",36.97,-112.98,34710245],
		["Hoquiam","WA",47.11,-123.96,34711832],
		["Carver","MA",41.88,-70.76,34713417],
		["Indian Head","MD",38.59,-77.15,34715002],
		["Anchorage","AK",61.18,-149.88,34716587],
		["Charleston","WV",38.35,-81.63,34718171],
		["Spencer","IA",43.14,-95.15,34719755],
		["West Harrison","NY",41.05,-73.74,34721338],
		["Buffalo","NY",42.88,-78.85,34722921],
		["Carrollton","GA",33.58,-85.07,34724504],
		["Austin","TX",30.26,-97.74,34726087],
		["San Juan","PR",18.4,-66.06,34727669],
		["Prince Frederick","MD",38.54,-76.58,34729251],
		["Wonder Lake","IL",42.38,-88.36,34730833],
		["Winfield","IL",41.87,-88.15,34732415],
		["Giddings","TX",30.18,-96.92,34733997],
		["Johnstown","PA",40.32,-78.91,34735578],
		["Clearfield","PA",41.02,-78.43,34737159],
		["Mansfield","OH",40.76,-82.52,34738740],
		["Haslett","MI",42.75,-84.4,34740321],
		["Glencoe","IL",42.13,-87.76,34741902],
		["Eugene","OR",44.13,-123.07,34743483],
		["Eatonville","WA",46.86,-122.25,34745064],
		["Youngstown","OH",41.09,-80.64,34746643],
		["Wheelersburg","OH",38.73,-82.84,34748222],
		["Bryan","TX",30.66,-96.36,34749801],
		["Johnstown","CO",40.33,-104.91,34751380],
		["Hillsdale","NJ",41,-74.04,34752958],
		["Georgetown","IN",38.29,-85.97,34754536],
		["Fairmont","NC",34.49,-79.11,34756113],
		["Indianapolis","IN",39.77,-86.14,34757690],
		["Sioux City","IA",42.5,-96.39,34759267],
		["Chester","NJ",40.78,-74.69,34760843],
		["Russell Springs","KY",37.05,-85.07,34762419],
		["Herkimer","NY",43.02,-74.99,34763994],
		["Louisville","KY",38.22,-85.74,34765569],
		["Waseca","MN",44.08,-93.5,34767144],
		["Clinton","MO",38.37,-93.76,34768719],
		["Shreveport","LA",32.47,-93.79,34770294],
		["Stony Brook","NY",40.9,-73.12,34771868],
		["Reading","PA",40.33,-75.92,34773442],
		["Punta Gorda","FL",26.89,-82.05,34775016],
		["Denison","IA",42.01,-95.34,34776590],
		["Port Washington","WI",43.39,-87.87,34778164],
		["Berlin","NJ",39.79,-74.93,34779737],
		["Lake Wales","FL",27.9,-81.58,34781310],
		["Struthers","OH",41.05,-80.59,34782883],
		["Boerne","TX",29.8,-98.73,34784456],
		["San Antonio","TX",29.45,-98.5,34786029],
		["Harrison","MI",44.01,-84.8,34787601],
		["Wichita","KS",37.68,-97.34,34789173],
		["Mead","WA",47.83,-117.21,34790745],
		["Des Moines","IA",41.57,-93.61,34792315],
		["Gilmer","TX",32.73,-94.94,34793885],
		["Timmonsville","SC",34.13,-79.94,34795454],
		["Windsor Locks","CT",41.92,-72.65,34797022],
		["Washingtonville","NY",41.42,-74.15,34798590],
		["Wilmington","DE",39.73,-75.53,34800158],
		["Shipshewana","IN",41.67,-85.57,34801726],
		["Lincoln","NE",40.81,-96.68,34803294],
		["Shreveport","LA",32.47,-93.79,34804862],
		["Fort Polk","LA",31.13,-93.27,34806430],
		["Brighton","CO",39.97,-104.74,34807998],
		["Ilion","NY",43.01,-75.04,34809565],
		["Norfolk","VA",36.84,-76.28,34811132],
		["Saint Paul","MN",44.94,-93.1,34812699],
		["Pittsburgh","PA",40.43,-79.97,34814265],
		["Newport News","VA",37.07,-76.51,34815831],
		["Bradenton","FL",27.48,-82.57,34817397],
		["Lanett","AL",32.86,-85.2,34818963],
		["Bluff City","TN",36.46,-82.27,34820529],
		["Farmington","ME",44.66,-70.14,34822094],
		["San Juan","PR",18.4,-66.06,34823658],
		["Fresh Meadows","NY",40.73,-73.79,34825222],
		["Perkinston","MS",30.78,-89.13,34826786],
		["Groveport","OH",39.86,-82.89,34828350],
		["Gary","IN",41.59,-87.34,34829914],
		["Dorr","MI",42.72,-85.72,34831478],
		["Great Falls","MT",47.51,-111.27,34833042],
		["Alden","NY",42.89,-78.49,34834605],
		["Woodbine","MD",39.33,-77.06,34836168],
		["Evansville","IN",37.98,-87.54,34837731],
		["Kansas City","MO",39.09,-94.58,34839294],
		["Topeka","KS",39.03,-95.69,34840857],
		["Prescott","AZ",34.76,-112.88,34842420],
		["Roy","WA",46.98,-122.47,34843983],
		["Clay","NY",43.18,-76.17,34845545],
		["Effort","PA",40.96,-75.45,34847107],
		["Glenolden","PA",39.89,-75.29,34848669],
		["Geneseo","IL",41.44,-90.15,34850231],
		["Lambertville","MI",41.74,-83.62,34851792],
		["Saint Louis","MO",38.63,-90.24,34853353],
		["Ahoskie","NC",36.28,-76.98,34854913],
		["Williston","FL",29.37,-82.45,34856473],
		["Morrilton","AR",35.15,-92.74,34858033],
		["Fort Madison","IA",40.61,-91.35,34859592],
		["Berkeley","CA",37.86,-122.25,34861151],
		["Marksville","LA",31.12,-92.06,34862709],
		["San Antonio","TX",29.45,-98.5,34864267],
		["Long Beach","CA",33.77,-118.14,34865825],
		["Louisa","VA",38.01,-77.99,34867382],
		["Berkeley Springs","WV",39.55,-78.21,34868939],
		["Delphos","OH",40.84,-84.33,34870496],
		["Bakersfield","CA",35.38,-119.02,34872053],
		["Storrs Mansfield","CT",41.8,-72.25,34873609],
		["Fredericktown","MO",37.56,-90.29,34875165],
		["Saint Amant","LA",30.19,-90.78,34876721],
		["Perryton","TX",36.39,-100.8,34878277],
		["Waynesville","OH",39.53,-84.08,34879832],
		["Bonham","TX",33.58,-96.18,34881387],
		["Wellesley","MA",42.3,-71.28,34882941],
		["Lake Butler","FL",30.02,-82.33,34884495],
		["Niagara Falls","NY",43.09,-79.01,34886048],
		["Ganado","AZ",35.67,-109.65,34887601],
		["Gig Harbor","WA",47.37,-122.71,34889154],
		["Hollywood","MD",38.34,-76.57,34890706],
		["Essexville","MI",43.61,-83.84,34892258],
		["Waimanalo","HI",21.34,-157.72,34893809],
		["Huron","OH",41.39,-82.56,34895359],
		["South Beloit","IL",42.48,-89.03,34896909],
		["Lincoln","NE",40.81,-96.68,34898459],
		["Titusville","PA",41.62,-79.67,34900008],
		["Dallas","TX",32.79,-96.76,34901557],
		["New York","NY",40.71,-73.99,34903105],
		["Winston Salem","NC",36.1,-80.24,34904653],
		["Anderson","SC",34.51,-82.64,34906201],
		["Wadsworth","IL",42.43,-87.92,34907749],
		["New Bedford","MA",41.66,-70.93,34909296],
		["Atlanta","GA",33.76,-84.42,34910843],
		["Milwaukee","WI",43.06,-87.96,34912390],
		["Ludowici","GA",31.71,-81.74,34913936],
		["Glendale","AZ",33.53,-112.31,34915482],
		["Winsted","CT",41.92,-73.06,34917027],
		["Hudson","FL",28.35,-82.69,34918572],
		["Ligonier","IN",41.46,-85.59,34920117],
		["Miles City","MT",46.22,-105.6,34921662],
		["Thomasville","GA",30.83,-83.97,34923206],
		["Zephyrhills","FL",28.23,-82.17,34924750],
		["Palisades Park","NJ",40.84,-73.99,34926293],
		["Saddle Brook","NJ",40.9,-74.09,34927836],
		["Atlanta","GA",33.64,-84.46,34929379],
		["Spencer","IN",39.28,-86.76,34930922],
		["Saint Joseph","MO",39.76,-94.85,34932465],
		["Bulverde","TX",29.73,-98.45,34934008],
		["Sacramento","CA",38.55,-121.45,34935551],
		["Harrisburg","PA",40.27,-76.88,34937093],
		["Bridgewater","VA",38.38,-78.96,34938635],
		["Lisbon","OH",40.77,-80.76,34940177],
		["Sandersville","GA",32.98,-82.81,34941718],
		["Moultrie","GA",31.12,-83.68,34943259],
		["Mc Farland","WI",43.01,-89.29,34944800],
		["Philadelphia","PA",39.95,-75.16,34946340],
		["Wilkesboro","NC",36.14,-81.16,34947880],
		["Lima","OH",40.68,-84.12,34949420],
		["Vancouver","WA",45.65,-122.66,34950960],
		["Waxahachie","TX",32.4,-96.85,34952499],
		["Brownfield","TX",33.17,-102.27,34954038],
		["Fords","NJ",40.54,-74.31,34955576],
		["Felton","DE",39,-75.57,34957114],
		["Grosse Ile","MI",42.12,-83.14,34958652],
		["Denver","CO",39.76,-104.87,34960190],
		["Suncook","NH",43.13,-71.45,34961727],
		["Whitehouse Station","NJ",40.6,-74.76,34963264],
		["East Dublin","GA",32.53,-82.92,34964801],
		["Cazenovia","NY",42.92,-75.85,34966337],
		["Cottondale","AL",33.16,-87.36,34967873],
		["Junction City","OR",44.21,-123.28,34969409],
		["Pageland","SC",34.77,-80.38,34970944],
		["Grove","OK",36.59,-94.78,34972479],
		["Williston Park","NY",40.75,-73.64,34974013],
		["Galena","OH",40.22,-82.88,34975547],
		["Olney","IL",38.73,-88.08,34977081],
		["Eldon","MO",38.35,-92.57,34978615],
		["Los Osos","CA",35.29,-120.81,34980149],
		["Bayside","NY",40.78,-73.78,34981682],
		["Boca Raton","FL",26.37,-80.1,34983215],
		["Little Rock","AR",34.72,-92.35,34984748],
		["Ridgefield","NJ",40.83,-74.01,34986280],
		["Roxboro","NC",36.4,-78.98,34987812],
		["Louisa","KY",38.1,-82.6,34989344],
		["Dayton","OH",39.77,-84.19,34990876],
		["Ponca City","OK",36.72,-97.06,34992408],
		["Bedford","TX",32.84,-97.13,34993940],
		["Salem","NJ",39.56,-75.47,34995471],
		["Red Hook","NY",41.99,-73.87,34997002],
		["Brooksville","FL",28.55,-82.39,34998533],
		["Inwood","WV",39.35,-78.05,35000063],
		["Jackson","AL",31.53,-87.89,35001593],
		["Dearborn","MI",42.31,-83.21,35003123],
		["Laurel","MT",45.6,-108.68,35004653],
		["Vilonia","AR",35.08,-92.21,35006183],
		["Glenwood","IL",41.54,-87.61,35007712],
		["Slatington","PA",40.75,-75.61,35009240],
		["Elwood","IN",40.27,-85.83,35010768],
		["Wilmington","IL",41.29,-88.15,35012296],
		["Keithville","LA",32.29,-93.92,35013824],
		["Tallulah","LA",32.4,-91.18,35015352],
		["Berthoud","CO",40.3,-105.08,35016880],
		["Clarkesville","GA",34.6,-83.52,35018407],
		["Houston","TX",29.76,-95.38,35019934],
		["White City","OR",42.56,-122.92,35021461],
		["Independence","LA",30.63,-90.5,35022987],
		["Lonoke","AR",34.78,-91.89,35024513],
		["Santa Barbara","CA",34.44,-119.61,35026039],
		["Washington","DC",38.89,-77.03,35027564],
		["Salem","IL",38.62,-88.95,35029089],
		["Mount Vernon","NY",40.91,-73.82,35030613],
		["Atlanta","GA",33.83,-84.38,35032137],
		["Fort Knox","KY",37.89,-85.96,35033661],
		["Macedonia","OH",41.31,-81.49,35035185],
		["Canton","OH",40.81,-81.37,35036709],
		["Morrow","OH",39.34,-84.12,35038233],
		["Lenexa","KS",38.9,-94.68,35039757],
		["Etters","PA",40.15,-76.79,35041280],
		["Charleston","WV",38.35,-81.63,35042803],
		["Charlotte","NC",35.19,-80.83,35044326],
		["Bradley","IL",41.14,-87.85,35045849],
		["Greenville","KY",37.2,-87.17,35047371],
		["Berryville","AR",36.37,-93.56,35048893],
		["Culver City","CA",34.02,-118.39,35050415],
		["Weymouth","MA",42.2,-70.94,35051936],
		["Sandwich","MA",41.75,-70.49,35053457],
		["Browns Summit","NC",36.2,-79.69,35054978],
		["Rockwell","NC",35.55,-80.4,35056499],
		["Palm Desert","CA",33.77,-116.34,35058020],
		["Orefield","PA",40.62,-75.6,35059540],
		["Dunlap","TN",35.37,-85.38,35061060],
		["Port Clinton","OH",41.5,-82.93,35062580],
		["Medford","WI",45.13,-90.34,35064100],
		["Fairless Hills","PA",40.17,-74.85,35065619],
		["Wiggins","MS",30.86,-89.13,35067138],
		["Edison","NJ",40.52,-74.36,35068656],
		["Magee","MS",31.87,-89.73,35070174],
		["Davenport","IA",41.55,-90.6,35071692],
		["Harrah","OK",35.48,-97.18,35073210],
		["Accokeek","MD",38.67,-77.01,35074727],
		["Metter","GA",32.39,-82.06,35076244],
		["Commerce","GA",34.2,-83.46,35077761],
		["Chillicothe","IL",40.91,-89.49,35079278],
		["Cushing","OK",35.97,-96.76,35080795],
		["Bath","NY",42.33,-77.31,35082311],
		["Lake Havasu City","AZ",34.61,-114.3,35083827],
		["Portland","ME",43.66,-70.25,35085342],
		["Highland Park","NJ",40.5,-74.42,35086857],
		["Edenton","NC",36.05,-76.6,35088372],
		["Marshville","NC",34.98,-80.36,35089887],
		["Mattawan","MI",42.21,-85.78,35091402],
		["Kansas City","KS",39.1,-94.62,35092917],
		["Saint Francisville","LA",30.78,-91.37,35094432],
		["North Arlington","NJ",40.79,-74.12,35095946],
		["Carthage","NY",43.98,-75.6,35097460],
		["Monaca","PA",40.68,-80.27,35098974],
		["Portland","MI",42.87,-84.9,35100488],
		["Sinton","TX",28.03,-97.5,35102002],
		["San Francisco","CA",37.8,-122.41,35103516],
		["Nine Mile Falls","WA",47.8,-117.62,35105030],
		["Malone","NY",44.84,-74.29,35106543],
		["Center Valley","PA",40.53,-75.42,35108056],
		["Cedarville","OH",39.74,-83.81,35109569],
		["Whitesboro","NY",43.12,-75.29,35111081],
		["Memphis","TN",35.1,-90,35112593],
		["Lake Bluff","IL",42.28,-87.85,35114105],
		["Sainte Genevieve","MO",37.97,-90.04,35115617],
		["Anchorage","AK",61.22,-149.85,35117129],
		["Black Mountain","NC",35.61,-82.33,35118640],
		["Nashville","GA",31.2,-83.24,35120151],
		["Fresno","CA",36.9,-119.75,35121662],
		["Montville","NJ",40.9,-74.36,35123172],
		["Gainesville","FL",29.67,-82.33,35124682],
		["Birmingham","AL",33.52,-86.79,35126192],
		["Aguirre","PR",17.96,-66.22,35127700],
		["Pittsburgh","PA",40.43,-79.97,35129208],
		["Aberdeen","NC",35.13,-79.42,35130716],
		["Pleasant Grove","AL",33.48,-86.97,35132224],
		["Bristow","OK",35.83,-96.39,35133732],
		["Waco","TX",31.56,-97.18,35135240],
		["Devine","TX",29.14,-98.9,35136748],
		["Baker City","OR",44.81,-117.74,35138256],
		["Hartville","OH",40.96,-81.33,35139763],
		["Nanticoke","PA",41.2,-76,35141269],
		["Watertown","WI",43.19,-88.72,35142775],
		["San Antonio","TX",29.45,-98.5,35144281],
		["Owego","NY",42.1,-76.26,35145786],
		["Pittsburgh","PA",40.43,-79.97,35147291],
		["Pell City","AL",33.56,-86.27,35148796],
		["Mc Kenzie","TN",36.13,-88.51,35150301],
		["Melvindale","MI",42.28,-83.17,35151806],
		["Twin Lake","MI",43.36,-86.17,35153311],
		["Iron Mountain","MI",45.82,-88.06,35154816],
		["Kansas City","KS",39.1,-94.62,35156320],
		["Washington","DC",38.89,-77.03,35157823],
		["Destin","FL",30.4,-86.49,35159326],
		["Indialantic","FL",28.08,-80.56,35160829],
		["Clyde","OH",41.3,-82.97,35162332],
		["Manistee","MI",44.24,-86.32,35163835],
		["Atlanta","TX",33.11,-94.16,35165338],
		["Mansfield","OH",40.76,-82.52,35166840],
		["Anadarko","OK",35.06,-98.24,35168342],
		["Closter","NJ",40.97,-73.96,35169843],
		["Pittston","PA",41.32,-75.78,35171344],
		["Brookville","IN",39.42,-85,35172844],
		["Scappoose","OR",45.8,-122.97,35174344],
		["Lowell","AR",36.26,-94.13,35175843],
		["Celina","TX",33.32,-96.78,35177342],
		["Beverly Hills","CA",34.06,-118.4,35178841],
		["Bogart","GA",33.94,-83.53,35180339],
		["Naples","FL",26.14,-81.79,35181837],
		["Gering","NE",41.82,-103.66,35183335],
		["Everson","WA",48.9,-122.3,35184833],
		["North Smithfield","RI",41.95,-71.55,35186330],
		["Middle Island","NY",40.88,-72.94,35187827],
		["Estacada","OR",45.2,-122.06,35189324],
		["Mount Gilead","OH",40.55,-82.83,35190820],
		["North Manchester","IN",41,-85.77,35192316],
		["Algonac","MI",42.61,-82.53,35193812],
		["Santa Monica","CA",34.03,-118.49,35195308],
		["Fresno","CA",36.77,-119.95,35196804],
		["Mattawa","WA",46.72,-119.73,35198300],
		["Scranton","PA",41.4,-75.66,35199795],
		["Centreville","MD",39.04,-76.06,35201290],
		["Montgomery","AL",32.35,-86.28,35202785],
		["Harrison","TN",35.12,-85.14,35204280],
		["Ithaca","NY",42.44,-76.5,35205774],
		["Summit","MS",31.28,-90.46,35207268],
		["New Richmond","OH",38.96,-84.27,35208762],
		["Islip Terrace","NY",40.74,-73.18,35210255],
		["Barboursville","WV",38.4,-82.29,35211748],
		["Livingston","LA",30.5,-90.74,35213241],
		["Payette","ID",44.06,-116.7,35214734],
		["Pittsburgh","PA",40.43,-79.97,35216226],
		["Carleton","MI",42.05,-83.38,35217718],
		["South Park","PA",40.29,-79.99,35219209],
		["Miami","FL",25.77,-80.2,35220700],
		["Somerville","TN",35.23,-89.35,35222191],
		["Youngstown","OH",41.09,-80.64,35223682],
		["Bethalto","IL",38.9,-90.04,35225173],
		["Wimberley","TX",29.99,-98.1,35226664],
		["Hyrum","UT",41.6,-111.72,35228155],
		["San Jose","CA",37.22,-121.78,35229646],
		["Edwardsburg","MI",41.79,-86.08,35231136],
		["Houston","TX",29.76,-95.38,35232626],
		["Berkeley","CA",37.87,-122.26,35234116],
		["West Newton","MA",42.35,-71.22,35235605],
		["Sharon Hill","PA",39.9,-75.26,35237094],
		["Martinsburg","WV",39.46,-77.96,35238583],
		["Boca Raton","FL",26.37,-80.1,35240072],
		["Leesburg","FL",28.8,-81.88,35241561],
		["Ingleside","TX",27.87,-97.2,35243050],
		["Florence","MA",42.32,-72.67,35244538],
		["Morganfield","KY",37.68,-87.9,35246026],
		["Minerva","OH",40.72,-81.1,35247514],
		["Vinita","OK",36.64,-95.15,35249002],
		["Gustine","CA",37.14,-121.04,35250490],
		["Washington","DC",38.89,-77.03,35251977],
		["Hamilton","OH",39.39,-84.56,35253464],
		["Kansas City","MO",39.09,-94.58,35254951],
		["Jupiter","FL",26.92,-80.1,35256437],
		["Stuart","FL",27.19,-80.24,35257923],
		["La Junta","CO",37.98,-103.54,35259409],
		["Grants","NM",35.15,-107.83,35260895],
		["Little River","SC",33.87,-78.63,35262380],
		["Springfield","MO",37.19,-93.28,35263865],
		["Burlington","CT",41.76,-72.96,35265349],
		["Raleigh","NC",35.82,-78.65,35266833],
		["Vienna","WV",39.32,-81.53,35268316],
		["Georgetown","TX",30.7,-97.73,35269799],
		["Hasbrouck Heights","NJ",40.86,-74.07,35271281],
		["Mantua","NJ",39.78,-75.17,35272763],
		["Virginia State University","VA",37.2,-77.39,35274245],
		["Wise","VA",36.97,-82.58,35275727],
		["Pulaski","WI",44.66,-88.23,35277209],
		["Norfolk","MA",42.11,-71.31,35278690],
		["Chesapeake Beach","MD",38.68,-76.53,35280171],
		["El Paso","TX",31.84,-106.43,35281652],
		["Ellsworth","ME",44.58,-68.49,35283132],
		["Farmington","NY",42.95,-77.32,35284612],
		["Kunkletown","PA",40.88,-75.47,35286092],
		["Owings","MD",38.69,-76.62,35287572],
		["Charlotte","NC",35.19,-80.83,35289052],
		["Chillicothe","MO",39.79,-93.55,35290532],
		["Benton City","WA",46.24,-119.49,35292012],
		["Pleasant Valley","NY",41.74,-73.82,35293491],
		["Hawley","PA",41.47,-75.18,35294970],
		["Walkersville","MD",39.48,-77.35,35296449],
		["Newberry","FL",29.64,-82.61,35297928],
		["Lake Elmo","MN",44.99,-92.9,35299407],
		["Saint Paul","MN",44.94,-93.1,35300886],
		["Waterford","PA",41.94,-79.98,35302364],
		["Leola","PA",40.09,-76.18,35303842],
		["Glenmoore","PA",40.09,-75.77,35305320],
		["Stillwater","OK",36.13,-97.07,35306798],
		["Collins","MS",31.63,-89.55,35308275],
		["Narragansett","RI",41.39,-71.48,35309750],
		["Buffalo","NY",42.88,-78.85,35311225],
		["Palmdale","CA",34.61,-117.81,35312700],
		["North Billerica","MA",42.57,-71.29,35314174],
		["Raleigh","NC",35.88,-78.75,35315648],
		["Barnhart","MO",38.33,-90.4,35317122],
		["Fairview","NJ",40.81,-74,35318595],
		["Hanover","MD",39.16,-76.72,35320068],
		["Akron","OH",41.08,-81.52,35321541],
		["Buffalo","NY",42.88,-78.85,35323013],
		["Cranbury","NJ",40.31,-74.51,35324484],
		["New York","NY",40.77,-73.95,35325955],
		["Roanoke","VA",37.27,-79.95,35327426],
		["Franklin","GA",33.28,-85.09,35328897],
		["Athens","GA",33.95,-83.39,35330368],
		["Norman","OK",35.22,-97.34,35331839],
		["Menominee","MI",45.12,-87.62,35333309],
		["Holtville","CA",32.83,-115.19,35334779],
		["Great Neck","NY",40.8,-73.73,35336248],
		["Mercer","PA",41.22,-80.23,35337717],
		["Apex","NC",35.77,-78.94,35339186],
		["Saint Louis","MO",38.63,-90.24,35340655],
		["Boonville","MO",38.96,-92.74,35342124],
		["Mansfield","LA",32.03,-93.7,35343593],
		["Greenlawn","NY",40.86,-73.36,35345061],
		["Arnaudville","LA",30.4,-91.93,35346529],
		["Fort Gibson","OK",35.78,-95.24,35347997],
		["Hamilton","MT",46.16,-114.14,35349463],
		["Socorro","NM",34.05,-106.9,35350929],
		["Boxford","MA",42.67,-70.98,35352394],
		["Terre Haute","IN",39.47,-87.38,35353859],
		["Vicksburg","MI",42.12,-85.538042,-85.53,35355324],
		["Hamilton","NY",42.82,-75.54,35356788],
		["Lady Lake","FL",28.92,-81.92,35358252],
		["Templeton","CA",35.54,-120.75,35359716],
		["High Springs","FL",29.82,-82.59,35361179],
		["Oakland","CA",37.83,-122.26,35362642],
		["Mobile","AL",30.68,-88.04,35364104],
		["Ardmore","PA",40,-75.29,35365565],
		["Interlachen","FL",29.61,-81.89,35367026],
		["Mims","FL",28.68,-80.84,35368487],
		["Erwin","TN",36.14,-82.41,35369948],
		["Clinton","OK",35.5,-98.97,35371409],
		["Spencer","MA",42.24,-71.99,35372869],
		["East Hanover","NJ",40.81,-74.36,35374329],
		["Stuarts Draft","VA",38.02,-79.02,35375788],
		["Claremont","NC",35.71,-81.15,35377247],
		["Springfield","IL",39.76,-89.57,35378705],
		["Tonganoxie","KS",39.11,-95.08,35380163],
		["Harrington","DE",38.92,-75.57,35381620],
		["Mitchell","IN",38.73,-86.47,35383077],
		["Mount Horeb","WI",43,-89.73,35384534],
		["Jackson","WY",43.47,-110.76,35385991],
		["Pompton Plains","NJ",40.96,-74.3,35387447],
		["Largo","FL",27.9,-82.78,35388903],
		["Mendenhall","MS",31.96,-89.86,35390359],
		["Sandston","VA",37.51,-77.27,35391814],
		["Dayton","OH",39.77,-84.19,35393269],
		["Pleasant Hill","IA",41.58,-93.49,35394724],
		["Stuttgart","AR",34.49,-91.55,35396179],
		["Heber Springs","AR",35.5,-92.03,35397634],
		["Crystal City","TX",28.68,-99.82,35399089],
		["Derby","CT",41.32,-73.08,35400543],
		["Hayes","VA",37.37,-76.5,35401997],
		["Alexandria","IN",40.25,-85.67,35403451],
		["Stevensville","MI",42.01,-86.52,35404905],
		["Saint Louis","MO",38.63,-90.24,35406359],
		["Winters","CA",38.56,-121.99,35407813],
		["Honeoye Falls","NY",42.95,-77.59,35409266],
		["Blacksburg","SC",35.12,-81.51,35410719],
		["Conley","GA",33.63,-84.33,35412172],
		["Sumrall","MS",31.41,-89.54,35413625],
		["Pacific Grove","CA",36.61,-121.92,35415078],
		["Armonk","NY",41.13,-73.71,35416530],
		["Brookshire","TX",29.78,-95.95,35417982],
		["Prescott","AZ",34.49,-112.44,35419434],
		["Las Marias","PR",18.27,-67.06,35420885],
		["Mission","KS",38.9,-94.68,35422336],
		["California","MD",38.29,-76.49,35423786],
		["Strasburg","VA",38.98,-78.35,35425236],
		["Dania","FL",26.05,-80.14,35426686],
		["Madisonville","LA",30.39,-90.16,35428136],
		["Albany","NY",42.66,-73.79,35429585],
		["Fort Belvoir","VA",38.7,-77.14,35431034],
		["Portsmouth","VA",36.83,-76.29,35432482],
		["Morehead City","NC",34.72,-76.73,35433930],
		["Wesson","MS",31.7,-90.39,35435378],
		["Cresskill","NJ",40.93,-73.95,35436825],
		["Washington","DC",38.89,-77.03,35438272],
		["Apollo Beach","FL",27.78,-82.39,35439719],
		["Kelseyville","CA",38.91,-122.79,35441166],
		["Sabana Seca","PR",18.42,-66.19,35442612],
		["Erie","PA",42.12,-80.08,35444058],
		["Mc Gregor","TX",31.45,-97.37,35445504],
		["Buffalo","NY",42.88,-78.85,35446949],
		["Genoa City","WI",42.5,-88.32,35448394],
		["Camp Verde","AZ",34.48,-111.78,35449839],
		["Irvine","CA",33.67,-117.73,35451284],
		["Danielson","CT",41.8,-71.88,35452728],
		["Mercersburg","PA",39.83,-77.9,35454172],
		["Westmoreland","TN",36.56,-86.24,35455616],
		["Pittsburgh","PA",40.43,-79.97,35457059],
		["Brookings","OR",42.13,-124.24,35458502],
		["Lawrence","NY",40.6,-73.71,35459944],
		["Philadelphia","PA",39.95,-75.16,35461386],
		["Clinton","OH",40.92,-81.63,35462828],
		["Apple Creek","OH",40.75,-81.84,35464270],
		["Waterloo","NY",42.9,-76.86,35465711],
		["Southfield","MI",42.47,-83.25,35467152],
		["Imlay City","MI",43.01,-83.07,35468593],
		["East Grand Forks","MN",47.93,-97.01,35470034],
		["Belvedere Tiburon","CA",37.89,-122.47,35471475],
		["Blairstown","NJ",40.98,-74.96,35472915],
		["Milton","PA",41,-76.85,35474355],
		["Elmendorf","TX",29.25,-98.33,35475795],
		["Willard","MO",37.29,-93.41,35477234],
		["Crete","NE",40.62,-96.95,35478673],
		["Camilla","GA",31.22,-84.21,35480111],
		["Nashville","AR",33.94,-93.85,35481549],
		["Lunenburg","MA",42.59,-71.72,35482986],
		["Leicester","NC",35.65,-82.68,35484423],
		["Mystic","CT",41.35,-71.97,35485859],
		["Conshohocken","PA",40.07,-75.3,35487294],
		["Princeton","KY",37.1,-87.88,35488729],
		["Dardanelle","AR",35.22,-93.16,35490164],
		["Bullard","TX",32.14,-95.31,35491599],
		["Lamar","CO",38.08,-102.61,35493034],
		["Magalia","CA",39.84,-121.6,35494469],
		["Stayton","OR",44.81,-122.72,35495904],
		["Granite Falls","WA",48.05,-121.74,35497339],
		["Mifflinburg","PA",40.92,-77.04,35498773],
		["Ecorse","MI",42.24,-83.14,35500207],
		["North Branch","MI",43.22,-83.19,35501641],
		["Bellevue","NE",41.15,-95.92,35503075],
		["Schenectady","NY",42.8,-73.92,35504508],
		["Mansfield","PA",41.8,-77.07,35505941],
		["Springfield","GA",32.36,-81.3,35507374],
		["Jackson","MS",32.32,-90.2,35508807],
		["Belmont","MI",43.07,-85.6,35510240],
		["Ishpeming","MI",46.48,-87.66,35511673],
		["Lakeport","CA",39.04,-122.93,35513106],
		["Montoursville","PA",41.24,-76.92,35514538],
		["Southport","NC",33.92,-78.02,35515970],
		["Claxton","GA",32.16,-81.9,35517402],
		["Weare","NH",43.1,-71.73,35518833],
		["Longs","SC",33.93,-78.73,35520264],
		["Novi","MI",42.47,-83.49,35521694],
		["Metropolis","IL",37.15,-88.71,35523124],
		["Sioux Center","IA",43.07,-96.17,35524553],
		["Farmersville","TX",33.16,-96.35,35525982],
		["Burbank","CA",34.17,-118.31,35527411],
		["Coarsegold","CA",37.17,-119.71,35528840],
		["Lebanon","NJ",40.64,-74.83,35530268],
		["Andalusia","AL",31.3,-86.48,35531696],
		["Morton","MS",32.35,-89.65,35533124],
		["Cincinnati","OH",39.09,-84.51,35534552],
		["Rockdale","TX",30.65,-97,35535980],
		["Newark","NJ",40.72,-74.17,35537407],
		["Pittsburgh","PA",40.43,-79.97,35538834],
		["Dobson","NC",36.39,-80.72,35540261],
		["North Fort Myers","FL",26.63,-81.85,35541688],
		["East Saint Louis","IL",38.61,-90.13,35543115],
		["Liberty Lake","WA",47.63,-117.09,35544542],
		["Waltham","MA",42.38,-71.24,35545968],
		["Orlando","FL",28.5,-81.37,35547394],
		["Hazlehurst","MS",31.86,-90.39,35548820],
		["Hayward","WI",46.01,-91.48,35550246],
		["Henryetta","OK",35.44,-95.98,35551672],
		["Snow Hill","NC",35.45,-77.67,35553097],
		["Fairmont","MN",43.64,-94.45,35554522],
		["Dublin","TX",32.08,-98.33,35555947],
		["Pompton Lakes","NJ",41,-74.28,35557371],
		["Frostproof","FL",27.74,-81.53,35558795],
		["Newton Falls","OH",41.18,-80.96,35560219],
		["Delano","MN",45.04,-93.78,35561643],
		["Peoria","IL",40.74,-89.6,35563067],
		["Bohemia","NY",40.77,-73.12,35564490],
		["Pittsburgh","PA",40.43,-79.97,35565913],
		["Hyattsville","MD",38.95,-76.95,35567336],
		["Ozark","AR",35.49,-93.84,35568759],
		["Richfield","UT",38.75,-112.1,35570182],
		["Washington","DC",38.89,-77.03,35571604],
		["Beverly Hills","FL",28.91,-82.46,35573026],
		["Amherst","VA",37.58,-79.05,35574447],
		["Cincinnati","OH",39.09,-84.51,35575868],
		["Hebron","IN",41.32,-87.2,35577289],
		["Santa Monica","CA",34.03,-118.5,35578710],
		["Birmingham","AL",33.52,-86.79,35580130],
		["Carrollton","OH",40.57,-81.09,35581550],
		["Topeka","KS",39.03,-95.69,35582970],
		["Fredericksburg","OH",40.67,-81.86,35584389],
		["Kennebunk","ME",43.38,-70.54,35585807],
		["Scottsboro","AL",34.64,-86.04,35587225],
		["Kalamazoo","MI",42.27,-85.58,35588643],
		["Berrien Springs","MI",41.94,-86.34,35590061],
		["Chugiak","AK",61.41,-149.17,35591479],
		["Litchfield","NH",42.83,-71.46,35592896],
		["Gambrills","MD",39.05,-76.66,35594313],
		["Atlanta","GA",33.76,-84.33,35595730],
		["Hailey","ID",43.57,-114.21,35597147],
		["Guaynabo","PR",18.38,-66.11,35598563],
		["Bridgeport","CT",41.18,-73.19,35599979],
		["Bound Brook","NJ",40.56,-74.53,35601395],
		["Southern Pines","NC",35.18,-79.4,35602811],
		["Huntingburg","IN",38.29,-86.95,35604227],
		["Buchanan","MI",41.82,-86.36,35605643],
		["Annapolis","MD",38.97,-76.5,35607058],
		["Swansboro","NC",34.69,-77.12,35608473],
		["Saluda","SC",34,-81.77,35609888],
		["Hilliard","FL",30.69,-81.92,35611303],
		["Hallsville","TX",32.5,-94.57,35612718],
		["Montrose","CO",38.3,-107.9,35614133],
		["Sumner","WA",47.17,-122.18,35615548],
		["Pittsburgh","PA",40.43,-79.97,35616962],
		["Smithsburg","MD",39.65,-77.57,35618376],
		["Oneida","TN",36.5,-84.51,35619790],
		["Hohenwald","TN",35.55,-87.55,35621204],
		["Jordan","MN",44.66,-93.63,35622618],
		["Kingston","GA",34.23,-84.94,35624031],
		["Stewartstown","PA",39.75,-76.59,35625443],
		["Henrico","VA",37.55,-77.32,35626855],
		["Idabel","OK",33.9,-94.81,35628267],
		["Wahpeton","ND",46.26,-96.6,35629677],
		["Lincoln","NE",40.81,-96.68,35631087],
		["Bernalillo","NM",35.31,-106.55,35632497],
		["Sutton","MA",42.15,-71.76,35633906],
		["Rochester","NY",43.16,-77.61,35635315],
		["New Boston","MI",42.14,-83.36,35636724],
		["Browning","MT",48.59,-113.27,35638133],
		["Wood River","IL",38.86,-90.08,35639542],
		["Manchester","NH",42.99,-71.45,35640950],
		["Sarasota","FL",27.33,-82.54,35642358],
		["Howard City","MI",43.39,-85.46,35643766],
		["Andover","NJ",40.98,-74.74,35645173],
		["Myrtle Beach","SC",33.69,-78.89,35646580],
		["Atlanta","GA",33.79,-84.32,35647986],
		["Dunlap","IL",40.86,-89.67,35649392],
		["Odessa","MO",38.99,-93.95,35650798],
		["Redding","CT",41.3,-73.38,35652203],
		["Avondale","PA",39.82,-75.78,35653608],
		["Hertford","NC",36.18,-76.47,35655013],
		["Atlanta","GA",33.78,-84.34,35656418],
		["Mountain City","TN",36.47,-81.8,35657823],
		["Prestonsburg","KY",37.67,-82.76,35659228],
		["Lansing","KS",39.24,-94.88,35660633],
		["Winston","GA",33.65,-84.86,35662037],
		["Cleveland","OH",41.47,-81.67,35663441],
		["Dearborn","MI",42.31,-83.21,35664845],
		["Stilwell","KS",38.78,-94.68,35666249],
		["Burgaw","NC",34.55,-77.92,35667652],
		["Palmetto","GA",33.52,-84.66,35669055],
		["Crookston","MN",47.77,-96.6,35670458],
		["East Saint Louis","IL",38.61,-90.13,35671861],
		["Shasta Lake","CA",40.68,-122.37,35673264],
		["Elma","WA",47.1,-123.39,35674667],
		["Swannanoa","NC",35.6,-82.39,35676069],
		["Eustis","FL",28.85,-81.68,35677471],
		["Cincinnati","OH",39.09,-84.51,35678873],
		["East Saint Louis","IL",38.61,-90.13,35680275],
		["Sheboygan Falls","WI",43.72,-87.82,35681676],
		["Seward","NE",40.91,-97.09,35683077],
		["Riverside","CT",41.03,-73.58,35684477],
		["Clarence Center","NY",43,-78.63,35685877],
		["Norfolk","VA",36.84,-76.28,35687277],
		["Seymour","MO",37.14,-92.76,35688677],
		["Oklahoma City","OK",35.46,-97.51,35690077],
		["Hurst","TX",32.83,-97.17,35691477],
		["Denver","CO",39.76,-104.87,35692877],
		["Dalhart","TX",36.06,-102.51,35694276],
		["Santa Fe","NM",35.67,-105.95,35695675],
		["Tracy","CA",37.76,-121.63,35697074],
		["Madison","NC",36.38,-79.97,35698472],
		["Vale","NC",35.53,-81.39,35699870],
		["Bolivar","TN",35.26,-88.99,35701268],
		["Big Bear City","CA",34.26,-116.84,35702665],
		["Detroit","MI",42.38,-83.1,35704061],
		["Albuquerque","NM",35.11,-106.62,35705457],
		["Berkeley","CA",37.86,-122.28,35706853],
		["Somers","CT",41.98,-72.45,35708248],
		["Woodbridge","CT",41.34,-72.96,35709643],
		["Clarence","NY",42.98,-78.6,35711038],
		["Dallastown","PA",39.89,-76.64,35712433],
		["Clemson","SC",34.68,-82.81,35713828],
		["Inverness","FL",28.83,-82.34,35715223],
		["Sugarcreek","OH",40.5,-81.64,35716617],
		["Scottsdale","AZ",33.53,-111.89,35718011],
		["Glendale","CA",34.15,-118.26,35719405],
		["Glen Oaks","NY",40.74,-73.71,35720798],
		["West Branch","MI",44.27,-84.23,35722190],
		["Glenwood","IA",41.04,-95.74,35723582],
		["North Liberty","IA",41.74,-91.6,35724974],
		["Spring Hill","KS",38.75,-94.82,35726366],
		["Williamstown","MA",42.7,-73.2,35727757],
		["Brookeville","MD",39.17,-77.05,35729148],
		["Elkton","VA",38.41,-78.61,35730539],
		["Ayden","NC",35.47,-77.42,35731930],
		["Aurora","IN",39.06,-84.9,35733321],
		["Whitefish","MT",48.44,-114.53,35734712],
		["Amarillo","TX",35.2,-101.81,35736103],
		["Cullman","AL",34.17,-86.83,35737493],
		["Huntsville","AR",36.08,-93.73,35738883],
		["Rusk","TX",31.79,-95.14,35740273],
		["Santa Clara","CA",37.34,-121.93,35741663],
		["Hastings On Hudson","NY",40.98,-73.87,35743052],
		["Albertville","MN",45.23,-93.66,35744441],
		["Steger","IL",41.46,-87.63,35745830],
		["Von Ormy","TX",29.25,-98.62,35747219],
		["Camden","TN",36.06,-88.1,35748607],
		["Columbus","OH",39.98,-82.98,35749995],
		["Pittsburgh","PA",40.43,-79.97,35751382],
		["New Wilmington","PA",41.11,-80.33,35752769],
		["Trenton","GA",34.87,-85.5,35754156],
		["University Park","IL",41.44,-87.68,35755542],
		["Omaha","NE",41.26,-96.01,35756928],
		["Atascosa","TX",29.28,-98.73,35758314],
		["Alexandria","VA",38.82,-77.08,35759699],
		["Gray Court","SC",34.6,-82.11,35761084],
		["Doniphan","MO",36.62,-90.82,35762469],
		["Winnsboro","TX",32.95,-95.28,35763854],
		["Marshall","NC",35.79,-82.68,35765238],
		["Clearwater","FL",27.97,-82.76,35766622],
		["Mascoutah","IL",38.5,-89.8,35768006],
		["Houston","TX",29.76,-95.38,35769390],
		["Homer","AK",59.4,-151.32,35770774],
		["San Francisco","CA",37.77,-122.41,35772157],
		["Pennsburg","PA",40.39,-75.49,35773539],
		["Social Circle","GA",33.65,-83.71,35774921],
		["Coal City","IL",41.28,-88.27,35776303],
		["Rhome","TX",33.05,-97.46,35777685],
		["Duncansville","PA",40.42,-78.42,35779066],
		["Williamsport","PA",41.23,-77.03,35780447],
		["Elizabethtown","NC",34.62,-78.61,35781828],
		["Hortonville","WI",44.33,-88.63,35783209],
		["Waco","TX",31.56,-97.18,35784590],
		["Tunnel Hill","GA",34.84,-85.04,35785970],
		["Madison","FL",30.46,-83.41,35787350],
		["Sorrento","FL",28.81,-81.49,35788730],
		["Saint Robert","MO",37.83,-92.12,35790110],
		["Greenbrae","CA",37.94,-122.56,35791490],
		["Hyannis","MA",41.65,-70.29,35792869],
		["Terryville","CT",41.67,-73,35794248],
		["Holiday","FL",28.18,-82.74,35795627],
		["Birch Run","MI",43.25,-83.79,35797006],
		["Brooklyn","MI",42.1,-84.24,35798385],
		["Del Mar","CA",32.96,-117.25,35799763],
		["Freedom","CA",36.93,-121.78,35801141],
		["Bow","NH",43.13,-71.54,35802518],
		["Oak Hill","WV",37.98,-81.14,35803895],
		["Cherokee","NC",35.47,-83.31,35805272],
		["Elkmont","AL",34.92,-86.97,35806649],
		["Gardiner","ME",44.19,-69.78,35808025],
		["Westlake","LA",30.25,-93.25,35809401],
		["Gulf Shores","AL",30.27,-87.7,35810776],
		["Richland Center","WI",43.33,-90.38,35812151],
		["Huron","CA",36.2,-120.09,35813526],
		["Acushnet","MA",41.68,-70.9,35814900],
		["Cape Elizabeth","ME",43.56,-70.2,35816274],
		["Abilene","KS",38.92,-97.22,35817648],
		["Quincy","MA",42.26,-71,35819021],
		["Somersworth","NH",43.25,-70.88,35820394],
		["Worth","IL",41.68,-87.79,35821767],
		["Saint James","MO",38,-91.61,35823140],
		["Delhi","NY",42.27,-74.91,35824512],
		["Liberty","NC",35.85,-79.57,35825884],
		["Mequon","WI",43.23,-87.98,35827256],
		["Minneapolis","MN",44.96,-93.26,35828628],
		["Braddock","PA",40.4,-79.86,35829999],
		["Hendersonville","NC",35.32,-82.46,35831370],
		["Hemingway","SC",33.75,-79.44,35832741],
		["Tuskegee","AL",32.43,-85.71,35834112],
		["Peculiar","MO",38.72,-94.45,35835483],
		["Amarillo","TX",35.2,-101.81,35836854],
		["Houston","TX",29.76,-95.38,35838224],
		["Signal Hill","CA",33.8,-118.16,35839594],
		["Whitinsville","MA",42.11,-71.67,35840963],
		["Bellmawr","NJ",39.86,-75.09,35842332],
		["Miami Beach","FL",25.77,-80.2,35843701],
		["Albany","KY",36.69,-85.13,35845070],
		["Greenville","WI",44.29,-88.53,35846439],
		["Cloverdale","CA",38.78,-122.92,35847808],
		["Somers Point","NJ",39.31,-74.6,35849176],
		["Oyster Bay","NY",40.86,-73.53,35850544],
		["Southampton","NY",40.88,-72.39,35851912],
		["Ridley Park","PA",39.87,-75.32,35853280],
		["Charlotte","NC",35.19,-80.83,35854648],
		["Marysville","MI",42.91,-82.47,35856016],
		["Byron","IL",42.12,-89.25,35857384],
		["Yoakum","TX",29.29,-97.14,35858752],
		["Yuma","AZ",32.68,-114.38,35860120],
		["Dayton","NJ",40.38,-74.51,35861487],
		["Walnut Cove","NC",36.29,-80.13,35862854],
		["Louisville","KY",38.22,-85.74,35864221],
		["Princeton","IL",41.37,-89.46,35865588],
		["Lacombe","LA",30.3,-89.92,35866955],
		["Canton","OH",40.81,-81.37,35868321],
		["Cameron","MO",39.74,-94.23,35869686],
		["Omaha","NE",41.26,-96.01,35871051],
		["Gilmer","TX",32.73,-94.94,35872416],
		["Kingston","WA",47.81,-122.53,35873781],
		["Lumberton","MS",31,-89.45,35875145],
		["Marlow","OK",34.63,-97.96,35876509],
		["Dallas","TX",32.79,-96.76,35877873],
		["Bealeton","VA",38.57,-77.76,35879236],
		["Northfield","VT",44.15,-72.65,35880598],
		["East Rockaway","NY",40.64,-73.66,35881960],
		["Richmond","VA",37.55,-77.46,35883322],
		["Atlanta","GA",33.74,-84.37,35884684],
		["Grimes","IA",41.66,-93.78,35886046],
		["Mulvane","KS",37.48,-97.24,35887408],
		["Torrington","WY",42.06,-104.18,35888770],
		["Rocky Point","NC",34.43,-77.88,35890131],
		["Honea Path","SC",34.44,-82.39,35891492],
		["Polk City","FL",28.18,-81.82,35892853],
		["Hooper","UT",41.15,-112.14,35894214],
		["Narvon","PA",40.12,-75.97,35895574],
		["Benton","IL",38,-88.91,35896934],
		["La Grange","TX",29.9,-96.87,35898294],
		["Canton","CT",41.81,-72.9,35899653],
		["Clinton","IN",39.66,-87.4,35901012],
		["La Center","WA",45.87,-122.61,35902370],
		["Elkview","WV",38.43,-81.47,35903727],
		["Fairfield","IA",41,-91.96,35905084],
		["Bellville","TX",29.94,-96.25,35906441],
		["Port Townsend","WA",48.03,-122.81,35907798],
		["Wareham","MA",41.76,-70.71,35909154],
		["South Dartmouth","MA",41.55,-70.98,35910510],
		["Pikeville","TN",35.6,-85.19,35911866],
		["Auburndale","MA",42.34,-71.24,35913221],
		["Scranton","PA",41.4,-75.66,35914576],
		["Reston","VA",38.95,-77.34,35915931],
		["Orange","VA",38.24,-78.11,35917286],
		["Saint Simons Island","GA",31.21,-81.37,35918641],
		["Eldridge","IA",41.63,-90.57,35919996],
		["Inwood","NY",40.62,-73.75,35921350],
		["Rustburg","VA",37.28,-79.1,35922704],
		["Whitwell","TN",35.2,-85.51,35924058],
		["Southwick","MA",42.05,-72.76,35925410],
		["Warren","OH",41.23,-80.81,35926762],
		["Indian Orchard","MA",42.15,-72.51,35928113],
		["New York","NY",40.76,-73.96,35929464],
		["Nashville","TN",36.17,-86.78,35930815],
		["Ambridge","PA",40.59,-80.22,35932165],
		["Shallotte","NC",33.95,-78.39,35933515],
		["Tyrone","GA",33.46,-84.59,35934865],
		["Tazewell","TN",36.45,-83.57,35936215],
		["Richmond","MI",42.8,-82.75,35937565],
		["Mount Carmel","IL",38.41,-87.77,35938915],
		["Atoka","OK",34.38,-96.13,35940265],
		["Big Stone Gap","VA",36.86,-82.77,35941614],
		["Greenup","KY",38.57,-82.82,35942963],
		["Navarre","OH",40.72,-81.52,35944312],
		["Winnfield","LA",31.92,-92.64,35945661],
		["Purcell","OK",35.01,-97.37,35947010],
		["Wharton","NJ",40.89,-74.58,35948358],
		["Wading River","NY",40.94,-72.81,35949706],
		["Tell City","IN",37.94,-86.75,35951054],
		["Island Lake","IL",42.27,-88.2,35952402],
		["Muncy","PA",41.2,-76.78,35953749],
		["Pahokee","FL",26.81,-80.66,35955096],
		["Madison","AL",34.7,-86.74,35956443],
		["Knoxville","TN",35.97,-83.94,35957790],
		["Iowa","LA",30.23,-93.01,35959137],
		["Newton","MA",42.33,-71.2,35960483],
		["Chadds Ford","PA",39.86,-75.59,35961829],
		["Lyman","SC",34.95,-82.12,35963175],
		["Minneapolis","MN",44.96,-93.26,35964521],
		["Mountain Grove","MO",37.13,-92.26,35965867],
		["Alliance","NE",42.1,-102.87,35967213],
		["Kaplan","LA",30,-92.28,35968559],
		["Lake Park","GA",30.68,-83.18,35969904],
		["Peru","IL",41.34,-89.12,35971249],
		["Cuero","TX",29.09,-97.28,35972594],
		["Ridge","NY",40.91,-72.88,35973938],
		["Fort George G Meade","MD",39.08,-76.76,35975282],
		["Crossville","AL",34.28,-85.99,35976626],
		["San Francisco","CA",37.74,-122.44,35977970],
		["Hamburg","PA",40.55,-75.98,35979313],
		["Hogansville","GA",33.16,-84.9,35980656],
		["Monticello","FL",30.54,-83.87,35981999],
		["Robinson","IL",39,-87.74,35983342],
		["Geismar","LA",30.23,-91.01,35984685],
		["Baton Rouge","LA",30.44,-91.12,35986028],
		["Midland","GA",32.58,-84.84,35987370],
		["Orlando","FL",28.5,-81.37,35988712],
		["Fort Meade","FL",27.75,-81.79,35990054],
		["Heber","CA",32.73,-115.47,35991396],
		["New York","NY",40.71,-73.99,35992737],
		["Great Neck","NY",40.8,-73.73,35994078],
		["Fairdale","KY",38.11,-85.75,35995419],
		["River Rouge","MI",42.27,-83.13,35996760],
		["Hollister","MO",36.61,-93.22,35998101],
		["Arizona City","AZ",32.73,-111.67,35999442],
		["Portland","OR",45.49,-122.72,36000783],
		["Bethel","AK",60.93,-161.8,36002124],
		["Cedar Grove","NJ",40.85,-74.22,36003464],
		["Bedford","PA",40.01,-78.5,36004804],
		["Perry","IA",41.84,-94.09,36006144],
		["Glencoe","MO",38.57,-90.63,36007484],
		["Zuni","NM",35.08,-108.76,36008824],
		["Holbrook","MA",42.14,-71,36010163],
		["Albany","NY",42.66,-73.79,36011502],
		["Elkin","NC",36.25,-80.84,36012841],
		["Union Grove","WI",42.68,-88.05,36014180],
		["Gardnerville","NV",38.75,-119.61,36015519],
		["Poughquag","NY",41.63,-73.66,36016857],
		["Kansas City","KS",39.1,-94.62,36018195],
		["Cut Off","LA",29.52,-90.33,36019533],
		["Charles City","IA",43.06,-92.67,36020870],
		["Lagrangeville","NY",41.67,-73.73,36022206],
		["Florence","AL",34.82,-87.66,36023542],
		["Elizabeth","PA",40.27,-79.88,36024877],
		["Triangle","VA",38.54,-77.31,36026212],
		["Beaufort","SC",32.42,-80.68,36027547],
		["Durand","MI",42.91,-83.98,36028882],
		["Rainbow City","AL",33.92,-86.08,36030216],
		["Upper Sandusky","OH",40.83,-83.28,36031550],
		["West Olive","MI",42.91,-86.12,36032884],
		["Monahans","TX",31.63,-103.06,36034218],
		["Tacoma","WA",47.22,-122.44,36035551],
		["Beverly","NJ",40.06,-74.92,36036883],
		["Waterford","NY",42.79,-73.67,36038215],
		["Hellertown","PA",40.58,-75.33,36039547],
		["Santa Clara","UT",37.13,-113.65,36040879],
		["Taos","NM",36.38,-105.57,36042211],
		["Oakdale","PA",40.39,-80.18,36043542],
		["Minneapolis","MN",44.96,-93.26,36044873],
		["Spring Valley","CA",32.71,-116.94,36046204],
		["Woodbury","NY",40.81,-73.47,36047534],
		["Sayre","PA",41.97,-76.51,36048864],
		["Luray","VA",38.66,-78.45,36050194],
		["Grant","MI",43.33,-85.81,36051524],
		["Tucson","AZ",32.54,-110.95,36052854],
		["Ruckersville","VA",38.23,-78.37,36054183],
		["Ingleside","IL",42.35,-88.15,36055512],
		["Pascagoula","MS",30.36,-88.54,36056840],
		["Diboll","TX",31.18,-94.78,36058168],
		["Tacoma","WA",47.13,-122.37,36059496],
		["Villas","NJ",39.01,-74.93,36060823],
		["West Henrietta","NY",43.03,-77.69,36062150],
		["Edinboro","PA",41.87,-80.12,36063477],
		["Opp","AL",31.29,-86.25,36064804],
		["Douglas","WY",42.75,-105.39,36066131],
		["Jamul","CA",32.69,-116.8,36067458],
		["Littleton","MA",42.53,-71.47,36068784],
		["Fayette","AL",33.69,-87.83,36070110],
		["Mendota","IL",41.55,-89.12,36071436],
		["Clinton","IL",40.15,-88.96,36072762],
		["Pagosa Springs","CO",37.24,-107.03,36074088],
		["Hollis","NH",42.73,-71.58,36075413],
		["Jamesville","NY",42.99,-76.07,36076738],
		["Aldie","VA",38.97,-77.64,36078063],
		["Toledo","OH",41.66,-83.58,36079388],
		["Kansas City","MO",39.09,-94.58,36080713],
		["Milford","NJ",40.56,-75.09,36082037],
		["Littleton","CO",39.59,-105.01,36083361],
		["Lafayette Hill","PA",40.08,-75.25,36084684],
		["Lawrence","KS",38.96,-95.25,36086007],
		["Guadalupe","CA",34.93,-120.59,36087330],
		["Carterville","IL",37.76,-89.08,36088652],
		["Waddell","AZ",33.55,-112.43,36089974],
		["Cambridge","MA",42.37,-71.11,36091295],
		["Pawleys Island","SC",33.38,-79.17,36092616],
		["Boaz","AL",34.2,-86.15,36093937],
		["Proctorville","OH",38.43,-82.38,36095258],
		["West Terre Haute","IN",39.46,-87.44,36096579],
		["Willows","CA",39.5,-122.28,36097900],
		["Fort Myers","FL",26.63,-81.85,36099220],
		["Fort Pierce","FL",27.44,-80.32,36100540],
		["Standish","ME",43.73,-70.55,36101859],
		["Congers","NY",41.14,-73.94,36103178],
		["Whitehall","MI",43.39,-86.34,36104497],
		["Gray","LA",29.67,-90.78,36105816],
		["Palmyra","NY",43.06,-77.23,36107134],
		["Oliver Springs","TN",36.03,-84.32,36108452],
		["Blakely","GA",31.33,-84.94,36109770],
		["Urbandale","IA",41.63,-93.74,36111088],
		["Newalla","OK",35.4,-97.17,36112406],
		["Conroe","TX",30.32,-95.47,36113724],
		["Northfield","NJ",39.37,-74.55,36115041],
		["Thonotosassa","FL",28.07,-82.3,36116358],
		["Saint Anthony","ID",43.98,-111.79,36117675],
		["Mcloud","OK",35.41,-97.09,36118991],
		["Tyler","TX",32.32,-95.3,36120307],
		["Mentone","CA",34.1,-117.04,36121623],
		["Berryville","VA",39.14,-77.98,36122938],
		["Amelia Court House","VA",37.34,-77.96,36124253],
		["Cayce","SC",33.95,-81.06,36125568],
		["Gilbert","SC",33.92,-81.39,36126883],
		["Springville","AL",33.77,-86.47,36128198],
		["Orient","OH",39.8,-83.15,36129513],
		["East Saint Louis","IL",38.61,-90.13,36130828],
		["Houlton","ME",46.11,-67.83,36132142],
		["Uncasville","CT",41.45,-72.12,36133456],
		["Pell City","AL",33.56,-86.27,36134770],
		["Otsego","MI",42.45,-85.69,36136084],
		["Weiser","ID",44.47,-116.91,36137398],
		["Wellington","KS",37.27,-97.39,36138711],
		["New Orleans","LA",30.06,-89.93,36140024],
		["Portland","ME",43.66,-70.25,36141336],
		["Rocky Face","GA",34.75,-85.08,36142648],
		["Knoxville","TN",35.97,-83.94,36143960],
		["Schiller Park","IL",41.95,-87.87,36145272],
		["Wright City","MO",38.82,-91.02,36146584],
		["Piedmont","OK",35.67,-97.75,36147896],
		["Hermosa Beach","CA",33.86,-118.39,36149208],
		["Williston","VT",44.43,-73.07,36150519],
		["Angola","NY",42.63,-79.02,36151830],
		["Morgantown","KY",37.21,-86.7,36153141],
		["Jefferson","OH",41.73,-80.76,36154452],
		["Bath","PA",40.72,-75.39,36155762],
		["Orlando","FL",28.5,-81.37,36157072],
		["Jasper","AL",33.85,-87.27,36158382],
		["Johnson City","TN",36.33,-82.36,36159692],
		["Creston","IA",41.05,-94.36,36161002],
		["Blanchester","OH",39.28,-83.97,36162311],
		["Montrose","MI",43.17,-83.89,36163620],
		["Burton","MI",42.99,-83.61,36164928],
		["Baldwin City","KS",38.77,-95.18,36166236],
		["Mathis","TX",28.09,-97.82,36167544],
		["Buhl","ID",42.6,-114.84,36168852],
		["Windber","PA",40.23,-78.82,36170159],
		["North Palm Beach","FL",26.81,-80.08,36171466],
		["Terry","MS",32.1,-90.29,36172773],
		["Pineville","KY",36.76,-83.7,36174080],
		["Ione","CA",38.33,-120.88,36175387],
		["Hayden","AL",33.89,-86.75,36176693],
		["Hamilton","MI",42.67,-86,36177999],
		["Lyons","IL",41.81,-87.81,36179305],
		["Colfax","CA",39.09,-120.92,36180611],
		["Palmerton","PA",40.8,-75.61,36181915],
		["Carrizo Springs","TX",28.52,-99.85,36183219],
		["Homosassa","FL",28.78,-82.61,36184522],
		["Fennville","MI",42.59,-86.1,36185825],
		["Newport Coast","CA",33.6,-117.82,36187128],
		["Plantsville","CT",41.57,-72.9,36188430],
		["Water Valley","MS",34.16,-89.63,36189732],
		["Loogootee","IN",38.67,-86.91,36191034],
		["Troy","AL",31.8,-85.96,36192335],
		["Mount Orab","OH",39.02,-83.92,36193636],
		["Dansville","NY",42.56,-77.69,36194936],
		["Coeburn","VA",36.94,-82.46,36196236],
		["Cincinnati","OH",39.09,-84.51,36197536],
		["Blackstone","MA",42.01,-71.5,36198835],
		["Woodbury","CT",41.56,-73.2,36200134],
		["Pinehurst","NC",35.18,-79.46,36201433],
		["Hartford City","IN",40.45,-85.37,36202732],
		["Mission","KS",38.9,-94.68,36204031],
		["Lorena","TX",31.38,-97.21,36205330],
		["Las Vegas","NV",36.32,-115.35,36206629],
		["York","ME",43.14,-70.65,36207927],
		["Oakdale","NY",40.73,-73.13,36209225],
		["Bloomfield","IN",39.02,-86.93,36210523],
		["International Falls","MN",48.58,-93.4,36211821],
		["Overland Park","KS",38.9,-94.68,36213119],
		["Topeka","KS",39.03,-95.69,36214417],
		["Weatherford","TX",32.83,-97.7,36215715],
		["Olmito","TX",26.03,-97.55,36217013],
		["Glen Ridge","NJ",40.8,-74.2,36218310],
		["Fredericktown","OH",40.47,-82.54,36219607],
		["Detroit","MI",42.38,-83.1,36220904],
		["Iowa Park","TX",33.95,-98.68,36222201],
		["Denver","CO",39.76,-104.87,36223498],
		["Vashon","WA",47.42,-122.45,36224795],
		["Steubenville","OH",40.36,-80.64,36226091],
		["Jefferson","WI",43,-88.8,36227387],
		["Monongahela","PA",40.19,-79.92,36228682],
		["Marianna","FL",30.77,-85.23,36229977],
		["Berne","IN",40.65,-84.95,36231271],
		["Laie","HI",21.64,-157.92,36232565],
		["Creswell","OR",43.9,-123.02,36233859],
		["Montpelier","VT",44.26,-72.57,36235152],
		["Gouverneur","NY",44.33,-75.46,36236445],
		["Fairview","PA",42.03,-80.25,36237738],
		["Sarasota","FL",27.33,-82.54,36239031],
		["Grambling","LA",32.52,-92.71,36240324],
		["Watertown","NY",43.97,-75.91,36241616],
		["California","PA",40.06,-79.91,36242908],
		["Kenly","NC",35.59,-78.12,36244200],
		["Philomath","OR",44.59,-123.46,36245492],
		["Prospect","CT",41.49,-72.97,36246783],
		["West Columbia","SC",33.99,-81.08,36248074],
		["Liberty","KY",37.31,-84.93,36249365],
		["La Salle","IL",41.34,-89.09,36250656],
		["Pasadena","CA",34.14,-118.13,36251947],
		["Stanhope","NJ",40.91,-74.7,36253237],
		["Germantown","OH",39.63,-84.36,36254527],
		["Rochester","NY",43.16,-77.61,36255816],
		["Millington","MI",43.28,-83.52,36257105],
		["Grass Lake","MI",42.25,-84.2,36258394],
		["Willcox","AZ",32.43,-109.88,36259683],
		["Kansas City","MO",39.09,-94.58,36260971],
		["Pitman","NJ",39.73,-75.13,36262258],
		["Nokesville","VA",38.69,-77.58,36263545],
		["Salem","WI",42.6,-88.12,36264832],
		["Fayetteville","PA",39.91,-77.56,36266118],
		["Clayton","DE",39.29,-75.63,36267404],
		["Chadron","NE",42.82,-103,36268690],
		["Santa Barbara","CA",34.4,-119.72,36269976],
		["Woodstown","NJ",39.65,-75.32,36271261],
		["Davidsonville","MD",38.93,-76.63,36272546],
		["Livingston","MT",45.7,-110.53,36273831],
		["Saint Rose","LA",29.95,-90.31,36275116],
		["Farmerville","LA",32.77,-92.4,36276401],
		["Crestline","CA",34.25,-117.3,36277686],
		["Rutland","MA",42.36,-71.95,36278970],
		["Dalzell","SC",34.03,-80.45,36280254],
		["Kershaw","SC",34.54,-80.58,36281538],
		["Wayne","NE",42.23,-97.01,36282822],
		["Oakland","CA",37.84,-122.23,36284106],
		["Douglas","MA",42.05,-71.73,36285389],
		["Rochester","NY",43.16,-77.61,36286672],
		["Highland","MI",42.63,-83.61,36287955],
		["Milaca","MN",45.75,-93.65,36289238],
		["Holts Summit","MO",38.64,-92.11,36290521],
		["Alma","GA",31.54,-82.47,36291803],
		["Lake Wales","FL",27.9,-81.58,36293085],
		["Fairview","OR",45.54,-122.44,36294367],
		["Manchester Township","NJ",39.93,-74.39,36295648],
		["Maynardville","TN",36.24,-83.8,36296929],
		["Mc Cook","NE",40.2,-100.62,36298210],
		["Rodeo","CA",38.03,-122.23,36299491],
		["Potosi","MO",37.93,-90.78,36300771],
		["Waldwick","NJ",41.01,-74.12,36302050],
		["Estero","FL",26.44,-81.81,36303329],
		["Gypsum","CO",39.64,-106.94,36304608],
		["Belfair","WA",47.43,-122.92,36305887],
		["Easton","CT",41.24,-73.31,36307165],
		["Wheatfield","IN",41.19,-87.05,36308443],
		["Coopersville","MI",43.06,-85.93,36309721],
		["Yreka","CA",41.75,-122.67,36310999],
		["Croydon","PA",40.09,-74.89,36312276],
		["Havana","FL",30.62,-84.41,36313553],
		["Indiantown","FL",27.03,-80.46,36314830],
		["Lenoir City","TN",35.8,-84.27,36316107],
		["Litchfield","MN",45.11,-94.52,36317384],
		["Cold Spring","MN",45.45,-94.43,36318661],
		["Asheville","NC",35.57,-82.54,36319937],
		["Selmer","TN",35.17,-88.59,36321213],
		["Osceola","AR",35.7,-89.99,36322489],
		["Myrtle Creek","OR",43.05,-123.2,36323765],
		["Denton","MD",38.88,-75.82,36325040],
		["Shalimar","FL",30.44,-86.57,36326315],
		["Kansas City","MO",39.09,-94.58,36327590],
		["Vieques","PR",18.42,-65.83,36328864],
		["Painted Post","NY",42.16,-77.09,36330138],
		["Pensacola","FL",30.44,-87.18,36331412],
		["Tipton","IN",40.28,-86.04,36332686],
		["East Troy","WI",42.78,-88.39,36333960],
		["Devils Lake","ND",48.11,-98.87,36335234],
		["Whitesboro","TX",33.65,-96.9,36336508],
		["Smithville","TX",30,-97.15,36337781],
		["Medical Lake","WA",47.61,-117.7,36339054],
		["Merion Station","PA",40,-75.24,36340326],
		["Williamsport","MD",39.59,-77.81,36341598],
		["Monticello","GA",33.3,-83.68,36342870],
		["Bristol","IN",41.72,-85.81,36344142],
		["Gladstone","MI",45.85,-87.02,36345414],
		["Mariposa","CA",37.49,-120,36346686],
		["Pittsburgh","PA",40.43,-79.97,36347957],
		["Louisville","TN",35.82,-84.04,36349228],
		["Livingston","TN",36.38,-85.32,36350499],
		["Laingsburg","MI",42.89,-84.34,36351770],
		["Belle Plaine","MN",44.62,-93.76,36353041],
		["Trumann","AR",35.67,-90.51,36354312],
		["Seneca Falls","NY",42.91,-76.79,36355582],
		["Panama City Beach","FL",30.17,-85.8,36356852],
		["Montgomery","AL",32.35,-86.28,36358122],
		["Columbiana","OH",40.88,-80.68,36359392],
		["Williamsburg","OH",39.05,-84.05,36360662],
		["Adkins","TX",29.37,-98.25,36361932],
		["College Place","WA",46.04,-118.4,36363202],
		["Caribou","ME",46.86,-67.99,36364471],
		["Jemison","AL",32.96,-86.74,36365740],
		["Wetumpka","AL",32.54,-86.2,36367009],
		["Clare","MI",43.82,-84.76,36368278],
		["Sparrows Point","MD",39.22,-76.43,36369546],
		["Boonsboro","MD",39.5,-77.65,36370814],
		["Terre Haute","IN",39.47,-87.38,36372082],
		["Supply","NC",34.01,-78.26,36373349],
		["Eastchester","NY",40.95,-73.8,36374615],
		["Totowa","NJ",40.9,-74.22,36375880],
		["Buena Vista","VA",37.73,-79.35,36377145],
		["Boca Raton","FL",26.37,-80.1,36378410],
		["Old Saybrook","CT",41.29,-72.36,36379674],
		["Chelsea","AL",33.32,-86.63,36380938],
		["Bellville","OH",40.62,-82.51,36382202],
		["Ferriday","LA",31.63,-91.55,36383466],
		["Effingham","SC",34.05,-79.75,36384729],
		["Perry","FL",30.1,-83.58,36385992],
		["Checotah","OK",35.47,-95.52,36387255],
		["Phoenix","AZ",33.3,-112.1,36388518],
		["Seattle","WA",47.44,-122.31,36389781],
		["Garnerville","NY",41.2,-74,36391043],
		["Lake Orion","MI",42.78,-83.24,36392305],
		["Iowa City","IA",41.65,-91.53,36393567],
		["Salt Lake City","UT",40.75,-111.86,36394829],
		["Charleston","WV",38.35,-81.63,36396090],
		["Bladenboro","NC",34.53,-78.79,36397351],
		["Grand Forks","ND",47.91,-97.07,36398612],
		["Polson","MT",47.72,-114.21,36399873],
		["Houston","TX",29.76,-95.38,36401134],
		["Anchorage","AK",61.15,-149.88,36402395],
		["Tamaqua","PA",40.8,-75.93,36403655],
		["Trenton","TN",35.97,-88.93,36404915],
		["Cedar Rapids","IA",41.97,-91.66,36406175],
		["Bayport","NY",40.74,-73.05,36407434],
		["Buffalo","NY",42.88,-78.85,36408693],
		["Chattanooga","TN",35.06,-85.25,36409952],
		["Thatcher","AZ",32.82,-109.78,36411211],
		["Garrett","IN",41.34,-85.12,36412469],
		["Skillman","NJ",40.41,-74.7,36413726],
		["Bridgeton","MO",38.76,-90.42,36414983],
		["Bacliff","TX",29.5,-94.99,36416240],
		["Albany","NY",42.66,-73.79,36417496],
		["Oak Ridge","NC",36.17,-79.98,36418752],
		["Farmville","NC",35.59,-77.59,36420008],
		["Strawberry Plains","TN",36.06,-83.68,36421264],
		["Goodrich","MI",42.91,-83.5,36422520],
		["Ulysses","KS",37.57,-101.35,36423776],
		["Sweeny","TX",29.04,-95.69,36425032],
		["Soquel","CA",37.02,-121.94,36426288],
		["Batesburg","SC",33.9,-81.54,36427543],
		["Walkerton","IN",41.46,-86.48,36428798],
		["Lansing","MI",42.7,-84.55,36430053],
		["Acton","CA",34.49,-118.22,36431308],
		["Annandale","NJ",40.64,-74.89,36432562],
		["Demopolis","AL",32.5,-87.83,36433816],
		["Garrettsville","OH",41.28,-81.09,36435070],
		["Alto","MI",42.85,-85.38,36436324],
		["Drums","PA",41.01,-75.99,36437577],
		["Quitman","GA",30.78,-83.56,36438830],
		["Hamilton","AL",34.14,-87.98,36440083],
		["Wilmore","KY",37.86,-84.65,36441336],
		["Itasca","IL",41.97,-88.01,36442589],
		["Omak","WA",48.36,-119.27,36443842],
		["Edwards","CO",39.65,-106.6,36445094],
		["Middleton","MA",42.6,-71.01,36446345],
		["Portland","CT",41.58,-72.62,36447596],
		["Clyde","NC",35.53,-82.91,36448847],
		["Chittenango","NY",43.04,-75.87,36450097],
		["Narberth","PA",40,-75.26,36451347],
		["Daleville","AL",31.3,-85.71,36452597],
		["Greenfield","OH",39.35,-83.38,36453847],
		["Booneville","AR",35.13,-93.91,36455097],
		["Eaton","CO",40.52,-104.71,36456347],
		["Fort Defiance","AZ",35.77,-109.11,36457597],
		["Skowhegan","ME",44.77,-69.71,36458846],
		["Natrona Heights","PA",40.64,-79.73,36460095],
		["Orange City","IA",43,-96.05,36461344],
		["Washington","IA",41.29,-91.69,36462593],
		["Wisconsin Dells","WI",43.62,-89.77,36463842],
		["Prairie Grove","AR",35.97,-94.31,36465091],
		["Burlington","VT",44.48,-73.27,36466339],
		["Old Lyme","CT",41.31,-72.34,36467587],
		["Bala Cynwyd","PA",40.01,-75.22,36468835],
		["Evansville","WI",42.77,-89.29,36470083],
		["Black River Falls","WI",44.29,-90.84,36471331],
		["La Pine","OR",43.69,-121.14,36472579],
		["Jacksonville","FL",30.33,-81.65,36473826],
		["Stacy","MN",45.39,-92.98,36475073],
		["Export","PA",40.41,-79.62,36476319],
		["Coloma","MI",42.18,-86.3,36477565],
		["Patterson","LA",29.69,-91.3,36478811],
		["Oklahoma City","OK",35.46,-97.51,36480056],
		["Phoenix","AZ",33.45,-112.07,36481301],
		["Bath","ME",43.93,-69.83,36482545],
		["Genoa","IL",42.09,-88.68,36483789],
		["Little Rock","AR",34.72,-92.35,36485033],
		["Lakeside","AZ",34.1,-110.08,36486277],
		["Portland","OR",45.51,-122.59,36487521],
		["Wallace","NC",34.73,-77.99,36488764],
		["Birmingham","AL",33.52,-86.79,36490007],
		["Grayling","MI",44.65,-84.7,36491250],
		["Honolulu","HI",21.3,-157.85,36492493],
		["Irvington","NY",41.03,-73.86,36493735],
		["Putnam Valley","NY",41.34,-73.87,36494977],
		["Carrboro","NC",35.91,-79.08,36496219],
		["Vinemont","AL",34.26,-86.94,36497461],
		["Smiths Creek","MI",42.91,-82.6,36498703],
		["Ware","MA",42.25,-72.24,36499944],
		["Henrietta","NY",43.04,-77.61,36501185],
		["Folkston","GA",30.83,-82,36502426],
		["Peebles","OH",38.94,-83.4,36503667],
		["Mora","MN",45.87,-93.29,36504908],
		["Ava","MO",36.95,-92.66,36506149],
		["Jessup","MD",39.14,-76.77,36507389],
		["Lynchburg","VA",37.4,-79.19,36508629],
		["Minden","NV",39.01,-119.75,36509869],
		["Slingerlands","NY",42.64,-73.88,36511108],
		["Pawling","NY",41.56,-73.59,36512347],
		["Syracuse","NY",43.04,-76.14,36513586],
		["Akron","NY",43.01,-78.49,36514825],
		["Pauls Valley","OK",34.72,-97.22,36516064],
		["Parker","AZ",33.99,-114.23,36517303],
		["Castle Rock","WA",46.29,-122.95,36518542],
		["Monson","MA",42.09,-72.31,36519780],
		["Great Barrington","MA",42.19,-73.35,36521018],
		["Leechburg","PA",40.63,-79.6,36522256],
		["Slaton","TX",33.43,-101.64,36523494],
		["Grand Junction","CO",39.08,-108.55,36524732],
		["Pelion","SC",33.78,-81.24,36525969],
		["York","NE",40.87,-97.59,36527206],
		["Buna","TX",30.44,-93.96,36528443],
		["Raymond","NH",43.03,-71.17,36529679],
		["Elma","NY",42.83,-78.63,36530915],
		["Miami Beach","FL",25.77,-80.2,36532151],
		["Stevens Point","WI",44.57,-89.54,36533387],
		["Du Quoin","IL",38,-89.23,36534623],
		["Santa Rosa","TX",26.25,-97.82,36535859],
		["Fort White","FL",29.92,-82.71,36537094],
		["Fort Myers","FL",26.63,-81.85,36538329],
		["Little Falls","NY",43.04,-74.85,36539563],
		["Johnstown","PA",40.32,-78.91,36540797],
		["Orlando","FL",28.5,-81.37,36542031],
		["Dale","TX",29.87,-97.53,36543265],
		["Reading","PA",40.33,-75.92,36544498],
		["Grafton","WV",39.34,-80.01,36545731],
		["Mc Leansville","NC",36.1,-79.66,36546964],
		["Greenwood","MO",38.85,-94.34,36548197],
		["Upton","MA",42.17,-71.61,36549429],
		["Saint Johnsbury","VT",44.41,-71.97,36550661],
		["Stewartsville","NJ",40.69,-75.1,36551893],
		["Central Square","NY",43.28,-76.14,36553125],
		["Hampton","VA",37.04,-76.29,36554357],
		["Chattanooga","TN",35.06,-85.25,36555589],
		["Fort Wayne","IN",41.07,-85.13,36556821],
		["Ames","IA",42.02,-93.62,36558053],
		["Madison","SD",44,-97.1,36559284],
		["Cassville","MO",36.67,-93.86,36560515],
		["Scottsdale","AZ",33.48,-111.92,36561746],
		["Leonia","NJ",40.86,-73.99,36562976],
		["Catskill","NY",42.21,-73.86,36564206],
		["Rowland","NC",34.53,-79.29,36565436],
		["Reston","VA",38.95,-77.34,36566665],
		["Arlington","VA",38.87,-77.1,36567894],
		["Lodi","WI",43.31,-89.53,36569123],
		["Denton","NC",35.63,-80.11,36570351],
		["Mary Esther","FL",30.41,-86.66,36571579],
		["Hawthorne","FL",29.59,-82.08,36572807],
		["Houston","MS",33.89,-89,36574034],
		["Snowflake","AZ",34.64,-110.11,36575261],
		["Presque Isle","ME",46.68,-67.98,36576487],
		["Windsor","NC",36,-76.94,36577713],
		["Catlettsburg","KY",38.41,-82.6,36578939],
		["Canton","OH",40.81,-81.37,36580165],
		["Solana Beach","CA",32.99,-117.25,36581391],
		["Tacoma","WA",47.23,-122.36,36582617],
		["South Easton","MA",42.03,-71.1,36583842],
		["Kill Devil Hills","NC",36.01,-75.66,36585067],
		["Nokomis","FL",27.12,-82.43,36586292],
		["Monee","IL",41.42,-87.75,36587517],
		["Leawood","KS",38.9,-94.68,36588742],
		["Putnam","CT",41.91,-71.9,36589966],
		["Fort Edward","NY",43.26,-73.58,36591190],
		["Morrisville","NY",42.89,-75.64,36592414],
		["Scottsboro","AL",34.64,-86.04,36593638],
		["Columbus","OH",39.98,-82.98,36594862],
		["Indianapolis","IN",39.77,-86.14,36596086],
		["Greenville","IL",38.88,-89.4,36597310],
		["Ripley","WV",38.82,-81.7,36598533],
		["Syracuse","IN",41.42,-85.74,36599756],
		["Perry","MI",42.82,-84.22,36600979],
		["Fairview","NC",35.52,-82.4,36602201],
		["Panama City","FL",30.17,-85.67,36603423],
		["West Union","OH",38.79,-83.54,36604645],
		["Little Chute","WI",44.28,-88.31,36605867],
		["Many","LA",31.56,-93.47,36607089],
		["Baltimore","MD",39.3,-76.61,36608310],
		["Georgetown","OH",38.86,-83.9,36609531],
		["Beaverton","MI",43.88,-84.48,36610752],
		["Alexandria","VA",38.82,-77.08,36611972],
		["Birmingham","AL",33.52,-86.79,36613192],
		["Greensboro","AL",32.7,-87.59,36614412],
		["Huntington","TX",31.27,-94.57,36615632],
		["Wildwood","NJ",38.98,-74.82,36616851],
		["Mohegan Lake","NY",41.31,-73.84,36618070],
		["Brownsville","PA",40.01,-79.89,36619289],
		["Jasper","AL",33.85,-87.27,36620508],
		["Tijeras","NM",35.08,-106.37,36621727],
		["Kansas City","MO",39.09,-94.58,36622945],
		["Star City","AR",33.94,-91.84,36624163],
		["Pittsburgh","PA",40.43,-79.97,36625380],
		["Falls Church","VA",38.88,-77.17,36626597],
		["West Liberty","KY",37.91,-83.26,36627814],
		["Madison","WI",43.07,-89.38,36629031],
		["Mountain View","HI",19.52,-155.14,36630248],
		["Somerdale","NJ",39.84,-75.02,36631464],
		["Wellsboro","PA",41.74,-77.3,36632680],
		["Enfield","NC",36.17,-77.66,36633896],
		["Mount Vernon","KY",37.36,-84.34,36635112],
		["Delphi","IN",40.58,-86.67,36636328],
		["East Berlin","PA",39.93,-76.97,36637543],
		["Bluefield","VA",37.23,-81.26,36638758],
		["West End","NC",35.24,-79.53,36639973],
		["Blue Ridge","GA",34.86,-84.32,36641188],
		["Coushatta","LA",32.02,-93.34,36642403],
		["Bella Vista","AR",36.46,-94.29,36643618],
		["Yacolt","WA",45.83,-122.38,36644833],
		["Bogota","NJ",40.87,-74.02,36646047],
		["Campobello","SC",35.11,-82.14,36647261],
		["Detroit","MI",42.38,-83.1,36648475],
		["Slinger","WI",43.33,-88.28,36649689],
		["Minneapolis","MN",44.96,-93.26,36650903],
		["Westville","NJ",39.86,-75.13,36652116],
		["Woodstock","MD",39.32,-76.87,36653329],
		["Belcourt","ND",48.84,-99.74,36654542],
		["Vandalia","IL",38.98,-89.1,36655755],
		["Moab","UT",38.45,-109.6,36656968],
		["Eden","NY",42.65,-78.9,36658180],
		["Pelham","GA",31.12,-84.15,36659392],
		["Pawcatuck","CT",41.37,-71.85,36660603],
		["Bernardsville","NJ",40.73,-74.59,36661814],
		["Ward","AR",35.01,-91.95,36663025],
		["Tyler","TX",32.32,-95.3,36664236],
		["Niantic","CT",41.32,-72.19,36665446],
		["Centre","AL",34.15,-85.67,36666656],
		["Kiln","MS",30.41,-89.43,36667866],
		["West Salem","OH",40.96,-82.1,36669076],
		["Omaha","NE",41.26,-96.01,36670286],
		["Zillah","WA",46.41,-120.23,36671496],
		["Georgetown","MA",42.73,-70.98,36672705],
		["North Scituate","RI",41.83,-71.63,36673914],
		["Stevens","PA",40.23,-76.18,36675123],
		["Shamokin","PA",40.78,-76.55,36676332],
		["Milford","MI",42.58,-83.6,36677541],
		["Newport","AR",35.62,-91.23,36678750],
		["Venus","TX",32.42,-97.1,36679959],
		["Gardena","CA",33.88,-118.27,36681168],
		["Blandon","PA",40.44,-75.87,36682376],
		["Baltimore","OH",39.84,-82.6,36683584],
		["Sullivan City","TX",26.28,-98.56,36684792],
		["Glen Saint Mary","FL",30.27,-82.16,36685999],
		["Midway City","CA",33.74,-117.98,36687206],
		["Oradell","NJ",40.95,-74.03,36688412],
		["Fruitland Park","FL",28.85,-81.9,36689618],
		["Cookeville","TN",36.15,-85.5,36690824],
		["Perry","OH",41.76,-81.14,36692030],
		["Uhrichsville","OH",40.4,-81.35,36693236],
		["Ashdown","AR",33.67,-94.12,36694442],
		["Kountze","TX",30.37,-94.31,36695648],
		["Fowler","CA",36.62,-119.67,36696854],
		["Lowville","NY",43.78,-75.48,36698059],
		["Murfreesboro","NC",36.44,-77.09,36699264],
		["Shepherd","TX",30.49,-95,36700469],
		["Clear Lake","IA",43.13,-93.37,36701673],
		["Cameron","TX",30.85,-96.97,36702877],
		["Lake Arrowhead","CA",34.27,-117.19,36704081],
		["Thornville","OH",39.89,-82.41,36705284],
		["Hewlett","NY",40.64,-73.69,36706486],
		["Royston","GA",34.28,-83.1,36707688],
		["Waco","TX",31.56,-97.18,36708890],
		["Port Charlotte","FL",26.98,-82.1,36710091],
		["Kodak","TN",35.97,-83.62,36711292],
		["Churubusco","IN",41.23,-85.32,36712493],
		["Shawnee","KS",38.9,-94.68,36713694],
		["San Diego","CA",32.74,-117.17,36714895],
		["New Egypt","NJ",40.06,-74.53,36716095],
		["Lewiston","NY",43.17,-79.04,36717295],
		["Wildwood","FL",28.85,-82.04,36718495],
		["Thomasville","AL",31.91,-87.74,36719695],
		["Webster City","IA",42.46,-93.81,36720895],
		["Sells","AZ",32,-111.93,36722095],
		["Tuckahoe","NY",40.95,-73.82,36723294],
		["Albrightsville","PA",40.96,-75.61,36724493],
		["Ferris","TX",32.53,-96.66,36725692],
		["Hereford","AZ",31.44,-110.23,36726891],
		["Ledyard","CT",41.44,-71.99,36728089],
		["Harlem","GA",33.41,-82.31,36729287],
		["Columbia Station","OH",41.31,-81.94,36730485],
		["Girard","PA",42,-80.31,36731682],
		["Valdese","NC",35.74,-81.55,36732879],
		["Columbus","OH",39.98,-82.98,36734076],
		["Seymour","WI",44.51,-88.32,36735273],
		["East Saint Louis","IL",38.61,-90.13,36736470],
		["Oklahoma City","OK",35.46,-97.51,36737667],
		["Breckenridge","TX",32.75,-98.91,36738864],
		["Hampden","ME",44.74,-68.83,36740060],
		["Union City","PA",41.89,-79.84,36741256],
		["Danielsville","GA",34.12,-83.22,36742452],
		["Centerton","AR",36.36,-94.28,36743648],
		["Camden","NJ",39.93,-75.1,36744843],
		["Churchville","NY",43.1,-77.88,36746038],
		["Salem","AL",32.61,-85.17,36747233],
		["Portland","OR",45.51,-122.64,36748428],
		["Monroe","OH",39.44,-84.34,36749622],
		["Berlin","WI",43.97,-88.95,36750816],
		["New Boston","TX",33.46,-94.41,36752010],
		["Montrose","PA",41.83,-75.87,36753203],
		["Appomattox","VA",37.35,-78.82,36754396],
		["Ormond Beach","FL",29.28,-81.06,36755589],
		["Naples","FL",26.14,-81.79,36756782],
		["Highland","MI",42.63,-83.61,36757975],
		["Montrose","CA",34.21,-118.23,36759168],
		["Kirtland","NM",36.73,-108.34,36760360],
		["Haiku","HI",20.91,-156.32,36761552],
		["South Hamilton","MA",42.62,-70.86,36762742],
		["Independence","IA",42.46,-91.89,36763932],
		["Madisonville","TX",30.95,-95.9,36765122],
		["Bladensburg","MD",38.94,-76.92,36766311],
		["Canton","OH",40.81,-81.37,36767500],
		["Cowpens","SC",35.01,-81.8,36768688],
		["Bushnell","FL",28.66,-82.11,36769876],
		["Cohasset","MA",42.23,-70.8,36771063],
		["Van Alstyne","TX",33.41,-96.57,36772250],
		["Old Greenwich","CT",41.03,-73.56,36773436],
		["Savannah","MO",39.93,-94.82,36774622],
		["Wenham","MA",42.6,-70.88,36775807],
		["Woodbine","NJ",39.22,-74.8,36776992],
		["Vero Beach","FL",27.64,-80.39,36778177],
		["Lexington","OK",35.01,-97.33,36779362],
		["Bridge City","TX",30.02,-93.85,36780547],
		["Durham","CT",41.47,-72.68,36781731],
		["Shavertown","PA",41.31,-75.95,36782915],
		["Evergreen","AL",31.43,-86.95,36784099],
		["Lexington","MS",33.11,-90.04,36785283],
		["New Lexington","OH",39.71,-82.2,36786467],
		["Madill","OK",34.09,-96.77,36787651],
		["Bridgeville","DE",38.74,-75.6,36788834],
		["Rittman","OH",40.97,-81.78,36790017],
		["Waldron","AR",34.89,-94.09,36791200],
		["Rawlins","WY",41.78,-107.22,36792383],
		["Sheridan","OR",45.1,-123.42,36793566],
		["Lake Hopatcong","NJ",40.96,-74.61,36794748],
		["Wenonah","NJ",39.79,-75.14,36795930],
		["Wellsville","NY",42.12,-77.94,36797112],
		["Tabor City","NC",34.14,-78.87,36798294],
		["Blountsville","AL",34.08,-86.58,36799476],
		["Heflin","AL",33.64,-85.58,36800658],
		["Catasauqua","PA",40.65,-75.46,36801838],
		["Philadelphia","PA",39.95,-75.16,36803018],
		["Stoneville","NC",36.46,-79.9,36804198],
		["Chandler","TX",32.3,-95.47,36805378],
		["Pahrump","NV",36.29,-116.09,36806558],
		["Pixley","CA",35.95,-119.31,36807738],
		["Montvale","NJ",41.05,-74.04,36808917],
		["Midway","GA",31.8,-81.42,36810096],
		["Springfield","KY",37.68,-85.22,36811275],
		["Lamar","MO",37.49,-94.27,36812454],
		["Bowie","TX",33.55,-97.84,36813633],
		["Whitney","TX",31.94,-97.32,36814812],
		["Binghamton","NY",42.1,-75.91,36815990],
		["Hamlin","NY",43.32,-77.93,36817168],
		["Middlesex","NC",35.78,-78.2,36818346],
		["Fort Wayne","IN",41.07,-85.13,36819524],
		["Sutherlin","OR",43.41,-123.11,36820702],
		["Shepherdstown","WV",39.43,-77.8,36821879],
		["Beaufort","NC",34.72,-76.65,36823056],
		["Saint Matthews","SC",33.66,-80.77,36824233],
		["Millen","GA",32.8,-81.94,36825410],
		["Northwood","OH",41.61,-83.48,36826587],
		["Dixon","MO",37.99,-92.09,36827764],
		["Providence","UT",41.69,-111.81,36828941],
		["Florham Park","NJ",40.77,-74.39,36830117],
		["Skaneateles","NY",42.94,-76.42,36831293],
		["Columbiana","AL",33.17,-86.6,36832469],
		["Russellville","AL",34.5,-87.72,36833645],
		["Tuskegee Institute","AL",32.42,-85.71,36834821],
		["Troutville","VA",37.41,-79.87,36835996],
		["Graniteville","SC",33.56,-81.81,36837171],
		["Sarasota","FL",27.33,-82.54,36838346],
		["Dayton","OH",39.77,-84.19,36839521],
		["Star","ID",43.72,-116.49,36840696],
		["Swansea","SC",33.73,-81.1,36841870],
		["Fellsmere","FL",27.76,-80.59,36843044],
		["Grand Rapids","MI",42.96,-85.65,36844218],
		["Iola","KS",37.92,-95.4,36845392],
		["Tickfaw","LA",30.57,-90.48,36846566],
		["Oakdale","LA",30.81,-92.65,36847740],
		["Tonopah","AZ",33.57,-113.02,36848914],
		["Sebring","FL",27.41,-81.48,36850087],
		["Schaumburg","IL",42.03,-88.08,36851260],
		["Beardstown","IL",40,-90.41,36852433],
		["Centralia","MO",39.21,-92.13,36853606],
		["Corte Madera","CA",37.92,-122.51,36854779],
		["Linthicum Heights","MD",39.2,-76.66,36855951],
		["Doylestown","OH",40.97,-81.69,36857123],
		["Maquoketa","IA",42.06,-90.66,36858295],
		["Galveston","TX",29.3,-94.79,36859467],
		["Dupont","WA",47.09,-122.64,36860639],
		["Spokane","WA",47.66,-117.43,36861811],
		["Middleburg","PA",40.78,-77.04,36862982],
		["Saint Helena Island","SC",32.38,-80.56,36864153],
		["Wellston","OH",39.11,-82.53,36865324],
		["Litchfield","IL",39.17,-89.65,36866495],
		["Jay","OK",36.42,-94.79,36867666],
		["Needham Heights","MA",42.28,-71.24,36868836],
		["Dublin","VA",37.1,-80.68,36870006],
		["Andalusia","AL",31.37,-86.47,36871176],
		["Buffalo","MO",37.64,-93.09,36872346],
		["Gentry","AR",36.26,-94.48,36873516],
		["Glen Rose","TX",32.23,-97.75,36874686],
		["Clyde","TX",32.4,-99.49,36875856],
		["Arvada","CO",39.82,-105.1,36877026],
		["Apalachin","NY",42.07,-76.16,36878195],
		["Dawson","GA",31.76,-84.44,36879364],
		["Pine City","MN",45.83,-92.96,36880533],
		["Morris","MN",45.58,-95.9,36881702],
		["Park Rapids","MN",46.91,-95.06,36882871],
		["East Alton","IL",38.88,-90.1,36884040],
		["Riverdale","CA",36.48,-119.94,36885209],
		["Miami","FL",25.77,-80.2,36886377],
		["Hernando","FL",28.9,-82.37,36887545],
		["Oak Harbor","OH",41.51,-83.14,36888713],
		["Euclid","OH",41.59,-81.51,36889881],
		["Oak Grove","LA",32.86,-91.39,36891049],
		["Florence","OR",44.12,-124,36892217],
		["Winchester","IN",40.17,-84.97,36893384],
		["Topeka","KS",39.03,-95.69,36894551],
		["Yerington","NV",38.87,-119.14,36895718],
		["Kingsley","MI",44.58,-85.53,36896884],
		["Saint Francis","MN",45.39,-93.38,36898050],
		["University Of Richmond","VA",37.57,-77.53,36899215],
		["Lula","GA",34.39,-83.66,36900380],
		["Statham","GA",33.96,-83.59,36901545],
		["Aurora","CO",39.69,-104.81,36902710],
		["South Berwick","ME",43.23,-70.81,36903874],
		["Clayton","NJ",39.65,-75.08,36905038],
		["Woodbury","TN",35.82,-86.07,36906202],
		["Campbell","OH",41.07,-80.59,36907366],
		["Upland","IN",40.46,-85.51,36908530],
		["Centerville","MA",41.66,-70.34,36909693],
		["Washington","DC",38.89,-77.03,36910856],
		["Nebraska City","NE",40.67,-95.86,36912019],
		["Berkeley","CA",37.9,-122.28,36913182],
		["Princeton","NC",35.46,-78.16,36914344],
		["Rock Hill","SC",34.93,-81.02,36915506],
		["Centerburg","OH",40.3,-82.69,36916668],
		["Milan","IL",41.44,-90.56,36917830],
		["Chapmanville","WV",37.97,-82.01,36918991],
		["Mars Hill","NC",35.82,-82.55,36920152],
		["Brooksville","FL",28.48,-82.42,36921313],
		["Hodgenville","KY",37.56,-85.73,36922474],
		["Marine City","MI",42.71,-82.49,36923635],
		["Wellington","CO",40.7,-105,36924796],
		["Frankfort","NY",43.03,-75.07,36925956],
		["Hilmar","CA",37.39,-120.89,36927116],
		["Whippany","NJ",40.82,-74.41,36928275],
		["Cincinnati","OH",39.09,-84.51,36929434],
		["Houston","TX",29.76,-95.38,36930593],
		["Hitchcock","TX",29.31,-95.01,36931752],
		["Trinity","AL",34.59,-87.08,36932910],
		["Clawson","MI",42.53,-83.15,36934068],
		["River Grove","IL",41.92,-87.83,36935226],
		["Charleroi","PA",40.13,-79.9,36936383],
		["Willow Street","PA",39.98,-76.27,36937540],
		["Newbern","TN",36.11,-89.26,36938697],
		["Hamel","MN",45.07,-93.58,36939854],
		["Baxter","MN",46.34,-94.28,36941011],
		["Catoosa","OK",36.18,-95.76,36942168],
		["Pine Ridge","SD",43.02,-102.55,36943324],
		["Penn Valley","CA",39.18,-121.2,36944480],
		["Park Ridge","NJ",41.03,-74.04,36945635],
		["Jacksboro","TN",36.33,-84.19,36946790],
		["Toronto","OH",40.46,-80.6,36947945],
		["Akron","OH",41.08,-81.52,36949100],
		["Lindstrom","MN",45.38,-92.84,36950255],
		["Mount Vernon","MO",37.1,-93.81,36951410],
		["Louisburg","KS",38.62,-94.68,36952565],
		["Deville","LA",31.34,-92.15,36953720],
		["Trinity","TX",30.94,-95.37,36954875],
		["Edna","TX",28.97,-96.64,36956030],
		["Crownpoint","NM",35.68,-108.14,36957185],
		["South Salem","NY",41.25,-73.54,36958339],
		["Ijamsville","MD",39.33,-77.31,36959493],
		["Stratham","NH",43.02,-70.91,36960646],
		["Philadelphia","PA",39.95,-75.16,36961799],
		["Lawndale","NC",35.41,-81.56,36962952],
		["Seneca","SC",34.68,-82.95,36964105],
		["Naples","FL",26.14,-81.79,36965258],
		["Louisville","KY",38.22,-85.74,36966411],
		["Delta","OH",41.57,-84,36967564],
		["Yarmouth","ME",43.79,-70.2,36968716],
		["Holley","NY",43.22,-78.02,36969868],
		["Tomahawk","WI",45.47,-89.72,36971020],
		["Sioux Falls","SD",43.54,-96.73,36972172],
		["Pratt","KS",37.64,-98.73,36973324],
		["Vandergrift","PA",40.59,-79.57,36974475],
		["White Cloud","MI",43.55,-85.77,36975626],
		["Caruthersville","MO",36.17,-89.66,36976777],
		["Lanoka Harbor","NJ",39.86,-74.16,36977927],
		["Milton","DE",38.77,-75.31,36979077],
		["Alexandria","VA",38.82,-77.08,36980227],
		["Lizella","GA",32.76,-83.86,36981377],
		["Richmond","MO",39.27,-93.97,36982527],
		["Eudora","KS",38.93,-95.09,36983677],
		["Leander","TX",30.56,-97.86,36984827],
		["Little Ferry","NJ",40.84,-74.03,36985976],
		["Iuka","MS",34.8,-88.19,36987125],
		["Eden Prairie","MN",44.84,-93.45,36988274],
		["White Oak","TX",32.53,-94.85,36989423],
		["Pittsburgh","PA",40.43,-79.97,36990571],
		["Dingmans Ferry","PA",41.22,-74.87,36991719],
		["Pleasant View","TN",36.37,-87.04,36992867],
		["Allendale","NJ",41.03,-74.13,36994014],
		["Linwood","NJ",39.34,-74.57,36995161],
		["Chesapeake","OH",38.43,-82.45,36996308],
		["Sturgis","SD",44.41,-103.51,36997455],
		["Blackwell","OK",36.8,-97.29,36998602],
		["Woodville","TX",30.77,-94.42,36999749],
		["Strathmore","CA",36.14,-119.09,37000896],
		["Rindge","NH",42.75,-72.01,37002042],
		["Santa Rosa Beach","FL",30.37,-86.22,37003188],
		["South Bend","IN",41.67,-86.26,37004334],
		["Charlevoix","MI",45.31,-85.25,37005480],
		["Palos Park","IL",41.66,-87.84,37006626],
		["Dallas","TX",32.79,-96.76,37007772],
		["East Greenbush","NY",42.59,-73.7,37008917],
		["Belpre","OH",39.27,-81.58,37010062],
		["Broadview","IL",41.85,-87.85,37011207],
		["Dunbar","WV",38.36,-81.73,37012351],
		["Englewood","FL",26.95,-82.35,37013495],
		["Richton","MS",31.34,-88.94,37014639],
		["Sparta","GA",33.27,-82.97,37015782],
		["Gadsden","AL",34.01,-86.01,37016925],
		["Averill Park","NY",42.63,-73.55,37018067],
		["Plaistow","NH",42.83,-71.09,37019208],
		["Port Matilda","PA",40.79,-78.05,37020349],
		["Plymouth","PA",41.24,-75.95,37021490],
		["Milton","WV",38.43,-82.13,37022631],
		["Old Town","FL",29.59,-82.98,37023772],
		["Moody","AL",33.59,-86.49,37024913],
		["Bryan","TX",30.66,-96.36,37026054],
		["Fair Haven","NJ",40.36,-74.03,37027194],
		["Stevensville","MT",46.5,-114.1,37028334],
		["Muleshoe","TX",34.22,-102.72,37029474],
		["Thomaston","CT",41.67,-73.07,37030613],
		["Hinckley","OH",41.23,-81.73,37031752],
		["Towanda","PA",41.76,-76.44,37032890],
		["Hillside","IL",41.87,-87.89,37034027],
		["Farmington","AR",36.04,-94.24,37035164],
		["Sound Beach","NY",40.95,-72.96,37036300],
		["Summersville","WV",38.28,-80.84,37037436],
		["Magnolia","MS",31.15,-90.46,37038572],
		["Emmitsburg","MD",39.7,-77.32,37039707],
		["Broadway","VA",38.6,-78.79,37040842],
		["Metamora","MI",42.94,-83.29,37041977],
		["Fort Huachuca","AZ",31.52,-110.36,37043112],
		["Baltimore","MD",39.3,-76.61,37044245],
		["Childersburg","AL",33.27,-86.35,37045378],
		["New Haven","MI",42.73,-82.79,37046511],
		["Newport","OR",44.66,-124.01,37047644],
		["Vancouver","WA",45.72,-122.74,37048777],
		["Mississippi State","MS",33.45,-88.79,37049909],
		["West Salem","WI",43.89,-91.08,37051041],
		["Allston","MA",42.35,-71.13,37052172],
		["Linton","IN",39.03,-87.15,37053303],
		["Duluth","MN",46.78,-92.11,37054434],
		["Erath","LA",29.95,-92.03,37055565],
		["Jonesboro","LA",32.23,-92.71,37056696],
		["Wilburton","OK",34.91,-95.3,37057827],
		["Tyler","TX",32.32,-95.3,37058958],
		["Pipe Creek","TX",29.71,-98.93,37060089],
		["Moriarty","NM",35,-106.04,37061220],
		["Farmville","VA",37.29,-78.39,37062350],
		["Dora","AL",33.73,-87.08,37063480],
		["Mount Pleasant","TN",35.55,-87.19,37064610],
		["Macon","MS",33.11,-88.56,37065740],
		["Fruitland","ID",43.96,-116.91,37066870],
		["Jber","AK",61.27,-149.79,37068000],
		["Granby","CT",41.95,-72.78,37069129],
		["Tarentum","PA",40.6,-79.76,37070258],
		["Duncannon","PA",40.39,-77.02,37071387],
		["Baldwyn","MS",34.5,-88.63,37072516],
		["Barrington","NH",43.22,-71.04,37073644],
		["Ventnor City","NJ",39.34,-74.48,37074772],
		["Jackson","MS",32.32,-90.2,37075900],
		["Rose Hill","KS",37.56,-97.13,37077028],
		["Audubon","NJ",39.88,-75.07,37078155],
		["Absecon","NJ",39.42,-74.49,37079282],
		["Alexandria","VA",38.82,-77.08,37080409],
		["Saint Stephen","SC",33.4,-79.92,37081536],
		["Marianna","FL",30.77,-85.23,37082663],
		["Falling Waters","WV",39.55,-77.89,37083789],
		["Warsaw","NC",34.99,-78.08,37084915],
		["Savannah","GA",32.08,-81.1,37086041],
		["Waverly","TN",36.09,-87.78,37087167],
		["Crossville","TN",35.95,-85.03,37088293],
		["Union","MS",32.57,-89.11,37089419],
		["Denton","TX",33.21,-97.13,37090545],
		["Township Of Washington","NJ",40.98,-74.06,37091670],
		["Paulsboro","NJ",39.83,-75.24,37092795],
		["Troutman","NC",35.7,-80.89,37093920],
		["Sheffield","AL",34.75,-87.7,37095045],
		["Huntingdon","TN",36,-88.41,37096170],
		["Detroit","MI",42.38,-83.1,37097295],
		["Dousman","WI",43.01,-88.47,37098420],
		["Warren","RI",41.71,-71.26,37099544],
		["Arlington","VA",38.87,-77.1,37100668],
		["Ellenboro","NC",35.32,-81.75,37101792],
		["Punta Gorda","FL",26.89,-82.05,37102916],
		["Winterset","IA",41.33,-94.01,37104040],
		["De Witt","IA",41.82,-90.54,37105164],
		["Kewaskum","WI",43.52,-88.23,37106288],
		["Kasson","MN",44.02,-92.75,37107412],
		["Plainfield","CT",41.67,-71.92,37108535],
		["Pompano Beach","FL",26.23,-80.13,37109658],
		["Paoli","IN",38.55,-86.46,37110781],
		["Sterling","MA",42.43,-71.75,37111903],
		["Palacios","TX",28.71,-96.23,37113025],
		["Coal Township","PA",40.78,-76.54,37114146],
		["Pine Grove","PA",40.54,-76.38,37115267],
		["Conifer","CO",39.52,-105.3,37116388],
		["Grand Junction","CO",39.08,-108.55,37117509],
		["Castleton On Hudson","NY",42.53,-73.7,37118629],
		["Charlotte","NC",35.19,-80.83,37119749],
		["Carlinville","IL",39.28,-89.88,37120869],
		["Kempner","TX",31.07,-98,37121989],
		["Atlanta","GA",33.81,-84.36,37123108],
		["Tompkinsville","KY",36.7,-85.69,37124227],
		["Wamego","KS",39.2,-96.3,37125346],
		["Little Rock","AR",34.72,-92.35,37126465],
		["Cutler","CA",36.49,-119.28,37127584],
		["Shoreham","NY",40.95,-72.9,37128702],
		["Fort Eustis","VA",37.12,-76.59,37129820],
		["Dillon","MT",45.05,-112.82,37130938],
		["Omaha","NE",41.26,-96.01,37132056],
		["New Martinsville","WV",39.66,-80.85,37133173],
		["Pineville","NC",35.08,-80.88,37134290],
		["Citronelle","AL",31.09,-88.24,37135407],
		["Austin","IN",38.74,-85.81,37136524],
		["Jackson","WI",43.32,-88.16,37137641],
		["Libby","MT",48.32,-115.36,37138758],
		["Rio Hondo","TX",26.23,-97.58,37139875],
		["Dunkirk","MD",38.72,-76.66,37140991],
		["Warrenton","NC",36.4,-78.15,37142107],
		["Fort Lauderdale","FL",26.14,-80.13,37143223],
		["Maurice","LA",30.1,-92.12,37144339],
		["Mannford","OK",36.12,-96.32,37145455],
		["Davisburg","MI",42.75,-83.54,37146570],
		["Monterey","TN",36.13,-85.24,37147684],
		["Mount Vernon","IA",41.92,-91.42,37148798],
		["Port Jefferson","NY",40.94,-73.05,37149911],
		["Amarillo","TX",35.2,-101.81,37151024],
		["Beverly Hills","CA",34.06,-118.38,37152137],
		["Seattle","WA",47.53,-122.39,37153250],
		["Shannon","NC",34.83,-79.11,37154362],
		["Louisville","KY",38.22,-85.74,37155474],
		["Byron","MN",44.03,-92.64,37156586],
		["Dequincy","LA",30.37,-93.4,37157698],
		["East Earl","PA",40.14,-76.02,37158809],
		["Baltimore","MD",39.3,-76.61,37159920],
		["Rural Hall","NC",36.23,-80.29,37161031],
		["Forest Park","IL",41.86,-87.81,37162142],
		["Tulsa","OK",36.12,-95.91,37163253],
		["Franklin","NH",43.45,-71.66,37164363],
		["Columbus","NJ",40.06,-74.68,37165473],
		["Lovettsville","VA",39.27,-77.63,37166583],
		["Moseley","VA",37.41,-77.77,37167693],
		["Clearwater","FL",27.97,-82.76,37168803],
		["Inverness","FL",28.83,-82.34,37169913],
		["Estherville","IA",43.39,-94.83,37171023],
		["Cedar Hill","MO",38.35,-90.64,37172133],
		["Kimberly","ID",42.42,-114.38,37173243],
		["Berlin","NH",44.48,-71.25,37174352],
		["Winston Salem","NC",36.1,-80.24,37175461],
		["Waterville","OH",41.49,-83.73,37176570],
		["Nekoosa","WI",44.31,-89.9,37177679],
		["Marseilles","IL",41.32,-88.68,37178788],
		["Pinon","AZ",36.2,-110.23,37179897],
		["Charlestown","MA",42.38,-71.06,37181005],
		["Clifton","NJ",40.86,-74.15,37182113],
		["Glenmont","NY",42.59,-73.78,37183221],
		["Rochester","NY",43.16,-77.61,37184329],
		["Jarrettsville","MD",39.6,-76.47,37185437],
		["Chuckey","TN",36.24,-82.71,37186544],
		["Mantua","OH",41.28,-81.22,37187651],
		["Albion","IN",41.39,-85.41,37188758],
		["Sierra Madre","CA",34.2,-118.05,37189865],
		["Sebring","FL",27.49,-81.45,37190971],
		["Headland","AL",31.35,-85.34,37192077],
		["Pittsboro","IN",39.86,-86.46,37193183],
		["Ellettsville","IN",39.23,-86.62,37194289],
		["Chicago","IL",41.9,-87.65,37195395],
		["Sitka","AK",56.73,-135,37196501],
		["Elba","AL",31.41,-86.07,37197606],
		["Sullivan","IN",39.09,-87.4,37198711],
		["Hearne","TX",30.87,-96.59,37199816],
		["Los Angeles","CA",34.1,-118.45,37200921],
		["Los Gatos","CA",37.13,-121.94,37202026],
		["Beaver Dam","KY",37.4,-86.87,37203130],
		["Box Elder","SD",44.11,-103.07,37204234],
		["Pueblo","CO",38.27,-104.62,37205338],
		["Newland","NC",36.08,-81.92,37206441],
		["Bloomingdale","GA",32.11,-81.3,37207544],
		["Mondovi","WI",44.56,-91.66,37208647],
		["Calhoun","LA",32.5,-92.34,37209750],
		["San Juan","PR",18.4,-66.06,37210852],
		["Stokesdale","NC",36.23,-79.98,37211954],
		["Beecher","IL",41.34,-87.61,37213056],
		["Saint Leonard","MD",38.47,-76.5,37214157],
		["Manchester","IA",42.48,-91.45,37215258],
		["Oakville","CT",41.59,-73.08,37216358],
		["Port Deposit","MD",39.6,-76.11,37217458],
		["Cincinnati","OH",39.09,-84.51,37218558],
		["Hugo","OK",34.01,-95.51,37219658],
		["Seaside","OR",45.92,-123.7,37220758],
		["Schnecksville","PA",40.67,-75.61,37221857],
		["Schuyler","NE",41.44,-97.05,37222956],
		["Chandler","OK",35.7,-96.87,37224055],
		["Bonners Ferry","ID",48.75,-116.51,37225154],
		["Williamson","NY",43.24,-77.15,37226252],
		["Hillsville","VA",36.76,-80.73,37227350],
		["Reno","NV",39.52,-119.81,37228448],
		["Carson City","NV",39.16,-119.86,37229546],
		["Cashmere","WA",47.55,-120.48,37230644],
		["Round Hill","VA",39.13,-77.77,37231741],
		["Maylene","AL",33.23,-86.93,37232838],
		["Williamstown","KY",38.63,-84.56,37233935],
		["Troup","TX",32.14,-95.12,37235032],
		["Westminster","MA",42.55,-71.9,37236128],
		["Plainville","MA",42,-71.33,37237224],
		["Dunnellon","FL",29.04,-82.44,37238320],
		["New Tazewell","TN",36.43,-83.6,37239416],
		["Viroqua","WI",43.55,-90.88,37240512],
		["Littlefield","TX",33.91,-102.33,37241608],
		["Weston","WV",39.04,-80.46,37242703],
		["Osceola","WI",45.31,-92.69,37243798],
		["Pomona","NY",41.18,-74.05,37244892],
		["Springville","NY",42.5,-78.67,37245986],
		["Duluth","MN",46.78,-92.11,37247080],
		["Lincolnshire","IL",42.19,-87.91,37248174],
		["Trenton","MO",40.08,-93.6,37249268],
		["Warren","AR",33.61,-92.06,37250362],
		["Manville","NJ",40.54,-74.58,37251455],
		["Rochester","PA",40.7,-80.28,37252548],
		["Martins Ferry","OH",40.1,-80.72,37253641],
		["Fort Wayne","IN",41.07,-85.13,37254734],
		["Latta","SC",34.33,-79.43,37255826],
		["Vacherie","LA",29.96,-90.71,37256918],
		["Orange","MA",42.59,-72.3,37258009],
		["New York","NY",40.71,-73.99,37259100],
		["Sarver","PA",40.73,-79.73,37260191],
		["Ferrum","VA",36.92,-80.02,37261282],
		["Dunnellon","FL",29.04,-82.45,37262373],
		["Goshen","KY",38.4,-85.59,37263464],
		["Evansdale","IA",42.46,-92.27,37264555],
		["Shreveport","LA",32.47,-93.79,37265646],
		["Dover","MA",42.24,-71.27,37266736],
		["Wynantskill","NY",42.68,-73.63,37267826],
		["Bamberg","SC",33.29,-81.03,37268916],
		["Atlanta","GA",33.76,-84.42,37270006],
		["Strafford","MO",37.26,-93.11,37271096],
		["Mammoth Lakes","CA",37.58,-118.84,37272186],
		["Greensburg","KY",37.25,-85.49,37273275],
		["Piketon","OH",39.06,-82.99,37274364],
		["Colusa","CA",39.27,-122.05,37275453],
		["Wells","ME",43.32,-70.58,37276541],
		["Cedarhurst","NY",40.62,-73.72,37277629],
		["Beulaville","NC",34.92,-77.77,37278717],
		["Vidalia","LA",31.56,-91.44,37279805],
		["Krum","TX",33.26,-97.22,37280893],
		["Lincoln Park","NJ",40.92,-74.3,37281980],
		["Millburn","NJ",40.74,-74.32,37283067],
		["Le Roy","NY",42.97,-77.99,37284154],
		["Fort Stewart","GA",31.87,-81.61,37285241],
		["Cannon Falls","MN",44.51,-92.9,37286328],
		["Millis","MA",42.16,-71.35,37287414],
		["Lebanon","CT",41.66,-72.24,37288500],
		["Middlebury","CT",41.52,-73.12,37289586],
		["Poolesville","MD",39.14,-77.41,37290672],
		["Montpelier","VA",37.81,-77.67,37291758],
		["Hardeeville","SC",32.27,-81.07,37292844],
		["Mounds","OK",35.87,-96.05,37293930],
		["Denair","CA",37.54,-120.68,37295016],
		["Webster","FL",28.61,-82.05,37296101],
		["Toledo","OH",41.66,-83.58,37297186],
		["Saint Louis","MI",43.4,-84.61,37298271],
		["Veneta","OR",43.97,-123.42,37299356],
		["Nettleton","MS",34.09,-88.61,37300440],
		["Anaconda","MT",46.12,-112.97,37301524],
		["Carmel","CA",36.46,-121.82,37302608],
		["Parkesburg","PA",39.95,-75.91,37303691],
		["Roanoke","VA",37.27,-79.95,37304774],
		["Greensboro","GA",33.57,-83.18,37305857],
		["Pompano Beach","FL",26.23,-80.13,37306940],
		["Rainsville","AL",34.49,-85.84,37308023],
		["Roscommon","MI",44.49,-84.58,37309106],
		["Judsonia","AR",35.27,-91.64,37310189],
		["Nephi","UT",39.68,-111.83,37311272],
		["Denmark","SC",33.31,-81.13,37312354],
		["Brooklet","GA",32.38,-81.66,37313436],
		["Tellico Plains","TN",35.36,-84.29,37314518],
		["Glencoe","MN",44.76,-94.14,37315600],
		["Stewartville","MN",43.85,-92.48,37316682],
		["Clint","TX",31.59,-106.22,37317764],
		["Palm Springs","CA",33.72,-116.51,37318846],
		["Ocean City","NJ",39.26,-74.6,37319927],
		["Lebanon","VA",36.89,-82.07,37321008],
		["Palmer","MA",42.16,-72.32,37322088],
		["Old Westbury","NY",40.78,-73.59,37323168],
		["Lake View","NY",42.71,-78.93,37324248],
		["Beech Island","SC",33.48,-81.89,37325328],
		["Marion","AL",32.63,-87.31,37326408],
		["Donalsonville","GA",31.04,-84.87,37327488],
		["Edinburgh","IN",39.35,-85.96,37328568],
		["Castroville","TX",29.34,-98.87,37329648],
		["Berkley","MA",41.85,-71.08,37330727],
		["Weehawken","NJ",40.76,-74.01,37331806],
		["Hempstead","NY",40.7,-73.61,37332885],
		["Point Pleasant","WV",38.85,-82.13,37333964],
		["Youngstown","OH",41.09,-80.64,37335043],
		["Kearneysville","WV",39.38,-77.88,37336121],
		["Reserve","LA",30.07,-90.56,37337199],
		["Boring","OR",45.42,-122.37,37338277],
		["Athens","GA",33.95,-83.39,37339354],
		["Birmingham","AL",33.52,-86.79,37340431],
		["Overton","TX",32.27,-94.97,37341508],
		["Falfurrias","TX",27.22,-98.14,37342585],
		["Luling","TX",29.68,-97.64,37343662],
		["Austin","TX",30.26,-97.74,37344739],
		["Worland","WY",44,-107.95,37345816],
		["Jonestown","PA",40.41,-76.48,37346892],
		["Mooresboro","NC",35.29,-81.69,37347968],
		["Bellaire","OH",40.01,-80.74,37349044],
		["Clintonville","WI",44.62,-88.75,37350120],
		["Newtonville","MA",42.35,-71.2,37351195],
		["Springfield","VT",43.28,-72.47,37352270],
		["Lyndonville","VT",44.53,-72,37353345],
		["North Versailles","PA",40.37,-79.8,37354420],
		["Adamsville","AL",33.59,-86.95,37355495],
		["Altoona","AL",34.03,-86.31,37356570],
		["Decatur","TN",35.52,-84.79,37357645],
		["Whitehouse","OH",41.51,-83.8,37358720],
		["Duluth","MN",46.78,-92.11,37359795],
		["Hull","MA",42.3,-70.9,37360869],
		["Vanceboro","NC",35.3,-77.15,37361943],
		["Union Springs","AL",32.13,-85.71,37363017],
		["Lubbock","TX",33.57,-101.87,37364091],
		["East Lyme","CT",41.38,-72.24,37365164],
		["Oakdale","CT",41.46,-72.18,37366237],
		["Roebuck","SC",34.87,-81.96,37367310],
		["Bowling Green","FL",27.63,-81.82,37368383],
		["Topeka","IN",41.53,-85.54,37369456],
		["Umatilla","OR",45.9,-119.33,37370529],
		["Brookville","PA",41.16,-79.08,37371601],
		["Moneta","VA",37.18,-79.63,37372673],
		["Scott Depot","WV",38.44,-81.89,37373745],
		["Somerville","AL",34.47,-86.8,37374817],
		["Luxemburg","WI",44.54,-87.7,37375889],
		["California","MO",38.63,-92.56,37376961],
		["Kennedale","TX",32.64,-97.21,37378033],
		["Rancho Mirage","CA",33.78,-116.41,37379105],
		["West Nyack","NY",41.09,-73.96,37380176],
		["Edinboro","PA",41.87,-80.12,37381247],
		["Olyphant","PA",41.45,-75.59,37382318],
		["Leo","IN",41.23,-85.04,37383389],
		["Vinton","IA",42.16,-92.02,37384460],
		["Virginia","MN",47.51,-92.5,37385531],
		["Brewer","ME",44.77,-68.73,37386601],
		["Omaha","NE",41.26,-96.01,37387671],
		["Bayfield","CO",37.23,-107.59,37388741],
		["Halifax","MA",41.98,-70.86,37389810],
		["Wallington","NJ",40.85,-74.1,37390879],
		["Philadelphia","PA",39.95,-75.16,37391948],
		["North Port","FL",27.17,-82.28,37393017],
		["Central City","KY",37.29,-87.12,37394086],
		["Center Line","MI",42.48,-83.02,37395155],
		["Abita Springs","LA",30.47,-90.03,37396224],
		["American Falls","ID",42.72,-112.99,37397293],
		["Maynard","MA",42.42,-71.45,37398361],
		["Fairfield","PA",39.78,-77.36,37399429],
		["Atlanta","GA",33.74,-84.31,37400497],
		["Gulf Breeze","FL",30.36,-87.17,37401565],
		["Montpelier","OH",41.58,-84.6,37402633],
		["Denver City","TX",32.96,-102.83,37403701],
		["Adams","MA",42.62,-73.11,37404768],
		["Unionville","CT",41.75,-72.88,37405835],
		["Cheswick","PA",40.54,-79.8,37406902],
		["Iron Station","NC",35.44,-81.15,37407969],
		["Rogersville","AL",34.82,-87.28,37409036],
		["Talbott","TN",36.14,-83.4,37410103],
		["Winona","MS",33.48,-89.72,37411170],
		["Cincinnati","OH",39.09,-84.51,37412237],
		["Joshua Tree","CA",34.15,-116.27,37413304],
		["Lake Hiawatha","NJ",40.88,-74.38,37414370],
		["Key Largo","FL",25.08,-80.44,37415436],
		["Hartford","MI",42.2,-86.16,37416502],
		["East Helena","MT",46.53,-111.77,37417568],
		["Cincinnati","OH",39.09,-84.51,37418633],
		["Bluffton","OH",40.89,-83.88,37419698],
		["Atherton","CA",37.44,-122.2,37420763],
		["Coxsackie","NY",42.35,-73.8,37421827],
		["Hilton Head Island","SC",32.19,-80.74,37422891],
		["Vienna","GA",32.08,-83.79,37423955],
		["Louisville","KY",38.22,-85.74,37425019],
		["Granger","WA",46.32,-120.15,37426083],
		["Mineral","VA",38,-77.9,37427146],
		["Johnsonville","SC",33.81,-79.44,37428209],
		["San Diego","CA",32.71,-117.16,37429272],
		["Hillsborough","NH",43.12,-71.92,37430334],
		["Bernville","PA",40.43,-76.11,37431396],
		["Lecanto","FL",28.85,-82.48,37432458],
		["Phoenix","MD",39.5,-76.56,37433519],
		["Ashburnham","MA",42.63,-71.9,37434579],
		["Crownsville","MD",39.01,-76.59,37435639],
		["Naples","FL",26.14,-81.79,37436699],
		["Falmouth","KY",38.67,-84.33,37437759],
		["Victoria","MN",44.86,-93.65,37438819],
		["Pollock Pines","CA",38.74,-120.57,37439879],
		["Merrimac","MA",42.83,-71,37440938],
		["Brooklyn","CT",41.78,-71.95,37441997],
		["Farmingdale","NJ",40.19,-74.17,37443056],
		["Ravenswood","WV",38.95,-81.76,37444115],
		["Reidsville","GA",32.08,-82.11,37445174],
		["Amery","WI",45.3,-92.36,37446233],
		["Foley","MN",45.66,-93.9,37447292],
		["Folsom","LA",30.63,-90.19,37448351],
		["Sunset","LA",30.4,-92.06,37449410],
		["Homer","LA",32.79,-93.05,37450469],
		["Wolfforth","TX",33.5,-102.01,37451528],
		["Lancaster","MA",42.45,-71.66,37452586],
		["Tilton","NH",43.44,-71.58,37453644],
		["Gap","PA",39.98,-76.02,37454702],
		["Abingdon","VA",36.7,-81.96,37455760],
		["Twin Lakes","WI",42.51,-88.25,37456818],
		["Eufaula","OK",35.29,-95.58,37457876],
		["Corrales","NM",35.23,-106.62,37458934],
		["Chiefland","FL",29.47,-82.86,37459991],
		["Diberville","MS",30.44,-88.9,37461048],
		["San Francisco","CA",37.75,-122.43,37462105],
		["Hamburg","NJ",41.14,-74.57,37463161],
		["Waverly","NY",42.01,-76.52,37464217],
		["Elmira","NY",42.08,-76.8,37465273],
		["Plymouth","NC",35.86,-76.74,37466329],
		["Oakwood","GA",34.23,-83.88,37467385],
		["Quincy","FL",30.65,-84.69,37468441],
		["Cardington","OH",40.49,-82.89,37469497],
		["San Antonio","TX",29.45,-98.5,37470553],
		["Chepachet","RI",41.91,-71.7,37471608],
		["Fort Plain","NY",42.93,-74.62,37472663],
		["Washington","DC",38.89,-77.03,37473718],
		["Panama City Beach","FL",30.17,-85.8,37474773],
		["Saint Petersburg","FL",27.75,-82.64,37475828],
		["Sanford","MI",43.67,-84.38,37476883],
		["Hammond","LA",30.5,-90.46,37477938],
		["Gravette","AR",36.42,-94.45,37478993],
		["Wernersville","PA",40.33,-76.08,37480047],
		["Nesbit","MS",34.89,-90.02,37481101],
		["Bloomfield","IA",40.74,-92.41,37482155],
		["Prairie Du Chien","WI",43.04,-91.14,37483209],
		["Eureka","IL",40.71,-89.27,37484263],
		["Cuba","MO",38.06,-91.39,37485317],
		["Williams","CA",39.11,-122.27,37486371],
		["Gary","IN",41.59,-87.34,37487424],
		["Nevada","IA",42.01,-93.45,37488477],
		["Houston","TX",29.76,-95.38,37489530],
		["Halifax","PA",40.46,-76.93,37490582],
		["Iva","SC",34.3,-82.66,37491634],
		["Englewood","FL",26.95,-82.35,37492686],
		["Walls","MS",34.95,-90.13,37493738],
		["Dallas","TX",32.79,-96.76,37494790],
		["Old Town","ME",44.95,-68.73,37495841],
		["Allentown","NJ",40.17,-74.58,37496892],
		["Elm City","NC",35.8,-77.86,37497943],
		["Columbia","SC",34,-81.03,37498994],
		["Monticello","IL",40.02,-88.57,37500045],
		["White River Junction","VT",43.65,-72.32,37501095],
		["Cheltenham","PA",40.05,-75.1,37502145],
		["Bunker Hill","WV",39.32,-78.05,37503195],
		["Houston","TX",29.76,-95.38,37504245],
		["Albertson","NY",40.77,-73.64,37505294],
		["Woodstock","VA",38.87,-78.51,37506343],
		["Scottsville","VA",37.79,-78.49,37507392],
		["Spring Hope","NC",35.94,-78.1,37508441],
		["Calumet","MI",47.24,-88.45,37509490],
		["Somerville","MA",42.39,-71.1,37510538],
		["South Glens Falls","NY",43.29,-73.63,37511586],
		["Vanceburg","KY",38.59,-83.32,37512634],
		["Dundee","OH",40.58,-81.6,37513682],
		["Hartland","MI",42.65,-83.75,37514730],
		["Anamosa","IA",42.1,-91.28,37515778],
		["Madison","WI",43.07,-89.38,37516826],
		["Stigler","OK",35.25,-95.12,37517874],
		["Topsfield","MA",42.63,-70.95,37518921],
		["Lincoln","AL",33.59,-86.13,37519968],
		["Harrogate","TN",36.56,-83.54,37521015],
		["Galesburg","MI",42.29,-85.41,37522062],
		["Thousand Palms","CA",33.8,-116.36,37523109],
		["Pacific","WA",47.25,-122.24,37524156],
		["Patterson","NY",41.5,-73.58,37525202],
		["Hull","GA",34.01,-83.29,37526248],
		["Mc Kee","KY",37.43,-83.98,37527294],
		["Lake Mills","WI",43.07,-88.9,37528340],
		["North Chelmsford","MA",42.63,-71.39,37529385],
		["Philadelphia","PA",39.95,-75.16,37530430],
		["Parkton","NC",34.9,-79.01,37531475],
		["West Point","GA",32.87,-85.16,37532520],
		["Jasper","FL",30.51,-82.95,37533565],
		["Winamac","IN",41.05,-86.6,37534610],
		["Inola","OK",36.13,-95.53,37535655],
		["Benson","AZ",32.19,-110.38,37536700],
		["Topsham","ME",43.93,-69.94,37537744],
		["Delton","MI",42.49,-85.4,37538788],
		["Ruston","LA",32.53,-92.63,37539832],
		["Locust Grove","OK",36.19,-95.16,37540876],
		["Calhan","CO",39.03,-104.29,37541920],
		["Acampo","CA",38.19,-121.25,37542964],
		["Parkton","MD",39.65,-76.67,37544007],
		["Albany","NY",42.66,-73.79,37545049],
		["Granville","NY",43.4,-73.26,37546091],
		["Mount Rainier","MD",38.94,-76.96,37547133],
		["Robbinsville","NC",35.32,-83.8,37548175],
		["Belle Fourche","SD",44.66,-103.85,37549217],
		["Colby","KS",39.38,-101.04,37550259],
		["Milltown","NJ",40.45,-74.43,37551300],
		["Wellford","SC",34.95,-82.09,37552341],
		["Independence","MO",39.08,-94.35,37553382],
		["Bella Vista","AR",36.46,-94.29,37554423],
		["Ivins","UT",37.18,-113.63,37555464],
		["Cohutta","GA",34.95,-84.95,37556504],
		["Athens","AL",34.78,-86.95,37557544],
		["Slocomb","AL",31.11,-85.59,37558584],
		["Milwaukee","WI",43.06,-87.96,37559624],
		["Sulphur","OK",34.5,-96.97,37560664],
		["Lytle","TX",29.23,-98.79,37561704],
		["Golden Valley","AZ",35.13,-114.25,37562744],
		["Leicester","MA",42.25,-71.9,37563783],
		["Maywood","NJ",40.9,-74.06,37564822],
		["Blauvelt","NY",41.06,-73.95,37565861],
		["Raton","NM",36.88,-104.44,37566900],
		["North Hollywood","CA",34.15,-118.36,37567939],
		["Terra Bella","CA",35.93,-119.03,37568978],
		["Hebron","CT",41.65,-72.36,37570016],
		["East Bend","NC",36.21,-80.5,37571054],
		["North Myrtle Beach","SC",33.82,-78.67,37572092],
		["Napoleonville","LA",29.93,-91.02,37573130],
		["Bailey","CO",39.4,-105.47,37574168],
		["South Hill","VA",36.72,-78.12,37575205],
		["Philippi","WV",39.15,-80.04,37576242],
		["Pilot Mountain","NC",36.38,-80.47,37577279],
		["Crescent City","FL",29.43,-81.51,37578316],
		["Spring City","TN",35.68,-84.86,37579353],
		["Aumsville","OR",44.82,-122.85,37580390],
		["Swanton","VT",44.92,-73.12,37581426],
		["New Freedom","PA",39.73,-76.69,37582462],
		["Fairfield","IL",38.38,-88.36,37583498],
		["Dover","AR",35.4,-93.11,37584534],
		["Holdenville","OK",35.08,-96.39,37585570],
		["Redwood City","CA",37.53,-122.24,37586606],
		["Sultan","WA",47.85,-121.74,37587642],
		["Spruce Pine","NC",35.91,-82.06,37588677],
		["Cumberland Center","ME",43.8,-70.25,37589711],
		["Bumpass","VA",37.96,-77.73,37590745],
		["Gate City","VA",36.63,-82.58,37591779],
		["Grundy","VA",37.27,-82.1,37592813],
		["Tallapoosa","GA",33.75,-85.29,37593847],
		["Fortson","GA",32.63,-84.98,37594881],
		["Somerset","WI",45.12,-92.67,37595915],
		["Denison","TX",33.75,-96.56,37596949],
		["Comanche","TX",31.89,-98.6,37597983],
		["San Antonio","TX",29.45,-98.5,37599017],
		["Presidio","TX",29.56,-104.36,37600051],
		["Atwater","OH",41.03,-81.18,37601084],
		["Cassopolis","MI",41.91,-86,37602117],
		["Berkeley","CA",37.86,-122.24,37603150],
		["Townsend","MA",42.66,-71.7,37604182],
		["Evington","VA",37.23,-79.28,37605214],
		["Bowdon","GA",33.53,-85.25,37606246],
		["Winfield","AL",33.92,-87.79,37607278],
		["Violet","LA",29.89,-89.89,37608310],
		["Pilot Point","TX",33.39,-96.95,37609342],
		["Parma","ID",43.8,-116.91,37610374],
		["Delta","UT",39.29,-112.56,37611406],
		["Wittmann","AZ",33.72,-112.65,37612438],
		["Montesano","WA",47.17,-123.6,37613470],
		["Brewster","MA",41.76,-70.08,37614501],
		["Daytona Beach","FL",29.19,-81.05,37615532],
		["Silver Springs","FL",29.22,-82,37616563],
		["Brooksville","FL",28.55,-82.39,37617594],
		["Hartsville","TN",36.39,-86.15,37618625],
		["Wilton","CA",38.39,-121.24,37619656],
		["Shelburne","VT",44.38,-73.23,37620686],
		["Hewitt","NJ",41.16,-74.35,37621716],
		["Sylacauga","AL",33.17,-86.26,37622746],
		["Crestline","OH",40.78,-82.73,37623776],
		["Reeds Spring","MO",36.75,-93.38,37624806],
		["Freeport","ME",43.85,-70.1,37625835],
		["Jonesville","LA",31.62,-91.83,37626864],
		["Green Forest","AR",36.33,-93.42,37627893],
		["New River","AZ",33.95,-112.07,37628922],
		["Stockton","CA",37.98,-121.3,37629951],
		["Castle Hayne","NC",34.35,-77.9,37630979],
		["Cocoa Beach","FL",28.33,-80.62,37632007],
		["Centerville","TN",35.79,-87.44,37633035],
		["Newcomerstown","OH",40.27,-81.59,37634063],
		["Monte Vista","CO",37.57,-106.14,37635091],
		["Caruthers","CA",36.52,-119.87,37636119],
		["Woodstock","CT",41.95,-71.98,37637146],
		["Califon","NJ",40.71,-74.83,37638173],
		["Valencia","PA",40.67,-79.98,37639200],
		["Crozet","VA",38.06,-78.69,37640227],
		["Ridgeville","SC",33.08,-80.3,37641254],
		["Loxley","AL",30.62,-87.75,37642281],
		["Cincinnati","OH",39.09,-84.51,37643308],
		["San Diego","TX",27.75,-98.23,37644335],
		["Sun City","AZ",33.68,-112.31,37645362],
		["Homeland","CA",33.75,-117.11,37646389],
		["Newport","WA",48.15,-117.24,37647416],
		["Stamford","CT",41.09,-73.55,37648442],
		["Millstone Township","NJ",40.21,-74.44,37649468],
		["Grifton","NC",35.37,-77.43,37650494],
		["Howard","OH",40.38,-82.27,37651520],
		["Manchester","MI",42.14,-84.03,37652546],
		["Atlantic","IA",41.39,-95.01,37653572],
		["Odessa","TX",31.85,-102.36,37654598],
		["Bedford","NY",41.19,-73.64,37655623],
		["Jim Thorpe","PA",40.87,-75.73,37656648],
		["Archbold","OH",41.51,-84.3,37657673],
		["Denver","CO",39.76,-104.87,37658698],
		["Salisbury","MA",42.83,-70.84,37659722],
		["Hales Corners","WI",42.94,-88.04,37660746],
		["La Crescent","MN",43.82,-91.3,37661770],
		["New York","NY",40.71,-73.99,37662793],
		["West Jefferson","OH",39.94,-83.3,37663816],
		["Dalton","OH",40.79,-81.69,37664839],
		["Sauk Centre","MN",45.73,-94.95,37665862],
		["Sullivan","IL",39.59,-88.6,37666885],
		["Lenexa","KS",38.9,-94.68,37667908],
		["Seattle","WA",47.66,-122.38,37668931],
		["Boqueron","PR",17.99,-67.15,37669953],
		["Pipersville","PA",40.43,-75.1,37670975],
		["Ellabell","GA",32.12,-81.48,37671997],
		["Lawton","MI",42.16,-85.84,37673019],
		["Chilton","WI",44.02,-88.16,37674041],
		["Lewistown","MT",47.01,-109.34,37675063],
		["Runnemede","NJ",39.85,-75.07,37676084],
		["Disputanta","VA",37.12,-77.22,37677105],
		["Spencer","OK",35.5,-97.37,37678126],
		["Oceano","CA",35.06,-120.6,37679147],
		["Saint Petersburg","FL",27.75,-82.64,37680167],
		["Boyne City","MI",45.21,-85.01,37681187],
		["Macon","MO",39.73,-92.47,37682207],
		["Kansas City","MO",39.09,-94.58,37683227],
		["Eagle","CO",39.65,-106.82,37684247],
		["Newport","PA",40.47,-77.13,37685266],
		["Arcanum","OH",39.99,-84.55,37686285],
		["Glendive","MT",47.12,-104.87,37687304],
		["Saint Helena","CA",38.55,-122.39,37688323],
		["Carrollton","KY",38.67,-85.16,37689341],
		["Canton","OH",40.81,-81.37,37690359],
		["New Carlisle","IN",41.7,-86.51,37691377],
		["Columbus","WI",43.33,-89.02,37692395],
		["East Sandwich","MA",41.73,-70.43,37693412],
		["Pendleton","SC",34.65,-82.78,37694429],
		["Demorest","GA",34.56,-83.54,37695446],
		["Richland","MI",42.37,-85.45,37696463],
		["Washington","GA",33.73,-82.74,37697479],
		["Marion","KY",37.33,-88.07,37698495],
		["Sheridan","IN",40.13,-86.21,37699511],
		["Hanover","IN",38.71,-85.47,37700527],
		["Yorktown","IN",40.17,-85.47,37701543],
		["Houston","TX",29.76,-95.38,37702559],
		["Cotati","CA",38.31,-122.7,37703575],
		["Archer","FL",29.53,-82.51,37704590],
		["Tarpon Springs","FL",28.14,-82.78,37705605],
		["Westville","IN",41.53,-86.9,37706620],
		["Posen","IL",41.62,-87.68,37707635],
		["Anthony","TX",31.99,-106.59,37708650],
		["Richland","MS",32.32,-90.2,37709664],
		["Warsaw","MO",38.24,-93.37,37710678],
		["Valley Cottage","NY",41.11,-73.94,37711691],
		["Salem","WV",39.28,-80.56,37712704],
		["East China","MI",42.77,-82.53,37713717],
		["Negaunee","MI",46.49,-87.59,37714730],
		["Iowa Falls","IA",42.52,-93.26,37715743],
		["Centerville","IA",40.73,-92.87,37716756],
		["Dryden","NY",42.49,-76.29,37717768],
		["Mill Hall","PA",41.1,-77.49,37718780],
		["Tunica","MS",34.68,-90.38,37719792],
		["Taylorsville","MS",31.83,-89.43,37720804],
		["Dayton","OH",39.77,-84.19,37721816],
		["Bloomingburg","NY",41.55,-74.44,37722827],
		["Caddo Mills","TX",33.06,-96.22,37723838],
		["Kirbyville","TX",30.65,-93.89,37724849],
		["Gooding","ID",43.01,-114.7,37725860],
		["Mountain View","CA",37.38,-122.07,37726871],
		["North Branford","CT",41.33,-72.77,37727881],
		["Kenilworth","NJ",40.67,-74.28,37728891],
		["Vernon","NJ",41.18,-74.51,37729901],
		["Pocomoke City","MD",38.06,-75.56,37730911],
		["Troy","NC",35.36,-79.89,37731921],
		["Perry","FL",30.1,-83.58,37732931],
		["Kalkaska","MI",44.73,-85.17,37733941],
		["Trevor","WI",42.51,-88.13,37734951],
		["Mauston","WI",43.8,-90.08,37735961],
		["De Soto","KS",38.97,-94.95,37736971],
		["Bunkie","LA",30.95,-92.18,37737981],
		["Brewerton","NY",43.23,-76.14,37738990],
		["Bailey","NC",35.78,-78.11,37739999],
		["Owenton","KY",38.53,-84.83,37741008],
		["Jamestown","OH",39.65,-83.74,37742017],
		["Spotswood","NJ",40.39,-74.39,37743025],
		["Altamont","NY",42.7,-74.03,37744033],
		["Bryson City","NC",35.42,-83.44,37745041],
		["Commerce","GA",34.2,-83.46,37746049],
		["Wakeman","OH",41.25,-82.4,37747057],
		["Pana","IL",39.38,-89.07,37748065],
		["Brush","CO",40.25,-103.63,37749073],
		["Morton","PA",39.91,-75.32,37750080],
		["Prosperity","SC",34.21,-81.53,37751087],
		["Carnation","WA",47.64,-121.89,37752094],
		["Harwich","MA",41.69,-70.07,37753100],
		["Live Oak","FL",30.29,-82.98,37754106],
		["Des Moines","IA",41.57,-93.61,37755112],
		["Peotone","IL",41.33,-87.79,37756118],
		["San Antonio","TX",29.45,-98.5,37757124],
		["Walnut Creek","CA",37.86,-122.07,37758130],
		["Folsom","PA",39.89,-75.32,37759135],
		["Haverford","PA",40,-75.31,37760140],
		["Warrenville","SC",33.51,-81.82,37761145],
		["Shepherd","MI",43.52,-84.69,37762150],
		["El Dorado Springs","MO",37.86,-94.01,37763155],
		["Sanborn","NY",43.14,-78.87,37764159],
		["Glen Rock","PA",39.79,-76.73,37765163],
		["Hollywood","FL",26.02,-80.16,37766167],
		["Dundee","MI",41.95,-83.66,37767171],
		["New Berlin","WI",42.97,-88.12,37768175],
		["Fox Lake","IL",42.41,-88.18,37769179],
		["Marianna","AR",34.77,-90.76,37770183],
		["New Britain","CT",41.67,-72.78,37771186],
		["Lincolnton","GA",33.79,-82.47,37772189],
		["Philadelphia","PA",39.95,-75.16,37773191],
		["Temple","PA",40.4,-75.92,37774193],
		["Townsend","GA",31.53,-81.52,37775195],
		["Wisconsin Rapids","WI",44.39,-89.83,37776197],
		["Tulsa","OK",36.12,-95.91,37777199],
		["Fairfield","TX",31.71,-96.16,37778201],
		["Norwood","NC",35.22,-80.12,37779202],
		["Alto","GA",34.47,-83.57,37780203],
		["Worcester","MA",42.26,-71.8,37781203],
		["Rhinebeck","NY",41.92,-73.9,37782203],
		["Larose","LA",29.57,-90.37,37783203],
		["Talent","OR",42.2,-122.81,37784203],
		["Walworth","NY",43.14,-77.27,37785202],
		["White Plains","MD",38.59,-76.97,37786201],
		["Glenn Dale","MD",38.98,-76.8,37787200],
		["Rutledge","TN",36.27,-83.52,37788199],
		["Spencer","WV",38.8,-81.35,37789197],
		["Itta Bena","MS",33.49,-90.32,37790195],
		["Rineyville","KY",37.75,-85.96,37791193],
		["Campbellsport","WI",43.59,-88.28,37792191],
		["San Antonio","TX",29.45,-98.5,37793189],
		["Lubbock","TX",33.57,-101.87,37794187],
		["Pinon Hills","CA",34.47,-117.62,37795185],
		["Maricao","PR",18.18,-66.98,37796182],
		["Northford","CT",41.38,-72.77,37797179],
		["Clarinda","IA",40.73,-95.03,37798176],
		["Newport","NH",43.37,-72.17,37799172],
		["Ashville","AL",33.83,-86.26,37800168],
		["New Market","TN",36.09,-83.55,37801164],
		["Omro","WI",44.03,-88.74,37802160],
		["Millstadt","IL",38.45,-90.09,37803156],
		["Moscow Mills","MO",38.94,-90.91,37804152],
		["Corona Del Mar","CA",33.6,-117.86,37805148],
		["Fairfield","NJ",40.88,-74.3,37806143],
		["Ebensburg","PA",40.48,-78.72,37807138],
		["Brodhead","WI",42.61,-89.37,37808133],
		["Pea Ridge","AR",36.45,-94.11,37809128],
		["Odessa","TX",31.85,-102.36,37810123],
		["South Glastonbury","CT",41.65,-72.56,37811117],
		["Centerport","NY",40.9,-73.37,37812111],
		["Charleston","WV",38.35,-81.63,37813105],
		["Fremont","IN",41.73,-84.93,37814099],
		["Almont","MI",42.92,-83.04,37815093],
		["Avon","CO",39.65,-106.53,37816087],
		["Kula","HI",20.72,-156.34,37817081],
		["Landrum","SC",35.17,-82.18,37818074],
		["Pekin","IN",38.49,-86.01,37819067],
		["Oak Brook","IL",41.83,-87.95,37820060],
		["Trion","GA",34.54,-85.31,37821052],
		["Sardis","MS",34.43,-89.91,37822044],
		["Bad Axe","MI",43.8,-82.99,37823036],
		["Bandera","TX",29.72,-99.07,37824028],
		["Orange Grove","TX",27.95,-97.93,37825020],
		["Malibu","CA",34.03,-118.7,37826012],
		["Gales Ferry","CT",41.46,-72.09,37827003],
		["Windsor","NY",42.07,-75.64,37827994],
		["Chatham","VA",36.81,-79.39,37828985],
		["Tenino","WA",46.84,-122.85,37829976],
		["Dalton","MA",42.47,-73.16,37830966],
		["Mexico","NY",43.46,-76.23,37831956],
		["Ellijay","GA",34.66,-84.33,37832946],
		["Sunman","IN",39.23,-85.09,37833936],
		["Croswell","MI",43.27,-82.61,37834926],
		["Quincy","MI",41.94,-84.88,37835916],
		["Kansas City","MO",39.09,-94.58,37836906],
		["West","TX",31.8,-97.09,37837896],
		["Somerset","TX",29.22,-98.65,37838886],
		["Belmont","NH",43.44,-71.47,37839875],
		["New York","NY",40.71,-73.99,37840864],
		["Pike Road","AL",32.3,-86.08,37841853],
		["Seville","OH",41.02,-81.86,37842842],
		["Montevideo","MN",44.95,-95.71,37843831],
		["Hamburg","AR",33.22,-91.79,37844820],
		["East Rochester","NY",43.11,-77.48,37845808],
		["Walkertown","NC",36.17,-80.15,37846796],
		["Seven Springs","NC",35.22,-77.84,37847784],
		["Oakland","TN",35.22,-89.51,37848772],
		["Rossford","OH",41.6,-83.56,37849760],
		["Annandale","MN",45.26,-94.11,37850748],
		["Sidney","NE",41.13,-102.97,37851736],
		["Denver","CO",39.76,-104.87,37852724],
		["Wellsville","UT",41.62,-111.93,37853712],
		["Santa Fe","NM",35.67,-105.95,37854700],
		["Burton","OH",41.47,-81.14,37855687],
		["Lake City","MI",44.33,-85.2,37856674],
		["Greenwood","DE",38.8,-75.59,37857660],
		["Denver","CO",39.76,-104.87,37858646],
		["Columbus","GA",32.51,-84.87,37859631],
		["Frankenmuth","MI",43.33,-83.74,37860616],
		["Morrison","IL",41.8,-89.96,37861601],
		["Walnut Ridge","AR",36.06,-90.95,37862586],
		["Fort Irwin","CA",35.3,-116.74,37863571],
		["Solvang","CA",34.61,-120.14,37864556],
		["Atlanta","GA",33.86,-84.47,37865540],
		["Hart","MI",43.69,-86.36,37866524],
		["Versailles","MO",38.43,-92.84,37867508],
		["Phoenix","AZ",33.44,-112.07,37868492],
		["Berwick","ME",43.26,-70.86,37869475],
		["Emerson","NJ",40.97,-74.02,37870458],
		["Millstone Township","NJ",40.19,-74.42,37871441],
		["Ford City","PA",40.76,-79.53,37872424],
		["Pinconning","MI",43.85,-83.96,37873407],
		["Lawson","MO",39.43,-94.21,37874390],
		["Jena","LA",31.68,-92.12,37875373],
		["Cleveland","OK",36.29,-96.46,37876356],
		["Philipsburg","PA",40.89,-78.21,37877338],
		["Fort Oglethorpe","GA",34.92,-85.25,37878320],
		["Saint Petersburg","FL",27.75,-82.64,37879302],
		["Minneapolis","MN",44.96,-93.26,37880284],
		["Valley City","ND",46.92,-98,37881266],
		["Carlyle","IL",38.61,-89.37,37882248],
		["New Orleans","LA",30.06,-89.93,37883230],
		["Ligonier","PA",40.24,-79.23,37884211],
		["Fayetteville","WV",38.05,-81.1,37885192],
		["Butner","NC",36.13,-78.77,37886173],
		["Oak Grove","KY",36.66,-87.42,37887154],
		["Schoolcraft","MI",42.11,-85.63,37888135],
		["Ironwood","MI",46.45,-90.15,37889116],
		["Highland Lakes","NJ",41.17,-74.44,37890096],
		["Elmsford","NY",41.05,-73.81,37891076],
		["West Portsmouth","OH",38.76,-83.03,37892056],
		["Grandview","TX",32.26,-97.17,37893036],
		["New Castle","CO",39.58,-107.53,37894016],
		["Oakhurst","CA",37.4,-119.61,37894996],
		["Boston","MA",42.35,-71.06,37895975],
		["Buxton","ME",43.66,-70.6,37896954],
		["Phoenix","NY",43.23,-76.29,37897933],
		["Chadbourn","NC",34.32,-78.82,37898912],
		["Brookline","MO",37.12,-93.39,37899891],
		["Landing","NJ",40.9,-74.66,37900869],
		["Hartsdale","NY",41.02,-73.8,37901847],
		["Elmira","NY",42.08,-76.8,37902825],
		["Homosassa","FL",28.78,-82.61,37903803],
		["Etowah","TN",35.33,-84.52,37904781],
		["Cokato","MN",45.07,-94.18,37905759],
		["Bennington","NE",41.36,-96.15,37906737],
		["Stratford","NJ",39.82,-75.01,37907714],
		["Falkville","AL",34.37,-86.9,37908691],
		["Belzoni","MS",33.17,-90.48,37909668],
		["Adel","IA",41.61,-94.02,37910645],
		["Long Prairie","MN",45.97,-94.86,37911622],
		["Marshall","IL",39.39,-87.69,37912599],
		["Concordia","KS",39.57,-97.66,37913576],
		["San Martin","CA",37.1,-121.6,37914553],
		["Chesaning","MI",43.18,-84.11,37915529],
		["Marlin","TX",31.3,-96.89,37916505],
		["Progreso","TX",26.09,-97.96,37917481],
		["Marstons Mills","MA",41.67,-70.4,37918456],
		["East Taunton","MA",41.87,-71.01,37919431],
		["Cleveland","OH",41.47,-81.67,37920406],
		["Delhi","LA",32.45,-91.48,37921381],
		["Winthrop Harbor","IL",42.47,-87.82,37922355],
		["New Roads","LA",30.69,-91.45,37923329],
		["San Joaquin","CA",36.59,-120.19,37924303],
		["Winlock","WA",46.48,-122.87,37925277],
		["Imperial","PA",40.45,-80.25,37926250],
		["Minneapolis","MN",44.96,-93.26,37927223],
		["Winfield","MO",38.99,-90.73,37928196],
		["Newcastle","OK",35.24,-97.59,37929169],
		["Cisco","TX",32.38,-98.98,37930142],
		["Houston","TX",29.76,-95.38,37931115],
		["Santa Teresa","NM",31.87,-106.64,37932088],
		["Camden","NY",43.33,-75.74,37933060],
		["Freedom","PA",40.68,-80.25,37934032],
		["Archbald","PA",41.5,-75.53,37935004],
		["Chapel Hill","TN",35.62,-86.69,37935976],
		["East Jordan","MI",45.15,-85.13,37936948],
		["Ronan","MT",47.53,-114.13,37937920],
		["Pevely","MO",38.28,-90.39,37938892],
		["Winston","OR",43.05,-123.5,37939864],
		["Zephyrhills","FL",28.23,-82.17,37940835],
		["Marengo","OH",40.4,-82.81,37941806],
		["Coldwater","OH",40.48,-84.63,37942777],
		["Bronson","MI",41.87,-85.19,37943748],
		["Dodgeville","WI",42.96,-90.13,37944719],
		["Bloomer","WI",45.1,-91.49,37945690],
		["Denver","CO",39.76,-104.87,37946661],
		["Silver Springs","NV",39.37,-119.26,37947632],
		["Los Angeles","CA",34.13,-118.33,37948603],
		["Mendon","MA",42.1,-71.55,37949573],
		["Sharpsville","PA",41.25,-80.48,37950543],
		["Bunnell","FL",29.46,-81.25,37951513],
		["Rockville","IN",39.76,-87.23,37952483],
		["Red Oak","IA",41.01,-95.22,37953453],
		["Silvis","IL",41.5,-90.41,37954423],
		["Winnie","TX",29.81,-94.38,37955393],
		["Gunnison","CO",38.54,-106.92,37956363],
		["Fruitland","NM",36.73,-108.39,37957333],
		["Gambier","OH",40.37,-82.39,37958302],
		["Nashport","OH",40.04,-82.12,37959271],
		["Kingfisher","OK",35.84,-97.93,37960240],
		["Spicewood","TX",30.48,-98.16,37961209],
		["Planada","CA",37.32,-120.3,37962178],
		["Ramseur","NC",35.73,-79.65,37963146],
		["Nebo","NC",35.71,-81.93,37964114],
		["Leslie","MI",42.45,-84.43,37965082],
		["Marshall","WI",43.17,-89.06,37966050],
		["Sidney","MT",47.75,-104.28,37967018],
		["Oakland","ME",44.55,-69.71,37967985],
		["Street","MD",39.65,-76.34,37968952],
		["Roanoke","VA",37.27,-79.95,37969919],
		["Pembroke","GA",32.13,-81.62,37970886],
		["Yale","MI",43.12,-82.79,37971853],
		["Austin","AR",34.99,-91.98,37972820],
		["Atkins","AR",35.24,-92.94,37973787],
		["Brady","TX",31.13,-99.37,37974754],
		["Moscow","ID",46.72,-116.99,37975721],
		["Wickenburg","AZ",33.9,-112.9,37976688],
		["Center Moriches","NY",40.79,-72.79,37977654],
		["Fort Lauderdale","FL",26.14,-80.13,37978620],
		["Taft","TX",27.98,-97.39,37979586],
		["Grafton","MA",42.2,-71.68,37980551],
		["Cos Cob","CT",41.06,-73.59,37981516],
		["Woodcliff Lake","NJ",41.02,-74.05,37982481],
		["Everett","PA",40.01,-78.36,37983446],
		["Crystal River","FL",28.9,-82.59,37984411],
		["Nyssa","OR",43.73,-117.15,37985376],
		["Springfield","MA",42.11,-72.53,37986340],
		["Groveland","MA",42.75,-71.03,37987304],
		["Nantucket","MA",41.27,-70.1,37988268],
		["Great Neck","NY",40.8,-73.73,37989232],
		["Manchester","PA",40.06,-76.72,37990196],
		["Walnutport","PA",40.75,-75.59,37991160],
		["Charleston","SC",32.78,-79.99,37992124],
		["Colbert","GA",34.03,-83.21,37993088],
		["Columbiaville","MI",43.15,-83.4,37994052],
		["Montague","MI",43.41,-86.35,37995016],
		["Jefferson","TX",32.76,-94.34,37995980],
		["Kamas","UT",40.62,-111.15,37996944],
		["Malverne","NY",40.67,-73.67,37997907],
		["Trafford","PA",40.38,-79.75,37998870],
		["Oscoda","MI",44.42,-83.33,37999833],
		["Springfield","MO",37.19,-93.28,38000796],
		["Nashville","TN",36.17,-86.78,38001758],
		["West Milton","OH",39.95,-84.32,38002720],
		["Franksville","WI",42.79,-87.99,38003682],
		["Harrisburg","AR",35.56,-90.72,38004644],
		["Durango","CO",37.14,-107.88,38005606],
		["Peru","NY",44.58,-73.53,38006567],
		["Burgettstown","PA",40.38,-80.39,38007528],
		["Wellsburg","WV",40.28,-80.6,38008489],
		["Killingworth","CT",41.35,-72.56,38009449],
		["Mount Pleasant","NC",35.4,-80.43,38010409],
		["Vero Beach","FL",27.64,-80.39,38011369],
		["Ellenton","FL",27.52,-82.52,38012329],
		["Carlisle","KY",38.31,-84.03,38013289],
		["Cicero","IN",40.12,-86.02,38014249],
		["Brighton","IL",39.04,-90.13,38015209],
		["Grand Saline","TX",32.67,-95.71,38016169],
		["Lyons","NY",43.06,-76.99,38017128],
		["Lincoln","DE",38.86,-75.36,38018087],
		["Nitro","WV",38.41,-81.83,38019046],
		["Parma","MI",42.25,-84.59,38020005],
		["Locust Valley","NY",40.88,-73.58,38020963],
		["Scottdale","PA",40.1,-79.58,38021921],
		["Ninety Six","SC",34.17,-82.02,38022879],
		["Whitesburg","KY",37.11,-82.82,38023837],
		["Hemlock","MI",43.41,-84.23,38024795],
		["Fruitport","MI",43.12,-86.15,38025753],
		["Bryans Road","MD",38.63,-77.07,38026710],
		["Fort Myers","FL",26.57,-81.82,38027667],
		["Collinsville","MS",32.49,-88.84,38028624],
		["West Harrison","IN",39.26,-84.82,38029581],
		["Wadena","MN",46.43,-95.13,38030538],
		["Leadville","CO",39.24,-106.29,38031495],
		["Cuba","NM",36.02,-106.95,38032452],
		["Oak Hill","OH",38.89,-82.56,38033408],
		["Austin","TX",30.26,-97.74,38034364],
		["Mifflintown","PA",40.57,-77.39,38035319],
		["Solon","IA",41.8,-91.49,38036274],
		["Clinton","AR",35.58,-92.45,38037229],
		["Paxton","MA",42.31,-71.93,38038183],
		["Atlantic Highlands","NJ",40.4,-74.03,38039137],
		["Homer","NY",42.63,-76.18,38040091],
		["Dundee","NY",42.52,-76.97,38041045],
		["Williston","SC",33.4,-81.42,38041999],
		["Flemingsburg","KY",38.42,-83.73,38042953],
		["Okemah","OK",35.42,-96.29,38043907],
		["Dadeville","AL",32.83,-85.76,38044860],
		["Quitman","MS",32.04,-88.72,38045813],
		["West Columbia","TX",29.14,-95.64,38046766],
		["Cardiff By The Sea","CA",33.01,-117.27,38047719],
		["Moxee","WA",46.52,-120.2,38048672],
		["Exeter","RI",41.56,-71.68,38049624],
		["Oakhurst","NJ",40.26,-74.02,38050576],
		["White Plains","NY",41.02,-73.75,38051528],
		["Leesport","PA",40.44,-75.96,38052480],
		["Cape Girardeau","MO",37.3,-89.55,38053432],
		["Lucerne Valley","CA",34.52,-116.88,38054384],
		["Finleyville","PA",40.25,-80,38055335],
		["Flatwoods","KY",38.51,-82.72,38056286],
		["Mt Zion","IL",39.77,-88.87,38057237],
		["Lake Providence","LA",32.81,-91.18,38058188],
		["Midland Park","NJ",40.99,-74.14,38059138],
		["Baden","PA",40.63,-80.22,38060088],
		["Sterling","VA",39,-77.4,38061038],
		["Columbus Grove","OH",40.91,-84.05,38061988],
		["Gilford","NH",43.53,-71.38,38062937],
		["Orono","ME",44.88,-68.68,38063886],
		["Coplay","PA",40.67,-75.49,38064835],
		["Pink Hill","NC",35.05,-77.74,38065784],
		["Spring Lake","NJ",40.15,-74.02,38066732],
		["Erie","PA",42.12,-80.08,38067680],
		["Lakeland","GA",31.04,-83.07,38068628],
		["Indianapolis","IN",39.77,-86.14,38069576],
		["Shallowater","TX",33.69,-101.99,38070524],
		["Seabrook","NH",42.89,-70.87,38071471],
		["Roseboro","NC",34.95,-78.51,38072418],
		["Saint Joseph","IL",40.14,-88.06,38073365],
		["Pinckneyville","IL",38.07,-89.38,38074312],
		["Belfast","ME",44.42,-69.02,38075258],
		["Valatie","NY",42.41,-73.67,38076204],
		["Myakka City","FL",27.35,-82.16,38077150],
		["Leland","MS",33.4,-90.89,38078096],
		["Spring Arbor","MI",42.2,-84.55,38079042],
		["Pardeeville","WI",43.53,-89.3,38079988],
		["Watseka","IL",40.77,-87.73,38080934],
		["San Jose","CA",37.21,-121.75,38081880],
		["Saunderstown","RI",41.51,-71.44,38082825],
		["Hollywood","SC",32.75,-80.2,38083770],
		["Meridianville","AL",34.87,-86.57,38084715],
		["Burton","MI",42.99,-83.61,38085660],
		["Auburn","MI",43.6,-84.07,38086605],
		["Corunna","MI",42.98,-84.11,38087550],
		["Hattiesburg","MS",31.31,-89.3,38088494],
		["Attica","IN",40.28,-87.24,38089438],
		["Mayville","WI",43.49,-88.54,38090382],
		["Oconto","WI",44.89,-87.86,38091326],
		["Bowling Green","MO",39.33,-91.19,38092270],
		["Lockport","LA",29.64,-90.53,38093214],
		["Thoreau","NM",35.4,-108.22,38094158],
		["Portland","OR",45.48,-122.69,38095102],
		["Granby","MA",42.26,-72.52,38096045],
		["Eads","TN",35.2,-89.66,38096988],
		["Barnesville","OH",39.98,-81.17,38097931],
		["Topeka","KS",39.03,-95.69,38098874],
		["Mountain View","AR",35.87,-92.11,38099817],
		["Rumford","RI",41.84,-71.35,38100759],
		["Saint Augustine","FL",29.89,-81.31,38101701],
		["Winnebago","IL",42.26,-89.24,38102643],
		["Renton","WA",47.48,-122.19,38103585],
		["Greenville","RI",41.87,-71.55,38104526],
		["Raritan","NJ",40.57,-74.64,38105467],
		["Newark","DE",39.67,-75.75,38106408],
		["Glendale","CA",34.18,-118.25,38107349],
		["Saint Paris","OH",40.12,-83.95,38108289],
		["Kermit","TX",31.85,-103.09,38109229],
		["San Carlos","AZ",33.41,-110.11,38110169],
		["Delta","PA",39.72,-76.32,38111108],
		["Morgantown","PA",40.15,-75.9,38112047],
		["Foxworth","MS",31.27,-89.95,38112986],
		["Sergeant Bluff","IA",42.4,-96.35,38113925],
		["Lebanon","IL",38.6,-89.81,38114864],
		["Lawrenceville","IL",38.72,-87.68,38115803],
		["Haddon Heights","NJ",39.87,-75.06,38116741],
		["Cedar Bluff","VA",37.08,-81.76,38117679],
		["White","GA",34.28,-84.74,38118617],
		["Mascotte","FL",28.57,-81.88,38119555],
		["Jonesville","MI",41.98,-84.66,38120493],
		["Kiel","WI",43.91,-88.03,38121431],
		["Wichita","KS",37.68,-97.34,38122369],
		["Dumas","AR",33.88,-91.48,38123307],
		["Stow","MA",42.43,-71.5,38124244],
		["Ringoes","NJ",40.44,-74.82,38125181],
		["Marco Island","FL",25.94,-81.73,38126118],
		["Holden","MO",38.71,-93.98,38127055],
		["Belford","NJ",40.41,-74.08,38127991],
		["Melbourne Beach","FL",28.06,-80.55,38128927],
		["Punta Gorda","FL",26.89,-82.05,38129863],
		["Anderson","MO",36.65,-94.44,38130799],
		["Holden","LA",30.58,-90.67,38131735],
		["Mabank","TX",32.36,-96.1,38132671],
		["East Palestine","OH",40.83,-80.54,38133606],
		["Petersburg","MI",41.89,-83.71,38134541],
		["Pequot Lakes","MN",46.6,-94.32,38135476],
		["Scott City","MO",37.22,-89.53,38136411],
		["Osawatomie","KS",38.5,-94.95,38137346],
		["Kinder","LA",30.48,-92.84,38138281],
		["Kennebunkport","ME",43.35,-70.46,38139215],
		["Fanwood","NJ",40.64,-74.38,38140149],
		["Wood Ridge","NJ",40.85,-74.08,38141083],
		["Brigantine","NJ",39.41,-74.36,38142017],
		["Mount Berry","GA",34.28,-85.18,38142951],
		["Miami","FL",25.77,-80.2,38143885],
		["Brooksville","FL",28.55,-82.39,38144819],
		["Cincinnati","OH",39.09,-84.51,38145753],
		["Colona","IL",41.47,-90.34,38146687],
		["Scottsdale","AZ",33.5,-111.79,38147621],
		["North Grafton","MA",42.23,-71.69,38148554],
		["Island Park","NY",40.6,-73.64,38149487],
		["Medina","TN",35.8,-88.77,38150420],
		["Ossian","IN",40.87,-85.16,38151353],
		["Jacksonville","FL",30.33,-81.65,38152285],
		["Topeka","KS",39.03,-95.69,38153217],
		["Lubbock","TX",33.57,-101.87,38154149],
		["Athol","ID",47.96,-116.68,38155081],
		["Lawrenceville","VA",36.75,-77.85,38156012],
		["Roanoke","IN",40.96,-85.37,38156943],
		["Greentown","IN",40.47,-85.96,38157874],
		["Springfield","IL",39.78,-89.64,38158805],
		["Fort Smith","AR",35.36,-94.38,38159736],
		["Hampstead","NH",42.87,-71.18,38160666],
		["Wrightsville","PA",40.02,-76.53,38161596],
		["Ellsworth","WI",44.73,-92.47,38162526],
		["Chauvin","LA",29.44,-90.59,38163456],
		["Hobbs","NM",32.73,-103.16,38164386],
		["Santa Cruz","CA",37.03,-121.98,38165316],
		["New Hartford","CT",41.87,-72.97,38166245],
		["Lewisburg","WV",37.8,-80.43,38167174],
		["Hastings","FL",29.71,-81.5,38168103],
		["Drummonds","TN",35.46,-89.94,38169032],
		["Incline Village","NV",39.24,-119.92,38169961],
		["Elverta","CA",38.73,-121.46,38170890],
		["South Yarmouth","MA",41.67,-70.2,38171818],
		["Getzville","NY",43.02,-78.75,38172746],
		["Oreland","PA",40.11,-75.18,38173674],
		["Port Charlotte","FL",26.98,-82.1,38174602],
		["Mancelona","MI",44.9,-85.06,38175530],
		["Dexter","NM",33.19,-104.36,38176458],
		["Los Angeles","CA",33.98,-118.37,38177386],
		["Appling","GA",33.6,-82.31,38178313],
		["Collinsville","AL",34.26,-85.86,38179240],
		["Tornillo","TX",31.44,-106.08,38180167],
		["Colorado Springs","CO",38.86,-104.76,38181094],
		["Morro Bay","CA",35.38,-120.78,38182021],
		["San Francisco","CA",37.76,-122.39,38182948],
		["Fort Wainwright","AK",64.81,-147.62,38183875],
		["Charlestown","RI",41.38,-71.65,38184801],
		["Atkinson","NH",42.83,-71.14,38185727],
		["Livonia","NY",42.82,-77.66,38186653],
		["Atlanta","GA",33.78,-84.38,38187579],
		["White Bluff","TN",36.1,-87.21,38188505],
		["Newton Highlands","MA",42.31,-71.2,38189430],
		["Lincroft","NJ",40.34,-74.12,38190355],
		["Belvidere","NJ",40.82,-75.07,38191280],
		["Thorofare","NJ",39.85,-75.18,38192205],
		["Ashburn","GA",31.7,-83.65,38193130],
		["Chariton","IA",41.01,-93.3,38194055],
		["Auburn","IL",39.57,-89.74,38194980],
		["Long Lake","MN",44.98,-93.56,38195904],
		["Scott Air Force Base","IL",38.54,-89.85,38196828],
		["West Fork","AR",35.93,-94.18,38197752],
		["Salado","TX",30.95,-97.52,38198676],
		["Aspen","CO",39.19,-106.82,38199600],
		["Towaco","NJ",40.93,-74.34,38200523],
		["Stuart","VA",36.64,-80.27,38201446],
		["Crooksville","OH",39.76,-82.09,38202369],
		["Maple Plain","MN",45,-93.66,38203292],
		["Prescott Valley","AZ",34.69,-112.3,38204215],
		["Brookline","NH",42.73,-71.66,38205137],
		["Hamptonville","NC",36.11,-80.81,38206059],
		["Freeport","FL",30.5,-86.13,38206981],
		["Inverness","FL",28.83,-82.34,38207903],
		["Smiths Grove","KY",37.05,-86.2,38208825],
		["Harlan","IA",41.65,-95.32,38209747],
		["Braidwood","IL",41.26,-88.22,38210669],
		["Lebanon","ME",43.39,-70.85,38211590],
		["Broadway","NC",35.45,-79.05,38212511],
		["Mobile","AL",30.68,-88.04,38213432],
		["Crittenden","KY",38.78,-84.6,38214353],
		["Seneca","MO",36.83,-94.6,38215274],
		["Fordyce","AR",33.81,-92.41,38216195],
		["Bald Knob","AR",35.3,-91.57,38217116],
		["Somers","NY",41.33,-73.69,38218036],
		["Strasburg","PA",39.98,-76.18,38218956],
		["Chilhowie","VA",36.8,-81.68,38219876],
		["Erwin","NC",35.32,-78.67,38220796],
		["Darlington","SC",34.29,-79.87,38221716],
		["Christiana","TN",35.7,-86.34,38222636],
		["Ocilla","GA",31.59,-83.24,38223555],
		["Cataula","GA",32.66,-84.88,38224474],
		["Mc Ewen","TN",36.1,-87.63,38225393],
		["Youngstown","OH",41.09,-80.64,38226312],
		["Gobles","MI",42.36,-85.87,38227231],
		["Stockbridge","MI",42.45,-84.17,38228150],
		["Manton","MI",44.41,-85.4,38229069],
		["Pomona","NJ",39.46,-74.55,38229987],
		["Bogue Chitto","MS",31.43,-90.45,38230905],
		["Wendell","ID",42.74,-114.73,38231823],
		["Lewisberry","PA",40.13,-76.86,38232740],
		["Ocklawaha","FL",29.04,-81.92,38233657],
		["Lagrange","OH",41.23,-82.12,38234574],
		["Saint Charles","MI",43.29,-84.14,38235491],
		["Villa Ridge","MO",38.47,-90.88,38236408],
		["Somerville","MA",42.39,-71.1,38237324],
		["Wilkes Barre","PA",41.24,-75.87,38238240],
		["Satsuma","AL",30.85,-88.06,38239156],
		["Diamondhead","MS",30.38,-89.36,38240072],
		["Hallettsville","TX",29.44,-96.94,38240988],
		["Pen Argyl","PA",40.86,-75.25,38241903],
		["Delmar","MD",38.45,-75.57,38242818],
		["Romney","WV",39.34,-78.75,38243733],
		["Richwood","OH",40.42,-83.29,38244648],
		["Milton","WA",47.25,-122.31,38245563],
		["Hayesville","NC",35.04,-83.81,38246477],
		["Elkton","KY",36.81,-87.15,38247391],
		["Missoula","MT",46.89,-114.21,38248305],
		["Mountain Lakes","NJ",40.89,-74.44,38249218],
		["Thornwood","NY",41.11,-73.76,38250131],
		["Bayville","NY",40.9,-73.56,38251044],
		["Marcellus","NY",42.98,-76.34,38251957],
		["Prospect Park","PA",39.88,-75.3,38252870],
		["Morgantown","IN",39.37,-86.25,38253783],
		["Boyce","LA",31.39,-92.66,38254696],
		["Castleton","VT",43.62,-73.18,38255608],
		["Moosup","CT",41.71,-71.87,38256520],
		["Montezuma","GA",32.29,-84.02,38257432],
		["Mc Arthur","OH",39.24,-82.47,38258344],
		["Mount Vernon","TX",33.17,-95.22,38259256],
		["Jber","AK",61.31,-149.65,38260168],
		["Lebanon","NH",43.63,-72.25,38261079],
		["Ellenville","NY",41.7,-74.36,38261990],
		["Marietta","PA",40.05,-76.55,38262901],
		["Gordonsville","VA",38.13,-78.18,38263812],
		["Aylett","VA",37.77,-77.12,38264723],
		["Wellsville","OH",40.6,-80.65,38265634],
		["Shreveport","LA",32.47,-93.79,38266545],
		["Stamford","CT",41.09,-73.55,38267455],
		["Cambridge Springs","PA",41.8,-80.06,38268365],
		["Gretna","VA",36.95,-79.36,38269275],
		["Saint George","SC",33.18,-80.58,38270185],
		["Silver Creek","GA",34.13,-85.13,38271095],
		["Saint Petersburg","FL",27.75,-82.64,38272005],
		["Armada","MI",42.84,-82.88,38272915],
		["Melissa","TX",33.28,-96.57,38273825],
		["Holbrook","AZ",34.94,-110.09,38274735],
		["Boulder Creek","CA",37.16,-122.15,38275645],
		["Goldendale","WA",45.84,-120.76,38276555],
		["Grottoes","VA",38.26,-78.82,38277464],
		["Mount Hope","WV",37.89,-81.17,38278373],
		["Greenville","SC",34.83,-82.37,38279282],
		["Kent City","MI",43.21,-85.75,38280191],
		["Newman Lake","WA",47.77,-117.09,38281100],
		["Pascoag","RI",41.95,-71.7,38282008],
		["Edmonton","KY",36.98,-85.62,38282916],
		["Spiro","OK",35.24,-94.61,38283824],
		["Colorado City","TX",32.39,-100.85,38284732],
		["West Greenwich","RI",41.63,-71.65,38285639],
		["Old Forge","PA",41.37,-75.73,38286546],
		["Robbins","NC",35.43,-79.58,38287453],
		["Birmingham","AL",33.52,-86.79,38288360],
		["Gwinn","MI",46.29,-87.43,38289267],
		["Clifton","TX",31.78,-97.58,38290174],
		["Blanding","UT",37.55,-109.48,38291081],
		["Ardsley","NY",41.01,-73.84,38291987],
		["Reynoldsville","PA",41.09,-78.88,38292893],
		["Pleasant Garden","NC",35.96,-79.77,38293799],
		["Rockvale","TN",35.75,-86.55,38294705],
		["Owingsville","KY",38.13,-83.75,38295611],
		["Gas City","IN",40.48,-85.6,38296517],
		["Olivet","MI",42.44,-84.92,38297423],
		["Reed City","MI",43.87,-85.5,38298329],
		["Altoona","WI",44.8,-91.44,38299235],
		["Shelbyville","IL",39.4,-88.8,38300141],
		["Farmington","NH",43.4,-71.07,38301046],
		["Attica","NY",42.86,-78.28,38301951],
		["Salamanca","NY",42.16,-78.72,38302856],
		["Myersville","MD",39.5,-77.56,38303761],
		["Biloxi","MS",30.39,-88.88,38304666],
		["Ravenna","MI",43.18,-85.94,38305571],
		["Timberlake","NC",36.28,-78.95,38306475],
		["Saint James","MN",43.98,-94.62,38307379],
		["Perry","OK",36.28,-97.3,38308283],
		["Carrollton","VA",36.94,-76.56,38309186],
		["Ridgeway","VA",36.57,-79.85,38310089],
		["Clayton","GA",34.87,-83.4,38310992],
		["Oelwein","IA",42.67,-91.91,38311895],
		["Athens","WI",45.03,-90.07,38312798],
		["Chester","NH",42.95,-71.25,38313700],
		["Sandown","NH",42.92,-71.18,38314602],
		["Huntington","WV",38.41,-82.43,38315504],
		["Resaca","GA",34.58,-84.94,38316406],
		["Foristell","MO",38.81,-90.96,38317308],
		["New York","NY",40.71,-73.99,38318209],
		["Cornwall","NY",41.41,-74.04,38319110],
		["Dresher","PA",40.14,-75.16,38320011],
		["Chocowinity","NC",35.51,-77.09,38320912],
		["Norman Park","GA",31.26,-83.68,38321813],
		["Manchester","GA",32.85,-84.61,38322714],
		["Sisters","OR",44.41,-121.92,38323615],
		["Grant","AL",34.51,-86.25,38324515],
		["Camden","OH",39.63,-84.64,38325415],
		["Forks","WA",47.79,-124.26,38326315],
		["Boxborough","MA",42.5,-71.5,38327214],
		["Bon Aqua","TN",35.94,-87.34,38328113],
		["Rainier","OR",46.04,-122.98,38329012],
		["Quinton","VA",37.53,-77.11,38329910],
		["Moundville","AL",32.99,-87.63,38330808],
		["Northport","AL",33.24,-87.59,38331706],
		["Grabill","IN",41.21,-84.96,38332604],
		["Spirit Lake","IA",43.42,-95.11,38333502],
		["Park City","UT",40.64,-111.49,38334400],
		["Hopedale","MA",42.12,-71.54,38335297],
		["Bloomingdale","NJ",41.02,-74.33,38336194],
		["Little Silver","NJ",40.33,-74.03,38337091],
		["Groton","NY",42.58,-76.36,38337988],
		["Arlington","VA",38.87,-77.1,38338885],
		["Williamson","GA",33.18,-84.36,38339782],
		["Rochester","IL",39.75,-89.54,38340679],
		["Waialua","HI",21.57,-158.12,38341576],
		["Summertown","TN",35.43,-87.33,38342472],
		["Philpot","KY",37.7,-86.93,38343368],
		["Richfield","OH",41.23,-81.63,38344264],
		["Oneida","WI",44.49,-88.21,38345160],
		["Cache","OK",34.62,-98.61,38346056],
		["Valhalla","NY",41.07,-73.77,38346951],
		["Jefferson","MD",39.36,-77.53,38347846],
		["Tampa","FL",27.95,-82.48,38348741],
		["Aitkin","MN",46.53,-93.71,38349636],
		["Bennett","CO",39.75,-104.42,38350531],
		["Carthage","TN",36.25,-85.94,38351425],
		["Hicksville","OH",41.29,-84.76,38352319],
		["Centerville","IN",39.81,-84.99,38353213],
		["Alva","OK",36.8,-98.66,38354107],
		["Jourdanton","TX",28.91,-98.54,38355001],
		["Bristol","VT",44.13,-73.08,38355894],
		["Fort Washington","PA",40.13,-75.19,38356787],
		["Tappahannock","VA",37.92,-76.86,38357680],
		["Blackstone","VA",37.07,-78,38358573],
		["Winterville","GA",33.96,-83.28,38359466],
		["Riverton","IL",39.84,-89.54,38360359],
		["Colcord","OK",36.26,-94.69,38361252],
		["Newport","VT",44.93,-72.2,38362144],
		["Felton","PA",39.85,-76.56,38363036],
		["Allentown","PA",40.59,-75.47,38363928],
		["Nottingham","PA",39.74,-76.04,38364820],
		["Hesperia","MI",43.56,-86.04,38365712],
		["Denmark","WI",44.34,-87.82,38366604],
		["Malden","MO",36.58,-89.97,38367496],
		["Friona","TX",34.63,-102.72,38368388],
		["Olathe","CO",38.6,-107.98,38369280],
		["Boise","ID",43.6,-116.12,38370172],
		["Mount Pleasant","UT",39.55,-111.39,38371064],
		["Saranac Lake","NY",44.32,-74.13,38371955],
		["Federalsburg","MD",38.69,-75.77,38372846],
		["Kingston Springs","TN",36.09,-87.1,38373737],
		["Harvard","MA",42.5,-71.58,38374627],
		["Livingston","AL",32.59,-88.18,38375517],
		["Joelton","TN",36.33,-86.91,38376407],
		["Osceola","IA",41.03,-93.77,38377297],
		["Helena","AR",34.52,-90.6,38378187],
		["Pulaski","NY",43.56,-76.12,38379076],
		["Dawson Springs","KY",37.17,-87.68,38379965],
		["Weed","CA",41.51,-122.36,38380854],
		["Mineral Wells","WV",39.17,-81.53,38381742],
		["Greenville","SC",34.83,-82.37,38382630],
		["Little Rock","AR",34.72,-92.35,38383518],
		["Mojave","CA",35.09,-118.13,38384406],
		["Robbins","IL",41.64,-87.7,38385293],
		["Scottsdale","AZ",33.85,-111.78,38386180],
		["Tucumcari","NM",35.16,-103.72,38387067],
		["North Las Vegas","NV",36.28,-115.13,38387954],
		["Felton","CA",37.08,-122.05,38388841],
		["Belcamp","MD",39.46,-76.23,38389727],
		["Raleigh","NC",35.82,-78.65,38390612],
		["Town Creek","AL",34.67,-87.4,38391497],
		["White Pine","TN",36.09,-83.29,38392382],
		["Beattyville","KY",37.58,-83.7,38393267],
		["Liberty","IN",39.63,-84.92,38394152],
		["Ithaca","MI",43.29,-84.6,38395037],
		["Sturtevant","WI",42.7,-87.89,38395922],
		["Jasper","TN",35.05,-85.62,38396806],
		["Prentiss","MS",31.59,-89.87,38397690],
		["Chisago City","MN",45.37,-92.88,38398574],
		["Owensville","MO",38.34,-91.49,38399458],
		["Bloomfield","NY",42.87,-77.46,38400341],
		["Shenandoah","PA",40.81,-76.2,38401224],
		["Port Gibson","MS",31.95,-90.98,38402107],
		["Vinton","LA",30.19,-93.58,38402990],
		["Marlborough","CT",41.63,-72.45,38403872],
		["Landisville","PA",40.09,-76.41,38404754],
		["Folcroft","PA",39.88,-75.27,38405636],
		["West Jefferson","NC",36.39,-81.49,38406518],
		["Blountstown","FL",30.44,-85.04,38407400],
		["Pomeroy","OH",39.02,-82.03,38408282],
		["Eagle","WI",42.87,-88.47,38409164],
		["Dover","TN",36.48,-87.84,38410045],
		["Algona","IA",43.07,-94.23,38410926],
		["Caseyville","IL",38.63,-90.03,38411807],
		["Ruidoso","NM",33.36,-105.64,38412688],
		["Capistrano Beach","CA",33.45,-117.66,38413569],
		["Hauula","HI",21.59,-157.92,38414450],
		["Wilmington","DE",39.73,-75.53,38415329],
		["Estill Springs","TN",35.26,-86.13,38416208],
		["Groesbeck","TX",31.52,-96.53,38417087],
		["Church Rock","NM",35.53,-108.59,38417966],
		["Hoosick Falls","NY",42.9,-73.35,38418844],
		["Liberty","NY",41.8,-74.74,38419722],
		["Toledo","OH",41.66,-83.58,38420600],
		["Duluth","MN",46.78,-92.11,38421478],
		["Chelsea","OK",36.53,-95.43,38422356],
		["Newmarket","NH",43.07,-70.94,38423233],
		["Demarest","NJ",40.95,-73.95,38424110],
		["Princeton","NJ",40.35,-74.65,38424987],
		["Greene","NY",42.32,-75.77,38425864],
		["Saltsburg","PA",40.48,-79.44,38426741],
		["Washington","DC",38.89,-77.03,38427618],
		["Hot Springs Village","AR",34.66,-93,38428495],
		["Tonalea","AZ",36.91,-110.87,38429372],
		["Sophia","NC",35.81,-79.89,38430248],
		["Biloxi","MS",30.39,-88.88,38431124],
		["Hawkins","TX",32.59,-95.2,38432000],
		["Columbus","TX",29.7,-96.55,38432876],
		["Villa Park","CA",33.81,-117.81,38433752],
		["Peterborough","NH",42.87,-71.96,38434627],
		["Fairfield","ME",44.59,-69.6,38435502],
		["Harbor Springs","MI",45.43,-84.98,38436377],
		["Oregon","IL",42.01,-89.33,38437252],
		["Chesterfield","SC",34.73,-80.08,38438126],
		["North Judson","IN",41.21,-86.77,38439000],
		["Milan","IN",39.12,-85.13,38439874],
		["Fox River Grove","IL",42.19,-88.21,38440748],
		["Heavener","OK",34.89,-94.6,38441622],
		["East Rutherford","NJ",40.81,-74.08,38442495],
		["Ocean View","NJ",39.2,-74.71,38443368],
		["New Windsor","MD",39.54,-77.1,38444241],
		["Leipsic","OH",41.09,-83.98,38445114],
		["Forest City","IA",43.25,-93.63,38445987],
		["Prescott","WI",44.75,-92.79,38446860],
		["Minneapolis","MN",44.96,-93.26,38447733],
		["Wewoka","OK",35.14,-96.49,38448606],
		["Santa Fe","TX",29.38,-95.09,38449479],
		["Natalia","TX",29.19,-98.85,38450352],
		["Vergennes","VT",44.16,-73.25,38451224],
		["Bellevue","MI",42.44,-85.01,38452096],
		["Coldspring","TX",30.59,-95.12,38452968],
		["Henderson","NV",35.88,-115.04,38453840],
		["Mount Carmel","PA",40.79,-76.41,38454711],
		["Ohatchee","AL",33.78,-86.01,38455582],
		["Stanton","KY",37.84,-83.85,38456453],
		["Middletown","IN",40.05,-85.54,38457324],
		["New Hudson","MI",42.5,-83.61,38458195],
		["Watervliet","MI",42.18,-86.25,38459066],
		["Sheldon","IA",43.17,-95.84,38459937],
		["Kalona","IA",41.48,-91.7,38460808],
		["Chester","IL",37.91,-89.82,38461679],
		["Glen Gardner","NJ",40.69,-74.94,38462549],
		["Watsontown","PA",41.08,-76.86,38463419],
		["Elberta","AL",30.41,-87.6,38464289],
		["Carey","OH",40.95,-83.38,38465159],
		["Staunton","IL",39.01,-89.78,38466029],
		["Fair Grove","MO",37.38,-93.15,38466899],
		["Monrovia","MD",39.35,-77.25,38467768],
		["Colonial Beach","VA",38.25,-76.97,38468637],
		["Pinnacle","NC",36.33,-80.4,38469506],
		["Millers Creek","NC",36.18,-81.23,38470375],
		["Utica","OH",40.23,-82.44,38471244],
		["Stanton","MI",43.29,-85.07,38472113],
		["Milliken","CO",40.31,-104.85,38472982],
		["Parachute","CO",39.44,-108.05,38473851],
		["Sacaton","AZ",33.08,-111.74,38474720],
		["West Bridgewater","MA",42.01,-71,38475588],
		["Pittstown","NJ",40.57,-74.96,38476456],
		["Trumansburg","NY",42.54,-76.66,38477324],
		["Nashville","IN",39.2,-86.23,38478192],
		["Loranger","LA",30.57,-90.34,38479060],
		["Phoenix","AZ",33.43,-112.01,38479928],
		["Mountville","PA",40.04,-76.43,38480795],
		["Westville","OK",35.98,-94.57,38481662],
		["Ely","NV",39.42,-115,38482529],
		["Mills River","NC",35.39,-82.57,38483395],
		["Naples","FL",26.14,-81.79,38484261],
		["Windsor","VA",36.8,-76.73,38485126],
		["New London","NC",35.44,-80.21,38485991],
		["Delray Beach","FL",26.45,-80.08,38486856],
		["Chelan","WA",47.89,-119.99,38487721],
		["Cleveland","NC",35.73,-80.67,38488585],
		["Evart","MI",43.9,-85.27,38489449],
		["Lake City","MN",44.44,-92.27,38490313],
		["Rice","MN",45.74,-94.22,38491177],
		["Butler","MO",38.25,-94.33,38492041],
		["Jones","OK",35.56,-97.29,38492905],
		["Buffalo","WY",44.34,-106.71,38493769],
		["Newcastle","CA",38.87,-121.15,38494633],
		["Brewster","WA",48.15,-119.82,38495497],
		["Bethany","CT",41.42,-72.99,38496360],
		["Branchville","NJ",41.14,-74.74,38497223],
		["Derry","PA",40.33,-79.3,38498086],
		["Oakland","MI",42.77,-83.16,38498949],
		["Hillsboro","IL",39.16,-89.48,38499812],
		["Carmi","IL",38.08,-88.17,38500675],
		["Berkeley","CA",37.9,-122.26,38501538],
		["Mount Wolf","PA",40.06,-76.7,38502400],
		["Wyoming","PA",41.3,-75.84,38503262],
		["Soperton","GA",32.37,-82.59,38504124],
		["Desert Hot Springs","CA",33.83,-116.29,38504986],
		["Fairfax","CA",37.98,-122.6,38505848],
		["Capitola","CA",36.97,-121.95,38506710],
		["Great Mills","MD",38.25,-76.49,38507571],
		["Savannah","GA",32.08,-81.1,38508432],
		["Ellaville","GA",32.23,-84.3,38509293],
		["Adamsville","TN",35.23,-88.38,38510154],
		["Clay City","KY",37.86,-83.92,38511015],
		["Elm Grove","WI",43.04,-88.08,38511876],
		["Wautoma","WI",44.06,-89.29,38512737],
		["Clatskanie","OR",46.06,-123.27,38513598],
		["Ocean City","MD",38.33,-75.08,38514458],
		["Alexandria","VA",38.82,-77.08,38515318],
		["Wilberforce","OH",39.71,-83.88,38516178],
		["Breese","IL",38.61,-89.52,38517038],
		["Athens","TX",32.2,-95.85,38517898],
		["Dimmitt","TX",34.54,-102.31,38518758],
		["Childress","TX",34.42,-100.24,38519618],
		["Casa Grande","AZ",32.86,-111.83,38520478],
		["Calimesa","CA",33.97,-117.05,38521338],
		["Norwood","NJ",40.99,-73.95,38522197],
		["Old Fort","NC",35.63,-82.17,38523056],
		["Danville","IL",40.14,-87.61,38523915],
		["Mountain View","MO",36.99,-91.69,38524774],
		["Oklahoma City","OK",35.46,-97.51,38525633],
		["Hoopeston","IL",40.46,-87.67,38526491],
		["Alfred","ME",43.47,-70.71,38527348],
		["Axton","VA",36.66,-79.71,38528205],
		["Troy","AL",31.8,-85.96,38529062],
		["Guntown","MS",34.44,-88.66,38529919],
		["Newton","MS",32.31,-89.15,38530776],
		["Crandall","TX",32.63,-96.45,38531633],
		["Barre","MA",42.42,-72.1,38532489],
		["Collinsville","VA",36.72,-79.91,38533345],
		["Fort Leavenworth","KS",39.36,-94.91,38534201],
		["Saint Marys","KS",39.19,-96.06,38535057],
		["La Joya","TX",26.24,-98.47,38535913],
		["Jefferson","OR",44.75,-123.04,38536769],
		["Watchung","NJ",40.64,-74.44,38537624],
		["Curtis Bay","MD",39.21,-76.55,38538479],
		["Meadowview","VA",36.76,-81.85,38539334],
		["Hudson","MI",41.85,-84.34,38540189],
		["North Weymouth","MA",42.2,-70.94,38541043],
		["Dayville","CT",41.85,-71.84,38541897],
		["Derby","NY",42.68,-78.99,38542751],
		["Lothian","MD",38.8,-76.65,38543605],
		["Winona Lake","IN",41.21,-85.81,38544459],
		["Selkirk","NY",42.53,-73.8,38545312],
		["Millbrook","NY",41.78,-73.69,38546165],
		["Miami","FL",25.77,-80.2,38547018],
		["Waynesboro","TN",35.32,-87.75,38547871],
		["Sioux City","IA",42.5,-96.39,38548724],
		["Saint Paul","MN",44.94,-93.1,38549577],
		["Melrose","MN",45.67,-94.81,38550430],
		["Greenville","SC",34.83,-82.37,38551282],
		["Mc Rae","GA",32.06,-82.9,38552134],
		["Gurley","AL",34.7,-86.37,38552986],
		["Rison","AR",33.95,-92.19,38553838],
		["Estes Park","CO",40.37,-105.52,38554690],
		["Furlong","PA",40.29,-75.05,38555541],
		["Bargersville","IN",39.51,-86.16,38556392],
		["Hancock","MI",47.13,-88.6,38557243],
		["Redwood Falls","MN",44.54,-95.11,38558094],
		["Gilberts","IL",42.1,-88.36,38558945],
		["Flora","IL",38.67,-88.47,38559796],
		["Paradise","TX",33.08,-97.7,38560647],
		["Mohave Valley","AZ",34.86,-114.51,38561498],
		["Battle Mountain","NV",40.47,-117.03,38562349],
		["Kalama","WA",46.01,-122.7,38563200],
		["Jericho","VT",44.5,-72.98,38564050],
		["Greenwich","NY",43.08,-73.49,38564900],
		["Mount Shasta","CA",41.31,-122.32,38565750],
		["Westbrook","CT",41.28,-72.45,38566599],
		["Bishop","GA",33.81,-83.43,38567448],
		["Lake Alfred","FL",28.09,-81.72,38568297],
		["Gadsden","AL",34.01,-86.01,38569146],
		["Baxter Springs","KS",37.02,-94.73,38569995],
		["Abbeville","AL",31.56,-85.25,38570843],
		["Shannon","MS",34.11,-88.69,38571691],
		["New Orleans","LA",30.06,-89.93,38572539],
		["Sunnyvale","TX",32.79,-96.55,38573387],
		["Watertown","TN",36.09,-86.13,38574234],
		["Orleans","IN",38.66,-86.45,38575081],
		["China Spring","TX",31.67,-97.31,38575928],
		["Carlisle","MA",42.53,-71.35,38576774],
		["Newfane","NY",43.28,-78.69,38577620],
		["Reinholds","PA",40.27,-76.11,38578466],
		["Hughesville","MD",38.53,-76.78,38579312],
		["Versailles","OH",40.22,-84.48,38580158],
		["Cass City","MI",43.6,-83.17,38581004],
		["Lake Odessa","MI",42.78,-85.13,38581850],
		["Decatur","MI",42.1,-85.97,38582696],
		["Kingston","OK",34,-96.72,38583542],
		["Salt Lake City","UT",40.76,-111.84,38584388],
		["West Simsbury","CT",41.87,-72.84,38585233],
		["Broadalbin","NY",43.05,-74.19,38586078],
		["Wayne","WV",38.22,-82.44,38586923],
		["Wewahitchka","FL",30.11,-85.19,38587768],
		["Flora","MS",32.54,-90.31,38588613],
		["Watertown","MN",44.96,-93.84,38589458],
		["Tuscola","IL",39.79,-88.28,38590303],
		["Lincoln","AR",35.94,-94.42,38591148],
		["Filer","ID",42.56,-114.61,38591993],
		["Millersburg","PA",40.54,-76.95,38592837],
		["Graceville","FL",30.96,-85.51,38593681],
		["Eagle Butte","SD",45,-101.23,38594525],
		["Holdrege","NE",40.44,-99.37,38595369],
		["Bourg","LA",29.55,-90.61,38596213],
		["Lindsay","OK",34.83,-97.6,38597057],
		["Pottsboro","TX",33.77,-96.67,38597901],
		["New Waverly","TX",30.53,-95.48,38598745],
		["Mabton","WA",46.15,-120.09,38599589],
		["Avon","NY",42.91,-77.74,38600432],
		["Coats","NC",35.4,-78.66,38601275],
		["Lavonia","GA",34.43,-83.1,38602118],
		["Roseau","MN",48.84,-95.76,38602961],
		["Kansas City","MO",39.09,-94.58,38603804],
		["San Antonio","PR",18.49,-67.09,38604646],
		["Lincoln","ME",45.36,-68.5,38605488],
		["Mountainside","NJ",40.68,-74.36,38606330],
		["New Tripoli","PA",40.65,-75.74,38607172],
		["Toano","VA",37.37,-76.8,38608014],
		["Midland","NC",35.22,-80.5,38608856],
		["Ashford","AL",31.18,-85.23,38609698],
		["Midland City","AL",31.31,-85.49,38610540],
		["Baxter","TN",36.15,-85.63,38611382],
		["Red Bud","IL",38.21,-89.99,38612224],
		["Pierre Part","LA",29.95,-91.2,38613066],
		["Lincoln City","OR",44.94,-123.94,38613908],
		["Sturbridge","MA",42.09,-72.06,38614749],
		["Freeville","NY",42.51,-76.34,38615590],
		["Pittsburgh","PA",40.43,-79.97,38616431],
		["Bell Buckle","TN",35.59,-86.35,38617272],
		["Carlisle","IA",41.51,-93.48,38618113],
		["Knob Noster","MO",38.76,-93.56,38618954],
		["Royal City","WA",46.9,-119.66,38619795],
		["Corinth","NY",43.24,-73.83,38620635],
		["Monessen","PA",40.15,-79.88,38621475],
		["Saltville","VA",36.87,-81.76,38622315],
		["Utica","KY",37.61,-87.07,38623155],
		["Farwell","MI",43.83,-84.86,38623995],
		["Dittmer","MO",38.33,-90.68,38624835],
		["Hubbard","OR",45.18,-122.79,38625675],
		["West Boylston","MA",42.36,-71.78,38626514],
		["Litchfield","CT",41.74,-73.19,38627353],
		["Rose Hill","NC",34.82,-78.02,38628192],
		["Gordon","GA",32.88,-83.33,38629031],
		["Mechanicsburg","OH",40.07,-83.55,38629870],
		["Paulding","OH",41.13,-84.58,38630709],
		["Attica","MI",43.03,-83.16,38631548],
		["Bristol","WI",42.54,-88.02,38632387],
		["Ayer","MA",42.56,-71.58,38633225],
		["Jesup","GA",31.59,-81.88,38634063],
		["Wolf Point","MT",48.28,-105.66,38634901],
		["Bono","AR",35.91,-90.79,38635739],
		["Pittsburgh","PA",40.43,-79.97,38636576],
		["Floyd","VA",36.91,-80.31,38637413],
		["Charlotte","NC",35.19,-80.83,38638250],
		["Catawba","NC",35.7,-81.07,38639087],
		["Trenton","SC",33.74,-81.84,38639924],
		["Sand Lake","MI",43.29,-85.51,38640761],
		["Baldwin","WI",44.96,-92.37,38641598],
		["Turner","ME",44.25,-70.25,38642434],
		["Newfield","NJ",39.54,-75.01,38643270],
		["Rougemont","NC",36.22,-78.93,38644106],
		["West Blocton","AL",33.11,-87.12,38644942],
		["Comfort","TX",29.97,-98.9,38645778],
		["Sedona","AZ",34.94,-111.94,38646614],
		["West Wendover","NV",40.87,-114.15,38647450],
		["Rancho Cordova","CA",38.57,-121.17,38648286],
		["Tully","NY",42.79,-76.1,38649121],
		["Follansbee","WV",40.33,-80.59,38649956],
		["Steelville","MO",37.96,-91.35,38650791],
		["Pasadena","CA",34.14,-118.16,38651626],
		["Waikoloa","HI",19.93,-155.79,38652461],
		["New Lebanon","OH",39.74,-84.4,38653295],
		["Keene","TX",32.39,-97.33,38654129],
		["Gray","ME",43.88,-70.33,38654962],
		["Grover","NC",35.17,-81.45,38655795],
		["Richland","MO",37.86,-92.39,38656628],
		["Fouke","AR",33.26,-93.88,38657461],
		["Emory","TX",32.87,-95.76,38658294],
		["Alpine","TX",30.36,-103.66,38659127],
		["Orangeburg","NY",41.05,-73.94,38659959],
		["North Tazewell","VA",37.16,-81.5,38660791],
		["Edgefield","SC",33.78,-81.93,38661623],
		["Defuniak Springs","FL",30.74,-86.19,38662455],
		["Alcoa","TN",35.79,-83.97,38663287],
		["Waveland","MS",30.28,-89.38,38664119],
		["Monticello","IA",42.23,-91.19,38664951],
		["Terrell","TX",32.73,-96.29,38665783],
		["San Augustine","TX",31.52,-94.11,38666615],
		["Strasburg","CO",39.73,-104.3,38667447],
		["Friday Harbor","WA",48.53,-123.06,38668279],
		["Mckeesport","PA",40.33,-79.83,38669110],
		["Covington","KY",39.08,-84.5,38669941],
		["Kansas City","MO",39.09,-94.58,38670772],
		["Big Sandy","TX",32.58,-95.11,38671603],
		["Ivanhoe","CA",36.38,-119.22,38672434],
		["Portola Valley","CA",37.37,-122.21,38673265],
		["Moorefield","WV",39.06,-78.96,38674095],
		["Grantville","GA",33.23,-84.82,38674925],
		["Zolfo Springs","FL",27.49,-81.79,38675755],
		["Davenport","FL",28.16,-81.6,38676585],
		["Dayton","OH",39.77,-84.19,38677415],
		["Angie","LA",30.96,-89.81,38678245],
		["Taylor","AZ",34.43,-110.06,38679075],
		["Smithfield","PA",39.8,-79.8,38679904],
		["Fort Mc Coy","FL",29.34,-81.85,38680733],
		["Cottontown","TN",36.48,-86.59,38681562],
		["Greenwich","OH",41.03,-82.51,38682391],
		["Montgomery","IN",38.66,-87.04,38683220],
		["Petersburg","IL",40.01,-89.85,38684049],
		["Brookfield","MO",39.78,-93.07,38684878],
		["Salida","CO",38.53,-106,38685707],
		["New London","NH",43.41,-71.98,38686535],
		["Sabattus","ME",44.11,-70.1,38687363],
		["Little Neck","NY",40.76,-73.74,38688191],
		["Marlboro","NY",41.6,-73.97,38689019],
		["Munfordville","KY",37.28,-85.89,38689847],
		["New Concord","OH",39.99,-81.73,38690675],
		["Huntington Woods","MI",42.48,-83.16,38691503],
		["Neillsville","WI",44.56,-90.59,38692331],
		["Williamsburg","MI",44.76,-85.4,38693158],
		["Hiawatha","IA",42.04,-91.68,38693985],
		["Oklahoma City","OK",35.46,-97.51,38694812],
		["Delmar","DE",38.46,-75.57,38695638],
		["Washington","WV",39.24,-81.67,38696464],
		["Bean Station","TN",36.34,-83.28,38697290],
		["Mount Olive","MS",31.75,-89.65,38698116],
		["South Bend","IN",41.67,-86.26,38698942],
		["Cresco","IA",43.37,-92.11,38699768],
		["Sperry","OK",36.29,-95.98,38700594],
		["Marion","TX",29.57,-98.14,38701420],
		["Homer City","PA",40.53,-79.15,38702245],
		["Boynton Beach","FL",26.49,-80.19,38703070],
		["Ocala","FL",29.18,-82.13,38703895],
		["Clinton","MI",42.06,-83.97,38704720],
		["Ladysmith","WI",45.46,-91.09,38705545],
		["Sagle","ID",48.18,-116.56,38706370],
		["New Ipswich","NH",42.75,-71.85,38707194],
		["Marriottsville","MD",39.35,-76.89,38708018],
		["Aiken","SC",33.54,-81.72,38708842],
		["Piney Flats","TN",36.44,-82.34,38709666],
		["South Bend","IN",41.67,-86.26,38710490],
		["Southampton","MA",42.23,-72.73,38711313],
		["Portage","PA",40.38,-78.67,38712136],
		["Rehoboth Beach","DE",38.7,-75.09,38712959],
		["Manistique","MI",45.95,-86.25,38713782],
		["Higginsville","MO",39.06,-93.72,38714605],
		["Mamou","LA",30.63,-92.41,38715428],
		["Florence","CO",38.38,-105.11,38716251],
		["Hainesport","NJ",39.98,-74.82,38717073],
		["Voorheesville","NY",42.64,-73.93,38717895],
		["Gainesboro","TN",36.36,-85.65,38718717],
		["Okolona","MS",34,-88.75,38719539],
		["Prairie Du Sac","WI",43.29,-89.72,38720361],
		["Ridgway","PA",41.42,-78.72,38721182],
		["Chase City","VA",36.79,-78.46,38722003],
		["West Alexandria","OH",39.74,-84.53,38722824],
		["Marble Hill","MO",37.3,-89.98,38723645],
		["Clearwater","KS",37.5,-97.5,38724466],
		["Ensenada","PR",17.96,-66.94,38725286],
		["Syracuse","NY",43.04,-76.14,38726106],
		["Evans City","PA",40.76,-80.05,38726926],
		["Jermyn","PA",41.52,-75.54,38727746],
		["Homerville","GA",31.03,-82.75,38728566],
		["Youngstown","FL",30.36,-85.43,38729386],
		["Luverne","MN",43.65,-96.2,38730206],
		["Stone Park","IL",41.9,-87.88,38731026],
		["Duquesne","PA",40.37,-79.85,38731845],
		["Blakeslee","PA",41.04,-75.52,38732664],
		["Macon","GA",32.83,-83.65,38733483],
		["Jeffersonville","KY",37.96,-83.82,38734302],
		["Elkins","AR",36.01,-94.02,38735121],
		["Quitman","TX",32.79,-95.44,38735940],
		["Warsaw","NY",42.74,-78.14,38736758],
		["Buchanan","GA",33.8,-85.18,38737576],
		["Cross Plains","WI",43.11,-89.64,38738394],
		["Sisseton","SD",45.66,-97.04,38739212],
		["Corvallis","MT",46.34,-113.95,38740030],
		["San Antonio","TX",29.45,-98.5,38740848],
		["Los Angeles","CA",34.07,-118.37,38741666],
		["Bolton","MA",42.43,-71.6,38742483],
		["Mayo","FL",30.05,-83.17,38743300],
		["Covington","KY",39.08,-84.5,38744117],
		["Auburn","KY",36.86,-86.71,38744934],
		["Sardinia","OH",39,-83.8,38745751],
		["Rockport","IN",37.88,-87.05,38746568],
		["Freeburg","IL",38.42,-89.9,38747385],
		["Saint Louis","MO",38.63,-90.24,38748202],
		["Larned","KS",38.18,-99.1,38749019],
		["Harrington Park","NJ",40.98,-73.98,38749835],
		["Albany","NY",42.66,-73.79,38750651],
		["Shickshinny","PA",41.15,-76.15,38751467],
		["Dayton","VA",38.47,-79.08,38752283],
		["Chunchula","AL",30.92,-88.2,38753099],
		["Coxs Creek","KY",37.9,-85.46,38753915],
		["Burkesville","KY",36.79,-85.36,38754731],
		["Butler","IN",41.42,-84.87,38755547],
		["Waukon","IA",43.26,-91.47,38756363],
		["Oak View","CA",34.4,-119.3,38757179],
		["San Juan","PR",18.44,-66.06,38757994],
		["Orwigsburg","PA",40.65,-76.1,38758809],
		["Castlewood","VA",36.87,-82.28,38759624],
		["Woodbine","GA",30.95,-81.72,38760439],
		["Mobile","AL",30.68,-88.04,38761254],
		["Maryville","IL",38.72,-89.96,38762069],
		["Raymond","WA",46.64,-123.63,38762884],
		["San Juan","PR",18.4,-66.06,38763698],
		["Albia","IA",41.02,-92.8,38764512],
		["Kansas City","MO",39.09,-94.58,38765326],
		["Lincoln","NE",40.81,-96.68,38766140],
		["Sherborn","MA",42.23,-71.36,38766953],
		["Norwood","PA",39.88,-75.29,38767766],
		["Charlotte","TN",36.18,-87.34,38768579],
		["Erie","MI",41.79,-83.49,38769392],
		["Kimberly","WI",44.27,-88.34,38770205],
		["Clear Spring","MD",39.65,-77.93,38771017],
		["Gordo","AL",33.32,-87.9,38771829],
		["Indianapolis","IN",39.77,-86.14,38772641],
		["Two Harbors","MN",47.03,-91.67,38773453],
		["Lexington","MO",39.18,-93.87,38774265],
		["Saint Maries","ID",47.2,-116.55,38775077],
		["Redwood Valley","CA",39.32,-123.23,38775889],
		["Neshanic Station","NJ",40.49,-74.73,38776700],
		["Saegertown","PA",41.71,-80.13,38777511],
		["Breinigsville","PA",40.55,-75.66,38778322],
		["Jonesville","VA",36.68,-83.11,38779133],
		["Paintsville","KY",37.81,-82.8,38779944],
		["Huntertown","IN",41.23,-85.16,38780755],
		["Saukville","WI",43.38,-87.94,38781566],
		["Valley Park","MO",38.55,-90.48,38782377],
		["Saint Louis","MO",38.63,-90.24,38783188],
		["Jamestown","CA",37.85,-120.49,38783999],
		["Dillwyn","VA",37.54,-78.46,38784809],
		["Lafayette","AL",32.89,-85.4,38785619],
		["Bainbridge","OH",39.22,-83.26,38786429],
		["Blissfield","MI",41.83,-83.86,38787239],
		["Saint Paul Park","MN",44.83,-92.99,38788049],
		["Momence","IL",41.16,-87.66,38788859],
		["Window Rock","AZ",35.69,-108.98,38789669],
		["Sodus","NY",43.23,-77.06,38790478],
		["Alexandria","VA",38.82,-77.08,38791287],
		["Interlochen","MI",44.65,-85.75,38792096],
		["Georgetown","IL",39.97,-87.63,38792905],
		["Ashland","MO",38.77,-92.25,38793714],
		["Post","TX",33.19,-101.38,38794523],
		["Franklin Park","NJ",40.44,-74.54,38795331],
		["Greenvale","NY",40.81,-73.62,38796139],
		["Devon","PA",40.04,-75.42,38796947],
		["Lookout Mountain","GA",34.96,-85.36,38797755],
		["Seminary","MS",31.55,-89.49,38798563],
		["Priest River","ID",48.38,-116.89,38799371],
		["Boiling Springs","PA",40.15,-77.13,38800178],
		["Floral City","FL",28.74,-82.29,38800985],
		["Dresden","TN",36.27,-88.69,38801792],
		["Walnut","MS",34.94,-88.9,38802599],
		["New London","OH",41.07,-82.4,38803406],
		["Palmyra","MO",39.79,-91.52,38804213],
		["Willow Springs","MO",36.99,-91.96,38805020],
		["Hidden Valley Lake","CA",38.8,-122.55,38805827],
		["York Haven","PA",40.11,-76.71,38806633],
		["Talking Rock","GA",34.5,-84.5,38807439],
		["Pearson","GA",31.29,-82.85,38808245],
		["Oologah","OK",36.44,-95.71,38809051],
		["Rochester","MA",41.73,-70.81,38809856],
		["White Plains","NY",41.02,-73.75,38810661],
		["North Chili","NY",43.11,-77.8,38811466],
		["Eupora","MS",33.54,-89.27,38812271],
		["Pipestone","MN",44,-96.31,38813076],
		["Foresthill","CA",39.03,-120.82,38813881],
		["Henniker","NH",43.17,-71.81,38814685],
		["Eliot","ME",43.15,-70.8,38815489],
		["Newfield","NY",42.36,-76.59,38816293],
		["Hurlock","MD",38.62,-75.87,38817097],
		["Horton","AL",34.18,-86.32,38817901],
		["Kingsford","MI",45.8,-88.09,38818705],
		["Saint Francis","WI",42.97,-87.87,38819509],
		["San Jose","CA",37.42,-121.94,38820313],
		["Buffalo","NY",42.88,-78.85,38821116],
		["Pittston","PA",41.32,-75.78,38821919],
		["Flat Rock","NC",35.27,-82.44,38822722],
		["Hartford","KY",37.45,-86.89,38823525],
		["Cloverdale","IN",39.52,-86.79,38824328],
		["Bangor","MI",42.31,-86.11,38825131],
		["Sauk City","WI",43.27,-89.72,38825934],
		["Armona","CA",36.31,-119.71,38826737],
		["Contoocook","NH",43.22,-71.71,38827539],
		["Northvale","NJ",41,-73.95,38828341],
		["Lake Placid","NY",44.28,-73.98,38829143],
		["Paoli","PA",40.04,-75.49,38829945],
		["Haw River","NC",36.09,-79.36,38830747],
		["Bonneau","SC",33.3,-79.95,38831549],
		["Warroad","MN",48.9,-95.32,38832351],
		["Florence","MT",46.65,-114.07,38833153],
		["Quincy","CA",39.96,-121,38833955],
		["Coquille","OR",43.2,-124.12,38834757],
		["Pittsburgh","PA",40.43,-79.97,38835558],
		["Paradise","PA",40,-76.12,38836359],
		["Boones Mill","VA",37.11,-79.95,38837160],
		["Eastover","SC",33.87,-80.69,38837961],
		["Mobile","AL",30.68,-88.04,38838762],
		["Lyles","TN",35.87,-87.32,38839563],
		["Caldwell","OH",39.74,-81.51,38840364],
		["South Bend","IN",41.67,-86.26,38841165],
		["Saranac","MI",42.92,-85.2,38841966],
		["Bigfork","MT",47.88,-113.87,38842767],
		["Saint Louis","MO",38.63,-90.24,38843568],
		["Black Diamond","WA",47.32,-121.99,38844369],
		["Shirley","MA",42.54,-71.65,38845169],
		["Darien","GA",31.37,-81.42,38845969],
		["Geneva","FL",28.73,-81.11,38846769],
		["Caledonia","MS",33.68,-88.32,38847569],
		["Bondurant","IA",41.68,-93.46,38848369],
		["Antlers","OK",34.23,-95.61,38849169],
		["Jacksboro","TX",33.22,-98.15,38849969],
		["Odem","TX",27.94,-97.58,38850769],
		["Kane","PA",41.66,-78.8,38851568],
		["Christiana","PA",39.95,-75.99,38852367],
		["Williamstown","WV",39.4,-81.45,38853166],
		["Citra","FL",29.41,-82.11,38853965],
		["Helendale","CA",34.74,-117.35,38854764],
		["Hughesville","PA",41.23,-76.72,38855562],
		["Holly Hill","SC",33.32,-80.41,38856360],
		["Jay","FL",30.95,-87.15,38857158],
		["Weaver","AL",33.75,-85.8,38857956],
		["Pigeon Forge","TN",35.79,-83.55,38858754],
		["Charleston","MO",36.91,-89.33,38859552],
		["Columbia","LA",32.1,-92.07,38860350],
		["Paris","AR",35.28,-93.72,38861148],
		["Surprise","AZ",33.71,-112.4,38861946],
		["Mattapoisett","MA",41.66,-70.8,38862743],
		["Addison","NY",42.1,-77.23,38863540],
		["Waynesville","NC",35.63,-83.02,38864337],
		["Buellton","CA",34.61,-120.22,38865134],
		["Marcy","NY",43.17,-75.29,38865930],
		["Wind Gap","PA",40.84,-75.29,38866726],
		["Fishersville","VA",38.09,-78.96,38867522],
		["Varnville","SC",32.85,-81.08,38868318],
		["Wrightsville","GA",32.72,-82.72,38869114],
		["Hooks","TX",33.46,-94.28,38869910],
		["Houston","TX",29.76,-95.38,38870706],
		["Ranchos De Taos","NM",36.36,-105.6,38871502],
		["Ben Lomond","CA",37.1,-122.08,38872298],
		["Epping","NH",43.03,-71.07,38873093],
		["Beacon Falls","CT",41.44,-73.04,38873888],
		["Seven Valleys","PA",39.85,-76.76,38874683],
		["Amissville","VA",38.67,-77.99,38875478],
		["Winfield","WV",38.52,-81.88,38876273],
		["Lutz","FL",28.13,-82.46,38877068],
		["Cave City","KY",37.13,-85.96,38877863],
		["Union City","IN",40.2,-84.81,38878658],
		["Clifton Forge","VA",37.82,-79.82,38879452],
		["Buena Vista","GA",32.31,-84.51,38880246],
		["Trafalgar","IN",39.41,-86.14,38881040],
		["New Orleans","LA",30.06,-89.93,38881834],
		["Nowata","OK",36.69,-95.63,38882628],
		["Colorado Springs","CO",38.94,-104.7,38883422],
		["Calipatria","CA",33.13,-115.52,38884216],
		["Otis Orchards","WA",47.72,-117.17,38885010],
		["Howard","PA",41.01,-77.65,38885803],
		["Charleston","WV",38.35,-81.63,38886596],
		["Sparta","NC",36.5,-81.13,38887389],
		["South Pittsburg","TN",35.01,-85.71,38888182],
		["Colgate","WI",43.2,-88.25,38888975],
		["Anna","IL",37.46,-89.24,38889768],
		["Aurora","NE",40.86,-98,38890561],
		["Brielle","NJ",40.1,-74.06,38891353],
		["Charleston","MS",34,-90.05,38892145],
		["Basehor","KS",39.12,-94.92,38892937],
		["Dayton","OR",45.19,-123.07,38893729],
		["Rumford","ME",44.54,-70.56,38894520],
		["Marion","NY",43.16,-77.17,38895311],
		["Calvert City","KY",37.03,-88.35,38896102],
		["Rockford","MN",45.08,-93.73,38896893],
		["Sparta","IL",38.15,-89.71,38897684],
		["Welsh","LA",30.23,-92.81,38898475],
		["Clinton","LA",30.86,-91.01,38899266],
		["Hulbert","OK",35.93,-95.14,38900057],
		["Daingerfield","TX",33.03,-94.72,38900848],
		["Ben Wheeler","TX",32.41,-95.66,38901639],
		["Tulia","TX",34.53,-101.77,38902430],
		["Russiaville","IN",40.41,-86.27,38903220],
		["Peshtigo","WI",45.05,-87.74,38904010],
		["Tonkawa","OK",36.68,-97.3,38904800],
		["Austin","TX",30.26,-97.74,38905590],
		["Dewey","AZ",34.56,-112.12,38906380],
		["Arbuckle","CA",39.02,-122.04,38907170],
		["Womelsdorf","PA",40.36,-76.18,38907959],
		["Whitsett","NC",36.03,-79.59,38908748],
		["Parsons","TN",35.65,-88.12,38909537],
		["Baltic","OH",40.44,-81.7,38910326],
		["Chandler","IN",38.04,-87.36,38911115],
		["Perham","MN",46.59,-95.57,38911904],
		["Rowley","MA",42.71,-70.87,38912692],
		["Marlette","MI",43.32,-83.08,38913480],
		["White Pigeon","MI",41.79,-85.64,38914268],
		["Harwinton","CT",41.75,-73.05,38915055],
		["Tappan","NY",41.02,-73.95,38915842],
		["Clintwood","VA",37.15,-82.45,38916629],
		["Petersburg","WV",38.99,-79.12,38917416],
		["Grimesland","NC",35.56,-77.19,38918203],
		["Luverne","AL",31.7,-86.26,38918990],
		["Le Sueur","MN",44.46,-93.9,38919777],
		["Pierz","MN",45.97,-94.1,38920564],
		["Brentwood","MD",38.94,-76.95,38921350],
		["Berwick","LA",29.7,-91.23,38922136],
		["Hebbronville","TX",27.31,-98.68,38922922],
		["Putney","VT",42.97,-72.52,38923707],
		["Franklin","NJ",41.11,-74.58,38924492],
		["Homestead","FL",25.46,-80.45,38925277],
		["Dunnellon","FL",29.04,-82.45,38926062],
		["North Street","MI",43.03,-82.56,38926847],
		["Yellville","AR",36.22,-92.68,38927632],
		["Marietta","OK",33.93,-97.12,38928417],
		["Williams","AZ",35.37,-112.26,38929202],
		["Fall City","WA",47.58,-121.88,38929987],
		["Lincoln","MA",42.41,-71.3,38930771],
		["Oceanport","NJ",40.31,-74.02,38931555],
		["Allegany","NY",42.09,-78.49,38932339],
		["Kitty Hawk","NC",36.07,-75.71,38933123],
		["Waleska","GA",34.31,-84.55,38933907],
		["Decatur","MS",32.43,-89.11,38934691],
		["Salt Lake City","UT",40.75,-111.88,38935475],
		["Sloughhouse","CA",38.51,-121.12,38936259],
		["Banks","OR",45.66,-123.15,38937043],
		["Henagar","AL",34.63,-85.73,38937826],
		["Cuthbert","GA",31.77,-84.75,38938609],
		["Hildale","UT",37,-112.96,38939392],
		["West Yarmouth","MA",41.65,-70.24,38940174],
		["East Moriches","NY",40.81,-72.76,38940956],
		["Wolcott","NY",43.22,-76.81,38941738],
		["Whitakers","NC",36.1,-77.71,38942520],
		["Ponte Vedra","FL",30.08,-81.4,38943302],
		["Spring Hill","FL",28.44,-82.56,38944084],
		["Morrison","TN",35.6,-85.91,38944866],
		["Hugoton","KS",37.17,-101.34,38945648],
		["Prescott","AR",33.8,-93.38,38946430],
		["Norwalk","CT",41.09,-73.42,38947211],
		["Pemberton","NJ",39.97,-74.68,38947992],
		["Chicora","PA",40.94,-79.74,38948773],
		["Cochranville","PA",39.87,-75.92,38949554],
		["Ethridge","TN",35.32,-87.3,38950335],
		["Campton","KY",37.73,-83.54,38951116],
		["Jackson","CA",38.35,-120.77,38951897],
		["Boardman","OR",45.77,-119.52,38952678],
		["Weedsport","NY",43.04,-76.56,38953458],
		["Byesville","OH",39.96,-81.54,38954238],
		["Spooner","WI",45.82,-91.89,38955018],
		["Marshall","VA",38.87,-77.85,38955797],
		["Allendale","SC",33,-81.3,38956576],
		["Nicholls","GA",31.51,-82.63,38957355],
		["Kewaunee","WI",44.46,-87.51,38958134],
		["Calistoga","CA",38.62,-122.61,38958913],
		["Keyes","CA",37.55,-120.91,38959692],
		["Saint Marys","WV",39.4,-81.19,38960470],
		["Hartford","SD",43.62,-96.94,38961248],
		["Afton","OK",36.69,-94.96,38962026],
		["Northbridge","MA",42.15,-71.65,38962803],
		["Hiddenite","NC",35.9,-81.09,38963580],
		["Anna","OH",40.39,-84.17,38964357],
		["Lewisburg","OH",39.84,-84.54,38965134],
		["Coal Valley","IL",41.44,-90.44,38965911],
		["Boyd","TX",33.08,-97.56,38966688],
		["Cresson","PA",40.46,-78.58,38967464],
		["Stanfield","NC",35.23,-80.42,38968240],
		["Delray Beach","FL",26.45,-80.08,38969016],
		["Camden","AL",31.99,-87.29,38969792],
		["Osage","IA",43.28,-92.81,38970568],
		["Black Hawk","SD",44.16,-103.25,38971344],
		["Pollock","LA",31.52,-92.4,38972120],
		["Palmyra","NJ",40,-75.03,38972895],
		["Athens","PA",41.95,-76.51,38973670],
		["White Hall","MD",39.65,-76.56,38974445],
		["Augusta","WV",39.3,-78.59,38975220],
		["Broad Brook","CT",41.9,-72.54,38975994],
		["Youngstown","NY",43.24,-79.04,38976768],
		["Ranson","WV",39.29,-77.86,38977542],
		["Bridgeport","OH",40.06,-80.74,38978316],
		["Hardin","MT",45.75,-107.79,38979090],
		["Salina","OK",36.29,-95.15,38979864],
		["Turner","OR",44.79,-122.94,38980638],
		["Pine Brook","NJ",40.86,-74.34,38981411],
		["Nauvoo","AL",33.98,-87.48,38982184],
		["Adams","TN",36.58,-87.06,38982957],
		["Sleepy Eye","MN",44.29,-94.72,38983730],
		["Tea","SD",43.44,-96.83,38984503],
		["Pawhuska","OK",36.66,-96.33,38985276],
		["Twentynine Palms","CA",34.27,-116.1,38986049],
		["Warden","WA",47.01,-119.12,38986822],
		["Waban","MA",42.32,-71.23,38987594],
		["Walton","NY",42.16,-75.13,38988366],
		["Turtle Creek","PA",40.4,-79.82,38989138],
		["Gordonville","PA",40.04,-76.1,38989910],
		["Norton","VA",36.93,-82.62,38990682],
		["Banner Elk","NC",36.16,-81.87,38991454],
		["South Shore","KY",38.72,-82.96,38992226],
		["Eubank","KY",37.27,-84.65,38992998],
		["Shreve","OH",40.68,-82.02,38993770],
		["Linn","MO",38.48,-91.84,38994542],
		["Frankston","TX",32.05,-95.5,38995314],
		["Eagar","AZ",34.11,-109.38,38996086],
		["Trenton","NJ",40.22,-74.76,38996857],
		["Fort Lauderdale","FL",26.14,-80.13,38997628],
		["Fort Recovery","OH",40.41,-84.77,38998399],
		["Coatesville","IN",39.68,-86.66,38999170],
		["Hampton","IA",42.74,-93.2,38999941],
		["Afton","WY",42.72,-110.92,39000712],
		["Punta Santiago","PR",18.15,-65.76,39001482],
		["Foster","RI",41.85,-71.76,39002252],
		["Marathon","FL",24.71,-81.08,39003022],
		["Dunnellon","FL",29.04,-82.44,39003792],
		["Phil Campbell","AL",34.35,-87.7,39004562],
		["Odon","IN",38.84,-86.98,39005332],
		["Poynette","WI",43.39,-89.4,39006102],
		["Eagle River","WI",45.92,-89.25,39006872],
		["Berkeley","IL",41.88,-87.91,39007642],
		["Rockport","MA",42.64,-70.61,39008411],
		["Kingston","NH",42.93,-71.05,39009180],
		["Stedman","NC",35.01,-78.69,39009949],
		["Bristol","FL",30.42,-84.97,39010718],
		["Fort Pierce","FL",27.44,-80.32,39011487],
		["Aliceville","AL",33.12,-88.15,39012256],
		["Simpsonville","KY",38.21,-85.35,39013025],
		["Hardinsburg","KY",37.77,-86.45,39013794],
		["Albany","MN",45.63,-94.56,39014563],
		["Dwight","IL",41.09,-88.42,39015332],
		["Elgin","OK",34.78,-98.29,39016101],
		["Addison","TX",32.96,-96.83,39016870],
		["Fresno","CA",36.83,-119.79,39017639],
		["Haleiwa","HI",21.59,-158.1,39018408],
		["Punta Gorda","FL",26.98,-82.1,39019176],
		["Loudonville","OH",40.63,-82.23,39019944],
		["Ovid","MI",43,-84.37,39020712],
		["Licking","MO",37.49,-91.86,39021480],
		["Swanzey","NH",42.86,-72.28,39022247],
		["Lucama","NC",35.64,-78,39023014],
		["Maysville","GA",34.25,-83.55,39023781],
		["Orwell","OH",41.53,-80.85,39024548],
		["Dyersville","IA",42.48,-91.11,39025315],
		["Sherman","IL",39.88,-89.6,39026082],
		["Springhill","LA",33,-93.46,39026849],
		["Grand Prairie","TX",32.69,-97.01,39027616],
		["Breckenridge","CO",39.5,-106.04,39028383],
		["Silverdale","WA",47.72,-122.71,39029150],
		["Coudersport","PA",41.77,-78.01,39029916],
		["East Greenville","PA",40.4,-75.5,39030682],
		["Naples","FL",26.14,-81.79,39031448],
		["Fultondale","AL",33.61,-86.8,39032214],
		["Englewood","TN",35.42,-84.48,39032980],
		["East Canton","OH",40.81,-81.37,39033746],
		["Memphis","MI",42.89,-82.76,39034512],
		["Shelby","MI",43.61,-86.35,39035278],
		["Topeka","KS",39.03,-95.69,39036044],
		["Hyde Park","UT",41.8,-111.8,39036810],
		["Bisbee","AZ",31.44,-109.87,39037576],
		["Harrisburg","OR",44.27,-123.05,39038342],
		["Stanley","VA",38.57,-78.5,39039107],
		["Laurel Hill","NC",34.8,-79.54,39039872],
		["Dell Rapids","SD",43.82,-96.71,39040637],
		["Max Meadows","VA",36.96,-80.93,39041401],
		["Brown City","MI",43.21,-82.98,39042165],
		["North Sioux City","SD",42.53,-96.5,39042929],
		["Frazier Park","CA",34.8,-119.01,39043693],
		["Halifax","VA",36.76,-78.93,39044456],
		["Baker","FL",30.79,-86.68,39045219],
		["Monticello","MS",31.55,-90.11,39045982],
		["Wolcottville","IN",41.52,-85.36,39046745],
		["Washington","MI",42.72,-83.03,39047508],
		["Granby","MO",36.91,-94.26,39048271],
		["Goliad","TX",28.66,-97.39,39049034],
		["Boonville","NY",43.48,-75.33,39049796],
		["Wyncote","PA",40.09,-75.14,39050558],
		["Kittrell","NC",36.22,-78.44,39051320],
		["Louisville","GA",32.99,-82.4,39052082],
		["Colquitt","GA",31.15,-84.67,39052844],
		["West Liberty","OH",40.25,-83.75,39053606],
		["Staples","MN",46.36,-94.8,39054368],
		["Madison","IL",38.67,-90.15,39055130],
		["East Prairie","MO",36.77,-89.38,39055892],
		["Wheatland","WY",42.05,-104.95,39056654],
		["Berkeley","CA",37.87,-122.3,39057416],
		["Lambertville","NJ",40.36,-74.94,39058177],
		["Pound Ridge","NY",41.21,-73.57,39058938],
		["Mckeesport","PA",40.33,-79.83,39059699],
		["Belleville","PA",40.6,-77.72,39060460],
		["Young Harris","GA",34.93,-83.84,39061221],
		["Dryden","MI",42.94,-83.12,39061982],
		["Sandusky","MI",43.42,-82.83,39062743],
		["Shenandoah","IA",40.75,-95.36,39063504],
		["Eveleth","MN",47.46,-92.54,39064265],
		["Box Elder","MT",48.31,-110.01,39065026],
		["Lolo","MT",46.73,-114.36,39065787],
		["Paxton","IL",40.45,-88.09,39066548],
		["Wheatland","CA",39.04,-121.39,39067309],
		["Garrochales","PR",18.45,-66.6,39068069],
		["Hampton","VA",37.04,-76.29,39068829],
		["Hartford","AL",31.1,-85.69,39069589],
		["Greenback","TN",35.65,-84.17,39070349],
		["Lake City","TN",36.22,-84.15,39071109],
		["Brownstown","IN",38.88,-86.04,39071869],
		["Saint Charles","MN",43.96,-92.06,39072629],
		["Holton","KS",39.47,-95.73,39073389],
		["Royal","AR",34.52,-93.32,39074149],
		["Prague","OK",35.48,-96.69,39074909],
		["Heyburn","ID",42.56,-113.81,39075669],
		["Ransomville","NY",43.23,-78.9,39076428],
		["Alexandria","VA",38.82,-77.08,39077187],
		["De Leon Springs","FL",29.11,-81.35,39077946],
		["Sewanee","TN",35.2,-85.92,39078705],
		["Limestone","TN",36.22,-82.63,39079464],
		["Constantine","MI",41.83,-85.66,39080223],
		["Omaha","NE",41.26,-96.01,39080982],
		["Early","TX",31.74,-98.94,39081741],
		["Wrightstown","NJ",40.02,-74.63,39082499],
		["New Castle","PA",40.99,-80.34,39083257],
		["Nahunta","GA",31.2,-81.98,39084015],
		["Stuart","FL",27.19,-80.24,39084773],
		["Adams","NY",43.8,-76.02,39085530],
		["Susquehanna","PA",41.94,-75.6,39086287],
		["Pearisburg","VA",37.32,-80.72,39087044],
		["Crystal River","FL",28.9,-82.59,39087801],
		["Fayette","MS",31.71,-91.06,39088558],
		["Palmer","TX",32.42,-96.67,39089315],
		["Nolanville","TX",31.07,-97.61,39090072],
		["Scio","OR",44.68,-122.76,39090829],
		["Pelahatchie","MS",32.31,-89.8,39091585],
		["Eldorado","IL",37.81,-88.44,39092341],
		["Albany","LA",30.52,-90.61,39093097],
		["Eagle Lake","TX",29.58,-96.32,39093853],
		["Eden","UT",41.33,-111.79,39094609],
		["Stockton","CA",37.95,-121.28,39095365],
		["Terrebonne","OR",44.41,-121.29,39096121],
		["New Boston","NH",42.96,-71.68,39096876],
		["Shrewsbury","PA",39.77,-76.68,39097631],
		["Tazewell","VA",37.12,-81.51,39098386],
		["Linwood","NC",35.75,-80.31,39099141],
		["East Bernstadt","KY",37.18,-84.11,39099896],
		["Onsted","MI",42,-84.19,39100651],
		["Teague","TX",31.63,-96.28,39101406],
		["Clifton Springs","NY",42.95,-77.13,39102160],
		["Windsor","PA",39.91,-76.58,39102914],
		["Canton","PA",41.65,-76.85,39103668],
		["Union Bridge","MD",39.56,-77.17,39104422],
		["West Point","VA",37.55,-76.8,39105176],
		["Battleboro","NC",36.04,-77.75,39105930],
		["Leoma","TN",35.12,-87.3,39106684],
		["Clear Lake","MN",45.44,-93.99,39107438],
		["Newton","TX",30.85,-93.75,39108192],
		["Mart","TX",31.54,-96.83,39108946],
		["Arcade","NY",42.53,-78.43,39109699],
		["Crewe","VA",37.18,-78.13,39110452],
		["Ridgeley","WV",39.64,-78.77,39111205],
		["Frankfort","OH",39.4,-83.18,39111958],
		["Petersburg","IN",38.49,-87.28,39112711],
		["Fayette","MO",39.14,-92.68,39113464],
		["Langston","OK",35.94,-97.26,39114217],
		["Loudon","NH",43.28,-71.46,39114969],
		["Moravia","NY",42.71,-76.42,39115721],
		["Meyersdale","PA",39.81,-79.02,39116473],
		["Biglerville","PA",39.93,-77.24,39117225],
		["Boonville","NC",36.23,-80.7,39117977],
		["Bay Springs","MS",31.98,-89.28,39118729],
		["Billings","MO",37.06,-93.55,39119481],
		["Rescue","CA",38.72,-121,39120233],
		["Bedford Hills","NY",41.22,-73.69,39120984],
		["West Newton","PA",40.2,-79.76,39121735],
		["Perryville","MD",39.57,-76.06,39122486],
		["Scranton","SC",33.91,-79.74,39123237],
		["Hortense","GA",31.33,-81.95,39123987],
		["Bronson","FL",29.44,-82.63,39124737],
		["Hiram","OH",41.31,-81.14,39125487],
		["Mansfield","OH",40.76,-82.52,39126237],
		["Bradford","OH",40.13,-84.42,39126987],
		["Wahoo","NE",41.21,-96.62,39127737],
		["Ball","LA",31.41,-92.41,39128487],
		["Malakoff","TX",32.17,-96.01,39129237],
		["Warm Springs","OR",44.82,-121.31,39129987],
		["San Antonio","TX",29.45,-98.5,39130736],
		["Newmanstown","PA",40.35,-76.21,39131484],
		["Goodwater","AL",33.06,-86.05,39132232],
		["Philadelphia","TN",35.67,-84.4,39132980],
		["Fairland","IN",39.59,-85.85,39133728],
		["Avon","MN",45.6,-94.45,39134476],
		["New Orleans","LA",30.06,-89.93,39135224],
		["Springfield","LA",30.42,-90.54,39135972],
		["New Plymouth","ID",43.94,-116.8,39136720],
		["Kingsville","MD",39.44,-76.41,39137467],
		["Columbus","NC",35.24,-82.2,39138214],
		["Danville","AL",34.39,-87.12,39138961],
		["Eclectic","AL",32.63,-86.03,39139708],
		["Lineville","AL",33.31,-85.75,39140455],
		["Elkhart","TX",31.62,-95.57,39141202],
		["Steilacoom","WA",47.21,-122.68,39141949],
		["Wolfeboro","NH",43.58,-71.2,39142695],
		["Sea Cliff","NY",40.84,-73.64,39143441],
		["Northumberland","PA",40.89,-76.79,39144187],
		["Munford","AL",33.53,-85.92,39144933],
		["Bethpage","TN",36.5,-86.3,39145679],
		["Erin","TN",36.31,-87.7,39146425],
		["Cambridge","MA",42.37,-71.11,39147170],
		["Hampton","NJ",40.7,-74.96,39147915],
		["Sparks Glencoe","MD",39.54,-76.68,39148660],
		["Johnston","SC",33.83,-81.8,39149405],
		["Burns","TN",36.05,-87.31,39150150],
		["Pittsfield","IL",39.61,-90.8,39150895],
		["Whitewright","TX",33.51,-96.39,39151640],
		["Coleman","TX",31.83,-99.42,39152385],
		["Delta Junction","AK",63.7,-144.89,39153130],
		["Mount Gilead","NC",35.21,-80,39153874],
		["Pine Mountain","GA",32.86,-84.85,39154618],
		["Mount Sterling","OH",39.71,-83.26,39155362],
		["New Bremen","OH",40.43,-84.38,39156106],
		["Paynesville","MN",45.37,-94.71,39156850],
		["Clancy","MT",46.43,-112.01,39157594],
		["Columbus","KS",37.16,-94.84,39158338],
		["Manila","AR",35.88,-90.16,39159082],
		["Needles","CA",34.78,-114.59,39159826],
		["Larkspur","CA",37.93,-122.53,39160570],
		["Higganum","CT",41.49,-72.55,39161313],
		["Perkiomenville","PA",40.32,-75.51,39162056],
		["Neptune Beach","FL",30.31,-81.39,39162799],
		["Mingo Junction","OH",40.32,-80.61,39163542],
		["Nashville","MI",42.6,-85.09,39164285],
		["Harrisburg","SD",43.43,-96.69,39165028],
		["Garland","UT",41.79,-112.17,39165771],
		["Harrisville","RI",41.96,-71.67,39166513],
		["Enosburg Falls","VT",44.9,-72.8,39167255],
		["Edinburg","VA",38.82,-78.56,39167997],
		["Newton Grove","NC",35.25,-78.35,39168739],
		["Ridgeway","SC",34.3,-80.96,39169481],
		["Homer","MI",42.14,-84.81,39170223],
		["Oconto Falls","WI",44.87,-88.14,39170965],
		["Barron","WI",45.4,-91.84,39171707],
		["Austin","TX",30.26,-97.74,39172449],
		["Columbia","CT",41.7,-72.3,39173190],
		["Wayland","NY",42.56,-77.59,39173931],
		["Frankford","DE",38.52,-75.23,39174672],
		["Port Saint Joe","FL",29.83,-85.24,39175413],
		["Waterloo","WI",43.18,-88.98,39176154],
		["Lancaster","WI",42.84,-90.7,39176895],
		["Bourbon","MO",38.15,-91.24,39177636],
		["Stonewall","LA",32.26,-93.81,39178377],
		["Warrenton","OR",46.12,-123.91,39179118],
		["New Cumberland","WV",40.49,-80.6,39179858],
		["Bostic","NC",35.36,-81.83,39180598],
		["Knightstown","IN",39.79,-85.52,39181338],
		["Mackinaw","IL",40.53,-89.35,39182078],
		["Galena","KS",37.07,-94.63,39182818],
		["Anahuac","TX",29.76,-94.67,39183558],
		["Winooski","VT",44.49,-73.18,39184297],
		["New Providence","PA",39.9,-76.23,39185036],
		["Alburtis","PA",40.5,-75.6,39185775],
		["Ray City","GA",31.07,-83.19,39186514],
		["Como","MS",34.51,-89.94,39187253],
		["Somerset","OH",39.8,-82.3,39187992],
		["Rootstown","OH",41.09,-81.18,39188731],
		["Creve Coeur","IL",40.64,-89.59,39189470],
		["Kansas City","MO",39.09,-94.58,39190209],
		["Kingsland","TX",30.66,-98.45,39190948],
		["Rogue River","OR",42.54,-123.1,39191687],
		["Old Orchard Beach","ME",43.52,-70.38,39192425],
		["Cochranton","PA",41.51,-80.04,39193163],
		["Warsaw","VA",37.96,-76.76,39193901],
		["Sneads Ferry","NC",34.55,-77.37,39194639],
		["Eutaw","AL",32.84,-87.89,39195377],
		["Mobile","AL",30.68,-88.04,39196115],
		["Spring Valley","IL",41.32,-89.19,39196853],
		["Rio Vista","CA",38.18,-121.73,39197591],
		["Delbarton","WV",37.7,-82.18,39198328],
		["Shinnston","WV",39.39,-80.29,39199065],
		["Snow Camp","NC",35.86,-79.41,39199802],
		["Cherokee","IA",42.75,-95.55,39200539],
		["Omaha","NE",41.26,-96.01,39201276],
		["Ponder","TX",33.17,-97.28,39202013],
		["Running Springs","CA",34.18,-117.08,39202750],
		["Wanaque","NJ",41.04,-74.29,39203486],
		["Toledo","OH",41.66,-83.58,39204222],
		["Morley","MI",43.49,-85.44,39204958],
		["Comanche","OK",34.36,-97.96,39205694],
		["De Kalb","TX",33.5,-94.61,39206430],
		["Homedale","ID",43.59,-116.94,39207166],
		["Charleston","WV",38.35,-81.63,39207901],
		["Marietta","SC",35.03,-82.49,39208636],
		["Blackville","SC",33.36,-81.28,39209371],
		["Centreville","AL",32.95,-87.13,39210106],
		["Dayton","KY",39.11,-84.46,39210841],
		["Amarillo","TX",35.2,-101.81,39211576],
		["Jaffrey","NH",42.81,-72.02,39212310],
		["Monroeville","NJ",39.64,-75.16,39213044],
		["Ravena","NY",42.47,-73.81,39213778],
		["Anderson","IN",40.09,-85.68,39214512],
		["Brillion","WI",44.17,-88.06,39215246],
		["Topeka","KS",39.03,-95.69,39215980],
		["Charleston","AR",35.29,-94.04,39216714],
		["Eastland","TX",32.4,-98.81,39217448],
		["Salmon","ID",45,-113.86,39218182],
		["Pamplico","SC",33.99,-79.56,39218915],
		["Howe","IN",41.72,-85.42,39219648],
		["Houghton Lake","MI",44.31,-84.76,39220381],
		["Elk Mound","WI",44.87,-91.68,39221114],
		["Esko","MN",46.7,-92.36,39221847],
		["Arthur","IL",39.71,-88.46,39222580],
		["El Paso","TX",31.84,-106.43,39223313],
		["Gold Canyon","AZ",33.34,-111.42,39224046],
		["Tipton","CA",36.04,-119.34,39224779],
		["Winthrop","ME",44.31,-69.96,39225511],
		["Altavista","VA",37.12,-79.28,39226243],
		["Butler","GA",32.55,-84.23,39226975],
		["Albany","OH",39.22,-82.19,39227707],
		["Capac","MI",43.01,-82.92,39228439],
		["Frazee","MN",46.72,-95.69,39229171],
		["Calverton","NY",40.92,-72.76,39229902],
		["Dalton","PA",41.53,-75.73,39230633],
		["Barto","PA",40.38,-75.57,39231364],
		["Sherrills Ford","NC",35.57,-80.99,39232095],
		["Pisgah Forest","NC",35.27,-82.67,39232826],
		["Hamilton","GA",32.75,-84.87,39233557],
		["Wartburg","TN",36.1,-84.58,39234288],
		["Deer River","MN",47.33,-93.79,39235019],
		["Clay Center","KS",39.37,-97.12,39235750],
		["Lincoln","NE",40.81,-96.68,39236481],
		["Buena Vista","CO",38.83,-106.14,39237212],
		["Cave Junction","OR",42.11,-123.6,39237943],
		["Sag Harbor","NY",40.99,-72.29,39238673],
		["Utica","MS",32.1,-90.62,39239403],
		["Coleman","MI",43.75,-84.58,39240133],
		["Glenwood","AR",34.33,-93.54,39240863],
		["Richmond","VA",37.55,-77.46,39241592],
		["Halls","TN",35.88,-89.4,39242321],
		["Larkspur","CO",39.18,-104.89,39243050],
		["Angwin","CA",38.57,-122.44,39243779],
		["Aurora","OR",45.23,-122.8,39244508],
		["Leavenworth","WA",47.81,-120.86,39245237],
		["Corfu","NY",42.96,-78.4,39245965],
		["Camden","NC",36.32,-76.16,39246693],
		["Bedford","KY",38.59,-85.31,39247421],
		["Aledo","IL",41.19,-90.74,39248149],
		["Diana","TX",32.69,-94.66,39248877],
		["Freeland","PA",41.01,-75.89,39249604],
		["Clendenin","WV",38.48,-81.34,39250331],
		["Gillsville","GA",34.31,-83.63,39251058],
		["Ardmore","AL",34.98,-86.83,39251785],
		["Hebron","OH",39.96,-82.49,39252512],
		["Bolivar","OH",40.65,-81.45,39253239],
		["Lindsborg","KS",38.57,-97.67,39253966],
		["Cozad","NE",40.86,-99.98,39254693],
		["Des Allemands","LA",29.8,-90.48,39255420],
		["Perkins","OK",35.97,-97.02,39256147],
		["Rocky Ford","CO",38.04,-103.72,39256874],
		["French Camp","CA",37.86,-121.29,39257601],
		["Jacksonville","OR",42.18,-123.04,39258328],
		["Oxford","ME",44.14,-70.5,39259054],
		["Williamson","WV",37.67,-82.27,39259780],
		["Seagrove","NC",35.54,-79.77,39260506],
		["Littleton","NC",36.43,-77.91,39261232],
		["Stratford","WI",44.8,-90.07,39261958],
		["Fairbury","IL",40.74,-88.51,39262684],
		["Ballinger","TX",31.74,-99.95,39263410],
		["Clyde","NY",43.08,-76.87,39264135],
		["Locust","NC",35.25,-80.43,39264860],
		["Nicholson","GA",34.11,-83.43,39265585],
		["Akron","OH",41.08,-81.52,39266310],
		["Fair Haven","MI",42.67,-82.64,39267035],
		["West Liberty","IA",41.57,-91.26,39267760],
		["Marthasville","MO",38.62,-91.05,39268485],
		["Elbert","CO",39.22,-104.53,39269210],
		["Tonasket","WA",48.68,-119.3,39269935],
		["Danville","NH",42.91,-71.12,39270659],
		["Tennille","GA",32.93,-82.81,39271383],
		["Venice","FL",27.09,-82.43,39272107],
		["New Hampton","IA",43.05,-92.31,39272831],
		["Piedmont","MO",37.14,-90.69,39273555],
		["Lavaca","AR",35.33,-94.18,39274279],
		["Moody","TX",31.3,-97.36,39275003],
		["Youngtown","AZ",33.58,-112.3,39275727],
		["Pomfret Center","CT",41.86,-71.99,39276450],
		["Hannibal","NY",43.31,-76.57,39277173],
		["Charleston","WV",38.35,-81.63,39277896],
		["Charleston","SC",32.78,-79.99,39278619],
		["Edgewater","FL",28.96,-80.9,39279342],
		["Frisco City","AL",31.43,-87.4,39280065],
		["Haskell","OK",35.82,-95.67,39280788],
		["Brockway","PA",41.24,-78.79,39281510],
		["Delray Beach","FL",26.45,-80.08,39282232],
		["Covington","IN",40.13,-87.39,39282954],
		["Cadott","WI",44.95,-91.15,39283676],
		["Ragley","LA",30.51,-93.23,39284398],
		["Van","TX",32.52,-95.63,39285120],
		["Corpus Christi","TX",27.8,-97.39,39285842],
		["Manti","UT",39.25,-111.52,39286564],
		["Manchester","MA",42.56,-70.76,39287285],
		["East Haddam","CT",41.45,-72.46,39288006],
		["Old Bethpage","NY",40.75,-73.45,39288727],
		["New Haven","KY",37.65,-85.58,39289448],
		["Amanda","OH",39.65,-82.74,39290169],
		["Greenville","IN",38.37,-85.98,39290890],
		["Newton","IL",38.98,-88.16,39291611],
		["Kansas City","MO",39.09,-94.58,39292332],
		["Zwolle","LA",31.64,-93.61,39293053],
		["Crane","TX",31.39,-102.35,39293774],
		["Paulden","AZ",34.9,-112.43,39294495],
		["Oakland","CA",37.8,-122.27,39295216],
		["Mansfield Center","CT",41.77,-72.19,39295936],
		["Niagara Falls","NY",43.09,-79.01,39296656],
		["Perry","NY",42.71,-78,39297376],
		["Ringgold","VA",36.6,-79.3,39298096],
		["Kenova","WV",38.4,-82.58,39298816],
		["Rembert","SC",34.09,-80.51,39299536],
		["Mount Airy","GA",34.51,-83.5,39300256],
		["Covington","OH",40.11,-84.35,39300976],
		["Maple Lake","MN",45.23,-94,39301696],
		["Roland","OK",35.41,-94.51,39302416],
		["White Salmon","WA",45.82,-121.46,39303136],
		["Bolton","CT",41.76,-72.43,39303855],
		["Dresden","OH",40.12,-82.01,39304574],
		["Sour Lake","TX",30.13,-94.4,39305293],
		["Silt","CO",39.54,-107.65,39306012],
		["Laguna","NM",35.03,-107.39,39306731],
		["Mesquite","NM",32.16,-106.69,39307450],
		["Auburn","NH",43,-71.34,39308168],
		["Poland","ME",44.06,-70.39,39308886],
		["Grove Hill","AL",31.7,-87.77,39309604],
		["Fairmount","IN",40.41,-85.64,39310322],
		["Tremont","IL",40.52,-89.49,39311040],
		["Bishop","TX",27.58,-97.79,39311758],
		["Casa Grande","AZ",32.91,-111.65,39312476],
		["Logandale","NV",36.64,-114.46,39313194],
		["Honaker","VA",37.01,-81.97,39313911],
		["Mc Leansboro","IL",38.09,-88.53,39314628],
		["Bush","LA",30.6,-89.9,39315345],
		["Hensley","AR",34.53,-92.25,39316062],
		["Canterbury","CT",41.7,-71.96,39316778],
		["Pinetops","NC",35.79,-77.63,39317494],
		["Clayton","IN",39.68,-86.52,39318210],
		["Clarkton","NC",34.48,-78.65,39318925],
		["Harmony","NC",35.95,-80.77,39319640],
		["Scottville","MI",43.95,-86.28,39320355],
		["Hesston","KS",38.13,-97.42,39321070],
		["Baton Rouge","LA",30.44,-91.12,39321785],
		["Hampden","MA",42.06,-72.41,39322499],
		["New Gloucester","ME",43.96,-70.28,39323213],
		["Loretto","PA",40.5,-78.63,39323927],
		["Bakersville","NC",36.01,-82.15,39324641],
		["Stockton","MO",37.69,-93.79,39325355],
		["Nevada","TX",33.03,-96.37,39326069],
		["Linden","CA",38.05,-121.03,39326783],
		["Hubbardston","MA",42.48,-72.01,39327496],
		["Randolph","NY",42.16,-78.97,39328209],
		["Selbyville","DE",38.45,-75.22,39328922],
		["Yemassee","SC",32.69,-80.85,39329635],
		["Cordova","AL",33.76,-87.19,39330348],
		["Decherd","TN",35.2,-86.08,39331061],
		["California","KY",38.91,-84.26,39331774],
		["Bidwell","OH",38.94,-82.25,39332487],
		["Detroit","MI",42.38,-83.1,39333200],
		["Cotulla","TX",28.43,-99.23,39333913],
		["Lyford","TX",26.41,-97.78,39334626],
		["Dillon","CO",39.62,-106.03,39335339],
		["Littleton","NH",44.31,-71.76,39336051],
		["Charlestown","NH",43.23,-72.42,39336763],
		["Willington","CT",41.86,-72.27,39337475],
		["Morrisonville","NY",44.69,-73.55,39338187],
		["Curwensville","PA",40.97,-78.51,39338899],
		["Pennington Gap","VA",36.75,-83.02,39339611],
		["Bellevue","KY",39.1,-84.47,39340323],
		["Clearwater","MN",45.41,-94.04,39341035],
		["Cut Bank","MT",48.65,-112.84,39341747],
		["Vivian","LA",32.87,-93.98,39342459],
		["Captain Cook","HI",19.5,-155.9,39343171],
		["Monkton","MD",39.57,-76.58,39343882],
		["Bolivia","NC",34.07,-78.14,39344593],
		["Orlando","FL",28.5,-81.37,39345304],
		["Culleoka","TN",35.46,-86.99,39346015],
		["Woodville","MS",31.1,-91.29,39346726],
		["Cass Lake","MN",47.37,-94.6,39347437],
		["East Bernard","TX",29.52,-96.06,39348148],
		["Kalaheo","HI",21.92,-159.52,39348859],
		["Carlstadt","NJ",40.82,-74.06,39349569],
		["Stillwater","NY",42.95,-73.64,39350279],
		["Berlin","PA",39.92,-78.95,39350989],
		["Newton","AL",31.33,-85.6,39351699],
		["Peoria Heights","IL",40.74,-89.57,39352409],
		["Buckner","MO",39.13,-94.19,39353119],
		["Sacramento","CA",38.57,-121.46,39353829],
		["Ashland","PA",40.78,-76.34,39354538],
		["Cambridge City","IN",39.81,-85.17,39355247],
		["Standish","MI",43.98,-83.96,39355956],
		["Belleville","WI",42.86,-89.53,39356665],
		["Fulton","IL",41.86,-90.15,39357374],
		["Anacoco","LA",31.25,-93.34,39358083],
		["Chewelah","WA",48.26,-117.76,39358792],
		["Southold","NY",41.05,-72.42,39359500],
		["Jonesville","NC",36.23,-80.84,39360208],
		["Roseville","OH",39.8,-82.07,39360916],
		["Grafton","ND",48.41,-97.4,39361624],
		["Carrollton","MO",39.36,-93.49,39362332],
		["Hermann","MO",38.69,-91.43,39363040],
		["Bertram","TX",30.74,-98.05,39363748],
		["Merkel","TX",32.46,-100.01,39364456],
		["Long Pond","PA",41.05,-75.44,39365163],
		["Elverson","PA",40.15,-75.83,39365870],
		["Glade Spring","VA",36.79,-81.77,39366577],
		["Avilla","IN",41.36,-85.23,39367284],
		["Oostburg","WI",43.62,-87.79,39367991],
		["Reedsville","WI",44.15,-87.95,39368698],
		["Pine Island","MN",44.21,-92.64,39369405],
		["Dry Prong","LA",31.57,-92.52,39370112],
		["Bells","TN",35.71,-89.08,39370818],
		["Cadiz","OH",40.26,-80.99,39371524],
		["Lonsdale","MN",44.47,-93.42,39372230],
		["Carmel Valley","CA",36.39,-121.65,39372936],
		["Campbell Hall","NY",41.44,-74.25,39373641],
		["Benton","PA",41.19,-76.38,39374346],
		["Mannington","WV",39.52,-80.34,39375051],
		["Springville","IN",38.94,-86.62,39375756],
		["Canton","MO",40.12,-91.52,39376461],
		["Forestdale","MA",41.68,-70.5,39377165],
		["East Freetown","MA",41.75,-70.97,39377869],
		["West Haverstraw","NY",41.2,-73.98,39378573],
		["Belington","WV",39.02,-79.93,39379277],
		["Linden","TN",35.61,-87.84,39379981],
		["Arcadia","WI",44.25,-91.49,39380685],
		["Hillsboro","WI",43.65,-90.33,39381389],
		["Basalt","CO",39.36,-107.02,39382093],
		["Green Valley","AZ",31.84,-111.06,39382797],
		["Nathalie","VA",36.93,-78.95,39383500],
		["Chetek","WI",45.31,-91.65,39384203],
		["Duluth","MN",46.78,-92.11,39384906],
		["Heyworth","IL",40.31,-88.97,39385609],
		["Greensburg","LA",30.82,-90.67,39386312],
		["Washington","DC",38.89,-77.03,39387014],
		["Starr","SC",34.37,-82.69,39387716],
		["Knoxville","TN",35.97,-83.94,39388418],
		["Carroll","OH",39.79,-82.7,39389120],
		["Humboldt","IA",42.72,-94.22,39389822],
		["Rock Valley","IA",43.2,-96.29,39390524],
		["Hamilton","VA",39.13,-77.66,39391225],
		["Madison","VA",38.37,-78.25,39391926],
		["Fort Pierce","FL",27.44,-80.32,39392627],
		["Gibsonburg","OH",41.38,-83.32,39393328],
		["Weidman","MI",43.69,-84.97,39394029],
		["Colfax","LA",31.51,-92.7,39394730],
		["Florence","TX",30.83,-97.79,39395431],
		["Houston","TX",29.76,-95.38,39396132],
		["Ignacio","CO",37.11,-107.63,39396833],
		["Kanab","UT",37.27,-112.23,39397534],
		["San Diego","CA",32.77,-117.1,39398235],
		["Chattaroy","WA",47.92,-117.28,39398936],
		["Gasport","NY",43.19,-78.57,39399636],
		["Barboursville","VA",38.17,-78.28,39400336],
		["Aynor","SC",33.99,-79.2,39401036],
		["Polk City","IA",41.77,-93.71,39401736],
		["Wingdale","NY",41.64,-73.56,39402435],
		["Port Saint Lucie","FL",27.27,-80.35,39403134],
		["Laceys Spring","AL",34.48,-86.62,39403833],
		["Lynchburg","OH",39.24,-83.78,39404532],
		["Winchester","OH",38.94,-83.65,39405231],
		["Bellevue","IA",42.25,-90.42,39405930],
		["Meraux","LA",29.92,-89.91,39406629],
		["San Juan","PR",18.4,-66.06,39407327],
		["Linden","NC",35.25,-78.74,39408025],
		["Summerdale","AL",30.48,-87.7,39408723],
		["Laurelville","OH",39.47,-82.73,39409421],
		["Minster","OH",40.39,-84.37,39410119],
		["Chaffee","MO",37.18,-89.66,39410817],
		["Rexburg","ID",43.81,-111.78,39411515],
		["Meredith","NH",43.65,-71.5,39412212],
		["Tupper Lake","NY",44.23,-74.46,39412909],
		["Osprey","FL",27.19,-82.49,39413606],
		["Frazeysburg","OH",40.11,-82.11,39414303],
		["Cashton","WI",43.74,-90.78,39415000],
		["Johnston City","IL",37.82,-88.92,39415697],
		["Ogallala","NE",41.13,-101.72,39416394],
		["Beggs","OK",35.78,-96.02,39417091],
		["Mohawk","NY",43,-75,39417787],
		["Gates","NC",36.5,-76.76,39418483],
		["Galivants Ferry","SC",34.05,-79.24,39419179],
		["Cosby","TN",35.81,-83.25,39419875],
		["Millersburg","IN",41.52,-85.69,39420571],
		["Highwood","IL",42.19,-87.8,39421267],
		["Sparta","MO",37,-93.08,39421963],
		["Yuma","CO",40.12,-102.72,39422659],
		["Kittery","ME",43.09,-70.73,39423354],
		["Farrell","PA",41.2,-80.49,39424049],
		["Science Hill","KY",37.17,-84.63,39424744],
		["Goodland","KS",39.34,-101.7,39425439],
		["Cave City","AR",35.94,-91.54,39426134],
		["Vian","OK",35.49,-94.97,39426829],
		["Hughes Springs","TX",32.99,-94.63,39427524],
		["Barrow","AK",71.28,-156.78,39428219],
		["Marion","MA",41.7,-70.76,39428913],
		["West Berlin","NJ",39.8,-74.93,39429607],
		["Zelienople","PA",40.78,-80.14,39430301],
		["Poca","WV",38.47,-81.81,39430995],
		["Punta Gorda","FL",26.89,-82.05,39431689],
		["Hawesville","KY",37.89,-86.75,39432383],
		["Horse Cave","KY",37.17,-85.91,39433077],
		["Buffalo","TX",31.46,-96.06,39433771],
		["San Antonio","TX",29.45,-98.5,39434465],
		["Morrisville","VT",44.55,-72.59,39435158],
		["Lafayette","NJ",41.09,-74.68,39435851],
		["Asbury","NJ",40.67,-75.02,39436544],
		["Gowanda","NY",42.46,-78.93,39437237],
		["Lake City","PA",42.01,-80.34,39437930],
		["Okatie","SC",32.3,-80.92,39438623],
		["North Liberty","IN",41.53,-86.43,39439316],
		["West Point","NE",41.83,-96.7,39440009],
		["Chouteau","OK",36.18,-95.33,39440702],
		["Grangeville","ID",45.9,-116.13,39441395],
		["Olalla","WA",47.43,-122.57,39442088],
		["Gouldsboro","PA",41.24,-75.45,39442780],
		["Century","FL",30.97,-87.26,39443472],
		["Seale","AL",32.3,-85.18,39444164],
		["Friendsville","TN",35.75,-84.13,39444856],
		["Tishomingo","OK",34.23,-96.67,39445548],
		["Stephenville","TX",32.21,-98.21,39446240],
		["Pinehurst","TX",30.18,-95.7,39446932],
		["Toledo","OR",44.6,-123.89,39447624],
		["Rockland","ME",44.12,-69.13,39448315],
		["West Chazy","NY",44.79,-73.52,39449006],
		["Stanardsville","VA",38.3,-78.43,39449697],
		["Hodges","SC",34.28,-82.24,39450388],
		["Edgar","WI",44.92,-89.96,39451079],
		["Cheney","KS",37.62,-97.78,39451770],
		["Rock Spring","GA",34.81,-85.24,39452460],
		["Bulls Gap","TN",36.26,-83.07,39453150],
		["De Witt","AR",34.28,-91.33,39453840],
		["Waskom","TX",32.47,-94.06,39454530],
		["Kenedy","TX",28.81,-97.85,39455220],
		["Huachuca City","AZ",31.73,-110.31,39455910],
		["Glenwood","MD",39.28,-77.02,39456599],
		["Tryon","NC",35.2,-82.23,39457288],
		["Jamestown","RI",41.48,-71.36,39457976],
		["Port Monmouth","NJ",40.43,-74.09,39458664],
		["Lamar","SC",34.16,-80.06,39459352],
		["Belden","MS",34.27,-88.82,39460040],
		["Byron","MI",42.82,-83.94,39460728],
		["Missouri Valley","IA",41.55,-95.89,39461416],
		["Windom","MN",43.86,-95.11,39462104],
		["Pelican Rapids","MN",46.57,-96.08,39462792],
		["Nocona","TX",33.78,-97.73,39463480],
		["San Francisco","CA",37.79,-122.4,39464168],
		["Mather","CA",38.54,-121.28,39464856],
		["Connell","WA",46.66,-118.9,39465544],
		["San Juan","PR",18.4,-66.06,39466231],
		["East Granby","CT",41.93,-72.71,39466918],
		["Charlotte Hall","MD",38.47,-76.78,39467605],
		["Allenhurst","GA",31.78,-81.6,39468292],
		["Ashland","AL",33.27,-85.83,39468979],
		["Whitley City","KY",36.72,-84.47,39469666],
		["Cabool","MO",37.12,-92.1,39470353],
		["Alturas","CA",41.43,-120.53,39471040],
		["North Easton","MA",42.05,-71.1,39471726],
		["Mount Union","PA",40.38,-77.88,39472412],
		["Mount Olive","AL",33.67,-86.89,39473098],
		["Leesburg","OH",39.34,-83.55,39473784],
		["Geneva","IN",40.59,-84.95,39474470],
		["Fall Creek","WI",44.76,-91.27,39475156],
		["Cumberland","WI",45.53,-92.02,39475842],
		["Dewey","OK",36.78,-95.93,39476528],
		["Rainier","WA",46.87,-122.66,39477214],
		["Lee","MA",42.3,-73.25,39477899],
		["Northern Cambria","PA",40.66,-78.78,39478584],
		["New Bloomfield","PA",40.41,-77.18,39479269],
		["Galena","IL",42.42,-90.42,39479954],
		["George West","TX",28.33,-98.11,39480639],
		["Blanco","TX",30.09,-98.42,39481324],
		["Beaver","UT",38.38,-112.66,39482009],
		["Roseland","NJ",40.82,-74.3,39482693],
		["Mount Ephraim","NJ",39.88,-75.09,39483377],
		["Niagara University","NY",43.13,-79.03,39484061],
		["Mount Jackson","VA",38.74,-78.63,39484745],
		["Lake Ozark","MO",38.18,-92.62,39485429],
		["Tacoma","WA",47.24,-122.53,39486113],
		["Harlan","KY",36.84,-83.32,39486796],
		["Columbus","OH",39.98,-82.98,39487479],
		["Vevay","IN",38.74,-85.07,39488162],
		["Arcola","IL",39.68,-88.3,39488845],
		["Stormville","NY",41.54,-73.73,39489527],
		["Hinton","WV",37.66,-80.88,39490209],
		["Kingwood","WV",39.47,-79.68,39490891],
		["Mosheim","TN",36.19,-82.95,39491573],
		["North Grosvenordale","CT",41.98,-71.9,39492254],
		["Red Boiling Springs","TN",36.53,-85.85,39492935],
		["Dimondale","MI",42.64,-84.64,39493616],
		["Black Creek","WI",44.47,-88.45,39494297],
		["Falls City","NE",40.06,-95.59,39494978],
		["Kettle Falls","WA",48.74,-118.09,39495659],
		["Clinton","NJ",40.63,-74.91,39496339],
		["Middleport","NY",43.21,-78.47,39497019],
		["Shermans Dale","PA",40.33,-77.19,39497699],
		["Stony Point","NC",35.86,-81.04,39498379],
		["Alexandria","AL",33.78,-85.9,39499059],
		["Nancy","KY",37.07,-84.75,39499739],
		["Bremerton","WA",47.56,-122.64,39500419],
		["Tacoma","WA",47.26,-122.48,39501099],
		["Grantham","PA",40.23,-77.02,39501778],
		["Liberty Center","OH",41.44,-84,39502457],
		["Waite Park","MN",45.55,-94.22,39503136],
		["Hawley","MN",46.87,-96.31,39503815],
		["Roaring Spring","PA",40.33,-78.39,39504493],
		["Cumberland","VA",37.49,-78.24,39505171],
		["Hardy","VA",37.18,-79.8,39505849],
		["Franklinville","NC",35.74,-79.69,39506527],
		["Norlina","NC",36.44,-78.19,39507205],
		["Glouster","OH",39.5,-82.08,39507883],
		["Monroe","IN",40.74,-84.94,39508561],
		["Galliano","LA",29.43,-90.3,39509239],
		["Corning","AR",36.41,-90.58,39509917],
		["Orofino","ID",46.48,-116.12,39510595],
		["Tucson","AZ",31.9,-111.36,39511273],
		["Peach Bottom","PA",39.76,-76.18,39511950],
		["Fruitland","MD",38.32,-75.62,39512627],
		["Dearing","GA",33.41,-82.38,39513304],
		["Molino","FL",30.71,-87.32,39513981],
		["Melrose","FL",29.7,-82.05,39514658],
		["Saint Bonifacius","MN",44.9,-93.74,39515335],
		["Pineville","MO",36.59,-94.38,39516012],
		["Denver","CO",39.76,-104.87,39516689],
		["Nassau","NY",42.51,-73.61,39517365],
		["Factoryville","PA",41.56,-75.78,39518041],
		["Knoxville","MD",39.35,-77.64,39518717],
		["Earlysville","VA",38.15,-78.48,39519393],
		["Greenville","FL",30.46,-83.63,39520069],
		["Cedar Hill","TN",36.55,-87,39520745],
		["Columbus","MI",42.85,-82.68,39521421],
		["Weyauwega","WI",44.32,-88.92,39522097],
		["Bethel","MN",45.4,-93.26,39522773],
		["Caledonia","MN",43.63,-91.5,39523449],
		["La Verkin","UT",37.27,-113.19,39524125],
		["Dover Foxcroft","ME",45.21,-69.18,39524800],
		["Wilsonville","AL",33.23,-86.47,39525475],
		["Pierceton","IN",41.19,-85.7,39526150],
		["Frederick","OK",34.38,-99.01,39526825],
		["Gervais","OR",45.11,-122.94,39527500],
		["Delanson","NY",42.74,-74.18,39528174],
		["Caledonia","NY",42.97,-77.85,39528848],
		["Cuba","NY",42.21,-78.27,39529522],
		["Harrison City","PA",40.37,-79.67,39530196],
		["Centerville","GA",32.63,-83.67,39530870],
		["Edwards","MS",32.33,-90.6,39531544],
		["Saint Henry","OH",40.42,-84.63,39532218],
		["Boscobel","WI",43.13,-90.7,39532892],
		["Garnett","KS",38.28,-95.23,39533566],
		["Troy","TX",31.19,-97.3,39534240],
		["Austin","TX",30.26,-97.74,39534914],
		["Brandon","VT",43.8,-73.08,39535587],
		["Hunlock Creek","PA",41.2,-76.06,39536260],
		["Richlands","VA",37.09,-81.8,39536933],
		["Cassatt","SC",34.34,-80.47,39537606],
		["Cottondale","FL",30.79,-85.37,39538279],
		["Clanton","AL",32.84,-86.62,39538952],
		["Franklin","TX",31.02,-96.48,39539625],
		["Booneville","KY",37.47,-83.67,39540297],
		["Leonard","MI",42.86,-83.14,39540969],
		["Cortland","IL",41.92,-88.69,39541641],
		["Longview","TX",32.51,-94.76,39542313],
		["Magnolia","NJ",39.85,-75.03,39542984],
		["North Salem","NY",41.33,-73.59,39543655],
		["Kouts","IN",41.31,-87.02,39544326],
		["Rushville","IL",40.11,-90.56,39544997],
		["Portageville","MO",36.42,-89.69,39545668],
		["Eustace","TX",32.3,-96.01,39546339],
		["Scurry","TX",32.47,-96.41,39547010],
		["Grapeland","TX",31.49,-95.48,39547681],
		["Chester","MD",38.95,-76.28,39548351],
		["Conowingo","MD",39.68,-76.16,39549021],
		["Sanderson","FL",30.25,-82.27,39549691],
		["Mcconnelsville","OH",39.65,-81.84,39550361],
		["Oakland City","IN",38.34,-87.34,39551031],
		["Lakeview","MI",43.44,-85.27,39551701],
		["Center Point","IA",42.18,-91.77,39552371],
		["Darlington","WI",42.68,-90.11,39553041],
		["Belle Rose","LA",30.03,-91.04,39553711],
		["Leonard","TX",33.38,-96.24,39554381],
		["Topanga","CA",34.1,-118.6,39555051],
		["Jenkins","KY",37.17,-82.62,39555720],
		["Genoa","OH",41.51,-83.36,39556389],
		["Spencerville","OH",40.7,-84.35,39557058],
		["Cuba City","WI",42.6,-90.43,39557727],
		["Beloit","KS",39.46,-98.1,39558396],
		["Maud","TX",33.33,-94.34,39559065],
		["Bandon","OR",43.05,-124.36,39559734],
		["Gold Bar","WA",47.86,-121.66,39560403],
		["Candor","NC",35.29,-79.74,39561071],
		["South Charleston","OH",39.82,-83.64,39561739],
		["Rising Sun","IN",38.95,-84.85,39562407],
		["Bath","MI",42.81,-84.44,39563075],
		["Wilder","ID",43.65,-116.93,39563743],
		["Mount Angel","OR",45.06,-122.76,39564411],
		["Okanogan","WA",48.22,-119.45,39565079],
		["Manteo","NC",35.89,-75.66,39565746],
		["Enoree","SC",34.65,-81.96,39566413],
		["Kingsport","TN",36.52,-82.55,39567080],
		["Leetonia","OH",40.87,-80.76,39567747],
		["New Haven","MO",38.6,-91.21,39568414],
		["Gothenburg","NE",40.92,-100.16,39569081],
		["Stroud","OK",35.76,-96.64,39569748],
		["Fritch","TX",35.64,-101.59,39570415],
		["Cold Spring","NY",41.41,-73.95,39571081],
		["Newark Valley","NY",42.22,-76.18,39571747],
		["Naples","NY",42.61,-77.4,39572413],
		["Westfield","NY",42.32,-79.57,39573079],
		["Maysville","NC",34.9,-77.23,39573745],
		["Heath Springs","SC",34.59,-80.67,39574411],
		["Union Grove","AL",34.4,-86.44,39575077],
		["Birmingham","AL",33.52,-86.79,39575743],
		["Tama","IA",41.96,-92.57,39576409],
		["Canton","SD",43.3,-96.58,39577075],
		["Ingram","TX",30.07,-99.23,39577741],
		["Bedminster","NJ",40.65,-74.67,39578406],
		["Kirkville","NY",43.07,-75.95,39579071],
		["Ware Shoals","SC",34.39,-82.24,39579736],
		["Woodsfield","OH",39.76,-81.11,39580401],
		["Gregory","MI",42.45,-84.08,39581066],
		["Tacoma","WA",47.2,-122.37,39581731],
		["Cold Spring Harbor","NY",40.86,-73.44,39582395],
		["Oxford","NY",42.44,-75.59,39583059],
		["Freeport","PA",40.68,-79.68,39583723],
		["Marcellus","MI",42.02,-85.81,39584387],
		["Llano","TX",30.75,-98.67,39585051],
		["Seattle","WA",47.63,-122.34,39585715],
		["Fort Kent","ME",47.26,-68.57,39586378],
		["Hopewell","NJ",40.38,-74.76,39587041],
		["Woodford","VA",38.11,-77.4,39587704],
		["Flowood","MS",32.32,-90.2,39588367],
		["Lewisburg","KY",36.98,-86.95,39589030],
		["Mayville","MI",43.33,-83.35,39589693],
		["Williamsburg","IA",41.66,-92,39590356],
		["Port Barre","LA",30.55,-91.95,39591019],
		["Brusly","LA",30.39,-91.25,39591682],
		["Houston","TX",29.76,-95.38,39592345],
		["Wrightwood","CA",34.36,-117.61,39593008],
		["Waretown","NJ",39.78,-74.19,39593670],
		["Annandale On Hudson","NY",41.99,-73.83,39594332],
		["Whitehall","NY",43.56,-73.41,39594994],
		["Russell","KY",38.51,-82.69,39595656],
		["New Baden","IL",38.53,-89.69,39596318],
		["Spirit Lake","ID",47.98,-116.89,39596980],
		["Richgrove","CA",35.81,-119.13,39597642],
		["Fryeburg","ME",44.01,-70.97,39598303],
		["Pittsfield","ME",44.77,-69.38,39598964],
		["Flintstone","GA",34.92,-85.35,39599625],
		["Jayess","MS",31.41,-90.18,39600286],
		["Akron","OH",41.08,-81.52,39600947],
		["Chisholm","MN",47.48,-92.87,39601608],
		["Windsor","MO",38.53,-93.52,39602269],
		["Schriever","LA",29.73,-90.82,39602930],
		["Luther","OK",35.66,-97.18,39603591],
		["Salem","CT",41.48,-72.27,39604251],
		["Preston","MD",38.71,-75.9,39604911],
		["Fort Lauderdale","FL",26.14,-80.13,39605571],
		["Geneva","AL",31.03,-85.87,39606231],
		["Nashville","IL",38.34,-89.37,39606891],
		["Houston","TX",29.76,-95.38,39607551],
		["Platteville","CO",40.21,-104.82,39608211],
		["Peridot","AZ",33.21,-110.53,39608871],
		["Tacoma","WA",47.26,-122.45,39609531],
		["Port Byron","NY",43.03,-76.62,39610190],
		["Bainbridge","NY",42.29,-75.48,39610849],
		["Venice","FL",27.09,-82.43,39611508],
		["Jackson","LA",30.83,-91.21,39612167],
		["White Castle","LA",30.16,-91.14,39612826],
		["Corrigan","TX",30.99,-94.82,39613485],
		["White Haven","PA",41.06,-75.77,39614143],
		["Land O Lakes","FL",28.24,-82.49,39614801],
		["Fyffe","AL",34.45,-85.9,39615459],
		["Elkhorn City","KY",37.3,-82.34,39616117],
		["Le Claire","IA",41.59,-90.36,39616775],
		["Cambridge","WI",43,-89.01,39617433],
		["Lincoln","NE",40.81,-96.68,39618091],
		["Henrietta","TX",33.81,-98.19,39618749],
		["Saint Michaels","AZ",35.64,-109.26,39619407],
		["Mescalero","NM",33.15,-105.79,39620065],
		["Hoopa","CA",41.11,-123.64,39620723],
		["Newton","NH",42.86,-71.03,39621380],
		["Mattituck","NY",41,-72.53,39622037],
		["Adamstown","MD",39.29,-77.45,39622694],
		["Hurt","VA",37.09,-79.29,39623351],
		["Mantachie","MS",34.32,-88.49,39624008],
		["Piggott","AR",36.38,-90.2,39624665],
		["Stonington","CT",41.33,-71.9,39625321],
		["Silver Creek","NY",42.54,-79.16,39625977],
		["Martinsburg","PA",40.31,-78.32,39626633],
		["Catawissa","PA",40.95,-76.46,39627289],
		["Sterrett","AL",33.43,-86.5,39627945],
		["Benton","TN",35.17,-84.65,39628601],
		["Lodi","OH",41.03,-82.01,39629257],
		["Clayton","OH",39.86,-84.35,39629913],
		["Jefferson","IA",42.01,-94.37,39630569],
		["Casey","IL",39.3,-87.98,39631225],
		["Malad City","ID",42.24,-112.3,39631881],
		["Pima","AZ",33,-109.88,39632537],
		["North Dighton","MA",41.85,-71.15,39633192],
		["Oakmont","PA",40.51,-79.83,39633847],
		["Ronks","PA",40.01,-76.16,39634502],
		["Ponce De Leon","FL",30.72,-85.93,39635157],
		["Carver","MN",44.76,-93.62,39635812],
		["Evanston","IL",42.04,-87.68,39636467],
		["Dupo","IL",38.51,-90.2,39637122],
		["Stanton","TX",32.12,-101.79,39637777],
		["Lindale","GA",34.18,-85.18,39638431],
		["Zebulon","GA",33.1,-84.34,39639085],
		["Heidelberg","MS",31.89,-88.98,39639739],
		["Ferdinand","IN",38.22,-86.86,39640393],
		["Otisville","MI",43.16,-83.52,39641047],
		["Hinckley","MN",46.01,-92.93,39641701],
		["Collegeville","MN",45.64,-94.31,39642355],
		["Chicago","IL",41.83,-87.68,39643009],
		["Danville","AR",35.05,-93.39,39643663],
		["Valley View","TX",33.48,-97.16,39644317],
		["Cactus","TX",36.04,-102.01,39644971],
		["Tularosa","NM",33.07,-106.02,39645625],
		["Los Angeles","CA",34,-118.21,39646279],
		["West Newbury","MA",42.8,-71,39646932],
		["Baldwin","MD",39.51,-76.49,39647585],
		["Brunswick","MD",39.31,-77.61,39648238],
		["Crisfield","MD",37.98,-75.85,39648891],
		["Montegut","LA",29.46,-90.55,39649544],
		["Schulenburg","TX",29.68,-96.9,39650197],
		["Algodones","NM",35.38,-106.47,39650850],
		["Hawthorne","NY",41.1,-73.79,39651502],
		["Conestoga","PA",39.93,-76.36,39652154],
		["Beaver","WV",37.74,-81.15,39652806],
		["Alamo","TN",35.78,-89.11,39653458],
		["Fresno","OH",40.36,-81.75,39654110],
		["Dennison","OH",40.39,-81.32,39654762],
		["Howe","TX",33.51,-96.61,39655414],
		["Lake Isabella","CA",35.64,-118.46,39656066],
		["Southport","CT",41.14,-73.28,39656717],
		["Macon","GA",32.83,-83.65,39657368],
		["New Hope","AL",34.53,-86.41,39658019],
		["Jasonville","IN",39.16,-87.19,39658670],
		["Sheridan","MI",43.21,-85.07,39659321],
		["Webberville","MI",42.66,-84.18,39659972],
		["Mayer","AZ",34.34,-112.03,39660623],
		["Santa Ynez","CA",34.64,-120.02,39661274],
		["Sherburne","NY",42.67,-75.49,39661924],
		["Claysville","PA",40.12,-80.41,39662574],
		["Auburn","PA",40.59,-76.09,39663224],
		["Summerton","SC",33.6,-80.35,39663874],
		["Moorhead","MS",33.44,-90.5,39664524],
		["Crab Orchard","KY",37.46,-84.5,39665174],
		["Dassel","MN",45.08,-94.3,39665824],
		["Thayer","MO",36.52,-91.54,39666474],
		["Oneill","NE",42.46,-98.64,39667124],
		["Mayflower","AR",34.97,-92.41,39667774],
		["Bosque Farms","NM",34.85,-106.7,39668424],
		["Laughlin","NV",35.1,-114.71,39669074],
		["Carson City","NV",39.05,-119.83,39669724],
		["Vale","OR",44.03,-117.38,39670374],
		["Milford","IN",41.41,-85.84,39671023],
		["Noel","MO",36.54,-94.48,39671672],
		["Palisade","CO",39.1,-108.35,39672321],
		["Burnt Hills","NY",42.91,-73.9,39672969],
		["Kerhonkson","NY",41.77,-74.29,39673617],
		["Eutawville","SC",33.39,-80.34,39674265],
		["Memphis","TN",35.1,-90,39674913],
		["Sabina","OH",39.49,-83.63,39675561],
		["Lexington","IN",38.65,-85.61,39676209],
		["Hahnville","LA",29.97,-90.42,39676857],
		["Staatsburg","NY",41.84,-73.92,39677504],
		["Chester","WV",40.61,-80.56,39678151],
		["Riceville","TN",35.38,-84.69,39678798],
		["Curtice","OH",41.63,-83.31,39679445],
		["Newbury","OH",41.47,-81.23,39680092],
		["North Baltimore","OH",41.18,-83.67,39680739],
		["Camanche","IA",41.79,-90.27,39681386],
		["Somonauk","IL",41.63,-88.68,39682033],
		["East Dubuque","IL",42.49,-90.63,39682680],
		["Gallup","NM",35.52,-108.73,39683327],
		["Marina Del Rey","CA",33.97,-118.43,39683974],
		["Forest City","PA",41.65,-75.46,39684620],
		["Blue Ridge","VA",37.38,-79.82,39685266],
		["Vass","NC",35.25,-79.28,39685912],
		["Cordova","SC",33.43,-80.92,39686558],
		["Aragon","GA",34.04,-85.05,39687204],
		["Hot Springs","SD",43.43,-103.48,39687850],
		["Saint Anne","IL",41.02,-87.71,39688496],
		["Arcadia","LA",32.55,-92.92,39689142],
		["Raymond","ME",43.9,-70.47,39689787],
		["North Stonington","CT",41.44,-71.89,39690432],
		["Carnesville","GA",34.36,-83.23,39691077],
		["Double Springs","AL",34.14,-87.4,39691722],
		["Kingston","OH",39.47,-82.91,39692367],
		["Olympia Fields","IL",41.51,-87.69,39693012],
		["Ticonderoga","NY",43.84,-73.42,39693656],
		["Phelps","NY",42.95,-77.06,39694300],
		["Saint Bonaventure","NY",42.08,-78.47,39694944],
		["Carmichaels","PA",39.89,-79.97,39695588],
		["Cogan Station","PA",41.31,-77.03,39696232],
		["Havana","IL",40.29,-90.05,39696876],
		["Hatch","NM",32.66,-107.15,39697520],
		["Lee","NH",43.1,-71.01,39698163],
		["Ho Ho Kus","NJ",40.99,-74.09,39698806],
		["Greensboro","MD",38.97,-75.8,39699449],
		["Andrews","NC",35.19,-83.82,39700092],
		["Martin","GA",34.48,-83.18,39700735],
		["Danville","OH",40.44,-82.26,39701378],
		["Montello","WI",43.79,-89.33,39702021],
		["Lathrop","MO",39.54,-94.32,39702664],
		["Kemah","TX",29.52,-95.02,39703307],
		["Blue Point","NY",40.75,-73.03,39703949],
		["Montross","VA",38.09,-76.82,39704591],
		["Goodview","VA",37.21,-79.71,39705233],
		["Braxton","MS",32.02,-89.97,39705875],
		["Inez","KY",37.86,-82.53,39706517],
		["Hope","IN",39.29,-85.76,39707159],
		["Hayti","MO",36.23,-89.74,39707801],
		["Clever","MO",37.02,-93.47,39708443],
		["Oklahoma City","OK",35.46,-97.51,39709085],
		["Sonora","TX",30.57,-100.64,39709727],
		["Pinetop","AZ",34.08,-109.9,39710369],
		["Saint Johns","AZ",34.56,-109.33,39711011],
		["Angels Camp","CA",38.04,-120.61,39711653],
		["Meadow Vista","CA",39,-121.03,39712295],
		["Burbank","WA",46.22,-118.82,39712937],
		["Nome","AK",64.71,-164.62,39713579],
		["Falmouth","MA",41.56,-70.62,39714220],
		["Bridgton","ME",44.06,-70.72,39714861],
		["Mineral Ridge","OH",41.13,-80.76,39715502],
		["Rush City","MN",45.68,-92.96,39716143],
		["Dover Plains","NY",41.74,-73.58,39716783],
		["Samson","AL",31.11,-86.04,39717423],
		["Kevil","KY",37.08,-88.88,39718063],
		["Borden","IN",38.46,-85.95,39718703],
		["Holt","MO",39.45,-94.34,39719343],
		["Forsyth","MO",36.68,-93.1,39719983],
		["Sayre","OK",35.29,-99.63,39720623],
		["Hemphill","TX",31.34,-93.85,39721263],
		["Truth Or Consequences","NM",33.13,-107.24,39721903],
		["Hope Valley","RI",41.51,-71.72,39722542],
		["Martinsville","NJ",40.6,-74.56,39723181],
		["Shenandoah","VA",38.48,-78.62,39723820],
		["Heiskell","TN",36.12,-84.04,39724459],
		["Fredonia","WI",43.46,-87.95,39725098],
		["New Holstein","WI",43.94,-88.09,39725737],
		["Thorp","WI",44.95,-90.8,39726376],
		["Mission","SD",43.3,-100.66,39727015],
		["Teutopolis","IL",39.13,-88.47,39727654],
		["Lake Village","AR",33.32,-91.28,39728293],
		["Wellston","OK",35.68,-97.06,39728932],
		["Lisbon Falls","ME",44,-70.05,39729570],
		["Port Crane","NY",42.16,-75.83,39730208],
		["Birmingham","AL",33.52,-86.79,39730846],
		["Hubertus","WI",43.23,-88.24,39731484],
		["Bagley","MN",47.52,-95.4,39732122],
		["Deer Lodge","MT",46.37,-112.72,39732760],
		["Baton Rouge","LA",30.44,-91.12,39733398],
		["Fulshear","TX",29.69,-95.88,39734036],
		["Lafayette","OR",45.24,-123.11,39734674],
		["Lima","NY",42.9,-77.61,39735311],
		["Pound","VA",37.12,-82.6,39735948],
		["Orange Beach","AL",30.28,-87.59,39736585],
		["Roan Mountain","TN",36.18,-82.07,39737222],
		["Portland","OR",45.52,-122.64,39737859],
		["Lansing","NY",42.48,-76.48,39738495],
		["Monroe","VA",37.6,-79.24,39739131],
		["Alva","FL",26.71,-81.61,39739767],
		["Winchester","NH",42.77,-72.38,39740402],
		["East Quogue","NY",40.85,-72.57,39741037],
		["Robesonia","PA",40.34,-76.14,39741672],
		["Wagener","SC",33.65,-81.36,39742307],
		["Chattanooga","TN",35.06,-85.25,39742942],
		["Waterloo","IN",41.43,-85.02,39743577],
		["Iron River","MI",46.09,-88.63,39744212],
		["Custer","SD",43.76,-103.6,39744847],
		["Trenton","IL",38.6,-89.68,39745482],
		["Mccall","ID",44.88,-115.77,39746117],
		["North Brookfield","MA",42.27,-72.08,39746751],
		["Johnstown","PA",40.32,-78.91,39747385],
		["Thornton","PA",39.89,-75.53,39748019],
		["Fayetteville","NC",35.05,-78.87,39748653],
		["Ellerbe","NC",35.07,-79.76,39749287],
		["Clarkson","KY",37.49,-86.22,39749921],
		["Garfield","AR",36.45,-93.96,39750555],
		["Aberdeen","ID",43.09,-112.84,39751189],
		["Diamond Springs","CA",38.67,-120.81,39751823],
		["Honokaa","HI",20.07,-155.46,39752457],
		["Coupeville","WA",48.18,-122.66,39753091],
		["Fairfax","VT",44.67,-73.02,39753724],
		["Murrayville","GA",34.44,-83.89,39754357],
		["Clinton","WI",42.55,-88.86,39754990],
		["Pecatonica","IL",42.3,-89.35,39755623],
		["Anza","CA",33.53,-116.68,39756256],
		["Bucksport","ME",44.6,-68.79,39756888],
		["Pequannock","NJ",40.94,-74.29,39757520],
		["Lemoyne","PA",40.24,-76.89,39758152],
		["Canon","GA",34.34,-83.11,39758784],
		["Stevenson","AL",34.87,-85.83,39759416],
		["Andover","OH",41.6,-80.56,39760048],
		["Culver","IN",41.21,-86.42,39760680],
		["Avoca","MI",43.05,-82.67,39761312],
		["Colfax","WI",44.99,-91.72,39761944],
		["Minot Afb","ND",48.41,-101.33,39762576],
		["Kansas City","MO",39.09,-94.58,39763208],
		["Bismarck","AR",34.33,-93.19,39763840],
		["Teec Nos Pos","AZ",36.89,-109.37,39764472],
		["Rye","NH",43.01,-70.77,39765103],
		["Edgewater","NJ",40.82,-73.97,39765734],
		["Greentown","PA",41.32,-75.3,39766365],
		["Fremont","NC",35.54,-77.97,39766996],
		["Ragland","AL",33.74,-86.13,39767627],
		["Yellow Springs","OH",39.79,-83.89,39768258],
		["Scott City","KS",38.47,-100.9,39768889],
		["Iota","LA",30.32,-92.49,39769520],
		["Tyler","TX",32.32,-95.3,39770151],
		["Godley","TX",32.44,-97.52,39770782],
		["Pinedale","WY",42.86,-109.86,39771413],
		["Midway","UT",40.5,-111.52,39772044],
		["Mertztown","PA",40.49,-75.68,39772674],
		["Gotha","FL",28.54,-81.52,39773304],
		["Chapmansboro","TN",36.37,-87.11,39773934],
		["Enon","OH",39.86,-83.93,39774564],
		["Lanesville","IN",38.23,-85.98,39775194],
		["Milbank","SD",45.21,-96.63,39775824],
		["Poplar","MT",48.3,-105.19,39776454],
		["Elsberry","MO",39.17,-90.78,39777084],
		["Goodfellow Afb","TX",31.43,-100.4,39777714],
		["Palo Cedro","CA",40.56,-122.2,39778344],
		["Hiawassee","GA",34.94,-83.75,39778973],
		["Pierson","FL",29.23,-81.46,39779602],
		["Walbridge","OH",41.58,-83.48,39780231],
		["New London","MN",45.29,-94.94,39780860],
		["Oklahoma City","OK",35.46,-97.51,39781489],
		["Dundee","OR",45.26,-123.02,39782118],
		["Cle Elum","WA",47.2,-120.9,39782747],
		["West Kingston","RI",41.5,-71.59,39783375],
		["Bristol","NH",43.6,-71.74,39784003],
		["Willard","NC",34.69,-77.98,39784631],
		["Miami","FL",25.77,-80.2,39785259],
		["Chattanooga","TN",35.06,-85.25,39785887],
		["Butler","KY",38.78,-84.37,39786515],
		["Walworth","WI",42.53,-88.59,39787143],
		["Chatfield","MN",43.84,-92.18,39787771],
		["Pitkin","LA",30.93,-92.93,39788399],
		["Pebble Beach","CA",36.58,-121.94,39789027],
		["Johnson","VT",44.63,-72.67,39789654],
		["Stockton","NJ",40.4,-74.97,39790281],
		["Donora","PA",40.17,-79.86,39790908],
		["Munroe Falls","OH",41.13,-81.43,39791535],
		["Peosta","IA",42.44,-90.85,39792162],
		["Saint Croix Falls","WI",45.4,-92.63,39792789],
		["Bauxite","AR",34.55,-92.52,39793416],
		["Marana","AZ",32.51,-111.16,39794043],
		["Syracuse","NY",43.04,-76.14,39794669],
		["Mckeesport","PA",40.33,-79.83,39795295],
		["Waymart","PA",41.58,-75.4,39795921],
		["Rolesville","NC",35.92,-78.45,39796547],
		["Branford","FL",29.96,-82.92,39797173],
		["Lebanon Junction","KY",37.83,-85.72,39797799],
		["Osgood","IN",39.12,-85.29,39798425],
		["Westby","WI",43.65,-90.85,39799051],
		["Sheridan","IL",41.52,-88.68,39799677],
		["Crane","MO",36.9,-93.57,39800303],
		["Mc Gehee","AR",33.62,-91.39,39800929],
		["Rochester","NH",43.3,-70.97,39801554],
		["Strafford","NH",43.32,-71.18,39802179],
		["Richmond","VT",44.4,-73,39802804],
		["Waterbury","VT",44.33,-72.75,39803429],
		["Cooperstown","NY",42.7,-74.92,39804054],
		["Marathon","NY",42.44,-76.03,39804679],
		["Albion","PA",41.88,-80.36,39805304],
		["Taylor","PA",41.39,-75.71,39805929],
		["Saint Marys City","MD",38.18,-76.42,39806554],
		["Highland","MD",39.17,-76.95,39807179],
		["Lanexa","VA",37.42,-76.9,39807804],
		["Cecilia","KY",37.66,-85.95,39808429],
		["Casco","MI",42.76,-82.67,39809054],
		["Saint Paul","MN",44.94,-93.1,39809679],
		["Kansas City","MO",39.09,-94.58,39810304],
		["Tatum","TX",32.31,-94.51,39810929],
		["Lakebay","WA",47.28,-122.77,39811554],
		["Epsom","NH",43.22,-71.33,39812178],
		["Montgomery","PA",41.17,-76.87,39812802],
		["Oakboro","NC",35.22,-80.32,39813426],
		["Lascassas","TN",35.94,-86.29,39814050],
		["Valley City","OH",41.23,-81.92,39814674],
		["Campo","CA",32.68,-116.47,39815298],
		["Union City","MI",42.06,-85.14,39815921],
		["Marathon","WI",44.98,-89.89,39816544],
		["Clarksville","TX",33.61,-95.05,39817167],
		["Big Bear Lake","CA",34.23,-116.86,39817790],
		["Gaston","OR",45.47,-123.2,39818413],
		["Clinton","WA",47.95,-122.41,39819036],
		["Snow Hill","MD",38.17,-75.39,39819658],
		["Rotonda West","FL",26.85,-82.29,39820280],
		["Mount Carmel","TN",36.56,-82.65,39820902],
		["Rienzi","MS",34.76,-88.53,39821524],
		["Calhoun City","MS",33.85,-89.31,39822146],
		["Clarksville","OH",39.4,-83.98,39822768],
		["Hobart","OK",35.02,-99.09,39823390],
		["Colchester","VT",44.53,-73.15,39824011],
		["Rexford","NY",42.84,-73.85,39824632],
		["Allentown","PA",40.59,-75.47,39825253],
		["Estill","SC",32.75,-81.24,39825874],
		["Twin City","GA",32.58,-82.15,39826495],
		["Valdosta","GA",30.84,-83.27,39827116],
		["Masury","OH",41.23,-80.53,39827737],
		["Franklin Furnace","OH",38.6,-82.84,39828358],
		["Clifton","NJ",40.86,-74.15,39828978],
		["Florida","NY",41.33,-74.35,39829598],
		["Reddick","FL",29.36,-82.19,39830218],
		["Elko New Market","MN",44.56,-93.32,39830838],
		["Shawnee","KS",38.9,-94.68,39831458],
		["La Salle","CO",40.34,-104.7,39832078],
		["Frenchtown","NJ",40.52,-75.05,39832697],
		["Dunbar","PA",39.97,-79.61,39833316],
		["Saxonburg","PA",40.75,-79.81,39833935],
		["Mc Clure","PA",40.7,-77.31,39834554],
		["Scotland Neck","NC",36.13,-77.42,39835173],
		["Comer","GA",34.06,-83.12,39835792],
		["Canton","OH",40.81,-81.37,39836411],
		["Willis","MI",42.12,-83.57,39837030],
		["Horicon","WI",43.44,-88.63,39837649],
		["Grantsburg","WI",45.78,-92.68,39838268],
		["Plainview","MN",44.16,-92.16,39838887],
		["Brinkley","AR",34.88,-91.18,39839506],
		["East Petersburg","PA",40.1,-76.35,39840124],
		["Mahanoy City","PA",40.81,-76.13,39840742],
		["Frederica","DE",39,-75.46,39841360],
		["Hopkins","MI",42.62,-85.76,39841978],
		["Altamont","IL",39.05,-88.74,39842596],
		["Lakewood","WA",47.12,-122.5,39843214],
		["Glen Arm","MD",39.44,-76.5,39843831],
		["Newport News","VA",37.07,-76.51,39844448],
		["Sarasota","FL",27.33,-82.54,39845065],
		["Dyer","TN",36.07,-88.99,39845682],
		["Alvaton","KY",36.86,-86.35,39846299],
		["Pismo Beach","CA",35.15,-120.66,39846916],
		["Maple Falls","WA",48.95,-122.07,39847533],
		["Amston","CT",41.62,-72.37,39848149],
		["Flourtown","PA",40.1,-75.2,39848765],
		["Dagsboro","DE",38.54,-75.24,39849381],
		["Turbeville","SC",33.89,-80.01,39849997],
		["Bell","FL",29.75,-82.86,39850613],
		["Morris","AL",33.74,-86.8,39851229],
		["Woodlawn","TN",36.5,-87.54,39851845],
		["Mc Donald","TN",35.08,-84.98,39852461],
		["Haubstadt","IN",38.2,-87.57,39853077],
		["Galena","MO",36.8,-93.47,39853693],
		["Lyman","WY",41.32,-110.3,39854309],
		["Dunstable","MA",42.66,-71.48,39854924],
		["Fair Haven","VT",43.59,-73.27,39855539],
		["Mohrsville","PA",40.47,-76.02,39856154],
		["North","SC",33.61,-81.1,39856769],
		["Clearwater","FL",27.97,-82.76,39857384],
		["Nashville","TN",36.17,-86.78,39857999],
		["French Lick","IN",38.54,-86.62,39858614],
		["Juneau","WI",43.4,-88.7,39859229],
		["Lena","IL",42.38,-89.82,39859844],
		["Russell","KS",38.88,-98.85,39860459],
		["Lexington","TX",30.41,-97,39861074],
		["Silverthorne","CO",39.65,-106.08,39861689],
		["Mona","UT",39.85,-111.84,39862304],
		["Avalon","CA",33.38,-118.45,39862919],
		["North Berwick","ME",43.3,-70.73,39863533],
		["Charlotte","VT",44.3,-73.25,39864147],
		["Mason","TN",35.41,-89.53,39864761],
		["Bradford","AR",35.42,-91.45,39865375],
		["Meeker","OK",35.48,-96.89,39865989],
		["Floydada","TX",33.98,-101.33,39866603],
		["Mesa","WA",46.58,-119.15,39867217],
		["Hogansburg","NY",44.97,-74.63,39867830],
		["Rural Retreat","VA",36.89,-81.27,39868443],
		["Ona","WV",38.48,-82.22,39869056],
		["Sneedville","TN",36.53,-83.21,39869669],
		["Winneconne","WI",44.11,-88.71,39870282],
		["Benson","MN",45.31,-95.6,39870895],
		["Forestville","CA",38.48,-122.9,39871508],
		["Templeton","MA",42.55,-72.06,39872120],
		["Waldoboro","ME",44.09,-69.38,39872732],
		["York Springs","PA",40,-77.11,39873344],
		["Hartly","DE",39.16,-75.71,39873956],
		["Goochland","VA",37.68,-77.88,39874568],
		["Nichols","SC",34.23,-79.14,39875180],
		["Birmingham","AL",33.52,-86.79,39875792],
		["South Lebanon","OH",39.37,-84.21,39876404],
		["Dilley","TX",28.66,-99.17,39877016],
		["Hebron","MD",38.41,-75.68,39877627],
		["Branchland","WV",38.25,-82.25,39878238],
		["Leakesville","MS",31.15,-88.55,39878849],
		["Cairo","GA",30.97,-84.2,39879460],
		["Grand Junction","MI",42.4,-86.07,39880071],
		["Montgomery","MN",44.43,-93.58,39880682],
		["Pearcy","AR",34.43,-93.3,39881293],
		["Santo Domingo Pueblo","NM",35.51,-106.36,39881904],
		["Jay","ME",44.5,-70.21,39882514],
		["Madison","ME",44.79,-69.88,39883124],
		["Pennellville","NY",43.26,-76.24,39883734],
		["Duffield","VA",36.71,-82.79,39884344],
		["Hustonville","KY",37.47,-84.81,39884954],
		["Shadyside","OH",39.97,-80.75,39885564],
		["Leavittsburg","OH",41.24,-80.9,39886174],
		["Mc Bain","MI",44.22,-85.21,39886784],
		["Mineral Point","WI",42.86,-90.18,39887394],
		["Suamico","WI",44.63,-88.05,39888004],
		["Stanley","WI",44.96,-90.94,39888614],
		["Glenwood","MN",45.65,-95.38,39889224],
		["Glenmora","LA",30.97,-92.58,39889834],
		["Dulce","NM",36.93,-106.99,39890444],
		["Worcester","MA",42.26,-71.8,39891053],
		["Virginia Beach","VA",36.73,-76.04,39891662],
		["Smithton","IL",38.4,-89.99,39892271],
		["Colwich","KS",37.78,-97.53,39892880],
		["San Francisco","CA",37.8,-122.43,39893489],
		["Parish","NY",43.4,-76.12,39894097],
		["New Bethlehem","PA",41,-79.32,39894705],
		["Robersonville","NC",35.82,-77.25,39895313],
		["Mc Cormick","SC",33.91,-82.29,39895921],
		["De Graff","OH",40.31,-83.91,39896529],
		["Durham","CA",39.61,-121.77,39897137],
		["Greenfield Center","NY",43.13,-73.85,39897744],
		["Beaverdam","VA",37.94,-77.65,39898351],
		["Madison","NE",41.82,-97.45,39898958],
		["Cottonport","LA",30.98,-92.05,39899565],
		["Bullhead City","AZ",35.14,-114.49,39900172],
		["Seattle","WA",47.63,-122.32,39900779],
		["Hinesburg","VT",44.33,-73.12,39901385],
		["Grantsville","MD",39.69,-79.15,39901991],
		["Timberville","VA",38.63,-78.77,39902597],
		["Concord","VA",37.35,-78.98,39903203],
		["Elmore","AL",32.53,-86.31,39903809],
		["South Whitley","IN",41.08,-85.62,39904415],
		["Kawkawlin","MI",43.65,-83.94,39905021],
		["Pullman","MI",42.48,-86.09,39905627],
		["Girard","KS",37.51,-94.84,39906233],
		["Milford","NE",40.77,-97.05,39906839],
		["Quitman","AR",35.38,-92.21,39907445],
		["Fremont","NH",42.99,-71.14,39908050],
		["Northwood","NH",43.19,-71.15,39908655],
		["Englewood Cliffs","NJ",40.88,-73.94,39909260],
		["Leonardo","NJ",40.41,-74.06,39909865],
		["Florence","NJ",40.11,-74.8,39910470],
		["Windham","OH",41.23,-81.03,39911075],
		["Ely","MN",47.9,-91.85,39911680],
		["Oklahoma City","OK",35.46,-97.51,39912285],
		["Alto","TX",31.65,-95.07,39912890],
		["Laton","CA",36.43,-119.69,39913495],
		["Cross Junction","VA",39.32,-78.29,39914099],
		["Troy","TN",36.33,-89.16,39914703],
		["Wilton","IA",41.58,-91.02,39915307],
		["Deerfield","WI",43.05,-89.07,39915911],
		["Zumbrota","MN",44.29,-92.67,39916515],
		["Lame Deer","MT",45.52,-106.61,39917119],
		["Oklahoma City","OK",35.46,-97.51,39917723],
		["Pawnee","OK",36.33,-96.8,39918327],
		["Bridgeport","WA",47.97,-119.59,39918931],
		["Haskell","NJ",41.02,-74.3,39919534],
		["Osseo","WI",44.57,-91.21,39920137],
		["Houston","MO",37.32,-91.95,39920740],
		["Coalgate","OK",34.53,-96.21,39921343],
		["Fresno","CA",36.73,-119.78,39921946],
		["Irrigon","OR",45.86,-119.54,39922549],
		["Columbia","NJ",40.95,-75.06,39923151],
		["Mickleton","NJ",39.78,-75.25,39923753],
		["Mc Gaheysville","VA",38.36,-78.74,39924355],
		["Faison","NC",35.11,-78.13,39924957],
		["Cape Canaveral","FL",28.4,-80.6,39925559],
		["Silverhill","AL",30.54,-87.74,39926161],
		["Creston","OH",40.97,-81.9,39926763],
		["Algoma","WI",44.6,-87.44,39927365],
		["Steele","MO",36.08,-89.84,39927967],
		["Davis","OK",34.48,-97.13,39928569],
		["Yatahey","NM",35.61,-108.71,39929171],
		["Saint Johnsville","NY",43,-74.67,39929772],
		["Jonesville","SC",34.83,-81.67,39930373],
		["Altha","FL",30.57,-85.12,39930974],
		["Hollandale","MS",33.17,-90.85,39931575],
		["Newport","KY",39.08,-84.48,39932176],
		["Tipton","IA",41.77,-91.12,39932777],
		["Tolono","IL",39.98,-88.26,39933378],
		["Weimar","TX",29.7,-96.77,39933979],
		["Pahrump","NV",36.08,-115.92,39934580],
		["Reedsville","PA",40.65,-77.6,39935180],
		["Eastaboga","AL",33.6,-85.97,39935780],
		["Marion","MI",44.1,-85.14,39936380],
		["El Paso","IL",40.73,-89.01,39936980],
		["Carle Place","NY",40.75,-73.61,39937579],
		["Santee","SC",33.48,-80.48,39938178],
		["Malvern","OH",40.68,-81.18,39938777],
		["Richfield","WI",43.27,-88.21,39939376],
		["Fennimore","WI",42.98,-90.65,39939975],
		["New London","MO",39.58,-91.39,39940574],
		["Fox Island","WA",47.24,-122.63,39941173],
		["South Dennis","MA",41.7,-70.15,39941771],
		["South China","ME",44.39,-69.58,39942369],
		["Ashford","CT",41.86,-72.11,39942967],
		["Ridgely","MD",38.94,-75.88,39943565],
		["Fort Lee","VA",37.23,-77.33,39944163],
		["Autryville","NC",34.99,-78.64,39944761],
		["Hampton","SC",32.86,-81.1,39945359],
		["Clermont","GA",34.47,-83.77,39945957],
		["Leighton","AL",34.69,-87.53,39946555],
		["Collegedale","TN",35.04,-85.05,39947153],
		["Novelty","OH",41.47,-81.32,39947751],
		["Mc Donald","OH",41.16,-80.72,39948349],
		["Gaines","MI",42.87,-83.91,39948947],
		["Brownsboro","TX",32.29,-95.61,39949545],
		["Fort Worth","TX",32.75,-97.33,39950143],
		["Hamilton","TX",31.7,-98.12,39950741],
		["Mc Connellsburg","PA",39.93,-77.99,39951338],
		["Oglethorpe","GA",32.29,-84.06,39951935],
		["Moore Haven","FL",26.83,-81.09,39952532],
		["Shubuta","MS",31.86,-88.7,39953129],
		["Onamia","MN",46.06,-93.66,39953726],
		["Redlake","MN",47.83,-95.08,39954323],
		["Hometown","IL",41.73,-87.73,39954920],
		["Garden City","MO",38.56,-94.18,39955517],
		["Kingman","KS",37.64,-98.11,39956114],
		["Haworth","NJ",40.96,-73.99,39956710],
		["Barrington","NJ",39.87,-75.05,39957306],
		["Eighty Four","PA",40.18,-80.13,39957902],
		["Biscoe","NC",35.36,-79.78,39958498],
		["Vernon","AL",33.75,-88.11,39959094],
		["Le Roy","IL",40.34,-88.76,39959690],
		["Gillespie","IL",39.12,-89.81,39960286],
		["Louisiana","MO",39.44,-91.05,39960882],
		["Victor","ID",43.65,-111.22,39961478],
		["Conneaut Lake","PA",41.6,-80.3,39962073],
		["Liberty","MS",31.16,-90.8,39962668],
		["Rossville","IN",40.42,-86.59,39963263],
		["Jesup","IA",42.47,-92.06,39963858],
		["New Franken","WI",44.53,-87.83,39964453],
		["Newcastle","WY",43.84,-104.21,39965048],
		["Montezuma Creek","UT",37.21,-109.19,39965643],
		["Harmony","PA",40.8,-80.12,39966237],
		["Woodlyn","PA",39.87,-75.34,39966831],
		["Afton","TN",36.21,-82.74,39967425],
		["North Bend","OH",39.14,-84.74,39968019],
		["Athens","IL",39.96,-89.72,39968613],
		["Hiawatha","KS",39.85,-95.53,39969207],
		["Broken Bow","NE",41.4,-99.63,39969801],
		["Gurdon","AR",33.91,-93.14,39970395],
		["El Paso","TX",31.84,-106.43,39970989],
		["Weaverville","CA",40.74,-122.93,39971583],
		["West Lafayette","OH",40.27,-81.75,39972176],
		["Potterville","MI",42.62,-84.74,39972769],
		["Morenci","MI",41.72,-84.21,39973362],
		["Alanson","MI",45.44,-84.78,39973955],
		["Amboy","IL",41.71,-89.33,39974548],
		["Independence","MO",39.08,-94.35,39975141],
		["Portland","OR",45.55,-122.73,39975734],
		["Elk","WA",48.01,-117.22,39976327],
		["Evans Mills","NY",44.08,-75.8,39976919],
		["Jefferson","SC",34.65,-80.38,39977511],
		["Charleston","TN",35.28,-84.76,39978103],
		["Emmetsburg","IA",43.11,-94.68,39978695],
		["Arp","TX",32.22,-95.05,39979287],
		["South Paris","ME",44.21,-70.51,39979878],
		["Oakfield","NY",43.06,-78.27,39980469],
		["Catlett","VA",38.61,-77.64,39981060],
		["Shady Spring","WV",37.7,-81.09,39981651],
		["Millry","AL",31.62,-88.31,39982242],
		["Andersonville","TN",36.2,-84.04,39982833],
		["Saint Bernard","LA",29.88,-89.51,39983424],
		["Phoenix","OR",42.26,-122.81,39984015],
		["Norway","ME",44.21,-70.55,39984605],
		["Cream Ridge","NJ",40.14,-74.49,39985195],
		["Cross","SC",33.32,-80.14,39985785],
		["Carbon Hill","AL",33.89,-87.52,39986375],
		["Stanwood","MI",43.58,-85.44,39986965],
		["Eldora","IA",42.36,-93.1,39987555],
		["Wagner","SD",43.07,-98.3,39988145],
		["Westville","IL",40.04,-87.63,39988735],
		["Bunker Hill","IL",39.04,-89.95,39989325],
		["Wichita Falls","TX",33.89,-98.51,39989915],
		["Navajo","NM",35.89,-109.03,39990505],
		["Moraga","CA",37.84,-122.12,39991095],
		["Lenox","MA",42.35,-73.28,39991684],
		["Berry","AL",33.66,-87.6,39992273],
		["Gray","KY",36.94,-84,39992862],
		["Necedah","WI",44.02,-90.07,39993451],
		["Montrose","MN",45.06,-93.91,39994040],
		["Manhattan","MT",45.77,-111.37,39994629],
		["Seneca","IL",41.3,-88.61,39995218],
		["Crystal City","MO",38.22,-90.38,39995807],
		["Oroville","WA",48.87,-119.22,39996396],
		["La Fayette","NY",42.88,-76.1,39996984],
		["Pine City","NY",42.03,-76.86,39997572],
		["West Middlesex","PA",41.17,-80.45,39998160],
		["Buchanan","VA",37.52,-79.68,39998748],
		["Pisgah","AL",34.68,-85.84,39999336],
		["Sebring","OH",40.92,-81.02,39999924],
		["Hamersville","OH",38.91,-83.98,40000512],
		["Woodburn","IN",41.12,-84.85,40001100],
		["Elizabeth","IN",38.12,-85.97,40001688],
		["Keego Harbor","MI",42.6,-83.34,40002276],
		["Huxley","IA",41.89,-93.6,40002864],
		["Glasgow","MT",48.18,-106.89,40003452],
		["Ashland","NE",41.04,-96.37,40004040],
		["Hennessey","OK",36.1,-97.89,40004628],
		["Melba","ID",43.36,-116.55,40005216],
		["Providence Forge","VA",37.44,-77.04,40005803],
		["Fincastle","VA",37.49,-79.87,40006390],
		["Brooksville","KY",38.68,-84.06,40006977],
		["Hagerstown","IN",39.91,-85.15,40007564],
		["Lake","MI",43.85,-85,40008151],
		["Elsie","MI",43.08,-84.39,40008738],
		["Richmond","MN",45.45,-94.51,40009325],
		["New Town","ND",47.98,-102.49,40009912],
		["Wellsville","KS",38.71,-95.08,40010499],
		["Mead","CO",40.23,-104.98,40011086],
		["Marsing","ID",43.48,-116.84,40011673],
		["Nottingham","NH",43.11,-71.1,40012259],
		["Far Hills","NJ",40.69,-74.62,40012845],
		["Sauquoit","NY",43,-75.26,40013431],
		["Taberg","NY",43.3,-75.61,40014017],
		["Holland","NY",42.63,-78.54,40014603],
		["Mount Vernon","GA",32.18,-82.59,40015189],
		["Ottawa Lake","MI",41.76,-83.74,40015775],
		["Horace","ND",46.75,-96.9,40016361],
		["Cadet","MO",37.98,-90.68,40016947],
		["Hillsboro","KS",38.35,-97.2,40017533],
		["Monroe","UT",38.63,-111.75,40018119],
		["Camino","CA",38.74,-120.68,40018705],
		["Lawrence","MA",42.7,-71.16,40019290],
		["Shrewsbury","NJ",40.32,-74.05,40019875],
		["Knox","PA",41.23,-79.53,40020460],
		["Hays","NC",36.24,-81.11,40021045],
		["Fort Branch","IN",38.24,-87.57,40021630],
		["Holton","MI",43.41,-86.07,40022215],
		["Willow Springs","IL",41.73,-87.88,40022800],
		["Valley Mills","TX",31.65,-97.47,40023385],
		["Stockdale","TX",29.23,-97.96,40023970],
		["Deerfield","NH",43.14,-71.21,40024554],
		["Springvale","ME",43.46,-70.8,40025138],
		["Millington","NJ",40.66,-74.51,40025722],
		["Rochester","NY",43.16,-77.61,40026306],
		["Emporium","PA",41.51,-78.23,40026890],
		["Emlenton","PA",41.18,-79.7,40027474],
		["Mill Spring","NC",35.29,-82.16,40028058],
		["Wrens","GA",33.2,-82.38,40028642],
		["Solsberry","IN",39.12,-86.75,40029226],
		["Oglesby","IL",41.29,-89.06,40029810],
		["Lyons","KS",38.34,-98.2,40030394],
		["Preston","CT",41.55,-71.99,40030977],
		["De Kalb","MS",32.76,-88.65,40031560],
		["Saint Louis","MO",38.63,-90.24,40032143],
		["Hallsville","MO",39.11,-92.21,40032726],
		["Jarrell","TX",30.81,-97.6,40033309],
		["Burlington","CO",39.3,-102.27,40033892],
		["Sidney","NY",42.31,-75.39,40034474],
		["Shortsville","NY",42.95,-77.22,40035056],
		["Pacolet","SC",34.89,-81.76,40035638],
		["Cedar Bluff","AL",34.22,-85.59,40036220],
		["Mc Intosh","AL",31.26,-88.02,40036802],
		["Jamestown","KY",36.99,-85.07,40037384],
		["Manito","IL",40.42,-89.78,40037966],
		["David City","NE",41.25,-97.12,40038548],
		["Central City","NE",41.11,-98,40039130],
		["Houghton","NY",42.42,-78.16,40039711],
		["Spartansburg","PA",41.82,-79.68,40040292],
		["Millerstown","PA",40.55,-77.15,40040873],
		["Baltimore","MD",39.3,-76.61,40041454],
		["Cleveland","AL",33.99,-86.57,40042035],
		["Madrid","IA",41.87,-93.81,40042616],
		["Dunseith","ND",48.81,-100.06,40043197],
		["Eureka","MT",48.82,-114.93,40043778],
		["Ironton","MO",37.59,-90.63,40044359],
		["Manitou Springs","CO",38.85,-104.91,40044940],
		["Amity","OR",45.1,-123.23,40045521],
		["Boylston","MA",42.35,-71.73,40046101],
		["Franklinville","NY",42.33,-78.45,40046681],
		["Linden","VA",38.9,-78.07,40047261],
		["Orangeburg","SC",33.49,-80.86,40047841],
		["Warrenton","GA",33.4,-82.66,40048421],
		["Fountain","FL",30.47,-85.42,40049001],
		["Duncanville","AL",33.06,-87.42,40049581],
		["Blue Springs","MS",34.4,-88.87,40050161],
		["Ackerman","MS",33.31,-89.17,40050741],
		["Montgomery City","MO",38.97,-91.5,40051321],
		["Gramercy","LA",30.05,-90.69,40051901],
		["Lordsburg","NM",32.34,-108.7,40052481],
		["Ocean View","HI",19.1,-155.76,40053061],
		["East Walpole","MA",42.15,-71.21,40053640],
		["Cattaraugus","NY",42.32,-78.86,40054219],
		["Port Republic","MD",38.49,-76.54,40054798],
		["Sharpsburg","MD",39.45,-77.74,40055377],
		["Midway Park","NC",34.73,-77.3,40055956],
		["Charleston","SC",32.78,-79.99,40056535],
		["Fort Pierce","FL",27.44,-80.32,40057114],
		["New Paris","IN",41.5,-85.82,40057693],
		["Loretto","MN",45.05,-93.63,40058272],
		["Bethany","MO",40.26,-94.02,40058851],
		["La Mesa","NM",32.12,-106.7,40059430],
		["Esparto","CA",38.78,-122.12,40060009],
		["Greene","ME",44.18,-70.14,40060587],
		["Marmora","NJ",39.26,-74.66,40061165],
		["Lake George","NY",43.42,-73.71,40061743],
		["Crum Lynne","PA",39.86,-75.33,40062321],
		["Satsuma","FL",29.55,-81.65,40062899],
		["Alpine","AL",33.33,-86.18,40063477],
		["Brent","AL",32.94,-87.17,40064055],
		["Brundidge","AL",31.71,-85.81,40064633],
		["Crofton","KY",37.04,-87.48,40065211],
		["Spencerville","IN",41.27,-84.92,40065789],
		["Crandon","WI",45.56,-88.89,40066367],
		["Ronceverte","WV",37.74,-80.47,40066944],
		["Efland","NC",36.06,-79.17,40067521],
		["Great Falls","SC",34.57,-80.9,40068098],
		["Mansura","LA",31.05,-92.05,40068675],
		["Glenrock","WY",42.85,-105.85,40069252],
		["San Miguel","CA",35.89,-120.6,40069829],
		["Hadley","MA",42.35,-72.58,40070405],
		["Troy","PA",41.78,-76.78,40070981],
		["Niota","TN",35.51,-84.54,40071557],
		["Walland","TN",35.72,-83.81,40072133],
		["Nineveh","IN",39.38,-86.08,40072709],
		["Gosport","IN",39.35,-86.66,40073285],
		["Waverly","NE",40.91,-96.53,40073861],
		["Pocola","OK",35.24,-94.47,40074437],
		["Temecula","CA",33.48,-117.22,40075013],
		["Sausalito","CA",37.84,-122.53,40075589],
		["Culloden","WV",38.41,-82.06,40076164],
		["New Smyrna Beach","FL",29.02,-80.92,40076739],
		["Tavernier","FL",25.01,-80.51,40077314],
		["Fisherville","KY",38.16,-85.42,40077889],
		["Claypool","IN",41.12,-85.87,40078464],
		["Prescott","MI",44.19,-83.93,40079039],
		["Braham","MN",45.72,-93.17,40079614],
		["Osage Beach","MO",38.13,-92.65,40080189],
		["Oklahoma City","OK",35.46,-97.51,40080764],
		["Soda Springs","ID",42.75,-111.53,40081339],
		["Assonet","MA",41.78,-71.06,40081913],
		["Garrison","NY",41.37,-73.92,40082487],
		["Clayton","NY",44.23,-76.08,40083061],
		["Lakewood","NY",42.1,-79.32,40083635],
		["Logan","WV",37.84,-81.98,40084209],
		["Lawrence","MI",42.21,-86.05,40084783],
		["Blue Earth","MN",43.63,-94.09,40085357],
		["Denver","CO",39.76,-104.87,40085931],
		["Willard","UT",41.35,-112.15,40086505],
		["Woodbridge","CA",38.16,-121.29,40087079],
		["North Plains","OR",45.67,-123.02,40087653],
		["Whitney Point","NY",42.33,-75.96,40088226],
		["Fort Lauderdale","FL",26.14,-80.13,40088799],
		["Guin","AL",33.96,-87.91,40089372],
		["Hayneville","AL",32.18,-86.57,40089945],
		["Mio","MI",44.66,-84.14,40090518],
		["Denver","CO",39.76,-104.87,40091091],
		["San Andreas","CA",38.2,-120.62,40091664],
		["Poultney","VT",43.51,-73.23,40092236],
		["Thomasville","PA",39.92,-76.87,40092808],
		["Keysville","VA",37.03,-78.48,40093380],
		["White Sulphur Springs","WV",37.79,-80.29,40093952],
		["Jackson","SC",33.32,-81.79,40094524],
		["Cincinnati","OH",39.09,-84.51,40095096],
		["Minford","OH",38.88,-82.83,40095668],
		["Versailles","IN",39.06,-85.25,40096240],
		["Marne","MI",43.03,-85.82,40096812],
		["Cameron","WI",45.4,-91.74,40097384],
		["Dodge Center","MN",44.02,-92.84,40097956],
		["England","AR",34.54,-91.96,40098528],
		["Brookland","AR",35.9,-90.58,40099100],
		["Lone Grove","OK",34.18,-97.27,40099672],
		["Corsicana","TX",32.08,-96.39,40100244],
		["Spearman","TX",36.19,-101.19,40100816],
		["Kersey","CO",40.38,-104.56,40101388],
		["Turners Falls","MA",42.59,-72.55,40101959],
		["Bar Harbor","ME",44.38,-68.21,40102530],
		["Arlington","VA",38.87,-77.1,40103101],
		["Eglin Afb","FL",30.46,-86.54,40103672],
		["Russellville","TN",36.25,-83.19,40104243],
		["Shiloh","OH",40.96,-82.6,40104814],
		["Fairbury","NE",40.14,-97.17,40105385],
		["Canajoharie","NY",42.9,-74.57,40105955],
		["Gerrardstown","WV",39.37,-78.11,40106525],
		["Elizabeth","WV",39.06,-81.39,40107095],
		["Whittier","NC",35.43,-83.27,40107665],
		["Morriston","FL",29.27,-82.49,40108235],
		["Southington","OH",41.3,-80.95,40108805],
		["Veedersburg","IN",40.11,-87.25,40109375],
		["Virden","IL",39.5,-89.76,40109945],
		["Mansfield","MO",37.1,-92.58,40110515],
		["Karnes City","TX",28.88,-97.9,40111085],
		["Seattle","WA",47.6,-122.32,40111655],
		["Valdez","AK",61.4,-145.66,40112225],
		["Great Meadows","NJ",40.84,-74.94,40112794],
		["Margate City","NJ",39.33,-74.5,40113363],
		["Spencer","NY",42.21,-76.49,40113932],
		["Dry Fork","VA",36.75,-79.41,40114501],
		["Bridgman","MI",41.94,-86.56,40115070],
		["Carter Lake","IA",41.28,-95.91,40115639],
		["Columbus Junction","IA",41.27,-91.36,40116208],
		["Augusta","WI",44.67,-91.12,40116777],
		["Jackson","MN",43.62,-94.98,40117346],
		["Vienna","IL",37.41,-88.89,40117915],
		["Purdy","MO",36.81,-93.92,40118484],
		["Douglass","KS",37.51,-97.01,40119053],
		["Burns","OR",43.59,-118.89,40119622],
		["Seabeck","WA",47.58,-122.9,40120191],
		["Mount Morris","NY",42.72,-77.87,40120759],
		["Scottsville","NY",43.02,-77.75,40121327],
		["Blue Mountain","MS",34.67,-89.02,40121895],
		["Winner","SD",43.37,-99.85,40122463],
		["Sedgwick","KS",37.91,-97.42,40123031],
		["Vassalboro","ME",44.45,-69.67,40123598],
		["Titusville","NJ",40.31,-74.86,40124165],
		["Marysville","PA",40.33,-76.93,40124732],
		["Henryville","IN",38.53,-85.76,40125299],
		["Dilworth","MN",46.87,-96.69,40125866],
		["Monroe City","MO",39.65,-91.73,40126433],
		["Puxico","MO",36.95,-90.15,40127000],
		["Pollok","TX",31.42,-94.86,40127567],
		["Sugar City","ID",43.85,-111.69,40128134],
		["North Yarmouth","ME",43.84,-70.21,40128700],
		["Bridgeport","PA",40.1,-75.34,40129266],
		["Seaford","VA",37.19,-76.43,40129832],
		["Empire","AL",33.84,-87.01,40130398],
		["Monclova","OH",41.56,-83.76,40130964],
		["Bloomfield","MO",36.88,-89.93,40131530],
		["Ash Grove","MO",37.31,-93.58,40132096],
		["Park Hill","OK",35.75,-95.02,40132662],
		["Onalaska","TX",30.8,-95.1,40133228],
		["Tuscola","TX",32.21,-99.79,40133794],
		["Frederick","CO",40.1,-104.94,40134360],
		["Manchester Center","VT",43.17,-73.04,40134925],
		["Gibbstown","NJ",39.82,-75.27,40135490],
		["Williamsburg","PA",40.46,-78.2,40136055],
		["Mc Veytown","PA",40.49,-77.74,40136620],
		["Jupiter","FL",26.92,-80.1,40137185],
		["Valley Head","AL",34.55,-85.62,40137750],
		["Savanna","IL",42.09,-90.13,40138315],
		["Defiance","MO",38.63,-90.78,40138880],
		["Bonsall","CA",33.28,-117.19,40139445],
		["Holden","ME",44.75,-68.67,40140009],
		["Highland Falls","NY",41.36,-73.96,40140573],
		["Linesville","PA",41.65,-80.42,40141137],
		["Fort Mitchell","AL",32.38,-85.1,40141701],
		["Beloit","OH",40.92,-81,40142265],
		["New Paris","OH",39.85,-84.79,40142829],
		["Holcomb","KS",37.99,-100.98,40143393],
		["Evansville","WY",42.87,-106.25,40143957],
		["Shady Side","MD",38.83,-76.52,40144520],
		["Verona","VA",38.19,-79,40145083],
		["Yanceyville","NC",36.4,-79.34,40145646],
		["Jennings","FL",30.6,-83.1,40146209],
		["Woodland","AL",33.37,-85.39,40146772],
		["Notasulga","AL",32.55,-85.67,40147335],
		["South Fulton","TN",36.49,-88.88,40147898],
		["Tawas City","MI",44.27,-83.51,40148461],
		["Amity","AR",34.26,-93.46,40149024],
		["Earle","AR",35.27,-90.46,40149587],
		["Courtland","VA",36.71,-77.06,40150149],
		["Georgiana","AL",31.63,-86.74,40150711],
		["Manchester","OH",38.69,-83.6,40151273],
		["Bourbon","IN",41.29,-86.11,40151835],
		["Durand","WI",44.62,-91.96,40152397],
		["Breckenridge","MN",46.26,-96.58,40152959],
		["Port Byron","IL",41.62,-90.32,40153521],
		["Maize","KS",37.77,-97.46,40154083],
		["Slaughter","LA",30.71,-91.14,40154645],
		["Sutter","CA",39.18,-121.81,40155207],
		["Beavercreek","OR",45.25,-122.46,40155769],
		["Eagle Creek","OR",45.34,-122.29,40156331],
		["Mercedita","PR",18,-66.56,40156892],
		["Babson Park","MA",42.29,-71.26,40157453],
		["North Waterboro","ME",43.63,-70.73,40158014],
		["Oxford","NJ",40.81,-74.99,40158575],
		["Schuylerville","NY",43.1,-73.58,40159136],
		["Mount Pocono","PA",41.12,-75.35,40159697],
		["Sarasota","FL",27.33,-82.54,40160258],
		["Sturgis","KY",37.54,-87.98,40160819],
		["Felicity","OH",38.83,-84.09,40161380],
		["Story City","IA",42.18,-93.59,40161941],
		["Spencer","WI",44.75,-90.29,40162502],
		["Abingdon","IL",40.8,-90.4,40163063],
		["Lisbon","ME",44.01,-70.12,40163623],
		["Renfrew","PA",40.8,-79.98,40164183],
		["Sulligent","AL",33.89,-88.13,40164743],
		["Garner","IA",43.09,-93.6,40165303],
		["Spicer","MN",45.23,-94.93,40165863],
		["Paulina","LA",30.08,-90.77,40166423],
		["Lakeview","OR",42.38,-120.2,40166983],
		["Quantico","VA",38.52,-77.29,40167542],
		["Germanton","NC",36.26,-80.23,40168101],
		["Cottageville","SC",32.93,-80.47,40168660],
		["Taylorsville","GA",34.08,-84.98,40169219],
		["Remlap","AL",33.84,-86.62,40169778],
		["Caryville","TN",36.32,-84.21,40170337],
		["Bruce","MS",33.99,-89.34,40170896],
		["Lena","MS",32.59,-89.59,40171455],
		["Waynesburg","KY",37.33,-84.68,40172014],
		["Edgerton","OH",41.44,-84.74,40172573],
		["Rives Junction","MI",42.38,-84.46,40173132],
		["Suttons Bay","MI",44.97,-85.65,40173691],
		["Maple Park","IL",41.9,-88.59,40174250],
		["Dacono","CO",40.07,-104.94,40174809],
		["Center","CO",37.75,-106.11,40175368],
		["Cedaredge","CO",38.89,-107.92,40175927],
		["Travis Afb","CA",38.26,-121.93,40176486],
		["Glendora","NJ",39.84,-75.06,40177044],
		["West Creek","NJ",39.65,-74.28,40177602],
		["Chesterfield","NJ",40.15,-74.66,40178160],
		["Sutton","WV",38.66,-80.71,40178718],
		["Seabrook","SC",32.56,-80.73,40179276],
		["Huntsville","AL",34.7,-86.62,40179834],
		["Fort Rucker","AL",31.34,-85.71,40180392],
		["Loretto","TN",35.08,-87.43,40180950],
		["Lamoni","IA",40.62,-93.93,40181508],
		["Pierce City","MO",36.94,-94,40182066],
		["Hudson","CO",40.07,-104.64,40182624],
		["Kotzebue","AK",67.24,-161.19,40183182],
		["Quaker Hill","CT",41.4,-72.12,40183739],
		["Middleburgh","NY",42.59,-74.32,40184296],
		["Cambridge","NY",43.02,-73.38,40184853],
		["Warrensburg","NY",43.5,-73.77,40185410],
		["Dexter","NY",44,-76.04,40185967],
		["Matamoras","PA",41.35,-74.7,40186524],
		["Belle","WV",38.23,-81.53,40187081],
		["Ash","NC",34.07,-78.47,40187638],
		["Carolina Beach","NC",34.04,-77.89,40188195],
		["West Farmington","OH",41.39,-80.97,40188752],
		["Keshena","WI",44.88,-88.63,40189309],
		["Cherry Valley","IL",42.24,-88.96,40189866],
		["Wallis","TX",29.63,-96.06,40190423],
		["Miami","AZ",33.4,-110.97,40190980],
		["Le Grand","CA",37.25,-120.2,40191537],
		["Kaunakakai","HI",21.08,-157.01,40192094],
		["Thompson","CT",41.95,-71.86,40192650],
		["Boalsburg","PA",40.77,-77.79,40193206],
		["Grasonville","MD",38.95,-76.19,40193762],
		["Waverly","VA",37.03,-77.09,40194318],
		["Narrows","VA",37.33,-80.8,40194874],
		["Orlando","FL",28.5,-81.37,40195430],
		["Georgetown","TN",35.3,-84.91,40195986],
		["Lowellville","OH",41.04,-80.54,40196542],
		["Greenleaf","WI",44.31,-88.09,40197098],
		["Dayton","MN",45.18,-93.47,40197654],
		["Tulsa","OK",36.12,-95.91,40198210],
		["Edgewood","TX",32.69,-95.88,40198766],
		["Tuolumne","CA",37.95,-120.2,40199322],
		["Naches","WA",46.78,-120.88,40199878],
		["Tieton","WA",46.71,-120.76,40200434],
		["Greenwood Lake","NY",41.22,-74.28,40200989],
		["Fort Gay","WV",38.11,-82.59,40201544],
		["Myrtle Beach","SC",33.69,-78.89,40202099],
		["Casselberry","FL",28.66,-81.32,40202654],
		["Bethel Springs","TN",35.23,-88.6,40203209],
		["Verona","KY",38.83,-84.69,40203764],
		["Pine Knot","KY",36.66,-84.43,40204319],
		["Menahga","MN",46.74,-95.1,40204874],
		["Alba","TX",32.79,-95.63,40205429],
		["Limestone","ME",46.91,-67.83,40205983],
		["Cheltenham","MD",38.73,-76.82,40206537],
		["Eminence","KY",38.36,-85.17,40207091],
		["Irvington","KY",37.87,-86.28,40207645],
		["Fayetteville","OH",39.18,-83.93,40208199],
		["Spring Valley","MN",43.68,-92.38,40208753],
		["Refugio","TX",28.3,-97.27,40209307],
		["Montpelier","ID",42.28,-111.33,40209861],
		["Phoenix","AZ",33.5,-112.07,40210415],
		["Toledo","WA",46.45,-122.76,40210969],
		["Deep River","CT",41.39,-72.43,40211522],
		["Conklin","NY",42.03,-75.8,40212075],
		["Blue Grass","IA",41.5,-90.76,40212628],
		["Wellton","AZ",32.7,-114.17,40213181],
		["South Deerfield","MA",42.47,-72.59,40213733],
		["West Sayville","NY",40.72,-73.1,40214285],
		["Unadilla","NY",42.32,-75.31,40214837],
		["Bergen","NY",43.08,-77.94,40215389],
		["Charles City","VA",37.34,-77.07,40215941],
		["Ruleville","MS",33.72,-90.55,40216493],
		["Spencer","OH",41.09,-82.12,40217045],
		["Harbor Beach","MI",43.84,-82.65,40217597],
		["Woodruff","WI",45.9,-89.69,40218149],
		["Lake Arthur","LA",30.07,-92.67,40218701],
		["Coalville","UT",40.88,-111.31,40219253],
		["Morenci","AZ",33.04,-109.37,40219805],
		["Sandia Park","NM",35.16,-106.36,40220357],
		["Willacoochee","GA",31.34,-83.04,40220908],
		["South Vienna","OH",39.92,-83.61,40221459],
		["Topeka","KS",39.03,-95.69,40222010],
		["Stratford","OK",34.79,-96.96,40222561],
		["Wilmer","TX",32.59,-96.68,40223112],
		["Powderly","TX",33.77,-95.53,40223663],
		["Big Lake","TX",31.19,-101.45,40224214],
		["Somerville","TX",30.34,-96.53,40224765],
		["San Manuel","AZ",32.62,-110.59,40225316],
		["Myrtle Point","OR",43.06,-124.03,40225867],
		["Bellows Falls","VT",43.13,-72.45,40226417],
		["Volant","PA",41.11,-80.25,40226967],
		["Keedysville","MD",39.48,-77.69,40227517],
		["Coker","AL",33.28,-87.67,40228067],
		["Warsaw","KY",38.78,-84.89,40228617],
		["Plymouth","OH",40.99,-82.66,40229167],
		["Bonduel","WI",44.74,-88.44,40229717],
		["Granite Falls","MN",44.81,-95.53,40230267],
		["Gibson City","IL",40.46,-88.34,40230817],
		["Carbondale","IL",37.72,-89.21,40231367],
		["Choudrant","LA",32.53,-92.51,40231917],
		["Aromas","CA",36.87,-121.63,40232467],
		["Sutter Creek","CA",38.42,-120.75,40233017],
		["North Hampton","NH",42.97,-70.83,40233566],
		["Westtown","NY",41.32,-74.54,40234115],
		["Pittsburgh","PA",40.43,-79.97,40234664],
		["Bruceton Mills","WV",39.65,-79.64,40235213],
		["Whitesburg","GA",33.49,-84.91,40235762],
		["Unicoi","TN",36.19,-82.35,40236311],
		["Whiteville","TN",35.32,-89.14,40236860],
		["Quaker City","OH",39.96,-81.29,40237409],
		["Owensville","IN",38.27,-87.69,40237958],
		["Phillips","WI",45.69,-90.39,40238507],
		["Adrian","MO",38.39,-94.35,40239056],
		["Italy","TX",32.18,-96.88,40239605],
		["Rimrock","AZ",34.68,-111.7,40240154],
		["Fulton","MD",39.15,-76.92,40240702],
		["Belhaven","NC",35.54,-76.62,40241250],
		["Flat Rock","AL",34.8,-85.73,40241798],
		["Cumberland","KY",36.98,-82.98,40242346],
		["Sandy Hook","KY",38.09,-83.12,40242894],
		["Reading","MI",41.83,-84.74,40243442],
		["Newberry","MI",46.35,-85.5,40243990],
		["Blooming Prairie","MN",43.86,-93.05,40244538],
		["Savoy","IL",40.06,-88.25,40245086],
		["Watonga","OK",35.84,-98.41,40245634],
		["Penrose","CO",38.43,-105.01,40246182],
		["Idyllwild","CA",33.76,-116.73,40246730],
		["Bend","OR",43.83,-121.49,40247278],
		["Harrisburg","PA",40.27,-76.88,40247825],
		["Luttrell","TN",36.2,-83.74,40248372],
		["Columbus","OH",39.98,-82.98,40248919],
		["Argos","IN",41.23,-86.24,40249466],
		["Monroeville","IN",40.97,-84.86,40250013],
		["Albany","IN",40.3,-85.23,40250560],
		["Ore City","TX",32.8,-94.71,40251107],
		["Shiner","TX",29.43,-97.17,40251654],
		["Brea","CA",33.92,-117.8,40252201],
		["San Francisco","CA",37.81,-122.36,40252748],
		["Manson","WA",47.97,-120.15,40253295],
		["Airway Heights","WA",47.64,-117.56,40253842],
		["Spokane","WA",47.64,-117.43,40254389],
		["Boston","MA",42.35,-71.06,40254935],
		["Port Allegany","PA",41.81,-78.27,40255481],
		["Scranton","PA",41.47,-75.62,40256027],
		["Atglen","PA",39.94,-75.97,40256573],
		["Polkton","NC",35,-80.2,40257119],
		["Jacksonville","FL",30.33,-81.65,40257665],
		["Brookwood","AL",33.24,-87.32,40258211],
		["Brownsville","KY",37.18,-86.25,40258757],
		["Forsyth","IL",39.92,-88.95,40259303],
		["New Madrid","MO",36.58,-89.54,40259849],
		["Midland","PA",40.63,-80.45,40260394],
		["Abbottstown","PA",39.88,-76.98,40260939],
		["Oley","PA",40.37,-75.76,40261484],
		["Concord","GA",33.09,-84.43,40262029],
		["Vance","AL",33.17,-87.22,40262574],
		["Pine Hill","AL",31.98,-87.58,40263119],
		["Uniontown","AL",32.44,-87.5,40263664],
		["Sterling","KS",38.2,-98.2,40264209],
		["Gunter","TX",33.45,-96.74,40264754],
		["Fort Worth","TX",32.75,-97.33,40265299],
		["Franktown","CO",39.38,-104.75,40265844],
		["Kahuku","HI",21.68,-157.94,40266389],
		["Chatham","NY",42.36,-73.59,40266933],
		["Wyalusing","PA",41.66,-76.26,40267477],
		["Chattahoochee","FL",30.69,-84.83,40268021],
		["Cross Plains","TN",36.54,-86.68,40268565],
		["Union City","OH",40.19,-84.79,40269109],
		["Rolling Prairie","IN",41.68,-86.6,40269653],
		["Pawnee","IL",39.59,-89.58,40270197],
		["Kellyville","OK",35.94,-96.21,40270741],
		["Hutchins","TX",32.64,-96.7,40271285],
		["Dexter","ME",45.01,-69.29,40271828],
		["Kirkwood","NY",42.03,-75.79,40272371],
		["Smethport","PA",41.8,-78.44,40272914],
		["Mount Vernon","AL",31.09,-88.01,40273457],
		["La Porte City","IA",42.31,-92.19,40274000],
		["Markesan","WI",43.7,-88.98,40274543],
		["Logansport","LA",31.97,-93.99,40275086],
		["Mulberry","AR",35.5,-94.07,40275629],
		["Calera","OK",33.93,-96.43,40276172],
		["Camden","ME",44.2,-69.06,40276714],
		["Sherman","CT",41.58,-73.5,40277256],
		["Newburg","PA",40.13,-77.55,40277798],
		["Washington Crossing","PA",40.28,-74.87,40278340],
		["Mc Coll","SC",34.66,-79.54,40278882],
		["Six Mile","SC",34.8,-82.81,40279424],
		["Savannah","GA",32.08,-81.1,40279966],
		["Brooklyn","WI",42.85,-89.37,40280508],
		["Shepherd","MT",46.05,-108.38,40281050],
		["Wilson","OK",34.15,-97.43,40281592],
		["Olney","TX",33.36,-98.75,40282134],
		["Abernathy","TX",33.83,-101.84,40282676],
		["Denver","CO",39.76,-104.87,40283218],
		["Lovelock","NV",40.32,-118.2,40283760],
		["Millville","MA",42.03,-71.58,40284301],
		["Cresco","PA",41.16,-75.25,40284842],
		["Wirtz","VA",37.08,-79.9,40285383],
		["Mayodan","NC",36.41,-79.97,40285924],
		["Fort Bragg","NC",35.13,-79,40286465],
		["Magnolia","NC",34.89,-78.05,40287006],
		["Lexington","AL",34.96,-87.37,40287547],
		["Frenchburg","KY",37.95,-83.62,40288088],
		["Rush","KY",38.29,-82.73,40288629],
		["Williamsport","IN",40.28,-87.29,40289170],
		["Eagle Grove","IA",42.66,-93.9,40289711],
		["Rock Rapids","IA",43.42,-96.16,40290252],
		["Barling","AR",35.32,-94.3,40290793],
		["Langley","WA",48.04,-122.44,40291334],
		["Princeton","MA",42.45,-71.86,40291874],
		["New Castle","VA",37.5,-80.11,40292414],
		["Butler","OH",40.58,-82.42,40292954],
		["Castalia","OH",41.4,-82.8,40293494],
		["West Branch","IA",41.67,-91.34,40294034],
		["Colorado Springs","CO",38.86,-104.76,40294574],
		["Montague","CA",41.76,-122.39,40295114],
		["Koloa","HI",21.9,-159.46,40295654],
		["Candia","NH",43.07,-71.27,40296193],
		["Rochelle Park","NJ",40.9,-74.07,40296732],
		["Falconer","NY",42.11,-79.19,40297271],
		["Mount Holly Springs","PA",40.11,-77.18,40297810],
		["Dunkirk","IN",40.37,-85.2,40298349],
		["Spring Green","WI",43.17,-90.06,40298888],
		["Chicago","IL",41.83,-87.68,40299427],
		["Marionville","MO",37,-93.63,40299966],
		["Canadian","TX",35.91,-100.38,40300505],
		["Las Cruces","NM",32.33,-106.75,40301044],
		["Overton","NV",36.39,-114.37,40301583],
		["Davenport","WA",47.67,-118.19,40302122],
		["Norridgewock","ME",44.71,-69.79,40302660],
		["Stowe","VT",44.46,-72.68,40303198],
		["Sparks","GA",31.16,-83.44,40303736],
		["Tchula","MS",33.18,-90.22,40304274],
		["West Paducah","KY",37.11,-88.77,40304812],
		["Wakarusa","IN",41.53,-86.01,40305350],
		["Dillsboro","IN",39.01,-85.06,40305888],
		["Allenton","MI",42.92,-82.88,40306426],
		["Linwood","MI",43.73,-83.97,40306964],
		["Sobieski","WI",44.71,-88.06,40307502],
		["Burlington","KS",38.19,-95.74,40308040],
		["Dubach","LA",32.69,-92.65,40308578],
		["Walters","OK",34.35,-98.3,40309116],
		["Drumright","OK",35.99,-96.59,40309654],
		["Pine Grove","CA",38.38,-120.64,40310192],
		["Avon","MA",42.11,-71.03,40310729],
		["Livingston Manor","NY",41.89,-74.82,40311266],
		["Centerville","PA",41.73,-79.76,40311803],
		["Suffolk","VA",36.7,-76.63,40312340],
		["Garysburg","NC",36.44,-77.55,40312877],
		["Rising Fawn","GA",34.81,-85.5,40313414],
		["Speedwell","TN",36.47,-83.81,40313951],
		["Evarts","KY",36.86,-83.19,40314488],
		["Marengo","IA",41.79,-92.06,40315025],
		["Rothschild","WI",44.88,-89.62,40315562],
		["Howard Lake","MN",45.05,-94.06,40316099],
		["Polo","IL",41.98,-89.57,40316636],
		["Premont","TX",27.35,-98.12,40317173],
		["Paul","ID",42.73,-113.89,40317710],
		["Duchesne","UT",40.1,-110.49,40318247],
		["Vernonia","OR",45.88,-123.23,40318784],
		["Jordan","NY",43.06,-76.47,40319320],
		["Lykens","PA",40.56,-76.69,40319856],
		["South Bay","FL",26.66,-80.71,40320392],
		["Myrtle","MS",34.55,-89.11,40320928],
		["Forest","OH",40.8,-83.51,40321464],
		["Britton","MI",41.98,-83.83,40322000],
		["New Glarus","WI",42.81,-89.63,40322536],
		["Barnesville","MN",46.64,-96.41,40323072],
		["Minden","NE",40.5,-98.95,40323608],
		["Hope","RI",41.75,-71.56,40324143],
		["Waynesville","GA",31.22,-81.79,40324678],
		["Rock Island","TN",35.71,-85.6,40325213],
		["Monroeville","OH",41.24,-82.7,40325748],
		["Harris","MN",45.6,-92.98,40326283],
		["Linden","TX",33.01,-94.36,40326818],
		["Nixon","TX",29.26,-97.76,40327353],
		["Masontown","PA",39.84,-79.9,40327887],
		["Spring Mills","PA",40.86,-77.57,40328421],
		["Mont Alto","PA",39.83,-77.55,40328955],
		["Middletown","VA",39.02,-78.27,40329489],
		["Cape Charles","VA",37.27,-76.01,40330023],
		["Whitesville","KY",37.68,-86.86,40330557],
		["Milan","OH",41.28,-82.6,40331091],
		["Auburn","NE",40.38,-95.84,40331625],
		["Lost Hills","CA",35.61,-119.9,40332159],
		["Harpursville","NY",42.17,-75.62,40332692],
		["Delmont","PA",40.41,-79.57,40333225],
		["Green Lane","PA",40.33,-75.47,40333758],
		["Mount Bethel","PA",40.9,-75.11,40334291],
		["Nicholson","PA",41.62,-75.78,40334824],
		["Kenbridge","VA",36.96,-78.13,40335357],
		["Holly Ridge","NC",34.49,-77.55,40335890],
		["Fort Payne","AL",34.45,-85.69,40336423],
		["Guilford","IN",39.2,-84.96,40336956],
		["Mapleton","IL",40.56,-89.72,40337489],
		["New Bloomfield","MO",38.71,-92.08,40338022],
		["San Saba","TX",31.19,-98.72,40338555],
		["Walsenburg","CO",37.62,-104.77,40339088],
		["Port Reading","NJ",40.56,-74.24,40339620],
		["Centre Hall","PA",40.84,-77.68,40340152],
		["Weatherly","PA",40.94,-75.82,40340684],
		["Heathsville","VA",37.91,-76.47,40341216],
		["Wedowee","AL",33.3,-85.48,40341748],
		["Cumberland Furnace","TN",36.3,-87.4,40342280],
		["Gowen","MI",43.25,-85.3,40342812],
		["Sterlington","LA",32.69,-92.06,40343344],
		["Alton","NH",43.45,-71.21,40343875],
		["Romulus","NY",42.75,-76.83,40344406],
		["Campbell","NY",42.23,-77.19,40344937],
		["Peckville","PA",41.47,-75.57,40345468],
		["Glenville","WV",38.93,-80.83,40345999],
		["Rydal","GA",34.37,-84.7,40346530],
		["Ochlocknee","GA",30.97,-84.05,40347061],
		["Sneads","FL",30.7,-84.92,40347592],
		["New Salisbury","IN",38.31,-86.1,40348123],
		["Mantorville","MN",44.06,-92.75,40348654],
		["Mobridge","SD",45.54,-100.43,40349185],
		["Addis","LA",30.35,-91.26,40349716],
		["Hackett","AR",35.18,-94.41,40350247],
		["Thermopolis","WY",43.64,-108.21,40350778],
		["Auberry","CA",37,-119.15,40351309],
		["Medina","WA",47.62,-122.23,40351840],
		["Frackville","PA",40.78,-76.23,40352370],
		["Pocono Summit","PA",41.1,-75.42,40352900],
		["Norfolk","VA",36.84,-76.28,40353430],
		["York","AL",32.49,-88.29,40353960],
		["Pegram","TN",36.1,-87.05,40354490],
		["Lewisport","KY",37.93,-86.9,40355020],
		["Stearns","KY",36.69,-84.47,40355550],
		["Lakeview","OH",40.48,-83.92,40356080],
		["Townsend","MT",46.48,-111.34,40356610],
		["Kahoka","MO",40.42,-91.71,40357140],
		["Peralta","NM",34.82,-106.68,40357670],
		["North Beach","MD",38.7,-76.53,40358199],
		["Moscow","TN",35.06,-89.39,40358728],
		["Pemberville","OH",41.4,-83.45,40359257],
		["Cromwell","IN",41.4,-85.61,40359786],
		["Russellville","MO",38.51,-92.43,40360315],
		["Auxvasse","MO",39.01,-91.89,40360844],
		["Sabetha","KS",39.9,-95.79,40361373],
		["Concrete","WA",48.56,-121.77,40361902],
		["Cato","NY",43.16,-76.57,40362430],
		["Delevan","NY",42.49,-78.47,40362958],
		["Minersville","PA",40.69,-76.25,40363486],
		["Remington","VA",38.53,-77.8,40364014],
		["Parksley","VA",37.78,-75.65,40364542],
		["Ravenel","SC",32.77,-80.22,40365070],
		["Miami","FL",25.77,-80.2,40365598],
		["Akron","IN",41.03,-86.02,40366126],
		["Saint Ignace","MI",45.86,-84.72,40366654],
		["Carlisle","AR",34.78,-91.74,40367182],
		["Big Lake","AK",61.46,-150.09,40367710],
		["Seward","AK",60.05,-149.33,40368238],
		["North Bennington","VT",42.92,-73.24,40368765],
		["Watkins Glen","NY",42.38,-76.87,40369292],
		["Ruffin","NC",36.45,-79.55,40369819],
		["Telford","TN",36.24,-82.54,40370346],
		["Durant","MS",33.08,-89.85,40370873],
		["Ostrander","OH",40.26,-83.21,40371400],
		["Palmyra","IN",38.4,-86.11,40371927],
		["Gillett","WI",44.88,-88.3,40372454],
		["Boys Town","NE",41.25,-96.13,40372981],
		["Marshall","AR",35.9,-92.63,40373508],
		["Hico","TX",31.98,-98.02,40374035],
		["New Vienna","OH",39.32,-83.69,40374561],
		["Thorntown","IN",40.12,-86.61,40375087],
		["Lamar","AR",35.43,-93.38,40375613],
		["Shoshone","ID",43.11,-114.4,40376139],
		["Colfax","WA",46.85,-117.44,40376665],
		["Milton","NH",43.4,-70.98,40377190],
		["Wilton","ME",44.59,-70.23,40377715],
		["Chester","VT",43.26,-72.59,40378240],
		["Candor","NY",42.23,-76.34,40378765],
		["Danville","WV",38.08,-81.83,40379290],
		["Dundee","FL",28.02,-81.62,40379815],
		["Courtland","MS",34.24,-89.94,40380340],
		["Arcadia","IN",40.17,-86.02,40380865],
		["Galveston","IN",40.58,-86.19,40381390],
		["New Lisbon","WI",43.87,-90.16,40381915],
		["Davis Junction","IL",42.1,-89.09,40382440],
		["Fordland","MO",37.15,-92.94,40382965],
		["Fredonia","KS",37.53,-95.82,40383490],
		["Lutcher","LA",30.06,-90.71,40384015],
		["Clarendon","TX",34.93,-100.89,40384540],
		["West Brookfield","MA",42.23,-72.14,40385064],
		["Yarmouth Port","MA",41.7,-70.22,40385588],
		["Holmes","NY",41.53,-73.66,40386112],
		["Keswick","VA",38.02,-78.35,40386636],
		["Red Bay","AL",34.43,-88.13,40387160],
		["Caneyville","KY",37.42,-86.48,40387684],
		["Moores Hill","IN",39.11,-85.08,40388208],
		["Brookston","IN",40.6,-86.86,40388732],
		["Lake Crystal","MN",44.1,-94.21,40389256],
		["Knoxville","IL",40.9,-90.28,40389780],
		["Grayson","LA",32.04,-92.11,40390304],
		["Mountainburg","AR",35.63,-94.16,40390828],
		["Itasca","TX",32.15,-97.14,40391352],
		["Idalou","TX",33.66,-101.68,40391876],
		["Florissant","CO",38.95,-105.29,40392400],
		["Helper","UT",39.67,-111.01,40392924],
		["Hinsdale","NH",42.78,-72.48,40393447],
		["Bryceville","FL",30.38,-81.93,40393970],
		["Jacksonville","FL",30.33,-81.65,40394493],
		["Carson City","MI",43.17,-84.84,40395016],
		["Lanse","MI",46.72,-88.28,40395539],
		["Pine River","MN",46.72,-94.39,40396062],
		["Timpson","TX",31.9,-94.39,40396585],
		["Biggs","CA",39.42,-121.67,40397108],
		["South Grafton","MA",42.17,-71.68,40397630],
		["Roebling","NJ",40.11,-74.77,40398152],
		["New Market","VA",38.64,-78.67,40398674],
		["Gladys","VA",37.16,-79.08,40399196],
		["Greenville","GA",33.02,-84.71,40399718],
		["Bristolville","OH",41.38,-80.87,40400240],
		["Kenilworth","IL",42.08,-87.71,40400762],
		["Campbell","MO",36.49,-90.07,40401284],
		["Dallas","TX",32.79,-96.76,40401806],
		["Freer","TX",27.88,-98.61,40402328],
		["Kamiah","ID",46.25,-115.96,40402850],
		["Mc Kean","PA",41.99,-80.13,40403371],
		["Milner","GA",33.11,-84.19,40403892],
		["Ray","MI",42.78,-82.93,40404413],
		["Lexington","MI",43.26,-82.53,40404934],
		["Reese","MI",43.45,-83.68,40405455],
		["Sarcoxie","MO",37.06,-94.12,40405976],
		["Iberia","MO",38.08,-92.29,40406497],
		["Basile","LA",30.48,-92.6,40407018],
		["Haynesville","LA",32.96,-93.13,40407539],
		["Apache","OK",34.89,-98.35,40408060],
		["Wynnewood","OK",34.64,-97.16,40408581],
		["Newkirk","OK",36.88,-97.05,40409102],
		["West Winfield","NY",42.88,-75.19,40409622],
		["Glassport","PA",40.32,-79.88,40410142],
		["Houston","PA",40.25,-80.21,40410662],
		["Gladwyne","PA",40.04,-75.28,40411182],
		["Hancock","MD",39.7,-78.17,40411702],
		["Terra Alta","WV",39.44,-79.54,40412222],
		["Fort Loramie","OH",40.34,-84.37,40412742],
		["Toledo","IA",41.99,-92.58,40413262],
		["Stillman Valley","IL",42.1,-89.17,40413782],
		["Rector","AR",36.26,-90.29,40414302],
		["Cambria","CA",35.59,-121.01,40414822],
		["Andover","CT",41.73,-72.36,40415341],
		["South Bound Brook","NJ",40.55,-74.52,40415860],
		["Blossvale","NY",43.27,-75.64,40416379],
		["Fredericksburg","PA",40.45,-76.42,40416898],
		["Troy","VA",37.94,-78.24,40417417],
		["Verbena","AL",32.75,-86.52,40417936],
		["Greenfield","TN",36.15,-88.8,40418455],
		["Middleport","OH",38.99,-82.05,40418974],
		["Combined Locks","WI",44.26,-88.31,40419493],
		["Lenexa","KS",38.9,-94.68,40420012],
		["Plain Dealing","LA",32.9,-93.69,40420531],
		["Ranger","TX",32.47,-98.67,40421050],
		["Lake Hughes","CA",34.69,-118.52,40421569],
		["Windsor","VT",43.48,-72.42,40422087],
		["New Kent","VA",37.51,-76.97,40422605],
		["Lawtey","FL",30.04,-82.07,40423123],
		["Anthony","FL",29.28,-82.11,40423641],
		["Cottonwood","AL",31.05,-85.3,40424159],
		["Vonore","TN",35.59,-84.23,40424677],
		["Calhoun","KY",37.54,-87.25,40425195],
		["Pocahontas","IL",38.82,-89.53,40425713],
		["Milan","MO",40.2,-93.12,40426231],
		["Osage City","KS",38.63,-95.82,40426749],
		["Moultonborough","NH",43.75,-71.39,40427266],
		["Bethlehem","CT",41.63,-73.21,40427783],
		["Schoharie","NY",42.66,-74.31,40428300],
		["Wedgefield","SC",33.89,-80.54,40428817],
		["East Palatka","FL",29.65,-81.59,40429334],
		["Fort Jennings","OH",40.9,-84.29,40429851],
		["East Tawas","MI",44.28,-83.48,40430368],
		["Mount Morris","IL",42.04,-89.43,40430885],
		["Princeville","IL",40.93,-89.75,40431402],
		["Cool","CA",38.88,-120.98,40431919],
		["Onalaska","WA",46.6,-122.67,40432436],
		["Waterford Works","NJ",39.71,-74.81,40432952],
		["Westville","FL",30.76,-85.84,40433468],
		["Babson Park","FL",27.83,-81.52,40433984],
		["Old Fort","TN",35.04,-84.64,40434500],
		["Stamping Ground","KY",38.27,-84.68,40435016],
		["Warsaw","OH",40.33,-82,40435532],
		["Laurel","IN",39.5,-85.18,40436048],
		["Robins","IA",42.07,-91.66,40436564],
		["Saint Ignatius","MT",47.32,-114.02,40437080],
		["Reedsport","OR",43.79,-123.9,40437596],
		["Fort Drum","NY",44.05,-75.77,40438111],
		["Fairmount","GA",34.44,-84.7,40438626],
		["Gatlinburg","TN",35.72,-83.49,40439141],
		["Raleigh","MS",32.03,-89.52,40439656],
		["Providence","KY",37.39,-87.75,40440171],
		["Bicknell","IN",38.77,-87.3,40440686],
		["Windsor Heights","IA",41.6,-93.71,40441201],
		["Swisher","IA",41.84,-91.69,40441716],
		["Cedar Grove","WI",43.56,-87.82,40442231],
		["Burney","CA",40.94,-121.74,40442746],
		["Carlton","OR",45.29,-123.22,40443261],
		["Orrington","ME",44.73,-68.82,40443775],
		["Stockholm","NJ",41.1,-74.53,40444289],
		["Friedens","PA",40.04,-79,40444803],
		["Columbus","OH",39.98,-82.98,40445317],
		["Leesburg","IN",41.32,-85.84,40445831],
		["Fowler","IN",40.61,-87.31,40446345],
		["Janesville","MN",44.11,-93.7,40446859],
		["Mount Sterling","IL",39.98,-90.76,40447373],
		["Verona","MO",36.96,-93.79,40447887],
		["Corpus Christi","TX",27.8,-97.39,40448401],
		["Divide","CO",38.94,-105.16,40448915],
		["Kaibeto","AZ",36.69,-111.07,40449429],
		["Del Rey","CA",36.65,-119.59,40449943],
		["Custer","WA",48.94,-122.62,40450457],
		["Byfield","MA",42.75,-70.93,40450970],
		["Pittsfield","NH",43.3,-71.33,40451483],
		["Montague","NJ",41.29,-74.74,40451996],
		["Keeseville","NY",44.5,-73.48,40452509],
		["Oak Island","NC",33.91,-78.1,40453022],
		["Birmingham","AL",33.52,-86.79,40453535],
		["Brodhead","KY",37.4,-84.41,40454048],
		["Morning View","KY",38.83,-84.51,40454561],
		["Fife Lake","MI",44.57,-85.35,40455074],
		["Shiocton","WI",44.44,-88.57,40455587],
		["Cherryvale","KS",37.26,-95.55,40456100],
		["Mitchell","NE",41.94,-103.8,40456613],
		["Winona","TX",32.49,-95.17,40457126],
		["Hale Center","TX",34.06,-101.84,40457639],
		["Westfield","MA",42.13,-72.75,40458151],
		["Wilton","NH",42.84,-71.73,40458663],
		["Norwich","VT",43.72,-72.3,40459175],
		["Gardners","PA",40.04,-77.18,40459687],
		["West Union","WV",39.29,-80.77,40460199],
		["Avondale Estates","GA",33.76,-84.26,40460711],
		["Middleton","TN",35.06,-88.89,40461223],
		["Fulton","KY",36.51,-88.88,40461735],
		["Lovell","WY",44.83,-108.39,40462247],
		["Oracle","AZ",32.64,-110.84,40462759],
		["Winterhaven","CA",32.87,-114.72,40463271],
		["Gerber","CA",40.03,-122.17,40463783],
		["Canaan","NH",43.64,-72.01,40464294],
		["Ledgewood","NJ",40.88,-74.66,40464805],
		["Rush","NY",42.98,-77.67,40465316],
		["Atlanta","GA",33.77,-84.37,40465827],
		["Reform","AL",33.37,-88.01,40466338],
		["Mooreville","MS",34.28,-88.59,40466849],
		["Goodells","MI",42.93,-82.66,40467360],
		["Scotts","MI",42.19,-85.41,40467871],
		["Barnum","MN",46.5,-92.68,40468382],
		["Cottage Hills","IL",38.91,-90.08,40468893],
		["Valentine","NE",42.87,-100.55,40469404],
		["Tulsa","OK",36.12,-95.91,40469915],
		["Trenton","NJ",40.22,-74.76,40470425],
		["Yaphank","NY",40.83,-72.92,40470935],
		["Moosic","PA",41.35,-75.72,40471445],
		["Jamestown","IN",39.92,-86.62,40471955],
		["Columbus","MT",45.72,-109.23,40472465],
		["Grant Park","IL",41.23,-87.64,40472975],
		["Eldorado","TX",30.86,-100.59,40473485],
		["Parowan","UT",37.91,-112.85,40473995],
		["Magdalena","NM",34.11,-107.23,40474505],
		["Frewsburg","NY",42.05,-79.16,40475014],
		["Sugarloaf","PA",40.99,-76.08,40475523],
		["Manakin Sabot","VA",37.64,-77.7,40476032],
		["Craigsville","WV",38.32,-80.64,40476541],
		["Malabar","FL",27.98,-80.58,40477050],
		["Bloomfield","KY",37.91,-85.31,40477559],
		["Rock Creek","OH",41.66,-80.85,40478068],
		["South Range","WI",46.58,-91.93,40478577],
		["Port Royal","PA",40.53,-77.39,40479085],
		["South Mills","NC",36.44,-76.32,40479593],
		["Westport","IN",39.17,-85.57,40480101],
		["Three Forks","MT",45.91,-111.43,40480609],
		["Carrollton","IL",39.29,-90.41,40481117],
		["Meeker","CO",40.05,-107.89,40481625],
		["Janesville","CA",40.28,-120.5,40482133],
		["Sterling","AK",60.54,-150.79,40482641],
		["Lancaster","NH",44.48,-71.56,40483148],
		["Afton","VA",38.03,-78.83,40483655],
		["Strasburg","OH",40.59,-81.52,40484162],
		["Beaver","OH",39.03,-82.82,40484669],
		["Newark","IL",41.53,-88.58,40485176],
		["Dolores","CO",37.47,-108.49,40485683],
		["Hotchkiss","CO",38.8,-107.71,40486190],
		["Estancia","NM",34.76,-106.06,40486697],
		["Providence","RI",41.82,-71.41,40487203],
		["Vauxhall","NJ",40.71,-74.28,40487709],
		["Grantville","PA",40.39,-76.68,40488215],
		["Collins","GA",32.18,-82.1,40488721],
		["Section","AL",34.58,-85.98,40489227],
		["Blaine","TN",36.14,-83.69,40489733],
		["Jonesboro","IN",40.47,-85.62,40490239],
		["Woodward","IA",41.85,-93.92,40490745],
		["Bovey","MN",47.29,-93.4,40491251],
		["Neodesha","KS",37.42,-95.68,40491757],
		["Fillmore","UT",39.01,-112.27,40492263],
		["Albuquerque","NM",35.11,-106.62,40492769],
		["Yamhill","OR",45.36,-123.24,40493275],
		["Jefferson","MA",42.38,-71.87,40493780],
		["Enfield","NH",43.64,-72.14,40494285],
		["Limington","ME",43.73,-70.71,40494790],
		["Clymer","PA",40.66,-79.01,40495295],
		["Pauline","SC",34.83,-81.87,40495800],
		["Broxton","GA",31.62,-82.88,40496305],
		["Louin","MS",32.07,-89.26,40496810],
		["Shoals","IN",38.66,-86.79,40497315],
		["Kincheloe","MI",46.24,-84.49,40497820],
		["Hull","IA",43.19,-96.13,40498325],
		["Carthage","IL",40.41,-91.13,40498830],
		["Alvord","TX",33.35,-97.69,40499335],
		["Canisteo","NY",42.27,-77.6,40499839],
		["Colfax","NC",36.13,-80.02,40500343],
		["Hampton","TN",36.28,-82.17,40500847],
		["Roxie","MS",31.5,-91.06,40501351],
		["Ida","MI",41.91,-83.57,40501855],
		["Lisbon","IA",41.92,-91.39,40502359],
		["Marysville","KS",39.84,-96.64,40502863],
		["Maringouin","LA",30.49,-91.51,40503367],
		["Wister","OK",34.97,-94.72,40503871],
		["Tohatchi","NM",35.85,-108.75,40504375],
		["Copperopolis","CA",37.94,-120.66,40504879],
		["Winterport","ME",44.65,-68.85,40505382],
		["Wurtsboro","NY",41.57,-74.48,40505885],
		["Rockwood","PA",39.91,-79.15,40506388],
		["Spindale","NC",35.36,-81.92,40506891],
		["Bunnlevel","NC",35.29,-78.83,40507394],
		["Grand Ridge","FL",30.71,-85.01,40507897],
		["Bonita Springs","FL",26.34,-81.78,40508400],
		["Cherokee","AL",34.75,-87.96,40508903],
		["Helenwood","TN",36.43,-84.56,40509406],
		["Mendon","MI",42,-85.45,40509909],
		["Greenwood","WI",44.77,-90.6,40510412],
		["Minneapolis","MN",44.96,-93.26,40510915],
		["Hardy","AR",36.32,-91.48,40511418],
		["Los Molinos","CA",40.05,-122.07,40511921],
		["Randolph","VT",43.92,-72.67,40512423],
		["Fort Ann","NY",43.41,-73.49,40512925],
		["Dolgeville","NY",43.1,-74.77,40513427],
		["Fogelsville","PA",40.59,-75.66,40513929],
		["Essington","PA",39.86,-75.28,40514431],
		["Riegelwood","NC",34.34,-78.26,40514933],
		["Lorman","MS",31.81,-91.19,40515435],
		["Roundup","MT",46.44,-108.51,40515937],
		["Healdton","OK",34.23,-97.48,40516439],
		["Ozona","TX",30.7,-101.2,40516941],
		["Aguanga","CA",33.5,-116.81,40517443],
		["Ravensdale","WA",47.34,-121.92,40517945],
		["Lost Creek","WV",39.15,-80.35,40518446],
		["Richfield","NC",35.47,-80.25,40518947],
		["Hamilton","IN",41.53,-84.91,40519448],
		["Wadesville","IN",38.07,-87.77,40519949],
		["Nashotah","WI",43.09,-88.4,40520450],
		["Osceola","MO",38.04,-93.69,40520951],
		["Soap Lake","WA",47.41,-119.43,40521452],
		["Bowman","SC",33.34,-80.68,40521952],
		["Bremen","OH",39.7,-82.43,40522452],
		["Okeana","OH",39.36,-84.78,40522952],
		["Le Center","MN",44.38,-93.73,40523452],
		["Valliant","OK",34,-95.08,40523952],
		["Baldwinville","MA",42.6,-72.07,40524451],
		["West Barnstable","MA",41.7,-70.37,40524950],
		["Beach Haven","NJ",39.55,-74.24,40525449],
		["Churchville","MD",39.56,-76.24,40525948],
		["Saint Cloud","FL",28.24,-81.28,40526447],
		["Goodman","MS",32.96,-89.91,40526946],
		["Moselle","MS",31.48,-89.32,40527445],
		["Centreville","MS",31.08,-91.06,40527944],
		["Pewee Valley","KY",38.31,-85.48,40528443],
		["New Middletown","OH",40.96,-80.56,40528942],
		["North Lawrence","OH",40.84,-81.63,40529441],
		["Vincent","OH",39.33,-81.67,40529940],
		["Birnamwood","WI",44.93,-89.2,40530439],
		["Pottsville","AR",35.23,-93.05,40530938],
		["Greenville","NY",42.41,-74.02,40531436],
		["Cabot","PA",40.78,-79.75,40531934],
		["Jessup","PA",41.46,-75.55,40532432],
		["Nescopeck","PA",41.05,-76.21,40532930],
		["Aulander","NC",36.22,-77.11,40533428],
		["West Union","SC",34.76,-83.04,40533926],
		["Flagler Beach","FL",29.47,-81.13,40534424],
		["Leesburg","AL",34.17,-85.76,40534922],
		["Wapello","IA",41.17,-91.18,40535420],
		["Eyota","MN",43.98,-92.22,40535918],
		["Garrison","TX",31.82,-94.49,40536416],
		["Anson","TX",32.75,-99.89,40536914],
		["Hernandez","NM",36.06,-106.11,40537412],
		["Saint Thomas","PA",39.92,-77.8,40537909],
		["Churchville","VA",38.22,-79.16,40538406],
		["Whitesburg","TN",36.29,-83.13,40538903],
		["Convoy","OH",40.91,-84.7,40539400],
		["Harrod","OH",40.7,-83.92,40539897],
		["Horton","MI",42.15,-84.51,40540394],
		["Osakis","MN",45.86,-95.15,40540891],
		["Crawford","TX",31.53,-97.44,40541388],
		["Warren","ME",44.12,-69.24,40541884],
		["Holland Patent","NY",43.24,-75.25,40542380],
		["Waterville","NY",42.93,-75.38,40542876],
		["Holtwood","PA",39.82,-76.33,40543372],
		["Surgoinsville","TN",36.47,-82.85,40543868],
		["Ripley","OH",38.73,-83.84,40544364],
		["Monrovia","IN",39.57,-86.5,40544860],
		["Runnells","IA",41.51,-93.35,40545356],
		["Clarion","IA",42.73,-93.73,40545852],
		["Whitehall","WI",44.36,-91.31,40546348],
		["Concordia","MO",38.98,-93.56,40546844],
		["Flippin","AR",36.27,-92.59,40547340],
		["Richmond","UT",41.9,-111.81,40547836],
		["Lompoc","CA",34.77,-120.53,40548332],
		["Willamina","OR",45.13,-123.51,40548828],
		["Sanbornville","NH",43.56,-71.01,40549323],
		["Patton","PA",40.63,-78.64,40549818],
		["Mineral Bluff","GA",34.91,-84.27,40550313],
		["Bryant","AL",34.95,-85.64,40550808],
		["Stoutsville","OH",39.6,-82.82,40551303],
		["Hilbert","WI",44.14,-88.15,40551798],
		["South Haven","MN",45.29,-94.21,40552293],
		["Richmond","IL",42.48,-88.3,40552788],
		["Earlville","IL",41.58,-88.92,40553283],
		["Brimfield","IL",40.83,-89.88,40553778],
		["San Juan Bautista","CA",36.83,-121.51,40554273],
		["Brimfield","MA",42.11,-72.2,40554767],
		["Godwin","NC",35.21,-78.68,40555261],
		["Winnabow","NC",34.28,-78.09,40555755],
		["Roberta","GA",32.71,-84.01,40556249],
		["Lake Panasoffkee","FL",28.79,-82.13,40556743],
		["Clayton","AL",31.88,-85.45,40557237],
		["Lily","KY",37.02,-84.07,40557731],
		["Waterford","OH",39.51,-81.66,40558225],
		["Hammond","WI",44.97,-92.43,40558719],
		["Roberts","WI",44.98,-92.55,40559213],
		["Crivitz","WI",45.23,-88,40559707],
		["Nisswa","MN",46.5,-94.29,40560201],
		["Lakin","KS",37.94,-101.25,40560695],
		["Ethel","LA",30.81,-91.11,40561189],
		["Oakland","OR",43.47,-123.35,40561683],
		["Richfield Springs","NY",42.85,-74.98,40562176],
		["Beaver Dams","NY",42.29,-76.96,40562669],
		["Stantonsburg","NC",35.6,-77.82,40563162],
		["Crandall","GA",34.93,-84.76,40563655],
		["Birmingham","AL",33.52,-86.79,40564148],
		["College Grove","TN",35.77,-86.74,40564641],
		["Warren","IN",40.68,-85.42,40565134],
		["Wells","MN",43.74,-93.72,40565627],
		["Lecompte","LA",31.08,-92.39,40566120],
		["Ajo","AZ",32.15,-112.62,40566613],
		["Center Barnstead","NH",43.33,-71.23,40567105],
		["West Suffield","CT",42,-72.72,40567597],
		["Big Bend","WI",42.88,-88.2,40568089],
		["Ogilvie","MN",45.83,-93.41,40568581],
		["Bottineau","ND",48.82,-100.44,40569073],
		["Cimarron","KS",37.8,-100.34,40569565],
		["Newark","TX",32.99,-97.47,40570057],
		["Chico","TX",33.29,-97.79,40570549],
		["Bedias","TX",30.74,-95.92,40571041],
		["Claysburg","PA",40.29,-78.44,40571532],
		["Tannersville","PA",41.04,-75.33,40572023],
		["Homer","GA",34.33,-83.49,40572514],
		["Mc Comb","OH",41.1,-83.78,40573005],
		["Loyal","WI",44.73,-90.49,40573496],
		["Goodhue","MN",44.4,-92.62,40573987],
		["Saginaw","MN",46.85,-92.44,40574478],
		["Unionville","MO",40.47,-93,40574969],
		["Clark","MO",39.28,-92.34,40575460],
		["Junction","TX",30.48,-99.77,40575951],
		["Corpus Christi","TX",27.8,-97.39,40576442],
		["Littlefield","AZ",36.9,-113.94,40576933],
		["Bow","WA",48.56,-122.44,40577424],
		["Salem","NY",43.17,-73.32,40577914],
		["Saint Louis","MO",38.63,-90.24,40578404],
		["Hamilton","MO",39.74,-94,40578894],
		["Oklahoma City","OK",35.46,-97.51,40579384],
		["Inez","TX",28.87,-96.79,40579874],
		["Hollis Center","ME",43.59,-70.6,40580363],
		["Bronx","NY",40.84,-73.87,40580852],
		["Morrisdale","PA",41.01,-78.22,40581341],
		["West Friendship","MD",39.3,-76.95,40581830],
		["Corydon","KY",37.73,-87.7,40582319],
		["Burt","MI",43.23,-83.9,40582808],
		["Galesville","WI",44.08,-91.35,40583297],
		["Redfield","SD",44.87,-98.52,40583786],
		["Celeste","TX",33.29,-96.19,40584275],
		["Keenesburg","CO",40.1,-104.51,40584764],
		["Sanders","AZ",35.13,-109.29,40585253],
		["Squaw Valley","CA",36.74,-119.19,40585742],
		["East Wareham","MA",41.77,-70.64,40586230],
		["Greenland","NH",43.03,-70.83,40586718],
		["Wiscasset","ME",44.01,-69.67,40587206],
		["North Collins","NY",42.59,-78.93,40587694],
		["Conneautville","PA",41.75,-80.36,40588182],
		["Duryea","PA",41.34,-75.75,40588670],
		["New Milford","PA",41.87,-75.72,40589158],
		["Houlka","MS",34.03,-89.01,40589646],
		["Eau Claire","MI",41.98,-86.3,40590134],
		["Meriden","KS",39.19,-95.56,40590622],
		["Kekaha","HI",21.97,-159.71,40591110],
		["Sloatsburg","NY",41.16,-74.19,40591597],
		["Jefferson","NC",36.42,-81.46,40592084],
		["Pensacola","FL",30.44,-87.18,40592571],
		["Laurel Hill","FL",30.96,-86.45,40593058],
		["Conrad","MT",48.2,-111.85,40593545],
		["Bernice","LA",32.82,-92.65,40594032],
		["Sedalia","CO",39.43,-104.97,40594519],
		["Many Farms","AZ",36.39,-109.59,40595006],
		["Fonda","NY",42.95,-74.37,40595492],
		["Hurley","NY",41.91,-74.05,40595978],
		["Boston","GA",30.79,-83.79,40596464],
		["Nortonville","KY",37.18,-87.45,40596950],
		["New Waterford","OH",40.84,-80.62,40597436],
		["Mesick","MI",44.4,-85.71,40597922],
		["Brimley","MI",46.4,-84.57,40598408],
		["West Burlington","IA",40.82,-91.17,40598894],
		["Park Falls","WI",45.93,-90.44,40599380],
		["Farmington","IL",40.69,-90,40599866],
		["Princeton","LA",32.61,-93.49,40600352],
		["De Leon","TX",32.11,-98.53,40600838],
		["Panhandle","TX",35.34,-101.38,40601324],
		["Amboy","WA",45.93,-122.44,40601810],
		["Midland","VA",38.59,-77.72,40602295],
		["Tobaccoville","NC",36.23,-80.39,40602780],
		["Cross City","FL",29.63,-83.12,40603265],
		["Grand Rapids","OH",41.41,-83.86,40603750],
		["Colby","WI",44.9,-90.31,40604235],
		["Wray","CO",40.07,-102.22,40604720],
		["Gila Bend","AZ",32.99,-112.9,40605205],
		["Oak Bluffs","MA",41.45,-70.56,40605689],
		["Clinton","PA",40.51,-80.34,40606173],
		["Bethel","PA",40.47,-76.29,40606657],
		["Freeland","MD",39.69,-76.72,40607141],
		["Unionville","VA",38.25,-77.96,40607625],
		["Omega","GA",31.33,-83.59,40608109],
		["Cropwell","AL",33.45,-86.34,40608593],
		["Parrish","AL",33.73,-87.28,40609077],
		["Virgie","KY",37.33,-82.57,40609561],
		["Vinton","OH",38.97,-82.34,40610045],
		["Winslow","IN",38.38,-87.21,40610529],
		["Amherst","WI",44.44,-89.28,40611013],
		["Neoga","IL",39.32,-88.45,40611497],
		["Lake City","AR",35.81,-90.44,40611981],
		["Wyandotte","OK",36.79,-94.72,40612465],
		["Talihina","OK",34.75,-95.04,40612949],
		["Pioneer","CA",38.56,-120.17,40613433],
		["Kersey","PA",41.32,-78.6,40613916],
		["Catawba","SC",34.85,-80.91,40614399],
		["Fairfax","SC",32.95,-81.23,40614882],
		["Bridgeport","AL",34.94,-85.71,40615365],
		["Kilauea","HI",22.21,-159.41,40615848],
		["Ashby","MA",42.68,-71.81,40616330],
		["Fort Fairfield","ME",46.76,-67.83,40616812],
		["New Hampton","NY",41.34,-74.45,40617294],
		["West Monroe","NY",43.29,-76.07,40617776],
		["Carrollton","AL",33.26,-88.09,40618258],
		["Antwerp","OH",41.18,-84.73,40618740],
		["Colfax","IA",41.67,-93.24,40619222],
		["Frederic","WI",45.65,-92.46,40619704],
		["Davis","IL",42.42,-89.41,40620186],
		["Hamilton","IL",40.38,-91.36,40620668],
		["Springfield","NE",41.08,-96.13,40621150],
		["Joaquin","TX",31.96,-94.05,40621632],
		["Electra","TX",34.03,-98.91,40622114],
		["Ocean Shores","WA",46.97,-124.14,40622596],
		["Naples","ME",43.97,-70.6,40623077],
		["Rome","PA",41.85,-76.34,40623558],
		["Weyers Cave","VA",38.28,-78.92,40624039],
		["Alderson","WV",37.72,-80.64,40624520],
		["Sebring","FL",27.42,-81.34,40625001],
		["Parrottsville","TN",36,-83.09,40625482],
		["Indian River","MI",45.41,-84.61,40625963],
		["Bushnell","IL",40.55,-90.5,40626444],
		["Alton","MO",36.69,-91.39,40626925],
		["Pierce","NE",42.19,-97.52,40627406],
		["Bigelow","AR",34.99,-92.63,40627887],
		["Seagraves","TX",32.94,-102.56,40628368],
		["Bellevue","ID",43.39,-114.24,40628849],
		["Portola","CA",39.79,-120.46,40629330],
		["Essex","MA",42.63,-70.77,40629810],
		["Clinton","ME",44.63,-69.5,40630290],
		["High Bridge","NJ",40.66,-74.89,40630770],
		["Windsor","SC",33.48,-81.51,40631250],
		["Wadley","AL",33.12,-85.56,40631730],
		["Malta","OH",39.65,-81.86,40632210],
		["Lennon","MI",42.98,-83.93,40632690],
		["Mountain Lake","MN",43.94,-94.92,40633170],
		["Sioux Falls","SD",43.54,-96.73,40633650],
		["Linn Creek","MO",38.04,-92.7,40634130],
		["Hinton","OK",35.49,-98.35,40634610],
		["Lone Oak","TX",32.99,-95.94,40635090],
		["Tenaha","TX",31.94,-94.24,40635570],
		["Shelburne Falls","MA",42.6,-72.74,40636049],
		["Conway","NH",43.97,-71.12,40636528],
		["Bridgeport","NY",42.91,-76.75,40637007],
		["Woodlawn","VA",36.71,-80.81,40637486],
		["Waterloo","SC",34.35,-82.05,40637965],
		["Millbury","OH",41.56,-83.42,40638444],
		["Flora","IN",40.54,-86.52,40638923],
		["Random Lake","WI",43.55,-87.95,40639402],
		["Hickman","NE",40.62,-96.63,40639881],
		["Waldo","AR",33.35,-93.29,40640360],
		["Penngrove","CA",38.31,-122.64,40640839],
		["Boswell","PA",40.16,-79.02,40641317],
		["Cusseta","GA",32.3,-84.77,40641795],
		["Apison","TN",35.01,-85,40642273],
		["Crown City","OH",38.58,-82.28,40642751],
		["La Salle","MI",41.86,-83.45,40643229],
		["Minneapolis","MN",44.96,-93.26,40643707],
		["Garretson","SD",43.71,-96.5,40644185],
		["Elwood","IL",41.41,-88.1,40644663],
		["Orion","IL",41.35,-90.37,40645141],
		["Memphis","MO",40.46,-92.16,40645619],
		["Morris","OK",35.61,-95.86,40646097],
		["Playa Del Rey","CA",33.94,-118.44,40646575],
		["Dighton","MA",41.81,-71.11,40647052],
		["La Fargeville","NY",44.18,-75.95,40647529],
		["Airville","PA",39.82,-76.39,40648006],
		["Dinwiddie","VA",37.07,-77.58,40648483],
		["Helena","GA",32.07,-82.91,40648960],
		["Hillsboro","TN",35.36,-85.95,40649437],
		["Augusta","MI",42.33,-85.35,40649914],
		["Rogers City","MI",45.42,-83.81,40650391],
		["Milford","IA",43.32,-95.15,40650868],
		["Golconda","IL",37.36,-88.48,40651345],
		["Gassville","AR",36.28,-92.49,40651822],
		["Johnson City","TX",30.27,-98.4,40652299],
		["Colton","OR",45.17,-122.4,40652776],
		["Portland","OR",45.73,-122.76,40653253],
		["West Wareham","MA",41.78,-70.75,40653729],
		["Casco","ME",44,-70.52,40654205],
		["Unionville","TN",35.62,-86.59,40654681],
		["Centreville","MI",41.92,-85.52,40655157],
		["Luck","WI",45.57,-92.46,40655633],
		["Bismarck","MO",37.76,-90.62,40656109],
		["Cornville","AZ",34.73,-111.91,40656585],
		["Chester","CT",41.4,-72.45,40657060],
		["Brightwaters","NY",40.71,-73.26,40657535],
		["Theresa","NY",44.21,-75.79,40658010],
		["Mc Alisterville","PA",40.65,-77.28,40658485],
		["Aberdeen Proving Ground","MD",39.47,-76.13,40658960],
		["Reynolds","GA",32.55,-84.09,40659435],
		["Butler","AL",32.08,-88.22,40659910],
		["Lakeville","IN",41.52,-86.27,40660385],
		["Daleville","IN",40.11,-85.55,40660860],
		["Three Oaks","MI",41.79,-86.61,40661335],
		["Onaway","MI",45.35,-84.22,40661810],
		["Sibley","IA",43.4,-95.74,40662285],
		["Belgium","WI",43.5,-87.85,40662760],
		["Galva","IL",41.16,-90.03,40663235],
		["Silver Lake","KS",39.09,-95.85,40663710],
		["Simmesport","LA",30.98,-91.81,40664185],
		["Dyess Afb","TX",32.42,-99.86,40664660],
		["Monmouth","ME",44.23,-70.01,40665134],
		["Acme","PA",40.09,-79.48,40665608],
		["Meshoppen","PA",41.61,-76.04,40666082],
		["Isle Of Palms","SC",32.8,-79.75,40666556],
		["Pedro","OH",38.66,-82.63,40667030],
		["Vestaburg","MI",43.39,-84.9,40667504],
		["Hartville","MO",37.25,-92.51,40667978],
		["Crescent","OK",35.94,-97.58,40668452],
		["Livermore Falls","ME",44.47,-70.18,40668925],
		["Elizabethville","PA",40.54,-76.81,40669398],
		["Peterstown","WV",37.39,-80.79,40669871],
		["Brooks","GA",33.29,-84.45,40670344],
		["Midway","FL",30.49,-84.46,40670817],
		["Mc David","FL",30.86,-87.46,40671290],
		["Golden","MS",34.48,-88.18,40671763],
		["Woodville","OH",41.45,-83.36,40672236],
		["Colon","MI",41.95,-85.32,40672709],
		["Randolph","WI",43.53,-89,40673182],
		["Lakeland","MN",44.95,-92.77,40673655],
		["Girard","IL",39.44,-89.78,40674128],
		["Benton","MO",37.09,-89.56,40674601],
		["Belle Plaine","KS",37.39,-97.27,40675074],
		["Ringgold","LA",32.32,-93.28,40675547],
		["Mansfield","AR",35.05,-94.24,40676020],
		["Hominy","OK",36.42,-96.39,40676493],
		["Hartshorne","OK",34.83,-95.55,40676966],
		["Point","TX",32.93,-95.87,40677439],
		["Haskell","TX",33.15,-99.73,40677912],
		["Morven","NC",34.86,-80,40678384],
		["Kenansville","NC",34.96,-77.96,40678856],
		["Trenton","NC",35.06,-77.35,40679328],
		["Huntsville","AL",34.7,-86.62,40679800],
		["Waynesburg","OH",40.66,-81.25,40680272],
		["Kyle","SD",43.42,-102.16,40680744],
		["Rockaway Beach","MO",36.7,-93.15,40681216],
		["Kansas City","KS",39.1,-94.62,40681688],
		["Three Rivers","TX",28.46,-98.17,40682160],
		["Freeland","WA",48.01,-122.47,40682632],
		["Elysburg","PA",40.86,-76.54,40683103],
		["Elliston","VA",37.21,-80.23,40683574],
		["Magnolia","OH",40.65,-81.29,40684045],
		["Onawa","IA",42.02,-96.08,40684516],
		["Johnson Creek","WI",43.07,-88.77,40684987],
		["Lone Jack","MO",38.87,-94.16,40685458],
		["Washington","LA",30.61,-92.05,40685929],
		["Goldthwaite","TX",31.45,-98.57,40686400],
		["Sugar Grove","PA",41.98,-79.34,40686870],
		["Mc Sherrystown","PA",39.8,-77.01,40687340],
		["Wapwallopen","PA",41.06,-76.07,40687810],
		["Fairview","WV",39.59,-80.24,40688280],
		["Hope Hull","AL",32.21,-86.4,40688750],
		["Patriot","OH",38.77,-82.43,40689220],
		["Rosedale","IN",39.62,-87.28,40689690],
		["Vermontville","MI",42.62,-85.02,40690160],
		["Rugby","ND",48.36,-99.99,40690630],
		["Norton","KS",39.83,-99.89,40691100],
		["Eddy","TX",31.35,-97.3,40691570],
		["Granby","CO",40.08,-105.93,40692040],
		["Lawnside","NJ",39.86,-75.03,40692509],
		["Ranger","GA",34.5,-84.71,40692978],
		["Flomaton","AL",31.01,-87.25,40693447],
		["Hamilton","MS",33.8,-88.43,40693916],
		["Litchfield","OH",41.16,-82.02,40694385],
		["Nunica","MI",43.07,-86.06,40694854],
		["Eleva","WI",44.57,-91.47,40695323],
		["Kansas","OK",36.2,-94.79,40695792],
		["Muenster","TX",33.65,-97.37,40696261],
		["Partlow","VA",38.03,-77.63,40696729],
		["Hanover","VA",37.76,-77.37,40697197],
		["Mc Bee","SC",34.46,-80.25,40697665],
		["Union Point","GA",33.61,-83.07,40698133],
		["Shaw","MS",33.6,-90.77,40698601],
		["Smithville","MS",34.06,-88.39,40699069],
		["Montpelier","IN",40.55,-85.28,40699537],
		["Covert","MI",42.29,-86.26,40700005],
		["Blanchard","MI",43.51,-85.08,40700473],
		["Hartington","NE",42.62,-97.26,40700941],
		["Downsville","LA",32.62,-92.41,40701409],
		["Warner","OK",35.49,-95.3,40701877],
		["North Las Vegas","NV",36.31,-115.2,40702345],
		["Baldwin","GA",34.48,-83.53,40702812],
		["Thorsby","AL",32.91,-86.71,40703279],
		["Bolton","MS",32.35,-90.45,40703746],
		["Cincinnati","OH",39.09,-84.51,40704213],
		["Ogden","IA",42.03,-94.02,40704680],
		["Scandia","MN",45.27,-92.82,40705147],
		["Shelby","MT",48.51,-111.73,40705614],
		["Oblong","IL",39,-87.9,40706081],
		["Towanda","KS",37.79,-96.99,40706548],
		["Salem","AR",36.37,-91.82,40707015],
		["Konawa","OK",34.95,-96.75,40707482],
		["Danbury","TX",29.22,-95.34,40707949],
		["Driggs","ID",43.73,-111.22,40708416],
		["Lukachukai","AZ",36.45,-109.27,40708883],
		["Litchfield","ME",44.13,-69.96,40709349],
		["Akron","PA",40.15,-76.2,40709815],
		["Athens","WV",37.42,-81.01,40710281],
		["Huger","SC",33.09,-79.8,40710747],
		["Petersburg","TN",35.31,-86.63,40711213],
		["Wartrace","TN",35.52,-86.33,40711679],
		["Pleasureville","KY",38.35,-85.11,40712145],
		["Sumner","IA",42.84,-92.09,40712611],
		["Plattsburg","MO",39.56,-94.46,40713077],
		["Diamond","MO",36.99,-94.31,40713543],
		["Crocker","MO",37.94,-92.26,40714009],
		["Mendon","UT",41.71,-111.98,40714475],
		["Huntington","UT",39.41,-111,40714941],
		["Kings Beach","CA",39.24,-120.04,40715407],
		["Hurdle Mills","NC",36.25,-79.08,40715872],
		["Hamer","SC",34.51,-79.4,40716337],
		["Vincent","AL",33.38,-86.4,40716802],
		["Woodville","AL",34.62,-86.28,40717267],
		["New Johnsonville","TN",36.01,-87.95,40717732],
		["Rockford","TN",35.82,-83.93,40718197],
		["Wittenberg","WI",44.82,-89.17,40718662],
		["Lennox","SD",43.35,-96.89,40719127],
		["Oakwood","IL",40.1,-87.77,40719592],
		["Washoe Valley","NV",39.26,-119.79,40720057],
		["Porterville","CA",36.05,-119.14,40720522],
		["Norwood","NY",44.74,-74.99,40720986],
		["Springdale","PA",40.53,-79.78,40721450],
		["Queenstown","MD",38.98,-76.15,40721914],
		["Victoria","VA",36.99,-78.22,40722378],
		["Cave Spring","GA",34.1,-85.33,40722842],
		["Corinth","KY",38.49,-84.56,40723306],
		["Kerens","TX",32.13,-96.22,40723770],
		["San Antonio","TX",29.45,-98.5,40724234],
		["Millinocket","ME",45.65,-68.69,40724697],
		["Barkhamsted","CT",41.93,-72.97,40725160],
		["East Windsor","CT",41.85,-72.6,40725623],
		["Sea Girt","NJ",40.12,-74.03,40726086],
		["Germantown","NY",42.11,-73.85,40726549],
		["Maybrook","NY",41.48,-74.21,40727012],
		["Argyle","NY",43.23,-73.49,40727475],
		["Kirkwood","PA",39.82,-76.09,40727938],
		["Hallstead","PA",41.96,-75.74,40728401],
		["Richmond","VA",37.55,-77.46,40728864],
		["Ocean Isle Beach","NC",33.89,-78.42,40729327],
		["Brooksville","MS",33.23,-88.58,40729790],
		["Rome","OH",41.6,-80.86,40730253],
		["Berlin Heights","OH",41.32,-82.49,40730716],
		["Niagara","WI",45.78,-88,40731179],
		["Minocqua","WI",45.86,-89.69,40731642],
		["Porcupine","SD",43.27,-102.33,40732105],
		["Kingston","IL",42.09,-88.75,40732568],
		["Cooper","TX",33.37,-95.69,40733031],
		["Fairview","UT",39.78,-111.44,40733494],
		["Vado","NM",32.11,-106.66,40733957],
		["Mayville","NY",42.25,-79.5,40734419],
		["Philadelphia","PA",39.95,-75.16,40734881],
		["Harwood","MD",38.86,-76.6,40735343],
		["Celina","TN",36.54,-85.5,40735805],
		["Eddyville","KY",37.07,-88.07,40736267],
		["Prudenville","MI",44.3,-84.66,40736729],
		["Foley","MO",39.04,-90.74,40737191],
		["Belle","MO",38.28,-91.72,40737653],
		["Bells","TX",33.62,-96.41,40738115],
		["Blue Ridge","TX",33.29,-96.39,40738577],
		["Wolfe City","TX",33.36,-96.06,40739039],
		["Mason","TX",30.74,-99.23,40739501],
		["Northfield","MA",42.7,-72.43,40739962],
		["Rockport","ME",44.18,-69.07,40740423],
		["Middlefield","CT",41.5,-72.71,40740884],
		["Boston","NY",42.61,-78.72,40741345],
		["Nanty Glo","PA",40.47,-78.83,40741806],
		["Henryville","PA",41.09,-75.26,40742267],
		["Brookneal","VA",37.05,-78.94,40742728],
		["Milton","KY",38.71,-85.37,40743189],
		["Stryker","OH",41.5,-84.41,40743650],
		["Kitts Hill","OH",38.57,-82.53,40744111],
		["Dale","IN",38.16,-86.98,40744572],
		["Munising","MI",46.4,-86.59,40745033],
		["Fremont","WI",44.25,-88.87,40745494],
		["Cologne","MN",44.77,-93.78,40745955],
		["Leawood","KS",38.9,-94.68,40746416],
		["Morse","LA",30.12,-92.49,40746877],
		["Mooringsport","LA",32.68,-93.96,40747338],
		["Bangs","TX",31.71,-99.13,40747799],
		["Sandia","TX",28.01,-97.88,40748260],
		["Monticello","UT",37.98,-109.29,40748721],
		["Pecos","NM",35.57,-105.67,40749182],
		["Somis","CA",34.29,-119.03,40749643],
		["El Dorado","CA",38.6,-120.84,40750104],
		["Gold Beach","OR",42.45,-124.3,40750565],
		["Woodbury Heights","NJ",39.81,-75.15,40751025],
		["Daniels","WV",37.72,-81.12,40751485],
		["Holly Pond","AL",34.17,-86.61,40751945],
		["Salineville","OH",40.62,-80.83,40752405],
		["English","IN",38.33,-86.46,40752865],
		["Wales","WI",43,-88.37,40753325],
		["Westfield","WI",43.88,-89.49,40753785],
		["Kingsville","MO",38.74,-94.06,40754245],
		["Norco","LA",30,-90.4,40754705],
		["Perryville","AR",35,-92.8,40755165],
		["Tahoka","TX",33.16,-101.79,40755625],
		["Lyons","CO",40.22,-105.26,40756085],
		["Colorado Springs","CO",38.86,-104.76,40756545],
		["Deposit","NY",42.06,-75.42,40757004],
		["Conewango Valley","NY",42.26,-79.01,40757463],
		["White Marsh","MD",39.38,-76.45,40757922],
		["Spencer","NC",35.69,-80.43,40758381],
		["Calabash","NC",33.89,-78.57,40758840],
		["Adger","AL",33.42,-87.19,40759299],
		["Seneca","KS",39.83,-96.06,40759758],
		["Gueydan","LA",30.02,-92.5,40760217],
		["Paonia","CO",38.86,-107.59,40760676],
		["Neeses","SC",33.53,-81.12,40761134],
		["Patterson","GA",31.38,-82.13,40761592],
		["Selma","IN",40.18,-85.27,40762050],
		["Shelburn","IN",39.17,-87.39,40762508],
		["New Buffalo","MI",41.79,-86.74,40762966],
		["Arlington","MN",44.6,-94.07,40763424],
		["Albion","IL",38.37,-88.05,40763882],
		["Bloomsdale","MO",38.01,-90.21,40764340],
		["Goodman","MO",36.73,-94.4,40764798],
		["Fletcher","OK",34.82,-98.24,40765256],
		["Cordell","OK",35.3,-98.98,40765714],
		["Winters","TX",31.96,-99.93,40766172],
		["Las Vegas","NV",36.11,-115.12,40766630],
		["Rio Dell","CA",40.46,-124.13,40767088],
		["Madison","WV",38.05,-81.8,40767545],
		["Orlando","FL",28.5,-81.37,40768002],
		["Bremen","AL",33.95,-87,40768459],
		["Beresford","SD",43.07,-96.78,40768916],
		["Mineral Point","MO",37.94,-90.72,40769373],
		["Conway Springs","KS",37.38,-97.64,40769830],
		["Caney","KS",37.01,-95.93,40770287],
		["Eudora","AR",33.11,-91.26,40770744],
		["Dixfield","ME",44.53,-70.45,40771200],
		["Pennsboro","WV",39.28,-80.97,40771656],
		["Sims","NC",35.76,-78.05,40772112],
		["Prospect","OH",40.45,-83.18,40772568],
		["Junction City","OH",39.72,-82.3,40773024],
		["Diamond","OH",41.09,-81.04,40773480],
		["Terrace Park","OH",39.15,-84.31,40773936],
		["Cascade","IA",42.29,-91,40774392],
		["West Union","IA",42.95,-91.81,40774848],
		["Wrightstown","WI",44.32,-88.17,40775304],
		["Webster","WI",45.87,-92.36,40775760],
		["Kimball","MN",45.31,-94.3,40776216],
		["Kansas City","MO",39.09,-94.58,40776672],
		["Cole Camp","MO",38.45,-93.2,40777128],
		["Collinsville","TX",33.55,-96.91,40777584],
		["Yorktown","TX",28.98,-97.5,40778040],
		["Summerland Key","FL",24.66,-81.45,40778495],
		["Bassfield","MS",31.49,-89.74,40778950],
		["Manawa","WI",44.46,-88.92,40779405],
		["Wabasha","MN",44.37,-92.04,40779860],
		["Flandreau","SD",44.04,-96.59,40780315],
		["Fort Yates","ND",46.08,-100.63,40780770],
		["Ellsinore","MO",36.93,-90.74,40781225],
		["Talala","OK",36.53,-95.7,40781680],
		["Ashville","NY",42.11,-79.42,40782134],
		["Kermit","WV",37.83,-82.4,40782588],
		["Wagram","NC",34.88,-79.36,40783042],
		["Tiptonville","TN",36.37,-89.47,40783496],
		["Drew","MS",33.8,-90.53,40783950],
		["Louisville","KY",38.22,-85.74,40784404],
		["Youngstown","OH",41.09,-80.64,40784858],
		["Lapel","IN",40.06,-85.84,40785312],
		["Sharpsville","IN",40.37,-86.08,40785766],
		["Browerville","MN",46.08,-94.86,40786220],
		["Jonesboro","IL",37.45,-89.26,40786674],
		["Blossom","TX",33.66,-95.38,40787128],
		["Groveton","TX",31.05,-95.12,40787582],
		["Center Point","TX",29.93,-99.03,40788036],
		["Westmorland","CA",33.03,-115.55,40788490],
		["Lockeford","CA",38.17,-121.13,40788944],
		["Kapaau","HI",20.23,-155.81,40789398],
		["Corbett","OR",45.51,-122.21,40789852],
		["Lyndonville","NY",43.32,-78.38,40790305],
		["Bemus Point","NY",42.16,-79.39,40790758],
		["Deep Run","NC",35.15,-77.69,40791211],
		["Meansville","GA",33.04,-84.3,40791664],
		["Warner Robins","GA",32.61,-83.63,40792117],
		["Lambert","MS",34.2,-90.28,40792570],
		["Cincinnati","OH",39.09,-84.51,40793023],
		["Maria Stein","OH",40.39,-84.51,40793476],
		["Newport","MN",44.87,-92.99,40793929],
		["Rich Hill","MO",38.09,-94.36,40794382],
		["Frontenac","KS",37.45,-94.69,40794835],
		["Las Animas","CO",38.06,-103.22,40795288],
		["Murphys","CA",38.14,-120.45,40795741],
		["Kealakekua","HI",19.54,-155.88,40796194],
		["White Swan","WA",46.36,-120.78,40796647],
		["Weatogue","CT",41.84,-72.82,40797099],
		["Vernon","NY",43.07,-75.53,40797551],
		["Hancock","NY",41.95,-75.27,40798003],
		["New Ringgold","PA",40.68,-75.99,40798455],
		["Keymar","MD",39.59,-77.26,40798907],
		["Rockville","VA",37.72,-77.67,40799359],
		["Lowell","NC",35.26,-81.1,40799811],
		["Morganton","GA",34.87,-84.24,40800263],
		["Lake","MS",32.34,-89.32,40800715],
		["Concord","MI",42.17,-84.64,40801167],
		["Adams","WI",43.95,-89.81,40801619],
		["Glenwood City","WI",45.05,-92.17,40802071],
		["Whitehall","MT",45.86,-112.24,40802523],
		["Mangum","OK",34.87,-99.5,40802975],
		["Brackettville","TX",29.31,-100.41,40803427],
		["Oakridge","OR",43.74,-122.41,40803879],
		["Gillette","NJ",40.7,-74.47,40804330],
		["Sparkill","NY",41.02,-73.9,40804781],
		["Harveys Lake","PA",41.37,-76.02,40805232],
		["Jane Lew","WV",39.1,-80.4,40805683],
		["Memphis","TN",35.1,-90,40806134],
		["State Line","MS",31.43,-88.47,40806585],
		["Medway","OH",39.87,-84.02,40807036],
		["Postville","IA",43.08,-91.56,40807487],
		["Nokomis","IL",39.3,-89.28,40807938],
		["Florien","LA",31.44,-93.45,40808389],
		["Queen City","TX",33.14,-94.15,40808840],
		["Outlook","WA",46.37,-120.09,40809291],
		["Boston","MA",42.35,-71.06,40809741],
		["Fall Branch","TN",36.41,-82.62,40810191],
		["Grawn","MI",44.66,-85.68,40810641],
		["Bangor","WI",43.89,-90.99,40811091],
		["Hazen","ND",47.29,-101.62,40811541],
		["Marceline","MO",39.71,-92.94,40811991],
		["Maurepas","LA",30.25,-90.66,40812441],
		["Dayton","WA",46.31,-117.9,40812891],
		["Durham","ME",43.92,-70.12,40813340],
		["Stanley","NY",42.82,-77.12,40813789],
		["Earleville","MD",39.41,-75.93,40814238],
		["Clarksville","VA",36.62,-78.56,40814687],
		["Rainelle","WV",37.96,-80.77,40815136],
		["Climax","NC",35.92,-79.73,40815585],
		["Sycamore","OH",40.95,-83.17,40816034],
		["Bates City","MO",39,-94.07,40816483],
		["Marion","KS",38.34,-97.01,40816932],
		["Bapchule","AZ",32.98,-111.75,40817381],
		["Twin Peaks","CA",34.23,-117.23,40817830],
		["Warren","OR",45.81,-122.88,40818279],
		["Forestville","NY",42.46,-79.17,40818727],
		["Avella","PA",40.28,-80.47,40819175],
		["Unadilla","GA",32.25,-83.73,40819623],
		["Vernon","FL",30.61,-85.7,40820071],
		["Belfry","KY",37.62,-82.26,40820519],
		["Kinsman","OH",41.43,-80.57,40820967],
		["Sullivan","OH",41.03,-82.21,40821415],
		["Frankton","IN",40.22,-85.77,40821863],
		["Breckenridge","MI",43.4,-84.47,40822311],
		["Merrill","MI",43.4,-84.33,40822759],
		["Logan","IA",41.64,-95.79,40823207],
		["Gibbon","NE",40.74,-98.84,40823655],
		["Hooker","OK",36.85,-101.21,40824103],
		["Rice","TX",32.24,-96.5,40824551],
		["De Berry","TX",32.21,-94.14,40824999],
		["Corpus Christi","TX",27.8,-97.39,40825447],
		["Austin","TX",30.26,-97.74,40825895],
		["Del Norte","CO",37.67,-106.35,40826343],
		["Chimayo","NM",35.99,-105.92,40826791],
		["Ruidoso Downs","NM",33.33,-105.6,40827239],
		["Mine Hill","NJ",40.87,-74.6,40827686],
		["Harrisville","PA",41.13,-80.01,40828133],
		["Jackson Springs","NC",35.19,-79.64,40828580],
		["Rutledge","GA",33.62,-83.61,40829027],
		["Shelby","MS",33.95,-90.76,40829474],
		["Rolling Fork","MS",32.9,-90.87,40829921],
		["Deshler","OH",41.2,-83.9,40830368],
		["Lynn","IN",40.04,-84.94,40830815],
		["Lewistown","IL",40.39,-90.15,40831262],
		["Lexington","IL",40.64,-88.78,40831709],
		["Lacygne","KS",38.34,-94.76,40832156],
		["Decatur","AR",36.33,-94.45,40832603],
		["Gunnison","CO",38.54,-106.92,40833050],
		["Eunice","NM",32.44,-103.19,40833497],
		["Hamilton City","CA",39.72,-122.05,40833944],
		["Gold Hill","OR",42.48,-123.06,40834391],
		["Brookfield","MA",42.21,-72.1,40834837],
		["Richford","VT",44.99,-72.67,40835283],
		["Loysville","PA",40.38,-77.33,40835729],
		["Cheyney","PA",39.92,-75.52,40836175],
		["Pylesville","MD",39.68,-76.37,40836621],
		["Independence","VA",36.61,-81.15,40837067],
		["Leon","WV",38.74,-81.95,40837513],
		["Kimberly","AL",33.76,-86.8,40837959],
		["Robinsonville","MS",34.81,-90.31,40838405],
		["Wallingford","KY",38.4,-83.61,40838851],
		["West Unity","OH",41.58,-84.43,40839297],
		["Emmett","MI",42.99,-82.76,40839743],
		["Lomira","WI",43.58,-88.44,40840189],
		["Kenyon","MN",44.27,-92.98,40840635],
		["Conway","MO",37.5,-92.82,40841081],
		["Hoxie","AR",36.04,-90.97,40841527],
		["Pocatello","ID",42.87,-112.46,40841973],
		["Petersburg","AK",57.05,-132.62,40842419],
		["National Park","NJ",39.86,-75.18,40842864],
		["Remsen","NY",43.32,-75.18,40843309],
		["Verona","NY",43.13,-75.57,40843754],
		["Wilson","NY",43.31,-78.82,40844199],
		["Lansford","PA",40.83,-75.88,40844644],
		["Tunnelton","WV",39.39,-79.74,40845089],
		["Vilas","NC",36.27,-81.78,40845534],
		["Maplesville","AL",32.78,-86.87,40845979],
		["Spencer","TN",35.74,-85.45,40846424],
		["Mc Dermott","OH",38.83,-83.07,40846869],
		["Roseland","LA",30.76,-90.51,40847314],
		["Arnold","CA",38.35,-120.2,40847759],
		["Breezy Point","NY",40.56,-73.91,40848203],
		["Kinzers","PA",40.01,-76.04,40848647],
		["Millville","PA",41.12,-76.52,40849091],
		["Mount Pleasant Mills","PA",40.69,-77,40849535],
		["Brodnax","VA",36.7,-78.03,40849979],
		["Garland","NC",34.78,-78.39,40850423],
		["Denver","IA",42.67,-92.33,40850867],
		["Caledonia","WI",42.82,-87.93,40851311],
		["Auburn","KS",38.9,-95.81,40851755],
		["Phillipsburg","KS",39.75,-99.32,40852199],
		["Hawley","TX",32.6,-99.81,40852643],
		["Middletown","CA",38.78,-122.58,40853087],
		["Westhampton Beach","NY",40.8,-72.61,40853530],
		["Accord","NY",41.8,-74.23,40853973],
		["Minoa","NY",43.07,-76,40854416],
		["Lumber Bridge","NC",34.88,-79.07,40854859],
		["East Flat Rock","NC",35.28,-82.41,40855302],
		["Uvalda","GA",32.03,-82.5,40855745],
		["Valparaiso","FL",30.49,-86.5,40856188],
		["Loretto","KY",37.63,-85.4,40856631],
		["Magnolia","KY",37.45,-85.75,40857074],
		["Michigan Center","MI",42.22,-84.32,40857517],
		["Bellaire","MI",44.97,-85.2,40857960],
		["Earlham","IA",41.49,-94.12,40858403],
		["Iola","WI",44.5,-89.12,40858846],
		["Golden Meadow","LA",29.38,-90.27,40859289],
		["Olla","LA",31.89,-92.23,40859732],
		["Marmaduke","AR",36.18,-90.38,40860175],
		["Bloomington","TX",28.65,-96.9,40860618],
		["Dauphin","PA",40.36,-76.93,40861060],
		["Columbia","NC",35.91,-76.25,40861502],
		["Cherry Point","NC",34.92,-76.89,40861944],
		["Portal","GA",32.53,-81.93,40862386],
		["Daytona Beach","FL",29.19,-81.05,40862828],
		["Jeromesville","OH",40.8,-82.19,40863270],
		["Lake Village","IN",41.12,-87.45,40863712],
		["Sandstone","MN",46.12,-92.86,40864154],
		["Merryville","LA",30.75,-93.53,40864596],
		["Brocton","NY",42.38,-79.44,40865037],
		["Parker","PA",41.09,-79.68,40865478],
		["Jamestown","PA",41.48,-80.43,40865919],
		["Townville","SC",34.56,-82.89,40866360],
		["Moreland","GA",33.28,-84.77,40866801],
		["Eva","AL",34.33,-86.75,40867242],
		["Castalian Springs","TN",36.38,-86.31,40867683],
		["Bentonia","MS",32.64,-90.37,40868124],
		["Sebree","KY",37.6,-87.52,40868565],
		["Monon","IN",40.86,-86.87,40869006],
		["Edmore","MI",43.4,-85.03,40869447],
		["Shelbyville","MI",42.59,-85.63,40869888],
		["Hawarden","IA",43,-96.48,40870329],
		["Redfield","AR",34.44,-92.18,40870770],
		["Stamford","TX",33.02,-99.64,40871211],
		["Rochester","NH",43.3,-70.97,40871651],
		["Union","ME",44.21,-69.27,40872091],
		["Waterbury","CT",41.55,-73.03,40872531],
		["Garwood","NJ",40.65,-74.32,40872971],
		["Lakehurst","NJ",40.01,-74.32,40873411],
		["Ruffs Dale","PA",40.15,-79.63,40873851],
		["Star","NC",35.4,-79.78,40874291],
		["Annville","KY",37.32,-83.96,40874731],
		["Bloomingdale","OH",40.34,-80.81,40875171],
		["Friendship","WI",43.97,-89.82,40875611],
		["New Athens","IL",38.31,-89.87,40876051],
		["Oran","MO",37.08,-89.65,40876491],
		["Tecumseh","KS",39,-95.53,40876931],
		["Mccleary","WA",47.03,-123.27,40877371],
		["Greenville","NH",42.76,-71.79,40877810],
		["West Rutland","VT",43.59,-73.04,40878249],
		["Champlain","NY",44.98,-73.44,40878688],
		["Loganton","PA",41.03,-77.3,40879127],
		["Lancaster","VA",37.76,-76.46,40879566],
		["Cana","VA",36.58,-80.66,40880005],
		["Bear Creek","NC",35.6,-79.36,40880444],
		["Moravian Falls","NC",36.1,-81.18,40880883],
		["Whigham","GA",30.9,-84.32,40881322],
		["Halstead","KS",38,-97.5,40881761],
		["Adair","OK",36.43,-95.26,40882200],
		["Lavon","TX",33.02,-96.43,40882639],
		["Corinth","ME",44.98,-69.01,40883077],
		["West Liberty","WV",40.16,-80.59,40883515],
		["Lake Helen","FL",28.98,-81.23,40883953],
		["Rockford","OH",40.69,-84.64,40884391],
		["Audubon","IA",41.71,-94.92,40884829],
		["Lac Du Flambeau","WI",45.97,-89.9,40885267],
		["Caledonia","IL",42.4,-88.9,40885705],
		["Cairo","IL",36.99,-89.17,40886143],
		["Robertsville","MO",38.41,-90.81,40886581],
		["Saint Paul","NE",41.21,-98.45,40887019],
		["Minco","OK",35.31,-97.95,40887457],
		["Manchaca","TX",30.13,-97.89,40887895],
		["Schaghticoke","NY",42.89,-73.58,40888332],
		["Clinton Corners","NY",41.87,-73.76,40888769],
		["Graysville","TN",35.44,-85.08,40889206],
		["Sarah","MS",34.6,-90.12,40889643],
		["Rushville","OH",39.76,-82.43,40890080],
		["Perrysville","OH",40.65,-82.31,40890517],
		["Spring Valley","WI",44.85,-92.23,40890954],
		["Elk Point","SD",42.68,-96.68,40891391],
		["Rosebud","SD",43.23,-100.83,40891828],
		["Tollhouse","CA",36.96,-119.3,40892265],
		["Dillingham","AK",58.82,-157.86,40892702],
		["North Granby","CT",42.01,-72.84,40893138],
		["Irving","NY",42.56,-79.04,40893574],
		["Gold Hill","NC",35.52,-80.33,40894010],
		["Salemburg","NC",35.01,-78.5,40894446],
		["Cedar","MI",44.85,-85.8,40894882],
		["Norway","MI",45.8,-87.91,40895318],
		["Stover","MO",38.44,-92.98,40895754],
		["Olton","TX",34.17,-102.13,40896190],
		["Shingletown","CA",40.5,-121.8,40896626],
		["East Kingston","NH",42.92,-71.01,40897061],
		["Bowdoin","ME",44.04,-70.02,40897496],
		["Bentleyville","PA",40.11,-80,40897931],
		["Erie","PA",42.12,-80.08,40898366],
		["Houtzdale","PA",40.82,-78.35,40898801],
		["Ocean View","DE",38.54,-75.09,40899236],
		["Richmond","VA",37.55,-77.46,40899671],
		["Riner","VA",37.02,-80.43,40900106],
		["Elkton","FL",29.78,-81.42,40900541],
		["Chamberlain","SD",43.8,-99.32,40900976],
		["Melbourne","AR",36.06,-91.89,40901411],
		["Ault","CO",40.58,-104.73,40901846],
		["Alcalde","NM",36.08,-106.05,40902281],
		["Springville","CA",36.17,-118.7,40902716],
		["Manville","RI",41.96,-71.47,40903150],
		["Underhill","VT",44.53,-72.97,40903584],
		["Otego","NY",42.39,-75.17,40904018],
		["Raven","VA",37.16,-81.9,40904452],
		["Cincinnati","OH",39.09,-84.51,40904886],
		["Olivia","MN",44.77,-94.99,40905320],
		["Porter","OK",35.86,-95.52,40905754],
		["Porum","OK",35.35,-95.26,40906188],
		["Bylas","AZ",33.07,-110.25,40906622],
		["Keams Canyon","AZ",35.76,-110.25,40907056],
		["Northville","NY",43.22,-74.17,40907489],
		["New Alexandria","PA",40.39,-79.41,40907922],
		["Beech Bluff","TN",35.6,-88.63,40908355],
		["Muncie","IN",40.19,-85.39,40908788],
		["Central City","IA",42.2,-91.52,40909221],
		["Elkhart Lake","WI",43.83,-88.01,40909654],
		["Afton","MN",44.9,-92.81,40910087],
		["Tracy","MN",44.23,-95.61,40910520],
		["Vandalia","MO",39.3,-91.49,40910953],
		["Mc Louth","KS",39.2,-95.22,40911386],
		["Horton","KS",39.66,-95.53,40911819],
		["Eureka","KS",37.82,-96.28,40912252],
		["Clarkdale","AZ",34.79,-112.1,40912685],
		["Twain Harte","CA",38.08,-120.24,40913118],
		["Stanfield","OR",45.8,-119.17,40913551],
		["Mont Vernon","NH",42.9,-71.66,40913983],
		["Machias","ME",44.7,-67.47,40914415],
		["Roaring River","NC",36.21,-81,40914847],
		["Whitmire","SC",34.5,-81.61,40915279],
		["Vardaman","MS",33.88,-89.17,40915711],
		["Crothersville","IN",38.79,-85.84,40916143],
		["South Rockwood","MI",42.06,-83.26,40916575],
		["Hazel Green","WI",42.53,-90.43,40917007],
		["Mason City","IL",40.2,-89.69,40917439],
		["New Berlin","IL",39.72,-89.91,40917871],
		["Seligman","MO",36.52,-93.93,40918303],
		["Cumby","TX",33.13,-95.83,40918735],
		["Millsap","TX",32.75,-98,40919167],
		["Moorcroft","WY",44.26,-104.95,40919599],
		["Lincolnville","ME",44.28,-69,40920030],
		["New York","NY",40.71,-73.99,40920461],
		["Hastings","NY",43.35,-76.15,40920892],
		["Arkport","NY",42.39,-77.69,40921323],
		["Alexander","NC",35.7,-82.63,40921754],
		["Juliette","GA",33.12,-83.82,40922185],
		["Jacksons Gap","AL",32.9,-85.83,40922616],
		["Lynchburg","TN",35.28,-86.35,40923047],
		["Marks","MS",34.25,-90.27,40923478],
		["Pickens","MS",32.89,-89.96,40923909],
		["Caledonia","OH",40.63,-82.96,40924340],
		["Fowler","MI",43,-84.74,40924771],
		["Owen","WI",44.94,-90.56,40925202],
		["Cohasset","MN",47.26,-93.62,40925633],
		["Pillager","MN",46.32,-94.47,40926064],
		["Stockton","IL",42.35,-90,40926495],
		["Quanah","TX",34.29,-99.74,40926926],
		["Telluride","CO",37.94,-107.9,40927357],
		["Ohkay Owingeh","NM",36.05,-106.07,40927788],
		["Seattle","WA",47.61,-122.34,40928219],
		["Williamstown","VT",44.12,-72.53,40928649],
		["Woodstock","NY",42.03,-74.11,40929079],
		["Milroy","PA",40.71,-77.58,40929509],
		["Wilmington","DE",39.73,-75.53,40929939],
		["Mansfield","GA",33.51,-83.73,40930369],
		["Coffeeville","MS",33.97,-89.67,40930799],
		["Maben","MS",33.55,-89.08,40931229],
		["Coolville","OH",39.22,-81.79,40931659],
		["Oglala","SD",43.18,-102.73,40932089],
		["Cherokee Village","AR",36.3,-91.53,40932519],
		["Thorndale","TX",30.61,-97.2,40932949],
		["Ganado","TX",29.04,-96.51,40933379],
		["Horseshoe Bay","TX",30.57,-98.27,40933809],
		["Clifton","AZ",33.27,-109.27,40934239],
		["Kurtistown","HI",19.58,-155.07,40934669],
		["Culver","OR",44.47,-121.18,40935099],
		["Tacoma","WA",47.14,-122.44,40935529],
		["Fairbanks","AK",64.86,-147.82,40935959],
		["Edgartown","MA",41.38,-70.53,40936388],
		["Cornwall On Hudson","NY",41.43,-74.01,40936817],
		["Milton","NY",41.65,-73.96,40937246],
		["West Sunbury","PA",41,-79.89,40937675],
		["Rixeyville","VA",38.58,-77.97,40938104],
		["Wadley","GA",32.86,-82.4,40938533],
		["Toomsuba","MS",32.43,-88.46,40938962],
		["Millersport","OH",39.89,-82.54,40939391],
		["Etna Green","IN",41.27,-86.04,40939820],
		["Remus","MI",43.61,-85.1,40940249],
		["Rapid City","MI",44.83,-85.28,40940678],
		["Lead","SD",44.35,-103.76,40941107],
		["Prophetstown","IL",41.66,-89.93,40941536],
		["Elmwood","IL",40.77,-89.96,40941965],
		["Mount Olive","IL",39.07,-89.72,40942394],
		["Hoisington","KS",38.51,-98.77,40942823],
		["Quapaw","OK",36.95,-94.78,40943252],
		["San Antonio","TX",29.45,-98.5,40943681],
		["Pittsburgh","PA",40.43,-79.97,40944109],
		["Shinglehouse","PA",41.96,-78.19,40944537],
		["Stem","NC",36.19,-78.72,40944965],
		["Blythe","GA",33.29,-82.19,40945393],
		["Sparta","KY",38.67,-84.9,40945821],
		["Continental","OH",41.09,-84.26,40946249],
		["Eaton","IN",40.34,-85.35,40946677],
		["Berryton","KS",38.92,-95.58,40947105],
		["Elton","LA",30.48,-92.69,40947533],
		["Fort Bliss","TX",31.84,-106.43,40947961],
		["Lewiston","UT",41.95,-111.86,40948389],
		["Baltic","CT",41.64,-72.07,40948816],
		["Moodus","CT",41.5,-72.45,40949243],
		["Brodheadsville","PA",40.92,-75.4,40949670],
		["Sistersville","WV",39.55,-80.99,40950097],
		["Lexington","GA",33.87,-83.11,40950524],
		["Dansville","MI",42.55,-84.3,40950951],
		["New London","IA",40.92,-91.4,40951378],
		["Medford","MN",44.17,-93.24,40951805],
		["Fort Hancock","TX",31.51,-105.71,40952232],
		["Herald","CA",38.33,-121.14,40952659],
		["Pavilion","NY",42.87,-77.99,40953085],
		["Industry","PA",40.65,-80.4,40953511],
		["Parsonsburg","MD",38.38,-75.47,40953937],
		["Jamesville","NC",35.81,-76.89,40954363],
		["Baconton","GA",31.37,-84.16,40954789],
		["Berry","KY",38.52,-84.38,40955215],
		["Bronston","KY",36.98,-84.61,40955641],
		["Fayette","OH",41.67,-84.32,40956067],
		["Mineral City","OH",40.6,-81.36,40956493],
		["Bryant","IN",40.53,-84.96,40956919],
		["Sublette","KS",37.47,-100.84,40957345],
		["Fultonville","NY",42.94,-74.37,40957770],
		["Ulster Park","NY",41.86,-73.99,40958195],
		["Guys Mills","PA",41.63,-79.95,40958620],
		["Linden","PA",41.27,-77.15,40959045],
		["Haysi","VA",37.2,-82.29,40959470],
		["State Road","NC",36.39,-80.89,40959895],
		["Ridge Spring","SC",33.84,-81.66,40960320],
		["Doerun","GA",31.31,-83.91,40960745],
		["Friendship","TN",35.91,-89.24,40961170],
		["Ubly","MI",43.71,-82.93,40961595],
		["Harlem","MT",48.49,-108.65,40962020],
		["Centerville","TX",31.25,-95.97,40962445],
		["Mount Hood Parkdale","OR",45.43,-121.63,40962870],
		["Otisville","NY",41.47,-74.53,40963294],
		["Heuvelton","NY",44.61,-75.4,40963718],
		["East Pittsburgh","PA",40.39,-79.83,40964142],
		["Darlington","PA",40.81,-80.42,40964566],
		["Wattsburg","PA",42,-79.8,40964990],
		["Ottsville","PA",40.46,-75.15,40965414],
		["Conway","NC",36.43,-77.22,40965838],
		["Lynchburg","SC",34.05,-80.07,40966262],
		["Pinewood","SC",33.73,-80.46,40966686],
		["New Auburn","WI",45.19,-91.56,40967110],
		["Roanoke","IL",40.79,-89.2,40967534],
		["Humansville","MO",37.79,-93.57,40967958],
		["Mc Crory","AR",35.25,-91.19,40968382],
		["Taftville","CT",41.56,-72.05,40968805],
		["Gardiner","NY",41.68,-74.18,40969228],
		["Perryopolis","PA",40.08,-79.75,40969651],
		["Darlington","MD",39.63,-76.2,40970074],
		["Lonaconing","MD",39.56,-78.97,40970497],
		["Yorktown","VA",37.23,-76.5,40970920],
		["Fries","VA",36.71,-80.97,40971343],
		["Linden","AL",32.3,-87.79,40971766],
		["Baldwin","MI",43.89,-85.85,40972189],
		["Tucson","AZ",32.21,-110.96,40972612],
		["Lake Luzerne","NY",43.32,-73.8,40973034],
		["Aspers","PA",39.97,-77.23,40973456],
		["Pamplin","VA",37.26,-78.68,40973878],
		["Donalds","SC",34.37,-82.34,40974300],
		["Florala","AL",31.02,-86.3,40974722],
		["Rockholds","KY",36.82,-84.11,40975144],
		["Harold","KY",37.53,-82.63,40975566],
		["Ashley","OH",40.4,-82.95,40975988],
		["Madison","WI",43.07,-89.38,40976410],
		["Abbotsford","WI",44.94,-90.31,40976832],
		["Erie","IL",41.65,-90.08,40977254],
		["Arabi","LA",29.95,-89.99,40977676],
		["Marked Tree","AR",35.53,-90.41,40978098],
		["Rudy","AR",35.52,-94.27,40978520],
		["Commerce","OK",36.93,-94.86,40978942],
		["Fairland","OK",36.75,-94.84,40979364],
		["Seymour","TX",33.59,-99.25,40979786],
		["Lanai City","HI",20.83,-156.92,40980208],
		["Sterling","CT",41.7,-71.83,40980629],
		["Westfield","NC",36.48,-80.61,40981050],
		["Silver Creek","MS",31.6,-90,40981471],
		["Brookfield","OH",41.26,-80.57,40981892],
		["Smithville","OH",40.86,-81.85,40982313],
		["Laporte","MN",47.21,-94.75,40982734],
		["Walnut Grove","MO",37.41,-93.54,40983155],
		["Gore","OK",35.54,-95.11,40983576],
		["Kellogg","ID",47.57,-116.11,40983997],
		["Antrim","NH",43.03,-71.94,40984417],
		["Saint Helen","MI",44.36,-84.41,40984837],
		["Northwood","IA",43.44,-93.21,40985257],
		["Mazomanie","WI",43.17,-89.79,40985677],
		["Crosby","MN",46.49,-93.95,40986097],
		["Cobden","IL",37.53,-89.25,40986517],
		["Clymer","NY",42.05,-79.66,40986936],
		["Rimersburg","PA",41.04,-79.5,40987355],
		["Beverly","WV",38.84,-79.87,40987774],
		["Quinton","AL",33.64,-87.14,40988193],
		["Hickory Flat","MS",34.61,-89.18,40988612],
		["North Lima","OH",40.95,-80.65,40989031],
		["Bark River","MI",45.72,-87.3,40989450],
		["Mayetta","KS",39.33,-95.72,40989869],
		["Mead","OK",34,-96.51,40990288],
		["Sedona","AZ",34.75,-111.77,40990707],
		["Waldport","OR",44.45,-123.94,40991126],
		["Orleans","MA",41.79,-70,40991544],
		["Lauderdale","MS",32.52,-88.46,40991962],
		["Springport","MI",42.37,-84.69,40992380],
		["Springfield","MN",44.23,-94.97,40992798],
		["Farmer City","IL",40.24,-88.64,40993216],
		["Advance","MO",37.1,-89.91,40993634],
		["Ketchum","ID",43.77,-114.62,40994052],
		["Buttonwillow","CA",35.43,-119.49,40994470],
		["Cutchogue","NY",41.01,-72.48,40994887],
		["Zionsville","PA",40.47,-75.52,40995304],
		["Roopville","GA",33.45,-85.13,40995721],
		["O Brien","FL",30.03,-82.92,40996138],
		["Ranburne","AL",33.52,-85.34,40996555],
		["Sallis","MS",33.02,-89.76,40996972],
		["Hanson","KY",37.41,-87.47,40997389],
		["Belle Center","OH",40.5,-83.74,40997806],
		["Sebewaing","MI",43.73,-83.45,40998223],
		["Crystal Falls","MI",46.09,-88.32,40998640],
		["Brandon","WI",43.73,-88.78,40999057],
		["Sumner","IL",38.71,-87.86,40999474],
		["Pleasant Plains","IL",39.87,-89.92,40999891],
		["Gerald","MO",38.39,-91.33,41000308],
		["Foreman","AR",33.71,-94.39,41000725],
		["Cabazon","CA",33.93,-116.77,41001142],
		["Montrose","NY",41.24,-73.94,41001558],
		["Somerville","OH",39.56,-84.63,41001974],
		["Leroy","MI",44.03,-85.41,41002390],
		["Baraga","MI",46.77,-88.49,41002806],
		["Moose Lake","MN",46.44,-92.76,41003222],
		["Gallatin","MO",39.91,-93.96,41003638],
		["Dakota City","NE",42.41,-96.41,41004054],
		["Doyline","LA",32.51,-93.41,41004470],
		["Big Piney","WY",42.54,-110.11,41004886],
		["Holloman Air Force Base","NM",32.83,-106.11,41005302],
		["Calais","ME",45.13,-67.2,41005717],
		["Pitcairn","PA",40.4,-79.77,41006132],
		["Bechtelsville","PA",40.37,-75.63,41006547],
		["La Crosse","VA",36.69,-78.09,41006962],
		["Kenna","WV",38.67,-81.66,41007377],
		["Julian","NC",35.95,-79.63,41007792],
		["Colerain","NC",36.2,-76.76,41008207],
		["Odum","GA",31.66,-82.02,41008622],
		["Trafford","AL",33.82,-86.74,41009037],
		["Oakman","AL",33.71,-87.38,41009452],
		["Lakeside Marblehead","OH",41.52,-82.78,41009867],
		["Holmesville","OH",40.62,-81.92,41010282],
		["Killbuck","OH",40.49,-81.98,41010697],
		["Memphis","IN",38.48,-85.76,41011112],
		["Madelia","MN",44.04,-94.41,41011527],
		["Royalton","MN",45.83,-94.28,41011942],
		["Moweaqua","IL",39.62,-89.01,41012357],
		["Winchester","IL",39.62,-90.45,41012772],
		["La Plata","MO",40.02,-92.49,41013187],
		["Minneapolis","KS",39.12,-97.7,41013602],
		["Buckfield","ME",44.28,-70.36,41014016],
		["Marienville","PA",41.45,-79.08,41014430],
		["Orrtanna","PA",39.88,-77.38,41014844],
		["Goode","VA",37.36,-79.38,41015258],
		["Charleston","WV",38.35,-81.63,41015672],
		["Elloree","SC",33.52,-80.57,41016086],
		["Branchville","SC",33.25,-80.81,41016500],
		["Stockport","OH",39.54,-81.79,41016914],
		["Rayland","OH",40.18,-80.69,41017328],
		["Leon","IA",40.74,-93.75,41017742],
		["Pleasantville","IA",41.38,-93.26,41018156],
		["Remsen","IA",42.81,-95.97,41018570],
		["Clear Lake","WI",45.25,-92.26,41018984],
		["Glyndon","MN",46.87,-96.57,41019398],
		["Beulah","ND",47.26,-101.77,41019812],
		["Lafitte","LA",29.68,-90.09,41020226],
		["Dermott","AR",33.52,-91.43,41020640],
		["Watkins","CO",39.75,-104.6,41021054],
		["Hagerman","NM",33.11,-104.32,41021468],
		["Richmond","ME",44.08,-69.79,41021881],
		["Galway","NY",43.01,-74.03,41022294],
		["Marion Center","PA",40.77,-79.04,41022707],
		["Kempton","PA",40.63,-75.87,41023120],
		["Webster Springs","WV",38.47,-80.41,41023533],
		["Pelham","NC",36.5,-79.46,41023946],
		["Eastanollee","GA",34.49,-83.25,41024359],
		["Butler","TN",36.31,-82,41024772],
		["Belmont","OH",40.02,-81.04,41025185],
		["Vienna","OH",41.25,-80.67,41025598],
		["Camden","MI",41.75,-84.75,41026011],
		["Fairfax","IA",41.92,-91.78,41026424],
		["Henry","IL",41.11,-89.36,41026837],
		["Weston","MO",39.41,-94.89,41027250],
		["Oberlin","LA",30.61,-92.76,41027663],
		["Tolar","TX",32.39,-97.91,41028076],
		["Mancos","CO",37.34,-108.29,41028489],
		["Tsaile","AZ",36.27,-109.26,41028902],
		["Garden Valley","CA",38.84,-120.83,41029315],
		["Westfield","PA",41.91,-77.54,41029727],
		["Onancock","VA",37.7,-75.74,41030139],
		["Harts","WV",38.03,-82.13,41030551],
		["Box Springs","GA",32.58,-84.61,41030963],
		["Arley","AL",34.08,-87.21,41031375],
		["Midway","KY",38.14,-84.68,41031787],
		["Waco","KY",37.73,-84.15,41032199],
		["Clinton","KY",36.66,-88.99,41032611],
		["Kingsville","OH",41.85,-80.64,41033023],
		["Cornell","WI",45.16,-91.15,41033435],
		["Hayfield","MN",43.89,-92.84,41033847],
		["Winona","MO",37,-91.33,41034259],
		["Norwood","MO",37.1,-92.41,41034671],
		["Omaha","TX",33.17,-94.74,41035083],
		["Rogers","TX",30.93,-97.22,41035495],
		["Flatonia","TX",29.68,-97.1,41035907],
		["Sacramento","CA",38.59,-121.49,41036319],
		["Harriman","NY",41.3,-74.14,41036730],
		["West Sand Lake","NY",42.63,-73.6,41037141],
		["Portville","NY",42.03,-78.33,41037552],
		["Russell","PA",41.92,-79.14,41037963],
		["Youngsville","PA",41.85,-79.31,41038374],
		["Landisburg","PA",40.34,-77.3,41038785],
		["Port Trevorton","PA",40.7,-76.91,41039196],
		["Lowgap","NC",36.49,-80.81,41039607],
		["Fort Lawn","SC",34.7,-80.89,41040018],
		["Hoboken","GA",31.18,-82.13,41040429],
		["Flat Lick","KY",36.82,-83.76,41040840],
		["Farmland","IN",40.18,-85.12,41041251],
		["Parker City","IN",40.18,-85.2,41041662],
		["Riverside","IA",41.48,-91.57,41042073],
		["Lena","WI",44.95,-88.05,41042484],
		["Huntsville","MO",39.43,-92.54,41042895],
		["Plantersville","TX",30.3,-95.84,41043306],
		["Westcliffe","CO",38.13,-105.46,41043717],
		["Trout Run","PA",41.39,-77.08,41044127],
		["Macclesfield","NC",35.75,-77.67,41044537],
		["Crouse","NC",35.42,-81.3,41044947],
		["New Brockton","AL",31.38,-85.92,41045357],
		["Berlin Center","OH",41.02,-80.93,41045767],
		["Little Hocking","OH",39.29,-81.73,41046177],
		["Jerome","MI",42.03,-84.45,41046587],
		["Lake Ann","MI",44.72,-85.84,41046997],
		["Belle Plaine","IA",41.89,-92.27,41047407],
		["Wellman","IA",41.46,-91.83,41047817],
		["Piedmont","SD",44.23,-103.4,41048227],
		["Roodhouse","IL",39.48,-90.37,41048637],
		["Kimball","NE",41.23,-103.65,41049047],
		["Highlands","NJ",40.4,-73.99,41049456],
		["Greenport","NY",41.1,-72.36,41049865],
		["Gillett","PA",41.95,-76.79,41050274],
		["Rock","WV",37.38,-81.23,41050683],
		["Staley","NC",35.79,-79.55,41051092],
		["Brownsboro","AL",34.7,-86.46,41051501],
		["Indian Mound","TN",36.47,-87.66,41051910],
		["Oronogo","MO",37.18,-94.46,41052319],
		["Edgerton","KS",38.76,-95.01,41052728],
		["Charlotte","TX",28.85,-98.7,41053137],
		["Kiowa","CO",39.34,-104.46,41053546],
		["Sublimity","OR",44.86,-122.73,41053955],
		["New Florence","PA",40.37,-79.07,41054363],
		["Wampum","PA",40.88,-80.33,41054771],
		["Burnside","KY",36.98,-84.6,41055179],
		["Seaman","OH",38.93,-83.57,41055587],
		["Cannelton","IN",37.91,-86.73,41055995],
		["Carrington","ND",47.45,-99.12,41056403],
		["Ellington","MO",37.23,-90.97,41056811],
		["Carbondale","KS",38.82,-95.69,41057219],
		["Herington","KS",38.67,-96.94,41057627],
		["Albion","NE",41.68,-98,41058035],
		["Rangely","CO",40.08,-108.77,41058443],
		["Hawthorne","NV",38.65,-118.83,41058851],
		["Mulino","OR",45.2,-122.54,41059259],
		["Liverpool","PA",40.57,-76.99,41059666],
		["Bahama","NC",36.17,-78.88,41060073],
		["Screven","GA",31.48,-82.01,41060480],
		["Epworth","IA",42.44,-90.93,41060887],
		["Hanover","MN",45.15,-93.65,41061294],
		["Kirkland","IL",42.09,-88.85,41061701],
		["Delavan","IL",40.37,-89.54,41062108],
		["Salisbury","MO",39.42,-92.8,41062515],
		["Watts","OK",36.1,-94.57,41062922],
		["Sagamore Beach","MA",41.79,-70.53,41063328],
		["Roper","NC",35.87,-76.61,41063734],
		["Panama City","FL",30.17,-85.67,41064140],
		["Bradford","TN",36.07,-88.81,41064546],
		["Conehatta","MS",32.45,-89.27,41064952],
		["Racine","OH",38.96,-81.91,41065358],
		["Walton","IN",40.66,-86.24,41065764],
		["Baroda","MI",41.95,-86.48,41066170],
		["Mount Calvary","WI",43.82,-88.24,41066576],
		["Lisbon","ND",46.43,-97.68,41066982],
		["Geneva","NE",40.52,-97.6,41067388],
		["Lexa","AR",34.59,-90.75,41067794],
		["West Hollywood","CA",34.09,-118.38,41068200],
		["Cincinnatus","NY",42.54,-75.89,41068605],
		["Bolivar","NY",42.06,-78.17,41069010],
		["Pittsburgh","PA",40.43,-79.97,41069415],
		["Appalachia","VA",36.9,-82.78,41069820],
		["Molena","GA",33.01,-84.5,41070225],
		["Montverde","FL",28.59,-81.67,41070630],
		["Iron City","TN",35.02,-87.58,41071035],
		["Eureka Springs","AR",36.39,-93.73,41071440],
		["Tulelake","CA",41.8,-121.46,41071845],
		["Warren","MA",42.21,-72.19,41072249],
		["Pounding Mill","VA",37.04,-81.73,41072653],
		["Marlinton","WV",38.22,-80.08,41073057],
		["Hollister","NC",36.25,-77.92,41073461],
		["Pendergrass","GA",34.16,-83.67,41073865],
		["Ecru","MS",34.35,-89.02,41074269],
		["Windsor","OH",41.56,-80.96,41074673],
		["Wanatah","IN",41.43,-86.88,41075077],
		["Newburg","MO",37.91,-91.9,41075481],
		["Greenwood","LA",32.44,-93.97,41075885],
		["Washington","OK",35.05,-97.48,41076289],
		["Holyoke","CO",40.58,-102.3,41076693],
		["Bethel","ME",44.4,-70.79,41077096],
		["Sherrill","NY",43.07,-75.59,41077499],
		["Ovid","NY",42.67,-76.82,41077902],
		["Fillmore","NY",42.46,-78.11,41078305],
		["Tower City","PA",40.58,-76.55,41078708],
		["Chatom","AL",31.46,-88.25,41079111],
		["Belvidere","TN",35.12,-86.18,41079514],
		["Paint Lick","KY",37.61,-84.41,41079917],
		["Hillman","MI",45.06,-83.9,41080320],
		["Hatley","WI",44.88,-89.33,41080723],
		["Motley","MN",46.33,-94.64,41081126],
		["Hinckley","IL",41.76,-88.64,41081529],
		["Cambridge","IL",41.3,-90.19,41081932],
		["Robeline","LA",31.69,-93.3,41082335],
		["Mc Rae","AR",35.11,-91.82,41082738],
		["La Villa","TX",26.29,-97.92,41083141],
		["Lockney","TX",34.12,-101.44,41083544],
		["Santa Rosa","NM",34.94,-104.67,41083947],
		["Sacramento","CA",38.58,-121.49,41084350],
		["Lemont Furnace","PA",39.92,-79.64,41084752],
		["Richland","PA",40.35,-76.25,41085154],
		["Woodstock","AL",33.16,-87.17,41085556],
		["Hudson","IA",42.42,-92.45,41085958],
		["Washburn","WI",46.67,-90.89,41086360],
		["Winsted","MN",44.96,-94.04,41086762],
		["Oronoco","MN",44.16,-92.54,41087164],
		["Victor","MT",46.39,-114.26,41087566],
		["Valley","NE",41.31,-96.34,41087968],
		["Boutte","LA",29.89,-90.39,41088370],
		["Salina","UT",38.95,-111.83,41088772],
		["Holualoa","HI",19.63,-155.92,41089174],
		["Whitefield","NH",44.37,-71.61,41089575],
		["Mayfield","NY",43.1,-74.26,41089976],
		["New Berlin","NY",42.62,-75.33,41090377],
		["Riva","MD",38.94,-76.58,41090778],
		["Rabun Gap","GA",34.95,-83.39,41091179],
		["Oxford","FL",28.92,-82.04,41091580],
		["Sugar Grove","OH",39.62,-82.54,41091981],
		["Elizabethtown","IN",39.13,-85.81,41092382],
		["Rapid River","MI",45.92,-86.97,41092783],
		["Casselton","ND",46.9,-97.21,41093184],
		["White Hall","IL",39.43,-90.4,41093585],
		["Marissa","IL",38.25,-89.75,41093986],
		["Ashton","ID",44.05,-111.32,41094387],
		["Phoenix","AZ",33.67,-111.95,41094788],
		["Clayton","NM",36.45,-103.17,41095189],
		["Borrego Springs","CA",33.17,-116.27,41095590],
		["Stevenson","WA",45.7,-121.72,41095991],
		["Mechanic Falls","ME",44.11,-70.39,41096391],
		["Winfield","PA",40.9,-76.87,41096791],
		["Danielsville","PA",40.78,-75.51,41097191],
		["Apalachicola","FL",29.72,-84.99,41097591],
		["Gloster","MS",31.19,-91.01,41097991],
		["East Sparta","OH",40.66,-81.35,41098391],
		["Santa Claus","IN",38.11,-86.92,41098791],
		["Orfordville","WI",42.62,-89.25,41099191],
		["Malta","MT",48.16,-107.87,41099591],
		["Sesser","IL",38.09,-89.05,41099991],
		["Half Way","MO",37.61,-93.24,41100391],
		["Marion","LA",32.89,-92.23,41100791],
		["Mammoth Spring","AR",36.49,-91.54,41101191],
		["Driftwood","TX",30.11,-98.03,41101591],
		["Guerneville","CA",38.52,-123.01,41101991],
		["Boston","MA",42.35,-71.06,41102390],
		["Vineyard Haven","MA",41.45,-70.6,41102789],
		["North Conway","NH",44.05,-71.12,41103188],
		["Brookhaven","NY",40.78,-72.91,41103587],
		["North Bangor","NY",44.79,-74.41,41103986],
		["Black River","NY",44,-75.79,41104385],
		["Johnsonburg","PA",41.49,-78.67,41104784],
		["Warfordsburg","PA",39.77,-78.2,41105183],
		["Zirconia","NC",35.2,-82.48,41105582],
		["Topeka","KS",39.03,-95.69,41105981],
		["Plympton","MA",41.95,-70.81,41106379],
		["Bloomsbury","NJ",40.65,-75.08,41106777],
		["Bishopville","MD",38.44,-75.19,41107175],
		["Shelby","AL",33.07,-86.56,41107573],
		["Sumiton","AL",33.75,-87.04,41107971],
		["Carlisle","IN",38.96,-87.4,41108369],
		["Poland","IN",39.45,-86.97,41108767],
		["Junction City","AR",33.02,-92.72,41109165],
		["Fairview","OK",36.26,-98.47,41109563],
		["Stratford","TX",36.33,-102.07,41109961],
		["Superior","AZ",33.28,-111.1,41110359],
		["Duncan","AZ",32.76,-109.07,41110757],
		["Ghent","NY",42.3,-73.64,41111154],
		["Petersburg","NY",42.72,-73.36,41111551],
		["Friendship","NY",42.2,-78.14,41111948],
		["Albertson","NC",35.11,-77.85,41112345],
		["Hillsboro","AL",34.63,-87.18,41112742],
		["Burlison","TN",35.55,-89.78,41113139],
		["Otway","OH",38.86,-83.18,41113536],
		["Berrien Springs","MI",41.94,-86.34,41113933],
		["Hanover","MI",42.1,-84.55,41114330],
		["Carlton","MN",46.66,-92.42,41114727],
		["Durand","IL",42.43,-89.32,41115124],
		["Council Grove","KS",38.66,-96.48,41115521],
		["Mount Hermon","LA",30.95,-90.27,41115918],
		["Campti","LA",31.89,-93.11,41116315],
		["Magazine","AR",35.15,-93.8,41116712],
		["Rush Springs","OK",34.77,-97.95,41117109],
		["Byers","CO",39.71,-104.22,41117506],
		["Blue Gap","AZ",36.07,-109.98,41117903],
		["Somerset","CA",38.62,-120.62,41118300],
		["Dunbarton","NH",43.1,-71.59,41118696],
		["Andover","NH",43.43,-71.82,41119092],
		["Limerick","ME",43.68,-70.79,41119488],
		["Tuxedo Park","NY",41.2,-74.2,41119884],
		["Stoystown","PA",40.1,-78.95,41120280],
		["Parsons","WV",39.09,-79.67,41120676],
		["Adrian","GA",32.52,-82.59,41121072],
		["Cunningham","TN",36.37,-87.43,41121468],
		["Hyden","KY",37.16,-83.37,41121864],
		["Tustin","MI",44.1,-85.45,41122260],
		["Granger","IA",41.76,-93.82,41122656],
		["Rio","WI",43.45,-89.23,41123052],
		["Martin","SD",43.17,-101.72,41123448],
		["Big Timber","MT",45.96,-109.85,41123844],
		["Kimberling City","MO",36.64,-93.42,41124240],
		["Hampton","AR",33.53,-92.46,41124636],
		["Lipan","TX",32.51,-98.04,41125032],
		["Holland","TX",30.88,-97.4,41125428],
		["Elm Mott","TX",31.67,-97.1,41125824],
		["Wellington","TX",34.85,-100.21,41126220],
		["Carlin","NV",40.73,-116.1,41126616],
		["Shohola","PA",41.47,-74.91,41127011],
		["Scottdale","GA",33.79,-84.26,41127406],
		["Holt","FL",30.72,-86.67,41127801],
		["Osteen","FL",28.84,-81.16,41128196],
		["Gaylesville","AL",34.26,-85.55,41128591],
		["Hale","MI",44.38,-83.8,41128986],
		["Bridgeport","IL",38.71,-87.75,41129381],
		["Valley Falls","KS",39.34,-95.46,41129776],
		["Caddo","OK",34.13,-96.26,41130171],
		["Little Compton","RI",41.5,-71.16,41130565],
		["Johnstown","PA",40.32,-78.91,41130959],
		["Pulaski","PA",41.09,-80.45,41131353],
		["Pequea","PA",39.9,-76.31,41131747],
		["Carsonville","MI",43.42,-82.67,41132141],
		["Osseo","MI",41.82,-84.53,41132535],
		["Silver Lake","WI",42.55,-88.16,41132929],
		["Marion","WI",44.67,-88.88,41133323],
		["Sebeka","MN",46.62,-95.08,41133717],
		["Ellsworth Afb","SD",44.14,-103.07,41134111],
		["Hanna City","IL",40.69,-89.79,41134505],
		["Herculaneum","MO",38.25,-90.38,41134899],
		["Bernie","MO",36.67,-89.97,41135293],
		["Independence","MO",39.08,-94.35,41135687],
		["Moreauville","LA",31.03,-91.98,41136081],
		["Black Hawk","CO",39.8,-105.49,41136475],
		["Pine","CO",39.42,-105.33,41136869],
		["Thayne","WY",42.91,-111,41137263],
		["Moss Beach","CA",37.51,-122.5,41137657],
		["Warner","NH",43.28,-71.81,41138050],
		["Barker","NY",43.32,-78.55,41138443],
		["Nanjemoy","MD",38.45,-77.21,41138836],
		["Dayton","MD",39.23,-76.98,41139229],
		["Lake Cormorant","MS",34.9,-90.21,41139622],
		["Wingo","KY",36.64,-88.73,41140015],
		["Sigourney","IA",41.33,-92.2,41140408],
		["Campbell","TX",33.14,-95.95,41140801],
		["Riesel","TX",31.47,-96.93,41141194],
		["Newberry Springs","CA",34.85,-116.51,41141587],
		["Edwards","CA",34.96,-117.87,41141980],
		["Bradford","VT",43.99,-72.12,41142372],
		["East Norwich","NY",40.84,-73.52,41142764],
		["Afton","NY",42.22,-75.52,41143156],
		["Avoca","NY",42.4,-77.42,41143548],
		["Gaston","NC",36.49,-77.64,41143940],
		["Orrum","NC",34.46,-79.01,41144332],
		["Eagle Lake","FL",27.97,-81.75,41144724],
		["Dutton","AL",34.6,-85.91,41145116],
		["Enterprise","MS",32.17,-88.81,41145508],
		["Lowell","OH",39.52,-81.5,41145900],
		["North Webster","IN",41.32,-85.69,41146292],
		["Aurora","MN",47.53,-92.24,41146684],
		["Colmesneil","TX",30.9,-94.42,41147076],
		["Huntsville","UT",41.28,-111.69,41147468],
		["Harrison","ME",44.11,-70.67,41147859],
		["Glenwood","NJ",41.24,-74.48,41148250],
		["New Columbia","PA",41.04,-76.88,41148641],
		["Shoemakersville","PA",40.49,-75.96,41149032],
		["Pembroke","VA",37.32,-80.63,41149423],
		["Little Mountain","SC",34.19,-81.41,41149814],
		["Abbeville","GA",31.99,-83.3,41150205],
		["Nunnelly","TN",35.89,-87.52,41150596],
		["Alexandria","OH",40.08,-82.61,41150987],
		["Weston","OH",41.34,-83.79,41151378],
		["Corning","OH",39.6,-82.08,41151769],
		["Bloomville","OH",41.05,-83.01,41152160],
		["Saint Charles","IA",41.28,-93.8,41152551],
		["Van Meter","IA",41.53,-93.95,41152942],
		["Mapleton","MN",43.92,-93.95,41153333],
		["New York Mills","MN",46.51,-95.37,41153724],
		["Inman","KS",38.22,-97.77,41154115],
		["Marvell","AR",34.55,-90.91,41154506],
		["La Jara","CO",37.27,-105.96,41154897],
		["Hill Afb","UT",41.12,-111.98,41155288],
		["Monroe","OR",44.34,-123.33,41155679],
		["Unity","ME",44.61,-69.33,41156069],
		["Upper Black Eddy","PA",40.53,-75.12,41156459],
		["Trappe","MD",38.65,-76.05,41156849],
		["Weirsdale","FL",28.98,-81.92,41157239],
		["Whites Creek","TN",36.28,-86.83,41157629],
		["Beaumont","MS",31.17,-88.92,41158019],
		["Rosholt","WI",44.63,-89.3,41158409],
		["Boyceville","WI",45.04,-92.03,41158799],
		["Fosston","MN",47.58,-95.74,41159189],
		["Thompsonville","IL",37.91,-88.76,41159579],
		["Hessmer","LA",31.05,-92.12,41159969],
		["Carnegie","OK",35.1,-98.59,41160359],
		["Bovina","TX",34.51,-102.88,41160749],
		["Monmouth Beach","NJ",40.33,-73.98,41161138],
		["Nunda","NY",42.58,-77.93,41161527],
		["Sandy Spring","MD",39.14,-77.02,41161916],
		["Norfolk","VA",36.84,-76.28,41162305],
		["Glade Hill","VA",37,-79.77,41162694],
		["Cramerton","NC",35.23,-81.07,41163083],
		["Sautee Nacoochee","GA",34.74,-83.71,41163472],
		["Pioneer","TN",36.46,-84.29,41163861],
		["Gleason","TN",36.21,-88.61,41164250],
		["Kingman","IN",39.96,-87.27,41164639],
		["Sterling","MI",44.03,-84.02,41165028],
		["Shell Lake","WI",45.73,-91.89,41165417],
		["Red Lodge","MT",45.19,-109.24,41165806],
		["Hanapepe","HI",21.91,-159.59,41166195],
		["Cheshire","MA",42.55,-73.15,41166583],
		["Eastham","MA",41.83,-69.96,41166971],
		["Callicoon","NY",41.76,-75.05,41167359],
		["Hyndman","PA",39.82,-78.72,41167747],
		["Byrdstown","TN",36.57,-85.13,41168135],
		["Jeffersonville","OH",39.65,-83.55,41168523],
		["Munith","MI",42.39,-84.25,41168911],
		["Trempealeau","WI",44,-91.43,41169299],
		["Plains","MT",47.57,-114.73,41169687],
		["Argenta","IL",39.98,-88.81,41170075],
		["Labadie","MO",38.53,-90.87,41170463],
		["Maysville","MO",39.88,-94.36,41170851],
		["Benton","KS",37.78,-97.1,41171239],
		["Winnebago","NE",42.23,-96.47,41171627],
		["Baldwin","LA",29.83,-91.55,41172015],
		["Warren","TX",30.61,-94.4,41172403],
		["Kemmerer","WY",41.78,-110.55,41172791],
		["Westwood","CA",40.26,-121.02,41173179],
		["Middle Grove","NY",43.11,-74.02,41173566],
		["Greeleyville","SC",33.57,-79.98,41173953],
		["Coward","SC",33.97,-79.74,41174340],
		["Edison","GA",31.56,-84.73,41174727],
		["Gnadenhutten","OH",40.35,-81.43,41175114],
		["Beverly","OH",39.54,-81.63,41175501],
		["Bancroft","MI",42.87,-84.06,41175888],
		["Beulah","MI",44.62,-86.09,41176275],
		["Gaylord","MN",44.55,-94.21,41176662],
		["Brookport","IL",37.12,-88.62,41177049],
		["Gower","MO",39.61,-94.59,41177436],
		["Barnett","MO",38.37,-92.67,41177823],
		["Elkland","MO",37.44,-93.03,41178210],
		["Georgetown","CA",38.93,-120.77,41178597],
		["La Conner","WA",48.41,-122.53,41178984],
		["Fairchild Air Force Base","WA",47.61,-117.65,41179371],
		["Belgrade","ME",44.44,-69.83,41179757],
		["Randolph Center","VT",43.93,-72.58,41180143],
		["Millport","AL",33.55,-88.08,41180529],
		["Jellico","TN",36.57,-84.12,41180915],
		["Pembroke","KY",36.77,-87.35,41181301],
		["Saint Louisville","OH",40.17,-82.41,41181687],
		["Marengo","IN",38.37,-86.34,41182073],
		["Chassell","MI",47.03,-88.53,41182459],
		["Darien","WI",42.6,-88.71,41182845],
		["Kansasville","WI",42.7,-88.12,41183231],
		["Withee","WI",44.95,-90.59,41183617],
		["Spearfish","SD",44.48,-103.84,41184003],
		["Wayne","IL",41.95,-88.26,41184389],
		["Greenup","IL",39.24,-88.15,41184775],
		["Andale","KS",37.79,-97.62,41185161],
		["Oswego","KS",37.16,-95.11,41185547],
		["Van Vleck","TX",29.03,-95.88,41185933],
		["Woodstock","VT",43.62,-72.51,41186318],
		["Schenectady","NY",42.8,-73.92,41186703],
		["Blairs","VA",36.68,-79.38,41187088],
		["Walstonburg","NC",35.59,-77.69,41187473],
		["Mooresburg","TN",36.34,-83.22,41187858],
		["Worthington","IN",39.11,-86.97,41188243],
		["Sioux City","IA",42.5,-96.39,41188628],
		["Ida Grove","IA",42.34,-95.47,41189013],
		["Saint Francis","SD",43.14,-100.9,41189398],
		["Charlo","MT",47.42,-114.18,41189783],
		["Tipton","MO",38.65,-92.78,41190168],
		["Declo","ID",42.46,-113.39,41190553],
		["Deming","WA",48.8,-122.06,41190938],
		["Red Creek","NY",43.24,-76.72,41191322],
		["Adams Center","NY",43.85,-75.99,41191706],
		["Genoa","WV",38.07,-82.46,41192090],
		["Meigs","GA",31.07,-84.09,41192474],
		["Mize","MS",31.86,-89.55,41192858],
		["Scooba","MS",32.83,-88.47,41193242],
		["Soso","MS",31.75,-89.27,41193626],
		["Gates Mills","OH",41.53,-81.41,41194010],
		["Maybee","MI",42,-83.51,41194394],
		["Hudson","IL",40.6,-88.98,41194778],
		["Goreville","IL",37.55,-88.97,41195162],
		["Des Arc","AR",34.97,-91.5,41195546],
		["Omaha","AR",36.46,-93.19,41195930],
		["Honey Grove","TX",33.58,-95.9,41196314],
		["Greybull","WY",44.49,-108.05,41196698],
		["Newbury","MA",42.8,-71,41197081],
		["Campton","NH",43.86,-71.63,41197464],
		["Lake Katrine","NY",41.98,-73.99,41197847],
		["Keuka Park","NY",42.61,-77.09,41198230],
		["Seneca","PA",41.37,-79.7,41198613],
		["Nesquehoning","PA",40.86,-75.82,41198996],
		["Triadelphia","WV",40.05,-80.62,41199379],
		["Waverly Hall","GA",32.68,-84.73,41199762],
		["Sadieville","KY",38.39,-84.53,41200145],
		["Eagle","MI",42.8,-84.79,41200528],
		["Watkins","MN",45.31,-94.4,41200911],
		["Rushford","MN",43.81,-91.75,41201294],
		["Yale","OK",36.11,-96.7,41201677],
		["Stinnett","TX",35.82,-101.44,41202060],
		["Wiggins","CO",40.23,-104.07,41202443],
		["Carson","WA",45.79,-121.89,41202826],
		["Islandia","NY",40.8,-73.17,41203208],
		["Cameron","WV",39.82,-80.57,41203590],
		["Ruffin","SC",33,-80.81,41203972],
		["Ellerslie","GA",32.64,-84.79,41204354],
		["Ariton","AL",31.59,-85.71,41204736],
		["Red Level","AL",31.4,-86.6,41205118],
		["Decaturville","TN",35.58,-88.11,41205500],
		["Guthrie","KY",36.65,-87.17,41205882],
		["Youngstown","OH",41.09,-80.64,41206264],
		["Markle","IN",40.82,-85.33,41206646],
		["Poseyville","IN",38.17,-87.78,41207028],
		["Abrams","WI",44.78,-88.06,41207410],
		["Lake Andes","SD",43.15,-98.53,41207792],
		["Elkhart","KS",37,-101.89,41208174],
		["Sarepta","LA",32.89,-93.45,41208556],
		["Oklahoma City","OK",35.46,-97.51,41208938],
		["Howe","OK",34.94,-94.63,41209320],
		["Mountain Home A F B","ID",43.05,-115.86,41209702],
		["Cameron","AZ",36.05,-111.45,41210084],
		["Tonopah","NV",38.35,-116.61,41210466],
		["Otis","OR",45,-123.89,41210848],
		["Newport","ME",44.83,-69.26,41211229],
		["Paul Smiths","NY",44.43,-74.25,41211610],
		["Columbia","SC",34,-81.03,41211991],
		["Micanopy","FL",29.5,-82.28,41212372],
		["Tishomingo","MS",34.63,-88.22,41212753],
		["Beach City","OH",40.65,-81.58,41213134],
		["Arlington","OH",40.89,-83.65,41213515],
		["Morristown","IN",39.67,-85.69,41213896],
		["Dallas Center","IA",41.68,-93.98,41214277],
		["Underwood","IA",41.38,-95.67,41214658],
		["Forsyth","MT",46.26,-106.67,41215039],
		["Villa Grove","IL",39.86,-88.15,41215420],
		["Perry","KS",39.07,-95.38,41215801],
		["Longville","LA",30.6,-93.23,41216182],
		["Morton","TX",33.72,-102.75,41216563],
		["Ralls","TX",33.67,-101.38,41216944],
		["Readfield","ME",44.38,-69.96,41217324],
		["Fairchance","PA",39.82,-79.75,41217704],
		["South Fork","PA",40.36,-78.79,41218084],
		["Huntsville","TN",36.41,-84.48,41218464],
		["Clearfield","KY",38.16,-83.43,41218844],
		["The Plains","OH",39.36,-82.13,41219224],
		["Gaston","IN",40.31,-85.5,41219604],
		["Manitou Beach","MI",41.95,-84.26,41219984],
		["Pride","LA",30.69,-90.97,41220364],
		["Horatio","AR",33.93,-94.35,41220744],
		["Rose Bud","AR",35.33,-92.07,41221124],
		["Pueblo Of Acoma","NM",34.9,-107.58,41221504],
		["Georgetown","PA",40.64,-80.49,41221883],
		["Shippenville","PA",41.25,-79.46,41222262],
		["Washington","DC",38.89,-77.03,41222641],
		["Cordova","MD",38.87,-75.99,41223020],
		["Mardela Springs","MD",38.45,-75.75,41223399],
		["Sutherland","VA",37.19,-77.56,41223778],
		["Grand Island","FL",28.88,-81.73,41224157],
		["Tracy City","TN",35.25,-85.75,41224536],
		["Marshallville","OH",40.9,-81.73,41224915],
		["Guttenberg","IA",42.78,-91.1,41225294],
		["Kohler","WI",43.73,-87.77,41225673],
		["Houston","MN",43.76,-91.57,41226052],
		["Waterville","MN",44.22,-93.57,41226431],
		["Canby","MN",44.71,-96.26,41226810],
		["Frenchtown","MT",47.07,-114.22,41227189],
		["Ramsey","IL",39.14,-89.1,41227568],
		["Louisville","IL",38.76,-88.5,41227947],
		["Winslow","AR",35.8,-94.13,41228326],
		["Brownsville","OR",44.38,-122.94,41228705],
		["Gorham","NH",44.39,-71.18,41229083],
		["Cliffwood","NJ",40.43,-74.23,41229461],
		["Pocono Lake","PA",41.11,-75.56,41229839],
		["Marble","NC",35.17,-83.92,41230217],
		["Shelbiana","KY",37.42,-82.49,41230595],
		["Campbellsburg","IN",38.65,-86.26,41230973],
		["Palmyra","WI",42.87,-88.58,41231351],
		["Fall River","WI",43.38,-89.04,41231729],
		["Mahnomen","MN",47.31,-95.96,41232107],
		["Clifton","IL",40.93,-87.93,41232485],
		["Sandoval","IL",38.61,-89.11,41232863],
		["Huntington","AR",35.08,-94.26,41233241],
		["Ivoryton","CT",41.34,-72.43,41233618],
		["Colora","MD",39.66,-76.09,41233995],
		["Flintville","TN",35.04,-86.45,41234372],
		["Adolphus","KY",36.67,-86.27,41234749],
		["Farmersville","OH",39.67,-84.42,41235126],
		["Martin","MI",42.53,-85.63,41235503],
		["Young America","MN",44.78,-93.91,41235880],
		["Troy","MT",48.72,-115.74,41236257],
		["Minonk","IL",40.9,-89.03,41236634],
		["Fisher","IL",40.31,-88.34,41237011],
		["Kirbyville","MO",36.62,-93.16,41237388],
		["Firth","NE",40.53,-96.6,41237765],
		["Oklahoma City","OK",35.46,-97.51,41238142],
		["Barnsdall","OK",36.56,-96.16,41238519],
		["Martindale","TX",29.84,-97.84,41238896],
		["Sunray","TX",36.01,-101.82,41239273],
		["Gamerco","NM",35.57,-108.76,41239650],
		["Eldred","PA",41.95,-78.38,41240026],
		["King William","VA",37.68,-77.01,41240402],
		["Lancing","TN",36.15,-84.69,41240778],
		["Washburn","TN",36.31,-83.59,41241154],
		["Garrison","KY",38.55,-83.19,41241530],
		["Huntsville","OH",40.44,-83.8,41241906],
		["North Jackson","OH",41.09,-80.86,41242282],
		["Conklin","MI",43.14,-85.85,41242658],
		["North Freedom","WI",43.45,-89.86,41243034],
		["Jasper","MO",37.33,-94.3,41243410],
		["Eagle","NE",40.81,-96.43,41243786],
		["Hughes","AR",34.94,-90.47,41244162],
		["Cotuit","MA",41.62,-70.44,41244537],
		["New Durham","NH",43.43,-71.17,41244912],
		["Hampton","CT",41.78,-72.05,41245287],
		["Edinburg","PA",41.02,-80.46,41245662],
		["Mount Clare","WV",39.2,-80.26,41246037],
		["Henning","TN",35.67,-89.57,41246412],
		["Kenton","TN",36.19,-89.01,41246787],
		["Taft","TN",35.05,-86.68,41247162],
		["Fairbank","IA",42.63,-92.05,41247537],
		["Granton","WI",44.58,-90.46,41247912],
		["Birch Tree","MO",36.99,-91.49,41248287],
		["Moundridge","KS",38.2,-97.51,41248662],
		["Murchison","TX",32.27,-95.75,41249037],
		["Maxwell","TX",29.88,-97.83,41249412],
		["Amado","AZ",31.71,-111.02,41249787],
		["Shonto","AZ",36.72,-110.62,41250162],
		["Canyonville","OR",42.94,-123.22,41250537],
		["Eastsound","WA",48.65,-122.81,41250912],
		["Seaside Heights","NJ",39.94,-74.07,41251286],
		["Norfolk","NY",44.8,-74.98,41251660],
		["Cassadaga","NY",42.34,-79.31,41252034],
		["Woodleaf","NC",35.76,-80.59,41252408],
		["Ramer","TN",35.07,-88.61,41252782],
		["Belmont","MS",34.5,-88.2,41253156],
		["Elmore","OH",41.47,-83.29,41253530],
		["Hudson","IN",41.53,-85.08,41253904],
		["Belmond","IA",42.84,-93.6,41254278],
		["Humboldt","KS",37.81,-95.43,41254652],
		["Oklahoma City","OK",35.46,-97.51,41255026],
		["Beckville","TX",32.24,-94.45,41255400],
		["Lovelady","TX",31.12,-95.44,41255774],
		["Port Norris","NJ",39.24,-75.04,41256147],
		["Port Charlotte","FL",26.98,-82.1,41256520],
		["Carrollton","MS",33.5,-89.92,41256893],
		["Lyons","MI",42.98,-84.94,41257266],
		["Donnellson","IA",40.64,-91.56,41257639],
		["Lone Rock","WI",43.18,-90.2,41258012],
		["Lester Prairie","MN",44.88,-94.03,41258385],
		["Exeter","MO",36.67,-93.93,41258758],
		["Ozawkie","KS",39.23,-95.46,41259131],
		["Ellsworth","KS",38.73,-98.22,41259504],
		["Albany","TX",32.72,-99.29,41259877],
		["Port Aransas","TX",27.82,-97.06,41260250],
		["Enterprise","UT",37.52,-113.75,41260623],
		["Los Olivos","CA",34.71,-120.01,41260996],
		["Hardwick","VT",44.5,-72.37,41261368],
		["Goshen","CT",41.85,-73.23,41261740],
		["Sandy Lake","PA",41.34,-80.08,41262112],
		["Alamo","GA",32.14,-82.77,41262484],
		["Lenox","GA",31.26,-83.46,41262856],
		["Mulga","AL",33.54,-86.97,41263228],
		["Memphis","TN",35.1,-90,41263600],
		["Big Sandy","TN",36.23,-88.08,41263972],
		["Ewing","KY",38.42,-83.86,41264344],
		["Deckerville","MI",43.52,-82.74,41264716],
		["Darby","MT",45.78,-114.24,41265088],
		["Roland","AR",34.89,-92.52,41265460],
		["Schofield Barracks","HI",21.49,-158.06,41265832],
		["Sumas","WA",48.94,-122.23,41266204],
		["Center Conway","NH",43.97,-71.04,41266575],
		["Norwalk","CT",41.09,-73.42,41266946],
		["Guilderland","NY",42.69,-73.89,41267317],
		["Portersville","PA",40.92,-80.14,41267688],
		["Kulpmont","PA",40.79,-76.47,41268059],
		["Mcadoo","PA",40.9,-75.99,41268430],
		["Glen","MS",34.86,-88.5,41268801],
		["Mathiston","MS",33.53,-89.12,41269172],
		["Williamsport","OH",39.58,-83.11,41269543],
		["Wilton","WI",43.81,-90.52,41269914],
		["Eagle Lake","MN",44.15,-93.88,41270285],
		["Slayton","MN",43.99,-95.75,41270656],
		["Walker","MN",47.08,-94.57,41271027],
		["Worden","IL",38.93,-89.83,41271398],
		["Stanberry","MO",40.21,-94.53,41271769],
		["Inkom","ID",42.82,-112.23,41272140],
		["Saint David","AZ",31.88,-110.18,41272511],
		["Glide","OR",43.24,-123,41272882],
		["Chicopee","MA",42.17,-72.57,41273252],
		["Osterville","MA",41.62,-70.38,41273622],
		["Harpswell","ME",43.8,-69.98,41273992],
		["Eddington","ME",44.82,-68.69,41274362],
		["New York","NY",40.71,-73.99,41274732],
		["Woodsboro","MD",39.53,-77.31,41275102],
		["Bland","VA",37.1,-81.11,41275472],
		["Marbury","AL",32.64,-86.54,41275842],
		["Ten Mile","TN",35.66,-84.66,41276212],
		["Hickory","KY",36.84,-88.69,41276582],
		["Green Springs","OH",41.25,-83.05,41276952],
		["Hersey","MI",43.84,-85.44,41277322],
		["Mitchellville","IA",41.66,-93.36,41277692],
		["Lewiston","MN",43.98,-91.86,41278062],
		["Holdingford","MN",45.73,-94.47,41278432],
		["Highland","KS",39.86,-95.26,41278802],
		["Hinsdale","MA",42.42,-73.07,41279171],
		["Fiskdale","MA",42.12,-72.11,41279540],
		["Essex Fells","NJ",40.82,-74.28,41279909],
		["Thiells","NY",41.2,-74.01,41280278],
		["Athens","NY",42.26,-73.81,41280647],
		["Philadelphia","NY",44.15,-75.7,41281016],
		["New Stanton","PA",40.22,-79.6,41281385],
		["Dayton","PA",40.88,-79.24,41281754],
		["Mascot","TN",36.06,-83.76,41282123],
		["Ovett","MS",31.48,-89.03,41282492],
		["Steens","MS",33.57,-88.31,41282861],
		["Ely","IA",41.87,-91.58,41283230],
		["Elroy","WI",43.74,-90.27,41283599],
		["Gainesville","MO",36.6,-92.42,41283968],
		["Farwell","TX",34.39,-102.89,41284337],
		["Salt Lake City","UT",40.75,-111.9,41284706],
		["Piru","CA",34.43,-118.75,41285075],
		["Suquamish","WA",47.72,-122.56,41285444],
		["Waterboro","ME",43.53,-70.71,41285812],
		["Aurora","NY",42.75,-76.7,41286180],
		["East Freedom","PA",40.32,-78.45,41286548],
		["Germansville","PA",40.72,-75.71,41286916],
		["Landis","NC",35.54,-80.61,41287284],
		["Awendaw","SC",33.03,-79.61,41287652],
		["Greenwood","FL",30.87,-85.16,41288020],
		["Wellington","AL",33.86,-85.91,41288388],
		["Parkersburg","IA",42.57,-92.78,41288756],
		["Williams Bay","WI",42.57,-88.54,41289124],
		["Woodville","WI",44.95,-92.28,41289492],
		["Marine On Saint Croix","MN",45.2,-92.78,41289860],
		["Edwards","IL",40.75,-89.71,41290228],
		["Fort Calhoun","NE",41.45,-96.02,41290596],
		["Barksdale Afb","LA",32.51,-93.6,41290964],
		["Bartlett","TX",30.79,-97.42,41291332],
		["Milan","NM",35.18,-107.89,41291700],
		["Morongo Valley","CA",34.1,-116.58,41292068],
		["Sanbornton","NH",43.48,-71.58,41292435],
		["Munnsville","NY",42.97,-75.58,41292802],
		["Hookstown","PA",40.59,-80.47,41293169],
		["Chesapeake City","MD",39.52,-75.81,41293536],
		["Enigma","GA",31.4,-83.33,41293903],
		["Coden","AL",30.4,-88.16,41294270],
		["North Lewisburg","OH",40.22,-83.55,41294637],
		["Waynesfield","OH",40.6,-83.97,41295004],
		["Fountaintown","IN",39.66,-85.78,41295371],
		["Depauw","IN",38.33,-86.21,41295738],
		["Grundy Center","IA",42.36,-92.77,41296105],
		["Fayette","IA",42.84,-91.8,41296472],
		["Stanchfield","MN",45.66,-93.26,41296839],
		["Volga","SD",44.32,-96.92,41297206],
		["Nash","TX",33.44,-94.12,41297573],
		["Holliday","TX",33.81,-98.68,41297940],
		["Sebastian","TX",26.34,-97.79,41298307],
		["Bradford","RI",41.39,-71.75,41298673],
		["Chateaugay","NY",44.92,-74.08,41299039],
		["Smicksburg","PA",40.87,-79.17,41299405],
		["Clear Brook","VA",39.26,-78.1,41299771],
		["Charlotte Court House","VA",37.05,-78.63,41300137],
		["Clearwater Beach","FL",27.98,-82.82,41300503],
		["Gallion","AL",32.54,-87.7,41300869],
		["Lexington","KY",38.04,-84.45,41301235],
		["Monroe","IA",41.52,-93.1,41301601],
		["Mount Carroll","IL",42.09,-89.97,41301967],
		["Lonedell","MO",38.3,-90.82,41302333],
		["Lincoln","MO",38.39,-93.33,41302699],
		["Leachville","AR",35.92,-90.25,41303065],
		["Eucha","OK",36.37,-94.91,41303431],
		["Keota","OK",35.25,-94.92,41303797],
		["Loma","CO",39.2,-108.82,41304163],
		["Firth","ID",43.22,-111.99,41304529],
		["Brisbane","CA",37.68,-122.41,41304895],
		["Montara","CA",37.54,-122.5,41305261],
		["Port Hadlock","WA",48,-122.74,41305627],
		["Berlin","MA",42.38,-71.63,41305992],
		["Chichester","NH",43.25,-71.39,41306357],
		["Rock Tavern","NY",41.47,-74.16,41306722],
		["Chaumont","NY",44.06,-76.13,41307087],
		["Conesus","NY",42.71,-77.66,41307452],
		["Boiling Springs","NC",35.24,-81.66,41307817],
		["Horse Shoe","NC",35.39,-82.64,41308182],
		["Mediapolis","IA",41,-91.16,41308547],
		["Fort Pierre","SD",44.36,-100.37,41308912],
		["Steeleville","IL",38,-89.66,41309277],
		["Hartsburg","MO",38.69,-92.3,41309642],
		["Pleasant Hope","MO",37.46,-93.27,41310007],
		["Zavalla","TX",31.15,-94.41,41310372],
		["Mentmore","NM",35.51,-108.84,41310737],
		["Republic","WA",48.59,-118.7,41311102],
		["Arlington","VT",43.06,-73.15,41311466],
		["Keasbey","NJ",40.51,-74.31,41311830],
		["Little Valley","NY",42.24,-78.79,41312194],
		["Cowen","WV",38.41,-80.55,41312558],
		["Lansing","NC",36.49,-81.5,41312922],
		["Bear Lake","MI",44.42,-86.14,41313286],
		["Guthrie Center","IA",41.67,-94.49,41313650],
		["Panora","IA",41.69,-94.36,41314014],
		["Springville","IA",42.05,-91.44,41314378],
		["Mishicot","WI",44.23,-87.64,41314742],
		["Choteau","MT",47.86,-112.44,41315106],
		["Archie","MO",38.48,-94.34,41315470],
		["Lewisville","AR",33.36,-93.57,41315834],
		["Colbert","OK",33.85,-96.5,41316198],
		["Sumner","TX",33.77,-95.71,41316562],
		["Woodsboro","TX",28.23,-97.32,41316926],
		["Chiloquin","OR",42.72,-121.79,41317290],
		["Sewaren","NJ",40.55,-74.26,41317653],
		["Pleasant Plain","OH",39.27,-84.1,41318016],
		["Paragon","IN",39.39,-86.56,41318379],
		["Mecosta","MI",43.61,-85.23,41318742],
		["State Center","IA",42.01,-93.16,41319105],
		["Muscoda","WI",43.18,-90.43,41319468],
		["Gravois Mills","MO",38.3,-92.82,41319831],
		["Saint Gabriel","LA",30.28,-91.07,41320194],
		["Meridian","TX",31.92,-97.64,41320557],
		["Paige","TX",30.21,-97.11,41320920],
		["Wright","WY",43.75,-105.49,41321283],
		["El Prado","NM",36.43,-105.65,41321646],
		["Walpole","NH",43.08,-72.43,41322008],
		["Salt Point","NY",41.8,-73.8,41322370],
		["Locke","NY",42.66,-76.43,41322732],
		["Turbotville","PA",41.1,-76.76,41323094],
		["Millington","MD",39.25,-75.84,41323456],
		["Rentz","GA",32.38,-82.99,41323818],
		["Miramar Beach","FL",30.39,-86.31,41324180],
		["Sonora","KY",37.52,-85.89,41324542],
		["West Mansfield","OH",40.39,-83.54,41324904],
		["Lewiston","MI",44.88,-84.31,41325266],
		["Alta","IA",42.67,-95.3,41325628],
		["Colstrip","MT",45.92,-106.68,41325990],
		["Naples","TX",33.2,-94.67,41326352],
		["Van Horn","TX",31.04,-104.83,41326714],
		["Prewitt","NM",35.36,-108.04,41327076],
		["Carmel","ME",44.79,-69.05,41327437],
		["Levant","ME",44.86,-68.93,41327798],
		["Holmes","PA",39.9,-75.3,41328159],
		["Whiteford","MD",39.7,-76.34,41328520],
		["Westernport","MD",39.48,-79.04,41328881],
		["Bradenton","FL",27.48,-82.57,41329242],
		["Collinwood","TN",35.17,-87.74,41329603],
		["Edon","OH",41.55,-84.76,41329964],
		["Farmersburg","IN",39.25,-87.38,41330325],
		["Britt","IA",43.09,-93.8,41330686],
		["La Valle","WI",43.58,-90.13,41331047],
		["Parkston","SD",43.39,-97.98,41331408],
		["Eros","LA",32.39,-92.42,41331769],
		["Poolville","TX",32.97,-97.87,41332130],
		["Iola","TX",30.73,-96.07,41332491],
		["Paradise","UT",41.48,-111.77,41332852],
		["Second Mesa","AZ",35.81,-110.48,41333213],
		["Malaga","WA",47.32,-120.19,41333574],
		["Elbridge","NY",43.03,-76.44,41333934],
		["Oriskany","NY",43.15,-75.33,41334294],
		["Crimora","VA",38.16,-78.83,41334654],
		["Haddock","GA",33.08,-83.44,41335014],
		["Christmas","FL",28.53,-81.02,41335374],
		["Sweet Water","AL",32.1,-87.86,41335734],
		["Michie","TN",35.06,-88.42,41336094],
		["Londonderry","OH",39.27,-82.75,41336454],
		["Cloverdale","OH",41.01,-84.3,41336814],
		["La Fontaine","IN",40.67,-85.72,41337174],
		["Fountain City","IN",39.95,-84.91,41337534],
		["Moville","IA",42.48,-96.06,41337894],
		["North Prairie","WI",42.93,-88.39,41338254],
		["Forreston","IL",42.12,-89.57,41338614],
		["Silex","MO",39.12,-91.05,41338974],
		["Juniata","NE",40.58,-98.5,41339334],
		["Smackover","AR",33.36,-92.73,41339694],
		["London","AR",35.32,-93.24,41340054],
		["Normangee","TX",31.03,-96.11,41340414],
		["Memphis","TX",34.72,-100.54,41340774],
		["Millville","UT",41.68,-111.82,41341134],
		["Gunnison","UT",39.22,-111.75,41341494],
		["Indian Wells","AZ",35.41,-110.08,41341854],
		["Colebrook","NH",44.89,-71.49,41342213],
		["Sherman","NY",42.16,-79.59,41342572],
		["Saluda","VA",37.6,-76.59,41342931],
		["Newborn","GA",33.51,-83.69,41343290],
		["Fort Stewart","GA",31.87,-81.61,41343649],
		["Mound Bayou","MS",33.88,-90.72,41344008],
		["Upton","KY",37.46,-85.89,41344367],
		["Oregonia","OH",39.41,-84.04,41344726],
		["Summitville","IN",40.33,-85.64,41345085],
		["Cleveland","WI",43.91,-87.75,41345444],
		["Pittsville","WI",44.45,-90.13,41345803],
		["Mountain Iron","MN",47.52,-92.62,41346162],
		["Princeton","MO",40.39,-93.58,41346521],
		["Sorrento","LA",30.18,-90.86,41346880],
		["Elm Grove","LA",32.34,-93.49,41347239],
		["Ogdensburg","NJ",41.07,-74.59,41347597],
		["Amenia","NY",41.84,-73.55,41347955],
		["Kintnersville","PA",40.53,-75.21,41348313],
		["Exmore","VA",37.53,-75.82,41348671],
		["Greenville","VA",38,-79.15,41349029],
		["Alton","VA",36.56,-79,41349387],
		["Hickman","KY",36.56,-89.18,41349745],
		["New Sharon","IA",41.47,-92.65,41350103],
		["Walker","IA",42.28,-91.78,41350461],
		["Chenoa","IL",40.74,-88.71,41350819],
		["Aviston","IL",38.6,-89.6,41351177],
		["Lockesburg","AR",33.97,-94.17,41351535],
		["Cushing","TX",31.81,-94.84,41351893],
		["Wilson","WY",43.5,-110.87,41352251],
		["Rock Point","AZ",36.66,-109.6,41352609],
		["Eleele","HI",21.91,-159.58,41352967],
		["Manchester","ME",44.32,-69.86,41353324],
		["Salisbury Mills","NY",41.44,-74.12,41353681],
		["Copenhagen","NY",43.89,-75.67,41354038],
		["Milford","VA",38.02,-77.36,41354395],
		["Mount Crawford","VA",38.35,-78.94,41354752],
		["Halifax","NC",36.32,-77.59,41355109],
		["Ekron","KY",37.93,-86.17,41355466],
		["Converse","IN",40.57,-85.87,41355823],
		["Potosi","WI",42.68,-90.71,41356180],
		["Maroa","IL",40.03,-88.95,41356537],
		["Omaha","NE",41.26,-96.01,41356894],
		["Omaha","NE",41.26,-96.01,41357251],
		["Shady Cove","OR",42.61,-122.8,41357608],
		["Ashaway","RI",41.42,-71.78,41357964],
		["Sunapee","NH",43.38,-72.08,41358320],
		["Jeffersonville","VT",44.64,-72.82,41358676],
		["Alexander","NY",42.9,-78.26,41359032],
		["Savona","NY",42.28,-77.22,41359388],
		["Shelocta","PA",40.65,-79.3,41359744],
		["Fredonia","PA",41.32,-80.25,41360100],
		["Port Royal","SC",32.38,-80.69,41360456],
		["Jeffersonville","GA",32.68,-83.34,41360812],
		["Brantley","AL",31.58,-86.25,41361168],
		["Augusta","KY",38.77,-84,41361524],
		["Montezuma","IA",41.58,-92.52,41361880],
		["Lacon","IL",41.02,-89.4,41362236],
		["Van Buren","MO",37,-91.01,41362592],
		["Wood River","NE",40.82,-98.59,41362948],
		["Gibson","LA",29.56,-91.03,41363304],
		["Quitman","LA",32.34,-92.72,41363660],
		["Beasley","TX",29.49,-95.91,41364016],
		["Hansen","ID",42.45,-114.26,41364372],
		["Boron","CA",34.96,-117.67,41364728],
		["Byron","NY",43.07,-78.06,41365083],
		["Abbeville","MS",34.5,-89.5,41365438],
		["Potts Camp","MS",34.64,-89.3,41365793],
		["La Rue","OH",40.57,-83.38,41366148],
		["Roachdale","IN",39.84,-86.8,41366503],
		["Bridgeport","MI",43.37,-83.88,41366858],
		["Climax","MI",42.23,-85.33,41367213],
		["Mc Laughlin","SD",45.81,-100.81,41367568],
		["Watford City","ND",47.8,-103.27,41367923],
		["Sherrard","IL",41.31,-90.5,41368278],
		["Sunrise Beach","MO",38.17,-92.77,41368633],
		["Bearden","AR",33.72,-92.61,41368988],
		["Elmore City","OK",34.62,-97.39,41369343],
		["Jefferson","ME",44.2,-69.45,41369697],
		["Madawaska","ME",47.34,-68.33,41370051],
		["Ripley","NY",42.26,-79.71,41370405],
		["Newburg","MD",38.34,-76.92,41370759],
		["Harrisville","WV",39.21,-81.04,41371113],
		["Peachland","NC",34.99,-80.26,41371467],
		["Teachey","NC",34.76,-78,41371821],
		["Gresham","SC",33.93,-79.41,41372175],
		["Evensville","TN",35.56,-84.95,41372529],
		["Salt Lick","KY",38.11,-83.61,41372883],
		["Atlanta","IN",40.21,-86.02,41373237],
		["Riverdale","MI",43.38,-84.83,41373591],
		["Cement City","MI",42.06,-84.32,41373945],
		["Blair","WI",44.29,-91.22,41374299],
		["Saint George","KS",39.19,-96.42,41374653],
		["Sibley","LA",32.54,-93.29,41375007],
		["Larue","TX",32.14,-95.64,41375361],
		["La Pryor","TX",28.94,-99.84,41375715],
		["Panguitch","UT",37.86,-112.34,41376069],
		["Cibecue","AZ",34.15,-110.51,41376423],
		["Walnut Grove","CA",38.23,-121.58,41376777],
		["Bowdoinham","ME",44.01,-69.89,41377130],
		["Ellendale","DE",38.8,-75.42,41377483],
		["Cope","SC",33.37,-81,41377836],
		["Salem","SC",34.89,-82.97,41378189],
		["Woodbury","GA",32.98,-84.58,41378542],
		["Bethesda","OH",40.01,-81.07,41378895],
		["Jeddo","MI",43.15,-82.58,41379248],
		["Buckley","MI",44.5,-85.67,41379601],
		["Creal Springs","IL",37.61,-88.83,41379954],
		["Hamlin","TX",32.88,-100.12,41380307],
		["Limon","CO",39.26,-103.68,41380660],
		["Springerville","AZ",34.04,-109.2,41381013],
		["Ramah","NM",35.13,-108.49,41381366],
		["Covelo","CA",39.82,-123.16,41381719],
		["Union","OR",45.15,-117.77,41382072],
		["Eielson Afb","AK",64.67,-147.04,41382425],
		["Windsor","ME",44.31,-69.58,41382777],
		["Milford","ME",44.94,-68.64,41383129],
		["Cairo","NY",42.3,-74,41383481],
		["Millerton","NY",41.95,-73.51,41383833],
		["Boydton","VA",36.66,-78.38,41384185],
		["Weldon","NC",36.42,-77.6,41384537],
		["Delco","NC",34.33,-78.18,41384889],
		["Crawford","GA",33.88,-83.15,41385241],
		["Burnsville","MS",34.84,-88.31,41385593],
		["Union Mills","IN",41.46,-86.81,41385945],
		["Saint Ansgar","IA",43.37,-92.91,41386297],
		["Sac City","IA",42.42,-94.99,41386649],
		["Capron","IL",42.39,-88.74,41387001],
		["Old Monroe","MO",38.93,-90.74,41387353],
		["Garden Plain","KS",37.66,-97.68,41387705],
		["Labadieville","LA",29.82,-90.95,41388057],
		["Montgomery","LA",31.66,-92.88,41388409],
		["Ferndale","CA",40.45,-124.26,41388761],
		["Attleboro Falls","MA",41.97,-71.3,41389112],
		["Constantia","NY",43.25,-76,41389463],
		["Marietta","NY",42.9,-76.32,41389814],
		["Brasher Falls","NY",44.8,-74.79,41390165],
		["Elba","NY",43.07,-78.18,41390516],
		["Saxton","PA",40.21,-78.24,41390867],
		["Saint Clair","PA",40.72,-76.19,41391218],
		["Ulster","PA",41.84,-76.5,41391569],
		["Bethel","NC",35.8,-77.37,41391920],
		["Salley","SC",33.56,-81.3,41392271],
		["Miami","FL",25.77,-80.2,41392622],
		["Birchwood","TN",35.36,-84.99,41392973],
		["Polk","OH",40.94,-82.21,41393324],
		["Elberfeld","IN",38.16,-87.44,41393675],
		["Crystal","MI",43.26,-84.91,41394026],
		["Deerwood","MN",46.47,-93.89,41394377],
		["Norris City","IL",37.97,-88.32,41394728],
		["Senath","MO",36.13,-90.16,41395079],
		["Greenfield","MO",37.41,-93.84,41395430],
		["Stanton","NE",41.94,-97.22,41395781],
		["Pittsburgh","PA",40.43,-79.97,41396131],
		["Richfield","PA",40.69,-77.12,41396481],
		["Pittsville","MD",38.39,-75.41,41396831],
		["Ridgely","TN",36.26,-89.48,41397181],
		["Plantersville","MS",34.21,-88.66,41397531],
		["Jackson","MS",32.32,-90.2,41397881],
		["Burr Oak","MI",41.84,-85.32,41398231],
		["Crow Agency","MT",45.62,-107.32,41398581],
		["Catlin","IL",40.06,-87.7,41398931],
		["Dieterich","IL",39.05,-88.38,41399281],
		["Tulsa","OK",36.12,-95.91,41399631],
		["Pittsford","VT",43.7,-73.03,41399980],
		["Laceyville","PA",41.64,-76.16,41400329],
		["Savage","MD",39.13,-76.81,41400678],
		["Maple Hill","NC",34.66,-77.69,41401027],
		["Saluda","NC",35.23,-82.34,41401376],
		["Bowman","GA",34.2,-83.02,41401725],
		["Cornersville","TN",35.35,-86.84,41402074],
		["Prospect","TN",35.05,-87,41402423],
		["Melbourne","KY",39.03,-84.37,41402772],
		["Atlanta","MI",45,-84.15,41403121],
		["Brook Park","MN",45.94,-93.07,41403470],
		["Centerview","MO",38.74,-93.84,41403819],
		["Jamesport","MO",39.97,-93.8,41404168],
		["Oskaloosa","KS",39.21,-95.31,41404517],
		["Delcambre","LA",29.95,-91.98,41404866],
		["Stamps","AR",33.35,-93.49,41405215],
		["West Townsend","MA",42.66,-71.74,41405563],
		["West Lebanon","NH",43.62,-72.29,41405911],
		["Shapleigh","ME",43.54,-70.84,41406259],
		["Cedar Knolls","NJ",40.82,-74.45,41406607],
		["Hillsdale","NY",42.2,-73.54,41406955],
		["Tivoli","NY",42.05,-73.91,41407303],
		["Mc Graw","NY",42.59,-76.09,41407651],
		["Savannah","NY",43.09,-76.75,41407999],
		["Belmont","NY",42.22,-78.03,41408347],
		["Osceola Mills","PA",40.85,-78.26,41408695],
		["Beach Lake","PA",41.6,-75.15,41409043],
		["Jeffersonton","VA",38.63,-77.91,41409391],
		["Hurley","VA",37.42,-82.02,41409739],
		["Gassaway","WV",38.67,-80.77,41410087],
		["Morrice","MI",42.83,-84.17,41410435],
		["Suring","WI",45,-88.37,41410783],
		["Lakefield","MN",43.67,-95.17,41411131],
		["Iuka","IL",38.61,-88.78,41411479],
		["Sweet Springs","MO",38.96,-93.41,41411827],
		["Ord","NE",41.6,-98.92,41412175],
		["Simsboro","LA",32.53,-92.78,41412523],
		["Lepanto","AR",35.6,-90.33,41412871],
		["Oklahoma City","OK",35.46,-97.51,41413219],
		["Sabinal","TX",29.32,-99.46,41413567],
		["Jemez Pueblo","NM",35.61,-106.72,41413915],
		["Bayard","NM",32.75,-108.13,41414263],
		["Penryn","CA",38.85,-121.18,41414611],
		["Mossyrock","WA",46.48,-122.44,41414959],
		["Holland","MA",42.05,-72.15,41415306],
		["Orleans","VT",44.81,-72.2,41415653],
		["Rio Grande","NJ",39.02,-74.87,41416000],
		["South Fallsburg","NY",41.71,-74.63,41416347],
		["Barton","NY",42.04,-76.44,41416694],
		["Sidman","PA",40.32,-78.76,41417041],
		["Tionesta","PA",41.49,-79.44,41417388],
		["Bainbridge","PA",40.09,-76.67,41417735],
		["Bowling Green","VA",38.04,-77.35,41418082],
		["Fort Ashby","WV",39.49,-78.76,41418429],
		["Wade","NC",35.16,-78.73,41418776],
		["Purlear","NC",36.21,-81.38,41419123],
		["Pavo","GA",30.96,-83.73,41419470],
		["Wellborn","FL",30.23,-82.81,41419817],
		["Dillonvale","OH",40.19,-80.77,41420164],
		["Russia","OH",40.23,-84.41,41420511],
		["Independence","WI",44.36,-91.41,41420858],
		["Mayer","MN",44.88,-93.88,41421205],
		["Ellis","KS",38.93,-99.55,41421552],
		["Edgard","LA",30.02,-90.55,41421899],
		["Cameron","OK",35.13,-94.53,41422246],
		["Groveton","NH",44.59,-71.51,41422592],
		["Bellwood","PA",40.6,-78.33,41422938],
		["Hamlin","WV",38.27,-82.1,41423284],
		["Waverly","WV",39.3,-81.33,41423630],
		["Guston","KY",37.88,-86.21,41423976],
		["Andrews","IN",40.85,-85.6,41424322],
		["Westphalia","MI",42.92,-84.79,41424668],
		["Larsen","WI",44.18,-88.61,41425014],
		["Lake Park","MN",46.88,-96.09,41425360],
		["Ellinwood","KS",38.35,-98.58,41425706],
		["Meade","KS",37.28,-100.33,41426052],
		["Forest Hill","LA",31.05,-92.52,41426398],
		["Arkadelphia","AR",34.12,-93.07,41426744],
		["Jewett","TX",31.36,-96.14,41427090],
		["Kremmling","CO",40.05,-106.37,41427436],
		["Bagdad","AZ",34.5,-113.07,41427782],
		["Columbus","NM",31.82,-107.64,41428128],
		["Riddle","OR",42.91,-123.43,41428474],
		["Swanton","MD",39.47,-79.21,41428819],
		["Ararat","VA",36.6,-80.51,41429164],
		["Olin","NC",35.95,-80.85,41429509],
		["Bokeelia","FL",26.7,-82.05,41429854],
		["Pioneer","OH",41.67,-84.55,41430199],
		["Greenfield","IA",41.3,-94.45,41430544],
		["Dresser","WI",45.36,-92.63,41430889],
		["Thompson Falls","MT",47.56,-115.44,41431234],
		["Shamrock","TX",35.21,-100.24,41431579],
		["Voluntown","CT",41.59,-71.85,41431923],
		["Newport","NY",43.18,-75.01,41432267],
		["Welch","WV",37.43,-81.58,41432611],
		["Campbellsburg","KY",38.52,-85.2,41432955],
		["Ixonia","WI",43.16,-88.58,41433299],
		["Livonia","LA",30.56,-91.55,41433643],
		["Pangburn","AR",35.42,-91.83,41433987],
		["Tyler","TX",32.32,-95.3,41434331],
		["Seadrift","TX",28.41,-96.71,41434675],
		["Falls City","TX",28.98,-98.02,41435019],
		["Denver","CO",39.76,-104.87,41435363],
		["North Fork","CA",37.23,-119.49,41435707],
		["Lee Center","NY",43.3,-75.51,41436050],
		["Normalville","PA",40.02,-79.4,41436393],
		["Philadelphia","PA",39.95,-75.16,41436736],
		["Glenwood","GA",32.18,-82.67,41437079],
		["Rochelle","GA",31.94,-83.45,41437422],
		["Lamar","MS",34.91,-89.31,41437765],
		["Litchfield","MI",42.04,-84.75,41438108],
		["Atkins","IA",41.99,-91.85,41438451],
		["Oakes","ND",46.13,-98.08,41438794],
		["Medinah","IL",41.97,-88.05,41439137],
		["Onarga","IL",40.71,-88.01,41439480],
		["New Orleans","LA",30.06,-89.93,41439823],
		["Lapwai","ID",46.32,-116.78,41440166],
		["Fredonia","AZ",36.74,-112.11,41440509],
		["Mesilla Park","NM",32.27,-106.61,41440852],
		["Seeley","CA",32.79,-115.69,41441195],
		["Paia","HI",20.91,-156.37,41441538],
		["Sunderland","MA",42.46,-72.58,41441880],
		["Minot","ME",44.08,-70.32,41442222],
		["Woolwich","ME",43.96,-69.78,41442564],
		["Hyde Park","VT",44.59,-72.61,41442906],
		["Williamstown","NY",43.42,-75.88,41443248],
		["Brackenridge","PA",40.6,-79.74,41443590],
		["New Enterprise","PA",40.16,-78.37,41443932],
		["Surry","VA",37.13,-76.83,41444274],
		["Davisville","WV",39.21,-81.46,41444616],
		["Due West","SC",34.33,-82.38,41444958],
		["Waco","GA",33.7,-85.18,41445300],
		["Gilbertsville","KY",36.96,-88.27,41445642],
		["Atlantic Mine","MI",47.09,-88.71,41445984],
		["Casco","WI",44.55,-87.61,41446326],
		["Custer","WI",44.5,-89.41,41446668],
		["Sturgeon","MO",39.23,-92.28,41447010],
		["Goodwell","OK",36.59,-101.63,41447352],
		["East Hampstead","NH",42.88,-71.13,41447693],
		["Paisley","FL",28.98,-81.54,41448034],
		["Fort Deposit","AL",31.98,-86.57,41448375],
		["Clay","KY",37.47,-87.82,41448716],
		["Strunk","KY",36.62,-84.43,41449057],
		["Buckeye Lake","OH",39.93,-82.47,41449398],
		["Addison","MI",41.98,-84.34,41449739],
		["Corning","IA",40.99,-94.73,41450080],
		["Oakfield","WI",43.68,-88.54,41450421],
		["Monticello","WI",42.74,-89.58,41450762],
		["Whitelaw","WI",44.14,-87.82,41451103],
		["Warrens","WI",44.13,-90.5,41451444],
		["Gardner","IL",41.18,-88.31,41451785],
		["Ola","AR",35.03,-93.22,41452126],
		["Kearny","AZ",33.1,-110.97,41452467],
		["Unalaska","AK",53.9,-166.4,41452808],
		["Farmingdale","ME",44.25,-69.78,41453148],
		["Bozrah","CT",41.54,-72.17,41453488],
		["Mount Arlington","NJ",40.91,-74.64,41453828],
		["Kendall","NY",43.32,-78.03,41454168],
		["Central City","PA",40.1,-78.8,41454508],
		["Tioga","PA",41.9,-77.13,41454848],
		["Port Tobacco","MD",38.49,-77.04,41455188],
		["Vansant","VA",37.23,-82.08,41455528],
		["Fair Play","SC",34.51,-82.98,41455868],
		["Bayou La Batre","AL",30.4,-88.25,41456208],
		["Lynnville","TN",35.37,-87,41456548],
		["Ansonia","OH",40.21,-84.63,41456888],
		["Botkins","OH",40.46,-84.18,41457228],
		["Redkey","IN",40.34,-85.15,41457568],
		["Clayton","WI",45.32,-92.17,41457908],
		["Irving","TX",32.85,-96.96,41458248],
		["Dallas","TX",32.79,-96.76,41458588],
		["Hebron","ME",44.19,-70.4,41458927],
		["Cadyville","NY",44.66,-73.68,41459266],
		["Crescent","PA",40.55,-80.23,41459605],
		["Fawn Grove","PA",39.72,-76.45,41459944],
		["Glenville","PA",39.76,-76.85,41460283],
		["Oceana","WV",37.69,-81.63,41460622],
		["Castalia","NC",36.08,-78.05,41460961],
		["Salters","SC",33.59,-79.85,41461300],
		["Armuchee","GA",34.47,-85.21,41461639],
		["Fort Myers Beach","FL",26.48,-81.95,41461978],
		["Astatula","FL",28.71,-81.73,41462317],
		["Hagerhill","KY",37.78,-82.79,41462656],
		["Battle Ground","IN",40.51,-86.83,41462995],
		["New Richland","MN",43.89,-93.49,41463334],
		["Chebanse","IL",41,-87.91,41463673],
		["Moro","IL",38.95,-89.99,41464012],
		["Port Sulphur","LA",29.49,-89.7,41464351],
		["New Llano","LA",31.11,-93.27,41464690],
		["Yantis","TX",32.93,-95.57,41465029],
		["Mount Enterprise","TX",31.91,-94.68,41465368],
		["Maypearl","TX",32.31,-97,41465707],
		["Granger","TX",30.71,-97.44,41466046],
		["John Day","OR",44.42,-118.94,41466385],
		["South Royalton","VT",43.82,-72.52,41466723],
		["Montauk","NY",41.04,-71.94,41467061],
		["Bluemont","VA",39.11,-77.83,41467399],
		["Harleyville","SC",33.21,-80.44,41467737],
		["Wallace","SC",34.72,-79.84,41468075],
		["Silas","AL",31.76,-88.32,41468413],
		["Lockbourne","OH",39.8,-82.97,41468751],
		["Kentland","IN",40.77,-87.44,41469089],
		["Valders","WI",44.06,-87.88,41469427],
		["Arlee","MT",47.16,-114,41469765],
		["Oakley","KS",39.12,-100.85,41470103],
		["Garyville","LA",30.07,-90.62,41470441],
		["Hector","AR",35.46,-92.97,41470779],
		["Oklahoma City","OK",35.46,-97.51,41471117],
		["Hollis","OK",34.68,-99.91,41471455],
		["Wetumka","OK",35.24,-96.23,41471793],
		["Bruceville","TX",31.33,-97.21,41472131],
		["Leupp","AZ",35.41,-111.02,41472469],
		["Mill City","OR",44.74,-122.48,41472807],
		["Mapleton","ME",46.68,-68.16,41473144],
		["Gibbsboro","NJ",39.83,-74.96,41473481],
		["Slate Hill","NY",41.37,-74.49,41473818],
		["Earlville","NY",42.74,-75.54,41474155],
		["Bolton","NC",34.31,-78.4,41474492],
		["Repton","AL",31.4,-87.23,41474829],
		["Rosedale","MS",33.85,-91.03,41475166],
		["Staffordsville","KY",37.83,-82.84,41475503],
		["Lucas","OH",40.7,-82.42,41475840],
		["New Era","MI",43.55,-86.34,41476177],
		["Bayfield","WI",46.81,-90.82,41476514],
		["Glasford","IL",40.57,-89.81,41476851],
		["Chapman","KS",38.97,-97.02,41477188],
		["Belleville","AR",35.09,-93.44,41477525],
		["Maysville","OK",34.81,-97.41,41477862],
		["Waurika","OK",34.18,-98.02,41478199],
		["Burns Flat","OK",35.35,-99.17,41478536],
		["Jennings","OK",36.18,-96.56,41478873],
		["Haworth","OK",33.84,-94.65,41479210],
		["Cottonwood","ID",45.94,-116.52,41479547],
		["Pauma Valley","CA",33.3,-116.92,41479884],
		["Canaan","CT",42.03,-73.33,41480220],
		["Netcong","NJ",40.89,-74.7,41480556],
		["Stirling","NJ",40.68,-74.49,41480892],
		["Mount Royal","NJ",39.81,-75.2,41481228],
		["Richmondville","NY",42.63,-74.56,41481564],
		["Valley Falls","NY",42.9,-73.56,41481900],
		["Chazy","NY",44.88,-73.43,41482236],
		["Warners","NY",43.08,-76.32,41482572],
		["Chenango Forks","NY",42.23,-75.84,41482908],
		["Bird In Hand","PA",40.05,-76.18,41483244],
		["Daleville","VA",37.41,-79.92,41483580],
		["Belews Creek","NC",36.25,-80.06,41483916],
		["Sharon","WI",42.5,-88.73,41484252],
		["Cambria","WI",43.54,-89.11,41484588],
		["Carbondale","IL",37.72,-89.21,41484924],
		["Plainville","KS",39.23,-99.3,41485260],
		["Harper","TX",30.3,-99.25,41485596],
		["Pleasant Hill","OR",43.96,-122.92,41485932],
		["Orangeville","PA",41.07,-76.41,41486267],
		["Summit Hill","PA",40.82,-75.84,41486602],
		["Eastpoint","FL",29.75,-84.86,41486937],
		["Crawford","MS",33.3,-88.62,41487272],
		["Waddy","KY",38.13,-85.06,41487607],
		["Frankfort","MI",44.63,-86.23,41487942],
		["Bessemer","MI",46.47,-90.04,41488277],
		["Brooklyn","IA",41.73,-92.44,41488612],
		["Wausaukee","WI",45.37,-87.95,41488947],
		["Mayville","ND",47.5,-97.32,41489282],
		["Pembroke Township","IL",41.11,-87.56,41489617],
		["Medicine Lodge","KS",37.28,-98.58,41489952],
		["Beaumont","TX",30.08,-94.14,41490287],
		["Wheeler","TX",35.44,-100.27,41490622],
		["Akron","CO",40.16,-103.21,41490957],
		["Polacca","AZ",35.84,-110.37,41491292],
		["Loving","NM",32.28,-104.09,41491627],
		["Fort Dix","NJ",40.22,-74.76,41491961],
		["Sinclairville","NY",42.26,-79.25,41492295],
		["Farmington","PA",39.78,-79.61,41492629],
		["Transfer","PA",41.32,-80.42,41492963],
		["East New Market","MD",38.59,-75.92,41493297],
		["Prichard","WV",38.21,-82.56,41493631],
		["Saint Petersburg","FL",27.75,-82.64,41493965],
		["Pinson","TN",35.47,-88.73,41494299],
		["Rockbridge","OH",39.53,-82.57,41494633],
		["Scio","OH",40.39,-81.08,41494967],
		["Deerfield","OH",41.03,-81.05,41495301],
		["Attica","OH",41.06,-82.88,41495635],
		["Edgewood","IA",42.64,-91.4,41495969],
		["Oxford","IA",41.72,-91.78,41496303],
		["Almond","WI",44.26,-89.4,41496637],
		["Redgranite","WI",44.05,-89.1,41496971],
		["Webster","SD",45.33,-97.52,41497305],
		["Walnut","IL",41.55,-89.59,41497639],
		["Woodlawn","IL",38.33,-89.03,41497973],
		["Shelbina","MO",39.69,-92.03,41498307],
		["Imperial","NE",40.51,-101.63,41498641],
		["Damon","TX",29.27,-95.72,41498975],
		["Hayden","CO",40.48,-107.26,41499309],
		["Cordova","AK",60.79,-145.61,41499643],
		["Alstead","NH",43.15,-72.36,41499976],
		["Panama","NY",42.07,-79.48,41500309],
		["Prattsburgh","NY",42.52,-77.29,41500642],
		["Huddleston","VA",37.16,-79.48,41500975],
		["Colliers","WV",40.34,-80.55,41501308],
		["Ararat","NC",36.4,-80.56,41501641],
		["Ronda","NC",36.22,-80.94,41501974],
		["Sunfield","MI",42.76,-84.99,41502307],
		["Clarklake","MI",42.12,-84.36,41502640],
		["Montgomery","MI",41.77,-84.8,41502973],
		["Mc Camey","TX",31.13,-102.21,41503306],
		["Waterflow","NM",36.81,-108.41,41503639],
		["Julian","CA",32.99,-116.41,41503972],
		["Greene","RI",41.69,-71.74,41504304],
		["Milo","ME",45.25,-68.98,41504636],
		["Pownal","VT",42.76,-73.23,41504968],
		["Carrolltown","PA",40.6,-78.7,41505300],
		["Harborcreek","PA",42.17,-79.93,41505632],
		["Arlington","VA",38.87,-77.1,41505964],
		["Mc Kenney","VA",36.99,-77.74,41506296],
		["Damascus","VA",36.63,-81.78,41506628],
		["Swords Creek","VA",37.08,-81.91,41506960],
		["Patrick","SC",34.57,-80.04,41507292],
		["Calhoun Falls","SC",34.09,-82.59,41507624],
		["Webb","AL",31.25,-85.28,41507956],
		["Gilbertown","AL",31.87,-88.31,41508288],
		["Stanton","TN",35.46,-89.4,41508620],
		["Obion","TN",36.26,-89.19,41508952],
		["Cable","OH",40.17,-83.64,41509284],
		["Spring Valley","OH",39.61,-84,41509616],
		["Vernon","VT",42.78,-72.52,41509947],
		["Essex","CT",41.35,-72.39,41510278],
		["Stone Ridge","NY",41.86,-74.17,41510609],
		["Pine Plains","NY",41.97,-73.65,41510940],
		["Winthrop","NY",44.74,-74.8,41511271],
		["Rushville","NY",42.76,-77.22,41511602],
		["Montgomery","WV",38.17,-81.31,41511933],
		["Wayne","OH",41.3,-83.47,41512264],
		["De Soto","IL",37.81,-89.22,41512595],
		["Overbrook","KS",38.78,-95.55,41512926],
		["Wilber","NE",40.48,-96.96,41513257],
		["Wisner","LA",31.98,-91.65,41513588],
		["Ash Flat","AR",36.22,-91.6,41513919],
		["Churchton","MD",38.8,-76.58,41514249],
		["Hot Springs","VA",38,-79.83,41514579],
		["Buffalo","SC",34.72,-81.68,41514909],
		["Mc Alpin","FL",30.13,-82.96,41515239],
		["Cusseta","AL",32.75,-85.23,41515569],
		["Meadville","MS",31.47,-90.89,41515899],
		["Glenford","OH",39.88,-82.31,41516229],
		["Richland","IN",37.91,-87.2,41516559],
		["Fulda","MN",43.87,-95.6,41516889],
		["Brownstown","IL",38.99,-88.95,41517219],
		["Syracuse","NE",40.66,-96.18,41517549],
		["Gordon","NE",42.8,-102.2,41517879],
		["Stevinson","CA",37.3,-120.87,41518209],
		["Puerto Real","PR",18.33,-65.63,41518538],
		["New Paris","PA",40.1,-78.64,41518867],
		["Avonmore","PA",40.52,-79.47,41519196],
		["Julian","PA",40.91,-77.93,41519525],
		["Orrstown","PA",40.05,-77.6,41519854],
		["Mount Ulla","NC",35.65,-80.72,41520183],
		["Currie","NC",34.46,-78.1,41520512],
		["Buhl","AL",33.25,-87.73,41520841],
		["Pleasant Shade","TN",36.35,-85.9,41521170],
		["Ashland","MS",34.83,-89.17,41521499],
		["Hanoverton","OH",40.75,-80.93,41521828],
		["Wolverine","MI",45.27,-84.6,41522157],
		["Des Moines","IA",41.57,-93.61,41522486],
		["Freeport","MN",45.66,-94.68,41522815],
		["Augusta","AR",35.28,-91.36,41523144],
		["Hindsville","AR",36.14,-93.86,41523473],
		["Oktaha","OK",35.57,-95.47,41523802],
		["Allyn","WA",47.37,-122.84,41524131],
		["Pine Beach","NJ",39.93,-74.17,41524459],
		["South Dayton","NY",42.36,-79.05,41524787],
		["Worthington","PA",40.83,-79.63,41525115],
		["Arlington","VA",38.87,-77.1,41525443],
		["Lumber City","GA",31.93,-82.68,41525771],
		["Steele","AL",33.93,-86.19,41526099],
		["Orrville","AL",32.3,-87.24,41526427],
		["Ardmore","TN",34.99,-86.84,41526755],
		["Viper","KY",37.18,-83.14,41527083],
		["New Madison","OH",39.96,-84.7,41527411],
		["New Matamoras","OH",39.53,-81.12,41527739],
		["Lake Mills","IA",43.41,-93.53,41528067],
		["Little Suamico","WI",44.71,-88.01,41528395],
		["Whiteman Air Force Base","MO",38.73,-93.55,41528723],
		["Macy","NE",42.11,-96.36,41529051],
		["Ventress","LA",30.67,-91.4,41529379],
		["Chilton","TX",31.29,-97.11,41529707],
		["Huntington","MA",42.23,-72.88,41530034],
		["Bethlehem","NH",44.28,-71.68,41530361],
		["Stoneboro","PA",41.33,-80.1,41530688],
		["Norfolk","VA",36.84,-76.28,41531015],
		["Howey In The Hills","FL",28.71,-81.77,41531342],
		["Camp Hill","AL",32.8,-85.65,41531669],
		["Big Rock","TN",36.59,-87.69,41531996],
		["Hindman","KY",37.33,-82.98,41532323],
		["Mentone","IN",41.17,-86.03,41532650],
		["Bunker Hill","IN",40.66,-86.1,41532977],
		["Alger","MI",44.11,-84.11,41533304],
		["Athens","MI",42.08,-85.23,41533631],
		["Prairie City","IA",41.59,-93.23,41533958],
		["Traer","IA",42.19,-92.46,41534285],
		["Topeka","KS",39.03,-95.69,41534612],
		["Syracuse","KS",37.98,-101.75,41534939],
		["Bridgeport","NE",41.66,-103.09,41535266],
		["Saint James","LA",29.98,-90.87,41535593],
		["Bay","AR",35.74,-90.55,41535920],
		["Subiaco","AR",35.29,-93.63,41536247],
		["Axtell","TX",31.65,-96.97,41536574],
		["Nederland","CO",39.96,-105.5,41536901],
		["Nichols","NY",42,-77.26,41537227],
		["Kennedy","NY",42.14,-79.07,41537553],
		["Tarawa Terrace","NC",34.73,-77.37,41537879],
		["Emerald Isle","NC",34.66,-77.02,41538205],
		["Dexter","GA",32.43,-83.06,41538531],
		["Lumpkin","GA",32.04,-84.79,41538857],
		["Almo","KY",36.71,-88.31,41539183],
		["East Leroy","MI",42.19,-85.24,41539509],
		["Pleasant Lake","MI",42.39,-84.34,41539835],
		["Akron","IA",42.82,-96.55,41540161],
		["Long Grove","IA",41.69,-90.58,41540487],
		["Centuria","WI",45.44,-92.55,41540813],
		["Anthony","KS",37.15,-98.02,41541139],
		["Little River Academy","TX",30.97,-97.37,41541465],
		["Maricopa","CA",34.84,-119.27,41541791],
		["Rollinsford","NH",43.23,-70.82,41542116],
		["Constable","NY",44.93,-74.28,41542441],
		["Summerhill","PA",40.37,-78.76,41542766],
		["Columbia Cross Roads","PA",41.83,-76.8,41543091],
		["Bethany","WV",40.2,-80.56,41543416],
		["Maidsville","WV",39.7,-79.99,41543741],
		["Gadsden","SC",33.84,-80.74,41544066],
		["Baileyton","AL",34.26,-86.61,41544391],
		["Sale Creek","TN",35.38,-85.1,41544716],
		["Mount Perry","OH",39.87,-82.18,41545041],
		["Huntsburg","OH",41.53,-81.05,41545366],
		["Thompson","OH",41.67,-81.06,41545691],
		["Markleville","IN",39.97,-85.61,41546016],
		["Arlington","NE",41.45,-96.35,41546341],
		["Mount Ida","AR",34.55,-93.63,41546666],
		["Hazelton","ID",42.58,-114.07,41546991],
		["Clearlake Oaks","CA",39.05,-122.55,41547316],
		["North Falmouth","MA",41.64,-70.63,41547640],
		["Walker","WV",39.18,-81.36,41547964],
		["White Oak","NC",34.74,-78.7,41548288],
		["Lake View","SC",34.34,-79.16,41548612],
		["Silver Lake","IN",41.07,-85.89,41548936],
		["Oxford","WI",43.78,-89.56,41549260],
		["Auburndale","WI",44.62,-90.01,41549584],
		["Wathena","KS",39.76,-94.94,41549908],
		["Geary","OK",35.62,-98.31,41550232],
		["Hubbard","TX",31.84,-96.8,41550556],
		["Hagerman","ID",42.81,-114.91,41550880],
		["East Waterboro","ME",43.6,-70.69,41551203],
		["Castine","ME",44.38,-68.8,41551526],
		["Tomkins Cove","NY",41.27,-73.98,41551849],
		["Cleveland","NY",43.23,-75.88,41552172],
		["Andover","NY",42.15,-77.79,41552495],
		["Hollsopple","PA",40.24,-78.96,41552818],
		["Ewing","VA",36.63,-83.43,41553141],
		["Richburg","SC",34.71,-81.02,41553464],
		["Dewy Rose","GA",34.16,-82.95,41553787],
		["Republic","OH",41.12,-83.01,41554110],
		["Nashua","IA",42.95,-92.53,41554433],
		["Warren","MN",48.19,-96.76,41554756],
		["Fort Thompson","SD",44.04,-99.41,41555079],
		["Groton","SD",45.45,-98.1,41555402],
		["Stonewall","OK",34.65,-96.52,41555725],
		["Trenton","TX",33.42,-96.33,41556048],
		["Baird","TX",32.39,-99.39,41556371],
		["Nice","CA",39.12,-122.84,41556694],
		["Oakville","WA",46.83,-123.28,41557017],
		["Shrub Oak","NY",41.32,-73.82,41557339],
		["Mc Clellandtown","PA",39.88,-79.86,41557661],
		["Paden City","WV",39.6,-80.93,41557983],
		["Castleberry","AL",31.29,-87.02,41558305],
		["Lobelville","TN",35.75,-87.79,41558627],
		["Pleasantville","OH",39.8,-82.52,41558949],
		["Holgate","OH",41.24,-84.12,41559271],
		["Windsor","WI",43.21,-89.34,41559593],
		["Melrose","WI",44.13,-90.99,41559915],
		["Elgin","MN",44.13,-92.25,41560237],
		["Murfreesboro","AR",34.06,-93.69,41560559],
		["Bokchito","OK",34.01,-96.14,41560881],
		["Boling","TX",29.23,-95.98,41561203],
		["Westport","WA",46.87,-124.11,41561525],
		["Acton","ME",43.53,-70.91,41561846],
		["Mount Solon","VA",38.36,-79.16,41562167],
		["Franklin","WV",38.64,-79.33,41562488],
		["Plainville","GA",34.4,-85.03,41562809],
		["Saint George","GA",30.5,-82.12,41563130],
		["White Springs","FL",30.33,-82.75,41563451],
		["Huntsville","AL",34.7,-86.62,41563772],
		["Chattanooga","TN",35.06,-85.25,41564093],
		["Corydon","IA",40.75,-93.31,41564414],
		["Hazleton","IA",42.61,-91.9,41564735],
		["Granville","IL",41.26,-89.22,41565056],
		["Downs","IL",40.39,-88.87,41565377],
		["Camp Point","IL",40.03,-91.06,41565698],
		["Wichita","KS",37.68,-97.34,41566019],
		["Rosebud","TX",31.07,-96.97,41566340],
		["Siletz","OR",44.77,-123.92,41566661],
		["Elgin","OR",45.61,-117.89,41566982],
		["Nahant","MA",42.42,-70.92,41567302],
		["Cedarville","NJ",39.32,-75.2,41567622],
		["Buchanan","NY",41.26,-73.94,41567942],
		["Buffalo","NY",42.88,-78.85,41568262],
		["Stony Creek","VA",36.94,-77.4,41568582],
		["Cove City","NC",35.18,-77.32,41568902],
		["Gibson","GA",33.23,-82.59,41569222],
		["Palm Beach","FL",26.69,-80.03,41569542],
		["Holcomb","MS",33.75,-89.96,41569862],
		["Oneida","KY",37.27,-83.64,41570182],
		["Rockfield","KY",36.9,-86.55,41570502],
		["Otter Lake","MI",43.21,-83.46,41570822],
		["Dysart","IA",42.17,-92.3,41571142],
		["Solon Springs","WI",46.34,-91.82,41571462],
		["Plainfield","WI",44.21,-89.49,41571782],
		["Gilbert","MN",47.49,-92.46,41572102],
		["Verndale","MN",46.39,-95.01,41572422],
		["Drexel","MO",38.48,-94.6,41572742],
		["Yutan","NE",41.24,-96.39,41573062],
		["Ainsworth","NE",42.54,-99.85,41573382],
		["Starks","LA",30.31,-93.66,41573702],
		["Converse","LA",31.78,-93.69,41574022],
		["Oklahoma City","OK",35.46,-97.51,41574342],
		["Bokoshe","OK",35.19,-94.79,41574662],
		["Gruver","TX",36.25,-101.41,41574982],
		["Plains","TX",33.19,-102.82,41575302],
		["Jal","NM",32.11,-103.18,41575622],
		["Waitsburg","WA",46.22,-118.15,41575942],
		["Port Murray","NJ",40.79,-74.91,41576261],
		["Central Valley","NY",41.32,-74.12,41576580],
		["Union Springs","NY",42.84,-76.69,41576899],
		["Wilmerding","PA",40.39,-79.81,41577218],
		["Enon Valley","PA",40.85,-80.45,41577537],
		["Gallitzin","PA",40.48,-78.55,41577856],
		["Woodland","PA",40.99,-78.38,41578175],
		["Waterford","VA",39.18,-77.61,41578494],
		["Cool Ridge","WV",37.64,-81.12,41578813],
		["Luthersville","GA",33.2,-84.74,41579132],
		["Warm Springs","GA",32.88,-84.67,41579451],
		["Boligee","AL",32.75,-88.02,41579770],
		["Jackson","MS",32.32,-90.2,41580089],
		["Richmond","OH",40.43,-80.77,41580408],
		["Homerville","OH",41.02,-82.12,41580727],
		["Macy","IN",40.95,-86.12,41581046],
		["Tekonsha","MI",42.09,-84.98,41581365],
		["Pierson","MI",43.31,-85.49,41581684],
		["Farley","IA",42.44,-91,41582003],
		["Dalton","WI",43.68,-89.19,41582322],
		["Florence","WI",45.93,-88.25,41582641],
		["Stoddard","WI",43.66,-91.21,41582960],
		["Battle Lake","MN",46.28,-95.71,41583279],
		["Saint Elmo","IL",39.02,-88.85,41583598],
		["Imboden","AR",36.2,-91.17,41583917],
		["Karnack","TX",32.62,-94.14,41584236],
		["Shelbyville","TX",31.7,-93.95,41584555],
		["Pearblossom","CA",34.48,-117.88,41584874],
		["Hayfork","CA",40.5,-123.19,41585193],
		["Moonachie","NJ",40.84,-74.05,41585511],
		["Riverdale","NJ",40.99,-74.3,41585829],
		["Truxton","NY",42.71,-76.02,41586147],
		["Alexandria","PA",40.55,-78.1,41586465],
		["Three Springs","PA",40.19,-77.98,41586783],
		["Painter","VA",37.58,-75.78,41587101],
		["Pantego","NC",35.58,-76.65,41587419],
		["Turkey","NC",34.99,-78.18,41587737],
		["Ty Ty","GA",31.47,-83.65,41588055],
		["Shorter","AL",32.4,-85.93,41588373],
		["Thorn Hill","TN",36.35,-83.41,41588691],
		["Buckatunna","MS",31.53,-88.52,41589009],
		["Francesville","IN",40.98,-86.88,41589327],
		["Sherwood","WI",44.17,-88.27,41589645],
		["Sturgeon Lake","MN",46.38,-92.82,41589963],
		["Platte","SD",43.38,-98.84,41590281],
		["Grand Forks Afb","ND",47.96,-97.38,41590599],
		["Johnson","KS",37.57,-101.75,41590917],
		["Ravenna","NE",41.02,-98.91,41591235],
		["Singer","LA",30.65,-93.41,41591553],
		["Shirley","AR",35.64,-92.31,41591871],
		["Como","TX",33.06,-95.47,41592189],
		["Gregory","TX",27.92,-97.29,41592507],
		["San Bernardino","CA",34.1,-117.29,41592825],
		["Lucerne","CA",39.12,-122.76,41593143],
		["Cosmopolis","WA",46.85,-123.62,41593461],
		["Grapeview","WA",47.32,-122.94,41593779],
		["Cherry Valley","MA",42.23,-71.87,41594096],
		["Carlton","GA",34.04,-83.03,41594413],
		["De Mossville","KY",38.74,-84.47,41594730],
		["Tripoli","IA",42.8,-92.25,41595047],
		["Arpin","WI",44.54,-90.03,41595364],
		["Fountain City","WI",44.11,-91.69,41595681],
		["Bruce","WI",45.45,-91.27,41595998],
		["Grand Marais","MN",47.75,-90.34,41596315],
		["Fort Totten","ND",47.97,-98.99,41596632],
		["Taylor Ridge","IL",41.38,-90.66,41596949],
		["South Roxana","IL",38.82,-90.05,41597266],
		["Wakefield","NE",42.26,-96.86,41597583],
		["Weleetka","OK",35.33,-96.13,41597900],
		["Simms","TX",33.35,-94.5,41598217],
		["Elmira","OR",44.09,-123.39,41598534],
		["Bonanza","OR",42.21,-121.3,41598851],
		["Youngwood","PA",40.24,-79.58,41599167],
		["Ashton","MD",39.15,-76.98,41599483],
		["Scottsburg","VA",36.75,-78.79,41599799],
		["Reedsville","WV",39.51,-79.8,41600115],
		["Wadmalaw Island","SC",32.68,-80.16,41600431],
		["Sanibel","FL",26.44,-82.1,41600747],
		["Gordonsville","TN",36.18,-85.93,41601063],
		["Woodbine","IA",41.73,-95.7,41601379],
		["Milford","IL",40.62,-87.69,41601695],
		["Lanark","IL",42.1,-89.83,41602011],
		["Saint Hedwig","TX",29.42,-98.21,41602327],
		["Red Rock","TX",29.99,-97.39,41602643],
		["Antonito","CO",37.07,-106.01,41602959],
		["Groveland","CA",37.85,-119.76,41603275],
		["Lyons","OR",44.82,-122.37,41603591],
		["Leeds","ME",44.3,-70.12,41603906],
		["Springboro","PA",41.8,-80.37,41604221],
		["Willis","VA",36.85,-80.48,41604536],
		["Pomaria","SC",34.26,-81.41,41604851],
		["Calhoun","TN",35.29,-84.74,41605166],
		["Prairie","MS",33.8,-88.66,41605481],
		["Kimbolton","OH",40.15,-81.57,41605796],
		["Homeworth","OH",40.85,-81.05,41606111],
		["Pigeon","MI",43.82,-83.27,41606426],
		["Reinbeck","IA",42.32,-92.59,41606741],
		["Ada","MN",47.29,-96.51,41607056],
		["Red Lake Falls","MN",47.88,-96.27,41607371],
		["Liberty","IL",39.88,-91.1,41607686],
		["Toledo","IL",39.27,-88.24,41608001],
		["Miller","MO",37.21,-93.84,41608316],
		["Jasper","AR",36,-93.18,41608631],
		["Johnsonville","NY",42.88,-73.49,41608945],
		["Hastings","PA",40.66,-78.7,41609259],
		["Austinville","VA",36.82,-80.86,41609573],
		["French Creek","WV",38.83,-80.25,41609887],
		["Masontown","WV",39.55,-79.8,41610201],
		["Alcolu","SC",33.76,-80.15,41610515],
		["Mc Clellanville","SC",33.08,-79.46,41610829],
		["Dry Branch","GA",32.63,-83.47,41611143],
		["Center Hill","FL",28.64,-81.99,41611457],
		["Gruetli Laager","TN",35.36,-85.74,41611771],
		["Medon","TN",35.45,-88.86,41612085],
		["Jewett","OH",40.36,-81,41612399],
		["Orleans","MI",43.06,-85.13,41612713],
		["Strawberry Point","IA",42.68,-91.53,41613027],
		["Calmar","IA",43.17,-91.86,41613341],
		["Pound","WI",45.09,-88.03,41613655],
		["Christopher","IL",37.97,-89.05,41613969],
		["Brighton","MO",37.45,-93.34,41614283],
		["Mineral Springs","AR",33.87,-93.92,41614597],
		["Tuckerman","AR",35.72,-91.2,41614911],
		["Tucson","AZ",32.17,-110.87,41615225],
		["Willow","AK",61.89,-149.73,41615539],
		["Mexico","ME",44.56,-70.54,41615852],
		["Haddam","CT",41.45,-72.5,41616165],
		["Lake Waccamaw","NC",34.31,-78.51,41616478],
		["Shiloh","GA",32.81,-84.69,41616791],
		["Hackleburg","AL",34.27,-87.83,41617104],
		["Palmyra","TN",36.41,-87.5,41617417],
		["Huntland","TN",35.05,-86.26,41617730],
		["Puryear","TN",36.44,-88.33,41618043],
		["Lake Milton","OH",41.09,-80.95,41618356],
		["Oakwood","OH",41.09,-84.37,41618669],
		["Cashion","OK",35.8,-97.67,41618982],
		["Bogata","TX",33.46,-95.21,41619295],
		["Miles","TX",31.6,-100.18,41619608],
		["Collbran","CO",39.23,-107.95,41619921],
		["Windham","CT",41.7,-72.16,41620233],
		["Plymouth","CT",41.65,-73.04,41620545],
		["Newfoundland","NJ",41.07,-74.42,41620857],
		["Nedrow","NY",42.97,-76.14,41621169],
		["Allenwood","PA",41.09,-76.9,41621481],
		["Bethune","SC",34.41,-80.34,41621793],
		["Vance","SC",33.43,-80.42,41622105],
		["Smoaks","SC",33.08,-80.81,41622417],
		["Addison","AL",34.19,-87.17,41622729],
		["Huron","TN",35.58,-88.5,41623041],
		["Fillmore","IN",39.67,-86.75,41623353],
		["Fostoria","MI",43.25,-83.37,41623665],
		["Baudette","MN",48.71,-94.58,41623977],
		["Tekamah","NE",41.77,-96.22,41624289],
		["Rio Vista","TX",32.23,-97.37,41624601],
		["Empire","CA",37.64,-120.9,41624913],
		["Enterprise","OR",45.67,-117.03,41625225],
		["Darrington","WA",48.19,-121.53,41625537],
		["Three Rivers","MA",42.18,-72.37,41625848],
		["Lanesboro","MA",42.51,-73.22,41626159],
		["Wolcott","VT",44.55,-72.47,41626470],
		["Fabius","NY",42.83,-75.98,41626781],
		["Home","PA",40.78,-79.15,41627092],
		["Stephenson","VA",39.2,-78.09,41627403],
		["Eagle Springs","NC",35.3,-79.65,41627714],
		["Bayboro","NC",35.14,-76.76,41628025],
		["Flovilla","GA",33.25,-83.89,41628336],
		["Mauk","GA",32.5,-84.37,41628647],
		["Eagleville","TN",35.74,-86.65,41628958],
		["Cumberland Gap","TN",36.59,-83.66,41629269],
		["Robbins","TN",36.32,-84.57,41629580],
		["Eastview","KY",37.58,-86.05,41629891],
		["Hamden","OH",39.16,-82.52,41630202],
		["Saint Paul","IN",39.42,-85.62,41630513],
		["Rose City","MI",44.42,-84.11,41630824],
		["Monona","IA",43.05,-91.39,41631135],
		["Buras","LA",29.34,-89.49,41631446],
		["Indiahoma","OK",34.62,-98.75,41631757],
		["Goodrich","TX",30.6,-94.94,41632068],
		["Anderson","TX",30.55,-96.03,41632379],
		["Austin","TX",30.26,-97.74,41632690],
		["Pinehurst","ID",47.46,-116.21,41633001],
		["Granville","MA",42.06,-72.86,41633311],
		["Cranesville","PA",41.9,-80.34,41633621],
		["Ringtown","PA",40.85,-76.23,41633931],
		["Canadensis","PA",41.24,-75.22,41634241],
		["Topton","PA",40.5,-75.7,41634551],
		["Nickelsville","VA",36.75,-82.41,41634861],
		["Edgemoor","SC",34.8,-81.01,41635171],
		["Kellyton","AL",32.97,-86.09,41635481],
		["Kinston","AL",31.21,-86.16,41635791],
		["Osyka","MS",31,-90.47,41636101],
		["Arlington","GA",31.41,-84.69,41636411],
		["Whitestown","IN",39.99,-86.34,41636721],
		["Ladoga","IN",39.91,-86.79,41637031],
		["Six Lakes","MI",43.42,-85.15,41637341],
		["Pittsford","MI",41.9,-84.43,41637651],
		["Ontonagon","MI",46.86,-89.31,41637961],
		["Shullsburg","WI",42.57,-90.23,41638271],
		["Madison Lake","MN",44.2,-93.81,41638581],
		["Belgrade","MN",45.45,-94.99,41638891],
		["Langdon","ND",48.76,-98.37,41639201],
		["Thompson","ND",47.77,-97.1,41639511],
		["Coulterville","IL",38.18,-89.6,41639821],
		["Eugene","MO",38.35,-92.4,41640131],
		["Doniphan","NE",40.77,-98.37,41640441],
		["Plaucheville","LA",30.96,-91.98,41640751],
		["Glencoe","OK",36.22,-96.92,41641061],
		["Pineland","TX",31.24,-93.97,41641371],
		["Santa Margarita","CA",35.31,-120.27,41641681],
		["Chualar","CA",36.57,-121.44,41641991],
		["Chester","CA",40.27,-121.24,41642301],
		["Newfields","NH",43.03,-70.93,41642610],
		["Hartland","VT",43.55,-72.4,41642919],
		["Bradley Beach","NJ",40.2,-74.01,41643228],
		["New York Mills","NY",43.1,-75.29,41643537],
		["Castorland","NY",43.88,-75.51,41643846],
		["New Salem","PA",39.9,-76.79,41644155],
		["Tyner","KY",37.34,-83.9,41644464],
		["Park City","KY",37.09,-86.04,41644773],
		["Adairville","KY",36.66,-86.85,41645082],
		["Big Prairie","OH",40.6,-82.06,41645391],
		["Rome City","IN",41.48,-85.35,41645700],
		["New Lothrop","MI",43.11,-83.96,41646009],
		["Onondaga","MI",42.44,-84.56,41646318],
		["Iron Ridge","WI",43.39,-88.53,41646627],
		["Malone","WI",43.88,-88.28,41646936],
		["Waubun","MN",47.18,-95.93,41647245],
		["Pearl City","IL",42.26,-89.82,41647554],
		["Carrier Mills","IL",37.65,-88.61,41647863],
		["Waterloo","NE",41.28,-96.28,41648172],
		["Harleton","TX",32.66,-94.52,41648481],
		["Oldtown","ID",48.13,-116.99,41648790],
		["Veguita","NM",34.51,-106.76,41649099],
		["Bernardston","MA",42.66,-72.55,41649407],
		["Pocasset","MA",41.69,-70.63,41649715],
		["West Tisbury","MA",41.38,-70.67,41650023],
		["Center Ossipee","NH",43.76,-71.12,41650331],
		["Waitsfield","VT",44.18,-72.82,41650639],
		["Lake Peekskill","NY",41.33,-73.88,41650947],
		["Rock Hill","NY",41.62,-74.59,41651255],
		["Seward","PA",40.41,-79.02,41651563],
		["Tremont","PA",40.62,-76.39,41651871],
		["The Plains","VA",38.86,-77.77,41652179],
		["Dorchester","SC",33.13,-80.39,41652487],
		["Pensacola","FL",30.44,-87.18,41652795],
		["Sandy Hook","MS",31.03,-89.8,41653103],
		["Ingalls","IN",39.96,-85.79,41653411],
		["Bluford","IL",38.32,-88.73,41653719],
		["Hoyt","KS",39.24,-95.7,41654027],
		["Erie","KS",37.56,-95.24,41654335],
		["Bayard","NE",41.75,-103.32,41654643],
		["Hazen","AR",34.78,-91.57,41654951],
		["Palmer Lake","CO",39.11,-104.9,41655259],
		["Hesperus","CO",37.28,-108.03,41655567],
		["Sugarloaf","CA",34.24,-116.82,41655875],
		["Bethel","VT",43.83,-72.63,41656182],
		["Minotola","NJ",39.51,-74.94,41656489],
		["Brushton","NY",44.83,-74.51,41656796],
		["West Falls","NY",42.7,-78.67,41657103],
		["Honeoye","NY",42.75,-77.49,41657410],
		["Prospect","VA",37.3,-78.55,41657717],
		["Rivesville","WV",39.53,-80.12,41658024],
		["Boomer","NC",36.06,-81.25,41658331],
		["Bruceton","TN",36.03,-88.24,41658638],
		["Cedar Grove","TN",35.81,-88.6,41658945],
		["Philo","OH",39.86,-81.9,41659252],
		["Bloomingdale","MI",42.38,-85.95,41659559],
		["Clayton","MI",41.86,-84.23,41659866],
		["Turtle Lake","WI",45.39,-92.14,41660173],
		["Chrisman","IL",39.8,-87.67,41660480],
		["Hartman","AR",35.43,-93.61,41660787],
		["Depew","OK",35.8,-96.5,41661094],
		["Terlton","OK",36.18,-96.49,41661401],
		["Louise","TX",29.18,-96.42,41661708],
		["Placitas","NM",35.32,-106.46,41662015],
		["Tacoma","WA",47.26,-122.44,41662322],
		["Stamford","CT",41.09,-73.55,41662628],
		["Westhampton","NY",40.83,-72.66,41662934],
		["Harrisville","NY",44.15,-75.32,41663240],
		["Berkshire","NY",42.3,-76.18,41663546],
		["Patrick Springs","VA",36.67,-80.13,41663852],
		["West Hamlin","WV",38.28,-82.19,41664158],
		["Lumberport","WV",39.37,-80.34,41664464],
		["Lilesville","NC",34.96,-79.98,41664770],
		["Benton","MS",32.8,-90.28,41665076],
		["Fort Gaines","GA",31.61,-84.99,41665382],
		["Clarksville","IA",42.78,-92.66,41665688],
		["Manning","IA",41.9,-95.06,41665994],
		["Isle","MN",46.14,-93.46,41666300],
		["Albany","MO",40.24,-94.33,41666606],
		["Washburn","MO",36.58,-93.96,41666912],
		["Anahola","HI",22.14,-159.31,41667218],
		["Wrangell","AK",55.95,-131.96,41667524],
		["Newton Upper Falls","MA",42.31,-71.22,41667829],
		["Thomaston","ME",44.08,-69.18,41668134],
		["Highgate Center","VT",44.95,-72.99,41668439],
		["Woodhull","NY",42.08,-77.4,41668744],
		["Prospect","PA",40.9,-80.04,41669049],
		["Sumerduck","VA",38.46,-77.72,41669354],
		["Maysville","WV",39.11,-79.16,41669659],
		["Tyner","NC",36.25,-76.63,41669964],
		["Charlotte","NC",35.19,-80.83,41670269],
		["Pomona Park","FL",29.49,-81.59,41670574],
		["Beechgrove","TN",35.64,-86.2,41670879],
		["Pippa Passes","KY",37.33,-82.87,41671184],
		["Burbank","OH",40.98,-81.99,41671489],
		["Aberdeen","OH",38.66,-83.76,41671794],
		["Otterbein","IN",40.49,-87.1,41672099],
		["Baltic","SD",43.75,-96.73,41672404],
		["Danvers","IL",40.52,-89.17,41672709],
		["Gridley","IL",40.74,-88.88,41673014],
		["Macks Creek","MO",37.96,-92.97,41673319],
		["Haven","KS",37.9,-97.78,41673624],
		["Castor","LA",32.25,-93.16,41673929],
		["Ninnekah","OK",34.95,-97.92,41674234],
		["Alviso","CA",37.44,-121.99,41674539],
		["Lisbon","NY",44.72,-75.26,41674843],
		["Williamstown","PA",40.58,-76.61,41675147],
		["Wellsville","PA",40.05,-76.94,41675451],
		["York","PA",39.96,-76.73,41675755],
		["Thaxton","VA",37.35,-79.63,41676059],
		["Pineville","WV",37.58,-81.53,41676363],
		["Paw Paw","WV",39.53,-78.45,41676667],
		["Tiger","GA",34.84,-83.43,41676971],
		["Hickory","MS",32.31,-89.02,41677275],
		["Georgetown","GA",31.85,-85.04,41677579],
		["Summer Shade","KY",36.88,-85.7,41677883],
		["Pleasant Lake","IN",41.58,-85.01,41678187],
		["Durant","IA",41.6,-90.9,41678491],
		["Mcgregor","MN",46.6,-93.3,41678795],
		["Minneota","MN",44.56,-95.98,41679099],
		["Darrow","LA",30.14,-90.94,41679403],
		["Cove","AR",34.43,-94.4,41679707],
		["Oklahoma City","OK",35.46,-97.51,41680011],
		["Challis","ID",44.65,-114.15,41680315],
		["Texico","NM",34.38,-103.05,41680619],
		["Orondo","WA",47.72,-120.15,41680923],
		["Canterbury","NH",43.33,-71.56,41681226],
		["Grantham","NH",43.48,-72.13,41681529],
		["Canaan","ME",44.76,-69.56,41681832],
		["Napanoch","NY",41.75,-74.37,41682135],
		["Hadley","NY",43.3,-74.01,41682438],
		["Accident","MD",39.62,-79.32,41682741],
		["Meadow Bridge","WV",37.86,-80.85,41683044],
		["Capon Bridge","WV",39.29,-78.43,41683347],
		["Otto","NC",35.06,-83.38,41683650],
		["Plains","GA",32.03,-84.39,41683953],
		["Big Pine Key","FL",24.67,-81.35,41684256],
		["San Antonio","FL",28.33,-82.27,41684559],
		["Harpersville","AL",33.34,-86.43,41684862],
		["Sunbright","TN",36.24,-84.67,41685165],
		["Junction City","KY",37.58,-84.78,41685468],
		["North Bloomfield","OH",41.45,-80.8,41685771],
		["Pandora","OH",40.94,-83.96,41686074],
		["Fenwick","MI",43.14,-85.08,41686377],
		["Elmira","MI",45.06,-84.85,41686680],
		["Maxwell","IA",41.89,-93.39,41686983],
		["Villisca","IA",40.93,-94.97,41687286],
		["Danville","IA",40.86,-91.31,41687589],
		["La Farge","WI",43.57,-90.63,41687892],
		["Shafer","MN",45.38,-92.74,41688195],
		["Catawissa","MO",38.42,-90.78,41688498],
		["Paris","MO",39.47,-92,41688801],
		["Monument Valley","UT",37.08,-110.25,41689104],
		["La Grange","CA",37.66,-120.45,41689407],
		["Glendale","OR",42.79,-123.4,41689710],
		["Sparrow Bush","NY",41.44,-74.72,41690012],
		["Woodbourne","NY",41.75,-74.59,41690314],
		["Hammondsport","NY",42.4,-77.22,41690616],
		["Richwood","WV",38.22,-80.53,41690918],
		["Lee","FL",30.41,-83.3,41691220],
		["Midway","TN",36.28,-82.42,41691522],
		["Boston","KY",37.78,-85.66,41691824],
		["Nevada","OH",40.81,-83.13,41692126],
		["South Webster","OH",38.81,-82.72,41692428],
		["Ohio City","OH",40.77,-84.61,41692730],
		["Au Gres","MI",44.04,-83.69,41693032],
		["Hartley","IA",43.17,-95.47,41693334],
		["Allenton","WI",43.47,-88.35,41693636],
		["Strum","WI",44.55,-91.38,41693938],
		["Herscher","IL",41.04,-88.1,41694240],
		["Milford","UT",38.36,-113.11,41694542],
		["Kettleman City","CA",35.99,-119.99,41694844],
		["Crockett","CA",38.04,-122.23,41695146],
		["Drain","OR",43.71,-123.32,41695448],
		["Pomeroy","WA",46.44,-117.54,41695750],
		["East Brookfield","MA",42.22,-72.04,41696051],
		["Lisbon","NH",44.21,-71.9,41696352],
		["Duanesburg","NY",42.76,-74.13,41696653],
		["Middleburg","VA",38.97,-77.73,41696954],
		["Andrews Air Force Base","MD",38.8,-76.87,41697255],
		["Boaz","KY",36.91,-88.61,41697556],
		["Tipton","MI",42.01,-84.06,41697857],
		["Ossineke","MI",44.9,-83.43,41698158],
		["Walcott","IA",41.59,-90.77,41698459],
		["Ortonville","MN",45.3,-96.44,41698760],
		["Ipswich","SD",45.44,-99.02,41699061],
		["Mount Pulaski","IL",40.01,-89.28,41699362],
		["Slater","MO",39.22,-93.06,41699663],
		["Bee Branch","AR",35.42,-92.41,41699964],
		["Mooreland","OK",36.43,-99.2,41700265],
		["Thrall","TX",30.58,-97.29,41700566],
		["Poth","TX",29.07,-98.08,41700867],
		["Jean","NV",35.75,-115.53,41701168],
		["Cold Brook","NY",43.24,-75.03,41701468],
		["Church Road","VA",37.15,-77.63,41701768],
		["Lavalette","WV",38.32,-82.41,41702068],
		["Middlebourne","WV",39.49,-80.9,41702368],
		["Clio","SC",34.57,-79.54,41702668],
		["Ambrose","GA",31.59,-83.01,41702968],
		["Pleasant Hill","OH",40.05,-84.34,41703268],
		["Kewanna","IN",41.01,-86.41,41703568],
		["Clarksville","MI",42.84,-85.24,41703868],
		["Tigerton","WI",44.74,-89.06,41704168],
		["Princeton","WI",43.85,-89.12,41704468],
		["Larimore","ND",47.9,-97.62,41704768],
		["Anchor Point","AK",59.82,-151.59,41705068],
		["Piffard","NY",42.84,-77.88,41705367],
		["Cohocton","NY",42.5,-77.5,41705666],
		["Waldo","FL",29.79,-82.17,41705965],
		["Mobile","AL",30.68,-88.04,41706264],
		["Readyville","TN",35.8,-86.19,41706563],
		["Newhebron","MS",31.72,-90.04,41706862],
		["Luckey","OH",41.45,-83.48,41707161],
		["Ridgeville","IN",40.28,-85.02,41707460],
		["Minnesota City","MN",44.09,-91.75,41707759],
		["Britton","SD",45.78,-97.74,41708058],
		["Waterman","IL",41.76,-88.77,41708357],
		["Atlanta","IL",40.26,-89.23,41708656],
		["Wakeeney","KS",39.02,-99.87,41708955],
		["Jessieville","AR",34.7,-93.21,41709254],
		["Quinton","OK",35.12,-95.36,41709553],
		["Gary","TX",32.01,-94.24,41709852],
		["Brookeland","TX",31.09,-93.97,41710151],
		["Upper Lake","CA",39.26,-122.96,41710450],
		["Hatfield","MA",42.37,-72.6,41710748],
		["Hampton Falls","NH",42.91,-70.86,41711046],
		["Porter Corners","NY",43.14,-73.88,41711344],
		["Upperco","MD",39.57,-76.8,41711642],
		["Fleetwood","NC",36.3,-81.51,41711940],
		["Sardis","GA",32.97,-81.76,41712238],
		["Alexandria","TN",36.07,-86.03,41712536],
		["Springville","TN",36.25,-88.15,41712834],
		["Hillsboro","KY",38.29,-83.65,41713132],
		["Siren","WI",45.78,-92.38,41713430],
		["Hebron","IL",42.47,-88.42,41713728],
		["Orrick","MO",39.21,-94.12,41714026],
		["Rossville","KS",39.13,-95.94,41714324],
		["Vail","CO",39.64,-106.39,41714622],
		["Oakley","ID",42.18,-113.86,41714920],
		["Winkelman","AZ",32.88,-110.66,41715218],
		["Saranac","NY",44.67,-73.82,41715515],
		["Noxapater","MS",32.99,-89.06,41715812],
		["Royal Center","IN",40.86,-86.5,41716109],
		["Wolcott","IN",40.75,-87.04,41716406],
		["Sullivan","WI",43.01,-88.58,41716703],
		["Redby","MN",47.84,-94.93,41717000],
		["Greenfield","IL",39.34,-90.2,41717297],
		["Palestine","IL",39,-87.61,41717594],
		["Ava","IL",37.88,-89.49,41717891],
		["South West City","MO",36.52,-94.6,41718188],
		["Niangua","MO",37.38,-92.82,41718485],
		["Burwell","NE",41.78,-99.13,41718782],
		["Byron","CA",37.85,-121.62,41719079],
		["Petersburg","PA",40.57,-78.04,41719375],
		["Sudlersville","MD",39.18,-75.85,41719671],
		["Red House","WV",38.55,-81.89,41719967],
		["Marshallville","GA",32.45,-83.94,41720263],
		["Ider","AL",34.7,-85.66,41720559],
		["Midway","AL",32.07,-85.52,41720855],
		["Columbus","OH",39.98,-82.98,41721151],
		["Medora","IN",38.82,-86.17,41721447],
		["Scipio","IN",39.07,-85.72,41721743],
		["Freeport","MI",42.76,-85.31,41722039],
		["Central Lake","MI",45.06,-85.26,41722335],
		["Vienna","MO",38.18,-91.94,41722631],
		["Trout","LA",31.63,-92.24,41722927],
		["Hornbeck","LA",31.32,-93.39,41723223],
		["Texhoma","OK",36.5,-101.78,41723519],
		["Bremond","TX",31.16,-96.67,41723815],
		["Boys Ranch","TX",35.51,-102.25,41724111],
		["Ocean Park","WA",46.54,-124.04,41724407],
		["Ritzville","WA",47.07,-118.47,41724703],
		["Oakham","MA",42.35,-72.05,41724998],
		["Esperance","NY",42.76,-74.25,41725293],
		["Port Leyden","NY",43.58,-75.34,41725588],
		["Cherry Tree","PA",40.72,-78.8,41725883],
		["Sweet Valley","PA",41.28,-76.15,41726178],
		["Luzerne","PA",41.28,-75.89,41726473],
		["Boyce","VA",39.09,-78.05,41726768],
		["Reva","VA",38.49,-78.13,41727063],
		["Rose Hill","VA",36.67,-83.36,41727358],
		["Burlington","WV",39.36,-78.91,41727653],
		["Sharon","SC",34.95,-81.34,41727948],
		["Bowersville","GA",34.37,-83.08,41728243],
		["Winfield","TN",36.56,-84.45,41728538],
		["Grand Junction","TN",35.04,-89.18,41728833],
		["Ledbetter","KY",37.04,-88.49,41729128],
		["Bainbridge","IN",39.76,-86.81,41729423],
		["Deerfield","MI",41.89,-83.77,41729718],
		["South Boardman","MI",44.63,-85.25,41730013],
		["Stephenson","MI",45.41,-87.6,41730308],
		["Camp Douglas","WI",43.91,-90.26,41730603],
		["Kansas City","MO",39.09,-94.58,41730898],
		["Lockwood","MO",37.38,-93.95,41731193],
		["Hermitage","AR",33.44,-92.17,41731488],
		["Dierks","AR",34.12,-94.01,41731783],
		["Newark","AR",35.7,-91.44,41732078],
		["Altus","AR",35.44,-93.76,41732373],
		["Graford","TX",32.93,-98.24,41732668],
		["Idaho Springs","CO",39.74,-105.51,41732963],
		["Union","NH",43.45,-71.03,41733257],
		["Whitefield","ME",44.17,-69.62,41733551],
		["Atlantic Beach","NY",40.58,-73.71,41733845],
		["Poland","NY",43.22,-75.06,41734139],
		["Brooktondale","NY",42.38,-76.39,41734433],
		["Nakina","NC",34.13,-78.66,41734727],
		["Ruby","SC",34.74,-80.17,41735021],
		["Sebastian","FL",27.78,-80.48,41735315],
		["Bradenton Beach","FL",27.46,-82.69,41735609],
		["North Port","FL",27.04,-82.19,41735903],
		["Clarkrange","TN",36.21,-84.96,41736197],
		["Sherrodsville","OH",40.49,-81.24,41736491],
		["Harlan","IN",41.22,-84.83,41736785],
		["Nora Springs","IA",43.14,-93,41737079],
		["Cascade","WI",43.65,-88,41737373],
		["Eden","WI",43.69,-88.36,41737667],
		["Coleman","WI",45.07,-88.03,41737961],
		["Wild Rose","WI",44.17,-89.24,41738255],
		["Silver Bay","MN",47.29,-91.27,41738549],
		["Truman","MN",43.82,-94.43,41738843],
		["Leslie","MO",38.41,-91.23,41739137],
		["Towaoc","CO",37.2,-108.72,41739431],
		["Stratford","CA",36.17,-119.81,41739725],
		["Cathlamet","WA",46.22,-123.31,41740019],
		["Melrose","NY",42.85,-73.6,41740312],
		["Sharon Springs","NY",42.79,-74.61,41740605],
		["Hopwood","PA",39.87,-79.7,41740898],
		["Vanderbilt","PA",40.03,-79.66,41741191],
		["Fieldale","VA",36.7,-79.94,41741484],
		["Dryden","VA",36.77,-82.94,41741777],
		["Corapeake","NC",36.53,-76.57,41742070],
		["Sycamore","GA",31.67,-83.63,41742363],
		["Titus","AL",32.71,-86.25,41742656],
		["Salvisa","KY",37.91,-84.86,41742949],
		["Lizton","IN",39.88,-86.54,41743242],
		["Lake Linden","MI",47.08,-88.4,41743535],
		["Madison","MN",45.01,-96.18,41743828],
		["Parkers Prairie","MN",46.15,-95.32,41744121],
		["Atkinson","NE",42.53,-98.97,41744414],
		["Krotz Springs","LA",30.53,-91.75,41744707],
		["Allen","OK",34.87,-96.41,41745000],
		["Austin","TX",30.26,-97.74,41745293],
		["Crosbyton","TX",33.64,-101.23,41745586],
		["Crested Butte","CO",38.87,-106.98,41745879],
		["Vanderwagen","NM",35.26,-108.8,41746172],
		["Browns Valley","CA",39.33,-121.33,41746465],
		["Dexter","OR",43.88,-122.81,41746758],
		["Interlaken","NY",42.61,-76.72,41747050],
		["Glenelg","MD",39.26,-76.99,41747342],
		["Rapidan","VA",38.31,-78.06,41747634],
		["Moncure","NC",35.62,-79.08,41747926],
		["Olanta","SC",33.93,-79.93,41748218],
		["Anderson","AL",34.91,-87.27,41748510],
		["Tanner","AL",34.66,-86.95,41748802],
		["Mc Dowell","KY",37.42,-82.74,41749094],
		["Hector","MN",44.74,-94.71,41749386],
		["Henderson","MN",44.53,-93.9,41749678],
		["Lower Brule","SD",44.07,-99.58,41749970],
		["Cuba","IL",40.49,-90.19,41750262],
		["Blue Mound","IL",39.7,-89.11,41750554],
		["Everton","MO",37.34,-93.7,41750846],
		["Branch","LA",30.34,-92.26,41751138],
		["Newellton","LA",32.07,-91.23,41751430],
		["Laverne","OK",36.7,-99.89,41751722],
		["Ramona","OK",36.53,-95.92,41752014],
		["Lott","TX",31.2,-97.03,41752306],
		["Laporte","CO",40.62,-105.13,41752598],
		["Whitewater","CO",38.98,-108.43,41752890],
		["Menan","ID",43.75,-112,41753182],
		["Fort Duchesne","UT",40.29,-109.8,41753474],
		["Bunkerville","NV",36.72,-114.14,41753766],
		["Springfield","MA",42.11,-72.53,41754057],
		["Rebersburg","PA",40.94,-77.49,41754348],
		["Sharpsburg","NC",35.86,-77.83,41754639],
		["Kilmichael","MS",33.43,-89.56,41754930],
		["Bagdad","KY",38.26,-85.05,41755221],
		["Baxter","KY",36.86,-83.33,41755512],
		["Wickliffe","KY",36.96,-89.08,41755803],
		["Amsterdam","OH",40.47,-80.92,41756094],
		["Remington","IN",40.76,-87.15,41756385],
		["Snover","MI",43.5,-82.97,41756676],
		["Rudyard","MI",46.23,-84.6,41756967],
		["Ackley","IA",42.55,-93.05,41757258],
		["Chinook","MT",48.47,-109.21,41757549],
		["Edina","MO",40.16,-92.17,41757840],
		["Riverside","MO",39.09,-94.58,41758131],
		["Bucyrus","KS",38.71,-94.71,41758422],
		["Pleasanton","KS",38.17,-94.71,41758713],
		["Pomona","KS",38.61,-95.45,41759004],
		["Welling","OK",35.91,-94.85,41759295],
		["Joseph City","AZ",35.05,-110.4,41759586],
		["Caliente","NV",37.32,-114.53,41759877],
		["Corinna","ME",44.92,-69.26,41760167],
		["Darien Center","NY",42.89,-78.39,41760457],
		["Thompsontown","PA",40.56,-77.23,41760747],
		["Millmont","PA",40.86,-77.23,41761037],
		["West River","MD",38.82,-76.55,41761327],
		["Shawsville","VA",37.16,-80.24,41761617],
		["Hoagland","IN",40.94,-85,41761907],
		["Harrisville","MI",44.65,-83.29,41762197],
		["Hinton","IA",42.62,-96.29,41762487],
		["Argyle","WI",42.7,-89.86,41762777],
		["Junction City","WI",44.59,-89.76,41763067],
		["Hampton","MN",44.6,-93,41763357],
		["Schaumburg","IL",42.03,-88.08,41763647],
		["Harper","KS",37.28,-98.02,41763937],
		["Louisville","NE",40.99,-96.16,41764227],
		["Red Oak","OK",34.95,-95.08,41764517],
		["Tennessee Colony","TX",31.88,-95.91,41764807],
		["Sundown","TX",33.45,-102.49,41765097],
		["Rochdale","MA",42.2,-71.9,41765386],
		["Sterling","NY",43.32,-76.64,41765675],
		["Lisle","NY",42.35,-76,41765964],
		["Beech Creek","PA",41.07,-77.58,41766253],
		["Rhoadesville","VA",38.28,-77.9,41766542],
		["Dover","NC",35.21,-77.43,41766831],
		["Carrabelle","FL",29.84,-84.67,41767120],
		["Tennessee Ridge","TN",36.31,-87.76,41767409],
		["Pope","MS",34.21,-89.94,41767698],
		["Wallins Creek","KY",36.82,-83.41,41767987],
		["Phelps","KY",37.5,-82.16,41768276],
		["Martin","KY",37.57,-82.75,41768565],
		["Jackson Center","OH",40.43,-84.03,41768854],
		["New Knoxville","OH",40.49,-84.31,41769143],
		["Kingston","MI",43.41,-83.18,41769432],
		["Troy","KS",39.78,-95.08,41769721],
		["Big Cabin","OK",36.53,-95.22,41770010],
		["Bremerton","WA",47.55,-122.64,41770299],
		["Westerlo","NY",42.51,-74.04,41770587],
		["Mullens","WV",37.57,-81.38,41770875],
		["Cedar Grove","NC",36.2,-79.17,41771163],
		["Bullock","NC",36.5,-78.55,41771451],
		["Gibson","NC",34.75,-79.6,41771739],
		["Cross Hill","SC",34.3,-81.98,41772027],
		["Milford Center","OH",40.17,-83.43,41772315],
		["Dayton","OH",39.77,-84.19,41772603],
		["Sherwood","MI",42,-85.24,41772891],
		["Waldo","WI",43.67,-87.94,41773179],
		["Norwood Young America","MN",44.76,-93.92,41773467],
		["Linwood","KS",39,-95.03,41773755],
		["Loreauville","LA",30.05,-91.73,41774043],
		["Fort Davis","TX",30.59,-103.9,41774331],
		["Grace","ID",42.51,-111.74,41774619],
		["Capitan","NM",33.54,-105.59,41774907],
		["Glen Ellen","CA",38.38,-122.52,41775195],
		["Valley","WA",48.14,-117.76,41775483],
		["Maidens","VA",37.66,-77.88,41775770],
		["Mount Sidney","VA",38.26,-78.97,41776057],
		["Rich Square","NC",36.27,-77.28,41776344],
		["Port Washington","OH",40.32,-81.51,41776631],
		["Pewamo","MI",43,-84.84,41776918],
		["Norwalk","WI",43.83,-90.62,41777205],
		["Mazeppa","MN",44.27,-92.54,41777492],
		["Shell Knob","MO",36.63,-93.63,41777779],
		["Satanta","KS",37.43,-100.98,41778066],
		["Beaver","OK",36.81,-100.52,41778353],
		["Los Alamos","CA",34.73,-120.24,41778640],
		["North Rose","NY",43.2,-76.91,41778926],
		["Montour Falls","NY",42.34,-76.84,41779212],
		["Lawrenceville","PA",41.99,-77.12,41779498],
		["Willards","MD",38.39,-75.34,41779784],
		["Jarratt","VA",36.81,-77.47,41780070],
		["Burkeville","VA",37.18,-78.2,41780356],
		["Woodland","NC",36.33,-77.21,41780642],
		["Hookerton","NC",35.42,-77.58,41780928],
		["Blowing Rock","NC",36.12,-81.67,41781214],
		["Clyo","GA",32.48,-81.26,41781500],
		["Richland","GA",32.08,-84.67,41781786],
		["Crenshaw","MS",34.5,-90.19,41782072],
		["Sunflower","MS",33.54,-90.53,41782358],
		["Tutwiler","MS",34.01,-90.42,41782644],
		["Preston","MS",32.85,-88.8,41782930],
		["Pleasant City","OH",39.9,-81.54,41783216],
		["Powhatan Point","OH",39.86,-80.8,41783502],
		["Payne","OH",41.07,-84.72,41783788],
		["Carthage","IN",39.73,-85.57,41784074],
		["Wilkinson","IN",39.88,-85.6,41784360],
		["Shirley","IN",39.89,-85.57,41784646],
		["Dane","WI",43.25,-89.5,41784932],
		["Adrian","MN",43.63,-95.93,41785218],
		["Clinton","MT",46.61,-113.59,41785504],
		["Saint Jacob","IL",38.71,-89.76,41785790],
		["Plato","MO",37.5,-92.22,41786076],
		["Plains","KS",37.26,-100.57,41786362],
		["Horseshoe Bend","ID",43.92,-116.17,41786648],
		["Orangeville","UT",39.44,-111.14,41786934],
		["Moroni","UT",39.54,-111.61,41787220],
		["Hines","OR",43.44,-119.23,41787506],
		["Fitzwilliam","NH",42.78,-72.15,41787791],
		["Guilford","ME",45.16,-69.38,41788076],
		["South Hackensack","NJ",40.86,-74.04,41788361],
		["Sandyville","WV",38.9,-81.64,41788646],
		["Shenandoah Junction","WV",39.35,-77.84,41788931],
		["Etowah","NC",35.31,-82.59,41789216],
		["Pineville","SC",33.42,-80.02,41789501],
		["Menlo","GA",34.48,-85.47,41789786],
		["Altoona","FL",29.04,-81.59,41790071],
		["Lillian","AL",30.41,-87.43,41790356],
		["Monroe","TN",36.45,-85.2,41790641],
		["Smithland","KY",37.13,-88.4,41790926],
		["Manly","IA",43.28,-93.2,41791211],
		["West Point","IA",40.71,-91.45,41791496],
		["Balsam Lake","WI",45.45,-92.45,41791781],
		["Dawson","MN",44.92,-96.04,41792066],
		["Gilman","IL",40.76,-87.99,41792351],
		["Grafton","IL",38.97,-90.42,41792636],
		["Urbana","MO",37.84,-93.16,41792921],
		["Colt","AR",35.13,-90.81,41793206],
		["La Luz","NM",32.97,-105.93,41793491],
		["Searsport","ME",44.46,-68.91,41793775],
		["Port Ewen","NY",41.9,-73.97,41794059],
		["Croghan","NY",43.89,-75.39,41794343],
		["West Valley","NY",42.43,-78.62,41794627],
		["Saint Paul","VA",36.9,-82.31,41794911],
		["Gallipolis Ferry","WV",38.76,-82.11,41795195],
		["Coolidge","GA",31.01,-83.86,41795479],
		["Rossville","TN",35.04,-89.54,41795763],
		["Duck Hill","MS",33.63,-89.71,41796047],
		["Mount Olivet","KY",38.53,-84.03,41796331],
		["Drakesboro","KY",37.21,-87.04,41796615],
		["Cub Run","KY",37.3,-86.06,41796899],
		["Van Buren","IN",40.61,-85.5,41797183],
		["Rothbury","MI",43.5,-86.34,41797467],
		["Boulder","MT",46.17,-111.99,41797751],
		["Virginia","IL",39.95,-90.21,41798035],
		["Waverly","IL",39.59,-89.95,41798319],
		["Leoti","KS",38.48,-101.35,41798603],
		["Minatare","NE",41.81,-103.5,41798887],
		["Grand Cane","LA",32.08,-93.8,41799171],
		["Portland","OR",45.53,-122.68,41799455],
		["Haines","AK",59.16,-135.86,41799739],
		["Salisbury","PA",39.75,-79.08,41800022],
		["Hunker","PA",40.2,-79.61,41800305],
		["Rice","VA",37.27,-78.29,41800588],
		["Alum Creek","WV",38.28,-81.84,41800871],
		["Union Mills","NC",35.49,-81.96,41801154],
		["Petersburg","KY",39.03,-84.83,41801437],
		["Cayuga","IN",39.94,-87.46,41801720],
		["Darlington","IN",40.1,-86.77,41802003],
		["Manson","IA",42.52,-94.53,41802286],
		["Dunkerton","IA",42.56,-92.15,41802569],
		["Alton","IA",42.98,-96,41802852],
		["Barneveld","WI",43.01,-89.89,41803135],
		["Black Earth","WI",43.13,-89.74,41803418],
		["Nashwauk","MN",47.38,-93.16,41803701],
		["Oquawka","IL",40.93,-90.94,41803984],
		["Forrest","IL",40.75,-88.4,41804267],
		["Taylor","AR",33.1,-93.46,41804550],
		["Archer City","TX",33.59,-98.62,41804833],
		["Gardendale","TX",32.02,-102.36,41805116],
		["Newcomb","NM",36.28,-108.7,41805399],
		["Pilot Rock","OR",45.39,-118.78,41805682],
		["Chatham","MA",41.67,-69.96,41805964],
		["Comstock","NY",43.45,-73.37,41806246],
		["Rouses Point","NY",44.99,-73.36,41806528],
		["Elliottsburg","PA",40.36,-77.26,41806810],
		["Callao","VA",37.96,-76.55,41807092],
		["Ivor","VA",36.9,-76.89,41807374],
		["Glen Dale","WV",39.94,-80.75,41807656],
		["Pinebluff","NC",35.1,-79.47,41807938],
		["Traphill","NC",36.35,-81.03,41808220],
		["Lookout Mountain","TN",34.99,-85.35,41808502],
		["Livermore","KY",37.49,-87.13,41808784],
		["Adena","OH",40.21,-80.87,41809066],
		["Rogers","OH",40.79,-80.62,41809348],
		["Clay City","IN",39.27,-87.11,41809630],
		["Bedford","IA",40.67,-94.72,41809912],
		["Dongola","IL",37.36,-89.16,41810194],
		["Harrisburg","MO",39.14,-92.45,41810476],
		["Lecompton","KS",39.04,-95.39,41810758],
		["Amarillo","TX",35.2,-101.81,41811040],
		["Alpaugh","CA",35.86,-119.46,41811322],
		["Ford","WA",47.91,-117.8,41811604],
		["Somerset","MA",41.73,-71.15,41811885],
		["Goldens Bridge","NY",41.29,-73.64,41812166],
		["Sheffield","PA",41.69,-79.02,41812447],
		["Meherrin","VA",37.09,-78.36,41812728],
		["Raphine","VA",37.93,-79.23,41813009],
		["Alapaha","GA",31.38,-83.22,41813290],
		["Talbotton","GA",32.67,-84.54,41813571],
		["Lisman","AL",32.16,-88.28,41813852],
		["Palo","IA",42.06,-91.79,41814133],
		["Washburn","IL",40.92,-89.29,41814414],
		["Saint Mary","MO",37.87,-89.94,41814695],
		["Madison","MO",39.47,-92.21,41814976],
		["Smithton","MO",38.68,-93.09,41815257],
		["Phillipsburg","MO",37.55,-92.78,41815538],
		["Mount Hope","KS",37.87,-97.67,41815819],
		["Sutherland","NE",41.15,-101.12,41816100],
		["Ochelata","OK",36.6,-95.98,41816381],
		["Hull","TX",30.13,-94.65,41816662],
		["Las Vegas","NV",35.99,-115.25,41816943],
		["Stateline","NV",38.96,-119.9,41817224],
		["Lower Lake","CA",38.88,-122.55,41817505],
		["Knights Landing","CA",38.89,-121.8,41817786],
		["Plymouth","CA",38.46,-120.88,41818067],
		["Hanscom Afb","MA",42.46,-71.28,41818347],
		["Shaftsbury","VT",42.98,-73.2,41818627],
		["Glen Spey","NY",41.47,-74.81,41818907],
		["Colden","NY",42.65,-78.68,41819187],
		["Freedom","NY",42.48,-78.32,41819467],
		["Bloxom","VA",37.83,-75.62,41819747],
		["Union","WV",37.59,-80.54,41820027],
		["Buckhead","GA",33.56,-83.36,41820307],
		["Copperhill","TN",34.99,-84.37,41820587],
		["Pinola","MS",31.82,-90.02,41820867],
		["Newton","GA",31.3,-84.37,41821147],
		["Smithfield","KY",38.38,-85.25,41821427],
		["Pierpont","OH",41.76,-80.57,41821707],
		["Sterling","OH",40.94,-81.85,41821987],
		["Montrose","IA",40.52,-91.41,41822267],
		["Hurley","WI",46.44,-90.19,41822547],
		["Morristown","MN",44.22,-93.44,41822827],
		["Cottonwood","MN",44.6,-95.67,41823107],
		["Bowman","ND",46.18,-103.4,41823387],
		["Clarkton","MO",36.45,-89.96,41823667],
		["Kansas City","MO",39.09,-94.58,41823947],
		["Lyndon","KS",38.61,-95.68,41824227],
		["Buhler","KS",38.13,-97.77,41824507],
		["Okarche","OK",35.72,-97.97,41824787],
		["Booker","TX",36.45,-100.54,41825067],
		["Potlatch","ID",46.96,-116.91,41825347],
		["Troy","ID",46.74,-116.74,41825627],
		["Wellington","UT",39.54,-110.74,41825907],
		["Santa Monica","CA",34.01,-118.49,41826187],
		["Rock Island","WA",47.35,-120.05,41826467],
		["Prescott","WA",46.35,-118.47,41826747],
		["Woodsville","NH",44.14,-72.02,41827026],
		["Albion","ME",44.53,-69.44,41827305],
		["Roxbury","CT",41.55,-73.3,41827584],
		["Lilly","PA",40.42,-78.62,41827863],
		["Newfoundland","PA",41.3,-75.32,41828142],
		["Hoffman","NC",35.03,-79.54,41828421],
		["Lyerly","GA",34.4,-85.4,41828700],
		["Rutherford","TN",36.12,-88.99,41828979],
		["West","MS",33.19,-89.78,41829258],
		["Dixon","KY",37.51,-87.69,41829537],
		["Robards","KY",37.67,-87.52,41829816],
		["Stuart","IA",41.5,-94.31,41830095],
		["Freeman","SD",43.35,-97.43,41830374],
		["Joliet","MT",45.48,-108.99,41830653],
		["Leland","IL",41.61,-88.79,41830932],
		["Martinsville","IL",39.33,-87.88,41831211],
		["Makanda","IL",37.61,-89.23,41831490],
		["La Monte","MO",38.77,-93.42,41831769],
		["Peru","NE",40.47,-95.73,41832048],
		["Strong","AR",33.1,-92.35,41832327],
		["Calico Rock","AR",36.12,-92.13,41832606],
		["Earlsboro","OK",35.32,-96.79,41832885],
		["Oakwood","TX",31.58,-95.84,41833164],
		["Black Canyon City","AZ",34.13,-112.13,41833443],
		["Soulsbyville","CA",37.99,-120.25,41833722],
		["Fort Jones","CA",41.59,-122.96,41834001],
		["Stockton Springs","ME",44.48,-68.85,41834279],
		["Dunn Loring","VA",38.89,-77.23,41834557],
		["Crab Orchard","WV",37.74,-81.23,41834835],
		["Casar","NC",35.51,-81.61,41835113],
		["Cuba","AL",32.44,-88.37,41835391],
		["Red Banks","MS",34.83,-89.58,41835669],
		["Dike","IA",42.46,-92.63,41835947],
		["Blackduck","MN",47.72,-94.54,41836225],
		["Lovington","IL",39.71,-88.63,41836503],
		["East Carondelet","IL",38.53,-90.23,41836781],
		["Stark City","MO",36.86,-94.18,41837059],
		["Freeburg","MO",38.31,-91.92,41837337],
		["Burlingame","KS",38.75,-95.83,41837615],
		["Shady Point","OK",35.13,-94.66,41837893],
		["Roscoe","TX",32.44,-100.54,41838171],
		["Concho","AZ",34.51,-109.69,41838449],
		["Houck","AZ",35.36,-109.24,41838727],
		["Moapa","NV",36.63,-114.62,41839005],
		["Lebec","CA",34.86,-118.87,41839283],
		["Troy","NH",42.83,-72.18,41839560],
		["Grand Isle","VT",44.72,-73.3,41839837],
		["Stamford","NY",42.4,-74.61,41840114],
		["Stanfordville","NY",41.86,-73.71,41840391],
		["Memphis","NY",43.08,-76.37,41840668],
		["Wakefield","VA",36.96,-76.98,41840945],
		["Callaway","VA",37.01,-80.05,41841222],
		["Bradyville","TN",35.69,-86.1,41841499],
		["Mayslick","KY",38.53,-83.87,41841776],
		["Pellston","MI",45.55,-84.78,41842053],
		["Rockwell City","IA",42.39,-94.63,41842330],
		["Blanchardville","WI",42.8,-89.86,41842607],
		["Lake Nebagamon","WI",46.5,-91.69,41842884],
		["Bethany","IL",39.64,-88.74,41843161],
		["Albers","IL",38.54,-89.61,41843438],
		["Mendon","IL",40.08,-91.28,41843715],
		["Cleveland","MO",38.67,-94.59,41843992],
		["South Hutchinson","KS",38.02,-97.94,41844269],
		["Higden","AR",35.56,-92.2,41844546],
		["Lead Hill","AR",36.41,-92.9,41844823],
		["Blooming Grove","TX",32.09,-96.71,41845100],
		["Ridgway","CO",38.15,-107.75,41845377],
		["Heppner","OR",45.31,-119.58,41845654],
		["Worcester","NY",42.59,-74.75,41845930],
		["Lacona","NY",43.64,-76.06,41846206],
		["South New Berlin","NY",42.53,-75.35,41846482],
		["Prosperity","PA",40.02,-80.25,41846758],
		["Fredericktown","PA",40.02,-80.03,41847034],
		["Needmore","PA",39.86,-78.15,41847310],
		["Brogue","PA",39.86,-76.45,41847586],
		["Renovo","PA",41.32,-77.74,41847862],
		["Hegins","PA",40.66,-76.48,41848138],
		["Riegelsville","PA",40.59,-75.19,41848414],
		["Oakland","MS",34.05,-89.91,41848690],
		["Mc Henry","MS",30.69,-89.18,41848966],
		["Willisburg","KY",37.81,-85.12,41849242],
		["Harned","KY",37.75,-86.41,41849518],
		["Argillite","KY",38.42,-82.81,41849794],
		["Bloomingburg","OH",39.6,-83.39,41850070],
		["Dubois","IN",38.45,-86.8,41850346],
		["Dorchester","WI",45,-90.33,41850622],
		["Preston","MN",43.67,-92.08,41850898],
		["Hillsboro","ND",47.4,-97.06,41851174],
		["Park City","MT",45.65,-108.97,41851450],
		["Baker","MT",46.28,-104.28,41851726],
		["Philo","IL",40,-88.15,41852002],
		["Assumption","IL",39.51,-89.04,41852278],
		["Scott","AR",34.68,-92.12,41852554],
		["Cherry Valley","AR",35.4,-90.75,41852830],
		["Long Beach","WA",46.39,-124.03,41853106],
		["Baileyville","ME",45.09,-67.46,41853381],
		["Waterbury Center","VT",44.38,-72.7,41853656],
		["Glen Lyon","PA",41.17,-76.07,41853931],
		["Friendsville","MD",39.66,-79.4,41854206],
		["Zuni","VA",36.86,-76.82,41854481],
		["Chinquapin","NC",34.82,-77.74,41854756],
		["Shaw A F B","SC",33.97,-80.45,41855031],
		["Round O","SC",32.93,-80.54,41855306],
		["Joppa","AL",34.27,-86.54,41855581],
		["Reagan","TN",35.51,-88.35,41855856],
		["Climax","GA",30.89,-84.44,41856131],
		["Flushing","OH",40.14,-81.06,41856406],
		["Eden Valley","MN",45.32,-94.54,41856681],
		["Depue","IL",41.32,-89.29,41856956],
		["Barry","IL",39.69,-91.03,41857231],
		["Hawk Point","MO",38.97,-91.13,41857506],
		["Topeka","KS",39.03,-95.69,41857781],
		["Leon","KS",37.68,-96.78,41858056],
		["Kensett","AR",35.23,-91.67,41858331],
		["Tulsa","OK",36.12,-95.91,41858606],
		["Marfa","TX",30.31,-104.02,41858881],
		["Cedar Crest","NM",35.1,-106.37,41859156],
		["Questa","NM",36.71,-105.59,41859431],
		["Inyokern","CA",35.88,-118.15,41859706],
		["Biola","CA",36.8,-120.01,41859981],
		["Rumney","NH",43.8,-71.81,41860255],
		["Verplanck","NY",41.26,-73.89,41860529],
		["Sackets Harbor","NY",43.94,-76.12,41860803],
		["Millerton","PA",41.98,-76.95,41861077],
		["Drakes Branch","VA",36.99,-78.6,41861351],
		["Seth","WV",38.09,-81.64,41861625],
		["Cerro Gordo","NC",34.32,-78.92,41861899],
		["Jenkinsburg","GA",33.32,-84.03,41862173],
		["Kite","GA",32.69,-82.51,41862447],
		["Indian Rocks Beach","FL",27.88,-82.84,41862721],
		["Tollesboro","KY",38.57,-83.57,41862995],
		["Glendale","KY",37.6,-85.91,41863269],
		["Mount Victory","OH",40.53,-83.52,41863543],
		["Wilson","MI",45.7,-87.45,41863817],
		["Walford","IA",41.87,-91.83,41864091],
		["Lakeside","MT",47.99,-114.27,41864365],
		["Plumerville","AR",35.16,-92.64,41864639],
		["Lone Star","TX",32.93,-94.7,41864913],
		["Shandon","CA",35.63,-120.26,41865187],
		["New York","NY",40.71,-73.99,41865460],
		["Berne","NY",42.62,-74.13,41865733],
		["Coaldale","PA",40.82,-75.91,41866006],
		["Saint Michaels","MD",38.78,-76.22,41866279],
		["Kilmarnock","VA",37.71,-76.38,41866552],
		["Letart","WV",38.88,-81.97,41866825],
		["Deep Gap","NC",36.23,-81.51,41867098],
		["Riceboro","GA",31.72,-81.44,41867371],
		["Poulan","GA",31.51,-83.78,41867644],
		["Sawyerville","AL",32.74,-87.74,41867917],
		["Sequatchie","TN",35.19,-85.66,41868190],
		["Scotts Hill","TN",35.51,-88.25,41868463],
		["Tougaloo","MS",32.39,-90.15,41868736],
		["Unionville","MI",43.65,-83.46,41869009],
		["Lenox","IA",40.88,-94.55,41869282],
		["Mount Ayr","IA",40.71,-94.23,41869555],
		["Kingsley","IA",42.58,-95.96,41869828],
		["Hermosa","SD",43.84,-103.19,41870101],
		["Tampico","IL",41.63,-89.78,41870374],
		["Wyoming","IL",41.06,-89.77,41870647],
		["Shipman","IL",39.12,-90.04,41870920],
		["Odin","IL",38.61,-89.05,41871193],
		["Summersville","MO",37.17,-91.65,41871466],
		["Solomon","KS",38.91,-97.37,41871739],
		["Pioneer","LA",32.73,-91.43,41872012],
		["Cedarville","AR",35.57,-94.37,41872285],
		["Blair","OK",34.77,-99.33,41872558],
		["Mountain View","WY",41.27,-110.33,41872831],
		["Castle Dale","UT",39.2,-110.92,41873104],
		["Cabin John","MD",38.97,-77.16,41873376],
		["Dennis","MS",34.56,-88.18,41873648],
		["Fox Lake","WI",43.56,-88.91,41873920],
		["Center City","MN",45.39,-92.81,41874192],
		["Harmony","MN",43.55,-92,41874464],
		["Nicollet","MN",44.27,-94.18,41874736],
		["Ashton","IL",41.86,-89.22,41875008],
		["Emerson","AR",33.09,-93.19,41875280],
		["Midway","TX",31.02,-95.75,41875552],
		["Burton","TX",30.17,-96.59,41875824],
		["Oakland","CA",37.78,-122.18,41876096],
		["Laytonville","CA",39.8,-123.5,41876368],
		["Steep Falls","ME",43.76,-70.64,41876639],
		["Sandy Creek","NY",43.64,-76.08,41876910],
		["Hammond","NY",44.44,-75.69,41877181],
		["Springwater","NY",42.68,-77.57,41877452],
		["Templeton","PA",40.94,-79.45,41877723],
		["Melfa","VA",37.64,-75.74,41877994],
		["Creola","AL",30.89,-88,41878265],
		["Mc Cool","MS",33.16,-89.33,41878536],
		["Hardin","KY",36.76,-88.3,41878807],
		["Sherwood","OH",41.29,-84.55,41879078],
		["Williamsburg","IN",39.96,-85,41879349],
		["Lachine","MI",45.08,-83.73,41879620],
		["Albany","WI",42.7,-89.43,41879891],
		["Irma","WI",45.34,-89.66,41880162],
		["Rib Lake","WI",45.31,-90.2,41880433],
		["Elko New Market","MN",44.57,-93.34,41880704],
		["Silver Lake","MN",44.9,-94.19,41880975],
		["Thornton","IL",41.57,-87.61,41881246],
		["Whitewater","KS",37.96,-97.14,41881517],
		["Clarendon","AR",34.69,-91.3,41881788],
		["Everton","AR",36.15,-92.9,41882059],
		["Bronson","TX",31.34,-94,41882330],
		["Mc Queeney","TX",29.59,-98.04,41882601],
		["Landers","CA",34.33,-116.51,41882872],
		["Newport Beach","CA",33.6,-117.9,41883143],
		["Portland","OR",45.51,-122.68,41883414],
		["Palmyra","ME",44.84,-69.35,41883684],
		["Madrid","NY",44.75,-75.15,41883954],
		["Pleasantville","PA",41.59,-79.57,41884224],
		["Monroeton","PA",41.71,-76.47,41884494],
		["Ailey","GA",32.18,-82.57,41884764],
		["Preston","GA",32.06,-84.54,41885034],
		["Finger","TN",35.35,-88.61,41885304],
		["Friars Point","MS",34.36,-90.63,41885574],
		["La Center","KY",37.07,-88.97,41885844],
		["Saint Joe","IN",41.31,-84.9,41886114],
		["Marysville","IN",38.58,-85.65,41886384],
		["Treynor","IA",41.23,-95.6,41886654],
		["Clear Lake","SD",44.76,-96.68,41886924],
		["Pomona","MO",36.86,-91.91,41887194],
		["Thomas","OK",35.74,-98.74,41887464],
		["Blum","TX",32.14,-97.39,41887734],
		["Etna","CA",41.41,-123.02,41888004],
		["Hansville","WA",47.91,-122.57,41888274],
		["Port Ludlow","WA",47.89,-122.7,41888544],
		["Wyoming","RI",41.52,-71.67,41888813],
		["Anson","ME",44.79,-69.88,41889082],
		["Newfane","VT",42.98,-72.65,41889351],
		["Alpine","NJ",40.95,-73.92,41889620],
		["Landisville","NJ",39.52,-74.93,41889889],
		["Altmar","NY",43.51,-76,41890158],
		["Fayette City","PA",40.1,-79.83,41890427],
		["Fombell","PA",40.81,-80.2,41890696],
		["Washington","DC",38.89,-77.03,41890965],
		["Virginia Beach","VA",36.73,-76.04,41891234],
		["Salt Rock","WV",38.32,-82.24,41891503],
		["Harrells","NC",34.72,-78.2,41891772],
		["Todd","NC",36.3,-81.6,41892041],
		["Charleston","SC",32.78,-79.99,41892310],
		["Hermanville","MS",32.02,-90.8,41892579],
		["Slaughters","KY",37.49,-87.5,41892848],
		["Alger","OH",40.7,-83.84,41893117],
		["Morocco","IN",40.94,-87.45,41893386],
		["Oxford","MI",42.82,-83.25,41893655],
		["Saugatuck","MI",42.65,-86.2,41893924],
		["Mapleton","IA",42.16,-95.79,41894193],
		["Tiffin","IA",41.7,-91.67,41894462],
		["Welch","MN",44.59,-92.71,41894731],
		["Nevis","MN",46.96,-94.84,41895000],
		["Saint Michael","ND",47.94,-98.9,41895269],
		["Ellendale","ND",46,-98.52,41895538],
		["Cerro Gordo","IL",39.89,-88.73,41895807],
		["Kansas City","MO",39.09,-94.58,41896076],
		["Stewartsville","MO",39.75,-94.49,41896345],
		["Carterville","MO",37.15,-94.43,41896614],
		["Altamont","KS",37.19,-95.29,41896883],
		["Stephentown","NY",42.54,-73.44,41897151],
		["Alum Bank","PA",40.19,-78.62,41897419],
		["Mount Savage","MD",39.69,-78.88,41897687],
		["Pollocksville","NC",35,-77.22,41897955],
		["Elk Park","NC",36.15,-81.98,41898223],
		["Keysville","GA",33.23,-82.22,41898491],
		["Columbia","AL",31.29,-85.11,41898759],
		["Oakdale","TN",35.98,-84.55,41899027],
		["Buchanan","TN",36.42,-88.17,41899295],
		["Smithdale","MS",31.33,-90.68,41899563],
		["New Holland","OH",39.55,-83.25,41899831],
		["New Straitsville","OH",39.57,-82.23,41900099],
		["Bloomdale","OH",41.17,-83.55,41900367],
		["Deputy","IN",38.8,-85.65,41900635],
		["Gilman","WI",45.16,-90.8,41900903],
		["Garrison","ND",47.65,-101.42,41901171],
		["Lodge Grass","MT",45.22,-107.42,41901439],
		["Homer","IL",40.03,-87.95,41901707],
		["Greentop","MO",40.35,-92.56,41901975],
		["Lincoln","KS",39.04,-98.14,41902243],
		["Ringling","OK",34.17,-97.59,41902511],
		["Rotan","TX",32.85,-100.46,41902779],
		["Clayton","WA",48.03,-117.57,41903047],
		["Bradford","NH",43.27,-71.96,41903314],
		["Huntington","VT",44.33,-72.98,41903581],
		["Morris","CT",41.68,-73.17,41903848],
		["Martville","NY",43.28,-76.62,41904115],
		["Bolivar","PA",40.39,-79.15,41904382],
		["Manson","NC",36.47,-78.29,41904649],
		["Society Hill","SC",34.5,-79.85,41904916],
		["Hampton","FL",29.86,-82.13,41905183],
		["Hollywood","AL",34.71,-85.96,41905450],
		["Sylvania","AL",34.55,-85.8,41905717],
		["Attapulgus","GA",30.74,-84.47,41905984],
		["Coggon","IA",42.27,-91.53,41906251],
		["Rubicon","WI",43.31,-88.45,41906518],
		["Hager City","WI",44.64,-92.56,41906785],
		["Harvey","ND",47.77,-99.93,41907052],
		["Liberal","MO",37.55,-94.51,41907319],
		["Humphrey","NE",41.68,-97.48,41907586],
		["Mountain Pine","AR",34.57,-93.17,41907853],
		["Crawfordsville","AR",35.22,-90.32,41908120],
		["Wayne","OK",34.91,-97.31,41908387],
		["Mammoth","AZ",32.68,-110.62,41908654],
		["Asotin","WA",46.17,-117.16,41908921],
		["Thornton","NH",43.93,-71.62,41909187],
		["Moriches","NY",40.8,-72.82,41909453],
		["Spring Grove","VA",37.16,-76.97,41909719],
		["Jacksonville","FL",30.33,-81.65,41909985],
		["Hurlburt Field","FL",30.42,-86.69,41910251],
		["Holladay","TN",35.89,-88.07,41910517],
		["Flatgap","KY",37.93,-82.88,41910783],
		["Chippewa Lake","OH",41.06,-81.92,41911049],
		["Wawaka","IN",41.48,-85.48,41911315],
		["Denver","IN",40.86,-86.07,41911581],
		["Milltown","IN",38.34,-86.27,41911847],
		["Perrinton","MI",43.18,-84.67,41912113],
		["Lawton","IA",42.47,-96.18,41912379],
		["Waverly","MN",45.06,-93.96,41912645],
		["Renville","MN",44.79,-95.21,41912911],
		["Arlington","SD",44.36,-97.13,41913177],
		["Alexandria","SD",43.65,-97.77,41913443],
		["Malta","IL",41.93,-88.86,41913709],
		["Mazon","IL",41.23,-88.42,41913975],
		["Ogden","KS",39.11,-96.7,41914241],
		["Cyril","OK",34.89,-98.2,41914507],
		["Lometa","TX",31.21,-98.39,41914773],
		["Maxwell","CA",39.31,-122.17,41915039],
		["Ocean Gate","NJ",39.92,-74.13,41915304],
		["Eastport","NY",40.81,-72.7,41915569],
		["Cayuga","NY",42.91,-76.72,41915834],
		["Antwerp","NY",44.19,-75.6,41916099],
		["Kent","NY",43.33,-78.13,41916364],
		["Knotts Island","NC",36.51,-75.91,41916629],
		["Highlands","NC",35.05,-83.19,41916894],
		["Blackstock","SC",34.55,-81.15,41917159],
		["Locust Fork","AL",33.9,-86.62,41917424],
		["Fosters","AL",33.12,-87.72,41917689],
		["Bremen","KY",37.36,-87.21,41917954],
		["Oxford","IN",40.52,-87.24,41918219],
		["Barryton","MI",43.74,-85.14,41918484],
		["Fountain","MI",44.04,-86.17,41918749],
		["Riceville","IA",43.36,-92.55,41919014],
		["Laona","WI",45.56,-88.68,41919279],
		["Glenville","MN",43.56,-93.27,41919544],
		["Wheaton","MN",45.8,-96.49,41919809],
		["Jefferson","SD",42.6,-96.56,41920074],
		["Bland","MO",38.3,-91.63,41920339],
		["North Newton","KS",38.07,-97.34,41920604],
		["Wilmar","AR",33.62,-91.92,41920869],
		["Tyronza","AR",35.49,-90.35,41921134],
		["Centerfield","UT",39.1,-111.79,41921399],
		["Round Rock","AZ",36.53,-109.44,41921664],
		["White Sands Missile Range","NM",32.26,-106.47,41921929],
		["San Diego","CA",32.9,-117.2,41922194],
		["Greenville","CA",40.15,-120.84,41922459],
		["Naalehu","HI",19.07,-155.57,41922724],
		["Blue Hill","ME",44.41,-68.58,41922988],
		["Jeffersonville","NY",41.78,-74.93,41923252],
		["Woodridge","NY",41.71,-74.57,41923516],
		["Erin","NY",42.18,-76.67,41923780],
		["Mineral Point","PA",40.38,-78.82,41924044],
		["Coalport","PA",40.74,-78.53,41924308],
		["Burnham","PA",40.63,-77.56,41924572],
		["Chincoteague Island","VA",37.92,-75.32,41924836],
		["Buffalo","WV",38.61,-81.98,41925100],
		["Inglis","FL",29.03,-82.65,41925364],
		["Vina","AL",34.37,-88.05,41925628],
		["Perdido","AL",31,-87.62,41925892],
		["Hornbeak","TN",36.33,-89.29,41926156],
		["Randolph","MS",34.18,-89.16,41926420],
		["Rushsylvania","OH",40.46,-83.67,41926684],
		["Bradner","OH",41.32,-83.43,41926948],
		["Collins","OH",41.22,-82.47,41927212],
		["Reedsville","OH",39.14,-81.78,41927476],
		["Vanderbilt","MI",45.14,-84.66,41927740],
		["Avoca","IA",41.48,-95.33,41928004],
		["Holcombe","WI",45.25,-91.15,41928268],
		["Winthrop","MN",44.54,-94.36,41928532],
		["Edgerton","MN",43.87,-96.13,41928796],
		["Henning","MN",46.32,-95.44,41929060],
		["Whitewood","SD",44.46,-103.64,41929324],
		["Green Valley","IL",40.4,-89.64,41929588],
		["Wayne City","IL",38.34,-88.58,41929852],
		["Heflin","LA",32.45,-93.26,41930116],
		["Arcadia","OK",35.66,-97.32,41930380],
		["Cement","OK",34.93,-98.13,41930644],
		["Rose","OK",36.22,-95.03,41930908],
		["Savoy","TX",33.59,-96.36,41931172],
		["Ordway","CO",38.22,-103.75,41931436],
		["Red Rock","AZ",32.57,-111.36,41931700],
		["Pala","CA",33.38,-117.07,41931964],
		["Chaplin","CT",41.8,-72.11,41932227],
		["Kingsley","PA",41.76,-75.78,41932490],
		["Hiwassee","VA",36.97,-80.64,41932753],
		["Irwinton","GA",32.81,-83.17,41933016],
		["Deer Lodge","TN",36.21,-84.83,41933279],
		["Hilham","TN",36.4,-85.43,41933542],
		["Jewell","IA",42.31,-93.63,41933805],
		["Malvern","IA",41,-95.58,41934068],
		["Neola","IA",41.45,-95.61,41934331],
		["Helenville","WI",43.01,-88.71,41934594],
		["Wonewoc","WI",43.65,-90.22,41934857],
		["Ringle","WI",44.9,-89.41,41935120],
		["Braceville","IL",41.22,-88.26,41935383],
		["Big Rock","IL",41.76,-88.54,41935646],
		["Lilbourn","MO",36.59,-89.61,41935909],
		["Saint John","KS",38,-98.76,41936172],
		["Roca","NE",40.65,-96.66,41936435],
		["Avery","TX",33.55,-94.77,41936698],
		["Frost","TX",32.07,-96.8,41936961],
		["Chambers","AZ",35.14,-109.57,41937224],
		["Naselle","WA",46.36,-123.75,41937487],
		["Peshastin","WA",47.51,-120.62,41937750],
		["Ulysses","PA",41.9,-77.75,41938012],
		["Dickerson","MD",39.22,-77.42,41938274],
		["Westover","MD",38.12,-75.7,41938536],
		["Eagle Rock","VA",37.63,-79.8,41938798],
		["Providence","NC",36.5,-79.36,41939060],
		["Grandy","NC",36.24,-75.87,41939322],
		["Parkers Lake","KY",36.84,-84.48,41939584],
		["Theresa","WI",43.51,-88.45,41939846],
		["Clara City","MN",44.95,-95.36,41940108],
		["Union","IL",42.23,-88.54,41940370],
		["Colchester","IL",40.42,-90.79,41940632],
		["Udall","KS",37.38,-97.11,41940894],
		["Loon Lake","WA",48.09,-117.58,41941156],
		["New Hampton","NH",43.6,-71.65,41941417],
		["Kingfield","ME",44.95,-70.15,41941678],
		["North Franklin","CT",41.61,-72.14,41941939],
		["Basom","NY",43.08,-78.39,41942200],
		["Gilbert","WV",37.61,-81.86,41942461],
		["Aurora","NC",35.3,-76.78,41942722],
		["Clarendon","NC",34.2,-78.79,41942983],
		["Grady","AL",32.01,-86.11,41943244],
		["Mohawk","TN",36.19,-83.05,41943505],
		["Hamlet","IN",41.37,-86.58,41943766],
		["New Harmony","IN",38.12,-87.93,41944027],
		["Whittemore","MI",44.23,-83.8,41944288],
		["Pickford","MI",46.16,-84.36,41944549],
		["Neshkoro","WI",43.96,-89.21,41944810],
		["Swanville","MN",45.91,-94.63,41945071],
		["Fertile","MN",47.53,-96.29,41945332],
		["Wanblee","SD",43.57,-101.66,41945593],
		["Toulon","IL",41.09,-89.86,41945854],
		["Bement","IL",39.92,-88.57,41946115],
		["Beecher City","IL",39.18,-88.78,41946376],
		["Donaldson","AR",34.2,-92.93,41946637],
		["Chappell Hill","TX",30.19,-96.21,41946898],
		["Leakey","TX",29.72,-99.76,41947159],
		["Frisco","CO",39.57,-106.09,41947420],
		["Kasilof","AK",60.28,-151.26,41947681],
		["Eagle Bridge","NY",42.94,-73.39,41947941],
		["Schenevus","NY",42.54,-74.82,41948201],
		["Yorkville","NY",43.11,-75.27,41948461],
		["Smithton","PA",40.15,-79.74,41948721],
		["Rural Valley","PA",40.79,-79.31,41948981],
		["Blossburg","PA",41.68,-77.06,41949241],
		["Barnardsville","NC",35.77,-82.45,41949501],
		["Gay","GA",33.09,-84.57,41949761],
		["Autaugaville","AL",32.43,-86.65,41950021],
		["Cincinnati","OH",39.09,-84.51,41950281],
		["Butlerville","IN",39.12,-85.49,41950541],
		["Griswold","IA",41.23,-95.13,41950801],
		["Gleason","WI",45.3,-89.5,41951061],
		["Port Edwards","WI",44.34,-89.84,41951321],
		["Salem","SD",43.72,-97.38,41951581],
		["Cavalier","ND",48.79,-97.62,41951841],
		["Elsah","IL",38.95,-90.35,41952101],
		["Tamaroa","IL",38.13,-89.22,41952361],
		["Galatia","IL",37.84,-88.61,41952621],
		["Neligh","NE",42.13,-98.02,41952881],
		["Welch","OK",36.87,-95.09,41953141],
		["Plummer","ID",47.28,-116.88,41953401],
		["Kykotsmovi Village","AZ",35.91,-110.53,41953661],
		["Smith River","CA",41.93,-124.08,41953921],
		["Deer Island","OR",45.94,-122.92,41954181],
		["Neah Bay","WA",48.33,-124.63,41954441],
		["Montague","MA",42.53,-72.53,41954700],
		["Loch Sheldrake","NY",41.77,-74.65,41954959],
		["Redwood","NY",44.32,-75.77,41955218],
		["Fenelton","PA",40.85,-79.75,41955477],
		["Polk","PA",41.36,-79.93,41955736],
		["Beavertown","PA",40.75,-77.16,41955995],
		["Red Hill","PA",40.37,-75.48,41956254],
		["Maurertown","VA",38.98,-78.51,41956513],
		["Danbury","NC",36.4,-80.21,41956772],
		["Jacksonville","FL",30.33,-81.65,41957031],
		["Monteagle","TN",35.24,-85.84,41957290],
		["Brush Creek","TN",36.11,-86.01,41957549],
		["Ray","OH",39.2,-82.68,41957808],
		["Elkton","MI",43.81,-83.18,41958067],
		["Slater","IA",41.88,-93.68,41958326],
		["Brownsville","WI",43.61,-88.49,41958585],
		["Cecil","WI",44.81,-88.44,41958844],
		["Fairfield","MT",47.64,-112.12,41959103],
		["Lampe","MO",36.56,-93.43,41959362],
		["Luxora","AR",35.75,-89.93,41959621],
		["Alpena","AR",36.29,-93.29,41959880],
		["Saint Jo","TX",33.69,-97.52,41960139],
		["Benavides","TX",27.59,-98.4,41960398],
		["Skidmore","TX",28.23,-97.68,41960657],
		["Glendale Luke Afb","AZ",33.53,-112.38,41960916],
		["Mountainair","NM",34.52,-106.24,41961175],
		["Merlin","OR",42.56,-123.56,41961434],
		["Livermore","ME",44.33,-70.24,41961692],
		["Plainfield","VT",44.28,-72.43,41961950],
		["Troy","NY",42.73,-73.67,41962208],
		["Conway","PA",40.66,-80.24,41962466],
		["Clearville","PA",39.91,-78.37,41962724],
		["New Albany","PA",41.6,-76.44,41962982],
		["Spout Spring","VA",37.35,-78.91,41963240],
		["Waverly","AL",32.73,-85.57,41963498],
		["Watauga","TN",36.36,-82.29,41963756],
		["Sharon","TN",36.23,-88.82,41964014],
		["Sacramento","KY",37.41,-87.26,41964272],
		["Big Clifty","KY",37.55,-86.15,41964530],
		["Windfall","IN",40.36,-85.95,41964788],
		["Paris","MI",43.77,-85.5,41965046],
		["Cook","MN",47.85,-92.68,41965304],
		["Okawville","IL",38.43,-89.54,41965562],
		["Wellsville","MO",39.07,-91.56,41965820],
		["Rock Port","MO",40.41,-95.51,41966078],
		["Green Ridge","MO",38.62,-93.4,41966336],
		["Fort Washakie","WY",43,-108.92,41966594],
		["Cascade","ID",44.59,-115.57,41966852],
		["Los Angeles","CA",34.04,-118.24,41967110],
		["Entiat","WA",47.79,-120.44,41967368],
		["Sheffield","MA",42.1,-73.34,41967625],
		["Jersey City","NJ",40.71,-74.06,41967882],
		["West Coxsackie","NY",42.4,-73.81,41968139],
		["Clarksville","PA",39.97,-80.04,41968396],
		["Washington Boro","PA",39.98,-76.43,41968653],
		["Danville","GA",32.6,-83.24,41968910],
		["Cowan","TN",35.16,-86.01,41969167],
		["Aplington","IA",42.58,-92.88,41969424],
		["Eddyville","IA",41.15,-92.62,41969681],
		["Elmwood","WI",44.78,-92.14,41969938],
		["Atwater","MN",45.13,-94.77,41970195],
		["Windsor","IL",39.43,-88.59,41970452],
		["Eolia","MO",39.23,-91.01,41970709],
		["Peabody","KS",38.16,-97.1,41970966],
		["Kiefer","OK",35.94,-96.05,41971223],
		["Washington","TX",30.33,-96.22,41971480],
		["Fowler","CO",38.12,-104.02,41971737],
		["Morrill","ME",44.44,-69.14,41971993],
		["New Eagle","PA",40.2,-79.95,41972249],
		["Fort Loudon","PA",39.94,-77.87,41972505],
		["Dushore","PA",41.52,-76.39,41972761],
		["Doswell","VA",37.86,-77.46,41973017],
		["Springfield","SC",33.49,-81.27,41973273],
		["Mount Croghan","SC",34.76,-80.22,41973529],
		["Newville","AL",31.42,-85.33,41973785],
		["Russellville","OH",38.86,-83.78,41974041],
		["Swayzee","IN",40.5,-85.82,41974297],
		["Williams","IN",38.77,-86.62,41974553],
		["Baxter","IA",41.82,-93.15,41974809],
		["Oakland","IA",41.3,-95.39,41975065],
		["Blue Mounds","WI",43.02,-89.82,41975321],
		["Lebo","KS",38.41,-95.85,41975577],
		["Belleville","KS",39.82,-97.63,41975833],
		["Geronimo","OK",34.48,-98.38,41976089],
		["Mccammon","ID",42.68,-112.2,41976345],
		["Tangent","OR",44.54,-123.08,41976601],
		["Culebra","PR",18.31,-65.3,41976856],
		["Williamsburg","MA",42.4,-72.71,41977111],
		["Damariscotta","ME",44.03,-69.51,41977366],
		["Princeton","ME",45.22,-67.57,41977621],
		["Kinderhook","NY",42.39,-73.7,41977876],
		["Bliss","NY",42.58,-78.24,41978131],
		["Castile","NY",42.63,-78.05,41978386],
		["Fountain","NC",35.67,-77.63,41978641],
		["Crane Hill","AL",34.07,-87.05,41978896],
		["Waterloo","AL",34.91,-88.06,41979151],
		["Arrington","TN",35.86,-86.65,41979406],
		["Bailey","MS",32.48,-88.69,41979661],
		["Mount Eden","KY",38.03,-85.16,41979916],
		["Russells Point","OH",40.46,-83.89,41980171],
		["Midland","OH",39.3,-83.91,41980426],
		["Ashley","IN",41.52,-85.06,41980681],
		["Metamora","IN",39.42,-85.14,41980936],
		["Saginaw","MI",43.41,-83.94,41981191],
		["Coon Rapids","IA",41.87,-94.67,41981446],
		["Greenbush","MN",48.69,-96.18,41981701],
		["Tecumseh","NE",40.37,-96.18,41981956],
		["Wisner","NE",41.98,-96.91,41982211],
		["Elm Creek","NE",40.71,-99.37,41982466],
		["Superior","NE",40.02,-98.06,41982721],
		["Monterey","LA",31.34,-91.66,41982976],
		["Damascus","AR",35.36,-92.4,41983231],
		["Hydro","OK",35.54,-98.57,41983486],
		["Turpin","OK",36.87,-100.88,41983741],
		["New Ulm","TX",29.87,-96.49,41983996],
		["Pingree","ID",43.12,-112.6,41984251],
		["Overgaard","AZ",34.37,-110.48,41984506],
		["Fort Wingate","NM",35.46,-108.54,41984761],
		["Cowiche","WA",46.66,-120.79,41985016],
		["Madbury","NH",43.17,-70.93,41985270],
		["Boothbay","ME",43.87,-69.63,41985524],
		["Gladstone","NJ",40.72,-74.67,41985778],
		["Confluence","PA",39.8,-79.35,41986032],
		["Dewitt","VA",37.03,-77.64,41986286],
		["Adams Run","SC",32.72,-80.34,41986540],
		["Arcadia","FL",27.21,-81.85,41986794],
		["Counce","TN",35.03,-88.29,41987048],
		["Camden","IN",40.61,-86.53,41987302],
		["Benzonia","MI",44.61,-86.09,41987556],
		["Sidney","IA",40.74,-95.64,41987810],
		["Williamsville","IL",39.95,-89.55,41988064],
		["Mulkeytown","IL",37.96,-89.13,41988318],
		["Polo","MO",39.55,-94.04,41988572],
		["Highlandville","MO",36.93,-93.28,41988826],
		["Saint Joseph","LA",31.95,-91.37,41989080],
		["Traskwood","AR",34.45,-92.66,41989334],
		["Proctor","AR",35.08,-90.24,41989588],
		["Fort Cobb","OK",35.1,-98.44,41989842],
		["Salem","NM",32.7,-107.21,41990096],
		["Hinkley","CA",34.95,-117.18,41990350],
		["Craig","AK",55.46,-132.83,41990604],
		["Mapleville","RI",41.97,-71.69,41990857],
		["Holderness","NH",43.73,-71.58,41991110],
		["Plainfield","NH",43.53,-72.35,41991363],
		["Westford","VT",44.62,-73.02,41991616],
		["Proctor","VT",43.65,-73.03,41991869],
		["Grahamsville","NY",41.84,-74.54,41992122],
		["Collins","NY",42.49,-78.86,41992375],
		["Machias","NY",42.38,-78.52,41992628],
		["Hadley","PA",41.43,-80.12,41992881],
		["Hopewell","PA",40.13,-78.26,41993134],
		["Mifflin","PA",40.56,-77.4,41993387],
		["Dublin","PA",40.37,-75.2,41993640],
		["Gum Spring","VA",37.77,-77.89,41993893],
		["Mcmechen","WV",39.98,-80.73,41994146],
		["Flemington","WV",39.26,-80.12,41994399],
		["New Zion","SC",33.77,-80.01,41994652],
		["Davisboro","GA",32.98,-82.6,41994905],
		["Graysville","AL",33.62,-86.96,41995158],
		["Stapleton","AL",30.74,-87.79,41995411],
		["Townsend","TN",35.67,-83.75,41995664],
		["Maceo","KY",37.85,-87,41995917],
		["Ney","OH",41.38,-84.52,41996170],
		["Peninsula","OH",41.23,-81.55,41996423],
		["Kirklin","IN",40.19,-86.35,41996676],
		["Reelsville","IN",39.53,-86.95,41996929],
		["Rockford","IA",43.05,-92.94,41997182],
		["Hopkinton","IA",42.34,-91.24,41997435],
		["Lone Tree","IA",41.48,-91.42,41997688],
		["Adell","WI",43.62,-87.94,41997941],
		["Green Lake","WI",43.84,-88.95,41998194],
		["Miller","SD",44.52,-98.98,41998447],
		["Germantown","IL",38.55,-89.53,41998700],
		["Topeka","KS",39.03,-95.69,41998953],
		["Smith Center","KS",39.77,-98.78,41999206],
		["Palestine","AR",34.97,-90.9,41999459],
		["Fairfax","OK",36.57,-96.7,41999712],
		["Fairplay","CO",39.22,-105.99,41999965],
		["Moyie Springs","ID",48.7,-116.12,42000218],
		["Van Buren","ME",47.16,-67.95,42000470],
		["Saint Albans","ME",44.91,-69.41,42000722],
		["Mooers","NY",44.96,-73.58,42000974],
		["Wyoming","NY",42.82,-78.08,42001226],
		["Strattanville","PA",41.2,-79.32,42001478],
		["Pittsfield","PA",41.81,-79.43,42001730],
		["Gladstone","VA",37.54,-78.84,42001982],
		["Scarbro","WV",37.95,-81.24,42002234],
		["New Ellenton","SC",33.41,-81.68,42002486],
		["Henry","TN",36.2,-88.41,42002738],
		["Kuttawa","KY",37.06,-88.1,42002990],
		["Cumberland","OH",39.85,-81.65,42003242],
		["Lafayette","IN",40.41,-86.87,42003494],
		["Elk Rapids","MI",44.89,-85.4,42003746],
		["Lake City","IA",42.26,-94.73,42003998],
		["Hill City","SD",43.93,-103.57,42004250],
		["Mangham","LA",32.3,-91.78,42004502],
		["Oklahoma City","OK",35.46,-97.51,42004754],
		["S Coffeyville","OK",36.99,-95.62,42005006],
		["Weston","ID",42.05,-112,42005258],
		["Mcnary","AZ",34.06,-109.7,42005510],
		["Wells","NV",41.35,-114.8,42005762],
		["Bodfish","CA",35.52,-118.4,42006014],
		["North Oxford","MA",42.16,-71.88,42006265],
		["Moira","NY",44.86,-74.57,42006516],
		["Oriskany Falls","NY",42.93,-75.46,42006767],
		["Worton","MD",39.29,-76.11,42007018],
		["Gore","VA",39.26,-78.33,42007269],
		["Creswell","NC",35.87,-76.39,42007520],
		["Register","GA",32.36,-81.88,42007771],
		["Uriah","AL",31.3,-87.5,42008022],
		["Falkner","MS",34.84,-88.93,42008273],
		["Berrien Center","MI",41.94,-86.26,42008524],
		["Reeseville","WI",43.3,-88.84,42008775],
		["Coon Valley","WI",43.7,-91.01,42009026],
		["West Concord","MN",44.15,-92.89,42009277],
		["Carlock","IL",40.58,-89.13,42009528],
		["Braymer","MO",39.59,-93.79,42009779],
		["Shelton","NE",40.77,-98.73,42010030],
		["Romance","AR",35.21,-92.06,42010281],
		["Santa Anna","TX",31.73,-99.32,42010532],
		["Dennis Port","MA",41.66,-70.13,42010782],
		["Parsonsfield","ME",43.72,-70.92,42011032],
		["Alburgh","VT",44.97,-73.3,42011282],
		["Crown Point","NY",43.95,-73.53,42011532],
		["Elkland","PA",41.98,-77.31,42011782],
		["Lenhartsville","PA",40.57,-75.89,42012032],
		["Flintstone","MD",39.7,-78.56,42012282],
		["Gloucester Point","VA",37.26,-76.49,42012532],
		["Penhook","VA",36.98,-79.63,42012782],
		["Coal City","WV",37.67,-81.21,42013032],
		["Zionville","NC",36.32,-81.74,42013282],
		["Mc Connells","SC",34.86,-81.22,42013532],
		["Emerson","GA",34.13,-84.73,42013782],
		["Fort Pierce","FL",27.44,-80.32,42014032],
		["Stringer","MS",31.84,-89.26,42014282],
		["Raymond","OH",40.24,-83.36,42014532],
		["Bergholz","OH",40.52,-80.88,42014782],
		["New Washington","OH",40.96,-82.85,42015032],
		["South Salem","OH",39.33,-83.3,42015282],
		["Ashley","MI",43.18,-84.47,42015532],
		["Elkader","IA",42.85,-91.4,42015782],
		["Foreston","MN",45.73,-93.7,42016032],
		["Pinesdale","MT",46.33,-114.22,42016282],
		["Wortham","TX",31.79,-96.46,42016532],
		["Rye","CO",37.92,-104.93,42016782],
		["Springfield","CO",37.4,-102.61,42017032],
		["Silverado","CA",33.75,-117.63,42017282],
		["Tamworth","NH",43.85,-71.26,42017531],
		["Clarksboro","NJ",39.8,-75.22,42017780],
		["Canaan","NY",42.41,-73.41,42018029],
		["Cuddebackville","NY",41.47,-74.63,42018278],
		["Barneveld","NY",43.27,-75.18,42018527],
		["Edmeston","NY",42.69,-75.24,42018776],
		["South Wales","NY",42.7,-78.53,42019025],
		["Karns City","PA",40.99,-79.72,42019274],
		["Clarendon","PA",41.78,-79.09,42019523],
		["Crumpler","NC",36.47,-81.37,42019772],
		["Maggie Valley","NC",35.51,-83.09,42020021],
		["Van Lear","KY",37.76,-82.75,42020270],
		["Metamora","OH",41.71,-83.9,42020519],
		["Milroy","IN",39.49,-85.51,42020768],
		["Waldron","IN",39.48,-85.66,42021017],
		["Birdseye","IN",38.31,-86.69,42021266],
		["Webster","MN",44.5,-93.34,42021515],
		["Grand Meadow","MN",43.7,-92.57,42021764],
		["Astoria","IL",40.22,-90.35,42022013],
		["Grayville","IL",38.25,-87.99,42022262],
		["Arma","KS",37.54,-94.7,42022511],
		["Crofton","NE",42.73,-97.49,42022760],
		["Oklahoma City","OK",35.46,-97.51,42023009],
		["Snowmass Village","CO",39.19,-106.93,42023258],
		["Rozet","WY",44.28,-105.2,42023507],
		["Wendover","UT",40.84,-114.02,42023756],
		["Ferron","UT",39.1,-111.16,42024005],
		["Malin","OR",42.04,-121.42,42024254],
		["Hancock","ME",44.52,-68.25,42024502],
		["Hermon","NY",44.46,-75.23,42024750],
		["Mannsville","NY",43.71,-76.06,42024998],
		["Point Marion","PA",39.73,-79.9,42025246],
		["Blair","SC",34.49,-81.35,42025494],
		["Brunson","SC",32.92,-81.18,42025742],
		["Otisco","IN",38.53,-85.66,42025990],
		["Rosebush","MI",43.69,-84.76,42026238],
		["Inwood","IA",43.28,-96.44,42026486],
		["Sheldon","WI",45.31,-90.95,42026734],
		["Mason","IL",38.95,-88.62,42026982],
		["Curtis","NE",40.63,-100.51,42027230],
		["Morrill","NE",41.96,-103.92,42027478],
		["Gibsland","LA",32.53,-93.05,42027726],
		["Arco","ID",43.65,-113.28,42027974],
		["Chestertown","NY",43.63,-73.8,42028221],
		["Bartonsville","PA",41,-75.29,42028468],
		["Church Hill","MD",39.14,-75.98,42028715],
		["Wardensville","WV",39.07,-78.59,42028962],
		["Lake Toxaway","NC",35.13,-82.93,42029209],
		["Minter","AL",32.1,-86.96,42029456],
		["Jonestown","MS",34.32,-90.45,42029703],
		["Senecaville","OH",39.93,-81.45,42029950],
		["Medaryville","IN",41.08,-86.89,42030197],
		["Maple City","MI",44.84,-85.86,42030444],
		["Gowrie","IA",42.27,-94.28,42030691],
		["Stanley","ND",48.31,-102.38,42030938],
		["Morrisonville","IL",39.42,-89.45,42031185],
		["Bonnerdale","AR",34.34,-93.31,42031432],
		["Lorenzo","TX",33.67,-101.53,42031679],
		["Los Angeles","CA",34.03,-118.24,42031926],
		["Greenfield","NH",42.95,-71.85,42032172],
		["Norfolk","CT",41.99,-73.19,42032418],
		["Belfast","NY",42.31,-78.09,42032664],
		["Paxinos","PA",40.83,-76.64,42032910],
		["Deale","MD",38.79,-76.54,42033156],
		["Lyndhurst","VA",37.96,-78.96,42033402],
		["Hot Springs","NC",35.89,-82.82,42033648],
		["Alford","FL",30.69,-85.39,42033894],
		["Oak Hill","FL",28.86,-80.85,42034140],
		["Glencoe","KY",38.71,-84.82,42034386],
		["Bonnieville","KY",37.37,-85.9,42034632],
		["Roann","IN",40.91,-85.92,42034878],
		["Frederic","MI",44.78,-84.73,42035124],
		["Boyd","WI",44.95,-91.04,42035370],
		["Parker","SD",43.39,-97.13,42035616],
		["Chester","MT",48.56,-111.01,42035862],
		["Chetopa","KS",37.03,-95.09,42036108],
		["Houston","AR",35.03,-92.69,42036354],
		["Windthorst","TX",33.57,-98.43,42036600],
		["Sudan","TX",34.06,-102.52,42036846],
		["Prather","CA",37,-119.5,42037092],
		["Hana","HI",20.77,-155.99,42037338],
		["Sharon","CT",41.87,-73.47,42037583],
		["Union Dale","PA",41.71,-75.49,42037828],
		["Marydel","MD",39.11,-75.74,42038073],
		["Lynch Station","VA",37.14,-79.36,42038318],
		["Bath","NC",35.47,-76.81,42038563],
		["Pinetown","NC",35.6,-76.83,42038808],
		["Grantsboro","NC",35.13,-76.89,42039053],
		["Early Branch","SC",32.74,-80.92,42039298],
		["Atwood","TN",35.97,-88.67,42039543],
		["Little Rock","MS",32.53,-89.18,42039788],
		["Stonewall","MS",32.13,-88.79,42040033],
		["White Plains","KY",37.18,-87.38,42040278],
		["Holton","IN",39.07,-85.38,42040523],
		["Cumming","IA",41.48,-93.76,42040768],
		["Lansing","IA",43.36,-91.22,42041013],
		["Shellsburg","IA",42.09,-91.86,42041258],
		["Gays Mills","WI",43.32,-90.85,42041503],
		["Winnebago","MN",43.76,-94.16,42041748],
		["Starbuck","MN",45.61,-95.53,42041993],
		["Bertha","MN",46.26,-95.06,42042238],
		["Odell","IL",41,-88.52,42042483],
		["Walthill","NE",42.14,-96.49,42042728],
		["Sutton","NE",40.6,-97.85,42042973],
		["Snyder","OK",34.65,-98.95,42043218],
		["Ringwood","OK",36.38,-98.24,42043463],
		["Waelder","TX",29.69,-97.29,42043708],
		["Malta","ID",42.22,-113.29,42043953],
		["Dunsmuir","CA",41.19,-122.26,42044198],
		["Becket","MA",42.33,-73.07,42044442],
		["Gilmanton","NH",43.42,-71.41,42044686],
		["Marlborough","NH",42.9,-72.21,42044930],
		["Washburn","ME",46.79,-68.15,42045174],
		["Stuyvesant","NY",42.38,-73.76,42045418],
		["Smock","PA",39.99,-79.75,42045662],
		["Williamsburg","VA",37.27,-76.7,42045906],
		["Suffolk","VA",36.7,-76.63,42046150],
		["Milligan College","TN",36.26,-82.29,42046394],
		["Gates","TN",35.83,-89.4,42046638],
		["Bardwell","KY",36.87,-89.01,42046882],
		["Mammoth Cave","KY",37.2,-86.1,42047126],
		["Beallsville","OH",39.84,-81.03,42047370],
		["Kensington","OH",40.7,-80.93,42047614],
		["Brewster","OH",40.71,-81.6,42047858],
		["Montezuma","IN",39.79,-87.36,42048102],
		["Johannesburg","MI",44.98,-84.46,42048346],
		["Amana","IA",41.8,-91.86,42048590],
		["Backus","MN",46.82,-94.51,42048834],
		["Griggsville","IL",39.7,-90.72,42049078],
		["Zeigler","IL",37.89,-89.05,42049322],
		["Brumley","MO",38.08,-92.48,42049566],
		["Yates Center","KS",37.87,-95.74,42049810],
		["Pickton","TX",33.02,-95.45,42050054],
		["Nazlini","AZ",35.92,-109.41,42050298],
		["Lone Pine","CA",36.56,-118.18,42050542],
		["Volcano","HI",19.49,-155.23,42050786],
		["Hardwick","MA",42.35,-72.2,42051029],
		["West Paris","ME",44.32,-70.57,42051272],
		["Falls Creek","PA",41.14,-78.8,42051515],
		["Penn Laird","VA",38.36,-78.79,42051758],
		["Duck","WV",38.56,-80.97,42052001],
		["Lesage","WV",38.48,-82.3,42052244],
		["Stapleton","GA",33.21,-82.46,42052487],
		["Kings Bay","GA",30.8,-81.53,42052730],
		["Tampa","FL",27.95,-82.48,42052973],
		["Glidden","IA",42.05,-94.72,42053216],
		["Lyndon Station","WI",43.71,-89.89,42053459],
		["Brussels","WI",44.73,-87.63,42053702],
		["Orr","MN",48.06,-92.82,42053945],
		["Superior","MT",47.05,-114.97,42054188],
		["Ashland","IL",39.88,-90,42054431],
		["Burrton","KS",38.02,-97.67,42054674],
		["Convent","LA",30.02,-90.82,42054917],
		["Wallingford","VT",43.48,-72.97,42055159],
		["Au Sable Forks","NY",44.47,-73.78,42055401],
		["Cherry Valley","NY",42.79,-74.75,42055643],
		["Hemlock","NY",42.77,-77.58,42055885],
		["East Mc Keesport","PA",40.38,-79.8,42056127],
		["Galena","MD",39.34,-75.87,42056369],
		["Woodland","MS",33.78,-89.05,42056611],
		["Wellington","KY",37.91,-83.5,42056853],
		["Stout","OH",38.67,-83.21,42057095],
		["Lynnville","IN",38.19,-87.31,42057337],
		["Pentwater","MI",43.78,-86.43,42057579],
		["Sheffield","IA",42.89,-93.21,42057821],
		["Iron River","WI",46.56,-91.4,42058063],
		["Minot","ND",48.23,-101.29,42058305],
		["Fairview","MT",47.95,-104.21,42058547],
		["Watson","IL",39.02,-88.56,42058789],
		["Wappapello","MO",36.99,-90.27,42059031],
		["Westmoreland","KS",39.39,-96.41,42059273],
		["Woodworth","LA",31.15,-92.5,42059515],
		["Dawson","TX",31.89,-96.71,42059757],
		["Saratoga","WY",41.45,-106.81,42059999],
		["Tetonia","ID",43.85,-111.11,42060241],
		["Touchet","WA",46.12,-118.64,42060483],
		["Strong","ME",44.8,-70.22,42060724],
		["Derby","VT",44.92,-72.12,42060965],
		["Cross River","NY",41.26,-73.59,42061206],
		["De Kalb Junction","NY",44.48,-75.3,42061447],
		["Schellsburg","PA",40.04,-78.64,42061688],
		["Barnesville","PA",40.79,-76.08,42061929],
		["Broad Run","VA",38.81,-77.72,42062170],
		["Oldtown","MD",39.54,-78.61,42062411],
		["Rock Hall","MD",39.14,-76.24,42062652],
		["Sophia","WV",37.7,-81.25,42062893],
		["Rupert","WV",37.96,-80.68,42063134],
		["Milton","NC",36.53,-79.2,42063375],
		["Hosford","FL",30.38,-84.79,42063616],
		["Cowarts","AL",31.19,-85.3,42063857],
		["Fancy Farm","KY",36.75,-88.79,42064098],
		["Pleasant Ridge","MI",42.47,-83.14,42064339],
		["Vandalia","MI",41.91,-85.91,42064580],
		["Thompsonville","MI",44.52,-85.93,42064821],
		["Vulcan","MI",45.78,-87.87,42065062],
		["Star Prairie","WI",45.19,-92.53,42065303],
		["Saint Paul","MN",44.94,-93.1,42065544],
		["Ellendale","MN",43.87,-93.3,42065785],
		["Chicago","IL",41.83,-87.68,42066026],
		["Sidney","IL",40.02,-88.07,42066267],
		["Marine","IL",38.78,-89.77,42066508],
		["Xenia","IL",38.63,-88.63,42066749],
		["Centertown","MO",38.61,-92.4,42066990],
		["Lohman","MO",38.54,-92.36,42067231],
		["Edgar Springs","MO",37.7,-91.86,42067472],
		["Hebron","NE",40.16,-97.58,42067713],
		["Bennington","OK",34,-96.03,42067954],
		["Naples","ID",48.59,-116.4,42068195],
		["Pine Mountain Club","CA",34.84,-119.17,42068436],
		["Yoncalla","OR",43.57,-123.14,42068677],
		["Cove","OR",45.35,-117.84,42068918],
		["Seattle","WA",47.61,-122.33,42069159],
		["Newbury","NH",43.32,-72.03,42069399],
		["Coal Center","PA",40.06,-79.9,42069639],
		["Atlantic","PA",41.53,-80.27,42069879],
		["Cascade","MD",39.7,-77.49,42070119],
		["Arrington","VA",37.68,-78.9,42070359],
		["Saint Stephens Church","VA",37.8,-77.05,42070599],
		["Henry","VA",36.83,-80,42070839],
		["Glasgow","VA",37.63,-79.45,42071079],
		["Ballard","WV",37.51,-80.75,42071319],
		["Langley","SC",33.51,-81.84,42071559],
		["Bloomington Springs","TN",36.22,-85.66,42071799],
		["Jacobsburg","OH",39.95,-80.88,42072039],
		["Laura","OH",39.99,-84.4,42072279],
		["Hope","MI",43.79,-84.32,42072519],
		["Deford","MI",43.51,-83.18,42072759],
		["Burlington","ND",48.27,-101.42,42072999],
		["Toluca","IL",41,-89.13,42073239],
		["Utica","IL",41.38,-88.99,42073479],
		["Macon","IL",39.7,-88.99,42073719],
		["Irondale","MO",37.83,-90.67,42073959],
		["Webbers Falls","OK",35.5,-95.16,42074199],
		["Detroit","TX",33.66,-95.26,42074439],
		["Bivins","TX",32.95,-94.14,42074679],
		["Fayetteville","TX",29.9,-96.67,42074919],
		["Osburn","ID",47.53,-116.01,42075159],
		["Red Valley","AZ",36.61,-109.16,42075399],
		["Three Rivers","CA",36.44,-118.86,42075639],
		["Weldon","CA",35.44,-118.25,42075879],
		["Hoolehua","HI",21.17,-157.07,42076119],
		["Sabillasville","MD",39.7,-77.45,42076358],
		["Hague","VA",38.07,-76.65,42076597],
		["Mill Creek","WV",38.73,-79.97,42076836],
		["Fordsville","KY",37.63,-86.71,42077075],
		["Commercial Point","OH",39.76,-83.05,42077314],
		["Lyons","OH",41.69,-84.07,42077553],
		["Freeport","OH",40.21,-81.26,42077792],
		["Mechanicsville","IA",41.9,-91.25,42078031],
		["Keosauqua","IA",40.73,-91.96,42078270],
		["Maribel","WI",44.27,-87.8,42078509],
		["Rosendale","WI",43.8,-88.67,42078748],
		["Fairfax","MN",44.52,-94.72,42078987],
		["Spring Grove","MN",43.56,-91.63,42079226],
		["Walnut Grove","MN",44.22,-95.46,42079465],
		["Marion","MT",48.11,-114.79,42079704],
		["Rossville","IL",40.38,-87.66,42079943],
		["La Harpe","IL",40.58,-90.97,42080182],
		["Agency","MO",39.65,-94.74,42080421],
		["Oberlin","KS",39.82,-100.53,42080660],
		["Monette","AR",35.89,-90.34,42080899],
		["Panama","OK",35.17,-94.66,42081138],
		["Avinger","TX",32.89,-94.55,42081377],
		["Kennard","TX",31.35,-95.18,42081616],
		["Rising Star","TX",32.09,-98.96,42081855],
		["North Zulch","TX",30.93,-96.08,42082094],
		["Medina","TX",29.8,-99.25,42082333],
		["Dennehotso","AZ",36.8,-109.84,42082572],
		["Sanostee","NM",36.43,-108.87,42082811],
		["Portland","OR",45.54,-122.67,42083050],
		["Halsey","OR",44.38,-123.11,42083289],
		["Hooper Bay","AK",61.6,-165.74,42083528],
		["Francestown","NH",42.98,-71.8,42083766],
		["Helmetta","NJ",40.37,-74.42,42084004],
		["Poestenkill","NY",42.69,-73.56,42084242],
		["Grindstone","PA",40.01,-79.83,42084480],
		["Raiford","FL",30.06,-82.23,42084718],
		["Ethelsville","AL",33.41,-88.21,42084956],
		["Vanleer","TN",36.23,-87.44,42085194],
		["Brooklyn","MS",30.98,-89.23,42085432],
		["Brooks","KY",38.06,-85.71,42085670],
		["Quincy","OH",40.29,-83.96,42085908],
		["Oakley","MI",43.14,-84.16,42086146],
		["Ceresco","MI",42.27,-85.06,42086384],
		["Elkville","IL",37.9,-89.23,42086622],
		["Holcomb","MO",36.4,-90.02,42086860],
		["Rayville","MO",39.34,-94.06,42087098],
		["Randolph","NE",42.37,-97.35,42087336],
		["Stephens","AR",33.42,-93.06,42087574],
		["Wright City","OK",34.06,-95,42087812],
		["Earth","TX",34.23,-102.4,42088050],
		["Colorado City","CO",37.94,-104.84,42088288],
		["Hotevilla","AZ",36.07,-110.58,42088526],
		["Peach Springs","AZ",35.64,-113.61,42088764],
		["Coulterville","CA",37.71,-120.12,42089002],
		["Pepeekeo","HI",19.83,-155.1,42089240],
		["Valleyford","WA",47.53,-117.26,42089478],
		["Phippsburg","ME",43.82,-69.81,42089715],
		["North Anson","ME",44.95,-69.94,42089952],
		["Kent","CT",41.72,-73.47,42090189],
		["Fort Monmouth","NJ",40.31,-74.03,42090426],
		["Appleton","NY",43.31,-78.63,42090663],
		["Great Valley","NY",42.21,-78.59,42090900],
		["Grantsville","WV",38.92,-81.09,42091137],
		["Midville","GA",32.82,-82.23,42091374],
		["Camden","MS",32.8,-89.87,42091611],
		["Dunnville","KY",37.2,-85,42091848],
		["Salesville","OH",39.97,-81.33,42092085],
		["Freetown","IN",39,-86.12,42092322],
		["Milo","IA",41.28,-93.43,42092559],
		["Plentywood","MT",48.72,-104.57,42092796],
		["Bradford","IL",41.17,-89.65,42093033],
		["Valmeyer","IL",38.3,-90.31,42093270],
		["Warrensburg","IL",39.93,-89.06,42093507],
		["Stella","MO",36.76,-94.19,42093744],
		["Saint Francis","KS",39.77,-101.8,42093981],
		["Lincoln","NE",40.81,-96.68,42094218],
		["Boswell","OK",34.02,-95.86,42094455],
		["Bon Wier","TX",30.68,-93.75,42094692],
		["La Coste","TX",29.3,-98.81,42094929],
		["Sundance","WY",44.4,-104.37,42095166],
		["Oakley","UT",40.81,-111.11,42095403],
		["Five Points","CA",36.38,-120.13,42095640],
		["Hiram","ME",43.87,-70.8,42095876],
		["Morris","NY",42.54,-75.24,42096112],
		["Burt","NY",43.31,-78.71,42096348],
		["Big Flats","NY",42.13,-76.93,42096584],
		["Springville","PA",41.69,-75.91,42096820],
		["White Post","VA",39.05,-78.1,42097056],
		["Cascade","VA",36.56,-79.66,42097292],
		["Cameron","SC",33.55,-80.71,42097528],
		["Monetta","SC",33.84,-81.61,42097764],
		["Smithville","GA",31.9,-84.25,42098000],
		["Gretna","FL",30.61,-84.66,42098236],
		["Wildersville","TN",35.78,-88.36,42098472],
		["Mulberry","IN",40.34,-86.66,42098708],
		["Heltonville","IN",38.97,-86.36,42098944],
		["Holstein","IA",42.48,-95.54,42099180],
		["Cochrane","WI",44.22,-91.83,42099416],
		["Manderson","SD",43.23,-102.47,42099652],
		["Groveland","IL",40.61,-89.52,42099888],
		["Tilton","IL",40.09,-87.63,42100124],
		["Bartelso","IL",38.53,-89.46,42100360],
		["Pilot Grove","MO",38.87,-92.91,42100596],
		["Plainview","NE",42.35,-97.78,42100832],
		["Melville","LA",30.69,-91.74,42101068],
		["Okeene","OK",36.11,-98.31,42101304],
		["Blessing","TX",28.83,-96.23,42101540],
		["Marquez","TX",31.23,-96.25,42101776],
		["Trinidad","CA",41.13,-124.04,42102012],
		["Bennington","NH",43,-71.93,42102247],
		["Amawalk","NY",41.29,-73.76,42102482],
		["Woodbury","PA",40.22,-78.36,42102717],
		["Mc Henry","MD",39.55,-79.35,42102952],
		["Rawlings","MD",39.53,-78.88,42103187],
		["New Canton","VA",37.7,-78.3,42103422],
		["Ranger","WV",38.13,-82.19,42103657],
		["Sandy Ridge","NC",36.5,-80.11,42103892],
		["Bradley","SC",34.04,-82.21,42104127],
		["Courtland","AL",34.66,-87.31,42104362],
		["Coalmont","TN",35.33,-85.71,42104597],
		["Delano","TN",35.25,-84.62,42104832],
		["Vaiden","MS",33.33,-89.75,42105067],
		["Hardyville","KY",37.25,-85.8,42105302],
		["Edison","OH",40.55,-82.86,42105537],
		["Martinsville","OH",39.32,-83.81,42105772],
		["Detroit","MI",42.38,-83.1,42106007],
		["Good Thunder","MN",44,-94.07,42106242],
		["Underwood","MN",46.28,-95.87,42106477],
		["Belt","MT",47.26,-110.87,42106712],
		["Franklin Grove","IL",41.84,-89.3,42106947],
		["Hampton","IL",41.55,-90.4,42107182],
		["Payson","IL",39.81,-91.24,42107417],
		["Scranton","KS",38.77,-95.74,42107652],
		["Wymore","NE",40.12,-96.66,42107887],
		["Creighton","NE",42.46,-97.9,42108122],
		["Eureka Springs","AR",36.39,-93.73,42108357],
		["Eden","TX",31.21,-99.84,42108592],
		["Anton","TX",33.81,-102.16,42108827],
		["Cripple Creek","CO",38.74,-105.17,42109062],
		["Genesee","ID",46.56,-116.88,42109297],
		["Fort Sumner","NM",34.47,-104.23,42109532],
		["Boonville","CA",38.99,-123.36,42109767],
		["Cobb","CA",38.83,-122.71,42110002],
		["Cloverdale","OR",45.22,-123.92,42110237],
		["Saint Paul","OR",45.2,-122.96,42110472],
		["Atkins","VA",36.86,-81.39,42110706],
		["Wheeler","MI",43.41,-84.43,42110940],
		["Lake Leelanau","MI",44.97,-85.73,42111174],
		["Marcus","IA",42.82,-95.8,42111408],
		["Elbow Lake","MN",45.99,-95.97,42111642],
		["Linton","ND",46.26,-100.23,42111876],
		["Bonfield","IL",41.14,-88.05,42112110],
		["Warren","IL",42.49,-89.99,42112344],
		["West Salem","IL",38.52,-88,42112578],
		["Tower Hill","IL",39.38,-88.95,42112812],
		["Montezuma","KS",37.59,-100.44,42113046],
		["Spearsville","LA",32.93,-92.6,42113280],
		["Wickes","AR",34.29,-94.33,42113514],
		["State University","AR",35.9,-90.45,42113748],
		["Hart","TX",34.38,-102.11,42113982],
		["Fountain Green","UT",39.64,-111.61,42114216],
		["Stanfield","AZ",32.93,-111.92,42114450],
		["Merrill","OR",42.04,-121.55,42114684],
		["Quilcene","WA",47.82,-122.84,42114918],
		["Conway","MA",42.51,-72.68,42115151],
		["Dennis","MA",41.73,-70.2,42115384],
		["Hagaman","NY",42.97,-74.15,42115617],
		["Pennsylvania Furnace","PA",40.72,-77.98,42115850],
		["Albright","WV",39.49,-79.63,42116083],
		["Dawson","AL",34.35,-85.92,42116316],
		["Geraldine","AL",34.36,-86,42116549],
		["Goshen","AL",31.71,-86.12,42116782],
		["Cumberland City","TN",36.38,-87.64,42117015],
		["Del Rio","TN",35.91,-83.02,42117248],
		["Shellman","GA",31.73,-84.6,42117481],
		["Foster","KY",38.79,-84.21,42117714],
		["Millfield","OH",39.41,-82.11,42117947],
		["Yoder","IN",40.93,-85.2,42118180],
		["Afton","IA",41.02,-94.19,42118413],
		["Hedrick","IA",41.17,-92.3,42118646],
		["Ettrick","WI",44.16,-91.26,42118879],
		["Coloma","WI",44.03,-89.51,42119112],
		["Freeman","MO",38.61,-94.5,42119345],
		["Oil City","LA",32.74,-93.97,42119578],
		["Smithville","AR",36.08,-91.3,42119811],
		["Fruitvale","TX",32.68,-95.8,42120044],
		["Riviera","TX",27.28,-97.81,42120277],
		["Salton City","CA",33.28,-115.92,42120510],
		["Mokelumne Hill","CA",38.29,-120.58,42120743],
		["Thornton","CA",38.17,-121.52,42120976],
		["Guaynabo","PR",18.38,-66.11,42121208],
		["Maryland","NY",42.53,-74.88,42121440],
		["Pattersonville","NY",42.84,-74.13,42121672],
		["Cassville","NY",42.94,-75.25,42121904],
		["Sopchoppy","FL",30.05,-84.48,42122136],
		["Brierfield","AL",33.06,-86.98,42122368],
		["Marietta","MS",34.5,-88.47,42122600],
		["Kimper","KY",37.49,-82.34,42122832],
		["Mc Clure","OH",41.37,-83.94,42123064],
		["Larwill","IN",41.17,-85.62,42123296],
		["Sears","MI",43.89,-85.18,42123528],
		["Randall","MN",46.08,-94.49,42123760],
		["Warsaw","IL",40.35,-91.42,42123992],
		["Shawneetown","IL",37.71,-88.16,42124224],
		["Trimble","MO",39.47,-94.56,42124456],
		["Sedan","KS",37.12,-96.18,42124688],
		["Robert","LA",30.5,-90.34,42124920],
		["Trona","CA",35.73,-117.36,42125152],
		["Harrah","WA",46.4,-120.64,42125384],
		["Moretown","VT",44.25,-72.75,42125615],
		["North Troy","VT",44.99,-72.4,42125846],
		["Lakeville","CT",41.94,-73.43,42126077],
		["East Nassau","NY",42.53,-73.5,42126308],
		["Ellenburg Depot","NY",44.9,-73.8,42126539],
		["Van Etten","NY",42.19,-76.55,42126770],
		["Herndon","PA",40.71,-76.85,42127001],
		["Bracey","VA",36.59,-78.14,42127232],
		["Mc Intyre","GA",32.84,-83.19,42127463],
		["Upatoi","GA",32.56,-84.74,42127694],
		["Astor","FL",29.14,-81.53,42127925],
		["Birmingham","AL",33.52,-86.79,42128156],
		["Clifton","TN",35.38,-87.99,42128387],
		["Rickman","TN",36.3,-85.38,42128618],
		["Lyon","MS",34.21,-90.54,42128849],
		["Montville","OH",41.6,-81.05,42129080],
		["Grandview","IN",37.93,-86.98,42129311],
		["Woodland","MI",42.72,-85.13,42129542],
		["Cassville","WI",42.71,-90.99,42129773],
		["Fort Ripley","MN",46.16,-94.36,42130004],
		["Clark","SD",44.88,-97.73,42130235],
		["Parmelee","SD",43.32,-101.03,42130466],
		["Park River","ND",48.39,-97.74,42130697],
		["New Salem","ND",46.84,-101.41,42130928],
		["Bennington","KS",39.03,-97.59,42131159],
		["Pleasant Plains","AR",35.55,-91.62,42131390],
		["Roff","OK",34.62,-96.84,42131621],
		["Odonnell","TX",33,-101.82,42131852],
		["Carefree","AZ",33.82,-111.9,42132083],
		["Grand Canyon","AZ",35.89,-112.27,42132314],
		["Charleston","ME",45.08,-69.04,42132544],
		["Franklin","VT",44.98,-72.92,42132774],
		["Danville","VT",44.42,-72.13,42133004],
		["Philmont","NY",42.24,-73.64,42133234],
		["Hurleyville","NY",41.73,-74.67,42133464],
		["Roscoe","NY",41.93,-74.91,42133694],
		["Chaffee","NY",42.56,-78.5,42133924],
		["Buckingham","VA",37.55,-78.55,42134154],
		["Farmington","WV",39.51,-80.25,42134384],
		["Goldston","NC",35.59,-79.32,42134614],
		["Dowelltown","TN",36.01,-85.94,42134844],
		["Chattanooga","TN",35.06,-85.25,42135074],
		["Weir","MS",33.26,-89.28,42135304],
		["Cutler","OH",39.4,-81.77,42135534],
		["Commiskey","IN",38.85,-85.63,42135764],
		["Skandia","MI",46.38,-87.23,42135994],
		["Gregory","SD",43.23,-99.42,42136224],
		["Benld","IL",39.09,-89.8,42136454],
		["Meredosia","IL",39.83,-90.55,42136684],
		["Kinmundy","IL",38.77,-88.84,42136914],
		["Maynard","AR",36.42,-90.9,42137144],
		["Oklahoma City","OK",35.46,-97.51,42137374],
		["Maud","OK",35.13,-96.77,42137604],
		["Talco","TX",33.36,-95.1,42137834],
		["Milano","TX",30.7,-96.85,42138064],
		["Sanford","CO",37.25,-105.9,42138294],
		["Pine Bluffs","WY",41.18,-104.06,42138524],
		["Spring City","UT",39.44,-111.45,42138754],
		["Toquerville","UT",37.26,-113.27,42138984],
		["Ash Fork","AZ",35.13,-112.52,42139214],
		["Flora Vista","NM",36.8,-108.08,42139444],
		["Cape Neddick","ME",43.17,-70.61,42139673],
		["Thorndike","ME",44.57,-69.27,42139902],
		["Clintondale","NY",41.69,-74.04,42140131],
		["Mahaffey","PA",40.87,-78.72,42140360],
		["Spring Run","PA",40.15,-77.71,42140589],
		["Draper","VA",37,-80.76,42140818],
		["Clio","AL",31.7,-85.61,42141047],
		["Dozier","AL",31.5,-86.37,42141276],
		["Normandy","TN",35.45,-86.25,42141505],
		["Earlington","KY",37.27,-87.51,42141734],
		["Greens Fork","IN",39.89,-85.04,42141963],
		["Spiceland","IN",39.83,-85.43,42142192],
		["Hickory Corners","MI",42.44,-85.37,42142421],
		["New Virginia","IA",41.18,-93.73,42142650],
		["Busby","MT",45.44,-107.04,42142879],
		["Elizabeth","IL",42.31,-90.22,42143108],
		["Nickerson","KS",38.14,-98.08,42143337],
		["Friend","NE",40.65,-97.28,42143566],
		["North Bend","NE",41.46,-96.78,42143795],
		["Paden","OK",35.5,-96.56,42144024],
		["Calvert","TX",30.97,-96.67,42144253],
		["Claude","TX",35.1,-101.36,42144482],
		["Aurora","CO",39.69,-104.81,42144711],
		["Avondale","CO",38.23,-104.35,42144940],
		["Columbia City","OR",45.89,-122.81,42145169],
		["Newport","RI",41.52,-71.3,42145397],
		["Cornwall Bridge","CT",41.81,-73.37,42145625],
		["Albany","NY",42.66,-73.79,42145853],
		["Willsboro","NY",44.37,-73.4,42146081],
		["Glenfield","NY",43.72,-75.26,42146309],
		["Lindley","NY",42.02,-77.14,42146537],
		["Petrolia","PA",41.02,-79.71,42146765],
		["Leasburg","NC",36.4,-79.16,42146993],
		["Sunbury","NC",36.44,-76.6,42147221],
		["Stella","NC",34.77,-77.12,42147449],
		["Lowndesboro","AL",32.27,-86.61,42147677],
		["Perryville","KY",37.64,-84.95,42147905],
		["Cawood","KY",36.74,-83.26,42148133],
		["Chandlersville","OH",39.86,-81.8,42148361],
		["Dugger","IN",39.06,-87.26,42148589],
		["Earlville","IA",42.48,-91.26,42148817],
		["Winfield","IA",41.12,-91.43,42149045],
		["Ontario","WI",43.72,-90.59,42149273],
		["Grove City","MN",45.15,-94.68,42149501],
		["Merrifield","MN",46.46,-94.17,42149729],
		["Flat Rock","IL",38.9,-87.67,42149957],
		["Tarkio","MO",40.44,-95.38,42150185],
		["Galva","KS",38.38,-97.53,42150413],
		["Oakland","NE",41.83,-96.46,42150641],
		["Battle Creek","NE",41.99,-97.59,42150869],
		["Morrison","OK",36.29,-97.01,42151097],
		["Cedar Valley","UT",40.33,-112.11,42151325],
		["Hickman","CA",37.6,-120.68,42151553],
		["Smartsville","CA",39.17,-121.29,42151781],
		["Welches","OR",45.3,-121.95,42152009],
		["Houston","AK",61.66,-149.68,42152237],
		["Greenbush","ME",45.08,-68.59,42152464],
		["Altona","NY",44.89,-73.65,42152691],
		["Hooversville","PA",40.15,-78.91,42152918],
		["Bessemer","PA",40.97,-80.48,42153145],
		["Girardville","PA",40.79,-76.28,42153372],
		["Lewiston Woodville","NC",36.11,-77.17,42153599],
		["Dorset","OH",41.65,-80.66,42153826],
		["Cecil","OH",41.21,-84.6,42154053],
		["Peck","MI",43.25,-82.81,42154280],
		["Larchwood","IA",43.45,-96.43,42154507],
		["Preston","IA",42.04,-90.39,42154734],
		["Kendall","WI",43.79,-90.36,42154961],
		["Appleton","MN",45.2,-96.02,42155188],
		["Lemmon","SD",45.93,-102.15,42155415],
		["La Moille","IL",41.53,-89.28,42155642],
		["Mulberry Grove","IL",38.92,-89.26,42155869],
		["Cisne","IL",38.51,-88.43,42156096],
		["Qulin","MO",36.59,-90.24,42156323],
		["Wakefield","KS",39.21,-97.01,42156550],
		["Stromsburg","NE",41.11,-97.59,42156777],
		["Shongaloo","LA",32.94,-93.29,42157004],
		["Cherokee","OK",36.75,-98.35,42157231],
		["Amarillo","TX",35.2,-101.81,42157458],
		["Lusk","WY",42.76,-104.45,42157685],
		["Lyme","NH",43.8,-72.15,42157911],
		["Southwest Harbor","ME",44.27,-68.33,42158137],
		["Wilmington","VT",42.86,-72.87,42158363],
		["Paterson","NJ",40.91,-74.16,42158589],
		["Pedricktown","NJ",39.73,-75.4,42158815],
		["Great River","NY",40.71,-73.16,42159041],
		["Durhamville","NY",43.12,-75.67,42159267],
		["Leicester","NY",42.77,-77.89,42159493],
		["Summerville","PA",41.11,-79.18,42159719],
		["Pomfret","MD",38.57,-77.01,42159945],
		["Jetersville","VA",37.29,-78.09,42160171],
		["Sugar Grove","NC",36.26,-81.78,42160397],
		["Green Sea","SC",34.12,-78.89,42160623],
		["Goodspring","TN",35.12,-87.14,42160849],
		["Rawson","OH",40.95,-83.78,42161075],
		["Fontana","WI",42.54,-88.55,42161301],
		["Bear Creek","WI",44.53,-88.72,42161527],
		["Taylors Falls","MN",45.41,-92.66,42161753],
		["Adams","MN",43.56,-92.71,42161979],
		["Clarence","MO",39.74,-92.26,42162205],
		["Williamsville","MO",36.97,-90.54,42162431],
		["Leeton","MO",38.58,-93.69,42162657],
		["Tunas","MO",37.84,-93.02,42162883],
		["Weeping Water","NE",40.86,-96.13,42163109],
		["Springfield","AR",35.27,-92.53,42163335],
		["Menard","TX",30.91,-99.78,42163561],
		["Firestone","CO",40.12,-104.93,42163787],
		["Terreton","ID",43.86,-112.38,42164013],
		["Cedar Glen","CA",34.25,-117.16,42164239],
		["Cayucos","CA",35.48,-120.92,42164465],
		["Geyserville","CA",38.74,-123.13,42164691],
		["Morton","WA",46.57,-122.33,42164917],
		["Nespelem","WA",48.21,-119.04,42165143],
		["Cambridge","VT",44.63,-72.88,42165368],
		["North Clarendon","VT",43.53,-72.96,42165593],
		["Drumore","PA",39.83,-76.25,42165818],
		["Laurys Station","PA",40.72,-75.53,42166043],
		["Sunderland","MD",38.64,-76.57,42166268],
		["Toms Brook","VA",38.94,-78.43,42166493],
		["Thurmond","NC",36.36,-80.94,42166718],
		["Jack","AL",31.57,-85.99,42166943],
		["Berea","KY",37.57,-84.29,42167168],
		["Worthville","KY",38.6,-85.06,42167393],
		["Kingsford Heights","IN",41.48,-86.69,42167618],
		["New Haven","MI",42.73,-82.79,42167843],
		["Conrad","IA",42.22,-92.87,42168068],
		["Winthrop","IA",42.47,-91.73,42168293],
		["Dallas City","IL",40.63,-91.16,42168518],
		["Fowler","IL",40,-91.25,42168743],
		["Annapolis","MO",37.35,-90.69,42168968],
		["Hineston","LA",31.14,-92.75,42169193],
		["Cheyenne","OK",35.61,-99.67,42169418],
		["Clayton","OK",34.58,-95.35,42169643],
		["Mc Dade","TX",30.3,-97.22,42169868],
		["Santa Clara","NM",32.79,-108.16,42170093],
		["Niland","CA",33.3,-115.55,42170318],
		["Crows Landing","CA",37.39,-121.05,42170543],
		["Colrain","MA",42.66,-72.68,42170767],
		["Gilmanton Iron Works","NH",43.43,-71.34,42170991],
		["Orland","ME",44.57,-68.73,42171215],
		["Freedom","ME",44.53,-69.29,42171439],
		["New Haven","VT",44.12,-73.15,42171663],
		["Cressona","PA",40.63,-76.19,42171887],
		["Summit Point","WV",39.24,-77.95,42172111],
		["Glenwood","WV",38.54,-82.14,42172335],
		["Mc Caysville","GA",34.98,-84.37,42172559],
		["Plantersville","AL",32.61,-86.98,42172783],
		["Pendleton","KY",38.48,-85.32,42173007],
		["Kaleva","MI",44.37,-86.01,42173231],
		["Boyne Falls","MI",45.16,-84.91,42173455],
		["North English","IA",41.51,-92.07,42173679],
		["Unity","WI",44.85,-90.3,42173903],
		["Danbury","WI",46,-92.37,42174127],
		["Shabbona","IL",41.76,-88.87,42174351],
		["Cissna Park","IL",40.56,-87.89,42174575],
		["Marquand","MO",37.42,-90.16,42174799],
		["Essex","MO",36.81,-89.86,42175023],
		["Grady","AR",34.07,-91.69,42175247],
		["Caraway","AR",35.75,-90.33,42175471],
		["Scranton","AR",35.36,-93.53,42175695],
		["Roberts","ID",43.73,-112.26,42175919],
		["Rhododendron","OR",45.36,-121.85,42176143],
		["Williams","OR",42.14,-123.32,42176367],
		["East Springfield","PA",41.98,-80.44,42176590],
		["Dunnsville","VA",37.85,-76.82,42176813],
		["Florahome","FL",29.73,-81.88,42177036],
		["Higdon","AL",34.88,-85.63,42177259],
		["Sardis","OH",39.66,-80.95,42177482],
		["Nova","OH",41.02,-82.35,42177705],
		["Bennington","IN",38.87,-85.07,42177928],
		["Hardinsburg","IN",38.46,-86.27,42178151],
		["Pocahontas","IA",42.73,-94.66,42178374],
		["Wabeno","WI",45.43,-88.66,42178597],
		["Kindred","ND",46.65,-97.02,42178820],
		["Noble","IL",38.69,-88.22,42179043],
		["Wellington","MO",39.13,-93.98,42179266],
		["Onaga","KS",39.48,-96.17,42179489],
		["Weir","KS",37.3,-94.77,42179712],
		["Ceresco","NE",41.05,-96.64,42179935],
		["Fullerton","NE",41.36,-97.97,42180158],
		["Evening Shade","AR",36.07,-91.62,42180381],
		["South Padre Island","TX",26.41,-97.23,42180604],
		["Kingsbury","TX",29.65,-97.81,42180827],
		["Sheep Springs","NM",36.14,-108.7,42181050],
		["Santa Cruz","NM",35.98,-106.01,42181273],
		["Wellington","NV",38.7,-119.32,42181496],
		["Ross","CA",37.96,-122.55,42181719],
		["Springdale","WA",48.02,-117.85,42181942],
		["Perry","ME",44.97,-67.07,42182164],
		["Vernon Center","NY",43.05,-75.5,42182386],
		["Gainesville","NY",42.64,-78.13,42182608],
		["Burdett","NY",42.41,-76.84,42182830],
		["Evans","WV",38.8,-81.8,42183052],
		["Whitman","WV",37.81,-82.03,42183274],
		["Rockford","AL",32.88,-86.21,42183496],
		["Mc Kenzie","AL",31.54,-86.71,42183718],
		["Sidon","MS",33.4,-90.2,42183940],
		["Busy","KY",37.25,-83.3,42184162],
		["Cygnet","OH",41.24,-83.64,42184384],
		["East Rochester","OH",40.76,-81.01,42184606],
		["Newport","OH",39.4,-81.26,42184828],
		["Wallace","MI",45.35,-87.61,42185050],
		["Merrill","IA",42.72,-96.24,42185272],
		["Neosho","WI",43.3,-88.51,42185494],
		["Rudolph","WI",44.49,-89.8,42185716],
		["Birchwood","WI",45.65,-91.55,42185938],
		["Ponemah","MN",48.04,-94.9,42186160],
		["Osborne","KS",39.43,-98.69,42186382],
		["Valparaiso","NE",41.07,-96.83,42186604],
		["Osmond","NE",42.35,-97.59,42186826],
		["Iona","ID",43.52,-111.92,42187048],
		["Jackpot","NV",41.85,-114.75,42187270],
		["Garberville","CA",40.22,-123.97,42187492],
		["West Enfield","ME",45.26,-68.58,42187713],
		["Herminie","PA",40.23,-79.71,42187934],
		["Immaculata","PA",40.04,-75.52,42188155],
		["Sullivans Island","SC",32.76,-79.83,42188376],
		["Wildwood","GA",34.96,-85.41,42188597],
		["Hollow Rock","TN",36.03,-88.27,42188818],
		["Battletown","KY",38.06,-86.3,42189039],
		["Falls Of Rough","KY",37.6,-86.55,42189260],
		["Zanesfield","OH",40.33,-83.67,42189481],
		["Lakeville","OH",40.63,-82.15,42189702],
		["Patoka","IN",38.4,-87.58,42189923],
		["Fairgrove","MI",43.52,-83.54,42190144],
		["Jones","MI",41.91,-85.81,42190365],
		["Sawyer","MI",41.86,-86.55,42190586],
		["Casnovia","MI",43.23,-85.79,42190807],
		["Dunbar","WI",45.65,-88.18,42191028],
		["Alden","MN",43.67,-93.57,42191249],
		["Sherburn","MN",43.65,-94.72,42191470],
		["Brooten","MN",45.5,-95.11,42191691],
		["Cascade","MT",47.16,-111.63,42191912],
		["Hopedale","IL",40.42,-89.41,42192133],
		["Rocheport","MO",38.97,-92.56,42192354],
		["Iraan","TX",30.91,-101.89,42192575],
		["Round Mountain","NV",38.79,-117.11,42192796],
		["Reno","NV",39.52,-119.81,42193017],
		["Ahwahnee","CA",37.39,-119.73,42193238],
		["Yachats","OR",44.32,-124,42193459],
		["Dorothy","NJ",39.4,-74.82,42193679],
		["New York","NY",40.71,-73.99,42193899],
		["Creekside","PA",40.68,-79.19,42194119],
		["Falls","PA",41.46,-75.84,42194339],
		["Roseland","VA",37.74,-78.97,42194559],
		["Sutherlin","VA",36.62,-79.18,42194779],
		["Iaeger","WV",37.46,-81.81,42194999],
		["Montrose","WV",39.06,-79.81,42195219],
		["Nesmith","SC",33.65,-79.51,42195439],
		["Dixons Mills","AL",32.05,-87.72,42195659],
		["Banner","KY",37.58,-82.7,42195879],
		["Willow Wood","OH",38.59,-82.45,42196099],
		["Gladbrook","IA",42.18,-92.71,42196319],
		["Newton","WI",43.99,-87.73,42196539],
		["Fairchild","WI",44.6,-90.95,42196759],
		["Dundas","MN",44.42,-93.2,42196979],
		["Hillman","MN",46,-93.88,42197199],
		["Velva","ND",48.05,-100.93,42197419],
		["Dawson","IL",39.85,-89.46,42197639],
		["Ashley","IL",38.32,-89.18,42197859],
		["Cairo","MO",39.51,-92.44,42198079],
		["Milford","TX",32.12,-96.94,42198299],
		["Corinne","UT",41.46,-112.47,42198519],
		["Hawi","HI",20.24,-155.83,42198739],
		["Leverett","MA",42.45,-72.5,42198958],
		["West Warren","MA",42.2,-72.2,42199177],
		["De Ruyter","NY",42.75,-75.86,42199396],
		["Bulger","PA",40.42,-80.35,42199615],
		["West Springfield","PA",41.94,-80.47,42199834],
		["Houston","DE",38.91,-75.5,42200053],
		["Eden","MD",38.28,-75.65,42200272],
		["Marion Station","MD",38.01,-75.73,42200491],
		["Farnham","VA",37.88,-76.62,42200710],
		["Ansted","WV",38.13,-81.1,42200929],
		["Semora","NC",36.49,-79.14,42201148],
		["Peterman","AL",31.58,-87.25,42201367],
		["Nashville","TN",36.17,-86.78,42201586],
		["Thaxton","MS",34.3,-89.17,42201805],
		["Cedarbluff","MS",33.61,-88.84,42202024],
		["Sharpsburg","KY",38.2,-83.92,42202243],
		["Dunkirk","OH",40.78,-83.64,42202462],
		["Laotto","IN",41.3,-85.2,42202681],
		["West Baden Springs","IN",38.56,-86.61,42202900],
		["Tennyson","IN",38.08,-87.11,42203119],
		["Westpoint","IN",40.36,-87.06,42203338],
		["Burlington","MI",42.1,-85.07,42203557],
		["Ossian","IA",43.14,-91.76,42203776],
		["Tiskilwa","IL",41.29,-89.5,42203995],
		["Kincaid","IL",39.58,-89.41,42204214],
		["Mounds","IL",37.11,-89.2,42204433],
		["King City","MO",40.05,-94.52,42204652],
		["Easton","KS",39.34,-95.11,42204871],
		["Milford","KS",39.16,-96.91,42205090],
		["Dubberly","LA",32.53,-93.23,42205309],
		["Munday","TX",33.44,-99.62,42205528],
		["Buckholts","TX",30.87,-97.12,42205747],
		["Christoval","TX",31.19,-100.49,42205966],
		["Batesville","TX",28.94,-99.61,42206185],
		["Fort Mcdowell","AZ",33.75,-111.57,42206404],
		["Tahoe City","CA",39.18,-120.14,42206623],
		["Springfield","MA",42.11,-72.53,42206841],
		["Palisades","NY",41,-73.91,42207059],
		["Rossiter","PA",40.86,-78.94,42207277],
		["Snow Shoe","PA",41.02,-77.95,42207495],
		["Port Carbon","PA",40.69,-76.16,42207713],
		["Mehoopany","PA",41.56,-76.06,42207931],
		["Fulks Run","VA",38.63,-78.98,42208149],
		["Northfork","WV",37.41,-81.42,42208367],
		["Newell","WV",40.61,-80.6,42208585],
		["New Hill","NC",35.68,-78.9,42208803],
		["Riverside","AL",33.61,-86.2,42209021],
		["Elmwood","TN",36.24,-85.88,42209239],
		["Isola","MS",33.26,-90.59,42209457],
		["Cleveland","OH",41.47,-81.67,42209675],
		["Akron","OH",41.08,-81.52,42209893],
		["Van Buren","OH",41.13,-83.64,42210111],
		["Rhodes","MI",43.9,-84.18,42210329],
		["Janesville","IA",42.64,-92.46,42210547],
		["Bernard","IA",42.31,-90.83,42210765],
		["Wayland","IA",41.14,-91.65,42210983],
		["Seeley Lake","MT",47.24,-113.53,42211201],
		["Leasburg","MO",38.09,-91.29,42211419],
		["Taneyville","MO",36.73,-93.03,42211637],
		["Batchelor","LA",30.84,-91.66,42211855],
		["Arkoma","OK",35.33,-94.45,42212073],
		["Mico","TX",29.53,-98.93,42212291],
		["Girdwood","AK",60.9,-148.91,42212509],
		["Eaton","NY",42.84,-75.61,42212726],
		["Mendon","NY",42.99,-77.49,42212943],
		["Grampian","PA",40.96,-78.61,42213160],
		["Carrsville","VA",36.71,-76.82,42213377],
		["Virgilina","VA",36.54,-78.77,42213594],
		["Oakwood","VA",37.21,-81.98,42213811],
		["New Creek","WV",39.37,-79.02,42214028],
		["Crawfordville","GA",33.55,-82.89,42214245],
		["Grant","FL",27.92,-80.52,42214462],
		["Anguilla","MS",32.97,-90.82,42214679],
		["Arlington","KY",36.79,-89.01,42214896],
		["Symsonia","KY",36.9,-88.51,42215113],
		["Helena","OH",41.33,-83.29,42215330],
		["Brook","IN",40.86,-87.36,42215547],
		["Galien","MI",41.8,-86.49,42215764],
		["Rockwell","IA",42.97,-93.18,42215981],
		["Keota","IA",41.36,-91.95,42216198],
		["Tyndall","SD",42.98,-97.86,42216415],
		["White River","SD",43.56,-100.74,42216632],
		["New Florence","MO",38.9,-91.44,42216849],
		["Sheldon","MO",37.65,-94.29,42217066],
		["Montreal","MO",37.96,-92.59,42217283],
		["Hooper","NE",41.61,-96.54,42217500],
		["Braithwaite","LA",29.62,-89.78,42217717],
		["Cotton Valley","LA",32.81,-93.42,42217934],
		["Bradley","AR",33.09,-93.65,42218151],
		["Viola","AR",36.39,-91.98,42218368],
		["Cross Plains","TX",32.12,-99.16,42218585],
		["Perrin","TX",33.08,-98.05,42218802],
		["Pescadero","CA",37.2,-122.3,42219019],
		["Loyalton","CA",39.63,-120.21,42219236],
		["Grand Ronde","OR",45.06,-123.65,42219453],
		["Silverlake","WA",46.32,-122.78,42219670],
		["Franklin","ME",44.58,-68.23,42219886],
		["Bingham","ME",45.05,-69.87,42220102],
		["Barton","VT",44.74,-72.17,42220318],
		["Marilla","NY",42.83,-78.55,42220534],
		["Strykersville","NY",42.73,-78.42,42220750],
		["Hinsdale","NY",42.16,-78.38,42220966],
		["White Stone","VA",37.64,-76.39,42221182],
		["Pilot","VA",37.05,-80.36,42221398],
		["Sunset Beach","NC",33.87,-78.51,42221614],
		["Tignall","GA",33.86,-82.74,42221830],
		["Naylor","GA",30.9,-83.07,42222046],
		["Waterford","MS",34.63,-89.46,42222262],
		["Arjay","KY",36.8,-83.64,42222478],
		["Farmdale","OH",41.39,-80.66,42222694],
		["Waynetown","IN",40.08,-87.06,42222910],
		["Minong","WI",46.09,-91.82,42223126],
		["Huntley","MT",45.82,-108.22,42223342],
		["Chatsworth","IL",40.75,-88.29,42223558],
		["Alhambra","IL",38.88,-89.73,42223774],
		["Beaufort","MO",38.41,-91.19,42223990],
		["Glasgow","MO",39.22,-92.83,42224206],
		["Blue Hill","NE",40.33,-98.44,42224422],
		["Gloster","LA",32.19,-93.82,42224638],
		["Cave Springs","AR",36.26,-94.22,42224854],
		["Havana","AR",35.11,-93.52,42225070],
		["Sunset","TX",33.45,-97.77,42225286],
		["Hurley","NM",32.69,-108.13,42225502],
		["South Bend","WA",46.56,-123.88,42225718],
		["Douglas","AK",58.23,-134.38,42225934],
		["Peru","ME",44.5,-70.4,42226149],
		["Hartland","ME",44.88,-69.45,42226364],
		["East Montpelier","VT",44.28,-72.49,42226579],
		["Scenery Hill","PA",40.08,-80.07,42226794],
		["West Alexander","PA",40.1,-80.5,42227009],
		["Jackson Center","PA",41.27,-80.13,42227224],
		["Sligo","PA",41.1,-79.49,42227439],
		["Montvale","VA",37.38,-79.73,42227654],
		["Craigsville","VA",38.08,-79.38,42227869],
		["Reevesville","SC",33.2,-80.64,42228084],
		["Tybee Island","GA",32.01,-80.85,42228299],
		["Mc Lain","MS",31.05,-88.8,42228514],
		["Williamsfield","OH",41.53,-80.61,42228729],
		["North Salem","IN",39.85,-86.64,42228944],
		["Mulliken","MI",42.76,-84.89,42229159],
		["Gilbert","IA",42.1,-93.64,42229374],
		["Greene","IA",42.89,-92.8,42229589],
		["Grand Marsh","WI",43.89,-89.71,42229804],
		["Akeley","MN",47,-94.72,42230019],
		["Crosslake","MN",46.68,-94.09,42230234],
		["Remer","MN",47.05,-93.91,42230449],
		["Faulkton","SD",45.03,-99.12,42230664],
		["Saint John","ND",48.94,-99.71,42230879],
		["Paw Paw","IL",41.68,-88.98,42231094],
		["Roxana","IL",38.83,-90.05,42231309],
		["Morrisville","MO",37.48,-93.42,42231524],
		["Jetmore","KS",38.08,-99.89,42231739],
		["Grant","NE",40.84,-101.72,42231954],
		["Coyle","OK",35.95,-97.23,42232169],
		["Waukomis","OK",36.28,-97.9,42232384],
		["Fraser","CO",39.93,-105.79,42232599],
		["Yoder","CO",38.84,-104.22,42232814],
		["Holly","CO",38.05,-102.12,42233029],
		["Garden Valley","ID",44.08,-115.85,42233244],
		["Myton","UT",40.17,-110.02,42233459],
		["San Diego","CA",32.87,-117.15,42233674],
		["Manchester","NH",42.99,-71.45,42233888],
		["Ludlow","VT",43.39,-72.69,42234102],
		["Scio","NY",42.17,-77.97,42234316],
		["Copper Hill","VA",37.08,-80.13,42234530],
		["Big Island","VA",37.53,-79.36,42234744],
		["Good Hope","GA",33.78,-83.6,42234958],
		["Waverly","GA",31.09,-81.72,42235172],
		["Barlow","KY",37.05,-89.04,42235386],
		["Mount Blanchard","OH",40.89,-83.55,42235600],
		["Glenbeulah","WI",43.79,-88.04,42235814],
		["Bowler","WI",44.86,-88.98,42236028],
		["Bayport","MN",45.01,-92.78,42236242],
		["Ogema","MN",47.1,-95.92,42236456],
		["Colman","SD",43.98,-96.81,42236670],
		["Edinburg","IL",39.65,-89.38,42236884],
		["Greenville","MO",37.12,-90.44,42237098],
		["Stockton","KS",39.43,-99.27,42237312],
		["Hershey","NE",41.15,-101,42237526],
		["French Settlement","LA",30.31,-90.8,42237740],
		["Saline","LA",32.16,-92.97,42237954],
		["Gilbert","LA",32.05,-91.65,42238168],
		["Prattsville","AR",34.31,-92.54,42238382],
		["Weiner","AR",35.61,-90.9,42238596],
		["Call","TX",30.61,-93.87,42238810],
		["Petersburg","TX",33.87,-101.59,42239024],
		["Haxtun","CO",40.64,-102.62,42239238],
		["Manassa","CO",37.17,-105.93,42239452],
		["Wofford Heights","CA",35.7,-118.5,42239666],
		["Kaaawa","HI",21.55,-157.85,42239880],
		["Temple","NH",42.81,-71.83,42240093],
		["Dresden","ME",44.1,-69.72,42240306],
		["Bridgewater","CT",41.52,-73.36,42240519],
		["New Preston Marble Dale","CT",41.69,-73.34,42240732],
		["Elizaville","NY",42.04,-73.79,42240945],
		["New Woodstock","NY",42.84,-75.85,42241158],
		["Jasper","NY",42.12,-77.5,42241371],
		["Mill Run","PA",39.96,-79.45,42241584],
		["Callaway","MD",38.23,-76.51,42241797],
		["Clay","WV",38.46,-81.08,42242010],
		["Proctor","WV",39.72,-80.75,42242223],
		["Evergreen","NC",34.4,-78.9,42242436],
		["Stockton","AL",30.99,-87.85,42242649],
		["Santa Fe","TN",35.75,-87.16,42242862],
		["Boyle","MS",33.7,-90.72,42243075],
		["Brinson","GA",30.94,-84.71,42243288],
		["Ghent","KY",38.73,-85.06,42243501],
		["Bulan","KY",37.29,-83.16,42243714],
		["Grand Rivers","KY",37,-88.23,42243927],
		["Levering","MI",45.63,-84.76,42244140],
		["Posen","MI",45.26,-83.69,42244353],
		["Alden","IA",42.51,-93.37,42244566],
		["Sanborn","IA",43.18,-95.65,42244779],
		["Cleveland","MN",44.32,-93.83,42244992],
		["Clay City","IL",38.68,-88.35,42245205],
		["Jonesburg","MO",38.85,-91.3,42245418],
		["Naylor","MO",36.57,-90.6,42245631],
		["Reeds","MO",37.11,-94.16,42245844],
		["Frierson","LA",32.23,-93.67,42246057],
		["Hattieville","AR",35.28,-92.79,42246270],
		["Leslie","AR",35.82,-92.55,42246483],
		["Copan","OK",36.89,-95.92,42246696],
		["Agra","OK",35.89,-96.87,42246909],
		["Bunch","OK",35.68,-94.77,42247122],
		["Cresson","TX",32.53,-97.65,42247335],
		["Ehrenberg","AZ",33.64,-114.44,42247548],
		["Pirtleville","AZ",31.35,-109.57,42247761],
		["Papaikou","HI",19.79,-155.09,42247974],
		["Twisp","WA",48.32,-119.93,42248187],
		["Addy","WA",48.32,-117.91,42248400],
		["Castaner","PR",18.19,-66.82,42248612],
		["Cecil","PA",40.33,-80.21,42248824],
		["New Church","VA",37.97,-75.53,42249036],
		["Clover","VA",36.83,-78.73,42249248],
		["Tar Heel","NC",34.73,-78.79,42249460],
		["Sun City Center","FL",27.72,-82.35,42249672],
		["Newbern","AL",32.59,-87.53,42249884],
		["Worthington","KY",38.55,-82.73,42250096],
		["Nebo","KY",37.38,-87.64,42250308],
		["Grovertown","IN",41.32,-86.52,42250520],
		["Melvin","MI",43.18,-82.86,42250732],
		["North Adams","MI",41.97,-84.52,42250944],
		["Hubbard Lake","MI",44.8,-83.59,42251156],
		["Allison","IA",42.75,-92.79,42251368],
		["Shell Rock","IA",42.71,-92.58,42251580],
		["Hustisford","WI",43.34,-88.6,42251792],
		["Houlton","WI",45.07,-92.75,42252004],
		["Genoa","WI",43.57,-91.22,42252216],
		["Bird Island","MN",44.76,-94.89,42252428],
		["Ashland","MT",45.45,-106.21,42252640],
		["Potomac","IL",40.3,-87.79,42252852],
		["Leadwood","MO",37.86,-90.58,42253064],
		["Gideon","MO",36.45,-89.91,42253276],
		["Pine Prairie","LA",30.78,-92.42,42253488],
		["Dodson","LA",32.07,-92.65,42253700],
		["Gorman","TX",32.21,-98.67,42253912],
		["Oak Creek","CO",40.27,-106.95,42254124],
		["Pierce","CO",40.63,-104.75,42254336],
		["Alto","NM",33.39,-105.68,42254548],
		["Isleton","CA",38.16,-121.59,42254760],
		["Chimacum","WA",47.97,-122.78,42254972],
		["Pine Island","NY",41.32,-74.38,42255183],
		["Olivebridge","NY",41.89,-74.24,42255394],
		["Burke","NY",44.9,-74.17,42255605],
		["Madison","NY",42.89,-75.51,42255816],
		["Alexandria Bay","NY",44.33,-75.91,42256027],
		["Chaptico","MD",38.36,-76.78,42256238],
		["Henderson","MD",39.07,-75.76,42256449],
		["Esmont","VA",37.83,-78.6,42256660],
		["Brilliant","AL",34.01,-87.77,42256871],
		["Lapine","AL",31.98,-86.27,42257082],
		["Bledsoe","KY",36.92,-83.36,42257293],
		["Chesterhill","OH",39.48,-81.86,42257504],
		["Willshire","OH",40.74,-84.79,42257715],
		["Dupont","IN",38.89,-85.51,42257926],
		["Hillsboro","IN",40.1,-87.15,42258137],
		["Waveland","IN",39.87,-87.04,42258348],
		["Sherrill","IA",42.6,-90.78,42258559],
		["Mason","WI",46.43,-91.06,42258770],
		["Harwood","ND",46.98,-96.87,42258981],
		["Leaf River","IL",42.12,-89.4,42259192],
		["Clayton","IL",40.03,-90.95,42259403],
		["Dearborn","MO",39.52,-94.77,42259614],
		["Washington","KS",39.81,-97.05,42259825],
		["Kinsley","KS",37.92,-99.41,42260036],
		["Niobrara","NE",42.75,-98.03,42260247],
		["Hartford","AR",35.02,-94.37,42260458],
		["Krebs","OK",34.92,-95.71,42260669],
		["Mertzon","TX",31.26,-100.82,42260880],
		["Liverpool","TX",29.29,-95.27,42261091],
		["Wilson","TX",33.31,-101.72,42261302],
		["Kooskia","ID",46.08,-115.86,42261513],
		["Tatum","NM",33.25,-103.31,42261724],
		["West Point","CA",38.42,-120.44,42261935],
		["Palermo","CA",39.43,-121.55,42262146],
		["Randolph","ME",44.23,-69.75,42262356],
		["Speonk","NY",40.89,-72.42,42262566],
		["Jefferson","NY",42.48,-74.61,42262776],
		["Townville","PA",41.67,-79.88,42262986],
		["Warriors Mark","PA",40.7,-78.11,42263196],
		["Galeton","PA",41.73,-77.64,42263406],
		["Delaware City","DE",39.57,-75.6,42263616],
		["Fraziers Bottom","WV",38.54,-82.02,42263826],
		["Hallsboro","NC",34.29,-78.59,42264036],
		["Hildebran","NC",35.71,-81.42,42264246],
		["Elko","GA",32.33,-83.73,42264456],
		["Milan","GA",32.01,-83.06,42264666],
		["Liberty","TN",36,-85.98,42264876],
		["Tomahawk","KY",37.87,-82.59,42265086],
		["Uniontown","KY",37.77,-87.93,42265296],
		["Lore City","OH",39.98,-81.45,42265506],
		["Casstown","OH",40.05,-84.12,42265716],
		["Guysville","OH",39.29,-81.92,42265926],
		["Waldron","MI",41.72,-84.41,42266136],
		["Bitely","MI",43.74,-85.86,42266346],
		["Urbana","IA",42.22,-91.87,42266556],
		["Hoyt Lakes","MN",47.55,-92.11,42266766],
		["Lanesboro","MN",43.71,-91.97,42266976],
		["White Sulphur Springs","MT",46.57,-111,42267186],
		["Wenona","IL",41.05,-89.05,42267396],
		["Divernon","IL",39.56,-89.65,42267606],
		["Bevier","MO",39.74,-92.56,42267816],
		["Appleton City","MO",38.19,-94.03,42268026],
		["Eminence","MO",37.14,-91.35,42268236],
		["Walnut Shade","MO",36.73,-93.19,42268446],
		["Genoa","NE",41.44,-97.73,42268656],
		["Hemingford","NE",42.32,-103.07,42268866],
		["Boise City","OK",36.73,-102.51,42269076],
		["Macomb","OK",35.14,-97,42269286],
		["Wanette","OK",34.96,-97.03,42269496],
		["Upton","WY",44.1,-104.62,42269706],
		["Mora","NM",35.97,-105.32,42269916],
		["Waterville","WA",47.69,-119.88,42270126],
		["Chevak","AK",61.56,-165.38,42270336],
		["Metlakatla","AK",55.14,-131.49,42270546],
		["Onset","MA",41.74,-70.66,42270755],
		["North Haverhill","NH",44.09,-71.99,42270964],
		["Kenvil","NJ",40.88,-74.62,42271173],
		["East Berne","NY",42.61,-74.05,42271382],
		["Point Of Rocks","MD",39.27,-77.53,42271591],
		["Bentonville","VA",38.83,-78.31,42271800],
		["Valley Grove","WV",40.09,-80.57,42272009],
		["Wrightsville Beach","NC",34.21,-77.79,42272218],
		["Muscadine","AL",33.74,-85.37,42272427],
		["Chancellor","AL",31.18,-85.87,42272636],
		["Forkland","AL",32.64,-87.86,42272845],
		["Marion Junction","AL",32.44,-87.28,42273054],
		["Altamont","TN",35.42,-85.72,42273263],
		["Toone","TN",35.35,-88.95,42273472],
		["Vossburg","MS",31.98,-88.96,42273681],
		["Raccoon","KY",37.49,-82.44,42273890],
		["Centertown","KY",37.41,-86.99,42274099],
		["Cambridge","IA",41.89,-93.53,42274308],
		["Dunlap","IA",41.85,-95.59,42274517],
		["De Smet","SD",44.38,-97.54,42274726],
		["Harlowton","MT",46.48,-109.88,42274935],
		["Deepwater","MO",38.25,-93.77,42275144],
		["Kechi","KS",37.79,-97.27,42275353],
		["Adams","NE",40.45,-96.51,42275562],
		["Egan","LA",30.23,-92.5,42275771],
		["Cartwright","OK",33.85,-96.57,42275980],
		["Spur","TX",33.47,-100.85,42276189],
		["Nehalem","OR",45.7,-123.86,42276398],
		["Lyndeborough","NH",42.9,-71.75,42276606],
		["Greeley","PA",41.42,-74.99,42276814],
		["Harbeson","DE",38.67,-75.23,42277022],
		["Springfield","WV",39.45,-78.69,42277230],
		["Mentone","AL",34.56,-85.58,42277438],
		["Shuqualak","MS",32.97,-88.56,42277646],
		["Sanders","KY",38.65,-84.94,42277854],
		["Risingsun","OH",41.26,-83.42,42278062],
		["Bringhurst","IN",40.51,-86.52,42278270],
		["Centerpoint","IN",39.37,-87.09,42278478],
		["Paullina","IA",42.97,-95.68,42278686],
		["Fort Atkinson","IA",43.14,-91.93,42278894],
		["Hixton","WI",44.38,-91.01,42279102],
		["Huson","MT",47.13,-114.53,42279310],
		["Avon","IL",40.66,-90.43,42279518],
		["Higbee","MO",39.3,-92.51,42279726],
		["Americus","KS",38.5,-96.26,42279934],
		["Harvard","NE",40.61,-98.09,42280142],
		["Amargosa Valley","NV",36.53,-116.47,42280350],
		["Yermo","CA",34.98,-116.78,42280558],
		["Beale Afb","CA",39.12,-121.39,42280766],
		["Athena","OR",45.83,-118.51,42280974],
		["Cushing","ME",44.01,-69.24,42281181],
		["High Falls","NY",41.8,-74.13,42281388],
		["Narrowsburg","NY",41.6,-75.06,42281595],
		["Richland","NY",43.57,-75.97,42281802],
		["Colton","NY",44.32,-74.82,42282009],
		["Angelica","NY",42.3,-78.02,42282216],
		["Greensboro","PA",39.79,-79.91,42282423],
		["Kennerdell","PA",41.27,-79.85,42282630],
		["Ashville","PA",40.55,-78.54,42282837],
		["Sugar Grove","VA",36.76,-81.4,42283044],
		["Fairfield","VA",37.88,-79.3,42283251],
		["Cottageville","WV",38.85,-81.81,42283458],
		["Newington","GA",32.58,-81.5,42283665],
		["Billingsley","AL",32.66,-86.7,42283872],
		["Moscow","OH",38.86,-84.22,42284079],
		["Mooreland","IN",39.99,-85.25,42284286],
		["Custer","MI",43.95,-86.21,42284493],
		["Kellogg","IA",41.71,-92.9,42284700],
		["Elgin","IA",42.95,-91.63,42284907],
		["Saint Germain","WI",45.91,-89.49,42285114],
		["Alma Center","WI",44.43,-90.91,42285321],
		["Plankinton","SD",43.71,-98.48,42285528],
		["New Rockford","ND",47.68,-99.13,42285735],
		["Rolla","ND",48.85,-99.61,42285942],
		["Absarokee","MT",45.5,-109.56,42286149],
		["Flanagan","IL",40.87,-88.86,42286356],
		["Fairdealing","MO",36.67,-90.64,42286563],
		["Canton","KS",38.38,-97.42,42286770],
		["Dallas","TX",32.79,-96.76,42286977],
		["Markham","TX",28.96,-96.06,42287184],
		["Stockton","UT",40.3,-112.3,42287391],
		["Tranquillity","CA",36.65,-120.28,42287598],
		["Paauilo","HI",20.04,-155.37,42287805],
		["South Walpole","MA",42.09,-71.27,42288011],
		["Palermo","ME",44.4,-69.47,42288217],
		["Avon By The Sea","NJ",40.19,-74.01,42288423],
		["Estell Manor","NJ",39.35,-74.77,42288629],
		["Earlton","NY",42.35,-73.9,42288835],
		["West Hurley","NY",42,-74.11,42289041],
		["Almond","NY",42.31,-77.73,42289247],
		["Genesee","PA",41.98,-77.9,42289453],
		["Shipman","VA",37.72,-78.83,42289659],
		["Columbia","VA",37.75,-78.16,42289865],
		["Accomac","VA",37.71,-75.66,42290071],
		["Sweet Briar","VA",37.56,-79.05,42290277],
		["Jackson","NC",36.39,-77.41,42290483],
		["Eure","NC",36.42,-76.85,42290689],
		["Shawboro","NC",36.4,-76.09,42290895],
		["Ennice","NC",36.55,-81,42291101],
		["Union Grove","NC",36.02,-80.86,42291307],
		["Mayesville","SC",33.98,-80.2,42291513],
		["Garnett","SC",32.6,-81.24,42291719],
		["Harrison","GA",32.82,-82.72,42291925],
		["Keavy","KY",37,-84.16,42292131],
		["Norman","IN",38.95,-86.22,42292337],
		["Van Dyne","WI",43.87,-88.52,42292543],
		["Valley Springs","SD",43.58,-96.46,42292749],
		["Percy","IL",38.01,-89.61,42292955],
		["Wheatland","MO",37.94,-93.4,42293161],
		["Ponca","NE",42.56,-96.7,42293367],
		["Paradis","LA",29.87,-90.44,42293573],
		["Delight","AR",34.02,-93.5,42293779],
		["Center Ridge","AR",35.4,-92.56,42293985],
		["Lonsdale","AR",34.54,-92.81,42294191],
		["Seiling","OK",36.14,-98.92,42294397],
		["Mccurtain","OK",35.13,-95.02,42294603],
		["Morgan","TX",32.01,-97.6,42294809],
		["Pointblank","TX",30.76,-95.25,42295015],
		["Franklin","ID",42.03,-111.79,42295221],
		["Goshen","UT",39.93,-111.88,42295427],
		["Selma","OR",42.26,-123.59,42295633],
		["Wales","MA",42.06,-72.21,42295838],
		["Deerfield","MA",42.55,-72.6,42296043],
		["East Millinocket","ME",45.62,-68.57,42296248],
		["Brooks","ME",44.55,-69.12,42296453],
		["Warren","VT",44.12,-72.85,42296658],
		["Rices Landing","PA",39.94,-79.99,42296863],
		["Manns Choice","PA",40,-78.59,42297068],
		["Kents Store","VA",37.87,-78.12,42297273],
		["Dudley","GA",32.53,-83.08,42297478],
		["Ocoee","TN",35.1,-84.7,42297683],
		["Hampshire","TN",35.58,-87.35,42297888],
		["Buffalo","KY",37.51,-85.7,42298093],
		["Graytown","OH",41.52,-83.26,42298298],
		["Rodney","MI",43.67,-85.32,42298503],
		["George","IA",43.34,-96,42298708],
		["Crescent","IA",41.36,-95.85,42298913],
		["Hamburg","IA",40.6,-95.65,42299118],
		["Soldiers Grove","WI",43.39,-90.77,42299323],
		["Finlayson","MN",46.2,-92.92,42299528],
		["Morgan","MN",44.41,-94.92,42299733],
		["Twin Valley","MN",47.25,-96.25,42299938],
		["Loda","IL",40.51,-88.07,42300143],
		["Hardin","IL",39.15,-90.62,42300348],
		["Dahlgren","en","IL",38.19,-88.68,42300553],
		["Rocky Comfort","MO",36.74,-94.09,42300758],
		["Council","ID",44.89,-116.54,42300963],
		["Panaca","NV",37.68,-114.29,42301168],
		["Reno","NV",39.91,-119.68,42301373],
		["Sheridan","CA",38.98,-121.35,42301578],
		["Salcha","AK",64.37,-146.59,42301783],
		["Westmoreland","NY",43.11,-75.4,42301987],
		["Richford","NY",42.35,-76.2,42302191],
		["Cherry Creek","NY",42.29,-79.1,42302395],
		["Wellsburg","NY",42.01,-76.72,42302599],
		["Mapleton Depot","PA",40.37,-77.97,42302803],
		["Summerdale","PA",40.29,-76.95,42303007],
		["North Garden","VA",37.94,-78.63,42303211],
		["Benwood","WV",40.01,-80.73,42303415],
		["Sledge","MS",34.43,-90.22,42303619],
		["Sturgis","MS",33.34,-89.04,42303823],
		["Turners Station","KY",38.55,-85.12,42304027],
		["Blue Rock","OH",39.79,-81.87,42304231],
		["New Springfield","OH",40.92,-80.58,42304435],
		["Bowerston","OH",40.42,-81.18,42304639],
		["Blue Creek","OH",38.74,-83.35,42304843],
		["Star City","IN",40.95,-86.52,42305047],
		["Munger","MI",43.52,-83.77,42305251],
		["Dexter","IA",41.51,-94.22,42305455],
		["Murray","IA",41.04,-93.94,42305659],
		["Fredericksburg","IA",42.96,-92.19,42305863],
		["Minneapolis","MN",44.96,-93.26,42306067],
		["Milnor","ND",46.26,-97.45,42306271],
		["Golden City","MO",37.39,-94.09,42306475],
		["Elwood","NE",40.58,-99.86,42306679],
		["Sicily Island","LA",31.84,-91.65,42306883],
		["Drasco","AR",35.66,-91.93,42307087],
		["Santo","TX",32.58,-98.18,42307291],
		["Naco","AZ",31.34,-109.94,42307495],
		["Descanso","CA",32.9,-116.62,42307699],
		["Pine Valley","CA",32.78,-116.48,42307903],
		["Gill","MA",42.7,-72.43,42308106],
		["Milan","NH",44.57,-71.18,42308309],
		["Derby Line","VT",45,-72.1,42308512],
		["New York","NY",40.71,-73.99,42308715],
		["Mount Morris","PA",39.75,-80.11,42308918],
		["Manor","PA",40.34,-79.67,42309121],
		["Mill Creek","PA",40.43,-77.92,42309324],
		["Brackney","PA",41.98,-75.92,42309527],
		["Marydel","DE",39.09,-75.72,42309730],
		["Alberta","VA",36.86,-77.88,42309933],
		["Mount Nebo","WV",38.19,-80.8,42310136],
		["Norway","SC",33.44,-81.12,42310339],
		["Dellroy","OH",40.55,-81.19,42310542],
		["Amboy","IN",40.6,-85.92,42310745],
		["Arena","WI",43.16,-89.91,42310948],
		["Brownton","MN",44.73,-94.35,42311151],
		["Le Roy","MN",43.51,-92.5,42311354],
		["Clarissa","MN",46.12,-94.94,42311557],
		["Kimball","SD",43.74,-98.95,42311760],
		["Worden","MT",46.12,-108.09,42311963],
		["Mooseheart","IL",41.82,-88.33,42312166],
		["Shannon","IL",42.15,-89.74,42312369],
		["Minier","IL",40.43,-89.31,42312572],
		["Nauvoo","IL",40.54,-91.38,42312775],
		["Tamms","IL",37.23,-89.26,42312978],
		["Edgerton","MO",39.5,-94.63,42313181],
		["Alma","KS",39.01,-96.28,42313384],
		["Carville","LA",30.22,-91.1,42313587],
		["Pine","AZ",34.32,-111.54,42313790],
		["Ribera","NM",35.37,-105.44,42313993],
		["Loleta","CA",40.68,-124.22,42314196],
		["Dunnigan","CA",38.88,-122,42314399],
		["Plymouth","ME",44.76,-69.21,42314601],
		["Allenhurst","NJ",40.23,-74,42314803],
		["Dannemora","NY",44.72,-73.71,42315005],
		["Fort Covington","NY",44.98,-74.47,42315207],
		["Perrysburg","NY",42.45,-79,42315409],
		["Roanoke","VA",37.27,-79.95,42315611],
		["Hiltons","VA",36.65,-82.46,42315813],
		["Fancy Gap","VA",36.66,-80.7,42316015],
		["Walton","WV",38.6,-81.4,42316217],
		["Epworth","GA",34.92,-84.51,42316419],
		["Culloden","GA",32.86,-84.09,42316621],
		["Kings Mills","OH",39.34,-84.3,42316823],
		["Roland","IA",42.16,-93.5,42317025],
		["Laurens","IA",42.84,-94.84,42317227],
		["Morning Sun","IA",41.09,-91.25,42317429],
		["Belmont","WI",42.73,-90.33,42317631],
		["Audubon","MN",46.86,-95.98,42317833],
		["Clearbrook","MN",47.69,-95.42,42318035],
		["Crooks","SD",43.66,-96.8,42318237],
		["Sorento","IL",39,-89.57,42318439],
		["Norborne","MO",39.3,-93.67,42318641],
		["Peck","KS",37.47,-97.31,42318843],
		["Ness City","KS",38.45,-99.9,42319045],
		["Victoria","KS",38.85,-99.14,42319247],
		["Bell City","LA",30.11,-92.96,42319449],
		["Hatfield","AR",34.48,-94.37,42319651],
		["Barry","TX",32.09,-96.63,42319853],
		["Cookville","TX",33.18,-94.86,42320055],
		["Runge","TX",28.88,-97.71,42320257],
		["Roby","TX",32.74,-100.37,42320459],
		["Burns","WY",41.19,-104.35,42320661],
		["New Meadows","ID",45.06,-116.37,42320863],
		["Elsinore","UT",38.68,-112.14,42321065],
		["Nellis Afb","NV",36.24,-114.96,42321267],
		["Chino","CA",34.02,-117.68,42321469],
		["Big Pine","CA",37.18,-118.18,42321671],
		["Russell","MA",42.18,-72.85,42321872],
		["Wellfleet","MA",41.93,-70.03,42322073],
		["East Machias","ME",44.73,-67.39,42322274],
		["Searsmont","ME",44.36,-69.19,42322475],
		["Hartwick","NY",42.65,-75.04,42322676],
		["Penn Run","PA",40.62,-78.97,42322877],
		["Carson","VA",37.03,-77.4,42323078],
		["Weber City","VA",36.62,-82.56,42323279],
		["Bunn","NC",35.95,-78.25,42323480],
		["San Mateo","FL",29.6,-81.58,42323681],
		["Banks","AL",31.81,-85.83,42323882],
		["Daviston","AL",33.05,-85.63,42324083],
		["Allons","TN",36.51,-85.32,42324284],
		["Cerulean","KY",36.96,-87.66,42324485],
		["Radnor","OH",40.39,-83.18,42324686],
		["Brilliant","OH",40.26,-80.62,42324887],
		["Freedom","IN",39.21,-86.85,42325088],
		["Oaktown","IN",38.87,-87.44,42325289],
		["Scandinavia","WI",44.46,-89.14,42325490],
		["Canton","MN",43.53,-91.92,42325691],
		["Raymond","MN",45.01,-95.23,42325892],
		["Orangeville","IL",42.46,-89.64,42326093],
		["Urich","MO",38.45,-93.99,42326294],
		["Trinidad","TX",32.14,-96.09,42326495],
		["Oro Grande","CA",34.65,-117.29,42326696],
		["Tok","AK",63.66,-143.19,42326897],
		["Madison","NH",43.89,-71.14,42327097],
		["Hallowell","ME",44.29,-69.81,42327297],
		["Margaretville","NY",42.14,-74.65,42327497],
		["Burlington Flats","NY",42.74,-75.18,42327697],
		["Mathews","VA",37.43,-76.32,42327897],
		["Mountain Rest","SC",34.86,-83.15,42328097],
		["Fort Lauderdale","FL",26.14,-80.13,42328297],
		["Dolomite","AL",33.47,-86.94,42328497],
		["Logan","AL",34.18,-87.04,42328697],
		["Axis","AL",30.92,-88.02,42328897],
		["Norris","TN",36.21,-84.06,42329097],
		["Enid","MS",34.11,-89.95,42329297],
		["Horse Branch","KY",37.4,-86.71,42329497],
		["New Riegel","OH",41.05,-83.31,42329697],
		["Marshall","IN",39.84,-87.18,42329897],
		["Aurelia","IA",42.71,-95.43,42330097],
		["Boyden","IA",43.19,-96,42330297],
		["Juda","WI",42.55,-89.49,42330497],
		["Gresham","WI",44.84,-88.78,42330697],
		["Hill City","MN",46.98,-93.59,42330897],
		["Grey Eagle","MN",45.82,-94.74,42331097],
		["Waubay","SD",45.33,-97.3,42331297],
		["Valier","MT",48.3,-112.36,42331497],
		["Sheldon","IL",40.77,-87.56,42331697],
		["Opdyke","IL",38.27,-88.75,42331897],
		["Waverly","MO",39.2,-93.51,42332097],
		["Elmer","LA",31.18,-92.67,42332297],
		["Rosston","AR",33.58,-93.27,42332497],
		["Bellvue","CO",40.63,-105.17,42332697],
		["Snelling","CA",37.54,-120.45,42332897],
		["Powell Butte","OR",44.23,-121.01,42333097],
		["Ashland","NH",43.69,-71.63,42333296],
		["Wilmot","NH",43.45,-71.91,42333495],
		["Dublin","NH",42.91,-72.06,42333694],
		["Westmoreland","NH",42.96,-72.45,42333893],
		["Newbury","VT",44.08,-72.06,42334092],
		["South Hero","VT",44.64,-73.31,42334291],
		["Boston","VA",38.54,-78.14,42334490],
		["Cloverport","KY",37.83,-86.62,42334689],
		["Kewadin","MI",44.93,-85.36,42334888],
		["Taylor","WI",44.32,-91.12,42335087],
		["Saint Paul","MN",44.94,-93.1,42335286],
		["Tonica","IL",41.21,-89.06,42335485],
		["Viola","IL",41.2,-90.58,42335684],
		["Bonnots Mill","MO",38.57,-91.96,42335883],
		["Mound City","KS",38.14,-94.82,42336082],
		["Gypsum","KS",38.7,-97.42,42336281],
		["Hill City","KS",39.36,-99.84,42336480],
		["Raymond","NE",40.95,-96.78,42336679],
		["Bloomfield","NE",42.59,-97.64,42336878],
		["Sparkman","AR",33.91,-92.84,42337077],
		["Spavinaw","OK",36.39,-95.05,42337276],
		["Dallas","TX",32.79,-96.76,42337475],
		["Crawford","CO",38.7,-107.6,42337674],
		["Winthrop","WA",48.5,-120.33,42337873],
		["Fairlee","VT",43.91,-72.19,42338071],
		["Modena","NY",41.64,-74.1,42338269],
		["Kiamesha Lake","NY",41.68,-74.66,42338467],
		["New Park","PA",39.76,-76.5,42338665],
		["Scotrun","PA",41.07,-75.33,42338863],
		["Noxen","PA",41.42,-76.05,42339061],
		["Wysox","PA",41.77,-76.39,42339259],
		["Cartersville","VA",37.66,-78.1,42339457],
		["Suffolk","VA",36.7,-76.63,42339655],
		["Stokes","NC",35.71,-77.27,42339853],
		["Cedar Key","FL",29.14,-83.03,42340051],
		["Fruithurst","AL",33.73,-85.43,42340249],
		["Webb","MS",33.94,-90.34,42340447],
		["Pearlington","MS",30.24,-89.6,42340645],
		["Island","KY",37.44,-87.14,42340843],
		["Houston","OH",40.24,-84.35,42341041],
		["Caseville","MI",43.94,-83.27,42341239],
		["Bailey","MI",43.27,-85.81,42341437],
		["Newell","IA",42.61,-95,42341635],
		["Mc Gregor","IA",43.02,-91.18,42341833],
		["Highland","WI",43.04,-90.37,42342031],
		["Castlewood","SD",44.72,-97.03,42342229],
		["Deadwood","SD",44.38,-103.72,42342427],
		["Hettinger","ND",46,-102.63,42342625],
		["Pleasant Hill","IL",39.44,-90.87,42342823],
		["Royalton","IL",37.87,-89.11,42343021],
		["Riley","KS",39.29,-96.82,42343219],
		["Shattuck","OK",36.27,-99.87,42343417],
		["Bronte","TX",31.88,-100.29,42343615],
		["Bushland","TX",35.19,-102.06,42343813],
		["Greenleaf","ID",43.67,-116.83,42344011],
		["Alamo","NV",37.27,-115.2,42344209],
		["Boulevard","CA",32.67,-116.31,42344407],
		["Cantua Creek","CA",36.45,-120.45,42344605],
		["Waimea","HI",21.95,-159.67,42344803],
		["Hope","ME",44.26,-69.15,42345000],
		["Starksboro","VT",44.22,-72.99,42345197],
		["New York","NY",40.71,-73.99,42345394],
		["Piermont","NY",41.03,-73.91,42345591],
		["North Creek","NY",43.69,-73.98,42345788],
		["Brownville","NY",44,-75.98,42345985],
		["Rensselaer Falls","NY",44.59,-75.32,42346182],
		["Varysburg","NY",42.73,-78.31,42346379],
		["Highspire","PA",40.2,-76.78,42346576],
		["Cleveland","VA",36.94,-82.15,42346773],
		["Granite Quarry","NC",35.61,-80.44,42346970],
		["Cobbtown","GA",32.28,-82.13,42347167],
		["Delta","AL",33.45,-85.68,42347364],
		["Saulsbury","TN",35.04,-89.08,42347561],
		["Coila","MS",33.36,-89.97,42347758],
		["Harrisville","MS",31.96,-90.09,42347955],
		["Warfield","KY",37.83,-82.42,42348152],
		["Salem","KY",37.26,-88.23,42348349],
		["North Fairfield","OH",41.1,-82.61,42348546],
		["Middle Point","OH",40.85,-84.44,42348743],
		["Twining","MI",44.11,-83.8,42348940],
		["Chili","WI",44.63,-90.35,42349137],
		["Viola","WI",43.5,-90.67,42349334],
		["Tyler","MN",44.27,-96.13,42349531],
		["Annawan","IL",41.39,-89.9,42349728],
		["Oreana","IL",39.93,-88.86,42349925],
		["Scribner","NE",41.66,-96.66,42350122],
		["Plainview","AR",34.98,-93.29,42350319],
		["Calumet","OK",35.6,-98.12,42350516],
		["Oilton","OK",36.08,-96.58,42350713],
		["Hargill","TX",26.42,-97.98,42350910],
		["Hopland","CA",38.93,-123.11,42351107],
		["Mcarthur","CA",41.1,-121.34,42351304],
		["Echo","OR",45.66,-119.23,42351501],
		["Mount Vernon","ME",44.5,-69.98,42351697],
		["Sheldon","VT",44.88,-72.95,42351893],
		["West Granby","CT",41.95,-72.86,42352089],
		["Millwood","NY",41.19,-73.79,42352285],
		["Leeds","NY",42.3,-73.94,42352481],
		["Mooers Forks","NY",44.96,-73.69,42352677],
		["Bernhards Bay","NY",43.29,-75.93,42352873],
		["Commodore","PA",40.69,-78.9,42353069],
		["Imler","PA",40.24,-78.54,42353265],
		["Nags Head","NC",35.94,-75.62,42353461],
		["Atkinson","NC",34.52,-78.17,42353657],
		["Edisto Island","SC",32.56,-80.28,42353853],
		["Felda","FL",26.54,-81.43,42354049],
		["Highland Home","AL",31.95,-86.31,42354245],
		["Hurtsboro","AL",32.23,-85.41,42354441],
		["Fredonia","KY",37.2,-88.05,42354637],
		["Martin","OH",41.58,-83.3,42354833],
		["Dayton","OH",39.77,-84.19,42355029],
		["Michigantown","IN",40.32,-86.39,42355225],
		["Patriot","IN",38.83,-84.82,42355421],
		["Brant","MI",43.25,-84.29,42355617],
		["Clifford","MI",43.31,-83.17,42355813],
		["Dowling","MI",42.52,-85.25,42356009],
		["Free Soil","MI",44.1,-86.21,42356205],
		["Copemish","MI",44.48,-85.92,42356401],
		["Irons","MI",44.14,-85.91,42356597],
		["Manilla","IA",41.88,-95.23,42356793],
		["Zumbro Falls","MN",44.28,-92.42,42356989],
		["White","SD",44.43,-96.64,42357185],
		["Kenmare","ND",48.67,-102.08,42357381],
		["Garden Prairie","IL",42.25,-88.73,42357577],
		["Ashmore","IL",39.53,-88.02,42357773],
		["Dix","IL",38.44,-88.94,42357969],
		["La Grange","MO",40.03,-91.52,42358165],
		["Millersville","MO",37.44,-89.8,42358361],
		["New Franklin","MO",39.01,-92.74,42358557],
		["Altheimer","AR",34.31,-91.84,42358753],
		["Erick","OK",35.21,-99.86,42358949],
		["Blanket","TX",31.82,-98.78,42359145],
		["Coolidge","TX",31.75,-96.65,42359341],
		["Spurger","TX",30.69,-94.17,42359537],
		["Rocksprings","TX",30.01,-100.21,42359733],
		["Basin","WY",44.37,-108.04,42359929],
		["Downey","ID",42.42,-112.1,42360125],
		["Shutesbury","MA",42.45,-72.4,42360320],
		["Brownfield","ME",43.93,-70.9,42360515],
		["East Hartland","CT",42,-72.94,42360710],
		["North Windham","CT",41.76,-72.13,42360905],
		["Palatine Bridge","NY",42.91,-74.57,42361100],
		["Middlesex","NY",42.7,-77.27,42361295],
		["Markleysburg","PA",39.73,-79.45,42361490],
		["New Galilee","PA",40.83,-80.4,42361685],
		["Venus","PA",41.37,-79.5,42361880],
		["Liberty","PA",41.55,-77.1,42362075],
		["Cyclone","WV",37.74,-81.66,42362270],
		["Gordon","AL",31.14,-85.09,42362465],
		["Waverly","KY",37.7,-87.81,42362660],
		["Wakefield","MI",46.47,-89.93,42362855],
		["Exira","IA",41.59,-94.87,42363050],
		["Wheatland","IA",41.83,-90.83,42363245],
		["Hancock","WI",44.13,-89.52,42363440],
		["Wrenshall","MN",46.61,-92.38,42363635],
		["Dent","MN",46.55,-95.71,42363830],
		["Allen","SD",43.28,-101.92,42364025],
		["Bismarck","IL",40.26,-87.6,42364220],
		["Atwood","IL",39.79,-88.46,42364415],
		["Hartford","IL",38.82,-90.09,42364610],
		["Maple Hill","KS",39.08,-96.02,42364805],
		["Cambridge","NE",40.28,-100.17,42365000],
		["Rosepine","LA",30.91,-93.28,42365195],
		["Buffalo","OK",36.83,-99.62,42365390],
		["Vici","OK",36.14,-99.29,42365585],
		["Moulton","TX",29.57,-97.14,42365780],
		["Austin","TX",30.26,-97.74,42365975],
		["Colorado Springs","CO",38.86,-104.76,42366170],
		["Clarksburg","CA",38.37,-121.58,42366365],
		["Togiak","AK",59.21,-160.54,42366560],
		["Devens","MA",42.53,-71.61,42366754],
		["Buena","NJ",39.52,-74.94,42366948],
		["Copake","NY",42.1,-73.55,42367142],
		["Calcium","NY",44.03,-75.84,42367336],
		["Dilliner","PA",39.75,-79.99,42367530],
		["Mayport","PA",41.02,-79.22,42367724],
		["Bastian","VA",37.15,-81.15,42367918],
		["Warrensville","NC",36.46,-81.55,42368112],
		["Lake Lure","NC",35.44,-82.2,42368306],
		["Ethel","MS",33.12,-89.46,42368500],
		["Paris","OH",40.8,-81.15,42368694],
		["Monroe City","IN",38.61,-87.35,42368888],
		["Sumner","MI",43.3,-84.81,42369082],
		["Buffalo Center","IA",43.38,-93.94,42369276],
		["Holy Cross","IA",42.6,-90.99,42369470],
		["Victor","IA",41.73,-92.29,42369664],
		["Princeton","IA",41.67,-90.35,42369858],
		["Eagle Bend","MN",46.16,-95.03,42370052],
		["Washburn","ND",47.29,-101.02,42370246],
		["Parshall","ND",47.95,-102.13,42370440],
		["Ennis","MT",45.28,-111.71,42370634],
		["Ogden","IL",40.11,-87.95,42370828],
		["Greenview","IL",40.08,-89.74,42371022],
		["Patton","MO",37.41,-90.06,42371216],
		["Neelyville","MO",36.55,-90.51,42371410],
		["Chatham","LA",32.3,-92.45,42371604],
		["Noble","LA",31.69,-93.68,42371798],
		["Parkin","AR",35.26,-90.55,42371992],
		["Summers","AR",35.98,-94.48,42372186],
		["Bluejacket","OK",36.8,-95.07,42372380],
		["Paducah","TX",34.01,-100.3,42372574],
		["Glenns Ferry","ID",43.09,-115.37,42372768],
		["Loa","UT",38.4,-111.58,42372962],
		["Dolan Springs","AZ",35.64,-114.31,42373156],
		["Eureka","NV",39.82,-116.19,42373350],
		["San Francisco","CA",37.79,-122.46,42373544],
		["Carolina","RI",41.47,-71.64,42373737],
		["Ashland","ME",46.63,-68.4,42373930],
		["Cropseyville","NY",42.76,-73.47,42374123],
		["Tillson","NY",41.83,-74.06,42374316],
		["Cape Vincent","NY",44.12,-76.33,42374509],
		["Knoxville","PA",41.95,-77.43,42374702],
		["Dalmatia","PA",40.65,-76.87,42374895],
		["Trevorton","PA",40.78,-76.67,42375088],
		["Philadelphia","PA",39.95,-75.16,42375281],
		["Crawley","WV",37.94,-80.61,42375474],
		["Axson","GA",31.27,-82.73,42375667],
		["Islamorada","FL",24.92,-80.63,42375860],
		["Bradenton","FL",27.48,-82.57,42376053],
		["Cromwell","KY",37.33,-86.8,42376246],
		["Lewisville","OH",39.76,-81.21,42376439],
		["Vallonia","IN",38.8,-86.05,42376632],
		["Quincy","IN",39.43,-86.79,42376825],
		["Colo","IA",42.01,-93.31,42377018],
		["Lacona","IA",41.18,-93.38,42377211],
		["Plain","WI",43.27,-90.04,42377404],
		["Dallas","WI",45.25,-91.81,42377597],
		["Walhalla","ND",48.92,-97.91,42377790],
		["Ashkum","IL",40.87,-87.95,42377983],
		["Plymouth","IL",40.29,-90.91,42378176],
		["Sigel","IL",39.22,-88.49,42378369],
		["Doe Run","MO",37.74,-90.5,42378562],
		["Fair Play","MO",37.63,-93.57,42378755],
		["Bennet","NE",40.68,-96.5,42378948],
		["Pleasant Hill","LA",31.81,-93.51,42379141],
		["Little Rock Air Force Base","AR",34.88,-92.16,42379334],
		["Sulphur Rock","AR",35.75,-91.49,42379527],
		["Port Bolivar","TX",29.48,-94.57,42379720],
		["Worley","ID",47.44,-116.89,42379913],
		["Casa Blanca","NM",35,-107.41,42380106],
		["Potrero","CA",32.63,-116.59,42380299],
		["Potter Valley","CA",39.37,-123.02,42380492],
		["Tahuya","WA",47.44,-123.02,42380685],
		["South Thomaston","ME",44.05,-69.12,42380877],
		["Waccabuc","NY",41.29,-73.6,42381069],
		["Swan Lake","NY",41.75,-74.77,42381261],
		["Henderson","NY",43.8,-76.19,42381453],
		["East Brady","PA",40.98,-79.61,42381645],
		["Newport","VA",37.29,-80.49,42381837],
		["Frankford","WV",37.91,-80.38,42382029],
		["Ivanhoe","NC",34.6,-78.25,42382221],
		["Inverness","MS",33.35,-90.59,42382413],
		["New Augusta","MS",31.2,-89.02,42382605],
		["Ravenna","KY",37.68,-83.94,42382797],
		["Morral","OH",40.68,-83.21,42382989],
		["Clarks Hill","IN",40.24,-86.72,42383181],
		["Applegate","MI",43.35,-82.63,42383373],
		["Lincoln","MI",44.68,-83.41,42383565],
		["Silverwood","MI",43.3,-83.27,42383757],
		["White Lake","WI",45.16,-88.76,42383949],
		["Iron","MN",47.41,-92.64,42384141],
		["Utica","MN",43.97,-91.95,42384333],
		["Kerkhoven","MN",45.19,-95.32,42384525],
		["Ironton","MN",46.48,-93.99,42384717],
		["Fort Benton","MT",47.98,-110.8,42384909],
		["Milledgeville","IL",41.96,-89.77,42385101],
		["Curryville","MO",39.34,-91.34,42385293],
		["Middletown","MO",39.12,-91.41,42385485],
		["Laurel","NE",42.42,-97.09,42385677],
		["Arapahoe","NE",40.3,-99.89,42385869],
		["Oklahoma City","OK",35.46,-97.51,42386061],
		["Tyrone","OK",36.95,-101.06,42386253],
		["La Blanca","TX",26.3,-97.94,42386445],
		["Los Angeles","CA",34.06,-118.31,42386637],
		["Reardan","WA",47.69,-117.86,42386829],
		["Dawson","PA",40.04,-79.65,42387020],
		["Fallentimber","PA",40.66,-78.48,42387211],
		["Catharpin","VA",38.84,-77.56,42387402],
		["Capron","VA",36.71,-77.2,42387593],
		["Rock Cave","WV",38.83,-80.34,42387784],
		["Bennett","NC",35.57,-79.52,42387975],
		["Blounts Creek","NC",35.36,-76.92,42388166],
		["Oriental","NC",35.03,-76.68,42388357],
		["Creston","NC",36.44,-81.65,42388548],
		["Tuckasegee","NC",35.27,-83.12,42388739],
		["Sharps Chapel","TN",36.34,-83.8,42388930],
		["Elk Horn","KY",37.31,-85.28,42389121],
		["Vickery","OH",41.38,-82.89,42389312],
		["Burghill","OH",41.33,-80.54,42389503],
		["Tabor","IA",40.89,-95.67,42389694],
		["Butternut","WI",46.01,-90.49,42389885],
		["Belfield","ND",46.88,-103.19,42390076],
		["Stewardson","IL",39.26,-88.62,42390267],
		["Murrayville","IL",39.58,-90.25,42390458],
		["Augusta","MO",38.57,-90.88,42390649],
		["Tillar","AR",33.71,-91.45,42390840],
		["Western Grove","AR",36.1,-92.95,42391031],
		["Medford","OK",36.8,-97.73,42391222],
		["Soper","OK",34.03,-95.69,42391413],
		["Ravenna","TX",33.71,-96.13,42391604],
		["Purdon","TX",31.94,-96.57,42391795],
		["Coahoma","TX",32.29,-101.3,42391986],
		["Green River","UT",38.98,-110.65,42392177],
		["Nobleboro","ME",44.07,-69.48,42392367],
		["Newport Center","VT",44.95,-72.3,42392557],
		["Tariffville","CT",41.9,-72.76,42392747],
		["Windham","NY",42.3,-74.25,42392937],
		["Manchester","NY",42.96,-77.23,42393127],
		["Ellicottville","NY",42.27,-78.67,42393317],
		["Spencer","VA",36.61,-80.01,42393507],
		["Wallace","WV",39.4,-80.48,42393697],
		["Blanch","NC",36.51,-79.28,42393887],
		["Marston","NC",34.99,-79.64,42394077],
		["Spruce Pine","AL",34.37,-87.69,42394267],
		["Honoraville","AL",31.85,-86.4,42394457],
		["Wooton","KY",37.17,-83.3,42394647],
		["Arcadia","OH",41.11,-83.51,42394837],
		["Long Bottom","OH",39.08,-81.87,42395027],
		["Corunna","IN",41.43,-85.14,42395217],
		["Kimmell","IN",41.37,-85.51,42395407],
		["West College Corner","IN",39.56,-84.81,42395597],
		["Laconia","IN",38.03,-86.08,42395787],
		["Ainsworth","IA",41.29,-91.55,42395977],
		["Kellogg","MN",44.3,-91.99,42396167],
		["Littlefork","MN",48.39,-93.55,42396357],
		["Enderlin","ND",46.62,-97.59,42396547],
		["Rock City","IL",42.41,-89.47,42396737],
		["Sheffield","IL",41.35,-89.73,42396927],
		["Illiopolis","IL",39.85,-89.24,42397117],
		["Thayer","KS",37.48,-95.47,42397307],
		["Enterprise","KS",38.9,-97.11,42397497],
		["Cairo","NE",41,-98.6,42397687],
		["Dulac","LA",29.36,-90.7,42397877],
		["Sadler","TX",33.68,-96.84,42398067],
		["Covington","TX",32.17,-97.25,42398257],
		["Ropesville","TX",33.41,-102.15,42398447],
		["Henefer","UT",40.97,-111.51,42398637],
		["Lapoint","UT",40.39,-109.84,42398827],
		["Honeyville","UT",41.64,-112.09,42399017],
		["Newton","UT",41.87,-111.99,42399207],
		["Pilot Hill","CA",38.8,-121.04,42399397],
		["Lopez Island","WA",48.49,-122.87,42399587],
		["Palouse","WA",46.88,-117.15,42399777],
		["Ossipee","NH",43.68,-71.11,42399966],
		["Ferrisburgh","VT",44.2,-73.25,42400155],
		["Lavallette","NJ",39.96,-74.06,42400344],
		["Fort Montgomery","NY",41.33,-73.98,42400533],
		["Franklin","NY",42.34,-75.16,42400722],
		["Ickesburg","PA",40.49,-77.29,42400911],
		["Valley View","PA",39.94,-76.7,42401100],
		["Toughkenamon","PA",39.83,-75.75,42401289],
		["Mason","WV",39.01,-82.02,42401478],
		["Lawsonville","NC",36.51,-80.22,42401667],
		["Macon","NC",36.43,-78.08,42401856],
		["Clarks Hill","SC",33.63,-82.14,42402045],
		["Ramer","AL",32.07,-86.24,42402234],
		["Ruth","MS",31.37,-90.29,42402423],
		["Hazel","KY",36.5,-88.32,42402612],
		["Haskins","OH",41.46,-83.7,42402801],
		["Hopewell","OH",39.96,-82.17,42402990],
		["Fowler","OH",41.33,-80.6,42403179],
		["Ellsworth","MI",45.16,-85.24,42403368],
		["Millersburg","MI",45.33,-84.06,42403557],
		["Champion","MI",46.52,-87.97,42403746],
		["Hospers","IA",43.07,-95.9,42403935],
		["Clarence","IA",41.88,-91.05,42404124],
		["Benton","WI",42.57,-90.38,42404313],
		["Wauzeka","WI",43.08,-90.89,42404502],
		["Poplar","WI",46.58,-91.78,42404691],
		["Floodwood","MN",46.92,-92.91,42404880],
		["Wanamingo","MN",44.3,-92.79,42405069],
		["Pennock","MN",45.14,-95.17,42405258],
		["Colton","SD",43.78,-96.92,42405447],
		["Hankinson","ND",46.07,-96.89,42405636],
		["Minot Afb","ND",48.41,-101.33,42405825],
		["Cornell","IL",40.99,-88.72,42406014],
		["Colfax","IL",40.56,-88.61,42406203],
		["Blue Eye","MO",36.49,-93.39,42406392],
		["Bois D Arc","MO",37.21,-93.55,42406581],
		["Caulfield","MO",36.61,-92.1,42406770],
		["Waverly","KS",38.39,-95.6,42406959],
		["Osceola","NE",41.17,-97.54,42407148],
		["Morganza","LA",30.73,-91.59,42407337],
		["Eckert","CO",38.84,-107.95,42407526],
		["Penasco","NM",36.17,-105.69,42407715],
		["Playa Vista","CA",33.97,-118.43,42407904],
		["Sutton","AK",61.86,-148.29,42408093],
		["Erving","MA",42.6,-72.4,42408281],
		["Lincoln","NH",44.04,-71.67,42408469],
		["Cornish","ME",43.8,-70.8,42408657],
		["Lee","ME",45.36,-68.28,42408845],
		["Deer Isle","ME",44.22,-68.67,42409033],
		["East Chatham","NY",42.42,-73.48,42409221],
		["Fallsburg","NY",41.73,-74.6,42409409],
		["Sidney Center","NY",42.29,-75.25,42409597],
		["West Decatur","PA",40.94,-78.36,42409785],
		["Millboro","VA",37.96,-79.6,42409973],
		["Glen Daniel","WV",37.77,-81.33,42410161],
		["Gatesville","NC",36.4,-76.75,42410349],
		["Bartow","GA",32.88,-82.47,42410537],
		["Meridian","MS",32.38,-88.71,42410725],
		["Mantee","MS",33.68,-89.02,42410913],
		["New Castle","KY",38.43,-85.17,42411101],
		["Reynolds Station","KY",37.69,-86.77,42411289],
		["Leavenworth","IN",38.2,-86.34,42411477],
		["Sodus","MI",42.03,-86.38,42411665],
		["Three Lakes","WI",45.79,-89.16,42411853],
		["Gibbon","MN",44.53,-94.52,42412041],
		["Lamberton","MN",44.22,-95.26,42412229],
		["Hancock","MN",45.49,-95.79,42412417],
		["Ottertail","MN",46.42,-95.55,42412605],
		["Raymond","IL",39.32,-89.57,42412793],
		["Elmwood","NE",40.84,-96.29,42412981],
		["Hammond","OR",46.17,-123.97,42413169],
		["Chester","MA",42.28,-72.98,42413356],
		["Barnstable","MA",41.7,-70.3,42413543],
		["Waterford","ME",44.18,-70.71,42413730],
		["Circleville","NY",41.52,-74.38,42413917],
		["Westport","NY",44.17,-73.43,42414104],
		["Gerry","NY",42.21,-79.16,42414291],
		["Amity","PA",40.05,-80.2,42414478],
		["Corsica","PA",41.18,-79.2,42414665],
		["Weems","VA",37.65,-76.44,42414852],
		["Hickory Grove","SC",34.98,-81.41,42415039],
		["Pineland","SC",32.6,-81.15,42415226],
		["Talmo","GA",34.18,-83.71,42415413],
		["Pitts","GA",31.94,-83.54,42415600],
		["Panacea","FL",30.03,-84.38,42415787],
		["Caryville","FL",30.77,-85.81,42415974],
		["Intercession City","FL",28.26,-81.5,42416161],
		["Flat Rock","IN",39.39,-85.68,42416348],
		["Luther","MI",44.03,-85.68,42416535],
		["Redfield","IA",41.59,-94.19,42416722],
		["Sully","IA",41.57,-92.84,42416909],
		["Butler","WI",43.1,-88.07,42417096],
		["Stetsonville","WI",45.07,-90.31,42417283],
		["Vesper","WI",44.48,-89.96,42417470],
		["Alcester","SD",43.02,-96.62,42417657],
		["Dupree","SD",45.04,-101.6,42417844],
		["Tioga","ND",48.39,-102.93,42418031],
		["Hays","MT",48.07,-108.74,42418218],
		["Carbon Cliff","IL",41.49,-90.39,42418405],
		["White Heath","IL",40.08,-88.5,42418592],
		["Riverton","KS",37.07,-94.7,42418779],
		["Kenesaw","NE",40.61,-98.65,42418966],
		["New Harmony","UT",37.38,-113.32,42419153],
		["Porter","ME",43.79,-70.93,42419339],
		["Malaga","NJ",39.57,-75.06,42419525],
		["Freehold","NY",42.38,-74.06,42419711],
		["Poughkeepsie","NY",41.69,-73.92,42419897],
		["Davidsville","PA",40.23,-78.93,42420083],
		["Rhodesdale","MD",38.59,-75.79,42420269],
		["Clinchco","VA",37.12,-82.33,42420455],
		["Hanover","WV",37.56,-81.81,42420641],
		["Pittsview","AL",32.16,-85.12,42420827],
		["New Marshfield","OH",39.33,-82.23,42421013],
		["Grover Hill","OH",41.01,-84.47,42421199],
		["Orland","IN",41.73,-85.17,42421385],
		["Underwood","IN",38.59,-85.78,42421571],
		["Dayton","IN",40.37,-86.77,42421757],
		["Muir","MI",42.99,-84.93,42421943],
		["Arkansaw","WI",44.62,-92.06,42422129],
		["Milltown","WI",45.52,-92.49,42422315],
		["Claremont","MN",44.04,-92.99,42422501],
		["Garfield","MN",45.94,-95.49,42422687],
		["Northwood","ND",47.73,-97.56,42422873],
		["Roseville","IL",40.73,-90.66,42423059],
		["Sparland","IL",41.03,-89.44,42423245],
		["Sibley","MO",39.17,-94.19,42423431],
		["Haviland","KS",37.61,-99.1,42423617],
		["Quinter","KS",39.06,-100.23,42423803],
		["Malcolm","NE",40.9,-96.86,42423989],
		["Granite","OK",34.96,-99.37,42424175],
		["Oregon House","CA",39.34,-121.23,42424361],
		["Lowell","OR",43.85,-122.69,42424547],
		["Odessa","WA",47.34,-118.66,42424733],
		["Housatonic","MA",42.25,-73.36,42424918],
		["Effingham","NH",43.71,-71,42425103],
		["Sebago","ME",43.89,-70.64,42425288],
		["Bolton Landing","NY",43.55,-73.65,42425473],
		["Port Henry","NY",44.04,-73.46,42425658],
		["Mc Donough","NY",42.49,-75.76,42425843],
		["East Concord","NY",42.55,-78.6,42426028],
		["Silver Springs","NY",42.66,-78.08,42426213],
		["Philadelphia","PA",39.95,-75.16,42426398],
		["Virginia Beach","VA",36.73,-76.04,42426583],
		["Belleville","WV",39.12,-81.67,42426768],
		["Coosada","AL",32.49,-86.33,42426953],
		["Palmer","TN",35.35,-85.56,42427138],
		["Norwich","OH",39.98,-81.79,42427323],
		["Florence","IN",38.82,-84.93,42427508],
		["Doon","IA",43.27,-96.23,42427693],
		["Neopit","WI",44.98,-88.82,42427878],
		["Curtiss","WI",44.95,-90.43,42428063],
		["Goodfield","IL",40.62,-89.27,42428248],
		["Franklin","IL",39.62,-90.04,42428433],
		["Assaria","KS",38.68,-97.6,42428618],
		["Atwood","KS",39.81,-101.04,42428803],
		["Cheneyville","LA",31.01,-92.28,42428988],
		["Moss Landing","CA",36.84,-121.77,42429173],
		["Princeville","HI",22.22,-159.48,42429358],
		["Bradford","ME",45.06,-68.93,42429542],
		["Phillips","ME",44.82,-70.34,42429726],
		["Craryville","NY",42.16,-73.65,42429910],
		["Schroon Lake","NY",43.83,-73.73,42430094],
		["Hickory","PA",40.3,-80.32,42430278],
		["Shacklefords","VA",37.54,-76.73,42430462],
		["Boykins","VA",36.57,-77.19,42430646],
		["Buffalo Junction","VA",36.6,-78.63,42430830],
		["Carlisle","SC",34.59,-81.46,42431014],
		["Five Points","AL",33.01,-85.35,42431198],
		["Duff","TN",36.42,-83.99,42431382],
		["Luna Pier","MI",41.8,-83.44,42431566],
		["Grand Junction","IA",42.03,-94.23,42431750],
		["Alburnett","IA",42.14,-91.61,42431934],
		["Deerbrook","WI",45.23,-89.16,42432118],
		["Kasota","MN",44.29,-93.96,42432302],
		["Elkton","SD",44.23,-96.47,42432486],
		["Kila","MT",48.06,-114.5,42432670],
		["Fairmount","IL",40.04,-87.82,42432854],
		["Ridge Farm","IL",39.89,-87.65,42433038],
		["Farina","IL",38.83,-88.77,42433222],
		["Lancaster","MO",40.52,-92.53,42433406],
		["Keatchie","LA",32.15,-93.96,42433590],
		["Emmet","AR",33.72,-93.46,42433774],
		["Julesburg","CO",40.98,-102.26,42433958],
		["Austin","CO",38.78,-107.93,42434142],
		["Donnelly","ID",44.67,-116,42434326],
		["Angel Fire","NM",36.39,-105.27,42434510],
		["Owyhee","NV",41.91,-116.18,42434694],
		["Curlew","WA",48.87,-118.63,42434878],
		["West Burke","VT",44.64,-71.98,42435061],
		["Jay","NY",44.35,-73.71,42435244],
		["Laurens","NY",42.53,-75.08,42435427],
		["Tidioute","PA",41.68,-79.4,42435610],
		["Irvona","PA",40.77,-78.55,42435793],
		["Covington","PA",41.74,-77.07,42435976],
		["Avis","PA",41.18,-77.31,42436159],
		["Cobbs Creek","VA",37.5,-76.39,42436342],
		["Lakemont","GA",34.78,-83.41,42436525],
		["Chester","GA",32.39,-83.15,42436708],
		["Fleming","GA",31.88,-81.42,42436891],
		["Malone","FL",30.95,-85.16,42437074],
		["Mount Hope","AL",34.4,-87.44,42437257],
		["Trezevant","TN",36.01,-88.62,42437440],
		["Metcalfe","MS",33.45,-91,42437623],
		["Marion","MS",32.42,-88.64,42437806],
		["Vicco","KY",37.21,-83.06,42437989],
		["Petersburg","OH",40.9,-80.54,42438172],
		["Thurman","OH",38.92,-82.43,42438355],
		["Francisco","IN",38.33,-87.44,42438538],
		["New Hartford","IA",42.56,-92.62,42438721],
		["New Vienna","IA",42.54,-91.11,42438904],
		["Bloomington","WI",42.89,-90.92,42439087],
		["De Soto","WI",43.42,-91.19,42439270],
		["Shevlin","MN",47.53,-95.25,42439453],
		["Towanda","IL",40.56,-88.9,42439636],
		["Westphalia","MO",38.44,-92,42439819],
		["Theriot","LA",29.36,-91.01,42440002],
		["Epps","LA",32.6,-91.47,42440185],
		["Doddridge","AR",33.11,-93.95,42440368],
		["Norman","AR",34.45,-93.67,42440551],
		["De Valls Bluff","AR",34.78,-91.46,42440734],
		["Mannsville","OK",34.18,-96.88,42440917],
		["Streetman","TX",31.87,-96.32,42441100],
		["Coupland","TX",30.46,-97.38,42441283],
		["Grand Lake","CO",40.25,-105.82,42441466],
		["Gilcrest","CO",40.28,-104.78,42441649],
		["Ririe","ID",43.49,-111.58,42441832],
		["Cloudcroft","NM",32.95,-105.73,42442015],
		["Columbia","CA",38.11,-120.38,42442198],
		["Rough And Ready","CA",39.22,-121.15,42442381],
		["Scotts Mills","OR",44.96,-122.57,42442564],
		["Royalston","MA",42.68,-72.18,42442746],
		["Fairfield","VT",44.8,-72.95,42442928],
		["Buskirk","NY",42.96,-73.45,42443110],
		["Clements","MD",38.32,-76.72,42443292],
		["Eleanor","WV",38.53,-81.93,42443474],
		["Nettie","WV",38.19,-80.67,42443656],
		["Joanna","SC",34.41,-81.81,42443838],
		["Chula","GA",31.54,-83.54,42444020],
		["Milton","TN",35.92,-86.17,42444202],
		["Langley","KY",37.53,-82.79,42444384],
		["Woodburn","KY",36.84,-86.52,42444566],
		["Croton","OH",40.23,-82.7,42444748],
		["Union","MI",41.78,-85.86,42444930],
		["Delhi","IA",42.43,-91.33,42445112],
		["Porterfield","WI",45.15,-87.8,42445294],
		["Bancroft","WI",44.31,-89.51,42445476],
		["Babbitt","MN",47.64,-91.94,42445658],
		["Erskine","MN",47.66,-96.01,42445840],
		["Newell","SD",44.71,-103.41,42446022],
		["Pender","NE",42.11,-96.71,42446204],
		["Sentinel","OK",35.15,-99.17,42446386],
		["Crowell","TX",33.98,-99.72,42446568],
		["Cascade","CO",38.9,-104.97,42446750],
		["Colorado Springs","CO",38.86,-104.76,42446932],
		["Ducor","CA",35.86,-118.99,42447114],
		["Gualala","CA",38.82,-123.51,42447296],
		["New Haven","CT",41.31,-72.92,42447477],
		["Northfield","CT",41.71,-73.12,42447658],
		["Water Mill","NY",40.92,-72.34,42447839],
		["Stahlstown","PA",40.13,-79.29,42448020],
		["Pocono Pines","PA",41.11,-75.45,42448201],
		["Mayking","KY",37.13,-82.76,42448382],
		["Springport","IN",40.04,-85.39,42448563],
		["Coal City","IN",39.24,-86.98,42448744],
		["Port Austin","MI",44.04,-82.99,42448925],
		["Odebolt","IA",42.31,-95.25,42449106],
		["Eldon","IA",40.91,-92.21,42449287],
		["Letts","IA",41.33,-91.23,42449468],
		["South Wayne","WI",42.56,-89.88,42449649],
		["Sabin","MN",46.78,-96.65,42449830],
		["Lamoure","ND",46.37,-98.28,42450011],
		["Venice","IL",38.67,-90.16,42450192],
		["Herrick","IL",39.21,-88.98,42450373],
		["Henley","MO",38.37,-92.3,42450554],
		["Grovespring","MO",37.39,-92.6,42450735],
		["Humboldt","NE",40.16,-95.94,42450916],
		["Saint Landry","LA",30.85,-92.4,42451097],
		["Horseshoe Bend","AR",36.22,-91.74,42451278],
		["Walnut Springs","TX",32.05,-97.75,42451459],
		["Aurora","UT",38.91,-111.93,42451640],
		["Tombstone","AZ",31.71,-110.04,42451821],
		["Newport Beach","CA",33.6,-117.89,42452002],
		["Scotia","CA",40.42,-124.01,42452183],
		["Pahala","HI",19.2,-155.48,42452364],
		["Feura Bush","NY",42.57,-73.88,42452544],
		["North Lawrence","NY",44.76,-74.65,42452724],
		["Odessa","NY",42.33,-76.78,42452904],
		["Penfield","PA",41.21,-78.6,42453084],
		["Beaver Springs","PA",40.74,-77.22,42453264],
		["Washington","DC",38.89,-77.03,42453444],
		["New Haven","WV",38.98,-81.96,42453624],
		["Dundee","MS",34.51,-90.45,42453804],
		["Essex","IA",40.83,-95.3,42453984],
		["Brighton","IA",41.17,-91.82,42454164],
		["Seymour","IA",40.68,-93.12,42454344],
		["Montfort","WI",42.97,-90.43,42454524],
		["Almena","WI",45.41,-92.03,42454704],
		["Altura","MN",44.06,-91.93,42454884],
		["Newfolden","MN",48.35,-96.32,42455064],
		["Oakland","IL",39.65,-88.02,42455244],
		["Grant City","MO",40.48,-94.41,42455424],
		["Lowry City","MO",38.14,-93.72,42455604],
		["Pretty Prairie","KS",37.78,-98.01,42455784],
		["Spearville","KS",37.84,-99.75,42455964],
		["Rushville","NE",42.71,-102.46,42456144],
		["Asher","OK",34.98,-96.92,42456324],
		["Thornton","TX",31.41,-96.57,42456504],
		["Mendocino","CA",39.3,-123.73,42456684],
		["Olympic Valley","CA",39.19,-120.22,42456864],
		["Taholah","WA",47.31,-124.22,42457044],
		["Ashfield","MA",42.53,-72.78,42457223],
		["Hancock","NH",42.96,-71.98,42457402],
		["Spofford","NH",42.9,-72.4,42457581],
		["Hudson","ME",45,-68.88,42457760],
		["Mount Upton","NY",42.42,-75.38,42457939],
		["Marianna","PA",40.01,-80.11,42458118],
		["Boyers","PA",41.11,-79.9,42458297],
		["Swoope","VA",38.14,-79.2,42458476],
		["Matewan","WV",37.62,-82.16,42458655],
		["Letohatchee","AL",32.08,-86.51,42458834],
		["Doyle","TN",35.85,-85.51,42459013],
		["Belton","KY",37.14,-86.96,42459192],
		["Rudolph","OH",41.29,-83.7,42459371],
		["Mendon","OH",40.67,-84.51,42459550],
		["Trufant","MI",43.31,-85.35,42459729],
		["Pacific Junction","IA",41.01,-95.79,42459908],
		["Durango","IA",42.56,-90.77,42460087],
		["Cazenovia","WI",43.52,-90.19,42460266],
		["Milladore","WI",44.6,-89.85,42460445],
		["Trimont","MN",43.76,-94.71,42460624],
		["Viborg","SD",43.17,-97.08,42460803],
		["Andalusia","IL",41.43,-90.72,42460982],
		["Grand Ridge","IL",41.23,-88.83,42461161],
		["Rosiclare","IL",37.42,-88.35,42461340],
		["Arcadia","MO",37.58,-90.62,42461519],
		["Emerson","NE",42.27,-96.72,42461698],
		["Collinston","LA",32.68,-91.87,42461877],
		["Clayton","LA",31.72,-91.53,42462056],
		["Holly Grove","AR",34.59,-91.2,42462235],
		["Elfrida","AZ",31.73,-109.7,42462414],
		["Fall River Mills","CA",41.01,-121.47,42462593],
		["Copper Center","AK",61.68,-145.14,42462772],
		["Newton Lower Falls","MA",42.33,-71.25,42462950],
		["Center Tuftonboro","NH",43.71,-71.25,42463128],
		["Downsville","NY",42.08,-74.99,42463306],
		["Buffalo","NY",42.88,-78.85,42463484],
		["Stillwater","PA",41.15,-76.36,42463662],
		["Friendsville","PA",41.91,-76.04,42463840],
		["Fairplay","MD",39.53,-77.74,42464018],
		["Phenix","VA",37.08,-78.74,42464196],
		["Engelhard","NC",35.54,-76.02,42464374],
		["Elora","TN",35.01,-86.35,42464552],
		["Gadsden","TN",35.77,-88.98,42464730],
		["Sedalia","KY",36.56,-88.58,42464908],
		["Hopedale","OH",40.32,-80.89,42465086],
		["West Manchester","OH",39.9,-84.62,42465264],
		["Mauckport","IN",38.02,-86.2,42465442],
		["Prentice","WI",45.54,-90.28,42465620],
		["Mindoro","WI",44.01,-91.09,42465798],
		["Coleraine","MN",47.3,-93.42,42465976],
		["Embarrass","MN",47.65,-92.19,42466154],
		["Evansville","MN",46,-95.68,42466332],
		["Hatton","ND",47.63,-97.45,42466510],
		["Killdeer","ND",47.36,-102.75,42466688],
		["Trout Creek","MT",47.76,-115.63,42466866],
		["Hillsdale","IL",41.61,-90.17,42467044],
		["Lerna","IL",39.41,-88.28,42467222],
		["Pittsburg","IL",37.77,-88.85,42467400],
		["Mullin","TX",31.55,-98.66,42467578],
		["El Paso","TX",31.84,-106.43,42467756],
		["Friant","CA",37.02,-119.69,42467934],
		["Bayside","CA",40.81,-124.01,42468112],
		["Randle","WA",46.47,-121.82,42468290],
		["Mountain Village","AK",62.09,-163.71,42468468],
		["Bradley","ME",44.92,-68.62,42468645],
		["Sprakers","NY",42.83,-74.45,42468822],
		["Scipio Center","NY",42.78,-76.55,42468999],
		["Lowman","NY",42.02,-76.72,42469176],
		["Richlandtown","PA",40.47,-75.32,42469353],
		["Sandy Hook","VA",37.75,-77.91,42469530],
		["Keeling","VA",36.71,-79.3,42469707],
		["Como","NC",36.49,-77.01,42469884],
		["Leslie","GA",31.95,-84.08,42470061],
		["Louisville","AL",31.78,-85.55,42470238],
		["Leroy","AL",31.5,-87.98,42470415],
		["Chunky","MS",32.32,-88.92,42470592],
		["Fletcher","OH",40.14,-84.11,42470769],
		["Carbon","IN",39.59,-87.1,42470946],
		["Elwell","MI",43.42,-84.76,42471123],
		["Sioux Rapids","IA",42.89,-95.14,42471300],
		["Saint Cloud","WI",43.82,-88.16,42471477],
		["Merrillan","WI",44.45,-90.83,42471654],
		["Hayti","SD",44.65,-97.2,42471831],
		["Howard","SD",44.01,-97.52,42472008],
		["Heron","MT",47.99,-115.9,42472185],
		["Atkinson","IL",41.41,-90.01,42472362],
		["Hoyleton","IL",38.44,-89.27,42472539],
		["Elizabethtown","IL",37.45,-88.3,42472716],
		["Claflin","KS",38.52,-98.53,42472893],
		["Deerfield","KS",37.98,-101.13,42473070],
		["Sasakwa","OK",34.94,-96.52,42473247],
		["Robert Lee","TX",31.89,-100.48,42473424],
		["Encinal","TX",28.04,-99.35,42473601],
		["White Deer","TX",35.43,-101.17,42473778],
		["Bluff","UT",37.14,-109.52,42473955],
		["Velarde","NM",36.15,-105.97,42474132],
		["Fort Johnson","NY",42.95,-74.23,42474308],
		["Clarksburg","PA",40.49,-79.36,42474484],
		["James Creek","PA",40.33,-78.18,42474660],
		["Hustontown","PA",40.03,-78.01,42474836],
		["Check","VA",37.05,-80.18,42475012],
		["Mathias","WV",38.87,-78.86,42475188],
		["Ferguson","NC",36.13,-81.41,42475364],
		["Charleston Afb","SC",32.9,-80.06,42475540],
		["Turtletown","TN",35.12,-84.35,42475716],
		["Pittsboro","MS",33.94,-89.33,42475892],
		["Trenton","KY",36.72,-87.26,42476068],
		["Schleswig","IA",42.16,-95.43,42476244],
		["Knapp","WI",44.95,-92.07,42476420],
		["Grasston","MN",45.85,-93.08,42476596],
		["Scotland","SD",43.14,-97.71,42476772],
		["Springfield","SD",42.85,-97.89,42476948],
		["Queen City","MO",40.41,-92.57,42477124],
		["Blackwell","MO",38.04,-90.62,42477300],
		["Viola","KS",37.48,-97.64,42477476],
		["Paoli","OK",34.82,-97.26,42477652],
		["Milburn","OK",34.24,-96.55,42477828],
		["Canton","OK",36.05,-98.58,42478004],
		["Kiowa","OK",34.72,-95.9,42478180],
		["May","TX",31.97,-98.91,42478356],
		["Hamshire","TX",29.86,-94.31,42478532],
		["Happy","TX",34.74,-101.85,42478708],
		["Etna","WY",43.03,-111.01,42478884],
		["Morristown","AZ",33.98,-112.44,42479060],
		["Lyle","WA",45.72,-121.16,42479236],
		["Spangle","WA",47.44,-117.35,42479412],
		["Emmonak","AK",62.69,-162.96,42479588],
		["Eastford","CT",41.9,-72.08,42479763],
		["Shelter Island","NY",41.06,-72.32,42479938],
		["Palenville","NY",42.17,-74.01,42480113],
		["Leeper","PA",41.36,-79.29,42480288],
		["Osterburg","PA",40.18,-78.53,42480463],
		["Linville","VA",38.52,-78.83,42480638],
		["Tornado","WV",38.33,-81.85,42480813],
		["Fair Bluff","NC",34.31,-79.03,42480988],
		["Rosman","NC",35.14,-82.82,42481163],
		["Bybee","TN",36.04,-83.18,42481338],
		["Tremont","MS",34.23,-88.24,42481513],
		["Woodbine","KY",36.9,-84.08,42481688],
		["Fair Oaks","IN",41.07,-87.2,42481863],
		["Forestville","WI",44.69,-87.47,42482038],
		["Rose Creek","MN",43.6,-92.82,42482213],
		["Clarkfield","MN",44.79,-95.8,42482388],
		["Burtrum","MN",45.86,-94.68,42482563],
		["Gettysburg","SD",45,-99.95,42482738],
		["Mound City","MO",40.13,-95.23,42482913],
		["Koshkonong","MO",36.59,-91.64,42483088],
		["Dighton","KS",38.48,-100.46,42483263],
		["Jarreau","LA",30.63,-91.41,42483438],
		["Strawberry","AR",35.96,-91.32,42483613],
		["Saint Joe","AR",35.98,-92.81,42483788],
		["Fort Towson","OK",34.02,-95.29,42483963],
		["Tryon","OK",35.87,-96.96,42484138],
		["Mount Calm","TX",31.75,-96.88,42484313],
		["Carlsbad","TX",31.59,-100.63,42484488],
		["Deer Trail","CO",39.61,-104.04,42484663],
		["Manzanola","CO",38.1,-103.86,42484838],
		["Richfield","ID",43.07,-114.17,42485013],
		["Palo Alto","CA",37.37,-122.18,42485188],
		["Saint Regis Falls","NY",44.49,-74.53,42485362],
		["Smyrna","NY",42.68,-75.56,42485536],
		["Cooperstown","PA",41.49,-79.87,42485710],
		["Fort Valley","VA",38.84,-78.42,42485884],
		["Urbanna","VA",37.63,-76.57,42486058],
		["Verdunville","WV",37.85,-82.05,42486232],
		["Raleigh","NC",35.82,-78.65,42486406],
		["White Oak","GA",31.03,-81.82,42486580],
		["Seminole","AL",30.51,-87.47,42486754],
		["Sidney","KY",37.62,-82.35,42486928],
		["Fleming","OH",39.4,-81.64,42487102],
		["Stratford","IA",42.27,-93.92,42487276],
		["Lowden","IA",41.85,-90.92,42487450],
		["Amherst Junction","WI",44.46,-89.31,42487624],
		["Randolph","MN",44.52,-93.01,42487798],
		["Palmyra","IL",39.43,-89.99,42487972],
		["Roach","MO",37.99,-92.83,42488146],
		["Franklin","NE",40.09,-98.95,42488320],
		["Waco","TX",31.56,-97.18,42488494],
		["Asherton","TX",28.44,-99.76,42488668],
		["Knox City","TX",33.41,-99.81,42488842],
		["Springer","NM",36.36,-104.59,42489016],
		["Pioche","NV",38.1,-114.38,42489190],
		["Indian Wells","CA",33.7,-116.33,42489364],
		["Carmel By The Sea","CA",36.55,-121.92,42489538],
		["Bingen","WA",45.77,-121.66,42489712],
		["Coulee Dam","WA",48.03,-118.94,42489886],
		["West Newfield","ME",43.64,-70.92,42490059],
		["Washington","ME",44.27,-69.36,42490232],
		["Perkinsville","VT",43.36,-72.51,42490405],
		["Rosendale","NY",41.84,-74.06,42490578],
		["Stockton","NY",42.31,-79.38,42490751],
		["Cooksville","MD",39.31,-77,42490924],
		["Faber","VA",37.85,-78.75,42491097],
		["Worthington","WV",39.45,-80.26,42491270],
		["Council","NC",34.42,-78.46,42491443],
		["Coffeeville","AL",31.76,-88.08,42491616],
		["Raywick","KY",37.55,-85.43,42491789],
		["Clarksburg","OH",39.5,-83.15,42491962],
		["Duncan Falls","OH",39.87,-81.92,42492135],
		["Kalida","OH",40.98,-84.19,42492308],
		["Goodland","IN",40.76,-87.29,42492481],
		["West Bend","IA",42.96,-94.44,42492654],
		["Correctionville","IA",42.47,-95.78,42492827],
		["Cedar Rapids","IA",41.97,-91.66,42493000],
		["Farmington","IA",40.63,-91.73,42493173],
		["Hillpoint","WI",43.38,-90.16,42493346],
		["Mabel","MN",43.52,-91.76,42493519],
		["Murdock","MN",45.22,-95.39,42493692],
		["Cannon Ball","ND",46.31,-100.63,42493865],
		["Hanover","IL",42.25,-90.27,42494038],
		["Illinois City","IL",41.4,-90.9,42494211],
		["Medora","IL",39.17,-90.14,42494384],
		["Glenallen","MO",37.31,-90.02,42494557],
		["Sedgewickville","MO",37.51,-89.9,42494730],
		["Olpe","KS",38.26,-96.16,42494903],
		["Hoxie","KS",39.35,-100.43,42495076],
		["Garber","OK",36.43,-97.58,42495249],
		["Quemado","TX",28.95,-100.63,42495422],
		["San Luis","CO",37.2,-105.42,42495595],
		["Grand View","ID",42.91,-116.12,42495768],
		["Neola","UT",40.41,-109.99,42495941],
		["Blue Lake","CA",40.96,-123.85,42496114],
		["Redway","CA",40.15,-123.89,42496287],
		["Alton Bay","NH",43.48,-71.24,42496459],
		["Medway","ME",45.6,-68.53,42496631],
		["Columbus","PA",41.95,-79.58,42496803],
		["Red Oak","VA",36.76,-78.63,42496975],
		["Glen Easton","WV",39.83,-80.66,42497147],
		["Wanchese","NC",35.83,-75.64,42497319],
		["Drexel","NC",35.75,-81.6,42497491],
		["Ehrhardt","SC",33.09,-81.01,42497663],
		["Montrose","GA",32.56,-83.15,42497835],
		["Whatley","AL",31.65,-87.62,42498007],
		["Pulaski","MS",32.27,-89.63,42498179],
		["Fountain Run","KY",36.72,-85.96,42498351],
		["Austinburg","OH",41.75,-80.85,42498523],
		["Modoc","IN",40.04,-85.12,42498695],
		["Fairview","MI",44.71,-84.05,42498867],
		["Coral","MI",43.36,-85.4,42499039],
		["Milton","IA",40.67,-92.16,42499211],
		["Eldorado","WI",43.84,-88.64,42499383],
		["Bigfork","MN",47.74,-93.65,42499555],
		["Centerville","SD",43.11,-96.95,42499727],
		["Mapleton","ND",46.89,-97.05,42499899],
		["Ringwood","IL",42.4,-88.29,42500071],
		["Thomson","IL",41.95,-90.1,42500243],
		["Oak Ridge","MO",37.49,-89.72,42500415],
		["Cottonwood Falls","KS",38.36,-96.54,42500587],
		["Ashland","KS",37.18,-99.76,42500759],
		["Benkelman","NE",40.05,-101.53,42500931],
		["Norfork","AR",36.2,-92.28,42501103],
		["Mountain View","OK",35.09,-98.75,42501275],
		["Tioga","TX",33.47,-96.91,42501447],
		["Vega","TX",35.24,-102.42,42501619],
		["Stratton","CO",39.3,-102.6,42501791],
		["Vernon","AZ",34.22,-109.64,42501963],
		["Cedarpines Park","CA",34.25,-117.32,42502135],
		["Raymond","CA",37.25,-119.96,42502307],
		["Port Orford","OR",42.72,-124.35,42502479],
		["Winchester","OR",43.29,-123.27,42502651],
		["Bayamon","PR",18.28,-66.13,42502822],
		["Lovell","ME",44.12,-70.89,42502993],
		["Newcastle","ME",44.03,-69.53,42503164],
		["Dorset","VT",43.25,-73.09,42503335],
		["Marshfield","VT",44.35,-72.35,42503506],
		["Bridport","VT",43.98,-73.32,42503677],
		["Waddington","NY",44.85,-75.19,42503848],
		["Himrod","NY",42.58,-76.95,42504019],
		["Aaronsburg","PA",40.86,-77.39,42504190],
		["Kinsale","VA",38.02,-76.57,42504361],
		["Vernon Hill","VA",36.79,-79.12,42504532],
		["Victor","WV",38.14,-81.03,42504703],
		["Atlantic Beach","NC",34.7,-76.73,42504874],
		["Arnoldsville","GA",33.91,-83.21,42505045],
		["Mitchell","GA",33.21,-82.7,42505216],
		["Sumner","GA",31.51,-83.74,42505387],
		["Seville","FL",29.31,-81.49,42505558],
		["Bascom","FL",30.92,-85.11,42505729],
		["Orlinda","TN",36.6,-86.67,42505900],
		["Merigold","MS",33.83,-90.72,42506071],
		["Spottsville","KY",37.82,-87.42,42506242],
		["Clarington","OH",39.77,-80.86,42506413],
		["Indianapolis","IN",39.77,-86.14,42506584],
		["Milton","IN",39.78,-85.15,42506755],
		["Bentley","MI",43.94,-84.13,42506926],
		["Schaller","IA",42.49,-95.29,42507097],
		["Primghar","IA",43.08,-95.62,42507268],
		["Newhall","IA",41.99,-91.96,42507439],
		["Pine River","WI",44.15,-89.08,42507610],
		["Balaton","MN",44.23,-95.87,42507781],
		["Humboldt","SD",43.64,-97.07,42507952],
		["Stronghurst","IL",40.74,-90.9,42508123],
		["Wentworth","MO",36.99,-94.07,42508294],
		["Kaiser","MO",38.13,-92.58,42508465],
		["Madison","KS",38.13,-96.13,42508636],
		["Offutt A F B","NE",41.11,-95.9,42508807],
		["Denton","NE",40.73,-96.84,42508978],
		["Clarkson","NE",41.72,-97.12,42509149],
		["Shelby","NE",41.19,-97.42,42509320],
		["Ama","LA",29.85,-90.28,42509491],
		["Mer Rouge","LA",32.77,-91.79,42509662],
		["Grandfield","OK",34.23,-98.68,42509833],
		["Dover","OK",35.98,-97.91,42510004],
		["Gilbertville","MA",42.35,-72.21,42510174],
		["Boston","MA",42.35,-71.06,42510344],
		["Easton","ME",46.64,-67.91,42510514],
		["North Ferrisburgh","VT",44.24,-73.19,42510684],
		["Dravosburg","PA",40.35,-79.88,42510854],
		["Cranberry","PA",41.35,-79.72,42511024],
		["Topping","VA",37.59,-76.46,42511194],
		["Natural Bridge Station","VA",37.58,-79.5,42511364],
		["Burnsville","WV",38.85,-80.65,42511534],
		["Green Mountain","NC",36.01,-82.31,42511704],
		["Orlando","FL",28.5,-81.37,42511874],
		["Gore Springs","MS",33.71,-89.57,42512044],
		["Pachuta","MS",32.04,-88.88,42512214],
		["Allen","MI",41.95,-84.76,42512384],
		["Elma","IA",43.24,-92.44,42512554],
		["Lake View","IA",42.3,-95.04,42512724],
		["Pembine","WI",45.63,-88,42512894],
		["Chaseburg","WI",43.65,-91.09,42513064],
		["Duluth","MN",46.78,-92.11,42513234],
		["Solway","MN",47.52,-95.12,42513404],
		["Marion","SD",43.42,-97.26,42513574],
		["Bonner","MT",46.95,-113.57,42513744],
		["Chadwick","IL",42.01,-89.88,42513914],
		["Reynolds","IL",41.33,-90.67,42514084],
		["Ladd","IL",41.38,-89.21,42514254],
		["Prairie Du Rocher","IL",38.08,-90.09,42514424],
		["Ewing","MO",40,-91.71,42514594],
		["Novinger","MO",40.23,-92.7,42514764],
		["Copeland","KS",37.54,-100.62,42514934],
		["Axtell","NE",40.47,-99.12,42515104],
		["Palmetto","LA",30.71,-91.9,42515274],
		["Natchez","LA",31.67,-93.04,42515444],
		["Mount Vernon","AR",35.22,-92.13,42515614],
		["Garvin","OK",33.95,-94.94,42515784],
		["Gans","OK",35.38,-94.69,42515954],
		["Burkeville","TX",30.99,-93.66,42516124],
		["Douglass","TX",31.65,-94.87,42516294],
		["Kress","TX",34.36,-101.74,42516464],
		["Buffalo Gap","TX",32.28,-99.83,42516634],
		["Minersville","UT",38.21,-112.93,42516804],
		["Cannon Beach","OR",45.92,-123.97,42516974],
		["Alakanuk","AK",62.44,-164.38,42517144],
		["Rangeley","ME",44.96,-70.64,42517313],
		["East Berlin","CT",41.61,-72.72,42517482],
		["Branchport","NY",42.59,-77.15,42517651],
		["Republic","PA",39.96,-79.87,42517820],
		["Ridge","MD",38.11,-76.37,42517989],
		["Cedar Grove","WV",38.22,-81.42,42518158],
		["Omar","WV",37.72,-82.01,42518327],
		["Crystal Beach","FL",28.08,-82.76,42518496],
		["Kennedy","AL",33.58,-87.98,42518665],
		["Mousie","KY",37.41,-82.87,42518834],
		["Negley","OH",40.76,-80.55,42519003],
		["North Benton","OH",40.98,-81.01,42519172],
		["Conover","OH",40.14,-84.03,42519341],
		["Kinde","MI",43.93,-82.99,42519510],
		["Delmar","IA",41.99,-90.6,42519679],
		["Van Horne","IA",42.01,-92.09,42519848],
		["Morton","MN",44.55,-94.98,42520017],
		["Cushing","MN",46.19,-94.57,42520186],
		["Vaughn","MT",47.59,-111.64,42520355],
		["Somers","MT",48.07,-114.12,42520524],
		["Dow","IL",39.04,-90.31,42520693],
		["Rushville","MO",39.58,-95.02,42520862],
		["Alma","NE",40.1,-99.36,42521031],
		["Pine Grove","LA",30.7,-90.77,42521200],
		["Leola","AR",34.16,-92.59,42521369],
		["Black Rock","AR",36.1,-91.1,42521538],
		["Sulphur Springs","AR",36.48,-94.45,42521707],
		["Bloomburg","TX",33.13,-94.05,42521876],
		["Batson","TX",30.24,-94.6,42522045],
		["Dove Creek","CO",37.76,-108.9,42522214],
		["Idaho City","ID",43.89,-115.76,42522383],
		["Kingston","ID",47.59,-116.24,42522552],
		["Santa Ysabel","CA",33.18,-116.73,42522721],
		["West Chesterfield","NH",42.87,-72.51,42522889],
		["Brockport","PA",41.27,-78.73,42523057],
		["Brenton","WV",37.58,-81.66,42523225],
		["Procious","WV",38.47,-81.22,42523393],
		["Great Cacapon","WV",39.62,-78.29,42523561],
		["Brandywine","WV",38.62,-79.24,42523729],
		["Green Pond","SC",32.73,-80.61,42523897],
		["Stephens","GA",33.77,-83.1,42524065],
		["Yatesville","GA",32.91,-84.14,42524233],
		["Pinetta","FL",30.59,-83.35,42524401],
		["Lester","AL",34.98,-87.14,42524569],
		["Lower Peach Tree","AL",31.84,-87.54,42524737],
		["Oakfield","TN",35.75,-88.79,42524905],
		["Silver Point","TN",36.09,-85.76,42525073],
		["Leary","GA",31.48,-84.5,42525241],
		["Jerusalem","OH",39.85,-81.09,42525409],
		["Daggett","MI",45.46,-87.6,42525577],
		["Elkhart","IA",41.79,-93.51,42525745],
		["Lime Springs","IA",43.45,-92.28,42525913],
		["Bay City","WI",44.58,-92.45,42526081],
		["Askov","MN",46.18,-92.78,42526249],
		["Surrey","ND",48.23,-101.13,42526417],
		["Chicago","IL",41.83,-87.68,42526585],
		["Monroe Center","IL",42.11,-89,42526753],
		["Parma","MO",36.61,-89.81,42526921],
		["Cherokee","KS",37.34,-94.82,42527089],
		["Utica","NE",40.89,-97.34,42527257],
		["Elgin","NE",41.98,-98.08,42527425],
		["Alda","NE",40.86,-98.46,42527593],
		["Oxford","NE",40.25,-99.63,42527761],
		["Lena","LA",31.46,-92.77,42527929],
		["Leesburg","TX",32.96,-95.1,42528097],
		["Mountain Home","TX",30.16,-99.36,42528265],
		["D Hanis","TX",29.35,-99.31,42528433],
		["East Wakefield","NH",43.64,-71,42528600],
		["Cumberland Foreside","ME",43.75,-70.2,42528767],
		["Howland","ME",45.25,-68.66,42528934],
		["Worcester","VT",44.37,-72.55,42529101],
		["Jobstown","NJ",40.03,-74.68,42529268],
		["Troupsburg","NY",42.04,-77.54,42529435],
		["War","WV",37.3,-81.68,42529602],
		["Julian","WV",38.13,-81.86,42529769],
		["Cadwell","GA",32.34,-83.04,42529936],
		["Rebecca","GA",31.8,-83.48,42530103],
		["Gravel Switch","KY",37.58,-85.05,42530270],
		["Kings Mountain","KY",37.36,-84.7,42530437],
		["Stopover","KY",37.52,-82.03,42530604],
		["Hamler","OH",41.23,-84.03,42530771],
		["Buffalo Lake","MN",44.73,-94.61,42530938],
		["Dover","MN",43.96,-92.13,42531105],
		["Brandon","MN",45.96,-95.59,42531272],
		["Hallock","MN",48.76,-96.94,42531439],
		["Faith","SD",45.02,-102.03,42531606],
		["New Underwood","SD",44.09,-102.83,42531773],
		["Berthold","ND",48.31,-101.73,42531940],
		["Gallatin Gateway","MT",45.26,-111.14,42532107],
		["Wataga","IL",41.02,-90.27,42532274],
		["Cardwell","MO",36.04,-90.29,42532441],
		["Stoutland","MO",37.81,-92.51,42532608],
		["Oxford","KS",37.27,-97.16,42532775],
		["Fordoche","LA",30.59,-91.61,42532942],
		["Baskin","LA",32.25,-91.74,42533109],
		["Amherst","TX",34.01,-102.41,42533276],
		["Gill","CO",40.45,-104.53,42533443],
		["Deary","ID",46.79,-116.51,42533610],
		["Bethel Island","CA",38.07,-121.62,42533777],
		["Occidental","CA",38.41,-123.01,42533944],
		["Pateros","WA",48.05,-120.05,42534111],
		["Talkeetna","AK",62.42,-148.76,42534278],
		["New Sharon","ME",44.63,-70.01,42534444],
		["Rockfall","CT",41.53,-72.69,42534610],
		["Gilboa","NY",42.39,-74.39,42534776],
		["Shokan","NY",41.97,-74.2,42534942],
		["Georgetown","NY",42.61,-75.81,42535108],
		["Edwards","NY",44.32,-75.25,42535274],
		["Swiftwater","PA",41.08,-75.36,42535440],
		["Bryn Athyn","PA",40.13,-75.06,42535606],
		["Catawba","VA",37.38,-80.11,42535772],
		["Griffithsville","WV",38.24,-81.99,42535938],
		["Riga","MI",41.78,-83.79,42536104],
		["Russell","IA",40.98,-93.2,42536270],
		["Elysian","MN",44.2,-93.67,42536436],
		["Rocky Mount","MO",38.27,-92.71,42536602],
		["Otterville","MO",38.7,-93,42536768],
		["Cedar Bluffs","NE",41.39,-96.6,42536934],
		["Brady","NE",41.02,-100.36,42537100],
		["Temple","OK",34.27,-98.23,42537266],
		["Bergheim","TX",29.81,-98.58,42537432],
		["Pettus","TX",28.57,-97.8,42537598],
		["Corpus Christi","TX",27.8,-97.39,42537764],
		["Guernsey","WY",42.26,-104.74,42537930],
		["Mc Neal","AZ",31.55,-109.69,42538096],
		["Bella Vista","CA",40.7,-122.09,42538262],
		["North Woodstock","NH",44.01,-71.73,42538427],
		["Shoreham","VT",43.9,-73.32,42538592],
		["Russell","NY",44.36,-75.04,42538757],
		["Orbisonia","PA",40.24,-77.89,42538922],
		["Hop Bottom","PA",41.7,-75.76,42539087],
		["Goldsboro","MD",39.03,-75.78,42539252],
		["Davis","WV",39.13,-79.46,42539417],
		["Rayle","GA",33.79,-82.9,42539582],
		["Sugar Valley","GA",34.55,-85.03,42539747],
		["Michigan City","MS",34.98,-89.25,42539912],
		["Rutland","OH",39.04,-82.12,42540077],
		["Holland","IN",38.24,-87.03,42540242],
		["Boswell","IN",40.51,-87.38,42540407],
		["Melbourne","IA",41.94,-93.1,42540572],
		["Livingston","WI",42.9,-90.43,42540737],
		["Dora","MO",36.77,-92.21,42540902],
		["Sterling","NE",40.46,-96.37,42541067],
		["Erwinville","LA",30.53,-91.4,42541232],
		["Ryan","OK",34.02,-97.95,42541397],
		["Scroggins","TX",33,-95.21,42541562],
		["Carey","ID",43.29,-113.64,42541727],
		["Aguila","AZ",33.81,-113.18,42541892],
		["Brimhall","NM",35.8,-108.57,42542057],
		["Dorris","CA",41.89,-121.74,42542222],
		["North Apollo","PA",40.59,-79.55,42542386],
		["Lenore","WV",37.8,-82.27,42542550],
		["Lester","WV",37.73,-81.3,42542714],
		["Cairo","WV",39.2,-81.15,42542878],
		["Independence","WV",39.44,-79.88,42543042],
		["Frametown","WV",38.62,-80.88,42543206],
		["Siloam","NC",36.28,-80.56,42543370],
		["Shiloh","NC",36.27,-76.08,42543534],
		["Badin","NC",35.4,-80.11,42543698],
		["Atlanta","GA",33.92,-84.34,42543862],
		["Mountain City","GA",34.91,-83.38,42544026],
		["Rives","TN",36.35,-89.04,42544190],
		["Gamaliel","KY",36.63,-85.79,42544354],
		["Tiltonsville","OH",40.17,-80.69,42544518],
		["Whipple","OH",39.48,-81.35,42544682],
		["Burnettsville","IN",40.76,-86.59,42544846],
		["Walkerville","MI",43.71,-86.12,42545010],
		["Hartford","IA",41.45,-93.4,42545174],
		["Moravia","IA",40.89,-92.81,42545338],
		["Deer Park","WI",45.18,-92.38,42545502],
		["Stewart","MN",44.72,-94.48,42545666],
		["Elmore","MN",43.5,-94.08,42545830],
		["Carlos","MN",45.97,-95.29,42545994],
		["Mount Vernon","SD",43.71,-98.26,42546158],
		["Cooperstown","ND",47.44,-98.12,42546322],
		["Pablo","MT",47.59,-114.11,42546486],
		["New Windsor","IL",41.2,-90.45,42546650],
		["Mc Lean","IL",40.31,-89.17,42546814],
		["Hale","MO",39.6,-93.34,42546978],
		["Harrisonburg","LA",31.76,-91.82,42547142],
		["Binger","OK",35.3,-98.34,42547306],
		["Tom Bean","TX",33.52,-96.48,42547470],
		["Cat Spring","TX",29.84,-96.32,42547634],
		["Stites","ID",46.08,-115.97,42547798],
		["Diablo","CA",37.83,-121.95,42547962],
		["West Stockbridge","MA",42.31,-73.39,42548125],
		["Petersham","MA",42.48,-72.18,42548288],
		["Ocean Grove","NJ",40.21,-74,42548451],
		["New Lebanon","NY",42.46,-73.39,42548614],
		["Austin","PA",41.63,-78.08,42548777],
		["Reeders","PA",40.99,-75.27,42548940],
		["Alkol","WV",38.17,-81.99,42549103],
		["Danese","WV",37.88,-80.97,42549266],
		["Mount Lookout","WV",38.17,-80.9,42549429],
		["Buxton","NC",35.24,-75.53,42549592],
		["Cades","SC",33.78,-79.78,42549755],
		["Olar","SC",33.18,-81.18,42549918],
		["Grandview","TN",35.74,-84.83,42550081],
		["Walhonding","OH",40.35,-82.14,42550244],
		["Alvada","OH",41.04,-83.39,42550407],
		["Eldorado","OH",39.9,-84.67,42550570],
		["Akron","MI",43.56,-83.51,42550733],
		["Honor","MI",44.66,-86.02,42550896],
		["Northport","MI",45.13,-85.61,42551059],
		["Adair","IA",41.5,-94.64,42551222],
		["Graettinger","IA",43.23,-94.75,42551385],
		["Shelby","IA",41.5,-95.45,42551548],
		["Jim Falls","WI",45.07,-91.26,42551711],
		["Nerstrand","MN",44.34,-93.06,42551874],
		["Minto","ND",48.29,-97.37,42552037],
		["Alexis","IL",41.06,-90.55,42552200],
		["Mansfield","IL",40.21,-88.5,42552363],
		["Stonington","IL",39.63,-89.19,42552526],
		["Nortonville","KS",39.41,-95.33,42552689],
		["Quenemo","KS",38.58,-95.52,42552852],
		["Henderson","NE",40.77,-97.81,42553015],
		["Crawford","NE",42.68,-103.41,42553178],
		["Roanoke","LA",30.23,-92.74,42553341],
		["Santa Maria","TX",26.1,-97.65,42553504],
		["Wink","TX",31.75,-103.15,42553667],
		["Creston","CA",35.47,-120.48,42553830],
		["Mars Hill","ME",46.51,-67.86,42553992],
		["Deansboro","NY",42.99,-75.42,42554154],
		["Oak City","NC",35.96,-77.3,42554316],
		["Pineview","GA",32.1,-83.5,42554478],
		["Sardis","TN",35.44,-88.29,42554640],
		["Parksville","KY",37.6,-84.9,42554802],
		["East Point","KY",37.74,-82.79,42554964],
		["Lost Creek","KY",37.48,-83.32,42555126],
		["Grethel","KY",37.49,-82.64,42555288],
		["Stone Creek","OH",40.39,-81.55,42555450],
		["Tiro","OH",40.9,-82.76,42555612],
		["Mill Creek","IN",41.53,-86.54,42555774],
		["Ramsey","IN",38.31,-86.15,42555936],
		["Pennville","IN",40.49,-85.14,42556098],
		["Oolitic","IN",38.89,-86.52,42556260],
		["Ireton","IA",42.97,-96.32,42556422],
		["Sloan","IA",42.23,-96.22,42556584],
		["Blue River","WI",43.18,-90.57,42556746],
		["Krakow","WI",44.75,-88.25,42556908],
		["Circle","MT",47.56,-105.77,42557070],
		["Mechanicsburg","IL",39.81,-89.39,42557232],
		["Lewistown","MO",40.08,-91.81,42557394],
		["Fisk","MO",36.78,-90.2,42557556],
		["Stafford","KS",37.96,-98.59,42557718],
		["Hackberry","LA",29.96,-93.43,42557880],
		["Gracemont","OK",35.18,-98.25,42558042],
		["Hankamer","TX",29.87,-94.57,42558204],
		["Murtaugh","ID",42.41,-114.12,42558366],
		["Hinckley","UT",39.37,-112.73,42558528],
		["Arenas Valley","NM",33.01,-108.28,42558690],
		["Lake Arthur","NM",32.99,-104.36,42558852],
		["Kittitas","WA",46.98,-120.41,42559014],
		["Washington","NH",43.17,-72.09,42559175],
		["Pownal","ME",43.89,-70.18,42559336],
		["Guilford","NY",42.4,-75.49,42559497],
		["Milford","NY",42.59,-74.94,42559658],
		["Salix","PA",40.29,-78.71,42559819],
		["Shirleysburg","PA",40.29,-77.87,42559980],
		["Warwick","MD",39.42,-75.8,42560141],
		["Brandy Station","VA",38.52,-77.89,42560302],
		["Dante","VA",36.98,-82.29,42560463],
		["Garfield","GA",32.65,-82.09,42560624],
		["Statenville","GA",30.7,-83.03,42560785],
		["Clarksville","FL",30.43,-85.18,42560946],
		["Crossville","TN",35.95,-85.03,42561107],
		["Vaughan","MS",32.81,-90.09,42561268],
		["Cunningham","KY",36.89,-88.87,42561429],
		["Uniondale","IN",40.82,-85.24,42561590],
		["National City","MI",44.23,-83.71,42561751],
		["Le Grand","IA",42,-92.77,42561912],
		["Alma","WI",44.33,-91.92,42562073],
		["Humboldt","IL",39.6,-88.31,42562234],
		["Bunker","MO",37.45,-91.21,42562395],
		["Broseley","MO",36.7,-90.23,42562556],
		["Princeton","KS",38.48,-95.27,42562717],
		["Arkadelphia","AR",34.12,-93.07,42562878],
		["Lafe","AR",36.2,-90.5,42563039],
		["Kinta","OK",35.11,-95.23,42563200],
		["Smiley","TX",29.27,-97.63,42563361],
		["Ranchester","WY",44.9,-107.16,42563522],
		["Point Arena","CA",38.91,-123.58,42563683],
		["Falls City","OR",44.87,-123.52,42563844],
		["Kwethluk","AK",60.79,-161.42,42564005],
		["Bryant Pond","ME",44.37,-70.64,42564165],
		["Greenville","ME",45.46,-69.59,42564325],
		["Lubec","ME",44.79,-67.11,42564485],
		["Owls Head","ME",44.08,-69.05,42564645],
		["Tenants Harbor","ME",43.95,-69.23,42564805],
		["Danby","VT",43.35,-73,42564965],
		["Bombay","NY",44.93,-74.59,42565125],
		["Scranton","PA",41.4,-75.66,42565285],
		["Dunlow","WV",38.03,-82.34,42565445],
		["Ralph","AL",33.05,-87.76,42565605],
		["Duck River","TN",35.71,-87.35,42565765],
		["Lyons","IN",38.98,-87.08,42565925],
		["Chrisney","IN",38.01,-87.03,42566085],
		["Lake Park","IA",43.44,-95.32,42566245],
		["Brewster","MN",43.69,-95.46,42566405],
		["Piper City","IL",40.75,-88.18,42566565],
		["South Pekin","IL",40.49,-89.65,42566725],
		["Richwoods","MO",38.16,-90.83,42566885],
		["Cape Fair","MO",36.72,-93.51,42567045],
		["Dodge","NE",41.72,-96.87,42567205],
		["Red Cloud","NE",40.08,-98.52,42567365],
		["Floral","AR",35.61,-91.73,42567525],
		["Canehill","AR",35.91,-94.4,42567685],
		["Knoxville","AR",35.37,-93.36,42567845],
		["Tye","TX",32.45,-99.86,42568005],
		["San Rafael","NM",35.11,-107.88,42568165],
		["Hydesville","CA",40.56,-124.07,42568325],
		["Barryville","NY",41.47,-74.91,42568484],
		["Colver","PA",40.54,-78.78,42568643],
		["Frenchville","PA",41.14,-78.24,42568802],
		["Blain","PA",40.33,-77.51,42568961],
		["Harrisonville","PA",39.97,-78.08,42569120],
		["Tatamy","PA",40.74,-75.25,42569279],
		["Ivanhoe","VA",36.83,-80.95,42569438],
		["Kure Beach","NC",33.99,-77.91,42569597],
		["Glade Valley","NC",36.48,-81.05,42569756],
		["Kelso","TN",35.12,-86.46,42569915],
		["Grimsley","TN",36.25,-85.03,42570074],
		["Pilgrim","KY",37.79,-82.42,42570233],
		["Hueysville","KY",37.54,-82.83,42570392],
		["Neon","KY",37.21,-82.7,42570551],
		["Melber","KY",36.91,-88.75,42570710],
		["Adamsville","OH",40.06,-81.88,42570869],
		["Walnut","IA",41.47,-95.22,42571028],
		["Loganville","WI",43.43,-90.03,42571187],
		["Plum City","WI",44.63,-92.19,42571346],
		["Willow River","MN",46.32,-92.83,42571505],
		["Bowlus","MN",45.82,-94.4,42571664],
		["Miltona","MN",46.04,-95.29,42571823],
		["Karlstad","MN",48.57,-96.51,42571982],
		["Montrose","SD",43.7,-97.18,42572141],
		["Tabor","SD",42.94,-97.65,42572300],
		["Oak Park","IL",41.88,-87.78,42572459],
		["Edelstein","IL",40.97,-89.58,42572618],
		["Evansville","IL",38.08,-89.93,42572777],
		["Ozark","IL",37.53,-88.76,42572936],
		["Rosebud","MO",38.38,-91.4,42573095],
		["Brunswick","MO",39.42,-93.12,42573254],
		["Kingdom City","MO",38.94,-91.93,42573413],
		["Hughesville","MO",38.83,-93.29,42573572],
		["Saint Paul","KS",37.51,-95.17,42573731],
		["Tipton","OK",34.5,-99.13,42573890],
		["Smithville","OK",34.46,-94.64,42574049],
		["Gordonville","TX",33.84,-96.88,42574208],
		["Meadow","TX",33.33,-102.2,42574367],
		["Garden City","TX",31.87,-101.48,42574526],
		["Mills","WY",42.84,-106.37,42574685],
		["Topock","AZ",34.72,-114.44,42574844],
		["San Ysidro","NM",35.55,-106.77,42575003],
		["Mountain Center","CA",33.63,-116.59,42575162],
		["Inchelium","WA",48.2,-118.24,42575321],
		["Cabot","VT",44.4,-72.31,42575479],
		["Bantam","CT",41.72,-73.24,42575637],
		["Wassaic","NY",41.78,-73.54,42575795],
		["Millport","NY",42.26,-76.83,42575953],
		["Garrett","PA",39.86,-79.06,42576111],
		["Roulette","PA",41.77,-78.16,42576269],
		["Bel Alton","MD",38.46,-76.98,42576427],
		["Cobb Island","MD",38.33,-76.91,42576585],
		["Arnoldsburg","WV",38.78,-81.12,42576743],
		["Dingess","WV",37.87,-82.18,42576901],
		["Denmark","TN",35.54,-88.97,42577059],
		["Portage","OH",41.32,-83.64,42577217],
		["Monterey","IN",41.15,-86.48,42577375],
		["Mikado","MI",44.56,-83.41,42577533],
		["Anita","IA",41.44,-94.76,42577691],
		["Hubbard","IA",42.3,-93.3,42577849],
		["Ionia","IA",43.03,-92.45,42578007],
		["Readlyn","IA",42.7,-92.22,42578165],
		["Norway","IA",41.9,-91.92,42578323],
		["Endeavor","WI",43.71,-89.46,42578481],
		["Wheeler","WI",45.04,-91.9,42578639],
		["Nemo","SD",44.19,-103.5,42578797],
		["Mandaree","ND",47.73,-102.67,42578955],
		["West Yellowstone","MT",44.69,-111.21,42579113],
		["Wyanet","IL",41.36,-89.58,42579271],
		["Buncombe","IL",37.47,-88.97,42579429],
		["Perry","MO",39.43,-91.66,42579587],
		["White City","KS",38.79,-96.73,42579745],
		["Oshkosh","NE",41.4,-102.34,42579903],
		["Concord","AR",35.66,-91.84,42580061],
		["Tabernash","CO",39.98,-105.85,42580219],
		["Greenwood","CA",38.92,-120.89,42580377],
		["Happy Camp","CA",41.78,-123.47,42580535],
		["Vader","WA",46.41,-122.99,42580693],
		["Ione","WA",48.69,-117.29,42580851],
		["Salisbury","NH",43.38,-71.71,42581008],
		["Hillburn","NY",41.12,-74.17,42581165],
		["Laurel","NY",40.97,-72.55,42581322],
		["Dornsife","PA",40.75,-76.76,42581479],
		["Lindside","WV",37.45,-80.66,42581636],
		["Tamassee","SC",34.89,-82.98,42581793],
		["Tyler","AL",32.27,-86.84,42581950],
		["Briceville","TN",36.16,-84.3,42582107],
		["Cypress Inn","TN",35.05,-87.79,42582264],
		["Carson","MS",31.55,-89.78,42582421],
		["Bee Spring","KY",37.28,-86.28,42582578],
		["Colfax","IN",40.19,-86.66,42582735],
		["Cutler","IN",40.47,-86.47,42582892],
		["Otwell","IN",38.48,-87.1,42583049],
		["Owendale","MI",43.72,-83.26,42583206],
		["Tower","MN",47.81,-92.29,42583363],
		["Rothsay","MN",46.47,-96.28,42583520],
		["Cando","ND",48.48,-99.2,42583677],
		["Bridger","MT",45.18,-108.54,42583834],
		["Alberton","MT",46.86,-114.59,42583991],
		["Dakota","IL",42.38,-89.52,42584148],
		["New Boston","IL",41.16,-91,42584305],
		["Gifford","IL",40.3,-88.02,42584462],
		["Ina","IL",38.15,-88.9,42584619],
		["Caldwell","KS",37.03,-97.6,42584776],
		["Goldonna","LA",32.02,-92.91,42584933],
		["Eagletown","OK",34.03,-94.57,42585090],
		["Knippa","TX",29.3,-99.63,42585247],
		["Dubois","WY",43.53,-109.64,42585404],
		["Dugway","UT",40.32,-112.79,42585561],
		["Ojo Caliente","NM",36.3,-106.04,42585718],
		["Wadsworth","NV",39.69,-119.39,42585875],
		["Charlemont","MA",42.63,-72.88,42586031],
		["Brownville","ME",45.3,-69.03,42586187],
		["Groton","VT",44.22,-72.2,42586343],
		["Salisbury","VT",43.9,-73.1,42586499],
		["Island Pond","VT",44.81,-71.88,42586655],
		["Alloway","NJ",39.56,-75.34,42586811],
		["West Edmeston","NY",42.78,-75.31,42586967],
		["Limestone","NY",42.02,-78.63,42587123],
		["Brightwood","VA",38.41,-78.16,42587279],
		["Port Republic","VA",38.32,-78.81,42587435],
		["Foster","WV",38.09,-81.77,42587591],
		["Montgomery","AL",32.35,-86.28,42587747],
		["Pine Apple","AL",31.87,-86.98,42587903],
		["Cottage Grove","TN",36.37,-88.47,42588059],
		["Iron City","GA",30.97,-84.8,42588215],
		["Dexter","KY",36.69,-88.16,42588371],
		["Rio Grande","OH",38.88,-82.37,42588527],
		["Lewisville","IN",39.8,-85.35,42588683],
		["Bowling Green","IN",39.38,-86.99,42588839],
		["West Lebanon","IN",40.27,-87.38,42588995],
		["Dayton","IA",42.26,-94.07,42589151],
		["Arkdale","WI",44.03,-89.88,42589307],
		["Prairie Farm","WI",45.23,-91.98,42589463],
		["Wabasso","MN",44.4,-95.25,42589619],
		["Wilton","ND",47.15,-100.78,42589775],
		["Beach","ND",46.91,-104,42589931],
		["Sheridan","MT",45.43,-112.12,42590087],
		["Congerville","IL",40.61,-89.2,42590243],
		["Laquey","MO",37.77,-92.3,42590399],
		["Flemington","MO",37.8,-93.49,42590555],
		["Hermitage","MO",37.94,-93.32,42590711],
		["Richmond","KS",38.4,-95.25,42590867],
		["Greenwood","NE",40.96,-96.44,42591023],
		["Reeves","LA",30.51,-93.04,42591179],
		["Effie","LA",31.21,-92.15,42591335],
		["Thackerville","OK",33.79,-97.14,42591491],
		["Brashear","TX",33.11,-95.72,42591647],
		["Aspermont","TX",33.13,-100.22,42591803],
		["Norwood","CO",38.13,-108.29,42591959],
		["Nucla","CO",38.26,-108.55,42592115],
		["Redmond","UT",39,-111.88,42592271],
		["Quartzsite","AZ",33.66,-114.23,42592427],
		["Sacramento","CA",38.49,-121.27,42592583],
		["Millville","CA",40.56,-122.07,42592739],
		["Etna","ME",44.82,-69.11,42592894],
		["Island Falls","ME",46,-68.27,42593049],
		["Burnham","ME",44.69,-69.42,42593204],
		["East Fairfield","VT",44.8,-72.91,42593359],
		["Sea Isle City","NJ",39.15,-74.69,42593514],
		["Lockwood","NY",42.09,-76.55,42593669],
		["Breezewood","PA",39.99,-78.24,42593824],
		["Yeagertown","PA",40.64,-77.58,42593979],
		["Pine Grove","WV",39.56,-80.68,42594134],
		["Mershon","GA",31.47,-82.21,42594289],
		["Woodland","GA",32.78,-84.56,42594444],
		["Benoit","MS",33.65,-91,42594599],
		["Hudson","KY",37.65,-86.28,42594754],
		["Lexington","KY",38.04,-84.45,42594909],
		["Ludlow Falls","OH",39.99,-84.33,42595064],
		["Perrysville","IN",40.05,-87.43,42595219],
		["Lupton","MI",44.43,-84.01,42595374],
		["Hubbardston","MI",43.09,-84.84,42595529],
		["Armstrong","IA",43.39,-94.47,42595684],
		["Bluffs","IL",39.75,-90.53,42595839],
		["Walnut Hill","IL",38.47,-89.04,42595994],
		["Hanover","KS",39.89,-96.87,42596149],
		["Saint Edward","NE",41.57,-97.86,42596304],
		["Culbertson","NE",40.22,-100.83,42596459],
		["Joiner","AR",35.5,-90.14,42596614],
		["Mount Pleasant","AR",35.98,-91.77,42596769],
		["Telephone","TX",33.8,-96,42596924],
		["Agua Dulce","TX",27.78,-97.9,42597079],
		["Pena Blanca","NM",35.57,-106.33,42597234],
		["Weston","OR",45.8,-118.26,42597389],
		["Rosalia","WA",47.25,-117.38,42597544],
		["Danbury","NH",43.52,-71.86,42597698],
		["Falls Village","CT",41.95,-73.36,42597852],
		["Island Heights","NJ",39.94,-74.14,42598006],
		["Point Lookout","NY",40.59,-73.58,42598160],
		["Wilmington","NY",44.38,-73.82,42598314],
		["King Ferry","NY",42.66,-76.61,42598468],
		["Waterport","NY",43.33,-78.24,42598622],
		["Whitesville","NY",42.03,-77.8,42598776],
		["New Freeport","PA",39.75,-80.45,42598930],
		["Sod","WV",38.26,-81.88,42599084],
		["Girard","GA",33.04,-81.71,42599238],
		["Stantonville","TN",35.15,-88.42,42599392],
		["Kokomo","MS",31.17,-89.98,42599546],
		["South Portsmouth","KY",38.7,-83.01,42599700],
		["Farmington","KY",36.67,-88.53,42599854],
		["Irwin","OH",40.12,-83.45,42600008],
		["East Liberty","OH",40.3,-83.58,42600162],
		["Summerfield","OH",39.79,-81.33,42600316],
		["New Weston","OH",40.33,-84.64,42600470],
		["Stilesville","IN",39.63,-86.63,42600624],
		["Bruceville","IN",38.75,-87.41,42600778],
		["Middleton","MI",43.16,-84.77,42600932],
		["Dafter","MI",46.36,-84.43,42601086],
		["Bussey","IA",41.2,-92.88,42601240],
		["Ridgeway","IA",43.29,-91.99,42601394],
		["Parnell","IA",41.58,-92,42601548],
		["Arlington","WI",43.33,-89.37,42601702],
		["Burnett","WI",43.52,-88.7,42601856],
		["Bryant","WI",45.2,-89.01,42602010],
		["Rankin","IL",40.46,-87.89,42602164],
		["Kell","IL",38.49,-88.9,42602318],
		["Shelbyville","MO",39.8,-92.03,42602472],
		["Chilhowee","MO",38.58,-93.85,42602626],
		["La Russell","MO",37.14,-94.06,42602780],
		["Effingham","KS",39.52,-95.39,42602934],
		["Moran","KS",37.91,-95.17,42603088],
		["Greensburg","KS",37.6,-99.29,42603242],
		["Daisetta","TX",30.11,-94.64,42603396],
		["China","TX",30.05,-94.33,42603550],
		["Moore","ID",43.68,-113.59,42603704],
		["Monteview","ID",43.94,-112.56,42603858],
		["Melrose","NM",34.42,-103.62,42604012],
		["Carlotta","CA",40.48,-123.95,42604166],
		["Marcola","OR",44.21,-122.8,42604320],
		["Trail","OR",42.72,-122.73,42604474],
		["Hoodsport","WA",47.42,-123.18,42604628],
		["Selawik","AK",66.59,-160.02,42604782],
		["Hill","NH",43.52,-71.7,42604935],
		["Cornish","NH",43.48,-72.32,42605088],
		["Sangerville","ME",45.16,-69.35,42605241],
		["Boothbay Harbor","ME",43.85,-69.62,42605394],
		["Edgecomb","ME",43.95,-69.63,42605547],
		["Tunbridge","VT",43.88,-72.5,42605700],
		["Concord","VT",44.43,-71.88,42605853],
		["Gaylordsville","CT",41.64,-73.48,42606006],
		["Ava","NY",43.41,-75.47,42606159],
		["Everson","PA",40.09,-79.58,42606312],
		["Lucinda","PA",41.31,-79.38,42606465],
		["Meadows Of Dan","VA",36.73,-80.41,42606618],
		["Fort Blackmore","VA",36.77,-82.58,42606771],
		["Mouth Of Wilson","VA",36.58,-81.33,42606924],
		["East Bank","WV",38.21,-81.44,42607077],
		["East Lynn","WV",38.19,-82.32,42607230],
		["Cashiers","NC",35.1,-83.09,42607383],
		["Rowesville","SC",33.37,-80.83,42607536],
		["Fruitdale","AL",31.34,-88.41,42607689],
		["Primm Springs","TN",35.8,-87.25,42607842],
		["Damascus","GA",31.28,-84.67,42607995],
		["West Point","KY",37.98,-85.95,42608148],
		["Custar","OH",41.28,-83.84,42608301],
		["Lagro","IN",40.83,-85.72,42608454],
		["Batavia","IA",40.99,-92.16,42608607],
		["Pepin","WI",44.44,-92.14,42608760],
		["Stone Lake","WI",45.84,-91.54,42608913],
		["Goodridge","MN",48.14,-95.8,42609066],
		["Canistota","SD",43.59,-97.29,42609219],
		["Broadus","MT",45.39,-105.5,42609372],
		["Cordova","IL",41.67,-90.32,42609525],
		["Fults","IL",38.16,-90.21,42609678],
		["Ursa","IL",40.07,-91.37,42609831],
		["Mulberry","KS",37.55,-94.62,42609984],
		["Newman Grove","NE",41.74,-97.77,42610137],
		["Loup City","NE",41.27,-98.96,42610290],
		["Elizabeth","LA",30.86,-92.79,42610443],
		["Fulton","AR",33.61,-93.81,42610596],
		["Wilson","AR",35.56,-90.04,42610749],
		["Alex","OK",34.92,-97.77,42610902],
		["Canute","OK",35.42,-99.27,42611055],
		["Lahoma","OK",36.38,-98.09,42611208],
		["Broaddus","TX",31.3,-94.26,42611361],
		["Garwood","TX",29.45,-96.51,42611514],
		["Green Mountain Falls","CO",38.93,-105.02,42611667],
		["Pearce","AZ",31.86,-109.33,42611820],
		["Bradley","CA",35.85,-120.8,42611973],
		["Sultana","CA",36.54,-119.33,42612126],
		["Cusick","WA",48.51,-117.37,42612279],
		["Steuben","ME",44.51,-67.96,42612431],
		["Dixmont","ME",44.68,-69.16,42612583],
		["Barnet","VT",44.3,-72.05,42612735],
		["Howes Cave","NY",42.7,-74.37,42612887],
		["East Bethany","NY",42.91,-78.13,42613039],
		["Tafton","PA",41.4,-75.18,42613191],
		["Thompson","PA",41.86,-75.51,42613343],
		["Reedville","VA",37.86,-76.29,42613495],
		["Littleton","WV",39.69,-80.51,42613647],
		["Loyall","KY",36.85,-83.35,42613799],
		["Garrett","KY",37.48,-82.83,42613951],
		["Lakemore","OH",41.02,-81.42,42614103],
		["Rossburg","OH",40.27,-84.63,42614255],
		["Quinnesec","MI",45.8,-87.99,42614407],
		["Albert City","IA",42.78,-94.94,42614559],
		["Hawkeye","IA",42.93,-91.95,42614711],
		["Moulton","IA",40.68,-92.67,42614863],
		["Wever","IA",40.71,-91.23,42615015],
		["Beldenville","WI",44.77,-92.43,42615167],
		["Green Isle","MN",44.67,-94,42615319],
		["Albany","IL",41.78,-90.21,42615471],
		["Edgewood","IL",38.92,-88.66,42615623],
		["Climax Springs","MO",38.1,-93.05,42615775],
		["Minneola","KS",37.44,-100.01,42615927],
		["Pawnee City","NE",40.11,-96.15,42616079],
		["Chappell","NE",41.09,-102.46,42616231],
		["Sunshine","LA",30.29,-91.17,42616383],
		["Bull Shoals","AR",36.37,-92.58,42616535],
		["Merino","CO",40.48,-103.35,42616687],
		["Leeds","UT",37.23,-113.36,42616839],
		["Sun City West","AZ",33.67,-112.36,42616991],
		["Baker","CA",35.31,-116.04,42617143],
		["Dufur","OR",45.4,-121.16,42617295],
		["Leeds","MA",42.35,-72.71,42617446],
		["Orford","NH",43.89,-72.06,42617597],
		["Bakersfield","VT",44.78,-72.8,42617748],
		["Baldwin Place","NY",41.32,-73.74,42617899],
		["Leetsdale","PA",40.56,-80.21,42618050],
		["Lewis Run","PA",41.87,-78.66,42618201],
		["Shamokin Dam","PA",40.85,-76.82,42618352],
		["Seaboard","NC",36.49,-77.44,42618503],
		["Cleveland","SC",35.07,-82.52,42618654],
		["Oakland","FL",28.55,-81.62,42618805],
		["Epes","AL",32.69,-88.12,42618956],
		["Beatrice","AL",31.73,-87.2,42619107],
		["Morris Chapel","TN",35.31,-88.35,42619258],
		["Bude","MS",31.46,-90.85,42619409],
		["Oakland","KY",37.04,-86.25,42619560],
		["Indianapolis","IN",39.77,-86.14,42619711],
		["Wellston","MI",44.21,-85.95,42619862],
		["Pelkie","MI",46.82,-88.63,42620013],
		["Fruitland","IA",41.34,-91.12,42620164],
		["Rollingstone","MN",44.09,-91.81,42620315],
		["Westbrook","MN",44.04,-95.43,42620466],
		["Worthing","SD",43.32,-96.76,42620617],
		["Peever","SD",45.54,-96.95,42620768],
		["Scobey","MT",48.78,-105.6,42620919],
		["Martinton","IL",40.91,-87.72,42621070],
		["Bonnie","IL",38.2,-88.9,42621221],
		["Laddonia","MO",39.24,-91.64,42621372],
		["Oregon","MO",39.98,-95.14,42621523],
		["Jamestown","MO",38.76,-92.47,42621674],
		["Edwards","MO",38.13,-93.16,42621825],
		["Paxton","NE",41.12,-101.35,42621976],
		["Grand Isle","LA",29.24,-89.98,42622127],
		["Moro","AR",34.79,-90.98,42622278],
		["Pond Creek","OK",36.66,-97.8,42622429],
		["Waynoka","OK",36.58,-98.87,42622580],
		["Lindsay","TX",33.63,-97.21,42622731],
		["Stowell","TX",29.78,-94.37,42622882],
		["Wallace","ID",47.61,-115.91,42623033],
		["Salome","AZ",33.72,-113.65,42623184],
		["Humboldt","AZ",34.5,-112.22,42623335],
		["Blanco","NM",36.61,-107.73,42623486],
		["Joseph","OR",45.3,-117.09,42623637],
		["Wallowa","OR",45.56,-117.5,42623788],
		["Indianola","WA",47.75,-122.53,42623939],
		["Blandford","MA",42.18,-72.93,42624089],
		["Freedom","NH",43.81,-71.03,42624239],
		["Kenduskeag","ME",44.91,-68.93,42624389],
		["Woodstock Valley","CT",41.92,-72.1,42624539],
		["Huguenot","NY",41.41,-74.63,42624689],
		["Bloomingdale","NY",44.42,-74.1,42624839],
		["Rochester","NY",43.16,-77.61,42624989],
		["Canaseraga","NY",42.46,-77.77,42625139],
		["Lodi","NY",42.61,-76.82,42625289],
		["Hartstown","PA",41.55,-80.38,42625439],
		["New Philadelphia","PA",40.71,-76.11,42625589],
		["Welcome","MD",38.45,-77.08,42625739],
		["Rochelle","VA",38.29,-78.27,42625889],
		["Renick","WV",37.98,-80.36,42626039],
		["Arapahoe","NC",35.01,-76.82,42626189],
		["Laurel Springs","NC",36.44,-81.25,42626339],
		["Atlanta","GA",33.84,-84.35,42626489],
		["Bear Creek","AL",34.26,-87.71,42626639],
		["Williamsport","TN",35.64,-87.23,42626789],
		["Hazel Green","KY",37.79,-83.41,42626939],
		["Jenera","OH",40.89,-83.72,42627089],
		["Wheatland","IN",38.66,-87.3,42627239],
		["Toddville","IA",42.1,-91.71,42627389],
		["Hazelhurst","WI",45.79,-89.71,42627539],
		["Amboy","MN",43.88,-94.15,42627689],
		["Estelline","SD",44.57,-96.9,42627839],
		["Highmore","SD",44.52,-99.43,42627989],
		["Lyndon","IL",41.71,-89.92,42628139],
		["Coffeen","IL",39.08,-89.39,42628289],
		["Arenzville","IL",39.87,-90.37,42628439],
		["Harviell","MO",36.68,-90.57,42628589],
		["Chamois","MO",38.67,-91.77,42628739],
		["Elwood","KS",39.75,-94.87,42628889],
		["Parker","KS",38.32,-94.99,42629039],
		["Le Roy","KS",38.08,-95.63,42629189],
		["Ravenden","AR",36.23,-91.25,42629339],
		["Ladonia","TX",33.42,-95.94,42629489],
		["Etoile","TX",31.34,-94.41,42629639],
		["Hartley","TX",35.88,-102.39,42629789],
		["Mclean","TX",35.23,-100.6,42629939],
		["Walden","CO",40.73,-106.28,42630089],
		["Arrey","NM",32.84,-107.31,42630239],
		["Bay City","OR",45.58,-123.81,42630389],
		["Christmas Valley","OR",43.24,-120.59,42630539],
		["Vaughn","WA",47.31,-122.77,42630689],
		["Northampton","MA",42.32,-72.67,42630838],
		["Cambridge","MA",42.37,-71.11,42630987],
		["Orwell","VT",43.8,-73.3,42631136],
		["Chadwicks","NY",43.02,-75.27,42631285],
		["Colmar","PA",40.27,-75.26,42631434],
		["Freeman","VA",36.8,-77.7,42631583],
		["Jarvisburg","NC",36.2,-75.86,42631732],
		["Manns Harbor","NC",35.81,-75.91,.91	,	4231881],
		["Sellers","SC",34.28,-79.47,42632030],
		["Arabi","GA",31.83,-83.73,42632179],
		["Saint James City","FL",26.49,-82.08,42632328],
		["Pall Mall","TN",36.56,-84.95,42632477],
		["Lawrence","MS",32.27,-89.28,42632626],
		["Ridgeway","OH",40.51,-83.56,42632775],
		["Tippecanoe","IN",41.22,-86.1,42632924],
		["Saint Anthony","IN",38.33,-86.82,42633073],
		["Minden","IA",41.46,-95.54,42633222],
		["Buffalo","IA",41.46,-90.71,42633371],
		["Ridgeland","WI",45.2,-91.89,42633520],
		["Dalton","MN",46.17,-95.91,42633669],
		["Saint Stephen","MN",45.7,-94.27,42633818],
		["Rosholt","SD",45.86,-96.73,42633967],
		["Rolette","ND",48.66,-99.84,42634116],
		["Bristol","IL",41.71,-88.4,42634265],
		["Cowden","IL",39.24,-88.85,42634414],
		["Alma","IL",38.72,-88.91,42634563],
		["Patoka","IL",38.75,-89.09,42634712],
		["La Crosse","KS",38.53,-99.3,42634861],
		["Palmyra","NE",40.7,-96.39,42635010],
		["Wausa","NE",42.49,-97.53,42635159],
		["Overton","NE",40.74,-99.53,42635308],
		["Tangipahoa","LA",30.87,-90.51,42635457],
		["Georgetown","LA",31.75,-92.38,42635606],
		["Humphrey","AR",34.42,-91.7,42635755],
		["Delaware","OK",36.77,-95.64,42635904],
		["Roxton","TX",33.54,-95.72,42636053],
		["Fred","TX",30.57,-94.17,42636202],
		["Lasara","TX",26.53,-97.88,42636351],
		["Cotopaxi","CO",38.37,-105.68,42636500],
		["Burlington","WY",44.44,-108.43,42636649],
		["Cokeville","WY",42.08,-110.95,42636798],
		["Bancroft","ID",42.79,-111.91,42636947],
		["Chama","NM",36.89,-106.58,42637096],
		["Dulzura","CA",32.62,-116.67,42637245],
		["Toksook Bay","AK",60.54,-165.14,42637394],
		["Seaside Park","NJ",39.79,-74.09,42637542],
		["Granite Springs","NY",41.3,-73.75,42637690],
		["Thorndale","PA",39.99,-75.75,42637838],
		["Hambleton","WV",39.08,-79.64,42637986],
		["Canvas","WV",38.26,-80.73,42638134],
		["Islandton","SC",32.9,-80.93,42638282],
		["Eldridge","AL",33.92,-87.61,42638430],
		["Jones","AL",32.6,-86.88,42638578],
		["Dover","KY",38.75,-83.88,42638726],
		["Germantown","KY",38.65,-83.96,42638874],
		["Berkey","OH",41.71,-83.83,42639022],
		["Hazleton","IN",38.48,-87.54,42639170],
		["Dana","IN",39.8,-87.49,42639318],
		["Sidney","MI",43.25,-85.13,42639466],
		["Waucoma","IA",43.05,-92.03,42639614],
		["Olin","IA",41.99,-91.14,42639762],
		["Browns Valley","MN",45.59,-96.83,42639910],
		["Hackensack","MN",46.92,-94.51,42640058],
		["Badger","MN",48.77,-96.02,42640206],
		["Saybrook","IL",40.42,-88.52,42640354],
		["Ellis Grove","IL",38,-89.9,42640502],
		["Eldridge","MO",37.82,-92.74,42640650],
		["Kissee Mills","MO",36.68,-93.04,42640798],
		["Norwich","KS",37.45,-97.84,42640946],
		["Fowler","KS",37.38,-100.19,42641094],
		["Tribune","KS",38.47,-101.75,42641242],
		["Lyons","NE",41.93,-96.47,42641390],
		["Bertrand","NE",40.52,-99.63,42641538],
		["Huttig","AR",33.04,-92.18,42641686],
		["Enola","AR",35.2,-92.2,42641834],
		["Throckmorton","TX",33.18,-99.17,42641982],
		["Evadale","TX",30.33,-94.06,42642130],
		["Lolita","TX",28.81,-96.44,42642278],
		["Dietrich","ID",42.87,-114.24,42642426],
		["Ballico","CA",37.47,-120.65,42642574],
		["Grizzly Flats","CA",38.64,-120.51,42642722],
		["Brownsville","CA",39.43,-121.25,42642870],
		["Eltopia","WA",46.5,-119.05,42643018],
		["Denmark","ME",43.97,-70.8,42643165],
		["Hannacroix","NY",42.42,-73.88,42643312],
		["Castle Creek","NY",42.22,-75.91,42643459],
		["Davenport","NY",42.47,-74.84,42643606],
		["Weedville","PA",41.26,-78.49,42643753],
		["Allensville","PA",40.53,-77.81,42643900],
		["Saxe","VA",36.93,-78.66,42644047],
		["Charleston","WV",38.35,-81.63,42644194],
		["Brasstown","NC",35.01,-83.91,42644341],
		["Folly Beach","SC",32.67,-79.92,42644488],
		["Surrency","GA",31.72,-82.19,42644635],
		["Lamont","FL",30.37,-83.81,42644782],
		["Alachua","FL",29.77,-82.48,42644929],
		["Gallant","AL",33.99,-86.22,42645076],
		["Chattanooga","TN",35.06,-85.25,42645223],
		["Ezel","KY",37.89,-83.44,42645370],
		["Woodstock","OH",40.17,-83.52,42645517],
		["Plainfield","IA",42.84,-92.53,42645664],
		["Buhl","MN",47.49,-92.76,42645811],
		["Hanska","MN",44.14,-94.49,42645958],
		["Lake Benton","MN",44.26,-96.29,42646105],
		["Ballantine","MT",45.89,-108.09,42646252],
		["Gardiner","MT",45.03,-110.7,42646399],
		["Alpha","IL",41.19,-90.38,42646546],
		["Addieville","IL",38.39,-89.48,42646693],
		["Long Lane","MO",37.61,-92.9,42646840],
		["Little River","KS",38.39,-98.01,42646987],
		["Spalding","NE",41.68,-98.36,42647134],
		["Estherwood","LA",30.18,-92.46,42647281],
		["Fairfield Bay","AR",35.6,-92.26,42647428],
		["Log Lane Village","CO",40.27,-103.82,42647575],
		["Shoshoni","WY",43.23,-108.1,42647722],
		["Lewisville","ID",43.68,-112.03,42647869],
		["Oak City","UT",39.37,-112.28,42648016],
		["Kenwood","CA",38.42,-122.54,42648163],
		["Philo","CA",39.07,-123.52,42648310],
		["Klamath","CA",41.59,-124.06,42648457],
		["Mcclellan","CA",38.66,-121.4,42648604],
		["Dallesport","WA",45.66,-121.13,42648751],
		["Lind","WA",46.9,-118.68,42648898],
		["Grafton","NH",43.55,-71.94,42649044],
		["East Thetford","VT",43.81,-72.19,42649190],
		["Irasburg","VT",44.81,-72.28,42649336],
		["Washington","CT",41.63,-73.28,42649482],
		["Newtonville","NJ",39.56,-74.85,42649628],
		["Bridgehampton","NY",40.94,-72.29,42649774],
		["Verbank","NY",41.71,-73.7,42649920],
		["Dalton","NY",42.54,-77.89,42650066],
		["Presto","PA",40.38,-80.12,42650212],
		["Lyndora","PA",40.85,-79.92,42650358],
		["Adrian","PA",40.9,-79.5,42650504],
		["Cowansville","PA",40.93,-79.59,42650650],
		["Fort Defiance","VA",38.21,-78.94,42650796],
		["Aurora","WV",39.29,-79.56,42650942],
		["Kelford","NC",36.18,-77.22,42651088],
		["Merry Hill","NC",36.01,-76.77,42651234],
		["Shady Dale","GA",33.4,-83.58,42651380],
		["Morven","GA",30.94,-83.5,42651526],
		["Brooker","FL",29.88,-82.33,42651672],
		["Orlando","KY",37.37,-84.26,42651818],
		["Lovely","KY",37.82,-82.45,42651964],
		["Brinkhaven","OH",40.47,-82.19,42652110],
		["Amesville","OH",39.4,-81.95,42652256],
		["Watersmeet","MI",46.27,-89.18,42652402],
		["Truro","IA",41.21,-93.84,42652548],
		["Pickett","WI",43.91,-88.73,42652694],
		["Dennison","MN",44.4,-93.02,42652840],
		["Oak Park","MN",45.69,-93.94,42652986],
		["Erhard","MN",46.48,-96.09,42653132],
		["Hennepin","IL",41.25,-89.31,42653278],
		["Stanford","IL",40.43,-89.22,42653424],
		["Kansas","IL",39.55,-87.93,42653570],
		["Witt","IL",39.25,-89.34,42653716],
		["Saint Francisville","IL",38.59,-87.64,42653862],
		["La Belle","MO",40.11,-91.91,42654008],
		["Ridgeway","MO",40.37,-93.93,42654154],
		["Spokane","MO",36.86,-93.29,42654300],
		["Frankfort","KS",39.7,-96.41,42654446],
		["Burden","KS",37.31,-96.75,42654592],
		["Sharon Springs","KS",38.89,-101.75,42654738],
		["Olustee","OK",34.54,-99.42,42654884],
		["Wann","OK",36.91,-95.8,42655030],
		["Calvin","OK",34.96,-96.25,42655176],
		["Abbott","TX",31.88,-97.07,42655322],
		["Richards","TX",30.54,-95.86,42655468],
		["Alpine","WY",43.16,-111.01,42655614],
		["Clark Fork","ID",48.06,-116.17,42655760],
		["Bear River City","UT",41.61,-112.12,42655906],
		["Gadsden","AZ",32.53,-114.76,42656052],
		["Point Reyes Station","CA",38.07,-122.86,42656198],
		["Fall Creek","OR",43.94,-122.68,42656344],
		["Glennallen","AK",62.1,-145.53,42656490],
		["North Monmouth","ME",44.27,-70.05,42656635],
		["Milbridge","ME",44.52,-67.88,42656780],
		["Garland","ME",45.03,-69.16,42656925],
		["Sharon","VT",43.78,-72.45,42657070],
		["Roosevelt","NJ",40.22,-74.47,42657215],
		["Alpine","NY",42.31,-76.72,42657360],
		["Champion","PA",40.04,-79.32,42657505],
		["Gwynedd Valley","PA",40.15,-75.26,42657650],
		["Rohrersville","MD",39.43,-77.65,42657795],
		["Schuyler","VA",37.79,-78.69,42657940],
		["Elberon","VA",37.07,-76.88,42658085],
		["Barrackville","WV",39.5,-80.17,42658230],
		["Alexis","NC",35.38,-81.1,42658375],
		["Wagarville","AL",31.44,-88.03,42658520],
		["Alberta","AL",32.23,-87.41,42658665],
		["Faunsdale","AL",32.45,-87.59,42658810],
		["Pheba","MS",33.59,-88.94,42658955],
		["Stinnett","KY",37.09,-83.39,42659100],
		["South Solon","OH",39.73,-83.61,42659245],
		["Celestine","IN",38.38,-86.78,42659390],
		["Falmouth","MI",44.25,-85.08,42659535],
		["Dollar Bay","MI",47.14,-88.53,42659680],
		["Salix","IA",42.3,-96.28,42659825],
		["Argonne","WI",45.66,-88.88,42659970],
		["Maiden Rock","WI",44.56,-92.3,42660115],
		["Ashby","MN",46.09,-95.81,42660260],
		["Argyle","MN",48.33,-96.81,42660405],
		["Wessington Springs","SD",44.08,-98.57,42660550],
		["Oneida","IL",41.07,-90.22,42660695],
		["Coldwater","KS",37.25,-99.33,42660840],
		["Downs","KS",39.5,-98.54,42660985],
		["Grapevine","AR",34.11,-92.3,42661130],
		["Poplar Grove","AR",34.53,-90.87,42661275],
		["Ivanhoe","TX",33.71,-96.14,42661420],
		["Bellevue","TX",33.63,-98.01,42661565],
		["Devers","TX",30.02,-94.58,42661710],
		["San Ygnacio","TX",27.19,-99.32,42661855],
		["Loraine","TX",32.4,-100.71,42662000],
		["Otis","CO",40.14,-102.96,42662145],
		["Simla","CO",39.14,-104.08,42662290],
		["East Carbon","UT",39.51,-110.45,42662435],
		["Koosharem","UT",38.51,-111.87,42662580],
		["Arlington","AZ",33.18,-112.79,42662725],
		["Dateland","AZ",32.94,-113.34,42662870],
		["San Fidel","NM",35.08,-107.59,42663015],
		["Stanley","NM",35.14,-105.97,42663160],
		["Medanales","NM",36.17,-106.18,42663305],
		["Wolf Creek","OR",42.66,-123.39,42663450],
		["Oak Harbor","WA",48.28,-122.62,42663595],
		["Pembroke","ME",44.95,-67.16,42663739],
		["Moriah","NY",44.05,-73.5,42663883],
		["Nineveh","NY",42.19,-75.6,42664027],
		["Willseyville","NY",42.29,-76.37,42664171],
		["Roaring Branch","PA",41.55,-76.89,42664315],
		["Great Bend","PA",41.97,-75.74,42664459],
		["Monterey","VA",38.41,-79.58,42664603],
		["Man","WV",37.74,-81.87,42664747],
		["Atlanta","GA",33.84,-84.38,42664891],
		["Lower Salem","OH",39.56,-81.39,42665035],
		["Losantville","IN",40.02,-85.18,42665179],
		["Lamar","IN",38.04,-86.96,42665323],
		["Empire","MI",44.81,-86.05,42665467],
		["Blairstown","IA",41.9,-92.08,42665611],
		["Donahue","IA",41.69,-90.67,42665755],
		["Merrimac","WI",43.37,-89.62,42665899],
		["Mellen","WI",46.32,-90.66,42666043],
		["Racine","MN",43.77,-92.48,42666187],
		["Heron Lake","MN",43.79,-95.32,42666331],
		["Rushmore","MN",43.62,-95.79,42666475],
		["Mohall","ND",48.76,-101.51,42666619],
		["Deer Creek","IL",40.62,-89.33,42666763],
		["Glenarm","IL",39.61,-89.67,42666907],
		["Loami","IL",39.67,-89.84,42667051],
		["San Jose","IL",40.3,-89.6,42667195],
		["Clarksville","MO",39.37,-90.9,42667339],
		["Whitewater","MO",37.23,-89.79,42667483],
		["Charenton","LA",29.86,-91.53,42667627],
		["Bethany","LA",32.36,-93.99,42667771],
		["Tulsa","OK",36.12,-95.91,42667915],
		["Bluff Dale","TX",32.35,-98.02,42668059],
		["Ouray","CO",38.08,-107.69,42668203],
		["Salt Lake City","UT",40.76,-111.83,42668347],
		["Beryl","UT",37.87,-113.66,42668491],
		["Tucson","AZ",32.18,-110.86,42668635],
		["Heber","AZ",34.53,-110.44,42668779],
		["Whitewater","CA",33.94,-116.65,42668923],
		["New Cuyama","CA",34.98,-119.8,42669067],
		["Wilbur","WA",47.72,-118.7,42669211],
		["Kipnuk","AK",59.93,-164.06,42669355],
		["Sullivan","ME",44.53,-68.15,42669498],
		["Harmony","ME",44.97,-69.54,42669641],
		["Niverville","NY",42.44,-73.64,42669784],
		["Ellenburg Center","NY",44.82,-73.85,42669927],
		["Alfred Station","NY",42.24,-77.81,42670070],
		["Sykesville","PA",41.04,-78.81,42670213],
		["Patuxent River","MD",38.28,-76.42,42670356],
		["Sedley","VA",36.77,-76.98,42670499],
		["Hobbsville","NC",36.34,-76.6,42670642],
		["Penrose","NC",35.25,-82.62,42670785],
		["White Plains","GA",33.47,-83.03,42670928],
		["Groveoak","AL",34.43,-86.04,42671071],
		["Porterville","MS",32.65,-88.48,42671214],
		["Lewistown","OH",40.43,-83.93,42671357],
		["Avoca","WI",43.18,-90.32,42671500],
		["Aniwa","WI",45.01,-89.2,42671643],
		["Rockland","WI",43.9,-90.91,42671786],
		["Dakota","MN",43.91,-91.35,42671929],
		["Steele","ND",46.85,-99.91,42672072],
		["Philipsburg","MT",46.29,-113.45,42672215],
		["Waltonville","IL",38.21,-89.03,42672358],
		["Stonefort","IL",37.61,-88.7,42672501],
		["Clarksburg","MO",38.66,-92.66,42672644],
		["Latham","MO",38.56,-92.68,42672787],
		["Mokane","MO",38.67,-91.87,42672930],
		["Blue Rapids","KS",39.67,-96.65,42673073],
		["Kiowa","KS",37.01,-98.48,42673216],
		["Gheens","LA",29.69,-90.49,42673359],
		["Chidester","AR",33.69,-93.01,42673502],
		["Williford","AR",36.25,-91.36,42673645],
		["Caney","OK",34.23,-96.21,42673788],
		["Chireno","TX",31.49,-94.34,42673931],
		["Leming","TX",29.06,-98.48,42674074],
		["Alleyton","TX",29.74,-96.46,42674217],
		["Pomerene","AZ",32.09,-110.22,42674360],
		["Glorieta","NM",35.58,-105.76,42674503],
		["Cascade Locks","OR",45.61,-121.84,42674646],
		["Noorvik","AK",66.83,-161.03,42674789],
		["Frenchville","ME",47.28,-68.38,42674931],
		["Jefferson","PA",39.93,-80.05,42675073],
		["Bear Lake","PA",41.99,-79.5,42675215],
		["Conyngham","PA",40.99,-76.05,42675357],
		["Adamstown","PA",40.24,-76.06,42675499],
		["Walkersville","WV",38.84,-80.5,42675641],
		["Kelly","NC",34.46,-78.32,42675783],
		["Stockton","GA",30.93,-83,42675925],
		["Houston","AL",34.12,-87.25,42676067],
		["Mc Call Creek","MS",31.52,-90.76,42676209],
		["Pinsonfork","KY",37.54,-82.25,42676351],
		["Glenmont","OH",40.51,-82.09,42676493],
		["Mechanicstown","OH",40.62,-80.95,42676635],
		["Yorkshire","OH",40.32,-84.49,42676777],
		["Powers","MI",45.68,-87.52,42676919],
		["Worthington","IA",42.39,-91.12,42677061],
		["Browntown","WI",42.57,-89.79,42677203],
		["Brule","WI",46.55,-91.57,42677345],
		["Welcome","MN",43.66,-94.61,42677487],
		["Mott","ND",46.37,-102.31,42677629],
		["Essex","IL",41.17,-88.19,42677771],
		["Ohio","IL",41.55,-89.45,42677913],
		["Niantic","IL",39.85,-89.16,42678055],
		["Frankford","MO",39.49,-91.32,42678197],
		["Downing","MO",40.48,-92.36,42678339],
		["Bell City","MO",37.02,-89.81,42678481],
		["Tuscumbia","MO",38.23,-92.45,42678623],
		["Ratcliff","AR",35.3,-93.88,42678765],
		["Arapaho","OK",35.57,-98.95,42678907],
		["Snowmass","CO",39.33,-106.98,42679049],
		["Lupton","AZ",35.29,-109.07,42679191],
		["Los Angeles","CA",34.04,-118.25,42679333],
		["Etna","NH",43.71,-72.19,42679474],
		["Cherryfield","ME",44.6,-67.92,42679615],
		["Corinth","VT",44.02,-72.23,42679756],
		["Lunenburg","VT",44.47,-71.68,42679897],
		["Stafford","NY",42.98,-78.08,42680038],
		["Tamiment","PA",41.14,-75.02,42680179],
		["Belle Haven","VA",37.55,-75.81,42680320],
		["Goshen","VA",37.99,-79.5,42680461],
		["Mountville","SC",34.33,-81.95,42680602],
		["Dumas","MS",34.63,-88.83,42680743],
		["Etta","MS",34.46,-89.2,42680884],
		["Freeburn","KY",37.54,-82.14,42681025],
		["Bloomingdale","IN",39.83,-87.24,42681166],
		["Romney","IN",40.25,-86.91,42681307],
		["Ellsworth","IA",42.31,-93.58,42681448],
		["Rolfe","IA",42.81,-94.53,42681589],
		["Carson","IA",41.23,-95.41,42681730],
		["Lannon","WI",43.15,-88.16,42681871],
		["Britt","MN",47.64,-92.68,42682012],
		["Jasper","MN",43.84,-96.39,42682153],
		["Graceville","MN",45.56,-96.43,42682294],
		["Gwinner","ND",46.22,-97.65,42682435],
		["Lynn Center","IL",41.3,-90.35,42682576],
		["Findlay","IL",39.52,-88.75,42682717],
		["Campbell Hill","IL",37.93,-89.55,42682858],
		["Amazonia","MO",39.88,-94.89,42682999],
		["Fairview","MO",36.81,-94.08,42683140],
		["Dunnegan","MO",37.7,-93.57,42683281],
		["Ridgedale","MO",36.5,-93.22,42683422],
		["Mead","NE",41.22,-96.48,42683563],
		["Howells","NE",41.72,-97,42683704],
		["Tilden","NE",42.04,-97.83,42683845],
		["Cameron","LA",29.79,-93.29,42683986],
		["Gould","AR",33.98,-91.56,42684127],
		["Kirby","AR",34.21,-93.58,42684268],
		["Oden","AR",34.62,-93.78,42684409],
		["Swifton","AR",35.82,-91.12,42684550],
		["Brookston","TX",33.61,-95.67,42684691],
		["Dike","TX",33.19,-95.46,42684832],
		["Whiteface","TX",33.59,-102.61,42684973],
		["La Honda","CA",37.28,-122.24,42685114],
		["Hanalei","HI",22.2,-159.5,42685255],
		["Mount Tabor","NJ",40.87,-74.47,42685395],
		["Jordanville","NY",42.91,-74.95,42685535],
		["Lyons Falls","NY",43.61,-75.36,42685675],
		["Lemont","PA",40.78,-77.9,42685815],
		["Ashford","WV",38.19,-81.71,42685955],
		["Bankston","AL",33.7,-87.68,42686095],
		["Minor Hill","TN",35.03,-87.17,42686235],
		["Walling","TN",35.85,-85.62,42686375],
		["Crosby","MS",31.27,-91.06,42686515],
		["Yeaddiss","KY",37.07,-83.21,42686655],
		["Kirksey","KY",36.68,-88.42,42686795],
		["Kanawha","IA",42.93,-93.79,42686935],
		["Maynard","IA",42.77,-91.87,42687075],
		["Charter Oak","IA",42.06,-95.58,42687215],
		["Scranton","IA",42.02,-94.54,42687355],
		["Colesburg","IA",42.63,-91.2,42687495],
		["Courtland","MN",44.26,-94.34,42687635],
		["Butterfield","MN",43.95,-94.79,42687775],
		["Lake Lillian","MN",44.94,-94.88,42687915],
		["Corsica","SD",43.42,-98.4,42688055],
		["Onida","SD",44.7,-100.06,42688195],
		["Philip","SD",44.04,-101.66,42688335],
		["Edgemont","SD",43.29,-103.82,42688475],
		["Thebes","IL",37.21,-89.45,42688615],
		["Pattonsburg","MO",40.04,-94.13,42688755],
		["Tebbetts","MO",38.62,-91.96,42688895],
		["Argonia","KS",37.26,-97.76,42689035],
		["Callaway","NE",41.29,-99.92,42689175],
		["Grosse Tete","LA",30.41,-91.43,42689315],
		["Union City","OK",35.4,-97.89,42689455],
		["Saltillo","TX",33.19,-95.34,42689595],
		["Douglassville","TX",33.19,-94.35,42689735],
		["Buchanan Dam","TX",30.79,-98.43,42689875],
		["Camp Wood","TX",29.66,-100.01,42690015],
		["Nunn","CO",40.7,-104.78,42690155],
		["La Plata","NM",36.92,-108.19,42690295],
		["Indian Springs","NV",36.51,-115.64,42690435],
		["Mi Wuk Village","CA",38.1,-120.13,42690575],
		["Rockaway Beach","OR",45.62,-123.91,42690715],
		["Union","WA",47.33,-123.06,42690855],
		["Haydenville","MA",42.39,-72.7,42690994],
		["Addison","ME",45.08,-67.46,42691133],
		["East Calais","VT",44.35,-72.44,42691272],
		["Salisbury","CT",42.01,-73.42,42691411],
		["Luthersburg","PA",41.03,-78.74,42691550],
		["Mifflinville","PA",41.02,-76.3,42691689],
		["Sinks Grove","WV",37.66,-80.52,42691828],
		["Whitesville","WV",37.97,-81.53,42691967],
		["Reader","WV",39.57,-80.74,42692106],
		["Winton","NC",36.38,-76.93,42692245],
		["Jamestown","SC",33.28,-79.69,42692384],
		["Gloverville","SC",33.52,-81.81,42692523],
		["Warwick","GA",31.82,-83.92,42692662],
		["Patrick Afb","FL",28.23,-80.6,42692801],
		["Sontag","MS",31.65,-90.18,42692940],
		["Canada","KY",37.6,-82.32,42693079],
		["New Plymouth","OH",39.37,-82.39,42693218],
		["Reynolds","IN",40.74,-86.87,42693357],
		["Omer","MI",44.04,-83.85,42693496],
		["Afton","MI",45.31,-84.45,42693635],
		["Hermansville","MI",45.71,-87.6,42693774],
		["Collins","IA",41.9,-93.3,42693913],
		["La Motte","IA",42.29,-90.62,42694052],
		["Glen Flora","WI",45.49,-90.89,42694191],
		["Conrath","WI",45.38,-91.03,42694330],
		["Hamburg","MN",44.73,-93.96,42694469],
		["Kandiyohi","MN",45.13,-94.93,42694608],
		["Avon","SD",43,-98.05,42694747],
		["Wahpeton","ND",46.26,-96.6,42694886],
		["Buckley","IL",40.59,-88.03,42695025],
		["Beckemeyer","IL",38.6,-89.43,42695164],
		["Mc Clure","IL",37.29,-89.43,42695303],
		["Eagleville","MO",40.46,-93.98,42695442],
		["Kidder","MO",39.78,-94.1,42695581],
		["Meadville","MO",39.78,-93.3,42695720],
		["Cortland","NE",40.5,-96.7,42695859],
		["Marthaville","LA",31.8,-93.39,42695998],
		["Ripley","OK",36.01,-96.9,42696137],
		["Gustine","TX",31.84,-98.4,42696276],
		["Drake","CO",40.43,-105.33,42696415],
		["Eden","ID",42.57,-114.25,42696554],
		["Cataldo","ID",47.57,-116.47,42696693],
		["Zephyr Cove","NV",38.99,-119.92,42696832],
		["Woodacre","CA",38,-122.64,42696971],
		["Mosier","OR",45.6,-121.37,42697110],
		["Akiachak","AK",60.87,-161.42,42697249],
		["Sedgwick","ME",44.3,-68.61,42697387],
		["Craftsbury","VT",44.63,-72.37,42697525],
		["Augusta","NJ",41.14,-74.68,42697663],
		["Elizabethtown","NY",44.22,-73.6,42697801],
		["Stittville","NY",43.22,-75.28,42697939],
		["Rodman","NY",43.81,-75.81,42698077],
		["Cowlesville","NY",42.8,-78.44,42698215],
		["Lawtons","NY",42.54,-78.89,42698353],
		["Lake Lynn","PA",39.74,-79.84,42698491],
		["Unityville","PA",41.24,-76.52,42698629],
		["Riverside","PA",40.95,-76.63,42698767],
		["Bally","PA",40.4,-75.58,42698905],
		["Saint Inigoes","MD",38.15,-76.41,42699043],
		["Nashville","TN",36.17,-86.78,42699181],
		["Jakin","GA",31.14,-85,42699319],
		["Hinkle","KY",36.91,-83.81,42699457],
		["Waldo","OH",40.46,-83.08,42699595],
		["Scottown","OH",38.61,-82.39,42699733],
		["Elnora","IN",38.87,-87.08,42699871],
		["Linden","IN",40.19,-86.9,42700009],
		["New Ross","IN",39.96,-86.71,42700147],
		["Mears","MI",43.68,-86.41,42700285],
		["De Soto","IA",41.53,-94,42700423],
		["Radcliffe","IA",42.31,-93.43,42700561],
		["Hornick","IA",42.23,-96.09,42700699],
		["Ruthven","IA",43.12,-94.89,42700837],
		["Stanton","IA",40.98,-95.1,42700975],
		["Keystone","IA",41.99,-92.19,42701113],
		["Richland","IA",41.18,-91.99,42701251],
		["Granada","MN",43.69,-94.34,42701389],
		["Renner","SD",43.63,-96.7,42701527],
		["Ullin","IL",37.28,-89.18,42701665],
		["Maywood","MO",39.95,-91.6,42701803],
		["Meta","MO",38.31,-92.16,42701941],
		["Weaubleau","MO",37.89,-93.54,42702079],
		["Paron","AR",34.8,-92.85,42702217],
		["Dewar","OK",35.45,-95.94,42702355],
		["Evant","TX",31.47,-98.14,42702493],
		["Sterling City","TX",31.83,-100.98,42702631],
		["West Point","TX",29.96,-97.03,42702769],
		["Livermore","CO",40.75,-105.23,42702907],
		["Lagrange","WY",41.62,-104.34,42703045],
		["Cowley","WY",44.88,-108.47,42703183],
		["Bingham Canyon","UT",40.59,-112.1,42703321],
		["Levan","UT",39.52,-111.88,42703459],
		["Jefferson","NH",44.41,-71.47,42703596],
		["Wayne","ME",44.34,-70.06,42703733],
		["Thetford Center","VT",43.82,-72.27,42703870],
		["East Hardwick","VT",44.52,-72.3,42704007],
		["Unionville","NY",41.3,-74.56,42704144],
		["Mount Vision","NY",42.57,-75.05,42704281],
		["East Otto","NY",42.4,-78.74,42704418],
		["Utica","PA",41.43,-79.95,42704555],
		["Mount Jewett","PA",41.72,-78.64,42704692],
		["Middlebury Center","PA",41.89,-77.3,42704829],
		["Damascus","PA",41.7,-75.06,42704966],
		["Ford","VA",37.14,-77.73,42705103],
		["Keokee","VA",36.86,-82.9,42705240],
		["Liberty","WV",38.63,-81.76,42705377],
		["Huttonsville","WV",38.71,-79.97,42705514],
		["Lane","SC",33.52,-79.88,42705651],
		["Bath","SC",33.5,-81.87,42705788],
		["Atlanta","GA",33.74,-84.54,42705925],
		["Gainestown","AL",31.44,-87.69,42706062],
		["Clairfield","TN",36.54,-83.95,42706199],
		["Westfield Center","OH",41.02,-81.93,42706336],
		["Fredericksburg","IN",38.43,-86.18,42706473],
		["Bay Port","MI",43.84,-83.37,42706610],
		["Gagetown","MI",43.65,-83.24,42706747],
		["Otley","IA",41.45,-93.03,42706884],
		["Wellsburg","IA",42.43,-92.92,42707021],
		["Ridgeway","WI",42.99,-89.99,42707158],
		["Ogdensburg","WI",44.45,-89.03,42707295],
		["Wishek","ND",46.25,-99.55,42707432],
		["Glen Ullin","ND",46.81,-101.83,42707569],
		["Hungry Horse","MT",48.37,-114.06,42707706],
		["Crossville","IL",38.16,-88.06,42707843],
		["Winchester","KS",39.32,-95.26,42707980],
		["Edna","KS",37.05,-95.35,42708117],
		["Homer","NE",42.32,-96.49,42708254],
		["Weston","NE",41.19,-96.74,42708391],
		["Bellwood","NE",41.34,-97.24,42708528],
		["Allen","NE",42.41,-96.84,42708665],
		["Ewing","NE",42.25,-98.34,42708802],
		["Hampton","NE",40.88,-97.88,42708939],
		["Springer","OK",34.28,-97.12,42709076],
		["Davenport","OK",35.7,-96.76,42709213],
		["Grulla","TX",26.39,-98.55,42709350],
		["Weippe","ID",46.38,-115.89,42709487],
		["Isleta","NM",34.91,-106.82,42709624],
		["San Ardo","CA",36,-120.87,42709761],
		["El Nido","CA",37.12,-120.51,42709898],
		["Ariel","WA",46.01,-122.47,42710035],
		["Barnstead","NH",43.33,-71.29,42710171],
		["Lempster","NH",43.23,-72.21,42710307],
		["Vinalhaven","ME",44.04,-68.83,42710443],
		["Townshend","VT",43.04,-72.66,42710579],
		["Graniteville","VT",44.15,-72.47,42710715],
		["Pottersville","NJ",40.66,-74.82,42710851],
		["Milmay","NJ",39.44,-74.86,42710987],
		["Roxbury","NY",42.28,-74.56,42711123],
		["Westbrookville","NY",41.5,-74.55,42711259],
		["Bradford","NY",42.37,-77.1,42711395],
		["Terre Hill","PA",40.15,-76.05,42711531],
		["Lignum","VA",38.41,-77.82,42711667],
		["Sperryville","VA",38.67,-78.25,42711803],
		["Maysel","WV",38.48,-81.12,42711939],
		["Sumerco","WV",38.19,-81.89,42712075],
		["Ernul","NC",35.29,-77.03,42712211],
		["Gable","SC",33.84,-80.08,42712347],
		["Wray","GA",31.62,-83.05,42712483],
		["Thomaston","AL",32.26,-87.62,42712619],
		["Enville","TN",35.38,-88.42,42712755],
		["Manitou","KY",37.41,-87.57,42712891],
		["Chauncey","OH",39.39,-82.12,42713027],
		["Hillsdale","IN",39.76,-87.4,42713163],
		["Brethren","MI",44.3,-86.01,42713299],
		["Dow City","IA",41.92,-95.49,42713435],
		["Drakesville","IA",40.79,-92.48,42713571],
		["Mcintosh","MN",47.63,-95.88,42713707],
		["Bryant","SD",44.58,-97.46,42713843],
		["Argusville","ND",47.05,-96.94,42713979],
		["Napoleon","ND",46.5,-99.76,42714115],
		["Trivoli","IL",40.69,-89.88,42714251],
		["Newman","IL",39.79,-87.98,42714387],
		["Keytesville","MO",39.43,-92.93,42714523],
		["Moscow","KS",37.32,-101.2,42714659],
		["Deshler","NE",40.13,-97.72,42714795],
		["Indianola","NE",40.23,-100.41,42714931],
		["Maxwell","NE",41.07,-100.52,42715067],
		["Hammon","OK",35.63,-99.38,42715203],
		["Byars","OK",34.87,-97.05,42715339],
		["Bryson","TX",33.15,-98.38,42715475],
		["Boone","CO",38.25,-104.25,42715611],
		["Granada","CO",38.06,-102.31,42715747],
		["La Veta","CO",37.51,-105,42715883],
		["Wiley","CO",38.15,-102.71,42716019],
		["Hulett","WY",44.68,-104.6,42716155],
		["Kanosh","UT",38.81,-112.42,42716291],
		["Carrizozo","NM",33.64,-105.88,42716427],
		["Mountain Ranch","CA",38.23,-120.49,42716563],
		["Glenn","CA",39.6,-122.02,42716699],
		["Ninilchik","AK",60.07,-151.42,42716835],
		["Scammon Bay","AK",61.74,-165.47,42716971],
		["Savoonga","AK",63.68,-170.49,42717107],
		["New Braintree","MA",42.31,-72.13,42717242],
		["Surry","ME",44.49,-68.5,42717377],
		["Eden","VT",44.7,-72.55,42717512],
		["Fleischmanns","NY",42.15,-74.53,42717647],
		["Schuyler Falls","NY",44.55,-73.75,42717782],
		["Salisbury Center","NY",43.23,-74.79,42717917],
		["Bradfordwoods","PA",40.63,-80.08,42718052],
		["Cairnbrook","PA",40.11,-78.81,42718187],
		["Zion Grove","PA",40.93,-76.25,42718322],
		["Goldvein","VA",38.44,-77.65,42718457],
		["Free Union","VA",38.15,-78.56,42718592],
		["Callands","VA",36.81,-79.58,42718727],
		["Long Island","VA",37.08,-79.1,42718862],
		["Bradshaw","WV",37.35,-81.8,42718997],
		["Red Jacket","WV",37.64,-82.13,42719132],
		["Thornton","WV",39.34,-79.93,42719267],
		["Elk Garden","WV",39.38,-79.15,42719402],
		["Tate","GA",34.42,-84.29,42719537],
		["Warthen","GA",33.13,-82.79,42719672],
		["Greensboro","FL",30.57,-84.74,42719807],
		["Coffee Springs","AL",31.16,-85.9,42719942],
		["Thelma","KY",37.81,-82.76,42720077],
		["Emmalena","KY",37.33,-83.07,42720212],
		["Sarahsville","OH",39.8,-81.46,42720347],
		["Decker","MI",43.45,-83.04,42720482],
		["Palmyra","MI",41.87,-83.94,42720617],
		["Hessel","MI",46.03,-84.44,42720752],
		["Rock","MI",46.06,-87.16,42720887],
		["Prole","IA",41.38,-93.73,42721022],
		["Lawler","IA",43.07,-92.15,42721157],
		["Stanwood","IA",41.89,-91.15,42721292],
		["Conover","WI",46.04,-89.25,42721427],
		["Eastman","WI",43.16,-91.02,42721562],
		["Foxboro","WI",46.48,-92.28,42721697],
		["Maple","WI",46.58,-91.72,42721832],
		["Dexter","MN",43.71,-92.7,42721967],
		["Fountain","MN",43.74,-92.13,42722102],
		["Minnesota Lake","MN",43.84,-93.82,42722237],
		["Brockton","MT",48.27,-104.82,42722372],
		["Yates City","IL",40.77,-90.01,42722507],
		["Allendale","IL",38.52,-87.71,42722642],
		["Creighton","MO",38.49,-94.07,42722777],
		["Eskridge","KS",38.85,-96.1,42722912],
		["Paxico","KS",39.06,-96.16,42723047],
		["Tescott","KS",39.01,-97.87,42723182],
		["Bucklin","KS",37.54,-99.63,42723317],
		["Ingalls","KS",37.82,-100.45,42723452],
		["Palmer","NE",41.22,-98.21,42723587],
		["Bentley","LA",31.48,-92.5,42723722],
		["El Paso","AR",35.12,-92.07,42723857],
		["Wesley","AR",36.03,-93.92,42723992],
		["Guy","TX",29.33,-95.76,42724127],
		["Winter Park","CO",39.88,-105.77,42724262],
		["Dayton","WY",44.87,-107.25,42724397],
		["Sonoita","AZ",31.75,-110.66,42724532],
		["Point Roberts","WA",48.98,-123.05,42724667],
		["Fairfield","WA",47.39,-117.18,42724802],
		["Evans","WA",48.72,-117.96,42724937],
		["Grand Coulee","WA",47.99,-119,42725072],
		["Stetson","ME",44.89,-69.14,42725206],
		["Athens","ME",44.92,-69.67,42725340],
		["Jackman","ME",45.62,-70.25,42725474],
		["Troy","ME",44.66,-69.24,42725608],
		["Neversink","NY",41.84,-74.61,42725742],
		["Natural Bridge","NY",44.09,-75.47,42725876],
		["Mont Clare","PA",40.13,-75.49,42726010],
		["Barton","MD",39.53,-79.01,42726144],
		["Quicksburg","VA",38.72,-78.71,42726278],
		["Troutdale","VA",36.7,-81.43,42726412],
		["Wharton","WV",37.9,-81.69,42726546],
		["Misenheimer","NC",35.48,-80.28,42726680],
		["Cragford","AL",33.21,-85.69,42726814],
		["Randolph","AL",32.89,-86.9,42726948],
		["Isom","KY",37.18,-82.89,42727082],
		["Lovilia","IA",41.13,-92.9,42727216],
		["Union","IA",42.24,-93.06,42727350],
		["Mondamin","IA",41.7,-96.02,42727484],
		["Sabula","IA",42.06,-90.17,42727618],
		["Sarona","WI",45.71,-91.8,42727752],
		["Gayville","SD",42.88,-97.17,42727886],
		["Center","ND",47.11,-101.29,42728020],
		["Woodhull","IL",41.17,-90.32,42728154],
		["Thomasboro","IL",40.24,-88.18,42728288],
		["Dalton City","IL",39.71,-88.8,42728422],
		["Lenzburg","IL",38.28,-89.81,42728556],
		["Columbia","MO",38.95,-92.32,42728690],
		["Dorchester","NE",40.64,-97.11,42728824],
		["Verdigre","NE",42.59,-98.03,42728958],
		["Dannebrog","NE",41.11,-98.54,42729092],
		["Kingsland","AR",33.86,-92.29,42729226],
		["Stuart","OK",34.9,-96.09,42729360],
		["Montalba","TX",31.93,-95.76,42729494],
		["Mexican Hat","UT",37.3,-110.02,42729628],
		["Mexican Springs","NM",35.78,-108.8,42729762],
		["North Palm Springs","CA",33.9,-116.56,42729896],
		["Mccloud","CA",41.26,-122.1,42730030],
		["Oak Run","CA",40.66,-122.03,42730164],
		["Herlong","CA",40.2,-120.19,42730298],
		["Camas Valley","OR",43.05,-123.67,42730432],
		["Aniak","AK",61.2,-158.6,42730566],
		["Healy","AK",64.01,-148.35,42730700],
		["Glenwood Landing","NY",40.82,-73.63,42730833],
		["Mountain Dale","NY",41.68,-74.53,42730966],
		["Forestport","NY",43.44,-75.2,42731099],
		["Hubbardsville","NY",42.81,-75.46,42731232],
		["Fairmount City","PA",41.05,-79.29,42731365],
		["Keezletown","VA",38.45,-78.76,42731498],
		["Caldwell","WV",37.75,-80.34,42731631],
		["Hico","WV",38.11,-80.94,42731764],
		["Alma","WV",39.42,-80.82,42731897],
		["Smyrna","SC",35.04,-81.41,42732030],
		["Akron","AL",32.87,-87.73,42732163],
		["Saint Stephens","AL",31.53,-88.05,42732296],
		["Toxey","AL",31.91,-88.3,42732429],
		["Quebeck","TN",35.83,-85.54,42732562],
		["Hardy","KY",37.61,-82.24,42732695],
		["South Bloomingville","OH",39.41,-82.59,42732828],
		["Shawnee","OH",39.61,-82.2,42732961],
		["Hanna","IN",41.4,-86.75,42733094],
		["Garwin","IA",42.09,-92.67,42733227],
		["Wall Lake","IA",42.26,-95.09,42733360],
		["Darwin","MN",45.09,-94.4,42733493],
		["New Germany","MN",44.88,-93.97,42733626],
		["Hokah","MN",43.76,-91.35,42733759],
		["Ulen","MN",47.07,-96.25,42733892],
		["Chapin","IL",39.76,-90.4,42734025],
		["Ridgway","IL",37.79,-88.26,42734158],
		["Arbyrd","MO",36.05,-90.24,42734291],
		["Walton","NE",40.74,-96.51,42734424],
		["Provencal","LA",31.65,-93.2,42734557],
		["Carney","OK",35.8,-97.01,42734690],
		["Cheyenne Wells","CO",38.81,-102.35,42734823],
		["Blanchard","ID",48.04,-116.96,42734956],
		["Altamont","UT",40.43,-110.25,42735089],
		["Congress","AZ",34.21,-112.97,42735222],
		["Earp","CA",34.15,-114.33,42735355],
		["Kotlik","AK",62.84,-163.65,42735488],
		["Franconia","NH",44.22,-71.74,42735620],
		["Chelsea","VT",43.98,-72.47,42735752],
		["Jamaica","VT",43.1,-72.8,42735884],
		["Maugansville","MD",39.69,-77.75,42736016],
		["Dungannon","VA",36.82,-82.46,42736148],
		["Matoaka","WV",37.41,-81.24,42736280],
		["Camden On Gauley","WV",38.36,-80.59,42736412],
		["Currituck","NC",36.44,-76.01,42736544],
		["Lavinia","TN",35.86,-88.64,42736676],
		["Austin","KY",36.81,-86.01,42736808],
		["Saint Meinrad","IN",38.18,-86.85,42736940],
		["Port Hope","MI",43.93,-82.71,42737072],
		["Flint","MI",43.02,-83.69,42737204],
		["Swea City","IA",43.38,-94.31,42737336],
		["Whittemore","IA",43.06,-94.42,42737468],
		["Anthon","IA",42.38,-95.86,42737600],
		["Miles","IA",42.04,-90.31,42737732],
		["Fisher","MN",47.79,-96.79,42737864],
		["Florence","SD",45.05,-97.32,42737996],
		["Armour","SD",43.31,-98.34,42738128],
		["Hot Springs","MT",47.66,-114.55,42738260],
		["Hardin","MO",39.26,-93.83,42738392],
		["Schell City","MO",38.01,-94.11,42738524],
		["Bucyrus","MO",37.34,-92.01,42738656],
		["Scammon","KS",37.27,-94.82,42738788],
		["Exeter","NE",40.64,-97.44,42738920],
		["Martell","NE",40.65,-96.74,42739052],
		["Phillips","NE",40.89,-98.21,42739184],
		["Barataria","LA",29.71,-90.12,42739316],
		["Moscow","TX",30.92,-94.93,42739448],
		["Newcastle","TX",33.19,-98.74,42739580],
		["Blanca","CO",37.43,-105.51,42739712],
		["Elmo","UT",39.28,-110.67,42739844],
		["Escalante","UT",37.77,-111.6,42739976],
		["Animas","NM",31.94,-108.83,42740108],
		["Lakeside","OR",43.58,-124.08,42740240],
		["Gambell","AK",63.75,-171.69,42740372],
		["Kittery Point","ME",43.08,-70.69,42740503],
		["Jonesport","ME",44.53,-67.59,42740634],
		["Brookfield","VT",44.05,-72.6,42740765],
		["Port Republic","NJ",39.53,-74.48,42740896],
		["Granville Summit","PA",41.71,-76.77,42741027],
		["Temperanceville","VA",37.89,-75.54,42741158],
		["Java","VA",36.83,-79.23,42741289],
		["Le Roy","WV",38.95,-81.57,42741420],
		["Barney","GA",31,-83.51,42741551],
		["Pocahontas","TN",35.05,-88.81,42741682],
		["Olympia","KY",38.09,-83.69,42741813],
		["New Bloomington","OH",40.58,-83.31,42741944],
		["Hartsville","IN",39.26,-85.69,42742075],
		["Early","IA",42.46,-95.15,42742206],
		["Ocheyedan","IA",43.41,-95.53,42742337],
		["Conesville","IA",41.38,-91.34,42742468],
		["Hewitt","WI",44.64,-90.1,42742599],
		["Winter","WI",45.82,-91.01,42742730],
		["Keewatin","MN",47.4,-93.08,42742861],
		["Sacred Heart","MN",44.78,-95.35,42742992],
		["Osage","MN",46.92,-95.25,42743123],
		["Big Stone City","SD",45.29,-96.46,42743254],
		["Wilmot","SD",45.4,-96.85,42743385],
		["Eureka","SD",45.76,-99.62,42743516],
		["Burke","SD",43.18,-99.29,42743647],
		["Sunburst","MT",48.86,-111.89,42743778],
		["Winslow","IL",42.49,-89.79,42743909],
		["Center","MO",39.5,-91.52,42744040],
		["Hornersville","MO",36.04,-90.11,42744171],
		["Easton","MO",39.72,-94.63,42744302],
		["Union Star","MO",39.97,-94.59,42744433],
		["Winside","NE",42.17,-97.17,42744564],
		["Chester","AR",35.67,-94.17,42744695],
		["Rattan","OK",34.19,-95.41,42744826],
		["Bardwell","TX",32.26,-96.69,42744957],
		["Ector","TX",33.57,-96.27,42745088],
		["Arimo","ID",42.54,-112.28,42745219],
		["Lava Hot Springs","ID",42.61,-112.04,42745350],
		["Dubois","ID",44.22,-112.37,42745481],
		["Clarkston","UT",41.92,-112.06,42745612],
		["Prairie City","OR",44.38,-118.64,42745743],
		["Bristol","ME",43.95,-69.5,42745873],
		["Chittenden","VT",43.72,-72.95,42746003],
		["Prattsville","NY",42.31,-74.43,42746133],
		["Parksville","NY",41.85,-74.76,42746263],
		["Erieville","NY",42.85,-75.75,42746393],
		["Chemung","NY",42,-76.62,42746523],
		["Buffalo Mills","PA",39.92,-78.63,42746653],
		["Wilcox","PA",41.58,-78.68,42746783],
		["Armagh","PA",40.45,-79.03,42746913],
		["Venango","PA",41.77,-80.11,42747043],
		["Tracys Landing","MD",38.76,-76.58,42747173],
		["Rocky Ridge","MD",39.61,-77.33,42747303],
		["Crozier","VA",37.63,-77.79,42747433],
		["North","VA",37.46,-76.38,42747563],
		["Amherstdale","WV",37.8,-81.75,42747693],
		["Rowlesburg","WV",39.34,-79.67,42747823],
		["Sunset","SC",34.99,-82.84,42747953],
		["Knoxville","GA",32.63,-83.96,42748083],
		["Musella","GA",32.83,-84.04,42748213],
		["Rhine","GA",31.98,-83.2,42748343],
		["Lorida","FL",27.45,-81.26,42748473],
		["Quincy","KY",38.63,-83.09,42748603],
		["Buckhorn","KY",37.34,-83.47,42748733],
		["Paris Crossing","IN",38.85,-85.74,42748863],
		["What Cheer","IA",41.4,-92.35,42748993],
		["Marengo","WI",46.42,-90.81,42749123],
		["Clarks Grove","MN",43.76,-93.32,42749253],
		["Villard","MN",45.71,-95.26,42749383],
		["Big Sandy","MT",48,-110.08,42749513],
		["Chandlerville","IL",40.04,-90.15,42749643],
		["Patterson","MO",37.21,-90.57,42749773],
		["Williamsburg","KS",38.48,-95.46,42749903],
		["Axtell","KS",39.87,-96.25,42750033],
		["Turon","KS",37.8,-98.42,42750163],
		["Clay Center","NE",40.52,-98.05,42750293],
		["Oak Ridge","LA",32.62,-91.77,42750423],
		["Verden","OK",35.08,-98.08,42750553],
		["Pattonville","TX",33.56,-95.39,42750683],
		["Rye","TX",30.31,-94.76,42750813],
		["Utopia","TX",29.61,-99.53,42750943],
		["Big Sur","CA",36.08,-121.6,42751073],
		["Cazadero","CA",38.56,-123.2,42751203],
		["Willow Creek","CA",40.94,-123.65,42751333],
		["Berry Creek","CA",39.66,-121.38,42751463],
		["Alsea","OR",44.35,-123.65,42751593],
		["Saint Marys","AK",62.06,-163.29,42751723],
		["Millers Falls","MA",42.59,-72.55,42751852],
		["West Baldwin","ME",43.83,-70.77,42751981],
		["Solon","ME",44.94,-69.85,42752110],
		["New York","NY",40.71,-73.99,42752239],
		["Clayville","NY",42.97,-75.25,42752368],
		["Shade Gap","PA",40.18,-77.86,42752497],
		["Barhamsville","VA",37.45,-76.84,42752626],
		["Hartfield","VA",37.55,-76.44,42752755],
		["Jolo","WV",37.33,-81.83,42752884],
		["Hobgood","NC",36.02,-77.39,42753013],
		["Margaret","AL",33.68,-86.47,42753142],
		["Pansey","AL",31.15,-85.17,42753271],
		["Moss","TN",36.59,-85.68,42753400],
		["Bonnyman","KY",37.31,-83.22,42753529],
		["Graham","KY",37.24,-87.29,42753658],
		["Fonda","IA",42.58,-94.84,42753787],
		["Granville","IA",42.98,-95.87,42753916],
		["Garnavillo","IA",42.86,-91.23,42754045],
		["Northome","MN",47.87,-94.26,42754174],
		["Leola","SD",45.72,-98.93,42754303],
		["Lidgerwood","ND",46.07,-97.14,42754432],
		["Walcott","ND",46.55,-96.93,42754561],
		["Buda","IL",41.32,-89.67,42754690],
		["Green Castle","MO",40.26,-92.87,42754819],
		["Collins","MO",37.89,-93.62,42754948],
		["Bakersfield","MO",36.52,-92.14,42755077],
		["Jefferson","AR",34.36,-92.13,42755206],
		["Osage","OK",36.29,-96.41,42755335],
		["Langley","OK",36.46,-95.05,42755464],
		["Mill Creek","OK",34.4,-96.82,42755593],
		["Deport","TX",33.52,-95.31,42755722],
		["Fort Worth","TX",32.75,-97.33,42755851],
		["Kopperl","TX",32.11,-97.58,42755980],
		["Zephyr","TX",31.67,-98.79,42756109],
		["Chillicothe","TX",34.25,-99.51,42756238],
		["Hugo","CO",39.13,-103.46,42756367],
		["Bluebell","UT",40.33,-110.2,42756496],
		["Arroyo Seco","NM",36.51,-105.56,42756625],
		["Rincon","NM",32.67,-107.06,42756754],
		["Traver","CA",36.45,-119.48,42756883],
		["Patten","ME",45.99,-68.44,42757011],
		["Mineville","NY",44.08,-73.52,42757139],
		["Penn","PA",40.32,-79.64,42757267],
		["Hilliards","PA",41.1,-79.83,42757395],
		["West Milton","PA",41.01,-76.8,42757523],
		["Beaver Meadows","PA",40.92,-75.91,42757651],
		["Cecilton","MD",39.4,-75.86,42757779],
		["Manquin","VA",37.7,-77.15,42757907],
		["Lynco","WV",37.7,-81.67,42758035],
		["Lyburn","WV",37.74,-81.93,42758163],
		["Lerona","WV",37.5,-80.98,42758291],
		["Belmont","WV",39.37,-81.26,42758419],
		["Coalton","WV",38.9,-80,42758547],
		["Savannah","GA",32.08,-81.1,42758675],
		["Steinhatchee","FL",29.67,-83.39,42758803],
		["Verona","MS",34.19,-88.71,42758931],
		["Water Valley","KY",36.56,-88.81,42759059],
		["Summersville","KY",37.31,-85.61,42759187],
		["Oldenburg","IN",39.33,-85.2,42759315],
		["Spruce","MI",44.83,-83.46,42759443],
		["Lansing","MI",42.7,-84.55,42759571],
		["Mc Millan","MI",46.37,-85.74,42759699],
		["Arlington","IA",42.74,-91.67,42759827],
		["Emerson","IA",41.01,-95.4,42759955],
		["Eland","WI",44.86,-89.21,42760083],
		["Trego","WI",45.89,-91.82,42760211],
		["Lafayette","MN",44.44,-94.39,42760339],
		["Maynard","MN",44.9,-95.46,42760467],
		["Ponsford","MN",46.97,-95.38,42760595],
		["Ethan","SD",43.54,-97.98,42760723],
		["Altona","IL",41.11,-90.16,42760851],
		["Golden","IL",40.1,-91.01,42760979],
		["Ewing","IL",38.08,-88.85,42761107],
		["Shobonier","IL",38.86,-89.08,42761235],
		["Wheaton","MO",36.76,-94.05,42761363],
		["Mc Cune","KS",37.35,-95.01,42761491],
		["Norwood","LA",30.96,-91.1,42761619],
		["Waterproof","LA",31.8,-91.38,42761747],
		["Perry","AR",35.04,-92.79,42761875],
		["Keiser","AR",35.67,-90.09,42762003],
		["Drummond","OK",36.3,-98.03,42762131],
		["Hendrix","OK",33.77,-96.4,42762259],
		["Wells","TX",31.49,-94.94,42762387],
		["Forestburg","TX",33.54,-97.59,42762515],
		["Oglesby","TX",31.41,-97.5,42762643],
		["Saratoga","TX",30.28,-94.52,42762771],
		["Bliss","ID",43.01,-114.94,42762899],
		["Mc Gill","NV",39.49,-114.75,42763027],
		["Saint John","WA",47.1,-117.64,42763155],
		["Wood River Junction","RI",41.45,-71.7,42763282],
		["Canton","ME",44.44,-70.31,42763409],
		["Frankfort","ME",44.6,-68.87,42763536],
		["Old Forge","NY",43.71,-74.97,42763663],
		["Portland","NY",42.38,-79.46,42763790],
		["Holbrook","PA",39.84,-80.34,42763917],
		["Adah","PA",39.88,-79.89,42764044],
		["Bethany Beach","DE",38.53,-75.06,42764171],
		["Singers Glen","VA",38.56,-78.9,42764298],
		["Dyke","VA",38.26,-78.57,42764425],
		["Crum","WV",37.94,-82.44,42764552],
		["Moatsville","WV",39.22,-79.9,42764679],
		["Dixie","GA",30.78,-83.66,42764806],
		["Zellwood","FL",28.73,-81.6,42764933],
		["Kathleen","FL",28.11,-82.02,42765060],
		["Lake Hamilton","FL",28.04,-81.62,42765187],
		["Whitleyville","TN",36.48,-85.7,42765314],
		["New Richmond","IN",40.19,-86.97,42765441],
		["Bannister","MI",43.13,-84.42,42765568],
		["Henderson","MI",43.08,-84.19,42765695],
		["South Range","MI",47.07,-88.64,42765822],
		["Wallingford","IA",43.32,-94.79,42765949],
		["Charlotte","IA",41.96,-90.46,42766076],
		["Lake Norden","SD",44.57,-97.2,42766203],
		["Lake Preston","SD",44.36,-97.37,42766330],
		["Woonsocket","SD",44.05,-98.27,42766457],
		["Twin Bridges","MT",45.45,-112.46,42766584],
		["Montrose","IL",39.16,-88.37,42766711],
		["Mercer","MO",40.51,-93.52,42766838],
		["Saint Thomas","MO",38.36,-92.21,42766965],
		["Marquette","KS",38.55,-97.83,42767092],
		["Sargent","NE",41.64,-99.37,42767219],
		["Hay Springs","NE",42.68,-102.68,42767346],
		["Plattenville","LA",29.98,-90.97,42767473],
		["Center Point","LA",31.26,-92.18,42767600],
		["Forgan","OK",36.9,-100.53,42767727],
		["Cookson","OK",35.7,-94.97,42767854],
		["Apple Springs","TX",31.26,-94.97,42767981],
		["Palo Pinto","TX",32.75,-98.28,42768108],
		["Hunt","TX",30.06,-99.33,42768235],
		["Mackay","ID",43.95,-113.84,42768362],
		["Virgin","UT",37.3,-113.08,42768489],
		["Lewiston","CA",40.78,-122.84,42768616],
		["Standish","CA",40.35,-120.38,42768743],
		["Trout Lake","WA",45.97,-121.53,42768870],
		["Pawlet","VT",43.35,-73.18,42768996],
		["Allenwood","NJ",40.13,-74.09,42769122],
		["Remsenburg","NY",40.89,-72.42,42769248],
		["East Durham","NY",42.38,-74.11,42769374],
		["Genoa","NY",42.66,-76.53,42769500],
		["Cameron Mills","NY",42.18,-77.36,42769626],
		["Ellsworth","PA",40.1,-80.02,42769752],
		["Bradenville","PA",40.3,-79.3,42769878],
		["Nicktown","PA",40.59,-78.81,42770004],
		["Westover","PA",40.74,-78.68,42770130],
		["Millheim","PA",40.89,-77.47,42770256],
		["Rich Creek","VA",37.38,-80.82,42770382],
		["Union Hall","VA",37,-79.68,42770508],
		["Collettsville","NC",35.92,-81.66,42770634],
		["Longboat Key","FL",27.37,-82.62,42770760],
		["Webster","KY",37.88,-86.33,42770886],
		["Ermine","KY",37.11,-82.79,42771012],
		["Cleveland","OH",41.47,-81.67,42771138],
		["Twelve Mile","IN",40.85,-86.2,42771264],
		["Branch","MI",43.91,-86.12,42771390],
		["Gilman","IA",41.87,-92.78,42771516],
		["Bancroft","IA",43.29,-94.21,42771642],
		["Agency","IA",40.99,-92.3,42771768],
		["Grand Mound","IA",41.82,-90.64,42771894],
		["Dickeyville","WI",42.62,-90.59,42772020],
		["Ogema","WI",45.44,-90.29,42772146],
		["Danube","MN",44.79,-95.1,42772272],
		["West Union","IL",39.21,-87.66,42772398],
		["Saint Louis","MO",38.63,-90.24,42772524],
		["Centralia","KS",39.72,-96.12,42772650],
		["Kismet","KS",37.2,-100.7,42772776],
		["Lindsay","NE",41.7,-97.69,42772902],
		["Glenvil","NE",40.5,-98.25,42773028],
		["Burneyville","OK",33.9,-97.28,42773154],
		["Coleman","OK",34.27,-96.42,42773280],
		["Petrolia","TX",34.01,-98.23,42773406],
		["Kosse","TX",31.3,-96.62,42773532],
		["Walsh","CO",37.38,-102.28,42773658],
		["Cimarron","NM",36.5,-104.91,42773784],
		["Logan","NM",35.36,-103.44,42773910],
		["Warner Springs","CA",33.28,-116.6,42774036],
		["Adams","OR",45.71,-118.48,42774162],
		["Klawock","AK",55.54,-132.96,42774288],
		["Ashley Falls","MA",42.05,-73.33,42774413],
		["Buzzards Bay","MA",41.75,-70.61,42774538],
		["Deal","NJ",40.24,-73.99,42774663],
		["Nemacolin","PA",39.87,-79.92,42774788],
		["Andreas","PA",40.74,-75.82,42774913],
		["Gilbert","PA",40.9,-75.43,42775038],
		["Delaplane","VA",38.92,-77.92,42775163],
		["Valley Lee","MD",38.18,-76.5,42775288],
		["Garrett Park","MD",39.03,-77.09,42775413],
		["Cabin Creek","WV",38.17,-81.5,42775538],
		["Holden","WV",37.81,-82.06,42775663],
		["Ellenboro","WV",39.26,-81.05,42775788],
		["Cooleemee","NC",35.81,-80.55,42775913],
		["Moody A F B","GA",30.84,-83.27,42776038],
		["Hodges","AL",34.32,-87.92,42776163],
		["Mathews","AL",32.13,-86.03,42776288],
		["Paulding","MS",32,-89.07,42776413],
		["Herndon","KY",36.71,-87.6,42776538],
		["Roundhill","KY",37.23,-86.45,42776663],
		["Urbana","IN",40.89,-85.71,42776788],
		["Casey","IA",41.5,-94.52,42776913],
		["Wesley","IA",43.08,-93.99,42777038],
		["Breda","IA",42.18,-94.97,42777163],
		["Vail","IA",42.05,-95.2,42777288],
		["Ryan","IA",42.35,-91.48,42777413],
		["Callaway","MN",46.98,-95.91,42777538],
		["Wall","SD",43.99,-102.23,42777663],
		["Edgeley","ND",46.36,-98.71,42777788],
		["Towner","ND",48.34,-100.4,42777913],
		["Heart Butte","MT",48.23,-112.77,42778038],
		["Lincoln","MT",47.16,-112.68,42778163],
		["Chana","IL",41.99,-89.2,42778288],
		["Peoria","IL",40.74,-89.6,42778413],
		["New Douglas","IL",38.97,-89.66,42778538],
		["Cantrall","IL",39.93,-89.68,42778663],
		["Belle Rive","IL",38.23,-88.73,42778788],
		["Delia","KS",39.23,-95.96,42778913],
		["Herman","NE",41.67,-96.21,42779038],
		["Buckner","AR",33.35,-93.43,42779163],
		["Casa","AR",35.02,-93.04,42779288],
		["Dyess","AR",35.58,-90.21,42779413],
		["Scotland","TX",33.64,-98.45,42779538],
		["Silverton","TX",34.47,-101.3,42779663],
		["Fleming","CO",40.68,-102.84,42779788],
		["Rush","CO",38.84,-104.08,42779913],
		["Olney Springs","CO",38.16,-103.94,42780038],
		["Verdi","NV",39.51,-119.99,42780163],
		["Caliente","CA",35.4,-118.51,42780288],
		["Colton","WA",46.56,-117.22,42780413],
		["Quinhagak","AK",59.81,-161.71,42780538],
		["Acra","NY",42.33,-74.09,42780662],
		["Lewis","NY",44.27,-73.57,42780786],
		["Hunt","NY",42.52,-77.98,42780910],
		["East Waterford","PA",40.36,-77.67,42781034],
		["Cofield","NC",36.35,-76.9,42781158],
		["Dixon Springs","TN",36.4,-86.04,42781282],
		["Reliance","TN",35.18,-84.56,42781406],
		["Hickman","TN",36.1,-85.9,42781530],
		["Pattison","MS",31.85,-90.8,42781654],
		["Ashcamp","KY",37.26,-82.43,42781778],
		["Phyllis","KY",37.44,-82.33,42781902],
		["Arlington","IN",39.65,-85.57,42782026],
		["Poneto","IN",40.65,-85.22,42782150],
		["Evanston","IN",38.02,-86.83,42782274],
		["Burt","IA",43.19,-94.22,42782398],
		["Pomeroy","IA",42.55,-94.67,42782522],
		["Honey Creek","IA",41.46,-95.9,42782646],
		["Clermont","IA",43,-91.65,42782770],
		["Chelsea","IA",41.92,-92.39,42782894],
		["Sister Bay","WI",45.18,-87.12,42783018],
		["Glidden","WI",46.13,-90.57,42783142],
		["Cromwell","MN",46.67,-92.87,42783266],
		["Kadoka","SD",43.83,-101.5,42783390],
		["Culbertson","MT",47.99,-104.49,42783514],
		["Wibaux","MT",47.01,-104.32,42783638],
		["Gilson","IL",40.86,-90.2,42783762],
		["Augusta","IL",40.23,-90.94,42783886],
		["Wardell","MO",36.35,-89.81,42784010],
		["Camden Point","MO",39.45,-94.74,42784134],
		["Fairfax","MO",40.33,-95.39,42784258],
		["Louisburg","MO",37.75,-93.13,42784382],
		["Rolla","KS",37.11,-101.63,42784506],
		["Kennard","NE",41.47,-96.2,42784630],
		["Ansley","NE",41.28,-99.38,42784754],
		["Urania","LA",31.86,-92.29,42784878],
		["Ravenden Springs","AR",36.31,-91.22,42785002],
		["Chester","TX",30.92,-94.6,42785126],
		["Indian Hills","CO",39.62,-105.23,42785250],
		["Weldona","CO",40.35,-103.97,42785374],
		["Carpenter","WY",41.03,-104.34,42785498],
		["Hamer","ID",43.98,-112.18,42785622],
		["Jensen","UT",40.47,-109.26,42785746],
		["Wallsburg","UT",40.36,-111.43,42785870],
		["San Diego","CA",32.7,-117.2,42785994],
		["Yountville","CA",38.41,-122.36,42786118],
		["Alta","CA",39.21,-120.79,42786242],
		["Carbonado","WA",47.01,-121.98,42786366],
		["Ilwaco","WA",46.31,-123.99,42786490],
		["Pilot Station","AK",61.94,-162.88,42786614],
		["Chilmark","MA",41.34,-70.74,42786737],
		["Greenwood","ME",44.4,-70.7,42786860],
		["Exeter","ME",44.97,-69.14,42786983],
		["Columbia Falls","ME",44.65,-67.72,42787106],
		["New Berlin","PA",40.88,-76.98,42787229],
		["Le Raysville","PA",41.83,-76.18,42787352],
		["Solomons","MD",38.33,-76.45,42787475],
		["Newark","MD",38.25,-75.29,42787598],
		["Port Haywood","VA",37.38,-76.31,42787721],
		["Reedy","WV",38.89,-81.42,42787844],
		["Ceredo","WV",38.39,-82.55,42787967],
		["Palestine","WV",38.96,-81.42,42788090],
		["Piedmont","WV",39.47,-79.04,42788213],
		["Campbellton","FL",30.95,-85.39,42788336],
		["Detroit","AL",34.02,-88.16,42788459],
		["Allardt","TN",36.38,-84.88,42788582],
		["Sitka","KY",37.88,-82.84,42788705],
		["Weeksbury","KY",37.32,-82.71,42788828],
		["Topmost","KY",37.36,-82.78,42788951],
		["Lindsey","OH",41.42,-83.22,42789074],
		["Conesville","OH",40.18,-81.89,42789197],
		["New Washington","IN",38.56,-85.55,42789320],
		["Unionville","IN",39.25,-86.41,42789443],
		["Presque Isle","MI",45.3,-83.46,42789566],
		["Albion","IA",42.11,-92.98,42789689],
		["Redding","IA",40.6,-94.38,42789812],
		["Whiting","IA",42.12,-96.15,42789935],
		["Little Rock","IA",43.44,-95.88,42790058],
		["Oxford Junction","IA",41.98,-90.95,42790181],
		["Scales Mound","IL",42.47,-90.25,42790304],
		["Varna","IL",41.03,-89.22,42790427],
		["Fieldon","IL",39.1,-90.49,42790550],
		["Kane","IL",39.19,-90.35,42790673],
		["Baldwin","IL",38.18,-89.84,42790796],
		["Hutsonville","IL",39.1,-87.66,42790919],
		["Westfield","IL",39.45,-87.99,42791042],
		["Atlanta","MO",39.89,-92.47,42791165],
		["Brashear","MO",40.14,-92.37,42791288],
		["Falcon","MO",37.6,-92.37,42791411],
		["Waterville","KS",39.69,-96.74,42791534],
		["Cullen","LA",32.97,-93.44,42791657],
		["Hodge","LA",32.27,-92.72,42791780],
		["New Edinburg","AR",33.76,-92.19,42791903],
		["Grannis","AR",34.24,-94.32,42792026],
		["Branch","AR",35.3,-93.95,42792149],
		["Ratliff City","OK",34.44,-97.51,42792272],
		["Canadian","OK",35.17,-95.64,42792395],
		["Dodd City","TX",33.57,-96.07,42792518],
		["Nome","TX",30.03,-94.41,42792641],
		["Placedo","TX",28.66,-96.83,42792764],
		["Eads","CO",38.48,-102.77,42792887],
		["Pavillion","WY",43.24,-108.69,42793010],
		["Mantua","UT",41.48,-111.94,42793133],
		["Patagonia","AZ",31.53,-110.71,42793256],
		["Chamisal","NM",36.17,-105.74,42793379],
		["Igo","CA",40.46,-122.7,42793502],
		["Coleville","CA",38.47,-119.48,42793625],
		["Longbranch","WA",47.2,-122.76,42793748],
		["Fruitland","WA",48,-118.2,42793871],
		["Buckland","AK",65.97,-161.13,42793994],
		["Springfield","NH",43.49,-72.03,42794116],
		["Stoddard","NH",43.08,-72.1,42794238],
		["Westminster","VT",43.07,-72.45,42794360],
		["Rotterdam Junction","NY",42.87,-74.04,42794482],
		["Vermontville","NY",44.52,-74.09,42794604],
		["Richville","NY",44.41,-75.39,42794726],
		["South Kortright","NY",42.37,-74.72,42794848],
		["Sycamore","PA",39.95,-80.22,42794970],
		["Fork Union","VA",37.76,-78.26,42795092],
		["Newton","WV",38.59,-81.15,42795214],
		["Apple Grove","WV",38.7,-82.17,42795336],
		["Mallory","WV",37.73,-81.81,42795458],
		["High View","WV",39.23,-78.4,42795580],
		["Chauncey","GA",32.1,-83.06,42795702],
		["Hartsfield","GA",31.21,-83.97,42795824],
		["Coahoma","MS",34.36,-90.52,42795946],
		["Tillatoba","MS",33.98,-89.89,42796068],
		["Bimble","KY",36.87,-83.82,42796190],
		["Webbville","KY",38.15,-82.79,42796312],
		["Debord","KY",37.82,-82.55,42796434],
		["Hi Hat","KY",37.39,-82.72,42796556],
		["Happy","KY",37.2,-83.09,42796678],
		["Pine Top","KY",37.27,-82.88,42796800],
		["Bristow","IN",38.13,-86.71,42796922],
		["Cedarville","MI",45.99,-84.36,42797044],
		["Salem","IA",40.85,-91.62,42797166],
		["Nelson","WI",44.42,-92,42797288],
		["Saint Clair","MN",44.08,-93.85,42797410],
		["Reynolds","ND",47.67,-97.1,42797532],
		["Max","ND",47.81,-101.29,42797654],
		["Williamsfield","IL",40.92,-90.01,42797776],
		["Alvin","IL",40.3,-87.6,42797898],
		["Enfield","IL",38.1,-88.33,42798020],
		["Burlington Junction","MO",40.45,-95.06,42798142],
		["Hopkins","MO",40.55,-94.81,42798264],
		["Beaver Crossing","NE",40.77,-97.28,42798386],
		["Fordyce","NE",42.69,-97.36,42798508],
		["Hubbard","NE",42.38,-96.59,42798630],
		["Mc Neil","AR",33.34,-93.2,42798752],
		["Oxford","AR",36.2,-91.91,42798874],
		["Indianola","OK",35.16,-95.77,42798996],
		["Haileyville","OK",34.85,-95.57,42799118],
		["Sutherland Springs","TX",29.26,-98.06,42799240],
		["Colorado Springs","CO",38.86,-104.76,42799362],
		["De Beque","CO",39.33,-108.21,42799484],
		["Lingle","WY",42.13,-104.34,42799606],
		["Central","AZ",32.86,-109.78,42799728],
		["Kirkland","AZ",34.29,-112.66,42799850],
		["Duarte","CA",34.14,-117.96,42799972],
		["Rockford","WA",47.48,-117.14,42800094],
		["Point Hope","AK",68.34,-166.67,42800216],
		["Gouldsboro","ME",44.47,-68.03,42800337],
		["Leesburg","NJ",39.23,-75,42800458],
		["Russellton","PA",40.61,-79.83,42800579],
		["Daisytown","PA",40.32,-78.9,42800700],
		["Wiconisco","PA",40.57,-76.72,42800821],
		["Paeonian Springs","VA",39.16,-77.61,42800942],
		["Lovingston","VA",37.77,-78.88,42801063],
		["Newsoms","VA",36.62,-77.12,42801184],
		["Green Bay","VA",37.13,-78.3,42801305],
		["Lashmeet","WV",37.44,-81.21,42801426],
		["Kimberly","WV",38.12,-81.32,42801547],
		["The Villages","FL",28.92,-81.92,42801668],
		["Vinegar Bend","AL",31.26,-88.34,42801789],
		["Glen Allan","MS",33.03,-91.01,42801910],
		["Finchville","KY",38.15,-85.31,42802031],
		["Blaine","KY",38.02,-82.85,42802152],
		["Kempton","IN",40.28,-86.23,42802273],
		["Glenwood","IN",39.62,-85.3,42802394],
		["New Market","IN",39.95,-86.92,42802515],
		["Chase","MI",43.88,-85.63,42802636],
		["Lorimor","IA",41.12,-94.05,42802757],
		["Fremont","IA",41.21,-92.43,42802878],
		["Wilson","WI",44.95,-92.17,42802999],
		["Lake Tomahawk","WI",45.81,-89.59,42803120],
		["Lynd","MN",44.38,-95.89,42803241],
		["Salol","MN",48.86,-95.57,42803362],
		["Hurley","SD",43.27,-97.09,42803483],
		["Pryor","MT",45.34,-108.43,42803604],
		["Kirkwood","IL",40.86,-90.74,42803725],
		["Irving","IL",39.2,-89.4,42803846],
		["Shumway","IL",39.18,-88.65,42803967],
		["Willow Hill","IL",38.99,-88.02,42804088],
		["Belgrade","MO",37.78,-90.84,42804209],
		["Faucett","MO",39.6,-94.81,42804330],
		["Hartford","KS",38.3,-95.95,42804451],
		["Clearwater","NE",42.17,-98.18,42804572],
		["Oscar","LA",30.58,-91.47,42804693],
		["Delaware","AR",35.23,-93.44,42804814],
		["Council Hill","OK",35.55,-95.65,42804935],
		["Abiquiu","NM",36.2,-106.31,42805056],
		["Beatty","NV",37.09,-116.79,42805177],
		["Rio Oso","CA",38.95,-121.49,42805298],
		["Dorena","OR",43.7,-122.89,42805419],
		["Canyon City","OR",44.22,-119.13,42805540],
		["Nelson","NH",42.99,-72.12,42805660],
		["Sumner","ME",44.39,-70.43,42805780],
		["Detroit","ME",44.79,-69.29,42805900],
		["Londonderry","VT",43.22,-72.8,42806020],
		["Madera","PA",40.82,-78.44,42806140],
		["Bremo Bluff","VA",37.71,-78.29,42806260],
		["Lizemores","WV",38.33,-81.16,42806380],
		["Jumping Branch","WV",37.65,-80.98,42806500],
		["Startex","SC",34.93,-82.09,42806620],
		["Yalaha","FL",28.74,-81.82,42806740],
		["Lynn","AL",34.04,-87.54,42806860],
		["Skipperville","AL",31.55,-85.54,42806980],
		["Crab Orchard","TN",35.9,-84.88,42807100],
		["Williston","TN",35.15,-89.37,42807220],
		["Georgetown","MS",31.86,-90.16,42807340],
		["Bradfordsville","KY",37.49,-85.14,42807460],
		["Wayland","KY",37.44,-82.8,42807580],
		["Chalmers","IN",40.66,-86.86,42807700],
		["Battle Creek","IA",42.31,-95.59,42807820],
		["New Albin","IA",43.49,-91.28,42807940],
		["Garrison","IA",42.14,-92.14,42808060],
		["South English","IA",41.45,-92.09,42808180],
		["Comfrey","MN",44.11,-94.9,42808300],
		["Manvel","ND",48.07,-97.17,42808420],
		["Little York","IL",41.01,-90.74,42808540],
		["Green City","MO",40.26,-92.95,42808660],
		["Linneus","MO",39.87,-93.18,42808780],
		["Spickard","MO",40.24,-93.59,42808900],
		["Walker","MO",37.89,-94.23,42809020],
		["Malta Bend","MO",39.19,-93.36,42809140],
		["Raymondville","MO",37.33,-91.83,42809260],
		["Preston","MO",37.94,-93.21,42809380],
		["South Haven","KS",37.04,-97.4,42809500],
		["Macksville","KS",37.95,-98.96,42809620],
		["Platte Center","NE",41.53,-97.48,42809740],
		["Hoskins","NE",42.11,-97.3,42809860],
		["Lillie","LA",32.92,-92.66,42809980],
		["Arnett","OK",36.13,-99.77,42810100],
		["Tuskahoma","OK",34.62,-95.27,42810220],
		["Sawyer","OK",34.02,-95.37,42810340],
		["Winfield","TX",33.16,-95.1,42810460],
		["Annona","TX",33.58,-94.91,42810580],
		["Big Wells","TX",28.56,-99.57,42810700],
		["Circleville","UT",38.18,-112.22,42810820],
		["Kaumakani","HI",21.92,-159.62,42810940],
		["Greenbank","WA",48.09,-122.57,42811060],
		["Usk","WA",48.3,-117.36,42811180],
		["New Salem","MA",42.5,-72.33,42811299],
		["Center Harbor","NH",43.7,-71.22,42811418],
		["Glen","NH",44.1,-71.17,42811537],
		["Lowell","VT",44.8,-72.45,42811656],
		["Richland","NJ",39.48,-74.87,42811775],
		["Berlin","NY",42.7,-73.38,42811894],
		["Sloansville","NY",42.76,-74.37,42812013],
		["Rock Stream","NY",42.47,-76.92,42812132],
		["Rochester Mills","PA",40.82,-79,42812251],
		["Blue Ridge Summit","PA",39.73,-77.46,42812370],
		["Equinunk","PA",41.85,-75.22,42812489],
		["Milan","PA",41.9,-76.52,42812608],
		["Quantico","MD",38.32,-75.79,42812727],
		["Birchleaf","VA",37.15,-82.23,42812846],
		["Bandy","VA",37.14,-81.7,42812965],
		["Chloe","WV",38.67,-81.09,42813084],
		["Yawkey","WV",38.2,-81.95,42813203],
		["Belvidere","NC",36.26,-76.53,42813322],
		["Blenheim","SC",34.51,-79.65,42813441],
		["Millwood","GA",31.26,-82.66,42813560],
		["Belleair Beach","FL",27.91,-82.84,42813679],
		["Emelle","AL",32.72,-88.31,42813798],
		["Gunnison","MS",33.94,-90.94,42813917],
		["Livingston","KY",37.29,-84.21,42814036],
		["Royalton","KY",37.67,-83.02,42814155],
		["Center","KY",37.13,-85.68,42814274],
		["Haviland","OH",41.01,-84.58,42814393],
		["Dakota City","IA",42.72,-94.19,42814512],
		["Sutherland","IA",42.97,-95.49,42814631],
		["Calamus","IA",41.82,-90.75,42814750],
		["Egg Harbor","WI",45.04,-87.29,42814869],
		["Brownsville","MN",43.69,-91.28,42814988],
		["Lyle","MN",43.5,-92.94,42815107],
		["Roberts","MT",45.34,-109.16,42815226],
		["Melvin","IL",40.57,-88.24,42815345],
		["Energy","IL",37.77,-89.02,42815464],
		["Altenburg","MO",37.63,-89.58,42815583],
		["Kansas City","MO",39.09,-94.58,42815702],
		["Rantoul","KS",38.54,-95.1,42815821],
		["Hope","KS",38.69,-97.07,42815940],
		["Conway","AR",35.08,-92.45,42816059],
		["Moore","TX",29.05,-99.01,42816178],
		["Banquete","TX",27.85,-97.79,42816297],
		["Bedford","WY",42.9,-110.93,42816416],
		["Bolinas","CA",37.94,-122.73,42816535],
		["Wilkeson","WA",47.1,-122.03,42816654],
		["Stebbins","AK",63.47,-162.28,42816773],
		["Noatak","AK",67.57,-163,42816892],
		["North Stratford","NH",44.76,-71.58,42817010],
		["Friendship","ME",43.98,-69.33,42817128],
		["Washington Depot","CT",41.65,-73.32,42817246],
		["Chatsworth","NJ",39.75,-74.46,42817364],
		["Lottsburg","VA",37.96,-76.51,42817482],
		["Hillsboro","WV",38.13,-80.21,42817600],
		["Toomsboro","GA",32.82,-83.08,42817718],
		["Townley","AL",33.83,-87.44,42817836],
		["Payneville","KY",37.98,-86.31,42817954],
		["Wharton","OH",40.86,-83.46,42818072],
		["Taswell","IN",38.33,-86.56,42818190],
		["Cynthiana","IN",38.18,-87.7,42818308],
		["Maple Rapids","MI",43.1,-84.69,42818426],
		["Cornell","MI",45.9,-87.23,42818544],
		["Bayard","IA",41.85,-94.55,42818662],
		["Goldfield","IA",42.73,-93.92,42818780],
		["Bronson","IA",42.4,-96.21,42818898],
		["Saxon","WI",46.49,-90.41,42819016],
		["Downing","WI",45.04,-92.12,42819134],
		["Exeland","WI",45.66,-91.24,42819252],
		["Ivanhoe","MN",44.46,-96.25,42819370],
		["Emily","MN",46.75,-93.96,42819488],
		["Crosby","ND",48.91,-103.29,42819606],
		["Steward","IL",41.84,-89.02,42819724],
		["Strasburg","IL",39.35,-88.62,42819842],
		["Lancaster","KS",39.57,-95.3,42819960],
		["Bancroft","NE",42.01,-96.57,42820078],
		["Mullen","NE",42.04,-101.04,42820196],
		["Fluker","LA",30.82,-90.53,42820314],
		["Pelican","LA",31.89,-93.55,42820432],
		["Chattanooga","OK",34.42,-98.65,42820550],
		["Ledbetter","TX",30.23,-96.76,42820668],
		["Bruneau","ID",42.57,-115.85,42820786],
		["Nageezi","NM",36.26,-107.74,42820904],
		["Catheys Valley","CA",37.38,-120.12,42821022],
		["Douglas City","CA",40.58,-122.86,42821140],
		["Prospect","OR",42.82,-122.51,42821258],
		["Toutle","WA",46.31,-122.69,42821376],
		["Unalakleet","AK",64.09,-160.36,42821494],
		["Kents Hill","ME",44.43,-70.07,42821611],
		["Mount Desert","ME",44.31,-68.36,42821728],
		["Rochester","VT",43.88,-72.82,42821845],
		["Diamond Point","NY",43.51,-73.69,42821962],
		["Hampton","NY",43.46,-73.27,42822079],
		["Clark Mills","NY",43.08,-75.37,42822196],
		["Fountainville","PA",40.33,-75.15,42822313],
		["Rileyville","VA",38.76,-78.38,42822430],
		["Richardsville","VA",38.4,-77.72,42822547],
		["Deltaville","VA",37.55,-76.32,42822664],
		["Cloverdale","VA",37.35,-79.9,42822781],
		["Blackwater","VA",36.62,-83.05,42822898],
		["Big Rock","VA",37.31,-82.22,42823015],
		["Gap Mills","WV",37.56,-80.41,42823132],
		["Junior","WV",38.97,-79.95,42823249],
		["Wana","WV",39.7,-80.31,42823366],
		["Suches","GA",34.73,-84.06,42823483],
		["Fackler","AL",34.83,-85.98,42823600],
		["Southside","TN",36.36,-87.3,42823717],
		["Five Points","TN",35.03,-87.28,42823834],
		["Silver Grove","KY",39.03,-84.39,42823951],
		["Oil Springs","KY",37.8,-82.94,42824068],
		["Gracey","KY",36.85,-87.65,42824185],
		["San Pierre","IN",41.21,-86.86,42824302],
		["Glennie","MI",44.56,-83.71,42824419],
		["Lynnville","IA",41.57,-92.78,42824536],
		["Melcher-dallas","IA",41.22,-93.16,42824653],
		["Nichols","IA",41.47,-91.3,42824770],
		["Clinton","MN",45.46,-96.44,42824887],
		["Westport","SD",45.64,-98.49,42825004],
		["Wounded Knee","SD",43.14,-102.36,42825121],
		["Frazer","MT",48.28,-106.06,42825238],
		["Fithian","IL",40.11,-87.87,42825355],
		["Seymour","IL",40.1,-88.41,42825472],
		["Geff","IL",38.44,-88.42,42825589],
		["Grandin","MO",36.82,-90.82,42825706],
		["Mayview","MO",39.05,-93.83,42825823],
		["Fortuna","MO",38.57,-92.77,42825940],
		["Clifton","KS",39.56,-97.27,42826057],
		["Wilson","KS",38.82,-98.47,42826174],
		["Lincoln","NE",40.81,-96.68,42826291],
		["Cotton Plant","AR",35,-91.25,42826408],
		["Poyen","AR",34.32,-92.64,42826525],
		["Randlett","OK",34.17,-98.46,42826642],
		["Laneville","TX",31.94,-94.85,42826759],
		["Aquilla","TX",31.85,-97.22,42826876],
		["Ovalo","TX",32.17,-99.8,42826993],
		["Veyo","UT",37.42,-113.72,42827110],
		["Dammeron Valley","UT",37.26,-113.69,42827227],
		["Hayden","AZ",33,-110.78,42827344],
		["El Rito","NM",36.34,-106.18,42827461],
		["Westley","CA",37.54,-121.29,42827578],
		["Doyle","CA",39.94,-120.06,42827695],
		["Richmond","MA",42.37,-73.36,42827811],
		["North Walpole","NH",43.14,-72.44,42827927],
		["Liberty","ME",44.38,-69.3,42828043],
		["Montgomery Center","VT",44.86,-72.59,42828159],
		["Purdys","NY",41.31,-73.65,42828275],
		["Cochecton","NY",41.7,-75.06,42828391],
		["Mainesburg","PA",41.78,-76.96,42828507],
		["Sciota","PA",40.92,-75.28,42828623],
		["Lakeville","PA",41.43,-75.27,42828739],
		["Big Pool","MD",39.66,-78,42828855],
		["Dugspur","VA",36.81,-80.61,42828971],
		["Gay","WV",38.8,-81.57,42829087],
		["Midway","WV",37.69,-81.25,42829203],
		["Rockport","WV",39.07,-81.57,42829319],
		["Hepzibah","WV",39.31,-80.36,42829435],
		["Dillard","GA",34.97,-83.38,42829551],
		["Greenwood Springs","MS",33.98,-88.29,42829667],
		["Cruger","MS",33.32,-90.23,42829783],
		["Garfield","KY",37.78,-86.35,42829899],
		["Lowmansville","KY",37.91,-82.71,42830015],
		["Mc Carr","KY",37.59,-82.16,42830131],
		["Taylorsville","IN",39.29,-85.94,42830247],
		["Hubbell","MI",47.17,-88.43,42830363],
		["Bricelyn","MN",43.56,-93.81,42830479],
		["Naytahwaush","MN",47.28,-95.63,42830595],
		["Williams","MN",48.76,-94.95,42830711],
		["Oklee","MN",47.83,-95.85,42830827],
		["Willow Lake","SD",44.62,-97.63,42830943],
		["Warner","SD",45.32,-98.49,42831059],
		["Hebron","ND",46.9,-102.04,42831175],
		["Wapella","IL",40.22,-88.96,42831291],
		["Sidell","IL",39.91,-87.82,42831407],
		["Bertrand","MO",36.9,-89.44,42831523],
		["Melvern","KS",38.5,-95.63,42831639],
		["Murray","NE",40.91,-95.92,42831755],
		["Wilson","LA",30.91,-91.11,42831871],
		["Athens","LA",32.65,-93.02,42831987],
		["Foster","OK",34.62,-97.48,42832103],
		["Gordon","TX",32.54,-98.36,42832219],
		["Austin","TX",30.26,-97.74,42832335],
		["Cambridge","ID",44.62,-116.7,42832451],
		["Bluewater","NM",35.14,-108.23,42832567],
		["New Laguna","NM",35.04,-107.42,42832683],
		["Clements","CA",38.21,-121.03,42832799],
		["Courtland","CA",38.31,-121.54,42832915],
		["Shedd","OR",44.45,-123.1,42833031],
		["Grayland","WA",46.79,-124.08,42833147],
		["Wellpinit","WA",47.89,-117.99,42833263],
		["Coulee City","WA",47.66,-119.43,42833379],
		["Nunapitchuk","AK",60.88,-162.32,42833495],
		["Constableville","NY",43.56,-75.42,42833610],
		["Rockton","PA",41.08,-78.66,42833725],
		["Six Mile Run","PA",40.15,-78.2,42833840],
		["Spencerville","MD",39.12,-76.96,42833955],
		["Skipwith","VA",36.69,-78.49,42834070],
		["Lake","WV",37.94,-81.93,42834185],
		["Quinwood","WV",38.05,-80.7,42834300],
		["Pinehurst","GA",32.19,-83.76,42834415],
		["Eden","GA",32.17,-81.39,42834530],
		["Gainesville","AL",32.81,-88.15,42834645],
		["Westpoint","TN",35.15,-87.53,42834760],
		["Reed","KY",37.87,-87.37,42834875],
		["Stewart","OH",39.32,-81.89,42834990],
		["Sweetser","IN",40.56,-85.76,42835105],
		["Zearing","IA",42.15,-93.29,42835220],
		["Gratiot","WI",42.57,-90.02,42835335],
		["Readstown","WI",43.44,-90.75,42835450],
		["Pengilly","MN",47.33,-93.19,42835565],
		["Sanborn","MN",44.21,-95.12,42835680],
		["Longville","MN",46.98,-94.21,42835795],
		["Emery","SD",43.6,-97.62,42835910],
		["Tripp","SD",43.22,-97.96,42836025],
		["Sublette","IL",41.64,-89.23,42836140],
		["Claremont","IL",38.71,-87.97,42836255],
		["Bunceton","MO",38.78,-92.79,42836370],
		["Houstonia","MO",38.89,-93.35,42836485],
		["Eagle Rock","MO",36.54,-93.75,42836600],
		["Theodosia","MO",36.57,-92.66,42836715],
		["Brookville","KS",38.77,-97.86,42836830],
		["Bassett","NE",42.58,-99.53,42836945],
		["Balmorhea","TX",30.98,-103.74,42837060],
		["Rankin","TX",31.22,-101.93,42837175],
		["Beulah","CO",38.08,-104.98,42837290],
		["Cocolalla","ID",48.1,-116.67,42837405],
		["Eureka","UT",39.95,-112.17,42837520],
		["Pleasant Grove","CA",38.83,-121.5,42837635],
		["Kualapuu","HI",21.16,-157.05,42837750],
		["Tekoa","WA",47.24,-117.11,42837865],
		["Kasigluk","AK",60.87,-162.54,42837980],
		["Glendale","RI",41.97,-71.69,42838094],
		["Springfield","ME",45.39,-68.13,42838208],
		["Eastport","ME",44.91,-67.01,42838322],
		["Riverton","CT",41.95,-73.02,42838436],
		["Cookstown","NJ",40.04,-74.55,42838550],
		["Amagansett","NY",40.98,-72.18,42838664],
		["South Otselic","NY",42.65,-75.78,42838778],
		["East Meredith","NY",42.41,-74.88,42838892],
		["Maine","NY",42.19,-76.06,42839006],
		["Dewittville","NY",42.24,-79.4,42839120],
		["Robertsdale","PA",40.17,-78.09,42839234],
		["Muncy Valley","PA",41.35,-76.6,42839348],
		["Pleasant Mount","PA",41.73,-75.43,42839462],
		["Waverly","PA",41.52,-75.7,42839576],
		["Falls Mills","VA",37.26,-81.33,42839690],
		["Clear Fork","WV",37.64,-81.69,42839804],
		["Davy","WV",37.47,-81.65,42839918],
		["Tiplersville","MS",34.9,-88.92,42840032],
		["Printer","KY",37.53,-82.74,42840146],
		["New Concord","KY",36.59,-88.06,42840260],
		["Switz City","IN",39.03,-87.05,42840374],
		["Fulton","MI",42.11,-85.35,42840488],
		["Carney","MI",45.58,-87.55,42840602],
		["Latimer","IA",42.76,-93.36,42840716],
		["Dumont","IA",42.75,-92.97,42840830],
		["Ashton","IA",43.3,-95.79,42840944],
		["Lost Nation","IA",41.96,-90.81,42841058],
		["Franklin","MN",44.53,-94.88,42841172],
		["Hewitt","MN",46.32,-95.08,42841286],
		["Middle River","MN",48.43,-96.16,42841400],
		["Pukwana","SD",43.77,-99.18,42841514],
		["Donovan","IL",40.88,-87.61,42841628],
		["Vermont","IL",40.29,-90.42,42841742],
		["Fillmore","IL",39.11,-89.27,42841856],
		["Versailles","IL",39.88,-90.65,42841970],
		["Buffalo","IL",39.84,-89.4,42842084],
		["Bethel","MO",39.87,-92.02,42842198],
		["Bragg City","MO",36.26,-89.91,42842312],
		["Rosendale","MO",40.04,-94.82,42842426],
		["Calhoun","MO",38.46,-93.62,42842540],
		["Mankato","KS",39.78,-98.2,42842654],
		["Monroe","LA",32.51,-92.08,42842768],
		["Caddo Gap","AR",34.41,-93.64,42842882],
		["Turrell","AR",35.37,-90.25,42842996],
		["Amber","OK",35.16,-97.88,42843110],
		["Arthur City","TX",33.84,-95.46,42843224],
		["Byers","TX",34.07,-98.19,42843338],
		["Dime Box","TX",30.35,-96.81,42843452],
		["Hermleigh","TX",32.64,-100.76,42843566],
		["Harrison","ID",47.54,-116.7,42843680],
		["Randolph","UT",41.65,-111.21,42843794],
		["Cochise","AZ",32.06,-109.91,42843908],
		["Vadito","NM",36.19,-105.67,42844022],
		["Llano","CA",34.49,-117.77,42844136],
		["Pe Ell","WA",46.57,-123.29,42844250],
		["Shishmaref","AK",66.02,-165.91,42844364],
		["Provincetown","MA",42.06,-70.2,42844477],
		["New York","NY",40.71,-73.99,42844590],
		["Caroga Lake","NY",43.13,-74.48,42844703],
		["Central Bridge","NY",42.7,-74.33,42844816],
		["Bloomville","NY",42.33,-74.8,42844929],
		["Kill Buck","NY",42.14,-78.61,42845042],
		["Creighton","PA",40.58,-79.78,42845155],
		["Sigel","PA",41.28,-79.12,42845268],
		["East Prospect","PA",39.97,-76.52,42845381],
		["Suffolk","VA",36.7,-76.63,42845494],
		["Birch River","WV",38.49,-80.75,42845607],
		["Old Fields","WV",39.14,-78.95,42845720],
		["Prospect Hill","NC",36.25,-79.2,42845833],
		["Pelham","TN",35.32,-85.78,42845946],
		["Cornettsville","KY",37.13,-83.07,42846059],
		["Vanlue","OH",40.97,-83.48,42846172],
		["Keystone","IN",40.58,-85.27,42846285],
		["Idaville","IN",40.78,-86.66,42846398],
		["Mackinaw City","MI",45.77,-84.74,42846511],
		["Gilbertville","IA",42.41,-92.21,42846624],
		["Fontanelle","IA",41.29,-94.56,42846737],
		["Saint Nazianz","WI",44,-87.92,42846850],
		["Vergas","MN",46.65,-95.8,42846963],
		["Stephen","MN",48.45,-96.87,42847076],
		["Bridgewater","SD",43.55,-97.49,42847189],
		["Colome","SD",43.26,-99.71,42847302],
		["Ray","ND",48.34,-103.16,42847415],
		["Danforth","IL",40.82,-87.97,42847528],
		["Equality","IL",37.73,-88.34,42847641],
		["Frohna","MO",37.63,-89.62,42847754],
		["De Kalb","MO",39.58,-94.92,42847867],
		["Hume","MO",38.08,-94.58,42847980],
		["Saint Elizabeth","MO",38.25,-92.26,42848093],
		["Viburnum","MO",37.71,-91.12,42848206],
		["Potwin","KS",37.93,-97.01,42848319],
		["Logan","KS",39.66,-99.56,42848432],
		["Saint Libory","NE",41.09,-98.36,42848545],
		["Cotter","AR",36.28,-92.52,42848658],
		["Lane","OK",34.3,-95.98,42848771],
		["Dustin","OK",35.27,-96.03,42848884],
		["Rainbow","TX",32.28,-97.7,42848997],
		["Point Comfort","TX",28.67,-96.55,42849110],
		["Driscoll","TX",27.67,-97.75,42849223],
		["Farson","WY",42.11,-109.44,42849336],
		["Fielding","UT",41.82,-112.1,42849449],
		["Jarales","NM",34.61,-106.76,42849562],
		["Depoe Bay","OR",44.81,-124.05,42849675],
		["South Beach","OR",44.56,-124.03,42849788],
		["Wainwright","AK",70.63,-159.96,42849901],
		["Stamford","VT",42.77,-72.94,42850013],
		["New York","NY",40.71,-73.99,42850125],
		["Hunter","NY",42.21,-74.21,42850237],
		["Naoma","WV",37.89,-81.52,42850349],
		["Millwood","WV",38.9,-81.81,42850461],
		["Baisden","WV",37.58,-81.94,42850573],
		["Wharncliffe","WV",37.55,-81.97,42850685],
		["Friendly","WV",39.51,-81.06,42850797],
		["Byromville","GA",32.2,-83.9,42850909],
		["West Green","GA",31.6,-82.73,42851021],
		["Stewart","MS",33.53,-89.49,42851133],
		["Yorkville","OH",40.15,-80.7,42851245],
		["Ruth","MI",43.74,-82.75,42851357],
		["Jasper","MI",41.74,-83.94,42851469],
		["Allerton","IA",40.7,-93.36,42851581],
		["Montour","IA",41.98,-92.71,42851693],
		["Stacyville","IA",43.43,-92.78,42851805],
		["Titonka","IA",43.23,-94.04,42851917],
		["Goose Lake","IA",41.96,-90.38,42852029],
		["Linden","WI",42.91,-90.27,42852141],
		["Hendricks","MN",44.5,-96.42,42852253],
		["Hoffman","MN",45.83,-95.78,42852365],
		["Utica","SD",42.98,-97.49,42852477],
		["Joy","IL",41.19,-90.87,42852589],
		["Hammond","IL",39.79,-88.59,42852701],
		["New Cambria","MO",39.77,-92.75,42852813],
		["Gilman City","MO",40.13,-93.87,42852925],
		["Loose Creek","MO",38.5,-91.95,42853037],
		["Burns","KS",38.08,-96.88,42853149],
		["Cedar Vale","KS",37.1,-96.5,42853261],
		["Stuart","NE",42.6,-99.14,42853373],
		["Kearney","NE",40.7,-99.08,42853485],
		["Atlanta","LA",31.8,-92.73,42853597],
		["Powhatan","AR",36.08,-91.11,42853709],
		["Sterling","OK",34.74,-98.17,42853821],
		["Village Mills","TX",30.37,-94.35,42853933],
		["Laredo","TX",27.53,-99.48,42854045],
		["Lincoln","TX",30.31,-96.97,42854157],
		["Round Top","TX",30.06,-96.69,42854269],
		["Juliaetta","ID",46.55,-116.74,42854381],
		["Clay Springs","AZ",34.36,-110.29,42854493],
		["Pinehill","NM",34.81,-108.45,42854605],
		["Forest Knolls","CA",38.01,-122.69,42854717],
		["Graton","CA",38.43,-122.86,42854829],
		["Forest Ranch","CA",39.97,-121.64,42854941],
		["New Stuyahok","AK",59.47,-157.27,42855053],
		["Bath","NH",44.16,-71.96,42855164],
		["Harrington","ME",44.61,-67.81,42855275],
		["Annapolis","MD",38.97,-76.5,42855386],
		["Claudville","VA",36.59,-80.42,42855497],
		["Baker","WV",39.04,-78.74,42855608],
		["Frisco","NC",35.24,-75.58,42855719],
		["Powells Point","NC",36.15,-75.85,42855830],
		["Jacksonville","GA",31.81,-82.97,42855941],
		["Isonville","KY",38.06,-83.05,42856052],
		["Mouthcard","KY",37.38,-82.25,42856163],
		["College Corner","OH",39.56,-84.81,42856274],
		["Maplewood","OH",40.35,-84.06,42856385],
		["Gentryville","IN",38.1,-87.03,42856496],
		["Alden","MI",44.88,-85.26,42856607],
		["Lamont","IA",42.59,-91.64,42856718],
		["Elk Horn","IA",41.59,-95.06,42856829],
		["Bonaparte","IA",40.7,-91.8,42856940],
		["Rock Springs","WI",43.47,-89.91,42857051],
		["Kettle River","MN",46.48,-92.87,42857162],
		["Brownsdale","MN",43.74,-92.86,42857273],
		["Wykoff","MN",43.7,-92.26,42857384],
		["Wood Lake","MN",44.65,-95.53,42857495],
		["Wyndmere","ND",46.26,-97.13,42857606],
		["Richardton","ND",46.88,-102.31,42857717],
		["Savage","MT",47.5,-104.47,42857828],
		["Saint Louis","MO",38.63,-90.24,42857939],
		["Wakarusa","KS",38.89,-95.71,42858050],
		["Cunningham","KS",37.64,-98.43,42858161],
		["Lincoln","NE",40.81,-96.68,42858272],
		["Coal Hill","AR",35.43,-93.66,42858383],
		["Tulsa","OK",36.12,-95.91,42858494],
		["Richland","TX",31.92,-96.42,42858605],
		["Flagler","CO",39.29,-103.06,42858716],
		["Applegate","CA",39,-120.99,42858827],
		["Stonyford","CA",39.27,-122.46,42858938],
		["Rickreall","OR",44.99,-123.2,42859049],
		["Lynn","MA",42.47,-70.96,42859159],
		["Whitingham","VT",42.78,-72.87,42859269],
		["Cuttingsville","VT",43.48,-72.88,42859379],
		["Canaan","VT",45,-71.53,42859489],
		["Coeymans Hollow","NY",42.48,-73.92,42859599],
		["Indian Lake","NY",43.78,-74.26,42859709],
		["Middleville","NY",43.13,-74.97,42859819],
		["Hector","NY",42.5,-76.87,42859929],
		["Penns Creek","PA",40.86,-77.07,42860039],
		["Kresgeville","PA",40.89,-75.5,42860149],
		["Drewryville","VA",36.71,-77.3,42860259],
		["Purgitsville","WV",39.23,-78.92,42860369],
		["Aydlett","NC",36.32,-75.9,42860479],
		["Ideal","GA",32.37,-84.18,42860589],
		["Denton","GA",31.72,-82.69,42860699],
		["Sumterville","FL",28.73,-82.01,42860809],
		["Scobey","MS",33.93,-89.88,42860919],
		["French Camp","MS",33.29,-89.39,42861029],
		["Muldraugh","KY",37.93,-85.99,42861139],
		["Ivel","KY",37.59,-82.66,42861249],
		["Mallie","KY",37.3,-82.91,42861359],
		["Olmstead","KY",36.75,-87.01,42861469],
		["Mortons Gap","KY",37.23,-87.47,42861579],
		["Tuscarawas","OH",40.39,-81.4,42861689],
		["Ottoville","OH",40.93,-84.33,42861799],
		["Forest","IN",40.37,-86.31,42861909],
		["Manilla","IN",39.57,-85.63,42862019],
		["Lucerne","IN",40.86,-86.41,42862129],
		["Velpen","IN",38.37,-87.1,42862239],
		["Leonidas","MI",42.03,-85.35,42862349],
		["Herron","MI",45,-83.66,42862459],
		["Danbury","IA",42.23,-95.72,42862569],
		["Everly","IA",43.16,-95.32,42862679],
		["Royal","IA",43.06,-95.28,42862789],
		["Persia","IA",41.57,-95.57,42862899],
		["Farwell","MN",45.75,-95.61,42863009],
		["Portland","ND",47.49,-97.36,42863119],
		["Saint Regis","MT",47.31,-115.14,42863229],
		["Chicago","IL",41.83,-87.68,42863339],
		["Tilden","IL",38.21,-89.68,42863449],
		["French Village","MO",37.97,-90.38,42863559],
		["Stotts City","MO",37.1,-93.94,42863669],
		["Reading","KS",38.51,-95.95,42863779],
		["Dry Creek","LA",30.66,-93.04,42863889],
		["Locust Grove","AR",35.71,-91.75,42863999],
		["Aguilar","CO",37.4,-104.65,42864109],
		["Mack","CO",39.22,-108.87,42864219],
		["Fort Bridger","WY",41.32,-110.38,42864329],
		["Reserve","NM",33.7,-108.76,42864439],
		["San Francisco","CA",37.76,-122.39,42864549],
		["Macdoel","CA",41.74,-121.89,42864659],
		["North Bonneville","WA",45.67,-121.96,42864769],
		["Mica","WA",47.54,-117.15,42864879],
		["Ancram","NY",42.05,-73.63,42864988],
		["Glenwood","NY",42.6,-78.63,42865097],
		["Duke Center","PA",41.96,-78.5,42865206],
		["Osceola","PA",41.98,-77.38,42865315],
		["Jewell Ridge","VA",37.18,-81.8,42865424],
		["Ivydale","WV",38.52,-81.02,42865533],
		["West Columbia","WV",38.93,-82.07,42865642],
		["Davin","WV",37.71,-81.78,42865751],
		["Goldsboro","NC",35.37,-77.97,42865860],
		["Margarettsville","NC",36.53,-77.35,42865969],
		["Birmingham","AL",33.52,-86.79,42866078],
		["Beersheba Springs","TN",35.45,-85.67,42866187],
		["Shady Valley","TN",36.51,-81.92,42866296],
		["Newcomb","TN",36.54,-84.2,42866405],
		["Buckner","KY",38.38,-85.43,42866514],
		["Cedar Grove","IN",39.35,-84.93,42866623],
		["Leopold","IN",38.1,-86.58,42866732],
		["Plainville","IN",38.8,-87.15,42866841],
		["Minden City","MI",43.67,-82.77,42866950],
		["Sand Creek","MI",41.76,-84.07,42867059],
		["Kelley","IA",41.95,-93.66,42867168],
		["Zwingle","IA",42.29,-90.68,42867277],
		["Elcho","WI",45.43,-89.18,42867386],
		["Menoken","ND",46.81,-100.51,42867495],
		["Plainville","IL",39.78,-91.18,42867604],
		["Decatur","IL",39.85,-88.93,42867713],
		["Texico","IL",38.43,-88.86,42867822],
		["Cave In Rock","IL",37.53,-88.23,42867931],
		["Matthews","MO",36.75,-89.58,42868040],
		["Fontana","KS",38.42,-94.83,42868149],
		["Howard","KS",37.46,-96.26,42868258],
		["De Witt","NE",40.39,-96.92,42868367],
		["Pleasant Dale","NE",40.79,-96.93,42868476],
		["Cloutierville","LA",31.54,-92.83,42868585],
		["Mulhall","OK",36.06,-97.4,42868694],
		["Longdale","OK",36.13,-98.55,42868803],
		["Wallisville","TX",29.86,-94.69,42868912],
		["Ransom Canyon","TX",33.52,-101.68,42869021],
		["Annabella","UT",38.71,-112.06,42869130],
		["Hanover","NM",32.88,-108.08,42869239],
		["Schurz","NV",38.92,-118.71,42869348],
		["Blairsden-graeagle","CA",39.78,-120.65,42869457],
		["Summerville","OR",45.52,-118.02,42869566],
		["West Cornwall","CT",41.87,-73.33,42869674],
		["Tannersville","NY",42.19,-74.13,42869782],
		["Lawrence","PA",40.31,-80.14,42869890],
		["Bobtown","PA",39.75,-79.98,42869998],
		["Wind Ridge","PA",39.88,-80.46,42870106],
		["Spring Church","PA",40.62,-79.44,42870214],
		["Kitzmiller","MD",39.38,-79.18,42870322],
		["Saint Charles","VA",36.8,-83.05,42870430],
		["Barren Springs","VA",36.91,-80.8,42870538],
		["Eskdale","WV",38.11,-81.47,42870646],
		["Peytona","WV",38.12,-81.7,42870754],
		["Longwood","NC",34,-78.54,42870862],
		["Crossnore","NC",36.02,-81.93,42870970],
		["Plum Branch","SC",33.84,-82.25,42871078],
		["Andersonville","GA",32.19,-84.14,42871186],
		["Equality","AL",32.8,-86.11,42871294],
		["Nashville","TN",36.17,-86.78,42871402],
		["Taylor","MS",34.27,-89.58,42871510],
		["Pine Ridge","KY",37.76,-83.61,42871618],
		["Smithfield","OH",40.27,-80.77,42871726],
		["Gratis","OH",39.64,-84.52,42871834],
		["Economy","IN",39.97,-85.08,42871942],
		["Badger","IA",42.61,-94.14,42872050],
		["Arcadia","IA",42.08,-95.04,42872158],
		["Wyoming","IA",42.06,-91,42872266],
		["Delmont","SD",43.26,-98.15,42872374],
		["Blandinsville","IL",40.55,-90.86,42872482],
		["Bellflower","MO",39,-91.35,42872590],
		["Cosby","MO",39.86,-94.67,42872698],
		["Syracuse","MO",38.66,-92.87,42872806],
		["Clyde","KS",39.59,-97.39,42872914],
		["Mc Cool Junction","NE",40.74,-97.59,42873022],
		["Eldorado","OK",34.47,-99.65,42873130],
		["Leedey","OK",35.86,-99.34,42873238],
		["Jonesboro","TX",31.59,-97.75,42873346],
		["Richland Springs","TX",31.27,-98.94,42873454],
		["Sheridan","TX",29.51,-96.69,42873562],
		["Sanderson","TX",30.15,-102.4,42873670],
		["Mount Vernon","OR",44.44,-119.16,42873778],
		["North Powder","OR",45.05,-118.01,42873886],
		["Thorndike","MA",42.18,-72.33,42873993],
		["Windsor","MA",42.51,-73.04,42874100],
		["Machiasport","ME",44.69,-67.39,42874207],
		["Peapack","NJ",40.7,-74.66,42874314],
		["Westernville","NY",43.3,-75.38,42874421],
		["Glen Campbell","PA",40.81,-78.83,42874528],
		["Spring Creek","PA",41.88,-79.51,42874635],
		["Sugar Run","PA",41.64,-76.24,42874742],
		["Aquasco","MD",38.58,-76.7,42874849],
		["Issue","MD",38.3,-76.86,42874956],
		["Castleton","VA",38.6,-78.1,42875063],
		["Washington","VA",38.71,-78.15,42875170],
		["Rosedale","VA",36.95,-81.93,42875277],
		["Given","WV",38.71,-81.7,42875384],
		["Pecks Mill","WV",37.92,-81.95,42875491],
		["Pipestem","WV",37.53,-80.96,42875598],
		["Newburg","WV",39.38,-79.85,42875705],
		["Fisher","WV",39.05,-79,42875812],
		["Pine Hall","NC",36.31,-80.06,42875919],
		["Balsam Grove","NC",35.22,-82.87,42876026],
		["Manor","GA",31.1,-82.57,42876133],
		["Rutledge","AL",31.72,-86.3,42876240],
		["Finley","TN",35.98,-89.58,42876347],
		["Oak Vale","MS",31.44,-90,42876454],
		["Charlottesville","IN",39.82,-85.65,42876561],
		["Iroquois","SD",44.36,-97.84,42876668],
		["Timber Lake","SD",45.42,-101.07,42876775],
		["Pesotum","IL",39.91,-88.27,42876882],
		["Grand Chain","IL",37.25,-89.01,42876989],
		["Karnak","IL",37.29,-88.97,42877096],
		["Valles Mines","MO",38,-90.45,42877203],
		["Ravenwood","MO",40.35,-94.67,42877310],
		["La Harpe","KS",37.91,-95.3,42877417],
		["Partridge","KS",37.96,-98.09,42877524],
		["Clarks","NE",41.21,-97.83,42877631],
		["Beemer","NE",41.93,-96.8,42877738],
		["Amherst","NE",40.83,-99.26,42877845],
		["Midway","AR",36.28,-92.43,42877952],
		["Braggs","OK",35.66,-95.19,42878059],
		["Strawn","TX",32.55,-98.49,42878166],
		["Clifton","ID",42.18,-112.03,42878273],
		["San Francisco","CA",37.79,-122.39,42878380],
		["Cheshire","OR",44.18,-123.37,42878487],
		["Haines","OR",44.94,-117.97,42878594],
		["South Chatham","MA",41.68,-70.02,42878700],
		["New Portland","ME",45.01,-70.08,42878806],
		["West Topsham","VT",44.13,-72.25,42878912],
		["East Burke","VT",44.6,-71.92,42879018],
		["Lyndon Center","VT",44.55,-72.04,42879124],
		["Quogue","NY",40.83,-72.6,42879230],
		["Cottekill","NY",41.84,-74.1,42879336],
		["Cameron","NY",42.19,-77.4,42879442],
		["Buena Vista","PA",40.28,-79.79,42879548],
		["Sutersville","PA",40.23,-79.8,42879654],
		["Ohiopyle","PA",39.86,-79.49,42879760],
		["Sharptown","MD",38.53,-75.72,42879866],
		["Suffolk","VA",36.7,-76.63,42879972],
		["Kimball","WV",37.42,-81.5,42880078],
		["Drybranch","WV",38.16,-81.51,42880184],
		["Accoville","WV",37.78,-81.85,42880290],
		["Stollings","WV",37.84,-81.93,42880396],
		["Pendleton","NC",36.47,-77.19,42880502],
		["Ward","SC",33.85,-81.73,42880608],
		["Olivehill","TN",35.26,-88.04,42880714],
		["Kite","KY",37.33,-82.81,42880820],
		["Hammondsville","OH",40.58,-80.72,42880926],
		["Engadine","MI",46.11,-85.56,42881032],
		["Cincinnati","IA",40.63,-92.92,42881138],
		["Lockridge","IA",40.99,-91.74,42881244],
		["Peterson","MN",43.78,-91.83,42881350],
		["Glenburn","ND",48.51,-101.22,42881456],
		["Dodson","MT",48.22,-108.35,42881562],
		["Verona","IL",41.21,-88.5,42881668],
		["Gladstone","IL",40.86,-90.95,42881774],
		["Ipava","IL",40.35,-90.32,42881880],
		["Lomax","IL",40.67,-91.07,42881986],
		["Secor","IL",40.74,-89.13,42882092],
		["Farmersville","IL",39.44,-89.65,42882198],
		["Marquette","NE",41,-98,42882304],
		["Lettsworth","LA",30.93,-91.7,42882410],
		["Louann","AR",33.39,-92.79,42882516],
		["Lookeba","OK",35.36,-98.36,42882622],
		["Sharon","OK",36.27,-99.33,42882728],
		["Lenapah","OK",36.85,-95.63,42882834],
		["Miami","TX",35.69,-100.64,42882940],
		["Baggs","WY",41.03,-107.65,42883046],
		["Hanna","WY",41.86,-106.55,42883152],
		["La Barge","WY",42.26,-110.19,42883258],
		["Kendrick","ID",46.64,-116.54,42883364],
		["Whitethorn","CA",40.07,-123.92,42883470],
		["Hornbrook","CA",41.91,-122.49,42883576],
		["Buena","WA",46.42,-120.29,42883682],
		["Pittsburg","NH",45.05,-71.39,42883787],
		["Sherman","ME",45.81,-68.31,42883892],
		["Washington","VT",44.1,-72.43,42883997],
		["Sutton","VT",44.66,-72.03,42884102],
		["Round Top","NY",42.27,-74.06,42884207],
		["North Java","NY",42.66,-78.33,42884312],
		["Mather","PA",39.95,-80.09,42884417],
		["Honey Grove","PA",40.42,-77.55,42884522],
		["Lackawaxen","PA",41.48,-74.98,42884627],
		["Upperville","VA",38.99,-77.88,42884732],
		["Saint James","MD",39.57,-77.82,42884837],
		["Chester Gap","VA",38.85,-78.13,42884942],
		["Barco","NC",36.39,-75.97,42885047],
		["Helen","GA",34.7,-83.72,42885152],
		["Venus","FL",27.06,-81.35,42885257],
		["Vandiver","AL",33.48,-86.5,42885362],
		["Cascilla","MS",33.89,-90.03,42885467],
		["Miracle","KY",36.76,-83.58,42885572],
		["Steele","KY",37.97,-82.18,42885677],
		["Washingtonville","OH",40.89,-80.76,42885782],
		["Nabb","IN",38.61,-85.63,42885887],
		["Brownsville","IN",39.69,-84.98,42885992],
		["Straughn","IN",39.8,-85.29,42886097],
		["Duncombe","IA",42.46,-93.99,42886202],
		["Gilmore City","IA",42.72,-94.43,42886307],
		["Orient","IA",41.2,-94.41,42886412],
		["Sperry","IA",40.95,-91.15,42886517],
		["Bagley","WI",42.9,-91.09,42886622],
		["Ferryville","WI",43.34,-91.08,42886727],
		["Wolsey","SD",44.41,-98.47,42886832],
		["Sawyer","ND",48.08,-101.05,42886937],
		["Emigrant","MT",45.28,-110.86,42887042],
		["Ulm","MT",47.4,-111.59,42887147],
		["Berger","MO",38.67,-91.33,42887252],
		["Kansas City","MO",39.09,-94.58,42887357],
		["Montrose","MO",38.25,-93.98,42887462],
		["Attica","KS",37.24,-98.22,42887567],
		["Arlington","KS",37.89,-98.17,42887672],
		["Loomis","NE",40.47,-99.5,42887777],
		["Stapleton","NE",41.47,-100.51,42887882],
		["Simpson","LA",31.25,-93.01,42887987],
		["Blevins","AR",33.87,-93.57,42888092],
		["Gillham","AR",34.16,-94.31,42888197],
		["Fargo","OK",36.37,-99.62,42888302],
		["Ralston","OK",36.5,-96.73,42888407],
		["Castle","OK",35.47,-96.38,42888512],
		["Ramah","CO",39.12,-104.16,42888617],
		["Fairfield","ID",43.41,-114.78,42888722],
		["Sun Valley","ID",43.69,-114.34,42888827],
		["Notus","ID",43.72,-116.8,42888932],
		["Viola","ID",46.86,-116.97,42889037],
		["Spreckels","CA",36.62,-121.64,42889142],
		["Meridian","CA",39.07,-121.84,42889247],
		["Cummington","MA",42.46,-72.9,42889351],
		["Chesterfield","NH",42.88,-72.46,42889455],
		["New Vernon","NJ",40.73,-74.48,42889559],
		["Elka Park","NY",42.14,-74.1,42889663],
		["Graysville","PA",39.89,-80.39,42889767],
		["Maytown","PA",40.07,-76.58,42889871],
		["Marshalls Creek","PA",41.04,-75.12,42889975],
		["Onley","VA",37.69,-75.71,42890079],
		["Natural Bridge","VA",37.63,-79.55,42890183],
		["Panther","WV",37.49,-81.88,42890287],
		["Indore","WV",38.35,-81.16,42890391],
		["West Milford","WV",39.2,-80.4,42890495],
		["Mc Grady","NC",36.33,-81.23,42890599],
		["Warne","NC",34.99,-83.9,42890703],
		["Palmersville","TN",36.41,-88.58,42890807],
		["Girdler","KY",36.93,-83.84,42890911],
		["Beechmont","KY",37.17,-87.05,42891015],
		["Port Sanilac","MI",43.43,-82.54,42891119],
		["Brutus","MI",45.5,-84.75,42891223],
		["Chatham","MI",46.34,-86.93,42891327],
		["Felch","MI",46,-87.85,42891431],
		["Mohawk","MI",47.3,-88.37,42891535],
		["Thornton","IA",42.94,-93.38,42891639],
		["Dixon","IA",41.74,-90.78,42891743],
		["Irene","SD",43.08,-97.15,42891847],
		["Artesian","SD",44,-97.92,42891951],
		["Drayton","ND",48.56,-97.17,42892055],
		["Elgin","ND",46.4,-101.84,42892159],
		["Gays","IL",39.45,-88.49,42892263],
		["Vergennes","IL",37.9,-89.33,42892367],
		["Skidmore","MO",40.28,-95.07,42892471],
		["Mindenmines","MO",37.47,-94.58,42892575],
		["Leonardville","KS",39.36,-96.85,42892679],
		["Miltonvale","KS",39.35,-97.45,42892783],
		["Newcastle","NE",42.65,-96.87,42892887],
		["Pleasanton","NE",40.97,-99.08,42892991],
		["Hayes","LA",30.1,-92.92,42893095],
		["Winthrop","AR",33.83,-94.35,42893199],
		["Elaine","AR",34.3,-90.85,42893303],
		["Sidney","AR",36,-91.65,42893407],
		["Oaks","OK",36.16,-94.85,42893511],
		["Boynton","OK",35.64,-95.65,42893615],
		["Powell","TX",32.11,-96.32,42893719],
		["Wiergate","TX",30.99,-93.7,42893823],
		["Rosanky","TX",29.93,-97.3,42893927],
		["Smyer","TX",33.58,-102.16,42894031],
		["Terlingua","TX",29.32,-103.62,42894135],
		["Byron","WY",44.79,-108.5,42894239],
		["Culdesac","ID",46.36,-116.67,42894343],
		["Wenden","AZ",34.06,-113.51,42894447],
		["Cubero","NM",35.08,-107.51,42894551],
		["Maunaloa","HI",21.13,-157.21,42894655],
		["Halfway","OR",44.94,-117.1,42894759],
		["Electric City","WA",47.87,-119.08,42894863],
		["Nenana","AK",64.47,-148.57,42894967],
		["North Sutton","NH",43.36,-71.94,42895070],
		["Sand Lake","NY",42.63,-73.49,42895173],
		["Arkville","NY",42.14,-74.62,42895276],
		["Plattsburgh","NY",44.69,-73.45,42895379],
		["Hobart","NY",42.37,-74.66,42895482],
		["Strabane","PA",40.21,-80.14,42895585],
		["Yukon","PA",40.21,-79.68,42895688],
		["Clarks Mills","PA",41.38,-80.16,42895791],
		["Lambsburg","VA",36.58,-80.76,42895894],
		["Charmco","WV",37.99,-80.69,42895997],
		["Topton","NC",35.24,-83.7,42896100],
		["Cecil","AL",32.3,-85.99,42896203],
		["Montgomery","AL",32.35,-86.28,42896306],
		["Lynch","KY",36.96,-82.91,42896409],
		["Thornton","KY",37.15,-82.73,42896512],
		["Malinta","OH",41.31,-84.03,42896615],
		["New Bavaria","OH",41.2,-84.16,42896718],
		["Irondale","OH",40.57,-80.72,42896821],
		["Boon","MI",44.28,-85.6,42896924],
		["Onekama","MI",44.36,-86.2,42897027],
		["Maurice","IA",42.96,-96.18,42897130],
		["Dalbo","MN",45.68,-93.45,42897233],
		["Herman","MN",45.81,-96.13,42897336],
		["Vesta","MN",44.5,-95.41,42897439],
		["Kelliher","MN",47.94,-94.44,42897542],
		["Chancellor","SD",43.37,-96.98,42897645],
		["Cullom","IL",40.87,-88.26,42897748],
		["Nebo","IL",39.44,-90.79,42897851],
		["Milo","MO",37.75,-94.3,42897954],
		["Prairie Home","MO",38.81,-92.58,42898057],
		["Aldrich","MO",37.54,-93.55,42898160],
		["Pittsburg","MO",37.83,-93.3,42898263],
		["Harveyville","KS",38.78,-95.96,42898366],
		["Murdock","NE",40.92,-96.28,42898469],
		["Rising City","NE",41.19,-97.29,42898572],
		["Rodessa","LA",32.97,-93.99,42898675],
		["Heth","AR",35.06,-90.46,42898778],
		["Princeton","ID",46.9,-116.81,42898881],
		["Smelterville","ID",47.52,-116.18,42898984],
		["Elgin","AZ",31.57,-110.54,42899087],
		["San Francisco","CA",37.78,-122.39,42899190],
		["Markleeville","CA",38.73,-119.84,42899293],
		["Maupin","OR",45.04,-121.15,42899396],
		["Blodgett","OR",44.62,-123.6,42899499],
		["Arlington","OR",45.55,-120.32,42899602],
		["Underwood","WA",45.74,-121.58,42899705],
		["Hopkinton","RI",41.45,-71.78,42899807],
		["Dennysville","ME",44.9,-67.22,42899909],
		["Ferndale","NY",41.77,-74.73,42900011],
		["Broad Top","PA",40.2,-78.14,42900113],
		["Vienna","MD",38.48,-75.82,42900215],
		["Nellysford","VA",37.85,-78.88,42900317],
		["Elk Creek","VA",36.71,-81.18,42900419],
		["Henrietta","NC",35.23,-81.8,42900521],
		["Sale City","GA",31.26,-84.02,42900623],
		["Pine Mountain Valley","GA",32.78,-84.82,42900725],
		["Placida","FL",26.83,-82.2,42900827],
		["Montgomery","AL",32.35,-86.28,42900929],
		["Flag Pond","TN",36.01,-82.56,42901031],
		["Louise","MS",32.98,-90.59,42901133],
		["Leburn","KY",37.34,-82.95,42901235],
		["Alvordton","OH",41.66,-84.43,42901337],
		["Wilmot","OH",40.65,-81.63,42901439],
		["Syracuse","OH",38.99,-81.97,42901541],
		["Canaan","IN",38.86,-85.3,42901643],
		["Vernon","MI",42.93,-84.02,42901745],
		["Germfask","MI",46.25,-85.92,42901847],
		["Farragut","IA",40.71,-95.48,42901949],
		["Blakesburg","IA",40.96,-92.63,42902051],
		["Crawfordsville","IA",41.21,-91.53,42902153],
		["Atalissa","IA",41.57,-91.16,42902255],
		["Mount Hope","WI",42.96,-90.85,42902357],
		["Weyerhaeuser","WI",45.42,-91.41,42902459],
		["Millville","MN",44.24,-92.29,42902561],
		["Grygla","MN",48.29,-95.61,42902663],
		["Buxton","ND",47.6,-97.09,42902765],
		["Lakota","ND",48.04,-98.34,42902867],
		["Big Sky","MT",45.26,-111.32,42902969],
		["Drummond","MT",46.65,-113.24,42903071],
		["Neponset","IL",41.29,-89.79,42903173],
		["Lovejoy","IL",38.65,-90.16,42903275],
		["Elkhart","IL",40.01,-89.48,42903377],
		["Wasola","MO",36.79,-92.57,42903479],
		["Nickerson","NE",41.53,-96.47,42903581],
		["Eustis","NE",40.66,-100.02,42903683],
		["Watson","AR",33.89,-91.25,42903785],
		["Lamont","OK",36.69,-97.55,42903887],
		["Hillister","TX",30.66,-94.38,42903989],
		["Bigfoot","TX",29.05,-98.85,42904091],
		["Texline","TX",36.37,-103.01,42904193],
		["Ponderay","ID",48.3,-116.53,42904295],
		["Lemitar","NM",34.16,-106.91,42904397],
		["J B P H H","HI",21.36,-157.94,42904499],
		["Buxton","OR",45.73,-123.19,42904601],
		["Seal Rock","OR",44.5,-123.99,42904703],
		["Worthington","MA",42.41,-72.93,42904804],
		["Monroe","NH",44.28,-72.01,42904905],
		["Piermont","NH",43.96,-72.08,42905006],
		["Quechee","VT",43.65,-72.42,42905107],
		["South Plymouth","NY",42.58,-75.56,42905208],
		["Willet","NY",42.46,-75.91,42905309],
		["Greenwood","NY",42.13,-77.64,42905410],
		["Mabscott","WV",37.76,-81.21,42905511],
		["Jacksonburg","WV",39.53,-80.65,42905612],
		["Statesboro","GA",32.44,-81.77,42905713],
		["Trimble","TN",36.2,-89.18,42905814],
		["Allensville","KY",36.71,-87.06,42905915],
		["Gettysburg","OH",40.11,-84.49,42906016],
		["Langsville","OH",39.05,-82.23,42906117],
		["Cairo","OH",40.83,-84.08,42906218],
		["La Crosse","IN",41.31,-86.88,42906319],
		["Stockport","IA",40.85,-91.83,42906420],
		["Spring Park","MN",44.93,-93.63,42906521],
		["Lake Wilson","MN",43.99,-95.95,42906622],
		["Blomkest","MN",44.94,-95.02,42906723],
		["Deer Creek","MN",46.39,-95.32,42906824],
		["Veblen","SD",45.86,-97.28,42906925],
		["White Lake","SD",43.72,-98.71,42907026],
		["Bowdle","SD",45.45,-99.65,42907127],
		["Finley","ND",47.51,-97.83,42907228],
		["New England","ND",46.54,-102.86,42907329],
		["Lee","IL",41.79,-88.94,42907430],
		["Magnolia","IL",41.11,-89.19,42907531],
		["Rutledge","MO",40.31,-92.08,42907632],
		["Lesterville","MO",37.45,-90.82,42907733],
		["Mound Valley","KS",37.2,-95.42,42907834],
		["Fairmont","NE",40.63,-97.58,42907935],
		["Lakeland","LA",30.57,-91.42,42908036],
		["Gage","OK",36.31,-99.75,42908137],
		["Crowder","OK",35.12,-95.66,42908238],
		["Nezperce","ID",46.28,-116.23,42908339],
		["Cleveland","UT",39.4,-110.91,42908440],
		["Skull Valley","AZ",34.58,-112.77,42908541],
		["Arroyo Hondo","NM",36.53,-105.66,42908642],
		["Sunol","CA",37.56,-121.84,42908743],
		["Butte Falls","OR",42.53,-122.53,42908844],
		["Riverside","WA",48.56,-119.35,42908945],
		["San Juan","PR",18.4,-66.06,42909045],
		["Bartlett","NH",44.08,-71.27,42909145],
		["Proctorsville","VT",43.41,-72.6,42909245],
		["Bondville","VT",43.16,-72.93,42909345],
		["Mill Neck","NY",40.88,-73.55,42909445],
		["Warnerville","NY",42.61,-74.47,42909545],
		["Boiceville","NY",41.99,-74.26,42909645],
		["Phoenicia","NY",42.08,-74.31,42909745],
		["Pottersville","NY",43.69,-73.75,42909845],
		["Slickville","PA",40.46,-79.5,42909945],
		["Olanta","PA",40.9,-78.5,42910045],
		["Blairs Mills","PA",40.25,-77.77,42910145],
		["Hereford","PA",40.44,-75.58,42910245],
		["Marbury","MD",38.55,-77.15,42910345],
		["Caret","VA",37.98,-76.96,42910445],
		["Rockbridge Baths","VA",37.9,-79.41,42910545],
		["Gandeeville","WV",38.69,-81.46,42910645],
		["Big Springs","WV",38.97,-80.99,42910745],
		["Trade","TN",36.34,-81.74,42910845],
		["Hornsby","TN",35.22,-88.83,42910945],
		["Crawford","TN",36.24,-85.18,42911045],
		["Cheshire","OH",38.94,-82.11,42911145],
		["Humeston","IA",40.86,-93.49,42911245],
		["Bode","IA",42.86,-94.28,42911345],
		["Raymond","IA",42.46,-92.22,42911445],
		["Footville","WI",42.67,-89.2,42911545],
		["Hollandale","WI",42.87,-89.93,42911645],
		["Humbird","WI",44.53,-90.9,42911745],
		["Hills","MN",43.52,-96.36,42911845],
		["Gary","MN",47.37,-96.26,42911945],
		["Selby","SD",45.5,-100.03,42912045],
		["Ashley","ND",46.03,-99.37,42912145],
		["Fairview","IL",40.65,-90.19,42912245],
		["London Mills","IL",40.71,-90.26,42912345],
		["Indianola","IL",39.92,-87.74,42912445],
		["Emden","IL",40.29,-89.48,42912545],
		["Grantsburg","IL",37.37,-88.76,42912645],
		["Thompson","MO",39.18,-91.98,42912745],
		["Uniontown","KS",37.84,-94.97,42912845],
		["Jackson","NE",42.44,-96.56,42912945],
		["Giltner","NE",40.77,-98.15,42913045],
		["Beaver City","NE",40.13,-99.82,42913145],
		["Wauneta","NE",40.41,-101.37,42913245],
		["Evans","LA",30.98,-93.5,42913345],
		["Tullos","LA",31.82,-92.32,42913445],
		["Warm Springs","AR",36.46,-91.04,42913545],
		["Pocasset","OK",35.2,-97.93,42913645],
		["Covington","OK",36.3,-97.58,42913745],
		["Roggen","CO",40.17,-104.37,42913845],
		["Ucon","ID",43.59,-111.96,42913945],
		["Seligman","AZ",35.13,-112.99,42914045],
		["Farmington","CA",37.96,-120.87,42914145],
		["Donald","OR",45.22,-122.83,42914245],
		["Monroe","ME",44.61,-69.01,42914344],
		["Wells","VT",43.42,-73.2,42914443],
		["Schodack Landing","NY",42.47,-73.74,42914542],
		["Dickinson Center","NY",44.71,-74.53,42914641],
		["Clarence","PA",41.08,-77.87,42914740],
		["Karthaus","PA",41.11,-78.12,42914839],
		["Tall Timbers","MD",38.15,-76.53,42914938],
		["Aroda","VA",38.32,-78.24,42915037],
		["Arvonia","VA",37.66,-78.41,42915136],
		["Baskerville","VA",36.68,-78.27,42915235],
		["Middlebrook","VA",38.02,-79.28,42915334],
		["Bancroft","WV",38.51,-81.84,42915433],
		["Racine","WV",38.16,-81.65,42915532],
		["Enterprise","WV",39.41,-80.27,42915631],
		["Rio","WV",39.13,-78.67,42915730],
		["Henrico","NC",36.53,-77.83,42915829],
		["Terrell","NC",35.58,-80.97,42915928],
		["Sardis","AL",32.27,-87.02,42916027],
		["Needham","AL",31.98,-88.33,42916126],
		["Auburntown","TN",35.94,-86.09,42916225],
		["Hickory Valley","TN",35.15,-89.12,42916324],
		["Darden","TN",35.63,-88.23,42916423],
		["Buffalo Valley","TN",36.18,-85.75,42916522],
		["Dayhoit","KY",36.86,-83.38,42916621],
		["Teaberry","KY",37.42,-82.64,42916720],
		["Canmer","KY",37.28,-85.78,42916819],
		["Addyston","OH",39.13,-84.71,42916918],
		["Dublin","IN",39.81,-85.2,42917017],
		["South Branch","MI",44.46,-83.88,42917116],
		["Ackworth","IA",41.36,-93.47,42917215],
		["Biwabik","MN",47.53,-92.35,42917314],
		["Palisade","MN",46.71,-93.49,42917413],
		["Maddock","ND",47.96,-99.52,42917512],
		["Turtle Lake","ND",47.52,-100.89,42917611],
		["Fromberg","MT",45.39,-108.79,42917710],
		["German Valley","IL",42.21,-89.48,42917809],
		["Good Hope","IL",40.55,-90.67,42917908],
		["Dennison","IL",39.46,-87.58,42918007],
		["Wheeler","IL",39.04,-88.31,42918106],
		["Grand Tower","IL",37.63,-89.5,42918205],
		["Gray Summit","MO",38.49,-90.81,42918304],
		["Portage Des Sioux","MO",38.92,-90.34,42918403],
		["Richards","MO",37.9,-94.55,42918502],
		["Asbury","MO",37.27,-94.6,42918601],
		["Martinsburg","MO",39.1,-91.64,42918700],
		["Vichy","MO",38.11,-91.76,42918799],
		["Redfield","KS",37.83,-94.88,42918898],
		["Okolona","AR",34,-93.33,42918997],
		["Dyer","AR",35.49,-94.13,42919096],
		["Okay","OK",35.85,-95.31,42919195],
		["Combes","TX",26.24,-97.72,42919294],
		["Minturn","CO",39.58,-106.42,42919393],
		["Sun Valley","AZ",34.96,-110.05,42919492],
		["Bosque","NM",34.47,-106.8,42919591],
		["Virginia City","NV",39.3,-119.63,42919690],
		["San Diego","CA",32.7,-117.18,42919789],
		["Forest Falls","CA",34.08,-116.9,42919888],
		["South Dos Palos","CA",36.95,-120.65,42919987],
		["Brinnon","WA",47.69,-122.99,42920086],
		["Intervale","NH",44.15,-71.06,42920184],
		["Penobscot","ME",44.46,-68.71,42920282],
		["Eagle Lake","ME",47.04,-68.59,42920380],
		["Oakfield","ME",46.09,-68.15,42920478],
		["Wardsboro","VT",43.02,-72.8,42920576],
		["Three Bridges","NJ",40.52,-74.8,42920674],
		["Coeymans","NY",42.48,-73.83,42920772],
		["Summit","NY",42.58,-74.58,42920870],
		["Glen Aubrey","NY",42.25,-76,42920968],
		["New Derry","PA",40.32,-79.3,42921066],
		["Schaefferstown","PA",40.29,-76.29,42921164],
		["Hallwood","VA",37.87,-75.58,42921262],
		["Randolph","VA",36.97,-78.7,42921360],
		["Bent Mountain","VA",37.15,-80.11,42921458],
		["Arnett","WV",37.84,-81.43,42921556],
		["Switzer","WV",37.78,-81.97,42921654],
		["Pleasant Hill","NC",36.51,-77.54,42921752],
		["Norwood","GA",33.46,-82.7,42921850],
		["Kenansville","FL",27.88,-80.99,42921948],
		["Glenwood","AL",31.66,-86.17,42922046],
		["Bumpus Mills","TN",36.62,-87.85,42922144],
		["Heidrick","KY",36.88,-83.87,42922242],
		["Ransom","KY",37.54,-82.2,42922340],
		["Mc Roberts","KY",37.21,-82.66,42922438],
		["Trenary","MI",46.2,-86.97,42922536],
		["Bruce Crossing","MI",46.51,-89.21,42922634],
		["Floyd","IA",43.12,-92.73,42922732],
		["Rowley","IA",42.36,-91.84,42922830],
		["Baileys Harbor","WI",45.06,-87.12,42922928],
		["Gordon","WI",46.24,-91.79,42923026],
		["Cosmos","MN",44.93,-94.69,42923124],
		["Mission Hill","SD",42.92,-97.27,42923222],
		["Bruce","SD",44.43,-96.88,42923320],
		["Emerado","ND",47.92,-97.36,42923418],
		["Underwood","ND",47.45,-101.14,42923516],
		["Halliday","ND",47.35,-102.33,42923614],
		["Hamburg","IL",39.23,-90.71,42923712],
		["Alto Pass","IL",37.57,-89.31,42923810],
		["Caledonia","MO",37.76,-90.77,42923908],
		["Gatewood","MO",36.56,-91.07,42924006],
		["Alma","MO",39.09,-93.54,42924104],
		["Osborn","MO",39.75,-94.35,42924202],
		["Browning","MO",40.03,-93.16,42924300],
		["Galt","MO",40.12,-93.38,42924398],
		["Winston","MO",39.86,-94.14,42924496],
		["Amsterdam","MO",38.34,-94.58,42924594],
		["Jerico Springs","MO",37.61,-94.01,42924692],
		["Alba","MO",37.23,-94.41,42924790],
		["Lewis","KS",37.93,-99.25,42924888],
		["Petersburg","NE",41.85,-98.07,42924986],
		["Fairfield","NE",40.43,-98.1,42925084],
		["Fountain Hill","AR",33.35,-91.85,42925182],
		["Newhope","AR",34.22,-93.85,42925280],
		["Biscoe","AR",34.83,-91.53,42925378],
		["Wapanucka","OK",34.37,-96.42,42925476],
		["Tupelo","OK",34.6,-96.42,42925574],
		["Red Rock","OK",36.45,-97.17,42925672],
		["Reklaw","TX",31.86,-94.98,42925770],
		["Pattison","TX",29.81,-95.97,42925868],
		["Nazareth","TX",34.54,-102.1,42925966],
		["Kittredge","CO",39.65,-105.3,42926064],
		["Garden City","UT",41.91,-111.25,42926162],
		["Paragonah","UT",37.93,-112.64,42926260],
		["Continental Divide","NM",35.47,-108.45,42926358],
		["Gales Creek","OR",45.61,-123.27,42926456],
		["Bondsville","MA",42.2,-72.34,42926553],
		["New Castle","NH",43.05,-70.72,42926650],
		["Blaine","ME",46.5,-67.86,42926747],
		["Smithfield","ME",44.63,-69.8,42926844],
		["Guildhall","VT",44.56,-71.56,42926941],
		["Mount Marion","NY",42.03,-74,42927038],
		["Lakeville","NY",42.82,-77.71,42927135],
		["Hydes","MD",39.48,-76.47,42927232],
		["Greenbackville","VA",38,-75.41,42927329],
		["Rocky Gap","VA",37.23,-81.1,42927426],
		["Gauley Bridge","WV",38.16,-81.2,42927523],
		["Granville","WV",39.64,-79.99,42927620],
		["Upper Tract","WV",38.79,-79.25,42927717],
		["Franklin","AL",31.71,-87.41,42927814],
		["Hartford","TN",35.81,-83.14,42927911],
		["New Site","MS",34.56,-88.41,42928008],
		["Eolia","KY",37.05,-82.79,42928105],
		["Windsor","KY",37.13,-84.91,42928202],
		["Thurston","OH",39.84,-82.54,42928299],
		["Burgoon","OH",41.26,-83.25,42928396],
		["Shade","OH",39.21,-82.01,42928493],
		["Eckerty","IN",38.31,-86.61,42928590],
		["Sandborn","IN",38.89,-87.18,42928687],
		["Melcher-dallas","IA",41.2,-93.27,42928784],
		["Dows","IA",42.65,-93.5,42928881],
		["Rudd","IA",43.12,-92.9,42928978],
		["Earling","IA",41.77,-95.41,42929075],
		["Deep River","IA",41.58,-92.37,42929172],
		["Bennett","IA",41.73,-90.97,42929269],
		["Belview","MN",44.6,-95.32,42929366],
		["Kensington","MN",45.77,-95.69,42929463],
		["Lancaster","MN",48.85,-96.8,42929560],
		["Jordan","MT",47.38,-106.81,42929657],
		["Black Eagle","MT",47.54,-111.26,42929754],
		["Lindenwood","IL",42.03,-89.02,42929851],
		["East Galesburg","IL",40.94,-90.31,42929948],
		["Industry","IL",40.32,-90.6,42930045],
		["Benson","IL",40.85,-89.12,42930142],
		["Bath","IL",40.19,-90.14,42930239],
		["Callao","MO",39.76,-92.62,42930336],
		["Franklin","MO",39.01,-92.75,42930433],
		["Edgar","NE",40.36,-97.97,42930530],
		["Morrow","LA",30.82,-92.01,42930627],
		["Hickory Ridge","AR",35.4,-90.99,42930724],
		["Tumbling Shoals","AR",35.55,-91.95,42930821],
		["Savanna","OK",34.82,-95.83,42930918],
		["Kaw City","OK",36.76,-96.86,42931015],
		["Achille","OK",33.83,-96.38,42931112],
		["Rochelle","TX",31.22,-99.21,42931209],
		["Rule","TX",33.18,-99.89,42931306],
		["Ackerly","TX",32.52,-101.71,42931403],
		["Roll","AZ",32.76,-113.87,42931500],
		["Kernville","CA",35.83,-118.45,42931597],
		["Nicasio","CA",38.07,-122.68,42931694],
		["Gasquet","CA",41.84,-123.85,42931791],
		["Grimes","CA",39.04,-121.9,42931888],
		["Wilderville","OR",42.4,-123.57,42931985],
		["Ethel","WA",46.51,-122.68,42932082],
		["Iliamna","AK",59.52,-154.4,42932179],
		["Fort Yukon","AK",66.56,-145.25,42932276],
		["Monterey","MA",42.18,-73.21,42932372],
		["Sandisfield","MA",42.11,-73.13,42932468],
		["Savoy","MA",42.56,-73.02,42932564],
		["Harwich Port","MA",41.66,-70.06,42932660],
		["Smyrna Mills","ME",46.12,-68.16,42932756],
		["Waterville","VT",44.71,-72.75,42932852],
		["West Charleston","VT",44.86,-72.05,42932948],
		["Andes","NY",42.18,-74.78,42933044],
		["Cayuta","NY",42.28,-76.69,42933140],
		["Strongstown","PA",40.56,-78.91,42933236],
		["Beulah","MS",33.79,-90.98,42933332],
		["Daleville","MS",32.54,-88.67,42933428],
		["Means","KY",37.94,-83.76,42933524],
		["Cannon","KY",36.91,-83.85,42933620],
		["Beauty","KY",37.83,-82.43,42933716],
		["Ferguson","KY",37.06,-84.59,42933812],
		["Yosemite","KY",37.35,-84.83,42933908],
		["Liberty Center","IN",40.69,-85.27,42934004],
		["Lewis","IN",39.28,-87.26,42934100],
		["Merom","IN",39.05,-87.56,42934196],
		["Minburn","IA",41.75,-94.02,42934292],
		["Otho","IA",42.42,-94.14,42934388],
		["Diagonal","IA",40.81,-94.34,42934484],
		["Plato","MN",44.77,-94.03,42934580],
		["Hines","MN",47.68,-94.63,42934676],
		["Roosevelt","MN",48.8,-95.1,42934772],
		["Broadview","MT",46.07,-108.87,42934868],
		["Lostant","IL",41.14,-89.06,42934964],
		["Dorsey","IL",38.97,-89.99,42935060],
		["Niota","IL",40.61,-91.28,42935156],
		["Hurst","IL",37.83,-89.14,42935252],
		["Knox City","MO",40.14,-92.01,42935348],
		["Elk City","KS",37.28,-95.91,42935444],
		["Almena","KS",39.89,-99.7,42935540],
		["Chapman","NE",41.02,-98.15,42935636],
		["Montrose","AR",33.29,-91.49,42935732],
		["Summit","AR",36.25,-92.68,42935828],
		["Ravia","OK",34.24,-96.75,42935924],
		["Daniel","WY",42.87,-110.07,42936020],
		["Fernwood","ID",47.09,-116.46,42936116],
		["Orderville","UT",37.28,-112.71,42936212],
		["San Quentin","CA",37.94,-122.49,42936308],
		["Carnelian Bay","CA",39.22,-120.08,42936404],
		["Tuntutuliak","AK",60.41,-162.65,42936500],
		["Gilsum","NH",43.05,-72.26,42936595],
		["Harrisville","NH",42.95,-72.1,42936690],
		["Lagrange","ME",45.16,-68.84,42936785],
		["New Vineyard","ME",44.8,-70.12,42936880],
		["Saxtons River","VT",43.13,-72.51,42936975],
		["Centerbrook","CT",41.34,-72.41,42937070],
		["Greenwich","NJ",39.39,-75.36,42937165],
		["Wawarsing","NY",41.73,-74.37,42937260],
		["Plymouth","NY",42.61,-75.6,42937355],
		["Richeyville","PA",40.02,-79.94,42937450],
		["Blanchard","PA",41.04,-77.7,42937545],
		["Tresckow","PA",40.91,-75.96,42937640],
		["Queen Anne","MD",38.91,-75.95,42937735],
		["Birdsnest","VA",37.43,-75.88,42937830],
		["Mount Gay","WV",37.85,-82.04,42937925],
		["Augusta","GA",33.45,-81.99,42938020],
		["Weogufka","AL",32.93,-86.41,42938115],
		["Auxier","KY",37.73,-82.75,42938210],
		["Filion","MI",43.9,-82.96,42938305],
		["Luzerne","MI",44.61,-84.26,42938400],
		["Marble Rock","IA",42.96,-92.86,42938495],
		["New Market","IA",40.73,-94.9,42938590],
		["Harpers Ferry","IA",43.2,-91.15,42938685],
		["Land O Lakes","WI",46.16,-89.23,42938780],
		["Montreal","WI",46.43,-90.23,42938875],
		["Comstock","WI",45.49,-92.09,42938970],
		["Barrett","MN",45.91,-95.89,42939065],
		["Winfred","SD",43.99,-97.36,42939160],
		["Roscoe","SD",45.45,-99.33,42939255],
		["Batesland","SD",43.12,-102.1,42939350],
		["Stanford","MT",47.04,-110.32,42939445],
		["Rexford","MT",48.8,-115.13,42939540],
		["Cameron","IL",40.88,-90.51,42939635],
		["Waggoner","IL",39.37,-89.65,42939730],
		["Mound City","IL",37.08,-89.16,42939825],
		["Raleigh","IL",37.82,-88.53,42939920],
		["Alexandria","MO",40.35,-91.45,42940015],
		["Barnard","MO",40.17,-94.82,42940110],
		["Clarksdale","MO",39.81,-94.55,42940205],
		["Greeley","KS",38.36,-95.12,42940300],
		["Leigh","NE",41.7,-97.23,42940395],
		["Silver Creek","NE",41.33,-97.66,42940490],
		["Wallace","NE",40.83,-101.16,42940585],
		["Briggsdale","CO",40.63,-104.32,42940680],
		["Kaycee","WY",43.7,-106.63,42940775],
		["Alta","WY",43.75,-111.04,42940870],
		["Teton","ID",43.88,-111.64,42940965],
		["Mullan","ID",47.46,-115.74,42941060],
		["Cochiti Pueblo","NM",35.6,-106.34,42941155],
		["Dixon","NM",36.19,-105.88,42941250],
		["Jacumba","CA",32.63,-116.18,42941345],
		["Gates","OR",44.74,-122.39,42941440],
		["Vida","OR",44.13,-122.42,42941535],
		["Walterville","OR",44.13,-122.64,42941630],
		["Warren","NH",43.92,-71.89,42941724],
		["Georgetown","ME",43.8,-69.74,42941818],
		["Sheffield","VT",44.6,-72.12,42941912],
		["Grand Gorge","NY",42.35,-74.5,42942006],
		["Rushford","NY",42.4,-78.23,42942100],
		["Westmoreland City","PA",40.27,-79.54,42942194],
		["Marion","PA",39.86,-77.67,42942288],
		["Bryantown","MD",38.55,-76.84,42942382],
		["Mattaponi","VA",37.53,-76.77,42942476],
		["Dendron","VA",37.03,-76.92,42942570],
		["Swanquarter","NC",35.4,-76.27,42942664],
		["Kinards","SC",34.37,-81.71,42942758],
		["Stewart","TN",36.32,-87.89,42942852],
		["Mayersville","MS",32.89,-91.04,42942946],
		["Coldiron","KY",36.82,-83.45,42943040],
		["David","KY",37.16,-82.95,42943134],
		["Lohrville","IA",42.26,-94.55,42943228],
		["Pulaski","IA",40.69,-92.27,42943322],
		["Westboro","WI",45.34,-90.3,42943416],
		["Vernon Center","MN",43.96,-94.16,42943510],
		["Gonvick","MN",47.73,-95.51,42943604],
		["Hudson","SD",43.12,-96.45,42943698],
		["Summit","SD",45.3,-97.03,42943792],
		["Ekalaka","MT",45.77,-104.51,42943886],
		["Crescent City","IL",40.77,-87.85,42943980],
		["Saunemin","IL",40.89,-88.4,42944074],
		["Sadorus","IL",39.96,-88.34,42944168],
		["Tallula","IL",39.94,-89.93,42944262],
		["Bucklin","MO",39.78,-92.88,42944356],
		["Spencer","NE",42.87,-98.7,42944450],
		["Arnold","NE",41.42,-100.19,42944544],
		["Portland","AR",33.23,-91.51,42944638],
		["Wilmot","AR",33.05,-91.57,42944732],
		["Edgemont","AR",35.64,-92.19,42944826],
		["Gillett","AR",34.11,-91.37,42944920],
		["Timbo","AR",35.86,-92.3,42945014],
		["Lone Wolf","OK",34.99,-99.24,42945108],
		["Cherokee","TX",30.98,-98.7,42945202],
		["Oakhurst","TX",30.74,-95.3,42945296],
		["Sierra Blanca","TX",31.17,-105.35,42945390],
		["Deweyville","UT",41.72,-112.08,42945484],
		["Sterling","UT",39.19,-111.68,42945578],
		["Joseph","UT",38.62,-112.33,42945672],
		["Jemez Springs","NM",35.77,-106.68,42945766],
		["Tahoma","CA",39.02,-120.14,42945860],
		["Glenoma","WA",46.53,-122.14,42945954],
		["Manokotak","AK",58.88,-158.93,42946048],
		["Yakutat","AK",59.51,-139.71,42946142],
		["Stockbridge","MA",42.3,-73.32,42946235],
		["Brooklin","ME",44.26,-68.56,42946328],
		["South Cairo","NY",42.27,-73.96,42946421],
		["Little Genesee","NY",42.02,-78.23,42946514],
		["West Finley","PA",40,-80.47,42946607],
		["Warren Center","PA",41.93,-76.18,42946700],
		["Machipongo","VA",37.4,-75.9,42946793],
		["Norfolk","VA",36.84,-76.28,42946886],
		["Laurel Fork","VA",36.71,-80.51,42946979],
		["Dixie","WV",38.26,-81.18,42947072],
		["Glasgow","WV",38.2,-81.42,42947165],
		["Henderson","WV",38.83,-82.13,42947258],
		["Chappells","SC",34.18,-81.87,42947351],
		["Pinopolis","SC",33.22,-80.03,42947444],
		["Ona","FL",27.49,-81.92,42947537],
		["Fitzpatrick","AL",32.17,-85.9,42947630],
		["Dana","KY",37.55,-82.69,42947723],
		["Tippecanoe","OH",40.27,-81.28,42947816],
		["Bettsville","OH",41.24,-83.23,42947909],
		["Mc Cutchenville","OH",40.96,-83.26,42948002],
		["Laurel","IA",41.88,-92.92,42948095],
		["Birmingham","IA",40.87,-91.94,42948188],
		["Hawkins","WI",45.51,-90.71,42948281],
		["Emmons","MN",43.5,-93.48,42948374],
		["Ceylon","MN",43.53,-94.63,42948467],
		["Boyd","MN",44.85,-95.9,42948560],
		["Hanley Falls","MN",44.69,-95.61,42948653],
		["Frankfort","SD",44.87,-98.3,42948746],
		["Frederick","SD",45.83,-98.5,42948839],
		["Molt","MT",45.85,-108.94,42948932],
		["Compton","IL",41.69,-89.08,42949025],
		["Oakdale","IL",38.26,-89.5,42949118],
		["Saint Peter","IL",38.86,-88.85,42949211],
		["Williamsburg","MO",38.91,-91.7,42949304],
		["Pottersville","MO",36.69,-92.03,42949397],
		["Strong City","KS",38.39,-96.53,42949490],
		["Lakeview","AR",36.37,-92.53,42949583],
		["Iredell","TX",31.98,-97.87,42949676],
		["Gause","TX",30.78,-96.67,42949769],
		["Los Indios","TX",26.1,-97.65,42949862],
		["Trenton","UT",41.92,-111.92,42949955],
		["Miranda","CA",40.21,-123.86,42950048],
		["Madison","CA",38.67,-121.96,42950141],
		["Days Creek","OR",42.97,-123.06,42950234],
		["Edwall","WA",47.51,-117.92,42950327],
		["Oakesdale","WA",47.12,-117.25,42950420],
		["Danforth","ME",45.66,-67.86,42950512],
		["Ancramdale","NY",42.03,-73.57,42950604],
		["Churubusco","NY",44.95,-73.92,42950696],
		["Fly Creek","NY",42.71,-74.98,42950788],
		["West Leyden","NY",43.46,-75.52,42950880],
		["Sodus Point","NY",43.26,-76.99,42950972],
		["East Millsboro","PA",39.98,-79.96,42951064],
		["Harrison Valley","PA",41.92,-77.67,42951156],
		["Kennedyville","MD",39.3,-75.98,42951248],
		["Greenbush","VA",37.76,-75.67,42951340],
		["Bruno","WV",37.69,-81.88,42951432],
		["Ghent","WV",37.61,-81.09,42951524],
		["Harrellsville","NC",36.3,-76.79,42951616],
		["Avera","GA",33.19,-82.52,42951708],
		["Valhermoso Springs","AL",34.55,-86.69,42951800],
		["Graham","AL",33.46,-85.33,42951892],
		["Dauphin Island","AL",30.25,-88.11,42951984],
		["Laurel Bloomery","TN",36.55,-81.76,42952076],
		["Crowder","MS",34.17,-90.13,42952168],
		["River","KY",37.86,-82.72,42952260],
		["Martinsburg","OH",40.26,-82.35,42952352],
		["Seven Mile","OH",39.47,-84.55,42952444],
		["Mingo","IA",41.76,-93.28,42952536],
		["Stanhope","IA",42.28,-93.79,42952628],
		["Elliott","IA",41.14,-95.16,42952720],
		["Clutier","IA",42.07,-92.4,42952812],
		["Mystic","IA",40.77,-92.94,42952904],
		["Echo","MN",44.62,-95.41,42952996],
		["Lowry","MN",45.7,-95.51,42953088],
		["Chester","SD",43.89,-96.92,42953180],
		["Buffalo","SD",45.58,-103.54,42953272],
		["Fairmount","ND",46.05,-96.6,42953364],
		["Ludlow","IL",40.38,-88.12,42953456],
		["Apple River","IL",42.5,-90.09,42953548],
		["Victoria","IL",41.03,-90.09,42953640],
		["Forest City","IL",40.37,-89.83,42953732],
		["Mount Auburn","IL",39.76,-89.26,42953824],
		["Des Arc","MO",37.28,-90.63,42953916],
		["Cooter","MO",36.04,-89.8,42954008],
		["Silva","MO",37.2,-90.43,42954100],
		["Napoleon","MO",39.13,-94.08,42954192],
		["Morrison","MO",38.67,-91.63,42954284],
		["Lane","KS",38.44,-95.08,42954376],
		["Wetmore","KS",39.63,-95.81,42954468],
		["Chase","KS",38.35,-98.34,42954560],
		["Monroe","NE",41.47,-97.6,42954652],
		["Litchfield","NE",41.15,-99.15,42954744],
		["Orleans","NE",40.13,-99.45,42954836],
		["Mc Caskill","AR",33.91,-93.64,42954928],
		["Deer","AR",35.84,-93.35,42955020],
		["Dill City","OK",35.28,-99.13,42955112],
		["Sparks","OK",35.61,-96.81,42955204],
		["Josephine","TX",33.06,-96.31,42955296],
		["Follett","TX",36.43,-100.14,42955388],
		["Iliff","CO",40.75,-103.06,42955480],
		["Saguache","CO",38.08,-106.14,42955572],
		["Grover","WY",42.69,-110.93,42955664],
		["Bridgeport","CA",38.27,-119.33,42955756],
		["Nicolaus","CA",38.83,-121.58,42955848],
		["Lacrosse","WA",46.73,-117.87,42955940],
		["Beverly","WA",46.87,-119.81,42956032],
		["South Londonderry","VT",43.18,-72.79,42956123],
		["Readsboro","VT",42.77,-72.94,42956214],
		["Newport","NJ",39.28,-75.16,42956305],
		["Medusa","NY",42.45,-74.14,42956396],
		["Roscoe","PA",40.07,-79.86,42956487],
		["Fairhope","PA",39.89,-78.83,42956578],
		["Glyndon","MD",39.47,-76.81,42956669],
		["Red House","VA",37.18,-78.8,42956760],
		["Woolwine","VA",36.78,-80.28,42956851],
		["Speedwell","VA",36.81,-81.16,42956942],
		["Cabins","WV",38.99,-79.2,42957033],
		["Coinjock","NC",36.34,-75.95,42957124],
		["Harkers Island","NC",34.69,-76.55,42957215],
		["De Soto","GA",31.95,-84.06,42957306],
		["Arlington","AL",32.07,-87.56,42957397],
		["Yuma","TN",35.86,-88.37,42957488],
		["Sumner","MS",33.96,-90.36,42957579],
		["Artemus","KY",36.83,-83.84,42957670],
		["Knifley","KY",37.22,-85.14,42957761],
		["East Fultonham","OH",39.85,-82.1,42957852],
		["Craigville","IN",40.8,-85.09,42957943],
		["Turner","MI",44.14,-83.78,42958034],
		["Alleman","IA",41.81,-93.61,42958125],
		["Thompson","IA",43.36,-93.77,42958216],
		["Ventura","IA",43.12,-93.45,42958307],
		["Harshaw","WI",45.66,-89.65,42958398],
		["Cable","WI",46.19,-91.3,42958489],
		["Chandler","MN",43.92,-95.94,42958580],
		["Menno","SD",43.23,-97.57,42958671],
		["Wakpala","SD",45.65,-100.53,42958762],
		["Forman","ND",46.1,-97.63,42958853],
		["South Wilmington","IL",41.17,-88.27,42958944],
		["Millington","IL",41.56,-88.59,42959035],
		["Cabery","IL",40.99,-88.2,42959126],
		["Reddick","IL",41.09,-88.24,42959217],
		["Pearl","IL",39.45,-90.62,42959308],
		["Dudley","MO",36.78,-90.09,42959399],
		["Conception Junction","MO",40.26,-94.69,42959490],
		["Colony","KS",38.07,-95.36,42959581],
		["Dexter","KS",37.17,-96.71,42959672],
		["Wichita","KS",37.68,-97.34,42959763],
		["Odell","NE",40.05,-96.8,42959854],
		["Angola","LA",30.96,-91.6,42959945],
		["Thicket","TX",30.39,-94.63,42960036],
		["Harwood","TX",29.66,-97.48,42960127],
		["Yancey","TX",29.15,-99.13,42960218],
		["Hope","ID",48.26,-116.24,42960309],
		["Brookdale","CA",37.1,-122.11,42960400],
		["Fulton","CA",38.49,-122.77,42960491],
		["Ione","OR",45.49,-119.83,42960582],
		["Adrian","OR",43.59,-117.1,42960673],
		["Warwick","MA",42.68,-72.33,42960763],
		["Saint David","ME",47.31,-68.22,42960853],
		["Rocky Hill","NJ",40.4,-74.63,42960943],
		["Jefferson Valley","NY",41.33,-73.79,42961033],
		["Turin","NY",43.62,-75.4,42961123],
		["Pittsburgh","PA",40.43,-79.97,42961213],
		["Markleton","PA",39.87,-79.29,42961303],
		["Morris","PA",41.65,-77.27,42961393],
		["Avenue","MD",38.27,-76.74,42961483],
		["Comfort","WV",38.12,-81.57,42961573],
		["Smithfield","WV",39.49,-80.56,42961663],
		["Merritt","NC",35.11,-76.69,42961753],
		["The Rock","GA",32.96,-84.24,42961843],
		["Saint Joseph","TN",35.03,-87.49,42961933],
		["Cranks","KY",36.76,-83.17,42962023],
		["Hulen","KY",36.74,-83.53,42962113],
		["Martha","KY",38.01,-82.95,42962203],
		["Mize","KY",37.86,-83.37,42962293],
		["Varney","KY",37.63,-82.42,42962383],
		["Gunlock","KY",37.54,-82.92,42962473],
		["Midvale","OH",40.43,-81.37,42962563],
		["Lucas","IA",41.03,-93.46,42962653],
		["Argyle","IA",40.53,-91.56,42962743],
		["Stockton","IA",41.59,-90.85,42962833],
		["Athelstane","WI",45.43,-88.09,42962923],
		["Mountain","WI",45.18,-88.48,42963013],
		["Tenstrike","MN",47.66,-94.68,42963103],
		["Wentworth","SD",43.99,-96.96,42963193],
		["Bath","SD",45.46,-98.32,42963283],
		["Flasher","ND",46.45,-101.23,42963373],
		["Fort Shaw","MT",47.55,-111.71,42963463],
		["Power","MT",47.69,-111.64,42963553],
		["Ridott","IL",42.29,-89.47,42963643],
		["Dalzell","IL",41.35,-89.17,42963733],
		["Philadelphia","MO",39.83,-91.73,42963823],
		["Bogard","MO",39.45,-93.52,42963913],
		["Blackwater","MO",38.97,-92.99,42964003],
		["Kincaid","KS",38.08,-95.15,42964093],
		["Alta Vista","KS",38.86,-96.48,42964183],
		["Davey","NE",40.98,-96.66,42964273],
		["Union","NE",40.81,-95.92,42964363],
		["Kelly","LA",32,-92.14,42964453],
		["New Blaine","AR",35.33,-93.44,42964543],
		["Natural Dam","AR",35.65,-94.4,42964633],
		["Nemo","TX",32.28,-97.64,42964723],
		["Stonewall","TX",30.2,-98.65,42964813],
		["Abilene","TX",32.45,-99.73,42964903],
		["Fort Garland","CO",37.43,-105.43,42964993],
		["Daggett","CA",34.86,-116.81,42965083],
		["March Air Reserve Base","CA",33.89,-117.27,42965173],
		["Monte Rio","CA",38.45,-123.01,42965263],
		["Elkton","OR",43.64,-123.61,42965353],
		["Sprague","WA",47.25,-118.04,42965443],
		["Chefornak","AK",60.15,-164.06,42965533],
		["Monson","ME",45.28,-69.5,42965622],
		["Glover","VT",44.7,-72.18,42965711],
		["Groveland","NY",42.68,-77.74,42965800],
		["Midway","PA",40.36,-80.29,42965889],
		["Rixford","PA",41.92,-78.45,42965978],
		["Little Meadows","PA",41.99,-76.13,42966067],
		["Whaleyville","MD",38.4,-75.3,42966156],
		["Wake","VA",37.56,-76.42,42966245],
		["Tangier","VA",37.82,-75.99,42966334],
		["Mount Storm","WV",39.27,-79.24,42966423],
		["Shanks","WV",39.26,-78.7,42966512],
		["Black Creek","NC",35.63,-77.93,42966601],
		["Ocracoke","NC",35.12,-75.91,42966690],
		["Jenkinsville","SC",34.25,-81.26,42966779],
		["Troy","SC",33.98,-82.29,42966868],
		["Mercer","TN",35.48,-89.03,42966957],
		["Powderly","KY",37.23,-87.16,42967046],
		["Harrietta","MI",44.3,-85.7,42967135],
		["Klemme","IA",43,-93.6,42967224],
		["Springbrook","WI",45.94,-91.68,42967313],
		["Minneapolis","MN",44.96,-93.26,42967402],
		["Magnolia","MN",43.64,-96.07,42967491],
		["Milan","MN",45.11,-95.91,42967580],
		["Halstad","MN",47.35,-96.82,42967669],
		["Oslo","MN",48.19,-97.12,42967758],
		["Jamestown","ND",46.91,-98.69,42967847],
		["Serena","IL",41.49,-88.75,42967936],
		["Chicago","IL",41.83,-87.68,42968025],
		["Keithsburg","IL",41.1,-90.93,42968114],
		["Maquon","IL",40.79,-90.16,42968203],
		["Topeka","IL",40.32,-89.93,42968292],
		["Hartsburg","IL",40.25,-89.44,42968381],
		["Bosworth","MO",39.46,-93.33,42968470],
		["Emmett","KS",39.3,-96.05,42968559],
		["Kensington","KS",39.76,-99.03,42968648],
		["Linn","KS",39.67,-97.08,42968737],
		["Meadow Grove","NE",42.02,-97.73,42968826],
		["Wilcox","NE",40.36,-99.16,42968915],
		["Ozan","AR",33.84,-93.71,42969004],
		["Almyra","AR",34.4,-91.41,42969093],
		["Griffithville","AR",35.12,-91.64,42969182],
		["Bagwell","TX",33.82,-95.14,42969271],
		["Georgetown","CO",39.71,-105.69,42969360],
		["Dayton","ID",42.14,-111.96,42969449],
		["Williamsburg","NM",33.11,-107.29,42969538],
		["Kneeland","CA",40.67,-123.88,42969627],
		["Idleyld Park","OR",43.26,-122.6,42969716],
		["Condon","OR",45.28,-120.15,42969805],
		["Monitor","WA",47.47,-120.41,42969894],
		["Harrington","WA",47.42,-118.4,42969983],
		["Northport","WA",48.93,-117.77,42970072],
		["Saint Michael","AK",63.47,-162.11,42970161],
		["Silver Lake","NH",43.87,-71.19,42970249],
		["Abbot","ME",45.18,-69.45,42970337],
		["Bremen","ME",44,-69.42,42970425],
		["West Rupert","VT",43.26,-73.19,42970513],
		["East Canaan","CT",42,-73.28,42970601],
		["West Shokan","NY",41.96,-74.28,42970689],
		["Palm","PA",40.43,-75.53,42970777],
		["Friendship","MD",39.3,-77.19,42970865],
		["Stockton","MD",38.05,-75.4,42970953],
		["Nassawadox","VA",37.47,-75.86,42971041],
		["Raysal","WV",37.34,-81.76,42971129],
		["Southside","WV",38.7,-81.99,42971217],
		["Orma","WV",38.74,-81.09,42971305],
		["Harman","WV",38.92,-79.52,42971393],
		["Horner","WV",38.95,-80.36,42971481],
		["Bristol","GA",31.44,-82.21,42971569],
		["Anna Maria","FL",27.53,-82.73,42971657],
		["Clopton","AL",31.6,-85.43,42971745],
		["Pinckard","AL",31.32,-85.54,42971833],
		["Calvert","AL",31.15,-88.01,42971921],
		["Pennington","AL",32.19,-88.05,42972009],
		["Eidson","TN",36.5,-83.08,42972097],
		["Harpster","OH",40.73,-83.25,42972185],
		["Decker","IN",38.51,-87.52,42972273],
		["Muskegon","MI",43.22,-86.25,42972361],
		["Republic","MI",46.4,-87.98,42972449],
		["Churdan","IA",42.15,-94.47,42972537],
		["Prescott","IA",41.02,-94.61,42972625],
		["Ollie","IA",41.19,-92.09,42972713],
		["Tony","WI",45.48,-90.99,42972801],
		["Hartland","MN",43.8,-93.48,42972889],
		["Campbell","MN",46.09,-96.4,42972977],
		["Goodwin","SD",44.87,-96.84,42973065],
		["Henry","SD",44.88,-97.46,42973153],
		["Letcher","SD",43.89,-98.14,42973241],
		["Granville","ND",48.26,-100.84,42973329],
		["Waynesville","IL",40.24,-89.12,42973417],
		["Loraine","IL",40.15,-91.22,42973505],
		["Oakford","IL",40.1,-89.96,42973593],
		["Valier","IL",38.01,-89.04,42973681],
		["Leopold","MO",37.26,-89.91,42973769],
		["Morehouse","MO",36.84,-89.69,42973857],
		["Breckenridge","MO",39.76,-93.8,42973945],
		["Everest","KS",39.67,-95.42,42974033],
		["Scotia","NE",41.46,-98.7,42974121],
		["Leblanc","LA",30.5,-92.94,42974209],
		["Jamestown","LA",32.33,-93.2,42974297],
		["Evergreen","LA",30.95,-92.1,42974385],
		["Adona","AR",35.04,-92.89,42974473],
		["Widener","AR",35.02,-90.68,42974561],
		["Pittsburg","OK",34.71,-95.85,42974649],
		["Shidler","OK",36.78,-96.65,42974737],
		["Groom","TX",35.2,-101.1,42974825],
		["Kinnear","WY",43.15,-108.68,42974913],
		["Tierra Amarilla","NM",36.7,-106.54,42975001],
		["Quemado","NM",34.31,-108.79,42975089],
		["Midpines","CA",37.58,-119.97,42975177],
		["Lexington","OR",45.57,-119.6,42975265],
		["Anderson Island","WA",47.15,-122.7,42975353],
		["Packwood","WA",46.56,-121.55,42975441],
		["Roslyn","WA",47.23,-121,42975529],
		["West Harwich","MA",41.67,-70.11,42975616],
		["Stonington","ME",44.18,-68.67,42975703],
		["Reading","VT",43.47,-72.55,42975790],
		["Middletown Springs","VT",43.48,-73.12,42975877],
		["Albany","VT",44.73,-72.38,42975964],
		["Hamden","NY",42.19,-74.99,42976051],
		["Bowmansville","NY",42.94,-78.68,42976138],
		["Piney Point","MD",38.14,-76.5,42976225],
		["Fort Myer","VA",38.87,-77.07,42976312],
		["Gallagher","WV",38.17,-81.38,42976399],
		["Ashton","WV",38.66,-82.09,42976486],
		["Circleville","WV",38.67,-79.49,42976573],
		["Paint Rock","AL",34.66,-86.32,42976660],
		["Bon Secour","AL",30.31,-87.72,42976747],
		["Glendora","MS",33.82,-90.29,42976834],
		["Calvin","KY",36.72,-83.62,42976921],
		["Scott","OH",40.98,-84.58,42977008],
		["Boggstown","IN",39.56,-85.91,42977095],
		["Leighton","IA",41.33,-92.78,42977182],
		["New Providence","IA",42.28,-93.17,42977269],
		["Alta Vista","IA",43.19,-92.41,42977356],
		["Masonville","IA",42.47,-91.59,42977443],
		["Amberg","WI",45.5,-88,42977530],
		["Fish Creek","WI",45.13,-87.23,42977617],
		["Mercer","WI",46.16,-90.06,42977704],
		["Bruno","MN",46.28,-92.66,42977791],
		["Russell","MN",44.31,-95.94,42977878],
		["Aurora","SD",44.28,-96.68,42977965],
		["Herreid","SD",45.83,-100.07,42978052],
		["Wheatland","ND",46.9,-97.34,42978139],
		["Hysham","MT",46,-107.12,42978226],
		["Baring","MO",40.24,-92.2,42978313],
		["Pilot Knob","MO",37.62,-90.64,42978400],
		["Prague","NE",41.3,-96.8,42978487],
		["Coleridge","NE",42.5,-97.2,42978574],
		["Orchard","NE",42.33,-98.24,42978661],
		["Rosedale","LA",30.44,-91.45,42978748],
		["Story","AR",34.67,-93.56,42978835],
		["Marble Falls","AR",36.08,-93.15,42978922],
		["Velma","OK",34.45,-97.66,42979009],
		["Kenefic","OK",34.14,-96.36,42979096],
		["Boley","OK",35.49,-96.47,42979183],
		["Matador","TX",34.01,-100.82,42979270],
		["South Fork","CO",37.67,-106.64,42979357],
		["Rockland","ID",42.5,-112.85,42979444],
		["Wales","UT",39.44,-111.67,42979531],
		["Counselor","NM",36.13,-106.93,42979618],
		["Anton Chico","NM",35.2,-105.14,42979705],
		["Mc Dermitt","NV",41.87,-117.97,42979792],
		["Davenport","CA",37.09,-122.24,42979879],
		["Tahoe Vista","CA",39.24,-120.05,42979966],
		["Blachly","OR",44.17,-123.57,42980053],
		["Almira","WA",47.72,-118.93,42980140],
		["Marshall","AK",61.87,-162.05,42980227],
		["Sand Point","AK",55.26,-160.66,42980314],
		["Hoonah","AK",58.08,-135.43,42980401],
		["Nicholville","NY",44.71,-74.65,42980487],
		["Atlanta","NY",42.55,-77.47,42980573],
		["West Pittsburg","PA",40.95,-80.36,42980659],
		["Nuremberg","PA",40.93,-76.18,42980745],
		["Funkstown","MD",39.6,-77.7,42980831],
		["Hinton","VA",38.48,-79,42980917],
		["Normantown","WV",38.89,-80.95,42981003],
		["Lorado","WV",37.79,-81.7,42981089],
		["Silverstreet","SC",34.21,-81.71,42981175],
		["Beaufort","SC",32.42,-80.68,42981261],
		["Tarrytown","GA",32.31,-82.55,42981347],
		["Georgetown","FL",29.39,-81.63,42981433],
		["Sycamore","AL",33.26,-86.17,42981519],
		["Vredenburgh","AL",31.82,-87.31,42981605],
		["Tibbie","AL",31.36,-88.24,42981691],
		["Ward","AL",32.29,-88.09,42981777],
		["Riddleton","TN",36.34,-86.03,42981863],
		["Meally","KY",37.79,-82.73,42981949],
		["Dunmor","KY",37.08,-87.01,42982035],
		["Menlo","IA",41.52,-94.4,42982121],
		["Lewis","IA",41.3,-95.08,42982207],
		["Douds","IA",40.8,-92.04,42982293],
		["Cushing","WI",45.56,-92.64,42982379],
		["Saint Hilaire","MN",48.01,-96.21,42982465],
		["Sand Coulee","MT",47.4,-111.09,42982551],
		["Harmon","IL",41.71,-89.55,42982637],
		["Hull","IL",39.7,-91.2,42982723],
		["Scheller","IL",38.16,-89.09,42982809],
		["Willisville","IL",37.98,-89.59,42982895],
		["Belleview","MO",37.68,-90.75,42982981],
		["Westphalia","KS",38.18,-95.49,42983067],
		["Gridley","KS",38.09,-95.88,42983153],
		["Longton","KS",37.37,-96.08,42983239],
		["Garland","NE",40.94,-96.98,42983325],
		["Johnson","NE",40.41,-95.99,42983411],
		["Plymouth","NE",40.3,-96.98,42983497],
		["Dalton","NE",41.4,-102.97,42983583],
		["Thornton","AR",33.77,-92.48,42983669],
		["Fox","AR",35.76,-92.3,42983755],
		["Burlington","TX",30.95,-96.86,42983841],
		["Rowena","TX",31.64,-100.04,42983927],
		["Tivoli","TX",28.43,-96.88,42984013],
		["Corpus Christi","TX",27.8,-97.39,42984099],
		["Blackwell","TX",32.08,-100.31,42984185],
		["Naturita","CO",38.21,-108.56,42984271],
		["Newdale","ID",43.86,-111.42,42984357],
		["Hammett","ID",42.9,-115.56,42984443],
		["Whiterocks","UT",40.55,-109.9,42984529],
		["Sigurd","UT",38.8,-111.88,42984615],
		["Sapello","NM",35.77,-105.25,42984701],
		["Las Vegas","NV",36.16,-115.43,42984787],
		["Genoa","NV",39,-119.84,42984873],
		["Guatay","CA",32.83,-116.58,42984959],
		["Burson","CA",38.18,-120.88,42985045],
		["Vallecito","CA",38.1,-120.43,42985131],
		["Powers","OR",42.84,-124.09,42985217],
		["Fossil","OR",44.9,-120.12,42985303],
		["Humptulips","WA",47.32,-123.91,42985389],
		["Brevig Mission","AK",65.44,-166.56,42985475],
		["Center Sandwich","NH",43.83,-71.47,42985560],
		["Monticello","ME",46.3,-67.84,42985645],
		["Longport","NJ",39.31,-74.52,42985730],
		["Eldred","NY",41.52,-74.88,42985815],
		["Johnsburg","NY",43.51,-73.96,42985900],
		["Lake Clear","NY",44.36,-74.25,42985985],
		["Smithville Flats","NY",42.39,-75.8,42986070],
		["Portageville","NY",42.55,-78.09,42986155],
		["Cokeburg","PA",40.09,-80.06,42986240],
		["White","PA",40.07,-79.42,42986325],
		["Winburne","PA",40.97,-78.15,42986410],
		["White Mills","PA",41.52,-75.2,42986495],
		["Cullen","VA",37.11,-78.65,42986580],
		["Slanesville","WV",39.37,-78.52,42986665],
		["Sarah Ann","WV",37.69,-81.95,42986750],
		["Flatwoods","WV",38.72,-80.65,42986835],
		["Calypso","NC",35.15,-78.1,42986920],
		["Junction City","GA",32.6,-84.45,42987005],
		["Mulberry","TN",35.18,-86.41,42987090],
		["Schlater","MS",33.63,-90.34,42987175],
		["Custer","KY",37.73,-86.25,42987260],
		["Millersburg","KY",38.3,-84.14,42987345],
		["Boons Camp","KY",37.83,-82.7,42987430],
		["Wittensville","KY",37.86,-82.79,42987515],
		["Portland","OH",38.99,-81.81,42987600],
		["Matthews","IN",40.38,-85.49,42987685],
		["Earl Park","IN",40.68,-87.42,42987770],
		["Drummond Island","MI",46,-83.67,42987855],
		["Peru","IA",41.2,-93.95,42987940],
		["Peterson","IA",42.91,-95.34,42988025],
		["Templeton","IA",41.91,-94.94,42988110],
		["Farmersburg","IA",42.95,-91.36,42988195],
		["Meadowlands","MN",47.07,-92.73,42988280],
		["Chokio","MN",45.57,-96.17,42988365],
		["Winger","MN",47.53,-95.98,42988450],
		["Medina","ND",46.89,-99.3,42988535],
		["Selfridge","ND",46.04,-100.92,42988620],
		["Burlington","IL",42.05,-88.54,42988705],
		["Kampsville","IL",39.3,-90.62,42988790],
		["Omaha","IL",37.88,-88.3,42988875],
		["Grubville","MO",38.28,-90.75,42988960],
		["Bolckow","MO",40.11,-94.82,42989045],
		["Kingston","MO",39.64,-94.03,42989130],
		["Nelson","MO",38.99,-93.03,42989215],
		["Cross Timbers","MO",38.02,-93.22,42989300],
		["Golden","MO",36.52,-93.65,42989385],
		["Tecumseh","MO",36.58,-92.28,42989470],
		["Colon","NE",41.29,-96.6,42989555],
		["Creston","NE",41.7,-97.36,42989640],
		["Lyman","NE",41.91,-104.03,42989725],
		["Proctor","OK",35.97,-94.78,42989810],
		["Watson","OK",34.43,-94.55,42989895],
		["Hot Sulphur Springs","CO",40.07,-106.09,42989980],
		["Romeo","CO",37.17,-105.98,42990065],
		["Mesa","CO",39.17,-108.13,42990150],
		["Sweet","ID",44.01,-116.33,42990235],
		["Plymouth","UT",41.91,-112.12,42990320],
		["San Simon","AZ",32,-109.21,42990405],
		["Volcano","CA",38.48,-120.57,42990490],
		["Lakehead","CA",40.88,-122.38,42990575],
		["Tygh Valley","OR",45.22,-121.26,42990660],
		["Chesterfield","MA",42.4,-72.85,42990744],
		["East Templeton","MA",42.53,-72.05,42990828],
		["North Truro","MA",42.04,-70.09,42990912],
		["Wentworth","NH",43.87,-71.91,42990996],
		["Brooksville","ME",44.34,-68.68,42991080],
		["Killington","VT",43.67,-72.77,42991164],
		["Spraggs","PA",39.78,-80.22,42991248],
		["Big Run","PA",40.96,-78.87,42991332],
		["Robinson","PA",40.41,-79.13,42991416],
		["Vintondale","PA",40.47,-78.91,42991500],
		["Coburn","PA",40.85,-77.49,42991584],
		["Grassflat","PA",41.01,-78.11,42991668],
		["Walnut Bottom","PA",40.09,-77.37,42991752],
		["Pitman","PA",40.7,-76.51,42991836],
		["Wycombe","PA",40.28,-75.01,42991920],
		["Modena","PA",39.96,-75.8,42992004],
		["Oaks","PA",40.13,-75.45,42992088],
		["Barclay","MD",39.14,-75.86,42992172],
		["Herndon","WV",37.51,-81.36,42992256],
		["Bickmore","WV",38.37,-81.1,42992340],
		["Bolt","WV",37.76,-81.41,42992424],
		["Mc Adenville","NC",35.26,-81.08,42992508],
		["Ebro","FL",30.44,-85.88,42992592],
		["Forest Hills","KY",37.63,-82.29,42992676],
		["Wheelwright","KY",37.33,-82.72,42992760],
		["Wilkesville","OH",39.07,-82.32,42992844],
		["Russellville","IN",39.85,-86.98,42992928],
		["Owensburg","IN",38.94,-86.75,42993012],
		["Carp Lake","MI",45.69,-84.79,42993096],
		["De Tour Village","MI",45.99,-83.9,42993180],
		["Malcom","IA",41.7,-92.55,42993264],
		["Reasnor","IA",41.57,-93.02,42993348],
		["Ute","IA",42.04,-95.7,42993432],
		["Martelle","IA",42.02,-91.35,42993516],
		["Kilkenny","MN",44.31,-93.57,42993600],
		["Nelson","MN",45.88,-95.26,42993684],
		["Climax","MN",47.6,-96.81,42993768],
		["Norris","SD",43.46,-101.2,42993852],
		["Presho","SD",43.9,-100.05,42993936],
		["Biggsville","IL",40.85,-90.86,42994020],
		["Rio","IL",41.1,-90.39,42994104],
		["Lowpoint","IL",40.87,-89.38,42994188],
		["Armington","IL",40.33,-89.31,42994272],
		["Latham","IL",39.96,-89.16,42994356],
		["Easton","IL",40.23,-89.84,42994440],
		["Simpson","IL",37.46,-88.75,42994524],
		["High Hill","MO",38.87,-91.37,42994608],
		["Zalma","MO",37.14,-90.08,42994692],
		["Armstrong","MO",39.26,-92.7,42994776],
		["Baileyville","KS",39.84,-96.18,42994860],
		["Robinson","KS",39.81,-95.41,42994944],
		["Rosalia","KS",37.78,-96.6,42995028],
		["Avoca","NE",40.79,-96.11,42995112],
		["Bradshaw","NE",40.88,-97.74,42995196],
		["Waco","NE",40.89,-97.46,42995280],
		["Cedar Rapids","NE",41.55,-98.14,42995364],
		["Pilger","NE",42,-97.05,42995448],
		["Pollard","AR",36.43,-90.26,42995532],
		["Ozone","AR",35.67,-93.43,42995616],
		["Lefors","TX",35.43,-100.8,42995700],
		["Hillrose","CO",40.32,-103.52,42995784],
		["Summerland","CA",34.41,-119.59,42995868],
		["Lockwood","CA",35.99,-121.06,42995952],
		["Pope Valley","CA",38.67,-122.44,42996036],
		["Laupahoehoe","HI",19.97,-155.24,42996120],
		["Albion","RI",41.95,-71.45,42996203],
		["East Dorset","VT",43.24,-72.99,42996286],
		["Mount Holly","VT",43.43,-72.78,42996369],
		["West Danville","VT",44.46,-72.22,42996452],
		["New Baltimore","NY",42.45,-73.79,42996535],
		["Hartford","NY",43.35,-73.4,42996618],
		["Spangler","PA",40.64,-78.77,42996701],
		["Jerome","PA",40.21,-78.98,42996784],
		["Gratz","PA",40.6,-76.71,42996867],
		["Big Cove Tannery","PA",39.84,-78.05,42996950],
		["Dry Run","PA",40.17,-77.76,42997033],
		["Klingerstown","PA",40.66,-76.62,42997116],
		["Rehrersburg","PA",40.43,-76.25,42997199],
		["Spurlockville","WV",38.07,-81.99,42997282],
		["Glen Fork","WV",37.65,-81.52,42997365],
		["Burton","WV",39.66,-80.39,42997448],
		["Falcon","NC",35.19,-78.64,42997531],
		["Arcadia","SC",34.95,-81.99,42997614],
		["Hollister","FL",29.62,-81.81,42997697],
		["Beaverton","AL",33.93,-88.02,42997780],
		["Belfast","TN",35.4,-86.71,42997863],
		["Silver City","MS",33.09,-90.49,42997946],
		["Jeremiah","KY",37.16,-82.92,42998029],
		["Knob Lick","KY",37.08,-85.68,42998112],
		["Owensville","OH",39.12,-84.13,42998195],
		["Troy","IN",38,-86.81,42998278],
		["Hymera","IN",39.18,-87.29,42998361],
		["Idlewild","MI",43.88,-85.8,42998444],
		["Caspian","MI",46.06,-88.62,42998527],
		["Joice","IA",43.36,-93.45,42998610],
		["Kensett","IA",43.35,-93.21,42998693],
		["Ellsworth","MN",43.52,-96.01,42998776],
		["Taunton","MN",44.59,-96.06,42998859],
		["Wahkon","MN",46.11,-93.52,42998942],
		["Richville","MN",46.5,-95.62,42999025],
		["Wakonda","SD",43,-97.1,42999108],
		["Geddes","SD",43.25,-98.69,42999191],
		["Pembina","ND",48.96,-97.24,42999274],
		["Baldwin","ND",46.97,-100.75,42999357],
		["Carson","ND",46.42,-101.57,42999440],
		["Strasburg","ND",46.13,-100.16,42999523],
		["Wilsall","MT",46.06,-110.59,42999606],
		["Ellsworth","IL",40.44,-88.71,42999689],
		["Soldier","KS",39.53,-95.96,42999772],
		["Vassar","KS",38.67,-95.56,42999855],
		["Bronson","KS",37.89,-95.07,42999938],
		["Galesburg","KS",37.47,-95.35,43000021],
		["Atlanta","KS",37.43,-96.76,43000104],
		["Delphos","KS",39.27,-97.76,43000187],
		["Glasco","KS",39.36,-97.83,43000270],
		["Bird City","KS",39.74,-101.53,43000353],
		["Lincoln","NE",40.81,-96.68,43000436],
		["Potter","NE",41.21,-103.31,43000519],
		["Solgohachia","AR",35.27,-92.67,43000602],
		["Cash","AR",35.79,-90.93,43000685],
		["Whitefield","OK",35.25,-95.23,43000768],
		["Marland","OK",36.56,-97.15,43000851],
		["Crestone","CO",37.99,-105.69,43000934],
		["Farmington","NM",36.75,-108.18,43001017],
		["Rowe","NM",35.49,-105.67,43001100],
		["San Jose","NM",36.03,-106.09,43001183],
		["Chamberino","NM",31.97,-106.96,43001266],
		["Fiddletown","CA",38.52,-120.7,43001349],
		["Whitmore","CA",40.63,-121.87,43001432],
		["Silver Lake","OR",43.18,-120.96,43001515],
		["Garfield","WA",46.99,-117.16,43001598],
		["Oakland","RI",41.96,-71.64,43001680],
		["Cavendish","VT",43.38,-72.62,43001762],
		["Morgan","VT",44.89,-71.96,43001844],
		["Cornwallville","NY",42.36,-74.17,43001926],
		["Pitcher","NY",42.58,-75.86,43002008],
		["Celoron","NY",42.1,-79.27,43002090],
		["Pine Valley","NY",42.22,-76.84,43002172],
		["Grand Valley","PA",41.72,-79.54,43002254],
		["Ellerslie","MD",39.72,-78.77,43002336],
		["Avondale","WV",37.41,-81.8,43002418],
		["Kistler","WV",37.76,-81.85,43002500],
		["Fenwick","WV",38.25,-80.64,43002582],
		["Durham","NC",35.98,-78.91,43002664],
		["Rocky Ford","GA",32.66,-81.82,43002746],
		["Perkins","GA",32.9,-81.95,43002828],
		["Elrod","AL",33.36,-87.8,43002910],
		["Mansfield","TN",36.18,-88.28,43002992],
		["Essie","KY",37.06,-83.45,43003074],
		["Redfox","KY",37.21,-82.94,43003156],
		["North Hampton","OH",39.99,-83.94,43003238],
		["Fertile","IA",43.26,-93.42,43003320],
		["Ringsted","IA",43.29,-94.5,43003402],
		["Galva","IA",42.5,-95.41,43003484],
		["Luana","IA",43.06,-91.45,43003566],
		["Ladora","IA",41.75,-92.18,43003648],
		["Forbes","MN",47.28,-92.61,43003730],
		["Puposky","MN",47.67,-94.9,43003812],
		["Mentor","MN",47.69,-96.14,43003894],
		["New Effington","SD",45.85,-96.91,43003976],
		["Stickney","SD",43.58,-98.43,43004058],
		["Neche","ND",48.98,-97.55,43004140],
		["Saco","MT",48.58,-107.42,43004222],
		["Jefferson City","MT",46.35,-112.17,43004304],
		["Beaverville","IL",40.95,-87.65,43004386],
		["Buckingham","IL",41.04,-88.17,43004468],
		["Matherville","IL",41.25,-90.6,43004550],
		["La Fayette","IL",41.1,-89.97,43004632],
		["Dewey","IL",40.3,-88.26,43004714],
		["Livingston","IL",38.96,-89.76,43004796],
		["Owaneco","IL",39.48,-89.19,43004878],
		["Macedonia","IL",38.05,-88.7,43004960],
		["Corder","MO",39.09,-93.63,43005042],
		["Cainsville","MO",40.44,-93.77,43005124],
		["Lawrence","NE",40.29,-98.25,43005206],
		["Bonita","LA",32.91,-91.67,43005288],
		["Carter","OK",35.21,-99.5,43005370],
		["Billings","OK",36.53,-97.44,43005452],
		["Era","TX",33.49,-97.3,43005534],
		["Delmita","TX",26.64,-98.4,43005616],
		["Dell City","TX",31.93,-105.2,43005698],
		["Swink","CO",38.01,-103.62,43005780],
		["Albion","ID",42.38,-113.6,43005862],
		["Lemon Cove","CA",36.46,-118.98,43005944],
		["Bodega Bay","CA",38.35,-123.06,43006026],
		["Wasco","OR",45.59,-120.63,43006108],
		["Ronald","WA",47.25,-121.05,43006190],
		["Naknek","AK",58.8,-156.73,43006272],
		["Kiana","AK",66.97,-160.43,43006354],
		["Wendell","MA",42.55,-72.4,43006435],
		["Block Island","RI",41.16,-71.58,43006516],
		["Sullivan","NH",43.01,-72.21,43006597],
		["Robbinston","ME",45.06,-67.16,43006678],
		["East Worcester","NY",42.61,-74.67,43006759],
		["Youngsville","NY",41.8,-74.88,43006840],
		["Putnam Station","NY",43.75,-73.41,43006921],
		["Center Cross","VA",37.8,-76.77,43007002],
		["New Milton","WV",39.17,-80.71,43007083],
		["Hundred","WV",39.68,-80.45,43007164],
		["Fargo","GA",30.68,-82.56,43007245],
		["Neely","MS",31.16,-88.75,43007326],
		["Union Church","MS",31.7,-90.82,43007407],
		["Scalf","KY",36.91,-83.7,43007488],
		["Kansas","OH",41.21,-83.32,43007569],
		["Rhodes","IA",41.92,-93.18,43007650],
		["Lakota","IA",43.37,-94.09,43007731],
		["Holland","IA",42.39,-92.79,43007812],
		["Massena","IA",41.25,-94.76,43007893],
		["Boulder Junction","WI",46.11,-89.63,43007974],
		["Lewisville","MN",43.92,-94.43,43008055],
		["Round Lake","MN",43.53,-95.46,43008136],
		["Rochert","MN",46.86,-95.68,43008217],
		["Plummer","MN",47.91,-96.03,43008298],
		["Conde","SD",45.15,-98.09,43008379],
		["Hoven","SD",45.24,-99.77,43008460],
		["Clyde Park","MT",45.86,-110.65,43008541],
		["Smithfield","IL",40.47,-90.29,43008622],
		["Penfield","IL",40.31,-87.96,43008703],
		["Camargo","IL",39.79,-88.16,43008784],
		["Hamel","IL",38.88,-89.84,43008865],
		["Weatherby","MO",39.9,-94.24,43008946],
		["Belvue","KS",39.21,-96.17,43009027],
		["Protection","KS",37.2,-99.48,43009108],
		["Decatur","NE",42,-96.25,43009189],
		["Sumner","NE",40.94,-99.5,43009270],
		["Belcher","LA",32.75,-93.83,43009351],
		["Prim","AR",35.69,-92.14,43009432],
		["Peggs","OK",36.08,-95.1,43009513],
		["Hodgen","OK",34.83,-94.62,43009594],
		["Deweyville","TX",30.3,-93.75,43009675],
		["Trent","TX",32.48,-100.12,43009756],
		["Meeteetse","WY",44.15,-108.87,43009837],
		["Paris","ID",42.22,-111.43,43009918],
		["San Antonio","NM",33.91,-106.86,43009999],
		["Cedarville","CA",41.48,-120.14,43010080],
		["Cinebar","WA",46.59,-122.51,43010161],
		["Napavine","WA",46.57,-122.9,43010242],
		["Galena","AK",64.74,-156.85,43010323],
		["Skagway","AK",59.57,-135.35,43010404],
		["Boston","MA",42.35,-71.06,43010484],
		["Hebron","NH",43.69,-71.8,43010564],
		["Peaks Island","ME",43.66,-70.18,43010644],
		["East Barre","VT",44.15,-72.44,43010724],
		["Stockbridge","VT",43.78,-72.77,43010804],
		["Athol","NY",43.48,-73.89,43010884],
		["Stratford","NY",43.18,-74.68,43010964],
		["Delancey","NY",42.2,-74.97,43011044],
		["Flinton","PA",40.7,-78.47,43011124],
		["Delaware Water Gap","PA",40.97,-75.13,43011204],
		["Oxford","MD",38.68,-76.17,43011284],
		["Deal Island","MD",38.15,-75.94,43011364],
		["Charlestown","MD",39.57,-75.97,43011444],
		["Dolphin","VA",36.83,-77.79,43011524],
		["Ripplemead","VA",37.38,-80.63,43011604],
		["Ceres","VA",37.01,-81.35,43011684],
		["Pocahontas","VA",37.3,-81.34,43011764],
		["Breeden","WV",37.92,-82.27,43011844],
		["Hillsboro","GA",33.17,-83.7,43011924],
		["Berlin","GA",31.06,-83.62,43012004],
		["Mc Andrews","KY",37.54,-82.28,43012084],
		["Allen","KY",37.61,-82.72,43012164],
		["Betsy Layne","KY",37.55,-82.63,43012244],
		["Lafayette","OH",40.75,-83.94,43012324],
		["Harsens Island","MI",42.59,-82.57,43012404],
		["Greenbush","MI",44.54,-83.35,43012484],
		["Merritt","MI",44.31,-84.93,43012564],
		["Hawks","MI",45.3,-83.9,43012644],
		["Plymouth","IA",43.24,-93.12,43012724],
		["Lehigh","IA",42.35,-94.05,43012804],
		["Thurman","IA",40.81,-95.74,43012884],
		["Waterville","IA",43.2,-91.29,43012964],
		["Brandon","IA",42.31,-92,43013044],
		["Butte Des Morts","WI",44.09,-88.65,43013124],
		["Hollandale","MN",43.75,-93.2,43013204],
		["Kiester","MN",43.53,-93.71,43013284],
		["Wilmont","MN",43.76,-95.82,43013364],
		["Bellingham","MN",45.13,-96.28,43013444],
		["Garrison","MN",46.3,-93.82,43013524],
		["Volin","SD",42.95,-97.18,43013604],
		["Tulare","SD",44.73,-98.5,43013684],
		["Ridgeview","SD",45.08,-100.8,43013764],
		["Dutton","MT",47.88,-111.75,43013844],
		["Simms","MT",47.43,-111.96,43013924],
		["Ransom","IL",41.15,-88.65,43014004],
		["Batchtown","IL",39.03,-90.65,43014084],
		["Cutler","IL",38.03,-89.56,43014164],
		["Smithboro","IL",38.89,-89.34,43014244],
		["Olive Branch","IL",37.16,-89.36,43014324],
		["Taylor","MO",39.93,-91.52,43014404],
		["Morley","MO",37.04,-89.61,43014484],
		["Bronaugh","MO",37.69,-94.46,43014564],
		["Rhineland","MO",38.71,-91.51,43014644],
		["Circleville","KS",39.5,-95.85,43014724],
		["Rexford","KS",39.47,-100.74,43014804],
		["Craig","NE",41.78,-96.36,43014884],
		["Flatwoods","LA",31.4,-92.86,43014964],
		["Norphlet","AR",33.31,-92.66,43015044],
		["Poughkeepsie","AR",36.08,-91.43,43015124],
		["Grant","OK",33.93,-95.52,43015204],
		["Auburn","WY",42.79,-111,43015284],
		["Rancho Santa Fe","CA",33.05,-117.19,43015364],
		["Blue River","OR",44.05,-122.05,43015444],
		["Tenmile","OR",43.1,-123.53,43015524],
		["Lummi Island","WA",48.69,-122.66,43015604],
		["Rice","WA",48.42,-118.11,43015684],
		["Saint Paul Island","AK",57.18,-170.26,43015764],
		["North Hero","VT",44.82,-73.28,43015843],
		["Roxbury","VT",44.1,-72.73,43015922],
		["Avalon","NJ",39.09,-74.73,43016001],
		["New Gretna","NJ",39.71,-74.52,43016080],
		["Bearsville","NY",42.01,-74.17,43016159],
		["West Park","NY",41.78,-73.96,43016238],
		["Lorraine","NY",43.74,-75.85,43016317],
		["Star Lake","NY",44.16,-75.03,43016396],
		["Black Creek","NY",42.29,-78.24,43016475],
		["Jennerstown","PA",40.16,-79.06,43016554],
		["Gordon","PA",40.75,-76.34,43016633],
		["Sheppton","PA",40.9,-76.11,43016712],
		["Trumbauersville","PA",40.41,-75.37,43016791],
		["Dahlgren","VA",38.33,-77.05,43016870],
		["Wilsons","VA",37.14,-77.85,43016949],
		["Berwind","WV",37.26,-81.66,43017028],
		["Volga","WV",39.06,-80.11,43017107],
		["Idamay","WV",39.49,-80.23,43017186],
		["Coleman","FL",28.8,-82.06,43017265],
		["Forest Home","AL",31.86,-86.84,43017344],
		["Safford","AL",32.29,-87.35,43017423],
		["Partridge","KY",37,-82.89,43017502],
		["Blackey","KY",37.14,-82.97,43017581],
		["Hollansburg","OH",39.99,-84.79,43017660],
		["Phillipsburg","OH",39.9,-84.4,43017739],
		["Cannelburg","IN",38.66,-86.99,43017818],
		["Ambia","IN",40.48,-87.51,43017897],
		["Garden","MI",45.77,-86.55,43017976],
		["Bagley","IA",41.84,-94.42,43018055],
		["Pierson","IA",42.54,-95.86,43018134],
		["Auburn","IA",42.24,-94.87,43018213],
		["Castalia","IA",43.11,-91.67,43018292],
		["Townsend","WI",45.33,-88.58,43018371],
		["Willard","WI",44.72,-90.73,43018450],
		["Phelps","WI",46.06,-89.08,43018529],
		["Brookston","MN",46.86,-92.6,43018608],
		["Beaver Creek","MN",43.61,-96.36,43018687],
		["Garvin","MN",44.21,-95.76,43018766],
		["Jeffers","MN",44.05,-95.19,43018845],
		["Leeds","ND",48.28,-99.43,43018924],
		["Nashua","MT",48.27,-106.27,43019003],
		["West Brooklyn","IL",41.69,-89.14,43019082],
		["Kilbourne","IL",40.15,-90,43019161],
		["Thayer","IL",39.53,-89.75,43019240],
		["Irvington","IL",38.43,-89.16,43019319],
		["Dawn","MO",39.66,-93.63,43019398],
		["Dadeville","MO",37.47,-93.67,43019477],
		["Cummings","KS",39.46,-95.25,43019556],
		["Cleveland","AR",35.36,-92.7,43019635],
		["Patterson","AR",35.25,-91.23,43019714],
		["Desha","AR",35.73,-91.67,43019793],
		["Fort Supply","OK",36.57,-99.57,43019872],
		["Snook","TX",30.49,-96.46,43019951],
		["Port O Connor","TX",28.44,-96.4,43020030],
		["Christine","TX",28.78,-98.49,43020109],
		["Ben Bolt","TX",27.63,-98.08,43020188],
		["Mirando City","TX",27.43,-98.93,43020267],
		["Channing","TX",35.68,-102.33,43020346],
		["Diamondville","WY",41.77,-110.53,43020425],
		["Manila","UT",40.94,-109.79,43020504],
		["Cerrillos","NM",35.37,-106.14,43020583],
		["Bieber","CA",41.06,-121.11,43020662],
		["Mapleton","OR",44,-123.84,43020741],
		["Imbler","OR",45.47,-117.96,43020820],
		["Clallam Bay","WA",48.17,-124.53,43020899],
		["Tununak","AK",60.6,-165.13,43020978],
		["Goshen","NH",43.3,-72.14,43021056],
		["Old Chatham","NY",42.42,-73.56,43021134],
		["Preston Hollow","NY",42.44,-74.2,43021212],
		["Chase Mills","NY",44.86,-75.07,43021290],
		["Addison","PA",39.74,-79.33,43021368],
		["Hibbs","PA",39.91,-79.87,43021446],
		["Cassville","PA",40.29,-78.02,43021524],
		["Montandon","PA",40.96,-76.86,43021602],
		["Dover Afb","DE",39.11,-75.46,43021680],
		["Dameron","MD",38.15,-76.36,43021758],
		["Huntly","VA",38.8,-78.14,43021836],
		["Hudgins","VA",37.47,-76.32,43021914],
		["Nelson","VA",36.55,-78.67,43021992],
		["Boomer","WV",38.15,-81.27,43022070],
		["Hernshaw","WV",38.2,-81.61,43022148],
		["Camden","WV",39.09,-80.61,43022226],
		["Fairfield","NC",35.57,-76.24,43022304],
		["Atlantic","NC",34.89,-76.33,43022382],
		["Rhodhiss","NC",35.77,-81.43,43022460],
		["Cherry Log","GA",34.78,-84.45,43022538],
		["Marianna","FL",30.77,-85.23,43022616],
		["Sharon Grove","KY",36.94,-87.09,43022694],
		["Amlin","OH",40.07,-83.18,43022772],
		["Dexter City","OH",39.65,-81.47,43022850],
		["Graysville","OH",39.66,-81.17,43022928],
		["Stockwell","IN",40.25,-86.78,43023006],
		["Westgate","IA",42.76,-91.99,43023084],
		["Cumberland","IA",41.27,-94.86,43023162],
		["Alvord","IA",43.34,-96.3,43023240],
		["Libertyville","IA",40.95,-92.04,43023318],
		["Ruthton","MN",44.17,-96.1,43023396],
		["Clitherall","MN",46.27,-95.62,43023474],
		["Bullhead","SD",45.76,-101.08,43023552],
		["Hazelton","ND",46.48,-100.27,43023630],
		["Geraldine","MT",47.69,-110.2,43023708],
		["Weldon","IL",40.12,-88.75,43023786],
		["Black","MO",37.53,-90.93,43023864],
		["Jacksonville","MO",39.58,-92.47,43023942],
		["Arcadia","KS",37.64,-94.62,43024020],
		["Brewster","KS",39.36,-101.37,43024098],
		["Cook","NE",40.51,-96.16,43024176],
		["Brainard","NE",41.18,-97,43024254],
		["Carroll","NE",42.27,-97.19,43024332],
		["Dennard","AR",35.72,-92.58,43024410],
		["Duke","OK",34.67,-99.58,43024488],
		["Lehigh","OK",34.46,-96.22,43024566],
		["New Summerfield","TX",31.98,-95.1,43024644],
		["Leona","TX",31.15,-95.97,43024722],
		["Paint Rock","TX",31.5,-99.92,43024800],
		["Jayton","TX",33.25,-100.57,43024878],
		["Yampa","CO",40.15,-106.9,43024956],
		["Riggins","ID",45.39,-116.47,43025034],
		["Mayfield","UT",39.1,-111.71,43025112],
		["Bicknell","UT",38.33,-111.55,43025190],
		["Parks","AZ",35.22,-111.89,43025268],
		["Polvadera","NM",34.13,-106.85,43025346],
		["Alamogordo","NM",32.88,-105.95,43025424],
		["High Rolls Mountain Park","NM",32.97,-105.79,43025502],
		["Yolo","CA",38.73,-121.81,43025580],
		["Makaweli","HI",21.94,-159.66,43025658],
		["Napakiak","AK",60.67,-162.01,43025736],
		["Kake","AK",56.79,-134.19,43025814],
		["Otis","MA",42.19,-73.07,43025891],
		["West Dennis","MA",41.66,-70.16,43025968],
		["Marlow","NH",43.11,-72.2,43026045],
		["Alna","ME",44.1,-69.6,43026122],
		["Vershire","VT",43.97,-72.32,43026199],
		["Grafton","VT",43.16,-72.61,43026276],
		["Eden Mills","VT",44.69,-72.48,43026353],
		["Heislerville","NJ",39.24,-74.99,43026430],
		["Rosenhayn","NJ",39.47,-75.13,43026507],
		["East Marion","NY",41.12,-72.34,43026584],
		["Connelly","NY",41.89,-73.99,43026661],
		["Forestburgh","NY",41.54,-74.69,43026738],
		["Breesport","NY",42.17,-76.73,43026815],
		["Claridge","PA",40.32,-79.65,43026892],
		["East Vandergrift","PA",40.59,-79.56,43026969],
		["Erie","PA",42.12,-80.08,43027046],
		["Crumpton","MD",39.23,-75.91,43027123],
		["Little Orleans","MD",39.62,-78.38,43027200],
		["Warfield","VA",36.89,-77.74,43027277],
		["Pilgrims Knob","VA",37.29,-81.9,43027354],
		["Little Rock","SC",34.47,-79.4,43027431],
		["Black","AL",31,-85.74,43027508],
		["Catherine","AL",32.18,-87.46,43027585],
		["Alligator","MS",34.08,-90.72,43027662],
		["Arcola","MS",33.27,-90.88,43027739],
		["Rose Hill","MS",32.13,-89.01,43027816],
		["Mackville","KY",37.73,-85.06,43027893],
		["Drift","KY",37.48,-82.74,43027970],
		["Glen Arbor","MI",44.85,-86.04,43028047],
		["Davis City","IA",40.64,-93.81,43028124],
		["Clare","IA",42.58,-94.34,43028201],
		["Grandview","IA",41.27,-91.18,43028278],
		["Fifield","WI",45.87,-90.42,43028355],
		["Ghent","MN",44.51,-95.89,43028432],
		["Leonard","MN",47.65,-95.26,43028509],
		["Harrold","SD",44.52,-99.73,43028586],
		["Sheyenne","ND",47.82,-99.11,43028663],
		["Aroma Park","IL",41.07,-87.8,43028740],
		["Hoffman","IL",38.53,-89.26,43028817],
		["Chesterfield","IL",39.25,-90.06,43028894],
		["Truxton","MO",39,-91.24,43028971],
		["Grantville","KS",39.08,-95.56,43029048],
		["Merna","NE",41.48,-99.76,43029125],
		["Bladen","NE",40.32,-98.59,43029202],
		["Scotland","AR",35.52,-92.66,43029279],
		["Witter","AR",35.93,-93.68,43029356],
		["Marble City","OK",35.58,-94.81,43029433],
		["Votaw","TX",30.33,-94.56,43029510],
		["Midfield","TX",28.94,-96.25,43029587],
		["Alma","CO",39.28,-106.06,43029664],
		["Bethune","CO",39.3,-102.42,43029741],
		["Arapahoe","WY",42.97,-108.47,43029818],
		["King Hill","ID",43.07,-115.19,43029895],
		["Tacna","AZ",32.68,-113.97,43029972],
		["Acme","WA",48.7,-122.19,43030049],
		["Plainfield","MA",42.51,-72.91,43030125],
		["Mattawamkeag","ME",45.51,-68.35,43030201],
		["Spruce Head","ME",44.01,-69.17,43030277],
		["Strafford","VT",43.87,-72.38,43030353],
		["East Wallingford","VT",43.43,-72.87,43030429],
		["Whiting","VT",43.87,-73.21,43030505],
		["Brant Lake","NY",43.71,-73.69,43030581],
		["Bouckville","NY",42.88,-75.55,43030657],
		["Harwick","PA",40.56,-79.8,43030733],
		["East Butler","PA",40.87,-79.84,43030809],
		["Rouseville","PA",41.47,-79.68,43030885],
		["Dysart","PA",40.6,-78.5,43030961],
		["Fannettsburg","PA",40.05,-77.83,43031037],
		["Viola","DE",39.04,-75.57,43031113],
		["Star Tannery","VA",39.08,-78.45,43031189],
		["Selma","VA",37.79,-79.87,43031265],
		["Gary","WV",37.36,-81.53,43031341],
		["Kopperston","WV",37.74,-81.55,43031417],
		["Verner","WV",37.63,-81.85,43031493],
		["Fairdale","WV",37.78,-81.39,43031569],
		["Durbin","WV",38.54,-79.82,43031645],
		["Valley Bend","WV",38.79,-79.92,43031721],
		["Arthurdale","WV",39.49,-79.82,43031797],
		["Exchange","WV",38.79,-80.76,43031873],
		["Gloucester","NC",34.72,-76.54,43031949],
		["Dickinson","AL",31.76,-87.71,43032025],
		["Excel","AL",31.42,-87.34,43032101],
		["Saltillo","TN",35.37,-88.21,43032177],
		["Sulphur","KY",38.48,-85.26,43032253],
		["Mount Sherman","KY",37.43,-85.62,43032329],
		["Homestead","IA",41.76,-91.86,43032405],
		["Oakville","IA",41.09,-91.04,43032481],
		["Marble","MN",47.32,-93.29,43032557],
		["Hazel","SD",44.75,-97.38,43032633],
		["Reliance","SD",43.88,-99.6,43032709],
		["Little Eagle","SD",45.68,-100.79,43032785],
		["Keystone","SD",43.89,-103.42,43032861],
		["Wimbledon","ND",47.17,-98.45,43032937],
		["Table Grove","IL",40.36,-90.42,43033013],
		["Baylis","IL",39.72,-90.9,43033089],
		["New Holland","IL",40.18,-89.58,43033165],
		["Canalou","MO",36.75,-89.68,43033241],
		["Blue Mound","KS",38.08,-95,43033317],
		["Walnut","KS",37.6,-95.07,43033393],
		["Ayr","NE",40.43,-98.44,43033469],
		["Transylvania","LA",32.66,-91.25,43033545],
		["Humnoke","AR",34.54,-91.75,43033621],
		["Beech Grove","AR",36.16,-90.61,43033697],
		["Black Oak","AR",35.83,-90.36,43033773],
		["Harriet","AR",35.98,-92.49,43033849],
		["Long Branch","TX",32.04,-94.5,43033925],
		["Milam","TX",31.48,-93.86,43034001],
		["Hedley","TX",34.86,-100.65,43034077],
		["Timnath","CO",40.52,-104.98,43034153],
		["Colorado Springs","CO",38.92,-104.67,43034229],
		["Mosca","CO",37.65,-105.88,43034305],
		["Story","WY",44.6,-107.11,43034381],
		["Freedom","WY",42.98,-111.04,43034457],
		["Howe","ID",43.92,-113.1,43034533],
		["Tropic","UT",37.69,-112.03,43034609],
		["Lagunitas","CA",38.03,-122.7,43034685],
		["Easton","WA",47.35,-121.27,43034761],
		["East Baldwin","ME",43.88,-70.69,43034836],
		["Saint Agatha","ME",47.24,-68.31,43034911],
		["White Lake","NY",41.67,-74.82,43034986],
		["Bridgewater","NY",42.87,-75.25,43035061],
		["Rowe","VA",37.12,-82.03,43035136],
		["Smithers","WV",38.17,-81.3,43035211],
		["Winifrede","WV",38.16,-81.56,43035286],
		["Looneyville","WV",38.66,-81.29,43035361],
		["Wallback","WV",38.57,-81.08,43035436],
		["Anmoore","WV",39.25,-80.29,43035511],
		["Linn","WV",38.96,-80.71,43035586],
		["Eglon","WV",39.29,-79.51,43035661],
		["Grassy Creek","NC",36.56,-81.4,43035736],
		["Deer Park","AL",31.21,-88.31,43035811],
		["Mobile","AL",30.68,-88.04,43035886],
		["Chavies","KY",37.34,-83.35,43035961],
		["Burna","KY",37.21,-88.4,43036036],
		["Middleburg","KY",37.35,-84.81,43036111],
		["Jerry City","OH",41.25,-83.6,43036186],
		["Knightsville","IN",39.52,-87.08,43036261],
		["Staunton","IN",39.48,-87.18,43036336],
		["Douglas","MI",42.64,-86.21,43036411],
		["Woodburn","IA",41.01,-93.59,43036486],
		["Fenton","IA",43.21,-94.42,43036561],
		["Dickens","IA",43.13,-95.02,43036636],
		["Terril","IA",43.3,-94.96,43036711],
		["Defiance","IA",41.82,-95.33,43036786],
		["Okauchee","WI",43.11,-88.43,43036861],
		["Couderay","WI",45.79,-91.29,43036936],
		["Lake Bronson","MN",48.73,-96.66,43037011],
		["Revillo","SD",45.01,-96.57,43037086],
		["Claremont","SD",45.67,-98.01,43037161],
		["Scranton","ND",46.14,-103.14,43037236],
		["Powers Lake","ND",48.56,-102.64,43037311],
		["Arlington","IL",41.47,-89.24,43037386],
		["Arrowsmith","IL",40.44,-88.63,43037461],
		["De Land","IL",40.12,-88.64,43037536],
		["Taylor Springs","IL",39.13,-89.49,43037611],
		["Hurdland","MO",40.15,-92.3,43037686],
		["Wheeling","MO",39.78,-93.38,43037761],
		["Moundville","MO",37.76,-94.45,43037836],
		["Olean","MO",38.41,-92.53,43037911],
		["Scandia","KS",39.79,-97.78,43037986],
		["Biggers","AR",36.33,-90.8,43038061],
		["Dallas","TX",32.79,-96.76,43038136],
		["Klondike","TX",33.32,-95.75,43038211],
		["Oilton","TX",27.45,-98.91,43038286],
		["Industry","TX",29.99,-96.49,43038361],
		["Peetz","CO",40.96,-103.11,43038436],
		["Elberta","UT",39.97,-111.98,43038511],
		["Lamy","NM",35.48,-105.87,43038586],
		["Onyx","CA",35.68,-118.07,43038661],
		["Raisin City","CA",36.59,-119.9,43038736],
		["Princeton","CA",39.41,-122.04,43038811],
		["Canby","CA",41.44,-120.94,43038886],
		["South Prairie","WA",47.13,-122.08,43038961],
		["South Cle Elum","WA",47.18,-120.97,43039036],
		["Angoon","AK",57.44,-134.48,43039111],
		["New Sweden","ME",46.94,-68.12,43039185],
		["North Springfield","VT",43.33,-72.52,43039259],
		["Round Lake","NY",42.93,-73.79,43039333],
		["West Elizabeth","PA",40.27,-79.89,43039407],
		["Hawthorn","PA",41.02,-79.27,43039481],
		["Basye","VA",38.8,-78.78,43039555],
		["Fort Monroe","VA",37,-76.3,43039629],
		["Mohawk","WV",37.49,-81.9,43039703],
		["Hewett","WV",37.96,-81.85,43039777],
		["Hugheston","WV",38.36,-81.33,43039851],
		["Mabie","WV",38.81,-80.03,43039925],
		["Wiley Ford","WV",39.61,-78.76,43039999],
		["Hamilton","NC",35.94,-77.2,43040073],
		["Cordesville","SC",33.13,-79.88,43040147],
		["Alpine","TN",36.36,-85.14,43040221],
		["Stephensport","KY",37.91,-86.53,43040295],
		["Huddy","KY",37.59,-82.27,43040369],
		["Beaver","KY",37.39,-82.65,43040443],
		["Kennard","IN",39.9,-85.51,43040517],
		["Saint Mary Of The Woods","IN",39.51,-87.46,43040591],
		["Cooks","MI",45.91,-86.47,43040665],
		["Wetmore","MI",46.38,-86.62,43040739],
		["Blockton","IA",40.61,-94.47,43040813],
		["Washta","IA",42.57,-95.71,43040887],
		["Greeley","IA",42.58,-91.34,43040961],
		["Rewey","WI",42.84,-90.39,43041035],
		["Gary","SD",44.79,-96.45,43041109],
		["Arthur","ND",47.1,-97.21,43041183],
		["Hoople","ND",48.53,-97.63,43041257],
		["Saint Thomas","ND",48.62,-97.44,43041331],
		["Sentinel Butte","ND",46.91,-103.84,43041405],
		["Bowbells","ND",48.8,-102.24,43041479],
		["Terry","MT",46.91,-105.43,43041553],
		["Eldred","IL",39.28,-90.55,43041627],
		["Chula","MO",39.92,-93.47,43041701],
		["Chadwick","MO",36.92,-93.05,43041775],
		["Lincolnville","KS",38.49,-96.96,43041849],
		["Jewell","KS",39.67,-98.15,43041923],
		["Davenport","NE",40.31,-97.81,43041997],
		["Diller","NE",40.1,-96.93,43042071],
		["Dunbar","NE",40.66,-96.03,43042145],
		["Lottie","LA",30.55,-91.64,43042219],
		["Faxon","OK",34.46,-98.57,43042293],
		["Prue","OK",36.24,-96.26,43042367],
		["Wynona","OK",36.54,-96.32,43042441],
		["Hanna","OK",35.2,-95.88,43042515],
		["Quitaque","TX",34.36,-101.05,43042589],
		["Hartsel","CO",39.02,-105.8,43042663],
		["Colorado Springs","CO",38.86,-104.76,43042737],
		["Moffat","CO",38,-105.9,43042811],
		["Poncha Springs","CO",38.51,-106.07,43042885],
		["Lenore","ID",46.64,-116.32,43042959],
		["Marysvale","UT",38.41,-112.24,43043033],
		["Rociada","NM",35.83,-105.42,43043107],
		["Brightwood","OR",45.36,-121.99,43043181],
		["Keno","OR",42.09,-121.99,43043255],
		["La Push","WA",47.9,-124.61,43043329],
		["Silver Creek","WA",46.55,-122.46,43043403],
		["Dryden","WA",47.54,-120.55,43043477],
		["Kivalina","AK",67.73,-164.55,43043551],
		["Cataumet","MA",41.66,-70.61,43043624],
		["Shiloh","NJ",39.45,-75.29,43043697],
		["Wainscott","NY",40.98,-72.18,43043770],
		["Keene","NY",44.25,-73.78,43043843],
		["Brownstown","PA",40.33,-76.21,43043916],
		["Atlantic","VA",37.9,-75.5,43043989],
		["Bramwell","WV",37.32,-81.31,43044062],
		["Bloomingrose","WV",38.15,-81.62,43044135],
		["Jeffrey","WV",37.94,-81.78,43044208],
		["Mc Graws","WV",37.67,-81.44,43044281],
		["Glenville","NC",35.19,-83.08,43044354],
		["Lockhart","SC",34.79,-81.46,43044427],
		["Okahumpka","FL",28.74,-81.89,43044500],
		["Mc Lemoresville","TN",35.98,-88.57,43044573],
		["Tutor Key","KY",37.84,-82.76,43044646],
		["Bevinsville","KY",37.33,-82.71,43044719],
		["Smilax","KY",37.13,-83.28,43044792],
		["Browder","KY",37.27,-87.01,43044865],
		["Rarden","OH",38.92,-83.24,43044938],
		["Mc Guffey","OH",40.69,-83.78,43045011],
		["Genesee","MI",43.11,-83.61,43045084],
		["Blairsburg","IA",42.47,-93.64,43045157],
		["Callender","IA",42.36,-94.29,43045230],
		["Steamboat Rock","IA",42.4,-93.06,43045303],
		["Westfield","IA",42.75,-96.6,43045376],
		["Kiron","IA",42.19,-95.32,43045449],
		["Washington Island","WI",45.37,-86.9,43045522],
		["New Auburn","MN",44.67,-94.22,43045595],
		["Alborn","MN",46.97,-92.57,43045668],
		["Geneva","MN",43.82,-93.26,43045741],
		["Prinsburg","MN",44.93,-95.18,43045814],
		["Cherry Creek","SD",44.6,-101.5,43045887],
		["Noxon","MT",48.03,-115.82,43045960],
		["Putnam","IL",41.18,-89.4,43046033],
		["Donnellson","IL",39.03,-89.47,43046106],
		["Buffalo","KS",37.7,-95.69,43046179],
		["Lacassine","LA",30.23,-92.92,43046252],
		["Sugartown","LA",30.83,-93.01,43046325],
		["Carthage","AR",34.07,-92.55,43046398],
		["Davidson","OK",34.24,-99.07,43046471],
		["Donie","TX",31.47,-96.24,43046544],
		["Falcon Heights","TX",26.56,-99.12,43046617],
		["Skellytown","TX",35.57,-101.17,43046690],
		["Clark","CO",40.7,-106.92,43046763],
		["Encampment","WY",41.2,-106.79,43046836],
		["Georgetown","ID",42.52,-111.29,43046909],
		["Castleford","ID",42.4,-114.88,43046982],
		["Rush Valley","UT",40.34,-112.48,43047055],
		["Meadview","AZ",35.98,-114.06,43047128],
		["Elephant Butte","NM",33.12,-107.25,43047201],
		["Mesilla","NM",32.27,-106.8,43047274],
		["Vaughn","NM",34.6,-105.21,43047347],
		["Lund","NV",38.98,-114.94,43047420],
		["North San Juan","CA",39.42,-120.99,43047493],
		["Ashford","WA",46.77,-122,43047566],
		["Sagamore","MA",41.78,-70.53,43047638],
		["Milton Mills","NH",43.5,-70.97,43047710],
		["Bridgewater","ME",46.42,-67.84,43047782],
		["Purling","NY",42.26,-74,43047854],
		["Shandaken","NY",42.12,-74.39,43047926],
		["Redford","NY",44.6,-73.8,43047998],
		["Revloc","PA",40.49,-78.74,43048070],
		["Koppel","PA",40.83,-80.32,43048142],
		["Hesston","PA",40.39,-78.11,43048214],
		["Picture Rocks","PA",41.28,-76.7,43048286],
		["East Stone Gap","VA",36.86,-82.72,43048358],
		["Forest Hill","WV",37.55,-80.83,43048430],
		["Smoot","WV",37.86,-80.66,43048502],
		["Kincaid","WV",38.02,-81.26,43048574],
		["Smithville","WV",39.06,-81.06,43048646],
		["Tallmansville","WV",38.83,-80.15,43048718],
		["Sapphire","NC",35.1,-83,43048790],
		["Tillman","SC",32.46,-81.1,43048862],
		["Kyles Ford","TN",36.57,-83.04,43048934],
		["Mc Carley","MS",33.59,-89.84,43049006],
		["North Carrollton","MS",33.51,-89.91,43049078],
		["Fairbanks","IN",39.2,-87.53,43049150],
		["Wells","MI",45.78,-87.06,43049222],
		["Pilot Mound","IA",42.15,-94.01,43049294],
		["Leland","IA",43.33,-93.63,43049366],
		["Mallard","IA",42.93,-94.68,43049438],
		["Moorhead","IA",41.92,-95.85,43049510],
		["Portsmouth","IA",41.65,-95.51,43049582],
		["Hills","IA",41.57,-91.53,43049654],
		["Ellison Bay","WI",45.25,-87.06,43049726],
		["Cotton","MN",47.16,-92.47,43049798],
		["Wright","MN",46.67,-93,43049870],
		["Olivet","SD",43.24,-97.67,43049942],
		["Bonesteel","SD",43.07,-98.94,43050014],
		["Gladstone","ND",46.86,-102.56,43050086],
		["Baileyville","IL",42.18,-89.59,43050158],
		["Brocton","IL",39.71,-87.93,43050230],
		["Bowen","IL",40.23,-91.06,43050302],
		["Jewett","IL",39.2,-88.24,43050374],
		["Belknap","IL",37.32,-88.94,43050446],
		["Cypress","IL",37.36,-89.01,43050518],
		["Altamont","MO",39.88,-94.08,43050590],
		["Amoret","MO",38.25,-94.58,43050662],
		["Steedman","MO",38.7,-91.81,43050734],
		["Boss","MO",37.64,-91.18,43050806],
		["Bradleyville","MO",36.78,-92.9,43050878],
		["Oldfield","MO",36.97,-93.03,43050950],
		["Peace Valley","MO",36.87,-91.73,43051022],
		["Mullinville","KS",37.58,-99.47,43051094],
		["Thedford","NE",41.97,-100.57,43051166],
		["Ida","LA",33,-93.89,43051238],
		["Banks","AR",33.57,-92.26,43051310],
		["Jerusalem","AR",35.39,-92.8,43051382],
		["Pineville","AR",36.15,-92.1,43051454],
		["Kingston","AR",36.05,-93.52,43051526],
		["Corn","OK",35.37,-98.78,43051598],
		["Eakly","OK",35.3,-98.55,43051670],
		["Custer City","OK",35.66,-98.88,43051742],
		["Cranfills Gap","TX",31.77,-97.82,43051814],
		["Darrouzett","TX",36.44,-100.32,43051886],
		["Mc Clave","CO",38.19,-102.93,43051958],
		["Weston","CO",37.13,-105.01,43052030],
		["Nathrop","CO",38.73,-106.08,43052102],
		["Fe Warren Afb","WY",41.17,-104.86,43052174],
		["Laramie","WY",41.31,-105.58,43052246],
		["Banner","WY",44.63,-106.8,43052318],
		["Island Park","ID",44.51,-111.35,43052390],
		["Silverton","ID",47.49,-115.95,43052462],
		["Springdale","UT",37.25,-112.99,43052534],
		["Tyrone","NM",32.65,-108.35,43052606],
		["Fellows","CA",35.21,-119.56,43052678],
		["Leggett","CA",39.87,-123.69,43052750],
		["Manton","CA",40.41,-121.84,43052822],
		["Pacific City","OR",45.19,-123.96,43052894],
		["Westfir","OR",43.74,-122.49,43052966],
		["Thorne Bay","AK",55.57,-132.39,43053038],
		["Truro","MA",42,-70.06,43053109],
		["Center Rutland","VT",43.61,-73.01,43053180],
		["Ripton","VT",44,-73.15,43053251],
		["South Kent","CT",41.69,-73.46,43053322],
		["Bridgeport","NJ",39.8,-75.34,43053393],
		["Johnson","NY",41.33,-74.53,43053464],
		["Orient","NY",41.14,-72.3,43053535],
		["Shushan","NY",43.12,-73.3,43053606],
		["Crucible","PA",39.93,-79.95,43053677],
		["North Bend","PA",41.37,-77.7,43053748],
		["Freeburg","PA",40.76,-76.94,43053819],
		["Bowmanstown","PA",40.8,-75.66,43053890],
		["Bushwood","MD",38.28,-76.78,43053961],
		["Skippers","VA",36.58,-77.54,43054032],
		["Big Creek","WV",38,-81.99,43054103],
		["Valley Head","WV",38.52,-80.03,43054174],
		["Grant Town","WV",39.55,-80.17,43054245],
		["Shorterville","AL",31.56,-85.1,43054316],
		["Coy","AL",31.89,-87.46,43054387],
		["Doddsville","MS",33.65,-90.52,43054458],
		["Big Creek","MS",33.84,-89.41,43054529],
		["Cary","MS",32.8,-90.92,43054600],
		["Burgin","KY",37.75,-84.76,43054671],
		["Cromona","KY",37.18,-82.69,43054742],
		["Pitsburg","OH",39.98,-84.48,43054813],
		["Burlington","IN",40.48,-86.39,43054884],
		["Cory","IN",39.33,-87.2,43054955],
		["Wingate","IN",40.17,-87.07,43055026],
		["Paton","IA",42.16,-94.25,43055097],
		["Yale","IA",41.77,-94.35,43055168],
		["Livermore","IA",42.86,-94.18,43055239],
		["Smithland","IA",42.22,-95.93,43055310],
		["Warba","MN",47.13,-93.27,43055381],
		["Cyrus","MN",45.61,-95.73,43055452],
		["Golden Eagle","IL",38.89,-90.57,43055523],
		["Wilsonville","IL",39.06,-89.85,43055594],
		["Dundas","IL",38.83,-88.08,43055665],
		["Wayland","MO",40.39,-91.58,43055736],
		["Centerville","MO",37.43,-90.96,43055807],
		["Fillmore","MO",40.02,-94.97,43055878],
		["Newtown","MO",40.37,-93.33,43055949],
		["Florence","MO",38.58,-92.97,43056020],
		["Goff","KS",39.66,-95.93,43056091],
		["Randolph","KS",39.43,-96.75,43056162],
		["Florence","KS",38.24,-96.92,43056233],
		["Bentley","KS",37.88,-97.51,43056304],
		["Palco","KS",39.25,-99.56,43056375],
		["Nehawka","NE",40.82,-95.98,43056446],
		["Morse Bluff","NE",41.43,-96.76,43056517],
		["Hildreth","NE",40.33,-99.04,43056588],
		["Brule","NE",41.09,-101.88,43056659],
		["Lodgepole","NE",41.14,-102.63,43056730],
		["Boothville","LA",29.32,-89.39,43056801],
		["Oil Trough","AR",35.62,-91.46,43056872],
		["Balko","OK",36.63,-100.68,43056943],
		["Bynum","TX",31.96,-97,43057014],
		["Bruni","TX",27.42,-98.84,43057085],
		["Fischer","TX",29.96,-98.26,43057156],
		["Lewis","CO",37.5,-108.67,43057227],
		["Midvale","ID",44.38,-116.58,43057298],
		["Noti","OR",44.09,-123.48,43057369],
		["Nooksack","WA",48.92,-122.32,43057440],
		["Salkum","WA",46.51,-122.63,43057511],
		["Koyuk","AK",64.93,-161.14,43057582],
		["Fort Buchanan","PR",18.41,-66.11,43057652],
		["East Otis","MA",42.17,-73.03,43057722],
		["Georges Mills","NH",43.42,-72.07,43057792],
		["East Boothbay","ME",43.82,-69.59,43057862],
		["New Harbor","ME",43.85,-69.5,43057932],
		["New Limerick","ME",46.1,-67.96,43058002],
		["Westfield","ME",46.57,-67.92,43058072],
		["Jacksonville","VT",42.79,-72.82,43058142],
		["Crompond","NY",41.26,-73.89,43058212],
		["Preble","NY",42.73,-76.14,43058282],
		["Brookfield","NY",42.81,-75.31,43058352],
		["East Branch","NY",41.98,-75.11,43058422],
		["Mumford","NY",42.99,-77.86,43058492],
		["Newell","PA",40.07,-79.88,43058562],
		["Star Junction","PA",40.1,-79.74,43058632],
		["Faulkner","MD",38.43,-76.97,43058702],
		["Girdletree","MD",38.09,-75.39,43058772],
		["Wingina","VA",37.64,-78.73,43058842],
		["Mac Arthur","WV",37.75,-81.21,43058912],
		["Sandersville","MS",31.78,-89.03,43058982],
		["Littcarr","KY",37.24,-82.94,43059052],
		["Gulliver","MI",45.98,-86.02,43059122],
		["Mass City","MI",46.77,-89.08,43059192],
		["Tracy","IA",41.28,-92.87,43059262],
		["Williams","IA",42.49,-93.54,43059332],
		["Modale","IA",41.61,-96.01,43059402],
		["Silver City","IA",41.11,-95.63,43059472],
		["Bryant","IA",41.96,-90.33,43059542],
		["Eau Galle","WI",44.72,-92.04,43059612],
		["Grand Portage","MN",47.96,-89.68,43059682],
		["Lismore","MN",43.74,-95.94,43059752],
		["Langford","SD",45.6,-97.82,43059822],
		["Bison","SD",45.52,-102.46,43059892],
		["Westhope","ND",48.91,-101.01,43059962],
		["Ryegate","MT",46.39,-109.38,43060032],
		["Malden","IL",41.42,-89.37,43060102],
		["Keyesport","IL",38.74,-89.27,43060172],
		["Gorham","IL",37.71,-89.48,43060242],
		["Cowgill","MO",39.56,-93.92,43060312],
		["Allen","KS",38.65,-96.16,43060382],
		["Sylvan Grove","KS",39.01,-98.39,43060452],
		["Windom","KS",38.38,-97.91,43060522],
		["Lincoln","NE",40.81,-96.68,43060592],
		["Butte","NE",42.91,-98.84,43060662],
		["Anselmo","NE",41.61,-99.86,43060732],
		["Trenton","NE",40.17,-101.01,43060802],
		["Evangeline","LA",30.26,-92.57,43060872],
		["Knobel","AR",36.31,-90.6,43060942],
		["Orlando","OK",36.14,-97.37,43061012],
		["Overbrook","OK",34.07,-97.15,43061082],
		["Meno","OK",36.38,-98.17,43061152],
		["Round Mountain","TX",30.43,-98.35,43061222],
		["Wildorado","TX",35.21,-102.24,43061292],
		["Turkey","TX",34.39,-100.89,43061362],
		["Midwest","WY",43.41,-106.27,43061432],
		["Paguate","NM",35.13,-107.37,43061502],
		["Trabuco Canyon","CA",33.66,-117.59,43061572],
		["Dobbins","CA",39.36,-121.2,43061642],
		["Homewood","CA",39.1,-120.19,43061712],
		["Keller","WA",48.1,-118.71,43061782],
		["Meriden","NH",43.52,-72.27,43061851],
		["Jackson","NH",44.14,-71.18,43061920],
		["West Pawlet","VT",43.36,-73.22,43061989],
		["New York","NY",40.71,-73.99,43062058],
		["Alplaus","NY",42.85,-73.9,43062127],
		["North Branch","NY",41.8,-74.99,43062196],
		["Victory Mills","NY",43.07,-73.64,43062265],
		["Witherbee","NY",44.09,-73.53,43062334],
		["Meridian","NY",43.16,-76.53,43062403],
		["Gilbertsville","NY",42.47,-75.32,43062472],
		["Sabinsville","PA",41.84,-77.54,43062541],
		["Kelayres","PA",40.9,-76.01,43062610],
		["Wye Mills","MD",38.91,-76.08,43062679],
		["Newtown","VA",37.91,-77.12,43062748],
		["Mendota","VA",36.72,-82.25,43062817],
		["Bud","WV",37.52,-81.35,43062886],
		["Anawalt","WV",37.33,-81.44,43062955],
		["Dorothy","WV",37.96,-81.49,43063024],
		["Levels","WV",39.48,-78.55,43063093],
		["North Matewan","WV",37.62,-82.1,43063162],
		["Beech Bottom","WV",40.22,-80.65,43063231],
		["Thomas","WV",39.14,-79.49,43063300],
		["Matthews","GA",33.21,-82.28,43063369],
		["Cobb","GA",31.96,-83.96,43063438],
		["Warbranch","KY",36.97,-83.44,43063507],
		["Rogers","KY",37.74,-83.63,43063576],
		["Jackhorn","KY",37.16,-82.68,43063645],
		["Mount Hermon","KY",36.8,-85.81,43063714],
		["Bethelridge","KY",37.23,-84.76,43063783],
		["Murray City","OH",39.51,-82.16,43063852],
		["Marenisco","MI",46.38,-89.68,43063921],
		["Kellerton","IA",40.71,-94.04,43063990],
		["Keswick","IA",41.45,-92.23,43064059],
		["Martensdale","IA",41.37,-93.73,43064128],
		["Rippey","IA",41.93,-94.2,43064197],
		["Pisgah","IA",41.83,-95.92,43064266],
		["Dundee","IA",42.57,-91.54,43064335],
		["Mount Union","IA",41.05,-91.39,43064404],
		["Lublin","WI",45.07,-90.72,43064473],
		["Murdo","SD",43.88,-100.71,43064542],
		["Oberon","ND",47.92,-99.2,43064611],
		["Winnett","MT",47.11,-108.22,43064680],
		["Coram","MT",48.42,-113.98,43064749],
		["Dahinda","IL",40.95,-90.1,43064818],
		["Du Bois","IL",38.22,-89.21,43064887],
		["Craig","MO",40.19,-95.37,43064956],
		["Blairstown","MO",38.55,-93.95,43065025],
		["Courtland","KS",39.78,-97.89,43065094],
		["Fall River","KS",37.6,-96.02,43065163],
		["Pawnee Rock","KS",38.26,-98.98,43065232],
		["Natoma","KS",39.18,-99.02,43065301],
		["Palisade","NE",40.34,-101.1,43065370],
		["Stratton","NE",40.15,-101.22,43065439],
		["Madrid","NE",40.84,-101.54,43065508],
		["Bienville","LA",32.36,-92.97,43065577],
		["Otis","LA",31.23,-92.74,43065646],
		["Violet Hill","AR",36.16,-91.85,43065715],
		["Hiwasse","AR",36.43,-94.33,43065784],
		["Helena","OK",36.54,-98.27,43065853],
		["Marietta","TX",33.17,-94.54,43065922],
		["Realitos","TX",27.42,-98.55,43065991],
		["Sinclair","WY",41.77,-107.11,43066060],
		["Chester","UT",39.45,-111.57,43066129],
		["Lytle Creek","CA",34.25,-117.54,43066198],
		["Hakalau","HI",19.89,-155.12,43066267],
		["Honaunau","HI",19.42,-155.91,43066336],
		["Azalea","OR",42.78,-123.12,43066405],
		["Umpqua","OR",43.35,-123.54,43066474],
		["Jordan Valley","OR",42.96,-117.36,43066543],
		["Thorp","WA",47.08,-120.71,43066612],
		["Stuyvesant Falls","NY",42.34,-73.75,43066680],
		["Stony Creek","NY",43.42,-74.03,43066748],
		["Caneadea","NY",42.35,-78.21,43066816],
		["Cuddy","PA",40.36,-80.17,43066884],
		["Tarrs","PA",40.15,-79.56,43066952],
		["Sandy Ridge","PA",40.79,-78.21,43067020],
		["Yale","VA",36.84,-77.28,43067088],
		["Whitetop","VA",36.6,-81.61,43067156],
		["Crockett","VA",36.88,-81.18,43067224],
		["Nimitz","WV",37.62,-80.93,43067292],
		["Windsor Heights","WV",40.22,-80.58,43067360],
		["Smyrna","NC",34.8,-76.51,43067428],
		["Bath Springs","TN",35.44,-88.1,43067496],
		["Sherman","MS",34.35,-88.83,43067564],
		["Westport","KY",38.48,-85.46,43067632],
		["Mc Daniels","KY",37.59,-86.4,43067700],
		["Ages Brookside","KY",36.83,-83.25,43067768],
		["Putney","KY",36.9,-83.22,43067836],
		["Closplint","KY",36.9,-83.06,43067904],
		["Christiansburg","OH",40.05,-84.02,43067972],
		["Cross Plains","IN",38.94,-85.22,43068040],
		["Pine Village","IN",40.44,-87.25,43068108],
		["Hillsboro","IA",40.83,-91.71,43068176],
		["Lowell","WI",43.33,-88.82,43068244],
		["Catawba","WI",45.53,-90.53,43068312],
		["Pemberton","MN",44,-93.78,43068380],
		["Bingham Lake","MN",43.9,-95.04,43068448],
		["Steen","MN",43.51,-96.26,43068516],
		["Milroy","MN",44.41,-95.55,43068584],
		["Stanton","ND",47.31,-101.38,43068652],
		["Hobson","MT",46.86,-110.19,43068720],
		["Saint David","IL",40.49,-90.05,43068788],
		["Brussels","IL",38.95,-90.58,43068856],
		["Beason","IL",40.15,-89.2,43068924],
		["Johnsonville","IL",38.52,-88.53,43068992],
		["West Alton","MO",38.86,-90.22,43069060],
		["Purcell","MO",37.24,-94.43,43069128],
		["Denison","KS",39.39,-95.62,43069196],
		["Geuda Springs","KS",37.11,-97.14,43069264],
		["Blue Springs","NE",40.13,-96.66,43069332],
		["Hallam","NE",40.53,-96.78,43069400],
		["Table Rock","NE",40.17,-96.08,43069468],
		["Arcadia","NE",41.42,-99.12,43069536],
		["North Loup","NE",41.49,-98.77,43069604],
		["Guide Rock","NE",40.07,-98.32,43069672],
		["Sikes","LA",32.07,-92.48,43069740],
		["Washington","AR",33.77,-93.68,43069808],
		["Elizabeth","AR",36.31,-92.09,43069876],
		["Gamaliel","AR",36.46,-92.2,43069944],
		["Mountain Park","OK",34.69,-98.95,43070012],
		["Meyersville","TX",28.92,-97.3,43070080],
		["Fulton","TX",28.07,-97.03,43070148],
		["Lawn","TX",32.13,-99.75,43070216],
		["Lake City","CO",38.07,-107.3,43070284],
		["Glade Park","CO",39,-108.73,43070352],
		["Wamsutter","WY",41.66,-107.97,43070420],
		["Craigmont","ID",46.23,-116.48,43070488],
		["Holden","UT",39.07,-112.31,43070556],
		["Glendale","UT",37.38,-112.58,43070624],
		["Picacho","AZ",32.71,-111.49,43070692],
		["Sun City","AZ",33.6,-112.28,43070760],
		["Bowie","AZ",32.28,-109.5,43070828],
		["Pinedale","AZ",34.27,-110.28,43070896],
		["San Diego","CA",32.67,-117.16,43070964],
		["Crescent","OR",43.42,-121.68,43071032],
		["Helix","OR",45.9,-118.77,43071100],
		["King Cove","AK",55.08,-162.32,43071168],
		["Goshen","MA",42.43,-72.8,43071235],
		["West Dover","VT",42.96,-72.91,43071302],
		["Bomoseen","VT",43.63,-73.2,43071369],
		["Greensboro Bend","VT",44.54,-72.21,43071436],
		["Peconic","NY",41.03,-72.46,43071503],
		["Sagaponack","NY",40.92,-72.27,43071570],
		["West Oneonta","NY",42.44,-75.11,43071637],
		["Olcott","NY",43.33,-78.71,43071704],
		["Vestaburg","PA",39.98,-80.03,43071771],
		["Lucernemines","PA",40.55,-79.15,43071838],
		["Forksville","PA",41.49,-76.6,43071905],
		["Silverdale","PA",40.34,-75.27,43071972],
		["Corriganville","MD",39.69,-78.79,43072039],
		["Dutton","VA",37.49,-76.46,43072106],
		["Virginia Beach","VA",36.73,-76.04,43072173],
		["Van","WV",37.95,-81.69,43072240],
		["Dublin","NC",34.65,-78.72,43072307],
		["Maury City","TN",35.81,-89.22,43072374],
		["Buena Vista","TN",35.98,-88.28,43072441],
		["Parrott","GA",31.92,-84.49,43072508],
		["Stoney Fork","KY",36.88,-83.52,43072575],
		["Vancleve","KY",37.62,-83.41,43072642],
		["Melvin","KY",37.35,-82.69,43072709],
		["Millstone","KY",37.16,-82.75,43072776],
		["Buchtel","OH",39.46,-82.18,43072843],
		["Mount Cory","OH",40.93,-83.82,43072910],
		["Venedocia","OH",40.78,-84.45,43072977],
		["Wheeler","IN",41.51,-87.18,43073044],
		["Sulphur Springs","IN",40,-85.44,43073111],
		["Stendal","IN",38.27,-87.12,43073178],
		["Carrollton","MI",43.49,-83.96,43073245],
		["Mc Intire","IA",43.46,-92.61,43073312],
		["Quasqueton","IA",42.39,-91.75,43073379],
		["Middletown","IA",40.82,-91.26,43073446],
		["Cobb","WI",42.96,-90.32,43073513],
		["Lakewood","WI",45.29,-88.51,43073580],
		["Garden City","MN",44.04,-94.18,43073647],
		["Beardsley","MN",45.55,-96.71,43073714],
		["Burbank","SD",42.76,-96.83,43073781],
		["Hunter","ND",47.19,-97.21,43073848],
		["Crary","ND",48.07,-98.64,43073915],
		["Augusta","MT",47.58,-112.6,43073982],
		["Denton","MT",47.45,-109.92,43074049],
		["Kempton","IL",40.93,-88.23,43074116],
		["Bellflower","IL",40.34,-88.52,43074183],
		["Browning","IL",40.12,-90.37,43074250],
		["Wolf Lake","IL",37.53,-89.44,43074317],
		["Arbela","MO",40.46,-92.01,43074384],
		["Luray","MO",40.45,-91.88,43074451],
		["Parnell","MO",40.44,-94.62,43074518],
		["Squires","MO",36.85,-92.62,43074585],
		["Muscotah","KS",39.55,-95.52,43074652],
		["Mapleton","KS",38.01,-94.88,43074719],
		["Douglas","NE",40.59,-96.38,43074786],
		["Shickley","NE",40.41,-97.72,43074853],
		["Unadilla","NE",40.68,-96.27,43074920],
		["Umpire","AR",34.28,-94.11,43074987],
		["Concepcion","TX",27.38,-98.27,43075054],
		["Carmine","TX",30.14,-96.68,43075121],
		["Ten Sleep","WY",44.03,-107.44,43075188],
		["Murphy","ID",43.08,-116.52,43075255],
		["Central","UT",37.39,-113.63,43075322],
		["Tonto Basin","AZ",33.83,-111.26,43075389],
		["Truchas","NM",36.04,-105.81,43075456],
		["Salyer","CA",40.86,-123.55,43075523],
		["Elk Creek","CA",39.54,-122.57,43075590],
		["Kunia","HI",21.46,-158.06,43075657],
		["Seal Cove","ME",44.3,-68.41,43075723],
		["Temple","ME",44.68,-70.22,43075789],
		["Bridgewater Corners","VT",43.6,-72.68,43075855],
		["South Ryegate","VT",44.22,-72.12,43075921],
		["Quinebaug","CT",42,-71.93,43075987],
		["Copake Falls","NY",42.14,-73.5,43076053],
		["Hutchinson","PA",40.23,-79.71,43076119],
		["Loyalhanna","PA",40.3,-79.34,43076185],
		["Saint Petersburg","PA",41.16,-79.65,43076251],
		["Ramey","PA",40.8,-78.39,43076317],
		["Allen","MD",38.28,-75.68,43076383],
		["Elkwood","VA",38.51,-77.85,43076449],
		["Uneeda","WV",38.01,-81.74,43076515],
		["Swiss","WV",38.27,-81.06,43076581],
		["Poplar Branch","NC",36.28,-75.89,43076647],
		["Ansonville","NC",35.1,-80.1,43076713],
		["Lattimore","NC",35.31,-81.66,43076779],
		["Hurricane Mills","TN",35.94,-87.76,43076845],
		["Guys","TN",35.01,-88.54,43076911],
		["Luray","TN",35.6,-88.59,43076977],
		["Lutts","TN",35.11,-87.9,43077043],
		["Pace","MS",33.79,-90.85,43077109],
		["Chaplin","KY",37.9,-85.21,43077175],
		["Kettle Island","KY",36.79,-83.6,43077241],
		["Cannel City","KY",37.78,-83.27,43077307],
		["Belcher","KY",37.34,-82.37,43077373],
		["Glens Fork","KY",37.01,-85.25,43077439],
		["Trinway","OH",40.15,-82.01,43077505],
		["Branchville","IN",38.16,-86.58,43077571],
		["Channing","MI",46.13,-88.08,43077637],
		["New Liberty","IA",41.71,-90.87,43077703],
		["Stitzer","WI",42.91,-90.63,43077769],
		["Okabena","MN",43.73,-95.31,43077835],
		["Alvarado","MN",48.19,-96.99,43077901],
		["Midland","SD",44.07,-101.15,43077967],
		["Nisland","SD",44.67,-103.55,43078033],
		["Hillview","IL",39.44,-90.53,43078099],
		["Camden","MO",39.2,-94.01,43078165],
		["Helena","MO",39.93,-94.69,43078231],
		["Rockville","MO",38.07,-94.08,43078297],
		["White Cloud","KS",39.97,-95.29,43078363],
		["Holyrood","KS",38.58,-98.41,43078429],
		["Benedict","NE",41,-97.6,43078495],
		["Gresham","NE",41.02,-97.4,43078561],
		["Pickrell","NE",40.37,-96.72,43078627],
		["Lynch","NE",42.83,-98.46,43078693],
		["Nelson","NE",40.2,-98.06,43078759],
		["Roseland","NE",40.47,-98.55,43078825],
		["Freedom","OK",36.76,-99.11,43078891],
		["Carbon","TX",32.26,-98.82,43078957],
		["New Deal","TX",33.73,-101.83,43079023],
		["Lueders","TX",32.79,-99.62,43079089],
		["Wickett","TX",31.57,-103,43079155],
		["Grover","CO",40.86,-104.22,43079221],
		["Yoder","WY",41.91,-104.29,43079287],
		["Deaver","WY",44.88,-108.59,43079353],
		["Mountain Home","UT",40.41,-110.4,43079419],
		["Tabiona","UT",40.38,-110.65,43079485],
		["Newcastle","UT",37.82,-113.72,43079551],
		["Supai","AZ",36.15,-112.63,43079617],
		["Hume","CA",36.8,-118.89,43079683],
		["Mad River","CA",40.37,-123.44,43079749],
		["Junction City","CA",40.87,-123.1,43079815],
		["Vina","CA",39.97,-122.02,43079881],
		["Lawai","HI",21.92,-159.5,43079947],
		["Malott","WA",48.3,-119.77,43080013],
		["Twin Mountain","NH",44.3,-71.5,43080078],
		["Brownsville","VT",43.45,-72.49,43080143],
		["West Townshend","VT",43.13,-72.71,43080208],
		["Trenton","NJ",40.22,-74.76,43080273],
		["Wells","NY",43.39,-74.29,43080338],
		["Smallwood","NY",41.66,-74.81,43080403],
		["Springfield Center","NY",42.82,-74.87,43080468],
		["Parishville","NY",44.63,-74.82,43080533],
		["West Leisenring","PA",39.96,-79.71,43080598],
		["Fryburg","PA",41.35,-79.45,43080663],
		["Slatedale","PA",40.73,-75.63,43080728],
		["Church Creek","MD",38.5,-76.15,43080793],
		["Simon","WV",37.61,-81.76,43080858],
		["Bomont","WV",38.4,-81.21,43080923],
		["Ireland","WV",38.75,-80.47,43080988],
		["Lockhart","AL",31.01,-86.35,43081053],
		["Petros","TN",36.09,-84.44,43081118],
		["Minter City","MS",33.75,-90.33,43081183],
		["South Williamson","KY",37.66,-82.29,43081248],
		["Olaton","KY",37.53,-86.7,43081313],
		["Millwood","KY",37.45,-86.5,43081378],
		["Lapaz","IN",41.45,-86.3,43081443],
		["Palms","MI",43.62,-82.73,43081508],
		["Michigamme","MI",46.53,-88.11,43081573],
		["Linden","IA",41.64,-94.27,43081638],
		["Kennan","WI",45.53,-90.58,43081703],
		["Side Lake","MN",47.66,-93.01,43081768],
		["Ostrander","MN",43.61,-92.42,43081833],
		["Euclid","MN",47.97,-96.63,43081898],
		["Astoria","SD",44.55,-96.54,43081963],
		["Corona","SD",45.33,-96.76,43082028],
		["South Shore","SD",45.1,-96.93,43082093],
		["Dimock","SD",43.47,-97.98,43082158],
		["Davenport","ND",46.71,-97.06,43082223],
		["Leonard","ND",46.65,-97.24,43082288],
		["Fordville","ND",48.21,-97.79,43082353],
		["Wolf Creek","MT",47.08,-112.17,43082418],
		["Prairie City","IL",40.62,-90.46,43082483],
		["Bone Gap","IL",38.44,-87.99,43082548],
		["Olmsted","IL",37.18,-89.08,43082613],
		["Pulaski","IL",37.21,-89.2,43082678],
		["Wooldridge","MO",38.9,-92.52,43082743],
		["Hartshorn","MO",37.26,-91.67,43082808],
		["Bern","KS",39.96,-95.97,43082873],
		["Olsburg","KS",39.43,-96.61,43082938],
		["Neosho Rapids","KS",38.36,-95.99,43083003],
		["Ames","NE",41.45,-96.63,43083068],
		["Polk","NE",41.07,-97.78,43083133],
		["Springview","NE",42.82,-99.74,43083198],
		["Clarkridge","AR",36.45,-92.34,43083263],
		["Ringold","OK",34.21,-95.13,43083328],
		["Moran","TX",32.54,-99.16,43083393],
		["Vanderbilt","TX",28.85,-96.66,43083458],
		["Tilden","TX",28.45,-98.54,43083523],
		["Salineno","TX",26.5,-99.07,43083588],
		["Grandfalls","TX",31.34,-102.85,43083653],
		["Dumont","CO",39.78,-105.6,43083718],
		["Guffey","CO",38.75,-105.53,43083783],
		["Sugar City","CO",38.23,-103.66,43083848],
		["Poston","AZ",34,-114.4,43083913],
		["Serafina","NM",35.48,-105.4,43083978],
		["Independence","CA",36.83,-118.24,43084043],
		["Jolon","CA",36,-121.34,43084108],
		["Vallejo","CA",38.11,-122.29,43084173],
		["Paicines","CA",36.53,-120.96,43084238],
		["Vernalis","CA",37.61,-121.25,43084303],
		["Bangor","CA",39.42,-121.36,43084368],
		["Anaktuvuk Pass","AK",68.15,-151.71,43084433],
		["Heath","MA",42.66,-72.83,43084497],
		["Plymouth","VT",43.53,-72.73,43084561],
		["Clarksville","NY",42.56,-73.97,43084625],
		["Sylvan Beach","NY",43.19,-75.73,43084689],
		["Allison","PA",39.98,-79.87,43084753],
		["Black Lick","PA",40.46,-79.18,43084817],
		["La Jose","PA",40.81,-78.62,43084881],
		["Carlton","PA",41.47,-80.03,43084945],
		["Harmonsburg","PA",41.66,-80.28,43085009],
		["Hyde","PA",41,-78.46,43085073],
		["Marion Heights","PA",40.8,-76.46,43085137],
		["Stockertown","PA",40.75,-75.26,43085201],
		["Trexlertown","PA",40.56,-75.6,43085265],
		["Starrucca","PA",41.9,-75.46,43085329],
		["Zieglerville","PA",40.28,-75.48,43085393],
		["Port Royal","VA",38.16,-77.19,43085457],
		["Flint Hill","VA",38.76,-78.1,43085521],
		["Breaks","VA",37.29,-82.28,43085585],
		["Eccles","WV",37.79,-81.26,43085649],
		["Lovejoy","GA",33.43,-84.31,43085713],
		["Frankville","AL",31.64,-88.14,43085777],
		["D Lo","MS",31.98,-89.85,43085841],
		["Rocky Ridge","OH",41.53,-83.21,43085905],
		["Tontogany","OH",41.42,-83.74,43085969],
		["Newport","IN",39.88,-87.4,43086033],
		["Okoboji","IA",43.39,-95.13,43086097],
		["Columbus City","IA",41.25,-91.37,43086161],
		["Steuben","WI",43.18,-90.85,43086225],
		["Barronett","WI",45.63,-92.04,43086289],
		["Caroline","WI",44.71,-88.88,43086353],
		["Rockville","MN",45.47,-94.33,43086417],
		["Lengby","MN",47.51,-95.63,43086481],
		["Blunt","SD",44.51,-99.98,43086545],
		["Hope","ND",47.32,-97.71,43086609],
		["Tower City","ND",46.92,-97.67,43086673],
		["Cedarville","IL",42.37,-89.63,43086737],
		["Cherry","IL",41.42,-89.21,43086801],
		["Annapolis","IL",39.15,-87.81,43086865],
		["Middletown","IL",40.1,-89.59,43086929],
		["Kansas City","MO",39.09,-94.58,43086993],
		["Excello","MO",39.63,-92.48,43087057],
		["Success","MO",37.44,-92.08,43087121],
		["New Century","KS",38.84,-94.93,43087185],
		["Murdock","KS",37.6,-97.97,43087249],
		["Severy","KS",37.62,-96.22,43087313],
		["Staplehurst","NE",40.97,-97.17,43087377],
		["Funk","NE",40.46,-99.24,43087441],
		["Maywood","NE",40.65,-100.62,43087505],
		["Sims","AR",34.66,-93.66,43087569],
		["Menifee","AR",35.14,-92.55,43087633],
		["Madison","AR",35.01,-90.73,43087697],
		["Wheatley","AR",34.91,-91.1,43087761],
		["Rosie","AR",35.65,-91.53,43087825],
		["Uniontown","AR",35.58,-94.45,43087889],
		["Foss","OK",35.45,-99.17,43087953],
		["Burlington","OK",36.9,-98.42,43088017],
		["Kremlin","OK",36.54,-97.83,43088081],
		["Keyes","OK",36.8,-102.25,43088145],
		["Rio Medina","TX",29.43,-98.88,43088209],
		["Hobson","TX",28.93,-97.98,43088273],
		["Kit Carson","CO",38.76,-102.79,43088337],
		["Cornish","UT",41.96,-112,43088401],
		["Holman","NM",36.03,-105.38,43088465],
		["Sunset Beach","CA",33.71,-118.07,43088529],
		["Inverness","CA",38.11,-122.92,43088593],
		["Orleans","CA",41.31,-123.61,43088657],
		["Papaaloa","HI",19.97,-155.22,43088721],
		["O Brien","OR",42.04,-123.71,43088785],
		["Everett","WA",47.96,-122.19,43088849],
		["Eek","AK",60.19,-162.13,43088913],
		["Gakona","AK",62.41,-143.41,43088977],
		["Kwigillingok","AK",59.87,-163.18,43089041],
		["Mc Grath","AK",62.51,-154.44,43089105],
		["Andover","ME",44.63,-70.75,43089168],
		["Stratton","ME",45.14,-70.44,43089231],
		["Glasco","NY",42.04,-73.95,43089294],
		["Mongaup Valley","NY",41.66,-74.78,43089357],
		["Atlasburg","PA",40.39,-80.44,43089420],
		["Melcroft","PA",40.06,-79.36,43089483],
		["Fishertown","PA",40.12,-78.58,43089546],
		["Irvine","PA",41.84,-79.18,43089609],
		["Cyclone","PA",41.82,-78.58,43089672],
		["Hawk Run","PA",40.94,-78.21,43089735],
		["Secretary","MD",38.6,-75.94,43089798],
		["Tilghman","MD",38.69,-76.33,43089861],
		["Warm Springs","VA",38.05,-79.78,43089924],
		["Bartley","WV",37.33,-81.73,43089987],
		["Asbury","WV",37.81,-80.56,43090050],
		["Hartford","WV",38.98,-81.99,43090113],
		["Orlando","WV",38.89,-80.56,43090176],
		["Glen Alpine","NC",35.72,-81.77,43090239],
		["Lodge","SC",33.06,-80.95,43090302],
		["Modoc","SC",33.71,-82.18,43090365],
		["Farner","TN",35.14,-84.32,43090428],
		["Bluffton","GA",31.55,-84.86,43090491],
		["Hitchins","KY",38.23,-82.91,43090554],
		["Hallie","KY",37.1,-83.02,43090617],
		["Dola","OH",40.78,-83.69,43090680],
		["Searsboro","IA",41.57,-92.7,43090743],
		["Corwith","IA",42.98,-93.95,43090806],
		["Lytton","IA",42.42,-94.86,43090869],
		["Macedonia","IA",41.19,-95.42,43090932],
		["Panama","IA",41.72,-95.47,43090995],
		["Davenport","IA",41.55,-90.6,43091058],
		["Glen Haven","WI",42.83,-91.06,43091121],
		["Lesterville","SD",43.03,-97.59,43091184],
		["Toronto","SD",44.57,-96.64,43091247],
		["Willow City","ND",48.6,-100.29,43091310],
		["Roseglen","ND",47.75,-101.83,43091373],
		["Mc Nabb","IL",41.17,-89.2,43091436],
		["New Canton","IL",39.63,-91.09,43091499],
		["Harvel","IL",39.35,-89.53,43091562],
		["Broughton","IL",37.93,-88.46,43091625],
		["Durham","MO",39.95,-91.67,43091688],
		["Marston","MO",36.51,-89.6,43091751],
		["Sheridan","MO",40.51,-94.61,43091814],
		["Argyle","MO",38.29,-92.02,43091877],
		["Myrtle","MO",36.5,-91.26,43091940],
		["Franklin","KS",37.52,-94.7,43092003],
		["Moline","KS",37.36,-96.3,43092066],
		["Glen Elder","KS",39.49,-98.3,43092129],
		["Lucas","KS",39.05,-98.53,43092192],
		["Dwight","NE",41.08,-97.01,43092255],
		["Trumbull","NE",40.68,-98.27,43092318],
		["Hayes Center","NE",40.51,-101.02,43092381],
		["Harrisburg","NE",41.55,-103.74,43092444],
		["Terral","OK",33.89,-97.93,43092507],
		["Stringtown","OK",34.46,-96.05,43092570],
		["Montague","TX",33.67,-97.72,43092633],
		["Canyon","TX",34.98,-101.92,43092696],
		["Rochester","TX",33.31,-99.85,43092759],
		["Placerville","CO",38.02,-108.07,43092822],
		["Chugwater","WY",41.75,-104.82,43092885],
		["Glenwood","UT",38.76,-111.97,43092948],
		["Cannon Afb","NM",34.38,-103.31,43093011],
		["Ruth","NV",39.27,-114.99,43093074],
		["Camptonville","CA",39.42,-121.11,43093137],
		["Beaver","OR",45.27,-123.71,43093200],
		["Amanda Park","WA",47.47,-123.92,43093263],
		["Skamokawa","WA",46.28,-123.44,43093326],
		["Tuluksak","AK",61.06,-160.36,43093389],
		["Southfield","MA",42.07,-73.23,43093451],
		["West Glover","VT",44.69,-72.26,43093513],
		["Stone Harbor","NJ",39.04,-74.76,43093575],
		["Doylesburg","PA",40.22,-77.7,43093637],
		["Locust Gap","PA",40.79,-76.43,43093699],
		["Milanville","PA",41.67,-75.06,43093761],
		["Mappsville","VA",37.84,-75.56,43093823],
		["Branchville","VA",36.56,-77.24,43093885],
		["Hometown","WV",38.56,-81.87,43093947],
		["Pratt","WV",38.2,-81.38,43094009],
		["Camp Creek","WV",37.48,-81.1,43094071],
		["Reynoldsville","WV",39.27,-80.41,43094133],
		["Cedar Mountain","NC",35.14,-82.64,43094195],
		["Welaka","FL",29.48,-81.66,43094257],
		["Langston","AL",34.53,-86.09,43094319],
		["Verona","OH",39.9,-84.48,43094381],
		["Waterloo","OH",38.73,-82.53,43094443],
		["Orchard","IA",43.22,-92.77,43094505],
		["Renwick","IA",42.82,-93.98,43094567],
		["Dorchester","IA",43.46,-91.51,43094629],
		["Clyman","WI",43.31,-88.71,43094691],
		["Presque Isle","WI",46.25,-89.73,43094753],
		["Frontenac","MN",44.49,-92.35,43094815],
		["Angora","MN",47.77,-92.63,43094877],
		["Sunburg","MN",45.34,-95.23,43094939],
		["Hitchcock","SD",44.62,-98.4,43095001],
		["Gilby","ND",48.08,-97.46,43095063],
		["Lansford","ND",48.62,-101.37,43095125],
		["Fortine","MT",48.76,-114.77,43095187],
		["Thawville","IL",40.67,-88.11,43095249],
		["Kenney","IL",40.09,-89.08,43095311],
		["Strawn","IL",40.65,-88.39,43095373],
		["Rockport","IL",39.53,-91,43095435],
		["Oconee","IL",39.28,-89.1,43095497],
		["Vanduser","MO",36.99,-89.68,43095559],
		["Kansas City","MO",39.09,-94.58,43095621],
		["Davisville","MO",37.8,-91.18,43095683],
		["Altoona","KS",37.52,-95.66,43095745],
		["Walton","KS",38.11,-97.25,43095807],
		["Bison","KS",38.51,-99.19,43095869],
		["Selden","KS",39.54,-100.56,43095931],
		["Loco","OK",34.32,-97.68,43095993],
		["Chester","OK",36.22,-98.92,43096055],
		["Naval Air Station/ Jrb","TX",32.77,-97.43,43096117],
		["Mont Belvieu","TX",29.85,-94.88,43096179],
		["Nordheim","TX",28.92,-97.61,43096241],
		["San Antonio","TX",29.45,-98.5,43096303],
		["Snyder","CO",40.32,-103.58,43096365],
		["Crested Butte","CO",38.87,-106.98,43096427],
		["Careywood","ID",48.06,-116.59,43096489],
		["Woodruff","UT",41.37,-111.27,43096551],
		["Lotus","CA",38.81,-120.93,43096613],
		["Honomu","HI",19.87,-155.11,43096675],
		["Foster","OR",44.39,-122.56,43096737],
		["Tacoma","WA",47.26,-122.39,43096799],
		["Islesboro","ME",44.3,-68.9,43096860],
		["North Pownal","VT",42.81,-73.24,43096921],
		["Jamesport","NY",40.94,-72.57,43096982],
		["Rock City Falls","NY",43.06,-73.92,43097043],
		["Farmersville Station","NY",42.44,-78.29,43097104],
		["Willow Hill","PA",40.11,-77.77,43097165],
		["Lake Winola","PA",41.5,-75.85,43097226],
		["Horntown","VA",37.97,-75.46,43097287],
		["Parrott","VA",37.21,-80.63,43097348],
		["Nellis","WV",38.15,-81.73,43097409],
		["Big Bend","WV",38.98,-81.13,43097470],
		["Edward","NC",35.32,-76.89,43097531],
		["Icard","NC",35.72,-81.45,43097592],
		["Fulton","AL",31.78,-87.72,43097653],
		["New Hope","KY",37.63,-85.51,43097714],
		["Westview","KY",37.68,-86.42,43097775],
		["Letcher","KY",37.14,-82.95,43097836],
		["Put In Bay","OH",41.64,-82.82,43097897],
		["Mount Pleasant","OH",40.17,-80.8,43097958],
		["Savannah","OH",40.96,-82.36,43098019],
		["Trimble","OH",39.48,-82.07,43098080],
		["Freelandville","IN",38.87,-87.34,43098141],
		["Palmer","MI",46.43,-87.58,43098202],
		["Aurora","IA",42.61,-91.72,43098263],
		["Cleghorn","IA",42.81,-95.71,43098324],
		["Packwood","IA",41.13,-92.08,43098385],
		["Moscow","IA",41.58,-91.08,43098446],
		["Pelican Lake","WI",45.5,-89.11,43098507],
		["Wolverton","MN",46.56,-96.73,43098568],
		["Spencer","SD",43.72,-97.59,43098629],
		["Kennebec","SD",43.9,-99.86,43098690],
		["Tappen","ND",46.87,-99.62,43098751],
		["Hinsdale","MT",48.42,-107.03,43098812],
		["East Glacier Park","MT",48.41,-113.31,43098873],
		["Seaton","IL",41.1,-90.79,43098934],
		["Parkersburg","IL",38.58,-88.05,43098995],
		["Turney","MO",39.63,-94.32,43099056],
		["Lanagan","MO",36.6,-94.45,43099117],
		["Wynot","NE",42.73,-97.16,43099178],
		["Greeley","NE",41.54,-98.53,43099239],
		["Big Springs","NE",41.06,-102.07,43099300],
		["Harrison","NE",42.68,-103.88,43099361],
		["Hosston","LA",32.88,-93.88,43099422],
		["Brockwell","AR",36.13,-91.96,43099483],
		["Mingus","TX",32.53,-98.42,43099544],
		["Woodson","TX",33.01,-99.05,43099605],
		["Capulin","CO",37.28,-106.12,43099666],
		["Desmet","ID",47.12,-116.91,43099727],
		["Datil","NM",34.14,-107.84,43099788],
		["Shaver Lake","CA",37.06,-119.04,43099849],
		["Albion","CA",39.21,-123.69,43099910],
		["Adin","CA",41.15,-120.89,43099971],
		["Bucoda","WA",46.78,-122.84,43100032],
		["Klickitat","WA",45.84,-121.04,43100093],
		["Uniontown","WA",46.51,-117.13,43100154],
		["Russian Mission","AK",61.81,-161.44,43100215],
		["West Hatfield","MA",42.39,-72.64,43100275],
		["South Lancaster","MA",42.43,-71.69,43100335],
		["Ashuelot","NH",42.81,-72.44,43100395],
		["Bernard","ME",44.24,-68.35,43100455],
		["Jonesboro","ME",44.66,-67.57,43100515],
		["East Corinth","VT",44.09,-72.22,43100575],
		["Peru","VT",43.23,-72.89,43100635],
		["Lakeside","CT",41.68,-73.23,43100695],
		["Mount Tremper","NY",42.04,-74.23,43100755],
		["Turtlepoint","PA",41.88,-78.32,43100815],
		["Madisonburg","PA",40.94,-77.49,43100875],
		["Brinklow","MD",39.18,-77.01,43100935],
		["Sylvester","WV",38,-81.56,43100995],
		["Edgarton","WV",37.58,-82.1,43101055],
		["Scranton","NC",35.58,-76.44,43101115],
		["Gifford","SC",32.86,-81.23,43101175],
		["Barwick","GA",30.89,-83.73,43101235],
		["Pathfork","KY",36.75,-83.46,43101295],
		["Tram","KY",37.58,-82.63,43101355],
		["Breeding","KY",36.95,-85.41,43101415],
		["Mansfield","OH",40.76,-82.52,43101475],
		["Camp Dennison","OH",39.19,-84.28,43101535],
		["Newtonsville","OH",39.18,-84.08,43101595],
		["Needham","IN",39.48,-85.99,43101655],
		["Moran","MI",46.08,-85.01,43101715],
		["Au Train","MI",46.43,-86.83,43101775],
		["Derby","IA",40.92,-93.45,43101835],
		["Barnum","IA",42.5,-94.36,43101895],
		["Rembrandt","IA",42.82,-95.16,43101955],
		["Beaman","IA",42.22,-92.82,43102015],
		["Clearfield","IA",40.8,-94.47,43102075],
		["Westside","IA",42.07,-95.1,43102135],
		["Mc Clelland","IA",41.32,-95.68,43102195],
		["Armstrong Creek","WI",45.65,-88.44,43102255],
		["Felton","MN",47.07,-96.5,43102315],
		["Wannaska","MN",48.65,-95.73,43102375],
		["Tokio","ND",47.92,-98.81,43102435],
		["Sanborn","ND",46.94,-98.22,43102495],
		["Wyola","MT",45.1,-107.41,43102555],
		["Highwood","MT",47.58,-110.78,43102615],
		["Laura","IL",40.91,-89.91,43102675],
		["Hindsboro","IL",39.68,-88.13,43102735],
		["Hidalgo","IL",39.15,-88.15,43102795],
		["Mount Erie","IL",38.51,-88.23,43102855],
		["Modesto","IL",39.47,-89.98,43102915],
		["Richview","IL",38.37,-89.18,43102975],
		["Middle Brook","MO",37.69,-90.68,43103035],
		["Henrietta","MO",39.23,-93.93,43103095],
		["Holliday","MO",39.49,-92.13,43103155],
		["Elk Creek","MO",37.18,-91.99,43103215],
		["Netawaka","KS",39.6,-95.71,43103275],
		["Cawker City","KS",39.5,-98.43,43103335],
		["Ulysses","NE",41.07,-97.2,43103395],
		["Elba","NE",41.28,-98.56,43103455],
		["Summerfield","LA",32.93,-92.8,43103515],
		["Mount Holly","AR",33.31,-92.94,43103575],
		["Henderson","AR",36.34,-92.23,43103635],
		["Compton","AR",36.08,-93.3,43103695],
		["Combs","AR",35.81,-93.83,43103755],
		["Boles","AR",34.71,-94.02,43103815],
		["New London","TX",32.26,-94.93,43103875],
		["San Antonio","TX",29.45,-98.5,43103935],
		["Tow","TX",30.88,-98.46,43103995],
		["Marathon","TX",30.2,-103.24,43104055],
		["Redvale","CO",38.18,-108.42,43104115],
		["Superior","WY",41.76,-108.96,43104175],
		["Tridell","UT",40.44,-109.84,43104235],
		["Cleveland","NM",35.99,-105.37,43104295],
		["Eagle Nest","NM",36.55,-105.26,43104355],
		["Yosemite National Park","CA",37.69,-119.59,43104415],
		["The Sea Ranch","CA",38.7,-123.44,43104475],
		["Round Mountain","CA",40.92,-122.02,43104535],
		["Shasta","CA",40.62,-122.5,43104595],
		["Chilcoot","CA",39.87,-120.2,43104655],
		["Garibaldi","OR",45.56,-123.9,43104715],
		["Hebo","OR",45.17,-123.83,43104775],
		["Kerby","OR",42.2,-123.65,43104835],
		["Marblemount","WA",48.44,-121.27,43104895],
		["Village Of Nagog Woods","MA",42.51,-71.41,43104954],
		["West Stewartstown","NH",44.95,-71.49,43105013],
		["Haverhill","NH",44.03,-72.06,43105072],
		["Chocorua","NH",43.89,-71.24,43105131],
		["Saint Francis","ME",47.17,-68.89,43105190],
		["Stacyville","ME",45.89,-68.43,43105249],
		["Wallagrass","ME",47.15,-68.57,43105308],
		["East Ryegate","VT",44.19,-72.07,43105367],
		["Pittsfield","VT",43.78,-72.82,43105426],
		["Bloomington","NY",41.85,-74.06,43105485],
		["Malden On Hudson","NY",42.1,-73.93,43105544],
		["Rector","PA",40.17,-79.22,43105603],
		["Washington Grove","MD",39.14,-77.17,43105662],
		["Stevenson","MD",39.42,-76.7,43105721],
		["Locust Hill","VA",37.59,-76.5,43105780],
		["Eastville","VA",37.35,-75.94,43105839],
		["Clear Creek","WV",37.89,-81.31,43105898],
		["Ridgeview","WV",38.15,-81.78,43105957],
		["Flat Top","WV",37.58,-81.11,43106016],
		["Meadowbrook","WV",39.33,-80.32,43106075],
		["Sand Fork","WV",38.91,-80.74,43106134],
		["Lahmansville","WV",39.12,-79.08,43106193],
		["Sugar Grove","WV",38.51,-79.32,43106252],
		["Pope Army Airfield","NC",35.17,-79.02,43106311],
		["Jacksonboro","SC",32.77,-80.45,43106370],
		["Du Pont","GA",30.98,-82.86,43106429],
		["Canal Point","FL",26.86,-80.63,43106488],
		["Magnolia Springs","AL",30.4,-87.77,43106547],
		["Redwood","MS",32.5,-90.79,43106606],
		["Morgan","GA",31.55,-84.62,43106665],
		["Kirkersville","OH",39.95,-82.59,43106724],
		["Adelphi","OH",39.46,-82.74,43106783],
		["Empire","OH",40.51,-80.62,43106842],
		["Beaver Island","MI",45.65,-85.55,43106901],
		["Harvey","IA",41.31,-92.92,43106960],
		["Quimby","IA",42.62,-95.64,43107019],
		["Irwin","IA",41.79,-95.2,43107078],
		["Watkins","IA",41.88,-91.98,43107137],
		["Mattoon","WI",45,-89.04,43107196],
		["Holland","MN",44.09,-96.19,43107255],
		["Marietta","MN",45.01,-96.41,43107314],
		["Twin Brooks","SD",45.2,-96.78,43107373],
		["Howes","SD",44.62,-102.04,43107432],
		["Fingal","ND",46.76,-97.79,43107491],
		["Oriska","ND",46.93,-97.78,43107550],
		["Forbes","ND",45.94,-98.78,43107609],
		["Solen","ND",46.38,-100.79,43107668],
		["Zap","ND",47.28,-101.92,43107727],
		["Greycliff","MT",45.72,-109.75,43107786],
		["Lavina","MT",46.44,-109,43107845],
		["Broadlands","IL",39.9,-87.99,43107904],
		["Coatsburg","IL",40.03,-91.15,43107963],
		["Palmer","IL",39.45,-89.4,43108022],
		["New Haven","IL",37.9,-88.12,43108081],
		["Kansas City","MO",39.09,-94.58,43108140],
		["Amity","MO",39.86,-94.43,43108199],
		["Laclede","MO",39.78,-93.16,43108258],
		["Lynchburg","MO",37.49,-92.29,43108317],
		["Prescott","KS",38.06,-94.69,43108376],
		["Jamestown","KS",39.59,-97.86,43108435],
		["Taylor","NE",41.76,-99.38,43108494],
		["Grant","LA",30.78,-92.94,43108553],
		["Parkdale","AR",33.12,-91.54,43108612],
		["Higginson","AR",35.19,-91.71,43108671],
		["Alicia","AR",35.89,-91.08,43108730],
		["Hasty","AR",36.01,-93.04,43108789],
		["Roosevelt","OK",34.84,-99.02,43108848],
		["Muldoon","TX",29.81,-97.06,43108907],
		["Loop","TX",32.92,-102.42,43108966],
		["Solomon","AZ",32.8,-109.61,43109025],
		["San Simeon","CA",35.69,-121.2,43109084],
		["Myers Flat","CA",40.27,-123.82,43109143],
		["Grenada","CA",41.6,-122.54,43109202],
		["Langlois","OR",42.91,-124.35,43109261],
		["Paisley","OR",42.61,-120.57,43109320],
		["Mineral","WA",46.71,-122.11,43109379],
		["Hydaburg","AK",55.2,-132.81,43109438],
		["Beals","ME",44.48,-67.58,43109496],
		["Whiting","ME",44.79,-67.17,43109554],
		["Blooming Grove","NY",41.35,-74.19,43109612],
		["Claverack","NY",42.22,-73.72,43109670],
		["Beaver Falls","NY",43.87,-75.44,43109728],
		["South Colton","NY",44.5,-74.88,43109786],
		["Slovan","PA",40.36,-80.4,43109844],
		["Waterfall","PA",40.12,-78.06,43109902],
		["Cumbola","PA",40.72,-76.15,43109960],
		["Hume","VA",38.83,-77.99,43110018],
		["Walkerton","VA",37.72,-77.02,43110076],
		["Vesuvius","VA",37.9,-79.2,43110134],
		["Martin","SC",33.06,-81.47,43110192],
		["Augusta","GA",33.45,-81.99,43110250],
		["Sipsey","AL",33.82,-87.08,43110308],
		["Hardaway","AL",32.3,-85.87,43110366],
		["Gallaway","TN",35.33,-89.6,43110424],
		["Stone","KY",37.56,-82.27,43110482],
		["Slemp","KY",37.07,-83.11,43110540],
		["Port Jefferson","OH",40.33,-84.09,43110598],
		["Edwardsport","IN",38.81,-87.25,43110656],
		["Saint Croix","IN",38.22,-86.61,43110714],
		["Naubinway","MI",46.11,-85.45,43110772],
		["Shingleton","MI",46.37,-86.48,43110830],
		["Harcourt","IA",42.26,-94.17,43110888],
		["Castana","IA",42.07,-95.9,43110946],
		["Sioux City","IA",42.5,-96.39,43111004],
		["Arnolds Park","IA",43.36,-95.13,43111062],
		["Melrose","IA",40.98,-93.04,43111120],
		["Hardwick","MN",43.77,-96.19,43111178],
		["Donnelly","MN",45.69,-96.01,43111236],
		["Belfry","MT",45.1,-109.01,43111294],
		["Rosebud","MT",46.46,-106.39,43111352],
		["Moore","MT",46.88,-109.61,43111410],
		["Golf","IL",42.05,-87.78,43111468],
		["Deer Grove","IL",41.61,-89.68,43111526],
		["Sibley","IL",40.58,-88.37,43111584],
		["Yale","IL",39.12,-88.02,43111642],
		["Alexander","IL",39.71,-90.05,43111700],
		["Rinard","IL",38.58,-88.47,43111758],
		["Junction","IL",37.72,-88.23,43111816],
		["Saint Albans","MO",38.57,-90.77,43111874],
		["Monticello","MO",40.11,-91.71,43111932],
		["Kansas City","MO",39.09,-94.58,43111990],
		["Macomb","MO",37.1,-92.48,43112048],
		["Protem","MO",36.52,-92.85,43112106],
		["Farlington","KS",37.61,-94.82,43112164],
		["Dwight","KS",38.84,-96.59,43112222],
		["Hamilton","KS",37.98,-96.16,43112280],
		["Wilsey","KS",38.63,-96.67,43112338],
		["Sharon","KS",37.25,-98.41,43112396],
		["Bazine","KS",38.44,-99.69,43112454],
		["Rush Center","KS",38.46,-99.31,43112512],
		["Lenora","KS",39.61,-100,43112570],
		["Snyder","NE",41.7,-96.78,43112628],
		["Chambers","NE",42.2,-98.74,43112686],
		["Lewellen","NE",41.33,-102.14,43112744],
		["Tryon","NE",41.55,-100.94,43112802],
		["Reyno","AR",36.36,-90.75,43112860],
		["Rocky","OK",35.15,-99.05,43112918],
		["Tynan","TX",28.15,-97.73,43112976],
		["Sheridan Lake","CO",38.46,-102.29,43113034],
		["Altonah","UT",40.47,-110.32,43113092],
		["Trinity Center","CA",41.05,-122.78,43113150],
		["Endicott","WA",46.95,-117.73,43113208],
		["Metaline Falls","WA",48.89,-117.21,43113266],
		["South Barre","MA",42.38,-72.09,43113323],
		["Vienna","ME",44.53,-69.98,43113380],
		["Orient","ME",45.81,-67.84,43113437],
		["East Randolph","VT",43.93,-72.55,43113494],
		["Topsham","VT",44.09,-72.22,43113551],
		["South Windham","CT",41.68,-72.18,43113608],
		["East Schodack","NY",42.52,-73.68,43113665],
		["Clemons","NY",43.63,-73.43,43113722],
		["Newcomb","NY",43.97,-74.17,43113779],
		["Olmstedville","NY",43.86,-74.06,43113836],
		["Owls Head","NY",44.71,-74.08,43113893],
		["Harpersfield","NY",42.43,-74.68,43113950],
		["Crystal Spring","PA",39.93,-78.21,43114007],
		["Adamsburg","PA",40.31,-79.65,43114064],
		["Grapeville","PA",40.3,-79.59,43114121],
		["Saint Benedict","PA",40.6,-78.73,43114178],
		["Bruin","PA",41.05,-79.72,43114235],
		["Moshannon","PA",41.02,-78.04,43114292],
		["Shartlesville","PA",40.44,-76.11,43114349],
		["Dowell","MD",38.35,-76.44,43114406],
		["Champlain","VA",38.01,-76.99,43114463],
		["White Plains","VA",36.63,-77.91,43114520],
		["Talcott","WV",37.65,-80.75,43114577],
		["Lookout","WV",38.05,-80.89,43114634],
		["Belva","WV",38.26,-81.13,43114691],
		["Pine Level","NC",35.51,-78.24,43114748],
		["Piney Creek","NC",36.55,-81.3,43114805],
		["Guild","TN",35.01,-85.5,43114862],
		["Bronwood","GA",31.82,-84.32,43114919],
		["Saint Charles","KY",37.18,-87.55,43114976],
		["Haydenville","OH",39.49,-82.29,43115033],
		["Lithopolis","OH",39.8,-82.81,43115090],
		["Mark Center","OH",41.29,-84.63,43115147],
		["Neffs","OH",40.02,-80.81,43115204],
		["Grafton","IA",43.33,-93.06,43115261],
		["Hastings","IA",41.02,-95.49,43115318],
		["Kimballton","IA",41.62,-95.07,43115375],
		["Delta","IA",41.32,-92.32,43115432],
		["Francis Creek","WI",44.2,-87.72,43115489],
		["Stockholm","WI",44.48,-92.26,43115546],
		["Radisson","WI",45.76,-91.21,43115603],
		["Vermillion","MN",44.67,-92.96,43115660],
		["Georgetown","MN",47.07,-96.79,43115717],
		["Mellette","SD",45.15,-98.49,43115774],
		["Isabel","SD",45.39,-101.42,43115831],
		["Page","ND",47.15,-97.57,43115888],
		["South Heart","ND",46.86,-102.99,43115945],
		["Des Lacs","ND",48.25,-101.56,43116002],
		["Sherwood","ND",48.96,-101.63,43116059],
		["Cardwell","MT",45.78,-111.98,43116116],
		["Martin City","MT",48.38,-114,43116173],
		["Armstrong","IL",40.31,-87.88,43116230],
		["Butler","IL",39.19,-89.53,43116287],
		["Pierron","IL",38.77,-89.56,43116344],
		["Villa Ridge","IL",37.15,-89.17,43116401],
		["Friedheim","MO",37.55,-89.84,43116458],
		["Welda","KS",38.16,-95.32,43116515],
		["Lebanon","KS",39.81,-98.55,43116572],
		["Geneseo","KS",38.51,-98.15,43116629],
		["New Cambria","KS",38.87,-97.5,43116686],
		["Ithaca","NE",41.16,-96.53,43116743],
		["Bartley","NE",40.25,-100.3,43116800],
		["Champion","NE",40.46,-101.71,43116857],
		["Grand Coteau","LA",30.41,-92.04,43116914],
		["Oak Grove","AR",36.45,-93.43,43116971],
		["Aline","OK",36.5,-98.44,43117028],
		["Cleo Springs","OK",36.4,-98.43,43117085],
		["Moyers","OK",34.32,-95.65,43117142],
		["Bethel","OK",34.35,-94.85,43117199],
		["Lake George","CO",38.98,-105.37,43117256],
		["Laketown","UT",41.81,-111.37,43117313],
		["Henrieville","UT",37.64,-111.92,43117370],
		["Young","AZ",34.08,-111.01,43117427],
		["Cliff","NM",32.96,-108.61,43117484],
		["San Jon","NM",35.11,-103.32,43117541],
		["Avila Beach","CA",35.18,-120.76,43117598],
		["Dunlap","CA",36.79,-119.13,43117655],
		["Wallace","CA",38.19,-120.95,43117712],
		["Long Barn","CA",38.12,-120.08,43117769],
		["Bly","OR",42.37,-121,43117826],
		["Paterson","WA",45.94,-119.66,43117883],
		["Huslia","AK",65.69,-156.35,43117940],
		["Teller","AK",65.25,-166.36,43117997],
		["Nuiqsut","AK",69.83,-152.14,43118054],
		["Sebec","ME",45.27,-69.11,43118110],
		["Cutler","ME",44.69,-67.21,43118166],
		["Isle La Motte","VT",44.87,-73.33,43118222],
		["Westfield","VT",44.88,-72.42,43118278],
		["Marion","CT",41.56,-72.92,43118334],
		["Tribes Hill","NY",42.94,-74.3,43118390],
		["Hague","NY",43.74,-73.49,43118446],
		["Lyon Mountain","NY",44.72,-73.92,43118502],
		["Skaneateles Falls","NY",42.99,-76.45,43118558],
		["Brier Hill","NY",44.54,-75.68,43118614],
		["Hiller","PA",40,-79.9,43118670],
		["Hyde Park","PA",40.63,-79.58,43118726],
		["Torrance","PA",40.36,-79.3,43118782],
		["Clark","PA",41.28,-80.4,43118838],
		["Harrisburg","PA",40.27,-76.88,43118894],
		["Tyler Hill","PA",41.69,-75.1,43118950],
		["Skippack","PA",40.22,-75.4,43119006],
		["Park Hall","MD",38.22,-76.44,43119062],
		["Upper Falls","MD",39.43,-76.4,43119118],
		["Bergton","VA",38.79,-78.96,43119174],
		["Pence Springs","WV",37.67,-80.7,43119230],
		["Dawes","WV",38.06,-81.42,43119286],
		["Rock Creek","WV",37.83,-81.48,43119342],
		["Ravencliff","WV",37.69,-81.49,43119398],
		["Mount Zion","WV",38.89,-81.12,43119454],
		["Rockledge","GA",32.36,-82.74,43119510],
		["Jacksonville","FL",30.33,-81.65,43119566],
		["Horseshoe Beach","FL",29.44,-83.28,43119622],
		["Magnolia","AL",32.13,-87.66,43119678],
		["Coleman","GA",31.66,-84.87,43119734],
		["Siler","KY",36.69,-83.96,43119790],
		["Denton","KY",38.28,-82.82,43119846],
		["Sedalia","OH",39.73,-83.31,43119902],
		["Grand River","OH",41.74,-81.28,43119958],
		["Jacksonville","OH",39.47,-82.08,43120014],
		["Orestes","IN",40.27,-85.72,43120070],
		["Schneider","IN",41.19,-87.44,43120126],
		["Kinross","MI",46.26,-84.51,43120182],
		["Garden Grove","IA",40.82,-93.6,43120238],
		["Van Wert","IA",40.87,-93.79,43120294],
		["Dedham","IA",41.9,-94.82,43120350],
		["Little Sioux","IA",41.8,-96.02,43120406],
		["Andrew","IA",42.15,-90.59,43120462],
		["University Park","IA",41.28,-92.61,43120518],
		["Makinen","MN",47.35,-92.36,43120574],
		["Easton","MN",43.76,-93.9,43120630],
		["Mc Grath","MN",46.24,-93.27,43120686],
		["Dallas","SD",43.23,-99.51,43120742],
		["Mooreton","ND",46.26,-96.87,43120798],
		["Edinburg","ND",48.49,-97.86,43120854],
		["Minnewaukan","ND",48.06,-99.25,43120910],
		["Garryowen","MT",45.5,-107.26,43120966],
		["Roberts","IL",40.61,-88.18,43121022],
		["Cisco","IL",40.01,-88.72,43121078],
		["Ivesdale","IL",39.94,-88.45,43121134],
		["Joppa","IL",37.2,-88.84,43121190],
		["New Burnside","IL",37.57,-88.77,43121246],
		["Wyaconda","MO",40.39,-91.92,43121302],
		["Wyatt","MO",36.91,-89.22,43121358],
		["Mendon","MO",39.59,-93.13,43121414],
		["Portland","MO",38.71,-91.71,43121470],
		["Ramona","KS",38.59,-97.06,43121526],
		["Grainfield","KS",39.11,-100.46,43121582],
		["Grinnell","KS",39.12,-100.63,43121638],
		["Malmo","NE",41.26,-96.72,43121694],
		["Filley","NE",40.28,-96.53,43121750],
		["Milligan","NE",40.49,-97.38,43121806],
		["Mermentau","LA",30.18,-92.58,43121862],
		["Glynn","LA",30.63,-91.33,43121918],
		["Arkansas City","AR",33.6,-91.2,43121974],
		["Calion","AR",33.32,-92.53,43122030],
		["Wilburn","AR",35.44,-91.9,43122086],
		["Cromwell","OK",35.34,-96.45,43122142],
		["Mc Leod","TX",32.95,-94.07,43122198],
		["Forreston","TX",32.27,-96.89,43122254],
		["San Perlita","TX",26.5,-97.63,43122310],
		["Glidden","TX",29.67,-96.54,43122366],
		["Creede","CO",37.85,-106.92,43122422],
		["Pleasant View","CO",37.58,-108.76,43122478],
		["Bouse","AZ",33.93,-113.86,43122534],
		["Gallina","NM",36.23,-106.85,43122590],
		["Caballo","NM",32.97,-107.3,43122646],
		["Garfield","NM",32.75,-107.26,43122702],
		["Orovada","NV",41.63,-118,43122758],
		["Angelus Oaks","CA",34.18,-116.86,43122814],
		["June Lake","CA",37.77,-119.06,43122870],
		["Miramonte","CA",36.7,-119.04,43122926],
		["Albion","WA",46.79,-117.25,43122982],
		["Shungnak","AK",66.88,-157.15,43123038],
		["Lenox Dale","MA",42.36,-73.27,43123093],
		["Bass Harbor","ME",44.15,-68.43,43123148],
		["Oneco","CT",41.69,-71.81,43123203],
		["Long Eddy","NY",41.85,-75.13,43123258],
		["Felts Mills","NY",44.01,-75.74,43123313],
		["Bovina Center","NY",42.26,-74.78,43123368],
		["Farnham","NY",42.59,-79.07,43123423],
		["Java Center","NY",42.66,-78.39,43123478],
		["Meadow Lands","PA",40.25,-80.24,43123533],
		["Fairbank","PA",39.98,-79.83,43123588],
		["Ardara","PA",40.36,-79.73,43123643],
		["Ernest","PA",40.67,-79.16,43123698],
		["Reno","PA",41.42,-79.74,43123753],
		["Allport","PA",40.97,-78.21,43123808],
		["Pine Grove Mills","PA",40.73,-77.87,43123863],
		["South Mountain","PA",39.83,-77.49,43123918],
		["Gilberton","PA",40.79,-76.22,43123973],
		["Lakewood","PA",41.85,-75.38,43124028],
		["Broomes Island","MD",38.41,-76.54,43124083],
		["Dundas","VA",36.91,-78.02,43124138],
		["Critz","VA",36.63,-80.15,43124193],
		["Indian Valley","VA",36.91,-80.55,43124248],
		["Bishop","VA",37.21,-81.53,43124303],
		["Justice","WV",37.59,-81.84,43124358],
		["Roderfield","WV",37.46,-81.67,43124413],
		["Williamsburg","WV",37.96,-80.5,43124468],
		["Hacker Valley","WV",38.67,-80.39,43124523],
		["Coxs Mills","WV",39,-80.86,43124578],
		["Heaters","WV",38.75,-80.65,43124633],
		["Delray","WV",39.19,-78.6,43124688],
		["Bloomery","WV",39.38,-78.37,43124743],
		["Avon","NC",35.34,-75.5,43124798],
		["Scotia","SC",32.68,-81.24,43124853],
		["Oakfield","GA",31.77,-83.97,43124908],
		["Olustee","FL",30.2,-82.42,43124963],
		["Duncan","MS",34.04,-90.74,43125018],
		["Helton","KY",36.95,-83.39,43125073],
		["Frakes","KY",36.64,-83.92,43125128],
		["Rumsey","KY",37.49,-87.28,43125183],
		["Lineville","IA",40.58,-93.52,43125238],
		["Mc Callsburg","IA",42.16,-93.39,43125293],
		["Woden","IA",43.23,-93.91,43125348],
		["Moorland","IA",42.44,-94.29,43125403],
		["Cantril","IA",40.64,-92.06,43125458],
		["Rose Hill","IA",41.32,-92.46,43125513],
		["Sayner","WI",46,-89.53,43125568],
		["Soudan","MN",47.81,-92.23,43125623],
		["Waltham","MN",43.82,-92.87,43125678],
		["Hendrum","MN",47.26,-96.81,43125733],
		["Vining","MN",46.26,-95.53,43125788],
		["Oacoma","SD",43.79,-99.38,43125843],
		["Northville","SD",45.15,-98.57,43125898],
		["Fessenden","ND",47.64,-99.62,43125953],
		["Mcclusky","ND",47.48,-100.44,43126008],
		["Montpelier","ND",46.69,-98.58,43126063],
		["Stockett","MT",47.2,-111.2,43126118],
		["Lima","MT",44.61,-112.27,43126173],
		["Sutter","IL",40.28,-91.35,43126228],
		["Timewell","IL",40.01,-90.85,43126283],
		["Mode","IL",39.28,-88.63,43126338],
		["Whittington","IL",38.1,-88.87,43126393],
		["Maitland","MO",40.2,-95.07,43126448],
		["Havensville","KS",39.51,-96.07,43126503],
		["Sawyer","KS",37.49,-98.68,43126558],
		["Dennis","KS",37.35,-95.42,43126613],
		["Bushton","KS",38.51,-98.39,43126668],
		["Garfield","KS",38.07,-99.24,43126723],
		["Ford","KS",37.63,-99.75,43126778],
		["Talmage","NE",40.53,-96.02,43126833],
		["Western","NE",40.39,-97.19,43126888],
		["Elsie","NE",40.84,-101.38,43126943],
		["Gurley","NE",41.32,-102.97,43126998],
		["Grand Chenier","LA",29.76,-92.97,43127053],
		["Peel","AR",36.41,-92.76,43127108],
		["Pelsor","AR",35.68,-93.05,43127163],
		["Braman","OK",36.92,-97.33,43127218],
		["Windom","TX",33.56,-95.99,43127273],
		["Loving","TX",33.27,-98.48,43127328],
		["Malone","TX",31.91,-96.89,43127383],
		["Penelope","TX",31.85,-96.92,43127438],
		["Tuleta","TX",28.56,-97.8,43127493],
		["Cost","TX",29.43,-97.56,43127548],
		["Lenorah","TX",32.31,-101.88,43127603],
		["Atwood","CO",40.55,-103.27,43127658],
		["Silverton","CO",37.82,-107.64,43127713],
		["Sunnyside","UT",39.67,-110.58,43127768],
		["Fayette","UT",39.29,-111.8,43127823],
		["Leamington","UT",39.51,-112.26,43127878],
		["Elida","NM",33.94,-103.65,43127933],
		["Lookout","CA",41.3,-121.1,43127988],
		["Nordland","WA",48.05,-122.68,43128043],
		["Mcchord Afb","WA",47.25,-122.44,43128098],
		["Mansfield","WA",47.89,-119.5,43128153],
		["King Salmon","AK",58.75,-156.54,43128208],
		["Nightmute","AK",60.49,-164.81,43128263],
		["Fayville","MA",42.29,-71.5,43128317],
		["Dorchester","NJ",39.28,-74.94,43128371],
		["Redfield","NY",43.59,-75.81,43128425],
		["Stockdale","PA",40.08,-79.85,43128479],
		["Wheatland","PA",41.19,-80.49,43128533],
		["Parryville","PA",40.82,-75.66,43128587],
		["Mountainhome","PA",41.17,-75.26,43128641],
		["Prompton","PA",41.58,-75.32,43128695],
		["Line Lexington","PA",40.29,-75.25,43128749],
		["Cheswold","DE",39.21,-75.58,43128803],
		["Midland","MD",39.58,-78.94,43128857],
		["Woolford","MD",38.5,-76.18,43128911],
		["Linkwood","MD",38.54,-75.94,43128965],
		["Fanrock","WV",37.56,-81.62,43129019],
		["Matheny","WV",37.68,-81.59,43129073],
		["Paynesville","WV",37.36,-81.66,43129127],
		["Clothier","WV",37.79,-81.99,43129181],
		["Sandstone","WV",37.76,-80.88,43129235],
		["Tioga","WV",38.39,-80.67,43129289],
		["Green Spring","WV",39.49,-78.62,43129343],
		["Wing","AL",31.02,-86.61,43129397],
		["Hatchechubbee","AL",32.28,-85.3,43129451],
		["Stambaugh","KY",37.88,-82.79,43129505],
		["Shelby Gap","KY",37.21,-82.55,43129559],
		["Krypton","KY",37.31,-83.33,43129613],
		["Tiline","KY",37.16,-88.25,43129667],
		["Dubre","KY",36.83,-85.55,43129721],
		["Green Camp","OH",40.53,-83.2,43129775],
		["Brooklyn","IN",39.54,-86.37,43129829],
		["Crandall","IN",38.28,-86.06,43129883],
		["Arcadia","MI",44.51,-86.23,43129937],
		["Spalding","MI",45.69,-87.51,43129991],
		["Liscomb","IA",42.19,-93,43130045],
		["Melvin","IA",43.28,-95.6,43130099],
		["Elberon","IA",42,-92.31,43130153],
		["Danvers","MN",45.28,-95.75,43130207],
		["Lucan","MN",44.4,-95.41,43130261],
		["Hitterdal","MN",46.97,-96.25,43130315],
		["Viking","MN",48.21,-96.4,43130369],
		["Trent","SD",43.9,-96.65,43130423],
		["Brandt","SD",44.66,-96.62,43130477],
		["Mansfield","SD",45.24,-98.56,43130531],
		["Warwick","ND",47.85,-98.7,43130585],
		["Regent","ND",46.42,-102.55,43130639],
		["Dixon","MT",47.25,-114.35,43130693],
		["Cropsey","IL",40.6,-88.48,43130747],
		["Rockwood","IL",37.83,-89.69,43130801],
		["Chestnut","IL",40.06,-89.2,43130855],
		["Tovey","IL",39.58,-89.44,43130909],
		["Hettick","IL",39.35,-90.03,43130963],
		["Sims","IL",38.36,-88.53,43131017],
		["Herod","IL",37.48,-88.47,43131071],
		["Fletcher","MO",38.16,-90.73,43131125],
		["Guilford","MO",40.16,-94.73,43131179],
		["Tina","MO",39.53,-93.44,43131233],
		["Cedarcreek","MO",36.56,-92.99,43131287],
		["Morrill","KS",39.92,-95.69,43131341],
		["Brunswick","NE",42.33,-97.97,43131395],
		["Mason City","NE",41.22,-99.29,43131449],
		["Holbrook","NE",40.3,-100.01,43131503],
		["Stamford","NE",40.13,-99.59,43131557],
		["Broadwater","NE",41.59,-102.85,43131611],
		["Chataignier","LA",30.56,-92.31,43131665],
		["Butler","OK",35.63,-99.18,43131719],
		["Avant","OK",36.48,-96.06,43131773],
		["Strang","OK",36.41,-95.13,43131827],
		["Alderson","OK",34.9,-95.69,43131881],
		["Millerton","OK",33.98,-95.01,43131935],
		["Sulphur Bluff","TX",33.33,-95.36,43131989],
		["Brookesmith","TX",31.55,-99.11,43132043],
		["Rock Island","TX",29.56,-96.59,43132097],
		["Kendalia","TX",29.96,-98.51,43132151],
		["Leesville","TX",29.39,-97.77,43132205],
		["Briggs","TX",30.86,-97.91,43132259],
		["Eckley","CO",40.11,-102.48,43132313],
		["Hillsdale","WY",41.21,-104.48,43132367],
		["Pierce","ID",46.56,-115.81,43132421],
		["Collinston","UT",41.79,-112.06,43132475],
		["Ponderosa","NM",34.98,-106.3,43132529],
		["Dyer","NV",37.68,-117.93,43132583],
		["Surfside","CA",33.72,-118.08,43132637],
		["Olancha","CA",36.26,-117.95,43132691],
		["Bass Lake","CA",37.38,-119.51,43132745],
		["San Lucas","CA",36.13,-120.86,43132799],
		["Knightsen","CA",37.97,-121.65,43132853],
		["Tres Pinos","CA",36.77,-121.29,43132907],
		["Samoa","CA",40.79,-124.19,43132961],
		["Montgomery Creek","CA",40.86,-121.91,43133015],
		["Litchfield","CA",40.51,-120.31,43133069],
		["Sprague River","OR",42.43,-121.41,43133123],
		["Preston","WA",47.53,-121.92,43133177],
		["Wishram","WA",45.66,-120.92,43133231],
		["Malo","WA",48.81,-118.61,43133285],
		["Elim","AK",64.63,-162.34,43133339],
		["Walpole","ME",43.94,-69.55,43133392],
		["Florence","VT",43.7,-73.08,43133445],
		["Denver","NY",42.21,-74.56,43133498],
		["Oak Hill","NY",42.4,-74.14,43133551],
		["White Sulphur Springs","NY",41.79,-74.82,43133604],
		["De Peyster","NY",44.53,-75.41,43133657],
		["Morristown","NY",44.58,-75.64,43133710],
		["Manorville","PA",40.78,-79.52,43133763],
		["Middleport","PA",40.72,-76.08,43133816],
		["Lake Harmony","PA",41.05,-75.66,43133869],
		["Chester Heights","PA",39.89,-75.46,43133922],
		["Charlton Heights","WV",38.11,-81.21,43133975],
		["Pond Gap","WV",38.28,-81.28,43134028],
		["Crawford","WV",38.83,-80.4,43134081],
		["Harbinger","NC",36.1,-75.81,43134134],
		["Rutherford College","NC",35.75,-81.52,43134187],
		["Wewahitchka","FL",30.26,-85.24,43134240],
		["Whitfield","MS",32.12,-90.13,43134293],
		["Napoleon","MI",42.16,-84.24,43134346],
		["Leland","MI",45.01,-85.76,43134399],
		["Dodgeville","MI",47.09,-88.57,43134452],
		["Grand River","IA",40.81,-93.96,43134505],
		["Swaledale","IA",42.97,-93.31,43134558],
		["Geneva","IA",42.67,-93.13,43134611],
		["Mount Auburn","IA",42.25,-92.09,43134664],
		["Avalon","WI",42.65,-88.83,43134717],
		["High Bridge","WI",46.38,-90.73,43134770],
		["Poy Sippi","WI",44.13,-89,43134823],
		["Hayward","MN",43.64,-93.24,43134876],
		["Federal Dam","MN",47.23,-94.21,43134929],
		["Egan","SD",43.99,-96.65,43134982],
		["Canova","SD",43.88,-97.5,43135035],
		["Saint Lawrence","SD",44.51,-98.94,43135088],
		["Meadow","SD",45.53,-102.21,43135141],
		["Mcville","ND",47.76,-98.17,43135194],
		["Munich","ND",48.66,-98.83,43135247],
		["Saint Xavier","MT",45.36,-108.02,43135300],
		["Sun River","MT",47.47,-111.8,43135353],
		["Hume","IL",39.79,-87.86,43135406],
		["Buckner","IL",37.98,-89.01,43135459],
		["Farber","MO",39.27,-91.57,43135512],
		["Glenwood","MO",40.52,-92.57,43135565],
		["Deerfield","MO",37.83,-94.5,43135618],
		["Goessel","KS",38.24,-97.34,43135671],
		["Fifty Six","AR",36.02,-92.22,43135724],
		["Onia","AR",35.94,-92.33,43135777],
		["Hastings","OK",34.22,-98.1,43135830],
		["Headrick","OK",34.62,-99.13,43135883],
		["Ames","OK",36.24,-98.18,43135936],
		["Goltry","OK",36.53,-98.15,43135989],
		["Spencerville","OK",34.13,-95.39,43136042],
		["Berclair","TX",28.5,-97.53,43136095],
		["Encino","TX",26.93,-98.13,43136148],
		["Los Ebanos","TX",26.33,-98.46,43136201],
		["Dinosaur","CO",40.24,-109,43136254],
		["Saint Charles","ID",42.14,-111.47,43136307],
		["Peck","ID",46.42,-116.46,43136360],
		["Fruitland","UT",40.15,-110.98,43136413],
		["Fort Apache","AZ",34.05,-109.88,43136466],
		["Cerro","NM",36.75,-105.61,43136519],
		["Mt Baldy","CA",34.2,-117.7,43136572],
		["Huntington","OR",44.45,-117.38,43136625],
		["Copalis Beach","WA",47.09,-124.13,43136678],
		["Belvidere Center","VT",44.75,-72.68,43136730],
		["Calais","VT",44.38,-72.43,43136782],
		["Delmont","NJ",39.2,-74.97,43136834],
		["Rensselaerville","NY",42.51,-74.15,43136886],
		["Stottville","NY",42.28,-73.75,43136938],
		["Masonville","NY",42.24,-75.37,43136990],
		["Yorkshire","NY",42.52,-78.47,43137042],
		["Wyano","PA",40.19,-79.73,43137094],
		["Youngstown","PA",40.28,-79.36,43137146],
		["Force","PA",41.26,-78.49,43137198],
		["Portland","PA",40.91,-75.09,43137250],
		["Paupack","PA",41.39,-75.19,43137302],
		["Odessa","DE",39.45,-75.66,43137354],
		["Greenwood","VA",38.05,-78.77,43137406],
		["Jamaica","VA",37.71,-76.69,43137458],
		["Oak Hall","VA",37.93,-75.54,43137510],
		["Coal Mountain","WV",37.67,-81.73,43137562],
		["Spanishburg","WV",37.45,-81.11,43137614],
		["Rosedale","WV",38.78,-80.89,43137666],
		["Saratoga","NC",35.65,-77.77,43137718],
		["Bridgeton","NC",35.12,-77.02,43137770],
		["Linville","NC",36.08,-81.85,43137822],
		["Boca Grande","FL",26.69,-82.25,43137874],
		["Docena","AL",33.56,-86.89,43137926],
		["Darling","MS",34.35,-90.26,43137978],
		["Rochester","KY",37.2,-86.89,43138030],
		["Mowrystown","OH",39.03,-83.75,43138082],
		["Shelby","IN",41.19,-87.34,43138134],
		["Painesdale","MI",47.03,-88.68,43138186],
		["Swan","IA",41.46,-93.3,43138238],
		["Weldon","IA",40.89,-93.73,43138290],
		["Linn Grove","IA",42.89,-95.24,43138342],
		["Harris","IA",43.44,-95.43,43138394],
		["Delavan","MN",43.76,-94.01,43138446],
		["Woodstock","MN",44.01,-96.09,43138498],
		["Porter","MN",44.64,-96.16,43138550],
		["Bluffton","MN",46.46,-95.23,43138602],
		["Ypsilanti","ND",46.78,-98.55,43138654],
		["Trenton","ND",48.06,-103.85,43138706],
		["Galata","MT",48.71,-111.35,43138758],
		["Gildford","MT",48.69,-110.25,43138810],
		["Olney","MT",48.59,-114.69,43138862],
		["Summerfield","IL",38.59,-89.74,43138914],
		["West Liberty","IL",38.86,-88.08,43138966],
		["Vanzant","MO",36.96,-92.3,43139018],
		["Greenleaf","KS",39.72,-96.97,43139070],
		["Milton","KS",37.43,-97.75,43139122],
		["Wichita","KS",37.68,-97.34,43139174],
		["Offerle","KS",37.89,-99.56,43139226],
		["Agra","KS",39.76,-99.11,43139278],
		["Wolbach","NE",41.4,-98.39,43139330],
		["Long Pine","NE",42.53,-99.7,43139382],
		["Hester","LA",30.03,-90.75,43139434],
		["Franklin","AR",36.17,-91.77,43139486],
		["Parks","AR",34.79,-93.87,43139538],
		["Taloga","OK",36.04,-98.96,43139590],
		["Hardesty","OK",36.61,-101.19,43139642],
		["Atwood","OK",34.95,-96.33,43139694],
		["Rosser","TX",32.46,-96.44,43139746],
		["Hudson","WY",42.9,-108.58,43139798],
		["Fort Hall","ID",43.04,-112.53,43139850],
		["Kirtland Afb","NM",35.05,-106.59,43139902],
		["Maxwell","NM",36.54,-104.54,43139954],
		["Wagon Mound","NM",36,-104.71,43140006],
		["Lee Vining","CA",38.03,-118.82,43140058],
		["Manzanita","OR",45.77,-123.96,43140110],
		["Pacific Beach","WA",47.21,-124.19,43140162],
		["Chelan Falls","WA",47.79,-119.99,43140214],
		["Akiak","AK",60.9,-161.29,43140266],
		["Rowe","MA",42.7,-72.9,43140317],
		["Sugar Hill","NH",44.23,-71.78,43140368],
		["Grand Isle","ME",47.3,-68.15,43140419],
		["Glenford","NY",42,-74.15,43140470],
		["Blandburg","PA",40.69,-78.44,43140521],
		["Plainfield","PA",40.19,-77.42,43140572],
		["State Line","PA",39.73,-77.71,43140623],
		["Bohannon","VA",37.39,-76.35,43140674],
		["Cheriton","VA",37.28,-75.96,43140725],
		["Howardsville","VA",37.73,-78.64,43140776],
		["Left Hand","WV",38.61,-81.24,43140827],
		["Mount Alto","WV",38.86,-81.87,43140878],
		["Edmond","WV",38.05,-81.03,43140929],
		["Seneca Rocks","WV",38.83,-79.37,43140980],
		["Vandemere","NC",35.18,-76.66,43141031],
		["Glendale","SC",34.95,-81.85,43141082],
		["Panola","AL",32.96,-88.29,43141133],
		["Trenton","AL",34.74,-86.27,43141184],
		["Ulysses","KY",38,-82.71,43141235],
		["Regina","KY",37.35,-82.37,43141286],
		["Sweeden","KY",37.25,-86.28,43141337],
		["Buffalo","OH",39.93,-81.5,43141388],
		["Falmouth","IN",39.74,-85.35,43141439],
		["Ewen","MI",46.54,-89.3,43141490],
		["Lu Verne","IA",42.9,-94.08,43141541],
		["Marathon","IA",42.86,-94.98,43141592],
		["Buckingham","IA",42.25,-92.41,43141643],
		["Saint Olaf","IA",42.92,-91.38,43141694],
		["Volga","IA",42.8,-91.54,43141745],
		["Willernie","MN",45.05,-92.95,43141796],
		["Alpha","MN",43.63,-94.87,43141847],
		["Watson","MN",45.01,-95.79,43141898],
		["Saint Martin","MN",45.5,-94.66,43141949],
		["Bena","MN",47.34,-94.2,43142000],
		["Ramona","SD",44.12,-97.21,43142051],
		["Tolna","ND",47.82,-98.43,43142102],
		["Reed Point","MT",45.63,-109.63,43142153],
		["Toston","MT",46.17,-111.51,43142204],
		["Hall","MT",46.49,-113.26,43142255],
		["Trego","MT",48.64,-114.93,43142306],
		["West Point","IL",40.25,-91.18,43142357],
		["West York","IL",39.17,-87.67,43142408],
		["Manchester","IL",39.54,-90.33,43142459],
		["Lowndes","MO",37.13,-90.25,43142510],
		["Laredo","MO",40.02,-93.44,43142561],
		["Clifton Hill","MO",39.43,-92.66,43142612],
		["Moody","MO",36.52,-91.98,43142663],
		["Morrowville","KS",39.84,-97.17,43142714],
		["Tampa","KS",38.54,-97.15,43142765],
		["Healy","KS",38.6,-100.62,43142816],
		["Rosalie","NE",42.05,-96.51,43142867],
		["Hordville","NE",41.07,-97.89,43142918],
		["Delaplaine","AR",36.23,-90.72,43142969],
		["Evansville","AR",35.8,-94.47,43143020],
		["Rover","AR",34.93,-93.4,43143071],
		["Burbank","OK",36.69,-96.72,43143122],
		["High Island","TX",29.56,-94.38,43143173],
		["Ellinger","TX",29.83,-96.7,43143224],
		["Mobeetie","TX",35.53,-100.44,43143275],
		["Gail","TX",32.77,-101.45,43143326],
		["Central City","CO",39.79,-105.51,43143377],
		["Idalia","CO",39.7,-102.3,43143428],
		["Albin","WY",41.41,-104.1,43143479],
		["Clearmont","WY",44.64,-106.38,43143530],
		["Boulder","WY",42.72,-109.63,43143581],
		["White Bird","ID",45.69,-116.18,43143632],
		["Douglas","AZ",31.52,-109.2,43143683],
		["El Portal","CA",37.65,-119.8,43143734],
		["Lorane","OR",43.82,-123.21,43143785],
		["Hamilton","WA",48.52,-121.99,43143836],
		["Centerville","WA",45.74,-120.93,43143887],
		["Glenwood","WA",45.98,-121.27,43143938],
		["Kahlotus","WA",46.66,-118.56,43143989],
		["Atqasuk","AK",70.48,-157.39,43144040],
		["Boston","MA",42.35,-71.06,43144090],
		["South Strafford","VT",43.83,-72.37,43144140],
		["Austerlitz","NY",42.32,-73.47,43144190],
		["Essex","NY",44.27,-73.39,43144240],
		["Mallory","NY",43.34,-76.08,43144290],
		["Morgan","PA",40.36,-80.15,43144340],
		["Jenners","PA",40.14,-79.08,43144390],
		["Alverton","PA",40.12,-79.56,43144440],
		["Rillton","PA",40.27,-79.72,43144490],
		["Munson","PA",40.96,-78.18,43144540],
		["Lyon Station","PA",40.52,-75.74,43144590],
		["New Berlinville","PA",40.37,-75.67,43144640],
		["Oldhams","VA",38,-76.66,43144690],
		["Locust Dale","VA",38.33,-78.11,43144740],
		["North Spring","WV",37.52,-81.79,43144790],
		["Buckeye","WV",38.19,-80.14,43144840],
		["Robson","WV",38.09,-81.24,43144890],
		["Rhodell","WV",37.6,-81.3,43144940],
		["Petroleum","WV",39.18,-81.25,43144990],
		["Cordova","NC",34.94,-79.82,43145040],
		["Mexico Beach","FL",29.94,-85.4,43145090],
		["Eva","TN",36.11,-87.99,43145140],
		["Lexington","KY",38.04,-84.45,43145190],
		["Catawba","OH",39.99,-83.62,43145240],
		["Beaverdam","OH",40.83,-83.97,43145290],
		["Mecca","IN",39.72,-87.33,43145340],
		["Union Pier","MI",41.81,-86.68,43145390],
		["Dawson","IA",41.84,-94.21,43145440],
		["Hanlontown","IA",43.28,-93.37,43145490],
		["Cushing","IA",42.46,-95.67,43145540],
		["Spragueville","IA",42.07,-90.43,43145590],
		["Floris","IA",40.86,-92.33,43145640],
		["Brantwood","WI",45.56,-90.11,43145690],
		["Manitowish Waters","WI",46.13,-89.88,43145740],
		["Calumet","MN",47.31,-93.26,43145790],
		["Avoca","MN",43.94,-95.64,43145840],
		["Storden","MN",44.03,-95.31,43145890],
		["Christine","ND",46.57,-96.8,43145940],
		["Kulm","ND",46.3,-98.94,43145990],
		["Marion","ND",46.6,-98.33,43146040],
		["Manning","ND",47.23,-102.76,43146090],
		["Drake","ND",47.92,-100.37,43146140],
		["Grass Range","MT",47.02,-108.88,43146190],
		["Froid","MT",48.3,-104.39,43146240],
		["Pendroy","MT",48.08,-112.32,43146290],
		["Osco","IL",41.35,-90.28,43146340],
		["Mineral","IL",41.38,-89.83,43146390],
		["Rutland","IL",40.98,-89.03,43146440],
		["Ellisville","IL",40.62,-90.3,43146490],
		["Smithshire","IL",40.8,-90.78,43146540],
		["Rosamond","IL",39.39,-89.19,43146590],
		["Gorin","MO",40.35,-92,43146640],
		["Mill Spring","MO",37.06,-90.68,43146690],
		["Forest City","MO",39.98,-95.18,43146740],
		["New Hampton","MO",40.26,-94.19,43146790],
		["Cassoday","KS",38.03,-96.63,43146840],
		["Ransom","KS",38.63,-99.93,43146890],
		["Gorham","KS",38.88,-99.02,43146940],
		["Linwood","NE",41.41,-96.93,43146990],
		["Campbell","NE",40.29,-98.73,43147040],
		["Delta","LA",32.32,-90.92,43147090],
		["Roe","AR",34.63,-91.38,43147140],
		["Reydon","OK",35.65,-99.92,43147190],
		["Bowlegs","OK",35.14,-96.66,43147240],
		["Welch","TX",32.93,-102.13,43147290],
		["Carr","CO",40.9,-104.87,43147340],
		["Rockvale","CO",38.36,-105.16,43147390],
		["Winchester","ID",46.21,-116.64,43147440],
		["Portage","UT",41.89,-112.38,43147490],
		["Gila","NM",32.96,-108.57,43147540],
		["Nogal","NM",33.55,-105.7,43147590],
		["Austin","NV",39.54,-117.19,43147640],
		["Green Valley Lake","CA",34.24,-117.07,43147690],
		["Keene","CA",35.23,-118.62,43147740],
		["San Geronimo","CA",38.02,-122.63,43147790],
		["Plymouth","WA",46,-119.18,43147840],
		["Still River","MA",42.49,-71.61,43147889],
		["Perham","ME",46.84,-68.19,43147938],
		["Weston","VT",43.28,-72.8,43147987],
		["South Jamesport","NY",40.92,-72.64,43148036],
		["Langeloth","PA",40.39,-80.44,43148085],
		["Sturgeon","PA",40.41,-80.19,43148134],
		["Muse","PA",40.32,-80.21,43148183],
		["Arona","PA",40.26,-79.65,43148232],
		["Twin Rocks","PA",40.52,-78.85,43148281],
		["Kylertown","PA",40.99,-78.16,43148330],
		["Saltillo","PA",40.21,-78,43148379],
		["Bendersville","PA",39.98,-77.24,43148428],
		["Hartleton","PA",40.89,-77.15,43148477],
		["Drifton","PA",40.99,-75.9,43148526],
		["Tyro","VA",37.82,-79,43148575],
		["Green Bank","WV",38.39,-79.78,43148624],
		["Mount Carbon","WV",38.14,-81.32,43148673],
		["Layland","WV",37.87,-80.99,43148722],
		["Rachel","WV",39.52,-80.29,43148771],
		["Maple","NC",36.41,-76,43148820],
		["Meldrim","GA",32.14,-81.37,43148869],
		["Lawley","AL",32.86,-86.96,43148918],
		["Lancaster","TN",36.09,-85.85,43148967],
		["Fedscreek","KY",37.4,-82.24,43149016],
		["Stanville","KY",37.58,-82.67,43149065],
		["Unionville Center","OH",40.13,-83.34,43149114],
		["Lansing","OH",40.1,-80.75,43149163],
		["Augusta","OH",40.68,-81.04,43149212],
		["Port William","OH",39.55,-83.78,43149261],
		["Central","IN",38.1,-86.15,43149310],
		["Moline","MI",42.72,-85.68,43149359],
		["Haverhill","IA",41.94,-92.96,43149408],
		["South Amana","IA",41.78,-91.96,43149457],
		["Rochester","WI",42.74,-88.22,43149506],
		["Drummond","WI",46.33,-91.25,43149555],
		["Lutsen","MN",47.63,-90.71,43149604],
		["Raymond","SD",44.91,-97.93,43149653],
		["Grand Forks Afb","ND",47.96,-97.38,43149702],
		["Litchville","ND",46.65,-98.19,43149751],
		["Epping","ND",48.28,-103.35,43149800],
		["Custer","MT",46.25,-107.74,43149849],
		["Allerton","IL",39.91,-87.93,43149898],
		["Saint Libory","IL",38.36,-89.71,43149947],
		["Lakewood","IL",39.32,-88.9,43149996],
		["Westboro","MO",40.53,-95.32,43150045],
		["Purdin","MO",39.95,-93.16,43150094],
		["Thornfield","MO",36.7,-92.65,43150143],
		["Corning","KS",39.65,-96.02,43150192],
		["Cedar Creek","NE",41.04,-96.09,43150241],
		["Bee","NE",41,-97.05,43150290],
		["Dawson","NE",40.13,-95.82,43150339],
		["Dunning","NE",41.82,-100.1,43150388],
		["Arthur","NE",41.57,-101.69,43150437],
		["Creole","LA",29.79,-93.11,43150486],
		["Sondheimer","LA",32.57,-91.25,43150535],
		["Tucker","AR",34.43,-91.89,43150584],
		["Greenway","AR",36.34,-90.22,43150633],
		["Grubbs","AR",35.65,-91.07,43150682],
		["Gepp","AR",36.44,-92.09,43150731],
		["Bradley","OK",34.87,-97.7,43150780],
		["Carmen","OK",36.57,-98.45,43150829],
		["Linn","TX",26.6,-98.19,43150878],
		["Red Feather Lakes","CO",40.8,-105.58,43150927],
		["Woodrow","CO",39.98,-103.58,43150976],
		["Coal Creek","CO",38.35,-105.14,43151025],
		["Vernon","UT",40.06,-112.34,43151074],
		["Emery","UT",38.9,-110.95,43151123],
		["Hanksville","UT",38.27,-110.75,43151172],
		["Tubac","AZ",31.63,-111.06,43151221],
		["Smith","NV",38.8,-119.32,43151270],
		["Rio Nido","CA",38.52,-122.97,43151319],
		["J B P H H","HI",21.31,-157.93,43151368],
		["Keyport","WA",47.69,-122.62,43151417],
		["Sekiu","WA",48.29,-124.4,43151466],
		["Hunters","WA",48.17,-118.12,43151515],
		["Lower Kalskag","AK",61.51,-160.36,43151564],
		["Seldovia","AK",59.39,-151.66,43151613],
		["Acworth","NH",43.21,-72.29,43151661],
		["South Gardiner","ME",44.17,-69.78,43151709],
		["Winn","ME",45.48,-68.37,43151757],
		["Haines Falls","NY",42.2,-74.09,43151805],
		["Findley Lake","NY",42.12,-79.73,43151853],
		["Leisenring","PA",40,-79.61,43151901],
		["Acosta","PA",40.1,-79.04,43151949],
		["Laughlintown","PA",40.19,-79.16,43151997],
		["Elderton","PA",40.69,-79.34,43152045],
		["Sagamore","PA",40.78,-79.23,43152093],
		["Yatesboro","PA",40.81,-79.33,43152141],
		["Fork","MD",39.47,-76.45,43152189],
		["Libertytown","MD",39.48,-77.24,43152237],
		["Etlan","VA",38.52,-78.26,43152285],
		["Foster","VA",37.45,-76.38,43152333],
		["Valentines","VA",36.58,-77.83,43152381],
		["Nora","VA",37.02,-82.34,43152429],
		["Arbovale","WV",38.45,-79.75,43152477],
		["Blacksville","WV",39.71,-80.21,43152525],
		["Metz","WV",39.61,-80.43,43152573],
		["Fork","SC",34.28,-79.27,43152621],
		["Ochopee","FL",25.9,-81.3,43152669],
		["Anniston","AL",33.66,-85.81,43152717],
		["Bellamy","AL",32.42,-88.12,43152765],
		["Chestnut Mound","TN",36.18,-85.84,43152813],
		["Artesia","MS",33.41,-88.64,43152861],
		["Saint Francis","KY",37.62,-85.46,43152909],
		["Denniston","KY",37.91,-83.53,43152957],
		["West Van Lear","KY",37.78,-82.77,43153005],
		["Majestic","KY",37.53,-82.1,43153053],
		["Blue River","KY",37.62,-82.84,43153101],
		["Piedmont","OH",40.14,-81.21,43153149],
		["Bowersville","OH",39.58,-83.72,43153197],
		["Newberry","IN",38.92,-87.01,43153245],
		["Jamaica","IA",41.84,-94.3,43153293],
		["Stanley","IA",42.64,-91.81,43153341],
		["Hancock","IA",41.39,-95.36,43153389],
		["Wadena","IA",42.83,-91.65,43153437],
		["Bigelow","MN",43.5,-95.68,43153485],
		["Aldrich","MN",46.37,-94.93,43153533],
		["Effie","MN",47.84,-93.63,43153581],
		["Alpena","SD",44.18,-98.36,43153629],
		["Okreek","SD",43.36,-100.38,43153677],
		["Grandin","ND",47.23,-97,43153725],
		["Dazey","ND",47.18,-98.2,43153773],
		["Anamoose","ND",47.88,-100.24,43153821],
		["Martinsdale","MT",46.47,-110.53,43153869],
		["Melstone","MT",46.57,-107.93,43153917],
		["Westby","MT",48.83,-104.16,43153965],
		["Babb","MT",48.78,-113.37,43154013],
		["Mc Allister","MT",45.47,-111.74,43154061],
		["Carman","IL",40.73,-91.06,43154109],
		["Shirley","IL",40.41,-89.03,43154157],
		["Orient","IL",37.91,-88.97,43154205],
		["Elmo","MO",40.51,-95.11,43154253],
		["Harwood","MO",37.95,-94.15,43154301],
		["Ulman","MO",38.15,-92.44,43154349],
		["Mora","MO",38.53,-93.14,43154397],
		["Devils Elbow","MO",37.82,-92.06,43154445],
		["South Greenfield","MO",37.37,-93.84,43154493],
		["Fairview","KS",39.83,-95.72,43154541],
		["Elbing","KS",38.05,-97.12,43154589],
		["Liberty","NE",40.08,-96.48,43154637],
		["Otoe","NE",40.72,-96.12,43154685],
		["Riverdale","NE",40.78,-99.16,43154733],
		["Upland","NE",40.31,-98.9,43154781],
		["Dix","NE",41.23,-103.48,43154829],
		["Cody","NE",42.93,-101.24,43154877],
		["Pointe A La Hache","LA",29.57,-89.79,43154925],
		["Ogden","AR",33.58,-94.04,43154973],
		["Fisher","AR",35.49,-90.97,43155021],
		["Loyal","OK",35.97,-98.11,43155069],
		["Mutual","OK",36.22,-99.16,43155117],
		["Nashoba","OK",34.48,-95.22,43155165],
		["Lake Creek","TX",33.46,-95.61,43155213],
		["Higgins","TX",36.12,-100.02,43155261],
		["Springlake","TX",34.23,-102.3,43155309],
		["Westbrook","TX",32.35,-101.01,43155357],
		["Silverthorne","CO",39.65,-106.08,43155405],
		["Basalt","ID",43.31,-112.16,43155453],
		["Leadore","ID",44.49,-113.36,43155501],
		["Bayview","ID",47.97,-116.56,43155549],
		["Randlett","UT",40.18,-109.71,43155597],
		["Axtell","UT",39.08,-111.84,43155645],
		["Green Valley","AZ",31.76,-110.85,43155693],
		["Golconda","NV",40.93,-117.33,43155741],
		["Crescent Valley","NV",40.52,-116.36,43155789],
		["Bodega","CA",38.33,-122.97,43155837],
		["Challenge","CA",39.47,-121.19,43155885],
		["Long Creek","OR",44.8,-119.13,43155933],
		["Trapper Creek","AK",62.69,-151.07,43155981],
		["Passadumkeag","ME",45.18,-68.61,43156028],
		["Southport","ME",43.84,-69.65,43156075],
		["Cambridge","ME",45.02,-69.47,43156122],
		["Flagtown","NJ",40.51,-74.67,43156169],
		["Keene Valley","NY",44.2,-73.78,43156216],
		["Lyon Mountain","NY",44.72,-73.92,43156263],
		["Westdale","NY",43.38,-75.81,43156310],
		["Rochester","NY",43.16,-77.61,43156357],
		["Indianola","PA",40.57,-79.87,43156404],
		["Allenport","PA",40.09,-79.85,43156451],
		["Rew","PA",41.9,-78.5,43156498],
		["Martins Creek","PA",40.85,-75.19,43156545],
		["East Smithfield","PA",41.86,-76.62,43156592],
		["Royal Oak","MD",38.74,-76.17,43156639],
		["New Richmond","WV",37.54,-81.47,43156686],
		["Maxwelton","WV",37.86,-80.41,43156733],
		["Wilkinson","WV",37.83,-81.98,43156780],
		["Bradley","WV",37.86,-81.19,43156827],
		["Stephenson","WV",37.57,-81.33,43156874],
		["Leivasy","WV",38.12,-80.73,43156921],
		["Riverton","WV",38.74,-79.43,43156968],
		["Middleburg","NC",36.39,-78.32,43157015],
		["Westville","SC",34.45,-80.58,43157062],
		["Dukedom","TN",36.47,-88.66,43157109],
		["Morgan City","MS",33.37,-90.34,43157156],
		["Big Creek","KY",37.16,-83.56,43157203],
		["Rockport","KY",37.33,-86.99,43157250],
		["Pettisville","OH",41.59,-84.17,43157297],
		["Donnelsville","OH",39.91,-83.94,43157344],
		["Tremont City","OH",40.01,-83.83,43157391],
		["Griffin","IN",38.2,-87.91,43157438],
		["Sagola","MI",46.08,-88.07,43157485],
		["Gaastra","MI",46.05,-88.6,43157532],
		["Wiota","IA",41.4,-94.88,43157579],
		["Palmer","IA",42.62,-94.59,43157626],
		["Webb","IA",42.94,-95.01,43157673],
		["Monmouth","IA",42.07,-90.88,43157720],
		["Onslow","IA",42.1,-91.01,43157767],
		["West Chester","IA",41.34,-91.81,43157814],
		["Potter","WI",44.11,-88.09,43157861],
		["Holyoke","MN",46.46,-92.38,43157908],
		["Upsala","MN",45.8,-94.56,43157955],
		["Vienna","SD",44.7,-97.49,43158002],
		["Fort Ransom","ND",46.52,-97.93,43158049],
		["Buchanan","ND",47.06,-98.82,43158096],
		["Fullerton","ND",46.16,-98.42,43158143],
		["Hague","ND",46.02,-99.99,43158190],
		["Bainville","MT",48.17,-104.27,43158237],
		["Lambert","MT",47.76,-104.67,43158284],
		["Elliston","MT",46.49,-112.41,43158331],
		["Walsh","IL",38.08,-89.85,43158378],
		["Toronto","KS",37.79,-95.94,43158425],
		["Mayfield","KS",37.26,-97.54,43158472],
		["Peru","KS",37.08,-96.09,43158519],
		["Tobias","NE",40.41,-97.33,43158566],
		["Page","NE",42.39,-98.41,43158613],
		["Holstein","NE",40.46,-98.65,43158660],
		["Marshall","OK",36.15,-97.62,43158707],
		["Oakhurst","OK",36.08,-96.06,43158754],
		["Olden","TX",32.42,-98.73,43158801],
		["La Ward","TX",28.84,-96.46,43158848],
		["Lopeno","TX",26.72,-99.1,43158895],
		["Guthrie","TX",33.62,-100.32,43158942],
		["Victor","CO",38.7,-105.14,43158989],
		["Smoot","WY",42.62,-110.92,43159036],
		["Felt","ID",43.88,-111.21,43159083],
		["Ferdinand","ID",46.14,-116.41,43159130],
		["Arivaca","AZ",31.59,-111.3,43159177],
		["Munds Park","AZ",34.92,-111.62,43159224],
		["Guadalupita","NM",36.13,-105.23,43159271],
		["Imlay","NV",40.59,-118.06,43159318],
		["Los Angeles","CA",34.05,-118.41,43159365],
		["Burnt Ranch","CA",40.95,-123.43,43159412],
		["Capay","CA",38.69,-122.12,43159459],
		["Guinda","CA",38.8,-122.18,43159506],
		["Dutch Flat","CA",39.19,-120.83,43159553],
		["Weimar","CA",39.03,-120.96,43159600],
		["Mitchell","OR",44.66,-119.78,43159647],
		["Seaview","WA",46.33,-124.05,43159694],
		["North Chatham","MA",41.7,-69.95,43159740],
		["Roxbury","ME",44.66,-70.59,43159786],
		["Wells River","VT",44.15,-72.06,43159832],
		["Peacham","VT",44.33,-72.17,43159878],
		["Rogers","CT",41.84,-71.91,43159924],
		["Deepwater","NJ",39.62,-75.51,43159970],
		["Highland Lake","NY",41.52,-74.85,43160016],
		["Middle Granville","NY",43.45,-73.3,43160062],
		["Knowlesville","NY",43.21,-78.38,43160108],
		["Pleasant Unity","PA",40.27,-79.42,43160154],
		["Berrysburg","PA",40.6,-76.8,43160200],
		["Artemas","PA",39.76,-78.4,43160246],
		["Madison","MD",38.5,-76.22,43160292],
		["Church View","VA",37.67,-76.68,43160338],
		["Water View","VA",37.72,-76.61,43160384],
		["Pittsville","VA",36.98,-79.46,43160430],
		["Staffordsville","VA",37.24,-80.76,43160476],
		["West Augusta","VA",38.27,-79.3,43160522],
		["Warriormine","WV",37.29,-81.67,43160568],
		["Cass","WV",38.45,-79.89,43160614],
		["Cannelton","WV",38.19,-81.27,43160660],
		["Deep Water","WV",38.09,-81.21,43160706],
		["Page","WV",38.05,-81.23,43160752],
		["Carolina","WV",39.48,-80.26,43160798],
		["High Shoals","NC",35.39,-81.2,43160844],
		["Lakeview","NC",35.22,-79.32,43160890],
		["Long Creek","SC",34.77,-83.25,43160936],
		["Norris","SC",34.76,-82.75,43160982],
		["La Crosse","FL",29.84,-82.4,43161028],
		["Wabasso","FL",27.75,-80.43,43161074],
		["Westport","TN",35.88,-88.29,43161120],
		["Linefork","KY",37.02,-82.95,43161166],
		["White Mills","KY",37.55,-86.03,43161212],
		["Williston","OH",41.58,-83.35,43161258],
		["Hoytville","OH",41.19,-83.78,43161304],
		["Creola","OH",39.35,-82.5,43161350],
		["Zaleski","OH",39.28,-82.39,43161396],
		["Benton Ridge","OH",41,-83.79,43161442],
		["Vernon","IN",38.98,-85.61,43161488],
		["Schnellville","IN",38.33,-86.75,43161534],
		["Alba","MI",44.96,-84.98,43161580],
		["Mackinac Island","MI",45.85,-84.62,43161626],
		["Meservey","IA",42.91,-93.47,43161672],
		["Farnhamville","IA",42.27,-94.4,43161718],
		["Goodman","WI",45.63,-88.34,43161764],
		["Leopolis","WI",44.76,-88.83,43161810],
		["Sargeant","MN",43.8,-92.8,43161856],
		["Lake George","MN",47.2,-94.99,43161902],
		["Wessington","SD",44.45,-98.69,43161948],
		["Ashton","SD",44.99,-98.49,43161994],
		["Doland","SD",44.89,-98.09,43162040],
		["Vale","SD",44.61,-103.4,43162086],
		["Buffalo","ND",46.92,-97.55,43162132],
		["Deering","ND",48.39,-101.04,43162178],
		["Grenora","ND",48.61,-103.93,43162224],
		["Creston","IL",41.93,-88.96,43162270],
		["Mc Connell","IL",42.43,-89.73,43162316],
		["Mark","IL",41.26,-89.24,43162362],
		["Bingham","IL",39.11,-89.21,43162408],
		["Trilla","IL",39.38,-88.35,43162454],
		["Hunnewell","MO",39.66,-91.85,43162500],
		["Blackburn","MO",39.1,-93.48,43162546],
		["Isabella","MO",36.58,-92.61,43162592],
		["Savonburg","KS",37.74,-95.14,43162638],
		["Rock","KS",37.42,-96.92,43162684],
		["Dearing","KS",37.05,-95.71,43162730],
		["Tipton","KS",39.33,-98.47,43162776],
		["Otis","KS",38.53,-99.05,43162822],
		["Plevna","KS",37.97,-98.3,43162868],
		["Wallace","KS",38.91,-101.59,43162914],
		["Brock","NE",40.48,-95.95,43162960],
		["Jansen","NE",40.18,-97.08,43163006],
		["Panama","NE",40.59,-96.51,43163052],
		["Steinauer","NE",40.2,-96.23,43163098],
		["Cord","AR",35.8,-91.3,43163144],
		["Lebanon","OK",33.98,-96.91,43163190],
		["Jet","OK",36.66,-98.18,43163236],
		["North Miami","OK",36.91,-94.87,43163282],
		["Petty","TX",33.61,-95.79,43163328],
		["Desdemona","TX",32.29,-98.56,43163374],
		["Prairie Lea","TX",29.71,-97.72,43163420],
		["Barstow","TX",31.46,-103.39,43163466],
		["Empire","CO",39.75,-105.68,43163512],
		["Seibert","CO",39.29,-102.86,43163558],
		["Hooper","CO",37.74,-105.87,43163604],
		["Howard","CO",38.45,-105.84,43163650],
		["Glendo","WY",42.5,-105.02,43163696],
		["Medicine Bow","WY",41.89,-106.2,43163742],
		["Manderson","WY",44.26,-107.96,43163788],
		["Red River","NM",36.7,-105.4,43163834],
		["Mayhill","NM",32.88,-105.47,43163880],
		["Pioneertown","CA",34.21,-116.52,43163926],
		["Mc Kittrick","CA",35.41,-119.76,43163972],
		["Stinson Beach","CA",37.91,-122.66,43164018],
		["Hathaway Pines","CA",38.18,-120.37,43164064],
		["Comptche","CA",39.24,-123.53,43164110],
		["Hood","CA",38.35,-121.5,43164156],
		["French Gulch","CA",40.73,-122.6,43164202],
		["Ookala","HI",20.01,-155.28,43164248],
		["Neskowin","OR",45.1,-123.93,43164294],
		["Tidewater","OR",44.32,-123.85,43164340],
		["Wallula","WA",46.06,-118.89,43164386],
		["Woods Hole","MA",41.52,-70.66,43164431],
		["East Haven","VT",44.67,-71.9,43164476],
		["Martinsburg","NY",43.72,-75.45,43164521],
		["Newton Falls","NY",44.21,-74.97,43164566],
		["La Belle","PA",39.96,-79.94,43164611],
		["Anita","PA",41.01,-78.96,43164656],
		["Heilwood","PA",40.61,-78.91,43164701],
		["Saint Michael","PA",40.33,-78.78,43164746],
		["Clintonville","PA",41.2,-79.87,43164791],
		["Lanse","PA",40.97,-78.13,43164836],
		["Burnt Cabins","PA",40.06,-77.9,43164881],
		["Upperstrasburg","PA",40.06,-77.76,43164926],
		["Ralston","PA",41.5,-76.94,43164971],
		["Treichlers","PA",40.78,-75.53,43165016],
		["Tyaskin","MD",38.32,-75.87,43165061],
		["Gasburg","VA",36.56,-77.89,43165106],
		["Rawlings","VA",36.94,-77.77,43165151],
		["Sandy Level","VA",37,-79.55,43165196],
		["Mc Clure","VA",37.08,-82.38,43165241],
		["Nemours","WV",37.29,-81.28,43165286],
		["Millstone","WV",38.85,-81.07,43165331],
		["Midkiff","WV",38.14,-82.13,43165376],
		["Odd","WV",37.56,-81.23,43165421],
		["Leslie","WV",38.03,-80.74,43165466],
		["Rosemont","WV",39.28,-80.16,43165511],
		["Scotland","GA",32.04,-82.81,43165556],
		["Crump","TN",35.23,-88.32,43165601],
		["Dellrose","TN",35.11,-86.8,43165646],
		["Paris","MS",34.18,-89.46,43165691],
		["Hope","KY",38.01,-83.77,43165736],
		["Highland","OH",39.34,-83.59,43165781],
		["Macksburg","OH",39.63,-81.45,43165826],
		["Laketon","IN",40.99,-85.87,43165871],
		["Elberta","MI",44.62,-86.22,43165916],
		["Gould City","MI",46.1,-85.7,43165961],
		["White Pine","MI",46.75,-89.58,43166006],
		["Brayton","IA",41.54,-94.92,43166051],
		["Kamrar","IA",42.39,-93.72,43166096],
		["Bristow","IA",42.77,-92.9,43166141],
		["Arthur","IA",42.33,-95.34,43166186],
		["Denmark","IA",40.75,-91.33,43166231],
		["Woodman","WI",43.09,-90.79,43166276],
		["Finland","MN",47.41,-91.24,43166321],
		["Swatara","MN",46.89,-93.67,43166366],
		["Dunnell","MN",43.56,-94.77,43166411],
		["Reading","MN",43.71,-95.75,43166456],
		["Kennedy","MN",48.64,-96.91,43166501],
		["Bristol","SD",45.34,-97.74,43166546],
		["Fulton","SD",43.72,-97.82,43166591],
		["Saint Onge","SD",44.56,-103.74,43166636],
		["Pisek","ND",48.31,-97.71,43166681],
		["Taylor","ND",46.9,-102.42,43166726],
		["Keene","ND",47.93,-102.95,43166771],
		["Winston","MT",46.36,-111.64,43166816],
		["Elmo","MT",47.8,-114.4,43166861],
		["Rapids City","IL",41.58,-90.34,43166906],
		["Basco","IL",40.32,-91.19,43166951],
		["Perry","IL",39.78,-90.74,43166996],
		["Calhoun","IL",38.65,-88.04,43167041],
		["Ellery","IL",38.36,-88.13,43167086],
		["Tyro","KS",37.03,-95.82,43167131],
		["Falun","KS",38.65,-97.81,43167176],
		["Olmitz","KS",38.51,-98.93,43167221],
		["Sylvia","KS",37.95,-98.4,43167266],
		["Weskan","KS",38.86,-101.96,43167311],
		["Bruning","NE",40.33,-97.56,43167356],
		["Daykin","NE",40.32,-97.29,43167401],
		["Verdon","NE",40.14,-95.71,43167446],
		["Mount Airy","LA",30.06,-90.64,43167491],
		["Midland","AR",35.09,-94.35,43167536],
		["Nicoma Park","OK",35.49,-97.32,43167581],
		["Devol","OK",34.19,-98.58,43167626],
		["Carrier","OK",36.47,-98.01,43167671],
		["Whitesboro","OK",34.68,-94.88,43167716],
		["Fitzhugh","OK",34.66,-96.77,43167761],
		["Muse","OK",34.67,-94.77,43167806],
		["Benjamin","TX",33.58,-99.79,43167851],
		["Gardner","CO",37.78,-105.17,43167896],
		["Peoa","UT",40.74,-111.36,43167941],
		["San Cristobal","NM",36.59,-105.63,43167986],
		["Hondo","NM",33.38,-105.27,43168031],
		["Annapolis","CA",38.71,-123.33,43168076],
		["Forbestown","CA",39.51,-121.23,43168121],
		["Lostine","OR",45.37,-117.52,43168166],
		["Bay Center","WA",46.63,-123.95,43168211],
		["Roosevelt","WA",45.85,-120.34,43168256],
		["Old Harbor","AK",57.21,-153.32,43168301],
		["Kaktovik","AK",70.12,-143.66,43168346],
		["Ambler","AK",67.08,-157.9,43168391],
		["Big Indian","NY",42.07,-74.44,43168435],
		["Durham","NY",42.4,-74.19,43168479],
		["Prospect","NY",43.3,-75.15,43168523],
		["Oswegatchie","NY",44.18,-75.07,43168567],
		["Pike","NY",42.55,-78.15,43168611],
		["Rexville","NY",42.08,-77.66,43168655],
		["Donegal","PA",40.11,-79.38,43168699],
		["Connoquenessing","PA",40.81,-80.01,43168743],
		["Brisbin","PA",40.83,-78.35,43168787],
		["Cornwall","PA",40.26,-76.4,43168831],
		["Pillow","PA",40.64,-76.8,43168875],
		["Lattimer Mines","PA",40.95,-76,43168919],
		["Strausstown","PA",40.49,-76.18,43168963],
		["Somerset","VA",38.2,-78.23,43169007],
		["Gwynn","VA",37.5,-76.28,43169051],
		["Deerfield","VA",38.19,-79.4,43169095],
		["Williamsville","VA",38.19,-79.56,43169139],
		["Greenville","WV",37.53,-80.66,43169183],
		["Ethel","WV",37.86,-81.87,43169227],
		["Powellton","WV",38.09,-81.31,43169271],
		["Surveyor","WV",37.73,-81.34,43169315],
		["Gypsy","WV",39.35,-80.3,43169359],
		["Osage","WV",39.65,-80,43169403],
		["Nallen","WV",38.1,-80.88,43169447],
		["Conetoe","NC",35.81,-77.45,43169491],
		["Cedar Island","NC",35,-76.32,43169535],
		["Porterdale","GA",33.57,-83.89,43169579],
		["Marble Hill","GA",34.42,-84.34,43169623],
		["Worthington Springs","FL",29.93,-82.42,43169667],
		["Lupton City","TN",35.12,-85.28,43169711],
		["Sugar Tree","TN",35.79,-88.02,43169755],
		["Lejunior","KY",36.9,-83.07,43169799],
		["Mozelle","KY",37,-83.39,43169843],
		["Walker","KY",36.88,-83.71,43169887],
		["Beech Creek","KY",37.18,-87.12,43169931],
		["Cleaton","KY",37.25,-87.09,43169975],
		["Bascom","OH",41.13,-83.28,43170019],
		["Hooven","OH",39.19,-84.75,43170063],
		["Lynx","OH",38.74,-83.36,43170107],
		["Richmond Dale","OH",39.2,-82.81,43170151],
		["Goetzville","MI",46.08,-84.09,43170195],
		["Ramsay","MI",46.47,-90.02,43170239],
		["Riverton","IA",40.68,-95.56,43170283],
		["Pleasant Valley","IA",41.56,-90.41,43170327],
		["Forest Junction","WI",44.21,-88.15,43170371],
		["Kerrick","MN",46.33,-92.58,43170415],
		["Dumont","MN",45.71,-96.42,43170459],
		["Shelly","MN",47.45,-96.82,43170503],
		["Strathcona","MN",48.55,-96.16,43170547],
		["Hecla","SD",45.88,-98.15,43170591],
		["Starkweather","ND",48.45,-98.87,43170635],
		["Jud","ND",46.52,-98.89,43170679],
		["Zeeland","ND",45.97,-99.83,43170723],
		["Carpio","ND",48.44,-101.71,43170767],
		["Norwich","ND",48.25,-100.98,43170811],
		["Ryder","ND",47.91,-101.67,43170855],
		["Winifred","MT",47.63,-109.21,43170899],
		["Bureau","IL",41.28,-89.36,43170943],
		["Cooksville","IL",40.54,-88.71,43170987],
		["Colp","IL",37.8,-89.07,43171031],
		["Dowell","IL",37.93,-89.23,43171075],
		["Rea","MO",40.06,-94.76,43171119],
		["Jameson","MO",40,-93.98,43171163],
		["Whiting","KS",39.58,-95.61,43171207],
		["Spivey","KS",37.44,-98.16,43171251],
		["Ensign","KS",37.65,-100.23,43171295],
		["Thurston","NE",42.17,-96.69,43171339],
		["Clatonia","NE",40.46,-96.85,43171383],
		["Duncan","NE",41.39,-97.49,43171427],
		["Saint Helena","NE",42.8,-97.24,43171471],
		["Waterbury","NE",42.45,-96.73,43171515],
		["Ashton","NE",41.24,-98.79,43171559],
		["Boelus","NE",41.07,-98.71,43171603],
		["Republican City","NE",40.1,-99.22,43171647],
		["Fenton","LA",30.36,-92.91,43171691],
		["Garland City","AR",33.31,-93.75,43171735],
		["Charlotte","AR",35.84,-91.36,43171779],
		["Saint Paul","AR",35.82,-93.76,43171823],
		["Matagorda","TX",28.8,-95.89,43171867],
		["Telferner","TX",28.83,-96.83,43171911],
		["Ward","CO",40.07,-105.51,43171955],
		["Ovid","CO",40.95,-102.38,43171999],
		["Torrey","UT",38.33,-111.06,43172043],
		["Ilfeld","NM",35.48,-105.4,43172087],
		["Deeth","NV",41.4,-115.48,43172131],
		["Manchester","CA",39,-123.62,43172175],
		["Butte City","CA",39.46,-121.93,43172219],
		["Walton","OR",44.02,-123.62,43172263],
		["Kalskag","AK",61.61,-160.2,43172307],
		["Gustavus","AK",58.42,-135.78,43172351],
		["La Plata","PR",18.16,-66.23,43172394],
		["Wheelwright","MA",42.35,-72.14,43172437],
		["Linwood","MA",42.05,-71.64,43172480],
		["Central Village","CT",41.7,-71.94,43172523],
		["Guilderland Center","NY",42.68,-74.01,43172566],
		["Maplecrest","NY",42.29,-74.15,43172609],
		["Yulan","NY",41.52,-74.93,43172652],
		["Long Lake","NY",43.97,-74.42,43172695],
		["Allentown","NY",42.06,-78.01,43172738],
		["Steamburg","NY",42.12,-78.89,43172781],
		["Dickerson Run","PA",40.02,-79.66,43172824],
		["Dunlevy","PA",40.11,-79.85,43172867],
		["Leckrone","PA",39.86,-79.87,43172910],
		["Ford Cliff","PA",40.75,-79.53,43172953],
		["Dudley","PA",40.2,-78.17,43172996],
		["Todd","PA",40.26,-78.1,43173039],
		["Springtown","PA",40.55,-75.28,43173082],
		["Abell","MD",38.25,-76.74,43173125],
		["Beallsville","MD",39.17,-77.41,43173168],
		["Bruington","VA",37.77,-76.93,43173211],
		["Pungoteague","VA",37.62,-75.81,43173254],
		["Bob White","WV",37.95,-81.72,43173297],
		["Allen Junction","WV",37.59,-81.35,43173340],
		["Converse","SC",34.95,-81.85,43173383],
		["Ulmer","SC",33.09,-81.2,43173426],
		["Louvale","GA",32.16,-84.81,43173469],
		["Lulu","FL",30.1,-82.49,43173512],
		["Mc Intosh","FL",29.44,-82.22,43173555],
		["Garrard","KY",37.12,-83.74,43173598],
		["Tarlton","OH",39.55,-82.77,43173641],
		["Fulton","OH",40.46,-82.82,43173684],
		["Robertsville","OH",40.8,-81.15,43173727],
		["Potsdam","OH",39.96,-84.41,43173770],
		["Buckland","OH",40.62,-84.26,43173813],
		["Bath","IN",39.49,-84.83,43173856],
		["Pimento","IN",39.28,-87.36,43173899],
		["Walloon Lake","MI",45.26,-84.91,43173942],
		["Cylinder","IA",43.09,-94.55,43173985],
		["Thor","IA",42.68,-94.05,43174028],
		["Woolstock","IA",42.56,-93.84,43174071],
		["Garber","IA",42.74,-91.26,43174114],
		["Green Bay","WI",44.52,-87.98,43174157],
		["Gile","WI",46.42,-90.22,43174200],
		["Jacobson","MN",47,-93.26,43174243],
		["Borup","MN",47.18,-96.5,43174286],
		["Perley","MN",47.17,-96.8,43174329],
		["Talmoon","MN",47.6,-93.77,43174372],
		["Strandquist","MN",48.49,-96.44,43174415],
		["Interior","SD",43.72,-101.98,43174458],
		["Colfax","ND",46.47,-96.87,43174501],
		["Aneta","ND",47.67,-97.98,43174544],
		["Helmville","MT",46.88,-113,43174587],
		["Elliott","IL",40.46,-88.27,43174630],
		["Woodland","IL",40.71,-87.73,43174673],
		["Nachusa","IL",41.82,-89.37,43174716],
		["Hecker","IL",38.3,-89.99,43174759],
		["Milton","IL",39.56,-90.64,43174802],
		["Anabel","MO",39.75,-92.35,43174845],
		["Revere","MO",40.49,-91.67,43174888],
		["Burfordville","MO",37.35,-89.81,43174931],
		["Coffey","MO",40.1,-94,43174974],
		["Gilliam","MO",39.23,-93,43175017],
		["Miami","MO",39.32,-93.22,43175060],
		["Cherryville","MO",37.85,-91.28,43175103],
		["Cook Sta","MO",37.81,-91.43,43175146],
		["Kanopolis","KS",38.71,-98.15,43175189],
		["Burdett","KS",38.19,-99.52,43175232],
		["Wright","KS",37.78,-99.89,43175275],
		["Burchard","NE",40.14,-96.34,43175318],
		["Bartlett","NE",41.88,-98.55,43175361],
		["Farnam","NE",40.7,-100.21,43175404],
		["Hyannis","NE",42,-101.76,43175447],
		["Harvey","AR",34.86,-93.76,43175490],
		["Camargo","OK",36.01,-99.28,43175533],
		["Sabine Pass","TX",29.73,-93.89,43175576],
		["Sarita","TX",27.21,-97.8,43175619],
		["Uvalde","TX",29.21,-99.78,43175662],
		["Orchard","CO",40.33,-104.12,43175705],
		["Rock River","WY",41.73,-105.97,43175748],
		["Fort Laramie","WY",42.21,-104.51,43175791],
		["Carmen","ID",45.36,-113.82,43175834],
		["Cochiti Lake","NM",35.64,-106.33,43175877],
		["San Patricio","NM",33.4,-105.32,43175920],
		["Orick","CA",41.35,-124.04,43175963],
		["River Pines","CA",38.54,-120.74,43176006],
		["Beaver","WA",48.05,-124.28,43176049],
		["Grayling","AK",62.89,-160.1,43176092],
		["Nulato","AK",64.72,-158.11,43176135],
		["Vega Baja","PR",18.44,-66.39,43176177],
		["South Egremont","MA",42.16,-73.41,43176219],
		["Winter Harbor","ME",44.39,-68.08,43176261],
		["Post Mills","VT",43.89,-72.26,43176303],
		["Long Island City","NY",40.74,-73.93,43176345],
		["Wampsville","NY",43.07,-75.7,43176387],
		["Leonardsville","NY",42.8,-75.25,43176429],
		["North Brookfield","NY",42.85,-75.36,43176471],
		["Millsboro","PA",39.98,-80.03,43176513],
		["Loysburg","PA",40.17,-78.37,43176555],
		["Smithmill","PA",40.76,-78.4,43176597],
		["Milesburg","PA",40.94,-77.79,43176639],
		["Wallaceton","PA",40.96,-78.29,43176681],
		["York New Salem","PA",39.9,-76.79,43176723],
		["Brockton","PA",40.77,-76.04,43176765],
		["Starlight","PA",41.9,-75.33,43176807],
		["Lawton","PA",41.78,-76.06,43176849],
		["Geigertown","PA",40.23,-75.83,43176891],
		["Oilville","VA",37.7,-77.78,43176933],
		["Bee","VA",37.08,-82.19,43176975],
		["Orgas","WV",38.06,-81.56,43177017],
		["Glen Rogers","WV",37.73,-81.44,43177059],
		["Diana","WV",38.58,-80.42,43177101],
		["Auburn","WV",39.09,-80.85,43177143],
		["Micro","NC",35.56,-78.2,43177185],
		["Stonewall","NC",35.13,-76.74,43177227],
		["Cortez","FL",27.46,-82.67,43177269],
		["Totz","KY",36.94,-83.11,43177311],
		["Bear Branch","KY",37.16,-83.54,43177353],
		["Premium","KY",37.12,-82.91,43177395],
		["Stratton","OH",40.52,-80.63,43177437],
		["Beverly Shores","IN",41.68,-86.97,43177479],
		["Curtis","MI",46.2,-85.75,43177521],
		["Berwick","IA",41.66,-93.55,43177563],
		["Gravity","IA",40.76,-94.74,43177605],
		["Soldier","IA",41.98,-95.77,43177647],
		["Cedar","IA",41.2,-92.5,43177689],
		["Promise City","IA",40.74,-93.15,43177731],
		["Olds","IA",41.13,-91.54,43177773],
		["Stockbridge","WI",44.07,-88.31,43177815],
		["Dale","WI",44.26,-88.68,43177857],
		["Elkton","MN",43.65,-92.7,43177899],
		["Frost","MN",43.58,-93.92,43177941],
		["Odessa","MN",45.26,-96.33,43177983],
		["Marty","SD",42.99,-98.42,43178025],
		["Camp Crook","SD",45.54,-103.97,43178067],
		["Binford","ND",47.55,-98.34,43178109],
		["Fishtail","MT",45.33,-109.67,43178151],
		["Condon","MT",47.43,-113.7,43178193],
		["Fenton","IL",41.73,-90.03,43178235],
		["Marietta","IL",40.49,-90.39,43178277],
		["Foosland","IL",40.36,-88.42,43178319],
		["Graham","MO",40.2,-95.03,43178361],
		["Summerfield","KS",39.99,-96.34,43178403],
		["Hepler","KS",37.66,-94.96,43178445],
		["Liberty","KS",37.15,-95.59,43178487],
		["Longford","KS",39.17,-97.32,43178529],
		["Manter","KS",37.52,-101.88,43178571],
		["Belgrade","NE",41.47,-98.06,43178613],
		["Dixon","NE",42.41,-96.99,43178655],
		["Oakdale","NE",42.07,-97.96,43178697],
		["Hall Summit","LA",32.17,-93.3,43178739],
		["Saratoga","AR",33.77,-93.88,43178781],
		["Russell","AR",35.36,-91.51,43178823],
		["Hitchcock","OK",35.96,-98.34,43178865],
		["Bailey","TX",33.43,-96.16,43178907],
		["Comstock","TX",29.68,-101.16,43178949],
		["Genoa","CO",39.27,-103.49,43178991],
		["Campo","CO",37.1,-102.57,43179033],
		["Chester","ID",43.99,-111.54,43179075],
		["Marble Canyon","AZ",36.74,-111.85,43179117],
		["Yucca","AZ",34.76,-114.01,43179159],
		["Rehoboth","NM",35.49,-108.65,43179201],
		["Corona","NM",34.24,-105.59,43179243],
		["Empire","NV",40.5,-119.5,43179285],
		["Fawnskin","CA",34.27,-116.94,43179327],
		["Valyermo","CA",34.39,-117.74,43179369],
		["Cressey","CA",37.39,-120.61,43179411],
		["Rockport","WA",48.56,-121.54,43179453],
		["Curtis","WA",46.47,-123.08,43179495],
		["Farmington","WA",47.11,-117.07,43179537],
		["Shaktoolik","AK",64.25,-160.91,43179579],
		["North Sandwich","NH",43.86,-71.38,43179620],
		["Ogunquit","ME",43.24,-70.59,43179661],
		["Woodbury","VT",44.44,-72.4,43179702],
		["Shelter Island Heights","NY",41.07,-72.34,43179743],
		["North Chatham","NY",42.47,-73.6,43179784],
		["Jewett","NY",42.27,-74.28,43179825],
		["Linwood","NY",42.9,-77.91,43179866],
		["Bakerstown","PA",40.64,-79.93,43179907],
		["Bunola","PA",40.23,-79.95,43179948],
		["Elrama","PA",40.24,-79.98,43179989],
		["Indian Head","PA",40.01,-79.39,43180030],
		["Uledi","PA",39.87,-79.7,43180071],
		["Gray","PA",40.19,-79.04,43180112],
		["Forbes Road","PA",40.34,-79.53,43180153],
		["Wells Tannery","PA",40.09,-78.18,43180194],
		["James City","PA",41.62,-78.83,43180235],
		["Tuscarora","PA",40.76,-76.05,43180276],
		["Point Pleasant","PA",40.42,-75.11,43180317],
		["Barnesville","MD",39.21,-77.38,43180358],
		["Nanticoke","MD",38.27,-75.9,43180399],
		["Occoquan","VA",38.68,-77.26,43180440],
		["Strasburg","VA",38.98,-78.35,43180481],
		["Ebony","VA",36.57,-77.99,43180522],
		["Pageton","WV",37.33,-81.46,43180563],
		["Institute","WV",38.38,-81.76,43180604],
		["Dallas","WV",39.96,-80.55,43180645],
		["Ellamore","WV",38.93,-80.08,43180686],
		["Hatteras","NC",35.21,-75.68,43180727],
		["Powellsville","NC",36.22,-76.93,43180768],
		["Winfall","NC",36.21,-76.45,43180809],
		["Davis","NC",34.94,-76.48,43180850],
		["Pine Lake","GA",33.79,-84.2,43180891],
		["Palmdale","FL",26.94,-81.31,43180932],
		["Nocatee","FL",27.16,-81.89,43180973],
		["Millington","TN",35.33,-89.88,43181014],
		["Parchman","MS",33.87,-90.55,43181055],
		["Banner","MS",34.1,-89.4,43181096],
		["Bellefontaine","MS",33.64,-89.33,43181137],
		["Sextons Creek","KY",37.31,-83.78,43181178],
		["Clay Center","OH",41.56,-83.36,43181219],
		["Gratiot","OH",39.95,-82.21,43181260],
		["Harveysburg","OH",39.5,-84,43181301],
		["Latham","OH",39.07,-83.32,43181342],
		["Comins","MI",44.8,-84.05,43181383],
		["Eastlake","MI",44.24,-86.29,43181424],
		["Eben Junction","MI",46.35,-86.97,43181465],
		["Foster City","MI",45.97,-87.75,43181506],
		["Hamilton","IA",41.17,-92.9,43181547],
		["Lone Rock","IA",43.22,-94.32,43181588],
		["Somers","IA",42.37,-94.43,43181629],
		["Baldwin","IA",42.07,-90.83,43181670],
		["Beltrami","MN",47.54,-96.52,43181711],
		["Gully","MN",47.76,-95.62,43181752],
		["Carpenter","SD",44.63,-97.91,43181793],
		["Kathryn","ND",46.67,-97.96,43181834],
		["Michigan","ND",48.02,-98.11,43181875],
		["Petersburg","ND",48.01,-97.99,43181916],
		["Plaza","ND",48.02,-101.95,43181957],
		["Upham","ND",48.58,-100.72,43181998],
		["Gold Creek","MT",46.6,-112.95,43182039],
		["Harrison","MT",45.68,-111.75,43182080],
		["Wellington","IL",40.54,-87.67,43182121],
		["Dana","IL",40.95,-88.94,43182162],
		["Walshville","IL",39.06,-89.61,43182203],
		["Saint Louis","MO",38.63,-90.24,43182244],
		["Duenweg","MO",37.07,-94.41,43182285],
		["Rush Hill","MO",39.2,-91.72,43182326],
		["Drury","MO",36.92,-92.32,43182367],
		["Bendena","KS",39.7,-95.17,43182408],
		["Lehigh","KS",38.37,-97.3,43182449],
		["Winona","KS",39.06,-101.24,43182490],
		["Bruno","NE",41.28,-96.96,43182531],
		["Rulo","NE",40.05,-95.43,43182572],
		["Comstock","NE",41.55,-99.24,43182613],
		["Talisheek","LA",30.47,-89.85,43182654],
		["Pyatt","AR",36.25,-92.84,43182695],
		["Cecil","AR",35.43,-93.95,43182736],
		["Taft","OK",35.75,-95.54,43182777],
		["Battiest","OK",34.4,-94.93,43182818],
		["Dallas","TX",32.79,-96.76,43182859],
		["Fentress","TX",29.75,-97.76,43182900],
		["Ira","TX",32.58,-101,43182941],
		["Galeton","CO",40.52,-104.58,43182982],
		["Crook","CO",40.85,-102.8,43183023],
		["Arapahoe","CO",38.85,-102.17,43183064],
		["Kim","CO",37.24,-103.35,43183105],
		["Osage","WY",43.98,-104.42,43183146],
		["Gunlock","UT",37.24,-113.79,43183187],
		["Palo Verde","AZ",33.32,-112.7,43183228],
		["Roosevelt","AZ",33.62,-111.03,43183269],
		["Tumacacori","AZ",31.55,-111.03,43183310],
		["Alpine","AZ",33.83,-109.19,43183351],
		["Glenbrook","NV",39.05,-119.92,43183392],
		["Skyforest","CA",34.23,-117.18,43183433],
		["Taylorsville","CA",40.04,-120.75,43183474],
		["Vantage","WA",46.86,-119.99,43183515],
		["Creston","WA",47.71,-118.51,43183556],
		["Tanana","AK",65.18,-152.07,43183597],
		["Newry","ME",44.48,-70.78,43183637],
		["Weld","ME",44.69,-70.42,43183677],
		["Round Pond","ME",43.91,-69.46,43183717],
		["Bridgewater","VT",43.58,-72.63,43183757],
		["Colebrook","CT",42.02,-73.1,43183797],
		["Carlisle","NY",42.74,-74.45,43183837],
		["Fort Hunter","NY",42.93,-74.2,43183877],
		["Lake Pleasant","NY",43.55,-74.43,43183917],
		["Ruby","NY",42,-74.01,43183957],
		["Ellington","NY",42.21,-79.12,43183997],
		["Burnside","PA",40.81,-78.78,43184037],
		["Dixonville","PA",40.73,-79.02,43184077],
		["Hillsville","PA",41.02,-80.49,43184117],
		["Bigler","PA",40.98,-78.32,43184157],
		["Rheems","PA",40.12,-76.57,43184197],
		["Branchdale","PA",40.64,-76.33,43184237],
		["Buck Hill Falls","PA",41.16,-75.27,43184277],
		["Laporte","PA",41.41,-76.49,43184317],
		["Piney River","VA",37.71,-79.02,43184357],
		["Hansford","WV",38.11,-81.37,43184397],
		["Peach Creek","WV",37.87,-81.96,43184437],
		["Mount Carmel","SC",34,-82.5,43184477],
		["Malcolm","AL",31.19,-88.01,43184517],
		["Frankewing","TN",35.19,-86.8,43184557],
		["Dwale","KY",37.62,-82.72,43184597],
		["Combs","KY",37.26,-83.21,43184637],
		["Holland","KY",36.7,-86.06,43184677],
		["Magnetic Springs","OH",40.35,-83.26,43184717],
		["Harrisburg","OH",39.81,-83.17,43184757],
		["Neapolis","OH",41.46,-83.84,43184797],
		["Canton","OH",40.81,-81.37,43184837],
		["Miami","IN",40.6,-85.97,43184877],
		["Pompeii","MI",43.18,-84.6,43184917],
		["Allouez","MI",47.28,-88.4,43184957],
		["Clemons","IA",42.11,-93.15,43184997],
		["Harper","IA",41.36,-92.05,43185037],
		["Packwaukee","WI",43.78,-89.39,43185077],
		["Currie","MN",44.07,-95.66,43185117],
		["Clements","MN",44.37,-95.05,43185157],
		["Bejou","MN",47.44,-95.97,43185197],
		["Brooks","MN",47.81,-96,43185237],
		["Dante","SD",43.03,-98.18,43185277],
		["Herrick","SD",43.11,-99.18,43185317],
		["Forest River","ND",48.21,-97.47,43185357],
		["Cleveland","ND",46.89,-99.12,43185397],
		["Streeter","ND",46.65,-99.35,43185437],
		["Ramsay","MT",46.04,-112.75,43185477],
		["Kent","IL",42.31,-89.9,43185517],
		["Manlius","IL",41.45,-89.66,43185557],
		["North Henderson","IL",41.09,-90.47,43185597],
		["Vernon","IL",38.8,-89.08,43185637],
		["Cambria","IL",37.77,-89.11,43185677],
		["Livonia","MO",40.49,-92.69,43185717],
		["Kansas City","MO",39.09,-94.58,43185757],
		["Bruner","MO",37.01,-92.98,43185797],
		["Denton","KS",39.73,-95.26,43185837],
		["Elsmore","KS",37.79,-95.14,43185877],
		["Iuka","KS",37.72,-98.73,43185917],
		["Rozel","KS",38.19,-99.4,43185957],
		["Kanorado","KS",39.33,-102.03,43185997],
		["Hanston","KS",38.12,-99.71,43186037],
		["Alvo","NE",40.87,-96.38,43186077],
		["Wellfleet","NE",40.75,-100.73,43186117],
		["Tunica","LA",30.93,-91.56,43186157],
		["Harrell","AR",33.51,-92.4,43186197],
		["Casscoe","AR",34.45,-91.32,43186237],
		["Guy","AR",35.32,-92.33,43186277],
		["Sherrill","AR",34.38,-91.95,43186317],
		["Lynn","AR",36,-91.25,43186357],
		["Pleasant Grove","AR",35.84,-91.92,43186397],
		["Saffell","AR",35.91,-91.3,43186437],
		["Mount Judea","AR",35.83,-93.04,43186477],
		["Colony","OK",35.34,-98.67,43186517],
		["Manitou","OK",34.5,-98.98,43186557],
		["Isabella","OK",36.23,-98.33,43186597],
		["Disney","OK",36.47,-95.02,43186637],
		["Finley","OK",34.33,-95.5,43186677],
		["Goree","TX",33.46,-99.52,43186717],
		["San Antonio","TX",29.45,-98.5,43186757],
		["San Antonio","TX",29.45,-98.5,43186797],
		["Bayside","TX",28.09,-97.21,43186837],
		["Santa Elena","TX",26.74,-98.52,43186877],
		["Arriba","CO",39.28,-103.27,43186917],
		["Navajo Dam","NM",36.8,-107.61,43186957],
		["Espanola","NM",36,-106.06,43186997],
		["Watrous","NM",35.79,-104.98,43187037],
		["Avery","CA",38.21,-120.33,43187077],
		["Milford","CA",40.18,-120.36,43187117],
		["Moro","OR",45.41,-120.79,43187157],
		["Tolovana Park","OR",45.86,-123.96,43187197],
		["Neotsu","OR",44.99,-123.98,43187237],
		["Richland","OR",44.79,-117.23,43187277],
		["Wilson Creek","WA",47.46,-119.14,43187317],
		["Anderson","AK",64.2,-148.97,43187357],
		["White Mountain","AK",64.64,-163.73,43187397],
		["Shannock","RI",41.46,-71.67,43187436],
		["Wytopitlock","ME",45.64,-68.07,43187475],
		["Meddybemps","ME",45.03,-67.35,43187514],
		["Northeast Harbor","ME",44.3,-68.36,43187553],
		["North Pomfret","VT",43.72,-72.49,43187592],
		["Manchester","VT",43.16,-73.07,43187631],
		["Greensboro","VT",44.58,-72.28,43187670],
		["Vienna","NJ",40.87,-74.89,43187709],
		["Green Village","NJ",40.74,-74.45,43187748],
		["Summitville","NY",41.62,-74.45,43187787],
		["Inlet","NY",43.72,-74.73,43187826],
		["Three Mile Bay","NY",43.99,-76.25,43187865],
		["Kanona","NY",42.37,-77.36,43187904],
		["South Heights","PA",40.57,-80.23,43187943],
		["Crabtree","PA",40.34,-79.53,43187982],
		["Troutville","PA",41.02,-78.78,43188021],
		["Nu Mine","PA",40.8,-79.26,43188060],
		["Spruce Creek","PA",40.62,-78.14,43188099],
		["New Millport","PA",40.88,-78.48,43188138],
		["Woodward","PA",40.92,-77.3,43188177],
		["Wilburton","PA",40.81,-76.38,43188216],
		["Mahanoy Plane","PA",40.76,-76.07,43188255],
		["Delano","PA",40.84,-76.06,43188294],
		["Gwynedd","PA",40.2,-75.24,43188333],
		["Westover","MD",38.12,-75.7,43188372],
		["Woodville","VA",38.6,-78.17,43188411],
		["Cardinal","VA",37.42,-76.37,43188450],
		["Little Plymouth","VA",37.62,-76.79,43188489],
		["Bim","WV",37.87,-81.69,43188528],
		["Gordon","WV",37.99,-81.68,43188567],
		["Mammoth","WV",38.25,-81.37,43188606],
		["Henlawson","WV",37.89,-81.96,43188645],
		["White Oak","WV",37.68,-81.03,43188684],
		["Hendricks","WV",39.07,-79.63,43188723],
		["Kerens","WV",39.02,-79.75,43188762],
		["Stovall","NC",36.44,-78.56,43188801],
		["Simpson","NC",35.57,-77.27,43188840],
		["Lake Junaluska","NC",35.52,-82.97,43188879],
		["Luray","SC",32.81,-81.24,43188918],
		["Tallassee","TN",35.55,-83.93,43188957],
		["Hellier","KY",37.27,-82.49,43188996],
		["Miamitown","OH",39.2,-84.58,43189035],
		["Melrose","OH",41.08,-84.41,43189074],
		["Ahmeek","MI",47.29,-88.39,43189113],
		["Hardy","IA",42.81,-94.05,43189152],
		["Ledyard","IA",43.42,-94.15,43189191],
		["Ottosen","IA",42.89,-94.37,43189230],
		["Oto","IA",42.28,-95.89,43189269],
		["Deloit","IA",42.09,-95.31,43189308],
		["Marne","IA",41.44,-95.11,43189347],
		["Coin","IA",40.65,-95.23,43189386],
		["Beacon","IA",41.27,-92.68,43189425],
		["Morrisonville","WI",43.27,-89.35,43189464],
		["Briggsville","WI",43.68,-89.57,43189503],
		["Wendell","MN",46.03,-96.1,43189542],
		["Orient","SD",44.9,-99.08,43189581],
		["Glenham","SD",45.53,-100.27,43189620],
		["Crystal","ND",48.59,-97.66,43189659],
		["Dawson","ND",46.86,-99.75,43189698],
		["Souris","ND",48.91,-100.68,43189737],
		["Richey","MT",47.71,-105.03,43189776],
		["Emington","IL",40.97,-88.35,43189815],
		["Dewitt","IL",40.18,-88.74,43189854],
		["Kinderhook","IL",39.7,-91.15,43189893],
		["New Salem","IL",39.7,-90.84,43189932],
		["Frederick","IL",40.06,-90.43,43189971],
		["Bellmont","IL",38.38,-87.9,43190010],
		["Pomona","IL",37.64,-89.34,43190049],
		["Worthington","MO",40.4,-92.68,43190088],
		["Blythedale","MO",40.47,-93.92,43190127],
		["Mooresville","MO",39.74,-93.72,43190166],
		["Windyville","MO",37.7,-92.92,43190205],
		["Garland","KS",37.64,-94.62,43190244],
		["Cuba","KS",39.8,-97.45,43190283],
		["Farwell","NE",41.21,-98.62,43190322],
		["Smithfield","NE",40.57,-99.74,43190361],
		["Haigler","NE",40.01,-101.93,43190400],
		["Clarence","LA",31.82,-93.02,43190439],
		["Pencil Bluff","AR",34.65,-93.74,43190478],
		["Wabbaseka","AR",34.36,-91.79,43190517],
		["Gilmore","AR",35.41,-90.27,43190556],
		["Success","AR",36.45,-90.72,43190595],
		["Sage","AR",36.04,-91.82,43190634],
		["Douglas","OK",36.26,-97.66,43190673],
		["Wakita","OK",36.88,-97.92,43190712],
		["Wainwright","OK",35.61,-95.56,43190751],
		["Deer Creek","OK",36.8,-97.51,43190790],
		["Megargel","TX",33.45,-98.92,43190829],
		["Lane City","TX",29.19,-95.99,43190868],
		["Corpus Christi","TX",27.8,-97.39,43190907],
		["Phippsburg","CO",40.23,-106.93,43190946],
		["Sedgwick","CO",40.93,-102.52,43190985],
		["La Sal","UT",38.23,-109.17,43191024],
		["Scipio","UT",39.18,-112.05,43191063],
		["Nixon","NV",39.86,-119.44,43191102],
		["Brooks","CA",38.8,-122.18,43191141],
		["Gleneden Beach","OR",44.88,-124.03,43191180],
		["Gilchrist","OR",43.51,-121.64,43191219],
		["Ukiah","OR",45.09,-118.89,43191258],
		["Northway","AK",63,-141.69,43191297],
		["East Dennis","MA",41.74,-70.15,43191335],
		["Orrs Island","ME",43.77,-69.96,43191373],
		["Stockholm","ME",47.04,-68.14,43191411],
		["Hancock","VT",43.93,-72.83,43191449],
		["Croton Falls","NY",41.3,-73.71,43191487],
		["Hankins","NY",41.81,-75.08,43191525],
		["Minerva","NY",43.78,-73.97,43191563],
		["Treadwell","NY",42.34,-75.05,43191601],
		["Shippingport","PA",40.62,-80.42,43191639],
		["Fort Hill","PA",39.86,-79.24,43191677],
		["Byrnedale","PA",41.31,-78.48,43191715],
		["Drifting","PA",41,-78.11,43191753],
		["Nelson","PA",41.97,-77.23,43191791],
		["Washington","DC",38.89,-77.03,43191829],
		["Bloomington","MD",39.5,-79.11,43191867],
		["Bivalve","MD",38.3,-75.88,43191905],
		["Irvington","VA",37.66,-76.42,43191943],
		["Iron Gate","VA",37.79,-79.79,43191981],
		["Jenkinjones","WV",37.28,-81.43,43192019],
		["Maybeury","WV",37.37,-81.35,43192057],
		["Josephine","WV",37.62,-81.21,43192095],
		["Center Point","WV",39.42,-80.62,43192133],
		["Cisco","GA",34.95,-84.73,43192171],
		["Ellenton","GA",31.17,-83.58,43192209],
		["Homeland","FL",27.79,-81.85,43192247],
		["Everglades City","FL",25.86,-81.38,43192285],
		["Brookside","AL",33.63,-86.91,43192323],
		["Sunflower","AL",31.37,-88.03,43192361],
		["Louisville","KY",38.22,-85.74,43192399],
		["Benham","KY",36.96,-82.95,43192437],
		["Kenvir","KY",36.85,-83.15,43192475],
		["Bryants Store","KY",36.77,-83.92,43192513],
		["Ary","KY",37.37,-83.14,43192551],
		["Dema","KY",37.43,-82.85,43192589],
		["Youngstown","OH",41.09,-80.64,43192627],
		["Sandyville","OH",40.64,-81.33,43192665],
		["Saratoga","IN",40.23,-84.91,43192703],
		["Black River","MI",44.81,-83.31,43192741],
		["Somerset Center","MI",42.05,-84.42,43192779],
		["Bridgewater","IA",41.24,-94.66,43192817],
		["Archer","IA",43.11,-95.74,43192855],
		["Grand View","WI",46.36,-91.1,43192893],
		["Squaw Lake","MN",47.62,-94.14,43192931],
		["Cavour","SD",44.37,-98.04,43192969],
		["Union Center","SD",44.56,-102.66,43193007],
		["Adams","ND",48.41,-98.07,43193045],
		["Hannaford","ND",47.31,-98.18,43193083],
		["Wing","ND",47.14,-100.28,43193121],
		["Alexander","ND",47.84,-103.64,43193159],
		["Judith Gap","MT",46.63,-109.68,43193197],
		["Butte","MT",45.9,-112.73,43193235],
		["Long Point","IL",41,-88.89,43193273],
		["Peoria","IL",40.74,-89.6,43193311],
		["Longview","IL",39.88,-88.06,43193349],
		["Boody","IL",39.76,-89.05,43193387],
		["Mill Shoals","IL",38.25,-88.34,43193425],
		["Utica","MO",39.74,-93.62,43193463],
		["East Lynne","MO",38.66,-94.22,43193501],
		["Solo","MO",37.23,-91.95,43193539],
		["Durham","KS",38.48,-97.22,43193577],
		["Morganville","KS",39.46,-97.2,43193615],
		["Damar","KS",39.31,-99.58,43193653],
		["Carleton","NE",40.3,-97.67,43193691],
		["Grafton","NE",40.62,-97.71,43193729],
		["Emmet","NE",42.47,-98.8,43193767],
		["Oconto","NE",41.14,-99.76,43193805],
		["Keo","AR",34.61,-92.01,43193843],
		["Oark","AR",35.71,-93.57,43193881],
		["Medicine Park","OK",34.72,-98.46,43193919],
		["Schulter","OK",35.52,-95.95,43193957],
		["Centrahoma","OK",34.6,-96.34,43193995],
		["Chatfield","TX",32.29,-96.36,43194033],
		["Water Valley","TX",31.66,-100.71,43194071],
		["Gillett","TX",29.13,-97.8,43194109],
		["Tarpley","TX",29.63,-99.28,43194147],
		["Estelline","TX",34.54,-100.43,43194185],
		["Forsan","TX",32.11,-101.36,43194223],
		["Sheffield","TX",30.69,-101.82,43194261],
		["Cope","CO",39.67,-102.85,43194299],
		["Maybell","CO",40.52,-108.08,43194337],
		["Reliance","WY",41.67,-109.19,43194375],
		["Croydon","UT",41.13,-111.54,43194413],
		["Lyman","UT",38.37,-111.6,43194451],
		["Willard","NM",34.59,-106.03,43194489],
		["Cordova","NM",36,-105.86,43194527],
		["Valdez","NM",36.53,-105.58,43194565],
		["Wilseyville","CA",38.38,-120.44,43194603],
		["Little River","CA",39.25,-123.73,43194641],
		["Alderpoint","CA",40.18,-123.6,43194679],
		["Tehama","CA",40.02,-122.13,43194717],
		["Goodnews Bay","AK",59.19,-161.71,43194755],
		["Holy Cross","AK",62.18,-159.84,43194793],
		["Nunam Iqua","AK",62.09,-165.18,43194831],
		["Whately","MA",42.45,-72.61,43194868],
		["North Haven","ME",44.15,-68.86,43194905],
		["East Dover","VT",42.96,-72.78,43194942],
		["Troy","VT",44.93,-72.38,43194979],
		["Mantoloking","NJ",40.04,-74.04,43195016],
		["Glen Wild","NY",41.65,-74.58,43195053],
		["Larimer","PA",40.35,-79.72,43195090],
		["Aultman","PA",40.54,-79.22,43195127],
		["Coral","PA",40.51,-79.2,43195164],
		["Eau Claire","PA",41.13,-79.79,43195201],
		["Wood","PA",40.16,-78.13,43195238],
		["Mineral Springs","PA",40.98,-78.35,43195275],
		["Fort Littleton","PA",40.06,-77.93,43195312],
		["Dewart","PA",41.13,-76.86,43195349],
		["Mar Lin","PA",40.68,-76.23,43195386],
		["Sacramento","PA",40.64,-76.61,43195423],
		["Stevensville","PA",41.76,-76.17,43195460],
		["Port Clinton","PA",40.58,-76.02,43195497],
		["Betterton","MD",39.36,-76.07,43195534],
		["Massey","MD",39.31,-75.81,43195571],
		["Elk Mills","MD",39.65,-75.85,43195608],
		["Burgess","VA",37.88,-76.34,43195645],
		["Rappahannock Academy","VA",38.15,-77.3,43195682],
		["Covesville","VA",37.89,-78.7,43195719],
		["Keene","VA",37.86,-78.55,43195756],
		["Hardyville","VA",37.55,-76.38,43195793],
		["Bartow","WV",38.58,-79.71,43195830],
		["Dunmore","WV",38.35,-79.82,43195867],
		["Handley","WV",38.18,-81.36,43195904],
		["Varney","WV",37.67,-82.06,43195941],
		["Glen Jean","WV",37.93,-81.17,43195978],
		["Alum Bridge","WV",39.04,-80.69,43196015],
		["Point Harbor","NC",36.08,-75.8,43196052],
		["Hagan","GA",32.15,-81.92,43196089],
		["Lilly","GA",32.14,-83.87,43196126],
		["Delphia","KY",37.02,-83.08,43196163],
		["Jeff","KY",37.2,-83.13,43196200],
		["Sassafras","KY",37.22,-83.05,43196237],
		["Sumava Resorts","IN",41.16,-87.43,43196274],
		["New Point","IN",39.3,-85.33,43196311],
		["Crane","IN",38.89,-86.9,43196348],
		["Alexander","IA",42.8,-93.47,43196385],
		["Scarville","IA",43.47,-93.61,43196422],
		["Ayrshire","IA",43.04,-94.83,43196459],
		["Vincent","IA",42.59,-94.01,43196496],
		["Larrabee","IA",42.86,-95.54,43196533],
		["Wilmot","WI",42.54,-88.12,43196570],
		["Wyocena","WI",43.49,-89.31,43196607],
		["Port Wing","WI",46.77,-91.38,43196644],
		["Eitzen","MN",43.5,-91.46,43196681],
		["Revere","MN",44.21,-95.36,43196718],
		["Alberta","MN",45.57,-96.05,43196755],
		["Davis","SD",43.25,-96.99,43196792],
		["Oldham","SD",44.22,-97.3,43196829],
		["Almont","ND",46.72,-101.5,43196866],
		["Reeder","ND",46.1,-102.94,43196903],
		["Ledger","MT",48.26,-111.47,43196940],
		["Rudyard","MT",48.45,-110.61,43196977],
		["Littleton","IL",40.23,-90.62,43197014],
		["Mossville","IL",40.81,-89.56,43197051],
		["Henning","IL",40.3,-87.7,43197088],
		["Panama","IL",39.02,-89.52,43197125],
		["Sainte Marie","IL",38.93,-88.02,43197162],
		["Browns","IL",38.37,-87.98,43197199],
		["Keenes","IL",38.33,-88.64,43197236],
		["Radom","IL",38.28,-89.19,43197273],
		["Leonard","MO",39.89,-92.18,43197310],
		["Williamstown","MO",40.2,-91.86,43197347],
		["Oxly","MO",36.58,-90.68,43197384],
		["Gentry","MO",40.33,-94.42,43197421],
		["Mc Fall","MO",40.11,-94.22,43197458],
		["Powersville","MO",40.54,-93.3,43197495],
		["Stoutsville","MO",39.55,-91.85,43197532],
		["Bremen","KS",39.87,-96.74,43197569],
		["Havana","KS",37.09,-95.94,43197606],
		["Beverly","KS",39.01,-97.97,43197643],
		["Prairie View","KS",39.83,-99.57,43197680],
		["Marienthal","KS",38.49,-101.21,43197717],
		["Brownville","NE",40.39,-95.66,43197754],
		["Fay","OK",35.82,-98.65,43197791],
		["Preston","OK",35.72,-95.98,43197828],
		["Francis","OK",34.87,-96.59,43197865],
		["Southmayd","TX",33.62,-96.72,43197902],
		["Avalon","TX",32.33,-96.8,43197939],
		["Simonton","TX",29.68,-95.99,43197976],
		["Campbellton","TX",28.75,-98.3,43198013],
		["Doss","TX",30.47,-99.18,43198050],
		["Morse","TX",36.06,-101.47,43198087],
		["Sanford","TX",35.7,-101.53,43198124],
		["Lakeview","TX",34.67,-100.69,43198161],
		["Wellman","TX",33.04,-102.42,43198198],
		["Tarzan","TX",32.31,-101.98,43198235],
		["Como","CO",39.32,-105.9,43198272],
		["Parshall","CO",40.05,-106.18,43198309],
		["Santa","ID",47.18,-116.41,43198346],
		["Howell","UT",41.79,-112.49,43198383],
		["Snowville","UT",41.81,-112.87,43198420],
		["Fort Thomas","AZ",32.98,-109.98,43198457],
		["Grady","NM",34.82,-103.31,43198494],
		["Edison","CA",35.32,-118.85,43198531],
		["Eddyville","OR",44.57,-123.75,43198568],
		["Westlake","OR",43.92,-123.98,43198605],
		["George","WA",47.07,-119.85,43198642],
		["Aleknagik","AK",59.24,-158.62,43198679],
		["Clam Gulch","AK",60.21,-151.4,43198716],
		["Allakaket","AK",66.57,-152.95,43198753],
		["Ruby","AK",64.79,-153.87,43198790],
		["Burlington","ME",45.2,-68.42,43198826],
		["Chichester","NY",42.08,-74.26,43198862],
		["Dayton","NY",42.42,-78.98,43198898],
		["Dresden","NY",42.68,-76.95,43198934],
		["Beallsville","PA",40.05,-80.03,43198970],
		["Elco","PA",40.08,-79.88,43199006],
		["United","PA",40.16,-79.54,43199042],
		["Cadogan","PA",40.75,-79.58,43199078],
		["Rockhill Furnace","PA",40.24,-77.9,43199114],
		["Zullinger","PA",39.75,-77.52,43199150],
		["Fort Howard","MD",39.2,-76.44,43199186],
		["Wallops Island","VA",37.84,-75.47,43199222],
		["Belspring","VA",37.19,-80.61,43199258],
		["Tannersville","VA",36.97,-81.64,43199294],
		["Mc Dowell","VA",38.3,-79.52,43199330],
		["Jesse","WV",37.66,-81.55,43199366],
		["Macfarlan","WV",39.07,-81.18,43199402],
		["Clifton","SC",34.98,-81.79,43199438],
		["Centenary","SC",34.03,-79.35,43199474],
		["Yankeetown","FL",29.03,-82.71,43199510],
		["Shiloh","TN",35.11,-88.36,43199546],
		["Gordon","KY",37,-83.02,43199582],
		["Union Furnace","OH",39.43,-82.34,43199618],
		["Old Washington","OH",40.03,-81.44,43199654],
		["Osgood","OH",40.34,-84.49,43199690],
		["Wren","OH",40.8,-84.77,43199726],
		["Kingsbury","IN",41.53,-86.69,43199762],
		["Fulton","IN",40.94,-86.26,43199798],
		["Lincoln City","IN",38.12,-86.98,43199834],
		["Copper City","MI",47.28,-88.38,43199870],
		["Coulter","IA",42.73,-93.37,43199906],
		["Henderson","IA",41.13,-95.43,43199942],
		["Braddyville","IA",40.57,-95.03,43199978],
		["Summit Lake","WI",45.38,-89.19,43200014],
		["Haugen","WI",45.6,-91.77,43200050],
		["Tamarack","MN",46.65,-93.11,43200086],
		["Elizabeth","MN",46.37,-96.13,43200122],
		["Kent","MN",46.43,-96.68,43200158],
		["Cresbard","SD",45.16,-98.94,43200194],
		["Cogswell","ND",46.1,-97.78,43200230],
		["Rhame","ND",46.23,-103.65,43200266],
		["Medicine Lake","MT",48.5,-104.37,43200302],
		["Roy","MT",47.42,-108.98,43200338],
		["Bondville","IL",40.11,-88.36,43200374],
		["Piasa","IL",39.11,-90.12,43200410],
		["Springerton","IL",38.17,-88.35,43200446],
		["Sturdivant","MO",37.06,-90.01,43200482],
		["Risco","MO",36.55,-89.81,43200518],
		["Darlington","MO",40.19,-94.4,43200554],
		["Pickering","MO",40.45,-94.84,43200590],
		["Jerome","MO",37.92,-91.97,43200626],
		["Bartlett","KS",37.05,-95.21,43200662],
		["Grenola","KS",37.35,-96.44,43200698],
		["Green","KS",39.43,-97,43200734],
		["Portis","KS",39.56,-98.69,43200770],
		["Alexandria","NE",40.24,-97.38,43200806],
		["Crookston","NE",42.92,-100.75,43200842],
		["Fairmont","OK",36.35,-97.7,43200878],
		["Hunter","OK",36.56,-97.66,43200914],
		["Easton","TX",32.38,-94.59,43200950],
		["Priddy","TX",31.69,-98.5,43200986],
		["Hungerford","TX",29.39,-96.08,43201022],
		["Wadsworth","TX",28.83,-95.93,43201058],
		["Westhoff","TX",29.2,-97.46,43201094],
		["Bleiblerville","TX",30.02,-96.44,43201130],
		["Coyanosa","TX",31.24,-103.07,43201166],
		["Wetmore","CO",38.23,-105.08,43201202],
		["Robertson","WY",41.18,-110.42,43201238],
		["Riverside","UT",41.78,-112.15,43201274],
		["Berino","NM",32.08,-106.56,43201310],
		["Organ","NM",32.42,-106.59,43201346],
		["Ocotillo","CA",32.72,-115.88,43201382],
		["Laguna Woods","CA",33.61,-117.72,43201418],
		["Beckwourth","CA",39.82,-120.37,43201454],
		["Bickleton","WA",45.92,-120.19,43201490],
		["Middlefield","MA",42.35,-73.01,43201525],
		["Clayville","RI",41.77,-71.66,43201560],
		["Pike","NH",44,-72,43201595],
		["Verona Beach","NY",43.19,-75.72,43201630],
		["Woodgate","NY",43.52,-75.15,43201665],
		["Ellisburg","NY",43.73,-76.13,43201700],
		["Gibbon Glade","PA",39.73,-79.56,43201735],
		["Hostetter","PA",40.27,-79.41,43201770],
		["Whitney","PA",40.27,-79.41,43201805],
		["Vowinckel","PA",41.37,-79.25,43201840],
		["Marble","PA",41.32,-79.44,43201875],
		["Lawn","PA",40.22,-76.55,43201910],
		["Mc Ewensville","PA",41.07,-76.81,43201945],
		["Quakake","PA",40.84,-75.97,43201980],
		["Weston","PA",41.68,-76.5,43202015],
		["South Gibson","PA",41.73,-75.63,43202050],
		["Galesville","MD",38.84,-76.54,43202085],
		["Mcdaniel","MD",38.82,-76.28,43202120],
		["King And Queen Court House","VA",37.72,-76.84,43202155],
		["Elkhorn","WV",37.35,-81.45,43202190],
		["Blount","WV",38.3,-81.38,43202225],
		["Piney View","WV",37.84,-81.13,43202260],
		["Norton","WV",38.94,-79.94,43202295],
		["Napier","WV",38.77,-80.57,43202330],
		["Roaring Gap","NC",36.4,-80.98,43202365],
		["Ducktown","TN",35.03,-84.38,43202400],
		["Sherwood","TN",35.06,-85.95,43202435],
		["Granville","TN",36.27,-85.74,43202470],
		["Louisville","KY",38.22,-85.74,43202505],
		["Vest","KY",37.39,-83,43202540],
		["Hestand","KY",36.65,-85.61,43202575],
		["La Fayette","KY",36.66,-87.65,43202610],
		["Sharon Center","OH",41.09,-81.73,43202645],
		["Burket","IN",41.15,-85.96,43202680],
		["Samaria","MI",41.8,-83.57,43202715],
		["Barbeau","MI",46.28,-84.28,43202750],
		["Barnes City","IA",41.5,-92.46,43202785],
		["Thayer","IA",41.02,-94.04,43202820],
		["College Springs","IA",40.62,-95.12,43202855],
		["Marquette","IA",43.04,-91.18,43202890],
		["Waldorf","MN",43.93,-93.69,43202925],
		["New Munich","MN",45.63,-94.75,43202960],
		["Lake City","SD",45.72,-97.41,43202995],
		["Galesburg","ND",47.27,-97.4,43203030],
		["Sheldon","ND",46.58,-97.49,43203065],
		["Esmond","ND",48.03,-99.76,43203100],
		["Golden Valley","ND",47.29,-102.06,43203135],
		["Kintyre","ND",46.55,-99.95,43203170],
		["Sterling","ND",46.81,-100.28,43203205],
		["Makoti","ND",47.96,-101.8,43203240],
		["Pompeys Pillar","MT",45.91,-107.93,43203275],
		["Geyser","MT",47.2,-110.44,43203310],
		["Fremont","MO",36.9,-91.16,43203345],
		["Polk","MO",37.72,-93.29,43203380],
		["Clearview City","KS",38.94,-94.95,43203415],
		["Vermillion","KS",39.71,-96.26,43203450],
		["Admire","KS",38.64,-96.1,43203485],
		["Collyer","KS",39.03,-100.11,43203520],
		["Kirwin","KS",39.66,-99.12,43203555],
		["Mc Donald","KS",39.78,-101.37,43203590],
		["Salem","NE",40.07,-95.72,43203625],
		["Kilbourne","LA",32.99,-91.31,43203660],
		["Camp","AR",36.39,-91.72,43203695],
		["Maramec","OK",36.24,-96.68,43203730],
		["Ketchum","OK",36.52,-95.02,43203765],
		["Lamar","OK",35.09,-96.12,43203800],
		["Elysian Fields","TX",32.44,-94.2,43203835],
		["Dickens","TX",33.62,-100.83,43203870],
		["Wingate","TX",32.04,-100.11,43203905],
		["Imperial","TX",31.27,-102.69,43203940],
		["Agate","CO",39.47,-103.95,43203975],
		["Stoneham","CO",40.63,-103.65,43204010],
		["Cahone","CO",37.65,-108.82,43204045],
		["Yellowstone National Park","WY",44.57,-110.51,43204080],
		["Mc Kinnon","WY",41.02,-109.93,43204115],
		["Park Valley","UT",41.74,-113.44,43204150],
		["Bryce","UT",37.63,-112.2,43204185],
		["Mcintosh","NM",34.85,-106.04,43204220],
		["Torreon","NM",34.72,-106.29,43204255],
		["Tres Piedras","NM",36.64,-105.96,43204290],
		["O Neals","CA",37.16,-119.62,43204325],
		["Klamath River","CA",41.83,-122.92,43204360],
		["Paynes Creek","CA",40.35,-121.86,43204395],
		["Dayville","OR",44.46,-119.5,43204430],
		["Kimberly","OR",44.69,-119.54,43204465],
		["Orcas","WA",48.61,-122.88,43204500],
		["Moose Pass","AK",60.57,-149.45,43204535],
		["South Lee","MA",42.3,-73.23,43204569],
		["Waterville Valley","NH",43.95,-71.5,43204603],
		["Mirror Lake","NH",43.63,-71.28,43204637],
		["Craftsbury Common","VT",44.65,-72.41,43204671],
		["North Concord","VT",44.56,-71.77,43204705],
		["Juliustown","NJ",39.95,-74.66,43204739],
		["Piseco","NY",43.42,-74.53,43204773],
		["Surprise","NY",42.35,-73.95,43204807],
		["Spring Glen","NY",41.66,-74.42,43204841],
		["North Hudson","NY",43.98,-73.7,43204875],
		["Thendara","NY",43.83,-74.99,43204909],
		["West Eaton","NY",42.85,-75.65,43204943],
		["Bairdford","PA",40.54,-79.83,43204977],
		["Beaverdale","PA",40.32,-78.69,43205011],
		["Mc Grann","PA",40.76,-79.53,43205045],
		["Mill Village","PA",41.87,-79.97,43205079],
		["Gaines","PA",41.78,-77.54,43205113],
		["Granville","PA",40.54,-77.6,43205147],
		["Seltzer","PA",40.69,-76.22,43205181],
		["Millrift","PA",41.4,-74.74,43205215],
		["Burr Hill","VA",38.34,-77.86,43205249],
		["Hustle","VA",38.04,-77.06,43205283],
		["Portsmouth","VA",36.83,-76.29,43205317],
		["Miami","WV",38.06,-81.42,43205351],
		["Yolyn","WV",37.86,-81.86,43205385],
		["Corinne","WV",37.57,-81.35,43205419],
		["Glen White","WV",37.74,-81.26,43205453],
		["Minden","WV",37.99,-81.08,43205487],
		["Sheldon","SC",32.6,-80.79,43205521],
		["Cecil","GA",31.04,-83.39,43205555],
		["Key Colony Beach","FL",24.72,-81.02,43205589],
		["Fort Davis","AL",32.29,-85.73,43205623],
		["Satartia","MS",32.67,-90.54,43205657],
		["Lockport","KY",38.43,-84.98,43205691],
		["Moorefield","KY",38.26,-83.93,43205725],
		["Fourmile","KY",36.79,-83.74,43205759],
		["Elkfork","KY",37.96,-83.13,43205793],
		["Columbus","KY",36.75,-89.1,43205827],
		["Chickasaw","OH",40.43,-84.49,43205861],
		["Toivola","MI",47,-88.75,43205895],
		["Saint Anthony","IA",42.12,-93.19,43205929],
		["Tingley","IA",40.85,-94.19,43205963],
		["Greenville","IA",43.01,-95.14,43205997],
		["Arion","IA",41.94,-95.46,43206031],
		["Blencoe","IA",41.92,-96.08,43206065],
		["Imogene","IA",40.87,-95.42,43206099],
		["Conroy","IA",41.73,-92,43206133],
		["Yarmouth","IA",41.03,-91.31,43206167],
		["Taconite","MN",47.32,-93.36,43206201],
		["Strandburg","SD",45.04,-96.76,43206235],
		["Hosmer","SD",45.57,-99.47,43206269],
		["Arvilla","ND",47.91,-97.49,43206303],
		["Rocklake","ND",48.79,-99.24,43206337],
		["Arnegard","ND",47.8,-103.43,43206371],
		["Birney","MT",45.27,-106.6,43206405],
		["Paradise","MT",47.39,-114.76,43206439],
		["Esmond","IL",42.02,-88.94,43206473],
		["Seatonville","IL",41.36,-89.27,43206507],
		["Dunfermline","IL",40.49,-90.03,43206541],
		["New Memphis","IL",38.47,-89.67,43206575],
		["Camden","IL",40.15,-90.77,43206609],
		["Chambersburg","IL",39.81,-90.65,43206643],
		["Lima","IL",40.17,-91.37,43206677],
		["Bulpitt","IL",39.59,-89.42,43206711],
		["Alsey","IL",39.55,-90.43,43206745],
		["Holland","MO",36.05,-89.87,43206779],
		["Clearmont","MO",40.5,-95.03,43206813],
		["Fulton","KS",38,-94.71,43206847],
		["Newport","NE",42.6,-99.32,43206881],
		["Kilgore","NE",42.93,-100.95,43206915],
		["Whitney","NE",42.78,-103.25,43206949],
		["Belmont","LA",31.71,-93.49,43206983],
		["Sieper","LA",31.19,-92.78,43207017],
		["Wilton","AR",33.73,-94.14,43207051],
		["Haynes","AR",34.88,-90.79,43207085],
		["Gotebo","OK",35.07,-98.87,43207119],
		["Gould","OK",34.66,-99.77,43207153],
		["Nash","OK",36.66,-98.05,43207187],
		["Ringgold","TX",33.8,-97.95,43207221],
		["Roaring Springs","TX",33.89,-100.85,43207255],
		["Jamestown","CO",40.11,-105.39,43207289],
		["Bovill","ID",46.9,-116.36,43207323],
		["Laclede","ID",48.16,-116.75,43207357],
		["Hanna","UT",40.54,-110.73,43207391],
		["Ibapah","UT",40.2,-113.86,43207425],
		["Greenville","UT",38.22,-112.8,43207459],
		["Hope","NM",32.81,-104.73,43207493],
		["Malaga","NM",32.22,-104.07,43207527],
		["Des Moines","NM",36.76,-103.83,43207561],
		["Searchlight","NV",35.49,-114.89,43207595],
		["Weott","CA",40.31,-123.92,43207629],
		["Gazelle","CA",41.42,-122.67,43207663],
		["Timber","OR",45.74,-123.31,43207697],
		["Olga","WA",48.63,-122.8,43207731],
		["Tokeland","WA",46.74,-123.99,43207765],
		["Loomis","WA",48.87,-119.73,43207799],
		["Hartline","WA",47.68,-119.12,43207833],
		["Washtucna","WA",46.82,-118.32,43207867],
		["Kobuk","AK",66.93,-156.88,43207901],
		["Rockville","RI",41.44,-71.77,43207934],
		["Eaton Center","NH",43.9,-71.06,43207967],
		["North Bridgton","ME",44.1,-70.7,43208000],
		["Topsfield","ME",45.41,-67.73,43208033],
		["Belgrade Lakes","ME",44.51,-69.84,43208066],
		["Windsor","NJ",40.21,-74.61,43208099],
		["Pond Eddy","NY",41.44,-74.82,43208132],
		["Retsof","NY",42.86,-77.88,43208165],
		["Warrendale","PA",40.64,-80.09,43208198],
		["Aleppo","PA",39.82,-80.45,43208231],
		["Oliver","PA",39.91,-79.72,43208264],
		["Lowber","PA",40.23,-79.71,43208297],
		["Lamar","PA",41.01,-77.51,43208330],
		["Emigsville","PA",40,-76.73,43208363],
		["Railroad","PA",39.76,-76.69,43208396],
		["Muir","PA",40.59,-76.51,43208429],
		["Lenoxville","PA",41.66,-75.63,43208462],
		["South Montrose","PA",41.79,-75.89,43208495],
		["Glen Echo","MD",38.96,-77.14,43208528],
		["Still Pond","MD",39.32,-76.05,43208561],
		["Radiant","VA",38.31,-78.17,43208594],
		["Claremont","VA",37.22,-76.96,43208627],
		["Hampden Sydney","VA",37.24,-78.45,43208660],
		["Whitewood","VA",37.26,-81.88,43208693],
		["Montcalm","WV",37.35,-81.25,43208726],
		["Premier","WV",37.43,-81.63,43208759],
		["Rock View","WV",37.64,-81.51,43208792],
		["Artie","WV",37.91,-81.36,43208825],
		["Ottawa","WV",37.96,-81.82,43208858],
		["Pullman","WV",39.18,-80.94,43208891],
		["Little Birch","WV",38.57,-80.7,43208924],
		["Lost City","WV",38.92,-78.83,43208957],
		["Yellow Spring","WV",39.18,-78.49,43208990],
		["Lake Como","FL",29.48,-81.57,43209023],
		["Nashville","TN",36.17,-86.78,43209056],
		["Farrell","MS",34.26,-90.67,43209089],
		["Philipp","MS",33.75,-90.2,43209122],
		["Lick Creek","KY",37.34,-82.32,43209155],
		["Hayesville","OH",40.77,-82.26,43209188],
		["Coalton","OH",39.11,-82.61,43209221],
		["Mexico","IN",40.81,-86.11,43209254],
		["Clifford","IN",39.28,-85.86,43209287],
		["Derby","IN",38.03,-86.53,43209320],
		["Conway","MI",45.41,-84.87,43209353],
		["Bouton","IA",41.85,-94.01,43209386],
		["Havelock","IA",42.83,-94.7,43209419],
		["Halbur","IA",42,-94.97,43209452],
		["Randolph","IA",40.87,-95.56,43209485],
		["Embarrass","WI",44.67,-88.7,43209518],
		["Hovland","MN",47.83,-89.97,43209551],
		["Goodland","MN",47.16,-93.13,43209584],
		["Freeborn","MN",43.76,-93.56,43209617],
		["Wallace","SD",45.08,-97.47,43209650],
		["Columbia","SD",45.61,-98.31,43209683],
		["Wood","SD",43.49,-100.48,43209716],
		["Amenia","ND",47,-97.22,43209749],
		["Clifford","ND",47.34,-97.4,43209782],
		["Berlin","ND",46.37,-98.48,43209815],
		["Courtenay","ND",47.22,-98.56,43209848],
		["Rapelje","MT",45.95,-109.27,43209881],
		["Volborg","MT",45.98,-105.72,43209914],
		["Loma","MT",48.05,-110.45,43209947],
		["Turner","MT",48.79,-108.45,43209980],
		["Avon","MT",46.65,-112.6,43210013],
		["Willow Creek","MT",45.82,-111.63,43210046],
		["Big Arm","MT",47.74,-114.32,43210079],
		["Proctor","MT",47.91,-114.35,43210112],
		["Clare","IL",42.02,-88.84,43210145],
		["Blackstone","IL",41.06,-88.64,43210178],
		["Adair","IL",40.41,-90.5,43210211],
		["Sciota","IL",40.56,-90.74,43210244],
		["Springfield","IL",39.78,-89.64,43210277],
		["New Boston","MO",39.95,-92.88,43210310],
		["Burr Oak","KS",39.87,-98.3,43210343],
		["Cambridge","KS",37.31,-96.66,43210376],
		["Abbyville","KS",37.97,-98.2,43210409],
		["Mc Cracken","KS",38.58,-99.56,43210442],
		["Alton","KS",39.46,-98.94,43210475],
		["Edison","NE",40.27,-99.77,43210508],
		["Danbury","NE",40.03,-100.4,43210541],
		["Husser","LA",30.7,-90.32,43210574],
		["Natalbany","LA",30.54,-90.48,43210607],
		["Winchester","AR",33.77,-91.47,43210640],
		["Oakland","AR",36.44,-92.57,43210673],
		["Meridian","OK",34.42,-97.97,43210706],
		["Oakwood","OK",35.93,-98.7,43210739],
		["Fate","TX",32.94,-96.38,43210772],
		["Myra","TX",33.62,-97.31,43210805],
		["Flynn","TX",31.14,-96.12,43210838],
		["Shiro","TX",30.63,-95.9,43210871],
		["Concan","TX",29.51,-99.73,43210904],
		["Pyote","TX",31.53,-103.12,43210937],
		["Jefferson","CO",39.38,-105.8,43210970],
		["Parkman","WY",44.96,-107.31,43211003],
		["Kanarraville","UT",37.53,-113.17,43211036],
		["Teasdale","UT",38.23,-111.47,43211069],
		["Canjilon","NM",36.47,-106.43,43211102],
		["Roy","NM",35.94,-104.19,43211135],
		["Rail Road Flat","CA",38.31,-120.51,43211168],
		["Bridgeville","CA",40.42,-123.52,43211201],
		["Meadow Valley","CA",39.92,-121.05,43211234],
		["Hat Creek","CA",40.8,-121.46,43211267],
		["Grass Valley","OR",45.28,-120.73,43211300],
		["Rufus","OR",45.66,-120.77,43211333],
		["Crane","OR",43.4,-118.4,43211366],
		["Drewsey","OR",43.81,-118.49,43211399],
		["Latah","WA",47.3,-117.14,43211432],
		["Minto","AK",65.09,-149.64,43211465],
		["Slatersville","RI",41.97,-71.55,43211497],
		["North Hartland","VT",43.59,-72.35,43211529],
		["South Pomfret","VT",43.68,-72.51,43211561],
		["Wauregan","CT",41.75,-71.91,43211593],
		["Stephentown","NY",42.54,-73.44,43211625],
		["Gabriels","NY",44.43,-74.19,43211657],
		["Deferiet","NY",44.03,-75.67,43211689],
		["Plessis","NY",44.27,-75.84,43211721],
		["Jones Mills","PA",40.06,-79.31,43211753],
		["Alverda","PA",40.63,-78.87,43211785],
		["Newry","PA",40.39,-78.43,43211817],
		["Waterville","PA",41.32,-77.35,43211849],
		["White Deer","PA",41.05,-76.96,43211881],
		["Wittman","MD",38.78,-76.3,43211913],
		["Low Moor","VA",37.74,-79.9,43211945],
		["Wilsondale","WV",37.95,-82.36,43211977],
		["Creston","WV",38.96,-81.25,43212009],
		["Montana Mines","WV",39.51,-80.1,43212041],
		["Bayard","WV",39.26,-79.36,43212073],
		["Slater","SC",35.07,-82.53,43212105],
		["Rupert","GA",32.42,-84.28,43212137],
		["Saint Marks","FL",30.16,-84.2,43212169],
		["Miami Beach","FL",25.77,-80.2,43212201],
		["Bellwood","AL",31.17,-85.79,43212233],
		["Nashville","TN",36.17,-86.78,43212265],
		["Milledgeville","TN",35.37,-88.36,43212297],
		["Sebastopol","MS",32.56,-89.33,43212329],
		["Green Road","KY",36.95,-83.83,43212361],
		["Garner","KY",37.35,-82.92,43212393],
		["Gomer","OH",40.81,-84.13,43212425],
		["Oakville","IN",40.07,-85.38,43212457],
		["Columbia","IA",41.18,-93.15,43212489],
		["Macksburg","IA",41.21,-94.18,43212521],
		["Nodaway","IA",40.93,-94.89,43212553],
		["Exline","IA",40.64,-92.84,43212585],
		["Foxhome","MN",46.27,-96.31,43212617],
		["Ideal","SD",43.54,-99.9,43212649],
		["Quinn","SD",43.98,-102.12,43212681],
		["Wasta","SD",44.06,-102.44,43212713],
		["Antler","ND",48.97,-101.28,43212745],
		["Dagmar","MT",48.56,-104.15,43212777],
		["Plevna","MT",46.41,-104.56,43212809],
		["Basin","MT",46.32,-112.41,43212841],
		["Berwick","IL",40.8,-90.53,43212873],
		["Graymont","IL",40.9,-88.75,43212905],
		["Metcalf","IL",39.8,-87.8,43212937],
		["Tennessee","IL",40.41,-90.83,43212969],
		["Blodgett","MO",37,-89.52,43213001],
		["Bixby","MO",37.66,-91.08,43213033],
		["Powhattan","KS",39.76,-95.63,43213065],
		["Utica","KS",38.64,-100.17,43213097],
		["Norcatur","KS",39.83,-100.18,43213129],
		["Endicott","NE",40.08,-97.09,43213161],
		["Manley","NE",40.91,-96.16,43213193],
		["Concord","NE",42.38,-96.98,43213225],
		["Hardy","NE",40.01,-97.92,43213257],
		["Fisher","LA",31.49,-93.46,43213289],
		["Glencoe","AR",36.29,-91.77,43213321],
		["Morrow","AR",35.85,-94.38,43213353],
		["Slick","OK",35.77,-96.26,43213385],
		["Bromide","OK",34.41,-96.49,43213417],
		["Nardin","OK",36.8,-97.44,43213449],
		["Melvin","TX",31.19,-99.58,43213481],
		["Barnhart","TX",31.12,-101.17,43213513],
		["El Indio","TX",28.51,-100.31,43213545],
		["Cotton Center","TX",33.99,-101.99,43213577],
		["Midkiff","TX",31.63,-101.83,43213609],
		["Pritchett","CO",37.37,-102.85,43213641],
		["Chama","CO",37.17,-105.38,43213673],
		["Manville","WY",42.77,-104.61,43213705],
		["Otto","WY",44.4,-108.27,43213737],
		["Cora","WY",42.94,-109.98,43213769],
		["Thatcher","ID",42.35,-111.64,43213801],
		["Cibola","AZ",33.32,-114.64,43213833],
		["Dragoon","AZ",32.02,-110.04,43213865],
		["Floyd","NM",34.21,-103.54,43213897],
		["Monument","NM",32.62,-103.26,43213929],
		["Bent","NM",33.15,-105.88,43213961],
		["Elk","CA",39.12,-123.62,43213993],
		["Seiad Valley","CA",41.86,-123.22,43214025],
		["Spray","OR",44.77,-119.9,43214057],
		["Index","WA",47.84,-121.43,43214089],
		["Crooked Creek","AK",61.86,-157.99,43214121],
		["Trevett","ME",43.89,-69.67,43214152],
		["South Woodstock","VT",43.57,-72.53,43214183],
		["Belmont","VT",43.42,-72.81,43214214],
		["Beecher Falls","VT",45,-71.51,43214245],
		["Middle Haddam","CT",41.56,-72.5,43214276],
		["Quinton","NJ",39.52,-75.38,43214307],
		["Cragsmoor","NY",41.68,-74.41,43214338],
		["Apulia Station","NY",42.81,-76.07,43214369],
		["Fine","NY",44.25,-75.13,43214400],
		["Willard","NY",42.68,-76.86,43214431],
		["Pulteney","NY",42.52,-77.16,43214462],
		["Madison","PA",40.24,-79.67,43214493],
		["Emeigh","PA",40.69,-78.76,43214524],
		["Mc Intyre","PA",40.59,-79.3,43214555],
		["Elton","PA",40.33,-78.77,43214586],
		["Washingtonville","PA",41.05,-76.67,43214617],
		["Bozman","MD",38.75,-76.26,43214648],
		["State Farm","VA",37.64,-77.82,43214679],
		["Steeles Tavern","VA",37.92,-79.2,43214710],
		["Beeson","WV",37.49,-81.18,43214741],
		["Itmann","WV",37.55,-81.4,43214772],
		["Pax","WV",37.9,-81.26,43214803],
		["Maury","NC",35.51,-77.62,43214834],
		["Drayton","SC",34.99,-81.92,43214865],
		["Una","SC",34.99,-81.92,43214896],
		["Captiva","FL",26.58,-82.21,43214927],
		["Chokoloskee","FL",25.83,-81.35,43214958],
		["Nobleton","FL",28.64,-82.26,43214989],
		["Garden City","AL",34.01,-86.75,43215020],
		["Wilder","TN",36.26,-85.08,43215051],
		["Rome","MS",33.93,-90.52,43215082],
		["London","KY",37.12,-84.08,43215113],
		["Robinson Creek","KY",37.32,-82.57,43215144],
		["Lucas","KY",36.88,-86.03,43215175],
		["South Carrollton","KY",37.33,-87.14,43215206],
		["Bladensburg","OH",40.27,-82.23,43215237],
		["West Elkton","OH",39.58,-84.55,43215268],
		["Higginsport","OH",38.79,-83.96,43215299],
		["Latty","OH",41.08,-84.58,43215330],
		["New Hampshire","OH",40.61,-83.93,43215361],
		["Salamonia","IN",40.38,-84.86,43215392],
		["Universal","IN",39.62,-87.45,43215423],
		["Barton City","MI",44.68,-83.6,43215454],
		["Paradise","MI",46.62,-85.03,43215485],
		["Trout Creek","MI",46.48,-89.02,43215516],
		["Crystal Lake","IA",43.22,-93.79,43215547],
		["Shannon City","IA",40.9,-94.26,43215578],
		["Mineola","IA",41.11,-95.74,43215609],
		["Pickerel","WI",45.36,-88.91,43215640],
		["Winnebago","WI",44.06,-88.51,43215671],
		["Knife River","MN",46.95,-91.78,43215702],
		["Iona","MN",43.91,-95.78,43215733],
		["Odin","MN",43.86,-94.74,43215764],
		["Clontarf","MN",45.37,-95.67,43215795],
		["Elrosa","MN",45.56,-94.94,43215826],
		["Outing","MN",46.82,-93.94,43215857],
		["Vivian","SD",43.92,-100.29,43215888],
		["Lantry","SD",45.02,-101.43,43215919],
		["Pollock","SD",45.9,-100.29,43215950],
		["Stirum","ND",46.21,-97.8,43215981],
		["Sharon","ND",47.59,-97.89,43216012],
		["Pingree","ND",47.16,-98.9,43216043],
		["Fort Peck","MT",48,-106.44,43216074],
		["Carter","MT",47.88,-111.02,43216105],
		["Whitewater","MT",48.78,-107.51,43216136],
		["Barstow","IL",41.51,-90.35,43216167],
		["Royal","IL",40.19,-87.97,43216198],
		["Rockbridge","IL",39.27,-90.2,43216229],
		["Concord","IL",39.81,-90.37,43216260],
		["Nilwood","IL",39.39,-89.8,43216291],
		["Catron","MO",36.61,-89.7,43216322],
		["Kansas City","MO",39.09,-94.58,43216353],
		["Harris","MO",40.3,-93.35,43216384],
		["Elmdale","KS",38.37,-96.64,43216415],
		["Wichita","KS",37.68,-97.34,43216446],
		["Alden","KS",38.24,-98.31,43216477],
		["Luray","KS",39.11,-98.69,43216508],
		["Chester","NE",40,-97.61,43216539],
		["Nemaha","NE",40.33,-95.67,43216570],
		["Shubert","NE",40.23,-95.68,43216601],
		["Inman","NE",42.38,-98.52,43216632],
		["Venango","NE",40.76,-102.04,43216663],
		["Lakeside","NE",42.06,-102.46,43216694],
		["Tatums","OK",34.47,-97.46,43216725],
		["Willow","OK",35.05,-99.5,43216756],
		["Gate","OK",36.85,-100.05,43216787],
		["Snow","OK",34.4,-95.42,43216818],
		["Lissie","TX",29.57,-96.2,43216849],
		["Rio Frio","TX",29.63,-99.73,43216880],
		["Amherst","CO",40.68,-102.17,43216911],
		["Colorado Springs","CO",38.86,-104.76,43216942],
		["Elk Mountain","WY",41.68,-106.41,43216973],
		["Beulah","WY",44.53,-104.08,43217004],
		["Moran","WY",43.84,-110.5,43217035],
		["Bern","ID",42.36,-111.37,43217066],
		["Kootenai","ID",48.31,-116.51,43217097],
		["Tensed","ID",47.12,-116.79,43217128],
		["Lake Powell","UT",37.57,-110.32,43217159],
		["Boulder","UT",37.92,-111.48,43217190],
		["Nutrioso","AZ",33.93,-109.22,43217221],
		["Costilla","NM",36.97,-105.53,43217252],
		["Chacon","NM",36.14,-105.36,43217283],
		["Mimbres","NM",32.85,-107.97,43217314],
		["Canyon","CA",37.82,-122.16,43217345],
		["Redcrest","CA",40.37,-123.84,43217376],
		["Dairy","OR",42.34,-121.59,43217407],
		["Harper","OR",43.86,-117.64,43217438],
		["Grays River","WA",46.35,-123.6,43217469],
		["Kearsarge","NH",44.06,-71.06,43217499],
		["East Wilton","ME",44.61,-70.18,43217529],
		["Swans Island","ME",44.14,-68.45,43217559],
		["Rancocas","NJ",40.02,-74.88,43217589],
		["Jamaica","NY",40.68,-73.78,43217619],
		["Lake Hill","NY",42.07,-74.2,43217649],
		["Piercefield","NY",44.23,-74.57,43217679],
		["Minetto","NY",43.39,-76.48,43217709],
		["Nelliston","NY",42.93,-74.6,43217739],
		["Harford","NY",42.42,-76.22,43217769],
		["Ronco","PA",39.86,-79.9,43217799],
		["Hannastown","PA",40.34,-79.53,43217829],
		["Parkhill","PA",40.36,-78.86,43217859],
		["Gifford","PA",41.85,-78.56,43217889],
		["Arnot","PA",41.66,-77.14,43217919],
		["Morris Run","PA",41.66,-77.04,43217949],
		["Neelyton","PA",40.13,-77.85,43217979],
		["Codorus","PA",39.8,-76.82,43218009],
		["Huntington Mills","PA",41.19,-76.23,43218039],
		["Fenwick Island","DE",38.46,-75.05,43218069],
		["Coltons Point","MD",38.23,-76.76,43218099],
		["Laneview","VA",37.77,-76.73,43218129],
		["Onemo","VA",37.39,-76.27,43218159],
		["Covel","WV",37.48,-81.31,43218189],
		["Ikes Fork","WV",37.53,-81.79,43218219],
		["Newhall","WV",37.26,-81.6,43218249],
		["Ragland","WV",37.7,-82.11,43218279],
		["Upperglade","WV",38.43,-80.5,43218309],
		["Rodanthe","NC",35.69,-75.48,43218339],
		["Elliott","SC",34.11,-80.18,43218369],
		["Daufuskie Island","SC",32.12,-80.86,43218399],
		["Wacissa","FL",30.35,-83.98,43218429],
		["Knoxville","TN",35.97,-83.94,43218459],
		["Morris","GA",31.82,-84.94,43218489],
		["Sandgap","KY",37.48,-84.09,43218519],
		["Eastern","KY",37.51,-82.8,43218549],
		["Gays Creek","KY",37.33,-83.4,43218579],
		["Eighty Eight","KY",36.91,-85.76,43218609],
		["Mc Henry","KY",37.37,-86.92,43218639],
		["Amo","IN",39.68,-86.61,43218669],
		["Riley","IN",39.38,-87.3,43218699],
		["Buffalo","IN",40.89,-86.73,43218729],
		["Topinabee","MI",45.48,-84.6,43218759],
		["Trout Lake","MI",46.18,-85.01,43218789],
		["Watton","MI",46.53,-88.6,43218819],
		["Decatur","IA",40.76,-93.84,43218849],
		["Dolliver","IA",43.46,-94.61,43218879],
		["Plano","IA",40.75,-93.04,43218909],
		["Friesland","WI",43.58,-89.06,43218939],
		["Ephraim","WI",45.15,-87.16,43218969],
		["Pearson","WI",45.36,-89.01,43218999],
		["Tripoli","WI",45.55,-90,43219029],
		["Mendota","MN",44.88,-93.15,43219059],
		["Northrop","MN",43.73,-94.43,43219089],
		["Ormsby","MN",43.85,-94.69,43219119],
		["Holloway","MN",45.24,-95.91,43219149],
		["Rutland","SD",44.06,-96.94,43219179],
		["Eden","SD",45.61,-97.42,43219209],
		["Yale","SD",44.43,-97.98,43219239],
		["Mound City","SD",45.72,-100.06,43219269],
		["Gackle","ND",46.62,-99.14,43219299],
		["Sykeston","ND",47.46,-99.39,43219329],
		["New Leipzig","ND",46.37,-101.95,43219359],
		["Fallon","MT",46.73,-104.88,43219389],
		["Kinsman","IL",41.19,-88.56,43219419],
		["Cedar Point","IL",41.26,-89.12,43219449],
		["Coello","IL",38.04,-89.09,43219479],
		["Logan","IL",37.93,-88.84,43219509],
		["Anniston","MO",36.82,-89.32,43219539],
		["Humphreys","MO",40.12,-93.31,43219569],
		["Centerville","KS",38.21,-95.01,43219599],
		["Beattie","KS",39.86,-96.41,43219629],
		["Formoso","KS",39.77,-97.99,43219659],
		["Coats","KS",37.51,-98.82,43219689],
		["Niotaze","KS",37.06,-96.01,43219719],
		["Belpre","KS",37.95,-99.09,43219749],
		["Hudson","KS",38.1,-98.66,43219779],
		["Glade","KS",39.68,-99.31,43219809],
		["Burr","NE",40.53,-96.29,43219839],
		["Du Bois","NE",40.03,-96.04,43219869],
		["Ericson","NE",41.78,-98.67,43219899],
		["Naper","NE",42.96,-99.09,43219929],
		["Archer","NE",41.16,-98.13,43219959],
		["Hazard","NE",41.09,-99.07,43219989],
		["Saronville","NE",40.6,-97.93,43220019],
		["Dickens","NE",40.82,-100.99,43220049],
		["Keystone","NE",41.22,-101.59,43220079],
		["Venice","LA",29.27,-89.35,43220109],
		["Gilliam","LA",32.82,-93.84,43220139],
		["Fort Necessity","LA",31.97,-91.81,43220169],
		["Amagon","AR",35.56,-91.11,43220199],
		["Letona","AR",35.36,-91.83,43220229],
		["Albion","OK",34.66,-95.09,43220259],
		["Pecan Gap","TX",33.43,-95.85,43220289],
		["Caddo","TX",32.68,-98.64,43220319],
		["Purmela","TX",31.46,-97.9,43220349],
		["London","TX",30.67,-99.57,43220379],
		["Orchard","TX",29.6,-95.96,43220409],
		["San Isidro","TX",26.73,-98.39,43220439],
		["Briscoe","TX",35.58,-100.28,43220469],
		["Knott","TX",32.4,-101.64,43220499],
		["Louviers","CO",39.47,-105,43220529],
		["Joes","CO",39.65,-102.68,43220559],
		["Hasty","CO",38.12,-102.97,43220589],
		["Ahsahka","ID",46.52,-116.3,43220619],
		["Clawson","UT",39.04,-110.98,43220649],
		["Cannonville","UT",37.57,-112.08,43220679],
		["Rio Verde","AZ",33.73,-111.68,43220709],
		["Yarnell","AZ",34.26,-112.78,43220739],
		["Jerome","AZ",34.71,-112.16,43220769],
		["Coyote","NM",36.16,-106.61,43220799],
		["Tecate","CA",32.59,-116.58,43220829],
		["Tomales","CA",38.24,-122.9,43220859],
		["Glencoe","CA",38.35,-120.58,43220889],
		["Swisshome","OR",44.12,-123.81,43220919],
		["Chinook","WA",46.28,-123.91,43220949],
		["Indian","AK",61,-149.43,43220979],
		["Mekoryuk","AK",60.08,-166.48,43221009],
		["Ouzinkie","AK",57.93,-152.42,43221039],
		["South Acworth","NH",43.18,-72.32,43221068],
		["Brookton","ME",45.52,-67.76,43221097],
		["East Arlington","VT",43.06,-73.13,43221126],
		["West Wardsboro","VT",43.02,-72.85,43221155],
		["Cossayuna","NY",43.17,-73.4,43221184],
		["North Bay","NY",43.22,-75.79,43221213],
		["Greig","NY",43.68,-75.31,43221242],
		["Clune","PA",40.57,-79.29,43221271],
		["Knox Dale","PA",41.09,-79.03,43221300],
		["Callery","PA",40.73,-80.03,43221329],
		["West Hickory","PA",41.58,-79.46,43221358],
		["Mackeyville","PA",41.05,-77.43,43221387],
		["Leck Kill","PA",40.72,-76.6,43221416],
		["Spring Glen","PA",40.63,-76.64,43221445],
		["Clifford","PA",41.64,-75.59,43221474],
		["Drayden","MD",38.17,-76.47,43221503],
		["Taylors Island","MD",38.46,-76.3,43221532],
		["Lunenburg","VA",36.96,-78.26,43221561],
		["Stanleytown","VA",36.75,-79.95,43221590],
		["Kegley","WV",37.4,-81.13,43221619],
		["Secondcreek","WV",37.66,-80.4,43221648],
		["Amma","WV",38.54,-81.24,43221677],
		["Points","WV",39.44,-78.61,43221706],
		["Sabine","WV",37.67,-81.49,43221735],
		["Bowden","WV",38.94,-79.63,43221764],
		["Sealevel","NC",34.89,-76.39,43221793],
		["Scaly Mountain","NC",35.01,-83.31,43221822],
		["Copeland","FL",25.96,-81.35,43221851],
		["Knoxville","AL",32.97,-87.8,43221880],
		["Estillfork","AL",34.91,-86.2,43221909],
		["Boykin","AL",32.07,-87.28,43221938],
		["Only","TN",35.87,-87.65,43221967],
		["Union Star","KY",37.93,-86.45,43221996],
		["Carbon Hill","OH",39.49,-82.22,43222025],
		["Thayer","IN",41.17,-87.33,43222054],
		["Rome","IN",37.93,-86.52,43222083],
		["New Goshen","IN",39.58,-87.46,43222112],
		["Newtown","IN",40.2,-87.14,43222141],
		["Eckerman","MI",46.36,-85.03,43222170],
		["Bergland","MI",46.6,-89.57,43222199],
		["Hamlin","IA",41.64,-94.91,43222228],
		["Sheldahl","IA",41.86,-93.69,43222257],
		["Dougherty","IA",42.92,-93.03,43222286],
		["Goodell","IA",42.92,-93.61,43222315],
		["Nunda","SD",44.16,-97.01,43222344],
		["Stockholm","SD",45.1,-96.79,43222373],
		["Hamill","SD",43.59,-99.67,43222402],
		["Mc Intosh","SD",45.92,-101.35,43222431],
		["Prairie City","SD",45.53,-102.8,43222460],
		["Mud Butte","SD",45,-102.87,43222489],
		["Edmore","ND",48.41,-98.45,43222518],
		["Kensal","ND",47.3,-98.73,43222547],
		["Douglas","ND",47.85,-101.5,43222576],
		["Henderson","IL",41.02,-90.35,43222605],
		["La Prairie","IL",40.14,-91,43222634],
		["Mc Gee","MO",37.03,-90.18,43222663],
		["Kansas City","MO",39.09,-94.58,43222692],
		["Kansas City","MO",39.09,-94.58,43222721],
		["De Witt","MO",39.38,-93.22,43222750],
		["Ludlow","MO",39.65,-93.7,43222779],
		["Palmer","KS",39.63,-97.13,43222808],
		["Long Island","KS",39.94,-99.53,43222837],
		["Byron","NE",40,-97.76,43222866],
		["Mclean","NE",42.38,-97.46,43222895],
		["Riverton","NE",40.08,-98.75,43222924],
		["Whitman","NE",42.04,-101.53,43222953],
		["Reddell","LA",30.67,-92.42,43222982],
		["Saint Charles","AR",34.37,-91.13,43223011],
		["Wideman","AR",36.21,-92,43223040],
		["Hagarville","AR",35.52,-93.33,43223069],
		["Manchester","OK",36.99,-98.03,43223098],
		["Mertens","TX",32.05,-96.89,43223127],
		["Prairie Hill","TX",31.65,-96.78,43223156],
		["Maryneal","TX",32.23,-100.45,43223185],
		["Novice","TX",31.98,-99.62,43223214],
		["Matheson","CO",39.17,-103.98,43223243],
		["Vona","CO",39.3,-102.74,43223272],
		["Arboles","CO",37.02,-107.4,43223301],
		["Woody Creek","CO",39.28,-106.88,43223330],
		["Fairview","WY",42.69,-110.98,43223359],
		["Dutch John","UT",40.91,-109.34,43223388],
		["Junction","UT",38.23,-112.26,43223417],
		["La Madera","NM",36.38,-106.04,43223446],
		["Rainsville","NM",35.97,-105.2,43223475],
		["Buckhorn","NM",33.03,-108.7,43223504],
		["Folsom","NM",36.84,-103.91,43223533],
		["Gerlach","NV",40.86,-119.32,43223562],
		["Paradise Valley","NV",41.38,-117.36,43223591],
		["Branscomb","CA",39.69,-123.61,43223620],
		["Sacramento","CA",38.69,-121.6,43223649],
		["Midland","OR",42.13,-121.81,43223678],
		["Port Lions","AK",57.9,-153.04,43223707],
		["Nondalton","AK",59.96,-154.87,43223736],
		["West Ossipee","NH",43.83,-71.2,43223764],
		["Newfield","ME",43.64,-70.84,43223792],
		["Hanover","ME",44.49,-70.69,43223820],
		["South Bristol","ME",43.86,-69.56,43223848],
		["Coventry","VT",44.87,-72.27,43223876],
		["Pine Meadow","CT",41.87,-72.96,43223904],
		["Climax","NY",42.37,-73.86,43223932],
		["Fultonham","NY",42.58,-74.39,43223960],
		["South Bethlehem","NY",42.61,-73.84,43223988],
		["Ashland","NY",42.3,-74.33,43224016],
		["Greenfield Park","NY",41.72,-74.52,43224044],
		["West Kill","NY",42.18,-74.34,43224072],
		["Claryville","NY",41.91,-74.57,43224100],
		["Montezuma","NY",43.01,-76.7,43224128],
		["Rome","NY",43.21,-75.47,43224156],
		["Ionia","NY",42.93,-77.49,43224184],
		["Alma","NY",42.01,-78.02,43224212],
		["Driftwood","PA",41.34,-78.13,43224240],
		["Stump Creek","PA",41.01,-78.83,43224268],
		["Garland","PA",41.8,-79.43,43224296],
		["Custer City","PA",41.89,-78.68,43224324],
		["Mills","PA",41.9,-77.75,43224352],
		["Mount Gretna","PA",40.24,-76.47,43224380],
		["Paris","VA",39,-77.95,43224408],
		["Gibson Island","MD",39.07,-76.42,43224436],
		["Eggleston","VA",37.28,-80.63,43224464],
		["Maxie","VA",37.31,-82.19,43224492],
		["Helen","WV",37.64,-81.31,43224520],
		["Smithburg","WV",39.29,-80.71,43224548],
		["Troy","WV",39.08,-80.75,43224576],
		["Roxobel","NC",36.2,-77.23,43224604],
		["Lowland","NC",35.3,-76.57,43224632],
		["Marshallberg","NC",34.73,-76.51,43224660],
		["Gerton","NC",35.47,-82.3,43224688],
		["Pacolet Mills","SC",34.91,-81.74,43224716],
		["Omaha","GA",32.14,-85.01,43224744],
		["Gulf Hammock","FL",29.25,-82.7,43224772],
		["Hollytree","AL",34.79,-86.25,43224800],
		["Winstonville","MS",33.91,-90.75,43224828],
		["Beverly","KY",36.93,-83.53,43224856],
		["Lovelaceville","KY",36.96,-88.83,43224884],
		["Stinesville","IN",39.3,-86.64,43224912],
		["Harbert","MI",41.88,-86.61,43224940],
		["Guernsey","IA",41.64,-92.34,43224968],
		["Luzerne","IA",41.9,-92.18,43224996],
		["Webster","IA",41.43,-92.17,43225024],
		["Iron Belt","WI",46.4,-90.32,43225052],
		["Canyon","MN",47.06,-92.4,43225080],
		["Fairfax","SD",43.02,-98.88,43225108],
		["Pierpont","SD",45.49,-97.83,43225136],
		["Milesville","SD",44.46,-101.68,43225164],
		["Inkster","ND",48.15,-97.64,43225192],
		["Mekinock","ND",48.01,-97.36,43225220],
		["Wolford","ND",48.49,-99.7,43225248],
		["Brady","MT",48.02,-111.57,43225276],
		["Silver Star","MT",45.65,-112.36,43225304],
		["Dayton","MT",47.86,-114.28,43225332],
		["Woodson","IL",39.62,-90.22,43225360],
		["Novelty","MO",40.01,-92.2,43225388],
		["Gibbs","MO",40.09,-92.41,43225416],
		["Home","KS",39.87,-96.52,43225444],
		["Piqua","KS",37.86,-95.59,43225472],
		["Latham","KS",37.53,-96.64,43225500],
		["Lorraine","KS",38.56,-98.31,43225528],
		["Schoenchen","KS",38.71,-99.33,43225556],
		["Pierceville","KS",37.88,-100.67,43225584],
		["Uehling","NE",41.73,-96.5,43225612],
		["Deweese","NE",40.35,-98.13,43225640],
		["Heartwell","NE",40.56,-98.78,43225668],
		["Vandervoort","AR",34.37,-94.36,43225696],
		["Egypt","AR",35.86,-90.95,43225724],
		["O Kean","AR",36.16,-90.81,43225752],
		["Parthenon","AR",35.93,-93.28,43225780],
		["Sidney","TX",31.92,-98.77,43225808],
		["Laughlin A F B","TX",29.35,-100.78,43225836],
		["Karval","CO",38.73,-103.53,43225864],
		["Cheraw","CO",38.1,-103.51,43225892],
		["Arvada","WY",44.76,-106.09,43225920],
		["Kelly","WY",43.6,-110.32,43225948],
		["Garrison","UT",39.06,-113.83,43225976],
		["La Jara","NM",36.08,-106.97,43226004],
		["Tinnie","NM",33.27,-105.13,43226032],
		["Blue Diamond","NV",36.03,-115.41,43226060],
		["Edwards","CA",34.92,-117.94,43226088],
		["Wishon","CA",37.26,-119.52,43226116],
		["Jenner","CA",38.51,-123.21,43226144],
		["Zenia","CA",40.16,-123.4,43226172],
		["Zamora","CA",38.8,-121.9,43226200],
		["Broadbent","OR",42.96,-124.17,43226228],
		["Deadwood","OR",44.16,-123.7,43226256],
		["Gardiner","OR",43.76,-124.14,43226284],
		["Scottsburg","OR",43.69,-123.91,43226312],
		["Marlin","WA",47.27,-119.04,43226340],
		["Tumtum","WA",47.89,-117.76,43226368],
		["Arctic Village","AK",68.09,-145.57,43226396],
		["Point Lay","AK",69.73,-162.82,43226424],
		["Boston","MA",42.35,-71.06,43226451],
		["Melvin Village","NH",43.69,-71.28,43226478],
		["Bailey Island","ME",43.73,-69.99,43226505],
		["Long Island","ME",43.69,-70.15,43226532],
		["Brownville Junction","ME",45.35,-69.05,43226559],
		["Kingman","ME",45.54,-68.2,43226586],
		["Sinclair","ME",47.16,-68.3,43226613],
		["Port Clyde","ME",43.93,-69.25,43226640],
		["Fishers Island","NY",41.27,-71.99,43226667],
		["Winslow","NJ",39.65,-74.86,43226694],
		["Port Kent","NY",44.53,-73.42,43226721],
		["Fishs Eddy","NY",41.96,-75.17,43226748],
		["Swain","NY",42.48,-77.88,43226775],
		["Cardale","PA",39.97,-79.84,43226802],
		["Chestnut Ridge","PA",39.98,-79.8,43226829],
		["Isabella","PA",39.95,-79.93,43226856],
		["Josephine","PA",40.48,-79.19,43226883],
		["Adamsville","PA",41.5,-80.37,43226910],
		["Defiance","PA",40.16,-78.23,43226937],
		["Aristes","PA",40.81,-76.34,43226964],
		["Toddville","MD",38.27,-76.05,43226991],
		["Quinby","VA",37.55,-75.73,43227018],
		["Coleman Falls","VA",37.5,-79.33,43227045],
		["Doran","VA",37.13,-81.81,43227072],
		["Dry Creek","WV",37.86,-81.49,43227099],
		["Copen","WV",38.84,-80.72,43227126],
		["Shock","WV",38.76,-80.99,43227153],
		["Calvin","WV",38.32,-80.7,43227180],
		["Stumpy Point","NC",35.74,-75.8,43227207],
		["Geneva","GA",32.57,-84.55,43227234],
		["Little River","AL",31.25,-87.7,43227261],
		["Lula","MS",34.45,-90.47,43227288],
		["Bethlehem","KY",38.4,-85.06,43227315],
		["Holmes Mill","KY",36.86,-83,43227342],
		["Wheatcroft","KY",37.48,-87.86,43227369],
		["Kunkle","OH",41.62,-84.49,43227396],
		["Nashville","OH",40.59,-82.11,43227423],
		["New Trenton","IN",39.31,-84.9,43227450],
		["Dunreith","IN",39.8,-85.43,43227477],
		["Mcbrides","MI",43.33,-85.02,43227504],
		["Lakeside","MI",41.85,-86.66,43227531],
		["Little Lake","MI",46.28,-87.33,43227558],
		["Stout","IA",42.52,-92.71,43227585],
		["Percival","IA",40.73,-95.81,43227612],
		["Chester","IA",43.49,-92.36,43227639],
		["Unionville","IA",40.81,-92.69,43227666],
		["Pennington","MN",47.48,-94.47,43227693],
		["Ree Heights","SD",44.51,-99.2,43227720],
		["Gardner","ND",47.14,-96.96,43227747],
		["Rutland","ND",46.05,-97.5,43227774],
		["Bowdon","ND",47.46,-99.7,43227801],
		["Grassy Butte","ND",47.4,-103.25,43227828],
		["Saint Marie","MT",48.39,-106.54,43227855],
		["Bardolph","IL",40.49,-90.56,43227882],
		["Media","IL",40.77,-90.83,43227909],
		["Denver","MO",40.39,-94.32,43227936],
		["Racine","MO",36.89,-94.52,43227963],
		["Emma","MO",38.97,-93.49,43227990],
		["Lenox","MO",37.65,-91.76,43228017],
		["Powersite","MO",36.65,-93.12,43228044],
		["Albert","KS",38.45,-99.01,43228071],
		["Monument","KS",39.1,-101,43228098],
		["Park","KS",39.11,-100.36,43228125],
		["Ohiowa","NE",40.41,-97.45,43228152],
		["Stella","NE",40.23,-95.77,43228179],
		["Rogers","NE",41.46,-96.91,43228206],
		["Eddyville","NE",41.01,-99.62,43228233],
		["Ashby","NE",42.02,-101.93,43228260],
		["Cade","LA",30.08,-91.9,43228287],
		["Enterprise","LA",31.89,-91.88,43228314],
		["Mora","LA",31.36,-92.93,43228341],
		["Tupelo","AR",35.39,-91.22,43228368],
		["Edmondson","AR",35.1,-90.3,43228395],
		["Maysville","AR",36.4,-94.58,43228422],
		["Pettigrew","AR",35.82,-93.65,43228449],
		["Bison","OK",36.2,-97.88,43228476],
		["Fredonia","TX",30.93,-99.11,43228503],
		["Mc Caulley","TX",32.79,-100.22,43228530],
		["Dupont","CO",39.84,-104.92,43228557],
		["Elk City","ID",45.9,-115.22,43228584],
		["Harvard","ID",46.94,-116.69,43228611],
		["Talmage","UT",40.39,-110.43,43228638],
		["Embudo","NM",36.2,-105.96,43228665],
		["Buena Vista","NM",35.91,-105.24,43228692],
		["Pinon","NM",32.61,-105.39,43228719],
		["Tupman","CA",35.32,-119.39,43228746],
		["Drytown","CA",38.44,-120.85,43228773],
		["Monument","OR",44.81,-119.43,43228800],
		["Rosburg","WA",46.29,-123.63,43228827],
		["Carlton","WA",48.23,-120.28,43228854],
		["Malden","WA",47.22,-117.45,43228881],
		["Ekwok","AK",59.35,-157.48,43228908],
		["Cantwell","AK",63.33,-148.33,43228935],
		["Eagle","AK",64.43,-141.22,43228962],
		["Kaltag","AK",63.81,-159.67,43228989],
		["Cornish Flat","NH",43.49,-72.27,43229015],
		["York Beach","ME",43.18,-70.67,43229041],
		["Gilman","VT",44.41,-71.71,43229067],
		["Speculator","NY",43.58,-74.38,43229093],
		["Wales Center","NY",42.75,-78.62,43229119],
		["Ceres","NY",42,-78.27,43229145],
		["Webster","PA",40.14,-79.81,43229171],
		["Jacobs Creek","PA",40.19,-79.73,43229197],
		["Springs","PA",39.74,-79.09,43229223],
		["Valier","PA",40.92,-79.08,43229249],
		["Oak Ridge","PA",41.01,-79.25,43229275],
		["Plumville","PA",40.79,-79.18,43229301],
		["Derrick City","PA",41.97,-78.55,43229327],
		["Mc Knightstown","PA",39.9,-77.34,43229353],
		["Mc Elhattan","PA",41.16,-77.35,43229379],
		["Paxtonville","PA",40.76,-77.08,43229405],
		["Milnesville","PA",40.95,-76,43229431],
		["Lake Como","PA",41.84,-75.33,43229457],
		["Mildred","PA",41.47,-76.37,43229483],
		["Port Penn","DE",39.47,-75.66,43229509],
		["Millville","DE",38.54,-75.11,43229535],
		["Isaban","WV",37.53,-81.85,43229561],
		["Kiahsville","WV",38.06,-82.26,43229587],
		["Lansing","WV",38.1,-81.03,43229613],
		["Brohard","WV",39.02,-81.19,43229639],
		["Spelter","WV",39.33,-80.3,43229665],
		["Oliver","GA",32.52,-81.53,43229691],
		["Sayre","AL",33.73,-86.99,43229717],
		["Gulston","KY",36.75,-83.33,43229743],
		["Holloway","OH",40.16,-81.12,43229769],
		["Mount Eaton","OH",40.69,-81.7,43229795],
		["North Star","OH",40.32,-84.56,43229821],
		["Palestine","OH",40.05,-84.74,43229847],
		["Booneville","IA",41.53,-93.88,43229873],
		["Rowan","IA",42.74,-93.54,43229899],
		["Hartwick","IA",41.78,-92.34,43229925],
		["Mount Sterling","IA",40.61,-91.93,43229951],
		["Gotham","WI",43.21,-90.29,43229977],
		["Kingston","WI",43.69,-89.13,43230003],
		["Nelsonville","WI",44.49,-89.31,43230029],
		["Long Lake","WI",45.83,-88.66,43230055],
		["Ojibwa","WI",45.79,-91.11,43230081],
		["Schroeder","MN",47.69,-90.96,43230107],
		["Bradley","SD",45.09,-97.64,43230133],
		["Java","SD",45.5,-99.88,43230159],
		["Stratford","SD",45.31,-98.3,43230185],
		["Trail City","SD",45.47,-100.73,43230211],
		["Havana","ND",45.95,-97.61,43230237],
		["Bathgate","ND",48.88,-97.47,43230263],
		["Fredonia","ND",46.32,-99.09,43230289],
		["Mchenry","ND",47.57,-98.59,43230315],
		["Spiritwood","ND",46.93,-98.5,43230341],
		["Divide","MT",45.78,-112.71,43230367],
		["Wise River","MT",45.66,-112.93,43230393],
		["Campus","IL",41.02,-88.3,43230419],
		["Saint Augustine","IL",40.71,-90.4,43230445],
		["Bryant","IL",40.46,-90.09,43230471],
		["Collison","IL",40.23,-87.8,43230497],
		["Elmer","MO",39.95,-92.64,43230523],
		["Daisy","MO",37.51,-89.82,43230549],
		["Conception","MO",40.25,-94.68,43230575],
		["Koeltztown","MO",38.32,-92.04,43230601],
		["Benton City","MO",39.13,-91.76,43230627],
		["Chestnutridge","MO",36.83,-93.25,43230653],
		["Couch","MO",36.6,-91.38,43230679],
		["Barnard","KS",39.18,-98.04,43230705],
		["Edson","KS",39.33,-101.54,43230731],
		["Belden","NE",42.41,-97.2,43230757],
		["Miller","NE",40.92,-99.39,43230783],
		["Rockville","NE",41.11,-98.83,43230809],
		["Wilsonville","NE",40.11,-100.1,43230835],
		["Merriman","NE",42.92,-101.7,43230861],
		["Wabash","AR",34.4,-90.92,43230887],
		["Dell","AR",35.85,-90.03,43230913],
		["Bessie","OK",35.38,-98.98,43230939],
		["Shamrock","OK",35.91,-96.57,43230965],
		["Tehuacana","TX",31.74,-96.54,43230991],
		["Beeville","TX",28.4,-97.74,43231017],
		["Dodson","TX",34.76,-100.02,43231043],
		["Whitharral","TX",33.73,-102.32,43231069],
		["Swan Valley","ID",43.4,-111.31,43231095],
		["Pollock","ID",45.31,-116.32,43231121],
		["Antimony","UT",38.09,-112,43231147],
		["Bellemont","AZ",35.23,-111.78,43231173],
		["Youngsville","NM",36.18,-106.55,43231199],
		["Jamestown","NM",35.35,-108.44,43231225],
		["Villanueva","NM",35.26,-105.36,43231251],
		["Winston","NM",33.34,-107.51,43231277],
		["Cassel","CA",40.92,-121.57,43231303],
		["Fort Bidwell","CA",41.88,-120.11,43231329],
		["Antelope","OR",44.95,-120.65,43231355],
		["Detroit","OR",44.73,-122.1,43231381],
		["Logsden","OR",44.74,-123.8,43231407],
		["Snoqualmie Pass","WA",47.42,-121.37,43231433],
		["Marcus","WA",48.66,-118.06,43231459],
		["Metaline","WA",48.84,-117.39,43231485],
		["Buckland","MA",42.6,-72.78,43231510],
		["West Chatham","MA",41.68,-69.99,43231535],
		["South Sutton","NH",43.31,-71.92,43231560],
		["Errol","NH",44.78,-71.13,43231585],
		["Chebeague Island","ME",43.73,-70.11,43231610],
		["Pemaquid","ME",43.89,-69.52,43231635],
		["Milldale","CT",41.61,-72.87,43231660],
		["Brookside","NJ",40.76,-74.6,43231685],
		["Barnegat Light","NJ",39.75,-74.11,43231710],
		["Kingston","NJ",40.37,-74.6,43231735],
		["Charlotteville","NY",42.53,-74.68,43231760],
		["Hensonville","NY",42.25,-74.22,43231785],
		["Kauneonga Lake","NY",41.69,-74.82,43231810],
		["Rainbow Lake","NY",44.47,-74.17,43231835],
		["Upper Jay","NY",44.33,-73.78,43231860],
		["Wanakena","NY",44.13,-74.92,43231885],
		["Wells Bridge","NY",42.36,-75.24,43231910],
		["Greenock","PA",40.24,-79.83,43231935],
		["Joffre","PA",40.38,-80.39,43231960],
		["Shanksville","PA",40.01,-78.9,43231985],
		["Dunlo","PA",40.28,-78.73,43232010],
		["Riddlesburg","PA",40.17,-78.24,43232035],
		["Crosby","PA",41.75,-78.37,43232060],
		["Campbelltown","PA",40.27,-76.58,43232085],
		["Concord","PA",40.25,-77.7,43232110],
		["Lost Creek","PA",40.81,-76.22,43232135],
		["Mount Aetna","PA",40.43,-76.25,43232160],
		["Haywood","VA",38.46,-78.17,43232185],
		["Saxis","VA",37.92,-75.72,43232210],
		["Glen Lyn","VA",37.37,-80.85,43232235],
		["Dryfork","WV",38.94,-79.43,43232260],
		["Galloway","WV",39.22,-80.13,43232285],
		["Gormania","WV",39.24,-79.35,43232310],
		["East Spencer","NC",35.67,-80.43,43232335],
		["Almond","NC",35.36,-83.56,43232360],
		["Sapelo Island","GA",31.39,-81.27,43232385],
		["Argyle","GA",31.07,-82.64,43232410],
		["Waverly","FL",27.96,-81.61,43232435],
		["Elkton","TN",35.06,-86.89,43232460],
		["Evansport","OH",41.29,-84.35,43232485],
		["Ava","OH",39.83,-81.58,43232510],
		["Alledonia","OH",39.89,-80.95,43232535],
		["Hannibal","OH",39.69,-80.89,43232560],
		["Parkman","OH",41.38,-81.05,43232585],
		["Burkettsville","OH",40.35,-84.64,43232610],
		["Gwynneville","IN",39.66,-85.68,43232635],
		["Patricksburg","IN",39.29,-86.99,43232660],
		["Somerville","IN",38.27,-87.37,43232685],
		["Perkins","MI",45.97,-87.07,43232710],
		["Rake","IA",43.48,-93.92,43232735],
		["Curlew","IA",42.97,-94.73,43232760],
		["Nemaha","IA",42.51,-95.08,43232785],
		["Rutland","IA",42.76,-94.29,43232810],
		["Calumet","IA",42.94,-95.55,43232835],
		["Kirkman","IA",41.72,-95.26,43232860],
		["Randalia","IA",42.86,-91.88,43232885],
		["Hustler","WI",43.87,-90.26,43232910],
		["La Pointe","WI",46.81,-90.68,43232935],
		["Sawyer","MN",46.67,-92.63,43232960],
		["Taopi","MN",43.55,-92.64,43232985],
		["Fifty Lakes","MN",46.74,-94.09,43233010],
		["Kabetogama","MN",48.41,-93.21,43233035],
		["Monroe","SD",43.48,-97.21,43233060],
		["Morristown","SD",45.93,-101.71,43233085],
		["Barney","ND",46.26,-96.99,43233110],
		["Brocket","ND",48.21,-98.35,43233135],
		["Lehr","ND",46.28,-99.35,43233160],
		["Coleharbor","ND",47.54,-101.22,43233185],
		["Lignite","ND",48.87,-102.56,43233210],
		["White Earth","ND",48.38,-102.77,43233235],
		["Flaxville","MT",48.71,-105.17,43233260],
		["Hingham","MT",48.56,-110.47,43233285],
		["Alder","MT",45.06,-112.11,43233310],
		["Wedron","IL",41.41,-88.73,43233335],
		["Preemption","IL",41.31,-90.58,43233360],
		["Redmon","IL",39.64,-87.86,43233385],
		["Huntsville","IL",40.18,-90.86,43233410],
		["Ingraham","IL",38.84,-88.31,43233435],
		["Redford","MO",37.32,-90.89,43233460],
		["Missouri City","MO",39.23,-94.3,43233485],
		["Yukon","MO",37.27,-91.84,43233510],
		["Powell","MO",36.62,-94.18,43233535],
		["Cedar Point","KS",38.25,-96.82,43233560],
		["Milan","KS",37.25,-97.67,43233585],
		["Wichita","KS",37.68,-97.34,43233610],
		["Ogallah","KS",38.98,-99.73,43233635],
		["Elk Creek","NE",40.28,-96.12,43233660],
		["Donner","LA",29.68,-90.87,43233685],
		["Brickeys","AR",34.85,-90.58,43233710],
		["Marcella","AR",35.74,-91.94,43233735],
		["Dougherty","OK",34.39,-97.05,43233760],
		["Sweetwater","OK",35.42,-99.92,43233785],
		["Felt","OK",36.57,-102.78,43233810],
		["Leflore","OK",34.92,-94.86,43233835],
		["Ben Franklin","TX",33.47,-95.77,43233860],
		["Cuney","TX",32.03,-95.41,43233885],
		["Gallatin","TX",31.89,-95.15,43233910],
		["Pennington","TX",31.15,-95.16,43233935],
		["Woden","TX",31.5,-94.5,43233960],
		["Lazbuddie","TX",34.39,-102.61,43233985],
		["Goldsmith","TX",31.98,-102.61,43234010],
		["Anton","CO",39.75,-103.22,43234035],
		["Frannie","WY",44.97,-108.62,43234060],
		["Big Horn","WY",44.72,-107.01,43234085],
		["Bloomington","ID",42.17,-111.42,43234110],
		["Wikieup","AZ",34.79,-113.37,43234135],
		["Hiko","NV",37.72,-115.33,43234160],
		["Gabbs","NV",38.8,-117.68,43234185],
		["Lamoille","NV",40.76,-115.41,43234210],
		["Ranchita","CA",33.21,-116.47,43234235],
		["Rimforest","CA",34.21,-117.24,43234260],
		["California Hot Springs","CA",35.87,-118.65,43234285],
		["Blocksburg","CA",40.31,-123.65,43234310],
		["Somes Bar","CA",41.47,-123.46,43234335],
		["Downieville","CA",39.58,-120.79,43234360],
		["Big Bar","CA",40.81,-123.25,43234385],
		["Calpine","CA",39.66,-120.43,43234410],
		["Lilliwaup","WA",47.52,-123.06,43234435],
		["Matlock","WA",47.22,-123.47,43234460],
		["Parker","WA",46.49,-120.46,43234485],
		["Elmer City","WA",48,-118.95,43234510],
		["Chitina","AK",61.45,-143.15,43234535],
		["Tyonek","AK",61.08,-151.16,43234560],
		["Venetie","AK",67,-146.37,43234585],
		["Seal Harbor","ME",44.29,-68.24,43234609],
		["Granville","VT",43.98,-72.85,43234633],
		["Hartford","CT",41.76,-72.68,43234657],
		["Kenoza Lake","NY",41.73,-74.95,43234681],
		["Adirondack","NY",43.72,-73.77,43234705],
		["Keeseville","NY",44.5,-73.48,43234729],
		["Davenport Center","NY",42.44,-74.91,43234753],
		["Meridale","NY",42.36,-74.95,43234777],
		["Westons Mills","NY",42.07,-78.35,43234801],
		["Denbo","PA",40.02,-79.94,43234825],
		["Keisterville","PA",39.94,-79.77,43234849],
		["Clarington","PA",41.32,-79.14,43234873],
		["Forestville","PA",41.11,-79.98,43234897],
		["Tiona","PA",41.75,-79.05,43234921],
		["Amberson","PA",40.16,-77.7,43234945],
		["Dimock","PA",41.74,-75.89,43234969],
		["Philadelphia","PA",39.95,-75.16,43234993],
		["Brandamore","PA",39.96,-75.82,43235017],
		["Benedict","MD",38.5,-76.68,43235041],
		["Scotland","MD",38.07,-76.34,43235065],
		["Millers Tavern","VA",37.83,-76.93,43235089],
		["Franktown","VA",37.47,-75.87,43235113],
		["Waverly","VA",37.03,-77.09,43235137],
		["Montebello","VA",37.86,-79.13,43235161],
		["Cucumber","WV",37.28,-81.61,43235185],
		["Squire","WV",37.26,-81.56,43235209],
		["Wyoming","WV",37.58,-81.58,43235233],
		["Dailey","WV",38.79,-79.89,43235257],
		["Folsom","WV",39.46,-80.52,43235281],
		["Baxter","WV",39.53,-80.14,43235305],
		["Salter Path","NC",34.68,-76.87,43235329],
		["Midnight","MS",33.06,-90.56,43235353],
		["Walthall","MS",33.6,-89.27,43235377],
		["Roark","KY",37.02,-83.51,43235401],
		["Deane","KY",37.24,-82.77,43235425],
		["West Millgrove","OH",41.24,-83.49,43235449],
		["Ridgeville Corners","OH",41.45,-84.28,43235473],
		["Limaville","OH",40.98,-81.14,43235497],
		["Miamiville","OH",39.19,-84.24,43235521],
		["Kettlersville","OH",40.43,-84.26,43235545],
		["Breedsville","MI",42.34,-86.07,43235569],
		["Dana","IA",42.1,-94.24,43235593],
		["Center Junction","IA",42.11,-91.08,43235617],
		["Millersburg","IA",41.57,-92.15,43235641],
		["Babcock","WI",44.29,-90.13,43235665],
		["Warsaw","MN",44.23,-93.34,43235689],
		["Arco","MN",44.38,-96.18,43235713],
		["Big Falls","MN",48.18,-93.8,43235737],
		["Mizpah","MN",47.92,-94.21,43235761],
		["Bisbee","ND",48.62,-99.37,43235785],
		["Lawton","ND",48.3,-98.36,43235809],
		["Cathay","ND",47.55,-99.41,43235833],
		["Driscoll","ND",46.85,-100.13,43235857],
		["Saint Anthony","ND",46.58,-100.89,43235881],
		["Dodge","ND",47.3,-102.2,43235905],
		["Dunn Center","ND",47.35,-102.62,43235929],
		["Donnybrook","ND",48.5,-101.88,43235953],
		["Maxbass","ND",48.72,-101.14,43235977],
		["Acton","MT",45.98,-108.68,43236001],
		["Shawmut","MT",46.39,-109.56,43236025],
		["West Glacier","MT",48.61,-113.81,43236049],
		["Shirland","IL",42.46,-89.2,43236073],
		["Ancona","IL",41.05,-88.76,43236097],
		["La Place","IL",39.8,-88.71,43236121],
		["Tallapoosa","MO",36.5,-89.81,43236145],
		["Clubb","MO",37.23,-90.36,43236169],
		["Lucerne","MO",40.46,-93.29,43236193],
		["Rothville","MO",39.65,-93.06,43236217],
		["Mc Farland","KS",39.05,-96.23,43236241],
		["Esbon","KS",39.82,-98.43,43236265],
		["Piedmont","KS",37.62,-96.37,43236289],
		["Herndon","KS",39.9,-100.78,43236313],
		["South Bend","NE",41,-96.24,43236337],
		["Amelia","NE",42.23,-98.96,43236361],
		["Bristow","NE",42.83,-98.58,43236385],
		["Halsey","NE",41.9,-100.26,43236409],
		["Hamburg","LA",31.03,-91.93,43236433],
		["Wildsville","LA",31.65,-91.6,43236457],
		["Big Flat","AR",36,-92.4,43236481],
		["Vendor","AR",35.94,-93.1,43236505],
		["Hitchita","OK",35.51,-95.75,43236529],
		["Daisy","OK",34.53,-95.73,43236553],
		["Lillian","TX",32.42,-97.2,43236577],
		["South Bend","TX",33,-98.66,43236601],
		["Gatesville","TX",31.44,-97.73,43236625],
		["Talpa","TX",31.77,-99.7,43236649],
		["Vancourt","TX",31.34,-100.17,43236673],
		["Umbarger","TX",34.95,-102.1,43236697],
		["Avoca","TX",32.86,-99.71,43236721],
		["Vernon","CO",39.93,-102.28,43236745],
		["Vilas","CO",37.37,-102.44,43236769],
		["Irwin","ID",43.33,-111.17,43236793],
		["Letha","ID",43.91,-116.66,43236817],
		["Lindrith","NM",36.3,-107.04,43236841],
		["Llano","NM",36.14,-105.68,43236865],
		["Tesuque","NM",35.76,-105.92,43236889],
		["Dona Ana","NM",32.39,-106.81,43236913],
		["Sedan","NM",36.14,-103.13,43236937],
		["Goldfield","NV",37.53,-117.51,43236961],
		["Desert Center","CA",33.85,-115.29,43236985],
		["Palo Verde","CA",33.32,-114.71,43237009],
		["Big Creek","CA",37.16,-119.3,43237033],
		["Fields Landing","CA",40.72,-124.21,43237057],
		["Petrolia","CA",40.24,-124.21,43237081],
		["Wheeler","OR",45.68,-123.88,43237105],
		["Beatty","OR",42.49,-121.27,43237129],
		["New Pine Creek","OR",41.96,-120.2,43237153],
		["Shaw Island","WA",48.57,-122.96,43237177],
		["Mckenna","WA",46.93,-122.55,43237201],
		["Quinault","WA",47.44,-123.77,43237225],
		["Berkshire","MA",42.51,-73.19,43237248],
		["Portage","ME",46.76,-68.47,43237271],
		["East Charleston","VT",44.83,-72,43237294],
		["Esopus","NY",41.8,-73.96,43237317],
		["Lake Huntington","NY",41.68,-74.99,43237340],
		["Wevertown","NY",43.64,-73.92,43237363],
		["Richburg","NY",42.08,-78.15,43237386],
		["New Geneva","PA",39.78,-79.89,43237409],
		["Luxor","PA",40.34,-79.53,43237432],
		["Wilmore","PA",40.38,-78.71,43237455],
		["Cherryville","PA",40.73,-75.55,43237478],
		["Erwinna","PA",40.49,-75.08,43237501],
		["Stevensburg","VA",38.44,-77.9,43237524],
		["Hallieford","VA",37.49,-76.37,43237547],
		["Wylliesburg","VA",36.85,-78.58,43237570],
		["Slab Fork","WV",37.68,-81.28,43237593],
		["Waco","NC",35.36,-81.42,43237616],
		["Horatio","SC",33.98,-80.58,43237639],
		["Homestead","FL",25.46,-80.45,43237662],
		["Montrose","AL",30.56,-87.9,43237685],
		["Stanton","AL",32.71,-86.91,43237708],
		["Jacks Creek","TN",35.46,-88.53,43237731],
		["Holly Bluff","MS",32.83,-90.69,43237754],
		["Clayhole","KY",37.46,-83.29,43237777],
		["Revelo","KY",36.67,-84.47,43237800],
		["Glencoe","OH",40.05,-80.91,43237823],
		["Clifton","OH",39.79,-83.82,43237846],
		["Wyatt","IN",41.52,-86.16,43237869],
		["Liberty Mills","IN",41.02,-85.7,43237892],
		["Spurgeon","IN",38.25,-87.25,43237915],
		["Prairie Creek","IN",39.29,-87.53,43237938],
		["Palo","MI",43.11,-84.98,43237961],
		["Oden","MI",45.42,-84.84,43237984],
		["Grand Marais","MI",46.65,-86.03,43238007],
		["Aredale","IA",42.83,-93,43238030],
		["Sharpsburg","IA",40.8,-94.64,43238053],
		["Protivin","IA",43.21,-92.08,43238076],
		["Kirkville","IA",41.14,-92.5,43238099],
		["Low Moor","IA",41.8,-90.35,43238122],
		["Dodge","WI",44.13,-91.56,43238145],
		["Hawthorne","WI",46.5,-91.86,43238168],
		["Norcross","MN",45.86,-96.19,43238191],
		["Labolt","SD",45.01,-96.69,43238214],
		["Harrison","SD",43.44,-98.52,43238237],
		["Draper","SD",43.92,-100.53,43238260],
		["Watauga","SD",45.92,-101.54,43238283],
		["Buffalo Gap","SD",43.49,-103.31,43238306],
		["Oelrichs","SD",43.18,-103.23,43238329],
		["Oral","SD",43.4,-103.26,43238352],
		["Luverne","ND",47.25,-97.93,43238375],
		["Hurdsfield","ND",47.44,-99.93,43238398],
		["Rogers","ND",47.07,-98.2,43238421],
		["Edgar","MT",45.44,-108.68,43238444],
		["Kinsey","MT",46.59,-105.69,43238467],
		["Hogeland","MT",48.79,-108.73,43238490],
		["Wisdom","MT",45.54,-113.54,43238513],
		["Conner","MT",45.89,-114.04,43238536],
		["Ovando","MT",47.07,-113.04,43238559],
		["Millbrook","IL",41.7,-88.51,43238582],
		["Chicago","IL",41.83,-87.68,43238605],
		["Norris","IL",40.62,-90.03,43238628],
		["Anchor","IL",40.56,-88.53,43238651],
		["Vermilion","IL",39.57,-87.58,43238674],
		["Paloma","IL",40.02,-91.19,43238697],
		["Ethel","MO",39.89,-92.74,43238720],
		["Old Appleton","MO",37.59,-89.7,43238743],
		["Gobler","MO",36.15,-89.93,43238766],
		["Dover","MO",39.19,-93.68,43238789],
		["Mahaska","KS",39.98,-97.35,43238812],
		["Isabel","KS",37.46,-98.55,43238835],
		["Dorrance","KS",38.84,-98.59,43238858],
		["Gem","KS",39.42,-100.89,43238881],
		["Richfield","KS",37.26,-101.78,43238904],
		["Primrose","NE",41.62,-98.23,43238927],
		["Royal","NE",42.33,-98.12,43238950],
		["Bushnell","NE",41.23,-103.89,43238973],
		["Tichnor","AR",34.05,-91.23,43238996],
		["Little Rock","AR",34.72,-92.35,43239019],
		["Guion","AR",35.92,-91.94,43239042],
		["Wheatland","OK",35.39,-97.65,43239065],
		["Martha","OK",34.72,-99.38,43239088],
		["Fanshawe","OK",34.98,-94.86,43239111],
		["Carlton","TX",31.9,-98.13,43239134],
		["Danevang","TX",29.08,-96.17,43239157],
		["Fannin","TX",28.69,-97.21,43239180],
		["Francitas","TX",28.8,-96.35,43239203],
		["Allenspark","CO",40.2,-105.52,43239226],
		["Veteran","WY",41.96,-104.35,43239249],
		["Ola","ID",44.27,-116.31,43239272],
		["Coolin","ID",48.6,-116.86,43239295],
		["Dover","ID",48.25,-116.6,43239318],
		["Luna","NM",33.81,-108.95,43239341],
		["Glenwood","NM",33.31,-108.88,43239364],
		["Baker","NV",38.9,-114.2,43239387],
		["San Diego","CA",32.72,-117.14,43239410],
		["Casmalia","CA",34.86,-120.52,43239433],
		["Kirkwood","CA",38.69,-120.05,43239456],
		["Ryde","CA",38.23,-121.55,43239479],
		["Big Bend","CA",41.04,-121.89,43239502],
		["Seneca","OR",44.12,-118.94,43239525],
		["Deer Harbor","WA",48.62,-123.01,43239548],
		["Appleton","WA",45.85,-121.28,43239571],
		["Anatone","WA",46.08,-117.27,43239594],
		["Port Alsworth","AK",60.46,-154.19,43239617],
		["Coffman Cove","AK",55.97,-132.74,43239640],
		["West Chesterfield","MA",42.41,-72.87,43239662],
		["Randolph","NH",44.37,-71.25,43239684],
		["Rockwood","ME",45.67,-69.74,43239706],
		["Waite","ME",45.32,-67.69,43239728],
		["Birch Harbor","ME",44.38,-68.04,43239750],
		["Little Deer Isle","ME",44.28,-68.71,43239772],
		["Prospect Harbor","ME",44.42,-68.03,43239794],
		["Collinsville","CT",41.81,-72.92,43239816],
		["Scotland","CT",41.7,-72.08,43239838],
		["Hibernia","NJ",40.96,-74.48,43239860],
		["West Fulton","NY",42.53,-74.45,43239882],
		["West Lebanon","NY",42.49,-73.45,43239904],
		["Lexington","NY",42.2,-74.36,43239926],
		["Callicoon Center","NY",41.83,-74.94,43239948],
		["Hortonville","NY",41.76,-75.03,43239970],
		["Phillipsport","NY",41.63,-74.44,43239992],
		["Delphi Falls","NY",42.87,-75.91,43240014],
		["Raymondville","NY",44.83,-74.97,43240036],
		["North Evans","NY",42.69,-78.99,43240058],
		["Arcadia","PA",40.77,-78.85,43240080],
		["Starford","PA",40.69,-78.97,43240102],
		["Timblin","PA",40.96,-79.19,43240124],
		["Ludlow","PA",41.73,-78.95,43240146],
		["Glen Richey","PA",40.93,-78.47,43240168],
		["Shunk","PA",41.56,-76.76,43240190],
		["Locustdale","PA",40.77,-76.37,43240212],
		["Mary D","PA",40.75,-76.06,43240234],
		["Lopez","PA",41.45,-76.29,43240256],
		["Bittinger","MD",39.58,-79.2,43240278],
		["Moon","VA",37.44,-76.3,43240300],
		["Sanford","VA",37.92,-75.66,43240322],
		["Tasley","VA",37.7,-75.7,43240344],
		["Widen","WV",38.45,-80.88,43240366],
		["Weston","GA",31.97,-84.61,43240388],
		["Coalfield","TN",36.03,-84.41,43240410],
		["Panther Burn","MS",33.06,-90.87,43240432],
		["Keaton","KY",37.98,-82.96,43240454],
		["Brice","OH",39.91,-82.83,43240476],
		["Kipling","OH",39.99,-81.5,43240498],
		["Uniopolis","OH",40.6,-84.08,43240520],
		["Grantsburg","IN",38.28,-86.46,43240542],
		["Buck Creek","IN",40.48,-86.76,43240564],
		["Mellott","IN",40.16,-87.14,43240586],
		["Mount Ayr","IN",40.95,-87.29,43240608],
		["Yeoman","IN",40.66,-86.72,43240630],
		["Amasa","MI",46.23,-88.45,43240652],
		["Superior","IA",43.43,-94.94,43240674],
		["Blanchard","IA",40.58,-95.22,43240696],
		["Udell","IA",40.78,-92.74,43240718],
		["Pease","MN",45.69,-93.65,43240740],
		["White Earth","MN",47.09,-95.85,43240762],
		["Trail","MN",47.78,-95.69,43240784],
		["Fedora","SD",44,-97.78,43240806],
		["Whitehorse","SD",45.29,-100.92,43240828],
		["Verona","ND",46.36,-98.07,43240850],
		["Riverdale","ND",47.49,-101.36,43240872],
		["Columbus","ND",48.9,-102.78,43240894],
		["Ross","ND",48.31,-102.54,43240916],
		["Voltaire","ND",48.01,-100.84,43240938],
		["Crane","MT",47.57,-104.26,43240960],
		["Norris","MT",45.58,-111.58,43240982],
		["Milltown","MT",46.87,-113.88,43241004],
		["Nora","IL",42.45,-89.94,43241026],
		["Standard","IL",41.25,-89.18,43241048],
		["Ophiem","IL",41.28,-90.33,43241070],
		["Lawndale","IL",40.21,-89.28,43241092],
		["Jacob","IL",37.74,-89.54,43241114],
		["Ionia","MO",38.5,-93.32,43241136],
		["Neosho Falls","KS",38,-95.55,43241158],
		["Burdick","KS",38.57,-96.83,43241180],
		["Barnes","KS",39.71,-96.87,43241202],
		["Hollenberg","KS",39.98,-96.99,43241224],
		["Wilmore","KS",37.33,-99.2,43241246],
		["Dresden","KS",39.62,-100.42,43241268],
		["Winnetoon","NE",42.51,-97.96,43241290],
		["Atlanta","NE",40.36,-99.47,43241312],
		["Lisco","NE",41.5,-102.62,43241334],
		["Johnstown","NE",42.57,-100.05,43241356],
		["Amelia","LA",29.66,-91.1,43241378],
		["Lawtell","LA",30.51,-92.18,43241400],
		["Turkey Creek","LA",30.87,-92.41,43241422],
		["Witts Springs","AR",35.78,-92.83,43241444],
		["Clarita","OK",34.48,-96.43,43241466],
		["Wardville","OK",34.66,-96.02,43241488],
		["Kildare","TX",32.94,-94.2,43241510],
		["Weinert","TX",33.32,-99.67,43241532],
		["Mereta","TX",31.45,-100.14,43241554],
		["Houston","TX",29.76,-95.38,43241576],
		["Adrian","TX",35.27,-102.66,43241598],
		["Afton","TX",33.76,-100.81,43241620],
		["Shell","WY",44.53,-107.78,43241642],
		["Recluse","WY",44.77,-105.7,43241664],
		["Greencreek","ID",46.11,-116.26,43241686],
		["Rockville","UT",37.15,-113.05,43241708],
		["Sevier","UT",38.55,-112.35,43241730],
		["Rogers","NM",33.98,-103.23,43241752],
		["Grenville","NM",36.59,-103.61,43241774],
		["Loma Mar","CA",37.27,-122.26,43241796],
		["Skykomish","WA",47.64,-121.33,43241818],
		["Malone","WA",46.95,-123.33,43241840],
		["Cummaquid","MA",41.7,-70.24,43241861],
		["East Andover","NH",43.48,-71.76,43241882],
		["West Fairlee","VT",43.9,-72.27,43241903],
		["Layton","NJ",41.21,-74.82,43241924],
		["Mountainville","NY",41.41,-74.05,43241945],
		["New Suffolk","NY",40.99,-72.48,43241966],
		["East Jewett","NY",42.27,-74.21,43241987],
		["Burlingham","NY",41.59,-74.38,43242008],
		["Harris","NY",41.71,-74.72,43242029],
		["Rogersville","PA",39.9,-80.28,43242050],
		["Martin","PA",39.8,-79.89,43242071],
		["Ringgold","PA",40.98,-79.14,43242092],
		["Distant","PA",40.97,-79.35,43242113],
		["Endeavor","PA",41.58,-79.35,43242134],
		["Hazel Hurst","PA",41.7,-78.58,43242155],
		["Fleming","PA",40.91,-77.92,43242176],
		["New Kingstown","PA",40.23,-77.02,43242197],
		["Newton Hamilton","PA",40.39,-77.83,43242218],
		["Shady Grove","PA",39.77,-77.68,43242239],
		["Llewellyn","PA",40.66,-76.26,43242260],
		["Oneida","PA",40.9,-76.13,43242281],
		["South Sterling","PA",41.26,-75.34,43242302],
		["Ransom","PA",41.39,-75.82,43242323],
		["Harmans","MD",39.16,-76.72,43242344],
		["Fishing Creek","MD",38.32,-76.23,43242365],
		["Millwood","VA",39.06,-78.03,43242386],
		["Mascot","VA",37.62,-76.7,43242407],
		["Assawoman","VA",37.87,-75.52,43242428],
		["Keller","VA",37.62,-75.76,43242449],
		["Boissevain","VA",37.28,-81.38,43242470],
		["Glen Ferris","WV",38.13,-81.18,43242491],
		["Twilight","WV",37.92,-81.62,43242512],
		["Erbacon","WV",38.54,-80.56,43242533],
		["Jekyll Island","GA",31.04,-81.41,43242554],
		["Balm","FL",27.76,-82.26,43242575],
		["Princeton","AL",34.83,-86.22,43242596],
		["Powder Springs","TN",36.24,-83.66,43242617],
		["Scott","MS",33.59,-91.07,43242638],
		["Tippo","MS",33.91,-90.1,43242659],
		["Rhodelia","KY",38,-86.41,43242680],
		["Emerson","KY",38.38,-83.26,43242701],
		["Fords Branch","KY",37.32,-82.57,43242722],
		["Carrie","KY",37.33,-83.03,43242743],
		["Seco","KY",37.17,-82.73,43242764],
		["Hampton","KY",37.33,-88.4,43242785],
		["Fultonham","OH",39.85,-82.14,43242806],
		["Barton","OH",40.11,-80.83,43242827],
		["Pemberton","OH",40.27,-84.05,43242848],
		["Haverhill","OH",38.58,-82.8,43242869],
		["Wingett Run","OH",39.54,-81.28,43242890],
		["Montezuma","OH",40.48,-84.54,43242911],
		["Zanesville","IN",40.91,-85.28,43242932],
		["Harmony","IN",39.53,-87.07,43242953],
		["Hamburg","MI",42.45,-83.79,43242974],
		["Nadeau","MI",45.56,-87.53,43242995],
		["Gibson","IA",41.48,-92.39,43243016],
		["Rodney","IA",42.2,-95.95,43243037],
		["Magnolia","IA",41.68,-95.87,43243058],
		["Elkport","IA",42.74,-91.27,43243079],
		["Morley","IA",42,-91.24,43243100],
		["Mc Causland","IA",41.74,-90.44,43243121],
		["Patch Grove","WI",42.93,-90.97,43243142],
		["Seneca","WI",43.26,-90.96,43243163],
		["West Union","MN",45.8,-95.08,43243184],
		["Wirt","MN",47.73,-93.96,43243205],
		["Fairview","SD",43.22,-96.48,43243226],
		["Carthage","SD",44.16,-97.71,43243247],
		["Pickstown","SD",43.06,-98.53,43243268],
		["Niagara","ND",47.99,-97.87,43243289],
		["Woodworth","ND",47.14,-99.3,43243310],
		["Mercer","ND",47.49,-100.71,43243331],
		["Cartwright","ND",47.86,-103.93,43243352],
		["Lindsay","MT",47.28,-105.18,43243373],
		["Hilger","MT",47.43,-109.27,43243394],
		["Dell","MT",44.78,-112.62,43243415],
		["Lonepine","MT",47.7,-114.64,43243436],
		["Rollins","MT",47.91,-114.21,43243457],
		["Chicago","IL",41.83,-87.68,43243478],
		["Galt","IL",41.78,-89.76,43243499],
		["Holcomb","IL",42.13,-89.1,43243520],
		["Pollock","MO",40.35,-93.08,43243541],
		["Whiteoak","MO",36.33,-90.02,43243562],
		["Avilla","MO",37.19,-94.13,43243583],
		["Huggins","MO",37.31,-92.19,43243604],
		["Oketo","KS",39.96,-96.59,43243625],
		["Munden","KS",39.91,-97.53,43243646],
		["Woodston","KS",39.45,-99.09,43243667],
		["Lebanon","NE",40.04,-100.27,43243688],
		["Mittie","LA",30.7,-92.9,43243709],
		["Weyanoke","LA",30.96,-91.44,43243730],
		["Calvin","LA",31.95,-92.77,43243751],
		["Ulm","AR",34.57,-91.46,43243772],
		["Caldwell","AR",35.07,-90.81,43243793],
		["Valley Springs","AR",36.15,-92.99,43243814],
		["Leon","OK",33.87,-97.42,43243835],
		["Hillsdale","OK",36.56,-97.99,43243856],
		["Kemp","OK",33.76,-96.35,43243877],
		["Doucette","TX",30.82,-94.42,43243898],
		["Oklaunion","TX",34.13,-99.13,43243919],
		["Riverside","TX",30.84,-95.39,43243940],
		["Port Mansfield","TX",26.55,-97.43,43243961],
		["Fluvanna","TX",32.89,-101.15,43243982],
		["Valentine","TX",30.58,-104.49,43244003],
		["Grant","CO",39.45,-105.67,43244024],
		["Almo","ID",42.06,-113.59,43244045],
		["Los Ojos","NM",36.72,-106.56,43244066],
		["Montezuma","NM",35.65,-105.27,43244087],
		["Rodeo","NM",31.83,-109.03,43244108],
		["Montello","NV",41.22,-114.23,43244129],
		["Glennville","CA",35.72,-118.72,43244150],
		["Benton","CA",37.87,-118.54,43244171],
		["Mount Hamilton","CA",37.36,-121.67,43244192],
		["Caspar","CA",39.36,-123.78,43244213],
		["Finley","CA",39,-122.87,43244234],
		["Korbel","CA",40.76,-123.82,43244255],
		["Crescent Mills","CA",40.07,-120.92,43244276],
		["Stirling City","CA",39.98,-121.53,43244297],
		["Greenview","CA",41.52,-122.95,43244318],
		["Kealia","HI",22.11,-159.31,43244339],
		["Government Camp","OR",45.27,-121.71,43244360],
		["Manning","OR",45.65,-123.23,43244381],
		["Princeton","OR",42.86,-118.42,43244402],
		["Gifford","WA",48.29,-118.09,43244423],
		["Deering","AK",66.07,-162.73,43244444],
		["Aurora","ME",44.85,-68.32,43244464],
		["Mount Freedom","NJ",40.84,-74.48,43244484],
		["New Kingston","NY",42.21,-74.68,43244504],
		["Etna","NY",42.48,-76.38,43244524],
		["Peterboro","NY",42.96,-75.68,43244544],
		["Pyrites","NY",44.49,-75.18,43244564],
		["Bellona","NY",42.75,-77.01,43244584],
		["Brave","PA",39.73,-80.25,43244604],
		["Callensburg","PA",41.12,-79.55,43244624],
		["Chest Springs","PA",40.57,-78.61,43244644],
		["Bear Creek","PA",41.18,-75.76,43244664],
		["Hillsgrove","PA",41.43,-76.71,43244684],
		["Burlington","PA",41.77,-76.6,43244704],
		["Pomeroy","PA",39.96,-75.92,43244724],
		["Sherwood","MD",38.74,-76.32,43244744],
		["Hagerstown","MD",39.63,-77.71,43244764],
		["Mitchells","VA",38.37,-78,43244784],
		["Pratts","VA",38.35,-78.27,43244804],
		["Criders","VA",38.75,-79,43244824],
		["Stevensville","VA",37.72,-76.92,43244844],
		["Cleveland","WV",38.83,-80.34,43244864],
		["Helvetia","WV",38.72,-80.18,43244884],
		["Caroleen","NC",35.26,-81.82,43244904],
		["Elko","SC",33.37,-81.37,43244924],
		["Allgood","AL",33.9,-86.51,43244944],
		["Mobile","AL",30.68,-88.04,43244964],
		["Grace","MS",32.96,-90.94,43244984],
		["Jonesville","KY",38.65,-84.75,43245004],
		["Dwarf","KY",37.33,-83.12,43245024],
		["Chesterville","OH",40.48,-82.68,43245044],
		["Milton Center","OH",41.3,-83.82,43245064],
		["Goldsmith","IN",40.26,-86.18,43245084],
		["Mount Summit","IN",40,-85.38,43245104],
		["Hadley","MI",42.95,-83.4,43245124],
		["Curran","MI",44.71,-83.8,43245144],
		["Weston","MI",41.74,-84.06,43245164],
		["Covington","MI",46.51,-88.44,43245184],
		["Randall","IA",42.23,-93.6,43245204],
		["Lincoln","IA",42.26,-92.69,43245224],
		["Meriden","IA",42.79,-95.63,43245244],
		["Lester","IA",43.43,-96.33,43245264],
		["Welton","IA",41.91,-90.59,43245284],
		["Fairwater","WI",43.74,-88.86,43245304],
		["Collins","WI",44.08,-87.98,43245324],
		["Gatzke","MN",48.42,-95.78,43245344],
		["Roslyn","SD",45.49,-97.49,43245364],
		["Okaton","SD",43.88,-100.89,43245384],
		["Lankin","ND",48.31,-97.92,43245404],
		["Osnabrock","ND",48.67,-98.14,43245424],
		["Webster","ND",48.28,-98.87,43245444],
		["Golva","ND",46.73,-103.98,43245464],
		["Medora","ND",46.91,-103.52,43245484],
		["Hammond","MT",45.35,-104.73,43245504],
		["Floweree","MT",47.71,-111.11,43245524],
		["Moccasin","MT",47.12,-109.92,43245544],
		["Raynesford","MT",47.2,-110.63,43245564],
		["Kremlin","MT",48.6,-110.06,43245584],
		["Canyon Creek","MT",46.87,-112.32,43245604],
		["Hooppole","IL",41.52,-89.91,43245624],
		["Castleton","IL",41.11,-89.7,43245644],
		["Kingston Mines","IL",40.55,-89.77,43245664],
		["Ohlman","IL",39.34,-89.21,43245684],
		["Sawyerville","IL",39.07,-89.8,43245704],
		["Modoc","IL",38.05,-90.03,43245724],
		["Kansas City","MO",39.09,-94.58,43245744],
		["Triplett","MO",39.49,-93.19,43245764],
		["Roby","MO",37.51,-92.13,43245784],
		["Halltown","MO",37.19,-93.62,43245804],
		["West Mineral","KS",37.28,-94.92,43245824],
		["Athol","KS",39.76,-98.91,43245844],
		["Hardtner","KS",37.01,-98.64,43245864],
		["Hazelton","KS",37.08,-98.4,43245884],
		["Aurora","KS",39.45,-97.52,43245904],
		["Waldo","KS",39.12,-98.79,43245924],
		["Ludell","KS",39.85,-100.96,43245944],
		["Brewster","NE",41.93,-99.86,43245964],
		["Parks","NE",40.04,-101.73,43245984],
		["Paincourtville","LA",29.99,-91.05,43246004],
		["Innis","LA",30.88,-91.69,43246024],
		["Burdette","AR",35.81,-89.94,43246044],
		["Goodwin","AR",34.96,-90.99,43246064],
		["Sturkie","AR",36.46,-91.99,43246084],
		["Twin Oaks","OK",36.2,-94.85,43246104],
		["Redbird","OK",35.88,-95.58,43246124],
		["Harrold","TX",34.08,-99.03,43246144],
		["Energy","TX",31.75,-98.36,43246164],
		["Leroy","TX",31.73,-97.02,43246184],
		["Romayor","TX",30.31,-94.76,43246204],
		["Staples","TX",29.78,-97.83,43246224],
		["Barksdale","TX",29.7,-100.03,43246244],
		["Allison","TX",35.6,-100.1,43246264],
		["Girard","TX",33.36,-100.66,43246284],
		["Eldorado Springs","CO",39.93,-105.27,43246304],
		["Silver Plume","CO",39.69,-105.72,43246324],
		["Model","CO",37.34,-104.27,43246344],
		["Almont","CO",38.67,-106.83,43246364],
		["Twin Lakes","CO",39.1,-106.47,43246384],
		["Rico","CO",37.64,-108.06,43246404],
		["Granger","WY",41.59,-109.96,43246424],
		["Fish Haven","ID",42.05,-111.44,43246444],
		["Rogerson","ID",42.11,-114.95,43246464],
		["Tendoy","ID",44.94,-113.64,43246484],
		["Indian Valley","ID",44.54,-116.41,43246504],
		["Deer Park","CA",38.55,-122.47,43246524],
		["Witter Springs","CA",39.2,-122.98,43246544],
		["Piercy","CA",39.95,-123.74,43246564],
		["Soda Springs","CA",39.27,-120.41,43246584],
		["Likely","CA",41.29,-120.44,43246604],
		["Camp Sherman","OR",44.47,-121.64,43246624],
		["Satsop","WA",47,-123.48,43246644],
		["Prudence Island","RI",41.6,-71.31,43246663],
		["Benedicta","ME",45.8,-68.41,43246682],
		["West Hartland","CT",42,-72.97,43246701],
		["Sterling Forest","NY",41.25,-74.34,43246720],
		["Glenham","NY",41.53,-73.89,43246739],
		["Rhinecliff","NY",41.92,-73.9,43246758],
		["Cranberry Lake","NY",44.22,-74.83,43246777],
		["Moriah Center","NY",44.06,-73.52,43246796],
		["New Russia","NY",44.14,-73.6,43246815],
		["Ray Brook","NY",44.3,-74.08,43246834],
		["Brantingham","NY",43.7,-75.29,43246853],
		["Southview","PA",40.32,-80.21,43246872],
		["Hamilton","PA",40.92,-79.09,43246891],
		["Mentcle","PA",40.63,-78.88,43246910],
		["West Lebanon","PA",40.6,-79.35,43246929],
		["Cassandra","PA",40.4,-78.64,43246948],
		["Foxburg","PA",41.14,-79.68,43246967],
		["Kossuth","PA",41.28,-79.59,43246986],
		["Calvin","PA",40.3,-78.03,43247005],
		["Rebuck","PA",40.72,-76.75,43247024],
		["Weikert","PA",40.86,-77.28,43247043],
		["Harleigh","PA",40.98,-75.97,43247062],
		["Bowers","PA",40.52,-75.74,43247081],
		["Syria","VA",38.48,-78.32,43247100],
		["Wachapreague","VA",37.6,-75.68,43247119],
		["Bacova","VA",38.01,-79.9,43247138],
		["Blair","WV",37.86,-81.83,43247157],
		["Princewick","WV",37.65,-81.23,43247176],
		["Capon Springs","WV",39.13,-78.48,43247195],
		["Montreat","NC",35.64,-82.3,43247214],
		["Bostwick","GA",33.73,-83.51,43247233],
		["Siloam","GA",33.53,-83.08,43247252],
		["Eastlake Weir","FL",29.04,-81.89,43247271],
		["Whiteside","TN",35.09,-85.39,43247290],
		["Elgin","TN",36.33,-84.6,43247309],
		["Fernwood","MS",31.18,-90.44,43247328],
		["Big Laurel","KY",36.97,-83.21,43247347],
		["Thousandsticks","KY",37.18,-83.42,43247366],
		["Rosewood","OH",40.23,-83.96,43247385],
		["Kirby","OH",40.81,-83.41,43247404],
		["Belle Valley","OH",39.78,-81.55,43247423],
		["Harrisville","OH",40.18,-80.88,43247442],
		["Somerdale","OH",40.57,-81.37,43247461],
		["Winesburg","OH",40.63,-81.7,43247480],
		["Flat Rock","OH",41.24,-82.85,43247499],
		["Neville","OH",38.81,-84.21,43247518],
		["Saint Johns","OH",40.56,-84.13,43247537],
		["Mays","IN",39.74,-85.46,43247556],
		["Somerset","IN",40.69,-85.86,43247575],
		["Westphalia","IN",38.86,-87.23,43247594],
		["Atlas","MI",42.92,-83.51,43247613],
		["Nottawa","MI",41.93,-85.44,43247632],
		["Ellston","IA",40.84,-94.1,43247651],
		["Little Cedar","IA",43.38,-92.73,43247670],
		["Prairieburg","IA",42.23,-91.42,43247689],
		["Herbster","WI",46.83,-91.26,43247708],
		["Odanah","WI",46.6,-90.68,43247727],
		["Eureka","WI",44,-88.84,43247746],
		["Lansing","MN",43.74,-92.96,43247765],
		["Kenneth","MN",43.75,-96.07,43247784],
		["Leota","MN",43.8,-95.99,43247803],
		["Loman","MN",48.51,-93.8,43247822],
		["Halma","MN",48.66,-96.59,43247841],
		["Kranzburg","SD",44.88,-96.9,43247860],
		["Holabird","SD",44.52,-99.59,43247879],
		["Scenic","SD",43.78,-102.55,43247898],
		["Erie","ND",47.11,-97.38,43247917],
		["Mantador","ND",46.16,-96.97,43247936],
		["Cummings","ND",47.51,-97.07,43247955],
		["Mylo","ND",48.63,-99.61,43247974],
		["Goodrich","ND",47.47,-100.12,43247993],
		["Moffit","ND",46.68,-100.28,43248012],
		["Martin","ND",47.82,-100.11,43248031],
		["Vida","MT",47.86,-105.42,43248050],
		["Joplin","MT",48.71,-110.79,43248069],
		["Lane","IL",40.13,-88.85,43248088],
		["Patterson","IL",39.48,-90.48,43248107],
		["Maunie","IL",38.03,-88.04,43248126],
		["Muddy","IL",37.76,-88.51,43248145],
		["Kewanee","MO",36.67,-89.56,43248164],
		["Martinsville","MO",40.38,-94.15,43248183],
		["Watson","MO",40.48,-95.62,43248202],
		["Freistatt","MO",37.02,-93.89,43248221],
		["Pontiac","MO",36.51,-92.6,43248240],
		["Stark","KS",37.68,-95.14,43248259],
		["Lost Springs","KS",38.56,-96.96,43248278],
		["Haddam","KS",39.85,-97.3,43248297],
		["Raymond","KS",38.27,-98.41,43248316],
		["Penokee","KS",39.35,-99.97,43248335],
		["Coolidge","KS",38.04,-102,43248354],
		["Kendall","KS",37.94,-101.54,43248373],
		["Bloomington","NE",40.09,-99.03,43248392],
		["Lemoyne","NE",41.28,-101.8,43248411],
		["Sun","LA",30.64,-89.9,43248430],
		["Jones","LA",32.94,-91.55,43248449],
		["Camden","AR",33.56,-92.84,43248468],
		["Langley","AR",34.3,-93.84,43248487],
		["Pindall","AR",36.05,-92.86,43248506],
		["Hennepin","OK",34.5,-97.35,43248525],
		["Elmer","OK",34.48,-99.35,43248544],
		["Blanco","OK",34.75,-95.77,43248563],
		["Moffett","OK",35.38,-94.44,43248582],
		["Randolph","TX",33.48,-96.25,43248601],
		["Lohn","TX",31.32,-99.4,43248620],
		["Wall","TX",31.37,-100.3,43248639],
		["Edmonson","TX",34.28,-101.89,43248658],
		["Rule","TX",33.18,-99.89,43248677],
		["New Raymer","CO",40.62,-103.78,43248696],
		["Kirk","CO",39.62,-102.6,43248715],
		["Crowley","CO",38.19,-103.85,43248734],
		["Edgerton","WY",43.41,-106.24,43248753],
		["Aladdin","WY",44.63,-104.18,43248772],
		["Moose","WY",43.65,-110.72,43248791],
		["Teton Village","WY",43.57,-110.81,43248810],
		["North Fork","ID",45.49,-114.06,43248829],
		["Alton","UT",37.46,-112.5,43248848],
		["Hatch","UT",37.6,-112.47,43248867],
		["Hackberry","AZ",35.36,-113.72,43248886],
		["Chloride","AZ",35.39,-114.21,43248905],
		["Willow Beach","AZ",35.9,-114.59,43248924],
		["Amalia","NM",36.94,-105.45,43248943],
		["Mcalister","NM",34.74,-103.66,43248962],
		["Rutherford","CA",38.45,-122.41,43248981],
		["Mount Hermon","CA",37.05,-122.05,43249000],
		["Danville","WA",48.94,-118.46,43249019],
		["Chignik Lake","AK",56.23,-158.74,43249038],
		["Manley Hot Springs","AK",64.65,-150.9,43249057],
		["Wales","AK",65.61,-168.09,43249076],
		["Lake Pleasant","MA",42.53,-72.54,43249094],
		["Vanceboro","ME",45.56,-67.43,43249112],
		["Eustis","ME",45.21,-70.47,43249130],
		["West Hartford","VT",43.72,-72.42,43249148],
		["Adamant","VT",44.35,-72.5,43249166],
		["Cobalt","CT",41.56,-72.5,43249184],
		["Willow","NY",42.09,-74.2,43249202],
		["Collins Center","NY",42.5,-78.91,43249220],
		["Coopers Plains","NY",42.18,-77.14,43249238],
		["Sipesville","PA",40.04,-79.12,43249256],
		["Marsteller","PA",40.64,-78.81,43249274],
		["Pleasant Hall","PA",40.02,-77.7,43249292],
		["Roxbury","PA",40.09,-77.67,43249310],
		["Woolrich","PA",41.19,-77.33,43249328],
		["Lavelle","PA",40.76,-76.39,43249346],
		["Harford","PA",41.78,-75.7,43249364],
		["Parker Ford","PA",40.2,-75.59,43249382],
		["Upper Fairmount","MD",38.1,-75.79,43249400],
		["Hood","VA",38.35,-78.38,43249418],
		["New Point","VA",37.34,-76.28,43249436],
		["Susan","VA",37.36,-76.31,43249454],
		["Withams","VA",37.96,-75.56,43249472],
		["Big Sandy","WV",37.47,-81.67,43249490],
		["Costa","WV",38.15,-81.71,43249508],
		["Glen","WV",38.35,-81.23,43249526],
		["Haywood","WV",39.36,-80.32,43249544],
		["Four States","WV",39.47,-80.29,43249562],
		["Dille","WV",38.46,-80.87,43249580],
		["Pineola","NC",36.02,-81.82,43249598],
		["Cross Anchor","SC",34.64,-81.85,43249616],
		["Tatum","SC",34.64,-79.58,43249634],
		["Tallulah Falls","GA",34.72,-83.38,43249652],
		["Seville","GA",31.95,-83.59,43249670],
		["Salem","FL",29.88,-83.41,43249688],
		["Echola","AL",33.32,-87.81,43249706],
		["Gattman","MS",33.88,-88.23,43249724],
		["Delta City","MS",33.07,-90.75,43249742],
		["Grays Knob","KY",36.8,-83.3,43249760],
		["Roxana","KY",37.1,-82.94,43249778],
		["Kelleys Island","OH",41.59,-82.71,43249796],
		["Sulphur Springs","OH",40.87,-82.86,43249814],
		["Homer","IN",39.58,-85.52,43249832],
		["Atwood","IN",41.26,-85.97,43249850],
		["New Middletown","IN",38.16,-86.05,43249868],
		["Winn","MI",43.52,-84.9,43249886],
		["New Troy","MI",41.83,-86.51,43249904],
		["Big Bay","MI",46.81,-87.72,43249922],
		["Rumely","MI",46.34,-86.92,43249940],
		["Nisula","MI",46.76,-88.79,43249958],
		["Skanee","MI",46.89,-88.18,43249976],
		["Chillicothe","IA",41.08,-92.52,43249994],
		["Fence","WI",45.75,-88.43,43250012],
		["Seaforth","MN",44.47,-95.32,43250030],
		["Nielsville","MN",47.52,-96.81,43250048],
		["Sinai","SD",44.24,-97.04,43250066],
		["Seneca","SD",45.06,-99.5,43250084],
		["Belvidere","SD",43.83,-101.27,43250102],
		["Ludlow","SD",45.83,-103.38,43250120],
		["Nome","ND",46.67,-97.81,43250138],
		["Regan","ND",47.15,-100.52,43250156],
		["Robinson","ND",47.14,-99.78,43250174],
		["Tuttle","ND",47.14,-99.99,43250192],
		["Mcgregor","ND",48.6,-102.93,43250210],
		["Wildrose","ND",48.62,-103.18,43250228],
		["Mc Leod","MT",45.44,-110.2,43250246],
		["Opheim","MT",48.83,-106.32,43250264],
		["Biddle","MT",45.12,-105.41,43250282],
		["Olive","MT",45.64,-105.43,43250300],
		["Monarch","MT",46.98,-110.85,43250318],
		["Garrison","MT",46.59,-112.83,43250336],
		["Muncie","IL",40.11,-87.84,43250354],
		["Winigan","MO",40.05,-92.9,43250372],
		["Benedict","KS",37.62,-95.74,43250390],
		["Simpson","KS",39.38,-97.93,43250408],
		["Woodbine","KS",38.79,-96.95,43250426],
		["Gove","KS",38.95,-100.49,43250444],
		["Levant","KS",39.38,-101.19,43250462],
		["Barneston","NE",40.04,-96.57,43250480],
		["Lewiston","NE",40.24,-96.4,43250498],
		["Swanton","NE",40.37,-97.07,43250516],
		["Lincoln","NE",40.81,-96.68,43250534],
		["Elyria","NE",41.67,-99,43250552],
		["Odessa","NE",40.7,-99.25,43250570],
		["Naponee","NE",40.07,-99.13,43250588],
		["Wood Lake","NE",42.63,-100.23,43250606],
		["Monroe","AR",34.71,-91.07,43250624],
		["Aubrey","AR",34.71,-90.89,43250642],
		["Peach Orchard","AR",36.28,-90.66,43250660],
		["Dallas","TX",32.79,-96.76,43250678],
		["Enloe","TX",33.43,-95.65,43250696],
		["Gatesville","TX",31.44,-97.73,43250714],
		["La Salle","TX",28.76,-96.62,43250732],
		["Sweet Home","TX",29.35,-97.08,43250750],
		["Severance","CO",40.52,-104.85,43250768],
		["Villa Grove","CO",38.25,-105.95,43250786],
		["Somerset","CO",38.92,-107.43,43250804],
		["Opal","WY",41.77,-110.32,43250822],
		["Dingle","ID",42.23,-111.27,43250840],
		["Ellis","ID",44.65,-113.99,43250858],
		["Lowman","ID",44.14,-115.32,43250876],
		["Forest Lakes","AZ",34.42,-110.91,43250894],
		["Causey","NM",33.86,-103.11,43250912],
		["Milnesand","NM",33.64,-103.33,43250930],
		["Amistad","NM",35.91,-103.15,43250948],
		["Duckwater","NV",38.86,-115.73,43250966],
		["Port Costa","CA",38.03,-122.19,43250984],
		["Big Oak Flat","CA",37.82,-120.25,43251002],
		["Fort Rock","OR",43.32,-121.03,43251020],
		["Imnaha","OR",45.46,-116.75,43251038],
		["Baring","WA",47.73,-121.49,43251056],
		["Mazama","WA",48.59,-120.39,43251074],
		["Lamont","WA",47.16,-117.84,43251092],
		["Dixie","WA",46.15,-118.18,43251110],
		["Akutan","AK",54.13,-165.88,43251128],
		["Hope","AK",60.84,-149.4,43251146],
		["Tanacross","AK",63.38,-143.39,43251164],
		["Siasconset","MA",41.27,-70,43251181],
		["York Harbor","ME",43.14,-70.64,43251198],
		["East Andover","ME",44.6,-70.72,43251215],
		["Shirley Mills","ME",45.35,-69.63,43251232],
		["Stillwater","ME",45.01,-68.72,43251249],
		["Sunset","ME",44.22,-68.7,43251266],
		["Mc Indoe Falls","VT",44.26,-72.06,43251283],
		["Websterville","VT",44.13,-72.45,43251300],
		["New Lisbon","NJ",39.92,-74.53,43251317],
		["North Blenheim","NY",42.47,-74.43,43251334],
		["Barrytown","NY",41.99,-73.92,43251351],
		["Chelsea","NY",41.59,-73.89,43251368],
		["North River","NY",43.73,-74.04,43251385],
		["Greenhurst","NY",42.14,-79.31,43251402],
		["Curryville","PA",40.29,-78.3,43251419],
		["Salona","PA",41.08,-77.45,43251436],
		["Laurelton","PA",40.94,-77.2,43251453],
		["Junedale","PA",40.92,-75.92,43251470],
		["Rock Glen","PA",40.96,-76.18,43251487],
		["Sterling","PA",41.34,-75.39,43251504],
		["Bethel","DE",38.57,-75.61,43251521],
		["Casanova","VA",38.61,-77.64,43251538],
		["Perryman","MD",39.46,-76.2,43251555],
		["Showell","MD",38.39,-75.21,43251572],
		["Markham","VA",38.9,-78,43251589],
		["Hacksneck","VA",37.63,-75.84,43251606],
		["Cripple Creek","VA",36.81,-81.1,43251623],
		["Hensley","WV",37.47,-81.67,43251640],
		["Nebo","WV",38.62,-81.02,43251657],
		["Millville","WV",39.29,-77.78,43251674],
		["Prince","WV",37.87,-81.03,43251691],
		["Slatyfork","WV",38.38,-80.06,43251708],
		["Cedarville","WV",38.84,-80.84,43251725],
		["Saint Elmo","AL",30.49,-88.24,43251742],
		["Burdine","KY",37.18,-82.6,43251759],
		["Lackey","KY",37.46,-82.8,43251776],
		["Kipton","OH",41.26,-82.3,43251793],
		["Mesopotamia","OH",41.46,-80.95,43251810],
		["North Robinson","OH",40.79,-82.85,43251827],
		["Sinking Spring","OH",39.07,-83.38,43251844],
		["Rockland","MI",46.73,-89.17,43251861],
		["Spillville","IA",43.2,-91.95,43251878],
		["Milwaukee","WI",43.06,-87.96,43251895],
		["Twin Lakes","MN",43.56,-93.42,43251912],
		["Birchdale","MN",48.62,-94.1,43251929],
		["Claire City","SD",45.85,-97.1,43251946],
		["Marvin","SD",45.26,-96.91,43251963],
		["Tolstoy","SD",45.2,-99.61,43251980],
		["Witten","SD",43.43,-100.08,43251997],
		["Fairburn","SD",43.68,-103.2,43252014],
		["Owanka","SD",44.01,-102.58,43252031],
		["White Owl","SD",44.59,-102.42,43252048],
		["Denhoff","ND",47.48,-100.26,43252065],
		["Fairfield","ND",47.18,-103.21,43252082],
		["Marmarth","ND",46.29,-103.93,43252099],
		["Butte","ND",47.83,-100.66,43252116],
		["Noonan","ND",48.89,-103.01,43252133],
		["Palermo","ND",48.33,-102.22,43252150],
		["Alamo","ND",48.58,-103.46,43252167],
		["Kevin","MT",48.73,-112.01,43252184],
		["Jackson","MT",45.31,-113.35,43252201],
		["Van Orin","IL",41.55,-89.35,43252218],
		["Eddyville","IL",37.5,-88.58,43252235],
		["Coatsville","MO",40.58,-92.63,43252252],
		["Strasburg","MO",38.75,-94.16,43252269],
		["Hatfield","MO",40.53,-94.15,43252286],
		["Roscoe","MO",37.97,-93.81,43252303],
		["Graff","MO",37.31,-92.28,43252320],
		["Virgil","KS",37.98,-96.01,43252337],
		["Bogue","KS",39.35,-99.68,43252354],
		["Gaylord","KS",39.64,-98.84,43252371],
		["Washington","NE",41.39,-96.2,43252388],
		["Virginia","NE",40.24,-96.49,43252405],
		["Surprise","NE",41.1,-97.3,43252422],
		["Oak","NE",40.23,-97.9,43252439],
		["Ruskin","NE",40.14,-97.86,43252456],
		["Melbeta","NE",41.78,-103.51,43252473],
		["Avery Island","LA",29.9,-91.91,43252490],
		["Aimwell","LA",31.75,-91.99,43252507],
		["Diamond City","AR",36.45,-92.91,43252524],
		["Enid","OK",36.4,-97.86,43252541],
		["Hallett","OK",36.23,-96.56,43252558],
		["Burkett","TX",31.99,-99.22,43252575],
		["Edroy","TX",27.93,-97.66,43252592],
		["Tokio","TX",33.18,-102.57,43252609],
		["Molina","CO",39.18,-108.07,43252626],
		["Red Cliff","CO",39.5,-106.36,43252643],
		["Centennial","WY",41.3,-106.11,43252660],
		["Arbon","ID",42.53,-112.55,43252677],
		["Geneva","ID",42.36,-111.11,43252694],
		["Reubens","ID",46.32,-116.51,43252711],
		["Mesa","ID",44.63,-116.44,43252728],
		["Summit","UT",37.77,-112.93,43252745],
		["Cebolla","NM",36.53,-106.48,43252762],
		["Vallecitos","NM",36.49,-106.12,43252779],
		["La Loma","NM",35.19,-105.08,43252796],
		["Pie Town","NM",34.33,-108.28,43252813],
		["Derry","NM",32.78,-107.28,43252830],
		["Loco Hills","NM",32.81,-103.97,43252847],
		["Glencoe","NM",33.4,-105.44,43252864],
		["Timberon","NM",32.63,-105.69,43252881],
		["Las Vegas","NV",35.99,-115.4,43252898],
		["Silver City","NV",39.25,-119.63,43252915],
		["Johannesburg","CA",35.36,-117.64,43252932],
		["Badger","CA",36.6,-118.92,43252949],
		["San Francisco","CA",37.79,-122.4,43252966],
		["Holt","CA",37.93,-121.42,43252983],
		["Elmira","CA",38.35,-121.9,43253000],
		["Gold Run","CA",39.13,-120.83,43253017],
		["Washington","CA",39.33,-120.79,43253034],
		["Callahan","CA",41.36,-122.77,43253051],
		["Castella","CA",41.1,-122.35,43253068],
		["Tiller","OR",42.91,-122.87,43253085],
		["Juntura","OR",43.72,-118.02,43253102],
		["Seattle","WA",47.57,-122.33,43253119],
		["Elbe","WA",46.76,-122.16,43253136],
		["Moclips","WA",47.25,-124.2,43253153],
		["Lincoln","WA",47.83,-118.46,43253170],
		["Orient","WA",48.78,-118.2,43253187],
		["Thornton","WA",47.09,-117.39,43253204],
		["Port Heiden","AK",57.09,-158.26,43253221],
		["Saint George Island","AK",56.57,-169.63,43253238],
		["Woronoco","MA",42.19,-72.82,43253254],
		["Stoneham","ME",44.25,-70.81,43253270],
		["Johnsonburg","NJ",40.96,-74.88,43253286],
		["Mauricetown","NJ",39.26,-74.97,43253302],
		["Southfields","NY",41.25,-74.17,43253318],
		["Cherry Plain","NY",42.66,-73.37,43253334],
		["Malden Bridge","NY",42.45,-73.58,43253350],
		["Rifton","NY",41.81,-74.04,43253366],
		["Kattskill Bay","NY",43.51,-73.62,43253382],
		["North Pitcher","NY",42.65,-75.81,43253398],
		["Roseboom","NY",42.74,-74.77,43253414],
		["Pierrepont Manor","NY",43.74,-76.04,43253430],
		["Sardinia","NY",42.53,-78.5,43253446],
		["Slaterville Springs","NY",42.39,-76.35,43253462],
		["Westland","PA",40.29,-80.3,43253478],
		["North Washington","PA",40.53,-79.6,43253494],
		["New Bedford","PA",41.1,-80.5,43253510],
		["Smoketown","PA",40.04,-76.21,43253526],
		["Friedensburg","PA",40.58,-76.23,43253542],
		["Earlville","PA",40.37,-75.67,43253558],
		["Hillsboro","MD",38.91,-75.94,43253574],
		["Woodberry Forest","VA",38.21,-78.05,43253590],
		["Blue Grass","VA",38.53,-79.6,43253606],
		["Hiawatha","WV",37.45,-81.23,43253622],
		["Waiteville","WV",37.48,-80.43,43253638],
		["Saulsville","WV",37.63,-81.44,43253654],
		["Pickens","WV",38.65,-80.19,43253670],
		["Salvo","NC",35.45,-75.48,43253686],
		["Sea Island","GA",31.18,-81.35,43253702],
		["Range","AL",31.31,-87.23,43253718],
		["Lenox","TN",36.07,-89.5,43253734],
		["Chappell","KY",37,-83.34,43253750],
		["Mary Alice","KY",36.78,-83.33,43253766],
		["Williamsport","KY",37.82,-82.72,43253782],
		["Bandana","KY",37.14,-88.94,43253798],
		["Berlin","OH",40.55,-81.8,43253814],
		["Mongo","IN",41.68,-85.27,43253830],
		["Young America","IN",40.57,-86.34,43253846],
		["Jonesville","IN",39.06,-85.88,43253862],
		["Cohoctah","MI",42.75,-83.94,43253878],
		["Deerton","MI",46.47,-87.04,43253894],
		["Loretto","MI",45.77,-87.81,43253910],
		["Patterson","IA",41.34,-93.87,43253926],
		["Jolley","IA",42.47,-94.71,43253942],
		["Brunsville","IA",42.81,-96.26,43253958],
		["Saint Paul","IA",40.76,-91.51,43253974],
		["Doylestown","WI",43.42,-89.14,43253990],
		["Garden City","SD",44.95,-97.58,43254006],
		["Ortley","SD",45.33,-97.2,43254022],
		["Hampden","ND",48.53,-98.65,43254038],
		["Grace City","ND",47.55,-98.8,43254054],
		["Benedict","ND",47.83,-101.08,43254070],
		["Karlsruhe","ND",48.09,-100.61,43254086],
		["Newburg","ND",48.71,-100.91,43254102],
		["Nye","MT",45.36,-109.81,43254118],
		["Iroquois","IL",40.82,-87.58,43254134],
		["Eldena","IL",41.8,-89.47,43254150],
		["Leonore","IL",41.18,-88.98,43254166],
		["Camp Grove","IL",41.08,-89.63,43254182],
		["Speer","IL",41.01,-89.69,43254198],
		["La Rose","IL",40.97,-89.23,43254214],
		["Keensburg","IL",38.35,-87.86,43254230],
		["Clyde","MO",40.26,-94.67,43254246],
		["Worth","MO",40.4,-94.44,43254262],
		["Neck City","MO",37.25,-94.44,43254278],
		["Jadwin","MO",37.48,-91.57,43254294],
		["Arcola","MO",37.54,-93.87,43254310],
		["Crestline","KS",37.17,-94.7,43254326],
		["Bunker Hill","KS",38.87,-98.7,43254342],
		["Morland","KS",39.34,-100.07,43254358],
		["Memphis","NE",41.09,-96.43,43254374],
		["Sprague","NE",40.62,-96.74,43254390],
		["Max","NE",40.11,-101.4,43254406],
		["Mathews","LA",29.68,-90.56,43254422],
		["Jersey","AR",33.34,-92.28,43254438],
		["Rohwer","AR",33.62,-91.39,43254454],
		["Antoine","AR",34.03,-93.42,43254470],
		["Minturn","AR",35.97,-91.02,43254486],
		["Ida","AR",35.59,-91.94,43254502],
		["Lucien","OK",36.28,-97.45,43254518],
		["Vera","OK",36.44,-95.88,43254534],
		["Fittstown","OK",34.62,-96.63,43254550],
		["Redwater","TX",33.35,-94.25,43254566],
		["Neches","TX",31.87,-95.5,43254582],
		["Jermyn","TX",33.25,-98.39,43254598],
		["Gouldbusk","TX",31.55,-99.47,43254614],
		["Pledger","TX",29.17,-95.89,43254630],
		["Mumford","TX",30.72,-96.56,43254646],
		["Fowlerton","TX",28.45,-98.81,43254662],
		["Willow City","TX",30.4,-98.7,43254678],
		["Flomot","TX",34.22,-100.98,43254694],
		["Saragosa","TX",31.03,-103.66,43254710],
		["Conejos","CO",37.08,-106.02,43254726],
		["Crowheart","WY",43.31,-109.19,43254742],
		["Picabo","ID",43.3,-114.07,43254758],
		["Mount Carmel","UT",37.24,-112.77,43254774],
		["Duck Creek Village","UT",37.4,-112.63,43254790],
		["Ocate","NM",36.17,-105.04,43254806],
		["House","NM",34.64,-103.9,43254822],
		["Encino","NM",34.65,-105.45,43254838],
		["Nara Visa","NM",35.6,-103.09,43254854],
		["San Gregorio","CA",37.33,-122.34,43254870],
		["San Jose","CA",37.33,-121.89,43254886],
		["Westport","CA",39.72,-123.77,43254902],
		["Hyampom","CA",40.6,-123.4,43254918],
		["Nubieber","CA",41.08,-121.19,43254934],
		["Sierra City","CA",39.6,-120.65,43254950],
		["Vinton","CA",39.71,-120.21,43254966],
		["Ninole","HI",19.93,-155.16,43254982],
		["Riley","OR",43.58,-119.57,43254998],
		["Hereford","OR",44.52,-118.02,43255014],
		["Sumpter","OR",44.75,-118.2,43255030],
		["Wauconda","WA",48.82,-118.95,43255046],
		["Chignik","AK",56.3,-158.36,43255062],
		["Cold Bay","AK",55.92,-160.29,43255078],
		["Perryville","AK",55.9,-159.27,43255094],
		["Tatitlek","AK",60.88,-146.65,43255110],
		["Nikolai","AK",62.87,-153.87,43255126],
		["Chalkyitsik","AK",66.64,-143.78,43255142],
		["Monroe Bridge","MA",42.72,-72.98,43255157],
		["Monument Beach","MA",41.71,-70.6,43255172],
		["Woodstock","NH",43.97,-71.68,43255187],
		["Rye Beach","NH",43.01,-70.76,43255202],
		["Ocean Park","ME",43.51,-70.38,43255217],
		["East Blue Hill","ME",44.44,-68.54,43255232],
		["Sorrento","ME",44.49,-68.18,43255247],
		["Delaware","NJ",40.88,-75.04,43255262],
		["Hancocks Bridge","NJ",39.47,-75.45,43255277],
		["Port Elizabeth","NJ",39.39,-74.88,43255292],
		["Thompson Ridge","NY",41.58,-74.36,43255307],
		["Pine Hill","NY",42.13,-74.47,43255322],
		["Bethel","NY",41.68,-74.87,43255337],
		["Obernburg","NY",41.84,-75,43255352],
		["Bakers Mills","NY",43.61,-74.07,43255367],
		["Blue Mountain Lake","NY",43.87,-74.44,43255382],
		["Port Gibson","NY",42.99,-77.19,43255397],
		["Rural Ridge","PA",40.56,-79.9,43255412],
		["Garards Fort","PA",39.81,-80.02,43255427],
		["Somerset","PA",40,-79.07,43255442],
		["Boynton","PA",39.78,-79.01,43255457],
		["Darragh","PA",40.25,-79.67,43255472],
		["East Smethport","PA",41.83,-78.43,43255487],
		["Mexico","PA",40.52,-77.32,43255502],
		["Eagles Mere","PA",41.4,-76.58,43255517],
		["East Texas","PA",40.6,-75.54,43255532],
		["Preston Park","PA",41.88,-75.35,43255547],
		["Frederick","PA",40.3,-75.53,43255562],
		["Washington","DC",38.89,-77.03,43255577],
		["Morganza","MD",38.37,-76.69,43255592],
		["Midlothian","MD",39.66,-78.94,43255607],
		["Augusta Springs","VA",38.1,-79.33,43255622],
		["Hilltop","WV",37.97,-81.09,43255637],
		["Meadow Creek","WV",37.8,-80.91,43255652],
		["Berea","WV",39.13,-80.92,43255667],
		["Hobucken","NC",35.26,-76.53,43255682],
		["Stacy","NC",34.85,-76.41,43255697],
		["Furman","SC",32.68,-81.18,43255712],
		["Nunez","GA",32.49,-82.34,43255727],
		["Crescent","GA",31.5,-81.36,43255742],
		["Earleton","FL",29.71,-82.11,43255757],
		["Long Key","FL",24.82,-80.81,43255772],
		["New Castle","AL",33.68,-86.77,43255787],
		["Belk","AL",33.64,-87.92,43255802],
		["Ellendale","TN",35.23,-89.83,43255817],
		["Chatham","MS",33.08,-91.08,43255832],
		["Trosper","KY",36.78,-83.81,43255847],
		["Marshes Siding","KY",36.74,-84.48,43255862],
		["Harbor View","OH",41.69,-83.44,43255877],
		["New Haven","OH",41.03,-82.68,43255892],
		["Reno","OH",39.39,-81.35,43255907],
		["Huron","IN",38.71,-86.65,43255922],
		["Brohman","MI",43.67,-85.82,43255937],
		["Walhalla","MI",43.95,-86.11,43255952],
		["Alpha","MI",46.04,-88.37,43255967],
		["Greenland","MI",46.82,-89.07,43255982],
		["Bradgate","IA",42.8,-94.41,43255997],
		["Zenda","WI",42.54,-88.49,43256012],
		["Tofte","MN",47.55,-90.88,43256027],
		["Kinney","MN",47.52,-92.72,43256042],
		["Darfur","MN",44.05,-94.83,43256057],
		["Flensburg","MN",45.94,-94.53,43256072],
		["Marcell","MN",47.59,-93.69,43256087],
		["Tuthill","SD",43.15,-101.49,43256102],
		["Milton","ND",48.62,-98.04,43256117],
		["Fortuna","ND",48.91,-103.77,43256132],
		["Fort Smith","MT",45.24,-107.93,43256147],
		["Brusett","MT",47.45,-107.54,43256162],
		["Inverness","MT",48.61,-110.7,43256177],
		["Glen","MT",45.48,-112.77,43256192],
		["Fidelity","IL",39.15,-90.16,43256207],
		["Wrights","IL",39.37,-90.29,43256222],
		["Harristown","IL",39.84,-89.05,43256237],
		["Newark","MO",39.99,-91.97,43256252],
		["Tiff","MO",38.01,-90.75,43256267],
		["Ponce De Leon","MO",36.87,-93.35,43256282],
		["Agenda","KS",39.7,-97.43,43256297],
		["Bluff City","KS",37.07,-97.87,43256312],
		["Danville","KS",37.28,-97.89,43256327],
		["Nashville","KS",37.43,-98.42,43256342],
		["Hunter","KS",39.23,-98.39,43256357],
		["Pfeifer","KS",38.73,-99.12,43256372],
		["Crab Orchard","NE",40.33,-96.42,43256387],
		["Mills","NE",42.94,-99.4,43256402],
		["Ellsworth","NE",42.06,-102.28,43256417],
		["Ethel","AR",34.19,-91.13,43256432],
		["Hunter","AR",35.05,-91.12,43256447],
		["Mc Dougal","AR",36.43,-90.39,43256462],
		["Bluffton","AR",34.89,-93.59,43256477],
		["Gravelly","AR",34.89,-93.67,43256492],
		["Dacoma","OK",36.66,-98.56,43256507],
		["Valera","TX",31.75,-99.54,43256522],
		["Valley Spring","TX",30.83,-98.84,43256537],
		["Dodge","TX",30.75,-95.43,43256552],
		["Redford","TX",29.45,-104.19,43256567],
		["Salt Flat","TX",31.74,-105.08,43256582],
		["Buffalo Creek","CO",39.38,-105.27,43256597],
		["Burns","CO",39.87,-106.88,43256612],
		["Mc Coy","CO",39.92,-106.72,43256627],
		["Coaldale","CO",38.37,-105.75,43256642],
		["Emblem","WY",44.51,-108.39,43256657],
		["Alva","WY",44.7,-104.45,43256672],
		["May","ID",44.48,-113.76,43256687],
		["Lucile","ID",45.54,-116.24,43256702],
		["Lincoln","NM",33.49,-105.38,43256717],
		["Glendale","CA",34.14,-118.25,43256732],
		["Posey","CA",35.88,-118.59,43256747],
		["Helm","CA",36.53,-120.11,43256762],
		["Glenhaven","CA",39.04,-122.74,43256777],
		["Amador City","CA",38.43,-120.84,43256792],
		["Paskenta","CA",39.88,-122.58,43256807],
		["Portland","OR",45.51,-122.67,43256822],
		["Sixes","OR",42.87,-124.18,43256837],
		["Chemult","OR",43.23,-121.91,43256852],
		["Westfall","OR",44.03,-117.71,43256867],
		["Copalis Crossing","WA",47.16,-124.12,43256882],
		["Palisades","WA",47.38,-119.96,43256897],
		["Shageluk","AK",62.64,-159.53,43256912],
		["Whittier","AK",60.28,-148.3,43256927],
		["Beaver","AK",66.32,-146.77,43256942],
		["Circle","AK",65.79,-144.2,43256957],
		["Mill River","MA",42.12,-73.27,43256971],
		["Montville","CT",41.44,-72.12,43256985],
		["Oldwick","NJ",40.66,-74.74,43256999],
		["Bullville","NY",41.58,-74.29,43257013],
		["West Camp","NY",42.12,-73.92,43257027],
		["Fremont Center","NY",41.84,-75.04,43257041],
		["Westford","NY",42.65,-74.79,43257055],
		["Portlandville","NY",42.53,-74.96,43257069],
		["York","NY",42.86,-77.88,43257083],
		["West Middletown","PA",40.24,-80.42,43257097],
		["Beccaria","PA",40.76,-78.5,43257111],
		["Cross Fork","PA",41.48,-77.83,43257125],
		["Summit Station","PA",40.58,-76.22,43257139],
		["Pocono Manor","PA",41.03,-75.28,43257153],
		["Shawnee On Delaware","PA",41.08,-75.13,43257167],
		["Unionville","PA",39.89,-75.73,43257181],
		["Neavitt","MD",38.72,-76.28,43257195],
		["Ewell","MD",37.98,-76.03,43257209],
		["Diggs","VA",37.43,-76.28,43257223],
		["Willis Wharf","VA",37.51,-75.81,43257237],
		["Yorktown","VA",37.23,-76.5,43257251],
		["Thorpe","WV",37.34,-81.49,43257265],
		["Sharples","WV",37.89,-81.82,43257279],
		["Amigo","WV",37.59,-81.34,43257293],
		["Maben","WV",37.66,-81.39,43257307],
		["Winona","WV",38.02,-80.96,43257321],
		["Pool","WV",38.14,-80.88,43257335],
		["Glendale Springs","NC",36.35,-81.38,43257349],
		["Liberty Hill","SC",34.47,-80.8,43257363],
		["Lydia","SC",34.28,-80.03,43257377],
		["Stillmore","GA",32.44,-82.21,43257391],
		["Tennga","GA",34.98,-84.72,43257405],
		["Green Pond","AL",33.21,-87.14,43257419],
		["Belle Mina","AL",34.65,-86.82,43257433],
		["Tigrett","TN",35.96,-89.24,43257447],
		["Hoskinston","KY",37.07,-83.39,43257461],
		["Raven","KY",37.4,-82.8,43257475],
		["Alpha","KY",36.76,-85.01,43257489],
		["Jacksontown","OH",39.92,-82.39,43257503],
		["Brownsville","OH",39.92,-82.39,43257517],
		["Summitville","OH",40.67,-80.88,43257531],
		["Bannock","OH",40.07,-81.06,43257545],
		["Boston","IN",39.74,-84.85,43257559],
		["Greensboro","IN",39.87,-85.46,43257573],
		["Scotland","IN",38.91,-86.91,43257587],
		["Alamo","IN",39.98,-87.05,43257601],
		["Filer City","MI",44.2,-86.3,43257615],
		["Hulbert","MI",46.35,-85.15,43257629],
		["Seney","MI",46.36,-85.97,43257643],
		["Clio","IA",40.63,-93.45,43257657],
		["Matlock","IA",43.24,-95.93,43257671],
		["Ashippun","WI",43.23,-88.47,43257685],
		["Elton","WI",45.15,-88.84,43257699],
		["Mount Sterling","WI",43.31,-90.93,43257713],
		["Waukau","WI",44,-88.76,43257727],
		["Nett Lake","MN",48.11,-93.07,43257741],
		["Greenwald","MN",45.6,-94.85,43257755],
		["Gann Valley","SD",44.03,-98.98,43257769],
		["Stephan","SD",44.24,-99.45,43257783],
		["Rockham","SD",44.9,-98.82,43257797],
		["Keldron","SD",45.93,-101.8,43257811],
		["Lodgepole","SD",45.8,-102.66,43257825],
		["Caputa","SD",43.99,-102.98,43257839],
		["Blanchard","ND",47.34,-97.21,43257853],
		["Mcleod","ND",46.39,-97.29,43257867],
		["Egeland","ND",48.62,-99.09,43257881],
		["Penn","ND",48.22,-99.08,43257895],
		["York","ND",48.31,-99.57,43257909],
		["Jamestown","ND",46.91,-98.69,43257923],
		["Dickey","ND",46.53,-98.46,43257937],
		["Raleigh","ND",46.36,-101.3,43257951],
		["Balfour","ND",47.95,-100.53,43257965],
		["Boyd","MT",45.43,-109.06,43257979],
		["Pray","MT",45.31,-110.76,43257993],
		["Reserve","MT",48.54,-104.49,43258007],
		["Bloomfield","MT",47.44,-104.84,43258021],
		["Ismay","MT",46.22,-105.02,43258035],
		["East Lynn","IL",40.46,-87.79,43258049],
		["Troy Grove","IL",41.46,-89.08,43258063],
		["Cornland","IL",39.94,-89.4,43258077],
		["Sailor Springs","IL",38.76,-88.35,43258091],
		["Reynolds","MO",37.4,-91.06,43258105],
		["Brownwood","MO",37.07,-89.96,43258119],
		["Delta","MO",37.19,-89.73,43258133],
		["Foster","MO",38.16,-94.5,43258147],
		["Renick","MO",39.34,-92.41,43258161],
		["Santa Fe","MO",39.36,-91.83,43258175],
		["Eunice","MO",37.24,-91.77,43258189],
		["Rueter","MO",36.6,-92.86,43258203],
		["Opolis","KS",37.34,-94.62,43258217],
		["Republic","KS",39.92,-97.82,43258231],
		["Lake City","KS",37.35,-98.81,43258245],
		["Elk Falls","KS",37.37,-96.19,43258259],
		["Paradise","KS",39.11,-98.91,43258273],
		["Center","NE",42.6,-97.87,43258287],
		["Inland","NE",40.59,-98.22,43258301],
		["Empire","LA",29.41,-89.61,43258315],
		["Milton","LA",30.1,-92.07,43258329],
		["Pickens","AR",33.8,-91.38,43258343],
		["Alleene","AR",33.66,-94.14,43258357],
		["Graham","OK",34.33,-97.43,43258371],
		["Putnam","OK",35.99,-99.23,43258385],
		["Satin","TX",31.38,-97.02,43258399],
		["Nursery","TX",28.91,-97.1,43258413],
		["Weesatche","TX",28.83,-97.41,43258427],
		["Pawnee","TX",28.64,-98,43258441],
		["Catarina","TX",28.35,-99.61,43258455],
		["Farnsworth","TX",36.32,-100.96,43258469],
		["Spade","TX",33.92,-102.15,43258483],
		["El Paso","TX",31.84,-106.43,43258497],
		["Idledale","CO",39.67,-105.23,43258511],
		["Two Buttes","CO",37.56,-102.39,43258525],
		["Yellow Jacket","CO",37.53,-108.72,43258539],
		["Granite Canon","WY",41.1,-105.08,43258553],
		["Hawk Springs","WY",41.79,-104.27,43258567],
		["Lance Creek","WY",43.02,-104.64,43258581],
		["Dixon","WY",41.03,-107.53,43258595],
		["Ralston","WY",44.76,-108.9,43258609],
		["Wayan","ID",42.9,-111.28,43258623],
		["Lemhi","ID",44.81,-113.66,43258637],
		["Crown King","AZ",34.2,-112.34,43258651],
		["Fence Lake","NM",34.65,-108.67,43258665],
		["Playas","NM",32.02,-108.68,43258679],
		["Ruby Valley","NV",40.49,-115.32,43258693],
		["Death Valley","CA",36.29,-116.45,43258707],
		["Port Hueneme Cbc Base","CA",34.16,-119.2,43258721],
		["Woody","CA",35.73,-118.92,43258735],
		["Campo Seco","CA",38.23,-120.86,43258749],
		["Phillipsville","CA",40.18,-123.76,43258763],
		["Strawberry Valley","CA",39.56,-121.1,43258777],
		["Tripler Army Medical Center","HI",21.36,-157.89,43258791],
		["Arch Cape","OR",45.84,-123.86,43258805],
		["Fields","OR",42.17,-118.36,43258819],
		["Conconully","WA",48.55,-119.81,43258833],
		["Stehekin","WA",48.34,-120.7,43258847],
		["Sleetmute","AK",61.31,-157.42,43258861],
		["Central","AK",65.47,-145.19,43258875],
		["Elfin Cove","AK",58.1,-136.29,43258889],
		["Westport Point","MA",41.52,-71.07,43258902],
		["South Tamworth","NH",43.83,-71.31,43258915],
		["East Newport","ME",44.81,-69.22,43258928],
		["Oquossoc","ME",44.96,-70.77,43258941],
		["West Halifax","VT",42.76,-72.72,43258954],
		["Norton","VT",45,-71.8,43258967],
		["Harrisonville","NJ",39.73,-75.32,43258980],
		["Ocean Beach","NY",40.64,-73.15,43258993],
		["Hollowville","NY",42.2,-73.67,43259006],
		["Little York","NY",42.69,-76.16,43259019],
		["Eagle Bay","NY",43.76,-74.82,43259032],
		["Garrattsville","NY",42.64,-75.17,43259045],
		["Wellesley Island","NY",44.33,-75.9,43259058],
		["Sheridan","NY",42.47,-79.24,43259071],
		["Maple Springs","NY",42.21,-79.41,43259084],
		["Pricedale","PA",40.14,-79.81,43259097],
		["Quecreek","PA",40.04,-79.12,43259110],
		["Hillsdale","PA",40.75,-78.88,43259123],
		["Belsano","PA",40.55,-78.86,43259136],
		["Cooksburg","PA",41.33,-79.18,43259149],
		["Ashfield","PA",40.81,-75.72,43259162],
		["Jackson","PA",41.83,-75.59,43259175],
		["Gunpowder","MD",39.41,-76.38,43259188],
		["Grimstead","VA",37.5,-76.3,43259201],
		["Paint Bank","VA",37.56,-80.28,43259214],
		["Kanawha Falls","WV",38.11,-81.15,43259227],
		["Brunswick","NC",34.29,-78.7,43259240],
		["Chimney Rock","NC",35.43,-82.24,43259253],
		["Wiley","GA",34.82,-83.45,43259266],
		["Varnell","GA",34.9,-84.97,43259279],
		["Howard","GA",32.6,-84.38,43259292],
		["Oconee","GA",32.85,-82.95,43259305],
		["Wausau","FL",30.63,-85.58,43259318],
		["Myrtlewood","AL",32.24,-87.94,43259331],
		["Vance","MS",34.08,-90.3,43259344],
		["Jackson","MS",32.32,-90.2,43259357],
		["Adams","KY",38,-82.71,43259370],
		["Rowdy","KY",37.41,-83.2,43259383],
		["Cameron","OH",39.78,-80.95,43259396],
		["Reesville","OH",39.48,-83.67,43259409],
		["Alpha","OH",39.67,-83.91,43259422],
		["Miller City","OH",41.1,-84.13,43259435],
		["West Newton","IN",39.7,-86.25,43259448],
		["State Line","IN",40.17,-87.47,43259461],
		["Whittaker","MI",42.12,-83.59,43259474],
		["Omena","MI",45.06,-85.61,43259487],
		["Kearsarge","MI",47.27,-88.46,43259500],
		["Williamson","IA",41.08,-93.25,43259513],
		["Andover","IA",41.97,-90.25,43259526],
		["Lebanon","WI",43.23,-88.59,43259539],
		["Green Valley","WI",44.79,-88.26,43259552],
		["Duluth","MN",46.78,-92.11,43259565],
		["Correll","MN",45.23,-96.16,43259578],
		["Comstock","MN",46.66,-96.74,43259591],
		["Nashua","MN",46.03,-96.3,43259604],
		["Waskish","MN",48.16,-94.51,43259617],
		["Virgil","SD",44.29,-98.42,43259630],
		["Andover","SD",45.41,-97.9,43259643],
		["Houghton","SD",45.76,-98.21,43259656],
		["Onaka","SD",45.19,-99.46,43259669],
		["Wales","ND",48.89,-98.6,43259682],
		["Pekin","ND",47.79,-98.32,43259695],
		["Glenfield","ND",47.45,-98.56,43259708],
		["Flaxton","ND",48.89,-102.39,43259721],
		["Portal","ND",48.99,-102.54,43259734],
		["Ruso","ND",47.83,-100.93,43259747],
		["Antelope","MT",48.69,-104.36,43259760],
		["Alzada","MT",45.23,-104.53,43259773],
		["Eola","IL",41.77,-88.15,43259786],
		["Seward","IL",42.24,-89.33,43259799],
		["Dover","IL",41.43,-89.39,43259812],
		["Liverpool","IL",40.39,-90,43259825],
		["Eagarville","IL",39.11,-89.78,43259838],
		["Elwin","IL",39.78,-88.95,43259851],
		["Freeman Spur","IL",37.85,-88.99,43259864],
		["Treece","KS",37,-94.84,43259877],
		["Narka","KS",39.96,-97.42,43259890],
		["Brownell","KS",38.64,-99.74,43259903],
		["Clayton","KS",39.73,-100.17,43259916],
		["Whiteclay","NE",42.95,-102.59,43259929],
		["Thida","AR",35.55,-91.46,43259942],
		["Lambrook","AR",34.37,-90.96,43259955],
		["Addington","OK",34.24,-97.96,43259968],
		["Durham","OK",35.83,-99.92,43259981],
		["Rosston","OK",36.81,-99.92,43259994],
		["Adams","OK",36.75,-101.08,43260007],
		["Rentiesville","OK",35.52,-95.49,43260020],
		["Connerville","OK",34.44,-96.65,43260033],
		["Pandora","TX",29.25,-97.83,43260046],
		["Summerfield","TX",34.74,-102.51,43260059],
		["Mcadoo","TX",33.77,-100.96,43260072],
		["O Brien","TX",33.38,-99.84,43260085],
		["Big Bend National Park","TX",29.28,-103.18,43260098],
		["Bond","CO",39.87,-106.7,43260111],
		["Padroni","CO",40.77,-103.17,43260124],
		["Branson","CO",37.01,-103.87,43260137],
		["Paradox","CO",38.37,-108.95,43260150],
		["Kirby","WY",43.8,-108.18,43260163],
		["Little America","WY",41.55,-109.86,43260176],
		["Calder","ID",47.31,-116.09,43260189],
		["Lynndyl","UT",39.5,-112.48,43260202],
		["White Mountain Lake","AZ",34.32,-110.07,43260215],
		["Happy Jack","AZ",34.73,-111.15,43260228],
		["Regina","NM",36.18,-106.95,43260241],
		["Carson","NM",36.69,-105.83,43260254],
		["Mosquero","NM",35.77,-103.95,43260267],
		["San Acacia","NM",34.25,-106.89,43260280],
		["Cal Nev Ari","NV",35.24,-114.87,43260293],
		["Tuscarora","NV",41.45,-116.27,43260306],
		["Tecopa","CA",35.85,-116.22,43260319],
		["Navarro","CA",39.21,-123.54,43260332],
		["Clio","CA",39.72,-120.57,43260345],
		["Eagleville","CA",41.3,-120.1,43260358],
		["Sierraville","CA",39.57,-120.34,43260371],
		["Topaz","CA",38.64,-119.48,43260384],
		["Paulina","OR",44.11,-119.79,43260397],
		["Cougar","WA",46.08,-122.28,43260410],
		["Wahkiacus","WA",45.85,-121.14,43260423],
		["Anvik","AK",62.63,-160.2,43260436],
		["Clarks Point","AK",58.83,-158.53,43260449],
		["Levelock","AK",58.95,-156.48,43260462],
		["Pilot Point","AK",57.83,-157.13,43260475],
		["South Naknek","AK",58.67,-156.98,43260488],
		["Koyukuk","AK",65.04,-158.66,43260501],
		["Hyannis Port","MA",41.64,-70.3,43260513],
		["East Dixfield","ME",44.57,-70.3,43260525],
		["Islesford","ME",44.3,-68.27,43260537],
		["Barnard","VT",43.73,-72.62,43260549],
		["Williamsville","VT",42.94,-72.65,43260561],
		["East Berkshire","VT",44.92,-72.7,43260573],
		["Richwood","NJ",39.71,-75.23,43260585],
		["Cape May Point","NJ",38.93,-74.96,43260597],
		["Aquebogue","NY",40.93,-72.61,43260609],
		["Lanesville","NY",42.15,-74.2,43260621],
		["Trout Creek","NY",42.2,-75.27,43260633],
		["Perkinsville","NY",42.53,-77.62,43260645],
		["Scottsburg","NY",42.65,-77.68,43260657],
		["Lily Dale","NY",42.35,-79.34,43260669],
		["Coulters","PA",40.3,-79.79,43260681],
		["Wickhaven","PA",40.11,-79.75,43260693],
		["Hidden Valley","PA",40.04,-79.11,43260705],
		["Oliveburg","PA",40.99,-79.03,43260717],
		["Benezett","PA",41.31,-78.33,43260729],
		["Seminole","PA",40.96,-79.33,43260741],
		["Sproul","PA",40.32,-78.5,43260753],
		["Williamson","PA",39.85,-77.79,43260765],
		["Saint Georges","DE",39.57,-75.63,43260777],
		["Jamesville","VA",37.51,-75.93,43260789],
		["Amonate","VA",37.18,-81.65,43260801],
		["Elbert","WV",37.33,-81.52,43260813],
		["Saxon","WV",37.78,-81.43,43260825],
		["Kanawha Head","WV",38.76,-80.37,43260837],
		["Bergoo","WV",38.48,-80.24,43260849],
		["Daisy","GA",32.15,-81.83,43260861],
		["Lanark Village","FL",29.88,-84.59,43260873],
		["Cypress","FL",30.71,-85.07,43260885],
		["Coalgood","KY",36.8,-83.23,43260897],
		["Grahn","KY",38.3,-83.09,43260909],
		["Dundee","KY",37.55,-86.78,43260921],
		["Mannsville","KY",37.36,-85.2,43260933],
		["Milledgeville","OH",39.59,-83.58,43260945],
		["Gypsum","OH",41.5,-82.94,43260957],
		["Lacarne","OH",41.53,-83.02,43260969],
		["Moxahala","OH",39.65,-82.12,43260981],
		["Plainfield","OH",40.2,-81.71,43260993],
		["Fairpoint","OH",40.13,-80.96,43261005],
		["Zoar","OH",40.61,-81.42,43261017],
		["Old Fort","OH",41.21,-83.11,43261029],
		["Onward","IN",40.69,-86.19,43261041],
		["Napoleon","IN",39.2,-85.32,43261053],
		["Harrodsburg","IN",39.09,-86.47,43261065],
		["Evansville","IN",37.98,-87.54,43261077],
		["Carpenter","IA",43.41,-93.01,43261089],
		["Burnside","IA",42.33,-94.11,43261101],
		["Chatsworth","IA",42.91,-96.51,43261113],
		["Lanesboro","IA",42.18,-94.69,43261125],
		["Ralston","IA",42.04,-94.62,43261137],
		["Selma","IA",40.86,-92.15,43261149],
		["Marquette","WI",43.74,-89.13,43261161],
		["Pigeon Falls","WI",44.42,-91.2,43261173],
		["Crane Lake","MN",48.26,-92.48,43261185],
		["Hope","MN",43.97,-93.34,43261197],
		["Huntley","MN",43.71,-94.18,43261209],
		["Benedict","MN",47.15,-94.69,43261221],
		["Saint Vincent","MN",48.97,-97.22,43261233],
		["Barnard","SD",45.73,-98.49,43261245],
		["Pringle","SD",43.6,-103.59,43261257],
		["Abercrombie","ND",46.44,-96.72,43261269],
		["Ayr","ND",47.04,-97.49,43261281],
		["Amidon","ND",46.48,-103.31,43261293],
		["Bearcreek","MT",45.13,-109.15,43261305],
		["Musselshell","MT",46.42,-108.05,43261317],
		["Sand Springs","MT",47.14,-107.59,43261329],
		["Coffee Creek","MT",47.39,-110.02,43261341],
		["Zortman","MT",47.78,-108.53,43261353],
		["Virginia City","MT",45.28,-111.91,43261365],
		["Claytonville","IL",40.53,-87.85,43261377],
		["Fiatt","IL",40.56,-90.18,43261389],
		["Barnhill","IL",38.27,-88.38,43261401],
		["Millcreek","IL",37.35,-89.26,43261413],
		["Luebbering","MO",38.26,-90.79,43261425],
		["Emden","MO",39.79,-91.86,43261437],
		["Lentner","MO",39.72,-92.14,43261449],
		["Commerce","MO",37.15,-89.44,43261461],
		["Kelso","MO",37.19,-89.55,43261473],
		["Brinktown","MO",38.12,-92.07,43261485],
		["Brixey","MO",36.75,-92.4,43261497],
		["Garrison","MO",36.83,-93.01,43261509],
		["Randall","KS",39.64,-98.04,43261521],
		["Westerville","NE",41.39,-99.39,43261533],
		["Marsland","NE",42.44,-103.3,43261545],
		["Datto","AR",36.39,-90.72,43261557],
		["Portia","AR",36.08,-91.07,43261569],
		["Cushman","AR",35.86,-91.77,43261581],
		["Magness","AR",35.7,-91.48,43261593],
		["Blue Mountain","AR",35.13,-93.71,43261605],
		["Hollister","OK",34.34,-98.87,43261617],
		["Omega","OK",35.86,-98.19,43261629],
		["Lequire","OK",35.1,-95.1,43261641],
		["Kirvin","TX",31.76,-96.32,43261653],
		["Latexo","TX",31.38,-95.47,43261665],
		["Voss","TX",31.62,-99.56,43261677],
		["Ace","TX",30.54,-94.79,43261689],
		["Altair","TX",29.57,-96.48,43261701],
		["Egypt","TX",29.39,-96.21,43261713],
		["Nada","TX",29.42,-96.39,43261725],
		["Normanna","TX",28.53,-97.79,43261737],
		["Vanderpool","TX",29.73,-99.55,43261749],
		["Nolan","TX",32.27,-100.24,43261761],
		["Ophir","CO",37.85,-107.82,43261773],
		["Springfield","ID",43.07,-112.67,43261785],
		["Clarkia","ID",47.02,-116.24,43261797],
		["Medimont","ID",47.44,-116.47,43261809],
		["Kenilworth","UT",39.69,-110.8,43261821],
		["Aragon","NM",33.88,-108.53,43261833],
		["Monticello","NM",33.39,-107.45,43261845],
		["Hachita","NM",31.91,-108.32,43261857],
		["Hillsboro","NM",32.92,-107.56,43261869],
		["Sloan","NV",35.93,-115.22,43261881],
		["Dillon Beach","CA",38.24,-122.95,43261893],
		["Coyote","CA",37.2,-121.72,43261905],
		["Platina","CA",40.38,-122.91,43261917],
		["Wendel","CA",40.31,-120.2,43261929],
		["Oceanside","OR",45.45,-123.96,43261941],
		["Meacham","OR",45.52,-118.44,43261953],
		["Unity","OR",44.47,-118.22,43261965],
		["Clearlake","WA",48.46,-122.23,43261977],
		["Atka","AK",52.23,-174.16,43261989],
		["Stevens Village","AK",66.01,-149.07,43262001],
		["West Hyannisport","MA",41.66,-70.33,43262012],
		["Elkins","NH",43.4,-71.94,43262023],
		["Drewsville","NH",43.12,-72.39,43262034],
		["North Vassalboro","ME",44.48,-69.56,43262045],
		["South Seaville","NJ",39.17,-74.77,43262056],
		["Hoosick","NY",42.88,-73.34,43262067],
		["Auburn","NY",42.93,-76.56,43262078],
		["Ouaquaga","NY",42.12,-75.64,43262089],
		["West Davenport","NY",42.43,-74.97,43262100],
		["Dale","NY",42.82,-78.17,43262111],
		["Rose","NY",43.15,-76.9,43262122],
		["De Lancey","PA",40.98,-78.97,43262133],
		["Seanor","PA",40.22,-78.89,43262144],
		["Hydetown","PA",41.65,-79.72,43262155],
		["Potts Grove","PA",40.99,-76.79,43262166],
		["Hamlin","PA",41.4,-75.39,43262177],
		["Kenton","DE",39.22,-75.66,43262188],
		["Washington","DC",38.89,-77.03,43262199],
		["Crapo","MD",38.32,-76.12,43262210],
		["Braddock Heights","MD",39.41,-77.5,43262221],
		["Burkittsville","MD",39.39,-77.62,43262232],
		["Ophelia","VA",37.9,-76.28,43262243],
		["Oakpark","VA",38.36,-78.16,43262254],
		["Harborton","VA",37.66,-75.84,43262265],
		["Alloy","WV",38.13,-81.24,43262276],
		["Turtle Creek","WV",38.04,-81.86,43262287],
		["New Manchester","WV",40.57,-80.55,43262298],
		["Corolla","NC",36.37,-75.83,43262309],
		["Norman","NC",35.17,-79.72,43262320],
		["Fingerville","SC",35.13,-82,43262331],
		["Williams","SC",33.03,-80.84,43262342],
		["Lowndesville","SC",34.21,-82.64,43262353],
		["Manassas","GA",32.16,-82.02,43262364],
		["Raiford","FL",30.06,-82.24,43262375],
		["Glen Allen","AL",33.88,-87.74,43262386],
		["Mc Williams","AL",31.84,-87.08,43262397],
		["Eagan","TN",36.55,-83.94,43262408],
		["Fairfield","KY",37.93,-85.38,43262419],
		["Jonancy","KY",37.31,-82.58,43262430],
		["Sizerock","KY",37.22,-83.5,43262441],
		["Rocky Hill","KY",37.06,-86.1,43262452],
		["Derby","OH",39.73,-83.16,43262463],
		["Grelton","OH",41.3,-84.05,43262474],
		["East Springfield","OH",40.46,-80.8,43262485],
		["Delong","IN",41.14,-86.42,43262496],
		["New Waverly","IN",40.77,-86.19,43262507],
		["Avoca","IN",38.91,-86.59,43262518],
		["Mayfield","MI",44.55,-85.63,43262529],
		["Perronville","MI",45.87,-87.48,43262540],
		["Colwell","IA",43.15,-92.59,43262551],
		["Cornucopia","WI",46.79,-91.1,43262562],
		["Beaver Bay","MN",47.25,-91.29,43262573],
		["Brimson","MN",47.27,-91.86,43262584],
		["Conger","MN",43.61,-93.52,43262595],
		["La Salle","MN",44.07,-94.57,43262606],
		["Spring Lake","MN",47.64,-93.86,43262617],
		["Grenville","SD",45.46,-97.38,43262628],
		["Amherst","SD",45.73,-97.92,43262639],
		["Lebanon","SD",45.06,-99.76,43262650],
		["Hayes","SD",44.37,-101.02,43262661],
		["Reva","SD",45.54,-103.08,43262672],
		["Hannah","ND",48.97,-98.69,43262683],
		["Kramer","ND",48.69,-100.7,43262694],
		["Peerless","MT",48.73,-105.84,43262705],
		["Buffalo","MT",46.8,-109.68,43262716],
		["Pony","MT",45.63,-111.9,43262727],
		["New Bedford","IL",41.51,-89.71,43262738],
		["Gerlaw","IL",40.98,-90.6,43262749],
		["Michael","IL",39.23,-90.62,43262760],
		["Elvaston","IL",40.39,-91.24,43262771],
		["Bluff Springs","IL",39.98,-90.37,43262782],
		["Dale","IL",37.99,-88.54,43262793],
		["Golden Gate","IL",38.35,-88.2,43262804],
		["Saverton","MO",39.64,-91.26,43262815],
		["Vulcan","MO",37.3,-90.66,43262826],
		["Braggadocio","MO",36.17,-89.82,43262837],
		["Sumner","MO",39.65,-93.24,43262848],
		["Beulah","MO",37.62,-91.91,43262859],
		["Chautauqua","KS",37.02,-96.17,43262870],
		["Alexander","KS",38.46,-99.55,43262881],
		["Beeler","KS",38.43,-100.2,43262892],
		["Liebenthal","KS",38.65,-99.32,43262903],
		["Goehner","NE",40.83,-97.21,43262914],
		["Hubbell","NE",40,-97.49,43262925],
		["Maskell","NE",42.69,-96.98,43262936],
		["Lisbon","LA",32.78,-92.86,43262947],
		["Wooster","AR",35.19,-92.44,43262958],
		["Mellwood","AR",34.19,-91.03,43262969],
		["Ponca","AR",36.06,-93.4,43262980],
		["Greenfield","OK",35.72,-98.37,43262991],
		["Amorita","OK",36.92,-98.29,43263002],
		["Milfay","OK",35.75,-96.57,43263013],
		["Cayuga","TX",31.95,-95.97,43263024],
		["Whitt","TX",32.96,-98.02,43263035],
		["Reagan","TX",31.23,-96.75,43263046],
		["Millersview","TX",31.4,-99.75,43263057],
		["Pontotoc","TX",30.9,-98.97,43263068],
		["Tennyson","TX",31.74,-100.28,43263079],
		["San Felipe","TX",29.79,-96.1,43263090],
		["Austwell","TX",28.39,-96.84,43263101],
		["Whitsett","TX",28.62,-98.27,43263112],
		["Hye","TX",30.22,-98.53,43263123],
		["Wrightsboro","TX",29.35,-97.5,43263134],
		["Justiceburg","TX",33.04,-101.2,43263145],
		["Old Glory","TX",33.12,-100.05,43263156],
		["Sylvester","TX",32.72,-100.25,43263167],
		["Meredith","CO",39.27,-106.59,43263178],
		["Bairoil","WY",42.23,-107.55,43263189],
		["Point Of Rocks","WY",41.68,-108.78,43263200],
		["Holbrook","ID",42.2,-112.67,43263211],
		["Grouse Creek","UT",41.68,-113.83,43263222],
		["Pine Valley","UT",37.47,-113.51,43263233],
		["Palomar Mountain","CA",33.31,-116.84,43263244],
		["Essex","CA",34.96,-115.31,43263255],
		["Birds Landing","CA",38.12,-121.81,43263266],
		["Scott Bar","CA",41.73,-123.04,43263277],
		["Adel","OR",42.1,-119.85,43263288],
		["Diamond","OR",42.91,-118.65,43263299],
		["Oxbow","OR",44.99,-116.87,43263310],
		["Jamieson","OR",44.24,-117.43,43263321],
		["Conway","WA",48.33,-122.34,43263332],
		["Methow","WA",48.14,-120.05,43263343],
		["Chignik Lagoon","AK",56.3,-158.49,43263354],
		["Tenakee Springs","AK",57.83,-135.13,43263365],
		["Point Baker","AK",56.3,-133.57,43263376],
		["Kenyon","RI",41.48,-71.68,43263386],
		["Farmington Falls","ME",44.62,-70.07,43263396],
		["Hanover","CT",41.62,-72.07,43263406],
		["Elwood","NJ",39.6,-74.68,43263416],
		["Thompsonville","NY",41.66,-74.62,43263426],
		["Mc Lean","NY",42.55,-76.29,43263436],
		["New Lisbon","NY",42.58,-75.18,43263446],
		["Van Voorhis","PA",40.13,-79.94,43263456],
		["East Hickory","PA",41.56,-79.37,43263466],
		["New Buffalo","PA",40.45,-76.97,43263476],
		["New Germantown","PA",40.27,-77.59,43263486],
		["Westport","PA",41.3,-77.84,43263496],
		["Rowland","PA",41.47,-75.04,43263506],
		["Naval Anacost Annex","DC",38.89,-77.03,43263516],
		["Ingleside","MD",39.12,-75.89,43263526],
		["Perry Point","MD",39.6,-75.95,43263536],
		["Capeville","VA",37.2,-75.95,43263546],
		["Roanoke","VA",37.27,-79.95,43263556],
		["Davenport","VA",37.1,-82.13,43263566],
		["Head Waters","VA",38.3,-79.42,43263576],
		["Simpson","WV",39.28,-80.06,43263586],
		["Hassell","NC",35.9,-77.27,43263596],
		["Parmele","NC",35.81,-77.31,43263606],
		["Proctorville","NC",34.47,-79.03,43263616],
		["Mayo","SC",35.08,-81.85,43263626],
		["Newry","SC",34.73,-82.91,43263636],
		["Vaucluse","SC",33.56,-81.81,43263646],
		["Turin","GA",33.32,-84.63,43263656],
		["Offerman","GA",31.41,-82.11,43263666],
		["Pensacola","FL",30.44,-87.18,43263676],
		["Otter Creek","FL",29.32,-82.77,43263686],
		["Huxford","AL",31.21,-87.46,43263696],
		["Samburg","TN",36.38,-89.35,43263706],
		["Lowes","KY",36.88,-88.77,43263716],
		["Blakeslee","OH",41.52,-84.73,43263726],
		["Lafferty","OH",40.07,-81.06,43263736],
		["Deersville","OH",40.3,-81.18,43263746],
		["Fowlerton","IN",40.4,-85.57,43263756],
		["Ora","IN",41.21,-86.52,43263766],
		["Ingalls","MI",45.38,-87.61,43263776],
		["Sidnaw","MI",46.5,-88.7,43263786],
		["Plover","IA",42.87,-94.62,43263796],
		["Benton","IA",40.7,-94.35,43263806],
		["Cromwell","IA",41.04,-94.46,43263816],
		["Fairfield","IA",41,-91.96,43263826],
		["Hertel","WI",45.8,-92.17,43263836],
		["Wanda","MN",44.31,-95.21,43263846],
		["Tintah","MN",46.01,-96.32,43263856],
		["Lyons","SD",43.71,-96.77,43263866],
		["Erwin","SD",44.48,-97.44,43263876],
		["Long Valley","SD",43.46,-101.49,43263886],
		["Sarles","ND",48.94,-98.99,43263896],
		["Roscoe","MT",45.29,-109.56,43263906],
		["Brockway","MT",47.07,-105.86,43263916],
		["Richland","MT",48.77,-106.11,43263926],
		["Forest Grove","MT",46.92,-109.07,43263936],
		["Sweet Grass","MT",48.94,-111.52,43263946],
		["Loring","MT",48.82,-108,43263956],
		["Warm Springs","MT",46.16,-112.83,43263966],
		["Essex","MT",48.31,-113.35,43263976],
		["Union Hill","IL",41.1,-88.14,43263986],
		["Burnt Prairie","IL",38.25,-88.25,43263996],
		["Stanton","MO",38.27,-91.1,43264006],
		["Duke","MO",37.65,-91.98,43264016],
		["Oneida","KS",39.86,-95.93,43264026],
		["Greenwich","KS",37.74,-97.24,43264036],
		["Zenda","KS",37.44,-98.28,43264046],
		["Englewood","KS",37.03,-99.98,43264056],
		["Strang","NE",40.41,-97.58,43264066],
		["Lincoln","NE",40.81,-96.68,43264076],
		["Inavale","NE",40.09,-98.63,43264086],
		["Enders","NE",40.44,-101.52,43264096],
		["Clarkedale","AR",35.32,-90.24,43264106],
		["Etowah","AR",35.71,-90.19,43264116],
		["Bexar","AR",36.3,-91.97,43264126],
		["Rufe","OK",34.12,-95.15,43264136],
		["Brandon","TX",32.05,-96.96,43264146],
		["Art","TX",30.74,-99.1,43264156],
		["Norton","TX",31.87,-100.12,43264166],
		["Steamboat Springs","CO",40.47,-106.82,43264176],
		["Steamboat Springs","CO",40.47,-106.82,43264186],
		["Niwot","CO",40.09,-105.15,43264196],
		["Lindon","CO",39.73,-103.4,43264206],
		["Cimarron","CO",38.37,-107.5,43264216],
		["Gateway","CO",38.68,-108.99,43264226],
		["Bosler","WY",41.58,-105.69,43264236],
		["Hartville","WY",42.32,-104.72,43264246],
		["Weston","WY",44.63,-105.33,43264256],
		["Swanlake","ID",42.32,-111.97,43264266],
		["Nordman","ID",48.67,-116.88,43264276],
		["Lukeville","AZ",32,-112.75,43264286],
		["La Joya","NM",34.34,-106.84,43264296],
		["Ojo Feliz","NM",36.05,-105.11,43264306],
		["Marshall","CA",38.16,-122.84,43264316],
		["Yorkville","CA",38.88,-123.29,43264326],
		["Honeydew","CA",40.25,-124.13,43264336],
		["Canyon Dam","CA",40.15,-121.12,43264346],
		["Flournoy","CA",39.93,-122.48,43264356],
		["Floriston","CA",39.39,-120.02,43264366],
		["Lake City","CA",41.71,-120.23,43264376],
		["Termo","CA",40.94,-120.64,43264386],
		["Idanha","OR",44.64,-122.01,43264396],
		["Saint Benedict","OR",45.05,-122.78,43264406],
		["Durkee","OR",44.6,-117.37,43264416],
		["Galvin","WA",46.74,-123.02,43264426],
		["Ardenvoir","WA",47.72,-120.36,43264436],
		["Egegik","AK",58.37,-156.8,43264446],
		["Platinum","AK",58.96,-161.74,43264456],
		["Dutch Harbor","AK",53.89,-166.53,43264466],
		["Hughes","AK",66.05,-154.24,43264476],
		["Drury","MA",42.68,-72.98,43264485],
		["Humarock","MA",42.19,-70.76,43264494],
		["South Wellfleet","MA",41.89,-69.98,43264503],
		["Glencliff","NH",43.98,-71.9,43264512],
		["Bretton Woods","NH",44.34,-71.55,43264521],
		["Corea","ME",44.41,-67.99,43264530],
		["Fairton","NJ",39.37,-75.21,43264539],
		["Alcove","NY",42.45,-74.03,43264548],
		["Spencertown","NY",42.3,-73.49,43264557],
		["Silver Bay","NY",43.7,-73.51,43264566],
		["Franklin Springs","NY",43.03,-75.39,43264575],
		["Knoxboro","NY",42.98,-75.51,43264584],
		["Killawog","NY",42.4,-76.02,43264593],
		["Salina","PA",40.53,-79.56,43264602],
		["Gipsy","PA",40.8,-78.88,43264611],
		["Kent","PA",40.53,-79.28,43264620],
		["Sinnamahoning","PA",41.38,-78.05,43264629],
		["Dilltown","PA",40.47,-78.99,43264638],
		["Glen Hope","PA",40.79,-78.49,43264647],
		["Genesee","PA",41.98,-77.9,43264656],
		["Quentin","PA",40.29,-76.43,43264665],
		["Fleetville","PA",41.59,-75.71,43264674],
		["Washington","DC",38.89,-77.03,43264683],
		["Buckeystown","MD",39.33,-77.43,43264692],
		["Tuscarora","MD",39.26,-77.5,43264701],
		["Battery Park","VA",36.96,-76.6,43264710],
		["London","WV",38.36,-81.33,43264719],
		["Raleigh","WV",37.75,-81.16,43264728],
		["Munday","WV",39,-81.22,43264737],
		["Whitmer","WV",38.76,-79.6,43264746],
		["Waves","NC",35.55,-75.46,43264755],
		["Mc Farlan","NC",34.81,-79.97,43264764],
		["Williston","NC",34.8,-76.49,43264773],
		["Columbia","SC",34,-81.03,43264782],
		["Reidville","SC",34.86,-82.11,43264791],
		["Parksville","SC",33.78,-82.21,43264800],
		["Lakeshore","FL",27.87,-81.47,43264809],
		["Aripeka","FL",28.43,-82.66,43264818],
		["Derwent","OH",39.93,-81.56,43264827],
		["Fairview","OH",40.05,-81.23,43264836],
		["Wayland","OH",41.17,-81.15,43264845],
		["Chatfield","OH",40.95,-82.94,43264854],
		["Marathon","OH",39.13,-84.05,43264863],
		["Bourneville","OH",39.27,-83.15,43264872],
		["Eminence","IN",39.46,-86.46,43264881],
		["Bridgeton","IN",39.65,-87.18,43264890],
		["Forestville","MI",43.66,-82.61,43264899],
		["Galt","IA",42.69,-93.6,43264908],
		["Garden City","IA",42.25,-93.4,43264917],
		["Rock Falls","IA",43.2,-93.08,43264926],
		["Varina","IA",42.65,-94.89,43264935],
		["Westphalia","IA",41.71,-95.39,43264944],
		["Northboro","IA",40.6,-95.29,43264953],
		["Yorktown","IA",40.73,-95.15,43264962],
		["Hingham","WI",43.75,-87.86,43264971],
		["Woodford","WI",42.64,-89.92,43264980],
		["Isabella","MN",47.61,-91.35,43264989],
		["Bock","MN",45.78,-93.55,43264998],
		["Humboldt","MN",48.92,-97.09,43265007],
		["Badger","SD",44.48,-97.2,43265016],
		["Brentford","SD",45.15,-98.32,43265025],
		["Agar","SD",44.83,-100.07,43265034],
		["Fairdale","ND",48.48,-98.23,43265043],
		["Hamilton","ND",48.8,-97.45,43265052],
		["Alsen","ND",48.62,-98.7,43265061],
		["Perth","ND",48.71,-99.45,43265070],
		["Pettibone","ND",47.11,-99.52,43265079],
		["Homestead","MT",48.4,-104.6,43265088],
		["Butte","MT",45.99,-112.55,43265097],
		["Melrose","MT",45.63,-112.64,43265106],
		["Milmine","IL",39.89,-88.64,43265115],
		["Kimmswick","MO",38.36,-90.36,43265124],
		["Annada","MO",39.26,-90.82,43265133],
		["Whiteside","MO",39.18,-91.01,43265142],
		["Perkins","MO",37.09,-89.77,43265151],
		["Gibson","MO",36.45,-90,43265160],
		["Talmage","KS",39.02,-97.26,43265169],
		["Belvidere","NE",40.25,-97.55,43265178],
		["Moorefield","NE",40.68,-100.4,43265187],
		["Moscow","AR",34.15,-91.79,43265196],
		["Ben Lomond","AR",33.83,-94.11,43265205],
		["Wiseman","AR",36.22,-91.85,43265214],
		["Briggsville","AR",34.9,-93.51,43265223],
		["Kiamichi Christian Mission","OK",34.53,-94.95,43265232],
		["Sacul","TX",31.81,-94.9,43265241],
		["Castell","TX",30.7,-98.5,43265250],
		["Voca","TX",31.01,-99.18,43265259],
		["Dawn","TX",34.91,-102.19,43265268],
		["Lelia Lake","TX",34.9,-100.77,43265277],
		["Toyah","TX",31.31,-103.79,43265286],
		["Glen Haven","CO",40.45,-105.43,43265295],
		["Hartman","CO",38.12,-102.21,43265304],
		["Chromo","CO",37.03,-106.83,43265313],
		["Jelm","WY",41.05,-106,43265322],
		["Stanley","ID",44.26,-114.97,43265331],
		["Echo","UT",41,-111.44,43265340],
		["Brian Head","UT",37.64,-112.8,43265349],
		["Greenwich","UT",38.45,-111.9,43265358],
		["Rillito","AZ",32.41,-111.16,43265367],
		["Greer","AZ",34,-109.48,43265376],
		["Woodruff","AZ",34.8,-109.94,43265385],
		["Bard","NM",35.12,-103.3,43265394],
		["Conchas Dam","NM",35.34,-103.98,43265403],
		["Nipton","CA",35.49,-115.45,43265412],
		["Fish Camp","CA",37.47,-119.66,43265421],
		["Olema","CA",38.02,-122.75,43265430],
		["Emigrant Gap","CA",39.25,-120.69,43265439],
		["Kyburz","CA",38.77,-120.26,43265448],
		["Madeline","CA",41.05,-120.49,43265457],
		["Wilbur","OR",43.32,-123.34,43265466],
		["Ashwood","OR",44.7,-120.72,43265475],
		["Lyman","WA",48.52,-122.06,43265484],
		["Waverly","WA",47.31,-117.24,43265493],
		["Starbuck","WA",46.55,-118.19,43265502],
		["Larsen Bay","AK",57.48,-153.99,43265511],
		["Pedro Bay","AK",59.86,-154.03,43265520],
		["Hyder","AK",55.97,-130.04,43265529],
		["Boston","MA",42.35,-71.06,43265537],
		["Guild","NH",43.37,-72.14,43265545],
		["Cliff Island","ME",43.69,-70.1,43265553],
		["Greenville Junction","ME",45.47,-69.69,43265561],
		["Isle Au Haut","ME",44.04,-68.62,43265569],
		["Cambridgeport","VT",43.15,-72.56,43265577],
		["Sheldon Springs","VT",44.89,-72.98,43265585],
		["Fortescue","NJ",39.22,-75.13,43265593],
		["Highmount","NY",42.16,-74.45,43265601],
		["Childwold","NY",44.28,-74.67,43265609],
		["Tioga Center","NY",42.05,-76.34,43265617],
		["West Bloomfield","NY",42.89,-77.52,43265625],
		["Wendel","PA",40.34,-79.53,43265633],
		["Sprankle Mills","PA",41.02,-79.08,43265641],
		["Entriken","PA",40.34,-78.18,43265649],
		["Swengel","PA",40.94,-77.07,43265657],
		["Ebervale","PA",40.98,-75.94,43265665],
		["Milford Square","PA",40.43,-75.39,43265673],
		["Monocacy Station","PA",40.26,-75.74,43265681],
		["Virginville","PA",40.46,-75.81,43265689],
		["Montchanin","DE",39.79,-75.54,43265697],
		["Claiborne","MD",38.82,-76.28,43265705],
		["Wingate","MD",38.28,-76.08,43265713],
		["Mears","VA",37.87,-75.64,43265721],
		["Nelsonia","VA",37.81,-75.58,43265729],
		["Doe Hill","VA",38.43,-79.44,43265737],
		["Crystal Hill","VA",36.85,-78.9,43265745],
		["Pinch","WV",38.4,-81.47,43265753],
		["Speed","NC",35.96,-77.44,43265761],
		["Mineral Springs","NC",34.93,-80.72,43265769],
		["Coosawhatchie","SC",32.58,-80.92,43265777],
		["Alston","GA",32.08,-82.47,43265785],
		["Camak","GA",33.45,-82.64,43265793],
		["Goodland","FL",25.92,-81.66,43265801],
		["Mooresville","AL",34.62,-86.88,43265809],
		["Bogota","TN",36.15,-89.49,43265817],
		["Star","MS",32.12,-90.13,43265825],
		["Valley Park","MS",32.57,-90.86,43265833],
		["Sasser","GA",31.71,-84.34,43265841],
		["Port Royal","KY",38.55,-85.08,43265849],
		["Zoe","KY",37.68,-83.68,43265857],
		["Avawam","KY",37.22,-83.27,43265865],
		["Beech Grove","KY",37.53,-87.25,43265873],
		["Randolph","OH",41.03,-81.24,43265881],
		["Indianapolis","IN",39.77,-86.14,43265889],
		["Houghton Lake Heights","MI",44.27,-84.76,43265897],
		["Lake George","MI",43.96,-84.94,43265905],
		["Copper Harbor","MI",47.46,-87.81,43265913],
		["Ferguson","IA",41.93,-92.86,43265921],
		["Sioux City","IA",42.5,-96.39,43265929],
		["Middle Amana","IA",41.8,-91.9,43265937],
		["Upson","WI",46.36,-90.4,43265945],
		["Swan River","MN",47.08,-93.19,43265953],
		["Ranier","MN",48.61,-93.34,43265961],
		["Long Lake","SD",45.77,-99.25,43265969],
		["Glencross","SD",45.43,-100.88,43265977],
		["Fort Meade","SD",44.41,-103.47,43265985],
		["Cayuga","ND",46.07,-97.38,43265993],
		["Braddock","ND",46.56,-100.08,43266001],
		["Lefor","ND",46.68,-102.55,43266009],
		["Bighorn","MT",46.01,-107.31,43266017],
		["Cohagen","MT",47.09,-106.62,43266025],
		["Dupuyer","MT",48.19,-112.7,43266033],
		["Oilmont","MT",48.8,-111.59,43266041],
		["Cameron","MT",45.03,-111.63,43266049],
		["De Borgia","MT",47.39,-115.32,43266057],
		["Woosung","IL",41.91,-89.57,43266065],
		["Lee Center","IL",41.71,-89.22,43266073],
		["Bishop Hill","IL",41.19,-90.11,43266081],
		["Raritan","IL",40.69,-90.82,43266089],
		["Rome","IL",40.87,-89.51,43266097],
		["Lake Fork","IL",39.93,-89.19,43266105],
		["Dutchtown","MO",37.25,-89.65,43266113],
		["Uniontown","MO",37.61,-89.71,43266121],
		["Mount Sterling","MO",38.46,-91.61,43266129],
		["Noble","MO",36.74,-92.56,43266137],
		["Matfield Green","KS",38.15,-96.56,43266145],
		["Beaumont","KS",37.65,-96.55,43266153],
		["Sycamore","KS",37.33,-95.72,43266161],
		["Gilead","NE",40.14,-97.41,43266169],
		["Julian","NE",40.41,-95.99,43266177],
		["Mcgrew","NE",41.74,-103.41,43266185],
		["Beedeville","AR",35.42,-91.1,43266193],
		["Driver","AR",35.54,-89.95,43266201],
		["Frenchmans Bayou","AR",35.46,-90.05,43266209],
		["Dolph","AR",36.22,-92.1,43266217],
		["Countyline","OK",34.45,-97.57,43266225],
		["Crawford","OK",35.83,-99.8,43266233],
		["Tulsa","OK",36.12,-95.91,43266241],
		["Gatesville","TX",31.44,-97.73,43266249],
		["Roosevelt","TX",30.49,-100.05,43266257],
		["Eola","TX",31.39,-100.08,43266265],
		["Knickerbocker","TX",31.26,-100.62,43266273],
		["Houston","TX",29.76,-95.38,43266281],
		["Calliham","TX",28.46,-98.35,43266289],
		["Enochs","TX",33.87,-102.75,43266297],
		["Goldsboro","TX",32.06,-99.68,43266305],
		["Shawnee","CO",39.42,-105.55,43266313],
		["Jaroso","CO",37,-105.63,43266321],
		["Parlin","CO",38.5,-106.72,43266329],
		["Egnar","CO",37.92,-108.93,43266337],
		["Hamilton","CO",40.37,-107.6,43266345],
		["Alcova","WY",42.55,-106.72,43266353],
		["Clayton","ID",44.16,-114.49,43266361],
		["Elk River","ID",46.77,-116.19,43266369],
		["Solano","NM",35.84,-104.06,43266377],
		["Mule Creek","NM",33.12,-108.95,43266385],
		["Broadview","NM",34.81,-103.21,43266393],
		["Lakewood","NM",32.61,-104.35,43266401],
		["Sunspot","NM",32.8,-105.78,43266409],
		["Weed","NM",32.8,-105.51,43266417],
		["Silverpeak","NV",37.76,-117.65,43266425],
		["Mina","NV",38.19,-118.18,43266433],
		["Bard","CA",32.79,-114.53,43266441],
		["Forks Of Salmon","CA",41.19,-123.2,43266449],
		["Davis Creek","CA",41.75,-120.4,43266457],
		["Cascadia","OR",44.44,-122.36,43266465],
		["Frenchglen","OR",42.72,-119.12,43266473],
		["Port Gamble","WA",47.85,-122.58,43266481],
		["Benge","WA",46.88,-118.15,43266489],
		["Hooper","WA",46.75,-118.14,43266497],
		["Cooper Landing","AK",60.49,-149.82,43266505],
		["Denali National Park","AK",63.64,-148.81,43266513],
		["Paris","ME",44.26,-70.49,43266520],
		["East Orland","ME",44.58,-68.66,43266527],
		["Chamberlain","ME",43.88,-69.49,43266534],
		["Grand Lake Stream","ME",45.17,-67.77,43266541],
		["Harborside","ME",44.33,-68.79,43266548],
		["West Farmington","ME",44.63,-70.16,43266555],
		["Teterboro","NJ",40.85,-74.06,43266562],
		["Strathmere","NJ",39.19,-74.66,43266569],
		["Upton","NY",40.86,-72.87,43266576],
		["Fair Haven","NY",43.32,-76.7,43266583],
		["Hoffmeister","NY",43.4,-74.72,43266590],
		["Fishers Landing","NY",44.32,-75.97,43266597],
		["Henderson Harbor","NY",43.86,-76.14,43266604],
		["Silver Lake","NY",42.7,-78.01,43266611],
		["Calumet","PA",40.2,-79.5,43266618],
		["Chandlers Valley","PA",41.95,-79.3,43266625],
		["Tylersburg","PA",41.39,-79.32,43266632],
		["Oakland Mills","PA",40.61,-77.31,43266639],
		["Ono","PA",40.41,-76.56,43266646],
		["Rouzerville","PA",39.73,-77.52,43266653],
		["Skytop","PA",41.21,-75.21,43266660],
		["Herrick Center","PA",41.74,-75.48,43266667],
		["Maryland Line","MD",39.69,-76.71,43266674],
		["Newcomb","MD",38.75,-76.19,43266681],
		["Banco","VA",38.4,-78.33,43266688],
		["Thurmond","WV",37.96,-81.08,43266695],
		["Linville Falls","NC",35.94,-81.88,43266702],
		["Penney Farms","FL",29.98,-81.81,43266709],
		["Gantt","AL",31.4,-86.48,43266716],
		["Viola","TN",35.53,-85.86,43266723],
		["Rugby","TN",36.35,-84.7,43266730],
		["La Grange","TN",35.04,-89.23,43266737],
		["Rena Lara","MS",34.15,-90.77,43266744],
		["Tolu","KY",37.43,-88.24,43266751],
		["Beaumont","KY",36.86,-85.63,43266758],
		["Kilbourne","OH",40.3,-83.05,43266765],
		["Cherry Fork","OH",38.88,-83.61,43266772],
		["Friendship","OH",38.69,-83.11,43266779],
		["Williamstown","OH",40.83,-83.63,43266786],
		["Arcola","IN",41.1,-85.3,43266793],
		["South Milford","IN",41.53,-85.27,43266800],
		["Norvell","MI",42.15,-84.18,43266807],
		["Millerton","IA",40.84,-93.3,43266814],
		["Thornburg","IA",41.45,-92.33,43266821],
		["Grant","IA",41.14,-94.98,43266828],
		["Grandy","MN",45.63,-93.17,43266835],
		["Roscoe","MN",45.43,-94.63,43266842],
		["Wilton","MN",47.5,-94.99,43266849],
		["Turton","SD",45.04,-98.09,43266856],
		["Smithwick","SD",43.3,-103.22,43266863],
		["Mountain","ND",48.68,-97.86,43266870],
		["Calvin","ND",48.85,-98.93,43266877],
		["Knox","ND",48.34,-99.68,43266884],
		["Nekoma","ND",48.57,-98.37,43266891],
		["Zahl","ND",48.56,-103.68,43266898],
		["Outlook","MT",48.88,-104.81,43266905],
		["Willard","MT",46.16,-104.33,43266912],
		["Lloyd","MT",48.02,-109.21,43266919],
		["Haugan","MT",47.32,-115.36,43266926],
		["Sula","MT",45.87,-113.75,43266933],
		["Eleroy","IL",42.33,-89.76,43266940],
		["Murdock","IL",39.8,-88.08,43266947],
		["Farrar","MO",37.71,-89.89,43266954],
		["Byers","KS",37.78,-98.86,43266961],
		["Cedar","KS",39.65,-98.94,43266968],
		["Steele City","NE",40.03,-97.02,43266975],
		["Rougon","LA",30.59,-91.37,43266982],
		["Saint Francis","AR",36.45,-90.14,43266989],
		["Camden","TX",30.95,-94.71,43266996],
		["Rosston","TX",33.48,-97.45,43267003],
		["Putnam","TX",32.36,-99.19,43267010],
		["Pierce","TX",29.22,-96.18,43267017],
		["Hardin","TX",30.15,-94.73,43267024],
		["Oakville","TX",28.41,-98.06,43267031],
		["Mineral","TX",28.55,-97.97,43267038],
		["Bluffton","TX",30.83,-98.49,43267045],
		["Waka","TX",36.28,-101.04,43267052],
		["Fieldton","TX",34.03,-102.22,43267059],
		["Maple","TX",33.84,-102.89,43267066],
		["Ohio City","CO",38.54,-106.58,43267073],
		["Powderhorn","CO",38.27,-107.08,43267080],
		["Placerville","ID",43.94,-115.99,43267087],
		["Mormon Lake","AZ",34.93,-111.47,43267094],
		["Valentine","AZ",35.39,-113.62,43267101],
		["Canones","NM",36.18,-106.43,43267108],
		["Petaca","NM",36.5,-106.01,43267115],
		["Miami","NM",36.35,-104.79,43267122],
		["Picacho","NM",33.35,-105.14,43267129],
		["Capulin","NM",36.74,-103.99,43267136],
		["Newkirk","NM",35.06,-104.26,43267143],
		["Keeler","CA",36.48,-117.86,43267150],
		["Trona","CA",35.76,-117.37,43267157],
		["Hornitos","CA",37.43,-120.24,43267164],
		["Pinecrest","CA",38.28,-119.82,43267171],
		["Alleghany","CA",39.46,-120.84,43267178],
		["Twain","CA",40.03,-121.08,43267185],
		["Mineral","CA",40.38,-121.57,43267192],
		["Ryderwood","WA",46.36,-123.07,43267199],
		["Adak","AK",51.9,-176.67,43267206],
		["Pelican","AK",57.95,-136.11,43267213],
		["North Hatfield","MA",42.4,-72.6,43267219],
		["Glendale","MA",42.3,-73.32,43267225],
		["Sargentville","ME",44.32,-68.7,43267231],
		["Wesley","ME",44.95,-67.66,43267237],
		["Oxbow","ME",46.41,-68.49,43267243],
		["Matinicus","ME",43.86,-68.88,43267249],
		["Monhegan","ME",43.76,-69.32,43267255],
		["Jersey City","NJ",40.71,-74.06,43267261],
		["Schooleys Mountain","NJ",40.78,-74.79,43267267],
		["Howells","NY",41.5,-74.38,43267273],
		["Alder Creek","NY",43.42,-75.22,43267279],
		["East Springfield","NY",42.83,-74.8,43267285],
		["West Stockholm","NY",44.73,-74.88,43267291],
		["Chalk Hill","PA",39.83,-79.6,43267297],
		["Sheakleyville","PA",41.44,-80.2,43267303],
		["Bowmansville","PA",40.21,-76.05,43267309],
		["Sybertsville","PA",40.99,-76.08,43267315],
		["Sassamansville","PA",40.31,-75.58,43267321],
		["Luke","MD",39.47,-79.06,43267327],
		["Tylerton","MD",37.96,-76.01,43267333],
		["Marionville","VA",37.44,-75.85,43267339],
		["Broadford","VA",36.91,-81.66,43267345],
		["Snowshoe","WV",38.4,-79.99,43267351],
		["Monterville","WV",38.51,-80.16,43267357],
		["Lando","SC",34.76,-81.05,43267363],
		["Sharon","GA",33.55,-82.79,43267369],
		["Allentown","GA",32.59,-83.22,43267375],
		["Perdue Hill","AL",31.51,-87.49,43267381],
		["Campbell","AL",31.95,-88.02,43267387],
		["Melvin","AL",31.92,-88.45,43267393],
		["Crockett Mills","TN",35.87,-89.19,43267399],
		["Greenville","MS",33.38,-91.05,43267405],
		["Money","MS",33.61,-90.19,43267411],
		["Whick","KY",37.42,-83.37,43267417],
		["Middlebranch","OH",40.89,-81.32,43267423],
		["Chilo","OH",38.79,-84.13,43267429],
		["Decatur","OH",38.81,-83.72,43267435],
		["Elgin","OH",40.74,-84.47,43267441],
		["Hobbs","IN",40.3,-85.83,43267447],
		["Ralph","MI",46.07,-87.73,43267453],
		["Bradford","IA",42.6,-93.2,43267459],
		["Oran","IA",42.69,-92.08,43267465],
		["Gillett Grove","IA",43.01,-95.03,43267471],
		["Saint Lucas","IA",43.06,-91.93,43267477],
		["Afton","WI",42.69,-89.09,43267483],
		["Henriette","MN",45.87,-93.12,43267489],
		["Ihlen","MN",43.91,-96.37,43267495],
		["Kanaranzi","MN",43.54,-96.11,43267501],
		["Trosky","MN",43.88,-96.25,43267507],
		["Max","MN",47.61,-94.06,43267513],
		["Angle Inlet","MN",49.21,-94.97,43267519],
		["Lane","SD",44.06,-98.42,43267525],
		["Dahlen","ND",48.15,-97.92,43267531],
		["Hensel","ND",48.68,-97.66,43267537],
		["Churchs Ferry","ND",48.26,-99.19,43267543],
		["Sutton","ND",47.4,-98.43,43267549],
		["Tolley","ND",48.73,-101.82,43267555],
		["Ambrose","ND",48.95,-103.48,43267561],
		["Mosby","MT",47.08,-107.8,43267567],
		["Marysville","MT",46.74,-112.3,43267573],
		["Ringling","MT",46.26,-110.78,43267579],
		["Kaneville","IL",41.84,-88.54,43267585],
		["Kasbeer","IL",41.5,-89.46,43267591],
		["Renault","IL",38.15,-90.13,43267597],
		["Olney","MO",39.08,-91.24,43267603],
		["Ashburn","MO",39.54,-91.17,43267609],
		["Rombauer","MO",36.84,-90.28,43267615],
		["Dalton","MO",39.39,-92.99,43267621],
		["Quincy","MO",38,-93.47,43267627],
		["Udall","MO",36.54,-92.25,43267633],
		["Neal","KS",37.86,-96.04,43267639],
		["Freeport","KS",37.19,-97.85,43267645],
		["Jennings","KS",39.68,-100.29,43267651],
		["Abie","NE",41.33,-96.94,43267657],
		["Winslow","NE",41.6,-96.5,43267663],
		["Ong","NE",40.39,-97.83,43267669],
		["Ragan","NE",40.31,-99.29,43267675],
		["Elsmere","NE",42.16,-100.18,43267681],
		["Perry","LA",29.91,-92.14,43267687],
		["Lebeau","LA",30.7,-91.95,43267693],
		["Whelen Springs","AR",33.83,-93.12,43267699],
		["Crocketts Bluff","AR",34.41,-91.22,43267705],
		["Crumrod","AR",34.15,-90.98,43267711],
		["Turner","AR",34.48,-91.02,43267717],
		["Loveland","OK",34.3,-98.77,43267723],
		["May","OK",36.61,-99.74,43267729],
		["Irene","TX",31.99,-96.85,43267735],
		["Doole","TX",31.39,-99.59,43267741],
		["Fort Mc Kavett","TX",30.84,-100.06,43267747],
		["Gilchrist","TX",29.5,-94.51,43267753],
		["Panna Maria","TX",28.97,-97.87,43267759],
		["Lipscomb","TX",36.23,-100.27,43267765],
		["Wayside","TX",34.79,-101.54,43267771],
		["Bledsoe","TX",33.61,-103.02,43267777],
		["Estes Park","CO",40.37,-105.52,43267783],
		["Boncarbo","CO",37.22,-104.7,43267789],
		["Pitkin","CO",38.6,-106.51,43267795],
		["Bedrock","CO",38.32,-108.88,43267801],
		["Wolcott","CO",39.7,-106.68,43267807],
		["Garrett","WY",42.11,-105.6,43267813],
		["Meriden","WY",41.53,-104.27,43267819],
		["Savery","WY",41.08,-107.34,43267825],
		["Hyattville","WY",44.25,-107.6,43267831],
		["Natrona","WY",43.03,-106.81,43267837],
		["Powder River","WY",43.03,-106.98,43267843],
		["Bondurant","WY",43.2,-110.41,43267849],
		["Corral","ID",43.35,-114.98,43267855],
		["Hill City","ID",43.3,-115.02,43267861],
		["Gibbonsville","ID",45.6,-113.89,43267867],
		["Murray","ID",47.63,-115.79,43267873],
		["Cache Junction","UT",41.82,-111.99,43267879],
		["Eden","AZ",32.97,-109.91,43267885],
		["Oatman","AZ",35.01,-114.44,43267891],
		["Pinos Altos","NM",32.88,-108.24,43267897],
		["Dora","NM",33.93,-103.33,43267903],
		["Taiban","NM",34.44,-104,43267909],
		["Parker Dam","CA",34.28,-114.18,43267915],
		["Camp Nelson","CA",36.06,-118.58,43267921],
		["Cantil","CA",35.3,-117.95,43267927],
		["Valley Ford","CA",38.29,-122.94,43267933],
		["Redding","CA",40.56,-122.29,43267939],
		["Kent","OR",45.08,-120.66,43267945],
		["Agness","OR",42.59,-124.01,43267951],
		["Summer Lake","OR",42.86,-120.71,43267957],
		["Brogan","OR",44.15,-117.67,43267963],
		["Waldron","WA",48.69,-123.03,43267969],
		["Marshall","WA",47.56,-117.49,43267975],
		["Laurier","WA",48.95,-118.28,43267981],
		["Tyringham","MA",42.24,-73.19,43267986],
		["South Carver","MA",41.83,-70.72,43267991],
		["Frenchboro","ME",44.11,-68.36,43267996],
		["West Forks","ME",45.42,-69.97,43268001],
		["Gilman","CT",41.58,-72.12,43268006],
		["Broadway","NJ",40.71,-75.05,43268011],
		["Floral Park","NY",40.72,-73.7,43268016],
		["Brainard","NY",42.48,-73.54,43268021],
		["Paradox","NY",43.9,-73.63,43268026],
		["Sabael","NY",43.72,-74.3,43268031],
		["Keeseville","NY",44.5,-73.48,43268036],
		["Pompey","NY",42.89,-76.01,43268041],
		["Hannawa Falls","NY",44.51,-75,43268046],
		["Wellersburg","PA",39.72,-78.84,43268051],
		["Walston","PA",40.96,-79,43268056],
		["Worthville","PA",41.02,-79.14,43268061],
		["Scotland","PA",39.96,-77.59,43268066],
		["Cedar Run","PA",41.52,-77.45,43268071],
		["Maxatawny","PA",40.52,-75.74,43268076],
		["Wicomico Church","VA",37.81,-76.37,43268081],
		["Tad","WV",38.29,-81.55,43268086],
		["Glady","WV",38.81,-79.7,43268091],
		["Dillsboro","NC",35.36,-83.25,43268096],
		["Milledgeville","GA",33.08,-83.23,43268101],
		["Suwannee","FL",29.32,-83.14,43268106],
		["Holder","FL",28.96,-82.41,43268111],
		["Cook Springs","AL",33.59,-86.36,43268116],
		["Lenox","AL",31.33,-87.18,43268121],
		["Point Clear","AL",30.48,-87.9,43268126],
		["Slayden","TN",36.29,-87.46,43268131],
		["Clermont","KY",37.91,-85.65,43268136],
		["Perry Park","KY",38.55,-84.97,43268141],
		["Slade","KY",37.79,-83.7,43268146],
		["Mc Kinney","KY",37.47,-84.75,43268151],
		["Bypro","KY",37.3,-82.7,43268156],
		["Morristown","OH",40.06,-81.07,43268161],
		["Warnock","OH",40,-80.95,43268166],
		["Coal Run","OH",39.54,-81.64,43268171],
		["Watertown","OH",39.47,-81.6,43268176],
		["Smithville","IN",39.09,-86.47,43268181],
		["Tunnelton","IN",38.8,-86.33,43268186],
		["Paxton","IN",39.11,-87.42,43268191],
		["Higgins Lake","MI",44.46,-84.75,43268196],
		["Long Lake","MI",45.13,-83.98,43268201],
		["Vining","IA",41.99,-92.38,43268206],
		["Beetown","WI",42.79,-90.88,43268211],
		["Melrude","MN",47.24,-92.42,43268216],
		["Reads Landing","MN",44.39,-92.13,43268221],
		["Jenkins","MN",46.65,-94.32,43268226],
		["Akaska","SD",45.33,-100.12,43268231],
		["Caledonia","ND",47.45,-96.88,43268236],
		["Springdale","MT",45.73,-110.22,43268241],
		["Hathaway","MT",46.25,-106.16,43268246],
		["Whitlash","MT",48.91,-111.15,43268251],
		["Polaris","MT",45.37,-113.06,43268256],
		["Ravalli","MT",47.27,-114.15,43268261],
		["Stryker","MT",48.7,-114.74,43268266],
		["Ferris","IL",40.46,-91.16,43268271],
		["Levasy","MO",39.13,-94.13,43268276],
		["Waldron","MO",39.22,-94.79,43268281],
		["Webber","KS",39.93,-98.03,43268286],
		["Sun City","KS",37.37,-98.91,43268291],
		["Magnet","NE",42.45,-97.46,43268296],
		["Hendley","NE",40.13,-99.97,43268301],
		["Seneca","NE",42.04,-100.83,43268306],
		["Bingham","NE",42.01,-102.06,43268311],
		["Melrose","LA",31.57,-92.95,43268316],
		["Bluff City","AR",33.71,-93.13,43268321],
		["Snow Lake","AR",34.05,-91.05,43268326],
		["Vinson","OK",34.9,-99.87,43268331],
		["Schwertner","TX",30.72,-97.43,43268336],
		["Cedar Lane","TX",28.94,-95.76,43268341],
		["Glen Flora","TX",29.34,-96.15,43268346],
		["Kendleton","TX",29.44,-96,43268351],
		["Armstrong","TX",26.82,-97.75,43268356],
		["Dougherty","TX",33.94,-101.08,43268361],
		["Tell","TX",34.38,-100.39,43268366],
		["Mentone","TX",31.71,-103.6,43268371],
		["Haswell","CO",38.45,-103.16,43268376],
		["Trinchera","CO",37.05,-104.03,43268381],
		["Wapiti","WY",44.47,-109.43,43268386],
		["Wolf","WY",44.7,-107.3,43268391],
		["Lonetree","WY",41.03,-110.14,43268396],
		["Spencer","ID",44.39,-112.22,43268401],
		["Avery","ID",47.17,-115.8,43268406],
		["Mount Lemmon","AZ",32.38,-110.75,43268411],
		["Pep","NM",33.83,-103.33,43268416],
		["Gladstone","NM",36.3,-103.97,43268421],
		["Mountain City","NV",41.77,-116.08,43268426],
		["Long Beach","CA",33.77,-118.11,43268431],
		["Verdugo City","CA",34.21,-118.24,43268436],
		["Mount Laguna","CA",32.87,-116.46,43268441],
		["Brandeis","CA",34.28,-118.69,43268446],
		["Red Mountain","CA",35.35,-117.62,43268451],
		["Strawberry","CA",38.19,-120.01,43268456],
		["Auburn","CA",38.9,-121.06,43268461],
		["Otter Rock","OR",44.74,-124.05,43268466],
		["Plush","OR",42.54,-119.8,43268471],
		["Ironside","OR",44.32,-117.83,43268476],
		["Lamona","WA",47.35,-118.48,43268481],
		["Red Devil","AK",61.66,-157.69,43268486],
		["Rampart","AK",65.4,-150.2,43268491],
		["West Nottingham","NH",43.13,-71.12,43268495],
		["Portland","ME",43.66,-70.25,43268499],
		["Lambert Lake","ME",45.54,-67.52,43268503],
		["China Village","ME",44.47,-69.51,43268507],
		["Hinckley","ME",44.67,-69.65,43268511],
		["South Lyme","CT",41.32,-72.3,43268515],
		["Hinckley","NY",43.31,-75.12,43268519],
		["Great Bend","NY",44.01,-75.69,43268523],
		["Nineveh","PA",39.97,-80.3,43268527],
		["Merrittstown","PA",39.96,-79.86,43268531],
		["Coolspring","PA",40.95,-78.92,43268535],
		["Beyer","PA",40.79,-79.19,43268539],
		["Old Zionsville","PA",40.48,-75.54,43268543],
		["South Canaan","PA",41.5,-75.41,43268547],
		["Carversville","PA",40.39,-75.06,43268551],
		["Kimberton","PA",40.12,-75.53,43268555],
		["Yorklyn","DE",39.78,-75.65,43268559],
		["Georgetown","MD",39.39,-75.87,43268563],
		["Sharps","VA",37.84,-76.7,43268567],
		["Portsmouth","VA",36.83,-76.29,43268571],
		["Alamance","NC",36.03,-79.48,43268575],
		["Everetts","NC",35.83,-77.17,43268579],
		["Balsam","NC",35.42,-83.08,43268583],
		["Peak","SC",34.23,-81.32,43268587],
		["Sunny Side","GA",33.34,-84.29,43268591],
		["Graysville","GA",34.89,-85.12,43268595],
		["Bagdad","FL",30.58,-87.03,43268599],
		["Orange Lake","FL",29.44,-81.99,43268603],
		["Walnut Grove","AL",34.06,-86.28,43268607],
		["Millerville","AL",33.15,-85.95,43268611],
		["River Falls","AL",31.35,-86.54,43268615],
		["Jachin","AL",32.23,-88.16,43268619],
		["Pruden","TN",36.54,-83.87,43268623],
		["Gray Hawk","KY",37.39,-83.93,43268627],
		["Wendover","KY",37.12,-83.36,43268631],
		["Blissfield","OH",40.28,-81.89,43268635],
		["Wolf Run","OH",40.48,-80.89,43268639],
		["Bethlehem","IN",38.53,-85.41,43268643],
		["Mackey","IN",38.24,-87.39,43268647],
		["Shepardsville","IN",39.6,-87.41,43268651],
		["Chippewa Lake","MI",43.74,-85.29,43268655],
		["Eastport","MI",45.04,-85.34,43268659],
		["Bevington","IA",41.35,-93.79,43268663],
		["Ira","IA",41.73,-93.16,43268667],
		["Minneapolis","MN",44.96,-93.26,43268671],
		["Dovray","MN",44.05,-95.54,43268675],
		["Donaldson","MN",48.57,-96.89,43268679],
		["New Holland","SD",43.42,-98.64,43268683],
		["Chaseley","ND",47.45,-99.8,43268687],
		["Jessie","ND",47.54,-98.23,43268691],
		["Shields","ND",46.23,-101.13,43268695],
		["Otter","MT",45.14,-106.08,43268699],
		["Two Dot","MT",46.47,-110.07,43268703],
		["Whitetail","MT",48.92,-105.19,43268707],
		["Lothair","MT",48.53,-112.15,43268711],
		["Zurich","MT",48.66,-109.01,43268715],
		["Polebridge","MT",48.85,-114.47,43268719],
		["Hematite","MO",38.2,-90.48,43268723],
		["Gipsy","MO",37.13,-90.19,43268727],
		["Wesco","MO",37.85,-91.35,43268731],
		["Butterfield","MO",36.74,-93.9,43268735],
		["Arnold","KS",38.63,-100.04,43268739],
		["Lorton","NE",40.59,-96.03,43268743],
		["Nenzel","NE",42.92,-101.1,43268747],
		["Sparks","NE",42.93,-100.26,43268751],
		["Coy","AR",34.54,-91.86,43268755],
		["Tilly","AR",35.69,-92.86,43268759],
		["Platter","OK",33.9,-96.53,43268763],
		["Ratcliff","TX",31.38,-95.11,43268767],
		["Bend","TX",31.09,-98.51,43268771],
		["Leggett","TX",30.84,-94.85,43268775],
		["Mcfaddin","TX",28.51,-97.01,43268779],
		["Artesia Wells","TX",28.22,-99.29,43268783],
		["Randolph A F B","TX",29.55,-98.3,43268787],
		["Odell","TX",34.35,-99.42,43268791],
		["Aurora","CO",39.69,-104.81,43268795],
		["Paoli","CO",40.61,-102.47,43268799],
		["Dinosaur","CO",40.24,-109,43268803],
		["Shawnee","WY",42.88,-105.03,43268807],
		["Van Tassell","WY",42.66,-104.09,43268811],
		["Petrified Forest Natl Pk","AZ",34.79,-109.87,43268815],
		["Temple Bar Marina","AZ",36.02,-114.29,43268819],
		["Claunch","NM",34.14,-105.99,43268823],
		["Tererro","NM",35.74,-105.67,43268827],
		["Ute Park","NM",36.55,-105.11,43268831],
		["Faywood","NM",32.56,-108.02,43268835],
		["Crossroads","NM",33.51,-103.33,43268839],
		["Mcdonald","NM",33.07,-103.21,43268843],
		["Maljamar","NM",32.85,-103.76,43268847],
		["Luning","NV",38.47,-118.3,43268851],
		["Winnemucca","NV",40.96,-117.72,43268855],
		["Randsburg","CA",35.39,-117.73,43268859],
		["Lakeshore","CA",37.33,-119.01,43268863],
		["Rumsey","CA",38.9,-122.29,43268867],
		["Clipper Mills","CA",39.54,-121.16,43268871],
		["Old Station","CA",40.67,-121.47,43268875],
		["Ravendale","CA",40.82,-120.28,43268879],
		["Mehama","OR",44.79,-122.6,43268883],
		["Brothers","OR",43.74,-120.38,43268887],
		["Mohler","WA",47.36,-118.35,43268891],
		["Steptoe","WA",47,-117.35,43268895],
		["Port Alexander","AK",56.21,-134.66,43268899],
		["Biddeford Pool","ME",43.44,-70.34,43268902],
		["North Waterford","ME",44.2,-70.72,43268905],
		["West Bethel","ME",44.4,-70.86,43268908],
		["West Boothbay Harbor","ME",43.85,-69.65,43268911],
		["Lake Elmore","VT",44.54,-72.52,43268914],
		["Northfield Falls","VT",44.16,-72.67,43268917],
		["East Middlebury","VT",43.98,-73.08,43268920],
		["Yantic","CT",41.53,-72.04,43268923],
		["Birmingham","NJ",39.92,-74.53,43268926],
		["Halcottsville","NY",42.2,-74.6,43268929],
		["Huletts Landing","NY",43.64,-73.5,43268932],
		["Riparius","NY",43.73,-73.55,43268935],
		["Van Hornesville","NY",42.88,-74.82,43268938],
		["Versailles","NY",42.5,-79,43268941],
		["Chautauqua","NY",42.2,-79.48,43268944],
		["Chambersville","PA",40.72,-79.14,43268947],
		["Elmora","PA",40.6,-78.73,43268950],
		["Lickingville","PA",41.37,-79.37,43268953],
		["Poyntelle","PA",41.82,-75.42,43268956],
		["Loretto","VA",38.07,-77.05,43268959],
		["Brownsburg","VA",37.93,-79.31,43268962],
		["Lowry","VA",37.34,-79.45,43268965],
		["Freeman","WV",37.33,-81.27,43268968],
		["Switchback","WV",37.37,-81.37,43268971],
		["Charlotte","NC",35.19,-80.83,43268974],
		["Scottville","NC",36.5,-81.28,43268977],
		["Pulaski","GA",32.39,-81.95,43268980],
		["Indian Lake Estates","FL",27.87,-81.47,43268983],
		["Bon Air","AL",33.26,-86.33,43268986],
		["Loachapoka","AL",32.6,-85.59,43268989],
		["Wynnburg","TN",36.29,-89.47,43268992],
		["Avon","MS",33.2,-91.06,43268995],
		["Holly Ridge","MS",33.48,-90.68,43268998],
		["Starkville","MS",33.45,-88.82,43269001],
		["Keene","KY",37.93,-84.63,43269004],
		["Bowling Green","KY",36.97,-86.44,43269007],
		["Middleburg","OH",40.27,-83.58,43269010],
		["Fulda","IN",38.11,-86.83,43269013],
		["Arnold","MI",46.05,-87.48,43269016],
		["Nahma","MI",46,-86.69,43269019],
		["Brokaw","WI",45.02,-89.65,43269022],
		["Star Lake","WI",46.03,-89.46,43269025],
		["Barry","MN",45.55,-96.55,43269028],
		["Ralph","SD",45.77,-103.06,43269031],
		["Hansboro","ND",48.95,-99.38,43269034],
		["Bantry","ND",48.49,-100.6,43269037],
		["Ingomar","MT",46.66,-107.46,43269040],
		["Melville","MT",46.12,-109.83,43269043],
		["Redstone","MT",48.73,-104.9,43269046],
		["Angela","MT",46.67,-106.39,43269049],
		["Mozier","IL",39.29,-90.75,43269052],
		["Kansas City","MO",39.09,-94.58,43269055],
		["Lock Springs","MO",39.84,-93.77,43269058],
		["Metz","MO",37.99,-94.44,43269061],
		["Zanoni","MO",36.68,-92.33,43269064],
		["Walker","KS",38.86,-99.06,43269067],
		["Purdum","NE",42.07,-100.26,43269070],
		["Angora","NE",41.87,-103.1,43269073],
		["Start","LA",32.47,-91.88,43269076],
		["Acme","LA",31.25,-91.76,43269079],
		["Gregory","AR",35.14,-91.39,43269082],
		["Hopeton","OK",36.68,-98.67,43269085],
		["Picher","OK",36.98,-94.83,43269088],
		["Gatesville","TX",31.44,-97.73,43269091],
		["Huntsville","TX",30.71,-95.54,43269094],
		["Thompsons","TX",29.49,-95.61,43269097],
		["Hillside","CO",38.27,-105.6,43269100],
		["Sargents","CO",38.4,-106.42,43269103],
		["Slater","CO",40.99,-107.37,43269106],
		["Buford","WY",41.11,-105.3,43269109],
		["Lost Springs","WY",42.76,-104.92,43269112],
		["Hiland","WY",43.12,-107.35,43269115],
		["Lysite","WY",43.25,-107.71,43269118],
		["Devils Tower","WY",44.6,-104.73,43269121],
		["Modena","UT",37.87,-113.86,43269124],
		["Sasabe","AZ",31.66,-111.5,43269127],
		["Flagstaff","AZ",35.22,-111.57,43269130],
		["Gray Mountain","AZ",35.67,-111.45,43269133],
		["Clines Corners","NM",35,-105.66,43269136],
		["Taos Ski Valley","NM",36.6,-105.45,43269139],
		["Las Cruces","NM",32.33,-106.75,43269142],
		["Whites City","NM",32.17,-104.37,43269145],
		["Orogrande","NM",32.37,-106.08,43269148],
		["Sacramento","NM",32.79,-105.56,43269151],
		["Manhattan","NV",38.53,-117.04,43269154],
		["Crystal Bay","NV",39.28,-119.95,43269157],
		["Los Angeles","CA",34.05,-118.45,43269160],
		["Ludlow","CA",34.72,-116.15,43269163],
		["Duncans Mills","CA",38.44,-123.05,43269166],
		["Norden","CA",39.31,-120.43,43269169],
		["Twin Bridges","CA",38.83,-120.18,43269172],
		["Shaniko","OR",44.98,-120.74,43269175],
		["Post","OR",44.05,-120.09,43269178],
		["Bates","OR",44.7,-118.79,43269181],
		["Anchorage","AK",61.19,-149.98,43269184],
		["False Pass","AK",54.83,-163.65,43269187],
		["Skwentna","AK",61.93,-151.73,43269190],
		["Prudhoe Bay","AK",70.01,-148.88,43269193],
		["Toa Baja","PR",18.44,-66.25,43269195],
		["East Winthrop","ME",44.33,-69.9,43269197],
		["Cranberry Isles","ME",44.24,-68.26,43269199],
		["Caratunk","ME",45.23,-69.99,43269201],
		["Shawmut","ME",44.62,-69.6,43269203],
		["East Killingly","CT",41.84,-71.8,43269205],
		["Raquette Lake","NY",43.81,-74.65,43269207],
		["Chippewa Bay","NY",44.44,-75.74,43269209],
		["Armbrust","PA",40.24,-79.55,43269211],
		["Cresson","PA",40.46,-78.58,43269213],
		["Pottersdale","PA",41.2,-78.02,43269215],
		["Kleinfeltersville","PA",40.31,-76.27,43269217],
		["Cammal","PA",41.2,-77.26,43269219],
		["Jersey Mills","PA",41.45,-77.47,43269221],
		["Philadelphia","PA",39.95,-75.16,43269223],
		["Spring House","PA",40.18,-75.22,43269225],
		["Washington","DC",38.89,-77.03,43269227],
		["Gaithersburg","MD",39.14,-77.21,43269229],
		["Orkney Springs","VA",38.79,-78.82,43269231],
		["Norwood","VA",37.64,-78.8,43269233],
		["Selbyville","WV",38.71,-80.3,43269235],
		["Saxapahaw","NC",35.94,-79.32,43269237],
		["Falkland","NC",35.69,-77.51,43269239],
		["Minneapolis","NC",36.09,-81.98,43269241],
		["Rome","GA",34.26,-85.18,43269243],
		["Vidalia","GA",32.21,-82.4,43269245],
		["Orlando","FL",28.37,-81.55,43269247],
		["Bay Pines","FL",27.81,-82.77,43269249],
		["Pineland","FL",26.67,-82.14,43269251],
		["Bucks","AL",31.01,-88.02,43269253],
		["Oak Hill","AL",31.92,-87.08,43269255],
		["Pickwick Dam","TN",35.05,-88.25,43269257],
		["Allred","TN",36.36,-85.19,43269259],
		["Saint Mary","KY",37.58,-85.33,43269261],
		["Frankfort","KY",38.19,-84.86,43269263],
		["Minerva","KY",38.69,-83.91,43269265],
		["Scuddy","KY",37.21,-83.09,43269267],
		["Middle Bass","OH",41.68,-82.81,43269269],
		["Stony Ridge","OH",41.47,-83.46,43269271],
		["Stafford","OH",39.71,-81.27,43269273],
		["Winona","OH",40.82,-80.87,43269275],
		["Jasper","OH",39.03,-83.05,43269277],
		["Riverside","MI",42.15,-86.42,43269279],
		["Burt Lake","MI",45.43,-84.71,43269281],
		["Pointe Aux Pins","MI",45.76,-84.46,43269283],
		["North Buena Vista","IA",42.68,-90.95,43269285],
		["Clam Lake","WI",46.15,-90.91,43269287],
		["Sand Creek","WI",45.17,-91.71,43269289],
		["Redig","SD",45.27,-103.54,43269291],
		["Absaraka","ND",46.97,-97.39,43269293],
		["Larslan","MT",48.66,-106.26,43269295],
		["Raymond","MT",48.89,-104.52,43269297],
		["Bynum","MT",48.04,-112.38,43269299],
		["Lees Summit","MO",38.91,-94.38,43269301],
		["Kansas City","MO",38.95,-94.52,43269303],
		["Allendale","MO",40.48,-94.28,43269305],
		["Stockville","NE",40.53,-100.38,43269307],
		["Willow Island","NE",40.88,-100.06,43269309],
		["Rivervale","AR",35.67,-90.4,43269311],
		["Gilbert","AR",35.99,-92.71,43269313],
		["Kenton","OK",36.9,-102.97,43269315],
		["Swink","OK",34.02,-95.2,43269317],
		["Pottsville","TX",31.68,-98.35,43269319],
		["Rockwood","TX",31.5,-99.37,43269321],
		["Collegeport","TX",28.68,-96.16,43269323],
		["Masterson","TX",35.63,-101.96,43269325],
		["Amarillo","TX",35.2,-101.81,43269327],
		["South Plains","TX",34.22,-101.3,43269329],
		["Orla","TX",31.83,-103.91,43269331],
		["Cowdrey","CO",40.85,-106.3,43269333],
		["Wild Horse","CO",38.82,-103,43269335],
		["Colorado Springs","CO",38.9,-104.66,43269337],
		["Fort Lyon","CO",38.1,-103.15,43269339],
		["Jay Em","WY",42.47,-104.36,43269341],
		["Leiter","WY",44.79,-106.35,43269343],
		["Frontier","WY",41.79,-110.6,43269345],
		["Macks Inn","ID",44.49,-111.33,43269347],
		["Eastport","ID",48.98,-116.17,43269349],
		["Blue","AZ",33.52,-109.21,43269351],
		["Valmora","NM",35.81,-104.92,43269353],
		["Yeso","NM",34.43,-104.6,43269355],
		["Ruidoso","NM",33.36,-105.64,43269357],
		["Cuervo","NM",35.03,-104.4,43269359],
		["Denio","NV",41.75,-118.66,43269361],
		["Incline Village","NV",39.25,-119.95,43269363],
		["Shoshone","CA",35.97,-116.2,43269365],
		["Point Mugu Nawc","CA",34.12,-119.09,43269367],
		["San Andreas","CA",38.25,-120.38,43269369],
		["Villa Grande","CA",38.47,-123.03,43269371],
		["Goodyears Bar","CA",39.51,-120.94,43269373],
		["Mill Creek","CA",40.34,-121.46,43269375],
		["M C B H Kaneohe Bay","HI",21.43,-157.74,43269377],
		["Seattle","WA",47.6,-122.33,43269379],
		["Hay","WA",46.65,-117.91,43269381],
		["Lake Minchumina","AK",63.81,-152.25,43269383],
		["Nantucket","MA",41.27,-70.1,43269384],
		["Hulls Cove","ME",44.42,-68.27,43269385],
		["Allamuchy","NJ",40.91,-74.83,43269386],
		["Lyons","NJ",40.66,-74.55,43269387],
		["New York","NY",40.71,-73.99,43269388],
		["Ardsley On Hudson","NY",41.02,-73.87,43269389],
		["Arden","NY",41.22,-74.18,43269390],
		["Queen","PA",40.25,-78.51,43269391],
		["Lairdsville","PA",41.23,-76.61,43269392],
		["Buckingham","PA",40.31,-75.06,43269393],
		["Crocheron","MD",38.24,-76.05,43269394],
		["Salisbury","MD",38.37,-75.58,43269395],
		["Mount Olive","WV",38.09,-81.26,43269396],
		["Little Switzerland","NC",35.83,-82.02,43269397],
		["Gracewood","GA",33.38,-82.02,43269398],
		["Jewell","GA",33.33,-82.73,43269399],
		["River Ranch","FL",27.88,-81.47,43269400],
		["Naples","FL",26.14,-81.79,43269401],
		["Hollins","AL",33.18,-86.09,43269402],
		["Mobile","AL",30.68,-88.04,43269403],
		["Pleasant Hill","TN",35.97,-85.19,43269404],
		["Rousseau","KY",37.59,-83.23,43269405],
		["Dycusburg","KY",37.16,-88.18,43269406],
		["Rosine","KY",37.45,-86.75,43269407],
		["Walnut Creek","OH",40.55,-81.71,43269408],
		["Birmingham","OH",41.32,-82.35,43269409],
		["Carbon","IA",41.04,-94.82,43269410],
		["Madison","WI",43.07,-89.38,43269411],
		["East Ellsworth","WI",44.72,-92.42,43269412],
		["Mikana","WI",45.61,-91.57,43269413],
		["Minneapolis","MN",44.96,-93.26,43269414],
		["Richwood","MN",46.97,-95.82,43269415],
		["Wolf Lake","MN",46.8,-95.35,43269416],
		["Margie","MN",48.09,-93.94,43269417],
		["Oak Island","MN",49.31,-94.84,43269418],
		["Ferney","SD",45.33,-98.09,43269419],
		["Cooke City","MT",45.08,-109.9,43269420],
		["Glentana","MT",48.84,-106.24,43269421],
		["Saltese","MT",47.42,-115.5,43269422],
		["Miller City","IL",37.08,-89.35,43269423],
		["Nekoma","KS",38.47,-99.44,43269424],
		["North Little Rock","AR",34.78,-92.25,43269425],
		["Hext","TX",30.86,-99.52,43269426],
		["Elmaton","TX",28.82,-96.08,43269427],
		["Oakland","TX",29.62,-96.8,43269428],
		["Kerrick","TX",36.45,-102.24,43269429],
		["Notrees","TX",31.92,-102.76,43269430],
		["Toponas","CO",40.07,-106.8,43269431],
		["Arlington","CO",38.33,-103.33,43269432],
		["Aspen","CO",39.19,-106.82,43269433],
		["Horse Creek","WY",41.41,-105.18,43269434],
		["Tie Siding","WY",41.04,-105.42,43269435],
		["Linch","WY",43.61,-106.2,43269436],
		["Wyarno","WY",44.89,-106.71,43269437],
		["Shoup","ID",45.22,-114.41,43269438],
		["Banks","ID",44.1,-116.13,43269439],
		["Warren","ID",45.29,-115.59,43269440],
		["Yellow Pine","ID",45.02,-115.52,43269441],
		["Kingston","UT",38.23,-112.19,43269442],
		["Prescott","AZ",34.54,-112.46,43269443],
		["Mills","NM",36.08,-104.25,43269444],
		["Redrock","NM",32.68,-108.73,43269445],
		["Fort Stanton","NM",33.49,-105.52,43269446],
		["Jean","NV",35.77,-115.32,43269447],
		["Los Angeles","CA",34.07,-118.44,43269448],
		["Sequoia National Park","CA",36.53,-118.72,43269449],
		["Echo Lake","CA",38.84,-120.07,43269450],
		["Belden","CA",40,-121.24,43269451],
		["Fort Klamath","OR",42.64,-122.06,43269452],
		["Bridgeport","OR",44.5,-117.65,43269453],
		["Blakely Island","WA",48.56,-122.8,43269454],
		["Nikolski","AK",52.99,-168.82,43269455],
		["Anchorage","AK",61.45,-150.63,43269456],
		["Fairbanks","AK",64.51,-147.66,43269457],
		["Meyers Chuck","AK",55.68,-132.19,43269458],
		["San Juan","PR",18.4,-66.06,43269459],
		["Toa Baja","PR",18.44,-66.25,43269460],
		["Springfield","MA",42.11,-72.53,43269461],
		["Hathorne","MA",42.57,-70.95,43269462],
		["Boston","MA",42.35,-71.06,43269463],
		["East Orleans","MA",41.74,-69.98,43269464],
		["North Eastham","MA",41.84,-69.97,43269465],
		["Cuttyhunk","MA",41.45,-70.8,43269466],
		["East Livermore","ME",44.39,-70.12,43269467],
		["Squirrel Island","ME",43.8,-69.63,43269468],
		["Estcourt Station","ME",47.4,-69.17,43269469],
		["Saint Albans Bay","VT",44.82,-73.12,43269470],
		["Averill","VT",45,-71.72,43269471],
		["Canton Center","CT",41.86,-72.9,43269472],
		["North Canton","CT",41.9,-72.89,43269473],
		["Hartford","CT",41.76,-72.68,43269474],
		["Norwalk","CT",41.09,-73.42,43269475],
		["Mahwah","NJ",41.08,-74.18,43269476],
		["Wallpack Center","NJ",41.12,-74.91,43269477],
		["Convent Station","NJ",40.79,-74.47,43269478],
		["Zarephath","NJ",40.53,-74.56,43269479],
		["New York","NY",40.71,-73.99,43269480],
		["New York","NY",40.71,-73.99,43269481],
		["New York","NY",40.71,-73.99,43269482],
		["New York","NY",40.71,-73.99,43269483],
		["New York","NY",40.71,-73.99,43269484],
		["New York","NY",40.71,-73.99,43269485],
		["New York","NY",40.71,-73.99,43269486],
		["New York","NY",40.71,-73.99,43269487],
		["New York","NY",40.71,-73.99,43269488],
		["New York","NY",40.71,-73.99,43269489],
		["New York","NY",40.71,-73.99,43269490],
		["New York","NY",40.71,-73.99,43269491],
		["New York","NY",40.71,-73.99,43269492],
		["New York","NY",40.71,-73.99,43269493],
		["New York","NY",40.71,-73.99,43269494],
		["New York","NY",40.71,-73.99,43269495],
		["New York","NY",40.71,-73.99,43269496],
		["New York","NY",40.71,-73.99,43269497],
		["New York","NY",40.71,-73.99,43269498],
		["New York","NY",40.71,-73.99,43269499],
		["New York","NY",40.71,-73.99,43269500],
		["New York","NY",40.71,-73.99,43269501],
		["New York","NY",40.71,-73.99,43269502],
		["Staten Island","NY",40.58,-74.14,43269503],
		["Maryknoll","NY",41.17,-73.83,43269504],
		["Bear Mountain","NY",41.3,-73.99,43269505],
		["New Hyde Park","NY",40.73,-73.68,43269506],
		["Flushing","NY",40.77,-73.84,43269507],
		["Bayside","NY",40.79,-73.77,43269508],
		["Flushing","NY",40.77,-73.84,43269509],
		["Jamaica","NY",40.68,-73.78,43269510],
		["Jamaica","NY",40.68,-73.78,43269511],
		["Jamaica","NY",40.68,-73.78,43269512],
		["Uniondale","NY",40.7,-73.59,43269513],
		["Severance","NY",43.88,-73.72,43269514],
		["Ellenburg","NY",44.88,-73.84,43269515],
		["Syracuse","NY",43.04,-76.14,43269516],
		["Thousand Island Park","NY",44.33,-75.94,43269517],
		["Niagara Falls","NY",43.09,-79.01,43269518],
		["Jacksonville","NY",42.5,-76.61,43269519],
		["Wayne","NY",42.47,-77.1,43269520],
		["Pittsburgh","PA",40.43,-79.97,43269521],
		["Pittsburgh","PA",40.43,-79.97,43269522],
		["Villa Maria","PA",41.08,-80.52,43269523],
		["De Young","PA",41.57,-78.91,43269524],
		["Harrisburg","PA",40.27,-76.88,43269525],
		["Quincy","PA",39.8,-77.55,43269526],
		["Lancaster","PA",40.04,-76.3,43269527],
		["Danville","PA",40.96,-76.61,43269528],
		["Bethlehem","PA",40.62,-75.36,43269529],
		["Allentown","PA",40.59,-75.47,43269530],
		["Allentown","PA",40.59,-75.47,43269531],
		["Montgomeryville","PA",40.24,-75.24,43269532],
		["Philadelphia","PA",39.95,-75.16,43269533],
		["Limekiln","PA",40.35,-75.78,43269534],
		["Rockland","DE",39.8,-75.61,43269535],
		["Winterthur","DE",39.79,-75.61,43269536],
		["Washington","DC",38.89,-77.03,43269537],
		["Washington","DC",38.89,-77.03,43269538],
		["Middleburg","VA",38.97,-77.73,43269539],
		["Washington","DC",38.89,-77.03,43269540],
		["Washington","DC",38.89,-77.03,43269541],
		["Washington","DC",38.89,-77.03,43269542],
		["Washington","DC",38.89,-77.03,43269543],
		["Washington","DC",38.89,-77.03,43269544],
		["Washington","DC",38.89,-77.03,43269545],
		["Washington","DC",38.89,-77.03,43269546],
		["Washington","DC",38.89,-77.03,43269547],
		["Washington","DC",38.89,-77.03,43269548],
		["Washington","DC",38.89,-77.03,43269549],
		["Washington","DC",38.89,-77.03,43269550],
		["Washington","DC",38.89,-77.03,43269551],
		["Washington","DC",38.89,-77.03,43269552],
		["Washington","DC",38.89,-77.03,43269553],
		["Washington","DC",38.89,-77.03,43269554],
		["Washington","DC",38.89,-77.03,43269555],
		["Washington","DC",38.89,-77.03,43269556],
		["Washington","DC",38.89,-77.03,43269557],
		["Washington","DC",38.89,-77.03,43269558],
		["Washington","DC",38.89,-77.03,43269559],
		["Washington","DC",38.89,-77.03,43269560],
		["Annapolis Junction","MD",39.12,-76.77,43269561],
		["Hunt Valley","MD",39.49,-76.65,43269562],
		["Baltimore","MD",39.3,-76.61,43269563],
		["Frederick","MD",39.42,-77.41,43269564],
		["Childs","MD",39.65,-75.85,43269565],
		["Fairfax","VA",38.85,-77.29,43269566],
		["Vienna","VA",38.9,-77.26,43269567],
		["Arlington","VA",38.87,-77.1,43269568],
		["Richmond","VA",37.55,-77.46,43269569],
		["Sussex","VA",36.91,-77.27,43269570],
		["Oriskany","VA",37.61,-79.98,43269571],
		["Colcord","WV",37.93,-81.45,43269572],
		["Charleston","WV",38.35,-81.63,43269573],
		["Charlotte","NC",35.19,-80.83,43269574],
		["Charlotte","NC",35.19,-80.83,43269575],
		["Fontana Dam","NC",35.43,-83.81,43269576],
		["State Park","SC",34.02,-80.96,43269577],
		["Mc Cormick","SC",33.91,-82.29,43269578],
		["Sargent","GA",33.41,-84.91,43269579],
		["Atlanta","GA",33.73,-84.4,43269580],
		["Macon","GA",32.83,-83.65,43269581],
		["Brunswick","GA",31.14,-81.49,43269582],
		["Tallahassee","FL",30.45,-84.28,43269583],
		["Rosemary Beach","FL",30.25,-85.94,43269584],
		["Miami","FL",25.77,-80.2,43269585],
		["Miami","FL",25.79,-80.3,43269586],
		["Nalcrest","FL",27.87,-81.47,43269587],
		["Wilton","AL",33.08,-86.88,43269588],
		["Natural Bridge","AL",34.1,-87.6,43269589],
		["Theodore","AL",30.54,-88.17,43269590],
		["Holy Trinity","AL",32.16,-85.12,43269591],
		["Nashville","TN",36.17,-86.78,43269592],
		["Nashville","TN",36.17,-86.78,43269593],
		["Memphis","TN",35.1,-90,43269594],
		["Memphis","TN",35.1,-90,43269595],
		["Greenville","MS",33.38,-91.05,43269596],
		["Swan Lake","MS",33.89,-90.24,43269597],
		["Jackson","MS",32.32,-90.2,43269598],
		["Glenview","KY",38.3,-85.65,43269599],
		["Masonic Home","KY",38.26,-85.65,43269600],
		["Nazareth","KY",37.83,-85.46,43269601],
		["Nerinx","KY",37.66,-85.42,43269602],
		["Louisville","KY",38.22,-85.74,43269603],
		["Maple Mount","KY",37.69,-87.32,43269604],
		["Mount Saint Joseph","OH",39.11,-84.61,43269605],
		["Dayton","OH",39.77,-84.19,43269606],
		["Cynthiana","OH",39.17,-83.34,43269607],
		["West Lafayette","IN",40.44,-86.91,43269608],
		["Lakeland","MI",42.46,-83.85,43269609],
		["Detroit","MI",42.38,-83.1,43269610],
		["Detroit","MI",42.38,-83.1,43269611],
		["Detroit","MI",42.38,-83.1,43269612],
		["Warren","MI",42.49,-83.02,43269613],
		["Flint","MI",43.02,-83.69,43269614],
		["Flint","MI",43.02,-83.69,43269615],
		["Flint","MI",43.02,-83.69,43269616],
		["Midland","MI",43.62,-84.22,43269617],
		["University Center","MI",43.55,-83.98,43269618],
		["Lansing","MI",42.7,-84.55,43269619],
		["Nazareth","MI",42.35,-85.52,43269620],
		["Macatawa","MI",42.76,-86.2,43269621],
		["Popejoy","IA",42.59,-93.42,43269622],
		["Des Moines","IA",41.57,-93.61,43269623],
		["Milwaukee","WI",43.06,-87.96,43269624],
		["Madison","WI",43.07,-89.38,43269625],
		["Mather","WI",44.14,-90.29,43269626],
		["Millston","WI",44.19,-90.64,43269627],
		["King","WI",44.33,-89.13,43269628],
		["Saint Paul","MN",44.94,-93.1,43269629],
		["Saint Paul","MN",44.94,-93.1,43269630],
		["Rochester","MN",44.01,-92.47,43269631],
		["Flom","MN",47.16,-96.13,43269632],
		["Sioux Falls","SD",43.54,-96.73,43269633],
		["Saint Charles","SD",43.12,-99.08,43269634],
		["Pillsbury","ND",47.2,-97.79,43269635],
		["Bismarck","ND",46.8,-100.76,43269636],
		["Minot","ND",48.23,-101.29,43269637],
		["Decker","MT",45.12,-106.64,43269638],
		["Sanders","MT",46.27,-107.07,43269639],
		["Silver Gate","MT",45.01,-109.96,43269640],
		["Neihart","MT",46.93,-110.7,43269641],
		["Fort Harrison","MT",46.61,-112.11,43269642],
		["Hines","IL",41.85,-87.83,43269643],
		["Rockford","IL",42.27,-89.06,43269644],
		["Earth City","MO",38.77,-90.46,43269645],
		["Liguori","MO",38.33,-90.43,43269646],
		["Saint Louis","MO",38.63,-90.24,43269647],
		["Plevna","MO",39.97,-92.08,43269648],
		["Poplar Bluff","MO",36.76,-90.4,43269649],
		["Kansas City","MO",39.09,-94.58,43269650],
		["Arrow Rock","MO",39.07,-92.94,43269651],
		["Kansas City","KS",39.1,-94.62,43269652],
		["Kansas City","KS",39.1,-94.62,43269653],
		["Topeka","KS",39.03,-95.69,43269654],
		["Waldron","KS",37,-98.18,43269655],
		["Wichita","KS",37.68,-97.34,43269656],
		["Fort Dodge","KS",37.73,-99.93,43269657],
		["New Orleans","LA",30.06,-89.93,43269658],
		["New Orleans","LA",30.06,-89.93,43269659],
		["Baton Rouge","LA",30.44,-91.12,43269660],
		["Baton Rouge","LA",30.44,-91.12,43269661],
		["Searcy","AR",35.24,-91.73,43269662],
		["Norman","OK",35.22,-97.34,43269663],
		["Wagoner","OK",35.95,-95.38,43269664],
		["Dallas","TX",32.79,-96.76,43269665],
		["Dallas","TX",32.79,-96.76,43269666],
		["Temple","TX",31.09,-97.36,43269667],
		["Davilla","TX",30.76,-97.18,43269668],
		["Silver","TX",32.07,-100.68,43269669],
		["Houston","TX",29.76,-95.38,43269670],
		["Pasadena","TX",29.66,-95.15,43269671],
		["Kerrville","TX",30.04,-99.13,43269672],
		["Aransas Pass","TX",27.9,-97.14,43269673],
		["Dryden","TX",30.05,-102.12,43269674],
		["Langtry","TX",29.81,-101.56,43269675],
		["Quail","TX",34.9,-100.4,43269676],
		["Pep","TX",33.81,-102.56,43269677],
		["Alpine","TX",30.36,-103.66,43269678],
		["El Paso","TX",31.84,-106.43,43269679],
		["Denver","CO",39.76,-104.87,43269680],
		["Denver","CO",39.76,-104.87,43269681],
		["Denver","CO",39.76,-104.87,43269682],
		["Denver","CO",39.76,-104.87,43269683],
		["Golden","CO",39.73,-105.21,43269684],
		["Pinecliffe","CO",39.93,-105.43,43269685],
		["Rand","CO",40.45,-106.17,43269686],
		["Colorado Springs","CO",38.88,-104.67,43269687],
		["Monarch","CO",38.53,-106.05,43269688],
		["Mesa Verde National Park","CO",37.18,-108.49,43269689],
		["Walcott","WY",41.81,-106.87,43269690],
		["Arminto","WY",43.18,-107.26,43269691],
		["Four Corners","WY",44.08,-104.13,43269692],
		["Atomic City","ID",43.4,-112.83,43269693],
		["Sun Valley","ID",43.67,-114.33,43269694],
		["Atlanta","ID",43.8,-115.15,43269695],
		["Salt Lake City","UT",40.76,-111.89,43269696],
		["Salt Lake City","UT",40.77,-111.9,43269697],
		["Cisco","UT",38.97,-109.31,43269698],
		["Thompson","UT",39.18,-109.49,43269699],
		["Tucson","AZ",32.19,-111,43269700],
		["Tucson","AZ",32.24,-110.94,43269701],
		["Tucson","AZ",32.2,-110.94,43269702],
		["North Rim","AZ",36.3,-112.08,43269703],
		["Fairacres","NM",32.31,-107.06,43269704],
		["Garita","NM",35.35,-104.48,43269705],
		["Valmy","NV",40.75,-117.1,43269706],
		["Carson City","NV",39.07,-119.79,43269707],
		["Jarbidge","NV",41.83,-115.54,43269708],
		["Los Angeles","CA",34.05,-118.25,43269709],
		["Los Angeles","CA",34.04,-118.25,43269710],
		["Dodgertown","CA",34.07,-118.24,43269711],
		["Torrance","CA",33.88,-118.33,43269712],
		["Carson","CA",33.86,-118.25,43269713],
		["Long Beach","CA",33.76,-118.2,43269714],
		["Woodland Hills","CA",34.18,-118.57,43269715],
		["Universal City","CA",34.13,-118.35,43269716],
		["San Diego","CA",32.71,-117.16,43269717],
		["Vidal","CA",34.14,-114.52,43269718],
		["Amboy","CA",34.45,-115.54,43269719],
		["San Luis Obispo","CA",35.27,-120.73,43269720],
		["Darwin","CA",36.3,-117.59,43269721],
		["Kings Canyon National Pk","CA",36.72,-118.95,43269722],
		["Monterey","CA",36.57,-121.83,43269723],
		["San Francisco","CA",37.61,-122.38,43269724],
		["Richmond","CA",37.94,-122.38,43269725],
		["Pinecrest","CA",38.34,-119.83,43269726],
		["Dos Rios","CA",39.73,-123.33,43269727],
		["Eldridge","CA",38.34,-122.51,43269728],
		["Richvale","CA",39.48,-121.73,43269729],
		["South Lake Tahoe","CA",38.78,-120.07,43269730],
		["Kalaupapa","HI",21.19,-156.98,43269731],
		["Honolulu","HI",21.3,-157.85,43269732],
		["Portland","OR",45.53,-122.56,43269733],
		["Crater Lake","OR",42.92,-122.12,43269734],
		["Seattle","WA",47.6,-122.33,43269735],
		["Seattle","WA",47.44,-122.31,43269736],
		["Seattle","WA",47.6,-122.33,43269737],
		["Seattle","WA",47.65,-122.29,43269738],
		["Manchester","WA",47.55,-122.54,43269739],
		["Camp Murray","WA",47.25,-122.44,43269740],
		["Oysterville","WA",46.56,-124.03,43269741],
		["Stratford","WA",47.44,-119.27,43269742],
		["Naches","WA",46.74,-121.14,43269743],
		["Pullman","WA",46.72,-117.15,43269744],
		["Anchorage","AK",61.22,-149.88,43269745],
		["Anchorage","AK",61.21,-149.88,43269746],
		["Clear","AK",64.28,-149.19,43269747],
		["Fairbanks","AK",64.83,-147.88,43269748],
		["Bettles Field","AK",67.24,-152.27,43269749],
		["Chicken","AK",63.86,-142.12,43269750]

    ];

    return {
//        first: first,
        location: location
   
    };
});   
   
   
   
   
   
   
   

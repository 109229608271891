/**
 * @name core.season
 * @namespace Somewhat of a hodgepodge. Basically, this is for anything related to a single season that doesn't deserve to be broken out into its own file. Currently, this includes things that happen when moving between phases of the season (i.e. regular season to playoffs) and scheduling. As I write this, I realize that it might make more sense to break up those two classes of functions into two separate modules, but oh well.
 */
define('core/season',["dao","db", "globals", "ui", "core/contractNegotiation", "core/draft", "core/finances", "core/freeAgents", "core/player", "core/team" ,"lib/bluebird", "lib/jquery", "lib/underscore", "util/account", "util/eventLog", "util/helpers", "util/message", "util/random"], function (dao,db, g, ui, contractNegotiation, draft, finances, freeAgents, player, team, Promise, $, _, account, eventLog, helpers, message, random) {

"use strict";

    var phaseText;

    /**
     * Update g.ownerMood based on performance this season.
     *
     * This is based on three factors: regular season performance, playoff performance, and finances. Designed to be called after the playoffs end.
     *
     * @memberOf core.season
     * @param {(IDBTransaction|null)} tx An IndexedDB transaction on gameAttributes and and teams, readwrite.
     * @return {Promise.Object} Resolves to an object containing the changes in g.ownerMood this season.
     */
    function updateOwnerMood(tx) {
        return team.filter({
			ot: tx,
            seasonAttrs: ["won", "playoffRoundsWon","playoffRoundsWonNational", "profit"],
            season: g.season,
            tid: g.userTid
        }).then(function (t) {
            var deltas, ownerMood;

            deltas = {};
            deltas.wins = 0.25 * (t.won - 6) / 6;

            /*if (t.playoffRoundsWon < 0) {
                deltas.playoffs = -0.2;
            } else if (t.playoffRoundsWon < 1) {
                deltas.playoffs = 0.10 * t.playoffRoundsWon;
            } else {
                deltas.playoffs = 0.2;
            }

			//playoffRoundsWonNational

            deltas.money = (t.profit - 15) / 100;*/


			if (g.gameType < 3) {
					if (t.playoffRoundsWonNational < 0) {
						deltas.playoffs = -.2;
					} else if (t.playoffRoundsWonNational < 165) {
		//            } else if (t.playoffRoundsWon < 4) {
						deltas.playoffs = 0.1 * t.playoffRoundsWonNational/240 +.02;
					} else {
						deltas.playoffs = 0.2;
					}
		/*	} else if (g.gameType === 1) {
					if (t.playoffRoundsWonNational < 0) {
						deltas.playoffs = -.2;
					} else if (t.playoffRoundsWonNational < 165) {
		//            } else if (t.playoffRoundsWon < 4) {
						deltas.playoffs = 0.1 * t.playoffRoundsWonNational/240+.02;
					} else {
						deltas.playoffs = 0.2;
					}

			} else if (g.numTeams === 120) {
					if (t.playoffRoundsWonNational < 0) {
						deltas.playoffs = -.2;
					} else if (t.playoffRoundsWonNational < 165) {
		//            } else if (t.playoffRoundsWon < 4) {
						deltas.playoffs = 0.1 * t.playoffRoundsWonNational/240+.02;
					} else {
						deltas.playoffs = 0.2;
					}*/
			} else if (g.gameType === 5) {
				if (t.playoffRoundsWonNational < 0) {
					deltas.playoffs = -.2;
				} else if (t.playoffRoundsWonNational < 6) {
	//            } else if (t.playoffRoundsWon < 4) {
					deltas.playoffs = 0.02 * t.playoffRoundsWonNational;
				} else {
					deltas.playoffs = 0.2;
				}
			} else {
				if (t.playoffRoundsWonNational < 0) {
					deltas.playoffs = -.2;
				} else if (t.playoffRoundsWonNational < 5) {
	//            } else if (t.playoffRoundsWon < 4) {
					deltas.playoffs = 0.025 * t.playoffRoundsWonNational;
				} else {
					deltas.playoffs = 0.2;
				}
			}
			/*} else if (g.numTeams === 320) {
				if (t.playoffRoundsWonNational < 0) {
					deltas.playoffs = -.2;
				} else if (t.playoffRoundsWonNational < 6) {
	//            } else if (t.playoffRoundsWon < 4) {
					deltas.playoffs = 0.02 * t.playoffRoundsWonNational;
				} else {
					deltas.playoffs = 0.2;
				}

			} else {
					if (t.playoffRoundsWonNational < 0) {
						deltas.playoffs = -.2;
					} else if (t.playoffRoundsWonNational < 165) {
		//            } else if (t.playoffRoundsWon < 4) {
						deltas.playoffs = 0.01 * t.playoffRoundsWonNational/240;
					} else {
						deltas.playoffs = 0.2;
					}
			}*/



            if (t.playoffRoundsWon < 0) {
                deltas.money = -0.2;
            } else if (t.playoffRoundsWon < 1) {
                deltas.money = 0.01;
            } else {
                deltas.money = 0.2;
            }



            return Promise.try(function () {

            // Only update owner mood if grace period is over
            if (g.season >= g.gracePeriodEnd) {
                ownerMood = {};
                ownerMood.wins = g.ownerMood.wins + deltas.wins;
                ownerMood.playoffs = g.ownerMood.playoffs + deltas.playoffs;
                ownerMood.money = g.ownerMood.money + deltas.money;

                // Bound only the top - can't win the game by doing only one thing, but you can lose it by neglecting one thing
                if (ownerMood.wins > 1) { ownerMood.wins = 1; }
                if (ownerMood.playoffs > 1) { ownerMood.playoffs = 1; }
                if (ownerMood.money > 1) { ownerMood.money = 1; }

                    return require("core/league").setGameAttributes(tx, {ownerMood: ownerMood});
                }
            }).then(function () {
                return deltas;
            });
        });
    }

    /**
     * Compute the awards (MVP, etc) after a season finishes.
     *
     * The awards are saved to the "awards" object store.
     *
     * @memberOf core.season
     * @return {Promise}
     */
    function awards(tx) {
        var awards, awardsByPlayer, saveAwardsByPlayer;

        awards = {season: g.season};

        // [{pid, type}]
        awardsByPlayer = [];

        saveAwardsByPlayer = function (awardsByPlayer) {
            var  pids;

            pids = _.uniq(_.pluck(awardsByPlayer, "pid"));


            return Promise.map(pids, function (pid) {
                return dao.players.get({ot: tx, key: pid}).then(function (p) {
                    var i;

                        for (i = 0; i < awardsByPlayer.length; i++) {
                            if (p.pid === awardsByPlayer[i].pid) {
                                p.awards.push({season: g.season, type: awardsByPlayer[i].type});
                            }
                        }

                    return dao.players.put({ot: tx, value: p});
                });
            });
        };


        //tx = dao.tx(["players", "playerStats", "releasedPlayers", "teams"]);

        // Get teams for won/loss record for awards, as well as finding the teams with the best records
        return team.filter({
            attrs: ["tid", "abbrev", "region", "name", "cid"],
            seasonAttrs: ["won", "lost", "winp", "playoffRoundsWon"],
            season: g.season,
            sortBy: "winp",
            ot: tx
        }).then(function (teams) {
            var  i, foundEast, foundWest, t;


            var found0, found1,found2, found3, found4, i, t;

            for (i = 0; i < teams.length; i++) {
                if (!found0 && teams[i].cid === 0) {
                    t = teams[i];
                    awards.bre = {tid: t.tid, abbrev: t.abbrev, region: t.region, name: t.name, won: t.won, lost: t.lost};
                    found0 = true;
                } else if (!found1 && teams[i].cid === 1) {
                    t = teams[i];
                    awards.brw = {tid: t.tid, abbrev: t.abbrev, region: t.region, name: t.name, won: t.won, lost: t.lost};
                    found1 = true;
                } else if (!found2 && teams[i].cid === 2) {
                    t = teams[i];
                    awards.br2 = {tid: t.tid, abbrev: t.abbrev, region: t.region, name: t.name, won: t.won, lost: t.lost};
                    found2 = true;
                } else if (!found3 && teams[i].cid === 3) {
                    t = teams[i];
                    awards.br3 = {tid: t.tid, abbrev: t.abbrev, region: t.region, name: t.name, won: t.won, lost: t.lost};
                    found3 = true;
                } else if (!found4  && teams[i].cid === 4) {
                    t = teams[i];
                    awards.br4 = {tid: t.tid, abbrev: t.abbrev, region: t.region, name: t.name, won: t.won, lost: t.lost};
                    found4 = true;
                }

                if (found0 && found1 && found2 && found3 && found4) {
                    break;
                }
            }


            /*for (i = 0; i < teams.length; i++) {
                if (!foundEast && teams[i].cid === 0) {
                    t = teams[i];
                    awards.bre = {tid: t.tid, abbrev: t.abbrev, region: t.region, name: t.name, won: t.won, lost: t.lost};
                    foundEast = true;
                } else if (!foundWest && teams[i].cid === 1) {
                    t = teams[i];
                    awards.brw = {tid: t.tid, abbrev: t.abbrev, region: t.region, name: t.name, won: t.won, lost: t.lost};
                    foundWest = true;
                }

                if (foundEast && foundWest) {
                    break;
                }
            }*/

            // Sort teams by tid so it can be easily used in awards formulas
            teams.sort(function (a, b) { return a.tid - b.tid; });

            return [teams, dao.players.getAll({
                ot: tx,
                index: "tid",
                key: IDBKeyRange.lowerBound(g.PLAYER.FREE_AGENT), // Any non-retired player can win an award
                statsSeasons: [g.season]
            })];
        }).spread(function (teams, players) {
                var champTid, i, p,   type;

                players = player.filter(players, {
                    attrs: ["pid", "name", "tid", "abbrev", "draft","pos"],
					ratings : ["ovr", "ins", "blk", "stre"],
                    stats: ["gp", "gs", "min", "pts", "trb", "ast", "blk", "stl", "ewa","qbr","orb","drb","stl","derpa","dec","olr","derpatp","intery","fgaMidRange","der","dep"],
                    season: g.season
                });

                // Add team games won to players
                for (i = 0; i < players.length; i++) {
                    // Special handling for players who were cut mid-season
                    if (players[i].tid >= 0) {
                        players[i].won = teams[players[i].tid].won;
                    } else {
                        players[i].won = 4;
                        //players[i].won = 3;
                    }
                }

                // Rookie of the Year
				if (g.statType == 0) {
					players.sort(function(a, b) {
						return (b.ratings.ovr - a.ratings.ovr);
					});
				} else {
					players.sort(function (a, b) {  return b.stats.orb - a.stats.orb + b.stats.drb - a.stats.drb + b.stats.stl - a.stats.stl; }); // Same formula as MVP, but no wins because some years with bad rookie classes can have the wins term dominate EWA
				}
//				players.sort(function (a, b) {  return b.ratings.ovr - a.ratings.ovr; }); // Same formula as MVP, but no wins because some years with bad rookie classes can have the wins term dominate EWA
//                players.sort(function (a, b) {  return b.stats.orb - a.stats.orb + b.stats.drb - a.stats.drb + b.stats.stl - a.stats.stl; }); // Same formula as MVP, but no wins because some years with bad rookie classes can have the wins term dominate EWA
                for (i = 0; i < players.length; i++) {
                    // This doesn't factor in players who didn't start playing right after being drafted, because currently that doesn't really happen in the game.
                    if (players[i].draft.year === g.season - 1) {
                        break;
                    }
                }
                p = players[i];
                if (p !== undefined) { // I suppose there could be no rookies at all.. which actually does happen when skip the draft from the debug menu
                    awards.roy = {pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast, stl: p.stats.stl, drb: p.stats.drb, orb: p.stats.orb};
                    awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: "Freshman of the Year"});
                }

                // Most Valuable Player
				//players.sort(function (a, b) {  return b.ratings.ovr - a.ratings.ovr; }); // Same formula as MVP, but no wins because some years with bad rookie classes can have the wins term dominate EWA

				if (g.statType == 0) {
					players.sort(function(a, b) {
						return (b.ratings.ovr + b.ratings.ins + b.ratings.blk + b.ratings.stre + b.won * 3 - a.ratings.ovr - a.ratings.ins - a.ratings.blk - a.ratings.stre - a.won * 3);
					});
				} else {
					players.sort(function (a, b) {  return (b.stats.qbr + 1.0 * b.won*b.gs/16) - (a.stats.qbr + 1.0 * a.won*b.gs/16); });
				}
                p = players[0];
                awards.mvp = {pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast, stl: p.stats.stl, drb: p.stats.drb, orb: p.stats.orb};
                awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: "National Player of the Year"});
                // Notification unless it's the user's player, in which case it'll be shown below
           /*     if (p.tid !== g.userTid) {
                    eventLog.add(null, {
                        type: "award",
                        text: '<a href="' + helpers.leagueUrl(["player", p.pid]) + '">' + p.name + '</a> (<a href="' + helpers.leagueUrl(["roster", p.abbrev]) + '">' + p.abbrev + '</a>) won the Most Valuable Player award.'
                    });
                }*/

                // Sixth Man of the Year - same sort as MVP
         /*       players.sort(function (a, b) {  return (b.stats.qbr + 1.0 * b.won*b.gs/16) - (a.stats.qbr + 1.0 * a.won*b.gs/16); });
                for (i = 0; i < players.length; i++) {
                    // Must have come off the bench in most games
                    if (players[i].stats.gs === 0 || players[i].stats.gp / players[i].stats.gs > 2) {
                        break;
                    }
                }
                p = players[i];
                awards.smoy = {pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast};
                awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: "Offensive Player of the Year"});*/

                // All League Team - same sort as MVP
                // Rookie of the Year
				var numQB,numRB,numTE,numOL,numWR,numDone;
				numQB = 0;
				numRB = 0;
				numTE = 0;
				numOL = 0;
				numWR = 0;
				numDone = 0;
				if (g.statType == 0) {
					players.sort(function(a, b) {
						return (b.ratings.ovr - a.ratings.ovr);
					});
				} else {
					players.sort(function (a, b) {  return b.stats.olr*4 + b.stats.orb - a.stats.orb + b.stats.drb - a.stats.olr*4- a.stats.drb + b.stats.stl - a.stats.stl; }); // Same formula as MVP, but no wins because some years with bad rookie classes can have the wins term dominate EWA
				}


                awards.allLeague = [{title: "All American - Offense", players: []}];
                type = "All American Offensive Team";
                /*for (i = 0; i < 11; i++) {
                    p = players[i];
						_.last(awards.allLeague).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast});
						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
                } */


                for (i = 0; i < players.length; i++) {
                    p = players[i];

//						_.last(awards.allLeague).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast});
//						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
			//		// console.log(p.pos);
			//		// console.log(numQB);
					if ((p.pos == "QB") && (numQB < 1)) {
						type = "All American Offensive Team - QB";
						_.last(awards.allLeague).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast});
						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numQB += 1;
						numDone += 1;
			//			// console.log("numQB: "+numQB+" numDone: "+numDone);
					}
					if ((p.pos == "RB") && (numRB < 2)) {
						type = "All American Offensive Team - RB";
						_.last(awards.allLeague).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast});
						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numRB += 1;
						numDone += 1;
				//		// console.log("numRB: "+numRB+" numDone: "+numDone);
					}
					if ((p.pos == "TE") && (numTE < 1)) {
						type = "All American Offensive Team - TE";
						_.last(awards.allLeague).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast});
						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numTE += 1;
						numDone += 1;
					}
					if ((p.pos == "WR") && (numWR < 2)) {
						type = "All American Offensive Team - WR";

						_.last(awards.allLeague).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast});
						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numWR += 1;
						numDone += 1;
					/*	if (numWR == 3) {
							numDone += 1;
						}*/
					}
					if ((p.pos == "OL") && (numOL < 5)) {
						type = "All American Offensive Team - OL";

						_.last(awards.allLeague).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast});
						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numOL += 1;
						numDone += 1;
					/*	if (numOL == 5) {
							numDone += 1;
						}*/
					}
					if (numDone >= 11) {
						i = players.length;
					}

                }

                // Defensive Player of the Year
				if (g.statType == 0) {
					players.sort(function(a, b) {
						return (b.ratings.ovr - a.ratings.ovr);
					});
				} else {
					players.sort(function (a, b) {  return (b.stats.fgaMidRange) - (a.stats.fgaMidRange) +(b.stats.dec- b.stats.der*15 - b.stats.dep*5) - (a.stats.dec - a.stats.der*15 - a.stats.dep*5); });
				}

//                players.sort(function (a, b) {  return (b.stats.dec- b.stats.derpa*2) - (a.stats.dec - a.stats.derpa*2); });
                p = players[0];
				if ((p.pos == "S") || (p.pos == "CB")  || (p.pos == "LB")) {
					awards.dpoy = {pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, trb: p.stats.trb, blk: p.stats.blk, stl: p.stats.stl, fgaMidRange: p.stats.fgaMidRange, intery: p.stats.intery, derpatp: p.stats.derpatp};
					awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: "Defensive Player of the Year"});
				} else {
					players.sort(function (a, b) {  return (b.stats.fgaMidRange)*10 - (a.stats.fgaMidRange)*10  - (b.stats.der) + (a.stats.der); });

//					players.sort(function (a, b) {  return (b.stats.fgaMidRange) - (a.stats.fgaMidRange); });
					p = players[0];
					awards.dpoy = {pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, trb: p.stats.trb, blk: p.stats.blk, stl: p.stats.stl, fgaMidRange: p.stats.fgaMidRange, intery: p.stats.intery, derpatp: p.stats.derpatp};
					awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: "Defensive Player of the Year"});
				}


//              players.sort(function (a, b) {  return -(b.stats.dec/b.stats.derpa) + (a.stats.dec/a.stats.derpa); });
    //            players.sort(function (a, b) {  return (b.stats.dec- b.stats.der - b.stats.dep) - (a.stats.dec - a.stats.der - a.stats.dep); });
                players.sort(function (a, b) {  return (b.stats.fgaMidRange) - (a.stats.fgaMidRange) + (b.stats.dec- b.stats.der*15 - b.stats.dep*5) - (a.stats.dec - a.stats.der*15 - a.stats.dep*5); });
//                players.sort(function (a, b) {  return (b.stats.dec- b.stats.derpa*2) - (a.stats.dec - a.stats.derpa*2); });
                // All Defensive Team - same sort as DPOY
			//	var numCB,numS,numLB,numDL;
				var numCB,numS,numLB,numDL;

				numCB = 0;
				numS = 0;
				numLB = 0;
				numDL = 0;
				numDone = 0;

                awards.allDefensive = [{title: "All American - Defense", players: []}];
                type = "All American Defensive Team";
/*                for (i = 0; i < 11; i++) {
                    p = players[i];
                    _.last(awards.allDefensive).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, trb: p.stats.trb, blk: p.stats.blk, stl: p.stats.stl});
                    awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
                }*/


                for (i = 0; i < players.length; i++) {
                    p = players[i];

//						_.last(awards.allLeague).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.stats.pts, trb: p.stats.trb, ast: p.stats.ast});
//						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
			//		// console.log(p.pos);
			//		// console.log(numQB);
					if ((p.pos == "CB") && (numCB < 2)) {
						type = "All American Defensive Team - CB";
                    _.last(awards.allDefensive).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, trb: p.stats.trb, blk: p.stats.blk, stl: p.stats.stl});
                    awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numCB += 1;
						numDone += 1;
			//			// console.log("numQB: "+numQB+" numDone: "+numDone);
					}
					if ((p.pos == "S") && (numS < 2)) {
						type = "All American Defensive Team - S";
                    _.last(awards.allDefensive).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, trb: p.stats.trb, blk: p.stats.blk, stl: p.stats.stl});
                    awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numS += 1;
						numDone += 1;
				//		// console.log("numRB: "+numRB+" numDone: "+numDone);
					}
					if ((p.pos == "LB") && (numLB < 3)) {
						type = "All American Defensive Team - LB";
                    _.last(awards.allDefensive).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, trb: p.stats.trb, blk: p.stats.blk, stl: p.stats.stl});
                    awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numLB += 1;
						numDone += 1;
					}

					if (numDone >= 7) {
						i = players.length;
					}

                }


                players.sort(function (a, b) {  return (b.stats.fgaMidRange)*10 - (a.stats.fgaMidRange)*10  - (b.stats.der) + (a.stats.der); });
                // All Defensive Team - same sort as DPOY


				numCB = 0;
				numS = 0;
				numLB = 0;
				numDL = 0;
				numDone = 0;

          //      awards.allDefensive = [{title: "All American - Defense", players: []}];
          //      type = "All American Defensive Team";
/*                for (i = 0; i < 11; i++) {
                    p = players[i];
                    _.last(awards.allDefensive).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, trb: p.stats.trb, blk: p.stats.blk, stl: p.stats.stl});
                    awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
                }*/


                for (i = 0; i < players.length; i++) {
                    p = players[i];

					if ((p.pos == "DL") && (numDL < 4)  ) {
						type = "All American Defensive Team - DL";

						_.last(awards.allDefensive).players.push({pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, trb: p.stats.trb, blk: p.stats.blk, stl: p.stats.stl});
						awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: type});
						numDL += 1;
						numDone += 1;
					/*	if (numWR == 3) {
							numDone += 1;
						}*/
					}
					if (numDone >= 4) {
						i = players.length;
					}

                }



                // Finals MVP - most WS in playoffs
                for (i = 0; i < teams.length; i++) {
                    if (teams[i].playoffRoundsWon === 1) {
                        champTid = teams[i].tid;
                        break;
                    }
                }
                // Need to read from DB again to really make sure I'm only looking at players from the champs. player.filter might not be enough. This DB call could be replaced with a loop manually checking tids, though.
            return [champTid, dao.players.getAll({
                ot: tx,
                index: "tid",
                key: champTid,
                statsSeasons: [g.season],
                statsTid: champTid,
                statsPlayoffs: true
            })];
        }).spread(function (champTid, players) {
            var i, p, text;
                    players = player.filter(players, { // Only the champions, only playoff stats
                        attrs: ["pid", "name", "tid", "abbrev"],
                        stats: ["pts", "trb", "ast", "ewa","orb","drb","stl"],
                        season: g.season,
                        playoffs: true,
                        tid: champTid
                    });
                    players.sort(function (a, b) {  return b.statsPlayoffs.orb - a.statsPlayoffs.orb + b.statsPlayoffs.drb - a.statsPlayoffs.drb + b.statsPlayoffs.stl - a.statsPlayoffs.stl ; });
                    p = players[0];
                    awards.finalsMvp = {pid: p.pid, name: p.name, tid: p.tid, abbrev: p.abbrev, pts: p.statsPlayoffs.pts, trb: p.statsPlayoffs.trb, ast: p.statsPlayoffs.ast, stl: p.statsPlayoffs.stl, drb: p.statsPlayoffs.drb, orb: p.statsPlayoffs.orb};
                    awardsByPlayer.push({pid: p.pid, tid: p.tid, name: p.name, type: "National Championship MVP"});

            /* tx = dao.tx("awards", "readwrite");
            dao.awards.put({ot: tx, value: awards});
            return tx.complete().then(function () {*/
			return dao.awards.put({ot: tx, value: awards}).then(function () {
                return saveAwardsByPlayer(awardsByPlayer);
            }).then(function () {

                        // Notifications for awards for user's players
                       // tx = dao.tx("events", "readwrite");
                for (i = 0; i < awardsByPlayer.length; i++) {
                    p = awardsByPlayer[i];

                    text = '<a href="' + helpers.leagueUrl(["player", p.pid]) + '">' + p.name + '</a> (<a href="' + helpers.leagueUrl(["roster", g.teamAbbrevsCache[p.tid], g.season]) + '">' + g.teamAbbrevsCache[p.tid] + '</a>) ';
                    if (p.type.indexOf("Team") >= 0) {
                        text += 'made the ' + p.type + '.';
                    } else {
                        text += 'won the ' + p.type + ' award.';
                    }
                    eventLog.add(null, {
                        type: "award",
                        text: text,
                        showNotification: p.tid === g.userTid || p.type === "Player of the Year",
                        pids: [p.pid],
                        tids: [p.tid]
                    });
                }
            });
        });
    }




    /**
     * Get an array of games from the schedule.
     *
     * @param {(IDBObjectStore|IDBTransaction|null)} options.ot An IndexedDB object store or transaction on schedule; if null is passed, then a new transaction will be used.
     * @param {boolean} options.oneDay Number of days of games requested. Default false.
     * @return {Promise} Resolves to the requested schedule array.
     */
    function getSchedule(options) {
        options = options !== undefined ? options : {};
        options.ot = options.ot !== undefined ? options.ot : null;
        options.oneDay = options.oneDay !== undefined ? options.oneDay : false;

        return dao.schedule.getAll({ot: options.ot}).then(function (schedule) {
            var i, tids;

            if (options.oneDay) {
                schedule = schedule.slice(0, g.numTeams / 2);  // This is the maximum number of games possible in a day

                // Only take the games up until right before a team plays for the second time that day
                tids = [];
                for (i = 0; i < schedule.length; i++) {
                    if (tids.indexOf(schedule[i].homeTid) < 0 && tids.indexOf(schedule[i].awayTid) < 0) {
                        tids.push(schedule[i].homeTid);
                        tids.push(schedule[i].awayTid);
                    } else {
                        break;
                    }
                }
                schedule = schedule.slice(0, i);
            }

            return schedule;
        });
    }

    /**
     * Save the schedule to the database, overwriting what's currently there.
     *
     * @param {(IDBTransaction|null)} options.ot An IndexedDB transaction on schedule readwrite; if null is passed, then a new transaction will be used.
     * @param {Array} tids A list of lists, each containing the team IDs of the home and
            away teams, respectively, for every game in the season, respectively.
     * @return {Promise}
     */
    function setSchedule(tx, tids) {
        var i, newSchedule;

		//console.log(tids.length);
        newSchedule = [];
        for (i = 0; i < tids.length; i++) {
            newSchedule.push({
                homeTid: tids[i][0],
                awayTid: tids[i][1]
            });
        }

        tx = dao.tx("schedule", "readwrite", tx);

        return dao.schedule.clear({ot: tx}).then(function () {
            return Promise.map(newSchedule, function (matchup) {
                return dao.schedule.add({ot: tx, value: matchup});
            });
        });
    }

    /**
     * Creates a new regular season schedule for 30 teams.
     *
     * This makes an NBA-like schedule in terms of conference matchups, division matchups, and home/away games.
     *
     * @memberOf core.season
     * @return {Array.<Array.<number>>} All the season's games. Each element in the array is an array of the home team ID and the away team ID, respectively.
     */
    function newScheduleDefault() {
        var cid, dids, game, games, good, i, ii, iters, j, jj, k, matchup, matchups, n, newMatchup, t, teams, tids, tidsByConf, tryNum;
		var homeGames;

        teams = helpers.getTeamsDefault(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.

        tids = [];  // tid_home, tid_away

        // Collect info needed for scheduling
        for (i = 0; i < teams.length; i++) {
            teams[i].homeGames = 0;
            teams[i].awayGames = 0;
        }
        for (i = 0; i < teams.length; i++) {

            for (j = 0; j < teams.length; j++) {
                if (teams[i].tid !== teams[j].tid) {
                    game = [teams[i].tid, teams[j].tid];

                    // Constraint: 1 home game vs. each team in other conference
                    if (teams[i].cid !== teams[j].cid) {
                        //tids.push(game);
                        teams[i].homeGames += 0;
                        teams[j].awayGames += 0;
                    }

//                    // Constraint: 2 home schedule vs. each team in same division
                    // Constraint: 1 home schedule vs. each team in same division
					// 6 games, 10 left
                    if (teams[i].did === teams[j].did) {
                        tids.push(game);
                        //tids.push(game);
                        teams[i].homeGames += 1;
                        teams[j].awayGames += 1;
                    }

                    // Constraint: 1-2 home schedule vs. each team in same conference and different division
                    // Only do 1 now
					// home against team 1 ahead, away against team 1 back
                    if (teams[i].cid === teams[j].cid && teams[i].did !== teams[j].did) {
                        //tids.push(game);
                        teams[i].homeGames += 0;
                        teams[j].awayGames += 0;
                    }


					// could make this very specific

                }
            }
        }


		                    // same conference different division
					// diff conference
					// play teams 8 before home, and 8 after away
        for (i = 0; i < teams.length; i++) {
		      //      // console.log("i: "+teams[i].tid+" division "+teams[i].did+" teams.length: "+teams.length);
					homeGames = 0;
					k= i+1;
					if (k > (teams.length-1)) {
						k=0;
					}
					while (homeGames < 5) {
		      //      // console.log("homeGames: "+homeGames+" k: "+k+" teams.length: "+teams.length+" teams[k].awayGames: "+teams[k].awayGames);

				//		if (teams[i].tid !== teams[k].tid) {
							game = [teams[i].tid, teams[k].tid];

						//		// console.log("k: "+teams[k].tid+" division "+teams[k].did+" homeGames "+homeGames+" awayGames "+teams[k].awayGames);

							if ((teams[i].did !== teams[k].did) &&  (teams[k].awayGames <8)) {
//							if ((teams[i].did !== teams[k].did)) {

								tids.push(game);
								teams[i].homeGames += 1;
								teams[k].awayGames += 1;
								homeGames +=1;
							}
							k += 1;
							if (k > (teams.length-1)) {
								k=0;
							}
				//		}
					}
        }

	//    // console.log("finished");



        return tids;
    }


    /**
     * Creates a new regular season schedule for 30 teams.
     *
     * This makes an NBA-like schedule in terms of conference matchups, division matchups, and home/away games.
     *
     * @memberOf core.season
     * @return {Array.<Array.<number>>} All the season's games. Each element in the array is an array of the home team ID and the away team ID, respectively.
     */
//    function newSchedule40Default(teams) {
    function newSchedule40Default() {
//    function newScheduleDefault() {
        var cid, dids, game, games, good, i, ii, iters, j, jj, k, matchup, matchups, n, newMatchup, t, teams, tids, tidsByConf, tryNum;
		var tryNum2;
		var numGames2;


	//	if ((g.gameType == 2) || (g.gameType == 4)) {
	//		teams = helpers.getTeams320Default(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.

	//	} else if (g.gameType == 0){
			teams = helpers.getTeams40Default(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.

	//	} else {
	//		teams = helpers.getTeams80Default(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.
	//	}
        tids = [];  // tid_home, tid_away



        var numGames, numRemaining, numWithRemaining;
		var count;

		var numberConferences,numberConferenceTeams;
//        numGames = 82;
        numGames = 5;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        tids = [];
        while (tids.length < numGames * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);
			count = 0;
			//    // console.log("countb: "+count);
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].cid === teams[j].cid) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if ((numRemaining[i] <= 0) && (numRemaining[j] <= 0)) {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);

				} else  if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				}

				count += 1;
            }
			    // console.log("count: "+count);

			if (count>9999) {
			  ii = 0;
			  if ((numRemaining[i] <= 0) && (numRemaining[j] <= 0)) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[jj] > 0) ) {
						j = jj;
						jj = g.numTeams;
						for (ii = 0; ii < g.numTeams; ii++) {
							if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].cid != teams[j].cid)) {
								i = ii;
								ii = g.numTeams;
							}
						}
					}
				}
			  } else if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].cid != teams[j].cid)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  } else  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].cid != teams[jj].cid)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}



            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
		//	// console.log(numWithRemaining);
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }



//       numGames2 = 7;
       numGames2 = 7;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames2;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

		var cid,t,teamsDone,allTeams,cTeams;


//        for (cid = 0; cid < 5; cid++) {
			for (allTeams = 0; allTeams < g.numTeams; allTeams++) {
				for (cTeams = allTeams; cTeams < g.numTeams; cTeams++) {
				    if ((teams[allTeams].cid == teams[cTeams].cid) && (teams[allTeams].tid != teams[cTeams].tid)) {
					  if (Math.random() > .5 ) {
						game = [teams[allTeams].tid, teams[cTeams].tid];
						tids.push(game);
						teams[allTeams].homeGames += 1;
						teams[cTeams].awayGames += 1;
					  } else {
						game = [teams[cTeams].tid, teams[allTeams].tid];
						tids.push(game);
						teams[cTeams].homeGames += 1;
						teams[allTeams].awayGames += 1;
					  }
					}
				}
			}
			tidsByConf = [[], [],[], [], []];
			dids = [[], [],[], [], []];
			numberConferences = g.confs.length;
			numberConferenceTeams = 8-1;
console.log(numberConferences);
     
        for (i = 0; i < teams.length; i++) {
            tidsByConf[teams[i].cid].push(i);
            dids[teams[i].cid].push(teams[i].did);
        }

        for (cid = 0; cid < numberConferences; cid++) {
            matchups = [];
			    	matchups.push([0, 1, 2, 3, 4]);
            games = 0;
            while (games < 0) {
                newMatchup = [];
                n = 0;
                while (n <= numberConferenceTeams) {  // 7 = num teams in conference - 1
                    iters = 0;
                    while (true) {
//                        tryNum = random.randInt(0, 15);
                        tryNum = random.randInt(0, numberConferenceTeams);
                      //  tryNum2 = random.randInt(0, 4);
                        // Pick tryNum such that it is in a different division than n and has not been picked before
                        if (dids[cid][tryNum] !== dids[cid][n] && newMatchup.indexOf(tryNum) < 0) {
                            good = true;
                            // Check for duplicate games
                            for (j = 0; j < matchups.length; j++) {
                                matchup = matchups[j];
                                if (matchup[n] === tryNum) {
                                    good = false;
                                    break;
                                }
                            }
                            if (good) {
                                newMatchup.push(tryNum);
                                break;
                            }
                        }
                        iters += 1;
                        // Sometimes this gets stuck (for example, first 14 teams in fine but 15th team must play itself)
                        // So, catch these situations and reset the newMatchup
                        if (iters > 500) {
                            newMatchup = [];
                            n = -1;
                            break;
                        }
                    }
                    n += 1;
                }
                matchups.push(newMatchup);
                games += 1;
            }
            matchups.shift();  // Remove the first row in matchups
            for (j = 0; j < matchups.length; j++) {
                matchup = matchups[j];
                for (k = 0; k < matchup.length; k++) {
                    t = matchup[k];
                    ii = tidsByConf[cid][t];
                    jj = tidsByConf[cid][matchup[t]];
                    game = [teams[ii].tid, teams[jj].tid];
                    tids.push(game);
                    teams[ii].homeGames += 1;
                    teams[jj].awayGames += 1;
                }
            }
        }

        return tids;
    }

    /**
     * Creates a new regular season schedule for 30 teams.
     *
     * This makes an NBA-like schedule in terms of conference matchups, division matchups, and home/away games.
     *
     * @memberOf core.season
     * @return {Array.<Array.<number>>} All the season's games. Each element in the array is an array of the home team ID and the away team ID, respectively.
     */
//    function newSchedule40Default(teams) {
    function newSchedule80Default() {
//    function newScheduleDefault() {
        var cid, dids, game, games, good, i, ii, iters, j, jj, k, matchup, matchups, n, newMatchup, t, teams, tids, tidsByConf, tryNum;
		var tryNum2;
		var numGames2;

        teams = helpers.getTeams80Default(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.
        tids = [];  // tid_home, tid_away



        var numGames, numRemaining, numWithRemaining;
		var count;
//        numGames = 82;
        numGames = 4;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        tids = [];
        while (tids.length < numGames * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);
			count = 0;
			//    // console.log("countb: "+count);
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].cid === teams[j].cid) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
                //i = random.randInt(0, g.numTeams - 1);
                //j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].cid != teams[j].cid)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].cid != teams[jj].cid)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }



       numGames2 = 6;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames2;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        while (tids.length < (numGames+numGames2) * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);

			count = 0;
			//    // console.log("countb: "+count);
//            while ( (i === j || numRemaining[i] === 0 || numRemaining[j] === 0 || teams[i].cid === teams[j].cid) && (count < 100) ) {
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].did != teams[j].did) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
                //i = random.randInt(0, g.numTeams - 1);
                //j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].did == teams[j].did)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].did == teams[jj].did)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }

        // Constraint: 1-2 home schedule vs. each team in same conference and different division
        // Constraint: We need 8 more of these games per home team!
		// actually need 6
		// actual want 8 total, 4 home

		// want 1 more
        tidsByConf = [[], [],[], [], []];
        dids = [[], [],[], [], []];
        for (i = 0; i < teams.length; i++) {
            tidsByConf[teams[i].cid].push(i);
            dids[teams[i].cid].push(teams[i].did);
        }

        for (cid = 0; cid < 5; cid++) {
            matchups = [];
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]);
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15,16]);
//            matchups.push([0, 1, 2, 3, 4]);
            matchups.push([0, 1, 2, 3, 4,5,6,7,8]);
            games = 0;
//            while (games < 8) {
            while (games < 1) {
                newMatchup = [];
                n = 0;
                while (n <= 15) {  // 15 = num teams in conference - 1
                    iters = 0;
                    while (true) {
                        tryNum = random.randInt(0, 15);
//                        tryNum = random.randInt(0, 7);
                      //  tryNum2 = random.randInt(0, 4);
                        // Pick tryNum such that it is in a different division than n and has not been picked before
                        if (dids[cid][tryNum] !== dids[cid][n] && newMatchup.indexOf(tryNum) < 0) {
                            good = true;
                            // Check for duplicate games
                            for (j = 0; j < matchups.length; j++) {
                                matchup = matchups[j];
                                if (matchup[n] === tryNum) {
                                    good = false;
                                    break;
                                }
                            }
                            if (good) {
                                newMatchup.push(tryNum);
                                break;
                            }
                        }
                        iters += 1;
                        // Sometimes this gets stuck (for example, first 14 teams in fine but 15th team must play itself)
                        // So, catch these situations and reset the newMatchup
                        if (iters > 500) {
                            newMatchup = [];
                            n = -1;
                            break;
                        }
                    }
                    n += 1;
                }
                matchups.push(newMatchup);
                games += 1;
            }
            matchups.shift();  // Remove the first row in matchups
            for (j = 0; j < matchups.length; j++) {
                matchup = matchups[j];
                for (k = 0; k < matchup.length; k++) {
                    t = matchup[k];
                    ii = tidsByConf[cid][t];
                    jj = tidsByConf[cid][matchup[t]];
                    game = [teams[ii].tid, teams[jj].tid];
                    tids.push(game);
                    teams[ii].homeGames += 1;
                    teams[jj].awayGames += 1;
                }
            }
        }

        return tids;
    }


   /**
     * Creates a new regular season schedule for 30 teams.
     *
     * This makes an NBA-like schedule in terms of conference matchups, division matchups, and home/away games.
     *
     * @memberOf core.season
     * @return {Array.<Array.<number>>} All the season's games. Each element in the array is an array of the home team ID and the away team ID, respectively.
     */
//    function newSchedule40Default(teams) {
// was 80 need to do
    function newSchedule120Default() {
//    function newScheduleDefault() {
        var cid, dids, game, games, good, i, ii, iters, j, jj, k, matchup, matchups, n, newMatchup, t, teams, tids, tidsByConf, tryNum;
		var tryNum2;
		var numGames2;

        teams = helpers.getTeams120Default(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.
        tids = [];  // tid_home, tid_away



        var numGames, numRemaining, numWithRemaining;
		var count;
//        numGames = 82;
        numGames = 4;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        tids = [];
        while (tids.length < numGames * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);
			count = 0;
			//    // console.log("countb: "+count);
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].cid === teams[j].cid) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
                //i = random.randInt(0, g.numTeams - 1);
                //j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].cid != teams[j].cid)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].cid != teams[jj].cid)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }



       numGames2 = 6;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames2;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        while (tids.length < (numGames+numGames2) * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);

			count = 0;
			//    // console.log("countb: "+count);
//            while ( (i === j || numRemaining[i] === 0 || numRemaining[j] === 0 || teams[i].cid === teams[j].cid) && (count < 100) ) {
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].did != teams[j].did) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
                //i = random.randInt(0, g.numTeams - 1);
                //j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].did == teams[j].did)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].did == teams[jj].did)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }

        // Constraint: 1-2 home schedule vs. each team in same conference and different division
        // Constraint: We need 8 more of these games per home team!
		// actually need 6
		// actual want 8 total, 4 home

		// want 1 more
        tidsByConf = [[], [],[], [], [],[], [],[], [], []];
        dids = [[], [],[], [], [],[], [],[], [], []];
        for (i = 0; i < teams.length; i++) {
            tidsByConf[teams[i].cid].push(i);
            dids[teams[i].cid].push(teams[i].did);
        }

        for (cid = 0; cid < 10; cid++) {
            matchups = [];
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]);
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15,16]);
//            matchups.push([0, 1, 2, 3, 4]);
            matchups.push([0, 1, 2, 3, 4,5,6]);
            games = 0;
//            while (games < 8) {
            while (games < 1) {
                newMatchup = [];
                n = 0;
                while (n <= 11) {  // 15 = num teams in conference - 1
                    iters = 0;
                    while (true) {
                        tryNum = random.randInt(0, 11);
//                        tryNum = random.randInt(0, 7);
                      //  tryNum2 = random.randInt(0, 4);
                        // Pick tryNum such that it is in a different division than n and has not been picked before
                        if (dids[cid][tryNum] !== dids[cid][n] && newMatchup.indexOf(tryNum) < 0) {
                            good = true;
                            // Check for duplicate games
                            for (j = 0; j < matchups.length; j++) {
                                matchup = matchups[j];
                                if (matchup[n] === tryNum) {
                                    good = false;
                                    break;
                                }
                            }
                            if (good) {
                                newMatchup.push(tryNum);
                                break;
                            }
                        }
                        iters += 1;
                        // Sometimes this gets stuck (for example, first 14 teams in fine but 15th team must play itself)
                        // So, catch these situations and reset the newMatchup
                        if (iters > 500) {
                            newMatchup = [];
                            n = -1;
                            break;
                        }
                    }
                    n += 1;
                }
                matchups.push(newMatchup);
                games += 1;
            }
            matchups.shift();  // Remove the first row in matchups
            for (j = 0; j < matchups.length; j++) {
                matchup = matchups[j];
                for (k = 0; k < matchup.length; k++) {
                    t = matchup[k];
                    ii = tidsByConf[cid][t];
                    jj = tidsByConf[cid][matchup[t]];
                    game = [teams[ii].tid, teams[jj].tid];
                    tids.push(game);
                    teams[ii].homeGames += 1;
                    teams[jj].awayGames += 1;
                }
            }
        }

        return tids;
    }

   /**
     * Creates a new regular season schedule for 30 teams.
     *
     * This makes an NBA-like schedule in terms of conference matchups, division matchups, and home/away games.
     *
     * @memberOf core.season
     * @return {Array.<Array.<number>>} All the season's games. Each element in the array is an array of the home team ID and the away team ID, respectively.
     */
//    function newSchedule40Default(teams) {
// was 80, need to do
    function newSchedule180Default() {
//    function newScheduleDefault() {
        var cid, dids, game, games, good, i, ii, iters, j, jj, k, matchup, matchups, n, newMatchup, t, teams, tids, tidsByConf, tryNum;
		var tryNum2;
		var numGames2;

        teams = helpers.getTeams180Default(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.
        tids = [];  // tid_home, tid_away



        var numGames, numRemaining, numWithRemaining;
		var count;
//        numGames = 82;
        numGames = 4;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        tids = [];
        while (tids.length < numGames * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);
			count = 0;
			//    // console.log("countb: "+count);
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].cid === teams[j].cid) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
                //i = random.randInt(0, g.numTeams - 1);
                //j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].cid != teams[j].cid)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].cid != teams[jj].cid)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }



       numGames2 = 6;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames2;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        while (tids.length < (numGames+numGames2) * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);

			count = 0;
			//    // console.log("countb: "+count);
//            while ( (i === j || numRemaining[i] === 0 || numRemaining[j] === 0 || teams[i].cid === teams[j].cid) && (count < 100) ) {
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].did != teams[j].did) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
                //i = random.randInt(0, g.numTeams - 1);
                //j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].did == teams[j].did)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].did == teams[jj].did)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }

        // Constraint: 1-2 home schedule vs. each team in same conference and different division
        // Constraint: We need 8 more of these games per home team!
		// actually need 6
		// actual want 8 total, 4 home

		// want 1 more
        tidsByConf = [[], [],[], [], [],[], [],[], [], [],[], [],[], [], []];
        dids = [[], [],[], [], [],[], [],[], [], [],[], [],[], [], []];
	//	    // console.log(teams.length);
        for (i = 0; i < teams.length; i++) {
		//    // console.log(i);
		//    // console.log(teams[i].cid);
            tidsByConf[teams[i].cid].push(i);
            dids[teams[i].cid].push(teams[i].did);
        }

        for (cid = 0; cid < 15; cid++) {
            matchups = [];
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]);
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15,16]);
//            matchups.push([0, 1, 2, 3, 4]);
            matchups.push([0, 1, 2, 3, 4,5,6]);
            games = 0;
//            while (games < 8) {
            while (games < 1) {
                newMatchup = [];
                n = 0;
                while (n <= 11) {  // 15 = num teams in conference - 1
                    iters = 0;
                    while (true) {
                        tryNum = random.randInt(0, 11);
//                        tryNum = random.randInt(0, 7);
                      //  tryNum2 = random.randInt(0, 4);
                        // Pick tryNum such that it is in a different division than n and has not been picked before
                        if (dids[cid][tryNum] !== dids[cid][n] && newMatchup.indexOf(tryNum) < 0) {
                            good = true;
                            // Check for duplicate games
                            for (j = 0; j < matchups.length; j++) {
                                matchup = matchups[j];
                                if (matchup[n] === tryNum) {
                                    good = false;
                                    break;
                                }
                            }
                            if (good) {
                                newMatchup.push(tryNum);
                                break;
                            }
                        }
                        iters += 1;
                        // Sometimes this gets stuck (for example, first 14 teams in fine but 15th team must play itself)
                        // So, catch these situations and reset the newMatchup
                        if (iters > 500) {
                            newMatchup = [];
                            n = -1;
                            break;
                        }
                    }
                    n += 1;
                }
                matchups.push(newMatchup);
                games += 1;
            }
            matchups.shift();  // Remove the first row in matchups
            for (j = 0; j < matchups.length; j++) {
                matchup = matchups[j];
                for (k = 0; k < matchup.length; k++) {
                    t = matchup[k];
                    ii = tidsByConf[cid][t];
                    jj = tidsByConf[cid][matchup[t]];
                    game = [teams[ii].tid, teams[jj].tid];
                    tids.push(game);
                    teams[ii].homeGames += 1;
                    teams[jj].awayGames += 1;
                }
            }
        }

        return tids;
    }
   /**
     * Creates a new regular season schedule for 30 teams.
     *
     * This makes an NBA-like schedule in terms of conference matchups, division matchups, and home/away games.
     *
     * @memberOf core.season
     * @return {Array.<Array.<number>>} All the season's games. Each element in the array is an array of the home team ID and the away team ID, respectively.
     */
//    function newSchedule40Default(teams) {
// was 80 need to do
    function newSchedule240Default() {
//    function newScheduleDefault() {
        var cid, dids, game, games, good, i, ii, iters, j, jj, k, matchup, matchups, n, newMatchup, t, teams, tids, tidsByConf, tryNum;
		var tryNum2;
		var numGames2;

        teams = helpers.getTeams240Default(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.
        tids = [];  // tid_home, tid_away



        var numGames, numRemaining, numWithRemaining;
		var count;
//        numGames = 82;
        numGames = 4;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        tids = [];
        while (tids.length < numGames * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);
			count = 0;
			//    // console.log("countb: "+count);
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].cid === teams[j].cid) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
                //i = random.randInt(0, g.numTeams - 1);
                //j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].cid != teams[j].cid)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].cid != teams[jj].cid)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }



       numGames2 = 6;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames2;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        while (tids.length < (numGames+numGames2) * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);

			count = 0;
			//    // console.log("countb: "+count);
//            while ( (i === j || numRemaining[i] === 0 || numRemaining[j] === 0 || teams[i].cid === teams[j].cid) && (count < 100) ) {
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].did != teams[j].did) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
                //i = random.randInt(0, g.numTeams - 1);
                //j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].did == teams[j].did)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].did == teams[jj].did)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }

        // Constraint: 1-2 home schedule vs. each team in same conference and different division
        // Constraint: We need 8 more of these games per home team!
		// actually need 6
		// actual want 8 total, 4 home

		// want 1 more
        tidsByConf = [[], [],[], [], [],[], [],[], [], [],[], [],[], [], [],[], [],[], [], []];
        dids = [[], [],[], [], [],[], [],[], [], [],[], [],[], [], [],[], [],[], [], []];
        for (i = 0; i < teams.length; i++) {
            tidsByConf[teams[i].cid].push(i);
            dids[teams[i].cid].push(teams[i].did);
        }

        for (cid = 0; cid < 20; cid++) {
            matchups = [];
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]);
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15,16]);
//            matchups.push([0, 1, 2, 3, 4]);
            matchups.push([0, 1, 2, 3, 4,5,6]);
            games = 0;
//            while (games < 8) {
            while (games < 1) {
                newMatchup = [];
                n = 0;
                while (n <= 11) {  // 15 = num teams in conference - 1
                    iters = 0;
                    while (true) {
                        tryNum = random.randInt(0, 11);
//                        tryNum = random.randInt(0, 7);
                      //  tryNum2 = random.randInt(0, 4);
                        // Pick tryNum such that it is in a different division than n and has not been picked before
                        if (dids[cid][tryNum] !== dids[cid][n] && newMatchup.indexOf(tryNum) < 0) {
                            good = true;
                            // Check for duplicate games
                            for (j = 0; j < matchups.length; j++) {
                                matchup = matchups[j];
                                if (matchup[n] === tryNum) {
                                    good = false;
                                    break;
                                }
                            }
                            if (good) {
                                newMatchup.push(tryNum);
                                break;
                            }
                        }
                        iters += 1;
                        // Sometimes this gets stuck (for example, first 14 teams in fine but 15th team must play itself)
                        // So, catch these situations and reset the newMatchup
                        if (iters > 500) {
                            newMatchup = [];
                            n = -1;
                            break;
                        }
                    }
                    n += 1;
                }
                matchups.push(newMatchup);
                games += 1;
            }
            matchups.shift();  // Remove the first row in matchups
            for (j = 0; j < matchups.length; j++) {
                matchup = matchups[j];
                for (k = 0; k < matchup.length; k++) {
                    t = matchup[k];
                    ii = tidsByConf[cid][t];
                    jj = tidsByConf[cid][matchup[t]];
                    game = [teams[ii].tid, teams[jj].tid];
                    tids.push(game);
                    teams[ii].homeGames += 1;
                    teams[jj].awayGames += 1;
                }
            }
        }

        return tids;
    }

    /**
     * Creates a new regular season schedule for 30 teams.
     *
     * This makes an NBA-like schedule in terms of conference matchups, division matchups, and home/away games.
     *
     * @memberOf core.season
     * @return {Array.<Array.<number>>} All the season's games. Each element in the array is an array of the home team ID and the away team ID, respectively.
     */
//    function newSchedule40Default(teams) {
    function newSchedule320Default() {
//    function newScheduleDefault() {
        var cid, dids, game, games, good, i, ii, iters, j, jj, k, matchup, matchups, n, newMatchup, t, teams, tids, tidsByConf, tryNum;
		var tryNum2;
		var numGames2;

        teams = helpers.getTeams320Default(); // Only tid, cid, and did are used, so this is okay for now. But if someone customizes cid and did, this will break. To fix that, make this function require DB access (and then fix the tests). Or even better, just accept "teams" as a param to this function, then the tests can use default values and the real one can use values from the DB.
        tids = [];  // tid_home, tid_away



        var numGames, numRemaining, numWithRemaining;
		var count;
//        numGames = 82;
        numGames = 4;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        tids = [];
        while (tids.length < numGames * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);

			count = 0;
			//    // console.log("countb: "+count);
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].cid === teams[j].cid) && (count < 10000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
    //            i = random.randInt(0, g.numTeams - 1);
    //            j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>9999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].cid != teams[j].cid)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].cid != teams[jj].cid)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining <= 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }



       numGames2 = 6;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames2;
		//	// console.log(teams[i].tid+" "+teams[i].cid+" "+teams[i].did)
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        while (tids.length < (numGames+numGames2) * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
            i = random.randInt(0, g.numTeams - 1);
            j = random.randInt(0, g.numTeams - 1);

			count = 0;
			//    // console.log("countb: "+count);
//            while ( (i === j || numRemaining[i] === 0 || numRemaining[j] === 0 || teams[i].cid === teams[j].cid) && (count < 100) ) {
            while ( (i === j || numRemaining[i] <= 0 || numRemaining[j] <= 0 || teams[i].did != teams[j].did) && (count < 5000) ) {
		//	    // console.log("countd: "+count);
		        if (numRemaining[i] > 0) {
					j = random.randInt(0, g.numTeams - 1);
				} else if (numRemaining[j] > 0) {
					i = random.randInt(0, g.numTeams - 1);
				} else {
					i = random.randInt(0, g.numTeams - 1);
					j = random.randInt(0, g.numTeams - 1);
				}
//                i = random.randInt(0, g.numTeams - 1);
//                j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }
		//	    // console.log("counta: "+count);

			if (count>4999) {
			  ii = 0;
			  if (numRemaining[i] <= 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j) && (teams[ii].did == teams[j].did)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] <= 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj) && (teams[i].did == teams[jj].did)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining === 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }

        // Constraint: 1-2 home schedule vs. each team in same conference and different division
        // Constraint: We need 8 more of these games per home team!
		// actually need 6
		// actual want 8 total, 4 home

		// want 1 more
        tidsByConf = [[], [],[], [], [],[], [],[], [], [],[], [],[], [], [],[], [],[], [], []];
        dids = [[], [],[], [], [],[], [],[], [], [],[], [],[], [], [],[], [],[], [], []];

        for (i = 0; i < teams.length; i++) {
            tidsByConf[teams[i].cid].push(i);
            dids[teams[i].cid].push(teams[i].did);
        }

        for (cid = 0; cid < 5; cid++) {
            matchups = [];
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]);
//            matchups.push([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15,16]);
//            matchups.push([0, 1, 2, 3, 4]);
            matchups.push([0, 1, 2, 3, 4,5,6,7,8]);
            games = 0;
//            while (games < 8) {
            while (games < 1) {
                newMatchup = [];
                n = 0;
                while (n <= 15) {  // 15 = num teams in conference - 1
                    iters = 0;
                    while (true) {
                        tryNum = random.randInt(0, 15);
//                        tryNum = random.randInt(0, 7);
                      //  tryNum2 = random.randInt(0, 4);
                        // Pick tryNum such that it is in a different division than n and has not been picked before
                        if (dids[cid][tryNum] !== dids[cid][n] && newMatchup.indexOf(tryNum) < 0) {
                            good = true;
                            // Check for duplicate games
                            for (j = 0; j < matchups.length; j++) {
                                matchup = matchups[j];
                                if (matchup[n] === tryNum) {
                                    good = false;
                                    break;
                                }
                            }
                            if (good) {
                                newMatchup.push(tryNum);
                                break;
                            }
                        }
                        iters += 1;
                        // Sometimes this gets stuck (for example, first 14 teams in fine but 15th team must play itself)
                        // So, catch these situations and reset the newMatchup
                        if (iters > 500) {
                            newMatchup = [];
                            n = -1;
                            break;
                        }
                    }
                    n += 1;
                }
                matchups.push(newMatchup);
                games += 1;
            }
            matchups.shift();  // Remove the first row in matchups
            for (j = 0; j < matchups.length; j++) {
                matchup = matchups[j];
                for (k = 0; k < matchup.length; k++) {
                    t = matchup[k];
                    ii = tidsByConf[cid][t];
                    jj = tidsByConf[cid][matchup[t]];
                    game = [teams[ii].tid, teams[jj].tid];
                    tids.push(game);
                    teams[ii].homeGames += 1;
                    teams[jj].awayGames += 1;
                }
            }
        }

        return tids;
    }



   /**
     * Creates a new regular season schedule for an arbitrary number of teams.
     *
     * newScheduleDefault is much nicer and more balanced, but only works for 30 teams.
     *
     * @memberOf core.season
     * @return {Array.<Array.<number>>} All the season's games. Each element in the array is an array of the home team ID and the away team ID, respectively.
     */
    function newScheduleCrappy() {
        var i, j, numGames, numRemaining, numWithRemaining, tids;
		var count, ii,jj;
//        numGames = 82;
        numGames = 12;

        // Number of games left to reschedule for each team
        numRemaining = [];
        for (i = 0; i < g.numTeams; i++) {
            numRemaining[i] = numGames;
        }
        numWithRemaining = g.numTeams; // Number of teams with numRemaining > 0

        tids = [];
        while (tids.length < numGames * g.numTeams) {
            i = -1; // Home tid
            j = -1; // Away tid
			count = 0;
            while ( (i === j || numRemaining[i] === 0 || numRemaining[j] === 0 ) && count < 3000) {
			   // // console.log("count: "+count);
                i = random.randInt(0, g.numTeams - 1);
                j = random.randInt(0, g.numTeams - 1);
				count += 1;
            }

			if (count>2999) {
			  ii = 0;
			  if (numRemaining[i] === 0) {
				for (ii = 0; ii < g.numTeams; ii++) {
				    if ((numRemaining[ii] > 0) && (ii!=j)) {
						i = ii;
						ii = g.numTeams;
					}
				}
			  }
			  if (numRemaining[j] === 0) {
				for (jj = 0; jj < g.numTeams; jj++) {
				    if ((numRemaining[ii] > 0) && (i!=jj)) {
						j = jj;
						jj = g.numTeams;
					}
				}
			  }
			}

            tids.push([i, j]);

            numRemaining[i] -= 1;
            numRemaining[j] -= 1;

            // Make sure we're not left with just one team to play itself
            if (numRemaining[i] === 0) {
                numWithRemaining -= 1;
            }
            if (numRemaining[j] === 0) {
                numWithRemaining -= 1;
            }
            if (numWithRemaining === 1) {
                // If this happens, we didn't find 82 for each team and one team will play a few less games
                break;
            }
        }

        return tids;
    }


    function newSchedule() {
        var days, i, j, jMax, tids, tidsInDays, used;


        if (g.numTeams === 40) {
            tids = newSchedule40Default();
        } else if (g.numTeams === 80) {
            tids = newSchedule80Default();
        } else if (g.numTeams === 120) {
            tids = newSchedule120Default();
        } else if (g.numTeams === 180) {
            tids = newSchedule180Default();
        } else if (g.numTeams === 240) {
            tids = newSchedule240Default();
        } else if (g.numTeams === 320) {
            tids = newSchedule320Default();
        } else {
            tids = newScheduleCrappy();
        }
	//	// console.log("schedule finished");
        // Order the schedule so that it takes fewer days to play
       // random.shuffle(tids);
        days = [[]];
        tidsInDays = [[]];
        jMax = 0;
        for (i = 0; i < tids.length; i++) {
            used = false;
            for (j = 0; j <= jMax; j++) {
                if (tidsInDays[j].indexOf(tids[i][0]) < 0 && tidsInDays[j].indexOf(tids[i][1]) < 0) {
                    tidsInDays[j].push(tids[i][0]);
                    tidsInDays[j].push(tids[i][1]);
                    days[j].push(tids[i]);
                    used = true;
                    break;
                }
            }
            if (!used) {
                days.push([tids[i]]);
                tidsInDays.push([tids[i][0], tids[i][1]]);
                jMax += 1;
            }
        }
       // random.shuffle(days); // Otherwise the most dense days will be at the beginning and the least dense days will be at the end
        tids = _.flatten(days, true);
        return tids;
    }






    /**
     * Create a single day's schedule for an in-progress playoffs.
     *
     * @memberOf core.season
     * @param {(IDBTransaction|null)} tx An IndexedDB transaction on playoffSeries, schedule, and teams, readwrite.
     * @return {Promise.boolean} Resolves to true if the playoffs are over. Otherwise, false.
     */
    function newSchedulePlayoffsDay(tx) {
            //var cursor, i, matchup, nextRound, numGames, playoffSeries, rnd, series, team0, team1, team2,team3,team4, tids, tidsWon;
        tx = dao.tx(["playoffSeries", "schedule", "teams"], "readwrite", tx);
         var  i, playoffSeries, rnd, series,tids;

        // This is a little tricky. We're returning this promise, but within the "then"s we're returning tx.complete() for the same transaction. Probably should be refactored.
        return dao.playoffSeries.get({
            ot: tx,
            key: g.season
        }).then(function (playoffSeriesLocal) {



            playoffSeries = playoffSeriesLocal;
            series = playoffSeries.series;
            rnd = playoffSeries.currentRound;
            tids = [];



            // Try to schedule games if there are active series
            for (i = 0; i < series.length; i++) {
                if (series[i].home.won < 1 && series[i].away.won < 1) {
                    // Make sure to set home/away teams correctly! Home for the lower seed is 1st, 2nd, 5th, and 7th games.
					// only 1 game, should always be home
                        tids.push([series[i].home.tid, series[i].away.tid]);
                }
            }

        }).then(function () {
			var cursor, matchup, nextRound, numGames, team0, team1, team2,team3,team4,tidsWon;
			var teamNumberOneC1;
			var teamNumberOneC2;
			var teamNumberTwoC1;
			var teamNumberTwoC2;
			var teamNumberThreeC1;
			var teamNumberThreeC2;
//console.log(tids.length);
            // If series are still in progress, write games and short circuit
            if (tids.length > 0) {
                return setSchedule(tx, tids).then(function () {
                    return false;
                });
            }

//		   if (rnd === 0) {
			// only one round
			for (i = 0; i < series.length; i++) {
				if (series[i].home.won === 1) {
					dao.teams.get({
						ot: tx,
						key: series[i].home.tid
					}).then(function (t) {

					   var s;

						s = t.seasons.length - 1;
						t.seasons[s].playoffRoundsWon += 1;
						t.seasons[s].confChamp = 1;
						t.seasons[s].hype += 0.05;
						if (t.seasons[s].hype > 1) {
							t.seasons[s].hype = 1;
						}

						return dao.teams.put({ot: tx, value: t});
					});
				}
				if (series[i].away.won === 1) {
					dao.teams.get({
						ot: tx,
						key: series[i].away.tid
					}).then(function (t) {

					   var s;

						s = t.seasons.length - 1;
						t.seasons[s].playoffRoundsWon += 1;
						t.seasons[s].confChamp = 1;
						t.seasons[s].hype += 0.05;
						if (t.seasons[s].hype > 1) {
							t.seasons[s].hype = 1;
						}

						return dao.teams.put({ot: tx, value: t});
					});
				}
			}
//			}
      //  }).then(function () {
			return true;
		});
	};



    /*Creates a single day's schedule for an in-progress playoffs.*/
    function newScheduleNationalPlayoffsDay(tx) {
		var playoffSeriesNational, rnd, series, tids,i;

        tx = dao.tx(["playoffSeriesNational", "schedule", "teams"], "readwrite", tx);

        // This is a little tricky. We're returning this promise, but within the "then"s we're returning tx.complete() for the same transaction. Probably should be refactored.
        return dao.playoffSeriesNational.get({
            ot: tx,
            key: g.season
        }).then(function (playoffSeriesLocal) {

			var numGames;


            playoffSeriesNational = playoffSeriesLocal;
            tids = [];
            series = playoffSeriesNational.series;
            rnd = playoffSeriesNational.currentRound;

            for (i = 0; i < series[rnd].length; i++) {
                if (series[rnd][i].home.won < 1 && series[rnd][i].away.won < 1) {
						numGames = series[rnd][i].home.won + series[rnd][i].away.won;
						if (numGames === 0) {
							tids.push([series[rnd][i].home.tid, series[rnd][i].away.tid]);
						} else {
							tids.push([series[rnd][i].away.tid, series[rnd][i].home.tid]);
						}
				}
            }
        }).then(function () {
            var cursor,  matchup, nextRound,  team0, team1, team2,team3,team4,  tidsWon;
			var teamNumberOneC1;
			var teamNumberOneC2;
			var teamNumberTwoC1;
			var teamNumberTwoC2;
			var teamNumberThreeC1;
			var teamNumberThreeC2;
			var teamBug1;
			var teamBug0;
			var seed0;
			var seed1;
			tidsWon = [];
           if (tids.length > 0) {

                return setSchedule(tx, tids).then(function () {
                    return false;
                });
            }


			    if ( (g.gameType < 3)) {

					if (rnd === 1) {

						if (series[1][0].home.won === 1) {
							team0 = helpers.deepCopy(series[1][0].home);
							teamBug0 = helpers.deepCopy(series[1][0].away);
							tidsWon.push(series[1][0].home.tid);
							//series[1][0].away.seed = 4;

						} else {
							team0 = helpers.deepCopy(series[1][0].away);
							teamBug0 = helpers.deepCopy(series[1][0].home);
							tidsWon.push(series[1][0].away.tid);
						}
							return dao.teams.get({
								ot: tx,
								key: tidsWon[0]
							}).then(function (t) {

							   var s;

								s = t.seasons.length - 1;
								if ( (g.gameType <3)) {
									t.seasons[s].playoffRoundsWonNational += 80;
									//teamSeason.playoffRoundsWonNational += 80;
								} else {
									t.seasons[s].playoffRoundsWonNational = 0;
									//teamSeason.playoffRoundsWonNational = 0;
								}
								//t.seasons[s].playoffRoundsWon += 1;
								//t.seasons[s].confChamp = 1;
								t.seasons[s].hype += 0.05;
								if (t.seasons[s].hype > 1) {
									t.seasons[s].hype = 1;
								}

								return dao.teams.put({ot: tx, value: t});
							}).then(function () {
								// Playoffs are over! Return true!

								return true;
//								 return newPhase(g.PHASE.BEFORE_DRAFT);
							 });

				////////////////////////////////////////////////////
					} else {

						if (series[0][0].home.won === 1) {
							team0 = helpers.deepCopy(series[0][0].home);
							teamBug0 = helpers.deepCopy(series[0][0].away);
							tidsWon.push(series[0][0].home.tid);
							//series[1][0].away.seed = 4;
							seed0 = 1;
						} else {
							team0 = helpers.deepCopy(series[0][0].away);
							teamBug0 = helpers.deepCopy(series[0][0].home);
							tidsWon.push(series[0][0].away.tid);
							seed0 = 4;
						}
						if (series[0][1].home.won === 1) {
							team1 = helpers.deepCopy(series[0][1].home);
							teamBug1 = helpers.deepCopy(series[0][1].away);
							tidsWon.push(series[0][1].home.tid);
		//					    series[1][2].away.seed = 4;
							seed1 = 2;
						} else {
							team1 = helpers.deepCopy(series[0][1].away);
							teamBug1 = helpers.deepCopy(series[0][1].home);
							tidsWon.push(series[0][1].away.tid);
		//						    series[1][2].away.seed = 5;
							seed1 = 3;

						}
						for (i = 2; i < series[0].length; i++) {

							if (series[0][i].home.won === 1) {
								//team1 = helpers.deepCopy(series[0][1].home);
								//teamBug1 = helpers.deepCopy(series[0][1].away);
								tidsWon.push(series[0][i].home.tid);
			//					    series[1][2].away.seed = 4;
			//					seed1 = 2;
							} else {
								//team1 = helpers.deepCopy(series[0][1].away);
								//teamBug1 = helpers.deepCopy(series[0][1].home);
								tidsWon.push(series[0][i].away.tid);
			//						    series[1][2].away.seed = 5;
			//					seed1 = 3;

							}
						}

						series[1][0]  = {home: team1, away: team0};                //// error here :Uncaught TypeError: Cannot read property '0' of undefined

		///////						series[rnd+1][0]  = {home: topseeds[0], away: team0};                //// error here :Uncaught TypeError: Cannot read property '0' of undefined
						//series[0][cid * 4] = {home: teamsConf[0], away: teamsConf[7]};
						series[1][0].home.seed = seed1;
						series[1][0].away.seed = seed0;
						series[1][0].home.won	= 0
						series[1][0].away.won	= 0
						series[1][0].home.pts = "";
						series[1][0].away.pts = "";



						//rnd = 0;

	//// console.log("series[1][0].home.tid: "+series[1][0].home.tid);
						playoffSeriesNational.currentRound += 1;
						return dao.playoffSeriesNational.put({ot: tx, value: playoffSeriesNational}).then(function () {
							// Update hype for winning a series
							return Promise.map(tidsWon, function (tid) {
								return dao.teams.get({
									ot: tx,
									key: tid
								}).then(function (t) {
											console.log("got here");
										   var s;

											s = t.seasons.length - 1;
											if ( (g.gameType <3)) {
												t.seasons[s].playoffRoundsWonNational += 80;
												//teamSeason.playoffRoundsWonNational += 80;
											} else {
												t.seasons[s].playoffRoundsWonNational = 0;
												//teamSeason.playoffRoundsWonNational = 0;
											}
											//t.seasons[s].playoffRoundsWon += 1;
											//t.seasons[s].confChamp = 1;
											t.seasons[s].hype += 0.05;
											if (t.seasons[s].hype > 1) {
												t.seasons[s].hype = 1;
											}

								   return dao.teams.put({ot: tx, value: t});
								});
							});
						}).then(function () {

							// Next time, the schedule for the first day of the next round will be set
							return newScheduleNationalPlayoffsDay(tx);
						});

					}

				} else if (g.gameType == 5) {

				   if (rnd === 5) {



						if (series[rnd][0].home.won === 1) {
							team0 = helpers.deepCopy(series[rnd][0].home);
							teamBug0 = helpers.deepCopy(series[rnd][0].away);
							tidsWon.push(series[rnd][0].home.tid);

						} else {
							team0 = helpers.deepCopy(series[rnd][0].away);
							teamBug0 = helpers.deepCopy(series[rnd][0].home);
							tidsWon.push(series[rnd][0].away.tid);
						}

						return dao.teams.get({
							ot: tx,
							key: tidsWon[0]
						}).then(function (t) {

						   var s;

							s = t.seasons.length - 1;
							t.seasons[s].playoffRoundsWonNational = 6;
							//t.seasons[s].playoffRoundsWon += 1;
							//t.seasons[s].confChamp = 1;
							t.seasons[s].hype *= 0.70;
							t.seasons[s].hype += 0.30;

							return dao.teams.put({ot: tx, value: t});
						}).then(function () {

//							return newPhase(g.PHASE.BEFORE_DRAFT);
							return true;
						 // // console.log("updated4");

						});
				//	}
					}	else {

				   //// above new
/////////////// below old



						tidsWon = [];
						// // console.log("rnd: "+rnd);
						// // console.log("series[rnd].length: "+series[rnd].length);
						for (i = 0; i < series[rnd].length; i += 2) {
					//	   // console.log("i: "+i);
							// Find the two winning teams
							if (series[rnd][i].home.won === 1) {
								team1 = helpers.deepCopy(series[rnd][i].home);
								tidsWon.push(series[rnd][i].home.tid);
							} else {
								team1 = helpers.deepCopy(series[rnd][i].away);
								tidsWon.push(series[rnd][i].away.tid);
							}
							if (series[rnd][i + 1].home.won === 1) {
								team2 = helpers.deepCopy(series[rnd][i + 1].home);
								tidsWon.push(series[rnd][i + 1].home.tid);
							} else {
								team2 = helpers.deepCopy(series[rnd][i + 1].away);
								tidsWon.push(series[rnd][i + 1].away.tid);
							}

							// Set home/away in the next round
							if (team1.winp > team2.winp) {
								matchup = {home: team1, away: team2};
							} else {
								matchup = {home: team2, away: team1};
							}

							matchup.home.won = 0;
							matchup.away.won = 0;
							matchup.home.pts = "";
							matchup.away.pts = "";
							series[rnd + 1][i / 2] = matchup;

						}

						// // console.log(series);
						playoffSeriesNational.currentRound += 1;
						return dao.playoffSeriesNational.put({ot: tx, value: playoffSeriesNational}).then(function () {
							// Update hype for winning a series
							return Promise.map(tidsWon, function (tid) {
								return dao.teams.get({
									ot: tx,
									key: tid
								}).then(function (t) {

										   var s;

											s = t.seasons.length - 1;
											t.seasons[s].playoffRoundsWonNational = playoffSeriesNational.currentRound ;
											//t.seasons[s].playoffRoundsWon += 1;
											//t.seasons[s].confChamp = 1;
											t.seasons[s].hype *= 0.90;
											t.seasons[s].hype += 0.10;
											if (t.seasons[s].hype > 1) {
												t.seasons[s].hype = 1;
											}
									return dao.teams.put({ot: tx, value: t});
								});
							});
						}).then(function () {

							// Next time, the schedule for the first day of the next round will be set
							return newScheduleNationalPlayoffsDay(tx);
						});
					}

				} else {

				   if (rnd === 4) {



						if (series[rnd][0].home.won === 1) {
							team0 = helpers.deepCopy(series[rnd][0].home);
							teamBug0 = helpers.deepCopy(series[rnd][0].away);
							tidsWon.push(series[rnd][0].home.tid);

						} else {
							team0 = helpers.deepCopy(series[rnd][0].away);
							teamBug0 = helpers.deepCopy(series[rnd][0].home);
							tidsWon.push(series[rnd][0].away.tid);
						}

						return dao.teams.get({
							ot: tx,
							key: tidsWon[0]
						}).then(function (t) {

						   var s;

							s = t.seasons.length - 1;
							t.seasons[s].playoffRoundsWonNational = 5 ;
							//t.seasons[s].playoffRoundsWon += 1;
							//t.seasons[s].confChamp = 1;
							t.seasons[s].hype *= 0.70;
							t.seasons[s].hype += 0.30;
							if (t.seasons[s].hype > 1) {
								t.seasons[s].hype = 1;
							}
							return dao.teams.put({ot: tx, value: t});
						}).then(function () {
						 // // console.log("updated3");
													console.log("got here");
//							return newPhase(g.PHASE.BEFORE_DRAFT);
							return true;
						 // // console.log("updated4");

						});
					}	else {

				   //// above new
/////////////// below old



						tidsWon = [];
						// // console.log("rnd: "+rnd);
						// // console.log("series[rnd].length: "+series[rnd].length);
						for (i = 0; i < series[rnd].length; i += 2) {
					//	   // console.log("i: "+i);
							// Find the two winning teams
							if (series[rnd][i].home.won === 1) {
								team1 = helpers.deepCopy(series[rnd][i].home);
								tidsWon.push(series[rnd][i].home.tid);
							} else {
								team1 = helpers.deepCopy(series[rnd][i].away);
								tidsWon.push(series[rnd][i].away.tid);
							}
							if (series[rnd][i + 1].home.won === 1) {
								team2 = helpers.deepCopy(series[rnd][i + 1].home);
								tidsWon.push(series[rnd][i + 1].home.tid);
							} else {
								team2 = helpers.deepCopy(series[rnd][i + 1].away);
								tidsWon.push(series[rnd][i + 1].away.tid);
							}

							// Set home/away in the next round
							if (team1.winp > team2.winp) {
								matchup = {home: team1, away: team2};
							} else {
								matchup = {home: team2, away: team1};
							}

							matchup.home.won = 0;
							matchup.away.won = 0;
							matchup.home.pts = "";
							matchup.away.pts = "";
							series[rnd + 1][i / 2] = matchup;

						}

						// // console.log(series);
						playoffSeriesNational.currentRound += 1;
						return dao.playoffSeriesNational.put({ot: tx, value: playoffSeriesNational}).then(function () {
							// Update hype for winning a series
							return Promise.map(tidsWon, function (tid) {
								return dao.teams.get({
									ot: tx,
									key: tid
								}).then(function (t) {

								   var s;

									s = t.seasons.length - 1;
									t.seasons[s].playoffRoundsWonNational = playoffSeriesNational.currentRound ;
									//t.seasons[s].playoffRoundsWon += 1;
									//t.seasons[s].confChamp = 1;
									t.seasons[s].hype *= 0.90;
									t.seasons[s].hype += 0.10;
									if (t.seasons[s].hype > 1) {
										t.seasons[s].hype = 1;
									}
									return dao.teams.put({ot: tx, value: t});
								});
							});
						}).then(function () {
							// Next time, the schedule for the first day of the next round will be set

							return newScheduleNationalPlayoffsDay(tx);
						});
                    }
				}
        });
    }



    /**
     * Get the number of days left in the regular season schedule.
     *
     * @memberOf core.season
     * @return {Promise} The number of days left in the schedule.
     */
    function getDaysLeftSchedule() {
        return getSchedule().then(function (schedule) {
            var i, numDays, tids;
            numDays = 0;

		//	// console.log(schedule.length);
            while (schedule.length > 0) {
                // Only take the games up until right before a team plays for the second time that day
                tids = [];
                for (i = 0; i < schedule.length; i++) {
                    if (tids.indexOf(schedule[i].homeTid) < 0 && tids.indexOf(schedule[i].awayTid) < 0) {
                        tids.push(schedule[i].homeTid);
                        tids.push(schedule[i].awayTid);
                    } else {
                        break;
                    }
                }
                numDays += 1;
                schedule = schedule.slice(i);
            }

            return numDays;
        });
    }

    return {
        awards: awards,
        updateOwnerMood: updateOwnerMood,
        getSchedule: getSchedule,
        setSchedule: setSchedule,
        newSchedule: newSchedule,
        newSchedulePlayoffsDay: newSchedulePlayoffsDay,
        newScheduleNationalPlayoffsDay: newScheduleNationalPlayoffsDay,
        getDaysLeftSchedule: getDaysLeftSchedule

    };
});


/**
 * @name views.editTeamInfo
 * @namespace Edit Team Info.
 */
define('views/editTeamInfo',["dao", "db", "globals", "ui", "core/league", "core/team", "lib/underscore", "util/bbgmView", "util/helpers"], function (dao, db, g, ui, league, team, _, bbgmView, helpers) {


    "use strict";

    function post(req) {
        var button, userName, userRegion;

        button = document.getElementById("edit-team-info");
        button.disabled = true;

        dao.teams.iterate({
			ot: dao.tx("teams", "readwrite"),			 			
            callback: function (t) {
                t.abbrev = req.params.abbrev[t.tid];
                t.region = req.params.region[t.tid];
                t.name = req.params.name[t.tid];
                t.seasons[t.seasons.length - 1].pop = parseFloat(req.params.pop[t.tid]);
                t.city = req.params.city[t.tid];
                t.state = req.params.state[t.tid];
                t.longitude = req.params.longitude[t.tid];
                t.latitude = req.params.latitude[t.tid];
				

                if (t.tid === g.userTid) {
                    userName = t.name;
                    userRegion = t.region;
                }

                return t;
            }
        }).then(function () {
            // Update meta cache of user's team
            return league.updateMetaNameRegion(userName, userRegion);
        }).then(function () {
            return league.setGameAttributesComplete({
                teamAbbrevsCache: req.params.abbrev,
                teamRegionsCache: req.params.region,
                teamNamesCache: req.params.name,
				teamLongitudeCache: req.params.longitude,
				teamLatitudeCache: req.params.latitude	
            });
        }).then(function () {
			league.updateLastDbChange();
            button.disabled = false;
            ui.realtimeUpdate([], helpers.leagueUrl(["edit_team_info"]));
        });
    }

    function updateTeamInfo() {
         return team.filter({
            attrs: ["tid", "abbrev", "region", "name","city","state","latitude","longitude"],
            seasonAttrs: ["pop"],
            season: g.season
        }).then(function (teams) {
            var i;

            for (i = 0; i < teams.length; i++) {
                teams[i].pop = helpers.round(teams[i].pop, 0);
            }

            return {
                teams: teams
            };
        });
    }

    function uiFirst(vm) {
        var fileEl;

        ui.title("Edit Team Names");

        fileEl = document.getElementById("custom-teams");
        fileEl.addEventListener("change", function () {
            var file, reader;

            file = fileEl.files[0];

            reader = new window.FileReader();
            reader.readAsText(file);
            reader.onload = function (event) {
                var i, newTeams, rosters, userName, userRegion;
				var cidNum,didNum;
                rosters = JSON.parse(event.target.result);
                newTeams = rosters.teams;

			   if (g.numTeams === 40) {
					cidNum = 4;
					didNum = 9;
				} else if (g.numTeams === 80) {
					cidNum = 4;
					didNum = 9;
				} else if (g.numTeams === 120) {
					cidNum = 9;
					didNum = 19;
				} else if (g.numTeams === 160) {
					cidNum = 9;
					didNum = 19;
				} else if (g.numTeams === 240) {
					cidNum = 14;
					didNum = 29;
				} else if (g.numTeams === 320) {
					cidNum = 19;
					didNum = 39;
				} else {
					cidNum = 19;
					didNum = 39;
				}
                // Validate teams
                if (newTeams.length < g.numTeams) {
                    console.log("ROSTER ERROR: Wrong number of teams");
                    return;
                }
                for (i = 0; i < newTeams.length; i++) {
                    if (i !== newTeams[i].tid) {
                        console.log("ROSTER ERROR: Wrong tid, team " + i);
                        return;
                    }
					
                    if (newTeams[i].cid < 0 || newTeams[i].cid > cidNum) {
                        console.log("ROSTER ERROR: Invalid cid, team " + i);
                        return;
                    }
                    if (newTeams[i].did < 0 || newTeams[i].did > didNum) {
                        console.log("ROSTER ERROR: Invalid did, team " + i);
                        return;
                    }
                    if (typeof newTeams[i].region !== "string") {
                        console.log("ROSTER ERROR: Invalid region, team " + i);
                        return;
                    }
                    if (typeof newTeams[i].name !== "string") {
                        console.log("ROSTER ERROR: Invalid name, team " + i);
                        return;
                    }
                    if (typeof newTeams[i].abbrev !== "string") {
                        console.log("ROSTER ERROR: Invalid abbrev, team " + i);
                        return;
                    }

                   if (typeof newTeams[i].city !== "string") {
                        console.log("ROSTER ERROR: Invalid city, team " + i);
                        return;
                    }
                    // Check for pop in either the root or the most recent season
                    if (!newTeams[i].hasOwnProperty("pop") && newTeams[i].hasOwnProperty("seasons")) {
                        newTeams[i].pop = newTeams[i].seasons[newTeams[i].seasons.length - 1].pop;
                    }
					
                    if (typeof newTeams[i].state !== "string") {
                        console.log("ROSTER ERROR: Invalid state, team " + i);
                        return;
                    }					

                   // Check for pop in either the root or the most recent season
                    if (!newTeams[i].hasOwnProperty("pop") && newTeams[i].hasOwnProperty("seasons")) {
                        newTeams[i].pop = newTeams[i].seasons[newTeams[i].seasons.length - 1].pop;
                    }
				
                    if (typeof newTeams[i].pop !== "number") {
                        console.log("ROSTER ERROR: Invalid pop, team " + i);
                        return;
                    }
                   if (typeof newTeams[i].longitude !== "number") {
                        console.log("ROSTER ERROR: Invalid longitude, team " + i);
                        return;
                    }
                   if (typeof newTeams[i].latitude !== "number") {
                        console.log("ROSTER ERROR: Invalid latitude, team " + i);
                        return;
                    }						
					
                }

                dao.teams.iterate({
					ot: dao.tx("teams", "readwrite"),			 										
                    callback: function (t) {

                        t.cid = newTeams[t.tid].cid;
                        t.did = newTeams[t.tid].did;
                        t.region = newTeams[t.tid].region;
                        t.name = newTeams[t.tid].name;
                        t.abbrev = newTeams[t.tid].abbrev;
                        t.seasons[t.seasons.length - 1].pop = newTeams[t.tid].pop;
                        t.city = newTeams[t.tid].city;
                        t.state = newTeams[t.tid].state;
                        t.longitude = newTeams[t.tid].longitude;
                        t.latitude = newTeams[t.tid].latitude;
						
                        if (newTeams[t.tid].imgURL) {
                            t.imgURL = newTeams[t.tid].imgURL;
                        }

                         if (t.tid === g.userTid) {
                            userName = t.name;
                            userRegion = t.region;
                        }

                        return t;
                    }
                }).then(function () {
                    // Update meta cache of user's team
                    return league.updateMetaNameRegion(userName, userRegion);
                }).then(function () {
                    return league.setGameAttributes({
                            lastDbChange: Date.now(),
                            teamAbbrevsCache: _.pluck(newTeams, "abbrev"),
                            teamRegionsCache: _.pluck(newTeams, "region"),
                            teamNamesCache: _.pluck(newTeams, "name"),
							teamLongitudeCache: _.pluck(newTeams, "longitude"),
							teamLatitudeCache: _.pluck(newTeams, "latitude")
                   });
                }).then(function () {
					league.updateLastDbChange();
                    ui.realtimeUpdate(["dbChange"]);
                });
            };
        });
    }

    return bbgmView.init({
        id: "editTeamInfo",
        post: post,
        runBefore: [updateTeamInfo],
        uiFirst: uiFirst
    });
});

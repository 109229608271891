/**
 * @name views.history
 * @namespace Summaries of past seasons, leaguewide.
 */
define('views/historyConference',["dao", "globals", "ui", "core/player", "core/team", "lib/bluebird", "lib/knockout", "util/bbgmView", "util/helpers", "views/components"], function (dao, g, ui, player, team, Promise, ko, bbgmView, helpers, components) {
     "use strict";

    function get(req) {
        var season;

        season = helpers.validateSeason(req.params.season);

        // If playoffs aren't over, season awards haven't been set
        if (g.phase <= g.PHASE.PLAYOFFS) {
            // View last season by default
            if (season === g.season) {
                season -= 1;
            }
        }

        if (season < g.startingSeason) {
            return {
                errorMessage: "There is no association history yet. Check back after the conference championship games."
            };
        }

        return {
            season: season
        };
    }

    function updateHistory(inputs, updateEvents, vm) {
        

        if (updateEvents.indexOf("dbChange") >= 0 || updateEvents.indexOf("firstRun") >= 0 || vm.season() !== inputs.season) {
            return Promise.all([
                dao.awards.get({key: inputs.season}),
                dao.players.getAll({
                    index: "retiredYear",
                    key: inputs.season
                }),
                team.filter({
					 attrs: ["tid","cid","abbrev", "region", "name"],
					seasonAttrs: ["playoffRoundsWon"],
                    season: inputs.season
                })
            ]).spread(function (awards, retiredPlayers, teams) {
                var i;
				var champ,champ2,champ3,champ4,champ5,champ6,champ7,champ8,champ9,champ10,champ11,champ12,champ13,champ14,champ15,champ16,champ17,champ18,champ19,champ20, i;
				var conf,conf2,conf3,conf4,conf5,conf6,conf7,conf8,conf9,conf10,conf11,conf12,conf13,conf14,conf15,conf16,conf17,conf18,conf19,conf20;

                // Hack placeholder for old seasons before Finals MVP existed
                if (!awards.hasOwnProperty("finalsMvp")) {
                    awards.finalsMvp = {
                        pid: 0,
                        name: "N/A",
                        pts: 0,
                        trb: 0,
                        ast: 0
                    };
                }


                    retiredPlayers = player.filter(retiredPlayers, {
                        attrs: ["pid", "name", "age", "hof"],
                        season: inputs.season
                    });
                    for (i = 0; i < retiredPlayers.length; i++) {
                        // Show age at retirement, not current age
                        retiredPlayers[i].age -= g.season - inputs.season;
                    }					
                    retiredPlayers.sort(function (a, b) { return b.age - a.age; });

 


						//var showExtraConferences;
						
						//showExtraConferences = false;
						
						
						var showExtraConferencesSecond,showPowerConferences,showIIIPowerConferencesFirst,showIIIPowerConferencesSecond,showExtraConferencesFirst;
					//	var numberConferences;
						//numberConferences = 5;
						showPowerConferences = true;
						showExtraConferencesSecond = false;
						showIIIPowerConferencesSecond = false;
						showIIIPowerConferencesFirst = false;
						showExtraConferencesFirst = false;
						
			/*			if ((g.gameType == 2) || (g.gameType == 4)) {
							numberConferences = 20;
							showExtraConferences = true;					  
						} */
								
						if (g.gameType == 0) {
						//	numberConferences = 5;
							//numberDivisions = 10;
							showExtraConferencesFirst = false;
							showExtraConferencesSecond = false;
							showPowerConferences = true;
							showIIIPowerConferencesFirst = false;
							showIIIPowerConferencesSecond = false;
						} else if (g.gameType == 1) {						
							showExtraConferencesFirst = false;
							showExtraConferencesSecond = false;
							showPowerConferences = true;
							showIIIPowerConferencesFirst = false;
							showIIIPowerConferencesSecond = false;
						} else if (g.gameType == 2) {						
							showExtraConferencesFirst = false;
							showExtraConferencesSecond = false;
							showPowerConferences = true;
							showIIIPowerConferencesFirst = false;
							showIIIPowerConferencesSecond = true;
						} else if ( (g.gameType == 3)) {
							showExtraConferencesFirst = true;				
							showExtraConferencesSecond = false;
							showPowerConferences = false;
							showIIIPowerConferencesFirst = false;
							showIIIPowerConferencesSecond = false;
						} else if ( (g.gameType == 4)) {
							showExtraConferencesFirst = false;
							showExtraConferencesSecond = true;
							showPowerConferences = false;
							showIIIPowerConferencesFirst = true;
							showIIIPowerConferencesSecond = false;
						} else if ( (g.gameType == 5)) {
							showExtraConferencesFirst = false;
							showExtraConferencesSecond = true;
							showPowerConferences = true;
							showIIIPowerConferencesFirst = false;
							showIIIPowerConferencesSecond = false;
						} else if ( (g.gameType == 6)) {
							showExtraConferencesFirst = false;
							showExtraConferencesSecond = false;
							showPowerConferences = true;
							showIIIPowerConferencesFirst = false;
							showIIIPowerConferencesSecond = false;
						}						
								
						
           /*             for (i = 0; i < teams.length; i++) {
//                            if ((teams[i].playoffRoundsWon === 1) && (teams[i].cid == userCid ) ){
                            if ((teams[i].playoffRoundsWon === 1) ){
                                champ = teams[i];
                                break;
                            }
                        } */

						for (i = 0; i < teams.length; i++) {
						// not being saved rigth?
						}
						
						for (i = 0; i < teams.length; i++) {
							if (teams[i].playoffRoundsWon === 1) {          
								// console.log(teams[i].tid);
								champ = teams[i];	
								conf = i;
								break;
							}
						}
					   for (i = 0; i < teams.length; i++) {
							if ((teams[i].playoffRoundsWon === 1) && (conf!=i)) {      
								// console.log(teams[i].tid);							
								champ2 = teams[i];						
								conf2 = i;						
								break;
							}
						}				
					   for (i = 0; i < teams.length; i++) {
					   
							if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i)) {                        
								// console.log(teams[i].tid);						
								champ3 = teams[i];						
								conf3 = i;						
								break;
							}
						}				
					   for (i = 0; i < teams.length; i++) {
							if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i)) {                        
								// console.log(teams[i].tid);							
								champ4 = teams[i];						
								conf4 = i;						
								break;
							}
						}				
					   for (i = 0; i < teams.length; i++) {
							if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i)) {                        
								// console.log(teams[i].tid);							
							
								champ5 = teams[i];						
								conf5 = i;						
								break;
							}
						}								
						if ((g.gameType > 1) && (g.gameType < 6)) {
						//	numberConferences = 20;
						//	showExtraConferences = true;					  
 

							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i)) {                        
								// console.log(teams[i].tid);							
										champ6 = teams[i];						
										conf6 = i;						
										break;
									}
								}					
							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i)) {                        
								// console.log(teams[i].tid);							
										champ7 = teams[i];						
										conf7 = i;						
										break;
									}
								}							
								
							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i)) {                        
										// console.log(teams[i].tid);							
										champ8 = teams[i];						
										conf8 = i;						
										break;
									}
								}						
								
							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i)) {                        
										// console.log(teams[i].tid);							
										champ9 = teams[i];						
										conf9 = i;						
										break;
									}
								}						
								
							  for (i = 0; i < teams.length; i++) {
									if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i)) {                        
										// console.log(teams[i].tid);							
										champ10 = teams[i];						
										conf10 = i;						
										break;
									}
								}	

							    if ((g.gameType > 2)) {
								  for (i = 0; i < teams.length; i++) {								  
										if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i)) {                        
								// console.log(teams[i].tid);							
											champ11 = teams[i];						
											conf11 = i;						
											break;
										}
									}	

									for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i)) {                        
								// console.log(teams[i].tid);							
											champ12 = teams[i];						
											conf12 = i;						
											break;
										}
									}					

									for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i)) {                        
								// console.log(teams[i].tid);							
											champ13 = teams[i];	
											conf13 = i;						
											break;
										}
									}				

									for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i)) { 
								// console.log(teams[i].tid);							
											champ14 = teams[i];	
											conf14 = i;						
											break;
										}
									}								
									
									for (i = 0; i < teams.length; i++) {
										if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i)) { 
								// console.log(teams[i].tid);							
											champ15 = teams[i];	
											conf15 = i;						
											break;
										}
									}								
									if ((g.gameType > 3)) {
									
										for (i = 0; i < teams.length; i++) {
											if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i)) { 
								// console.log(teams[i].tid);							
												champ16 = teams[i];	
												conf16 = i;						
												break;
											}
										}				

										for (i = 0; i < teams.length; i++) {
											if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i) && (conf16!=i)) { 
								// console.log(teams[i].tid);							
												champ17 = teams[i];	
												conf17 = i;						
												break;
											}
										}

										for (i = 0; i < teams.length; i++) {
											if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i) && (conf16!=i) && (conf17!=i)) { 
								// console.log(teams[i].tid);							
												champ18 = teams[i];	
												conf18 = i;						
												break;
											}
										}

										for (i = 0; i < teams.length; i++) {
											if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i) && (conf16!=i) && (conf17!=i) && (conf18!=i)) { 
								// console.log(teams[i].tid);							
												champ19 = teams[i];	
												conf19 = i;						
												break;
											}
										}

										for (i = 0; i < teams.length; i++) {
											if ((teams[i].playoffRoundsWon === 1) && (conf!=i) && (conf2!=i) && (conf3!=i) && (conf4!=i) && (conf5!=i) && (conf6!=i) && (conf7!=i) && (conf8!=i) && (conf9!=i) && (conf10!=i) && (conf11!=i) && (conf12!=i) && (conf13!=i) && (conf14!=i) && (conf15!=i) && (conf16!=i) && (conf17!=i) && (conf18!=i) && (conf19!=i)) { 
								// console.log(teams[i].tid);							
												champ20 = teams[i];	
												conf20 = i;						
												break;
											}
										}							
									}			else {
									   champ16 = champ;
									   champ17 = champ;
									   champ18 = champ;
									   champ19 = champ;
									   champ20 = champ;
									}						
								}			else {
								   champ11 = champ;
								   champ12 = champ;
								   champ13 = champ;
								   champ14 = champ;
								   champ15 = champ;
								   champ16 = champ;
								   champ17 = champ;
								   champ18 = champ;
								   champ19 = champ;
								   champ20 = champ;
								}						
						}			else {
						   champ6 = champ;
						   champ7 = champ;						   
						   champ8 = champ;
						   champ9 = champ;
						   champ10 = champ;
						   champ11 = champ;
						   champ12 = champ;
						   champ13 = champ;
						   champ14 = champ;
						   champ15 = champ;
						   champ16 = champ;
						   champ17 = champ;
						   champ18 = champ;
						   champ19 = champ;
						   champ20 = champ;
						}						
								
						// console.log(champ.tid);
						// console.log(champ2.tid);
						// console.log(champ3.tid);
						// console.log(champ4.tid);
						// console.log(champ5.tid);
                        champ.tid = g.teamAbbrevsCache.indexOf(champ.abbrev);
                        champ2.tid = g.teamAbbrevsCache.indexOf(champ2.abbrev);
                        champ3.tid = g.teamAbbrevsCache.indexOf(champ3.abbrev);
                        champ4.tid = g.teamAbbrevsCache.indexOf(champ4.abbrev);
                        champ5.tid = g.teamAbbrevsCache.indexOf(champ5.abbrev);
                        champ6.tid = g.teamAbbrevsCache.indexOf(champ6.abbrev);
                        champ7.tid = g.teamAbbrevsCache.indexOf(champ7.abbrev);
                        champ8.tid = g.teamAbbrevsCache.indexOf(champ8.abbrev);
                        champ9.tid = g.teamAbbrevsCache.indexOf(champ9.abbrev);
                        champ10.tid = g.teamAbbrevsCache.indexOf(champ10.abbrev);
                        champ11.tid = g.teamAbbrevsCache.indexOf(champ11.abbrev);
                        champ12.tid = g.teamAbbrevsCache.indexOf(champ12.abbrev);
                        champ13.tid = g.teamAbbrevsCache.indexOf(champ13.abbrev);
                        champ14.tid = g.teamAbbrevsCache.indexOf(champ14.abbrev);
                        champ15.tid = g.teamAbbrevsCache.indexOf(champ15.abbrev);
                        champ16.tid = g.teamAbbrevsCache.indexOf(champ16.abbrev);
                        champ17.tid = g.teamAbbrevsCache.indexOf(champ17.abbrev);
                        champ18.tid = g.teamAbbrevsCache.indexOf(champ18.abbrev);
                        champ19.tid = g.teamAbbrevsCache.indexOf(champ19.abbrev);
                        champ20.tid = g.teamAbbrevsCache.indexOf(champ20.abbrev);
						
                return {
                            awards: awards,
//							showExtraConferences: showExtraConferences,		
							showPowerConferences:showPowerConferences,
							showExtraConferencesSecond:showExtraConferencesSecond,
							showIIIPowerConferencesSecond:showIIIPowerConferencesSecond,
							showIIIPowerConferencesFirst:showIIIPowerConferencesFirst,
							showExtraConferencesFirst:showExtraConferencesFirst,													
                            champ: champ,
							champ2: champ2,
							champ3: champ3,
							champ4: champ4,
							champ5: champ5,
							champ6: champ6,
							champ7: champ7,
							champ8: champ8,
							champ9: champ9,
							champ10: champ10,
							champ11: champ11,
							champ12: champ12,
							champ13: champ13,
							champ14: champ14,
							champ15: champ15,
							champ16: champ16,
							champ17: champ17,
							champ18: champ18,
							champ19: champ19,
							champ20: champ20,							
                            retiredPlayers: retiredPlayers,
                            season: inputs.season,
							userTid: g.userTid
                };
            });
        }
    }

    function uiFirst(vm) {
        ko.computed(function () {
            ui.title("Season Summary - " + vm.season());
        }).extend({throttle: 1});
    }

    function uiEvery(updateEvents, vm) {
        components.dropdown("history-conference-dropdown", ["seasons"], [vm.season()], updateEvents);
    }

    return bbgmView.init({
        id: "historyConference",
        get: get,
        runBefore: [updateHistory],
        uiFirst: uiFirst,
        uiEvery: uiEvery
    });
});
